import {
  ToastInfo,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils'
import {
  FeatureDataDetailActionType,
  CurrentFeatureDataDetail,
  FeatureDataDetailState,
  ListDisplayCondition,
  FeatureDataDlLink,
  MlPipelineGenerateFile,
  EventHistoryData,
} from './types'

export type FeatureDataDetailAction = ActionsUnion<
  typeof featureDataDetailActions
>

export const featureDataDetailActions = {
  setCurrentFeatureDataDetail: (
    currentFeatureDataDetail: CurrentFeatureDataDetail
  ): ActionWithPayload<
    'SET_CURRENT_FEATURE_DATA_DETAIL',
    { currentFeatureDataDetail: CurrentFeatureDataDetail }
  > =>
    CreateAction(FeatureDataDetailActionType.SET_CURRENT_FEATURE_DATA_DETAIL, {
      currentFeatureDataDetail,
    }),
  setFeatureDataDetailState: (
    featureDataDetailState: FeatureDataDetailState
  ): ActionWithPayload<
    'SET_FEATURE_DATA_DETAIL_STATE',
    { featureDataDetailState: FeatureDataDetailState }
  > =>
    CreateAction(FeatureDataDetailActionType.SET_FEATURE_DATA_DETAIL_STATE, {
      featureDataDetailState,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_DETAIL,
      {
        inProgress,
      }
    ),
  setInProgressForDownloading: (
    isInProgressForDownloading: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_DOWNLOADING',
    { isInProgressForDownloading: boolean }
  > =>
    CreateAction(FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_DOWNLOADING, {
      isInProgressForDownloading,
    }),
  setInProgressForGettingGenerateFiles: (
    isInProgressForGettingGenerateFiles: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_FILES',
    { isInProgressForGettingGenerateFiles: boolean }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_GETTING_FILES,
      {
        isInProgressForGettingGenerateFiles,
      }
    ),
  setInProgressForEventHistories: (
    isInProgressForEventHistories: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_EVENT_HISTORIES_FOR_FEATURE_DATA_DETAIL',
    { isInProgressForEventHistories: boolean }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_EVENT_HISTORIES_FOR_FEATURE_DATA_DETAIL,
      {
        isInProgressForEventHistories,
      }
    ),
  clearFeatureDataDetailState: (): Action<'CLEAR_FEATURE_DATA_DETAIL_STATE'> =>
    CreateAction(FeatureDataDetailActionType.CLEAR_FEATURE_DATA_DETAIL_STATE),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_FEATURE_DATA_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_DETAIL,
      {
        toastInfo,
      }
    ),
  setFeatureDataListDisplayCondition: (
    condition: ListDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_DISPLAY_CONDITION',
    { condition: ListDisplayCondition }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_FEATURE_DATA_DISPLAY_CONDITION,
      {
        condition,
      }
    ),
  setFeatureDataListPagingState: (
    nextPageSubState: 'Enable' | 'Unable'
  ): ActionWithPayload<
    'SET_RELATED_FEATURE_DATA_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL',
    { nextPageSubState: 'Enable' | 'Unable' }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_RELATED_FEATURE_DATA_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL,
      {
        nextPageSubState,
      }
    ),
  setFeatureDataDlLinks: (
    featureDataDlLinks: FeatureDataDlLink[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_DL_LINKS',
    { featureDataDlLinks: FeatureDataDlLink[] }
  > =>
    CreateAction(FeatureDataDetailActionType.SET_FEATURE_DATA_DL_LINKS, {
      featureDataDlLinks,
    }),
  setMlPipelineGenerateFiles: (
    mlPipelineGenerateFiles: MlPipelineGenerateFile[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_GENERATE_FILES_FOR_FEATURE_DATA_DETAIL',
    { mlPipelineGenerateFiles: MlPipelineGenerateFile[] }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_ML_PIPELINE_GENERATE_FILES_FOR_FEATURE_DATA_DETAIL,
      {
        mlPipelineGenerateFiles,
      }
    ),
  setMlPipelineList: (
    mlPipelineList: EventHistoryData[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_LIST_FOR_FEATURE_DATA_DETAIL',
    { mlPipelineList: EventHistoryData[] }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_ML_PIPELINE_LIST_FOR_FEATURE_DATA_DETAIL,
      {
        mlPipelineList,
      }
    ),
  setMlPipelineListDisplayCondition: (
    condition: ListDisplayCondition
  ): ActionWithPayload<
    'SET_ML_PIPELINE_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_DETAIL',
    { condition: ListDisplayCondition }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_ML_PIPELINE_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_DETAIL,
      {
        condition,
      }
    ),
  setMlPipelineListPagingState: (
    nextPageSubState: 'Enable' | 'Unable'
  ): ActionWithPayload<
    'SET_ML_PIPELINE_LIST_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL',
    { nextPageSubState: 'Enable' | 'Unable' }
  > =>
    CreateAction(
      FeatureDataDetailActionType.SET_ML_PIPELINE_LIST_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL,
      {
        nextPageSubState,
      }
    ),
}
