import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { ReactReduxFirebaseConfig } from 'react-redux-firebase'

import { getFirebaseOptions } from './options'
import { decideEnvironment } from 'state/utils/urls'

// react-redux-firebase config
export const rrfConfig: Partial<ReactReduxFirebaseConfig> = {
  // userProfile: 'users',
  // useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
}

const firebaseApp = initializeApp(getFirebaseOptions())
const authInstance = getAuth(firebaseApp)
const functionsInstance = getFunctions(firebaseApp, 'asia-northeast1')
const firestoreInstance = getFirestore(firebaseApp)

if (decideEnvironment() === 'local') {
  // use Firebase Emulator Suite hosted on localhost
  connectAuthEmulator(authInstance, 'http://localhost:9099/', {
    disableWarnings: true,
  })
  connectFunctionsEmulator(functionsInstance, 'localhost', 5001)
  connectFirestoreEmulator(firestoreInstance, 'localhost', 8080)
}

export { authInstance, functionsInstance, firestoreInstance, firebase }
