import { Dispatch } from 'redux'
import { sceneCameraListActions } from './actions'
import { SceneCamera, SceneCameraListDisplayCondition } from './types'
import { State } from 'state/store'

import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import {
  getAug3DSceneCameraRevisionsCollection,
  getAug3DSceneCamerasCollection,
} from 'state/firebase'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraDocument } from 'utils/fireStore/aug3dSceneCamera'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraRevisionDocument } from 'utils/fireStore/aug3dSceneCameraRevision'
import { domainDataOperations } from 'state/app/domainData/operations'
import { isUndefined } from 'utils/typeguard'

export const onUpdateSceneCameraDocument = async (
  dispatch: Dispatch,
  userGroupId: string,
  snapshot: QuerySnapshot<DocumentData>,
  sceneCameraIds: string[],
  condition: SceneCameraListDisplayCondition,
  lastSceneCameraDoc: DocumentData | undefined
) => {
  const sceneCameraList = await Promise.all(
    snapshot.docs.map(async (document: DocumentData) => {
      const aug3dSceneCameraDocData = document.data()
      if (
        !fireStoreTypeGuardForAug3dSceneCameraDocument(aug3dSceneCameraDocData)
      ) {
        return undefined
      }

      // リビジョンを取得する
      const sceneCameraRevisionDocs = (
        await getDocs(
          query(
            getAug3DSceneCameraRevisionsCollection(
              userGroupId,
              aug3dSceneCameraDocData['aug-3d-scene-camera-id']
            ),
            orderBy('aug-3d-scene-camera-revision', 'desc'),
            limit(1)
          )
        )
      ).docs

      if (
        sceneCameraRevisionDocs.length === 0 ||
        !fireStoreTypeGuardForAug3dSceneCameraRevisionDocument(
          sceneCameraRevisionDocs[0].data()
        )
      ) {
        return undefined
      }

      return {
        id: aug3dSceneCameraDocData['aug-3d-scene-camera-id'],
        name: aug3dSceneCameraDocData['name'],
        overview: aug3dSceneCameraDocData['overview'],
        createdAt: aug3dSceneCameraDocData['created-at'],
        createdBy: aug3dSceneCameraDocData['created-by'],
      } as SceneCamera
    })
  )

  // aug-3d-scene-camera-id を保持
  // すでに保持している aug-3d-scene-camera-id が存在する場合は、現状の検索位置以降の aug-3d-scene-camera-id を一度破棄し
  // 新たに取得した aug-3d-scene-camera-id を保持する
  if (sceneCameraList.length >= 0) {
    if (lastSceneCameraDoc) {
      const index = sceneCameraIds.findIndex(
        (id) => id === lastSceneCameraDoc?.id
      )
      const beforePageIds = sceneCameraIds.slice(0, index + 1)
      dispatch(
        sceneCameraListActions.setSceneCameraIds([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        sceneCameraListActions.setSceneCameraIds([
          ...sceneCameraIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
  }
  // 取得したカメラ配置の一覧を保持
  dispatch(
    sceneCameraListActions.setSceneCameraList(
      sceneCameraList.filter((item) => item !== undefined) as SceneCamera[]
    )
  )
  let totalCountQuery =
    condition.selectedUserGroupKind === 'UserGroup'
      ? query(getAug3DSceneCamerasCollection(userGroupId))
      : query(
          getAug3DSceneCamerasCollection(userGroupId),
          where('access-control.is-shared', '==', true),
          where('access-control.share-permissions.webapp', '==', 'list')
        )

  // 文字列検索が存在する場合は、aug-3d-scene-camera-idの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )
    totalCountQuery = query(
      totalCountQuery,
      orderBy('aug-3d-scene-camera-id', 'asc'),
      where('aug-3d-scene-camera-id', '>=', startCode),
      where('aug-3d-scene-camera-id', '<=', endCode)
    )
  }

  const totalCount = await getCountFromServer(totalCountQuery)
  dispatch(
    sceneCameraListActions.setListDisplayCondition({
      ...condition,
      totalCount: totalCount.data().count,
    })
  )
}

export const getSceneCameraDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
    dispatch,
    getState
  )

  // 共有データの参照権がない場合は、カスタマーデータに変更する
  if (!hasSharedUserGroup) {
    dispatch(
      sceneCameraListActions.setListDisplayCondition({
        ...getState().pages.sceneCameraListState.domainData
          .sceneCameraListDisplayCondition,
        selectedUserGroupKind: 'UserGroup',
      })
    )
  }

  const userGroupId =
    getState().pages.sceneCameraListState.domainData
      .sceneCameraListDisplayCondition.selectedUserGroupKind === 'UserGroup'
      ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
      : domainDataOperations.getSharedUserGroupId()(dispatch, getState)

  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.sceneCameraListState.domainData
      .currentSceneCameraListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      sceneCameraListActions.setCurrentSceneCameraListSnapshot(undefined)
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.sceneCameraListState.domainData
      .sceneCameraListDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery =
    getState().pages.sceneCameraListState.domainData
      .sceneCameraListDisplayCondition.selectedUserGroupKind === 'UserGroup'
      ? query(getAug3DSceneCamerasCollection(userGroupId))
      : query(
          getAug3DSceneCamerasCollection(userGroupId),
          where('access-control.is-shared', '==', true),
          where('access-control.share-permissions.webapp', '==', 'list')
        )

  // aug-3d-scene-camerasを表示件数分取得
  commonQuery = query(commonQuery, limit(condition.displayNumber))

  // 文字列検索が存在する場合は、AugParamTemplateIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、AugParamTemplateIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('aug-3d-scene-camera-id', 'asc'),
      where('aug-3d-scene-camera-id', '>=', startCode),
      where('aug-3d-scene-camera-id', '<=', endCode)
    )
  } else {
    const sortKey =
      condition.sortKey === 'generated-at' ? 'created-at' : condition.sortKey
    commonQuery = query(commonQuery, orderBy(sortKey, condition.sortOrder))
  }

  const sceneCameraIds =
    getState().pages.sceneCameraListState.domainData.sceneCameraIds

  // 既に取得していれば最後の要素から取得
  let lastItem: DocumentData | undefined = undefined
  if (sceneCameraIds.length > 0) {
    lastItem =
      getState().pages.sceneCameraListState.domainData
        .sceneCameraListDisplayCondition.selectedUserGroupKind === 'UserGroup'
        ? await getDoc(
            doc(
              getAug3DSceneCamerasCollection(userGroupId),
              sceneCameraIds[sceneCameraIds.length - 1]
            )
          )
        : (
            await getDocs(
              query(
                getAug3DSceneCamerasCollection(userGroupId),
                where(
                  'aug-3d-scene-camera-id',
                  '==',
                  sceneCameraIds[sceneCameraIds.length - 1]
                ),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            )
          ).docs.map((augParamTemplate) => augParamTemplate.data())[0]

    commonQuery = query(commonQuery, startAfter(lastItem))
  }

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      onUpdateSceneCameraDocument(
        dispatch,
        userGroupId,
        snapshot,
        sceneCameraIds,
        condition,
        lastItem
      )
    }
  )

  // 現在、表示中のカメラ配置の一覧を保持
  dispatch(sceneCameraListActions.setCurrentSceneCameraListSnapshot(snapshot))
}

export const sceneCameraListOperations = {
  /** リストを取得する */
  getSceneCameraList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(sceneCameraListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentSceneCameraIds: string[] =
          getState().pages.sceneCameraListState.domainData.sceneCameraIds
        const condition =
          getState().pages.sceneCameraListState.domainData
            .sceneCameraListDisplayCondition
        const sceneCameraIds = currentSceneCameraIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(sceneCameraListActions.setSceneCameraIds(sceneCameraIds))

        // カメラ配置の一覧を取得
        await getSceneCameraDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(sceneCameraListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.sceneCameraListState.domainData
            .currentSceneCameraListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
