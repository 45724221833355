import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { BuildEntryParamsType } from '.'

const timeoutLimitSeconds = 15

export const BuildEntryApi = {
  executeBuildEntry: (
    buildEntryParams: BuildEntryParamsType
  ): Promise<HttpsCallableResult<{ mlPipelineId: string; stepId: string }>> =>
    httpsCallable<
      BuildEntryParamsType,
      { mlPipelineId: string; stepId: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'edge-container-image-builds-execute',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      ...buildEntryParams,
    }),
}
