import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
const timeoutLimitSeconds = 15

export const ModelDetailApi = {
  getModelsFiles: (trainedModelId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-models',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      trainedModelId: trainedModelId,
    }),
}
