import React from 'react'

import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import IconButton from '@mui/material/IconButton'

import { SettingIconProps } from './types'

export const SettingIcon: React.FC<SettingIconProps> = (props) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <TextSnippetOutlinedIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
