import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { FeatureDataGeneratingParamsType } from '.'

const timeoutLimitSeconds = 15

export const FeatureDataGeneratingApi = {
  executeFeatureDataGenerating: (
    featureDataGeneratingParams: FeatureDataGeneratingParamsType
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'feature-data-generating-execute',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      ...featureDataGeneratingParams,
    }),
}
