import React from 'react'
import Button from '@mui/material/Button'
import Check from '@mui/icons-material/Check'
import { CheckButtonProps } from './types'

export const CheckButton: React.FC<CheckButtonProps> = (
  props: CheckButtonProps
) => {
  return (
    <Button
      variant='contained'
      sx={props.width ? { width: props.width } : undefined}
      startIcon={props.checked ? <Check /> : undefined}
      disabled={props.disabled}
      color={props.checked ? 'success' : 'primary'}
      onClick={(e) => {
        props.onClick?.(e, !props.checked)
      }}
      data-testid={props['data-testid']}
    >
      {props.checked ? props.checkedLabel : props.uncheckedLabel}
    </Button>
  )
}
