import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { State } from 'state/store'
import {
  PasswordUpdateAction,
  passwordUpdateOperations,
  passwordUpdateActions,
} from 'state/ducks/passwordUpdate'

import { isUndefined } from 'utils/typeguard'
import {
  GlobalLoading,
  CustomTrainingPageParagraph,
  Toast,
  showToast,
} from 'views/components'
import { List, ListItem, ListItemText } from '@mui/material'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.passwordUpdateState,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, PasswordUpdateAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** パスワード更新実行 */
  executePasswordUpdate: (newPassword: string) =>
    dispatch(passwordUpdateOperations.executePasswordUpdate(newPassword)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(passwordUpdateActions.setToastInfo(undefined)),
  /** パスワード更新のStateをクリア */
  clearPasswordUpdateState: () => dispatch(passwordUpdateActions.clearState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(7),
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
    margin: 'auto 0',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  infoTextField: {
    backgroundColor: 'rgba(237, 219, 94, 0.5)',
    border: '1px solid rgb(237, 219, 94)',
    padding: theme.spacing(1),
    '& ul': {
      margin: '0.5em 0',
      '& li': {
        marginBottom: '0.5em',
      },
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  passwordInput: {
    width: '100%',
  },
  textField: {
    backgroundColor: '#fafafa',
    width: '100%',
    borderBottom: 'none',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
}))

const PasswordUpdate: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()

  /** 入力されたパスワード */
  const [newPassword, setNewPassword] = useState<string>('')
  /** 再入力の入力されたパスワード */
  const [newPasswordSecondTime, setNewPasswordSecondTime] = useState<string>('')
  /** パスワードの表示状態 */
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(true)

  /** パスワード更新 */
  const executePasswordUpdate = () => {
    props.executePasswordUpdate(newPassword)
  }

  /** パスワード更新のStateをクリア */
  useEffect(() => {
    return () => {
      props.clearPasswordUpdateState()
    }
  }, [])

  /** パスワード更新後、必要であればMFA設定画面へ */
  useEffect(() => {
    if (
      props.appState.isPasswordUpdated &&
      !isUndefined(props.appState.needMfa)
    ) {
      if (props.appState.needMfa) {
        history.push('/mfa-setting')
      } else {
        history.push('/')
      }
    }
  }, [props.appState.isPasswordUpdated, props.appState.needMfa])

  /** トーストのコンポーネントを取得する */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** トースト情報があった場合、表示する */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** パスワードが一致しているかつ12文字以上で有効パスワードとする */
  const isValidPassword = useMemo(() => {
    return (
      newPassword === newPasswordSecondTime &&
      newPasswordSecondTime.length >= 12 &&
      newPassword.length >= 12
    )
  }, [newPassword, newPasswordSecondTime])

  return (
    <>
      <div className={classes.container}>
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.content}>
            <CustomTrainingPageParagraph title={'パスワード更新'}>
              <Box className={classes.infoTextField}>
                <Typography>新しいパスワードを入力してください。</Typography>
                <ul>
                  <li>12文字以上</li>
                  <li>「英大文字」「英小文字」「数字」「記号」の4種類を含む</li>
                </ul>
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph title={'新しいパスワード'}>
              <FormControl variant='filled' className={classes.passwordInput}>
                <InputLabel htmlFor='filled-adornment-password'></InputLabel>
                <FilledInput
                  className={classes.textField}
                  id='filled-adornment-password'
                  type={isPasswordVisible ? 'password' : 'text'}
                  value={newPassword}
                  placeholder={'new password'}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setNewPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        edge='end'
                      >
                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  data-testid={'new-password-input'}
                />
              </FormControl>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph title={'新しいパスワード（再入力）'}>
              <FormControl variant='filled' className={classes.passwordInput}>
                <InputLabel htmlFor='filled-adornment-password'></InputLabel>
                <FilledInput
                  className={classes.textField}
                  id='filled-adornment-password'
                  type={isPasswordVisible ? 'password' : 'text'}
                  value={newPasswordSecondTime}
                  placeholder={'new password'}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setNewPasswordSecondTime(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        edge='end'
                      >
                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  data-testid={'new-password-second-time-input'}
                />
              </FormControl>
            </CustomTrainingPageParagraph>
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              <Button
                data-testid='password-update'
                variant='contained'
                color='primary'
                disabled={!isValidPassword}
                onClick={() => executePasswordUpdate()}
                className={classes.passwordInput}
              >
                {'パスワード更新'}
              </Button>
            </div>
          </div>
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

export const PasswordUpdatePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PasswordUpdate))
