import { MultiFactorResolver, RecaptchaVerifier } from 'firebase/auth'
// Type
export type LoginStateType =
  | 'Loading'
  | 'NotLogin'
  | 'Loggedin'
  | 'LoginFail'
  | 'NeedMfa'
  | 'PasswordUpdate'
  | 'MfaSetting'
  | 'MfaFail'
  | 'TooManyRequests'
  | 'NoCustomerList'
  | 'PasswordReset'

export type AuthStateType = 'LoginFail' | 'UnAuthed' | 'Authed' | 'AuthFail'

// ActionType
export const LoginActionType = {
  SET_RECAPCHA_VERIFIER: 'SET_RECAPCHA_VERIFIER',
  SET_LOGIN_STATE: 'SET_LOGIN_STATE',
  SET_AUTH_STATE: 'SET_AUTH_STATE',
  SET_VERIFICATION_ID: 'SET_VERIFICATION_ID',
  SET_LOGIN_ERROR: 'SET_LOGIN_ERROR',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_FAIL_TOO_MANY_REQUESTS_STATE: 'SET_FAIL_TOO_MANY_REQUESTS_STATE',
  SET_IN_PROGRESS_FOR_LOGIN: 'SET_IN_PROGRESS_FOR_LOGIN',
  CLEAR_LOGIN_STATE: 'CLEAR_LOGIN_STATE',
} as const

export interface LoginError {
  errorCode: string
  resolver?: MultiFactorResolver
}

export type SendPasswordResetMailSubState =
  | 'BeforeSending'
  | 'SendingSuccess'
  | 'SendingError'

export interface LoginState {
  appState: {
    recaptchaVerifier?: RecaptchaVerifier
    loginError: LoginError
    verificationId: string
    loginState: LoginStateType
    authState: AuthStateType
    errorMessage: string
    inProgress: boolean
  }
}
