import { DatasetDocument } from './types'

import {
  timeStampSchema,
  optionalVersionSchema,
  extendedObjectClassificationSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** datasets documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as DatasetDocument
  const schema = {
    required: [
      'algorithm-id',
      'annotation-format-id',
      'annotation-set-list',
      'dataset-id',
      'dataset-template-id',
      'user-group-id',
      'generated-for',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'annotation-format-id': {
        type: 'string',
      },
      'annotation-format-version': {
        ...optionalVersionSchema,
      },
      'annotation-set-list': {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      'dataset-id': {
        type: 'string',
      },
      'dataset-template-id': {
        type: 'string',
      },
      'generated-for': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
      extended: {
        type: 'object',
        properties: {
          'object-classification': {
            ...extendedObjectClassificationSchema,
          },
        },
      },
    },
  }

  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'dataset',
      value: data as unknown as DataObject,
    })
    return false
  }
}
