import { AccountFunction, CustomerFunction } from 'views/utils/types'

interface HasAuthAccountFunction {
  superUser: boolean
  accountGroupRole: string
  type?: AccountFunction
}

interface HasAuthCustomerFunction {
  auth: string
  type?: CustomerFunction
}

/** スーパーユーザーが使える機能 */
const superUserFunctionList: AccountFunction[] = [
  'ASSOCIATE_CUSTOMER_TO_ACCOUNT_GROUP',
  'CHANGE_ACCOUNT_GROUP_MFA_SETTING',
  'CHANGE_ACCOUNT_ACCOUNT_GROUP_PERMISSION',
  'CREATE_NEW_ACCOUNT_IN_ACCOUNT_GROUP',
  'REMOVE_ACCOUNT_FROM_ACCOUNT_GROUP',
  'ASSOCIATE_CUSTOMER_TO_ACCOUNT',
  'CHANGE_ACCOUNT_CUSTOMER_ROLE',
  'DISPLAY_ACCOUNT_GROUP_NAME_BELONGED_SELF_ACCOUNT',
]

/** account-group-roleがadminの場合に使える機能 */
const adminFunctionList: AccountFunction[] = [
  'CREATE_NEW_ACCOUNT_IN_ACCOUNT_GROUP',
  'REMOVE_ACCOUNT_FROM_ACCOUNT_GROUP',
  'ASSOCIATE_CUSTOMER_TO_ACCOUNT',
  'CHANGE_ACCOUNT_CUSTOMER_ROLE',
  'DISPLAY_ACCOUNT_GROUP_NAME_BELONGED_SELF_ACCOUNT',
]

/** account-group-roleがuserの場合に使える機能 */
const userFunctionList: AccountFunction[] = [
  'DISPLAY_ACCOUNT_GROUP_NAME_BELONGED_SELF_ACCOUNT',
]

/** カスタマーロールがadminの場合に使える機能 */
const adminFunctions: CustomerFunction[] = [
  // 'DELETE', // TODO: 削除機能が実装され次第使用する
  'UPLOAD_MODEL',
  'USE_SCREEN_FOR_PATCH_APPLY',
  'USE_FUNCTION_REQUIRED_FS_OPERATION',
]

/** カスタマーロールがdeveloperの場合に使える機能 */
const developerFunctions: CustomerFunction[] = [
  'UPLOAD_MODEL',
  'USE_SCREEN_FOR_PATCH_APPLY',
  'USE_FUNCTION_REQUIRED_FS_OPERATION',
]

/** カスタマーロールがuserの場合に使える機能 */
const userFunctions: CustomerFunction[] = ['USE_FUNCTION_REQUIRED_FS_OPERATION']

/**
 * 現在のアカウントに、対象の機能を使用する権限があるかチェックする
 */
export const hasAuthAccountFunction = (props: HasAuthAccountFunction) => {
  const { type, superUser, accountGroupRole } = props

  if (type == null) return true
  if (superUser) return superUserFunctionList.includes(type)
  if (accountGroupRole === 'admin') return adminFunctionList.includes(type)
  if (accountGroupRole === 'user') return userFunctionList.includes(type)
  return false
}

/**
 * 現在のカスタマーロールに、対象の機能を使用する権限があるかチェックする
 */
export const hasAuthCustomerFunction = (props: HasAuthCustomerFunction) => {
  if (props.type == null) return true
  switch (props.auth) {
    case 'admin':
      return adminFunctions.includes(props.type)
    case 'developer':
      return developerFunctions.includes(props.type)
    case 'user':
    case '':
      return userFunctions.includes(props.type)
    default:
      return false
  }
}
