import { DatasetAugmentationAction } from './actions'
import {
  DatasetAugmentationActionType,
  CurrentDatasetAugmentationState,
} from './types'

export const initialState: CurrentDatasetAugmentationState = {
  domainData: {
    uploaded3dData: [],
    textureList: [],
    textureListDisplayCondition: {
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
    },
    presetList: [],
    selectedPreset: undefined,
    presetListDisplayCondition: {
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
      searchValue: '',
    },
    backgrounds: [],
    selectedBackgrounds: [],
    lightGroups: [],
    selectedLightGroups: [],
    cameras: [],
    selectedCameras: [],
    layouts: [],
    layoutSeed: 1000,
    renderersInput: {
      augmentationNumber: 30,
      size: {
        width: 1280,
        height: 720,
      },
      depth: {
        min: 0,
        max: 6.5535,
      },
    },
    augmentationMetaData: {
      name: '',
      remarks: '',
    },
    generateDatasetMetaData: {
      name: '',
      remarks: '',
    },
    rotation: {
      userGroupId: '',
      augSiObjectRotationGroupId: '',
    },
    postProcessing: '',
  },
  appState: {
    datasetAugmentationState: '3dDataState',
    datasetAugmentationSubState: {
      '3dDataSubState': 'EmptyRequired',
      renderSettingSubState: 'EmptyRequired',
      placementSubState: 'EmptyRequired',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
  },
}

export const DatasetAugmentationReducer = (
  state: CurrentDatasetAugmentationState = initialState,
  action: DatasetAugmentationAction
): CurrentDatasetAugmentationState => {
  switch (action.type) {
    case DatasetAugmentationActionType.SET_UPLOADED_CAD_FILE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          uploaded3dData: [
            ...state.domainData.uploaded3dData,
            action.payload.uploadedCadFile,
          ],
        },
      }
    case DatasetAugmentationActionType.SET_TEXTURE_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          textureList: action.payload.textureList,
        },
      }
    case DatasetAugmentationActionType.SET_UPLOADED_CAD_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          uploaded3dData: action.payload.uploadedCadData,
        },
      }
    case DatasetAugmentationActionType.SET_UPLOADED_PLACEMENT_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          uploadedPlacementData: action.payload.placementData,
        },
      }
    case DatasetAugmentationActionType.CLEAR_TEXTURE_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          textureList: [],
        },
      }
    case DatasetAugmentationActionType.SET_TEXTURE_LIST_DISPLAY_CONDITION:
      if (action.payload.textureListDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            textureListDisplayCondition:
              action.payload.textureListDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case DatasetAugmentationActionType.CLEAR_TEXTURE_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          textureListDisplayCondition: {
            ...initialState.domainData.textureListDisplayCondition,
          },
        },
      }
    case DatasetAugmentationActionType.SET_DATASET_AUGMENTATION_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetAugmentationState: action.payload.datasetAugmentationStateKind,
        },
      }
    case DatasetAugmentationActionType.SET_TOAST_INFO_FOR_AUGMENTATION:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case DatasetAugmentationActionType.SET_BACKGROUNDS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          backgrounds: action.payload.backgrounds,
        },
      }
    case DatasetAugmentationActionType.SET_SELECTED_BACKGROUNDS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedBackgrounds: action.payload.selectedBackgrounds,
        },
      }
    case DatasetAugmentationActionType.SET_LIGHT_GROUPS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          lightGroups: action.payload.lightGroups,
        },
      }
    case DatasetAugmentationActionType.SET_SELECTED_LIGHT_GROUPS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedLightGroups: action.payload.selectedLightGroups,
        },
      }
    case DatasetAugmentationActionType.SET_CAMERAS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          cameras: action.payload.cameras,
        },
      }
    case DatasetAugmentationActionType.SET_SELECTED_CAMERAS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedCameras: action.payload.selectedCameras,
        },
      }
    case DatasetAugmentationActionType.SET_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          presetList: action.payload.presetList,
        },
      }
    case DatasetAugmentationActionType.SET_SELECTED_PRESET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedPreset: action.payload.preset,
        },
      }
    case DatasetAugmentationActionType.CLEAR_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          presetList: [],
        },
      }
    case DatasetAugmentationActionType.SET_PRESET_LIST_DISPLAY_CONDITION:
      if (action.payload.presetListDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            presetListDisplayCondition:
              action.payload.presetListDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case DatasetAugmentationActionType.CLEAR_PRESET_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          presetListDisplayCondition: {
            ...initialState.domainData.presetListDisplayCondition,
          },
        },
      }
    case DatasetAugmentationActionType.SET_LAYOUTS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          layouts: action.payload.layouts,
        },
      }
    case DatasetAugmentationActionType.SET_SELECTED_LAYOUT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedLayout: action.payload.layout,
        },
      }
    case DatasetAugmentationActionType.SET_RENDERERS_INPUT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          renderersInput: action.payload.renderersInput,
        },
      }
    case DatasetAugmentationActionType.SET_LAYOUT_SEED:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          layoutSeed: action.payload.layoutSeed,
        },
      }
    case DatasetAugmentationActionType.SET_AUGMENTATION_OUTPUT_FORMAT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          outputFormat: action.payload.outputFormat,
        },
      }
    case DatasetAugmentationActionType.SET_AUGMENTATION_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          augmentationMetaData: action.payload.augmentationMetaData,
        },
      }
    case DatasetAugmentationActionType.SET_GENERATE_DATASET_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          generateDatasetMetaData: action.payload.generateDatasetMetaData,
        },
      }
    case DatasetAugmentationActionType.SET_DATASET_AUGMENTATION_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetAugmentationSubState:
            action.payload.datasetAugmentationSubState,
        },
      }
    case DatasetAugmentationActionType.SET_IN_PROGRESS_FOR_AUGMENTATION:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case DatasetAugmentationActionType.SET_EXECUTED_INFO_FOR_AUGMENTATION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executionInfo: action.payload.executedInfo,
        },
      }
    case DatasetAugmentationActionType.SET_AUG_SI_OBJECT_ROTATION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          rotation: action.payload.augSiObjectRotation,
        },
      }
    case DatasetAugmentationActionType.CLEAR_CURRENT_AUGMENTATION_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
