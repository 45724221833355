import { Dispatch } from 'redux'
import {
  getGroupedDataCollection,
  getDatasetQueryCollection,
  getDatasetsMetaDataCollection,
  getTrainingImagesCollection,
  getTrainingDataCollection,
  getAccountSettingCollection,
  getClassSetMetaDataCollection,
} from 'state/firebase'
import { State } from 'state/store'
import { datasetDetailActions } from './actions'
import { DatasetDetailApi } from './apis'
import { getArrayChunk } from 'state/utils'
import {
  DocumentData,
  Timestamp,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForGroupedDataDocument } from 'utils/fireStore/groupedData'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForTrainingImagesDocument } from 'utils/fireStore/trainingImage'
import { fireStoreTypeGuard as fireStoreTypeGuardForTrainingDataDocument } from 'utils/fireStore/trainingData'
import { fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaDataDocument } from 'utils/fireStore/classSetMetaData'
import { saveAs } from 'file-saver'
import { AnnotationSetDocument, Extended } from './types'
import { GetObjectApi } from './apis'
import { isUndefined } from 'utils/typeguard'
import { Extensions, TrainingImageInfo } from 'types/StateTypes'
import axios from 'axios'

// Firestore の collection に対する where in の上限数
const FIRESTORE_WHERE_IN_QUOTA = 10

interface AnnotationResponse {
  extensions: {
    classes: [
      {
        class_name: string
        image_id_list: string[]
      },
      {
        class_name: string
        image_id_list: string[]
      }
    ]
  }
}

const downloadZip = async (
  getState: () => State,
  data: { datasetId?: string; annotationSetId?: string }
) => {
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId
  const accountId =
    getState().app.domainData.authedUser.auth.customClaims.accountId
  const accountGroupId =
    getState().app.domainData.authedUser.auth.customClaims.accountGroupId

  const zipSignedUrl = await DatasetDetailApi.downloadZip(
    accountGroupId,
    accountId,
    userGroupId,
    data.datasetId,
    data.annotationSetId
  )
  const res = await GetObjectApi.get(zipSignedUrl.data.signedUrl)

  const blob = res.data

  saveAs(blob, zipSignedUrl.data.fileName)
}

const isCurrentDataset = (datasetId: string, getState: () => State): boolean =>
  datasetId ===
  getState().pages.datasetDetailState.domainData.currentDatasetDetail.datasetId

export const DatasetDetailOperations = {
  /** リストを取得する */
  getDatasetDetail:
    (datasetId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId
        /** datasetのデータ */
        const datasetData = (
          await getDoc(doc(getDatasetQueryCollection(userGroupId), datasetId))
        ).data()

        if (!datasetData) {
          dispatch(
            datasetDetailActions.setDatasetDetailState({
              ...getState().pages.datasetDetailState.appState
                .datasetDetailState,
              datasetState: 'NotFoundProcessed',
            })
          )
          return
        }

        if (!fireStoreTypeGuardForDatasetQueryDocument(datasetData)) {
          dispatch(
            datasetDetailActions.setDatasetDetailState({
              ...getState().pages.datasetDetailState.appState
                .datasetDetailState,
              datasetState: 'Failed',
            })
          )
          return
        }

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                datasetData['created-by']
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }
        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          return
        }

        const annotationFormat =
          getState().app.domainData.annotationFormats &&
          getState().app.domainData.annotationFormats.find(
            (format) =>
              format.annotationFormatId === datasetData['annotation-format-id']
          )

        const datasetTemplate =
          getState().app.domainData.datasetTemplates &&
          getState().app.domainData.datasetTemplates.find(
            (template) =>
              template.datasetTemplateId === datasetData['dataset-template-id']
          )

        const algorithm = getState().app.domainData.algorithms.find(
          (algorithm) => algorithm.algorithmId === datasetData['algorithm-id']
        )

        let extended: Extended | undefined = undefined
        if (
          !isUndefined(datasetData) &&
          !isUndefined(datasetData?.['extended'])
        ) {
          extended = await getExtendedField(datasetData, userGroupId)
        }

        dispatch(
          datasetDetailActions.setCurrentDatasetDetail({
            algorithm: {
              algorithmId: datasetData['algorithm-id'],
              metadata: {
                name: algorithm?.metadata.name ?? { en: '', ja: '' },
              },
            },
            annotationFormat: {
              annotationFormatId: datasetData
                ? datasetData['annotation-format-id']
                : '',
              annotationFormatKind: annotationFormat?.annotationFormatKind,
              annotationFormatVersion: datasetData['annotation-format-version'][
                'display-name'
              ]
                ? {
                    displayName:
                      datasetData['annotation-format-version']['display-name'],
                    major: datasetData['annotation-format-version']['major'],
                    minor: datasetData['annotation-format-version']['minor'],
                    patch: datasetData['annotation-format-version']['patch'],
                  }
                : undefined,
            },
            createdAt: datasetData['created-at'],
            createdBy: accountSetting
              ? {
                  id: datasetData['created-by'] ?? '',
                  firstName: accountSetting['first-name'] ?? '',
                  familyName: accountSetting['family-name'] ?? '',
                }
              : datasetData
              ? datasetData['created-by']
              : '',
            datasetId: datasetData['dataset-id'],
            datasetName: datasetData['dataset-name'],
            datasetRemarks: datasetData['dataset-remarks'],
            datasetTemplate: {
              datasetTemplateId: datasetData['dataset-template-id'],
              metadata: {
                name: datasetTemplate?.metadata.name ?? { en: '', ja: '' },
              },
            },
            generatedFor: datasetData['generated-for'],
            extended,
          })
        )
        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            datasetState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            datasetState: 'Failed',
          })
        )
      } finally {
        dispatch(datasetDetailActions.setInProgress(false))
      }
    },
  /** アノテーションの表示データを取得 */
  getAnnotationList:
    (datasetId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetDetailActions.setInProgressForAnnotationSet(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        /** datasetのデータ */
        const datasetData = (
          await getDoc(doc(getDatasetQueryCollection(userGroupId), datasetId))
        ).data()

        if (!datasetData) {
          dispatch(
            datasetDetailActions.setDatasetDetailState({
              ...getState().pages.datasetDetailState.appState
                .datasetDetailState,
              datasetState: 'NotFoundProcessed',
            })
          )
          return
        }

        const annotationSetList = !isUndefined(
          datasetData['annotation-set-list']
        )
          ? await Promise.all(
              datasetData['annotation-set-list'].map(
                async (annotationSet: AnnotationSetDocument) => {
                  const groupedDataId = annotationSet['grouped-data-id']
                  const groupedData = (
                    await getDoc(
                      doc(getGroupedDataCollection(userGroupId), groupedDataId)
                    )
                  ).data()
                  if (
                    !groupedData ||
                    !fireStoreTypeGuardForGroupedDataDocument(groupedData)
                  ) {
                    return undefined
                  }

                  let annotationFileName = ''
                  let extensions: Extensions | undefined = undefined
                  if (annotationSet['annotation-id']) {
                    const annotationFileData =
                      await DatasetDetailApi.getAnnotationFile(
                        annotationSet['annotation-id']
                      )
                    const annotationFile = annotationFileData.data as {
                      name: string
                      fileNameForDownload: string
                    }
                    annotationFileName = annotationFile.name

                    if (datasetData['extended']?.['object-classification']) {
                      const annotationData = (
                        await axios.get<AnnotationResponse>(
                          annotationFile.fileNameForDownload
                        )
                      ).data

                      if (annotationData?.extensions?.classes != null) {
                        extensions = {
                          extensions: {
                            classes: annotationData.extensions.classes.map(
                              (classIem) => ({
                                className: classIem.class_name,
                                imageIdList: classIem.image_id_list,
                              })
                            ),
                          },
                        }
                      }
                    }
                  }

                  const trainingDataList = groupedData['training-data-list']
                  let thumbnail = ''

                  if (trainingDataList && trainingDataList?.length > 0) {
                    thumbnail = await getTrainingImageThumbnail(
                      userGroupId,
                      trainingDataList[0]
                    )
                  }

                  return {
                    groupedDataId: groupedDataId,
                    annotationFile: {
                      fileName: annotationFileName ?? '',
                    },
                    annotationSetId: annotationSet['annotation-set-id'],
                    annotationSetKind: annotationSet['annotation-set-kind'],
                    conditions: annotationSet['conditions']
                      ? {
                          trainKind: annotationSet['conditions']['train-kind'],
                        }
                      : undefined,
                    thumbnailUrl: thumbnail ?? '',
                    trainingImageList: groupedData['training-data-list'].map(
                      (trainingDataId: string) => {
                        return {
                          trainingImageId: trainingDataId,
                          thumbnailUrl: '',
                          originalUrl: '',
                        }
                      }
                    ),
                    extensions,
                  }
                }
              )
            )
          : []

        if (!isCurrentDataset(datasetId, getState)) return

        dispatch(datasetDetailActions.setAnnotationList(annotationSetList))
        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            annotationSetListSubState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetDetailActions.setInProgressForAnnotationSet(false))
      }
    },
  /** アノテーションのサムネイルを取得 */
  getAnnotationFiles:
    (datasetId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const annotationSetList = [
          ...getState().pages.datasetDetailState.domainData.annotationSetList,
        ]

        // アノテーション単位で サムネイル / オリジナル画像を取得する
        getImagePerAnnotationSet: for (const [
          annotationSetIndex,
          annotationSet,
        ] of annotationSetList.entries()) {
          // アノテーションセットに紐づく 学習画像 を10件ずづに分割し、サムネイル / オリジナル画像を取得する
          // 画像取得完了後に State に反映し、次の10件を取得する
          const trainingImageChunkList = getArrayChunk(
            annotationSet.trainingImageList ?? [],
            FIRESTORE_WHERE_IN_QUOTA
          )

          for (const [
            trainingImageChunkIndex,
            trainingImageChunk,
          ] of trainingImageChunkList.entries()) {
            const trainingImageInfo = await getTrainingImageInfoList(
              userGroupId,
              trainingImageChunk.map(
                (trainingImage) => trainingImage.trainingImageId
              )
            )

            const updatedTrainingImageList =
              annotationSetList[annotationSetIndex].trainingImageList != null
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  annotationSetList[annotationSetIndex].trainingImageList!
                : []
            updatedTrainingImageList.splice(
              trainingImageChunkIndex * FIRESTORE_WHERE_IN_QUOTA,
              trainingImageChunk.length,
              ...trainingImageInfo
            )

            annotationSetList[annotationSetIndex].trainingImageList =
              updatedTrainingImageList

            if (!isCurrentDataset(datasetId, getState)) {
              break getImagePerAnnotationSet
            }

            dispatch(datasetDetailActions.setAnnotationList(annotationSetList))
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
  /** データセットの名前を更新 */
  updateDatasetName:
    (docId: string, name: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Model IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getDatasetsMetaDataCollection(userGroupId), docId),
          {
            name: name,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )

        dispatch(
          datasetDetailActions.setCurrentDatasetDetail({
            ...getState().pages.datasetDetailState.domainData
              .currentDatasetDetail,
            datasetName: name,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetDetailActions.setInProgress(false))
      }
    },
  /** データセットの備考を更新 */
  updateDatasetRemarks:
    (docId: string, remarks: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Model IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getDatasetsMetaDataCollection(userGroupId), docId),
          {
            remarks: remarks,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetDetailActions.setInProgress(false))
      }
    },
  downloadDatasetZip:
    (data: { datasetId?: string }) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetDetailActions.setInProgress(true))
        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            datasetZipSubState: 'BeforeLoading',
          })
        )

        await downloadZip(getState, data)

        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            datasetZipSubState: 'Loaded',
          })
        )
      } catch {
        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            datasetZipSubState: 'Failed',
          })
        )

        dispatch(
          datasetDetailActions.setToastInfo({
            type: 'error',
            title: 'ダウンロードに失敗しました',
            targets: [],
          })
        )
      } finally {
        dispatch(datasetDetailActions.setInProgress(false))
      }
    },
  downloadAnnotationZip:
    (data: { annotationSetId?: string }) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetDetailActions.setInProgress(true))
        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            annotationSetZipDlSubState: 'BeforeLoading',
          })
        )

        await downloadZip(getState, data)

        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            annotationSetZipDlSubState: 'Loaded',
          })
        )
      } catch {
        dispatch(
          datasetDetailActions.setDatasetDetailState({
            ...getState().pages.datasetDetailState.appState.datasetDetailState,
            annotationSetZipDlSubState: 'Failed',
          })
        )
        dispatch(
          datasetDetailActions.setToastInfo({
            type: 'error',
            title: 'ダウンロードに失敗しました',
            targets: [],
          })
        )
      } finally {
        dispatch(datasetDetailActions.setInProgress(false))
      }
    },
}

const getTrainingImageThumbnail = async (
  userGroupId: string,
  trainingImageId: string
): Promise<string> => {
  const trainingImageData = (
    await getDoc(doc(getTrainingImagesCollection(userGroupId), trainingImageId))
  ).data()

  if (
    !trainingImageData ||
    !fireStoreTypeGuardForTrainingImagesDocument(trainingImageData)
  ) {
    return ''
  }

  let thumbnailUrl = undefined

  thumbnailUrl = await DatasetDetailApi.getSignedUrls(
    [{ id: trainingImageId, fileName: 'thumbnail.jpg' }],
    'read',
    'thumbnail'
  )

  return thumbnailUrl?.[trainingImageId] ?? ''
}

const getTrainingImageInfoList = async (
  userGroupId: string,
  trainingImageIdList: string[]
): Promise<TrainingImageInfo[]> => {
  const originalFileNames: { id: string; fileName: string }[] = []
  const thumbnailFileNames: { id: string; fileName: string }[] = []
  const imageFileInfoDict: {
    [id: string]: {
      id: string
      createdAt: Timestamp
    }
  } = {}

  const trainingImageList = await getDocs(
    query(
      getTrainingImagesCollection(userGroupId),
      where('training-data-id', 'in', trainingImageIdList)
    )
  )
  const trainingDataList = await getDocs(
    query(
      getTrainingDataCollection(userGroupId),
      where('training-data-id', 'in', trainingImageIdList)
    )
  )
  trainingImageList.docs.forEach((doc) => {
    try {
      const trainingImageData = doc.data()
      if (!fireStoreTypeGuardForTrainingImagesDocument(trainingImageData)) {
        return
      }
      const trainingDataId = trainingImageData['training-data-id']
      imageFileInfoDict[trainingDataId] = {
        id: trainingDataId,
        createdAt: trainingImageData['created-at'],
      }

      thumbnailFileNames.push({
        id: trainingDataId,
        fileName: 'thumbnail.jpg',
      })
    } catch (error) {
      console.error(error)
    }
  })

  trainingDataList.docs.forEach((doc) => {
    try {
      const trainingData = doc.data()
      if (!fireStoreTypeGuardForTrainingDataDocument(trainingData)) {
        return
      }
      const docFileName = trainingData['file-name']
      originalFileNames.push({
        id: trainingData['training-data-id'],
        fileName: typeof docFileName === 'string' ? docFileName : '',
      })
    } catch (error) {
      console.error(error)
    }
  })

  const imageFileInfoList = trainingImageIdList
    .map((id) => imageFileInfoDict[id])
    .filter((fileInfo) => fileInfo !== undefined)

  // signedUrl取得
  let thumbnailUrls: { [id: string]: string } = {}

  if (thumbnailFileNames.length > 0) {
    thumbnailUrls = await DatasetDetailApi.getSignedUrls(
      thumbnailFileNames,
      'read',
      'thumbnail'
    )
  }
  const originalUrls = await DatasetDetailApi.getSignedUrls(
    originalFileNames,
    'read',
    'original'
  )

  return imageFileInfoList.map((fileInfo) => {
    const thumbnailUrl = thumbnailUrls[fileInfo.id]
    const originalUrl = originalUrls[fileInfo.id]

    return {
      trainingImageId: fileInfo.id,
      thumbnailUrl: thumbnailUrl ? thumbnailUrl : '',
      originalUrl: originalUrl ? originalUrl : '',
      createdAt: fileInfo.createdAt,
    }
  })
}

const getExtendedField = async (
  datasetQuery: DocumentData,
  userGroupId: string
): Promise<Extended | undefined> => {
  // 物体クラス分類の場合
  if (datasetQuery['extended']['object-classification']) {
    const classSetId =
      (datasetQuery['extended']['object-classification']['class-set'][
        'class-set-id'
      ] as string) ?? ''
    const classSetUserGroupId =
      (datasetQuery['extended']['object-classification']['class-set'][
        'user-group-id'
      ] as string) ?? ''
    const classSetMetaData = (
      await getDocs(
        userGroupId === classSetUserGroupId
          ? query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId)
            )
          : query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId),
              where('access-control.is-shared', '==', true),
              where('access-control.share-permissions.webapp', '==', 'list')
            )
      )
    ).docs[0].data()
    if (!fireStoreTypeGuardForClassSetMetaDataDocument(classSetMetaData)) {
      return
    }

    const classSetName = classSetMetaData['name']

    return {
      objectClassification: {
        classSet: {
          classSetId,
          classSetName,
          userGroupId: classSetUserGroupId,
        },
      },
    }
  }

  return undefined
}
