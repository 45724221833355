import { BuildEntryAction } from './actions'
import { BuildEntryActionType, BuildEntryState } from './types'

const initialState: BuildEntryState = {
  domainData: {
    selectedTrainingAlgorithm: undefined,
    trainedModelGroups: {
      sharedUserGroup: [],
      userGroup: [],
    },
    selectedTrainedModelGroup: undefined,
    selectedTrainedModel: undefined,
    selectedBaseInferenceContainerImageIds: [],
    inferenceAlgorithmVersions: [],
    selectedInferenceAlgorithmVersion: undefined,
    isTransfer: true,
    isSystemEvaluation: true,
    buildInfoMetadata: {
      name: '',
      remarks: undefined,
    },
    edgeImageInfoMetadata: {
      name: '',
      remarks: undefined,
    },
    executionInfo: {
      mlPipelineId: undefined,
      buildStepId: undefined,
    },
  },
  appState: {
    inProgress: false,
    toastInfo: undefined,
    buildEntryStateKind: 'TrainedModelGroup',
    buildEntrySubStateKind: {
      trainedModelGroupSubState: 'Unselected',
      trainedModelSubState: 'Unselected',
      edgeImageSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    trainedModelGroupListDisplayCondition: {
      sortKey: 'created-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'UserGroup',
    },
    trainedModelDisplayCondition: {
      sortKey: 'trained-model-version',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
}

export const BuildUploadReducer = (
  state: BuildEntryState = initialState,
  action: BuildEntryAction
): BuildEntryState => {
  switch (action.type) {
    case BuildEntryActionType.SET_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
      if (action.payload.trainedModelGroupListDisplayCondition) {
        return {
          ...state,
          appState: {
            ...state.appState,
            trainedModelGroupListDisplayCondition:
              action.payload.trainedModelGroupListDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case BuildEntryActionType.SET_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
      if (action.payload.trainedModelListDisplayCondition) {
        return {
          ...state,
          appState: {
            ...state.appState,
            trainedModelDisplayCondition:
              action.payload.trainedModelListDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case BuildEntryActionType.SET_TRAINED_MODEL_GROUP_LIST_FOR_BUILD_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelGroups: action.payload.trainedModelGroupList,
        },
      }
    case BuildEntryActionType.SET_INFERENCE_ALGORITHM_VERSIONS_FOR_BUILD_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceAlgorithmVersions: action.payload.inferenceAlgorithmVersions,
        },
      }
    case BuildEntryActionType.SET_SELECTED_TRAINED_MODEL_GROUP_FOR_BUILD_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainedModelGroup: action.payload.selectedTrainedModelGroup,
        },
      }
    case BuildEntryActionType.SET_SELECTED_TRAINED_MODEL_FOR_BUILD_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainedModel: action.payload.selectedTrainedModel,
        },
      }
    case BuildEntryActionType.SET_SELECTED_INFERENCE_ALGORITHM_VERSION_FOR_BUILD_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedInferenceAlgorithmVersion:
            action.payload.selectedInferenceAlgorithmVersion,
        },
      }
    case BuildEntryActionType.SET_IS_TRANSFER:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          isTransfer: action.payload.isTransfer,
        },
      }
    case BuildEntryActionType.SET_IS_SYSTEM_EVALUATION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          isSystemEvaluation: action.payload.isSystemEvaluation,
        },
      }
    case BuildEntryActionType.SET_TRAINED_MODEL_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildEntrySubStateKind: {
            ...state.appState.buildEntrySubStateKind,
            trainedModelGroupSubState: action.payload.trainedModelGroupSubState,
          },
        },
      }
    case BuildEntryActionType.SET_TRAINED_MODEL_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildEntrySubStateKind: {
            ...state.appState.buildEntrySubStateKind,
            trainedModelSubState: action.payload.trainedModelSubState,
          },
        },
      }
    case BuildEntryActionType.SET_EDGE_IMAGE_SUB_STATE_FOR_BUILD_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildEntrySubStateKind: {
            ...state.appState.buildEntrySubStateKind,
            edgeImageSubState: action.payload.edgeImageSubState,
          },
        },
      }
    case BuildEntryActionType.SET_META_DATA_SUB_STATE_FOR_BUILD_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildEntrySubStateKind: {
            ...state.appState.buildEntrySubStateKind,
            metaDataSubState: action.payload.metaDataSubState,
          },
        },
      }
    case BuildEntryActionType.CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          trainedModelGroupListDisplayCondition: {
            ...initialState.appState.trainedModelGroupListDisplayCondition,
          },
        },
      }
    case BuildEntryActionType.CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          trainedModelDisplayCondition: {
            ...initialState.appState.trainedModelDisplayCondition,
          },
        },
      }
    case BuildEntryActionType.SET_BUILD_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          buildInfoMetadata: action.payload.buildMetaData,
        },
      }
    case BuildEntryActionType.SET_EDGE_IMAGE_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          edgeImageInfoMetadata: action.payload.edgeImageMetaData,
        },
      }
    case BuildEntryActionType.SET_SELECTED_ALGORITHM_ID_FOR_BUILD_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithm: {
            algorithmId: action.payload.selectedAlgorithmId,
          },
        },
      }
    case BuildEntryActionType.SET_BUILD_ENTRY_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildEntryStateKind: action.payload.buildEntryStateKind,
        },
      }
    case BuildEntryActionType.CLEAR_BUILD_ENTRY_STATE:
      return {
        domainData: {
          ...initialState.domainData,
          selectedBaseInferenceContainerImageIds: [],
        },
        appState: {
          ...initialState.appState,
        },
      }
    case BuildEntryActionType.SET_IN_PROGRESS_FOR_BUILD_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case BuildEntryActionType.SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedBaseInferenceContainerImageIds:
            action.payload.selectedBaseInferenceContainerImageId,
        },
      }
    case BuildEntryActionType.EXEC_BUILD_ENTRY_FAILURE:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildEntrySubStateKind: {
            ...state.appState.buildEntrySubStateKind,
            executeSubState: 'ExecuteError',
          },
        },
      }
    case BuildEntryActionType.EXEC_BUILD_ENTRY_SUCCESS:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildEntrySubStateKind: {
            ...state.appState.buildEntrySubStateKind,
            executeSubState: 'Executed',
          },
        },
      }
    case BuildEntryActionType.SET_EXECUTION_INFO_FOR_BUILD_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executionInfo: action.payload.executionInfo,
        },
      }
    default:
      return state
  }
}
