import { TrainingAlgorithmVersionDocument } from './types'
import { fireStoreTypeGuard } from './typeguard'

// TrainingAlgorithmVersionDocument に変換
export const convertDocument = (
  data: unknown
): TrainingAlgorithmVersionDocument => {
  const trainingAlgorithmVersionData = data as TrainingAlgorithmVersionDocument
  return {
    'algorithm-id': trainingAlgorithmVersionData['algorithm-id'],
    'algorithm-structure-relation-id':
      trainingAlgorithmVersionData['algorithm-structure-relation-id'],
    'algorithm-version': {
      'display-name':
        trainingAlgorithmVersionData['algorithm-version']['display-name'],
      major: trainingAlgorithmVersionData['algorithm-version']['major'],
      minor: trainingAlgorithmVersionData['algorithm-version']['minor'],
      patch: trainingAlgorithmVersionData['algorithm-version']['patch'],
      'pre-release':
        trainingAlgorithmVersionData['algorithm-version']['pre-release'],
    },
    'annotation-format-id':
      trainingAlgorithmVersionData['annotation-format-id'],
    'annotation-format-kind':
      trainingAlgorithmVersionData['annotation-format-kind'],
    'annotation-format-version': {
      'display-name':
        trainingAlgorithmVersionData['annotation-format-version'][
          'display-name'
        ],
      major: trainingAlgorithmVersionData['annotation-format-version']['major'],
      minor: trainingAlgorithmVersionData['annotation-format-version']['minor'],
      patch: trainingAlgorithmVersionData['annotation-format-version']['patch'],
    },
    'available-version': {
      'trained-model': {
        'lower-limit': {
          'display-name':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['display-name'],
          major:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['major'],
          minor:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['minor'],
          patch:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['patch'],
          'pre-release':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['pre-release'],
        },
        'upper-limit': {
          'display-name':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['display-name'],
          major:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['major'],
          minor:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['minor'],
          patch:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['patch'],
          'pre-release':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['pre-release'],
        },
      },
    },
    'commit-hash': trainingAlgorithmVersionData['commit-hash'],
    'inference-code-version': {
      'display-name':
        trainingAlgorithmVersionData['inference-code-version']['display-name'],
      major: trainingAlgorithmVersionData['inference-code-version']['major'],
      minor: trainingAlgorithmVersionData['inference-code-version']['minor'],
      patch: trainingAlgorithmVersionData['inference-code-version']['patch'],
    },
    metadata: {
      remarks: {
        en: trainingAlgorithmVersionData['metadata']['remarks']['en'],
        ja: trainingAlgorithmVersionData['metadata']['remarks']['ja'],
      },
    },
    'ml-framework': {
      ...trainingAlgorithmVersionData['ml-framework'],
    },
    'released-at': trainingAlgorithmVersionData['released-at'],
    'training-algorithm-version':
      trainingAlgorithmVersionData['training-algorithm-version'],
  }
}

// TrainingAlgorithmVersion Documentに変換
export const convertDocumentWithDataCheck = (
  data: unknown
): TrainingAlgorithmVersionDocument | undefined => {
  const trainingAlgorithmVersionData = data as TrainingAlgorithmVersionDocument
  const isTrainingAlgorithmVersionDocument = fireStoreTypeGuard(
    trainingAlgorithmVersionData
  )
  if (!isTrainingAlgorithmVersionDocument) {
    return undefined
  }

  return {
    'algorithm-id': trainingAlgorithmVersionData['algorithm-id'],
    'algorithm-structure-relation-id':
      trainingAlgorithmVersionData['algorithm-structure-relation-id'],
    'algorithm-version': {
      'display-name':
        trainingAlgorithmVersionData['algorithm-version']['display-name'],
      major: trainingAlgorithmVersionData['algorithm-version']['major'],
      minor: trainingAlgorithmVersionData['algorithm-version']['minor'],
      patch: trainingAlgorithmVersionData['algorithm-version']['patch'],
      'pre-release':
        trainingAlgorithmVersionData['algorithm-version']['pre-release'],
    },
    'annotation-format-id':
      trainingAlgorithmVersionData['annotation-format-id'],
    'annotation-format-kind':
      trainingAlgorithmVersionData['annotation-format-kind'],
    'annotation-format-version': {
      'display-name':
        trainingAlgorithmVersionData['annotation-format-version'][
          'display-name'
        ],
      major: trainingAlgorithmVersionData['annotation-format-version']['major'],
      minor: trainingAlgorithmVersionData['annotation-format-version']['minor'],
      patch: trainingAlgorithmVersionData['annotation-format-version']['patch'],
    },
    'available-version': {
      'trained-model': {
        'lower-limit': {
          'display-name':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['display-name'],
          major:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['major'],
          minor:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['minor'],
          patch:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['patch'],
          'pre-release':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'lower-limit'
            ]['pre-release'],
        },
        'upper-limit': {
          'display-name':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['display-name'],
          major:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['major'],
          minor:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['minor'],
          patch:
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['patch'],
          'pre-release':
            trainingAlgorithmVersionData['available-version']['trained-model'][
              'upper-limit'
            ]['pre-release'],
        },
      },
    },
    'commit-hash': trainingAlgorithmVersionData['commit-hash'],
    'inference-code-version': {
      'display-name':
        trainingAlgorithmVersionData['inference-code-version']['display-name'],
      major: trainingAlgorithmVersionData['inference-code-version']['major'],
      minor: trainingAlgorithmVersionData['inference-code-version']['minor'],
      patch: trainingAlgorithmVersionData['inference-code-version']['patch'],
    },
    metadata: {
      remarks: {
        en: trainingAlgorithmVersionData['metadata']['remarks']['en'],
        ja: trainingAlgorithmVersionData['metadata']['remarks']['ja'],
      },
    },
    'ml-framework': {
      ...trainingAlgorithmVersionData['ml-framework'],
    },
    'released-at': trainingAlgorithmVersionData['released-at'],
    'training-algorithm-version':
      trainingAlgorithmVersionData['training-algorithm-version'],
  }
}
