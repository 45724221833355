import { CustomTrainingDetailAction } from './actions'
import { CustomTrainingDetailActionType, CustomTrainingDetail } from './types'

const initialState: CustomTrainingDetail = {
  domainData: {
    currentCustomTrainingDetail: undefined,
    metricsFiles: [],
    trainedModelDlLinks: [],
    mlPipelineLogFiles: [],
  },
  appState: {
    customTrainingDetailState: {
      mlPipelineDataState: 'BeforeLoading',
      metricsSubState: 'BeforeLoading',
      trainedModelDlLinkSubState: 'BeforeLoading',
      mlPipelineLogSubState: 'BeforeLoading',
    },
    toastInfo: undefined,
    inProgress: false,
  },
}

export const CustomTrainingDetailReducer = (
  state: CustomTrainingDetail = initialState,
  action: CustomTrainingDetailAction
): CustomTrainingDetail => {
  switch (action.type) {
    case CustomTrainingDetailActionType.SET_CURRENT_CUSTOM_TRAINING_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentCustomTrainingDetail:
            action.payload.currentCustomTrainingDetail,
        },
      }
    case CustomTrainingDetailActionType.SET_CUSTOM_TRAINING_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingDetailState: action.payload.customTrainingDetailState,
        },
      }
    case CustomTrainingDetailActionType.SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case CustomTrainingDetailActionType.CLEAR_CUSTOM_TRAINING_DETAIL_STATE:
      return {
        ...initialState,
      }
    case CustomTrainingDetailActionType.SET_TOAST_INFO:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case CustomTrainingDetailActionType.SET_TRAINED_MODEL_DL_LINKS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelDlLinks: action.payload.trainedModelDlLinks,
        },
      }
    case CustomTrainingDetailActionType.SET_METRICS_FILES:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          metricsFiles: action.payload.metricsFiles,
        },
      }
    case CustomTrainingDetailActionType.SET_ML_PIPELINE_LOG_FILES_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelineLogFiles: action.payload.mlPipelineLogFiles,
        },
      }
    default:
      return state
  }
}
