import { Timestamp } from 'state/firebase'
import { ResourceLoadingState } from 'state/utils'

export const AccountGroupActionType = {
  SET_ACCOUNT_GROUP_LIST: 'SET_ACCOUNT_GROUP_LIST',
  SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
  SET_SUPER_USER_LIST_FOR_ACCOUNT_GROUPS:
    'SET_SUPER_USER_LIST_FOR_ACCOUNT_GROUPS',
  SET_SELECTED_ACCOUNT_GROUP_ID: 'SET_SELECTED_ACCOUNT_GROUP_ID',
  CLEAR_DISPLAY_CONDITION: 'CLEAR_DISPLAY_CONDITION',
  SET_CUSTOMER_LIST_DISPLAY_CONDITION: 'SET_CUSTOMER_LIST_DISPLAY_CONDITION',
  SET_SUPER_USER_LIST_DISPLAY_CONDITION_FOR_ACCOUNT_GROUPS:
    'SET_SUPER_USER_LIST_DISPLAY_CONDITION_FOR_ACCOUNT_GROUPS',
  SET_CUSTOMER_LIST_PAGING_STATE: 'SET_CUSTOMER_LIST_PAGING_STATE',
  SET_SELECTED_ACCOUNT_GROUP_MFA_GROUP_SETTING_FOR_ACCOUNT_GROUP:
    'SET_SELECTED_ACCOUNT_GROUP_MFA_GROUP_SETTING_FOR_ACCOUNT_GROUP',
  UPDATE_ACCOUNT_GROUP_CUSTOMER_RELATIONS:
    'UPDATE_ACCOUNT_GROUP_CUSTOMER_RELATIONS',
  SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE:
    'SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE',
  SET_SUPER_USER_LIST_SUB_STATE_FOR_ACCOUNT_GROUP:
    'SET_SUPER_USER_LIST_SUB_STATE_FOR_ACCOUNT_GROUP',
  SET_MFA_GROUP_SETTING_SUB_STATE_FOR_ACCOUNT_GROUP:
    'SET_MFA_GROUP_SETTING_SUB_STATE_FOR_ACCOUNT_GROUP',
  SET_IN_PROGRESS_FOR_ACCOUNT_GROUP: 'SET_IN_PROGRESS_FOR_ACCOUNT_GROUP',
  SET_IN_PROGRESS_FOR_GETTING_SUPER_USER_LIST:
    'SET_IN_PROGRESS_FOR_GETTING_SUPER_USER_LIST',
  SET_IN_PROGRESS_FOR_GETTING_MFA_GROUP_SETTING:
    'SET_IN_PROGRESS_FOR_GETTING_MFA_GROUP_SETTING',
  CLEAR_ACCOUNT_GROUP_STATE: 'CLEAR_ACCOUNT_GROUP_STATE',
} as const

export type AccountGroupUpdateCustomerListSubState =
  | 'BeforeUpdateCustomerList'
  | 'UpdateCustomerListSuccess'
  | 'UpdateCustomerListError'

export interface AccountGroupCustomer {
  customerId: string
}
export interface Customer extends AccountGroupCustomer {
  customerName: string
  userGroupId: string
  createdAt: Timestamp
}

export interface SuperUser {
  accountId: string
  email: string
  firstName: string
  familyName: string
  lastLoginTime?: Timestamp
  createdAt?: Timestamp
}

export interface AccountGroup {
  accountGroupId: string
  name: string
  customerList: AccountGroupCustomer[]
}

export interface CustomerListDisplayCondition {
  displayNumber: number
  pageNumber: number
}

export type SuperUserListSortKey = 'email' | 'createdAt' | 'lastLoginTime'

export interface SuperUserListDisplayCondition {
  sortKey: SuperUserListSortKey
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface CustomerListPagingState {
  nextPageSubState: 'Enable' | 'Unable'
}

export interface AccountGroupDomainData {
  accountGroupList: AccountGroup[]
  customerList: Customer[]
  superUserList: SuperUser[]
  selectedAccountGroupId: string
  selectedAccountGroupMfaGroupSetting: string
  customerListDisplayCondition: CustomerListDisplayCondition
  superUserListDisplayCondition: SuperUserListDisplayCondition
}

export interface AccountGroupAppState {
  customerListPagingState: CustomerListPagingState
  superUserListSubState: ResourceLoadingState
  mfaGroupSettingSubState: ResourceLoadingState
  accountGroupUpdateCustomerListSubState: AccountGroupUpdateCustomerListSubState
  inProgress: boolean
  inProgressForGettingSuperUserList: boolean
  inProgressForGettingMfaSetting: boolean
}

export interface AccountGroups {
  domainData: AccountGroupDomainData
  appState: AccountGroupAppState
}
