import { CustomTrainingListAction } from './actions'
import { CustomTrainingListActionType, CustomTraininglist } from './types'

const initialState: CustomTraininglist = {
  domainData: {
    currentCustomTrainingList: [],
    customTrainingDisplayCondition: {
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    mlPipelineIds: [],
    currentCustomTrainingListSnapshot: undefined,
  },
  appState: {
    inProgress: false,
  },
}

export const CustomTrainingListReducer = (
  state: CustomTraininglist = initialState,
  action: CustomTrainingListAction
): CustomTraininglist => {
  switch (action.type) {
    case CustomTrainingListActionType.SET_CUSTOM_TRAINING_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentCustomTrainingList: action.payload.currentCustomTrainingList,
        },
      }
    case CustomTrainingListActionType.SET_CUSTOM_TRAINING_DISPLAY_CONDITION:
      if (action.payload.customTrainingDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            customTrainingDisplayCondition:
              action.payload.customTrainingDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingListActionType.CLEAR_CUSTOM_TRAINING_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          customTrainingDisplayCondition: {
            ...initialState.domainData.customTrainingDisplayCondition,
          },
        },
      }
    case CustomTrainingListActionType.SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case CustomTrainingListActionType.SET_ML_PIPELINE_IDS_FOR_CUSTOM_TRAINING_LIST:
      if (action.payload.mlPipelineIds) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            mlPipelineIds: action.payload.mlPipelineIds,
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingListActionType.SET_CURRENT_CUSTOM_TRAINING_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentCustomTrainingListSnapshot:
            action.payload.currentCustomTrainingListSnapshot,
        },
      }
    case CustomTrainingListActionType.CLEAR_CUSTOM_TRAINING_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
