import React, { useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { makeStyles } from 'tss-react/mui'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'
import BrokenImage from '@mui/icons-material/BrokenImage'
import { Breakpoint, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ThumbnailInfo, ThumbnailSliderProps } from './types'
import { BackButton, NextButton } from 'views/components'

const useStyles = (
  rows: number,
  aspectRatio: { width: number; height: number } | undefined
) =>
  makeStyles()((theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      '& > *': {
        minWidth: 0,
        minHeight: 0,
      },
    },
    pageNav: {
      flexShrink: 0,
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    container: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    backButtonDiv: {
      paddingRight: theme.spacing(2),
      '& > *': {
        width: '5%',
        height: '100%',
        '& > *': {
          width: '100%',
          height: '100%',
        },
      },
    },
    gridList: {
      width: '100%',
      '& > div': {
        padding: theme.spacing(1),
      },
    },
    imgGrid: {
      aspectRatio: aspectRatio
        ? `${aspectRatio.width} / ${aspectRatio.height}`
        : '16 / 9',
      '& > *': {
        objectFit: 'cover',
        display: 'block',
        width: '100%',
        height: '100%',
      },
    },
    nextButtonDiv: {
      paddingLeft: theme.spacing(2),
      '& > *': {
        width: '5%',
        height: '100%',
        '& > *': {
          width: '100%',
          height: '100%',
        },
      },
    },
  }))

const useMediaQueryKeyWord = (): Breakpoint => {
  const theme = useTheme()
  const isXsOrUnder = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmOrUnder = useMediaQuery(theme.breakpoints.down('sm'))
  const isMdOrUnder = useMediaQuery(theme.breakpoints.down('md'))
  const isLgOrUnder = useMediaQuery(theme.breakpoints.down('lg'))
  if (isXsOrUnder) {
    return 'xs'
  } else if (isSmOrUnder) {
    return 'sm'
  } else if (isMdOrUnder) {
    return 'md'
  } else if (isLgOrUnder) {
    return 'lg'
  } else {
    return 'xl'
  }
}

const useCols = (colsOptions: { [key in Breakpoint]: number }): number =>
  colsOptions[useMediaQueryKeyWord()]

export const ThumbnailSlider: React.FC<ThumbnailSliderProps> = (
  props: ThumbnailSliderProps
) => {
  const colsOptions: { [key in Breakpoint]: number } = {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 6,
    xl: 6,
    ...props.colsOptions,
  }

  const cols = useCols(colsOptions)
  const rows = props.rows

  const { classes } = useStyles(rows, props.aspectRatio)()
  const [pageNum, setPageNum] = useState(0)
  useEffect(() => {
    setPageNum(0)
  }, [props.aspectRatio, props.colsOptions, props.rows])

  const pageLength = Math.ceil(props.thumbnails.length / (rows * cols))
  const targetStart = rows * cols * pageNum
  const targetEnd = rows * cols * (pageNum + 1)

  const targetList = props.thumbnails.slice(targetStart, targetEnd)

  useEffect(() => {
    if (pageNum > pageLength - 1) {
      setPageNum(pageLength - 1)
    }
  }, [cols, rows])

  const getTargetGridList = (
    targetList: ThumbnailInfo[],
    selectedId?: string
  ) => {
    const imgGridList: JSX.Element[] = []
    for (let index = 0; index < cols * rows; index++) {
      const targetImage =
        index < targetList.length ? targetList[index] : undefined
      imgGridList.push(
        <Grid
          item
          {...Object.fromEntries(
            Object.entries(colsOptions).map(([key, value]) => [key, 12 / value])
          )}
          key={targetImage ? `${targetImage.id}` : `dummy-grid-${uuidv4()}`}
          className={classes.imgGrid}
          style={
            selectedId && targetImage && selectedId === targetImage.id
              ? { backgroundColor: grey[300] }
              : {}
          }
        >
          {targetImage &&
            (targetImage.url === '' ? (
              <BrokenImage onClick={() => props.onClickImage(targetImage.id)} />
            ) : (
              <img
                src={targetImage.url}
                onClick={() => props.onClickImage(targetImage.id)}
              />
            ))}
        </Grid>
      )
    }
    return imgGridList
  }

  return (
    <div className={classes.root}>
      <Typography align='right' variant='subtitle1' className={classes.pageNav}>
        <span>{targetStart + 1}</span>
        <span>~</span>
        <span>
          {props.thumbnails.length < targetEnd
            ? props.thumbnails.length
            : targetEnd}
        </span>
        <span>/</span>
        <span>{props.thumbnails.length}</span>
      </Typography>
      <div className={classes.container}>
        <div className={classes.backButtonDiv}>
          <BackButton
            onClick={() => setPageNum(pageNum - 1 < 0 ? 0 : pageNum - 1)}
            disabled={pageNum === 0}
          />
        </div>
        <Grid
          container
          justifyContent='flex-start'
          alignItems='center'
          className={classes.gridList}
        >
          {getTargetGridList(targetList, props.selectedId)}
        </Grid>
        <div className={classes.nextButtonDiv}>
          <NextButton
            onClick={() =>
              setPageNum(
                pageNum + 1 > pageLength - 1 ? pageLength - 1 : pageNum + 1
              )
            }
            disabled={pageNum + 1 === pageLength}
          />
        </div>
      </div>
    </div>
  )
}
