import { Timestamp } from 'state/firebase'
import { Version } from 'types/StateTypes'

export const SceneCameraEntryActionType = {
  SET_IS_UPDATE_REVISION: 'SET_IS_UPDATE_REVISION',
  SET_SELECTED_SCENE_CAMERA: 'SET_SELECTED_SCENE_CAMERA',
  SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_ENTRY:
    'SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_ENTRY',
  SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT_FOR_SCENE_CAMERA_ENTRY:
    'SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT_FOR_SCENE_CAMERA_ENTRY',
  SET_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY:
    'SET_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY',
  SET_CAMERA_SETTING_FILE: 'SET_CAMERA_SETTING_FILE',
  SET_CAMERA_SETTING: 'SET_CAMERA_SETTING',
  SET_METADATA_FOR_SCENE_CAMERA_ENTRY: 'SET_METADATA_FOR_SCENE_CAMERA_ENTRY',
  SET_SCENE_CAMERA_ENTRY_STATE: 'SET_SCENE_CAMERA_ENTRY_STATE',
  SET_IN_PROGRESS_FOR_SCENE_CAMERA_ENTRY:
    'SET_IN_PROGRESS_FOR_SCENE_CAMERA_ENTRY',
  SET_CREATED_CAMERA_ID: 'SET_CREATED_CAMERA_ID',
  SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION_FOR_SCENE_CAMERA_ENTRY:
    'SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION_FOR_SCENE_CAMERA_ENTRY',
  SET_SCENE_CAMERA_ENTRY_SUB_STATE: 'SET_SCENE_CAMERA_ENTRY_SUB_STATE',
  CLEAR_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY:
    'CLEAR_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY',
  CLEAR_SCENE_CAMERA_ENTRY_STATE: 'CLEAR_SCENE_CAMERA_ENTRY_STATE',
  SET_TOAST_INFO_FOR_SCENE_CAMERA_ENTRY:
    'SET_TOAST_INFO_FOR_SCENE_CAMERA_ENTRY',
} as const

export type UploadType = 'Uploading' | 'Completed' | 'UploadError'

export const SceneCameraEntryStateKindArray = [
  'DestinationState',
  'UploadState',
  'MetadataState',
  'ExecuteState',
]

export type SceneCameraEntryStateKind =
  (typeof SceneCameraEntryStateKindArray)[number]

export interface SceneCamera {
  id: string
  revision: number
  name: string
  overview: string
  createdAt: Timestamp
  createdBy: string
}

export interface SceneCameraListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface CameraSetting {
  location: number[]
  fov: number[]
  lookAt: number[]
  formatVersion?: Version
}

export interface Metadata {
  name: string
  overview: string
}

export interface SceneCameraFile {
  schema_version: string
  camera: {
    location: number[]
    fov: number[]
    'look-at': number[]
  }
}

export interface SceneCameraEntryDomainData {
  isUpdateRevision: boolean
  currentSceneCameraList: SceneCamera[]
  sceneCameraIds: string[]
  currentSceneCameraListSnapshot?: () => void
  selectedSceneCamera?: SceneCamera
  cameraSettingFile?: File
  cameraSetting: CameraSetting
  metadata: Metadata
  createdCameraId?: string
  sceneCameraListDisplayCondition: SceneCameraListDisplayCondition
}

export interface SceneCameraEntrySubState {
  createSubState:
    | 'BeforeCreate'
    | 'CreateInProgress'
    | 'Created'
    | 'CreateError'
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface SceneCameraEntryAppState {
  sceneCameraEntryState: SceneCameraEntryStateKind
  sceneCameraEntrySubState: SceneCameraEntrySubState
  inProgress: boolean
  toastInfo: ToastInfo | undefined
}

export interface SceneCameraEntryState {
  domainData: SceneCameraEntryDomainData
  appState: SceneCameraEntryAppState
}
