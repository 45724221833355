import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import CircularProgress from '@mui/material/CircularProgress'
import { AnnotationListItem } from 'views/components/molecules/annotationListItem'
import { isUndefined } from 'utils/typeguard'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import { grey, red } from '@mui/material/colors'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { ListStateHandler } from 'views/components'

import {
  AnnotationSetListViewerProps,
  AnnotationSetType,
  GroupedImageDataType,
} from './types'

const useStyles = (
  maxHeight: number | undefined,
  hideScrollBar: boolean | undefined
) =>
  makeStyles()((theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      maxHeight: maxHeight ? maxHeight : 300,
      overflow: 'auto',
      outline: 'solid 1px',
      position: 'relative',
      zIndex: 0,
      '&::-webkit-scrollbar': {
        // for Chrome, Edge
        display: hideScrollBar ? 'none' : undefined,
      },
      // for Firefox
      scrollbarWidth: hideScrollBar ? 'none' : undefined,
    },
    list: {
      clear: 'both',
      padding: 0,
    },
    listSubHeader: {
      backgroundColor: theme.palette.background.default,
      height: '50px',
      display: 'flex',
      justifyContent: 'space-between',
      '& > *': {
        color: theme.palette.common.black,
        fontSize: '1rem',
      },
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    stickyListItem: {
      top: '50px',
      position: 'sticky',
      zIndex: 1,
    },
    selectedName: { marginRight: theme.spacing(2) },
    unselectedName: { color: grey[500], marginRight: theme.spacing(2) },
    usualStatus: { color: grey[500], marginRight: theme.spacing(2) },
    errorStatus: { color: red[500] },
    uploadingStatus: {
      width: '40%',
      marginLeft: 'auto',
      marginRight: theme.spacing(2),
    },
    kindSelector: {
      marginRight: theme.spacing(2),
    },
  }))

export const AnnotationSetListViewer: React.FC<AnnotationSetListViewerProps> = (
  props: AnnotationSetListViewerProps
) => {
  const { classes } = useStyles(props.maxHeight, props.hideScrollBar)()

  const [openArray, setOpenArray] = useState(
    props.annotationSetList.map(() => false)
  )
  const [filterFilesState, setFilterFilesState] = useState(false)

  const setOpenAll = () => {
    setOpenArray(openArray.map(() => true))
  }
  const setCloseAll = () => {
    setOpenArray(openArray.map(() => false))
  }

  return (
    <div className={classes.root}>
      <List aria-labelledby='nested-list-subheader' className={classes.list}>
        <ListSubheader
          id='nested-list-subheader'
          className={classes.listSubHeader}
        >
          <span>
            {props.listLabel}（{props.annotationSetList.length}）
            {props.isLoading ? <CircularProgress size={20} /> : <></>}
          </span>
          {props.filterProps && (
            <FormControlLabel
              control={
                <Switch
                  checked={filterFilesState}
                  onChange={() => setFilterFilesState(!filterFilesState)}
                  color='primary'
                />
              }
              label={props.filterProps.label}
            />
          )}
          <ListStateHandler
            onClickOpen={setOpenAll}
            onClickClose={setCloseAll}
          />
        </ListSubheader>
        {((annotationSetList) => {
          // filtering annotationSet
          const filterProps = props.filterProps
          const filteredAnnotationSetList =
            filterProps && filterFilesState
              ? annotationSetList.map(
                  (annotationSet) =>
                    annotationSet.groupedData.groupedImageList
                      .map((groupedImageData) => groupedImageData.fileStatus)
                      .some(filterProps.isTarget) && {
                      ...annotationSet,
                      groupedData: {
                        ...annotationSet.groupedData,
                        groupedImageList:
                          annotationSet.groupedData.groupedImageList.filter(
                            (groupedImageData) =>
                              filterProps.isTarget(groupedImageData.fileStatus)
                          ),
                      },
                    }
                )
              : annotationSetList

          // make List Component
          return filteredAnnotationSetList.map(
            (
              annotationSet: AnnotationSetType | false,
              annotationSetIndex: number
            ) =>
              annotationSet && (
                <React.Fragment
                  key={annotationSet.annotationData.metadata.name}
                >
                  <div className={classes.stickyListItem}>
                    <AnnotationListItem
                      statusIcon={props.getAnnotationStatusIcon(
                        annotationSet.annotationStatus,
                        annotationSet.groupedData.groupedImageList.map(
                          (groupedImageData) => groupedImageData.fileStatus
                        )
                      )}
                      statusText={props.getAnnotationStatusText(
                        annotationSet,
                        classes
                      )}
                      kindSelect={
                        <Select
                          variant='standard'
                          className={classes.kindSelector}
                          value={JSON.stringify({
                            index: annotationSetIndex,
                            annotationSetKind:
                              annotationSet.annotationData.annotationSetKind,
                            trainKind:
                              annotationSet.annotationData.conditions
                                ?.trainKind,
                          })}
                          onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                            e.stopPropagation()
                          }
                          onChange={(e: SelectChangeEvent<string>) => {
                            const value = JSON.parse(e.target.value as string)

                            return (
                              props.onChangeKind &&
                              props.onChangeKind(
                                value.index,
                                value.annotationSetKind,
                                value.trainKind
                              )
                            )
                          }}
                          disabled={isUndefined(props.onChangeKind)}
                        >
                          {props.annotationSetKindList.map(
                            (kind, kindIndex) => {
                              return (
                                <MenuItem
                                  data-testid='annotation-set-kind-list-item'
                                  value={JSON.stringify({
                                    index: annotationSetIndex,
                                    annotationSetKind: kind.annotationSetKind,
                                    trainKind: kind.conditions?.trainKind,
                                  })}
                                  key={kindIndex}
                                >
                                  {kind.name.en}
                                </MenuItem>
                              )
                            }
                          )}
                        </Select>
                      }
                      endIcon={
                        annotationSet.groupedData.groupedImageList.length >
                        0 ? (
                          openArray[annotationSetIndex] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : undefined
                      }
                      onClick={() =>
                        setOpenArray(
                          ((openArray: boolean[]) => {
                            const newOpenArray = [...openArray]
                            newOpenArray[annotationSetIndex] =
                              !newOpenArray[annotationSetIndex]
                            return newOpenArray
                          })(openArray)
                        )
                      }
                      data-testid={`${props['data-testid']}ListItem${
                        annotationSetIndex + 1
                      }`}
                    />
                  </div>
                  <Collapse
                    in={openArray[annotationSetIndex]}
                    timeout='auto'
                    unmountOnExit
                    data-testid={`${props['data-testid']}ImageList${
                      annotationSetIndex + 1
                    }`}
                  >
                    <List disablePadding>
                      {annotationSet.groupedData.groupedImageList.map(
                        (groupedImageData: GroupedImageDataType) => (
                          <AnnotationListItem
                            key={`${annotationSet.annotationData.metadata.name}-${groupedImageData.fileName}`}
                            nested
                            statusIcon={props.getFileStatusIcon(
                              groupedImageData.fileStatus
                            )}
                            statusText={props.getFileStatusText(
                              groupedImageData,
                              classes
                            )}
                            data-testid={`${props['data-testid']}ListItemImage${
                              annotationSetIndex + 1
                            }`}
                          />
                        )
                      )}
                    </List>
                  </Collapse>
                </React.Fragment>
              )
          )
        })(props.annotationSetList)}
      </List>
    </div>
  )
}
