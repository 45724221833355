import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import LockOutlined from '@mui/icons-material/LockOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { LoginInputProps } from './types'
import { EmphasizedMessage, ErrorMessage } from 'views/components'
import LoadingButton from '../loadingButton'

const useStyles = makeStyles()((theme) => ({
  button: {
    verticalAlign: 'bottom',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  passwordBox: {
    '& > .MuiFormControl-root > .MuiOutlinedInput-root': {
      // your root styles but with higher CSS specificity
      paddingRight: 0,
    },
  },
  passwordTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
    '& > .MuiOutlinedInput-root > .MuiOutlinedInput-input': {
      paddingRight: theme.spacing(5),
    },
  },
  passwordVisible: {
    position: 'absolute',
    right: '16px',
  },
}))

export const LoginInput: React.FC<LoginInputProps> = (
  props: LoginInputProps
) => {
  const { classes } = useStyles()

  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection='column'
      alignItems='center'
    >
      <Avatar>
        <LockOutlined />
      </Avatar>
      {!props.isMfa ? (
        <>
          {props.error ? (
            <Box mt={2} mb={2}>
              <ErrorMessage title={props.errorMessage} />
            </Box>
          ) : (
            <></>
          )}
          <Box display='flex'>
            <TextField
              disabled={props.loading}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              className={classes.textField}
              onChange={props.emailTextHandleChange}
              inputProps={{ 'data-testid': 'login-email' }}
            />
          </Box>
          <Box display='flex' className={classes.passwordBox}>
            <TextField
              disabled={props.loading}
              variant='outlined'
              margin='normal'
              fullWidth
              id='outlined-adornment-password'
              label='Password'
              name='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              className={classes.passwordTextField}
              onChange={props.passwordTextHandleChange}
              inputProps={{ 'data-testid': 'login-password' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    className={classes.passwordVisible}
                  >
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </>
      ) : (
        <>
          {!props.error ? (
            <Box mt={2} mb={2}>
              <EmphasizedMessage
                message={'SMSに送信された認証コードを入力してください。'}
              />
            </Box>
          ) : (
            <Box mt={2} mb={2}>
              <ErrorMessage title={props.errorMessage} />
            </Box>
          )}
          <Box display='flex' flexDirection='column'>
            <Box ml={1}>
              <FormHelperText>{props.phoneNumber}</FormHelperText>
            </Box>
            <TextField
              error={props.error}
              variant='outlined'
              disabled={props.loading}
              required
              fullWidth
              id='authentication Code'
              label='Authentication Code'
              name='authentication Code'
              value={props.authenticationCode}
              className={classes.textField}
              onChange={props.authenticationCodeChange}
            />
          </Box>
        </>
      )}
      <Box display='flex'>
        <LoadingButton
          disabled={props.loading}
          id='login-button'
          type='submit'
          fullWidth
          variant='contained'
          color='blue'
          onClick={props.onClickLoginButton}
          data-testid='login-button'
          text='login'
        />
      </Box>
      {!props.isMfa ? (
        <Box display='flex' justifyContent='center' mt={2}>
          <Link
            style={{ cursor: 'pointer' }}
            onClick={() => props.openPasswordResetDialog()}
          >
            <Typography variant='body2' noWrap>
              パスワードをお忘れの方
            </Typography>
          </Link>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}
