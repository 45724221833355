import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { styled, useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import { default as MuiDialogContent } from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'

import { TableDialogProps } from './types'

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  closeButton: {
    marginBottom: 'auto',
  },
  contentBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

export const TableDialog: React.FC<TableDialogProps> = (
  props: TableDialogProps
) => {
  const { classes } = useStyles()

  const DialogContent = styled(MuiDialogContent)({
    root: {
      padding: useTheme().spacing(2),
    },
  })

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='customized-dialog-title'
        open={props.open}
        fullWidth={true}
        data-testid='table-dialog'
      >
        <DialogContent>
          <div className={classes.contentBtn}>
            <ul>
              <section>
                {Object.entries(props.items ? props.items : {}).map(
                  (tupple) => {
                    return (
                      <li key={tupple[0]}>
                        {tupple[0]}:{tupple[1]}
                      </li>
                    )
                  }
                )}
              </section>
            </ul>
            <IconButton
              data-testid='table-dialog-close'
              aria-label='close'
              onClick={props.handleClose}
              className={classes.closeButton}
            >
              <Close />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
