import { Timestamp } from 'state/firebase'
import { Version } from 'types/StateTypes'

export const AdminFeatureDataListActionType = {
  GET_FEATURE_DATA_LIST: 'GET_FEATURE_DATA_LIST',
  SET_FEATURE_DATA_LIST: 'SET_FEATURE_DATA_LIST',
  CLEAR_FEATURE_DATA_LIST: 'CLEAR_FEATURE_DATA_LIST',
  SET_FEATURE_DATA_LIST_DISPLAY_CONDITION:
    'SET_FEATURE_DATA_LIST_DISPLAY_CONDITION',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST',
  CLEAR_FEATURE_DATA_LIST_STATE: 'CLEAR_FEATURE_DATA_LIST_STATE',
} as const

export interface FeatureDataInfo {
  featureDataId: string
  featureDataName: string
  featureDataGroupVersion: Version
  featureDataGroupId: string
  generatedAt?: Timestamp
  uid: string
}

export interface FeatureDataListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  selectedAlgorithmId: string
  totalCount?: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface FeatureDataListDomainData {
  currentFeatureDataList: FeatureDataInfo[]
  featureDataListDisplayCondition: FeatureDataListDisplayCondition
}

export interface FeatureDataListAppState {
  inProgress: boolean
}

export interface AdminFeatureDataInfoList {
  domainData: FeatureDataListDomainData
  appState: FeatureDataListAppState
}
