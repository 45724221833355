import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { ConfirmViewerDialogProps } from './types'

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  cancelButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}))

export const ConfirmViewerDialog: React.FC<ConfirmViewerDialogProps> = (
  props: ConfirmViewerDialogProps
) => {
  const { classes } = useStyles()

  return (
    <Dialog
      maxWidth={props.maxWidth}
      onClose={props.handleClose}
      open={props.open}
    >
      <Box className={classes.root}>
        {props.title && (
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex'>
              <DialogTitle style={{ padding: '8px' }}>
                {props.title}
              </DialogTitle>
            </Box>
            {props.control && <Box display='flex'>{props.control}</Box>}
          </Box>
        )}
        {props.message}
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={props.handleClose}
            color={'inherit'}
          >
            閉じる
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
