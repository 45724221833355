/** ML Pipeline Docs の Transaction Status に応じた進捗率を算出 （カスタム学習） */
export const convertProgressRateByTransactionStatusForCustomTraining = (
  status: string
): number => {
  switch (status) {
    case 'Starting':
      return 10
    case 'PreProcessing':
      return 20
    case 'CustomTraining':
      return 30
    case 'Evaluating':
      return 40
    case 'PostProcessing':
      return 70
    case 'CreatingAILibrary':
      return 80
    case 'EvaluatingAILibrary':
      return 90
    case 'Completed':
      return 100
    case 'Failed':
    default:
      return 0
  }
}

/** ML Pipeline Docs の Transaction Status に応じた進捗率を算出 （推論）*/
export const convertProgressRateByTransactionStatusForInference = (
  status: string
): number => {
  switch (status) {
    case 'Starting':
      return 10
    case 'PreProcessing':
      return 20
    case 'Inferring':
      return 30
    case 'PostProcessing':
      return 70
    case 'Completed':
      return 100
    case 'Failed':
    default:
      return 0
  }
}

/** ML Pipeline Docs の Transaction Status に応じた進捗率を算出 （ビルド）*/
export const convertProgressRateByTransactionStatusForBuild = (
  status: string
): number => {
  switch (status) {
    case 'Starting':
      return 20
    case 'BuildingEdgeImage':
      return 50
    case 'EvaluatingEdgeImage':
      return 60
    case 'Transferring':
      return 70
    case 'Completed':
      return 100
    case 'Failed':
    case 'Pending':
    default:
      return 0
  }
}

/** ML Pipeline Docs の Transaction Status に応じた進捗率を算出 （特徴量データ） */
export const convertProgressRateByTransactionStatusForFeatureDataGenerating = (
  status: string
): number => {
  switch (status) {
    case 'Starting':
      return 0
    case 'PreProcessing':
      return 25
    case 'CustomTraining':
      return 50
    case 'PostProcessing':
      return 75
    case 'Completed':
      return 100
    case 'Failed':
    default:
      return 0
  }
}

/** ML Pipeline Docs の Transaction Status に応じた進捗率を算出 （特徴量データ転送） */
export const convertProgressRateByTransactionStatusForFeatureDataTransferring =
  (status: string): number => {
    switch (status) {
      case 'Pending':
        return 0
      case 'Starting':
        return 50
      case 'Completed':
        return 100
      case 'Failed':
      default:
        return 0
    }
  }

/** ML Pipeline Docs の Transaction Status に応じた進捗率を算出 （データセットオーグメンテーション） */
export const convertProgressRateByTransactionStatusForDatasetAugmentationList =
  (status: string): number => {
    switch (status) {
      case 'Starting':
        return 10
      case 'Augmentation':
        return 50
      case 'Completed':
        return 100
      case 'Failed':
      default:
        return 0
    }
  }

export const convertQueryStartEndCodeBySearchValue = (
  searchValue: string
): { startCode: string; endCode: string } => {
  const searchValueLength = searchValue.length
  const searchValueStartCode = searchValue.slice(0, searchValueLength - 1)
  const searchValueEndCode = searchValue.slice(
    searchValueLength - 1,
    searchValueLength
  )
  const startCode = searchValue
  const endCode =
    searchValueStartCode +
    String.fromCharCode(searchValueEndCode.charCodeAt(0) + 1)

  return { startCode, endCode }
}

export const convertISO8601 = (day: string, time?: string) =>
  new Date(time ? `${day} ${time}` : day).toISOString()
