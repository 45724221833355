import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import PostAdd from '@mui/icons-material/PostAdd'

import { PostAddIconButtonProps } from './types'

const useStyles = makeStyles()((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  label: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

export const PostAddIconButton: React.FC<PostAddIconButtonProps> = (
  props: PostAddIconButtonProps
) => {
  const { classes } = useStyles()

  return (
    <div>
      <Button
        variant='outlined'
        className={classes.button}
        data-testid='add-button'
        startIcon={<PostAdd />}
        disabled={props.disabled}
        onClick={props.onClick}
        color={
          props.color && props.color === 'blue'
            ? 'primary'
            : props.color && props.color === 'red'
            ? 'secondary'
            : 'inherit'
        }
      >
        <Typography className={classes.label}>{props.label}</Typography>
      </Button>
    </div>
  )
}
