import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'

import { State } from 'state/store'
import {
  FeatureDataInfo,
  FeatureDataListActions,
  featureDataListActions,
  featureDataListOperations,
} from 'state/ducks/featureDataList'

import {
  FeatureDataIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  GlobalLoading,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  BreadcrumbsComponent,
} from 'views/components'

import { useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { formatDateTimeSec } from 'views/components/utils/date'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { FeatureDataListDisplayCondition } from 'state/ducks/featureDataList/types'
import {
  hasSharedUserGroupQueryParameter,
  sharedUserGroupQueryParameter,
} from 'views/containers/utils/queryParams'
import { CustomerAuthorizer } from 'views/components/organisms/customerAuthorizer'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataListState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データ一覧取得 */
  getFeatureDataList: (
    featureDataGroupId: string,
    isSharedUserGroup: boolean
  ) =>
    dispatch(
      featureDataListOperations.getFeatureDataList(
        featureDataGroupId,
        isSharedUserGroup
      )
    ),
  /** 特徴量データ一覧をクリア */
  clearFeatureDataList: () =>
    dispatch(featureDataListActions.clearFeatureDataList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: FeatureDataListDisplayCondition) =>
    dispatch(featureDataListActions.setListDisplayCondition(listCondition)),
  /** Stateのクリア */
  clearFeatureDataListState: () =>
    dispatch(featureDataListActions.clearFeatureDataListState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  explanation: {
    fontSize: theme.typography.pxToRem(18),
  },
  featureDataGroupInfoItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flexStart',
    flexDirection: 'column',
  },
  toolTipItem: {
    width: '300px',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'feature-dataId',
    title: '特徴量データ ID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'feature-dataName',
    title: '特徴量データ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'feature-data-group-version',
    title: 'バージョン',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'generated-at',
    title: '生成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'generatedUser',
    title: '生成ユーザーID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const FeatureDataList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const { classes } = useStyles()
  const globalTheme = useTheme()
  const history = useHistory()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  const [featureDataGroupId, setTrainedFeatureDataGroupId] =
    useState<string>('')
  useEffect(() => {
    // NOTE: featureDataGroupIdがprops.match.paramsに存在しないためwarningとなるが特徴量データグループ詳細に遷移する際は存在する
    setTrainedFeatureDataGroupId(
      (props.match.params as { [key: string]: string })['featureDataGroupId']
    )
    props.getFeatureDataList(
      (props.match.params as { [key: string]: string })['featureDataGroupId'],
      isSharedUserGroup
    )
    return () => {
      props.clearFeatureDataListState()
    }
  }, [props.authedUser.auth.customClaims.userGroupId])

  useEffect(() => {
    return () => {
      props.clearFeatureDataListState()
    }
  }, [])

  /** 元の特徴量データグループのデータがない時はhomeに戻る */
  useEffect(() => {
    if (props.appState.featureDataGroupSubState === 'NotFoundProcessed') {
      history.replace('/')
    }
  }, [props.appState.featureDataGroupSubState])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  } = tableActions(props, featureDataGroupId, isSharedUserGroup)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.featureDataListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.featureDataListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.featureDataListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentFeatureDataList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentFeatureDataList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.featureDataListDisplayCondition,
    props.domainData.currentFeatureDataList,
  ])

  /** テーブルに表示する特徴量データのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: FeatureDataInfo[] = tableContent.map(
      (featureDataList: FeatureDataInfo) => {
        return {
          featureDataId: featureDataList.featureDataId,
          featureDataName: featureDataList.featureDataName,
          featureDataGroupVersion: {
            displayName: featureDataList.featureDataGroupVersion.displayName,
            major: featureDataList.featureDataGroupVersion.major,
            minor: featureDataList.featureDataGroupVersion.minor,
            patch: featureDataList.featureDataGroupVersion.patch,
          },
          generatedAt: featureDataList.generatedAt,
          uid: featureDataList.uid,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataId') {
          return (
            <TooltipLink
              key={key}
              data-testid={`feature-data-${value}`}
              title={value}
              placement='right-start'
              onClick={() => {
                history.replace(
                  `/feature-data-groups/${featureDataGroupId}/feature-data/${value}${
                    isSharedUserGroup ? '?shared-user-group=true' : ''
                  }`
                )
              }}
            />
          )
        } else if (key === 'featureDataGroupVersion') {
          if (value.displayName) {
            return (
              <Typography key={key} align='center'>
                v{value.displayName}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'generatedAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'featureDataName') {
          if (value) {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value}</Typography>
              </Tooltip>
            )
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'uid') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else {
          return <Typography key={key}>{TABLE_CELL_NOT_APPLICABLE}</Typography>
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const featureDataListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.featureDataListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.featureDataListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.featureDataListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.featureDataListDisplayCondition.sortKey,
          order: props.domainData.featureDataListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.featureDataListDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: '特徴量データグループ一覧',
              path: 'feature-data-groups',
            },
            {
              name:
                props.domainData.featureDataGroupName !== ''
                  ? `${props.domainData.featureDataGroupName}`
                  : `${props.domainData.featureDataGroupId}`,
              path: `${props.domainData.featureDataGroupId}`,
              query: isSharedUserGroup
                ? sharedUserGroupQueryParameter
                : undefined,
            },
            {
              name: '特徴量データ一覧',
              path: 'feature-data',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <FeatureDataIcon
              className={classes.pageIcon}
              data-testid='feature-data-list-title-icon'
            />
            <Typography component='div'>
              <h2 data-testid='feature-data-list-title'>特徴量データ</h2>
            </Typography>
          </Box>
          <Box display='flex'>
            {isSharedUserGroup ? (
              <></>
            ) : (
              <CustomerAuthorizer
                type='UPLOAD_MODEL'
                auth={props.userProfile?.role ?? ''}
              >
                <>
                  <CloudUploadIcon />
                  <Link data-testid='feature-data-entry' to={`${url}/entry`}>
                    <Typography>特徴量データアップロード</Typography>
                  </Link>
                </>
              </CustomerAuthorizer>
            )}
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <div className={classes.featureDataGroupInfoItem}>
            <div className={classes.toolTipItem}>
              <Typography data-testid='feature-data-group-name'>
                特徴量データグループID:
                <TooltipLink
                  title={props.domainData.featureDataGroupId}
                  placement='right-start'
                  onClick={() =>
                    history.push(
                      `/feature-data-groups/${
                        props.domainData.featureDataGroupId
                      }${isSharedUserGroup ? '?shared-user-group=true' : ''}`
                    )
                  }
                  data-testid={`feature-data-group-id-${props.domainData.featureDataGroupId}`}
                />
              </Typography>
            </div>
            <Typography data-testid='feature-data-group-name'>
              {`特徴量データグループ名:${
                props.domainData.featureDataGroupName ??
                TABLE_CELL_NOT_APPLICABLE
              }`}
            </Typography>
          </div>
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (特徴量データID)'
                value={
                  props.domainData.featureDataListDisplayCondition.searchValue
                }
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {featureDataListTable}
        </CustomTrainingPageParagraph>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const tableActions = (
  props: Props,
  featureDataGroupId: string,
  isSharedUserGroup: boolean
) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    props.clearFeatureDataList()
    const pageNumber =
      props.domainData.currentFeatureDataList.length >
      props.domainData.featureDataListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.featureDataListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentFeatureDataList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getFeatureDataList(featureDataGroupId, isSharedUserGroup)
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearFeatureDataList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.featureDataListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getFeatureDataList(featureDataGroupId, isSharedUserGroup)
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getFeatureDataList(featureDataGroupId, isSharedUserGroup)
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearFeatureDataList()
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: 0,
    })

    props.getFeatureDataList(featureDataGroupId, isSharedUserGroup)
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  }
}

export const FeatureDataListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataList))
