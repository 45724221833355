import { Timestamp } from 'state/firebase'
import { ResourceLoadingState, TransactionStatusKind } from 'state/utils/types'

export const FeatureDataGeneratingDetailActionType = {
  SET_CURRENT_FEATURE_DATA_GENERATING_DETAIL:
    'SET_CURRENT_FEATURE_DATA_GENERATING_DETAIL',
  SET_FEATURE_DATA_GENERATING_DETAIL_STATE:
    'SET_FEATURE_DATA_GENERATING_DETAIL_STATE',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_DETAIL:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_DETAIL',
  CLEAR_FEATURE_DATA_GENERATING_DETAIL_STATE:
    'CLEAR_FEATURE_DATA_GENERATING_DETAIL_STATE',
  SET_TOAST_INFO_FOT_FEATURE_DATA_GENERATING:
    'SET_TOAST_INFO_FOT_FEATURE_DATA_GENERATING',
  SET_FEATURE_DATA_DL_LINKS: 'SET_FEATURE_DATA_DL_LINKS',
  SET_ML_PIPELINE_LOG_FILES_FOR_FEATURE_DATA_GENERATING:
    'SET_ML_PIPELINE_LOG_FILES_FOR_FEATURE_DATA_GENERATING',
} as const

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface Dataset {
  datasetId: string
  datasetName: string
}

interface FeatureData {
  featureDataId: string
  featureDataName: string
}

export interface MediaLink {
  mediaName: string
  mediaUrl: string
  mediaSize: number
}

export interface FeatureDataDlLink {
  linkName: string
  mediaLinks: MediaLink[]
  totalMediaSize: number
}

export type DestType = 'FeatureDataOnly' | 'All'
export type StepStatus =
  | 'Pending'
  | 'Starting'
  | 'PreProcessing'
  | 'Training'
  | 'Evaluating'
  | 'PostProcessing'
  | 'Completed'
  | 'Failed'

export interface MlPipelineLogFile {
  fileName: string
  fileLink: string
  fileSize: number
  createdAt: Date
}

interface Setting {
  settingId: string
  settingName: string
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface CurrentFeatureDataGeneratingDetail {
  mlPipelineId: string
  mlPipelineName: string
  mlPipelineRemarks: string
  trainingAlgorithm: {
    algorithmName: string
    trainingAlgorithmVersion: {
      displayName: string
      major: number
      minor: number
      patch: number
      preRelease: number
    }
  }
  progress: Progress
  startedAt: Timestamp
  endedAt: Timestamp
  dataset: Dataset
  setting: Setting
  featureData: FeatureData
  destType: DestType
  createdBy: AccountName
  steps: {
    [x: string]: {
      stepId: string
      stepStatus: StepStatus
    }
  }
}

export interface FeatureDataGeneratingDetailState {
  mlPipelineDataState: ResourceLoadingState
  featureDataDlLinkSubState: ResourceLoadingState
  mlPipelineLogSubState: ResourceLoadingState
}

export interface GetFeatureDataFilesResponse {
  data: {
    items: FeatureDataDlLink[]
  }
}

export interface FeatureDataGeneratingDetailDomainData {
  currentFeatureDataGeneratingDetail?: CurrentFeatureDataGeneratingDetail
  featureDataDlLinks: FeatureDataDlLink[]
  mlPipelineLogFiles: MlPipelineLogFile[]
}

export interface FeatureDataGeneratingDetailAppState {
  featureDataGeneratingDetailState: FeatureDataGeneratingDetailState
  toastInfo: ToastInfo | undefined
  inProgress: boolean
}

export interface FeatureDataGeneratingDetail {
  domainData: FeatureDataGeneratingDetailDomainData
  appState: FeatureDataGeneratingDetailAppState
}
