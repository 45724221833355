import React from 'react'
import IconButton from '@mui/material/IconButton'
import ImageSearch from '@mui/icons-material/ImageSearch'

import { RobotDataAnalysisIconProps } from './types'

export const RobotDataAnalysisIcon: React.FC<RobotDataAnalysisIconProps> = (
  props: RobotDataAnalysisIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <ImageSearch sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
