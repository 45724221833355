// Type
export interface Router {
  location: string
}

// ActionType
export const AppStateActionType = {
  SET_AUTHED: 'SET_AUTHED',
  SET_ROUTER: 'SET_ROUTER',
  CLEAR_APP_STATE: 'CLEAR_APP_STATE',
} as const
