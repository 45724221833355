export const RETRY_COUNT = 9
export const RETRY_BASE_DELAY = 200

export interface RetryConfig {
  count: number // リトライ回数
  baseDelay: number // リトライまでの待ち時間(ms)
  isExponentialBackOff?: boolean // ExponentialBackOffフラグ
}

export const DefaultRetryConfig: RetryConfig = {
  count: RETRY_COUNT,
  baseDelay: RETRY_BASE_DELAY,
  isExponentialBackOff: false,
}

const wait = (
  retryCount: number,
  baseDelay: number,
  isExponentialBackOff?: boolean
) => {
  const exponentialDelayFunc = exponentialDelay(baseDelay, isExponentialBackOff)
  return new Promise((resolve) =>
    setTimeout(resolve, exponentialDelayFunc(retryCount))
  )
}

export const retryOperation = async <T>(
  operation: () => Promise<T>,
  config: RetryConfig = DefaultRetryConfig,
  retryCount = 0,
  lastError?: unknown
): Promise<T> => {
  if (retryCount > config.count) throw lastError

  try {
    return await operation()
  } catch (e) {
    await wait(retryCount, config.baseDelay, config.isExponentialBackOff)
    return retryOperation(operation, config, retryCount + 1, e)
  }
}

export const exponentialDelay =
  (baseDelay: number, isExponentialBackOff?: boolean) =>
  (retryCount: number) => {
    return isExponentialBackOff ? baseDelay * 2 ** retryCount : baseDelay
  }
