import { authInstance } from 'state/firebase'
import { UserCredential, signInWithEmailAndPassword } from 'firebase/auth'
import { functionsInstance } from 'state/firebase'
import { HttpsCallableResult, httpsCallable } from 'firebase/functions'
import { AidpCloudFunctionsUrl } from 'state/utils'

const timeoutLimitSeconds = 15

export const LoginOperationsApi = {
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ): Promise<UserCredential> =>
    signInWithEmailAndPassword(authInstance, email, password),
  updateIsLastLoginStatus: (): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'update-user-login-last',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )(),
}
