import {
  CreateAction,
  ActionsUnion,
  Action,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  CustomTrainingActionType,
  TrainedModelGroup,
  Dataset,
  Setting,
  SettingFormat,
  DisplayCondition,
  SortDisplayCondition,
  MetaData,
  CustomTrainingStateKind,
  TrainedModel,
  InferenceAlgorithmVersion,
  ExecutionInfo,
  TrainedModelGroupLists,
  ClassSet,
  ClassSetDisplayCondition,
  ObjectClassificationExtendedSetting,
  PreProcessKind,
  ObjectRecognitionExtendedSetting,
} from './types'
import { TrainingAlgorithmVersion } from 'state/app/domainData'
import { Version } from 'types/StateTypes'

export type CustomTrainingAction = ActionsUnion<typeof customTrainingActions>

export const customTrainingActions = {
  setTrainedModelGroupList: (
    trainedModelGroups: TrainedModelGroup[]
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_GROUP_LIST',
    { trainedModelGroups: TrainedModelGroup[] }
  > =>
    CreateAction(CustomTrainingActionType.SET_TRAINED_MODEL_GROUP_LIST, {
      trainedModelGroups,
    }),
  setSourceTrainedModelGroupList: (
    trainedModelGroups: TrainedModelGroupLists
  ): ActionWithPayload<
    'SET_SOURCE_TRAINED_MODEL_GROUP_LIST',
    { trainedModelGroups: TrainedModelGroupLists }
  > =>
    CreateAction(CustomTrainingActionType.SET_SOURCE_TRAINED_MODEL_GROUP_LIST, {
      trainedModelGroups,
    }),
  setSelectedSourceTrainedModelGroup: (
    selectedTrainedModelGroup?: TrainedModelGroup
  ): ActionWithPayload<
    'SET_SELECTED_SOURCE_TRAINED_MODEL_GROUP',
    { selectedTrainedModelGroup?: TrainedModelGroup }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_SELECTED_SOURCE_TRAINED_MODEL_GROUP,
      {
        selectedTrainedModelGroup,
      }
    ),
  setSelectedDestinationTrainedModelGroup: (
    selectedDestinationTrainedModelGroup?: TrainedModelGroup
  ): ActionWithPayload<
    'SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP',
    { selectedDestinationTrainedModelGroup?: TrainedModelGroup }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP,
      {
        selectedDestinationTrainedModelGroup,
      }
    ),
  setDatasetList: (
    datasetList: Dataset[]
  ): ActionWithPayload<
    'SET_DATASET_LIST_FOR_CUSTOM_TRAINING',
    { datasetList: Dataset[] }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_DATASET_LIST_FOR_CUSTOM_TRAINING,
      {
        datasetList,
      }
    ),
  setSettingList: (
    settingList: Setting[]
  ): ActionWithPayload<'SET_SETTING_LIST', { settingList: Setting[] }> =>
    CreateAction(CustomTrainingActionType.SET_SETTING_LIST, {
      settingList,
    }),
  setInferenceAlgorithmList: (
    inferenceAlgorithmList: InferenceAlgorithmVersion[]
  ): ActionWithPayload<
    'SET_INFERENCE_ALGORITHM_LIST',
    { inferenceAlgorithmList: InferenceAlgorithmVersion[] }
  > =>
    CreateAction(CustomTrainingActionType.SET_INFERENCE_ALGORITHM_LIST, {
      inferenceAlgorithmList,
    }),
  setSourceModelDisplayCondition: (
    trainedModelDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_SOURCE_MODEL_DISPLAY_CONDITION',
    { trainedModelDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(CustomTrainingActionType.SET_SOURCE_MODEL_DISPLAY_CONDITION, {
      trainedModelDisplayCondition,
    }),
  clearBaseModelDisplayCondition:
    (): Action<'CLEAR_BASE_MODEL_DISPLAY_CONDITION'> =>
      CreateAction(CustomTrainingActionType.CLEAR_BASE_MODEL_DISPLAY_CONDITION),
  setDestinationModelDisplayCondition: (
    trainedModelDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_DESTINATION_MODEL_DISPLAY_CONDITION',
    { trainedModelDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_DESTINATION_MODEL_DISPLAY_CONDITION,
      {
        trainedModelDisplayCondition,
      }
    ),
  clearDestinationModelDisplayCondition:
    (): Action<'CLEAR_DESTINATION_MODEL_DISPLAY_CONDITION'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_DESTINATION_MODEL_DISPLAY_CONDITION
      ),
  setTrainingAlgorithmDisplayCondition: (
    trainingAlgorithmDisplayCondition?: SortDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINING_ALGORITHM_DISPLAY_CONDITION',
    { trainingAlgorithmDisplayCondition?: SortDisplayCondition }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_TRAINING_ALGORITHM_DISPLAY_CONDITION,
      {
        trainingAlgorithmDisplayCondition,
      }
    ),
  clearTrainingAlgorithmDisplayCondition:
    (): Action<'CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION
      ),
  setInheritDatasetDisplayCondition: (
    inheritDatasetDisplayCondition?: SortDisplayCondition
  ): ActionWithPayload<
    'SET_INHERIT_DATASET_DISPLAY_CONDITION',
    { inheritDatasetDisplayCondition?: SortDisplayCondition }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_INHERIT_DATASET_DISPLAY_CONDITION,
      {
        inheritDatasetDisplayCondition,
      }
    ),
  clearInferenceAlgorithmDisplayCondition:
    (): Action<'CLEAR_INFERENCE_ALGORITHM_DISPLAY_CONDITION'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_INFERENCE_ALGORITHM_DISPLAY_CONDITION
      ),
  setDatasetDisplayCondition: (
    datasetDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_DATASET_DISPLAY_CONDITION',
    { datasetDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(CustomTrainingActionType.SET_DATASET_DISPLAY_CONDITION, {
      datasetDisplayCondition,
    }),
  clearDatasetDisplayCondition: (): Action<'CLEAR_DATASET_DISPLAY_CONDITION'> =>
    CreateAction(CustomTrainingActionType.CLEAR_DATASET_DISPLAY_CONDITION),
  setSettingDisplayCondition: (
    settingDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_SETTING_DISPLAY_CONDITION',
    { settingDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(CustomTrainingActionType.SET_SETTING_DISPLAY_CONDITION, {
      settingDisplayCondition,
    }),
  clearSettingDisplayCondition: (): Action<'CLEAR_SETTING_DISPLAY_CONDITION'> =>
    CreateAction(CustomTrainingActionType.CLEAR_SETTING_DISPLAY_CONDITION),
  setSelectedTrainingAlgorithmId: (
    selectedTrainingAlgorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_ID',
    { selectedTrainingAlgorithmId: string }
  > =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_TRAINING_ALGORITHM_ID, {
      selectedTrainingAlgorithmId,
    }),
  setSelectedTrainingAlgorithmVersion: (
    selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION',
    { selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION,
      {
        selectedTrainingAlgorithmVersion,
      }
    ),
  setInferenceAlgorithmVersions: (
    inferenceAlgorithmVersions: InferenceAlgorithmVersion[]
  ): ActionWithPayload<
    'SET_INFERENCE_ALGORITHM_VERSIONS_FOR_CUSTOM_TRAINING',
    {
      inferenceAlgorithmVersions: InferenceAlgorithmVersion[]
    }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_INFERENCE_ALGORITHM_VERSIONS_FOR_CUSTOM_TRAINING,
      {
        inferenceAlgorithmVersions,
      }
    ),
  setSelectedBaseModel: (
    selectedBaseModel?: TrainedModel
  ): ActionWithPayload<
    'SET_SELECTED_BASE_MODEL',
    { selectedBaseModel?: TrainedModel }
  > =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_BASE_MODEL, {
      selectedBaseModel,
    }),
  setSelectedInheritModel: (
    selectedInheritModel?: TrainedModel
  ): ActionWithPayload<
    'SET_SELECTED_INHERIT_MODEL',
    { selectedInheritModel?: TrainedModel }
  > =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_INHERIT_MODEL, {
      selectedInheritModel,
    }),
  setSelectedDataset: (
    selectedDataset?: Dataset
  ): ActionWithPayload<'SET_SELECTED_DATASET', { selectedDataset?: Dataset }> =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_DATASET, {
      selectedDataset,
    }),
  setSelectedSetting: (
    selectedSetting?: Setting
  ): ActionWithPayload<'SET_SELECTED_SETTING', { selectedSetting?: Setting }> =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_SETTING, {
      selectedSetting,
    }),
  setSelectedSettingFormat: (
    selectedSettingFormat?: SettingFormat
  ): ActionWithPayload<
    'SET_SELECTED_SETTING_FORMAT',
    { selectedSettingFormat?: SettingFormat }
  > =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_SETTING_FORMAT, {
      selectedSettingFormat,
    }),
  setSelectedCustomTrainingGenerateKind: (
    selectedCustomTrainingGenerateKind: 'EdgeImage' | 'CustomModel'
  ): ActionWithPayload<
    'SET_SELECTED_CUSTOM_TRAINING_GENERATE_KIND',
    { selectedCustomTrainingGenerateKind: 'EdgeImage' | 'CustomModel' }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_SELECTED_CUSTOM_TRAINING_GENERATE_KIND,
      {
        selectedCustomTrainingGenerateKind,
      }
    ),
  setSelectedInferenceAlgorithmVersion: (
    selectedInferenceAlgorithm?: InferenceAlgorithmVersion
  ): ActionWithPayload<
    'SET_SELECTED_INFERENCE_ALGORITHM',
    { selectedInferenceAlgorithm?: InferenceAlgorithmVersion }
  > =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_INFERENCE_ALGORITHM, {
      selectedInferenceAlgorithm,
    }),
  setMlPipelinesMetaData: (
    mlPipelinesMetaData?: MetaData
  ): ActionWithPayload<
    'SET_ML_PIPELINE_META_DATA',
    { mlPipelinesMetaData?: MetaData }
  > =>
    CreateAction(CustomTrainingActionType.SET_ML_PIPELINE_META_DATA, {
      mlPipelinesMetaData,
    }),
  setCustomModelMetaData: (
    customModelMetaData?: MetaData
  ): ActionWithPayload<
    'SET_CUSTOM_MODEL_META_DATA',
    { customModelMetaData?: MetaData }
  > =>
    CreateAction(CustomTrainingActionType.SET_CUSTOM_MODEL_META_DATA, {
      customModelMetaData,
    }),
  setTrainingAlgorithmSubState: (
    trainingAlgorithmSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_TRAINING_ALGORITHM_SUB_STATE',
    { trainingAlgorithmSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(CustomTrainingActionType.SET_TRAINING_ALGORITHM_SUB_STATE, {
      trainingAlgorithmSubState,
    }),
  setBaseModelSubState: (
    baseModelSubState: 'Unselected' | 'Selected' | 'NotFound'
  ): ActionWithPayload<
    'SET_BASE_MODEL_SUB_STATE',
    { baseModelSubState: 'Unselected' | 'Selected' | 'NotFound' }
  > =>
    CreateAction(CustomTrainingActionType.SET_BASE_MODEL_SUB_STATE, {
      baseModelSubState,
    }),
  setClassSetSubState: (
    classSetSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_CLASS_SET_SUB_STATE_FOR_CUSTOM_TRAINING',
    { classSetSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_CLASS_SET_SUB_STATE_FOR_CUSTOM_TRAINING,
      {
        classSetSubState,
      }
    ),
  setDatasetSubState: (
    datasetSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_DATASET_SUB_STATE',
    { datasetSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(CustomTrainingActionType.SET_DATASET_SUB_STATE, {
      datasetSubState,
    }),
  setModelGroupSubState: (
    modelGroupSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_MODEL_GROUP_SUB_STATE',
    { modelGroupSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(CustomTrainingActionType.SET_MODEL_GROUP_SUB_STATE, {
      modelGroupSubState,
    }),
  setSettingSubState: (
    settingSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_SETTING_SUB_STATE',
    { settingSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(CustomTrainingActionType.SET_SETTING_SUB_STATE, {
      settingSubState,
    }),
  setOutputFormatSubState: (
    outputFormatSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_OUTPUT_FORMAT_SUB_STATE',
    { outputFormatSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(CustomTrainingActionType.SET_OUTPUT_FORMAT_SUB_STATE, {
      outputFormatSubState,
    }),
  setMetaDataSubState: (
    metaDataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_META_DATA_SUB_STATE',
    { metaDataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(CustomTrainingActionType.SET_META_DATA_SUB_STATE, {
      metaDataSubState,
    }),
  setCustomTrainingState: (
    customTrainingStateKind: CustomTrainingStateKind
  ): ActionWithPayload<
    'SET_CUSTOM_TRAINING_STATE',
    { customTrainingStateKind: CustomTrainingStateKind }
  > =>
    CreateAction(CustomTrainingActionType.SET_CUSTOM_TRAINING_STATE, {
      customTrainingStateKind,
    }),
  clearCurrentCustomTrainingState:
    (): Action<'CLEAR_CURRENT_CUSTOM_TRAINING_STATE'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_CURRENT_CUSTOM_TRAINING_STATE
      ),
  executeCustomTraining: (
    isExecuted: boolean
  ): ActionWithPayload<
    'EXEC_CUSTOM_TRAINING_SUCCESS',
    { isExecuted: boolean }
  > =>
    CreateAction(CustomTrainingActionType.EXEC_CUSTOM_TRAINING_SUCCESS, {
      isExecuted,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<'SET_IN_PROGRESS', { inProgress: boolean }> =>
    CreateAction(CustomTrainingActionType.SET_IN_PROGRESS, {
      inProgress,
    }),
  setExecutedInfo: (
    executedInfo?: ExecutionInfo
  ): ActionWithPayload<'SET_EXECUTED_INFO', { executedInfo?: ExecutionInfo }> =>
    CreateAction(CustomTrainingActionType.SET_EXECUTED_INFO, {
      executedInfo,
    }),
  executeCustomTrainingFailure: (): Action<'EXEC_CUSTOM_TRAINING_FAILURE'> =>
    CreateAction(CustomTrainingActionType.EXEC_CUSTOM_TRAINING_FAILURE),
  setSourceTrainedModelGroupDisplayCondition: (
    condition: DisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ): ActionWithPayload<
    'SET_SOURCE_MODEL_GROUP_DISPLAY_CONDITION',
    {
      condition: DisplayCondition & {
        selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
      }
    }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_SOURCE_MODEL_GROUP_DISPLAY_CONDITION,
      {
        condition,
      }
    ),
  setIsSelected: (
    selectedBaseInferenceContainerImageId: string[]
  ): ActionWithPayload<
    'SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID_FOR_CUSTOM_TRAINING',
    { selectedBaseInferenceContainerImageId: string[] }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID_FOR_CUSTOM_TRAINING,
      {
        selectedBaseInferenceContainerImageId,
      }
    ),
  setIsTransfer: (
    isTransfer: boolean
  ): ActionWithPayload<
    'SET_IS_TRANSFER_FOR_CUSTOM_TRAINING',
    { isTransfer: boolean }
  > =>
    CreateAction(CustomTrainingActionType.SET_IS_TRANSFER_FOR_CUSTOM_TRAINING, {
      isTransfer,
    }),
  setIsSystemEvaluation: (
    isSystemEvaluation: boolean
  ): ActionWithPayload<
    'SET_IS_SYSTEM_EVALUATION_FOR_CUSTOM_TRAINING',
    { isSystemEvaluation: boolean }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_IS_SYSTEM_EVALUATION_FOR_CUSTOM_TRAINING,
      {
        isSystemEvaluation,
      }
    ),
  setDestinationTrainedModelGroupDisplayCondition: (
    condition: DisplayCondition
  ): ActionWithPayload<
    'SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION',
    { condition: DisplayCondition }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION,
      {
        condition,
      }
    ),
  clearDestinationTrainedModelGroupDisplayCondition:
    (): Action<'CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION
      ),
  clearSourceTrainedModelGroupDisplayCondition:
    (): Action<'CLEAR_SOURCE_MODEL_GROUP_DISPLAY_CONDITION'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_SOURCE_MODEL_GROUP_DISPLAY_CONDITION
      ),
  setSelectedModelVersion: (
    selectedVersion?: Version
  ): ActionWithPayload<
    'SET_SELECTED_TRAINED_MODEL_VERSION',
    { selectedVersion?: Version }
  > =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_TRAINED_MODEL_VERSION, {
      selectedVersion,
    }),
  setCurrentDatasetListSnapshot: (
    currentDatasetListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_DATASET_LIST_SNAPSHOT',
    { currentDatasetListSnapshot?: () => void }
  > =>
    CreateAction(CustomTrainingActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT, {
      currentDatasetListSnapshot,
    }),

  /**
   * 選択中のデータセットテンプレートIDを設定する
   * @param datasetTemplateId データセットテンプレートID
   */
  setSelectedDatasetTemplateId: (
    datasetTemplateId: string
  ): ActionWithPayload<
    'SET_SELECTED_DATASET_TEMPLATE_ID',
    { datasetTemplateId: string }
  > =>
    CreateAction(CustomTrainingActionType.SET_SELECTED_DATASET_TEMPLATE_ID, {
      datasetTemplateId,
    }),

  /**
   * クラスセット一覧を設定する
   * @param classSets クラスセット一覧
   */
  setClassSetList: (
    classSets: ClassSet[]
  ): ActionWithPayload<
    'SET_CLASS_SET_LIST_FOR_CUSTOM_TRAINING',
    { classSets: ClassSet[] }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_CLASS_SET_LIST_FOR_CUSTOM_TRAINING,
      {
        classSets,
      }
    ),

  /**
   * 選択中のクラスセットを設定する
   * @param selectedClassSet 選択中のクラスセット
   */
  setSelectedClassSet: (
    selectedClassSet?: ClassSet
  ): ActionWithPayload<
    'SET_SELECTED_CLASS_SET_FOR_CUSTOM_TRAINING',
    { selectedClassSet?: ClassSet }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_SELECTED_CLASS_SET_FOR_CUSTOM_TRAINING,
      {
        selectedClassSet,
      }
    ),

  /**
   * クラスセット一覧の表示条件を設定する
   * @param classSetDisplayCondition クラスセット一覧の表示条件
   */
  setClassSetDisplayCondition: (
    classSetDisplayCondition: ClassSetDisplayCondition
  ): ActionWithPayload<
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING',
    { classSetDisplayCondition: ClassSetDisplayCondition }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING,
      {
        classSetDisplayCondition,
      }
    ),

  /**
   * クラスセット一覧の表示条件をクリアする
   */
  clearClassSetDisplayCondition:
    (): Action<'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING
      ),

  /**
   * クラスセット一覧をクリアする
   */
  clearClassSetList: (): Action<'CLEAR_CLASS_SET_LIST_FOR_CUSTOM_TRAINING'> =>
    CreateAction(
      CustomTrainingActionType.CLEAR_CLASS_SET_LIST_FOR_CUSTOM_TRAINING
    ),

  /**
   * セッティング付加情報を設定する
   */
  setObjectClassificationExtendedSetting: (
    objectClassificationExtendedSetting?: ObjectClassificationExtendedSetting
  ): ActionWithPayload<
    'SET_OBJECT_CLASSIFICATION_EXTENDED_SETTING',
    {
      objectClassificationExtendedSetting?: ObjectClassificationExtendedSetting
    }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_OBJECT_CLASSIFICATION_EXTENDED_SETTING,
      {
        objectClassificationExtendedSetting,
      }
    ),

  /**
   * セッティング付加情報をクリアする
   */
  clearObjectClassificationExtendedSetting:
    (): Action<'CLEAR_OBJECT_CLASSIFICATION_EXTENDED_SETTING'> =>
      CreateAction(
        CustomTrainingActionType.CLEAR_OBJECT_CLASSIFICATION_EXTENDED_SETTING
      ),

  /**
   * 前処理種別を設定する
   */
  setPreProcessKind: (
    preProcessKinds?: PreProcessKind[]
  ): ActionWithPayload<
    'SET_PRE_PROCESS_KIND',
    { preProcessKinds?: PreProcessKind[] }
  > =>
    CreateAction(CustomTrainingActionType.SET_PRE_PROCESS_KIND, {
      preProcessKinds,
    }),

  /**
   * 対象物認識の付加情報を設定する
   */
  setObjectRecognitionExtendedSetting: (
    objectRecognition?: ObjectRecognitionExtendedSetting
  ): ActionWithPayload<
    'SET_OBJECT_RECOGNITION_EXTENDED_SETTING',
    { objectRecognition?: ObjectRecognitionExtendedSetting }
  > =>
    CreateAction(
      CustomTrainingActionType.SET_OBJECT_RECOGNITION_EXTENDED_SETTING,
      {
        objectRecognition,
      }
    ),
}
