import { InferenceAlgorithmVersionDocument } from './types'
import { fireStoreTypeGuard } from './typeguard'

// InferenceAlgorithmVersionDocument Documentに変換
export const convertDocument = (
  data: unknown
): InferenceAlgorithmVersionDocument => {
  const inferenceAlgorithmVersionData =
    data as InferenceAlgorithmVersionDocument
  return {
    'algorithm-id': inferenceAlgorithmVersionData['algorithm-id'],
    'algorithm-version': {
      'display-name':
        inferenceAlgorithmVersionData['algorithm-version']['display-name'],
      major: inferenceAlgorithmVersionData['algorithm-version']['major'],
      minor: inferenceAlgorithmVersionData['algorithm-version']['minor'],
      patch: inferenceAlgorithmVersionData['algorithm-version']['patch'],
      'pre-release':
        inferenceAlgorithmVersionData['algorithm-version']['pre-release'],
    },
    'available-version': {
      'training-algorithm': {
        'lower-limit': {
          'display-name':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['display-name'],
          major:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['major'],
          minor:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['minor'],
          patch:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['patch'],
          'pre-release':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['pre-release'],
        },
        'upper-limit': {
          'display-name':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['display-name'],
          major:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['major'],
          minor:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['minor'],
          patch:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['patch'],
          'pre-release':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['pre-release'],
        },
      },
    },
    'commit-hash': inferenceAlgorithmVersionData['commit-hash'],
    'inference-algorithm-version':
      inferenceAlgorithmVersionData['inference-algorithm-version'],
    'inference-code-version': {
      'display-name':
        inferenceAlgorithmVersionData['inference-code-version']['display-name'],
      major: inferenceAlgorithmVersionData['inference-code-version']['major'],
      minor: inferenceAlgorithmVersionData['inference-code-version']['minor'],
      patch: inferenceAlgorithmVersionData['inference-code-version']['patch'],
    },
    metadata: {
      remarks: {
        en: inferenceAlgorithmVersionData['metadata']['remarks']['en'],
        ja: inferenceAlgorithmVersionData['metadata']['remarks']['ja'],
      },
    },
    'released-at': inferenceAlgorithmVersionData['released-at'],
  }
}

// InferenceAlgorithmVersionDocument Documentに変換
export const convertDocumentWithDataCheck = (
  data: unknown
): InferenceAlgorithmVersionDocument | undefined => {
  const inferenceAlgorithmVersionData =
    data as InferenceAlgorithmVersionDocument
  const isInferenceAlgorithmVersionDocument = fireStoreTypeGuard(
    inferenceAlgorithmVersionData
  )
  if (!isInferenceAlgorithmVersionDocument) {
    return undefined
  }

  return {
    'algorithm-id': inferenceAlgorithmVersionData['algorithm-id'],
    'algorithm-version': {
      'display-name':
        inferenceAlgorithmVersionData['algorithm-version']['display-name'],
      major: inferenceAlgorithmVersionData['algorithm-version']['major'],
      minor: inferenceAlgorithmVersionData['algorithm-version']['minor'],
      patch: inferenceAlgorithmVersionData['algorithm-version']['patch'],
      'pre-release':
        inferenceAlgorithmVersionData['algorithm-version']['pre-release'],
    },
    'available-version': {
      'training-algorithm': {
        'lower-limit': {
          'display-name':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['display-name'],
          major:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['major'],
          minor:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['minor'],
          patch:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['patch'],
          'pre-release':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['lower-limit']['pre-release'],
        },
        'upper-limit': {
          'display-name':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['display-name'],
          major:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['major'],
          minor:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['minor'],
          patch:
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['patch'],
          'pre-release':
            inferenceAlgorithmVersionData['available-version'][
              'training-algorithm'
            ]['upper-limit']['pre-release'],
        },
      },
    },
    'commit-hash': inferenceAlgorithmVersionData['commit-hash'],
    'inference-algorithm-version':
      inferenceAlgorithmVersionData['inference-algorithm-version'],
    'inference-code-version': {
      'display-name':
        inferenceAlgorithmVersionData['inference-code-version']['display-name'],
      major: inferenceAlgorithmVersionData['inference-code-version']['major'],
      minor: inferenceAlgorithmVersionData['inference-code-version']['minor'],
      patch: inferenceAlgorithmVersionData['inference-code-version']['patch'],
    },
    metadata: {
      remarks: {
        en: inferenceAlgorithmVersionData['metadata']['remarks']['en'],
        ja: inferenceAlgorithmVersionData['metadata']['remarks']['ja'],
      },
    },
    'released-at': inferenceAlgorithmVersionData['released-at'],
  }
}
