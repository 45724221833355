import { Timestamp } from 'state/firebase'
import { ResourceLoadingState } from 'state/utils/types'
import { Version } from 'types/StateTypes'

export const ModelDetailActionType = {
  SET_CURRENT_MODEL_DETAIL: 'SET_CURRENT_MODEL_DETAIL',
  SET_MODEL_DETAIL_STATE: 'SET_MODEL_DETAIL_STATE',
  SET_IN_PROGRESS_FOR_MODEL_DETAIL: 'SET_IN_PROGRESS_FOR_MODEL_DETAIL',
  CLEAR_MODEL_DETAIL_STATE: 'CLEAR_MODEL_DETAIL_STATE',
  SET_TOAST_INFO_FOR_MODEL_DETAIL: 'SET_TOAST_INFO_FOR_MODEL_DETAIL',
  SET_TRAINED_MODEL_DISPLAY_CONDITION: 'SET_TRAINED_MODEL_DISPLAY_CONDITION',
  SET_TABLE_NEXT_PAGE_SUB_STATE: 'SET_TABLE_NEXT_PAGE_SUB_STATE',
} as const

interface Algorithm {
  algorithmId: string
  metadata: {
    name: {
      ja: string
      en: string
    }
  }
  trainingAlgorithmVersion: string
}

type EvaluationStatusKind = 'Ne' | 'Passed' | 'Failed'

type ModelKind = 'Generic' | 'Custom' | 'Specified'

interface BaseModel {
  baseModelId: string
  baseModelGroupId: string
  baseModelName: string
  isSharedUserGroupBaseModel: boolean
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface InheritedDatasetListItem {
  createdAt: Timestamp
  datasetId: string
  name: string
  generation: number
  remarks: string
}
export interface DatasetListItemData {
  'dataset-id': string
  generation: number
  'user-group-id': string
}
export interface CurrentDataset {
  datasetId: string
  datasetName: string
}

interface Setting {
  settingId: string
  settingName: string
}

interface MlPipeline {
  mlPipelineId: string
  mlPipelineName: string
}

export interface MediaLink {
  mediaName: string
  mediaUrl: string
  mediaSize: number
}

export interface TrainedModelDlLink {
  linkName: string
  mediaLinks: MediaLink[]
  totalMediaSize: number
}

export interface InheritedVersion {
  trainedModelGroupId: string
  trainedModelGroupVersion: Version
  trainedModelId: string
  userGroupId: string
}

/**
 * アルゴリズム固有の情報
 */
export interface Extended {
  /** 物体クラス分類情報 */
  objectClassification?: {
    /** クラスセット情報 */
    classSet: {
      /** クラスセットID */
      classSetId: string
      /** クラスセット名 */
      classSetName: string
      /** ユーザーグループID */
      userGroupId: string
    }
  }
}

export interface CurrentTrainedModelDetail {
  trainedModelGroupId: string
  trainedModelGroupName: string
  trainedModelVersion: string
  relatedTrainedModelList: RelatedTrainedModel[]
  modelListDisplayCondition: ModelListDisplayCondition
  trainedModelId: string
  trainedModelName: string
  trainedModelRemarks: string
  trainedModelKind: ModelKind
  evaluationStatus: EvaluationStatusKind
  baseModel: BaseModel
  inheritedVersion?: InheritedVersion
  datasetList: CurrentDataset[]
  inheritedDatasetList: InheritedDatasetListItem[]
  setting: Setting
  mlPipeline: MlPipeline
  trainedModelDlLinks: TrainedModelDlLink[]
  trainingAlgorithm: Algorithm
  createdAt?: Timestamp
  createdUserName: AccountName | string
  extended?: Extended
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface ModelListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface RelatedTrainedModel {
  trainedModelId: string
  trainedModelVersion: Version
  trainedModelName: string
  inheritedVersion?: Version
  transactionStatus?: 'trained' | 'built' | 'transfered' // TODO: v0.3.0ではN/Aのためundefinedを許容する
}

export interface ModelDetailState {
  trainedModelDlLinkSubState: ResourceLoadingState
  trainedModelDataState: ResourceLoadingState
}

export interface GetModelsFilesResponse {
  data: {
    items: TrainedModelDlLink[]
  }
}

export interface ModelDetailDomainData {
  currentTrainedModelDetail: CurrentTrainedModelDetail
}

export interface ModelDetailAppState {
  modelDetailState: ModelDetailState
  toastInfo: ToastInfo | undefined
  inProgress: boolean
  tableNextPageSubState: 'Enable' | 'Unable'
}

export interface ModelDetail {
  domainData: ModelDetailDomainData
  appState: ModelDetailAppState
}
