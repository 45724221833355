import { Timestamp } from 'state/firebase'
import { InferenceResultV1 } from 'state/utils'

export const RobotDataAnalysisExtractedImageDetailActionType = {
  SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_INFERENCE_RESULT_DISPLAY_CONDITION_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_INFERENCE_RESULT_DISPLAY_CONDITION_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_INFERENCE_RESULTS_V1_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_INFERENCE_RESULTS_V1_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_INFERENCE_RESULTS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_INFERENCE_RESULTS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_MODEL_GROUP_LIST_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_MODEL_GROUP_LIST_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_EXECUTION_DATA_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_EXECUTION_DATA_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_COUNT_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_COUNT_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  SET_ENABLES_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
    'SET_ENABLES_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
  CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL_STATE:
    'CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL_STATE',
} as const

export interface Model {
  trainedModelGroupId: string
  trainedModelName: string
  trainedModelVersion: string
  trainedModelId: string
  userGroupId: string
}

export interface InferenceResultDisplayCondition {
  mask: boolean
  bbox: boolean
  label: boolean
  selectedIds: string[]
}

export interface InferenceResult {
  robotExecutionDataId: string
  output?: {
    height: number
    width: number
    results: InferenceResultV1[]
  }
}

export interface InferenceResultResponse {
  status: string
  result?: {
    inferenceResults: InferenceResult[]
  }
}

export interface RecognizedData {
  executionDataId: string
  model: Model
  labels: {
    id: string
    score: number
  }[]
}

export interface ExecutionData {
  trainingDataFileName: string
  trainingDataId: string
  executionStatus: string
  executedAt: Timestamp
  robotId: string
  confirmed: boolean
  url: string
  recognizedData: RecognizedData[]
}

export interface RobotDataAnalysisExtractedImageDetailDomainData {
  executionData?: ExecutionData
  inferenceResults: InferenceResult[]
  inferenceResultDisplayCondition: InferenceResultDisplayCondition
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface RobotDataAnalysisExtractedImageDetailAppState {
  inProgress: boolean
  toastInfo: ToastInfo | undefined
}

export interface RobotDataAnalysisExtractedImageDetailState {
  domainData: RobotDataAnalysisExtractedImageDetailDomainData
  appState: RobotDataAnalysisExtractedImageDetailAppState
}
