import { DatasetListActions } from './actions'
import { DatasetListActionType, DatasetInfoList } from './types'

const initialState: DatasetInfoList = {
  domainData: {
    currentDatasetList: [],
    datasetListDisplayCondition: {
      searchValue: '',
      sortKey: 'created-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedAlgorithmId: '',
      createdAt: {
        from: {
          date: '',
          time: '',
          enabled: false,
        },
        to: {
          date: '',
          time: '',
          enabled: false,
        },
      },
      generatedFor: 'Training',
      datasetTemplateId: undefined,
      createdBy: undefined,
    },
    thumbnails: {},
  },
  appState: {
    inProgress: false,
  },
}

export const DatasetListReducer = (
  state: DatasetInfoList = initialState,
  action: DatasetListActions
): DatasetInfoList => {
  switch (action.type) {
    case DatasetListActionType.SET_DATASET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetList: action.payload.datasetList,
        },
      }
    case DatasetListActionType.CLEAR_DATASET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetList: [],
        },
      }
    case DatasetListActionType.SET_DATASET_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetListDisplayCondition:
            action.payload.datasetListDisplayCondition,
        },
      }
    case DatasetListActionType.SET_THUMBNAILS_FOR_DATASET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          thumbnails: {
            ...state.domainData.thumbnails,
            ...action.payload.thumbnails,
          },
        },
      }
    case DatasetListActionType.SET_IN_PROGRESS_FOR_DATASET_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case DatasetListActionType.CLEAR_DATASET_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
