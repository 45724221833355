import { SettingDetailAction } from './actions'
import { SettingDetailActionType, SettingDetail } from './types'
import { Timestamp } from 'firebase/firestore'

const initialState: SettingDetail = {
  domainData: {
    currentSettingDetail: {
      settingId: '',
      settingName: '',
      algorithmId: '',
      settingDlLinks: [],
      settingFormat: {
        settingFormatKind: '',
        settingFormatVersion: {
          displayName: '',
          major: 0,
          minor: 0,
          patch: 0,
        },
      },
      datasetTemplate: {
        datasetTemplateName: '',
      },
      remarks: '',
      settingGroup: {
        settingGroupId: '',
        settingGroupName: '',
        settingGroupVersion: {
          displayName: '',
          major: 0,
          minor: 0,
          patch: 0,
        },
      },
      trainedModelGroup: {
        trainedModelGroupId: '',
      },
      createdAt: Timestamp.fromMillis(0),
      createdBy: '',
    },
  },
  appState: {
    inProgress: false,
    settingDetailState: {
      settingDlLinkSubState: 'BeforeLoading',
      settingDataState: 'BeforeLoading',
    },
    toastInfo: undefined,
  },
}

export const SettingDetailReducer = (
  state: SettingDetail = initialState,
  action: SettingDetailAction
): SettingDetail => {
  switch (action.type) {
    case SettingDetailActionType.SET_CURRENT_SETTING_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSettingDetail: action.payload.currentSettingDetail,
        },
      }
    case SettingDetailActionType.SET_IN_PROGRESS_FOR_SETTING_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case SettingDetailActionType.SET_TOAST_INFO_FOR_SETTING_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case SettingDetailActionType.SET_MEDIA_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          settingDetailState: action.payload.settingDetailState,
        },
      }
    case SettingDetailActionType.CLEAR_SETTING_DETAIL_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
