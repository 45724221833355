import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  ModelInfo,
  AdminModelListActionType,
  ModelListDisplayCondition,
} from './types'

export type AdminModelListActions = ActionsUnion<typeof adminModelListActions>

export const adminModelListActions = {
  setModelList: (
    modelList: ModelInfo[]
  ): ActionWithPayload<'SET_MODEL_LIST', { modelList: ModelInfo[] }> =>
    CreateAction(AdminModelListActionType.SET_MODEL_LIST, {
      modelList,
    }),
  clearModelList: (): Action<'CLEAR_MODEL_LIST'> =>
    CreateAction(AdminModelListActionType.CLEAR_MODEL_LIST),
  setListDisplayCondition: (
    modelListDisplayCondition: ModelListDisplayCondition
  ): ActionWithPayload<
    'SET_MODEL_LIST_DISPLAY_CONDITION',
    { modelListDisplayCondition: ModelListDisplayCondition }
  > =>
    CreateAction(AdminModelListActionType.SET_MODEL_LIST_DISPLAY_CONDITION, {
      modelListDisplayCondition,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MODEL_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(AdminModelListActionType.SET_IN_PROGRESS_FOR_MODEL_LIST, {
      inProgress,
    }),
  clearModelListState: (): Action<'CLEAR_MODEL_LIST_STATE'> =>
    CreateAction(AdminModelListActionType.CLEAR_MODEL_LIST_STATE),
}
