import { HttpsCallableResult } from 'firebase/functions'
import {
  AnnotationSetKindAll,
  AnnotationTrainKind,
  DatasetTemplate,
} from 'state/app/domainData/types'
import { Timestamp } from 'state/firebase'
import { TrainingData } from 'state/utils/types'

export const CreateDatasetActionType = {
  SET_TRAINING_ALGORITHM_DISPLAY_CONDITION:
    'SET_TRAINING_ALGORITHM_DISPLAY_CONDITION',
  COMPLETE_IMAGE_FILE_SAVE: 'COMPLETE_IMAGE_FILE_SAVE',
  SET_DATASET_NAME: 'SET_DATASET_NAME',
  SET_DATSET_REMARKS: 'SET_DATASET_REMARKS',
  UPLOAD_COMPLETED: 'UPLOAD_COMPLETED',
  CREATE_DATASET_SET_DATASETID: 'CREATE_DATASET_SET_DATASETID',
  CREATE_DATASET_UPLOADING: 'CREATE_DATASET_UPLOADING',
  CREATE_DATASET_DOCUMENT_ERROR: 'CREATE_DATASET_DOCUMENT_ERROR',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  UPLOAD_ERROR_ALL: 'UPLOAD_ERROR_ALL',
  SET_FILE_SIZE_ERROR: 'SET_FILE_SIZE_ERROR',
  CLEAR_CREATE_DATASET_STATE: 'CLEAR_CREATE_DATASET_STATE',
  ADD_ANNOTATION_FILE: 'ADD_ANNOTATION_FILE',
  SET_ANNOTATION_SUB_STATE: 'SET_ANNOTATION_SUB_STATE',
  SET_DATASET_STATE: 'SET_DATASET_STATE',
  CLEAR_METADATA_STEP_STATE: 'CLEAR_METADATA_STEP_STATE',
  CLEAR_UPLOAD_STEP_STATE: 'CLEAR_UPLOAD_STEP_STATE',
  SET_SELECTED_ALGORITHM_ID_FOR_CREATE_DATASET:
    'SET_SELECTED_ALGORITHM_ID_FOR_CREATE_DATASET',
  SET_SELECTED_ALGORITHM_VERSION_FOR_CREATE_DATASET:
    'SET_SELECTED_ALGORITHM_VERSION_FOR_CREATE_DATASET',
  SET_AVAILABLE_DATASET_TEMPLATES: 'SET_AVAILABLE_DATASET_TEMPLATES',
  SET_SELECTED_DATASET_TEMPLATE_FOR_CREATE_DATASET:
    'SET_SELECTED_DATASET_TEMPLATE_FOR_CREATE_DATASET',
  SET_SELECTED_ANNOTATION_FORMAT: 'SET_SELECTED_ANNOTATION_FORMAT',
  SET_ALGORITHM_SUB_STATE: 'SET_ALGORITHM_SUB_STATE',
  SET_DATASET_TEMPLATE_SUB_STATE: 'SET_DATASET_TEMPLATE_SUB_STATE',
  CLEAR_CLASS_SET_STEP_STATE: 'CLEAR_CLASS_SET_STEP_STATE',
  CLEAR_DATASET_TEMPLATE_STEP_STATE: 'CLEAR_DATASET_TEMPLATE_STEP_STATE',
  CLEAR_ANNOTATION_STEP_STATE: 'CLEAR_ANNOTATION_STEP_STATE',
  CLEAR_INPUT_STEP_STATE: 'CLEAR_INPUT_STEP_STATE',
  SET_IN_PROGRESS: 'SET_IN_PROGRESS',
  SET_SELECTED_CLASS_SET_FOR_CREATE_DATASET:
    'SET_SELECTED_CLASS_SET_FOR_CREATE_DATASET',
  CLEAR_CLASS_SET_LIST_FOR_CREATE_DATASET:
    'CLEAR_CLASS_SET_LIST_FOR_CREATE_DATASET',
  SET_CLASS_SET_LIST_FOR_CREATE_DATASET:
    'SET_CLASS_SET_LIST_FOR_CREATE_DATASET',
  CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET:
    'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET',
  SET_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET:
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET',
  SET_CLASS_SET_SUB_STATE_FOR_CREATE_DATASET:
    'SET_CLASS_SET_SUB_STATE_FOR_CREATE_DATASET',
  SET_TOAST_INFO_FOR_CREATE_DATASET: 'SET_TOAST_INFO_FOR_CREATE_DATASET',
} as const

export type SelectType = 'unselected' | 'selected'
export type InputRequireType = 'emptyRequired' | 'inputRequired'
export type AnnotationStatus =
  | 'beforeUpload'
  | 'uploading'
  | 'completed'
  | 'uploadError'
  | 'validateError'

export type FileStatus =
  | 'unselected'
  | 'selected'
  | 'uploading'
  | 'completed'
  | 'checkError'
  | 'uploadError'

export type GeneratedFor = 'Training' | 'Inference'
export type DataKind = 'Image' | 'Voice'

export interface AddedAnnotationSetList {
  annotationSetKind: AnnotationSetKindAll
  conditions?: {
    trainKind?: AnnotationTrainKind
  }
  annotationMetadata: {
    name: string
  }
  annotationId: string
  groupedData: {
    trainingDataList: TrainingData[]
  }
  uploadProgress: number
  annotationStatus: AnnotationStatus
}

export interface SelectedAnnotationFormat {
  annotationFormatId: string
  annotationFormatKind: string
  annotationFormatVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
}

export type DatasetState =
  | 'AlgorithmState'
  | 'DatasetTemplateState'
  | 'AnnotationState'
  | 'InputDataState'
  | 'MetadataState'
  | 'UploadState'

export type UploadSubState =
  | 'beforeUpload'
  | 'uploading'
  | 'completed'
  | 'uploadError'
  | 'documentError'

/**
 * クラスセット選択の表示条件
 */
export interface ClassSetDisplayCondition {
  /** 検索文字列 */
  searchValue: string
  /** ソートする項目のキー名 */
  sortKey: string
  /** ソート順 */
  sortOrder: 'asc' | 'desc'
  /** 表示データ数 */
  displayNumber: number
  /** 表示ページ番号 */
  pageNumber: number
  /** データ種別 */
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

/** クラスセット */
export interface ClassSet {
  /** クラスセットID */
  classSetId: string
  /** クラスセット名 */
  classSetName: string
  /** クラスセット備考 */
  classSetRemarks: string
  /** クラス名リスト */
  classList: string[]
  /** 登録日時 */
  createdAt: Timestamp
  /** ユーザグループ ID */
  userGroupId: string
}

export interface AlgorithmDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
}

export interface DatasetSubState {
  algorithmSubState: SelectType
  datasetTemplateSubState: SelectType
  annotationSubState: SelectType
  inputDataSubState: SelectType
  classSetSubState: SelectType
  metadataSubState: InputRequireType
  uploadSubState: UploadSubState
}

export interface CreateDatasetDomainData {
  generatedFor: GeneratedFor
  datasetMetadata: { name: string; remarks: string }
  selectedAlgorithmId?: string
  selectedAlgorithmVersion?: string
  availableDatasetTemplates: DatasetTemplate[]
  selectedAnnotationFormat?: SelectedAnnotationFormat
  selectedDatasetTemplate?: DatasetTemplate
  addedAnnotationSetList: AddedAnnotationSetList[]
  createdDatasetId: string
  trainingAlgorithmVersionDisplayCondition: AlgorithmDisplayCondition // 学習アルゴリズムの表示条件
  classSets: ClassSet[]
  selectedClassSet?: ClassSet
  /** クラスセット選択の表示条件 */
  classSetDisplayCondition: ClassSetDisplayCondition
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface CreateDatasetAppState {
  datasetState: DatasetState
  datasetSubState: DatasetSubState
  inProgress: boolean
  toastInfo?: ToastInfo
}

export interface GroupedImage {
  trainingDataId: string
  fileName: string
  fileType: string
  dataKind: DataKind
}

export interface AnnotationSetListRequestType {
  annotationSetKind: AnnotationSetKindAll
  annotationData?: {
    annotationId: string
    metadata: {
      name: string
    }
  }
  groupedData: {
    groupedImageList: GroupedImage[]
  }
}

export interface CreateDatasetRequestType {
  algorithmId: string
  metadata: {
    name: string
    remarks: string
  }
  annotationSetList: AnnotationSetListRequestType[]
  annotationFormatId: string
  annotationFormatVersion?: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  generatedFor: string
  datasetTemplateId: string
  extended?: {
    objectClassification?: {
      classSet: {
        classSetId: string
        userGroupId: string
      }
    }
  }
}

export interface CreateDatasetState {
  domainData: CreateDatasetDomainData
  appState: CreateDatasetAppState
}

interface ObjectDetectionAnnotation {
  id: number
  image_id: number
  category_id: number
  area: number
  segmentation: number[] | number[][]
  bbox: number[]
  iscrowd: number
  width?: number
  height?: number
}
export interface jsonCocoObjectType {
  info: {
    year: number
    version: string
    description: string
    contributor: string
    url: string
    date_created: Date
  }
  licenses: {
    id: number
    name: string
    url: string
  }[]
  images: {
    id: number
    width: number
    height: number
    file_name: string
    license: number
    flickr_url: string
    coco_url: string
    date_captured: Date
    'data-type'?: string
  }[]
  annotations: ObjectDetectionAnnotation[]
  categories: {
    id: number
    name: string
    supercategory: string
  }[]
  segment_info?: []
}
// function の戻り値
export interface functionsGroupedImage {
  fileName: string
  groupedImageId: string
}
export interface functionsAnnotationSets {
  annotationSetId: string
  annotationData: {
    metadata: { name: string }
    annotationId: string
    formatType: string
  }
  groupedData: {
    groupedDataId: string
    groupedImageList: functionsGroupedImage[]
  }
}

export interface CreateDatasetFirestoreResult extends HttpsCallableResult {
  readonly data: {
    datasetId: string
  }
}

export interface CreateAnnotationSetFirestoreResult
  extends HttpsCallableResult {
  readonly data: {
    annotationSetIdList: string[]
  }
}
