import { Timestamp } from 'state/firebase'
import { ResourceLoadingState } from 'state/utils/types'
import { I18nString, Version } from 'types/StateTypes'

export const ModelGroupDetailActionType = {
  SET_CURRENT_MODEL_GROUP_DETAIL: 'SET_CURRENT_MODEL_GROUP_DETAIL',
  SET_IN_PROGRESS_FOR_MODEL_GROUP_DETAIL:
    'SET_IN_PROGRESS_FOR_MODEL_GROUP_DETAIL',
  CLEAR_MODEL_GROUP_DETAIL_STATE: 'CLEAR_MODEL_GROUP_DETAIL_STATE',
  SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_MODEL_GROUP_DETAIL:
    'SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_MODEL_GROUP_DETAIL',
  SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_MODEL_GROUP_DETAIL:
    'SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_MODEL_GROUP_DETAIL',
  SET_TOAST_INFO_FOR_MODEL_GROUP_DETAIL:
    'SET_TOAST_INFO_FOR_MODEL_GROUP_DETAIL',
  SET_MODEL_GROUP_DATA_STATE: 'SET_MODEL_GROUP_DATA_STATE',
  SET_ICON_URL: 'SET_ICON_URL',
  SET_SETTING_LIST_DISPLAY_CONDITION: 'SET_SETTING_LIST_DISPLAY_CONDITION',
  SET_SETTING_LIST_FOR_MODEL_GROUP_DETAIL:
    'SET_SETTING_LIST_FOR_MODEL_GROUP_DETAIL',
  SET_IN_PROGRESS_FOR_GETTING_SETTING_LIST:
    'SET_IN_PROGRESS_FOR_GETTING_SETTING_LIST',
} as const

interface Algorithm {
  algorithmId: string
  algorithmName: string
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

/**
 * アルゴリズム固有の情報
 */
export interface Extended {
  /** 物体クラス分類情報 */
  objectClassification?: {
    /** クラスセット情報 */
    classSet: {
      /** クラスセットID */
      classSetId: string
      /** クラスセット名 */
      classSetName: string
      /** ユーザーグループID */
      userGroupId: string
    }
  }
}

export interface DatasetTemplate {
  datasetTemplateId: string
  metadata: {
    name: I18nString
  }
}

export interface Setting {
  settingId: string
  name: string
  settingGroupVersion: Version
  datasetTemplate: DatasetTemplate
  remarks: string
  createdAt: Timestamp
}

export interface SettingListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface Constraint {
  setting: {
    settingGroupId: string
    userGroupId: string
  }
}

export interface CurrentTrainedModelGroupDetail {
  trainedModelGroupId: string
  trainedModelGroupName: string
  relatedTrainedModelList: RelatedTrainedModel[]
  trainedModelListDisplayCondition: TrainedModelListDisplayCondition
  remarks: string
  trainingAlgorithm: Algorithm
  createdAt?: Timestamp
  createdBy: AccountName
  overview: string
  iconName: string
  iconUrl: string
  iconFileType: string
  constraint?: Constraint
  extended?: Extended
}
export interface TrainedModelListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface RelatedTrainedModel {
  trainedModelId: string
  trainedModelVersion: Version
  trainedModelName: string
  inheritedVersion?: Version
  transactionStatus?: 'trained' | 'built' | 'transfered' // TODO: v0.3.0ではN/Aのためundefinedを許容する
}

export interface ModelGroupDetailDomainData {
  currentTrainedModelGroupDetail: CurrentTrainedModelGroupDetail
  settingList: Setting[]
  settingListDisplayCondition: SettingListDisplayCondition
}

export interface ModelGroupDetailAppState {
  trainedModelGroupDataState: ResourceLoadingState
  inProgress: boolean
  isInProgressForGettingSettingList: boolean
  tableNextPageSubState: 'Enable' | 'Unable'
  toastInfo?: ToastInfo // トーストの表示する情報
}

export interface ModelGroupDetail {
  domainData: ModelGroupDetailDomainData
  appState: ModelGroupDetailAppState
}
