import { HttpsCallableResult } from 'firebase/functions'
import { Dispatch } from 'redux'
import { State } from 'state/store'
import { ToastInfo } from 'state/utils'
import { featureDataGroupEntryActions } from './'

import { FeatureDataGroupEntryApi } from './apis'
import { doc, getDoc } from 'firebase/firestore'
import { getUserGroupsCollection } from 'state/firebase'

export const featureDataGroupEntryOperations = {
  createFeatureDataGroup:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupEntryActions.setInProgress(true))
        // アルゴリズムID
        const algorithmId =
          getState().pages.featureDataGroupEntryState.domainData
            .selectedAlgorithm ?? ''
        // 特徴量データグループ名
        const name =
          getState().pages.featureDataGroupEntryState.domainData
            .featureDataGroupMetaData.featureDataGroupName
        // 備考
        const remarks =
          getState().pages.featureDataGroupEntryState.domainData
            .featureDataGroupMetaData.remarks ?? ''
        // 概要
        const overview =
          getState().pages.featureDataGroupEntryState.domainData
            .featureDataGroupMetaData.overView ?? ''
        // アイコン
        const featureDataGroupIcon =
          getState().pages.featureDataGroupEntryState.domainData
            .featureDataGroupMetaData.featureDataGroupIcon
        // アイコンのファイル名
        const iconName = featureDataGroupIcon ? featureDataGroupIcon.name : ''
        // アイコンのファイルタイプ
        const iconFileType = featureDataGroupIcon
          ? featureDataGroupIcon.type
          : ''

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        // ドキュメントIDを取得
        const result = (await FeatureDataGroupEntryApi.getDocumentId(
          userGroupId,
          'feature-data-groups'
        )) as HttpsCallableResult<{ documentId: string }>

        // 共有データからの判定
        const userGroupDoc = (
          await getDoc(doc(getUserGroupsCollection(), userGroupId))
        ).data()
        const isSharedUserGroup = !!(
          userGroupDoc && userGroupDoc['access-control']?.['is-shared'] === true
        )

        // アイコンがある場合GCSにアップロード
        if (featureDataGroupIcon) {
          const response =
            (await FeatureDataGroupEntryApi.getFileUploadSignedUrl(
              result.data.documentId,
              iconName
            )) as HttpsCallableResult<{ url: string }>
          try {
            // アップロード処理
            // 成功時にドキュメントを作成する
            // 失敗時にアップロードしたiconを削除する
            await FeatureDataGroupEntryApi.executeFileUpload(
              response.data.url,
              featureDataGroupIcon
            )
            try {
              // ドキュメント作成
              await FeatureDataGroupEntryApi.createFeatureDataGroup(
                result.data.documentId,
                algorithmId,
                name,
                remarks,
                overview,
                iconName,
                iconFileType,
                isSharedUserGroup
              )
              dispatch(
                featureDataGroupEntryActions.setGeneratedFeatureDataGroupId(
                  result.data.documentId
                )
              )
              dispatch(
                featureDataGroupEntryActions.setExecuteSubState('Executed')
              )
            } catch (error) {
              // ドキュメント作成失敗時処理
              console.error(error)
              const toastInfo: ToastInfo = {
                type: 'error',
                title: 'ドキュメントの作成に失敗しました。',
                targets: [],
              }
              dispatch(featureDataGroupEntryActions.setToastInfo(toastInfo))
              // アップロードしたiconを削除する
              const deleteUrl =
                (await FeatureDataGroupEntryApi.getDeleteSignedUrl(
                  result.data.documentId,
                  iconName
                )) as HttpsCallableResult<{ url: string }>
              await FeatureDataGroupEntryApi.deleteUploadedIcon(
                deleteUrl.data.url
              )
            }
          } catch (error) {
            // アップロード失敗処理
            console.error(error)
            const toastInfo: ToastInfo = {
              type: 'error',
              title: 'アップロードに失敗しました。',
              targets: [],
            }
            dispatch(featureDataGroupEntryActions.setToastInfo(toastInfo))
          }
        } else {
          try {
            // iconのアップロードがない場合
            // ドキュメント作成
            await FeatureDataGroupEntryApi.createFeatureDataGroup(
              result.data.documentId,
              algorithmId,
              name,
              remarks,
              overview,
              iconName,
              undefined,
              isSharedUserGroup
            )
            dispatch(
              featureDataGroupEntryActions.setGeneratedFeatureDataGroupId(
                result.data.documentId
              )
            )
            dispatch(
              featureDataGroupEntryActions.setExecuteSubState('Executed')
            )
          } catch (error) {
            // ドキュメント作成失敗時処理
            console.error(error)
            const toastInfo: ToastInfo = {
              type: 'error',
              title: '特徴量データグループの作成に失敗しました。',
              targets: [],
            }
            dispatch(featureDataGroupEntryActions.setToastInfo(toastInfo))
          }
        }
      } catch (error) {
        console.error(error)
        const toastInfo: ToastInfo = {
          type: 'error',
          title: '特徴量データグループの作成に失敗しました。',
          targets: [],
        }
        dispatch(featureDataGroupEntryActions.setToastInfo(toastInfo))
      } finally {
        dispatch(featureDataGroupEntryActions.setInProgress(false))
      }
    },
}
