import React, { useEffect, useMemo } from 'react'
import { FileRejection } from 'react-dropzone'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import { State } from 'state/store'
import {
  CreateImageSetAction,
  createImageSetOperations,
  ImageSetStateKindArray,
  createImageSetActions,
} from 'state/ducks/createImageSet'

import { isUndefined } from 'utils/typeguard'
import {
  StepperLayout,
  FileSelectableDropzone,
  MetadataInput,
  Toast,
  showToast,
  ErrorMessage,
  FileListViewer,
  GlobalLoading,
  CommonCompleteDialog,
  CustomTrainingPageParagraph,
} from 'views/components'

// How to simplify the description of react-redux.
const mapStateToProps = (state: State) => ({
  ...state.pages.currentCreateImageSetState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, CreateImageSetAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) =>
    dispatch(createImageSetOperations.nextStep(currentStep)),
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) =>
    dispatch(createImageSetOperations.prevStep(currentStep)),
  /** 画像セットファイル追加 */
  setInputFiles: (files: File[]) =>
    dispatch(createImageSetOperations.setInputFiles(files)),
  /** 画像をFireStore、GCSにアップロード */
  uploadImageSet: (files: File[]) =>
    dispatch(createImageSetOperations.uploadImageSet(files)),
  /** 入力したメタデータの備考をセットする */
  setMetaDataRemarks: (remarks: string) =>
    dispatch(createImageSetOperations.setImageSetMetadataRemarks(remarks)),
  /** 入力したメタデータの名前をセットする */
  setMetaDataName: (name: string) =>
    dispatch(createImageSetOperations.setImageSetMetadataName(name)),
  /** 入力内容をすべてクリア */
  clearCreateImageSetState: () =>
    dispatch(createImageSetOperations.clearCreateImageSetState()),
  /** モデルグループ作成のアルゴリズムをセット */
  setSelectedAlgorithm: (selectedAlgorithm: string) =>
    dispatch(createImageSetActions.setSelectedAlgorithm(selectedAlgorithm)),
  /** アルゴリズム選択状態 */
  setAlgorithmSubState: (algorithmSubState: 'Unselected' | 'Selected') =>
    dispatch(createImageSetActions.setAlgorithmSubState(algorithmSubState)),
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps &
  DispatchProps &
  RouteComponentProps &
  RouteComponentProps & {
    mode?: 'page' | 'dialog'
    handleCloseDialog?: (id?: string) => void
  }

const useStyles = makeStyles()((theme) => ({
  text: {
    height: '64px',
    [theme.breakpoints.down('xs')]: {
      height: '56px',
    },
    margin: 0,
  },
  pageTitle: { width: '98%', margin: theme.spacing(1) },
  stepperDiv: { margin: theme.spacing(3) },
  cancelLink: {
    margin: theme.spacing(1),
    float: 'right',
    color: 'red',
  },
  annotationListDiv: {
    marginTop: theme.spacing(3),
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  algorithmSelectBox: {
    width: '100%',
  },
}))

const CreateImageSet: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()

  useEffect(() => {
    return () => {
      props.clearCreateImageSetState()
    }
  }, [])

  useEffect(() => {
    props.setSelectedAlgorithm(props.algorithms[0].algorithmId)
  }, [])

  /** アルゴリズム選択時に状態を変更 */
  useEffect(() => {
    if (props.domainData.selectedAlgorithm) {
      props.setAlgorithmSubState('Selected')
    } else {
      props.setAlgorithmSubState('Unselected')
    }
  }, [props.domainData.selectedAlgorithm])

  const AnnotationSetErrormessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    props.domainData.annotationSet.groupedData.trainingDataList.forEach(
      (groupedData) => {
        if (groupedData.fileStatus === 'uploadError') {
          errorMessages.push(
            'データの送信に失敗しました。' + `${groupedData.file?.name}`
          )
        }
      }
    )
    if (errorMessages.length <= 0) {
      return <></>
    } else {
      return <ErrorMessage title='' targets={errorMessages} />
    }
  }

  // カレントのステップ 0: インプットデータ 1: メタデータ 2: 送信
  const currentStep = useMemo(
    () => ImageSetStateKindArray.indexOf(props.appState.imageSetState),
    [props.appState.imageSetState]
  )
  const stepNames = ['アルゴリズム', 'インプットデータ', 'メタデータ', '送信']

  const showErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='datasetsErrorToastFileName'>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  const imageSetErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  /** メタデータの名前変更 */
  const changeMetadataName = (name: string) => {
    props.setMetaDataName(name)
  }
  /** メタデータの備考変更 */
  const changeMetadataRemarks = (remarks: string) => {
    props.setMetaDataRemarks(remarks)
  }

  const getStepContent = (props: Props): JSX.Element => {
    const datasetMetadataInputHelper = (metadataSubState: string) =>
      metadataSubState === 'emptyRequired' ? '入力してください。' : ''

    switch (props.appState.imageSetState) {
      case 'AlgorithmState':
        if (isUndefined(props.algorithms)) {
          return <></>
        }
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelGroupEntry'>アルゴリズム</InputLabel>
                <Select
                  data-testid='select'
                  labelId='modelGroupEntry-label'
                  id='modelGroupEntry-outlined'
                  defaultValue={props.algorithms[0].algorithmId}
                  value={props.domainData.selectedAlgorithm}
                  onChange={(e: SelectChangeEvent<string>) =>
                    props.setSelectedAlgorithm(e.target.value as string)
                  }
                  label='Select Algorithm'
                >
                  {props.algorithms.map((algorithm) => (
                    <MenuItem
                      data-testid={algorithm.algorithmId}
                      value={algorithm.algorithmId}
                      key={algorithm.algorithmId}
                    >
                      {algorithm.metadata.name.ja}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'InputFileState':
        return (
          <div>
            <div className={classes.stepTitle}>
              <Typography>インプットデータを選択します。 </Typography>
            </div>
            <FileSelectableDropzone
              acceptFileOperation={(files: File[]) =>
                props.setInputFiles(files)
              }
              rejectedFileOperation={(fileRejections: FileRejection[]) =>
                imageSetErrors(fileRejections)
              }
              fileType='image/*'
              maxSize={5 * 1000 * 1000}
              data-testid='annotationFileDropZone'
            />
          </div>
        )
      case 'MetadataState':
        return (
          <div>
            <div className={classes.stepTitle}>
              <Typography>メタデータを入力します。</Typography>
            </div>
            <MetadataInput
              nameProps={{
                label: '画像セット名',
                value: props.domainData.imageSetMetaData.name,
                variant: 'outlined',
                readOnly: false,
                onChange: (event) => {
                  changeMetadataName(event.target.value)
                },
              }}
              remarksProps={{
                label: '備考',
                value: props.domainData.imageSetMetaData.remarks,
                variant: 'outlined',
                readOnly: false,
                onChange: (event) => {
                  changeMetadataRemarks(event.target.value)
                },
                rowNum: 4,
              }}
              helperText={datasetMetadataInputHelper('emptyRequired')}
              data-testid='image-sets-input'
            />
          </div>
        )
      case 'UploadState':
        return (
          <>
            <div className={classes.stepTitle}>
              <Typography>データを確認してサーバに送信します。</Typography>
            </div>
            <MetadataInput
              nameProps={{
                label: '画像セット名',
                value: props.domainData.imageSetMetaData.name,
                readOnly: true,
                variant: 'standard',
              }}
              remarksProps={{
                label: '備考',
                value: props.domainData.imageSetMetaData.remarks,
                readOnly: true,
                variant: 'standard',
                rowNum: 4,
              }}
              helperText=''
              data-testid='image-sets-input'
            />
            <div className={classes.errorField}>
              <AnnotationSetErrormessage {...props} />
            </div>
            <CommonCompleteDialog
              open={
                props.appState.imageSetState === 'UploadState' &&
                !isUndefined(props.domainData.groupedDataId)
              }
              value={props.domainData.groupedDataId ?? ''}
              handleClose={() =>
                props.handleCloseDialog &&
                props.handleCloseDialog(props.domainData.groupedDataId)
              }
              label={'画像セットID'}
              dialogText={'正常にデータを登録しました。'}
              data-testid={'imageSetInputPreview'}
            />
          </>
        )
      default:
        return (
          <div>
            <Typography>Unknown</Typography>
          </div>
        )
    }
  }

  const getNextButtonProps = () => {
    const label =
      props.appState.imageSetState !== 'UploadState' ? '次へ' : '送信'
    let disabled = false
    switch (props.appState.imageSetState) {
      case 'AlgorithmState':
        disabled = isUndefined(props.domainData.selectedAlgorithm)
        break
      case 'InputFileState':
        disabled = props.domainData.imageSetFiles.length <= 0
        break
      case 'MetadataState':
        disabled = props.domainData.imageSetMetaData.name
          ? props.domainData.imageSetMetaData.name.length <= 0
          : true
        break
      default:
        disabled = false
    }

    const onClick = () => {
      if (currentStep >= ImageSetStateKindArray.length - 1) {
        if (
          props.domainData.imageSetFiles &&
          props.domainData.imageSetMetaData
        ) {
          props.uploadImageSet(props.domainData.imageSetFiles)
        }
      } else {
        props.nextStep(currentStep)
      }
    }

    return {
      label,
      disabled,
      onClick,
    }
  }
  const getPrevButtonProps = () => {
    const label = '戻る'
    let disabled = false
    switch (props.appState.imageSetState) {
      case 'AlgorithmState':
        disabled = true
        break
      default:
        disabled = false
    }

    const onClick = () => {
      props.prevStep(currentStep)
    }

    return {
      label,
      disabled,
      onClick,
    }
  }

  const getInformationContent = (props: Props): JSX.Element => {
    switch (props.appState.imageSetState) {
      case 'InputFileState':
        return (
          <FileListViewer
            listLabel='インプットデータ'
            files={props.domainData.imageSetFiles}
          />
        )
      case 'UploadState':
        return (
          <FileListViewer
            listLabel='インプットデータ'
            files={props.domainData.imageSetFiles}
          />
        )
      default:
        return <p />
    }
  }

  return (
    <div>
      <Toast containerOptions={{ limit: 20 }}>
        <Button
          onClick={() => props.handleCloseDialog && props.handleCloseDialog()}
          className={classes.cancelLink}
          data-testid='datesetsButtonCancel'
        >
          <Typography>キャンセル</Typography>
        </Button>
        <Typography
          variant='h5'
          data-testid='datasets-title'
          className={classes.pageTitle}
        >
          画像セット
        </Typography>
        <div className={classes.stepperDiv}>
          <StepperLayout
            stepProps={stepNames.map((stepName) => ({
              label: stepName,
              previousButtonProps: getPrevButtonProps(),
              nextButtonProps: getNextButtonProps(),
              stepContent: getStepContent(props),
              informationContent: getInformationContent(props),
            }))}
            activeStepIndex={currentStep}
            data-testid='image-sets'
          />
        </div>
      </Toast>
      <GlobalLoading open={props.appState.inProgress} />
    </div>
  )
}

export const CreateImageSetPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateImageSet))
