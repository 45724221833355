import { AdminModelListActions } from './actions'
import { AdminModelInfoList, AdminModelListActionType } from './types'

const initialState: AdminModelInfoList = {
  domainData: {
    currentModelList: [],
    modelListDisplayCondition: {
      searchValue: '',
      sortKey: 'modelGroupVersion',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      algorithmId: undefined,
      selectedUserGroupKind: 'UserGroup',
    },
  },
  appState: {
    inProgress: false,
  },
}

export const AdminModelListReducer = (
  state: AdminModelInfoList = initialState,
  action: AdminModelListActions
): AdminModelInfoList => {
  switch (action.type) {
    case AdminModelListActionType.SET_MODEL_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentModelList: action.payload.modelList,
        },
      }
    case AdminModelListActionType.CLEAR_MODEL_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentModelList: [],
        },
      }
    case AdminModelListActionType.SET_MODEL_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelListDisplayCondition: action.payload.modelListDisplayCondition,
        },
      }
    case AdminModelListActionType.SET_IN_PROGRESS_FOR_MODEL_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case AdminModelListActionType.CLEAR_MODEL_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
