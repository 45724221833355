import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { GetSignedUrlsFunctionType } from '../datasetDetail'

export const RobotDataAnalysisExtractedImageDetailApi = {
  getExecutionLogAnalysis: (params: {
    'training-data-id': string
    'user-group-id': string
  }): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'execution-log-analysis'
    )({
      ...params,
    }),
  // Cloud Function
  getSignedUrls: async (
    fileInfoList: { id: string; fileName: string }[],
    action: 'read' | 'delete',
    type: 'thumbnail' | 'original'
  ): Promise<{ [id: string]: string }> => {
    const getReadableSignedUrlRecordsFunction: GetSignedUrlsFunctionType =
      httpsCallable(
        functionsInstance,
        `${AidpCloudFunctionsUrl()}data-manipulation-${action}-${type}`
      )
    return (
      await getReadableSignedUrlRecordsFunction({
        fileInfoList: fileInfoList,
      })
    ).data
  },
  confirm: (params: {
    'training-data-id': string
    'user-group-id': string
  }): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'checked-robot-data-update'
    )({
      ...params,
    }),
}
