import {
  ToastInfo,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils'
import { ResourceLoadingState } from 'state/utils/types'
import {
  FeatureDataGroupDetailActionType,
  CurrentFeatureDataGroupDetail,
  FeatureDataListDisplayCondition,
} from './types'

export type FeatureDataGroupDetailAction = ActionsUnion<
  typeof featureDataGroupDetailActions
>

export const featureDataGroupDetailActions = {
  setCurrentFeatureDataGroupDetail: (
    currentFeatureDataGroupDetail: CurrentFeatureDataGroupDetail
  ): ActionWithPayload<
    'SET_CURRENT_FEATURE_DATA_GROUP_DETAIL',
    {
      currentFeatureDataGroupDetail: CurrentFeatureDataGroupDetail
    }
  > =>
    CreateAction(
      FeatureDataGroupDetailActionType.SET_CURRENT_FEATURE_DATA_GROUP_DETAIL,
      {
        currentFeatureDataGroupDetail,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataGroupDetailActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_DETAIL,
      {
        inProgress,
      }
    ),
  clearFeatureDataGroupDetailState:
    (): Action<'CLEAR_FEATURE_DATA_GROUP_DETAIL_STATE'> =>
      CreateAction(
        FeatureDataGroupDetailActionType.CLEAR_FEATURE_DATA_GROUP_DETAIL_STATE
      ),
  setListDisplayCondition: (
    condition: FeatureDataListDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GROUP_DETAIL',
    { condition: FeatureDataListDisplayCondition }
  > =>
    CreateAction(
      FeatureDataGroupDetailActionType.SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GROUP_DETAIL,
      {
        condition,
      }
    ),
  setFeatureDataListPagingState: (
    nextPageSubState: 'Enable' | 'Unable'
  ): ActionWithPayload<
    'SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_GROUP_DETAIL',
    { nextPageSubState: 'Enable' | 'Unable' }
  > =>
    CreateAction(
      FeatureDataGroupDetailActionType.SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_GROUP_DETAIL,
      {
        nextPageSubState,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      FeatureDataGroupDetailActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_DETAIL,
      {
        toastInfo,
      }
    ),
  setFeatureDataGroupDataState: (
    data: ResourceLoadingState
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_DATA_STATE',
    { data: ResourceLoadingState }
  > =>
    CreateAction(
      FeatureDataGroupDetailActionType.SET_FEATURE_DATA_GROUP_DATA_STATE,
      {
        data,
      }
    ),
  setIconUrl: (
    iconUrl: string
  ): ActionWithPayload<
    'SET_ICON_URL_FOR_FEATURE_DATA_GROUP_DETAIL',
    { iconUrl: string }
  > =>
    CreateAction(
      FeatureDataGroupDetailActionType.SET_ICON_URL_FOR_FEATURE_DATA_GROUP_DETAIL,
      {
        iconUrl,
      }
    ),
}
