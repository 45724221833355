import { Dispatch } from 'redux'
import { State } from 'state/store'
import {
  getAug3DSceneCamerasCollection,
  getAug3DSceneCameraRevisionsCollection,
} from 'state/firebase'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'

import { sceneCameraEntryActions } from './actions'
import { SceneCamera, SceneCameraListDisplayCondition } from './types'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraDocument } from 'utils/fireStore/aug3dSceneCamera'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraRevisionDocument } from 'utils/fireStore/aug3dSceneCameraRevision'
import { isUndefined } from 'utils/typeguard'
import { SceneCameraEntryApi } from './apis'

export const onUpdateSceneCameraDocument = async (
  dispatch: Dispatch,
  userGroupId: string,
  snapshot: QuerySnapshot<DocumentData>,
  sceneCameraIds: string[],
  condition: SceneCameraListDisplayCondition,
  lastSceneCameraDoc: DocumentData | undefined
) => {
  const sceneCameraList = await Promise.all(
    snapshot.docs.map(async (document: DocumentData) => {
      const aug3dSceneCameraDocData = document.data()
      if (
        !fireStoreTypeGuardForAug3dSceneCameraDocument(aug3dSceneCameraDocData)
      ) {
        return undefined
      }

      // リビジョンを取得する
      const sceneCameraRevisionDocs = (
        await getDocs(
          query(
            getAug3DSceneCameraRevisionsCollection(
              userGroupId,
              aug3dSceneCameraDocData['aug-3d-scene-camera-id']
            ),
            orderBy('aug-3d-scene-camera-revision', 'desc'),
            limit(1)
          )
        )
      ).docs

      if (
        sceneCameraRevisionDocs.length === 0 ||
        !fireStoreTypeGuardForAug3dSceneCameraRevisionDocument(
          sceneCameraRevisionDocs[0].data()
        )
      ) {
        return
      }

      const sceneCameraRevisionData = sceneCameraRevisionDocs[0].data()

      return {
        id: aug3dSceneCameraDocData['aug-3d-scene-camera-id'],
        revision: sceneCameraRevisionData['aug-3d-scene-camera-revision'],
        name: aug3dSceneCameraDocData['name'],
        overview: aug3dSceneCameraDocData['overview'],
        createdAt: aug3dSceneCameraDocData['created-at'],
        createdBy: aug3dSceneCameraDocData['created-by'],
      } as SceneCamera
    })
  )

  // aug-3d-scene-camera-id を保持
  // すでに保持している aug-3d-scene-camera-id が存在する場合は、現状の検索位置以降の aug-3d-scene-camera-id を一度破棄し
  // 新たに取得した aug-3d-scene-camera-id を保持する
  if (sceneCameraList.length >= 0) {
    if (lastSceneCameraDoc) {
      const index = sceneCameraIds.findIndex(
        (id) => id === lastSceneCameraDoc?.id
      )
      const beforePageIds = sceneCameraIds.slice(0, index + 1)
      dispatch(
        sceneCameraEntryActions.setSceneCameraIds([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        sceneCameraEntryActions.setSceneCameraIds([
          ...sceneCameraIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
  }
  // 取得したカメラ配置の一覧を保持
  dispatch(
    sceneCameraEntryActions.setSceneCameraList(
      sceneCameraList.filter((item) => item !== undefined) as SceneCamera[]
    )
  )
  let totalCountQuery = query(getAug3DSceneCamerasCollection(userGroupId))

  // 文字列検索が存在する場合は、aug-3d-scene-camera-idの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )
    totalCountQuery = query(
      totalCountQuery,
      orderBy('aug-3d-scene-camera-id', 'asc'),
      where('aug-3d-scene-camera-id', '>=', startCode),
      where('aug-3d-scene-camera-id', '<=', endCode)
    )
  }

  const totalCount = await getCountFromServer(totalCountQuery)
  dispatch(
    sceneCameraEntryActions.setSceneCameraListDisplayCondition({
      ...condition,
      totalCount: totalCount.data().count,
    })
  )
}

export const getSceneCameraDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId

  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.sceneCameraEntryState.domainData
      .currentSceneCameraListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      sceneCameraEntryActions.setCurrentSceneCameraListSnapshot(undefined)
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.sceneCameraEntryState.domainData
      .sceneCameraListDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery = query(getAug3DSceneCamerasCollection(userGroupId))

  // aug-3d-scene-camerasを表示件数分取得
  commonQuery = query(commonQuery, limit(condition.displayNumber))

  // 文字列検索が存在する場合は、AugParamTemplateIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、AugParamTemplateIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('aug-3d-scene-camera-id', 'asc'),
      where('aug-3d-scene-camera-id', '>=', startCode),
      where('aug-3d-scene-camera-id', '<=', endCode)
    )
  } else {
    const sortKey =
      condition.sortKey === 'generated-at' ? 'created-at' : condition.sortKey
    commonQuery = query(commonQuery, orderBy(sortKey, condition.sortOrder))
  }

  const sceneCameraIds =
    getState().pages.sceneCameraEntryState.domainData.sceneCameraIds

  // 既に取得していれば最後の要素から取得
  let lastItem: DocumentData | undefined = undefined
  if (sceneCameraIds.length > 0) {
    lastItem = await getDoc(
      doc(
        getAug3DSceneCamerasCollection(userGroupId),
        sceneCameraIds[sceneCameraIds.length - 1]
      )
    )

    commonQuery = query(commonQuery, startAfter(lastItem))
  }

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      onUpdateSceneCameraDocument(
        dispatch,
        userGroupId,
        snapshot,
        sceneCameraIds,
        condition,
        lastItem
      )
    }
  )

  // 現在、表示中のカメラ配置の一覧を保持
  dispatch(sceneCameraEntryActions.setCurrentSceneCameraListSnapshot(snapshot))
}

export const sceneCameraEntryOperations = {
  /** 次へボタン押下時の処理 */
  nextStep:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const sceneCameraEntryState =
        getState().pages.sceneCameraEntryState.appState.sceneCameraEntryState

      switch (sceneCameraEntryState) {
        case 'DestinationState':
          dispatch(
            sceneCameraEntryActions.setSceneCameraEntryState('UploadState')
          )
          break
        case 'UploadState':
          dispatch(
            sceneCameraEntryActions.setSceneCameraEntryState('MetadataState')
          )
          break
        case 'MetadataState':
          dispatch(
            sceneCameraEntryActions.setSceneCameraEntryState('ExecuteState')
          )
          break
        default:
          break
      }
    },

  /** 戻るボタン押下時の処理 */
  prevStep:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const sceneCameraEntryState =
        getState().pages.sceneCameraEntryState.appState.sceneCameraEntryState

      switch (sceneCameraEntryState) {
        case 'UploadState':
          dispatch(sceneCameraEntryActions.setCameraSettingFile(undefined))
          dispatch(
            sceneCameraEntryActions.setSceneCameraEntryState('DestinationState')
          )
          break
        case 'MetadataState':
          dispatch(
            sceneCameraEntryActions.setMetadata({ name: '', overview: '' })
          )
          dispatch(
            sceneCameraEntryActions.setSceneCameraEntryState('UploadState')
          )
          break
        case 'ExecuteState':
          dispatch(
            sceneCameraEntryActions.setSceneCameraEntryState('MetadataState')
          )
          break
        default:
          break
      }
    },

  /** リストを取得する */
  getSceneCameraList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(sceneCameraEntryActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentSceneCameraIds: string[] =
          getState().pages.sceneCameraEntryState.domainData.sceneCameraIds
        const condition =
          getState().pages.sceneCameraEntryState.domainData
            .sceneCameraListDisplayCondition
        const sceneCameraIds = currentSceneCameraIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(sceneCameraEntryActions.setSceneCameraIds(sceneCameraIds))

        // カメラ配置の一覧を取得
        await getSceneCameraDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(sceneCameraEntryActions.setInProgress(false))
      }
    },

  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.sceneCameraEntryState.domainData
            .currentSceneCameraListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },

  /**
   * カメラ設定を作成する
   */
  createSceneCamera:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<boolean> => {
      try {
        dispatch(sceneCameraEntryActions.setInProgress(true))
        dispatch(
          sceneCameraEntryActions.setSceneCameraEntrySubState({
            ...getState().pages.sceneCameraEntryState.appState
              .sceneCameraEntrySubState,
            createSubState: 'CreateInProgress',
          })
        )

        const sceneCameraFile =
          getState().pages.sceneCameraEntryState.domainData.cameraSettingFile
        if (sceneCameraFile === undefined) return false

        const parsedSceneCameraFile = JSON.parse(await sceneCameraFile.text())

        let cameraId = ''
        if (
          getState().pages.sceneCameraEntryState.domainData.selectedSceneCamera
        ) {
          // 選択済みのカメラ設定がある場合は、リビジョン更新APIを呼ぶ
          cameraId = (
            await SceneCameraEntryApi.createSceneCameraRevision({
              'aug-3d-scene-camera-revision': {
                'aug-3d-scene-camera-id':
                  getState().pages.sceneCameraEntryState.domainData
                    .selectedSceneCamera?.id ?? '',
                revision: {
                  camera: {
                    fov: parsedSceneCameraFile['camera']['fov'],
                    location: parsedSceneCameraFile['camera']['location'],
                    'look-at': parsedSceneCameraFile['camera']['look-at'],
                  },
                  schema_version: parsedSceneCameraFile['schema_version'],
                },
              },
            })
          ).data['aug-3d-scene-camera-id']
        } else {
          // 選択済みのカメラ設定がない場合は、新規作成APIを呼ぶ
          cameraId = (
            await SceneCameraEntryApi.createSceneCamera({
              'aug-3d-scene-camera': {
                name: getState().pages.sceneCameraEntryState.domainData.metadata
                  .name,
                overview:
                  getState().pages.sceneCameraEntryState.domainData.metadata
                    .overview,
                revision: {
                  camera: {
                    fov: parsedSceneCameraFile['camera']['fov'],
                    location: parsedSceneCameraFile['camera']['location'],
                    'look-at': parsedSceneCameraFile['camera']['look-at'],
                  },
                  schema_version: parsedSceneCameraFile['schema_version'],
                },
              },
            })
          ).data['aug-3d-scene-camera-id']
        }

        dispatch(
          sceneCameraEntryActions.setSceneCameraEntrySubState({
            ...getState().pages.sceneCameraEntryState.appState
              .sceneCameraEntrySubState,
            createSubState: 'Created',
          })
        )
        dispatch(sceneCameraEntryActions.setCreatedCameraId(cameraId))
        return true
      } catch (error) {
        console.error(error)
        dispatch(
          sceneCameraEntryActions.setSceneCameraEntrySubState({
            ...getState().pages.sceneCameraEntryState.appState
              .sceneCameraEntrySubState,
            createSubState: 'CreateError',
          })
        )
        return false
      } finally {
        dispatch(sceneCameraEntryActions.setInProgress(false))
      }
    },

  validateSceneCameraFile:
    (sceneCameraFile: File) =>
    async (dispatch: Dispatch): Promise<boolean> => {
      try {
        dispatch(sceneCameraEntryActions.setInProgress(true))

        const parsedSceneCameraFile = JSON.parse(await sceneCameraFile.text())

        const isValid = (
          await SceneCameraEntryApi.createSceneCameraValidate({
            'aug-3d-scene-camera': parsedSceneCameraFile,
          })
        ).data['is-valid']

        if (!isValid) {
          dispatch(
            sceneCameraEntryActions.setToastInfo({
              type: 'error',
              title: 'データ形式に誤りがあります',
              targets: [sceneCameraFile.name],
            })
          )
          return false
        }

        // validate に成功した場合はファイルを保持する
        dispatch(sceneCameraEntryActions.setCameraSettingFile(sceneCameraFile))

        return true
      } catch (error) {
        console.error(error)
        dispatch(
          sceneCameraEntryActions.setToastInfo({
            type: 'error',
            title: 'データ形式に誤りがあります',
            targets: [sceneCameraFile.name],
          })
        )
        return false
      } finally {
        dispatch(sceneCameraEntryActions.setInProgress(false))
      }
    },
}
