import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { InferenceParamsType } from './types'

const timeoutLimitSeconds = 15

export const InferenceApi = {
  executeInference: (
    inferenceParams: InferenceParamsType
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'inference-execute',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      ...inferenceParams,
    }),
}
