import Ajv, { ErrorObject } from 'ajv'

export interface JSONSchemaType {
  required: string[]
  type: string
  properties: {
    [key: string]: {
      type: string
    }
  }
}

export type ValidationSequence = {
  schema: JSONSchemaType
  dataIndex?: string
  subs?: ValidationSequence[]
}
export type Data = string | number | boolean
export type DataObject = {
  [id: string]: Data | DataObject | (Data | DataObject)[]
}

const ajv = new Ajv()

export function validateData(
  validationSequences: ValidationSequence[],
  data: DataObject
): ErrorObject[] {
  let errors: ErrorObject[] = []
  validationSequences.forEach((validationSequence: ValidationSequence) => {
    const validator = ajv.compile(validationSequence.schema)
    const validatorData = validationSequence.dataIndex
      ? data[validationSequence.dataIndex]
      : data
    if (!validator(validatorData)) {
      if (validator.errors) {
        errors = [...errors, ...validator.errors]
      }
    }
    if (
      validatorData &&
      typeof validatorData === 'object' &&
      validationSequence.subs !== undefined
    ) {
      const errorList = validateData(
        validationSequence.subs,
        validatorData as DataObject
      )
      if (errorList) {
        errors = [...errors, ...errorList]
      }
    }
  })
  return errors
}
