import axios from 'axios'
import { Dispatch } from 'redux'
import {
  getAccountSettingCollection,
  getClassSetMetaDataCollection,
  getDatasetQueryCollection,
  getMlPipelineQueriesCollection,
  getSettingsMetaDataCollection,
  getTrainedModelQueriesCollection,
} from 'state/firebase'
import {
  isObject,
  isString,
  isUndefined,
  isNumber,
  isArray,
} from 'utils/typeguard'
import { State } from 'state/store'
import { customTrainingDetailActions } from './'
import {
  TrainedModelDlLink,
  MlPipelineFile,
  GetModelsFilesResponse,
  MediaLink,
  Extended,
} from './types'
import { CustomTrainingDetailApi } from './apis'
import { convertProgressRateByTransactionStatusForCustomTraining } from 'state/utils'
import {
  isGetLogResponse,
  isGetMetricsItemResponse,
  isGetMetricsResponse,
} from 'state/utils/typeguard'
import { GetLogResponse, GetMetricsResponse } from 'state/utils/types'
import {
  doc,
  getDoc,
  query,
  getDocs,
  where,
  DocumentData,
} from 'firebase/firestore'
import { HttpsCallableResult } from 'firebase/functions'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument } from 'utils/fireStore/customTrainingMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import saveAs from 'file-saver'
import { domainDataOperations } from 'state/app/domainData/operations'
import { fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaData } from 'utils/fireStore/classSetMetaData'

/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    customTrainingDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

/** MediaLink[]かどうか */
function isMediaLinks(object: unknown): object is MediaLink[] {
  return (
    isArray(object) &&
    object.every((element) => {
      return (
        isString(element.mediaName) &&
        isString(element.mediaUrl) &&
        isNumber(element.mediaSize)
      )
    })
  )
}

/** GetModelsFilesResponseかどうか */
function isGetModelsFilesResponse(
  object: unknown
): object is GetModelsFilesResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isString(element.linkName) && isMediaLinks(element.mediaLinks)
    })
  )
}

const getExtendedField = async (
  dispatch: Dispatch,
  getState: () => State,
  mlPipelineData: DocumentData,
  userGroupId: string
): Promise<Extended | undefined> => {
  // 物体クラス分類の場合
  if (
    mlPipelineData['training-step']['src']['extended']['object-classification']
  ) {
    const classSetId =
      (mlPipelineData['training-step']['src']['extended'][
        'object-classification'
      ]['class-set']['class-set-id'] as string) ?? ''
    const classSetUserGroupId =
      (mlPipelineData['training-step']['src']['extended'][
        'object-classification'
      ]['class-set']['user-group-id'] as string) ?? ''
    const classSetMetaData = (
      await getDocs(
        userGroupId === classSetUserGroupId
          ? query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId)
            )
          : query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId),
              where('access-control.is-shared', '==', true),
              where('access-control.share-permissions.webapp', '==', 'list')
            )
      )
    ).docs[0].data()

    if (!fireStoreTypeGuardForClassSetMetaData(classSetMetaData)) {
      return
    }

    const classSetName = classSetMetaData['name']

    return {
      objectClassification: {
        classSet: {
          classSetId,
          classSetName,
          userGroupId: classSetUserGroupId,
        },
        setting: {
          preProcessKind:
            mlPipelineData['training-step']['src']['extended'][
              'object-classification'
            ]['setting']['pre-process-kind'],
          baseSize: {
            width:
              mlPipelineData['training-step']['src']['extended'][
                'object-classification'
              ]['setting']['base-size']['width'],
            height:
              mlPipelineData['training-step']['src']['extended'][
                'object-classification'
              ]['setting']['base-size']['height'],
          },
        },
      },
    }
  }

  return undefined
}

export const customTrainingDetailOperations = {
  /** リストを取得する */
  getCustomTrainingDetail:
    (mlPipelineId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(customTrainingDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId
        const algorithms = getState().app.domainData.algorithms

        /** mlPipelineのデータ */
        const mlPipeline = await getDoc(
          doc(getMlPipelineQueriesCollection(userGroupId), mlPipelineId)
        )
        const mlPipelineData = mlPipeline.data()

        if (!mlPipelineData) {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              mlPipelineDataState: 'NotFoundProcessed',
            })
          )
        }

        if (
          !fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument(
            mlPipelineData
          )
        ) {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        const algorithmId = mlPipelineData
          ? mlPipelineData['training-step']['src']['algorithm-id']
          : ''
        // カスタム学習名取得
        const algorithm = algorithms.find((algorithm) => {
          return algorithm.algorithmId === algorithmId
        })

        const baseModelUserGroupId = mlPipelineData
          ? mlPipelineData['training-step']['src']['base-model']
            ? mlPipelineData['training-step']['src']['base-model'][
                'user-group-id'
              ]
            : userGroupId
          : ''

        const baseModelId = mlPipelineData
          ? mlPipelineData['training-step']['src']['base-model']
            ? mlPipelineData['training-step']['src']['base-model'][
                'trained-model-id'
              ]
            : mlPipelineData['base-model-id']
          : ''

        // ベースモデルの情報を取得
        const baseModelData = (
          await getDocs(
            userGroupId === baseModelUserGroupId
              ? query(
                  getTrainedModelQueriesCollection(baseModelUserGroupId),
                  where('trained-model-id', '==', baseModelId)
                )
              : query(
                  getTrainedModelQueriesCollection(baseModelUserGroupId),
                  where('trained-model-id', '==', baseModelId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
          )
        ).docs[0]?.data()

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const isSharedUserGroupBaseModel =
          baseModelUserGroupId === sharedUserGroupId

        if (!fireStoreTypeGuardForModelQueryDocument(baseModelData)) {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        /** データセットのメタデータ */
        const datasetQueryData = (
          await getDoc(
            doc(
              getDatasetQueryCollection(userGroupId),
              mlPipelineData
                ? mlPipelineData['training-step']['src']['dataset-id']
                : ''
            )
          )
        ).data()

        if (!fireStoreTypeGuardForDatasetQueryDocument(datasetQueryData)) {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }
        /** セッティングのメタデータ */
        const settingMetadata =
          mlPipelineData && mlPipelineData['training-step']['src']['setting-id']
            ? (
                await getDoc(
                  doc(
                    getSettingsMetaDataCollection(userGroupId),
                    mlPipelineData['training-step']['src']['setting-id']
                  )
                )
              ).data()
            : undefined

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                mlPipelineData ? mlPipelineData['created-by'] : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        /** カスタムモデル名を取得 */
        let trainedModel = undefined
        const trainedModelId = mlPipelineData?.['training-step']?.['dest']?.[
          'trained-model-id'
        ]
          ? mlPipelineData?.['training-step']?.['dest']?.['trained-model-id']
          : mlPipelineData?.['training-step']?.['dest']?.['trained-model']?.[
              'trained-model-id'
            ]
        if (trainedModelId) {
          trainedModel = await getDoc(
            doc(getTrainedModelQueriesCollection(userGroupId), trainedModelId)
          )
        }

        let customModelData
        /** trained-model-idと一致するtrained-model-queryを取得 */
        const trainedModelData = trainedModel ? trainedModel.data() : undefined
        if (!isUndefined(trainedModelData)) {
          customModelData = (
            await getDoc(
              doc(
                getTrainedModelQueriesCollection(userGroupId),
                trainedModelData['trained-model-id']
              )
            )
          ).data()
        }

        let steps

        if (mlPipelineData && mlPipelineData['build-step']) {
          steps = {
            trainingStep: {
              stepId: mlPipelineData
                ? mlPipelineData['training-step']['step-id']
                : '',
              stepStatus: mlPipelineData
                ? mlPipelineData['training-step']['step-status']
                : '',
            },
            buildStep: {
              stepId: mlPipelineData
                ? mlPipelineData['build-step']['step-id']
                : '',
              stepStatus: mlPipelineData
                ? mlPipelineData['build-step']['step-status']
                : '',
            },
            transferStep: {
              stepId: mlPipelineData
                ? mlPipelineData['transfer-step']['step-id']
                : '',
              stepStatus: mlPipelineData
                ? mlPipelineData['transfer-step']['step-status']
                : '',
            },
          }
        } else {
          steps = {
            trainingStep: {
              stepId: mlPipelineData
                ? mlPipelineData['training-step']['step-id']
                : '',
              stepStatus: mlPipelineData
                ? mlPipelineData['training-step']['step-status']
                : '',
            },
          }
        }

        let extended: Extended | undefined = undefined
        if (
          !isUndefined(mlPipelineData) &&
          !isUndefined(mlPipelineData?.['training-step']['src']['extended'])
        ) {
          extended = await getExtendedField(
            dispatch,
            getState,
            mlPipelineData,
            userGroupId
          )
        }

        dispatch(
          customTrainingDetailActions.setCurrentCustomTrainingDetail({
            mlPipelineId: mlPipelineData
              ? mlPipelineData['ml-pipeline']['ml-pipeline-id']
              : '',
            mlPipelineName: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['name']
              : '',
            mlPipelineRemarks: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['remarks']
              : '',
            trainingAlgorithm: {
              algorithmName: algorithm ? algorithm.metadata.name.ja : '',
              trainingAlgorithmVersion: {
                displayName: mlPipelineData
                  ? mlPipelineData['training-step']['src'][
                      'training-algorithm-version'
                    ]['display-name']
                  : '0.0.0',
                major: mlPipelineData
                  ? mlPipelineData['training-step']['src'][
                      'training-algorithm-version'
                    ]['major']
                  : 0,
                minor: mlPipelineData
                  ? mlPipelineData['training-step']['src'][
                      'training-algorithm-version'
                    ]['minor']
                  : 0,
                patch: mlPipelineData
                  ? mlPipelineData['training-step']['src'][
                      'training-algorithm-version'
                    ]['patch']
                  : 0,
                preRelease: mlPipelineData
                  ? mlPipelineData['training-step']['src'][
                      'training-algorithm-version'
                    ]['pre-release']
                  : 0,
              },
            },
            progress: {
              transactionStatus: mlPipelineData
                ? mlPipelineData['ml-pipeline']['transaction-status']
                : '',
              progressRate:
                convertProgressRateByTransactionStatusForCustomTraining(
                  mlPipelineData
                    ? mlPipelineData['ml-pipeline']['transaction-status']
                    : ''
                ),
            },
            startedAt: mlPipelineData
              ? mlPipelineData['ml-pipeline']['started-at']
              : '',
            endedAt:
              mlPipelineData &&
              mlPipelineData['ml-pipeline']['ended-at'] &&
              mlPipelineData['ml-pipeline']['ended-at'].seconds === 0
                ? undefined
                : mlPipelineData
                ? mlPipelineData['ml-pipeline']['ended-at']
                : '',
            dataset: {
              datasetId: mlPipelineData
                ? mlPipelineData['training-step']['src']['dataset-id']
                : '',
              datasetName: datasetQueryData
                ? datasetQueryData['dataset-name']
                : '',
            },
            setting: {
              settingId: mlPipelineData
                ? mlPipelineData['training-step']['src']['setting-id']
                : '',
              settingName: settingMetadata ? settingMetadata['name'] : '',
            },
            baseModel: {
              baseModelId: baseModelData
                ? baseModelData['trained-model-id']
                : '',
              baseModelName: baseModelData
                ? baseModelData['trained-model-name']
                : '',
              isSharedUserGroupBaseModel: isSharedUserGroupBaseModel,
            },
            inheritedVersion:
              mlPipelineData &&
              mlPipelineData['training-step']['src']['inherited-version'] &&
              mlPipelineData['training-step']['src']['inherited-version'][
                'trained-model-group-version'
              ]['display-name'] !== '0.0.0'
                ? {
                    trainedModelGroupId:
                      mlPipelineData['training-step']['src'][
                        'inherited-version'
                      ]['trained-model-group-id'],
                    trainedModelGroupVersion: {
                      displayName:
                        mlPipelineData['training-step']['src'][
                          'inherited-version'
                        ]['trained-model-group-version']['display-name'],
                      major:
                        mlPipelineData['training-step']['src'][
                          'inherited-version'
                        ]['trained-model-group-version']['major'],
                      minor:
                        mlPipelineData['training-step']['src'][
                          'inherited-version'
                        ]['trained-model-group-version']['minor'],
                      patch:
                        mlPipelineData['training-step']['src'][
                          'inherited-version'
                        ]['trained-model-group-version']['patch'],
                    },
                    trainedModelId:
                      mlPipelineData['training-step']['src'][
                        'inherited-version'
                      ]['trained-model-id'],
                    userGroupId:
                      mlPipelineData['training-step']['src'][
                        'inherited-version'
                      ]['user-group-id'],
                  }
                : undefined,
            trainedModel: {
              trainedModelId: trainedModelData
                ? trainedModelData['trained-model-id']
                  ? trainedModelData['trained-model-id']
                  : ''
                : '',
              trainedModelName: customModelData
                ? customModelData['trained-model-name']
                : '',
            },
            destType: mlPipelineData
              ? mlPipelineData['build-step']
                ? 'EdgeImage'
                : 'CustomModel'
              : 'CustomModel',
            steps: steps,
            createdBy: accountSetting
              ? {
                  firstName: accountSetting['first-name'] ?? '',
                  familyName: accountSetting['family-name'] ?? '',
                }
              : mlPipelineData
              ? mlPipelineData['created-by']
              : '',
            extended,
          })
        )
        dispatch(
          customTrainingDetailActions.setCustomTrainingDetailState({
            ...getState().pages.customTrainingDetailState.appState
              .customTrainingDetailState,
            mlPipelineDataState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          customTrainingDetailActions.setCustomTrainingDetailState({
            ...getState().pages.customTrainingDetailState.appState
              .customTrainingDetailState,
            mlPipelineDataState: 'Failed',
          })
        )
      } finally {
        dispatch(customTrainingDetailActions.setInProgress(false))
      }
    },
  /** ファイルデータの取得 */
  getFileData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const requestMlPipelineId =
          getState().pages.customTrainingDetailState.domainData
            .currentCustomTrainingDetail?.mlPipelineId
        const trainedModelId =
          getState().pages.customTrainingDetailState.domainData
            .currentCustomTrainingDetail?.trainedModel.trainedModelId

        if (!trainedModelId) {
          return
        }

        let logItems: MlPipelineFile[] = []
        const modelsFilesItems: TrainedModelDlLink[] = []
        let metricsItems: MlPipelineFile[] = []

        // モデルファイル用キャッチ
        try {
          const getModelsFilesResponse =
            await CustomTrainingDetailApi.getModelsFiles(trainedModelId)

          if (isGetModelsFilesResponse(getModelsFilesResponse)) {
            getModelsFilesResponse.data.items.map((file) => {
              modelsFilesItems.push({
                linkName: file.linkName,
                mediaLinks: file.mediaLinks,
                totalMediaSize: file.mediaLinks.reduce(
                  (prev, current) => prev + current.mediaSize,
                  0
                ),
              })
            })

            dispatch(
              customTrainingDetailActions.setCustomTrainingDetailState({
                ...getState().pages.customTrainingDetailState.appState
                  .customTrainingDetailState,
                trainedModelDlLinkSubState: 'Loaded',
              })
            )
          }
        } catch {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              trainedModelDlLinkSubState: 'Failed',
            })
          )
        }

        // メトリクスファイル用キャッチ
        try {
          // 評価結果
          const getAnalyzeResultsResponse =
            await CustomTrainingDetailApi.getAnalyzeResults(
              requestMlPipelineId ?? ''
            )
          const getConfigsResponse = await CustomTrainingDetailApi.getConfigs(
            requestMlPipelineId ?? ''
          )

          if (
            isGetMetricsResponse(getAnalyzeResultsResponse) &&
            isGetMetricsItemResponse(getConfigsResponse)
          ) {
            const analyzeResultItems = (
              getAnalyzeResultsResponse as GetMetricsResponse
            ).data.items.map((item) => ({
              fileName: item.name,
              fileLink: item.url,
              fileSize: item.size,
              createdAt: new Date(item.createdAt),
            }))

            const configItem =
              getConfigsResponse.data.item != null
                ? {
                    fileName: getConfigsResponse.data.item.name,
                    fileLink: getConfigsResponse.data.item.url,
                    fileSize: getConfigsResponse.data.item.size,
                    createdAt: new Date(getConfigsResponse.data.item.createdAt),
                  }
                : undefined

            metricsItems = configItem
              ? [...analyzeResultItems, configItem]
              : [...analyzeResultItems]

            dispatch(
              customTrainingDetailActions.setCustomTrainingDetailState({
                ...getState().pages.customTrainingDetailState.appState
                  .customTrainingDetailState,
                metricsSubState: 'Loaded',
              })
            )
          }
        } catch (error) {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              metricsSubState: 'Failed',
            })
          )
        }

        // ログファイル用キャッチ
        try {
          const logResponse = await CustomTrainingDetailApi.getLogs(
            requestMlPipelineId ? requestMlPipelineId : ''
          )
          if (isGetLogResponse(logResponse)) {
            logItems = (logResponse as GetLogResponse).data.items.map(
              (log) => ({
                fileName: log.fileNameForDownload,
                fileLink: log.name,
                fileSize: log.size,
                createdAt: new Date(log.createdAt),
              })
            )

            dispatch(
              customTrainingDetailActions.setCustomTrainingDetailState({
                ...getState().pages.customTrainingDetailState.appState
                  .customTrainingDetailState,
                mlPipelineLogSubState: 'Loaded',
              })
            )
          }
        } catch {
          dispatch(
            customTrainingDetailActions.setCustomTrainingDetailState({
              ...getState().pages.customTrainingDetailState.appState
                .customTrainingDetailState,
              mlPipelineLogSubState: 'Failed',
            })
          )
        }

        const currentMlPipelineId =
          getState().pages.customTrainingDetailState.domainData
            .currentCustomTrainingDetail?.mlPipelineId
        if (requestMlPipelineId === currentMlPipelineId) {
          dispatch(
            customTrainingDetailActions.setTrainedModelDlLinks(modelsFilesItems)
          )
          dispatch(customTrainingDetailActions.setMetricsFiles(metricsItems))
          dispatch(customTrainingDetailActions.setMlPipelineLogFiles(logItems))
        }
      } catch (error) {
        console.error(error)
      }
    },
  /** モデルファイルのダウンロード */
  modelFileDownload:
    (links: MediaLink[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(customTrainingDetailActions.setInProgress(true))

        // ファイルDL失敗リスト
        const failedModelFileList: string[] = []
        await Promise.all(
          links.map(async (link) => {
            try {
              await axios
                .get(link.mediaUrl, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, link.mediaName)
                })
            } catch {
              failedModelFileList.push(link.mediaName)
            }
          })
        )
        // 失敗した画像があれば、Storeに保持
        if (failedModelFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedModelFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingDetailActions.setInProgress(false))
      }
    },
  /** メトリクスファイルのダウンロード */
  metricsFileDownload:
    (metricsFiles: MlPipelineFile[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(customTrainingDetailActions.setInProgress(true))

        // DL失敗の画像のリスト
        const failedMetricsFileList: string[] = []

        await Promise.all(
          metricsFiles.map(async (file) => {
            try {
              await axios
                .get(file.fileLink, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, file.fileName)
                })
            } catch {
              failedMetricsFileList.push(file.fileName)
            }
          })
        )

        // 失敗した画像があれば、Storeに保持
        if (failedMetricsFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedMetricsFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingDetailActions.setInProgress(false))
      }
    },
  /** ログファイルのダウンロード */
  logFileDownload:
    (logFiles: MlPipelineFile[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const mlPipelineId =
          getState().pages.customTrainingDetailState.domainData
            .currentCustomTrainingDetail?.mlPipelineId

        if (isUndefined(mlPipelineId)) return

        dispatch(customTrainingDetailActions.setInProgress(true))

        // DL失敗の画像のリスト
        const failedLogFileList: string[] = []

        await Promise.all(
          logFiles.map(async (logFile) => {
            try {
              const res = (await CustomTrainingDetailApi.downloadLog(
                mlPipelineId,
                logFile.fileLink
              )) as HttpsCallableResult<{ blob: BlobPart; type: string }>
              const blob = new Blob([res.data.blob], {
                type: res.data.type,
              })
              saveAs(blob, logFile.fileName)
            } catch {
              failedLogFileList.push(logFile.fileName)
            }
          })
        )

        // 失敗した画像があれば、Storeに保持
        if (failedLogFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedLogFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingDetailActions.setInProgress(false))
      }
    },
}
