import { FeatureDataTransferringMLPipelineQueryDocument } from './types'

import { mlPipelineDocumentSchema, timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** ml-pipeline-query (特徴量データ転送) documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as FeatureDataTransferringMLPipelineQueryDocument
  const schema = {
    ...mlPipelineDocumentSchema,
    required: [...mlPipelineDocumentSchema.required, 'transfer-step'],
  }

  const transferStepSchema = {
    required: [
      'ended-at',
      'started-at',
      'step-id',
      'step-kind',
      'step-status',
      'src',
    ],
    type: 'object',
    properties: {
      'ended-at': {
        ...timeStampSchema,
      },
      'started-at': {
        ...timeStampSchema,
      },
      'step-id': {
        type: 'string',
      },
      'step-kind': {
        type: 'string',
      },
      'step-status': {
        type: 'string',
      },
      src: {
        required: ['feature-data-list'],
        type: 'object',
        properties: {
          'feature-data-list': {
            type: 'array',
            minItems: 1,
            items: {
              required: ['feature-data-id', 'user-group-id'],
              type: 'object',
              properties: {
                'feature-data-id': {
                  type: 'string',
                },
                'user-group-id': {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
  }
  const validationSequences = [
    { schema: schema },
    {
      schema: transferStepSchema,
      dataIndex: 'transfer-step',
    },
  ]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'ml-pipeline-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
