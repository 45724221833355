import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import {
  CheckableTableProps,
  CheckableTableHeader,
  CHECKABLE_STATUS_PROGRESS_BAR_ROW_HEIGHT,
  DISPLAY_NONE_CHECKBOX_ROW_HEIGHT,
  CHECKBOX_ROW_HEIGHT,
} from './types'
import { isUndefined } from 'utils/typeguard'

const TABLE_CELL_PADDING = 40
const CHECKBOX_WIDTH = 82

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    flexDirection: 'row',
    '& .PrivateSwitchBase-input': {
      height: 32,
    },
  },
  tableDiv: {
    maxWidth: '100%',
  },
  tableBody: {
    maxWidth: '100%',
    '& .MuiTableRow-root:hover': {
      backgroundColor: '#f2f2f2 !important',
    },
  },
  headerRow: {
    '& .MuiTableCell-head': {
      backgroundColor: '#eee',
      height: '48px',
      'z-index': 4,
    },
  },
  tableRow: {
    background: '#fafafa',
    '& th': {
      backgroundColor: 'inherit',
    },
    '& td': {
      backgroundColor: 'inherit',
    },
  },
  activeTableRow: {
    backgroundColor: '#f2f2f2',
  },
  table: {
    maxWidth: '100%',
  },
  tableHeaderCell: {
    top: 0,
    left: 0,
    zIndex: 3,
    position: 'sticky',
  },
  checkbox: {
    height: '30px',
    width: '30px',
  },
  tableCheckboxCell: {
    left: 0,
    zIndex: 1,
    position: 'sticky',
  },
  tableCellText: {
    '& p, & a': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}))

export const CheckableTable: React.FC<CheckableTableProps> = (
  props: CheckableTableProps
) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const tableRowHeight = props.includesStatusProgressBar
    ? CHECKABLE_STATUS_PROGRESS_BAR_ROW_HEIGHT
    : props.displayNoneCheckbox
    ? DISPLAY_NONE_CHECKBOX_ROW_HEIGHT
    : CHECKBOX_ROW_HEIGHT
  const [paginationRowsLabel, setPaginationRowsLabel] = useState<string>('')

  /** length分の空のセルを返す */
  const emptyRowCells = useMemo(() => {
    const { displayNoneCheckbox: displayNoneCheckbox, headers, rows } = props

    // 表示しているデータが10件以上の場合空白を入れない
    if (rows.length >= 10 || props.noEmptyRows) {
      return <></>
    }

    const emptyRowCell = []

    for (let i = 0; i < 10 - rows.length; i++) {
      emptyRowCell.push(
        <TableRow
          className={classes.tableRow}
          style={{
            height: `${tableRowHeight}px`,
          }}
          key={i}
        >
          {!displayNoneCheckbox && (
            <TableCell
              component='th'
              scope='row'
              className={classes.tableHeaderCell}
            ></TableCell>
          )}
          {headers.map((row, index) => (
            <TableCell key={index} align='center' component='th'></TableCell>
          ))}
        </TableRow>
      )
    }
    return emptyRowCell
  }, [props.rows])

  const [checkboxStatusList, setCheckboxStatusList] = useState<
    (boolean | undefined)[]
  >(new Array(props.rows.length))
  useEffect(() => {
    if (props.selectedRows) {
      setCheckboxStatusList([...props.selectedRows])
    }
  }, [props.selectedRows])

  const { onChangeDisplayNumber } = props

  let handleChangeRowsPerPage = undefined

  if (!isUndefined(onChangeDisplayNumber)) {
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeDisplayNumber(parseInt(event.target.value, 10))
    }
  }

  const getTableCellStyle = (index: number, layer: string, width: number) => {
    const zIndexValue = () => {
      switch (layer) {
        case 'low':
          return props.fixedColumnNumber > index ? 1 : 0
        case 'middle':
          return props.fixedColumnNumber > index ? 2 : 1
        default:
          return 0
      }
    }

    const leftPosition = (index: number) => {
      if (index === 0) return CHECKBOX_WIDTH

      const sumReducer = (
        accumulator: number,
        currentValue: CheckableTableHeader
      ) => accumulator + currentValue.width + TABLE_CELL_PADDING

      return (
        CHECKBOX_WIDTH + props.headers.slice(0, index).reduce(sumReducer, 0)
      )
    }

    const style: React.CSSProperties = {
      position: 'sticky',
      minWidth: width,
      maxWidth: width,
      zIndex: zIndexValue(),
      left: !props.displayNoneCheckbox
        ? props.fixedColumnNumber > index
          ? leftPosition(index)
          : 'auto'
        : 'auto',
      borderRight: !props.displayNoneCheckbox
        ? props.fixedColumnNumber - 1 === index
          ? '1px solid #efefef'
          : 'none'
        : 'none',
    }
    return style
  }

  const labelDisplayedRows = useCallback(
    (from: number, to: number, count: number) => {
      const rows =
        props.totalCount && props.totalCount < 0
          ? `${from}-${to} / ${to} ~`
          : `${from}-${to} / ${count}`

      // データ読み込み中の場合は、前回の件数ラベルを表示（読み込み完了後に算出して表示）
      if (props.loading) return paginationRowsLabel

      setPaginationRowsLabel(rows)

      return rows
    },
    [props.loading, props.totalCount]
  )

  return (
    <>
      <div className={classes.root}>
        <TableContainer
          component={Paper}
          className={classes.tableDiv}
          style={{
            maxHeight:
              (props.tableHeight ?? 500) +
              (theme.custom.scrollBar.width as number),
          }}
        >
          <Table stickyHeader className={classes.table} size='small'>
            <TableHead>
              <TableRow className={classes.headerRow}>
                {!props.displayNoneCheckbox && (
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.tableHeaderCell}
                  ></TableCell>
                )}
                {props.headers.map((row, index) => (
                  <TableCell
                    style={getTableCellStyle(index, 'middle', row.width)}
                    key={row.id}
                    align='center'
                    component='th'
                  >
                    {row.sortable ? (
                      <TableSortLabel
                        active={props.sortOrder?.key === row.id && row.sortable}
                        direction={props.sortOrder?.order}
                        hideSortIcon={!row.sortable}
                        onClick={() =>
                          props.onClickOrderChange &&
                          props.onClickOrderChange(row.id)
                        }
                      >
                        {row.title}
                      </TableSortLabel>
                    ) : (
                      row.title
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              className={clsx(props.disabled ? undefined : classes.tableBody, {
                [classes.cursorPointer]: props.isPointer,
              })}
            >
              {props.rows.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  className={clsx(classes.tableRow, {
                    [classes.activeTableRow]: checkboxStatusList[rowIndex],
                  })}
                >
                  {!props.displayNoneCheckbox && (
                    <TableCell
                      component='th'
                      scope='row'
                      className={classes.tableCheckboxCell}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        checked={checkboxStatusList[rowIndex] || false}
                        disabled={props.disabled === true}
                        onChange={() =>
                          props.onClickCheckbox &&
                          props.onClickCheckbox(rowIndex)
                        }
                        color={'secondary'}
                      ></Checkbox>
                    </TableCell>
                  )}
                  {row.map((item, columnIndex) => (
                    <TableCell
                      key={columnIndex}
                      align={props.headers[columnIndex].position}
                      onClick={() =>
                        props.onClickRow && props.onClickRow(rowIndex)
                      }
                      className={classes.tableCellText}
                      style={getTableCellStyle(
                        columnIndex,
                        'low',
                        props.headers[columnIndex].width
                      )}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {emptyRowCells}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {!isUndefined(props.totalCount) && props.displayNumber ? (
        <TablePagination
          component='div'
          count={props.totalCount}
          rowsPerPage={props.displayNumber}
          page={props.page}
          onPageChange={(event, page) =>
            props.onClickPageChange && props.onClickPageChange(page)
          }
          labelRowsPerPage={'ページあたりの行数'}
          rowsPerPageOptions={[10, 30, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            labelDisplayedRows(from, to, count)
          }
        />
      ) : (
        <></>
      )}
    </>
  )
}
