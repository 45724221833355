import {
  AlgorithmStructure,
  AlgorithmStructureVersion,
} from 'state/app/domainData'
import { Timestamp } from 'state/firebase'
import { ToastInfo } from 'state/utils'
import { DocumentVersion } from 'utils/fireStore/common'

// ActionType
export const FeatureDataUploadActionType = {
  SET_ALGORITHM_DISPLAY_CONDITION: 'SET_ALGORITHM_DISPLAY_CONDITION',
  SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD:
    'SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD',
  SET_SELECTED_ALGORITHM_ID_FOR_FEATURE_DATA_UPLOAD:
    'SET_SELECTED_ALGORITHM_ID_FOR_FEATURE_DATA_UPLOAD',
  SET_SELECTED_FEATURE_DATA_GROUP: 'SET_SELECTED_FEATURE_DATA_GROUP',
  SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_UPLOAD:
    'SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_UPLOAD',
  CLEAR_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD:
    'CLEAR_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD',
  SET_FEATURE_DATA_GROUP_SUB_STATE: 'SET_FEATURE_DATA_GROUP_SUB_STATE',
  SET_SELECTED_FEATURE_DATA_VERSION: 'SET_SELECTED_FEATURE_DATA_VERSION',
  SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_UPLOAD:
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_UPLOAD',
  SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_UPLOAD:
    'SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_UPLOAD',
  SET_FEATURE_DATA_META_DATA: 'SET_FEATURE_DATA_META_DATA',
  SET_ALGORITHM_SUB_STATE: 'SET_ALGORITHM_SUB_STATE',
  SET_FEATURE_DATA_UPLOAD_SUB_STATE: 'SET_FEATURE_DATA_UPLOAD_SUB_STATE',
  SET_FEATURE_DATA_META_DATA_SUB_STATE: 'SET_FEATURE_DATA_META_DATA_SUB_STATE',
  SET_SEND_FEATURE_DATA_STATE: 'SET_SEND_FEATURE_DATA_STATE',
  CLEAR_FEATURE_DATA_UPLOAD_STATE: 'CLEAR_FEATURE_DATA_UPLOAD_STATE',
  EXEC_FEATURE_DATA_SEND_SUCCESS: 'EXEC_FEATURE_DATA_SEND_SUCCESS',
  EXEC_FEATURE_DATA_SEND_FAILURE: 'EXEC_FEATURE_DATA_SEND_FAILURE',
  SET_EXECUTED_FEATURE_DATA_ID: 'SET_EXECUTED_FEATURE_DATA_ID',
  ADD_IMAGE_SET_FILE_FOR_FEATURE_DATA_UPLOAD:
    'ADD_IMAGE_SET_FILE_FOR_FEATURE_DATA_UPLOAD',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_UPLOAD:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_UPLOAD',
  SET_FILE_UPLOAD_RESULT: 'SET_FILE_UPLOAD_RESULT',
  SET_TOAST_INFO_FOR_FEATURE_DATA_UPLOAD:
    'SET_TOAST_INFO_FOR_FEATURE_DATA_UPLOAD',
} as const

export interface FeatureDataGroupDocument {
  id: string
  'feature-data-group-id': string
  'feature-data-group-name': string
  'feature-data-group-version-latest': DocumentVersion
  'latest-version': string
  'feature-data-count': number
  'feature-data-list': {
    'feature-data-id': string
    'feature-data-name': string
    'feature-data-group-version': DocumentVersion
    'transaction-status': string
  }[]
  'updated-at': Timestamp
  'updated-by': string
  'created-at': Timestamp
  'created-by': string
  'created-user-id': string
  ['access-control']?: {
    ['is-shared']: boolean
    ['share-permissions']: {
      ['webapp']: string
      ['backend']: string
    }
  }
}

export interface MetaData {
  name?: string
  remarks?: string
}

export interface FeatureDataUploadParamsType {
  trainingAlgorithmId: string
  trainingAlgorithmVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
    preRelease: number
  }
  featureDataGroupId: string
  featureDataVersion: string
  metadata: MetaData
  algorithmStructureId: string
  algorithmStructureVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  isSharedUserGroup: boolean
}

export interface AlgorithmDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
}

export interface FeatureDataUploadSubStateKind {
  algorithmSubState: 'Unselected' | 'Selected'
  featureDataGroupSubState: 'Unselected' | 'Selected'
  featureDataUploadSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError'
}

export interface FeatureDataGroup {
  featureDataGroupId: string
  featureDataGroupName: string
  latestVersion: string
  featureDataCount: number
  updatedAt: Timestamp
  createdAt: Timestamp
  createdUserId: string
  latestFeatureDataName: string
  isSharedUserGroup?: boolean // 共有データかどうか
}

export interface FeatureDataList {
  'feature-data-id': string
  'feature-data-name': string
  'feature-data-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
}

export interface FeatureDataGroupListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export const FeatureDataUploadStateKindArray = [
  'AlgorithmState',
  'FeatureDataGroup',
  'FeatureDataUpload',
  'MetaDataState',
  'ExecuteState',
]

export interface FileAndProgress {
  progress: number
  file: File
  uploadStatus: 'beforeUpload' | 'completed' | 'uploadError'
}

export interface AlgorithmStructureTableData {
  config: string
  createdAt: string
  version: string
  remarks: string
}

export type FeatureDataUploadStateKind =
  (typeof FeatureDataUploadStateKindArray)[number]

export interface TrainingAlgorithmVersion {
  trainingAlgorithmVersion: string // 学習アルゴリズムバージョン
  algorithmVersion: {
    // 学習アルゴリズムバージョン
    displayName: string // 表示名
    major: number // メジャーバージョン
    minor: number // マイナーバージョン
    patch: number // パッチバージョン
    preRelease: number // プレリリースバージョン
  }
  // メタデータ
  metadata: {
    remarks: string // アルゴリズムの備考
  }
  releasedAt: Timestamp // リリース日時
  algorithmStructures: AlgorithmStructure[]
}

export interface FeatureDataUploadDomainData {
  algorithmDisplayCondition: AlgorithmDisplayCondition // アルゴリズムの表示条件
  // selectedAlgorithmKind?: string // 選択中のアルゴリズム種別
  selectedTrainingAlgorithm: {
    // 選択中のアルゴリズム
    algorithmId: string
  }
  selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion // 選択中のアルゴリズムの情報
  selectedAlgorithmStructureVersion?: AlgorithmStructureVersion // 選択中のアルゴリズムストラクチャーバージョン
  featureDataGroupList: FeatureDataGroup[] // 特徴量データグループ一覧
  featureDataGroupListDisplayCondition: FeatureDataGroupListDisplayCondition // 特徴量データグループ表示条件
  selectedFeatureDataGroup?: FeatureDataGroup // 選択された特徴量データグループ
  featureDataMetaData?: MetaData // 入力された特徴量データのメタデータ
  executedFeatureDataId?: string // 特徴量データのアップロードを実行時に生成された 特徴量データのID
  inputFiles: FileAndProgress[] //アップロードされたファイル
  fileUploadResult: 'beforeUpload' | 'failed' | 'success' // ファイルアップロードステータス
  selectedVersion?: string // 選択された特徴量データバージョン
}

export interface FeatureDataUploadAppState {
  inProgress: boolean // データ取得中、実行中フラグ
  toastInfo?: ToastInfo // トーストの表示する情報
  featureDataUploadStateKind: FeatureDataUploadStateKind // 特徴量データアップロード画面のSTEP
  featureDataUploadSubStateKind: FeatureDataUploadSubStateKind // 特徴量データアップロード画面の各種STEPの状態
}

export interface FeatureDataUploadState {
  domainData: FeatureDataUploadDomainData
  appState: FeatureDataUploadAppState
}
