import { DatasetAugmentationListAction } from './actions'
import {
  DatasetAugmentationListActionType,
  DatasetAugmentationList,
} from './types'

const initialState: DatasetAugmentationList = {
  domainData: {
    currentDatasetAugmentationList: [],
    datasetAugmentationListDisplayCondition: {
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    mlPipelineIds: [],
    currentDatasetAugmentationListSnapshot: undefined,
  },
  appState: {
    inProgress: false,
  },
}

export const DatasetAugmentationListReducer = (
  state: DatasetAugmentationList = initialState,
  action: DatasetAugmentationListAction
): DatasetAugmentationList => {
  switch (action.type) {
    case DatasetAugmentationListActionType.SET_DATASET_AUGMENTATION_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetAugmentationList:
            action.payload.currentDatasetAugmentationList,
        },
      }
    case DatasetAugmentationListActionType.SET_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION:
      if (action.payload.datasetAugmentationListDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            datasetAugmentationListDisplayCondition:
              action.payload.datasetAugmentationListDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case DatasetAugmentationListActionType.CLEAR_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetAugmentationListDisplayCondition: {
            ...initialState.domainData.datasetAugmentationListDisplayCondition,
          },
        },
      }
    case DatasetAugmentationListActionType.SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case DatasetAugmentationListActionType.SET_ML_PIPELINE_IDS_FOR_DATASET_AUGMENTATION_LIST:
      if (action.payload.mlPipelineIds) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            mlPipelineIds: action.payload.mlPipelineIds,
          },
        }
      } else {
        return { ...state }
      }
    case DatasetAugmentationListActionType.SET_CURRENT_DATASET_AUGMENTATION_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetAugmentationListSnapshot:
            action.payload.currentDatasetAugmentationListSnapshot,
        },
      }
    case DatasetAugmentationListActionType.CLEAR_DATASET_AUGMENTATION_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
