import { authInstance } from 'state/firebase'
import { PasswordUpdateApi } from './apis'
import { passwordUpdateActions } from './actions'
import { Dispatch } from 'redux'
import {
  getAccountGroupCollection,
  getAccountSettingCollection,
} from 'state/firebase'
import { appStateActions } from 'state/app/appState'
import { AuthedUser, domainDataActions } from 'state/app/domainData'
import { loginActions } from 'state/ducks/login'
import { MfaSettingApi } from 'state/ducks/mfaSetting/apis'
import { getCustomerList } from 'state/utils/customer'
import { updatePassword } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupDocument } from 'utils/fireStore/accountGroup'
import {
  generateInvalidPasswordErrorMessage,
  validatePassword,
} from 'state/utils'

export const passwordUpdateOperations = {
  executePasswordUpdate:
    (newPassword: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(passwordUpdateActions.setInProgress(true))
        const user = authInstance.currentUser
        // パスワードアップデート
        if (!user) return

        const validationStatus = await validatePassword(
          authInstance,
          newPassword
        )
        if (
          validationStatus !== undefined &&
          validationStatus.isValid === false
        ) {
          dispatch(
            passwordUpdateActions.setToastInfo({
              type: 'error',
              title: 'パスワードが正しく設定されていません',
              targets: generateInvalidPasswordErrorMessage(validationStatus),
            })
          )
          return
        }

        await updatePassword(user, newPassword)
          .then(async () => {
            dispatch(passwordUpdateActions.setIsPasswordUpdated(true))
            // docのパスワード更新日時を更新
            await PasswordUpdateApi.setPasswordUpdateTime()

            const idToken = await user.getIdTokenResult()

            const accountsGroupDoc = (
              await getDoc(
                doc(
                  getAccountGroupCollection(),
                  idToken.claims['account-group-id'] as string
                )
              )
            ).data()

            // アカウントの設定
            const accountSettingDoc = (
              await getDoc(
                doc(
                  getAccountSettingCollection(
                    idToken.claims['account-group-id'] as string
                  ),
                  idToken.claims['account-id'] as string
                )
              )
            ).data()

            if (
              !accountsGroupDoc ||
              !fireStoreTypeGuardForAccountSettingDocument(accountSettingDoc) ||
              !fireStoreTypeGuardForAccountGroupDocument(accountsGroupDoc)
            )
              return
            // MFA設定が必須でない場合、ホーム画面に飛ぶ
            if (
              accountsGroupDoc['mfa-group-setting'] !== 'required' ||
              (accountSettingDoc && accountSettingDoc['is-mfa'])
            ) {
              if (user) {
                const result = await user.getIdTokenResult()
                const authedUser: AuthedUser = {
                  mailAddress: user.email as string,
                  userId: user.uid,
                  customers: [],
                  auth: {
                    token: await user.getIdToken(),
                    customClaims: {
                      accountId: result.claims['account-id'] as string,
                      accountGroupId: result.claims[
                        'account-group-id'
                      ] as string,
                      role: result.claims['role'] as string,
                      sharedList:
                        (result.claims['shared-list'] as string[]) ?? [],
                      superUser: result.claims['super-user'] as boolean,
                      userGroupId: result.claims['user-group-id'] as string,
                    },
                  },
                }
                // カスタマーリストを取得する
                const customerList = await getCustomerList(
                  result.claims['account-id'] as string,
                  result.claims['account-group-id'] as string,
                  result.claims['super-user'] as boolean
                )
                authedUser.customers = customerList
                // 初回ログインフラグを更新
                await MfaSettingApi.updateIsFirstLoginStatus()
                dispatch(appStateActions.setAuthed(true))
                dispatch(domainDataActions.setAuthedUser(authedUser))
                dispatch(loginActions.setLoginState('Loggedin'))
                dispatch(passwordUpdateActions.setNeedMfa(false))
              }
            } else {
              // MFA設定画面に遷移する
              dispatch(
                passwordUpdateActions.setNeedMfa(
                  accountsGroupDoc
                    ? accountsGroupDoc['mfa-group-setting'] === 'required'
                      ? true
                      : false
                    : false
                )
              )
            }
          })
          .catch(async (error: { code: string }) => {
            if (error.code === 'auth/requires-recent-login') {
              await authInstance.signOut()
            }
            console.error(error)
          })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(passwordUpdateActions.setInProgress(false))
      }
    },
}
