import { InferenceListAction } from './actions'
import { InferenceListActionType, InferenceList } from './types'

const initialState: InferenceList = {
  domainData: {
    currentInferenceList: [],
    inferenceDisplayCondition: {
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    currentInferenceListSnapshot: undefined,
    mlPipelineIds: [],
  },
  appState: {
    inProgress: false,
  },
}

export const InferenceListReducer = (
  state: InferenceList = initialState,
  action: InferenceListAction
): InferenceList => {
  switch (action.type) {
    case InferenceListActionType.SET_INFERENCE_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentInferenceList: action.payload.currentInferenceList,
        },
      }
    case InferenceListActionType.SET_INFERENCE_DISPLAY_CONDITION:
      if (action.payload.inferenceDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            inferenceDisplayCondition: action.payload.inferenceDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case InferenceListActionType.CLEAR_INFERENCE_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceDisplayCondition: {
            ...initialState.domainData.inferenceDisplayCondition,
          },
        },
      }
    case InferenceListActionType.SET_IN_PROGRESS_FOR_INFERENCE_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case InferenceListActionType.SET_ML_PIPELINE_IDS_FOR_INFERENCE_LIST:
      if (action.payload.mlPipelineIds) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            mlPipelineIds: action.payload.mlPipelineIds,
          },
        }
      } else {
        return { ...state }
      }
    case InferenceListActionType.SET_CURRENT_INFERENCE_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentInferenceListSnapshot:
            action.payload.currentInferenceListSnapshot,
        },
      }
    case InferenceListActionType.CLEAR_INFERENCE_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
