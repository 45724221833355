import { Timestamp } from 'state/firebase'
import { MediaLink, Version } from 'types/StateTypes'
import {
  ResourceLoadingState,
  ToastInfo,
  TransactionStatusKind,
} from 'state/utils'

export const FeatureDataDetailActionType = {
  SET_CURRENT_FEATURE_DATA_DETAIL: 'SET_CURRENT_FEATURE_DATA_DETAIL',
  SET_FEATURE_DATA_DETAIL_STATE: 'SET_FEATURE_DATA_DETAIL_STATE',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_DETAIL:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_DETAIL',
  SET_IN_PROGRESS_FOR_DOWNLOADING: 'SET_IN_PROGRESS_FOR_DOWNLOADING',
  SET_IN_PROGRESS_FOR_GETTING_FILES: 'SET_IN_PROGRESS_FOR_GETTING_FILES',
  SET_IN_PROGRESS_FOR_EVENT_HISTORIES_FOR_FEATURE_DATA_DETAIL:
    'SET_IN_PROGRESS_FOR_EVENT_HISTORIES_FOR_FEATURE_DATA_DETAIL',
  CLEAR_FEATURE_DATA_DETAIL_STATE: 'CLEAR_FEATURE_DATA_DETAIL_STATE',
  SET_TOAST_INFO_FOR_FEATURE_DATA_DETAIL:
    'SET_TOAST_INFO_FOR_FEATURE_DATA_DETAIL',
  SET_FEATURE_DATA_DISPLAY_CONDITION: 'SET_FEATURE_DATA_DISPLAY_CONDITION',
  SET_RELATED_FEATURE_DATA_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL:
    'SET_RELATED_FEATURE_DATA_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL',
  SET_FEATURE_DATA_DL_LINKS: 'SET_FEATURE_DATA_DL_LINKS',
  SET_ML_PIPELINE_GENERATE_FILES_FOR_FEATURE_DATA_DETAIL:
    'SET_ML_PIPELINE_GENERATE_FILES_FOR_FEATURE_DATA_DETAIL',
  SET_ML_PIPELINE_LIST_FOR_FEATURE_DATA_DETAIL:
    'SET_ML_PIPELINE_LIST_FOR_FEATURE_DATA_DETAIL',
  SET_ML_PIPELINE_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_DETAIL:
    'SET_ML_PIPELINE_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_DETAIL',
  SET_ML_PIPELINE_LIST_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL:
    'SET_ML_PIPELINE_LIST_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL',
  SET_FEATURE_DATA_EVENT_HISTORIES_STATE_FOR_FEATURE_DATA_DETAIL:
    '_FOR_FEATURE_DATA_DETAIL',
} as const

interface Algorithm {
  algorithmId: string
  metadata: {
    name: {
      ja: string
      en: string
    }
  }
  trainingAlgorithmVersion: string
}

type EvaluationStatusKind = 'Ne' | 'Passed' | 'Failed'

export interface Dataset {
  datasetId: string
  datasetName: string
}

interface Setting {
  settingId: string
  settingName: string
}

interface MlPipeline {
  mlPipelineId: string
  mlPipelineName: string
}
export interface MlPipelineGenerateFile {
  fileName: string
  fileLink: string
  fileSize: number
  createdAt: Date
}
export interface FeatureDataDlLink {
  linkName: string
  mediaLinks: MediaLink[]
  totalMediaSize: number
}

interface Progress {
  /** 進捗名 */
  transactionStatus: TransactionStatusKind
  /** 進捗率 */
  progressRate: number
}

export interface EventHistoryData {
  /** ML Pipeline 種別 */
  mlPipelineKind: 'FeatureDataGenerating' | 'FeatureDataTransferring'
  /** パイプライン種別 */
  mlPipelineId: string
  /** 進捗 */
  progress: Progress
  /** 開始時刻 */
  startedAt: Timestamp
  /** アカウントID（実行ユーザ） */
  accountId: string
}

export interface CurrentFeatureDataDetail {
  featureDataGroupId: string
  featureDataGroupName: string
  featureDataVersion: string
  relatedFeatureDataList: RelatedFeatureData[]
  featureDataListDisplayCondition: ListDisplayCondition
  featureDataId: string
  featureDataName: string
  featureDataRemarks: string
  evaluationStatus: EvaluationStatusKind
  mlPipeline: MlPipeline
  datasetList: Dataset[]
  setting: Setting
  trainingAlgorithm: Algorithm
  createdAt?: Timestamp
  createdUserName: AccountName | string
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface ListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface RelatedFeatureData {
  featureDataId: string
  featureDataVersion: Version
  featureDataName: string
}

export interface FeatureDataDetailState {
  featureDataGenerateFileSubState: ResourceLoadingState
  featureDataDlLinkSubState: ResourceLoadingState
  featureDataDataState: ResourceLoadingState
  featureDataEventHistoryState: ResourceLoadingState
}

export interface GetFeatureDataFilesResponse {
  data: {
    items: FeatureDataDlLink[]
  }
}

export interface FeatureDataDetailDomainData {
  currentFeatureDataDetail: CurrentFeatureDataDetail
  featureDataDlLinks: FeatureDataDlLink[]
  mlPipelineGenerateFiles: MlPipelineGenerateFile[]
  mlPipelineList: EventHistoryData[]
  mlPipelineListDisplayCondition: ListDisplayCondition
}

export interface FeatureDataDetailAppState {
  featureDataDetailState: FeatureDataDetailState
  toastInfo: ToastInfo | undefined
  inProgress: boolean
  isInProgressForDownloading: boolean
  isInProgressForGettingFiles: boolean
  isInProgressForEventHistories: boolean
  relatedFeatureDataTableNextPageSubState: 'Enable' | 'Unable'
  mlPipelineListTableNextPageSubState: 'Enable' | 'Unable'
}

export interface FeatureDataDetail {
  domainData: FeatureDataDetailDomainData
  appState: FeatureDataDetailAppState
}
