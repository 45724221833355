import { AccountGroupCustomerRelationDocument } from './types'
import { timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** account-customer-relation documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as AccountGroupCustomerRelationDocument
  const schema = {
    required: ['account-group-id', 'customer-list'],
    type: 'object',
    properties: {
      'account-group-id': {
        type: 'string',
      },
      'customer-list': {
        type: 'array',
        items: {
          required: ['customer-id'],
          type: 'object',
          properties: {
            'customer-id': {
              type: 'string',
            },
          },
        },
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'account-group-customer-relation',
      value: data as unknown as DataObject,
    })
    return false
  }
}
