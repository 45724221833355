import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Grid from '@mui/material/Grid'
import { Breakpoint } from '@mui/material/styles'

import { Tile } from 'views/components'

import { TileLayoutProps } from './types'

const useStyles = makeStyles()(() => ({
  gridList: {
    width: '100%',
  },
  card: {
    display: 'inline-block',
    width: '100%',
    zIndex: 0,
    position: 'relative',
    '&:hover': {
      '& $controller': {
        display: 'inline',
      },
    },
  },
}))

export const TileLayout: React.FC<TileLayoutProps> = (
  props: TileLayoutProps
) => {
  const colsOptions: { [key in Breakpoint]: number } = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
  }

  const { classes } = useStyles()

  return (
    <Grid container className={classes.gridList} spacing={2}>
      {props.tileList.map((tile) => {
        return (
          <Grid
            item
            {...Object.fromEntries(
              Object.entries(colsOptions).map(([key]) => {
                switch (key) {
                  case 'xs':
                    return [key, 6]
                  case 'sm':
                    return [key, 6]
                  case 'md':
                    return [key, 4]
                  case 'lg':
                    return [key, 3]
                  default:
                    return [key, 3]
                }
              })
            )}
            key={tile['data-testid']}
          >
            <div className={classes.card}>
              <Tile
                data-testid={tile['data-testid']}
                thumbnailUrl={tile.thumbnailUrl}
                name={tile.name}
                date={tile.date}
                description={tile.description}
                onClickCard={tile.onClickCard}
              />
            </div>
          </Grid>
        )
      })}
    </Grid>
  )
}
