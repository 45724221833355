import { AdminFeatureDataListActions } from './actions'
import {
  AdminFeatureDataInfoList,
  AdminFeatureDataListActionType,
} from './types'

const initialState: AdminFeatureDataInfoList = {
  domainData: {
    currentFeatureDataList: [],
    featureDataListDisplayCondition: {
      searchValue: '',
      sortKey: 'feature-data-group-version',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedAlgorithmId: '',
      selectedUserGroupKind: 'UserGroup',
    },
  },
  appState: {
    inProgress: false,
  },
}

export const AdminFeatureDataListReducer = (
  state: AdminFeatureDataInfoList = initialState,
  action: AdminFeatureDataListActions
): AdminFeatureDataInfoList => {
  switch (action.type) {
    case AdminFeatureDataListActionType.SET_FEATURE_DATA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataList: action.payload.featureDataList,
        },
      }
    case AdminFeatureDataListActionType.CLEAR_FEATURE_DATA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataList: [],
        },
      }
    case AdminFeatureDataListActionType.SET_FEATURE_DATA_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataListDisplayCondition:
            action.payload.featureDataListDisplayCondition,
        },
      }
    case AdminFeatureDataListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case AdminFeatureDataListActionType.CLEAR_FEATURE_DATA_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
