import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import { Build, BuildListActionType, BuildDisplayCondition } from './types'

export type BuildListAction = ActionsUnion<typeof buildListActions>

export const buildListActions = {
  setList: (
    currentBuildList: Build[]
  ): ActionWithPayload<'SET_BUILD_LIST', { currentBuildList: Build[] }> =>
    CreateAction(BuildListActionType.SET_BUILD_LIST, {
      currentBuildList,
    }),
  setListDisplayCondition: (
    buildDisplayCondition?: BuildDisplayCondition
  ): ActionWithPayload<
    'SET_BUILD_DISPLAY_CONDITION',
    { buildDisplayCondition?: BuildDisplayCondition }
  > =>
    CreateAction(BuildListActionType.SET_BUILD_DISPLAY_CONDITION, {
      buildDisplayCondition,
    }),
  clearDatasetDisplayCondition: (): Action<'CLEAR_BUILD_DISPLAY_CONDITION'> =>
    CreateAction(BuildListActionType.CLEAR_BUILD_DISPLAY_CONDITION),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_BUILD_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(BuildListActionType.SET_IN_PROGRESS_FOR_BUILD_LIST, {
      inProgress,
    }),
  setMLPipeLineIdList: (
    mlPipelineIds: string[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_IDS_FOR_BUILD_LIST',
    { mlPipelineIds: string[] }
  > =>
    CreateAction(BuildListActionType.SET_ML_PIPELINE_IDS_FOR_BUILD_LIST, {
      mlPipelineIds,
    }),
  clearBuildListState: (): Action<'CLEAR_BUILD_LIST_STATE'> =>
    CreateAction(BuildListActionType.CLEAR_BUILD_LIST_STATE),
  setCurrentBuildListSnapshot: (
    currentBuildListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_BUILD_LIST_SNAPSHOT',
    { currentBuildListSnapshot?: () => void }
  > =>
    CreateAction(BuildListActionType.SET_CURRENT_BUILD_LIST_SNAPSHOT, {
      currentBuildListSnapshot,
    }),
}
