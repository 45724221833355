import { Dispatch } from 'redux'
import { createSettingActions } from 'state/ducks/createSetting/actions'
import { CreateSettingApi } from './apis'

import {
  Setting,
  CreateSettingStateKindArray,
  SettingMetadata,
  CreateSettingFirestoreResult,
} from './types'
import { State } from 'state/store'

export const createSettingOperations = {
  setInputData:
    (file: File) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(createSettingActions.addSettingFile(file))
    },
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        createSettingActions.setSettingState(
          CreateSettingStateKindArray[currentStep + 1]
        )
      )
    },
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 0:
          return
        case 1:
          break
        default:
          break
      }

      dispatch(
        createSettingActions.setSettingState(
          CreateSettingStateKindArray[currentStep - 1]
        )
      )
    },
  createSetting:
    (
      setting: Setting,
      metadata: SettingMetadata,
      settingGroupId?: string,
      algorithmId?: string
    ) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(createSettingActions.setInProgress(true))

      const settingFile =
        getState().pages.currentCreateSettingState.domainData.settingFile
      if (settingFile === undefined) return

      const userGroupId =
        getState().app.domainData.authedUser.auth.customClaims.userGroupId

      try {
        // 署名付きURL取得
        const signedUrlData = (
          await CreateSettingApi.getSignedUrl({
            fileType: settingFile?.type ?? '',
            fileName: settingFile?.name ?? '',
          })
        ).data

        // セッティングファイルアップロード
        await CreateSettingApi.uploadSettingFile(signedUrlData.url, settingFile)

        // セッティングファイルのバリデーションチェック
        const isValidSettingFile = await CreateSettingApi.validate(
          signedUrlData.id ?? '',
          settingFile.name,
          userGroupId,
          algorithmId ?? ''
        )
        if (
          isValidSettingFile.data === undefined ||
          !isValidSettingFile.data.isValid
        ) {
          dispatch(createSettingActions.documentError())
          return
        }

        const settingFirestore = {
          settingId: signedUrlData.id ?? '',
          settingGroupId: settingGroupId ?? '',
          settingFormatId:
            isValidSettingFile.data.settingFormatVersion.settingFormatId,
          settingFormatVersion:
            isValidSettingFile.data.settingFormatVersion.settingFormatVersion,
          datasetTemplateId:
            isValidSettingFile.data.settingFormatVersion.datasetTemplateId,
          algorithmId: algorithmId ?? '',
          metadata: {
            name: metadata.name ?? '',
            remarks: metadata.remarks ?? '',
          },
        }
        const settingResult: CreateSettingFirestoreResult =
          await CreateSettingApi.settingFireStore(settingFirestore)

        dispatch(
          createSettingActions.setSettingId(settingResult.data.settingId)
        )
      } catch (error) {
        console.error(error)
        dispatch(createSettingActions.documentError())
      } finally {
        dispatch(createSettingActions.setInProgress(false))
      }
    },
}
