import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  FeatureDataGroupInfo,
  FeatureDataGroupListActionType,
  FeatureDataGroupListDisplayCondition,
} from './types'

export type FeatureDataGroupListActions = ActionsUnion<
  typeof featureDataGroupListActions
>

export const featureDataGroupListActions = {
  setFeatureDataGroupList: (
    featureDataGroupList: FeatureDataGroupInfo[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_LIST',
    { featureDataGroupList: FeatureDataGroupInfo[] }
  > =>
    CreateAction(FeatureDataGroupListActionType.SET_FEATURE_DATA_GROUP_LIST, {
      featureDataGroupList,
    }),
  clearFeatureDataGroupList: (): Action<'CLEAR_FEATURE_DATA_GROUP_LIST'> =>
    CreateAction(FeatureDataGroupListActionType.CLEAR_FEATURE_DATA_GROUP_LIST),
  setListDisplayCondition: (
    featureDataGroupListDisplayCondition: FeatureDataGroupListDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION',
    {
      featureDataGroupListDisplayCondition: FeatureDataGroupListDisplayCondition
    }
  > =>
    CreateAction(
      FeatureDataGroupListActionType.SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION,
      {
        featureDataGroupListDisplayCondition,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataGroupListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_LIST,
      {
        inProgress,
      }
    ),
  clearFeatureDataGroupListState:
    (): Action<'CLEAR_FEATURE_DATA_GROUP_LIST_STATE'> =>
      CreateAction(
        FeatureDataGroupListActionType.CLEAR_FEATURE_DATA_GROUP_LIST_STATE
      ),
}
