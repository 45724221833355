import { FeatureDataGeneratingAction } from './actions'
import {
  FeatureDataGeneratingActionType,
  FeatureDataGeneratingState,
} from './types'

const initialState: FeatureDataGeneratingState = {
  domainData: {
    selectedTrainingAlgorithm: undefined,
    selectedTrainingAlgorithmVersion: undefined,
    featureDataGroups: [],
    selectedFeatureDataGroupVersion: undefined,
    selectedAlgorithmStructureVersion: undefined,
    selectedFeatureDataGroup: undefined,
    datasetList: [],
    settingList: [],
    selectedDataset: undefined,
    selectedSetting: undefined,
    selectedFeatureDataGenerateKind: 'FeatureDataOnly',
    mlPipelinesMetaData: {
      name: '',
      remarks: '',
    },
    featureDataMetaData: {
      name: '',
      remarks: '',
    },
    executionInfo: undefined,
    trainingAlgorithmVersionDisplayCondition: {
      sortKey: 'version',
      sortOrder: 'desc',
    },
    featureDataGroupDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },

    datasetDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    settingDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    currentDatasetListSnapshot: undefined,
  },
  appState: {
    featureDataGeneratingState: 'TrainingAlgorithmState',
    featureDataGeneratingSubState: {
      trainingAlgorithmSubState: 'Unselected',
      featureDataGroupSubState: 'Unselected',
      datasetSubState: 'Unselected',
      settingSubState: 'Unselected',
      outputFormatSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
  },
}

export const FeatureDataGeneratingReducer = (
  state: FeatureDataGeneratingState = initialState,
  action: FeatureDataGeneratingAction
): FeatureDataGeneratingState => {
  switch (action.type) {
    case FeatureDataGeneratingActionType.SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroups: action.payload.featureDataGroups,
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedFeatureDataGroup: action.payload.selectedFeatureDataGroup,
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_FEATURE_DATA_GROUP_VERSION_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedFeatureDataGroupVersion: action.payload.selectedVersion,
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithmStructureVersion:
            action.payload.selectedAlgorithmStructureVersion,
        },
      }
    case FeatureDataGeneratingActionType.SET_DATASET_LIST_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetList: action.payload.datasetList,
        },
      }
    case FeatureDataGeneratingActionType.SET_SETTING_LIST_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingList: action.payload.settingList,
        },
      }
    case FeatureDataGeneratingActionType.SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      if (action.payload.featureDataGroupDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            featureDataGroupDisplayCondition:
              action.payload.featureDataGroupDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataGeneratingActionType.CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupDisplayCondition: {
            ...initialState.domainData.featureDataGroupDisplayCondition,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      if (action.payload.trainingAlgorithmDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            trainingAlgorithmVersionDisplayCondition: {
              ...action.payload.trainingAlgorithmDisplayCondition,
            },
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataGeneratingActionType.CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainingAlgorithmVersionDisplayCondition: {
            ...initialState.domainData.trainingAlgorithmVersionDisplayCondition,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      if (action.payload.datasetDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            datasetDisplayCondition: action.payload.datasetDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataGeneratingActionType.CLEAR_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetDisplayCondition: {
            ...initialState.domainData.datasetDisplayCondition,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      if (action.payload.settingDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            settingDisplayCondition: action.payload.settingDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataGeneratingActionType.CLEAR_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingDisplayCondition: {
            ...initialState.domainData.settingDisplayCondition,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithm: {
            algorithmId: action.payload.selectedTrainingAlgorithmId,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithmVersion:
            action.payload.selectedTrainingAlgorithmVersion,
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_DATASET_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDataset: action.payload.selectedDataset,
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_SETTING_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedSetting: action.payload.selectedSetting,
        },
      }
    case FeatureDataGeneratingActionType.SET_SELECTED_FEATURE_DATA_GENERATE_KIND_FOR_FEATURE_DATA_GENERATING: {
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedFeatureDataGenerateKind:
            action.payload.selectedFeatureDataGenerateKind,
        },
      }
    }
    case FeatureDataGeneratingActionType.SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelinesMetaData: action.payload.mlPipelinesMetaData,
        },
      }
    case FeatureDataGeneratingActionType.SET_FEATURE_DATA_META_DATA_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataMetaData: action.payload.featureDataMetaData,
        },
      }
    case FeatureDataGeneratingActionType.SET_TRAINING_ALGORITHM_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            trainingAlgorithmSubState: action.payload.trainingAlgorithmSubState,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            featureDataGroupSubState: action.payload.featureDataGroupSubState,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_DATASET_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            datasetSubState: action.payload.datasetSubState,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_SETTING_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            settingSubState: action.payload.settingSubState,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_OUTPUT_FORMAT_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            outputFormatSubState: action.payload.outputFormatSubState,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            metaDataSubState: action.payload.metaDataSubState,
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingState:
            action.payload.featureDataGeneratingStateKind,
        },
      }
    case FeatureDataGeneratingActionType.CLEAR_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...initialState,
      }
    case FeatureDataGeneratingActionType.EXEC_FEATURE_DATA_GENERATING_SUCCESS_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            executeSubState: 'Executed',
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataGeneratingActionType.EXEC_FEATURE_DATA_GENERATING_FAILURE_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingSubState: {
            ...state.appState.featureDataGeneratingSubState,
            executeSubState: 'ExecuteError',
          },
        },
      }
    case FeatureDataGeneratingActionType.SET_EXECUTED_INFO_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executionInfo: action.payload.executedInfo,
        },
      }
    case FeatureDataGeneratingActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetListSnapshot: action.payload.currentDatasetListSnapshot,
        },
      }
    default:
      return state
  }
}
