import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { CommonCompleteDialogProps } from './types'

const useStyles = makeStyles()((theme) => ({
  dialogChild: {
    margin: theme.spacing(1.5),
  },
  textField: {
    margin: theme.spacing(1),
    width: '98%',
  },
}))

export const CommonCompleteDialog: React.FC<CommonCompleteDialogProps> = (
  props: CommonCompleteDialogProps
) => {
  const { classes } = useStyles()

  return (
    <Dialog open={props.open} fullWidth maxWidth={'sm'}>
      <div className={classes.dialogChild}>
        <Typography data-testid={'dialog-message'}>
          {props.dialogText}
        </Typography>
        <TextField
          label={props.label}
          defaultValue={props.value}
          InputProps={{
            readOnly: true,
          }}
          className={classes.textField}
          variant='standard'
        />
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color='primary'
            data-testid={
              props['data-testid']
                ? `${props['data-testid']}-button-close`
                : undefined
            }
          >
            <b>閉じる</b>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
