import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { CreateAugParamTemplateRequestArgsType } from './types'

export const ScenePresetApi = {
  augParamTemplateFireStore: (
    augParamTemplate: CreateAugParamTemplateRequestArgsType
  ): Promise<HttpsCallableResult<{ augParamTemplateId: string }>> =>
    httpsCallable<
      { augParamTemplate: CreateAugParamTemplateRequestArgsType },
      { augParamTemplateId: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-aug-param-template-firestore'
    )({
      augParamTemplate,
    }),
}
