import { Timestamp } from 'state/firebase'

export const ModelGroupListActionType = {
  SET_MODEL_GROUP_LIST: 'SET_MODEL_GROUP_LIST',
  CLEAR_MODEL_GROUP_LIST: 'CLEAR_MODEL_GROUP_LIST',
  SET_MODEL_GROUP_LIST_DISPLAY_CONDITION:
    'SET_MODEL_GROUP_LIST_DISPLAY_CONDITION',
  SET_IN_PROGRESS_FOR_MODEL_GROUP_LIST: 'SET_IN_PROGRESS_FOR_MODEL_GROUP_LIST',
  CLEAR_MODEL_GROUP_LIST_STATE: 'CLEAR_MODEL_GROUP_LIST_STATE',
} as const

export interface ModelGroupInfo {
  modelGroupId: string
  modelGroupName: string
  algorithmName: string
  modelCount: number
  updatedAt: Timestamp
  createAt: Timestamp
  createdUserId: string
}

export interface ModelGroupListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
  algorithmId?: string
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface ModelGroupListDomainData {
  currentModelGroupList: ModelGroupInfo[]
  modelGroupListDisplayCondition: ModelGroupListDisplayCondition
}

export interface ModelGroupListAppState {
  inProgress: boolean
}

export interface ModelGroupInfoListState {
  domainData: ModelGroupListDomainData
  appState: ModelGroupListAppState
}
