import { Dispatch } from 'redux'
import {
  getTrainedModelGroupMetadataCollection,
  getTrainedModelGroupQueriesCollection,
  getAccountSettingCollection,
  getTrainedModelQueriesCollection,
  getClassSetMetaDataCollection,
  getSettingsCollection,
  getSettingsMetaDataCollection,
} from 'state/firebase'
import { modelGroupDetailActions } from './'
import { Extended, RelatedTrainedModel, Setting, ToastInfo } from './types'
import { compareVersions } from 'utils/versions'
import { State } from 'state/store'
import { ModelGroupDetailApi } from './apis'
import { isUndefined } from 'utils/typeguard'
import {
  DocumentData,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { HttpsCallableResult } from 'firebase/functions'
import { fireStoreTypeGuard as fireStoreTypeGuardForSettingsDocument } from 'utils/fireStore/settings'
import { fireStoreTypeGuard as fireStoreTypeGuardForSettingMetadataDocument } from 'utils/fireStore/settingMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaDataDocument } from 'utils/fireStore/classSetMetaData'
import { domainDataOperations } from 'state/app/domainData/operations'

type TrainedModelVersion = {
  ['display-name']: string
  ['major']: number
  ['minor']: number
  ['patch']: number
}

type TrainedModel = {
  ['trained-model-group-version']: TrainedModelVersion
  ['trained-model-id']: string
  ['trained-model-name']: string
  ['inherited-version']: {
    ['trained-model-group-id']: string
    ['trained-model-group-version']: {
      ['display-name']: string
      ['major']: number
      ['minor']: number
      ['patch']: number
    }
    ['trained-model-id']: string
    ['user-group-id']: string
  }
  ['transaction-status']: string
}

const getTrainedModelList = async (
  userGroupId: string,
  isSharedUserGroup: boolean,
  list: TrainedModel[]
): Promise<RelatedTrainedModel[]> => {
  const modelList = await Promise.all(
    list.map(async (item) => {
      const relatedTrainedModelData = (
        await getDocs(
          isSharedUserGroup
            ? query(
                getTrainedModelQueriesCollection(userGroupId),
                where('trained-model-id', '==', item['trained-model-id']),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            : query(
                getTrainedModelQueriesCollection(userGroupId),
                where('trained-model-id', '==', item['trained-model-id'])
              )
        )
      ).docs[0]?.data()

      return {
        trainedModelId: item ? item['trained-model-id'] : '',
        trainedModelVersion: {
          displayName: item
            ? item['trained-model-group-version']['display-name']
            : '',
          major: item ? item['trained-model-group-version']['major'] : 0,
          minor: item ? item['trained-model-group-version']['minor'] : 0,
          patch: item ? item['trained-model-group-version']['patch'] : 0,
        },
        trainedModelName: item ? item['trained-model-name'] : '',
        inheritedVersion:
          relatedTrainedModelData &&
          relatedTrainedModelData['inherited-version'] &&
          relatedTrainedModelData['inherited-version'][
            'trained-model-group-version'
          ]['display-name'] !== '0.0.0'
            ? {
                displayName:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['display-name'],
                major:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['major'],
                minor:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['minor'],
                patch:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['patch'],
              }
            : {
                displayName: '',
                major: 0,
                minor: 0,
                patch: 0,
              },
        transactionStatus: item
          ? (item['transaction-status'] as
              | 'trained'
              | 'built'
              | 'transfered'
              | undefined)
          : undefined,
      }
    })
  )
  if (modelList.length === 1) return modelList
  // バージョンでソートして返す
  return modelList.sort((a, b) =>
    compareVersions(a.trainedModelVersion, b.trainedModelVersion, 'desc')
  )
}

export const ModelGroupDetailOperations = {
  /** モデル詳細で表示パラメータを取得する */
  getModelGroupDetail:
    (modelGroupId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupDetailActions.setInProgress(true))
        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        /** trained-model-query document */
        const trainedModelGroupQuery = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  where('trained-model-group-id', '==', modelGroupId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((modelGroup) => modelGroup.data())[0]
          : (
              await getDoc(
                doc(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  modelGroupId
                )
              )
            ).data()

        if (!trainedModelGroupQuery) {
          dispatch(
            modelGroupDetailActions.setModelGroupDataState('NotFoundProcessed')
          )
          return
        }

        if (
          !fireStoreTypeGuardForModelGroupQueryDocument(trainedModelGroupQuery)
        ) {
          dispatch(modelGroupDetailActions.setModelGroupDataState('Failed'))
          return
        }

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                trainedModelGroupQuery
                  ? trainedModelGroupQuery['created-by']
                  : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(modelGroupDetailActions.setModelGroupDataState('Failed'))
          return
        }

        const algorithmDoc = getState().app.domainData.algorithms.find(
          (item) => item.algorithmId === trainedModelGroupQuery['algorithm-id']
        )

        let iconUrl = undefined

        try {
          iconUrl = (await ModelGroupDetailApi.getSignedUrl(
            trainedModelGroupQuery
              ? trainedModelGroupQuery['trained-model-group-id']
              : '',
            trainedModelGroupQuery
              ? trainedModelGroupQuery['icon-name'] ?? ''
              : ''
          )) as HttpsCallableResult<{ url: string }>
        } catch {
          iconUrl = undefined
        }

        let extended: Extended | undefined = undefined
        if (
          !isUndefined(trainedModelGroupQuery) &&
          !isUndefined(trainedModelGroupQuery['extended'])
        ) {
          const currentUserGroupId =
            getState().app.domainData.authedUser.auth.customClaims.userGroupId
          extended = await getExtendedField(
            trainedModelGroupQuery,
            currentUserGroupId
          )
        }

        dispatch(
          modelGroupDetailActions.setCurrentModelGroupDetail({
            trainedModelGroupId: trainedModelGroupQuery
              ? trainedModelGroupQuery['trained-model-group-id']
              : '',
            trainedModelGroupName: trainedModelGroupQuery
              ? trainedModelGroupQuery['trained-model-group-name']
              : '',
            relatedTrainedModelList: trainedModelGroupQuery
              ? await getTrainedModelList(
                  userGroupId,
                  isSharedUserGroup,
                  trainedModelGroupQuery['trained-model-list']
                )
              : [],
            remarks: trainedModelGroupQuery
              ? trainedModelGroupQuery.remarks ?? ''
              : '',
            trainingAlgorithm: {
              algorithmId: trainedModelGroupQuery
                ? trainedModelGroupQuery['algorithm-id'] ?? ''
                : '',
              algorithmName: algorithmDoc
                ? algorithmDoc.metadata?.name.ja ?? ''
                : '',
            },
            createdAt: trainedModelGroupQuery
              ? trainedModelGroupQuery['created-at']
              : undefined,
            createdBy: accountSetting
              ? {
                  firstName: accountSetting['first-name'] ?? '',
                  familyName: accountSetting['family-name'] ?? '',
                }
              : trainedModelGroupQuery
              ? trainedModelGroupQuery['created-by'] ?? ''
              : '',
            overview: trainedModelGroupQuery
              ? trainedModelGroupQuery['overview'] ?? ''
              : '',
            iconName: trainedModelGroupQuery
              ? trainedModelGroupQuery['icon-name'] ?? ''
              : '',
            iconFileType: trainedModelGroupQuery
              ? trainedModelGroupQuery['icon-filetype'] ?? ''
              : '',
            iconUrl: iconUrl ? iconUrl.data.url : '',
            trainedModelListDisplayCondition: {
              sortKey: 'modelVersion',
              sortOrder: 'desc',
              displayNumber: 10,
              pageNumber: 0,
            },
            constraint: {
              setting: {
                settingGroupId:
                  trainedModelGroupQuery['constraint']?.['setting']?.[
                    'setting-group-id'
                  ] ?? '',
                userGroupId:
                  trainedModelGroupQuery['constraint']?.['setting']?.[
                    'user-group-id'
                  ] ?? '',
              },
            },
            extended,
          })
        )
        dispatch(modelGroupDetailActions.setModelGroupDataState('Loaded'))
      } catch (error) {
        console.error(error)
        dispatch(modelGroupDetailActions.setModelGroupDataState('Failed'))
      } finally {
        dispatch(modelGroupDetailActions.setInProgress(false))
      }
    },
  /** モデルグループの名前を更新 */
  updateModelGroupDetailName:
    (docId: string, name: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        await updateDoc(
          doc(getTrainedModelGroupMetadataCollection(userGroupId), docId),
          {
            name: name,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )

        dispatch(
          modelGroupDetailActions.setCurrentModelGroupDetail({
            ...getState().pages.modelGroupDetailState.domainData
              .currentTrainedModelGroupDetail,
            trainedModelGroupName: name,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelGroupDetailActions.setInProgress(false))
      }
    },
  /** モデルグループの備考を更新 */
  updateModelGroupDetailRemarks:
    (docId: string, remarks: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Model Group IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getTrainedModelGroupMetadataCollection(userGroupId), docId),
          {
            remarks: remarks,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelGroupDetailActions.setInProgress(false))
      }
    },
  /** モデルグループの概要を更新 */
  updateModelGroupOverview:
    (docId: string, overview: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Model Group IDと一致したdocの概要を更新する
        await updateDoc(
          doc(getTrainedModelGroupMetadataCollection(userGroupId), docId),
          {
            overview: overview,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelGroupDetailActions.setInProgress(false))
      }
    },
  /** モデルグループのアイコンを更新 */
  updateModelGroupIcon:
    (docId: string, uploadModelGroupIcon: File) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // アップロードされている画像のsignedUrl
        const thumbnailUrl =
          getState().pages.modelGroupDetailState.domainData
            .currentTrainedModelGroupDetail.iconUrl
        // アップロードされている画像の名前
        const iconName =
          getState().pages.modelGroupDetailState.domainData
            .currentTrainedModelGroupDetail.iconName

        // アップロードされている元の画像を削除
        if (thumbnailUrl !== '') {
          const deleteUrl = (await ModelGroupDetailApi.getDeleteSignedUrl(
            docId,
            iconName
          )) as HttpsCallableResult<{ url: string }>
          await ModelGroupDetailApi.deleteUploadedIcon(deleteUrl.data.url)
          // ドキュメントを更新
          await updateDoc(
            doc(getTrainedModelGroupMetadataCollection(userGroupId), docId),
            {
              'icon-name': '',
              'icon-filetype': '',
              ['updated-by']:
                getState().app.domainData.authedUser.auth.customClaims
                  .accountId,
              ['updated-at']: new Date(),
            }
          )
        }
        // アップロードのsignedUrl取得
        const response = (await ModelGroupDetailApi.getFileUploadSignedUrl(
          docId,
          uploadModelGroupIcon.name
        )) as HttpsCallableResult<{ url: string }>
        try {
          // アップロード処理
          // 成功時にドキュメントを更新する
          // 失敗時にアップロードしたiconを削除する
          await ModelGroupDetailApi.executeFileUpload(
            response.data.url,
            uploadModelGroupIcon
          )
          const toastInfo: ToastInfo = {
            type: 'info',
            title: 'アイコンをアップロードしました。',
            targets: [],
          }
          dispatch(modelGroupDetailActions.setToastInfo(toastInfo))
          try {
            // ドキュメントを更新
            await updateDoc(
              doc(getTrainedModelGroupMetadataCollection(userGroupId), docId),
              {
                'icon-name': uploadModelGroupIcon.name,
                'icon-filetype': uploadModelGroupIcon.type,
                ['updated-by']:
                  getState().app.domainData.authedUser.auth.customClaims
                    .accountId,
                ['updated-at']: new Date(),
              }
            )
            let thumbnailUrl = undefined

            try {
              thumbnailUrl = (await ModelGroupDetailApi.getSignedUrl(
                docId,
                uploadModelGroupIcon.name
              )) as HttpsCallableResult<{ url: string }>
              if (!isUndefined(thumbnailUrl)) {
                dispatch(
                  modelGroupDetailActions.setIconUrl(thumbnailUrl.data.url)
                )
              }
            } catch (error) {
              // アップロードされた画像の取得失敗時処理
              console.error(error)
              const toastInfo: ToastInfo = {
                type: 'error',
                title: '画像の取得に失敗しました。',
                targets: [],
              }
              dispatch(modelGroupDetailActions.setToastInfo(toastInfo))
            }
          } catch (error) {
            // ドキュメント更新失敗時処理
            console.error(error)
            const toastInfo: ToastInfo = {
              type: 'error',
              title: 'ドキュメントの更新に失敗しました。',
              targets: [],
            }
            dispatch(modelGroupDetailActions.setToastInfo(toastInfo))
            // アップロードしたiconを削除する
            const deleteUrl = (await ModelGroupDetailApi.getDeleteSignedUrl(
              docId,
              uploadModelGroupIcon.name
            )) as HttpsCallableResult<{ url: string }>
            await ModelGroupDetailApi.deleteUploadedIcon(deleteUrl.data.url)
          }
        } catch (error) {
          // アップロード失敗処理
          console.error(error)
          const toastInfo: ToastInfo = {
            type: 'error',
            title: 'アップロードに失敗しました。',
            targets: [],
          }
          dispatch(modelGroupDetailActions.setToastInfo(toastInfo))
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelGroupDetailActions.setInProgress(false))
      }
    },
  /** モデルグループのアイコンを削除 */
  deleteModelGroupIcon:
    (docId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // アップロードされている画像のsignedUrl
        const iconName =
          getState().pages.modelGroupDetailState.domainData
            .currentTrainedModelGroupDetail.iconName

        try {
          const deleteUrl = (await ModelGroupDetailApi.getDeleteSignedUrl(
            docId,
            iconName
          )) as HttpsCallableResult<{ url: string }>
          await ModelGroupDetailApi.deleteUploadedIcon(deleteUrl.data.url)
          // ドキュメントを更新
          await updateDoc(
            doc(getTrainedModelGroupMetadataCollection(userGroupId), docId),
            {
              'icon-name': '',
              'icon-filetype': '',
              ['updated-by']:
                getState().app.domainData.authedUser.auth.customClaims
                  .accountId,
              ['updated-at']: new Date(),
            }
          )
          dispatch(modelGroupDetailActions.setIconUrl(''))
          const toastInfo: ToastInfo = {
            type: 'info',
            title: 'アイコンを削除しました。',
            targets: [],
          }
          dispatch(modelGroupDetailActions.setToastInfo(toastInfo))
        } catch (error) {
          // ドキュメント作成失敗時処理
          console.error(error)
          const toastInfo: ToastInfo = {
            type: 'error',
            title: 'アイコンの削除に失敗しました。',
            targets: [],
          }
          dispatch(modelGroupDetailActions.setToastInfo(toastInfo))
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelGroupDetailActions.setInProgress(false))
      }
    },
  getSettingList:
    (isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State) => {
      try {
        dispatch(
          modelGroupDetailActions.setIsInProgressForGettingSettingList(true)
        )

        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const settingGroupId =
          getState().pages.modelGroupDetailState.domainData
            .currentTrainedModelGroupDetail.constraint?.setting?.settingGroupId
        if (settingGroupId === undefined) {
          return
        }

        const settingDocs = (
          await getDocs(
            isSharedUserGroup
              ? query(
                  getSettingsCollection(userGroupId),
                  where('setting-group-id', '==', settingGroupId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              : query(
                  getSettingsCollection(userGroupId),
                  where('setting-group-id', '==', settingGroupId)
                )
          )
        ).docs

        const settingList: Setting[] = (
          await Promise.all(
            settingDocs.map(async (setting) => {
              const settingData = setting.data()
              if (!fireStoreTypeGuardForSettingsDocument(settingData)) {
                return
              }

              const settingMetadataDocs = (
                await getDocs(
                  isSharedUserGroup
                    ? query(
                        getSettingsMetaDataCollection(userGroupId),
                        where('access-control.is-shared', '==', true),
                        where(
                          'access-control.share-permissions.webapp',
                          '==',
                          'list'
                        ),
                        where('setting-id', '==', settingData['setting-id'])
                      )
                    : query(
                        getSettingsMetaDataCollection(userGroupId),
                        where('setting-id', '==', settingData['setting-id'])
                      )
                )
              ).docs

              const settingMetadataData = settingMetadataDocs
                .map((settingMetadataDoc) => {
                  const data = settingMetadataDoc.data()
                  if (!fireStoreTypeGuardForSettingMetadataDocument(data)) {
                    return
                  }
                  return data
                })
                .filter((item) => item !== undefined)
                .at(0)
              if (settingMetadataData === undefined) return

              const datasetTemplate =
                getState().app.domainData.datasetTemplates.find(
                  (datasetTemplate) =>
                    datasetTemplate.datasetTemplateId ===
                    settingData['dataset-template-id']
                )

              return {
                settingId: settingData['setting-id'],
                name: settingMetadataData?.['name'],
                remarks: settingMetadataData?.['remarks'] ?? '',
                settingGroupVersion: {
                  displayName:
                    settingData['setting-group-version']['display-name'],
                  major: settingData['setting-group-version']['major'],
                  minor: settingData['setting-group-version']['minor'],
                  patch: settingData['setting-group-version']['patch'],
                },
                datasetTemplate: {
                  datasetTemplateId: settingData['dataset-template-id'],
                  metadata: {
                    name: datasetTemplate?.metadata.name ?? { en: '', ja: '' },
                  },
                },
                createdAt: settingData['created-at'],
              }
            })
          )
        ).filter((setting) => setting !== undefined) as Setting[]

        dispatch(modelGroupDetailActions.setSettingList(settingList))

        const currentSettingListDisplayCondition =
          getState().pages.modelGroupDetailState.domainData
            .settingListDisplayCondition
        dispatch(
          modelGroupDetailActions.setSettingListDisplayCondition({
            ...currentSettingListDisplayCondition,
            pageNumber: 0,
            totalCount: settingList.length,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(
          modelGroupDetailActions.setIsInProgressForGettingSettingList(false)
        )
      }
    },
}

const getExtendedField = async (
  trainedModelQuery: DocumentData,
  userGroupId: string
): Promise<Extended | undefined> => {
  // 物体クラス分類の場合
  if (trainedModelQuery['extended']['object-classification']) {
    const classSetId =
      (trainedModelQuery['extended']['object-classification']['class-set'][
        'class-set-id'
      ] as string) ?? ''
    const classSetUserGroupId =
      (trainedModelQuery['extended']['object-classification']['class-set'][
        'user-group-id'
      ] as string) ?? ''
    const classSetMetaData = (
      await getDocs(
        userGroupId === classSetUserGroupId
          ? query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId)
            )
          : query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId),
              where('access-control.is-shared', '==', true),
              where('access-control.share-permissions.webapp', '==', 'list')
            )
      )
    ).docs[0].data()
    if (!fireStoreTypeGuardForClassSetMetaDataDocument(classSetMetaData)) {
      return
    }

    const classSetName = classSetMetaData['name']

    return {
      objectClassification: {
        classSet: {
          classSetId,
          classSetName,
          userGroupId: classSetUserGroupId,
        },
      },
    }
  }

  return undefined
}
