import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  useHistory,
  Link,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import Box from '@mui/material/Box'
import Add from '@mui/icons-material/Add'

import { State } from 'state/store'
import {
  DatasetAugmentationListAction,
  datasetAugmentationListActions,
  datasetAugmentationListOperations,
  DatasetAugmentationListDisplayCondition,
  DatasetAugmentation,
} from 'state/ducks/datasetAugmentationList'

import { isUndefined } from 'utils/typeguard'
import {
  DatasetAugmentationIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  StatusProgressBar,
  RunningTimeLabel,
  GlobalLoading,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
  BreadcrumbsComponent,
} from 'views/components'
import {
  convertProgressWord,
  convertProgressColor,
} from 'views/containers/utils'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
} from 'views/components/utils/date'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import { Link as MuiLink } from '@mui/material'

const mapStateToProps = (state: State) => ({
  ...state.pages.datasetAugmentationListState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, DatasetAugmentationListAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** データセットオーグメンテーションの一覧取得 */
  getDatasetAugmentationList: () =>
    dispatch(datasetAugmentationListOperations.getDatasetAugmentationList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (
    listCondition: DatasetAugmentationListDisplayCondition
  ) =>
    dispatch(
      datasetAugmentationListActions.setListDisplayCondition(listCondition)
    ),
  /** Stateのクリア */
  clearDatasetAugmentationListState: () =>
    dispatch(
      datasetAugmentationListActions.clearDatasetAugmentationListState()
    ),
  /** snapshotの購読解除 */
  unsubscribe: () => dispatch(datasetAugmentationListOperations.unsubscribe()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'mlPipelineId',
    title: 'ML Pipeline ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: 'Augmentation名',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'progress',
    title: '進捗',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'dataset',
    title: '生成データセット',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'annotationFormatKind',
    title: 'アノテーション種別',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'time',
    title: '実行時間',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ml-pipeline.started-at',
    title: '開始日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'ended-at',
    title: '終了日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'executionUser',
    title: '実行ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const DatasetAugmentationList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  useEffect(() => {
    return () => {
      props.unsubscribe()
    }
  }, [])

  useEffect(() => {
    props.getDatasetAugmentationList()
    return () => {
      props.clearDatasetAugmentationListState()
    }
  }, [props.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  } = useTable(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.datasetAugmentationListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.datasetAugmentationListDisplayCondition.searchValue])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: DatasetAugmentation[] =
      props.domainData.currentDatasetAugmentationList.map(
        (datasetAugmentation: DatasetAugmentation) => {
          return {
            mlPipelineId: datasetAugmentation.mlPipelineId,
            augmentationName: datasetAugmentation.augmentationName,
            progress: datasetAugmentation.progress,
            generatedDataset: {
              datasetId: datasetAugmentation.generatedDataset.datasetId,
              datasetName: datasetAugmentation.generatedDataset.datasetName,
            },
            annotationFormatKind: datasetAugmentation.annotationFormatKind,
            time: '',
            startedAt: datasetAugmentation.startedAt,
            endedAt: datasetAugmentation.endedAt,
            accountId: datasetAugmentation.accountId,
          }
        }
      )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'progress':
            return (
              <StatusProgressBar
                status={convertProgressWord(data.progress.transactionStatus)}
                progressRate={data.progress.progressRate}
                progressColor={convertProgressColor(
                  data.progress.transactionStatus
                )}
              />
            )
          case 'mlPipelineId':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            ) : (
              <TooltipLink
                data-testid={`dataset-augmentations-${value}`}
                title={value}
                placement='right-start'
                onClick={() => {
                  history.push(`dataset-augmentations/${value}`)
                }}
              />
            )
          case 'annotationFormatKind':
            return (
              <Typography key={key}>
                {value ? value : TABLE_CELL_NOT_APPLICABLE}
              </Typography>
            )
          case 'generatedDataset':
            return value.datasetName ? (
              <MuiLink
                data-testid={`generate-dataset-${value.datasetId}`}
                className={classes.link}
                underline='none'
                onClick={() => {
                  history.push(`datasets/${value.datasetId}`)
                }}
              >
                <Typography key={key}>{value.datasetName}</Typography>
              </MuiLink>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'startedAt':
          case 'endedAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            } else if (key === 'endedAt' && value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'time':
            // 成功、失敗かつ終了時刻が存在しないまたは、UnixTime0の場合は、N/Aとする
            if (
              (['Completed', 'Failed'].includes(
                data.progress.transactionStatus
              ) &&
                !data.endedAt) ||
              data.endedAt?.seconds === 0
            ) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return !isUndefined(data.startedAt) &&
              !isUndefined(data.endedAt) ? (
              <Typography key={key}>
                {formatTimeSecByMillSecond(
                  data.endedAt.toDate().getTime() -
                    data.startedAt.toDate().getTime()
                )}
              </Typography>
            ) : (
              <RunningTimeLabel startedAt={data.startedAt.toDate()} />
            )
          case 'augmentationName':
            if (value) {
              return (
                <Tooltip title={value} placement='bottom'>
                  <Typography>{value}</Typography>
                </Tooltip>
              )
            } else {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          case 'accountId':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          default:
            return (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [props.domainData.currentDatasetAugmentationList])

  /** テーブル */
  const datasetAugmentationListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.datasetAugmentationListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={
          props.domainData.datasetAugmentationListDisplayCondition.totalCount
        }
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={
          props.domainData.datasetAugmentationListDisplayCondition.pageNumber
        }
        sortOrder={{
          key: props.domainData.datasetAugmentationListDisplayCondition.sortKey,
          order:
            props.domainData.datasetAugmentationListDisplayCondition.sortOrder,
        }}
        includesStatusProgressBar={true}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.datasetAugmentationListDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <Box className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'オーグメンテーション一覧',
              path: 'dataset-augmentations',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex'>
            <DatasetAugmentationIcon
              className={classes.pageIcon}
              data-testid='DatasetAugmentationTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='dataset-augmentations-list-title'>
                オーグメンテーション
              </h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link data-testid='dataset-augmentations-entry' to={`${url}/entry`}>
              <Typography>データセット生成 (3D CAD データ) 開始</Typography>
            </Link>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (ML Pipeline ID)'
                value={
                  props.domainData.datasetAugmentationListDisplayCondition
                    .searchValue
                }
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {datasetAugmentationListTable}
        </CustomTrainingPageParagraph>
      </Box>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const DatasetAugmentationListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetAugmentationList))

const useTable = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    /** 検索ワードを変更 */
    props.setListDisplayCondition({
      ...props.domainData.datasetAugmentationListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.datasetAugmentationListDisplayCondition,
      pageNumber: 0,
      displayNumber: displayNumber,
    })

    props.getDatasetAugmentationList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.datasetAugmentationListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.datasetAugmentationListDisplayCondition.sortOrder ===
        'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getDatasetAugmentationList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.datasetAugmentationListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getDatasetAugmentationList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.setListDisplayCondition({
      ...props.domainData.datasetAugmentationListDisplayCondition,
      pageNumber: 0,
    })

    props.getDatasetAugmentationList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  }
}
