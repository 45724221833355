import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { makeStyles } from 'tss-react/mui'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'

import { State } from 'state/store'
import {
  AlgorithmStructureVersion,
  TrainingAlgorithmVersion,
} from 'state/app/domainData'
import {
  FeatureDataGeneratingAction,
  featureDataGeneratingActions,
  featureDataGeneratingOperations,
  MetaData,
  Dataset,
  Setting,
  FeatureDataGeneratingStateKindArray,
  DisplayCondition,
  AlgorithmDisplayCondition,
  FeatureDataGroup,
  FeatureDataGeneratingStateKind,
} from 'state/ducks/featureDataGenerating'

import { Version, VersionWithPreRelease } from 'types/StateTypes'
import { isUndefined, isString } from 'utils/typeguard'
import { compareVersionsWithPreRelease, compareVersions } from 'utils/versions'
import {
  SelectableTable,
  AccordionLabel,
  SearchInput,
  MLPipelineCompleteDialog,
  CommonStepper,
  MetadataInput,
  PostAddIconButton,
  CustomTrainingPageParagraph,
  ErrorMessage,
  GlobalLoading,
  InformationDialog,
  TableDialog,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  ConfirmViewerDialog,
  BreadcrumbsComponent,
} from 'views/components'
import { CreateDatasetPage, CreateSettingPage } from 'views/containers/pages'
import { formatDateTimeSec } from 'views/components/utils/date'
import { isVersionWithPreRelease } from 'views/containers/utils/typeguard'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useTheme } from '@mui/material/styles'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataGeneratingState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataGeneratingAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (
    featureDataGeneratingStateKind: FeatureDataGeneratingStateKind
  ) => {
    switch (featureDataGeneratingStateKind) {
      case 'FeatureDataGroupState':
        dispatch(featureDataGeneratingOperations.getFeatureDataGroupList())
        break
      case 'DatasetState':
        dispatch(featureDataGeneratingOperations.getDatasetList())
        break
      case 'SettingState':
        dispatch(featureDataGeneratingOperations.getSettingList())
        break
      default:
        break
    }
  },
  /** データセットの一覧取得 */
  getDatasetList: () => {
    dispatch(featureDataGeneratingOperations.getDatasetList())
  },
  /** データセットの一覧取得 */
  getSettingList: () => {
    dispatch(featureDataGeneratingOperations.getSettingList())
  },
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: FeatureDataGeneratingStateKind) => {
    if (
      FeatureDataGeneratingStateKindArray.indexOf(currentStep) >=
      FeatureDataGeneratingStateKindArray.length - 1
    ) {
      /** 特徴量データ生成情報開始 */
      dispatch(featureDataGeneratingOperations.executeFeatureDataGenerating())
    } else {
      dispatch(featureDataGeneratingOperations.nextStep(currentStep))
    }
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: FeatureDataGeneratingStateKind) => {
    dispatch(featureDataGeneratingOperations.prevStep(currentStep))
  },
  /** 選んだアルゴリズムIDをセットする(Training Algorithm Id) */
  setSelectedTrainingAlgorithmId: (algorithmId: string) => {
    dispatch(
      featureDataGeneratingActions.setSelectedTrainingAlgorithmId(algorithmId)
    )
  },
  /** 選んだリストをセットする(Training Algorithm) */
  setSelectedTrainingAlgorithmVersion: (
    algorithmVersion: TrainingAlgorithmVersion
  ) => {
    dispatch(
      featureDataGeneratingOperations.setSelectedTrainingAlgorithmVersion(
        algorithmVersion
      )
    )
  },
  /** 選んだリストをセットする(Dataset) */
  setSelectedDataset: (data?: Dataset) =>
    dispatch(featureDataGeneratingOperations.setSelectedDataset(data)),
  /** 選んだリストをセットする(Setting) */
  setSelectedSetting: (data: Setting | undefined, assignmentSetting: boolean) =>
    dispatch(
      featureDataGeneratingOperations.setSelectedSetting(
        data,
        assignmentSetting
      )
    ),
  /** 選んだアウトプット形式をセットする（アウトプット形式） */
  setSelectedFeatureDataGeneratingGenerateKind: (
    data: 'All' | 'FeatureDataOnly'
  ) => {
    dispatch(
      featureDataGeneratingActions.setSelectedFeatureDataGenerateKind(data)
    )
  },
  /** 入力したメタデータをセットする(特徴量データ生成情報) */
  setMlPipelinesMetaData: (data?: MetaData) =>
    dispatch(featureDataGeneratingOperations.setMlPipelinesMetaData(data)),
  /** 入力したメタデータをセットする(特徴量データ情報) */
  setFeatureDataMetaData: (data?: MetaData) =>
    dispatch(featureDataGeneratingOperations.setFeatureDataMetaData(data)),
  clearFeatureDataGeneratingState: () =>
    dispatch(featureDataGeneratingActions.clearFeatureDataGeneratingState()),
  /** データセットの表示条件の変更 */
  setDatasetDisplayCondition: (datasetCondition: DisplayCondition) =>
    dispatch(
      featureDataGeneratingActions.setDatasetDisplayCondition(datasetCondition)
    ),
  /** セッティングの表示条件の変更 */
  setSettingDisplayCondition: (settingCondition: DisplayCondition) =>
    dispatch(
      featureDataGeneratingActions.setSettingDisplayCondition(settingCondition)
    ),
  /** 学習アルゴリズムの表示条件の変更 */
  setTrainingDisplayCondition: (
    trainingAlgorithmCondition: AlgorithmDisplayCondition
  ) =>
    dispatch(
      featureDataGeneratingActions.setTrainingAlgorithmDisplayCondition(
        trainingAlgorithmCondition
      )
    ),
  /** 登録先特徴量データグループの選択 */
  setSelectedFeatureDataGroup: (featureDataGroup: FeatureDataGroup) =>
    dispatch(
      featureDataGeneratingOperations.setSelectedFeatureDataGroup(
        featureDataGroup
      )
    ),
  /** 登録先特徴量データの表示条件 */
  setFeatureDataGroupDisplayCondition: (condition: DisplayCondition) => {
    dispatch(
      featureDataGeneratingActions.setFeatureDataGroupDisplayCondition(
        condition
      )
    )
  },
  /** 特徴量データバージョンの選択 */
  setSelectedFeatureDataGroupVersion: (featureDataVersion?: Version) => {
    dispatch(
      featureDataGeneratingActions.setSelectedFeatureDataGroupVersion(
        featureDataVersion
      )
    )
  },
  /** ストラクチャーバージョンの選択 */
  setSelectedAlgorithmStructure: (
    algorithmStructureVersion?: AlgorithmStructureVersion
  ) => {
    dispatch(
      featureDataGeneratingOperations.setSelectedAlgorithmStructureVersion(
        algorithmStructureVersion
      )
    )
  },
  clearExecutedInfo: () => {
    dispatch(
      featureDataGeneratingActions.setExecutedInfo({
        mlPipelineId: undefined,
        trainingStepId: undefined,
      })
    )
  },
  /** snapshotの購読解除 */
  unsubscribeDatasetList: () =>
    dispatch(featureDataGeneratingOperations.unsubscribeDatasetList()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  outputFormatSelection: {
    display: 'flex',
    justifyContent: 'felx-start',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  settingSelection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  dataCreateDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  detailListItem: {
    padding: 0,
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
  cbList: {
    width: '100%',
  },
}))

/** ステップの名称 */
const STEP_NAMES = [
  '学習アルゴリズム',
  `登録先
  特徴量データグループ`,
  'データセット',
  'セッティング',
  'アウトプット形式',
  'メタデータ',
  '確認',
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** アルゴリズムのテーブルの選択状態 */
const ALGORITHM_HEADERS: SelectableTableHeader[] = [
  {
    id: 'version',
    title: 'バージョン',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'releasedAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 400,
    sortable: false,
    position: 'center',
  },
]

/** 特徴量データグループのヘッダー */
const FEATURE_DATA_GROUP_HEADERS: SelectableTableHeader[] = [
  {
    id: 'featureDataGroupId',
    title: '特徴量データグループID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureDataGroupName',
    title: '特徴量データグループ名',
    width: 220,
    sortable: true,
    position: 'left',
  },
  {
    id: 'featureDataCount',
    title: '登録特徴量データ数',
    width: 160,
    sortable: false,
    position: 'center',
  },
  {
    id: 'latestFeatureDataVersion',
    title: '最新バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'latestFeatureDataName',
    title: '最新特徴量データ名',
    width: 160,
    sortable: false,
    position: 'left',
  },
  {
    id: 'updatedAt',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'createdBy',
    title: '作成ユーザID',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** データセットのテーブルの選択状態 */
const DATASET_HEADERS: SelectableTableHeader[] = [
  {
    id: 'datasetName',
    title: 'データセット名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'datasetId',
    title: 'データセットID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

/** セッティングのテーブルのヘッダー */
const SETTING_HEADERS: SelectableTableHeader[] = [
  {
    id: 'settingName',
    title: 'セッティング名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'settingId',
    title: 'セッティングID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

/** コンフィギュレーションのテーブルのヘッダー */
const CONFIGURATION_HEADERS: SelectableTableHeader[] = [
  {
    id: 'settingName',
    title: 'コンフィグ',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'version',
    title: 'バージョン',
    width: 300,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const FeatureDataGeneratingInfoConfirmView: React.FC<Props> = (
  props: Props
) => {
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>特徴量データ生成情報</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: '表示名',
            value: props.domainData.mlPipelinesMetaData?.name,
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.mlPipelinesMetaData?.remarks,
            readOnly: true,
            variant: 'standard',
            rowNum: 0,
          }}
          textFieldSpace={0}
          helperText=''
          data-testid={'featureDataGeneratingInputPreview'}
        />
      </Box>
    </Box>
  )
}

type FeatureDataGroupInfoConfirmViewProps = Props & {
  featureDataGroupToDetail: (featureDataGroup?: FeatureDataGroup) =>
    | {
        ['特徴量データグループID']: string
        ['特徴量データグループ名']: string
        ['登録特徴量データ数']: string
        ['最新バージョン']: string
        ['最新特徴量データ名']: string
        ['更新日時']: string
        ['作成日時']: string
        ['作成ユーザーID']: string
      }
    | undefined
}

const FeatureDataGroupInfoConfirmView: React.FC<
  FeatureDataGroupInfoConfirmViewProps
> = (props: FeatureDataGroupInfoConfirmViewProps) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>特徴量データ情報</Typography>
      <Box mt={1}>
        <InputLabel shrink>登録先特徴量データグループ</InputLabel>
        <Box mt={1} mb={2}>
          <AccordionLabel
            label={
              props.domainData.selectedFeatureDataGroup?.featureDataGroupName
            }
            details={props.featureDataGroupToDetail(
              props.domainData.selectedFeatureDataGroup
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
        <Box mt={2}>
          <TextField
            id='featureDataVersion'
            className={classes.textField}
            variant='standard'
            label='特徴量データバージョン'
            value={
              props.domainData.selectedFeatureDataGroupVersion?.displayName
            }
            key={props.domainData.selectedFeatureDataGroupVersion?.displayName}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <MetadataInput
            nameProps={{
              label: '表示名',
              value: props.domainData.featureDataMetaData?.name,
              readOnly: true,
              variant: 'standard',
            }}
            remarksProps={{
              label: '備考',
              value: props.domainData.featureDataMetaData?.remarks,
              readOnly: true,
              variant: 'standard',
              rowNum: 0,
            }}
            textFieldSpace={0}
            helperText=''
            data-testid={'featureDataInputPreview'}
          />
        </Box>
      </Box>
    </Box>
  )
}

type TrainingAlgorithmConfirmViewProps = Props & {
  trainingAlgorithmToDetail: (
    trainingAlgorithmVersion?: TrainingAlgorithmVersion
  ) =>
    | {
        ['バージョン']: string
        ['登録日時']: string
        ['remarks']: string
      }
    | undefined
}

const TrainingAlgorithmConfirmView: React.FC<
  TrainingAlgorithmConfirmViewProps
> = (props: TrainingAlgorithmConfirmViewProps) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>学習アルゴリズム</Typography>
      <Box mt={1}>
        {props.domainData.selectedTrainingAlgorithm?.algorithmId && (
          <TextField
            id='algorithm'
            className={classes.textField}
            variant='standard'
            label='学習アルゴリズム名'
            value={
              props.algorithms.find(
                (list) =>
                  list.algorithmId ===
                  props.domainData.selectedTrainingAlgorithm?.algorithmId
              )?.metadata.name.ja
            }
            key={props.domainData.selectedTrainingAlgorithm?.algorithmId}
          />
        )}
        <Box mt={2}>
          <InputLabel shrink>バージョン</InputLabel>
          <Box mt={1}>
            <AccordionLabel
              label={
                props.domainData?.selectedTrainingAlgorithmVersion
                  ?.trainingAlgorithmVersion
              }
              details={props.trainingAlgorithmToDetail(
                props.domainData?.selectedTrainingAlgorithmVersion
              )}
              prefix={'-'}
              delimiter={':'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

type ExecutionInfoConfirmViewProps = Props & {
  datasetToDetail: (dataset?: Dataset) =>
    | {
        ['データセットID']: string
        ['データセット名']: string
        ['登録日時']: string
        ['Remarks']: string
      }
    | undefined
  settingToDetail: (setting?: Setting) =>
    | {
        ['セッティングID']: string
        ['セッティング名']: string
        ['作成日時']: string
        ['Remarks']: string
      }
    | undefined
}

const ExecutionInfoConfirmView: React.FC<ExecutionInfoConfirmViewProps> = (
  props: ExecutionInfoConfirmViewProps
) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} mb={2} p={'24px 32px 32px'} width='100%'>
      <Typography>実行情報</Typography>
      <Box mt={2}>
        <InputLabel shrink>データセット</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={props.domainData.selectedDataset?.name}
            details={props.datasetToDetail(props.domainData.selectedDataset)}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <InputLabel shrink>セッティング</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={props.domainData.selectedSetting?.name}
            details={props.settingToDetail(props.domainData.selectedSetting)}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <TextField
          id='destType'
          className={classes.textField}
          variant='standard'
          label='アウトプット形式'
          value={
            props.domainData.selectedFeatureDataGenerateKind === 'All'
              ? '生成 + 転送'
              : '生成のみ'
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </Box>
  )
}

const FeatureDataGenerating: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const history = useHistory()
  const { classes } = useStyles()

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  /** 設定する特徴量データのバージョンの配列 */
  const [featureDataVersionList, setFeatureDataVersionList] = useState<
    Version[]
  >([])

  useEffect(() => {
    return () => {
      props.clearFeatureDataGeneratingState()
      props.unsubscribeDatasetList()
    }
  }, [])

  useEffect(() => {
    props.setSelectedTrainingAlgorithmId(
      props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
      )[0].algorithmId
    )
  }, [])

  /** データセット新規追加ダイアログ表示状態 */
  const [openDatasetDialog, setOpenDatasetDialog] = useState(false)
  const [newDatasetId, setNewDatasetId] = useState<string | undefined>(
    undefined
  )
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  /** セッティングを設定するか */
  const [assignmentSetting, setAssignmentSetting] = useState(false)

  /** データセットの新規追加ボタン押下時処理 */
  const handleClickOpenDatasetDialog = () => {
    setOpenDatasetDialog(true)
  }

  /** データセットの新規追加完了時処理（または、キャンセル） */
  const handleClickCloseDatasetDialog = (id?: string) => {
    setOpenDatasetDialog(false)
    if (isUndefined(id)) return
    setNewDatasetId(id)
    props.getDatasetList()
  }

  /** バージョンの配列セット */
  useEffect(() => {
    const versionNumberList =
      !isUndefined(props.domainData.selectedFeatureDataGroup) &&
      props.domainData.selectedFeatureDataGroup.latestFeatureDataVersion !== ''
        ? props.domainData.selectedFeatureDataGroup.latestFeatureDataVersion.split(
            '.'
          )
        : '0.0.0'.split('.')
    const major = Number(versionNumberList[0])
    const minor = Number(versionNumberList[1])
    const patch = Number(versionNumberList[2])
    props.setSelectedFeatureDataGroupVersion({
      displayName: `${major}.${minor}.${patch + 1}`,
      major: major,
      minor: minor,
      patch: patch + 1,
    })
    setFeatureDataVersionList([
      {
        displayName: `${major}.${minor}.${patch + 1}`,
        major: major,
        minor: minor,
        patch: patch + 1,
      },
      {
        displayName: `${major}.${minor + 1}.0`,
        major: major,
        minor: minor + 1,
        patch: 0,
      },
      {
        displayName: `${major + 1}.0.0`,
        major: major + 1,
        minor: 0,
        patch: 0,
      },
    ])
  }, [props.domainData.selectedFeatureDataGroup])

  /** データセットの新規追加完了時処理 */
  useEffect(() => {
    if (isUndefined(newDatasetId)) return
    const newDataset = props.domainData.datasetList.find(
      (item) => item.datasetId === newDatasetId
    )
    if (isUndefined(newDataset)) return
    props.setSelectedDataset(newDataset)
    setNewDatasetId(undefined)
  }, [newDatasetId, props.domainData.datasetList])

  /** セッティング新規追加ダイアログ表示状態 */
  const [openSettingDialog, setOpenSettingDialog] = useState(false)
  const [newSettingId, setNewSettingId] = useState<string | undefined>(
    undefined
  )

  /** セッティングの新規追加ボタン押下時処理 */
  const handleClickOpenSettingDialog = () => {
    setOpenSettingDialog(true)
  }
  /** セッティングの新規追加完了時処理（または、キャンセル） */
  const handleClickCloseSettingDialog = (id?: string) => {
    setOpenSettingDialog(false)
    if (isUndefined(id)) return
    setNewSettingId(id)
    props.getSettingList()
  }

  /** セッティングの新規追加完了時処理 */
  useEffect(() => {
    if (isUndefined(newSettingId)) return
    const newSetting = props.domainData.settingList.find(
      (item) => item.settingId === newSettingId
    )
    if (isUndefined(newSetting)) return
    props.setSelectedSetting(newSetting, true)
    setNewSettingId(undefined)
  }, [newSettingId, props.domainData.settingList])

  /** 特徴量データ生成情報の表示名更新処理 */
  const updateMlPipelinesMetaDataName = (name: string) => {
    props.setMlPipelinesMetaData({
      ...props.domainData.mlPipelinesMetaData,
      name,
    })
  }

  /** 特徴量データ生成情報の備考更新処理 */
  const updateMlPipelinesMetaDataRemarks = (remarks: string) => {
    props.setMlPipelinesMetaData({
      ...props.domainData.mlPipelinesMetaData,
      remarks,
    })
  }

  /** 特徴量データの表示名更新処理 */
  const updateFeatureDataMetaDataName = (name: string) => {
    props.setFeatureDataMetaData({
      ...props.domainData.featureDataMetaData,
      name,
    })
  }

  /** 特徴量データの備考更新処理 */
  const updateFeatureDataMetaDataRemarks = (remarks: string) => {
    props.setFeatureDataMetaData({
      ...props.domainData.featureDataMetaData,
      remarks,
    })
  }

  /** カレントのステップ */
  const currentStep = useMemo(
    () =>
      FeatureDataGeneratingStateKindArray.indexOf(
        props.appState.featureDataGeneratingState
      ),
    [props.appState.featureDataGeneratingState]
  )

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.featureDataGeneratingState) {
      case 'TrainingAlgorithmState':
        return (
          props.appState.featureDataGeneratingSubState
            .trainingAlgorithmSubState === 'Selected'
        )
      case 'FeatureDataGroupState':
        return (
          props.appState.featureDataGeneratingSubState
            .featureDataGroupSubState === 'Selected'
        )
      case 'DatasetState':
        return (
          props.appState.featureDataGeneratingSubState.datasetSubState ===
          'Selected'
        )
      case 'SettingState':
        return (
          props.appState.featureDataGeneratingSubState.settingSubState ===
          'Selected'
        )
      case 'OutputFormatState':
        return (
          props.appState.featureDataGeneratingSubState.outputFormatSubState ===
          'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.featureDataGeneratingSubState.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    props.appState.featureDataGeneratingState,
    props.appState.featureDataGeneratingSubState,
    props.domainData.selectedFeatureDataGroup,
    props.domainData.selectedFeatureDataGroup,
  ])

  /** 前ページ表示 */
  const prevPageAction = useCallback(() => {
    switch (props.appState.featureDataGeneratingState) {
      case 'SettingState':
        setAssignmentSetting(false)
        props.prevStep(props.appState.featureDataGeneratingState)
        break
      default:
        props.prevStep(props.appState.featureDataGeneratingState)
        break
    }
  }, [props.appState.featureDataGeneratingState])

  useEffect(() => {
    /** ステップ更新時のスクロール操作 */
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
      handleChangeSearchValue('')
    }
    /** 各種一覧取得（学習アルゴリズム/学習特徴量データ/データセット/セッティング/推論アルゴリズム） */
    props.getParams(props.appState.featureDataGeneratingState)
  }, [props.appState.featureDataGeneratingState])

  /** 特徴量データ生成情報の開始失敗時メッセージ */
  const FeatureDataGeneratingErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.featureDataGeneratingSubState.executeSubState ===
      'ExecuteError'
    ) {
      errorMessages.push('生成指示に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  const {
    selectedFeatureDataTableRadio,
    selectedFeatureDataGroupIndex,
    featureDataGroupRows,
    algorithmStructureRows,
    selectedAlgorithmStructureIndex,
    selectAlgorithmStructureTableRadio,
    selectedTrainingAlgorithmIndex,
    selectTrainingAlgorithmTableRadio,
    selectedSettingIndex,
    selectSettingTableRadio,
    changeTableSortOrder,
    pageChange,
    trainingAlgorithmRows,
    handleChangeDisplayNumber,
    setSelectRowIndex,
    tableDialog,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    searchedSettingListLength,
    datasetRows,
    selectedDatasetIndex,
    selectDatasetTableRadio,
    searchedDatasetListLength,
    settingsRows,
    isOpenDownloadFailedDialog,
    setIsOpenDownloadFailedDialog,
    selectedAlgorithmTableOrder,
    featureDataGroupToDetail,
    trainingAlgorithmToDetail,
    datasetToDetail,
    settingToDetail,
  } = useTable(props)

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.featureDataGeneratingState) {
      case 'TrainingAlgorithmState':
        if (isUndefined(props.algorithms)) {
          return <></>
        }
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <Box mt={2}>
                <FormControl
                  variant='outlined'
                  className={classes.algorithmSelectBox}
                >
                  <InputLabel id='featureDataGeneratingModel'>
                    アルゴリズム
                  </InputLabel>
                  <Select
                    data-testid='select'
                    value={
                      props.domainData.selectedTrainingAlgorithm?.algorithmId
                    }
                    onChange={(e: SelectChangeEvent<string>) =>
                      props.setSelectedTrainingAlgorithmId(
                        e.target.value as string
                      )
                    }
                    label='Select Algorithm'
                    defaultValue={
                      props.algorithms.filter(
                        (algorithm) =>
                          algorithm.algorithmPurpose === 'TemplateMatching'
                      )[0].algorithmId
                    }
                  >
                    {props.algorithms
                      .filter(
                        (algorithm) =>
                          algorithm.algorithmPurpose === 'TemplateMatching'
                      )
                      .map((algorithm) => {
                        return (
                          <MenuItem
                            data-testid={algorithm.algorithmId}
                            value={algorithm.algorithmId}
                            key={algorithm.algorithmId}
                          >
                            {algorithm.metadata.name.ja}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              {!isUndefined(
                props.domainData.selectedTrainingAlgorithm?.algorithmId
              ) && (
                <SelectableTable
                  headers={ALGORITHM_HEADERS}
                  fixedColumnNumber={0}
                  selectedRowNumber={selectedTrainingAlgorithmIndex}
                  page={0}
                  rows={trainingAlgorithmRows}
                  tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                  sortOrder={{
                    key: props.domainData
                      .trainingAlgorithmVersionDisplayCondition.sortKey,
                    order:
                      props.domainData.trainingAlgorithmVersionDisplayCondition
                        .sortOrder,
                  }}
                  onClickRadio={(row: number) =>
                    selectTrainingAlgorithmTableRadio(row)
                  }
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                  onClickOrderChange={(key: string) => {
                    return selectedAlgorithmTableOrder(key)
                  }}
                />
              )}
            </CustomTrainingPageParagraph>
            <Box mb={2}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body1' className={classes.labelText}>
                    アドバンスド設定
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List className={classes.cbList}>
                    <Box sx={{ color: globalTheme.palette.text.secondary }}>
                      Configuration
                    </Box>
                    <SelectableTable
                      headers={CONFIGURATION_HEADERS}
                      fixedColumnNumber={0}
                      selectedRowNumber={selectedAlgorithmStructureIndex}
                      page={0}
                      rows={algorithmStructureRows}
                      tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                      onClickRadio={(row: number) =>
                        selectAlgorithmStructureTableRadio(row)
                      }
                    />
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
        )
      case 'FeatureDataGroupState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph
              level={'part'}
              title={'特徴量データを登録する特徴量データグループ'}
            >
              <Box mb={3}>
                <FormControl
                  variant='outlined'
                  className={classes.algorithmSelectBox}
                >
                  <InputLabel id='feature-data-input'>
                    特徴量データバージョン
                  </InputLabel>
                  <Select
                    data-testid='select-feature-data-version'
                    labelId='feature-data-input-label'
                    id='feature-data-input-outlined'
                    value={
                      props.domainData.selectedFeatureDataGroupVersion
                        ?.displayName
                    }
                    onChange={(e: SelectChangeEvent<string>) =>
                      props.setSelectedFeatureDataGroupVersion(
                        featureDataVersionList.find(
                          (featureDataVersion) =>
                            featureDataVersion.displayName === e.target.value
                        ) as Version
                      )
                    }
                    label='Select Feature Data Version'
                  >
                    {featureDataVersionList.map((value) => (
                      <MenuItem
                        data-testid={`select${value.displayName}`}
                        value={value.displayName}
                        key={value.displayName}
                      >
                        {value.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <CustomTrainingPageParagraph level={'chapter'}>
                <div className={classes.searchForm}>
                  <div className={classes.searchField}>
                    <SearchInput
                      placeholder='検索（特徴量データグループIDなど）'
                      value={tableSearchValue}
                      onChangeValue={(event) =>
                        handleChangeSearchValue(event.target.value)
                      }
                      onClickSearch={() => searchTableContent()}
                      onPressEnter={() => searchTableContent()}
                    />
                  </div>
                </div>
                <SelectableTable
                  displayNumber={
                    props.domainData.featureDataGroupDisplayCondition
                      .displayNumber
                  }
                  headers={FEATURE_DATA_GROUP_HEADERS}
                  rows={featureDataGroupRows}
                  totalCount={props.domainData.featureDataGroups.length}
                  tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                  onChangeDisplayNumber={(displayNumber: number) =>
                    handleChangeDisplayNumber(displayNumber)
                  }
                  selectedRowNumber={selectedFeatureDataGroupIndex}
                  fixedColumnNumber={0}
                  page={
                    props.domainData.featureDataGroupDisplayCondition.pageNumber
                  }
                  sortOrder={{
                    key: props.domainData.featureDataGroupDisplayCondition
                      .sortKey,
                    order:
                      props.domainData.featureDataGroupDisplayCondition
                        .sortOrder,
                  }}
                  onClickRadio={(row: number) =>
                    selectedFeatureDataTableRadio(row)
                  }
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                  onClickOrderChange={(key: string) =>
                    changeTableSortOrder(key)
                  }
                  onClickPageChange={(pageNumber: number) =>
                    pageChange(pageNumber)
                  }
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'DatasetState':
        if (isUndefined(props.domainData.datasetList)) {
          return <></>
        } else {
          return (
            <div className={classes.stepContainer}>
              <Dialog
                maxWidth='md'
                fullWidth={true}
                aria-labelledby='customized-dialog-title'
                open={openDatasetDialog}
              >
                <DialogContent className={classes.dataCreateDialog}>
                  <CreateDatasetPage
                    mode='dialog'
                    handleCloseDialog={(datasetId?: string) =>
                      handleClickCloseDatasetDialog(datasetId)
                    }
                    algorithmId={
                      props.domainData.selectedTrainingAlgorithm?.algorithmId
                    }
                  />
                </DialogContent>
              </Dialog>
              <div className={classes.postAddButton}>
                <Typography className={classes.sectionTitle}>
                  {'データセット'}
                </Typography>
                <div>
                  <PostAddIconButton
                    label={'新規追加'}
                    onClick={handleClickOpenDatasetDialog}
                  />
                </div>
              </div>
              <CustomTrainingPageParagraph level={'chapter'}>
                <div className={classes.searchForm}>
                  <div className={classes.searchField}>
                    <SearchInput
                      placeholder='検索（データセット名、データセットIDなど）'
                      value={tableSearchValue}
                      onChangeValue={(event) =>
                        handleChangeSearchValue(event.target.value)
                      }
                      onClickSearch={() => searchTableContent()}
                      onPressEnter={() => searchTableContent()}
                    />
                  </div>
                </div>
                <SelectableTable
                  displayNumber={
                    props.domainData.datasetDisplayCondition.displayNumber
                  }
                  headers={DATASET_HEADERS}
                  rows={datasetRows}
                  totalCount={
                    searchedDatasetListLength ??
                    props.domainData.datasetList.length
                  }
                  onChangeDisplayNumber={(displayNumber: number) =>
                    handleChangeDisplayNumber(displayNumber)
                  }
                  tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                  selectedRowNumber={selectedDatasetIndex}
                  fixedColumnNumber={0}
                  page={props.domainData.datasetDisplayCondition.pageNumber}
                  sortOrder={{
                    key: props.domainData.datasetDisplayCondition.sortKey,
                    order: props.domainData.datasetDisplayCondition.sortOrder,
                  }}
                  onClickRadio={(row: number) => selectDatasetTableRadio(row)}
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                  onClickOrderChange={(key: string) =>
                    changeTableSortOrder(key)
                  }
                  onClickPageChange={(pageNumber: number) =>
                    pageChange(pageNumber)
                  }
                />
              </CustomTrainingPageParagraph>
            </div>
          )
        }
      case 'SettingState':
        if (isUndefined(props.domainData.settingList)) {
          return <></>
        } else {
          return (
            <div className={classes.stepContainer}>
              <Dialog
                maxWidth='md'
                fullWidth={true}
                aria-labelledby='customized-dialog-title'
                open={openSettingDialog}
              >
                <DialogContent className={classes.dataCreateDialog}>
                  <CreateSettingPage
                    handleCloseDialog={(settingId?: string) =>
                      handleClickCloseSettingDialog(settingId)
                    }
                  />
                </DialogContent>
              </Dialog>

              <div className={classes.settingSelection}>
                <CustomTrainingPageParagraph
                  level={'part'}
                  title={'セッティング'}
                >
                  <div className={classes.outputFormatSelection}>
                    <RadioGroup
                      value={assignmentSetting}
                      row
                      onChange={(event) =>
                        setAssignmentSetting(JSON.parse(event.target.value))
                      }
                    >
                      <FormControlLabel
                        data-testid='setting-default'
                        value={false}
                        control={<Radio size='small' color='secondary' />}
                        label='デフォルト'
                        onClick={() =>
                          props.setSelectedSetting(undefined, false)
                        }
                      />
                      <FormControlLabel
                        data-testid='setting-assignment'
                        value={true}
                        control={<Radio size='small' color='secondary' />}
                        disabled={props.domainData.settingList.length === 0}
                        label='設定'
                        onClick={() => {
                          if (props.domainData.settingList.length > 0) {
                            props.setSelectedSetting(
                              props.domainData.settingList[0],
                              true
                            )
                          } else {
                            props.setSelectedSetting(
                              undefined,
                              assignmentSetting
                            )
                            setAssignmentSetting(false)
                          }
                        }}
                      />
                    </RadioGroup>
                  </div>
                </CustomTrainingPageParagraph>
                {assignmentSetting && (
                  <div>
                    <PostAddIconButton
                      label={'新規追加'}
                      onClick={handleClickOpenSettingDialog}
                    />
                  </div>
                )}
              </div>

              {assignmentSetting && (
                <CustomTrainingPageParagraph level={'chapter'}>
                  <div className={classes.searchForm}>
                    <div className={classes.searchField}>
                      <SearchInput
                        placeholder='検索（セッティング名、セッティングIDなど）'
                        value={tableSearchValue}
                        onChangeValue={(event) =>
                          handleChangeSearchValue(event.target.value)
                        }
                        onClickSearch={() => searchTableContent()}
                        onPressEnter={() => searchTableContent()}
                      />
                    </div>
                  </div>
                  <SelectableTable
                    displayNumber={
                      props.domainData.settingDisplayCondition.displayNumber
                    }
                    headers={SETTING_HEADERS}
                    rows={settingsRows}
                    totalCount={
                      searchedSettingListLength ??
                      props.domainData.settingList.length
                    }
                    tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                    onChangeDisplayNumber={(displayNumber: number) =>
                      handleChangeDisplayNumber(displayNumber)
                    }
                    selectedRowNumber={selectedSettingIndex}
                    fixedColumnNumber={0}
                    page={props.domainData.settingDisplayCondition.pageNumber}
                    sortOrder={{
                      key: props.domainData.settingDisplayCondition.sortKey,
                      order: props.domainData.settingDisplayCondition.sortOrder,
                    }}
                    onClickRadio={(row: number) => selectSettingTableRadio(row)}
                    onClickRow={(row: number) => {
                      setSelectRowIndex(row)
                    }}
                    onClickOrderChange={(key: string) =>
                      changeTableSortOrder(key)
                    }
                    onClickPageChange={(pageNumber: number) =>
                      pageChange(pageNumber)
                    }
                  />
                </CustomTrainingPageParagraph>
              )}
            </div>
          )
        }
      case 'OutputFormatState':
        return (
          <>
            <div className={classes.stepContainer}>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'アウトプット形式を選択'}
              >
                <div className={classes.outputFormatSelection}>
                  <RadioGroup
                    value={props.domainData.selectedFeatureDataGenerateKind}
                    row
                    onChange={(event) =>
                      props.setSelectedFeatureDataGeneratingGenerateKind(
                        event.target.value as 'All' | 'FeatureDataOnly'
                      )
                    }
                  >
                    <FormControlLabel
                      data-testid='feature-data-only'
                      value='FeatureDataOnly'
                      control={<Radio size='small' color='secondary' />}
                      label='生成のみ'
                    />
                    <FormControlLabel
                      data-testid='feature-data-all'
                      disabled
                      value='All'
                      control={<Radio size='small' color='secondary' />}
                      label='生成 + 転送'
                    />
                  </RadioGroup>
                </div>
              </CustomTrainingPageParagraph>
            </div>
          </>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'}>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'特徴量データ生成情報'}
              >
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.mlPipelinesMetaData?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateMlPipelinesMetaDataName(e.target.value),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.mlPipelinesMetaData?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateMlPipelinesMetaDataRemarks(e.target.value),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'feature-data-generating-input'}
                />
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'特徴量データ情報'}
              >
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.featureDataMetaData?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateFeatureDataMetaDataName(e.target.value),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.featureDataMetaData?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateFeatureDataMetaDataRemarks(e.target.value),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'feature-data-input'}
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <FeatureDataGeneratingErrorMessage {...props} />
            <FeatureDataGeneratingInfoConfirmView {...props} />
            <FeatureDataGroupInfoConfirmView
              {...props}
              featureDataGroupToDetail={featureDataGroupToDetail}
            />
            <TrainingAlgorithmConfirmView
              {...props}
              trainingAlgorithmToDetail={trainingAlgorithmToDetail}
            />
            <ExecutionInfoConfirmView
              {...props}
              datasetToDetail={datasetToDetail}
              settingToDetail={settingToDetail}
            />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Box pl={7}>
          <BreadcrumbsComponent
            breadcrumbsPath={[
              {
                name: '特徴量データ生成一覧',
                path: 'feature-data-generatings',
              },
              {
                name: '特徴量データ生成指示',
                path: 'entry',
              },
            ]}
          />
        </Box>
        <div className={classes.head}>
          <div className={classes.flexAndBetween}>
            <h2
              className={classes.pageTitle}
              data-testid='feature-data-generating-title'
            >
              特徴量データ
            </h2>
            <div className={classes.confirmButton}>
              <Tooltip title='設定情報' placement='bottom'>
                <IconButton
                  onClick={() => {
                    setOpenConfirmDialog(true)
                  }}
                >
                  <ReceiptLongIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={classes.stepper}>
            <CommonStepper
              stepLabels={STEP_NAMES}
              activeStepIndex={currentStep}
            />
          </div>
        </div>
        <div className={classes.content} ref={contentRef}>
          {getStepContent(props)}
        </div>
        <div className={classes.footerButtons}>
          <div className={classes.footer}>
            {props.appState.featureDataGeneratingState !==
            'TrainingAlgorithmState' ? (
              <Button
                variant='contained'
                color='primary'
                disabled={false}
                onClick={prevPageAction}
                className={classes.leftButton}
              >
                {'戻る'}
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              data-testid='next-step'
              variant='contained'
              color='primary'
              disabled={!enableNextButton}
              onClick={() =>
                props.nextStep(props.appState.featureDataGeneratingState)
              }
              className={classes.rightButton}
            >
              {props.appState.featureDataGeneratingState === 'ExecuteState'
                ? '開始'
                : '次へ'}
            </Button>
          </div>
        </div>
        <MLPipelineCompleteDialog
          open={
            !isUndefined(props.domainData.executionInfo?.mlPipelineId) &&
            !isUndefined(props.domainData.executionInfo?.trainingStepId)
          }
          value={props.domainData.executionInfo?.mlPipelineId ?? ''}
          secondValueItem={{
            label: '学習ステップ ID',
            value: props.domainData.executionInfo?.trainingStepId ?? '',
          }}
          handleClose={() => history.push('/feature-data-generatings')}
          label={'MLパイプラインID'}
          dialogText={'正常に学習を開始しました。'}
          data-testid={'ml-pipeline-id'}
        />
        {tableDialog}
        <InformationDialog
          onClose={() => setIsOpenDownloadFailedDialog(false)}
          open={isOpenDownloadFailedDialog}
          infoData={[
            {
              item: '現在ダウンロードはできません',
              info: '',
            },
          ]}
        />
        <ConfirmViewerDialog
          maxWidth='md'
          open={openConfirmDialog}
          message={
            <Box width='630px'>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.mlPipelinesMetaData !== undefined &&
                      props.domainData.mlPipelinesMetaData?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <FeatureDataGeneratingInfoConfirmView {...props} />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.selectedFeatureDataGroup !== undefined &&
                      props.domainData.featureDataMetaData !== undefined &&
                      props.domainData.featureDataMetaData?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <FeatureDataGroupInfoConfirmView
                  {...props}
                  featureDataGroupToDetail={featureDataGroupToDetail}
                />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.appState.featureDataGeneratingSubState
                        .trainingAlgorithmSubState === 'Selected'
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <TrainingAlgorithmConfirmView
                  {...props}
                  trainingAlgorithmToDetail={trainingAlgorithmToDetail}
                />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.appState.featureDataGeneratingSubState
                        .featureDataGroupSubState === 'Selected' &&
                      props.appState.featureDataGeneratingSubState
                        .datasetSubState === 'Selected' &&
                      props.appState.featureDataGeneratingSubState
                        .settingSubState === 'Selected'
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <ExecutionInfoConfirmView
                  {...props}
                  datasetToDetail={datasetToDetail}
                  settingToDetail={settingToDetail}
                />
              </Box>
            </Box>
          }
          handleClose={() => setOpenConfirmDialog(false)}
        />

        <GlobalLoading open={props.appState.inProgress} />
      </div>
    </>
  )
}

export const FeatureDataGeneratingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataGenerating))

const useTable = (props: Props) => {
  const globalTheme = useTheme()
  const [isOpenDownloadFailedDialog, setIsOpenDownloadFailedDialog] =
    useState<boolean>(false)

  /** 登録先特徴量データグループのラジオボタンの選択された時に切り替える処理 */
  const selectedFeatureDataTableRadio = (row: number) => {
    const featureDataGroup = adjustFeatureDataGroupTableRow[row]
    props.setSelectedFeatureDataGroup(featureDataGroup)
  }

  /** データセットのラジオボタン押下時処理 */
  const selectDatasetTableRadio = (row: number) => {
    const dataset = adjustDatasetTableRow[row]
    props.setSelectedDataset(dataset)
  }

  /** セッティングのラジオボタン押下時処理 */
  const selectSettingTableRadio = (row: number) => {
    const setting = adjustSettingTableRow[row]
    props.setSelectedSetting(setting, true)
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    switch (props.appState.featureDataGeneratingState) {
      case 'TrainingAlgorithmState':
        /** ソートキー、ソートオーダーをセット */
        props.setTrainingDisplayCondition({
          ...props.domainData.trainingAlgorithmVersionDisplayCondition,
          sortKey: key,
          sortOrder:
            props.domainData.trainingAlgorithmVersionDisplayCondition
              .sortKey === key
              ? props.domainData.trainingAlgorithmVersionDisplayCondition
                  .sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.trainingAlgorithmVersionDisplayCondition
                  .sortOrder,
        })
        break
      case 'FeatureDataGroupState':
        /** ソートキー、ソートオーダーをセット */
        props.setFeatureDataGroupDisplayCondition({
          ...props.domainData.featureDataGroupDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.featureDataGroupDisplayCondition.sortKey === key
              ? props.domainData.featureDataGroupDisplayCondition.sortOrder ===
                'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.featureDataGroupDisplayCondition.sortOrder,
        })
        break
      case 'DatasetState':
        /** ソートキー、ソートオーダーをセット */
        props.setDatasetDisplayCondition({
          ...props.domainData.datasetDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.datasetDisplayCondition.sortKey === key
              ? props.domainData.datasetDisplayCondition.sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.datasetDisplayCondition.sortOrder,
        })
        break
      case 'SettingState':
        /** ソートキー、ソートオーダーをセット */
        props.setSettingDisplayCondition({
          ...props.domainData.settingDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.settingDisplayCondition.sortKey === key
              ? props.domainData.settingDisplayCondition.sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.settingDisplayCondition.sortOrder,
        })
        break
      default:
        break
    }
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    switch (props.appState.featureDataGeneratingState) {
      case 'FeatureDataGroupState':
        props.setFeatureDataGroupDisplayCondition({
          ...props.domainData.featureDataGroupDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'DatasetState':
        props.setDatasetDisplayCondition({
          ...props.domainData.datasetDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'SettingState':
        props.setSettingDisplayCondition({
          ...props.domainData.settingDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      default:
        break
    }
  }

  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')

  /** 検索結果結果の配列の長さを保持(Dataset) */
  const [searchedDatasetListLength, setSearchedDatasetListLength] = useState<
    number | undefined
  >(undefined)

  /** 検索結果結果の配列の長さを保持(Setting) */
  const [searchedSettingListLength, setSearchedSettingListLength] = useState<
    number | undefined
  >(undefined)

  /** 検索結果結果の配列の長さを保持(featureDataGroupList) */
  const [
    searchedFeatureDataGroupListLength,
    setSearchedFeatureDataGroupListLength,
  ] = useState<number | undefined>(undefined)

  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    switch (props.appState.featureDataGeneratingState) {
      case 'TrainingAlgorithmState':
        {
          const maxPageNumber =
            Math.ceil(
              props.domainData.featureDataGroups.length / displayNumber
            ) - 1
          if (
            props.domainData.featureDataGroupDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setFeatureDataGroupDisplayCondition({
              ...props.domainData.featureDataGroupDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setFeatureDataGroupDisplayCondition({
              ...props.domainData.featureDataGroupDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
      case 'DatasetState':
        {
          const maxPageNumber =
            Math.ceil(props.domainData.datasetList.length / displayNumber) - 1
          if (
            props.domainData.datasetDisplayCondition.pageNumber <= maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setDatasetDisplayCondition({
              ...props.domainData.datasetDisplayCondition,
              displayNumber: displayNumber ?? 5,
            })
          } else {
            /** 表示数を変更 */
            props.setDatasetDisplayCondition({
              ...props.domainData.datasetDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber ?? 5,
            })
          }
        }
        return
      case 'SettingState':
        {
          const maxPageNumber =
            Math.ceil(props.domainData.settingList.length / displayNumber) - 1
          if (
            props.domainData.settingDisplayCondition.pageNumber <= maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setSettingDisplayCondition({
              ...props.domainData.settingDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setSettingDisplayCondition({
              ...props.domainData.settingDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
    }
  }

  /** 学習アルゴリズムのテーブルをソートして返す */
  const trainingAlgorithmRows = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm?.algorithmId)) {
      let selectedAlgorithm = props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
      )[0]
      props.algorithms.find((algorithm) => {
        if (
          algorithm.algorithmId ==
          props.domainData.selectedTrainingAlgorithm?.algorithmId
        ) {
          selectedAlgorithm = algorithm
        }
      })
      if (
        selectedAlgorithm.algorithmId !==
        props.domainData.selectedTrainingAlgorithm?.algorithmId
      ) {
        return []
      }
      let rowData =
        selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.map(
          (data) => {
            return {
              version: data.algorithmVersion,
              releasedAt: data.releasedAt
                ? formatDateTimeSec(data.releasedAt.toDate())
                : undefined,
              remarks: data.metadata.remarks.ja,
            }
          }
        )
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.trainingAlgorithmVersionDisplayCondition.sortKey ===
        'version'
      ) {
        rowData = rowData.sort(
          (
            item1: {
              version: VersionWithPreRelease
              releasedAt: string | undefined
              remarks: string
            },
            item2: {
              version: VersionWithPreRelease
              releasedAt: string | undefined
              remarks: string
            }
          ) => {
            return compareVersionsWithPreRelease(
              item1.version,
              item2.version,
              props.domainData.trainingAlgorithmVersionDisplayCondition
                .sortOrder
            )
          }
        )
      }
      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '' || isUndefined(value)) {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            if (key == 'version') {
              if (isVersionWithPreRelease(value)) {
                return <Typography key={key}>{value.displayName}</Typography>
              } else {
                return (
                  <Box key={key} sx={{ color: 'text.secondary' }}>
                    <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                  </Box>
                )
              }
            }
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [
    props.domainData.trainingAlgorithmVersionDisplayCondition,
    props.domainData.selectedTrainingAlgorithm?.algorithmId,
  ])

  const algorithmStructureRows = useMemo(() => {
    if (!props.domainData.selectedTrainingAlgorithmVersion) return []
    const algorithm = props.domainData.selectedTrainingAlgorithmVersion

    const structures: AlgorithmStructureVersion[] = []

    algorithm.algorithmStructures.forEach((algorithmStructure) => {
      algorithmStructure.algorithmStructureVersions.forEach(
        (algorithmStructureVersion) => {
          structures.push(algorithmStructureVersion)
        }
      )
    })

    const sortedStructures = structures.sort((a, b) =>
      compareVersions(
        a.algorithmStructureVersion,
        b.algorithmStructureVersion,
        'desc'
      )
    )

    const rowData = sortedStructures.map((data) => {
      return {
        config: data.metadata.name.ja,
        remarks: data.metadata.remarks.ja,
        version: data.algorithmStructureVersion.displayName,
        createdAt: data.createdAt
          ? formatDateTimeSec(data.createdAt.toDate())
          : undefined,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '' || isUndefined(value)) {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else {
          return <Typography key={key}>{value}</Typography>
        }
      })
    )
  }, [
    props.domainData.selectedTrainingAlgorithm?.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
  ])

  /** 表示対象のデータセットデータ */
  const adjustDatasetTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.datasetDisplayCondition)) {
      let newDatasetArray = props.domainData.datasetList
      /** 検索ワードから検索 */
      if (props.domainData.datasetDisplayCondition.searchValue) {
        newDatasetArray = newDatasetArray.filter(
          (item) =>
            item.name.startsWith(
              props.domainData.datasetDisplayCondition.searchValue
            ) ||
            item.datasetId.startsWith(
              props.domainData.datasetDisplayCondition.searchValue
            ) ||
            item.datasetId ===
              props.domainData.datasetDisplayCondition.searchValue ||
            item.name === props.domainData.datasetDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedDatasetListLength(newDatasetArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedDatasetListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (props.domainData.datasetDisplayCondition.sortKey === 'createdAt') {
        newDatasetArray = newDatasetArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.datasetDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }
      /** 表示するデータセットの整形 */
      return newDatasetArray.slice(
        props.domainData.datasetDisplayCondition.displayNumber *
          props.domainData.datasetDisplayCondition.pageNumber,
        (props.domainData.datasetDisplayCondition.pageNumber + 1) *
          props.domainData.datasetDisplayCondition.displayNumber
      )
    }
    return props.domainData.datasetList
  }, [props.domainData.datasetDisplayCondition, props.domainData.datasetList])

  /** 表示対象のセッティングデータ */
  const adjustSettingTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.settingDisplayCondition)) {
      let newSettingArray = props.domainData.settingList
      /** 検索ワードから検索 */
      if (props.domainData.settingDisplayCondition.searchValue) {
        newSettingArray = newSettingArray.filter(
          (item) =>
            item.settingId ===
              props.domainData.settingDisplayCondition.searchValue ||
            item.name ===
              props.domainData.settingDisplayCondition.searchValue ||
            item.name.startsWith(
              props.domainData.settingDisplayCondition.searchValue
            ) ||
            item.settingId.startsWith(
              props.domainData.settingDisplayCondition.searchValue
            )
        )
        /** 検索後の配列の長さをセット */
        setSearchedSettingListLength(newSettingArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedSettingListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (props.domainData.settingDisplayCondition.sortKey === 'createdAt') {
        newSettingArray = newSettingArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.settingDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }
      /** 表示するセッティングの整形 */
      return newSettingArray.slice(
        props.domainData.settingDisplayCondition.displayNumber *
          props.domainData.settingDisplayCondition.pageNumber,
        (props.domainData.settingDisplayCondition.pageNumber + 1) *
          props.domainData.settingDisplayCondition.displayNumber
      )
    }
    return props.domainData.settingList
  }, [props.domainData.settingDisplayCondition, props.domainData.settingList])

  /** 表示対象の登録先特徴量データグループデータ */
  const adjustFeatureDataGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.featureDataGroupDisplayCondition)) {
      let newFeatureDataGroupArray = props.domainData.featureDataGroups
      /** 検索ワードから検索 */
      if (props.domainData.featureDataGroupDisplayCondition.searchValue) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.filter(
          (item) =>
            item.featureDataGroupId.startsWith(
              props.domainData.featureDataGroupDisplayCondition.searchValue
            ) ||
            item.featureDataGroupId ===
              props.domainData.featureDataGroupDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedFeatureDataGroupListLength(newFeatureDataGroupArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedFeatureDataGroupListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.featureDataGroupDisplayCondition.sortKey ===
        'createdAt'
      ) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.featureDataGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else if (
        props.domainData.featureDataGroupDisplayCondition.sortKey ===
        'updatedAt'
      ) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.featureDataGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            if (item.featureDataGroupName < item2.featureDataGroupName) {
              return props.domainData.featureDataGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1
            }
            if (item.featureDataGroupName > item2.featureDataGroupName) {
              return props.domainData.featureDataGroupDisplayCondition
                .sortOrder === 'asc'
                ? 1
                : -1
            }
            // names must be equal
            return 0
          }
        )
      }
      /** 表示するベース特徴量データの整形 */
      return newFeatureDataGroupArray.slice(
        props.domainData.featureDataGroupDisplayCondition.displayNumber *
          props.domainData.featureDataGroupDisplayCondition.pageNumber,
        (props.domainData.featureDataGroupDisplayCondition.pageNumber + 1) *
          props.domainData.featureDataGroupDisplayCondition.displayNumber
      )
    }
    return props.domainData.featureDataGroups
  }, [
    props.domainData.featureDataGroupDisplayCondition,
    props.domainData.featureDataGroups,
  ])

  /** テーブルに表示ベース特徴量データの特徴量データグループのJSXの２次元配列 */
  const featureDataGroupRows = useMemo(() => {
    const rowData = adjustFeatureDataGroupTableRow.map((featureDataGroup) => {
      return {
        featureDataGroupId: featureDataGroup.featureDataGroupId,
        featureDataGroupName: featureDataGroup.featureDataGroupName,
        featureDataCount: featureDataGroup.featureDataCount,
        latestFeatureDataVersion: featureDataGroup.latestFeatureDataVersion,
        latestFeatureDataName: featureDataGroup.latestFeatureDataName,
        updatedAt: featureDataGroup.updatedAt
          ? formatDateTimeSec(featureDataGroup.updatedAt.toDate())
          : '',
        createdAt: featureDataGroup.createdAt
          ? formatDateTimeSec(featureDataGroup.createdAt.toDate())
          : '',
        createdBy: featureDataGroup.createdBy,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataGroupId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography data-testid={`feature-data-group-${value}`}>
                  {value.substring(0, 8)}
                </Typography>
              </Tooltip>
            )
          } else {
            return (
              <Typography key={key} data-testid={`feature-data-group-${value}`}>
                {value}
              </Typography>
            )
          }
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key !== 'featureDataGroupId' && value && value !== '') {
          return (
            <Typography key={key} data-testid={`feature-data-group-${value}`}>
              {value}
            </Typography>
          )
        } else if (key === 'featureDataCount') {
          return <Typography key={key}>{value}</Typography>
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustFeatureDataGroupTableRow])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const datasetRows = useMemo(() => {
    const rowData = adjustDatasetTableRow.map((dataset) => {
      return {
        name: dataset.name,
        generatedAt: dataset.createdAt
          ? formatDateTimeSec(dataset.createdAt.toDate())
          : '',
        remarks: dataset.remarks,
        datasetId: dataset.datasetId,
      }
    })
    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'datasetId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          }
        } else {
          return (
            <Typography key={key} data-testid={`datasets-${value}`}>
              {value}
            </Typography>
          )
        }
      })
    )
  }, [adjustDatasetTableRow, props.domainData.datasetList])
  /** データセットのテーブルの選択状態 */
  const selectedDatasetIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedDataset)) return -1
    return adjustDatasetTableRow.findIndex(
      (dataset) =>
        dataset.datasetId === props.domainData.selectedDataset?.datasetId
    )
  }, [adjustDatasetTableRow, props.domainData.selectedDataset])

  /** テーブルに表示するセッティングのJSXの２次元配列 */
  const settingsRows = useMemo(() => {
    const rowData = adjustSettingTableRow.map((setting) => {
      return {
        name: setting.name,
        generatedAt: setting.createdAt
          ? formatDateTimeSec(setting.createdAt.toDate())
          : '',
        remarks: setting.remarks,
        settingId: setting.settingId,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'settingId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          }
        } else {
          return (
            <Typography key={key} data-testid={`setting-${value}`}>
              {value || TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        }
      })
    )
  }, [adjustSettingTableRow])

  /** 特徴量データグループ詳細変換 */
  const featureDataGroupToDetail = (featureDataGroup?: FeatureDataGroup) => {
    if (isUndefined(featureDataGroup)) return undefined
    return {
      ['特徴量データグループID']: featureDataGroup.featureDataGroupId,
      ['特徴量データグループ名']: featureDataGroup.featureDataGroupName,
      ['登録特徴量データ数']: `${featureDataGroup.featureDataCount}`,
      ['最新バージョン']: featureDataGroup.latestFeatureDataVersion,
      ['最新特徴量データ名']: featureDataGroup.latestFeatureDataName,
      ['更新日時']: featureDataGroup.updatedAt
        ? formatDateTimeSec(featureDataGroup.updatedAt.toDate())
        : '',
      ['作成日時']: featureDataGroup.createdAt
        ? formatDateTimeSec(featureDataGroup.createdAt.toDate())
        : '',
      ['作成ユーザーID']: featureDataGroup.createdBy,
    }
  }

  /** データセット詳細変換 */
  const datasetToDetail = (dataset?: Dataset) => {
    if (isUndefined(dataset)) return undefined
    return {
      ['データセットID']: dataset.datasetId,
      ['データセット名']: dataset.name,
      ['登録日時']: dataset.createdAt
        ? formatDateTimeSec(dataset.createdAt.toDate())
        : '',
      ['Remarks']: dataset.remarks,
    }
  }

  /** データセット詳細変換 */
  const settingToDetail = (setting?: Setting) => {
    if (isUndefined(setting)) return undefined
    return {
      ['セッティングID']: setting.settingId,
      ['セッティング名']: setting.name,
      ['作成日時']: setting.createdAt
        ? formatDateTimeSec(setting.createdAt.toDate())
        : '',
      ['Remarks']: setting.remarks,
    }
  }

  /** 学習アルゴリズム詳細変換 */
  const trainingAlgorithmToDetail = (
    trainingAlgorithmVersion?: TrainingAlgorithmVersion
  ) => {
    if (isUndefined(trainingAlgorithmVersion)) return undefined
    return {
      ['バージョン']: trainingAlgorithmVersion.trainingAlgorithmVersion,
      ['登録日時']: trainingAlgorithmVersion.releasedAt
        ? formatDateTimeSec(trainingAlgorithmVersion.releasedAt.toDate())
        : '',
      ['remarks']: trainingAlgorithmVersion.metadata.remarks.ja,
    }
  }

  /** 選択中のテーブルの行 */
  const [selectRowIndex, setSelectRowIndex] = useState<number | undefined>(
    undefined
  )
  /** tableDialogの選択したrowのデータを出力用に変換する */
  const convertToDetail = () => {
    if (isUndefined(selectRowIndex)) return

    switch (props.appState.featureDataGeneratingState) {
      case 'DatasetState':
        if (isUndefined(adjustDatasetTableRow)) {
          return undefined
        }
        return datasetToDetail(adjustDatasetTableRow[selectRowIndex])
      case 'SettingState':
        if (isUndefined(adjustSettingTableRow)) {
          return undefined
        }
        return settingToDetail(adjustSettingTableRow[selectRowIndex])
      default:
        return undefined
    }
  }

  /** テーブルの列選択時のダイアログ */
  const tableDialog = useMemo(() => {
    if (isUndefined(selectRowIndex)) return
    /**  表示するものをここで受け取る */
    const detailItems = convertToDetail()
    if (isUndefined(detailItems)) {
      setSelectRowIndex(undefined)
      return
    }
    return (
      <TableDialog
        open={!isUndefined(selectRowIndex)}
        handleClose={() => setSelectRowIndex(undefined)}
        items={detailItems}
      />
    )
  }, [selectRowIndex])

  /** 検索処理 */
  const searchTableContent = () => {
    switch (props.appState.featureDataGeneratingState) {
      case 'FeatureDataGroupState':
        props.setFeatureDataGroupDisplayCondition({
          ...props.domainData.featureDataGroupDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      case 'DatasetState':
        props.setDatasetDisplayCondition({
          ...props.domainData.datasetDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        props.getDatasetList()
        break
      case 'SettingState':
        props.setSettingDisplayCondition({
          ...props.domainData.settingDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      default:
        break
    }
  }

  /** 学習アルゴリズムテーブルのソート設定 */
  const selectedAlgorithmTableOrder = (key: string) => {
    props.setTrainingDisplayCondition({
      sortKey: key,
      sortOrder:
        props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder ===
        'desc'
          ? 'asc'
          : 'desc',
    })
  }

  /** 学習アルゴリズムの初期値選択 */
  useEffect(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm?.algorithmId)) {
      let selectedAlgorithm = props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
      )[0]
      props.algorithms.find((algorithm) => {
        if (
          algorithm.algorithmId ==
          props.domainData.selectedTrainingAlgorithm?.algorithmId
        ) {
          selectedAlgorithm = algorithm
        }
      })
      if (
        selectedAlgorithm.algorithmId !==
        props.domainData.selectedTrainingAlgorithm?.algorithmId
      ) {
        return
      }
      const trainingAlgorithmVersions =
        selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
          (item1, item2) => {
            return compareVersionsWithPreRelease(
              item1.algorithmVersion,
              item2.algorithmVersion,
              props.domainData.trainingAlgorithmVersionDisplayCondition
                .sortOrder
            )
          }
        )

      const trainingAlgorithmVersion = trainingAlgorithmVersions[0]
      props.setSelectedTrainingAlgorithmVersion(trainingAlgorithmVersion)
    }
  }, [props.domainData.selectedTrainingAlgorithm?.algorithmId])

  /** 登録先特徴量データグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.featureDataGroups) &&
      isUndefined(props.domainData.selectedFeatureDataGroup)
    ) {
      props.setSelectedFeatureDataGroup(props.domainData.featureDataGroups[0])
    }
  }, [
    props.domainData.featureDataGroups,
    props.domainData.selectedTrainingAlgorithmVersion,
  ])

  /** アルゴリズムストラクチャーの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(algorithmStructureRows) &&
      !isUndefined(props.domainData.selectedTrainingAlgorithmVersion)
    ) {
      const algorithmVersion = props.domainData.selectedTrainingAlgorithmVersion

      const structures: AlgorithmStructureVersion[] = []

      algorithmVersion.algorithmStructures.forEach((algorithmStructure) => {
        algorithmStructure.algorithmStructureVersions.forEach(
          (algorithmStructureVersion) => {
            structures.push(algorithmStructureVersion)
          }
        )
      })

      const sortedStructures = structures.sort((a, b) =>
        compareVersions(
          a.algorithmStructureVersion,
          b.algorithmStructureVersion,
          'desc'
        )
      )
      props.setSelectedAlgorithmStructure(sortedStructures[0])
    }
  }, [algorithmStructureRows])

  /** データセットの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustDatasetTableRow) &&
      isUndefined(props.domainData.selectedDataset)
    ) {
      const dataset = adjustDatasetTableRow[0]
      props.setSelectedDataset(dataset)
    }
  }, [adjustDatasetTableRow])

  /** セッティングの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustSettingTableRow) &&
      isUndefined(props.domainData.selectedSetting)
    ) {
      props.setSelectedSetting(undefined, false)
    }
  }, [adjustSettingTableRow])

  /** 学習アルゴリズムのラジオボタンクリック */
  const selectTrainingAlgorithmTableRadio = (row: number) => {
    let selectedAlgorithm = props.algorithms.filter(
      (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
    )[0]
    props.algorithms.find((algorithm) => {
      if (
        algorithm.algorithmId ==
        props.domainData.selectedTrainingAlgorithm?.algorithmId
      ) {
        selectedAlgorithm = algorithm
      }
    })
    if (
      selectedAlgorithm.algorithmId !==
      props.domainData.selectedTrainingAlgorithm?.algorithmId
    ) {
      return
    }
    const trainingAlgorithmVersions =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
        (item1, item2) => {
          return compareVersionsWithPreRelease(
            item1.algorithmVersion,
            item2.algorithmVersion,
            props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder
          )
        }
      )
    const trainingAlgorithmVersion = trainingAlgorithmVersions[row]
    props.setSelectedTrainingAlgorithmVersion(trainingAlgorithmVersion)
  }

  const selectedTrainingAlgorithmIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedTrainingAlgorithmVersion))
      return -1
    let selectedAlgorithm = props.algorithms.filter(
      (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
    )[0]
    props.algorithms.find((algorithm) => {
      if (
        algorithm.algorithmId ==
        props.domainData.selectedTrainingAlgorithm?.algorithmId
      ) {
        selectedAlgorithm = algorithm
      }
    })
    if (
      selectedAlgorithm.algorithmId !==
      props.domainData.selectedTrainingAlgorithm?.algorithmId
    ) {
      return -1
    }

    const trainingAlgorithmVersions =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
        (item1, item2) => {
          return compareVersionsWithPreRelease(
            item1.algorithmVersion,
            item2.algorithmVersion,
            props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder
          )
        }
      )
    return trainingAlgorithmVersions.findIndex(
      (trainingAlgorithmVersion) =>
        trainingAlgorithmVersion.trainingAlgorithmVersion ===
        props.domainData.selectedTrainingAlgorithmVersion
          ?.trainingAlgorithmVersion
    )
  }, [
    trainingAlgorithmRows,
    props.algorithms,
    props.domainData.selectedTrainingAlgorithm?.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
  ])

  /** アルゴリズムストラクチャーバージョンのラジオボタンクリック */
  const selectAlgorithmStructureTableRadio = (row: number) => {
    if (
      !isUndefined(algorithmStructureRows) &&
      !isUndefined(props.domainData.selectedTrainingAlgorithmVersion)
    ) {
      const algorithm = props.domainData.selectedTrainingAlgorithmVersion

      const structures: AlgorithmStructureVersion[] = []

      algorithm.algorithmStructures.forEach((algorithmStructure) => {
        algorithmStructure.algorithmStructureVersions.forEach(
          (algorithmStructureVersion) => {
            structures.push(algorithmStructureVersion)
          }
        )
      })

      const sortedStructures = structures.sort((a, b) =>
        compareVersions(
          a.algorithmStructureVersion,
          b.algorithmStructureVersion,
          'desc'
        )
      )
      props.setSelectedAlgorithmStructure(sortedStructures[row])
    }
  }

  const selectedAlgorithmStructureIndex = useMemo(() => {
    if (
      isUndefined(props.domainData.selectedAlgorithmStructureVersion) ||
      isUndefined(props.domainData.selectedTrainingAlgorithmVersion)
    )
      return -1
    const algorithm = props.domainData.selectedTrainingAlgorithmVersion

    const structures: AlgorithmStructureVersion[] = []

    algorithm.algorithmStructures.forEach((algorithmStructure) => {
      algorithmStructure.algorithmStructureVersions.forEach(
        (algorithmStructureVersion) => {
          structures.push(algorithmStructureVersion)
        }
      )
    })

    const sortedStructures = structures.sort((a, b) =>
      compareVersions(
        a.algorithmStructureVersion,
        b.algorithmStructureVersion,
        'desc'
      )
    )
    return sortedStructures.findIndex(
      (structure) =>
        structure.algorithmStructureVersion.displayName ===
        props.domainData.selectedAlgorithmStructureVersion
          ?.algorithmStructureVersion.displayName
    )
  }, [
    algorithmStructureRows,
    props.domainData.selectedAlgorithmStructureVersion,
    props.algorithms,
    props.domainData.selectedTrainingAlgorithm?.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
  ])

  /** 登録先特徴量データグループテーブルの選択状態 */
  const selectedFeatureDataGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.featureDataGroups)) return -1
    return adjustFeatureDataGroupTableRow.findIndex(
      (featureDataGroup) =>
        featureDataGroup.featureDataGroupId ===
        props.domainData.selectedFeatureDataGroup?.featureDataGroupId
    )
  }, [
    adjustFeatureDataGroupTableRow,
    props.domainData.selectedFeatureDataGroup,
  ])

  /** セッティングのテーブルの選択状態 */
  const selectedSettingIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedSetting)) return -1
    return adjustSettingTableRow.findIndex(
      (setting) =>
        setting.settingId === props.domainData.selectedSetting?.settingId
    )
  }, [adjustSettingTableRow, props.domainData.selectedSetting])

  return {
    selectedFeatureDataTableRadio,
    selectedFeatureDataGroupIndex,
    featureDataGroupRows,
    algorithmStructureRows,
    selectedAlgorithmStructureIndex,
    selectedTrainingAlgorithmIndex,
    selectTrainingAlgorithmTableRadio,
    selectAlgorithmStructureTableRadio,
    selectedSettingIndex,
    selectDatasetTableRadio,
    selectSettingTableRadio,
    changeTableSortOrder,
    pageChange,
    trainingAlgorithmRows,
    handleChangeDisplayNumber,
    datasetToDetail,
    settingToDetail,
    setSelectRowIndex,
    tableDialog,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    searchedDatasetListLength,
    searchedSettingListLength,
    searchedFeatureDataGroupListLength,
    datasetRows,
    selectedDatasetIndex,
    settingsRows,
    isOpenDownloadFailedDialog,
    setIsOpenDownloadFailedDialog,
    selectedAlgorithmTableOrder,
    featureDataGroupToDetail,
    trainingAlgorithmToDetail,
  }
}
