import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import { State } from 'state/store'
import {
  SceneCameraListActions,
  sceneCameraListOperations,
  sceneCameraListActions,
  SceneCameraListDisplayCondition,
} from 'state/ducks/sceneCameraList'

import {
  BreadcrumbsComponent,
  CustomTrainingPageParagraph,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  GlobalLoading,
  SceneCameraIcon,
  SearchInput,
  SelectableTable,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  TooltipLink,
} from 'views/components'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { getUserGroupKindList } from 'views/containers/utils'
import { formatDateTimeSec } from 'views/components/utils/date'

const mapStateToProps = (state: State) => ({
  ...state.pages.sceneCameraListState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, SceneCameraListActions>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カメラ配置一覧取得 */
  getSceneCameraList: () => {
    dispatch(sceneCameraListOperations.getSceneCameraList())
  },
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: SceneCameraListDisplayCondition) =>
    dispatch(sceneCameraListActions.setListDisplayCondition(listCondition)),
  /** カメラ配置一覧のクリア */
  clearSceneCameraList: () =>
    dispatch(sceneCameraListActions.clearSceneCameraList()),
  /** snapshotの購読解除 */
  unsubscribe: () => dispatch(sceneCameraListOperations.unsubscribe()),
  /** Stateのクリア */
  clearSceneCameraListState: () =>
    dispatch(sceneCameraListActions.clearSceneCameraListState()),
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  addButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userGroupKindSelectBox: {
    width: '100%',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'aug-3d-scene-camera-id',
    title: 'カメラ配置ID',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: 'カメラ配置名',
    width: 240,
    sortable: false,
    position: 'left',
  },
  {
    id: 'overview',
    title: '概要',
    width: 240,
    sortable: false,
    position: 'left',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-by',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const SceneCameraList: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  useEffect(() => {
    return () => {
      props.unsubscribe()
    }
  }, [])

  useEffect(() => {
    props.getSceneCameraList()
    return () => {
      props.clearSceneCameraListState()
    }
  }, [props.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedUserGroupKind: selectUserGroupKind,
  } = tableActions(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.sceneCameraListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.sceneCameraListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.sceneCameraListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentSceneCameraList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentSceneCameraList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.sceneCameraListDisplayCondition,
    props.domainData.currentSceneCameraList,
  ])

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    return tableContent.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'createdBy') {
          if (value) {
            return <Typography key={key}>{value.substring(0, 8)}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'id') {
          return (
            <TooltipLink
              key={key}
              data-testid={`scene-camera-${value}`}
              title={value}
              placement='right-start'
              onClick={() => {
                history.push(
                  `3d-scene-cameras/${value}${
                    props.domainData.sceneCameraListDisplayCondition
                      .selectedUserGroupKind === 'UserGroup'
                      ? ''
                      : '?shared-user-group=true'
                  }`
                )
              }}
            />
          )
        } else if (key === 'createdAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const sceneCameraListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.sceneCameraListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.sceneCameraListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.sceneCameraListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.sceneCameraListDisplayCondition.sortKey,
          order: props.domainData.sceneCameraListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        isPointer={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.sceneCameraListDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            { name: 'カメラ配置一覧', path: '/3d-scene-cameras' },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <SceneCameraIcon
              className={classes.pageIcon}
              data-testid='sceneCameraListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='scene-camera-list-title'>カメラ配置</h2>
            </Typography>
          </Box>
          <Box>
            <Box className={classes.addButton}>
              <Add />
              <Button
                component='a'
                style={{
                  color: '#0000EE',
                  padding: 0,
                  margin: 0,
                  textDecoration: 'underline',
                }}
                data-testid='scene-cameras-entry'
                onClick={() => {
                  history.push('3d-scene-cameras/entry')
                }}
              >
                <Typography>カメラ配置を追加</Typography>
              </Button>
            </Box>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <CustomTrainingPageParagraph>
            <FormControl
              variant='outlined'
              className={classes.userGroupKindSelectBox}
            >
              <InputLabel id='sceneCameraListUserGroupKind'>
                データ種別
              </InputLabel>
              <Select
                data-testid='select'
                labelId='sceneCameraListUserGroupKind-label'
                id='sceneCameraListUserGroupKind-outlined'
                value={
                  props.domainData.sceneCameraListDisplayCondition
                    .selectedUserGroupKind
                }
                onChange={(
                  e: SelectChangeEvent<'UserGroup' | 'SharedUserGroup'>
                ) =>
                  selectUserGroupKind(
                    e.target.value as 'UserGroup' | 'SharedUserGroup'
                  )
                }
                label='Select User Group Kind'
              >
                {userGroupKindList.map((kind) => (
                  <MenuItem
                    data-testid={kind.kind}
                    value={kind.kind}
                    key={kind.kind}
                  >
                    {kind.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (カメラ配置ID)'
                value={
                  props.domainData.sceneCameraListDisplayCondition.searchValue
                }
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {sceneCameraListTable}
        </CustomTrainingPageParagraph>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const tableActions = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentSceneCameraList.length >
      props.domainData.sceneCameraListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.sceneCameraListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentSceneCameraList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getSceneCameraList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearSceneCameraList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.sceneCameraListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getSceneCameraList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getSceneCameraList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearSceneCameraList()
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      pageNumber: 0,
    })

    props.getSceneCameraList()
  }
  /** ユーザーグループ種別の選択 */
  const selectedUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearSceneCameraList()
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'created-at',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getSceneCameraList()
  }
  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedUserGroupKind,
  }
}

export const SceneCameraListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SceneCameraList))
