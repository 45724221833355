import { AnnotationSetDocument } from './types'

import { timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** annotation-sets documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as AnnotationSetDocument
  const schema = {
    required: [
      'annotation-id',
      'annotation-set-id',
      'annotation-set-kind',
      'grouped-data-id',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    type: 'object',
    properties: {
      'annotation-id': {
        type: 'string',
      },
      'annotation-set-id': {
        type: 'string',
      },
      'annotation-set-kind': {
        type: 'string',
      },
      'grouped-data-id': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'annotation-set',
      value: data as unknown as DataObject,
    })
    return false
  }
}
