import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  DatasetAugmentation,
  DatasetAugmentationListActionType,
  DatasetAugmentationListDisplayCondition,
} from './types'

export type DatasetAugmentationListAction = ActionsUnion<
  typeof datasetAugmentationListActions
>

export const datasetAugmentationListActions = {
  setList: (
    currentDatasetAugmentationList: DatasetAugmentation[]
  ): ActionWithPayload<
    'SET_DATASET_AUGMENTATION_LIST',
    { currentDatasetAugmentationList: DatasetAugmentation[] }
  > =>
    CreateAction(
      DatasetAugmentationListActionType.SET_DATASET_AUGMENTATION_LIST,
      {
        currentDatasetAugmentationList,
      }
    ),
  setListDisplayCondition: (
    datasetAugmentationListDisplayCondition?: DatasetAugmentationListDisplayCondition
  ): ActionWithPayload<
    'SET_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION',
    {
      datasetAugmentationListDisplayCondition?: DatasetAugmentationListDisplayCondition
    }
  > =>
    CreateAction(
      DatasetAugmentationListActionType.SET_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION,
      {
        datasetAugmentationListDisplayCondition,
      }
    ),
  clearDatasetDisplayCondition:
    (): Action<'CLEAR_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION'> =>
      CreateAction(
        DatasetAugmentationListActionType.CLEAR_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION
      ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      DatasetAugmentationListActionType.SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_LIST,
      {
        inProgress,
      }
    ),
  setMLPipeLineIdList: (
    mlPipelineIds: string[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_IDS_FOR_DATASET_AUGMENTATION_LIST',
    { mlPipelineIds: string[] }
  > =>
    CreateAction(
      DatasetAugmentationListActionType.SET_ML_PIPELINE_IDS_FOR_DATASET_AUGMENTATION_LIST,
      {
        mlPipelineIds,
      }
    ),
  setCurrentDatasetAugmentationListSnapshot: (
    currentDatasetAugmentationListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_DATASET_AUGMENTATION_LIST_SNAPSHOT',
    { currentDatasetAugmentationListSnapshot?: () => void }
  > =>
    CreateAction(
      DatasetAugmentationListActionType.SET_CURRENT_DATASET_AUGMENTATION_LIST_SNAPSHOT,
      {
        currentDatasetAugmentationListSnapshot,
      }
    ),
  clearDatasetAugmentationListState:
    (): Action<'CLEAR_DATASET_AUGMENTATION_LIST_STATE'> =>
      CreateAction(
        DatasetAugmentationListActionType.CLEAR_DATASET_AUGMENTATION_LIST_STATE
      ),
}
