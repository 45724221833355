import { Version, VersionWithPreRelease } from 'types/StateTypes'
import { isObject, isString, isNumber } from 'utils/typeguard'
import { DetailPathParams } from './types'

/** DetailPathParamsかどうか */
export function isDetailPathParams(
  object: unknown
): object is DetailPathParams {
  return isObject(object) && isString(object.id) && isString(object.p)
}

/** Versionかどうか */
export function isVersion(object: unknown): object is Version {
  return (
    isObject(object) &&
    isString(object.displayName) &&
    isNumber(object.major) &&
    isNumber(object.minor) &&
    isNumber(object.patch)
  )
}

/** VersionWithPreReleaseかどうか */
export function isVersionWithPreRelease(
  object: unknown
): object is VersionWithPreRelease {
  return (
    isObject(object) &&
    isString(object.displayName) &&
    isNumber(object.major) &&
    isNumber(object.minor) &&
    isNumber(object.patch) &&
    isNumber(object.preRelease)
  )
}
