import { SceneCameraListActions } from './actions'
import { SceneCameraListState, SceneCameraListActionType } from './types'

const initialState: SceneCameraListState = {
  domainData: {
    currentSceneCameraList: [],
    sceneCameraIds: [],
    sceneCameraListDisplayCondition: {
      searchValue: '',
      sortKey: 'created-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'UserGroup',
    },
    currentSceneCameraListSnapshot: undefined,
  },
  appState: {
    inProgress: false,
  },
}

export const SceneCameraListReducer = (
  state: SceneCameraListState = initialState,
  action: SceneCameraListActions
): SceneCameraListState => {
  switch (action.type) {
    case SceneCameraListActionType.SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sceneCameraIds: action.payload.sceneCameraIds,
        },
      }
    case SceneCameraListActionType.SET_SCENE_CAMERA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSceneCameraList: action.payload.sceneCameraList,
        },
      }
    case SceneCameraListActionType.SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sceneCameraListDisplayCondition:
            action.payload.sceneCameraListDisplayCondition,
        },
      }
    case SceneCameraListActionType.SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSceneCameraListSnapshot:
            action.payload.currentSceneCameraListSnapshot,
        },
      }
    case SceneCameraListActionType.CLEAR_SCENE_CAMERA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSceneCameraList: [],
        },
      }
    case SceneCameraListActionType.SET_IN_PROGRESS_FOR_SCENE_CAMERA_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case SceneCameraListActionType.CLEAR_SCENE_CAMERA_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
