import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'
import {
  buildDetailActions,
  BuildDetailOperations,
  BuildDetailAction,
  ContainerImage,
  EdgeContainerImage,
  SystemEvaluationFile,
} from 'state/ducks/buildDetail'

import { isUndefined } from 'utils/typeguard'
import {
  BuildIcon,
  CheckableTable,
  CheckableTableHeader,
  CopyableLabel,
  DataDetailItem,
  ExpandPaper,
  FileDownloadLabel,
  GlobalLoading,
  RunningTimeLabel,
  showToast,
  StatusProgressBar,
  Toast,
  BreadcrumbsComponent,
} from 'views/components'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import {
  formatDateTimeSec,
  formatTimeSecByDate,
  formatTimeSecByMillSecond,
  lowerThanDateOnly,
} from 'views/components/utils/date'
import {
  convertProgressWord,
  convertProgressColor,
  convertTransferStatusWord,
  convertByteToMatchUnit,
  handleResourceNotFound,
} from 'views/containers/utils'
import { isDetailPathParams } from 'views/containers/utils/typeguard'
import Divider from '@mui/material/Divider'
import { FileDownloadLabelPropsItems } from 'views/components/molecules/fileDownloadLabel/types'
import CircularProgress from '@mui/material/CircularProgress'
import { getTrainedModelGroupId } from 'utils/ducks/trainedModelGroup'

const mapStateToProps = (state: State) => ({
  ...state.pages.buildDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, BuildDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** ビルド詳細取得 */
  getBuildDetail: (mlPipelineId: string) =>
    dispatch(BuildDetailOperations.getBuildDetail(mlPipelineId)),
  /** ファイルデータ取得 */
  getFileData: () => dispatch(BuildDetailOperations.getFileData()),
  /** Stateのクリア */
  clearCurrentBuildDetail: () =>
    dispatch(buildDetailActions.clearCurrentBuildDetail()),
  /** resultファイルをダウンロードする */
  downloadSystemEvaluationFile: (link: SystemEvaluationFile) =>
    dispatch(BuildDetailOperations.downloadSystemEvaluationFile(link)),
  /** resultファイルをダウンロードする */
  downloadAllSystemEvaluationFiles: (
    systemEvaluationFiles: SystemEvaluationFile[]
  ) =>
    dispatch(
      BuildDetailOperations.downloadAllSystemEvaluationFiles(
        systemEvaluationFiles
      )
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(buildDetailActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const NO_TRANSFER_STATUS = '(N/A)'

/** アーキテクチャーのテーブルの選択状態 */
const CONTAINER_ARCHITECTURE_HEADERS: CheckableTableHeader[] = [
  {
    id: 'tags',
    title: 'Tags',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'osArch',
    title: 'OS/ARCH',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'baseImageId',
    title: 'Base Image ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** アーキテクチャーのテーブルの選択状態 */
const EDGE_CONTAINER_IMAGE_HEADERS: CheckableTableHeader[] = [
  {
    id: 'edgeContainerImageId',
    title: 'Edge Container Image ID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'tags',
    title: 'Tags',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'osArch',
    title: 'OS/ARCH',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkButton: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  noteTabButtonDisabled: {
    backgroundColor: theme.palette.grey[100],
  },
  mt2Box: {
    marginTop: '16px',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionContent: {
    marginBottom: '16px',
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  fileDataLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}))

const BuildDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()

  /** 初期実行 */
  useEffect(() => {
    return () => {
      props.clearCurrentBuildDetail()
    }
  }, [])

  useEffect(() => {
    if (props.domainData.currentBuildDetail?.mlPipelineId) {
      props.getFileData()
    }
  }, [props.domainData.currentBuildDetail?.mlPipelineId])

  useEffect(() => {
    props.getBuildDetail(
      isDetailPathParams(props.match.params) ? props.match.params.id : ''
    )
  }, [props.match.params])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.buildDetailState.mlPipelineDataState,
      history
    )
  }, [props.appState.buildDetailState.mlPipelineDataState])

  /** urlから取得したmlPipelineId */
  const mlPipelineId = isDetailPathParams(props.match.params)
    ? props.match.params.id
    : ''
  if (mlPipelineId === '') {
    console.error('Error Invalid ML Pipeline ID')
    return <></>
  }

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** アクションラベルに表示するコンテンツ */
  // TODO: 推論指示、学習開始画面で渡すクエリパラメータの修正が必要
  const getExpandPaperContents = (): {
    id: string
    element: React.ReactNode
  }[] => [
    {
      id: 'build',
      element: (
        <>
          <BuildIcon data-testid='BuildLinkIcon' />
          <Link
            variant='body1'
            data-testid='use-for-build'
            className={classes.linkButton}
            underline='none'
            onClick={() => {
              history.push({
                pathname: '/builds/entry',
                search: `?trained-model-group-id=${props.domainData.currentBuildDetail?.trainedModel.trainedModelGroupId}&trained-model-id=${props.domainData.currentBuildDetail?.trainedModel.trainedModelId}&algorithm-id=${props.domainData.currentBuildDetail?.trainingAlgorithm.algorithmId}`,
              })
            }}
          >
            再度ビルドする
          </Link>
        </>
      ),
    },
  ]

  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** モデルグループIDを取得 */
  const getTrainedModelGroupIdPath = async (
    userGroupId: string,
    modelId: string
  ) => {
    const modelGroupId = await getTrainedModelGroupId(userGroupId, modelId)
    return modelGroupId
  }

  const [nowTab, setNowTab] = useState(0)

  /**
   * アーキテクチャを全て選択にする
   */
  const [baseContainerImageRows] = useMemo(() => {
    if (!props.domainData.currentBuildDetail?.baseContainerImagelist) return []

    const containerImageList: ContainerImage[] =
      props.domainData.currentBuildDetail?.baseContainerImagelist
    const tagSortedRowData = containerImageList.sort((prev, next) => {
      const tagStringPrev = prev.containerImageTags.join()
      const tagStringNext = next.containerImageTags.join()
      return tagStringPrev.localeCompare(tagStringNext)
    })
    const osArchSortedRowData = tagSortedRowData.sort((prev, next) => {
      const osArchStringPrev = `${prev.containerImagePlatform.os}/${prev.containerImagePlatform.architecture}`
      const osArchStringNext = `${next.containerImagePlatform.os}/${next.containerImagePlatform.architecture}`
      const tagStringPrev = prev.containerImageTags.join()
      const tagStringNext = next.containerImageTags.join()
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev < osArchStringNext
      ) {
        return -1
      }
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev > osArchStringNext
      ) {
        return 1
      }
      // names must be equal
      return 0
    })

    const rows: JSX.Element[][] = osArchSortedRowData.map<JSX.Element[]>(
      (item) => {
        return [
          <Tooltip
            key={'tags'}
            title={item.containerImageTags.join(', ')}
            placement='bottom'
          >
            <Typography key={'tags'}>
              {item.containerImageTags.join(', ')}
            </Typography>
          </Tooltip>,
          <Typography key={'osArch'}>
            {item.containerImagePlatform.os}/
            {item.containerImagePlatform.architecture}
          </Typography>,
          <Tooltip
            key={'baseImageId'}
            data-testid={`model-group-${item.baseInferenceContainerImageId}`}
            title={item.baseInferenceContainerImageId}
            placement='bottom'
          >
            <Typography>
              {item.baseInferenceContainerImageId.substring(0, 8)}
            </Typography>
          </Tooltip>,
        ]
      }
    )

    return [rows]
  }, [props.domainData.currentBuildDetail?.baseContainerImagelist])

  /**
   * アーキテクチャを全て選択にする
   */
  const [edgeContainerImageRows] = useMemo(() => {
    if (!props.domainData.currentBuildDetail?.edgeContainerImagelist) return []

    const containerImageList: EdgeContainerImage[] =
      props.domainData.currentBuildDetail?.edgeContainerImagelist
    const tagSortedRowData = containerImageList.sort((prev, next) => {
      const tagStringPrev = prev.edgeContainerImageTags.join()
      const tagStringNext = next.edgeContainerImageTags.join()
      return tagStringPrev.localeCompare(tagStringNext)
    })
    const osArchSortedRowData = tagSortedRowData.sort((prev, next) => {
      const osArchStringPrev = prev.edgeContainerImagePlatform
        ? `${prev.edgeContainerImagePlatform.os}/${prev.edgeContainerImagePlatform.architecture}`
        : ''
      const osArchStringNext = next.edgeContainerImagePlatform
        ? `${next.edgeContainerImagePlatform.os}/${next.edgeContainerImagePlatform.architecture}`
        : ''
      const tagStringPrev = prev.edgeContainerImageTags.join()
      const tagStringNext = next.edgeContainerImageTags.join()
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev < osArchStringNext
      ) {
        return -1
      }
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev > osArchStringNext
      ) {
        return 1
      }
      // names must be equal
      return 0
    })

    const rows = osArchSortedRowData.map((item) => {
      return [
        <Tooltip
          key={'edgeContainerImageId'}
          data-testid={`model-group-${item.edgeContainerImageId}`}
          title={item.edgeContainerImageId}
          placement='bottom'
        >
          <Typography>{item.edgeContainerImageId.substring(0, 8)}</Typography>
        </Tooltip>,
        <Tooltip
          key={'tags'}
          title={item.edgeContainerImageTags.join(', ')}
          placement='bottom'
        >
          <Typography key={'tags'}>
            {item.edgeContainerImageTags.join(', ')}
          </Typography>
        </Tooltip>,
        {
          ...(item.edgeContainerImagePlatform &&
          (item.edgeContainerImagePlatform.os ||
            item.edgeContainerImagePlatform.architecture) ? (
            <Typography key={'osArch'}>
              {item.edgeContainerImagePlatform.os ?? TABLE_CELL_NOT_APPLICABLE}/
              {item.edgeContainerImagePlatform.architecture ??
                TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          ) : (
            <Typography key={'osArch'} color={'text.secondary'}>
              {TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )),
        },
      ]
    })

    return [rows]
  }, [props.domainData.currentBuildDetail?.edgeContainerImagelist])

  /** システム評価結果ファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.buildDetailState.systemEvaluationFileSubState === 'Failed'
    ) {
      showErrorToast('システム評価結果ファイルの取得に失敗しました。')
    }
  }, [props.appState.buildDetailState.systemEvaluationFileSubState])
  /** ファイルデータ取得中のloading */
  const fileDataLoading = useMemo(() => {
    if (props.appState.isInProgressForGettingSystemEvaluation) {
      if (nowTab === 1) {
        return (
          <Box className={classes.fileDataLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [props.appState.isInProgressForGettingSystemEvaluation, nowTab])

  const fileDownloadLabelPropsItems = (
    files: SystemEvaluationFile[]
  ): FileDownloadLabelPropsItems[] => {
    return files.map((file) => {
      return {
        timeAndCapacity: `${convertByteToMatchUnit(
          file.fileSize
        )} ${formatDateTimeSec(file.createdAt)}`,
        url: file.fileName,
        onClick: () => props.downloadSystemEvaluationFile(file),
      } as FileDownloadLabelPropsItems
    })
  }

  // 一般情報コンテンツ
  const infoTab: TabItems = {
    label: '一般情報',
    displayInfo: (
      <>
        <Box component={Paper} my={2}>
          <Box p={'24px 32px 32px'}>
            <DataDetailItem
              formHelperText='学習モデル'
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentBuildDetail
                      ? props.domainData.currentBuildDetail.trainedModel
                          .trainedModelId
                      : ''
                  }
                  isTooltip
                  placement='top'
                />
              }
              startAdornment={
                props.domainData.currentBuildDetail?.trainedModel
                  .trainedModelName ? (
                  <Link
                    variant='body1'
                    data-testid='trained-model-detail'
                    className={classes.linkButton}
                    underline='none'
                    onClick={async () => {
                      if (props.domainData.currentBuildDetail) {
                        const trainedModelGroupId =
                          await getTrainedModelGroupIdPath(
                            props.domainData.currentBuildDetail?.trainedModel
                              .isSharedUserGroupModel
                              ? props.auth.customClaims.sharedList[0]
                              : props.auth.customClaims.userGroupId,
                            props.domainData.currentBuildDetail.trainedModel
                              .trainedModelId
                          )
                        history.push(
                          `/model-groups/${trainedModelGroupId}/models/${
                            props.domainData.currentBuildDetail.trainedModel
                              .trainedModelId
                          }${
                            props.domainData.currentBuildDetail.trainedModel
                              .isSharedUserGroupModel
                              ? '?shared-user-group=true'
                              : ''
                          }`
                        )
                      }
                    }}
                  >
                    {props.domainData.currentBuildDetail
                      ? props.domainData.currentBuildDetail?.trainedModel
                          .trainedModelName
                      : ''}
                  </Link>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
            <Box mt={1}>
              <DataDetailItem
                formHelperText='推論アルゴリズムバージョン'
                startAdornment={
                  props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail
                    .inferenceAlgorithmVersion ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentBuildDetail
                            .inferenceAlgorithmVersion.displayName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='コンテナI/Fバージョン'
                startAdornment={
                  props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail
                    .containerInterfaceVersion ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentBuildDetail
                            .containerInterfaceVersion.displayName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='推論コードバージョン'
                startAdornment={
                  props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail.inferenceCodeVersion ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentBuildDetail
                            .inferenceCodeVersion.displayName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <Box>
                <Typography
                  sx={{ color: 'text.secondary', fontSize: '0.75rem' }}
                >
                  Container Architecture
                </Typography>
                <Box
                  sx={{
                    '& table': { color: 'text.secondary', fontSize: '0.75rem' },
                  }}
                >
                  <CheckableTable
                    headers={CONTAINER_ARCHITECTURE_HEADERS}
                    fixedColumnNumber={0}
                    page={0}
                    displayNoneCheckbox={true}
                    noEmptyRows={true}
                    disabled={true}
                    rows={baseContainerImageRows ?? []}
                  />
                </Box>
              </Box>
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='System Evaluation'
                startAdornment={
                  props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail.isSystemEvaluation !==
                    undefined ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {props.domainData.currentBuildDetail.isSystemEvaluation
                          ? 'ON'
                          : 'OFF'}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='Transfer to Connected Service'
                startAdornment={
                  props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail.isTransfer !==
                    undefined ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {props.domainData.currentBuildDetail.isTransfer
                          ? 'ON'
                          : 'OFF'}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
  }
  const resultTab: TabItems = {
    label: '処理結果',
    displayInfo: (
      <>
        <Box component={Paper} my={2}>
          <Box p={'24px 32px 32px'}>
            <Typography>{'AIライブラリ'}</Typography>
            <DataDetailItem
              formHelperText='AIライブラリバージョン'
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentBuildDetail &&
                    props.domainData.currentBuildDetail
                      .edgeImageContainerImageGroup
                      ? props.domainData.currentBuildDetail
                          .edgeImageContainerImageGroup
                          .edgeImageContainerImageGroupId
                      : ''
                  }
                  isTooltip
                  placement='top'
                />
              }
              startAdornment={
                props.domainData.currentBuildDetail &&
                props.domainData.currentBuildDetail
                  .edgeImageContainerImageGroup &&
                props.domainData.currentBuildDetail.edgeImageContainerImageGroup
                  .edgeImageContainerImageGroupVersion ? (
                  <Box sx={{ color: 'text.primary' }}>
                    <Typography>
                      {
                        props.domainData.currentBuildDetail
                          .edgeImageContainerImageGroup
                          .edgeImageContainerImageGroupVersion.displayName
                      }
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
            <Box mt={1}>
              <DataDetailItem
                formHelperText='ビルド完了日時'
                startAdornment={
                  props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail.buildEndedAt ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {`${formatDateTimeSec(
                          props.domainData.currentBuildDetail.buildEndedAt.toDate()
                        )}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='転送完了日時'
                startAdornment={
                  props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail.transferEndedAt ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {`${formatDateTimeSec(
                          props.domainData.currentBuildDetail.transferEndedAt.toDate()
                        )}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={2}>
              <Box display='flex' justifyContent='end'>
                <Typography sx={{ color: 'text.primary' }}>
                  イメージ数{' '}
                  {
                    props.domainData.currentBuildDetail?.edgeContainerImagelist
                      .length
                  }
                </Typography>
              </Box>
              <Box
                sx={{
                  '& table': { color: 'text.secondary', fontSize: '0.75rem' },
                }}
              >
                <CheckableTable
                  headers={EDGE_CONTAINER_IMAGE_HEADERS}
                  fixedColumnNumber={0}
                  page={0}
                  displayNoneCheckbox={true}
                  noEmptyRows={true}
                  disabled={true}
                  rows={edgeContainerImageRows ?? []}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component={Paper} mt={2} sx={{ position: 'relative' }}>
          <Box p={'24px 32px 32px'}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box display='flex'>
                <Typography component='div'>
                  <h4>システム評価結果</h4>
                </Typography>
              </Box>
              {props.domainData.systemEvaluationFiles.length > 0 ? (
                <Box display='flex'>
                  <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                  <Link
                    data-testid='all-download-logs'
                    style={{ cursor: 'pointer' }}
                    underline='none'
                    onClick={() =>
                      props.domainData.currentBuildDetail &&
                      props.downloadAllSystemEvaluationFiles(
                        props.domainData.systemEvaluationFiles
                      )
                    }
                  >
                    一括ダウンロード
                  </Link>
                </Box>
              ) : (
                <></>
              )}
            </div>
            <Divider />
            <FileDownloadLabel
              items={fileDownloadLabelPropsItems(
                props.domainData.systemEvaluationFiles
              )}
            />
          </Box>
          {fileDataLoading}
        </Box>
      </>
    ),
  }
  // 備考タブコンテンツ
  const noteTab: TabItems = {
    label: '備考',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <TextField
              style={{ width: '100%' }}
              value={props.domainData.currentBuildDetail?.mlPipelineRemarks}
              variant='outlined'
              multiline
              disabled
              minRows={5}
              inputProps={{ 'data-testid': 'input-remarks' }}
            />
          </Box>
        </Box>
      </>
    ),
  }

  const tabItems: TabItems[] = [infoTab, resultTab, noteTab]
  return (
    <>
      {!isUndefined(props.domainData.currentBuildDetail) ? (
        <>
          <div className={classes.container}>
            <Toast containerOptions={{ limit: 20 }}>
              <Box
                style={{
                  position: 'sticky',
                  top: '64px',
                  backgroundColor: '#fafafa',
                  zIndex: 10,
                }}
              >
                <Box className={classes.innerContainer}>
                  <Box pt={3}>
                    <BreadcrumbsComponent
                      breadcrumbsPath={[
                        {
                          name: 'ビルド一覧',
                          path: 'builds',
                        },
                        {
                          name:
                            props.domainData.currentBuildDetail
                              .mlPipelineName !== ''
                              ? `${props.domainData.currentBuildDetail.mlPipelineName}`
                              : `${props.domainData.currentBuildDetail.mlPipelineId}`,
                          path: `${props.domainData.currentBuildDetail.mlPipelineId}`,
                        },
                      ]}
                    />
                  </Box>
                  <div className={classes.flexAndBetween}>
                    <Box
                      display='flex'
                      style={{
                        maxWidth: 'calc(100% - 280px)',
                        overflow: 'hidden',
                      }}
                    >
                      <BuildIcon
                        className={classes.pageIcon}
                        data-testid='buildListTitleIcon'
                      />{' '}
                      <Box
                        height={76}
                        data-testid='build-detail-title'
                        style={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <Typography component='div'>
                          <h2
                            style={{
                              maxWidth: '100%',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {props.domainData.currentBuildDetail.mlPipelineName}
                          </h2>
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box mr={3}>
                        <CopyableLabel
                          value={mlPipelineId}
                          isTooltip
                          placement='top'
                        />
                      </Box>
                      <Box width='150px'>
                        <StatusProgressBar
                          status={convertProgressWord(
                            props.domainData.currentBuildDetail.progress
                              .transactionStatus
                          )}
                          progressRate={
                            props.domainData.currentBuildDetail.progress
                              .progressRate
                          }
                          progressColor={convertProgressColor(
                            props.domainData.currentBuildDetail.progress
                              .transactionStatus
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div className={classes.flexAndBetween}>
                    <Box display='flex' alignItems='center'>
                      <HandymanIcon
                        style={{ marginRight: '4px' }}
                        sx={{ color: 'text.secondary' }}
                      />
                      <Box sx={{ color: 'text.secondary' }}>
                        <Typography component='div'>
                          <h4>
                            {`${
                              props.domainData.currentBuildDetail
                                .trainingAlgorithm.algorithmName
                            } 
                        v ${
                          props.domainData.currentBuildDetail.trainingAlgorithm
                            .trainingAlgorithmVersion.displayName
                            ? props.domainData.currentBuildDetail
                                .trainingAlgorithm.trainingAlgorithmVersion
                                .displayName
                            : NO_NAME
                        }`}
                          </h4>
                        </Typography>
                      </Box>
                    </Box>
                    {props.domainData.currentBuildDetail &&
                    props.domainData.currentBuildDetail.transferStatus ? (
                      <Box sx={{ color: 'text.primary' }}>
                        <Typography>
                          {convertTransferStatusWord(
                            props.domainData.currentBuildDetail.transferStatus
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ color: 'text.secondary' }}>
                        <Typography>{NO_TRANSFER_STATUS}</Typography>
                      </Box>
                    )}
                  </div>
                  <div className={classes.flexAndBetween}>
                    <Box display='flex' mb={1}>
                      <TimerIcon style={{ marginRight: '4px' }} />
                      {isUndefined(
                        props.domainData.currentBuildDetail.mlPipelineEndedAt
                      ) ? (
                        // endAtがundefinedの場合
                        <Box alignItems='center' display='flex'>
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentBuildDetail.mlPipelineStartedAt.toDate()
                          )} ~ `}</Typography>
                          (
                          <RunningTimeLabel
                            startedAt={props.domainData.currentBuildDetail.mlPipelineStartedAt.toDate()}
                          />
                          )
                        </Box>
                      ) : lowerThanDateOnly(
                          props.domainData.currentBuildDetail.mlPipelineStartedAt.toDate(),
                          props.domainData.currentBuildDetail.mlPipelineEndedAt.toDate()
                        ) ? (
                        // 日付が同じ場合
                        <Typography>{`${formatDateTimeSec(
                          props.domainData.currentBuildDetail.mlPipelineStartedAt.toDate()
                        )} ~ ${formatTimeSecByDate(
                          props.domainData.currentBuildDetail.mlPipelineEndedAt.toDate()
                        )}(${formatTimeSecByMillSecond(
                          props.domainData.currentBuildDetail.mlPipelineEndedAt
                            .toDate()
                            .getTime() -
                            props.domainData.currentBuildDetail.mlPipelineStartedAt
                              .toDate()
                              .getTime()
                        )})`}</Typography>
                      ) : (
                        // 日付が違う場合
                        <Typography>{`${formatDateTimeSec(
                          props.domainData.currentBuildDetail.mlPipelineStartedAt.toDate()
                        )} ~ ${formatDateTimeSec(
                          props.domainData.currentBuildDetail.mlPipelineEndedAt.toDate()
                        )}(${formatTimeSecByMillSecond(
                          props.domainData.currentBuildDetail.mlPipelineEndedAt
                            .toDate()
                            .getTime() -
                            props.domainData.currentBuildDetail.mlPipelineStartedAt
                              .toDate()
                              .getTime()
                        )})`}</Typography>
                      )}
                    </Box>
                    <Box display='flex'>
                      {props.domainData.currentBuildDetail?.createdBy ? (
                        <Typography>
                          {props.domainData.currentBuildDetail?.createdBy}
                        </Typography>
                      ) : (
                        <Typography> </Typography>
                      )}
                    </Box>
                  </div>
                  {props.domainData.currentBuildDetail &&
                  props.domainData.currentBuildDetail.progress
                    .transactionStatus === 'Completed' ? (
                    <Paper className={classes.actionContent}>
                      <ExpandPaper
                        paperTitle={'アクション'}
                        defaultExpand={true}
                        contents={getExpandPaperContents()}
                      />
                    </Paper>
                  ) : (
                    <></>
                  )}

                  <Box
                    style={{
                      backgroundColor: '#fafafa',
                    }}
                  >
                    <Tabs
                      indicatorColor='primary'
                      value={nowTab}
                      style={{
                        paddingBottom: '16px',
                        marginBottom: '1px',
                      }}
                      onChange={(_, value) => setNowTab(value)}
                    >
                      {tabItems.map((item, index) => (
                        <Tab
                          style={{
                            width: `${100 / tabItems.length}%`,
                            maxWidth: '1200px',
                          }}
                          key={index}
                          className={clsx(nowTab === index && classes.nowTab)}
                          label={item.label}
                          data-testid={`change-tab-${index}`}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
            </Toast>
          </div>
        </>
      ) : (
        <></>
      )}
      <GlobalLoading
        open={
          props.appState.isInProgressForGettingBuildDetail ||
          props.appState.isInProgressForDownloading
        }
      />
    </>
  )
}

export const BuildDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuildDetail))
