import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  ModelDetailActionType,
  CurrentTrainedModelDetail,
  ModelDetailState,
  ToastInfo,
  ModelListDisplayCondition,
} from './types'

export type ModelDetailAction = ActionsUnion<typeof modelDetailActions>

export const modelDetailActions = {
  setCurrentModelDetail: (
    currentTrainedModelDetail: CurrentTrainedModelDetail
  ): ActionWithPayload<
    'SET_CURRENT_MODEL_DETAIL',
    { currentTrainedModelDetail: CurrentTrainedModelDetail }
  > =>
    CreateAction(ModelDetailActionType.SET_CURRENT_MODEL_DETAIL, {
      currentTrainedModelDetail,
    }),
  setModelDetailState: (
    modelDetailState: ModelDetailState
  ): ActionWithPayload<
    'SET_MODEL_DETAIL_STATE',
    { modelDetailState: ModelDetailState }
  > =>
    CreateAction(ModelDetailActionType.SET_MODEL_DETAIL_STATE, {
      modelDetailState,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MODEL_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(ModelDetailActionType.SET_IN_PROGRESS_FOR_MODEL_DETAIL, {
      inProgress,
    }),
  clearModelDetailState: (): Action<'CLEAR_MODEL_DETAIL_STATE'> =>
    CreateAction(ModelDetailActionType.CLEAR_MODEL_DETAIL_STATE),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_MODEL_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(ModelDetailActionType.SET_TOAST_INFO_FOR_MODEL_DETAIL, {
      toastInfo,
    }),
  setListDisplayCondition: (
    condition: ModelListDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_DISPLAY_CONDITION',
    { condition: ModelListDisplayCondition }
  > =>
    CreateAction(ModelDetailActionType.SET_TRAINED_MODEL_DISPLAY_CONDITION, {
      condition,
    }),
  setModelListPagingState: (
    nextPageSubState: 'Enable' | 'Unable'
  ): ActionWithPayload<
    'SET_TABLE_NEXT_PAGE_SUB_STATE',
    { nextPageSubState: 'Enable' | 'Unable' }
  > =>
    CreateAction(ModelDetailActionType.SET_TABLE_NEXT_PAGE_SUB_STATE, {
      nextPageSubState,
    }),
}
