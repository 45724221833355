import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Slider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider,
} from '@mui/material'
import { CheckList, OnsiteImageViewerSettingDialogProps } from './types'
import { makeStyles } from 'tss-react/mui'

const SLIDER_MARKS = [
  { value: 0, label: '0' },
  ...Array.from({ length: 10 }).map((_, i) => {
    const value = (i + 1) * 10
    let label
    if (value === 50) {
      label = '50'
    } else if (value === 100) {
      label = '100'
    }
    return { value, label }
  }),
]

const useStyles = makeStyles()(() => ({
  slider: {
    '& > .MuiSlider-track': {
      border: 'none',
    },
  },
}))

export const OnsiteImageViewerSettingDialog: React.FC<
  OnsiteImageViewerSettingDialogProps
> = (props: OnsiteImageViewerSettingDialogProps) => {
  const [score, setScore] = useState(props.defaultScore ?? 0)
  const [checkList, setCheckList] = useState<CheckList>(
    props.defaultCheckList ?? {
      mask: true,
      bbox: true,
    }
  )
  const { classes } = useStyles()
  return (
    <Dialog onClose={props.handleClose} open={props.open} fullWidth={true}>
      <Box m={2}>
        <DialogTitle sx={{ paddingLeft: 0 }}>Settings</DialogTitle>
        <Divider sx={{ marginBottom: (theme) => theme.spacing(3) }} />
        <DialogContent
          sx={{
            padding: 0,
            root: {
              padding: (theme) => theme.spacing(2),
            },
          }}
        >
          <Box mb={3}>
            <Typography variant='body2' color='inherit'>
              Drawing Item(s)
            </Typography>
            <FormGroup
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'initial',
              }}
            >
              {Object.entries(checkList).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  label={key.toUpperCase()}
                  control={
                    <Checkbox
                      checked={value}
                      size='small'
                      color='secondary'
                      sx={{ marginLeft: (theme) => theme.spacing(2) }}
                      onChange={(_, checked) =>
                        setCheckList({ ...checkList, [key]: checked })
                      }
                    />
                  }
                />
              ))}
            </FormGroup>
            <Divider sx={{ marginBottom: (theme) => theme.spacing(3) }} />
          </Box>
          <Box mb={3}>
            <Typography variant='body2' color='inherit'>
              Score filter
            </Typography>
            <Typography
              variant='body2'
              color='inherit'
              sx={{ paddingLeft: (theme) => theme.spacing(1) }}
            >
              {score}
            </Typography>
            <Box>
              <Slider
                className={classes.slider}
                marks={SLIDER_MARKS}
                value={score}
                defaultValue={score}
                step={1}
                min={0}
                max={100}
                valueLabelDisplay='auto'
                track='inverted'
                onChange={(e, value) => setScore(value as number)}
                sx={{
                  width: (theme) => `calc(100% - ${theme.spacing(4)})`,
                  margin: (theme) => `0 ${theme.spacing(2)}`,
                }}
              />
            </Box>
          </Box>
          <Divider sx={{ marginBottom: (theme) => theme.spacing(6) }} />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', padding: 0 }}>
          <Button
            variant='contained'
            onClick={props.handleClose}
            color={'inherit'}
          >
            CANCEL
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              props.handleApply({ ...checkList, score })
              props.handleClose()
            }}
          >
            APPLY
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
