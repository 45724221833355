import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  ExecuteSubState,
  InputRequireType,
  ScenePresetInfo,
  ScenePresetListActionType,
  ScenePresetListDisplayCondition,
  ScenePresetState,
  ThreeDimensionalData,
} from './types'

export type ScenePresetListActions = ActionsUnion<typeof scenePresetListActions>

export const scenePresetListActions = {
  setAugParamTemplateIdList: (
    augParamTemplateIds: string[]
  ): ActionWithPayload<
    'SET_AUG_PARAM_TEMPLATE_IDS_FOR_SCENE_PRESET_LIST',
    { augParamTemplateIds: string[] }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_AUG_PARAM_TEMPLATE_IDS_FOR_SCENE_PRESET_LIST,
      {
        augParamTemplateIds,
      }
    ),
  setCurrentScenePresetListSnapshot: (
    currentScenePresetListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_SCENE_PRESET_LIST_SNAPSHOT',
    { currentScenePresetListSnapshot?: () => void }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_CURRENT_SCENE_PRESET_LIST_SNAPSHOT,
      {
        currentScenePresetListSnapshot,
      }
    ),
  setScenePresetList: (
    scenePresetList: ScenePresetInfo[]
  ): ActionWithPayload<
    'SET_SCENE_PRESET_LIST',
    { scenePresetList: ScenePresetInfo[] }
  > =>
    CreateAction(ScenePresetListActionType.SET_SCENE_PRESET_LIST, {
      scenePresetList,
    }),
  clearScenePresetList: (): Action<'CLEAR_SCENE_PRESET_LIST'> =>
    CreateAction(ScenePresetListActionType.CLEAR_SCENE_PRESET_LIST),
  setListDisplayCondition: (
    scenePresetListDisplayCondition: ScenePresetListDisplayCondition
  ): ActionWithPayload<
    'SET_SCENE_PRESET_LIST_DISPLAY_CONDITION',
    { scenePresetListDisplayCondition: ScenePresetListDisplayCondition }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_SCENE_PRESET_LIST_DISPLAY_CONDITION,
      {
        scenePresetListDisplayCondition,
      }
    ),
  setBackgrounds: (
    backgrounds: ThreeDimensionalData[]
  ): ActionWithPayload<
    'SET_BACKGROUNDS_FOR_SCENE_PRESET_LIST',
    { backgrounds: ThreeDimensionalData[] }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_BACKGROUNDS_FOR_SCENE_PRESET_LIST,
      {
        backgrounds,
      }
    ),
  setCameras: (
    cameras: ThreeDimensionalData[]
  ): ActionWithPayload<
    'SET_CAMERAS_FOR_SCENE_PRESET_LIST',
    { cameras: ThreeDimensionalData[] }
  > =>
    CreateAction(ScenePresetListActionType.SET_CAMERAS_FOR_SCENE_PRESET_LIST, {
      cameras,
    }),
  setLightGroups: (
    lightGroups: ThreeDimensionalData[]
  ): ActionWithPayload<
    'SET_LIGHT_GROUPS_FOR_SCENE_PRESET_LIST',
    { lightGroups: ThreeDimensionalData[] }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_LIGHT_GROUPS_FOR_SCENE_PRESET_LIST,
      {
        lightGroups,
      }
    ),
  setScenePresetBackgrounds: (
    selectedBackgrounds: ThreeDimensionalData[]
  ): ActionWithPayload<
    'SET_SCENE_PRESET_BACKGROUNDS',
    { selectedBackgrounds: ThreeDimensionalData[] }
  > =>
    CreateAction(ScenePresetListActionType.SET_SCENE_PRESET_BACKGROUNDS, {
      selectedBackgrounds,
    }),
  setScenePresetCameras: (
    selectedCameras: ThreeDimensionalData[]
  ): ActionWithPayload<
    'SET_SCENE_PRESET_CAMERAS',
    { selectedCameras: ThreeDimensionalData[] }
  > =>
    CreateAction(ScenePresetListActionType.SET_SCENE_PRESET_CAMERAS, {
      selectedCameras,
    }),
  setScenePresetLightGroups: (
    selectedLightGroups: ThreeDimensionalData[]
  ): ActionWithPayload<
    'SET_SCENE_PRESET_LIGHT_GROUPS',
    { selectedLightGroups: ThreeDimensionalData[] }
  > =>
    CreateAction(ScenePresetListActionType.SET_SCENE_PRESET_LIGHT_GROUPS, {
      selectedLightGroups,
    }),
  setScenePresetName: (
    scenePresetName: string
  ): ActionWithPayload<'SET_SCENE_PRESET_NAME', { scenePresetName: string }> =>
    CreateAction(ScenePresetListActionType.SET_SCENE_PRESET_NAME, {
      scenePresetName,
    }),
  setScenePresetOverview: (
    scenePresetOverview: string
  ): ActionWithPayload<
    'SET_SCENE_PRESET_OVERVIEW',
    { scenePresetOverview: string }
  > =>
    CreateAction(ScenePresetListActionType.SET_SCENE_PRESET_OVERVIEW, {
      scenePresetOverview,
    }),
  setCreatedScenePresetId: (
    createdScenePresetId: string
  ): ActionWithPayload<
    'SET_CREATED_SCENE_PRESET_ID',
    { createdScenePresetId: string }
  > =>
    CreateAction(ScenePresetListActionType.SET_CREATED_SCENE_PRESET_ID, {
      createdScenePresetId,
    }),

  clearScenePresetState: (): Action<'CLEAR_SCENE_PRESET_STATE'> =>
    CreateAction(ScenePresetListActionType.CLEAR_SCENE_PRESET_STATE),
  clearSceneSettingSubState: (): Action<'CLEAR_SCENE_SETTING_SUB_STATE'> =>
    CreateAction(ScenePresetListActionType.CLEAR_SCENE_SETTING_SUB_STATE),
  clearMetadataSubState: (): Action<'CLEAR_METADATA_SUB_STATE'> =>
    CreateAction(ScenePresetListActionType.CLEAR_METADATA_SUB_STATE),
  clearExecuteSubState: (): Action<'CLEAR_EXECUTE_SUB_STATE'> =>
    CreateAction(ScenePresetListActionType.CLEAR_EXECUTE_SUB_STATE),

  setScenePresetState: (
    scenePresetState: ScenePresetState
  ): ActionWithPayload<
    'SET_SCENE_PRESET_STATE',
    { scenePresetState: ScenePresetState }
  > =>
    CreateAction(ScenePresetListActionType.SET_SCENE_PRESET_STATE, {
      scenePresetState,
    }),
  setSceneSettingSubState: (
    sceneSettingSubState: InputRequireType
  ): ActionWithPayload<
    'SET_SCENE_SETTING_SUB_STATE_FOR_SCENE_PRESET_LIST',
    { sceneSettingSubState: InputRequireType }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_SCENE_SETTING_SUB_STATE_FOR_SCENE_PRESET_LIST,
      {
        sceneSettingSubState,
      }
    ),
  setMetadataSubState: (
    metadataSubState: InputRequireType
  ): ActionWithPayload<
    'SET_METADATA_SUB_STATE_FOR_SCENE_PRESET_LIST',
    { metadataSubState: InputRequireType }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_METADATA_SUB_STATE_FOR_SCENE_PRESET_LIST,
      {
        metadataSubState,
      }
    ),
  setExecuteSubState: (
    executeSubState: ExecuteSubState
  ): ActionWithPayload<
    'SET_EXECUTE_SUB_STATE_FOR_SCENE_PRESET_LIST',
    { executeSubState: ExecuteSubState }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_EXECUTE_SUB_STATE_FOR_SCENE_PRESET_LIST,
      {
        executeSubState,
      }
    ),

  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_SCENE_PRESET_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      ScenePresetListActionType.SET_IN_PROGRESS_FOR_SCENE_PRESET_LIST,
      {
        inProgress,
      }
    ),
  clearScenePresetListState: (): Action<'CLEAR_SCENE_PRESET_LIST_STATE'> =>
    CreateAction(ScenePresetListActionType.CLEAR_SCENE_PRESET_LIST_STATE),
  clearCreateScenePresetDialogState:
    (): Action<'CLEAR_CREATE_SCENE_PRESET_DIALOG_STATE'> =>
      CreateAction(
        ScenePresetListActionType.CLEAR_CREATE_SCENE_PRESET_DIALOG_STATE
      ),
}
