const HostNameOfEnvs = {
  ['aidp-dev.kyocera-robotics.com']: 'dev',
  ['aidp-stg.kyocera-robotics.com']: 'stg',
  ['aidp-prd.kyocera-robotics.com']: 'prd',
  ['kcr-aidp-dev.web.app']: 'dev',
  ['kcr-aidp-stg.web.app']: 'stg',
  ['kcr-aidp-prd.web.app']: 'prd',
  ['kcr-aidp-dev.firebaseapp.com']: 'dev',
  ['kcr-aidp-stg.firebaseapp.com']: 'stg',
  ['kcr-aidp-prd.firebaseapp.com']: 'prd',
} as const

export const KYOCERA_ROBOTICS_SITE_URL =
  'https://www.kyocera.co.jp/prdct/robotics/' as const

export const decideEnvironment = (fqdnName?: string): string => {
  let fqdn: string
  fqdnName === undefined ? (fqdn = window.location.host) : (fqdn = fqdnName)

  let env = 'local'
  fqdn in HostNameOfEnvs
    ? (env = fqdn
        .replace('.kyocera-robotics.com', '')
        .replace('.web.app', '')
        .replace('.firebaseapp.com', '')
        .split(/[-]/)
        .slice(-1)[0])
    : fqdn === 'aidp.kyocera-robotics.com' ||
      fqdn === 'kcr-aidp.web.app' ||
      fqdn === 'kcr-aidp.firebaseapp.com'
    ? (env = 'prd')
    : fqdn === 'localhost'
    ? (env = 'ci')
    : (env = 'local')
  return env
}

export const AidpApiUrl = (fqdnName?: string): string => {
  const env = decideEnvironment(fqdnName)
  switch (env) {
    // 基本的に firebase sdk を利用するから cloud functions の url を意識することはないかも。
    // Cloud Functions ではない API を利用する際、利用すること。
    case 'dev':
      return 'https://asia-northeast-1-kcr-aidp-dev.cloudfunctions.net'
    case 'stg':
      return 'https://asia-northeast-1-kcr-aidp-stg.cloudfunctions.net'
    case 'prd':
      return 'https://asia-northeast-1-kcr-aidp-prd.cloudfunctions.net'
    default:
      // 'local'
      // 'ci'
      return 'http://localhost:9999'
  }
}

export const AidpCloudFunctionsUrl = (fqdnName?: string): string => {
  const env = decideEnvironment(fqdnName)
  switch (env) {
    // onCall Functionsを呼ぶとき用。関数名をStringで指定するため。
    case 'dev':
      return 'kcr-aidp-dev-func-'
    case 'stg':
      return 'kcr-aidp-stg-func-'
    case 'prd':
      return 'kcr-aidp-prd-func-'
    case 'local':
      return 'kcr-aidp-local-func-'
    default:
      // 'ci'
      return 'kcr-aidp-ci-func-'
  }
}

export const passwordResetRedirectUrl = (fqdnName?: string): string => {
  const env = decideEnvironment(fqdnName)
  switch (env) {
    case 'dev':
    case 'stg':
    case 'prd':
      return `https://${window.location.host}/webapp/login`
    case 'local':
      return 'https://webapp/login'
    default:
      // 'ci'
      return ''
  }
}
