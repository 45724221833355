import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import type { AxiosResponse } from 'axios'
import { getAxiosInstance } from 'state/utils'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'

export const FeatureDataGroupDetailApi = {
  getSignedUrl: (
    featureDataGroupId: string,
    iconFileName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredatagroup-read-signed-url'
    )({
      featureDataGroupId,
      iconFileName,
    }),
  getDeleteSignedUrl: (
    featureDataGroupId: string,
    iconFileName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredatagroup-delete-signed-url'
    )({
      featureDataGroupId,
      iconFileName,
    }),
  getFileUploadSignedUrl: (
    featureDataGroupId: string,
    iconFileName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredatagroup-get-signed-url'
    )({
      featureDataGroupId,
      iconFileName,
    }),
  deleteUploadedIcon: (signedUrl: string): Promise<AxiosResponse<void>> =>
    getAxiosInstance().delete(signedUrl),
  executeFileUpload: (
    signedUrl: string,
    file: File
  ): Promise<AxiosResponse<void>> => getAxiosInstance().upload(signedUrl, file),
}
