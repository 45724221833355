import { FeatureDataGroupListActions } from './actions'
import {
  FeatureDataGroupInfoListState,
  FeatureDataGroupListActionType,
} from './types'

const initialState: FeatureDataGroupInfoListState = {
  domainData: {
    currentFeatureDataGroupList: [],
    featureDataGroupListDisplayCondition: {
      searchValue: '',
      sortKey: 'updated-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'UserGroup',
    },
  },
  appState: {
    inProgress: false,
  },
}

export const FeatureDataGroupListReducer = (
  state: FeatureDataGroupInfoListState = initialState,
  action: FeatureDataGroupListActions
): FeatureDataGroupInfoListState => {
  switch (action.type) {
    case FeatureDataGroupListActionType.SET_FEATURE_DATA_GROUP_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGroupList: action.payload.featureDataGroupList,
        },
      }
    case FeatureDataGroupListActionType.CLEAR_FEATURE_DATA_GROUP_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGroupList: [],
        },
      }
    case FeatureDataGroupListActionType.SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupListDisplayCondition:
            action.payload.featureDataGroupListDisplayCondition,
        },
      }
    case FeatureDataGroupListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataGroupListActionType.CLEAR_FEATURE_DATA_GROUP_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
