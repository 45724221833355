import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'
import { makeStyles } from 'tss-react/mui'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import BusinessIcon from '@mui/icons-material/Business'
import ExitIcon from '@mui/icons-material/ExitToApp'
import MenuIcon from '@mui/icons-material/Menu'

import { HeaderProps } from './types'
import { LogoIcon } from 'views/components'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: theme.custom.header.borderBottom,
  },
  menuItem: {
    display: 'block',
    padding: '6px 16px !important',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  customerName: {
    textTransform: 'none',
  },
}))

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const open = Boolean(anchorEl)
  const history = useHistory()
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(undefined)

  const { handleClickMenu, handleClickCustomer } = props

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar} color='inherit'>
        <Toolbar>
          <IconButton
            aria-label='open drawer'
            onClick={handleClickMenu}
            edge='start'
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Grid
                container
                spacing={4}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item>
                  <Link
                    to='/'
                    style={{ textDecoration: 'none' }}
                    color={'#000000'}
                  >
                    <LogoIcon data-testid='AppLogoIcon' />
                  </Link>
                </Grid>
                <Grid item>
                  <Button onClick={handleClickCustomer} color='inherit'>
                    <BusinessIcon />
                    <Box ml={1} mr={1}>
                      <Typography
                        className={classes.customerName}
                        variant='body1'
                        noWrap
                      >
                        {props.customerName}
                      </Typography>
                    </Box>
                    <ArrowDropDown color={'inherit'} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  handleClick(e)
                }}
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <Box m={1} borderBottom='1px solid black'>
                  <Typography>
                    {props.userName !== '' ? props.userName : props.mailAddress}
                  </Typography>
                </Box>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    history.push('/user-settings/profile')
                    handleClose()
                  }}
                >
                  <Box display='flex'>
                    <Box mr={1}>
                      <AccountCircleIcon />
                    </Box>
                    <Typography>ユーザー設定</Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={async () => {
                    const auth = getAuth()
                    signOut(auth)
                  }}
                >
                  <Box display='flex'>
                    <Box mr={1}>
                      <ExitIcon />
                    </Box>
                    <Typography>ログアウト</Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}
