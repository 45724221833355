import React from 'react'

import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import IconButton from '@mui/material/IconButton'

import { FeatureDataGroupsIconProps } from './types'

export const FeatureDataGroupsIcon: React.FC<FeatureDataGroupsIconProps> = (
  props: FeatureDataGroupsIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <AutoGraphIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
