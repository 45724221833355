import { Dispatch } from 'redux'
import { scenePresetListActions } from './actions'
import {
  CreateAugParamTemplateRequestArgsType,
  ExecuteSubState,
  InputRequireType,
  ScenePresetInfo,
  ScenePresetListDisplayCondition,
  ThreeDimensionalData,
} from './types'
import { State } from 'state/store'
import { getAugParamTemplatesCollection } from 'state/firebase'

import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForAugParamTemplateQueryDocument } from 'utils/fireStore/augParamTemplate'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { isUndefined } from 'util'
import {
  getAug3DSceneBackgroundsCollection,
  getAug3dSceneLightGroupsCollection,
  getAug3DSceneCamerasCollection,
} from 'state/firebase'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneBackgroundDocument } from 'utils/fireStore/aug3dSceneBackground'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraDocument } from 'utils/fireStore/aug3dSceneCamera'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneLightGroupDocument } from 'utils/fireStore/aug3dSceneLightGroup'
import { ScenePresetApi } from './apis'
import { HttpsCallableResult } from 'firebase/functions'
import { domainDataOperations } from 'state/app/domainData/operations'

const createScenePresetListData = async (
  ugid: string,
  snapShot: QuerySnapshot<DocumentData>
): Promise<(ScenePresetInfo | undefined)[]> =>
  // 関連のDocsを取得し表示用のシーンプリセットデータを生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const augParamTemplateDocData = document.data()
      if (
        !fireStoreTypeGuardForAugParamTemplateQueryDocument(
          augParamTemplateDocData
        )
      ) {
        return undefined
      }

      return {
        name: augParamTemplateDocData['metadata']['name'],
        overview: augParamTemplateDocData['metadata']['overview'],
        augParamTemplateId: augParamTemplateDocData['aug-param-template-id'],
        backgroundCount:
          augParamTemplateDocData['3d-scene']['backgrounds'].length,
        cameraCount: augParamTemplateDocData['3d-scene']['cameras'].length,
        lightGroupCount:
          augParamTemplateDocData['3d-scene']['light-groups'].length,
        createdAt: augParamTemplateDocData['created-at'],
      } as ScenePresetInfo
    })
  )

export const onUpdateScenePresetTemplateDocument = async (
  dispatch: Dispatch,
  userGroupId: string,
  snapshot: QuerySnapshot<DocumentData>,
  augParamtenplateIds: string[],
  condition: ScenePresetListDisplayCondition,
  lastScenePresetDoc: DocumentData | undefined
) => {
  const scenePresetListData = await createScenePresetListData(
    userGroupId,
    snapshot
  )
  // Aug Param Template IDを保持
  // すでに保持しているIDが存在する場合は、現状の検索位置以降のIDを一度破棄し
  // 新たに取得したAug Param Template IDを保持する
  if (scenePresetListData.length >= 0) {
    if (lastScenePresetDoc) {
      const index = augParamtenplateIds.findIndex(
        (id) => id === lastScenePresetDoc?.id
      )
      const beforePageIds = augParamtenplateIds.slice(0, index + 1)
      dispatch(
        scenePresetListActions.setAugParamTemplateIdList([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        scenePresetListActions.setAugParamTemplateIdList([
          ...augParamtenplateIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
  }
  // 取得したシーンプリセットの一覧を保持
  dispatch(
    scenePresetListActions.setScenePresetList(
      scenePresetListData.filter(
        (item) => item !== undefined
      ) as ScenePresetInfo[]
    )
  )
  let totalCountQuery =
    condition.selectedUserGroupKind === 'UserGroup'
      ? query(getAugParamTemplatesCollection(userGroupId))
      : query(
          getAugParamTemplatesCollection(userGroupId),
          where('access-control.is-shared', '==', true),
          where('access-control.share-permissions.webapp', '==', 'list')
        )

  // 文字列検索が存在する場合は、aug-param-template-idの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )
    totalCountQuery = query(
      totalCountQuery,
      orderBy('aug-param-template-id', 'asc'),
      where('aug-param-template-id', '>=', startCode),
      where('aug-param-template-id', '<=', endCode)
    )
  }

  const totalCount = await getCountFromServer(totalCountQuery)
  dispatch(
    scenePresetListActions.setListDisplayCondition({
      ...condition,
      totalCount: totalCount.data().count,
    })
  )
}
// シーンプリセットの一覧の配列をセット
const getScenePresetListRelationDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
    dispatch,
    getState
  )

  // 共有データの参照権がない場合は、カスタマーデータに変更する
  if (!hasSharedUserGroup) {
    dispatch(
      scenePresetListActions.setListDisplayCondition({
        ...getState().pages.scenePresetListState.domainData
          .scenePresetListDisplayCondition,
        selectedUserGroupKind: 'UserGroup',
      })
    )
  }

  const userGroupId =
    getState().pages.scenePresetListState.domainData
      .scenePresetListDisplayCondition.selectedUserGroupKind === 'UserGroup'
      ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
      : domainDataOperations.getSharedUserGroupId()(dispatch, getState)

  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.scenePresetListState.domainData
      .currentScenePresetListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      scenePresetListActions.setCurrentScenePresetListSnapshot(undefined)
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.scenePresetListState.domainData
      .scenePresetListDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery =
    getState().pages.scenePresetListState.domainData
      .scenePresetListDisplayCondition.selectedUserGroupKind === 'UserGroup'
      ? query(getAugParamTemplatesCollection(userGroupId))
      : query(
          getAugParamTemplatesCollection(userGroupId),
          where('access-control.is-shared', '==', true),
          where('access-control.share-permissions.webapp', '==', 'list')
        )
  // aug-param-templatesを表示件数分取得
  commonQuery = query(commonQuery, limit(condition.displayNumber))

  // 文字列検索が存在する場合は、AugParamTemplateIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、AugParamTemplateIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('aug-param-template-id', 'asc'),
      where('aug-param-template-id', '>=', startCode),
      where('aug-param-template-id', '<=', endCode)
    )
  } else {
    const sortKey =
      condition.sortKey === 'generated-at' ? 'created-at' : condition.sortKey
    commonQuery = query(commonQuery, orderBy(sortKey, condition.sortOrder))
  }

  const augParamTemplateIds =
    getState().pages.scenePresetListState.domainData.augParamTemplateIdList

  // 既に取得していれば最後の要素から取得
  let lastItem: DocumentData | undefined = undefined
  if (augParamTemplateIds.length > 0) {
    lastItem =
      getState().pages.scenePresetListState.domainData
        .scenePresetListDisplayCondition.selectedUserGroupKind === 'UserGroup'
        ? await getDoc(
            doc(
              getAugParamTemplatesCollection(userGroupId),
              augParamTemplateIds[augParamTemplateIds.length - 1]
            )
          )
        : (
            await getDocs(
              query(
                getAugParamTemplatesCollection(userGroupId),
                where(
                  'aug-param-template-id',
                  '==',
                  augParamTemplateIds[augParamTemplateIds.length - 1]
                ),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            )
          ).docs.map((augParamTemplate) => augParamTemplate.data())[0]
    commonQuery = query(commonQuery, startAfter(lastItem))
  }

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      onUpdateScenePresetTemplateDocument(
        dispatch,
        userGroupId,
        snapshot,
        augParamTemplateIds,
        condition,
        lastItem
      )
    }
  )

  // 現在、表示中のシーンプリセット（Aug Param Template Docsの Snapshot を保持）
  dispatch(scenePresetListActions.setCurrentScenePresetListSnapshot(snapshot))
}

export const scenePresetListOperations = {
  /** リストを取得する */
  getScenePresetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(scenePresetListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentAugParamTemplateIds: string[] =
          getState().pages.scenePresetListState.domainData
            .augParamTemplateIdList
        const condition =
          getState().pages.scenePresetListState.domainData
            .scenePresetListDisplayCondition
        const augParamTemplateIds = currentAugParamTemplateIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(
          scenePresetListActions.setAugParamTemplateIdList(augParamTemplateIds)
        )

        // シーンプリセットの一覧に必要な関連Docsを取得
        await getScenePresetListRelationDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(scenePresetListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.scenePresetListState.domainData
            .currentScenePresetListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
  getScenePresetData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(scenePresetListActions.setInProgress(true))

        const staticUGID =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const background = await getDocs(
          query(getAug3DSceneBackgroundsCollection(staticUGID))
        )

        const sharedBackground = await getDocs(
          query(
            getAug3DSceneBackgroundsCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        const lightGroup = await getDocs(
          query(getAug3dSceneLightGroupsCollection(staticUGID))
        )

        const sharedLightGroup = await getDocs(
          query(
            getAug3dSceneLightGroupsCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        const camera = await getDocs(
          query(getAug3DSceneCamerasCollection(staticUGID))
        )

        const sharedCamera = await getDocs(
          query(
            getAug3DSceneCamerasCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        const backgrounds: (ThreeDimensionalData | undefined)[] =
          sharedBackground.docs.concat(background.docs).map((item) => {
            const backgroundDocData = item.data()
            if (
              !fireStoreTypeGuardForAug3dSceneBackgroundDocument(
                backgroundDocData
              )
            ) {
              return undefined
            }
            return {
              id: item.id,
              name: backgroundDocData['name'],
              userGroupId: backgroundDocData['user-group-id'],
            } as ThreeDimensionalData
          })

        dispatch(
          scenePresetListActions.setBackgrounds(
            backgrounds.filter(
              (background) => background !== undefined
            ) as ThreeDimensionalData[]
          )
        )

        const lightGroups: (ThreeDimensionalData | undefined)[] =
          sharedLightGroup.docs.concat(lightGroup.docs).map((item) => {
            const lightGroupDocData = item.data()

            if (
              !fireStoreTypeGuardForAug3dSceneLightGroupDocument(
                lightGroupDocData
              )
            ) {
              return undefined
            }

            return {
              id: item.id,
              name: lightGroupDocData['metadata']['name'],
              userGroupId: lightGroupDocData['user-group-id'],
            } as ThreeDimensionalData
          })

        dispatch(
          scenePresetListActions.setLightGroups(
            lightGroups.filter(
              (lightGroup) => lightGroup !== undefined
            ) as ThreeDimensionalData[]
          )
        )

        const cameras: (ThreeDimensionalData | undefined)[] = sharedCamera.docs
          .concat(camera.docs)
          .map((item) => {
            const cameraDocData = item.data()

            if (!fireStoreTypeGuardForAug3dSceneCameraDocument(cameraDocData)) {
              return undefined
            }

            return {
              id: item.id,
              name: cameraDocData['name'],
              userGroupId: cameraDocData['user-group-id'],
            } as ThreeDimensionalData
          })

        dispatch(
          scenePresetListActions.setCameras(
            cameras.filter(
              (camera) => camera !== undefined
            ) as ThreeDimensionalData[]
          )
        )
      } catch (error) {
        console.error(error)
        dispatch(scenePresetListActions.clearCreateScenePresetDialogState())
      } finally {
        dispatch(scenePresetListActions.setInProgress(false))
      }
    },

  setNextStep:
    (
      scenePresetState: string,
      scenePresetSubState: {
        sceneSettingSubState: InputRequireType
        metadataSubState: InputRequireType
        executeSubState: ExecuteSubState
      }
    ) =>
    async (dispatch: Dispatch): Promise<void> => {
      switch (scenePresetState) {
        case 'SceneSettingState':
          if (scenePresetSubState.sceneSettingSubState === 'InputRequired') {
            dispatch(
              scenePresetListActions.setScenePresetState('MetadataState')
            )
          }
          break
        case 'MetadataState':
          if (scenePresetSubState.metadataSubState === 'InputRequired') {
            dispatch(scenePresetListActions.setScenePresetState('ExecuteState'))
          }
          break
        default:
          dispatch(scenePresetListActions.clearScenePresetState())
      }
    },

  setPrevStep:
    (scenePresetState: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      switch (scenePresetState) {
        case 'MetadataState':
          dispatch(
            scenePresetListActions.setScenePresetState('SceneSettingState')
          )
          dispatch(scenePresetListActions.setScenePresetName(''))
          dispatch(scenePresetListActions.setScenePresetOverview(''))
          dispatch(scenePresetListActions.clearMetadataSubState())
          break
        case 'ExecuteState':
          dispatch(scenePresetListActions.setScenePresetState('MetadataState'))
          dispatch(scenePresetListActions.clearExecuteSubState())
          break
        default:
          break
      }
    },
  executeScenePreset:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(scenePresetListActions.setInProgress(true))
      const userGroupId =
        getState().app.domainData.authedUser.auth.customClaims.userGroupId
      try {
        const {
          name,
          overview,
          selectedBackgrounds,
          selectedCameras,
          selectedLightGroups,
        } =
          getState().pages.scenePresetListState.domainData
            .scenePresetCreationDialogData

        if (
          !(
            name &&
            selectedBackgrounds.length > 0 &&
            selectedCameras.length > 0 &&
            selectedLightGroups.length > 0
          )
        ) {
          console.error('Error invalid request')
          dispatch(scenePresetListActions.setExecuteSubState('ExecuteError'))
          return
        }

        const generateRequestParams: CreateAugParamTemplateRequestArgsType = {
          '3dScene': {
            backgrounds: selectedBackgrounds.map((backgroundDocList) => ({
              aug3dSceneBackgroundId: backgroundDocList.id,
              userGroupId: backgroundDocList.userGroupId,
            })),
            cameras: selectedCameras.map((camera) => ({
              aug3dSceneCameraId: camera.id,
              userGroupId: camera.userGroupId,
            })),
            lightGroups: selectedLightGroups.map((lightGroup) => ({
              aug3dSceneLightGroupId: lightGroup.id,
              userGroupId: lightGroup.userGroupId,
            })),
          },
          metadata: {
            name: name,
            overview: overview,
          },
          userGroupId: userGroupId,
        }

        const result = (await ScenePresetApi.augParamTemplateFireStore(
          generateRequestParams
        )) as HttpsCallableResult<{ augParamTemplateId: string }>

        dispatch(
          scenePresetListActions.setCreatedScenePresetId(
            result.data.augParamTemplateId
          )
        )

        dispatch(scenePresetListActions.setExecuteSubState('Executed'))
      } catch (error) {
        dispatch(scenePresetListActions.setExecuteSubState('ExecuteError'))
      } finally {
        dispatch(scenePresetListActions.setInProgress(false))
      }
    },
}
