import { ModelGroupDetailAction } from './actions'
import { ModelGroupDetailActionType, ModelGroupDetail } from './types'

const initialState: ModelGroupDetail = {
  domainData: {
    currentTrainedModelGroupDetail: {
      trainedModelGroupId: '',
      trainedModelGroupName: '',
      relatedTrainedModelList: [],
      trainedModelListDisplayCondition: {
        sortKey: 'modelVersion',
        sortOrder: 'desc',
        displayNumber: 10,
        pageNumber: 0,
      },
      remarks: '',
      trainingAlgorithm: {
        algorithmId: '',
        algorithmName: '',
      },
      createdAt: undefined,
      createdBy: {
        firstName: '',
        familyName: '',
      },
      iconName: '',
      iconFileType: '',
      overview: '',
      iconUrl: '',
      constraint: {
        setting: {
          settingGroupId: '',
          userGroupId: '',
        },
      },
    },
    settingList: [],
    settingListDisplayCondition: {
      displayNumber: 10,
      pageNumber: 0,
      sortKey: 'createdAt',
      sortOrder: 'desc',
    },
  },
  appState: {
    trainedModelGroupDataState: 'BeforeLoading',
    inProgress: false,
    isInProgressForGettingSettingList: false,
    tableNextPageSubState: 'Unable',
    toastInfo: undefined,
  },
}

export const ModelGroupDetailReducer = (
  state: ModelGroupDetail = initialState,
  action: ModelGroupDetailAction
): ModelGroupDetail => {
  switch (action.type) {
    case ModelGroupDetailActionType.SET_CURRENT_MODEL_GROUP_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentTrainedModelGroupDetail:
            action.payload.currentTrainedModelGroupDetail,
        },
      }
    case ModelGroupDetailActionType.SET_IN_PROGRESS_FOR_MODEL_GROUP_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ModelGroupDetailActionType.SET_SETTING_LIST_FOR_MODEL_GROUP_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingList: action.payload.settingList,
        },
      }
    case ModelGroupDetailActionType.SET_SETTING_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingListDisplayCondition:
            action.payload.settingListDisplayCondition,
        },
      }
    case ModelGroupDetailActionType.CLEAR_MODEL_GROUP_DETAIL_STATE:
      return {
        ...initialState,
      }
    case ModelGroupDetailActionType.SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_MODEL_GROUP_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentTrainedModelGroupDetail: {
            ...state.domainData.currentTrainedModelGroupDetail,
            trainedModelListDisplayCondition: action.payload.condition,
          },
        },
      }
    case ModelGroupDetailActionType.SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_MODEL_GROUP_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          tableNextPageSubState: action.payload.nextPageSubState,
        },
      }
    case ModelGroupDetailActionType.SET_TOAST_INFO_FOR_MODEL_GROUP_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case ModelGroupDetailActionType.SET_ICON_URL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentTrainedModelGroupDetail: {
            ...state.domainData.currentTrainedModelGroupDetail,
            iconUrl: action.payload.iconUrl,
          },
        },
      }
    case ModelGroupDetailActionType.SET_MODEL_GROUP_DATA_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          trainedModelGroupDataState: action.payload.data,
        },
      }
    case ModelGroupDetailActionType.SET_IN_PROGRESS_FOR_GETTING_SETTING_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingSettingList:
            action.payload.isInProgressForGettingSettingList,
        },
      }
    default:
      return state
  }
}
