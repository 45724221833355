import { sendPasswordResetEmail } from 'firebase/auth'
import { Dispatch } from 'redux'
import { authInstance } from 'state/firebase'
import { passwordResetRedirectUrl } from 'state/utils'

import { passwordResetActions } from './actions'

export const passwordResetOperations = {
  sendPasswordResetEmail:
    (email: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      const actionCodeSettings = {
        // パスワード再設定後のリダイレクト URL
        // TODO: ローカルでは成功するが、メールが来ない
        url: passwordResetRedirectUrl(),
        handleCodeInApp: false,
      }
      await sendPasswordResetEmail(authInstance, email, actionCodeSettings)
        .then(() => {
          dispatch(passwordResetActions.setPasswordResetState('SendingSuccess'))
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-email') {
            dispatch(
              passwordResetActions.setPasswordResetErrorMessage(
                'メールアドレスの形式に誤りがあります。'
              )
            )
          } else {
            dispatch(
              passwordResetActions.setPasswordResetErrorMessage(
                'メールの送信に失敗しました。管理者にお問い合わせください。'
              )
            )
          }
          dispatch(passwordResetActions.setPasswordResetState('SendingError'))
          console.error(error)
        })
    },
}
