import {
  createStore,
  compose,
  applyMiddleware,
  Store,
  AnyAction,
  CombinedState,
  combineReducers,
} from 'redux'
import thunk from 'redux-thunk'
import { getFirebase } from 'react-redux-firebase'
import * as app from 'state/app'
import * as pages from 'state/ducks'

export type State = {
  app: app.State
  pages: pages.State
}

const reducer = (state: State | undefined, action: AnyAction): State =>
  combineReducers<State>({
    app: app.reducers,
    pages: pages.reducers,
  })(state, action)

export const configureStore = (): Store<CombinedState<State>, AnyAction> => {
  const middlewares = []
  middlewares.push(thunk)
  middlewares.push(thunk.withExtraArgument(getFirebase))
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // eslint-disable-line @typescript-eslint/no-explicit-any
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middlewares))
  )
  return store
}
