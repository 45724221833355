import { DomainDataActions } from './actions'
import {
  DomainDataActionType,
  AuthedUser,
  Glossaries,
  Algorithm,
  UserProfile,
  AnnotationFormat,
  DatasetTemplate,
  FeatureDataFormat,
} from './types'

export interface DomainData {
  authedUser: AuthedUser
  glossaries?: Glossaries
  algorithms: Algorithm[]
  // userInfo: UserInfo // TODO: ユーザ情報
  userProfile?: UserProfile
  datasetTemplates: DatasetTemplate[]
  annotationFormats: AnnotationFormat[]
  featureDataFormats: FeatureDataFormat[]
  isLoading: boolean
}

const initialState: DomainData = {
  authedUser: {
    userId: '',
    mailAddress: '',
    locale: 'JP',
    customers: [],
    auth: {
      token: '',
      customClaims: {
        accountId: '',
        accountGroupId: '',
        role: '',
        sharedList: [],
        superUser: false,
        userGroupId: '',
      },
    },
  },
  glossaries: undefined,
  algorithms: [],
  userProfile: undefined,
  datasetTemplates: [],
  annotationFormats: [],
  featureDataFormats: [],
  isLoading: false,
}

export const DomainDataReducer = (
  state: DomainData = initialState,
  action: DomainDataActions
): DomainData => {
  switch (action.type) {
    case DomainDataActionType.SET_AUTHED_USER:
      return {
        ...state,
        authedUser: action.payload.data,
      }
    case DomainDataActionType.SET_GLOSSARIES:
      return {
        ...state,
        glossaries: action.payload.data,
      }
    case DomainDataActionType.SET_ALGORITHMS:
      return {
        ...state,
        algorithms: action.payload.data,
      }
    case DomainDataActionType.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.data,
      }
    case DomainDataActionType.SET_DATASET_TEMPLATES:
      return {
        ...state,
        datasetTemplates: action.payload.data,
      }
    case DomainDataActionType.SET_ANNOTATION_FORMATS:
      return {
        ...state,
        annotationFormats: action.payload.data,
      }
    case DomainDataActionType.SET_FEATURE_DATA_FORMATS:
      return {
        ...state,
        featureDataFormats: action.payload.data,
      }
    case DomainDataActionType.SET_IN_PROGRESS:
      return {
        ...state,
        isLoading: action.payload.data,
      }
    case DomainDataActionType.CLEAR_DOMAIN_DATA:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
