import { Timestamp } from 'state/firebase'
import { ResourceLoadingState, ToastInfo } from 'state/utils'
import { Version } from 'types/StateTypes'

export const FeatureDataGroupDetailActionType = {
  SET_CURRENT_FEATURE_DATA_GROUP_DETAIL:
    'SET_CURRENT_FEATURE_DATA_GROUP_DETAIL',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_DETAIL:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_DETAIL',
  CLEAR_FEATURE_DATA_GROUP_DETAIL_STATE:
    'CLEAR_FEATURE_DATA_GROUP_DETAIL_STATE',
  SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GROUP_DETAIL:
    'SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GROUP_DETAIL',
  SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_GROUP_DETAIL:
    'SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_GROUP_DETAIL',
  SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_DETAIL:
    'SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_DETAIL',
  SET_ICON_URL_FOR_FEATURE_DATA_GROUP_DETAIL:
    'SET_ICON_URL_FOR_FEATURE_DATA_GROUP_DETAIL',
  SET_FEATURE_DATA_GROUP_DATA_STATE: 'SET_FEATURE_DATA_GROUP_DATA_STATE',
} as const

interface Algorithm {
  algorithmId: string
  algorithmName: string
}

export interface CurrentFeatureDataGroupDetail {
  featureDataGroupId: string
  featureDataGroupName: string
  relatedFeatureDataList: RelatedFeatureData[]
  featureDataListDisplayCondition: FeatureDataListDisplayCondition
  remarks: string
  trainingAlgorithm: Algorithm
  createdAt?: Timestamp
  createdBy: AccountName
  overview: string
  iconName: string
  iconUrl: string
  iconFileType: string
}
export interface FeatureDataListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface RelatedFeatureData {
  featureDataId: string
  featureDataVersion: Version
  featureDataName: string
}

export interface FeatureDataGroupDetailDomainData {
  currentFeatureDataGroupDetail: CurrentFeatureDataGroupDetail
}

export interface FeatureDataGroupDetailAppState {
  featureDataGroupDataState: ResourceLoadingState
  inProgress: boolean
  tableNextPageSubState: 'Enable' | 'Unable'
  toastInfo?: ToastInfo // トーストの表示する情報
}

export interface FeatureDataGroupDetail {
  domainData: FeatureDataGroupDetailDomainData
  appState: FeatureDataGroupDetailAppState
}
