import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  FeatureDataGenerating,
  FeatureDataGeneratingListActionType,
  FeatureDataGeneratingDisplayCondition,
} from './types'

export type FeatureDataGeneratingListAction = ActionsUnion<
  typeof featureDataGeneratingListActions
>

export const featureDataGeneratingListActions = {
  setList: (
    currentFeatureDataGeneratingList: FeatureDataGenerating[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GENERATING_LIST',
    { currentFeatureDataGeneratingList: FeatureDataGenerating[] }
  > =>
    CreateAction(
      FeatureDataGeneratingListActionType.SET_FEATURE_DATA_GENERATING_LIST,
      {
        currentFeatureDataGeneratingList,
      }
    ),
  setListDisplayCondition: (
    featureDataGeneratingDisplayCondition?: FeatureDataGeneratingDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GENERATING_DISPLAY_CONDITION',
    {
      featureDataGeneratingDisplayCondition?: FeatureDataGeneratingDisplayCondition
    }
  > =>
    CreateAction(
      FeatureDataGeneratingListActionType.SET_FEATURE_DATA_GENERATING_DISPLAY_CONDITION,
      {
        featureDataGeneratingDisplayCondition,
      }
    ),
  clearDatasetDisplayCondition:
    (): Action<'CLEAR_FEATURE_DATA_GENERATING_DISPLAY_CONDITION'> =>
      CreateAction(
        FeatureDataGeneratingListActionType.CLEAR_FEATURE_DATA_GENERATING_DISPLAY_CONDITION
      ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataGeneratingListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_LIST,
      {
        inProgress,
      }
    ),
  setMLPipeLineIdList: (
    mlPipelineIds: string[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_GENERATING_LIST',
    { mlPipelineIds: string[] }
  > =>
    CreateAction(
      FeatureDataGeneratingListActionType.SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_GENERATING_LIST,
      {
        mlPipelineIds,
      }
    ),
  setCurrentFeatureDataGeneratingListSnapshot: (
    currentFeatureDataGeneratingListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_FEATURE_DATA_GENERATING_LIST_SNAPSHOT',
    { currentFeatureDataGeneratingListSnapshot?: () => void }
  > =>
    CreateAction(
      FeatureDataGeneratingListActionType.SET_CURRENT_FEATURE_DATA_GENERATING_LIST_SNAPSHOT,
      {
        currentFeatureDataGeneratingListSnapshot,
      }
    ),
  clearFeatureDataGeneratingListState:
    (): Action<'CLEAR_FEATURE_DATA_GENERATING_LIST_STATE'> =>
      CreateAction(
        FeatureDataGeneratingListActionType.CLEAR_FEATURE_DATA_GENERATING_LIST_STATE
      ),
}
