import React from 'react'
export const Home: React.FC = () => {
  return (
    // TODO: E2Eテストのためheightの設定とUIに影響しないように透過
    <div
      className='home'
      style={{ height: '1px', opacity: 0 }}
      data-testid='home-title'
    >
      {' '}
    </div>
  )
}
