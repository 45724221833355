import { RobotDataAnalysisExtractedImagesActions } from './actions'
import {
  RobotDataAnalysisExtractedImagesActionType,
  RobotDataAnalysisExtractedImagesState,
} from './types'

const initialState: RobotDataAnalysisExtractedImagesState = {
  domainData: {
    executionDataList: [],
    robotDataAnalysisExtractedImagesDisplayCondition: {
      sortKey: 'executedAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    inProgress: false,
  },
}

export const RobotDataAnalysisExtractedImagesReducer = (
  state: RobotDataAnalysisExtractedImagesState = initialState,
  action: RobotDataAnalysisExtractedImagesActions
): RobotDataAnalysisExtractedImagesState => {
  switch (action.type) {
    case RobotDataAnalysisExtractedImagesActionType.SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executionDataList: action.payload.executionDataList,
        },
      }
    case RobotDataAnalysisExtractedImagesActionType.SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          robotDataAnalysisExtractedImagesDisplayCondition:
            action.payload.robotDataAnalysisExtractedImagesDisplayCondition ??
            state.domainData.robotDataAnalysisExtractedImagesDisplayCondition,
        },
      }
    case RobotDataAnalysisExtractedImagesActionType.CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          robotDataAnalysisExtractedImagesDisplayCondition: {
            ...initialState.domainData
              .robotDataAnalysisExtractedImagesDisplayCondition,
          },
        },
      }
    case RobotDataAnalysisExtractedImagesActionType.SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case RobotDataAnalysisExtractedImagesActionType.CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
