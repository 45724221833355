import { Dispatch } from 'redux'
import {
  getAccountSettingCollection,
  getAug3DSceneCamerasCollection,
  getAug3DSceneCameraRevisionsCollection,
} from 'state/firebase'
import {
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'

import { sceneCameraDetailActions } from './'
import { CurrentSceneCameraDetail } from './types'
import { State } from 'state/store'

import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraDocument } from 'utils/fireStore/aug3dSceneCamera'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraRevisionDocument } from 'utils/fireStore/aug3dSceneCameraRevision'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSceneCameraDocument } from 'utils/fireStore/accountSetting'
import { domainDataOperations } from 'state/app/domainData/operations'

export const SceneCameraDetailOperations = {
  /** カメラ配置詳細で表示パラメータを取得する */
  getSceneCameraDetail:
    (id: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(sceneCameraDetailActions.setInProgress(true))

        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        // aug-3d-scene-camera document を取得
        const sceneCameraDocs = (
          await getDocs(
            isSharedUserGroup
              ? query(
                  getAug3DSceneCamerasCollection(userGroupId),
                  where('aug-3d-scene-camera-id', '==', id),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              : query(
                  getAug3DSceneCamerasCollection(userGroupId),
                  where('aug-3d-scene-camera-id', '==', id)
                )
          )
        ).docs

        if (
          sceneCameraDocs.length === 0 ||
          !fireStoreTypeGuardForAug3dSceneCameraDocument(
            sceneCameraDocs[0].data()
          ) ||
          sceneCameraDocs === undefined
        ) {
          dispatch(
            sceneCameraDetailActions.setSceneCameraDetailState({
              ...getState().pages.sceneCameraDetailState.appState
                .sceneCameraDetailSubState,
              sceneCameraDataSubState: 'Failed',
            })
          )
          return
        }

        const sceneCameraData = sceneCameraDocs[0].data()

        // aug-3d-scene-cameras/aug-3d-scene-revisions document を取得
        const sceneCameraRevisionDocs = (
          await getDocs(
            isSharedUserGroup
              ? query(
                  getAug3DSceneCameraRevisionsCollection(userGroupId, id),
                  where('access-control.is-shared', '==', true),
                  where(
                    'access-control.share-permissions.webapp',
                    '==',
                    'list'
                  ),
                  orderBy('aug-3d-scene-camera-revision', 'desc'),
                  limit(1)
                )
              : query(
                  getAug3DSceneCameraRevisionsCollection(userGroupId, id),
                  orderBy('aug-3d-scene-camera-revision', 'desc'),
                  limit(1)
                )
          )
        ).docs

        if (
          sceneCameraRevisionDocs.length === 0 ||
          !fireStoreTypeGuardForAug3dSceneCameraRevisionDocument(
            sceneCameraRevisionDocs[0].data()
          )
        ) {
          dispatch(
            sceneCameraDetailActions.setSceneCameraDetailState({
              ...getState().pages.sceneCameraDetailState.appState
                .sceneCameraDetailSubState,
              sceneCameraDataSubState: 'Failed',
            })
          )
          return
        }

        const sceneCameraRevisionData = sceneCameraRevisionDocs[0].data()

        // 作成ユーザを取得
        const accountSetting = (
          await getDoc(
            doc(
              getAccountSettingCollection(accountGroupId),
              sceneCameraRevisionData['created-by']
            )
          )
        ).data()

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSceneCameraDocument(accountSetting)
        ) {
          dispatch(
            sceneCameraDetailActions.setSceneCameraDetailState({
              ...getState().pages.sceneCameraDetailState.appState
                .sceneCameraDetailSubState,
              sceneCameraDataSubState: 'Failed',
            })
          )
          return
        }

        const sceneCameraDetail: CurrentSceneCameraDetail = {
          id,
          name: sceneCameraData['name'],
          revision: sceneCameraRevisionData['aug-3d-scene-camera-revision'],
          overview: sceneCameraData['overview'],
          createdAt: sceneCameraRevisionData['created-at'],
          location: sceneCameraRevisionData['config']['location'],
          fov: sceneCameraRevisionData['config']['fov'],
          lookAt: sceneCameraRevisionData['config']['look-at'],
          formatVersion: {
            displayName:
              sceneCameraRevisionData['config']['format-version'][
                'display-name'
              ],
            major: sceneCameraRevisionData['config']['format-version']['major'],
            minor: sceneCameraRevisionData['config']['format-version']['minor'],
            patch: sceneCameraRevisionData['config']['format-version']['patch'],
          },
          createdBy: accountSetting
            ? `${accountSetting['first-name']} ${accountSetting['family-name']}`
            : sceneCameraData['created-by'],
        }

        dispatch(
          sceneCameraDetailActions.setCurrentSceneCameraDetail(
            sceneCameraDetail
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(sceneCameraDetailActions.setInProgress(false))
      }
    },

  /** カメラ配置名を更新 */
  updateSceneCameraName:
    (id: string, name: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(sceneCameraDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        await updateDoc(doc(getAug3DSceneCamerasCollection(userGroupId), id), {
          name,
          ['updated-by']:
            getState().app.domainData.authedUser.auth.customClaims.accountId,
          ['updated-at']: new Date(),
        })

        dispatch(
          sceneCameraDetailActions.setCurrentSceneCameraDetail({
            ...getState().pages.sceneCameraDetailState.domainData
              .currentSceneCameraDetail,
            name,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(sceneCameraDetailActions.setInProgress(false))
      }
    },

  /** カメラ配置の概要を更新 */
  updateOverview:
    (id: string, overview: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(sceneCameraDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        await updateDoc(doc(getAug3DSceneCamerasCollection(userGroupId), id), {
          overview: overview,
          ['updated-by']:
            getState().app.domainData.authedUser.auth.customClaims.accountId,
          ['updated-at']: new Date(),
        })

        dispatch(
          sceneCameraDetailActions.setCurrentSceneCameraDetail({
            ...getState().pages.sceneCameraDetailState.domainData
              .currentSceneCameraDetail,
            overview,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(sceneCameraDetailActions.setInProgress(false))
      }
    },
}
