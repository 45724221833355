export interface Action<Type extends string> {
  type: Type
}

export interface ActionWithPayload<Type extends string, Payload> {
  type: Type
  payload: Payload
}

export function CreateAction<Type extends string>(type: Type): Action<Type>
export function CreateAction<Type extends string, Payload>(
  type: Type,
  payload: Payload
): ActionWithPayload<Type, Payload>
export function CreateAction<Type, Payload>(
  type: Type,
  payload?: Payload
): { type: Type } | { type: Type; payload: Payload } {
  return payload === undefined ? { type } : { type, payload }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ActionsUnion<
  A extends {
    [actionCreator: string]: (...args: any[]) => any
  }
> = Exclude<ReturnType<A[keyof A]>, (...args: any[]) => Promise<void>>
/* eslint-disable @typescript-eslint/no-explicit-any */
