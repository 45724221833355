import React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

import { IconCheckboxProps } from './types'

export const IconCheckbox: React.FC<IconCheckboxProps> = (
  props: IconCheckboxProps
) => {
  return (
    <Box display='flex' alignItems='center' sx={props.sx}>
      <Checkbox
        inputProps={{ 'aria-label': 'controlled' }}
        checkedIcon={props.checkedIcon}
        onChange={props.onChange}
        data-testid={props['data-testid']}
        checked={props.checked}
        disabled={props.disabled}
        icon={props.uncheckedIcon}
        id={props.id}
        name={props.name}
        value={props.value}
        color='secondary'
      />
      {props.icon && (
        <Box mr={0.5} display='flex' alignItems='center'>
          {props.icon}
        </Box>
      )}
      {props.label && (
        <Typography variant={props.variant ?? 'body2'} component='div' sx={{}}>
          {props.label}
        </Typography>
      )}
    </Box>
  )
}
