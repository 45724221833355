import { CustomTrainingMLPipelineQueryDocument } from './types'

import {
  mlPipelineDocumentSchema,
  preReleaseVersionSchema,
  timeStampSchema,
  trainingStepSrcExtendedObjectClassificationSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** ml-pipeline-query (カスタム学習) documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as CustomTrainingMLPipelineQueryDocument
  const schema = {
    ...mlPipelineDocumentSchema,
    required: [...mlPipelineDocumentSchema.required, 'training-step'],
  }

  const trainingStepSchema = {
    required: [
      'ended-at',
      'started-at',
      'step-id',
      'step-kind',
      'step-status',
      'src',
    ],
    type: 'object',
    properties: {
      'ended-at': {
        ...timeStampSchema,
      },
      'started-at': {
        ...timeStampSchema,
      },
      'step-id': {
        type: 'string',
      },
      'step-kind': {
        type: 'string',
      },
      'step-status': {
        type: 'string',
      },
      src: {
        required: [
          'algorithm-id',
          'annotation-set-list',
          'base-model',
          'dataset-id',
          'setting-id',
          'training-algorithm-version',
        ],
        type: 'object',
        properties: {
          'algorithm-id': {
            type: 'string',
          },
          'annotation-set-list': {
            type: 'array',
            items: {
              required: [
                'annotation-id',
                'annotation-set-id',
                'annotation-set-kind',
                'grouped-data-id',
              ],
              type: 'object',
              properties: {
                'annotation-id': {
                  type: 'string',
                },
                'annotation-set-id': {
                  type: 'string',
                },
                'annotation-set-kind': {
                  type: 'string',
                },
                'grouped-data-id': {
                  type: 'string',
                },
              },
            },
          },
          'base-model': {
            type: 'object',
          },
          'dataset-id': {
            type: 'string',
          },
          'setting-id': {
            type: 'string',
          },
          'training-algorithm-version': {
            ...preReleaseVersionSchema,
          },
          extended: {
            type: 'object',
            properties: {
              'object-classification': {
                ...trainingStepSrcExtendedObjectClassificationSchema,
              },
            },
          },
        },
      },
      dest: {
        required: [],
        type: 'object',
        properties: {
          'trained-model': {
            required: [
              'trained-model-group-id',
              'trained-model-group-version',
              'trained-model-id',
            ],
            type: 'object',
            properties: {
              'trained-model-group-id': {
                type: 'string',
              },
              'trained-model-group-version': {
                type: 'object',
              },
              'trained-model-id': {
                type: 'string',
              },
              'user-group-id': {
                type: 'string',
              },
            },
          },
        },
      },
    },
  }
  const validationSequences = [
    { schema: schema },
    {
      schema: trainingStepSchema,
      dataIndex: 'training-step',
    },
  ]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'ml-pipeline-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
