import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'

import { MetadataInputProps } from './types'
import { isUndefined } from 'utils/typeguard'

// style定義
const useStyles = makeStyles()((theme) => ({
  nameDiv: {
    height: '70px',
    marginBottom: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
}))

export const MetadataInput: React.FC<MetadataInputProps> = (
  props: MetadataInputProps
) => {
  const { classes } = useStyles()
  const [canShowNameError, setCanShowNameError] = useState(false)

  const getErrorStatus = (canShowErrorState: boolean, prop?: string) =>
    canShowErrorState && (!prop || prop === '')

  return (
    <div>
      <div
        className={classes.nameDiv}
        style={
          !isUndefined(props.textFieldSpace)
            ? { marginBottom: props.textFieldSpace }
            : {}
        }
      >
        <TextField
          id='name'
          className={classes.textField}
          label={props.nameProps.label}
          value={props.nameProps.value}
          onChange={props.nameProps.onChange}
          onBlur={() => setCanShowNameError(true)}
          variant={props.nameProps.variant}
          InputProps={{
            readOnly: props.nameProps.readOnly,
          }}
          error={getErrorStatus(canShowNameError, props.nameProps.value)}
          helperText={
            getErrorStatus(canShowNameError, props.nameProps.value) &&
            props.helperText
          }
          required
          inputProps={{ 'data-testid': `${props['data-testid']}-name` }}
        />
      </div>
      <div>
        <TextField
          id='remarks'
          className={classes.textField}
          label={props.remarksProps.label}
          value={props.remarksProps.value}
          onChange={props.remarksProps.onChange && props.remarksProps.onChange}
          variant={props.remarksProps.variant}
          InputProps={{
            readOnly: props.nameProps.readOnly,
          }}
          multiline
          minRows={props.remarksProps.rowNum ?? 1}
          inputProps={{ 'data-testid': `${props['data-testid']}-remarks` }}
        />
      </div>
    </div>
  )
}
