import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'

const timeoutLimitSeconds = 15

export const MfaSettingApi = {
  updatePhoneNumber: (phoneNumber: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'update-user-phonenumber',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )(phoneNumber),
  updateIsFirstLoginStatus: (): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'update-user-login-status',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )(),
}
