import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { LoadingButtonProps } from './types'

const useStyles = makeStyles()((theme) => ({
  button: {
    verticalAlign: 'bottom',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  circularProgress: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    left: 'calc(50% - 10px)',
  },
}))

export const LoadingButton: React.FC<LoadingButtonProps> = (
  props: LoadingButtonProps
) => {
  const { classes } = useStyles()

  let loading = null
  if (props.disabled) {
    loading = (
      <CircularProgress
        className={classes.circularProgress}
        size={20}
        color='primary'
      />
    )
  }

  return (
    <Button
      id={props.id}
      disabled={props.disabled}
      type={props.type}
      fullWidth={props.fullWidth}
      variant={props.variant}
      className={classes.button}
      onClick={props.onClick}
      color={
        props.color && props.color === 'blue'
          ? 'primary'
          : props.color && props.color === 'red'
          ? 'secondary'
          : 'inherit'
      }
      data-testid={props['data-testid']}
    >
      {props.text}
      {loading}
    </Button>
  )
}

export default LoadingButton
