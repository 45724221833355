import { FeatureDataGroupDetailAction } from './actions'
import {
  FeatureDataGroupDetailActionType,
  FeatureDataGroupDetail,
} from './types'

const initialState: FeatureDataGroupDetail = {
  domainData: {
    currentFeatureDataGroupDetail: {
      featureDataGroupId: '',
      featureDataGroupName: '',
      relatedFeatureDataList: [],
      featureDataListDisplayCondition: {
        sortKey: 'featureDataVersion',
        sortOrder: 'desc',
        displayNumber: 10,
        pageNumber: 0,
      },
      remarks: '',
      trainingAlgorithm: {
        algorithmId: '',
        algorithmName: '',
      },
      createdAt: undefined,
      createdBy: {
        firstName: '',
        familyName: '',
      },
      overview: '',
      iconName: '',
      iconFileType: '',
      iconUrl: '',
    },
  },
  appState: {
    featureDataGroupDataState: 'BeforeLoading',
    inProgress: false,
    tableNextPageSubState: 'Unable',
    toastInfo: undefined,
  },
}

export const FeatureDataGroupDetailReducer = (
  state: FeatureDataGroupDetail = initialState,
  action: FeatureDataGroupDetailAction
): FeatureDataGroupDetail => {
  switch (action.type) {
    case FeatureDataGroupDetailActionType.SET_CURRENT_FEATURE_DATA_GROUP_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGroupDetail:
            action.payload.currentFeatureDataGroupDetail,
        },
      }
    case FeatureDataGroupDetailActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataGroupDetailActionType.CLEAR_FEATURE_DATA_GROUP_DETAIL_STATE:
      return {
        ...initialState,
      }
    case FeatureDataGroupDetailActionType.SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GROUP_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGroupDetail: {
            ...state.domainData.currentFeatureDataGroupDetail,
            featureDataListDisplayCondition: action.payload.condition,
          },
        },
      }
    case FeatureDataGroupDetailActionType.SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_GROUP_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          tableNextPageSubState: action.payload.nextPageSubState,
        },
      }
    case FeatureDataGroupDetailActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case FeatureDataGroupDetailActionType.SET_ICON_URL_FOR_FEATURE_DATA_GROUP_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGroupDetail: {
            ...state.domainData.currentFeatureDataGroupDetail,
            iconUrl: action.payload.iconUrl,
          },
        },
      }
    case FeatureDataGroupDetailActionType.SET_FEATURE_DATA_GROUP_DATA_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGroupDataState: action.payload.data,
        },
      }
    default:
      return state
  }
}
