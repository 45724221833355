import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import OutlinedInput from '@mui/material/OutlinedInput'
import List from '@mui/material/List'
import Switch from '@mui/material/Switch'

import {
  validateDateString,
  validateTimeString,
  lowerThanDateOnly,
} from 'views/components/utils/date'

import { DateTimeRangeInputProps } from './types'

const useStyles = makeStyles()(() => ({
  cbList: {
    width: '100%',
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const DateTimeRangeInput: React.FC<DateTimeRangeInputProps> = (
  props: DateTimeRangeInputProps
) => {
  const { classes } = useStyles()

  /** 日付のバリデートエラーメッセージ(fromDate) */
  const [
    createdAtFromDateValidateErrorMessage,
    setCreatedAtFromDateValidateErrorMessage,
  ] = useState<string>('')

  /** 時間のバリデートエラーメッセージ(fromTime) */
  const [
    createdAtFromTimeValidateErrorMessage,
    setCreatedAtFromTimeValidateErrorMessage,
  ] = useState<string>('')

  /** 日付のバリデートエラーメッセージ(toDate) */
  const [
    createdAtTDateValidateErrorMessage,
    setCreatedAtToDateValidateErrorMessage,
  ] = useState<string>('')
  /** 時間のバリデートエラーメッセージ(toTime) */
  const [
    createdAtToTimeValidateErrorMessage,
    setCreatedAtToTimeValidateErrorMessage,
  ] = useState<string>('')

  /** 日付入力フォーマットチェック(fromDate) */
  const isValidCreatedAtFromDate = useMemo(() => {
    if (!validateDateString(props.fromDate.date)) {
      setCreatedAtFromDateValidateErrorMessage('yyyy/mm/dd で入力してください')
      return false
    }

    if (
      validateDateString(props.toDate.date) &&
      props.fromDate.enabled &&
      props.toDate.enabled
    ) {
      const fromDate = new Date(props.fromDate.date)
      const toDate = new Date(props.toDate.date)
      if (fromDate > toDate) {
        setCreatedAtFromDateValidateErrorMessage(
          '終了日時より前の日付を入力してください'
        )
        return false
      }
    }

    return true
  }, [
    props.toDate.date,
    props.fromDate.date,
    props.fromDate.enabled,
    props.toDate.enabled,
  ])

  /** 日付入力フォーマットチェック(ToDate) */
  const isValidCreatedAtToDate = useMemo(() => {
    if (!validateDateString(props.toDate.date)) {
      setCreatedAtToDateValidateErrorMessage('yyyy/mm/dd で入力してください')
      return false
    }

    if (
      validateDateString(props.fromDate.date) &&
      props.fromDate.enabled &&
      props.toDate.enabled
    ) {
      const fromDate = new Date(props.fromDate.date)
      const toDate = new Date(props.toDate.date)
      if (fromDate > toDate) {
        setCreatedAtToDateValidateErrorMessage(
          '開始日時より後の日付を入力してください'
        )
        return false
      }
    }

    return true
  }, [
    props.toDate.date,
    props.fromDate.date,
    props.fromDate.enabled,
    props.toDate.enabled,
  ])

  /** 時間入力フォーマットチェック(fromTime) */
  const isValidCreatedAtFromTime = useMemo(() => {
    if (!validateTimeString(props.fromDate.time)) {
      setCreatedAtFromTimeValidateErrorMessage('hh:mm:ss で入力してください')
      return false
    }

    if (
      validateDateString(props.fromDate.date) &&
      validateDateString(props.toDate.date) &&
      validateTimeString(props.toDate.time) &&
      props.fromDate.enabled &&
      props.toDate.enabled
    ) {
      const fromDate = new Date(props.fromDate.date)
      const toDate = new Date(props.toDate.date)
      if (lowerThanDateOnly(fromDate, toDate)) {
        const fromDateTime = new Date(
          `${props.fromDate.date} ${props.fromDate.time}`
        )
        const toDateTime = new Date(`${props.toDate.date} ${props.toDate.time}`)
        if (fromDateTime > toDateTime) {
          setCreatedAtFromTimeValidateErrorMessage(
            '終了日時より前の時間を入力してください'
          )
          return false
        }
      }
    }

    return true
  }, [
    props.fromDate.date,
    props.fromDate.time,
    props.toDate.date,
    props.toDate.time,
    props.fromDate.enabled,
    props.toDate.enabled,
  ])

  /** 時間入力フォーマットチェック(toTime) */
  const isValidCreatedAtToTime = useMemo(() => {
    if (!validateTimeString(props.toDate.time)) {
      setCreatedAtToTimeValidateErrorMessage('hh:mm:ss で入力してください')
      return false
    }

    if (
      validateDateString(props.fromDate.date) &&
      validateDateString(props.toDate.date) &&
      validateTimeString(props.fromDate.time) &&
      props.toDate.enabled &&
      props.fromDate.enabled
    ) {
      const fromDate = new Date(props.fromDate.date)
      const toDate = new Date(props.toDate.date)
      if (lowerThanDateOnly(fromDate, toDate)) {
        const fromDateTime = new Date(
          `${props.fromDate.date} ${props.fromDate.time}`
        )
        const toDateTime = new Date(`${props.toDate.date} ${props.toDate.time}`)
        if (fromDateTime > toDateTime) {
          setCreatedAtToTimeValidateErrorMessage(
            '開始日時より後の時間を入力してください'
          )
          return false
        }
      }
    }

    return true
  }, [
    props.fromDate.date,
    props.fromDate.time,
    props.toDate.date,
    props.toDate.time,
    props.fromDate.enabled,
    props.toDate.enabled,
  ])

  useEffect(() => {
    if (
      !isValidCreatedAtFromDate ||
      !isValidCreatedAtToDate ||
      !isValidCreatedAtFromTime ||
      !isValidCreatedAtToTime
    ) {
      props.setIsValidDateRange(false)
    } else {
      props.setIsValidDateRange(true)
    }
  }, [
    isValidCreatedAtFromDate,
    isValidCreatedAtToDate,
    isValidCreatedAtFromTime,
    isValidCreatedAtToTime,
  ])

  return (
    <>
      <List className={classes.cbList}>
        <Box ml={2} mr={2}>
          <div
            className={classes.flexAndBetween}
            style={{ alignItems: 'center' }}
          >
            <Box display='flex'>開始日時</Box>
            <FormControlLabel
              control={
                <Switch
                  data-testid='from-date'
                  checked={props.fromDate.enabled}
                  onChange={(event) =>
                    props.fromDate.setEnable(event.target.checked)
                  }
                  color='secondary'
                />
              }
              label=''
            />
          </div>
          <FormControl variant='outlined' fullWidth>
            <OutlinedInput
              disabled={!props.fromDate.enabled}
              inputProps={{ 'data-testid': 'input-from-date' }}
              error={!isValidCreatedAtFromDate}
              value={props.fromDate.date}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => props.fromDate.setDate(e.target.value)}
            />
            {!isValidCreatedAtFromDate && (
              <FormHelperText error>
                {createdAtFromDateValidateErrorMessage}
              </FormHelperText>
            )}
          </FormControl>
          <Box mt={2}>
            <FormControl variant='outlined' fullWidth>
              <OutlinedInput
                disabled={!props.fromDate.enabled}
                inputProps={{ 'data-testid': 'input-from-time' }}
                error={!isValidCreatedAtFromTime}
                value={props.fromDate.time}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => props.fromDate.setTime(e.target.value)}
              />
              {!isValidCreatedAtFromTime && (
                <FormHelperText error>
                  {createdAtFromTimeValidateErrorMessage}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <div
            className={classes.flexAndBetween}
            style={{ marginTop: '16px', alignItems: 'center' }}
          >
            <Box display='flex'>終了日時</Box>
            <FormControlLabel
              control={
                <Switch
                  data-testid='to-date'
                  checked={props.toDate.enabled}
                  onChange={(event) =>
                    props.toDate.setEnable(event.target.checked)
                  }
                  color='secondary'
                />
              }
              label=''
            />
          </div>
          <FormControl variant='outlined' fullWidth>
            <OutlinedInput
              inputProps={{ 'data-testid': 'input-to-date' }}
              disabled={!props.toDate.enabled}
              error={!isValidCreatedAtToDate}
              value={props.toDate.date}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => props.toDate.setDate(e.target.value)}
            />
            {!isValidCreatedAtToDate && (
              <FormHelperText error>
                {createdAtTDateValidateErrorMessage}
              </FormHelperText>
            )}
          </FormControl>
          <Box mt={2}>
            <FormControl variant='outlined' fullWidth>
              <OutlinedInput
                inputProps={{ 'data-testid': 'input-to-time' }}
                disabled={!props.toDate.enabled}
                error={!isValidCreatedAtToTime}
                value={props.toDate.time}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => props.toDate.setTime(e.target.value)}
              />
              {!isValidCreatedAtToTime && (
                <FormHelperText error>
                  {createdAtToTimeValidateErrorMessage}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
      </List>
    </>
  )
}
