import { FeatureDataGroupQueryDocument } from './types'

import { timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** feature-data-group-query documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as FeatureDataGroupQueryDocument
  const schema = {
    required: [
      'feature-data-group-id',
      'algorithm-id',
      'feature-data-group-name',
      'overview',
      'remarks',
      'icon-name',
      'icon-filetype',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    type: 'object',
    properties: {
      'feature-data-group-id': {
        type: 'string',
      },
      'algorithm-id': {
        type: 'string',
      },
      'feature-data-group-name': {
        type: 'string',
      },
      overview: {
        type: 'string',
      },
      remarks: {
        type: 'string',
      },
      'icon-filetype': {
        type: 'string',
      },
      'icon-name': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error(errors)
    return false
  }
}
