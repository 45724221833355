import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  CustomTrainingDetailActionType,
  CurrentCustomTrainingDetail,
  CustomTrainingDetailState,
  ToastInfo,
  TrainedModelDlLink,
  MlPipelineFile,
} from './types'

export type CustomTrainingDetailAction = ActionsUnion<
  typeof customTrainingDetailActions
>

export const customTrainingDetailActions = {
  setCurrentCustomTrainingDetail: (
    currentCustomTrainingDetail: CurrentCustomTrainingDetail
  ): ActionWithPayload<
    'SET_CURRENT_CUSTOM_TRAINING_DETAIL',
    { currentCustomTrainingDetail: CurrentCustomTrainingDetail }
  > =>
    CreateAction(
      CustomTrainingDetailActionType.SET_CURRENT_CUSTOM_TRAINING_DETAIL,
      {
        currentCustomTrainingDetail,
      }
    ),
  setCustomTrainingDetailState: (
    customTrainingDetailState: CustomTrainingDetailState
  ): ActionWithPayload<
    'SET_CUSTOM_TRAINING_DETAIL_STATE',
    { customTrainingDetailState: CustomTrainingDetailState }
  > =>
    CreateAction(
      CustomTrainingDetailActionType.SET_CUSTOM_TRAINING_DETAIL_STATE,
      {
        customTrainingDetailState,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      CustomTrainingDetailActionType.SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_DETAIL,
      {
        inProgress,
      }
    ),
  clearCustomTrainingDetailState:
    (): Action<'CLEAR_CUSTOM_TRAINING_DETAIL_STATE'> =>
      CreateAction(
        CustomTrainingDetailActionType.CLEAR_CUSTOM_TRAINING_DETAIL_STATE
      ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(CustomTrainingDetailActionType.SET_TOAST_INFO, {
      toastInfo,
    }),
  setTrainedModelDlLinks: (
    trainedModelDlLinks: TrainedModelDlLink[]
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_DL_LINKS',
    { trainedModelDlLinks: TrainedModelDlLink[] }
  > =>
    CreateAction(CustomTrainingDetailActionType.SET_TRAINED_MODEL_DL_LINKS, {
      trainedModelDlLinks,
    }),
  setMetricsFiles: (
    metricsFiles: MlPipelineFile[]
  ): ActionWithPayload<
    'SET_METRICS_FILES',
    { metricsFiles: MlPipelineFile[] }
  > =>
    CreateAction(CustomTrainingDetailActionType.SET_METRICS_FILES, {
      metricsFiles,
    }),
  setMlPipelineLogFiles: (
    mlPipelineLogFiles: MlPipelineFile[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_LOG_FILES_FOR_CUSTOM_TRAINING',
    { mlPipelineLogFiles: MlPipelineFile[] }
  > =>
    CreateAction(
      CustomTrainingDetailActionType.SET_ML_PIPELINE_LOG_FILES_FOR_CUSTOM_TRAINING,
      {
        mlPipelineLogFiles,
      }
    ),
}
