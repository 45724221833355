import { Dispatch } from 'redux'
import { featureDataListActions } from './'
import { FeatureDataInfo } from './types'
import { State } from 'state/store'
import {
  getFeatureDataQueriesCollection,
  getFeatureDataGroupQueriesCollection,
} from 'state/firebase'

import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataQueryDocument } from 'utils/fireStore/featureDataQuery'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { domainDataOperations } from 'state/app/domainData/operations'

//  特徴量データ一覧を生成
const createFeatureDataList = async (
  featureDataQuery: Query<DocumentData>
): Promise<(FeatureDataInfo | undefined)[]> =>
  await Promise.all(
    //  特徴量データリストの生成
    await (
      await getDocs(featureDataQuery)
    ).docs.map(async (document: DocumentData) => {
      const featureDataQueryDocData = document.data()
      if (
        !fireStoreTypeGuardForFeatureDataQueryDocument(featureDataQueryDocData)
      ) {
        console.error(`FeatureDataQuery document ${document.id} is invalid`)
        return undefined
      }

      //  特徴量データ一覧を返す
      return {
        featureDataId: featureDataQueryDocData['feature-data-id'],
        featureDataName: featureDataQueryDocData
          ? featureDataQueryDocData['feature-data-name']
          : '',
        featureDataGroupVersion: {
          displayName:
            featureDataQueryDocData['feature-data-group-version'][
              'display-name'
            ],
          major: featureDataQueryDocData['feature-data-group-version']['major'],
          minor: featureDataQueryDocData['feature-data-group-version']['minor'],
          patch: featureDataQueryDocData['feature-data-group-version']['patch'],
        },
        generatedAt: featureDataQueryDocData['generated-at'] ?? undefined,
        uid: featureDataQueryDocData['created-by'],
      } as FeatureDataInfo
    })
  )

export const featureDataListOperations = {
  /** リストを取得する */
  getFeatureDataList:
    (featureDataGroupId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataListActions.setInProgress(true))
        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // 表示条件取得
        const condition =
          getState().pages.featureDataListState.domainData
            .featureDataListDisplayCondition
        const featureDataGroup = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where('feature-data-group-id', '==', featureDataGroupId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((featureDataGroup) => featureDataGroup.data())[0]
          : (
              await getDoc(
                doc(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  featureDataGroupId
                )
              )
            ).data()
        if (!featureDataGroup) {
          dispatch(
            featureDataListActions.setFeatureDataGroupSubState(
              'NotFoundProcessed'
            )
          )
        }
        const featureDataGroupName = featureDataGroup
          ? featureDataGroup['feature-data-group-name']
          : ''
        if (
          getState().pages.featureDataListState.domainData
            .featureDataGroupId === ''
        ) {
          dispatch(
            featureDataListActions.setFeatureDataGroupId(featureDataGroupId)
          )
        }
        if (
          getState().pages.featureDataListState.domainData
            .featureDataGroupName === ''
        ) {
          dispatch(
            featureDataListActions.setFeatureDataGroupName(featureDataGroupName)
          )
        }
        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentFeatureDataList =
          getState().pages.featureDataListState.domainData
            .currentFeatureDataList

        const qLimit =
          condition.displayNumber * (condition.pageNumber + 1) -
          currentFeatureDataList.length
        let totalCountQuery = isSharedUserGroup
          ? query(
              getFeatureDataQueriesCollection(userGroupId),
              where('feature-data-group-id', '==', featureDataGroupId),
              where('access-control.is-shared', '==', true),
              where('access-control.share-permissions.webapp', '==', 'list')
            )
          : query(
              getFeatureDataQueriesCollection(userGroupId),
              where('feature-data-group-id', '==', featureDataGroupId)
            )
        // feature-dataを表示件数分取得
        let featureDataQuery = query(totalCountQuery, limit(qLimit))

        // 文字列検索が存在する場合は、featureDataIdの前方一致条件をQueryに設定
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )

          featureDataQuery = query(
            featureDataQuery,
            orderBy('feature-data-id', 'asc'),
            where('feature-data-id', '>=', startCode),
            where('feature-data-id', '<=', endCode)
          )
        } else {
          if (condition.sortKey === 'feature-data-group-version') {
            featureDataQuery = query(
              featureDataQuery,
              orderBy('feature-data-group-version.major', condition.sortOrder),
              orderBy('feature-data-group-version.minor', condition.sortOrder),
              orderBy('feature-data-group-version.patch', condition.sortOrder)
            )
          } else {
            featureDataQuery = query(
              featureDataQuery,
              orderBy(condition.sortKey, condition.sortOrder)
            )
          }
        }

        // 既に取得していれば最後の要素から取得
        let lastItem: DocumentData | undefined = undefined
        if (currentFeatureDataList.length) {
          lastItem = isSharedUserGroup
            ? (
                await getDocs(
                  query(
                    getFeatureDataQueriesCollection(userGroupId),
                    where(
                      'feature-data-id',
                      '==',
                      currentFeatureDataList[currentFeatureDataList.length - 1]
                        .featureDataId
                    ),
                    where('access-control.is-shared', '==', true),
                    where(
                      'access-control.share-permissions.webapp',
                      '==',
                      'list'
                    )
                  )
                )
              ).docs[0]
            : await getDoc(
                doc(
                  getFeatureDataQueriesCollection(userGroupId),
                  currentFeatureDataList[currentFeatureDataList.length - 1]
                    .featureDataId
                )
              )
          featureDataQuery = query(featureDataQuery, startAfter(lastItem))
        }

        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          totalCountQuery = query(
            totalCountQuery,
            where('feature-data-id', '>=', startCode),
            where('feature-data-id', '<=', endCode)
          )
        }
        const totalCount = await getCountFromServer(totalCountQuery)

        dispatch(
          featureDataListActions.setListDisplayCondition({
            ...condition,
            totalCount: totalCount.data().count,
          })
        )

        //  特徴量データ一覧を取得
        const newFeatureDataList: (FeatureDataInfo | undefined)[] =
          await createFeatureDataList(featureDataQuery)

        dispatch(
          featureDataListActions.setFeatureDataList([
            ...currentFeatureDataList,
            ...(newFeatureDataList.filter(
              (item) => item !== undefined
            ) as FeatureDataInfo[]),
          ])
        )
        dispatch(featureDataListActions.setFeatureDataGroupSubState('Loaded'))
      } catch (error) {
        console.error(error)
        dispatch(featureDataListActions.setFeatureDataGroupSubState('Failed'))
      } finally {
        dispatch(featureDataListActions.setInProgress(false))
      }
    },
}
