import { ClassSetDocument } from './types'

import { timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** class-set documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as ClassSetDocument
  const schema = {
    required: [
      'algorithm-id',
      'class-set-id',
      'class-list',
      'user-group-id',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'class-set-id': {
        type: 'string',
      },
      'class-list': {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      'user-group-id': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'classSet',
      value: data as unknown as DataObject,
    })
    return false
  }
}
