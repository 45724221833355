import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  Link,
  useRouteMatch,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'

import { State } from 'state/store'
import {
  BuildListAction,
  buildListActions,
  buildListOperations,
  BuildDisplayCondition,
  Build,
} from 'state/ducks/buildList'

import { isUndefined } from 'utils/typeguard'
import {
  BuildIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  StatusProgressBar,
  RunningTimeLabel,
  GlobalLoading,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
  BreadcrumbsComponent,
  AlgorithmSelect,
} from 'views/components'
import {
  convertProgressWord,
  convertProgressColor,
  convertTransferStatusWord,
} from 'views/containers/utils'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
} from 'views/components/utils/date'
import { useTheme } from '@mui/material/styles'

const mapStateToProps = (state: State) => ({
  ...state.pages.buildListState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, BuildListAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カスタム学習一覧取得 */
  getBuildList: () => dispatch(buildListOperations.getBuildList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: BuildDisplayCondition) =>
    dispatch(buildListActions.setListDisplayCondition(listCondition)),
  /** Stateのクリア */
  clearBuildListState: () => dispatch(buildListActions.clearBuildListState()),
  /** snapshotの購読解除 */
  unsubscribe: () => dispatch(buildListOperations.unsubscribe()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'ml-pipeline-id',
    title: 'ML Pipeline ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: 'ビルド名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'type',
    title: '種別',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'progress',
    title: '進捗',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'transfer-status',
    title: '転送ステータス',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'time',
    title: '実行時間',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ml-pipeline.started-at',
    title: '開始日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'ended-at',
    title: '終了日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ai-library-id',
    title: 'AIライブラリID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ai-library-version',
    title: 'AIライブラリバージョン',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'execution-user',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const BuildList: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const { url } = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    return () => {
      props.unsubscribe()
    }
  }, [])

  useEffect(() => {
    props.getBuildList()
    return () => {
      props.clearBuildListState()
    }
  }, [props.authedUser.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
  } = tableActions(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.buildDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.buildDisplayCondition.searchValue])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: Build[] = props.domainData.currentBuildList.map(
      (build: Build) => {
        return {
          mlPipelineId: build.mlPipelineId,
          mlPipelineName: build.mlPipelineName,
          algorithmName: build.algorithmName,
          progress: build.progress,
          transferStatus: build.transferStatus,
          time: '',
          startedAt: build.startedAt,
          endedAt: build.endedAt,
          aiLibraryId: build.aiLibraryId,
          aiLibraryVersion: build.aiLibraryVersion,
          accountId: build.accountId,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'progress':
            return (
              <StatusProgressBar
                status={convertProgressWord(data.progress.transactionStatus)}
                progressRate={data.progress.progressRate}
                progressColor={convertProgressColor(
                  data.progress.transactionStatus
                )}
              />
            )
          case 'mlPipelineId':
            return (
              <TooltipLink
                data-testid={`build-${value}`}
                title={value}
                placement='right-start'
                onClick={() => {
                  history.push(`builds/${value}`)
                }}
              />
            )
          case 'algorithmName':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography align='center'>
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              </Box>
            ) : (
              <Tooltip title={value} placement='bottom'>
                <Typography align='center'>{value}</Typography>
              </Tooltip>
            )
          case 'transferStatus':
            if (value) {
              return (
                <Typography key={key}>
                  {convertTransferStatusWord(value)}
                </Typography>
              )
            }
            return (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'aiLibraryVersion':
            return !value.displayName ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography align='center'>
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              </Box>
            ) : (
              <Typography align='center'>v{value.displayName}</Typography>
            )
          case 'startedAt':
          case 'endedAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            } else if (key === 'endedAt' && value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'time':
            // 成功、失敗かつ終了時刻が存在しないまたは、UnixTime0の場合は、N/Aとする
            if (
              (['Completed', 'Failed'].includes(
                data.progress.transactionStatus
              ) &&
                !data.endedAt) ||
              data.endedAt?.seconds === 0
            ) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return !isUndefined(data.startedAt) &&
              !isUndefined(data.endedAt) ? (
              <Typography key={key}>
                {formatTimeSecByMillSecond(
                  data.endedAt.toDate().getTime() -
                    data.startedAt.toDate().getTime()
                )}
              </Typography>
            ) : (
              <RunningTimeLabel startedAt={data.startedAt.toDate()} />
            )
          case 'mlPipelineName':
            if (value) {
              return (
                <Tooltip title={value} placement='bottom'>
                  <Typography>{value}</Typography>
                </Tooltip>
              )
            } else {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          case 'aiLibraryId':
          case 'accountId':
            if (value) {
              return (
                <Tooltip title={value} placement='bottom'>
                  <Typography>{value.substring(0, 8)}</Typography>
                </Tooltip>
              )
            } else {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          default:
            return (
              <Typography key={key}>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            )
        }
      })
    )
  }, [props.domainData.currentBuildList])

  /** テーブル */
  const buildTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={props.domainData.buildDisplayCondition.displayNumber}
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.buildDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.buildDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.buildDisplayCondition.sortKey,
          order: props.domainData.buildDisplayCondition.sortOrder,
        }}
        includesStatusProgressBar={true}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.buildDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'ビルド一覧',
              path: 'builds',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex'>
            <BuildIcon
              className={classes.pageIcon}
              data-testid='buildListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='build-list-title'>ビルド</h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link data-testid='build-entry' to={`${url}/entry`}>
              <Typography>新規ビルド開始</Typography>
            </Link>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <AlgorithmSelect
            data-testid='model-group-list-algorithm'
            type='TRAINED_MODEL'
            algorithms={props.algorithms}
            value={props.domainData.buildDisplayCondition.algorithmId}
            onChange={(value?: string) => selectedAlgorithm(value)}
            hasAll={true}
          />
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (ML Pipeline ID)'
                value={props.domainData.buildDisplayCondition.searchValue}
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {buildTable}
        </CustomTrainingPageParagraph>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const tableActions = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    /** 検索ワードを変更 */
    props.setListDisplayCondition({
      ...props.domainData.buildDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.buildDisplayCondition,
      pageNumber: 0,
      displayNumber: displayNumber,
    })

    props.getBuildList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.buildDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.buildDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getBuildList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.buildDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getBuildList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.setListDisplayCondition({
      ...props.domainData.buildDisplayCondition,
      pageNumber: 0,
    })

    props.getBuildList()
  }

  /** アルゴリズムの選択 */
  const selectedAlgorithm = (algorithm?: string) => {
    props.setListDisplayCondition({
      ...props.domainData.buildDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      algorithmId: algorithm,
    })
    props.getBuildList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
  }
}

export const BuildListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuildList))
