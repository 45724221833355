import { Dispatch } from 'redux'
import { State } from 'state/store'
import { datasetAugmentationListActions } from './actions'
import {
  getMlPipelineQueriesCollection,
  getDatasetQueryCollection,
} from 'state/firebase'
import {
  DatasetAugmentation,
  DatasetAugmentationListDisplayCondition,
} from './types'
import {
  convertProgressRateByTransactionStatusForDatasetAugmentationList,
  convertQueryStartEndCodeBySearchValue,
} from 'state/utils'
import { isUndefined } from 'utils/typeguard'
import { Algorithm, DatasetTemplate } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetAugmentationMLPipelineQueryDocument } from 'utils/fireStore/datasetAugmentationMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'

const createDatasetAugmentationListData = async (
  ugid: string,
  snapShot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[],
  datasetTemplates: DatasetTemplate[]
): Promise<(DatasetAugmentation | undefined)[]> =>
  // 関連のDocsを取得し表示用のオーグメンテーションデータを生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const mlPipelineQueryDocData = document.data()
      if (
        !fireStoreTypeGuardForDatasetAugmentationMLPipelineQueryDocument(
          mlPipelineQueryDocData
        )
      ) {
        return undefined
      }

      let datasetData = undefined
      let datasetTemplateId = ''

      // オーグメンテーション名取得
      const algorithm = algorithms.find((algorithm) => {
        return (
          algorithm.algorithmId ===
          mlPipelineQueryDocData['augmentation-step']?.['src']?.['algorithm-id']
        )
      })

      if (mlPipelineQueryDocData['augmentation-step']?.['dest']?.['results']) {
        datasetData = (
          await getDoc(
            doc(
              getDatasetQueryCollection(ugid),
              mlPipelineQueryDocData['augmentation-step']['dest']['results'][0][
                'dataset-id'
              ]
            )
          )
        ).data()

        if (!fireStoreTypeGuardForDatasetQueryDocument(datasetData)) {
          return undefined
        } else {
          datasetTemplateId = datasetData
            ? datasetData['dataset-template-id']
            : ''
        }
      }

      return {
        mlPipelineId: mlPipelineQueryDocData['ml-pipeline']['ml-pipeline-id'],
        augmentationName:
          mlPipelineQueryDocData['ml-pipeline-metadata']['name'],
        algorithmName: algorithm ? algorithm.metadata.name.ja : '',
        progress: {
          transactionStatus:
            mlPipelineQueryDocData['ml-pipeline']['transaction-status'],
          progressRate:
            convertProgressRateByTransactionStatusForDatasetAugmentationList(
              mlPipelineQueryDocData['ml-pipeline']['transaction-status']
            ),
        },
        startedAt: mlPipelineQueryDocData['ml-pipeline']['started-at'],
        endedAt:
          mlPipelineQueryDocData['ml-pipeline']['ended-at'] &&
          mlPipelineQueryDocData['ml-pipeline']['ended-at'].seconds === 0
            ? undefined
            : mlPipelineQueryDocData['ml-pipeline']['ended-at'],
        generatedDataset: {
          datasetId: datasetData
            ? mlPipelineQueryDocData['augmentation-step']['dest']['results'][0][
                'dataset-id'
              ]
            : '',
          datasetName: datasetData ? datasetData['dataset-name'] : '',
        },
        annotationFormatKind: datasetData
          ? datasetTemplates.find(
              (template) => template.datasetTemplateId === datasetTemplateId
            )?.metadata.name.ja
          : '',
        accountId: mlPipelineQueryDocData['created-by'],
      } as DatasetAugmentation
    })
  )

export const onUpdateMlPipelineQueryDocument = async (
  dispatch: Dispatch,
  ugid: string,
  snapshot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[],
  mlPipelineIds: string[],
  condition: DatasetAugmentationListDisplayCondition,
  mlPipeLineDoc: DocumentData | undefined,
  datasetTemplates: DatasetTemplate[]
) => {
  const datasetAugmentationListData = await createDatasetAugmentationListData(
    ugid,
    snapshot,
    algorithms,
    datasetTemplates
  )
  // ML Pipeline IDを保持
  // すでに保持しているIDが存在する場合は、現状の検索位置以降のIDを一度破棄し
  // 新たに取得したML Pipeline IDを保持する
  if (datasetAugmentationListData.length >= 0) {
    if (mlPipeLineDoc) {
      const index = mlPipelineIds.findIndex((id) => id === mlPipeLineDoc?.id)
      const beforePageIds = mlPipelineIds.slice(0, index + 1)
      dispatch(
        datasetAugmentationListActions.setMLPipeLineIdList([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        datasetAugmentationListActions.setMLPipeLineIdList([
          ...mlPipelineIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
  }
  // 取得したオーグメンテーションの一覧を保持
  dispatch(
    datasetAugmentationListActions.setList(
      datasetAugmentationListData.filter(
        (item) => item !== undefined
      ) as DatasetAugmentation[]
    )
  )
  let totalCountQuery = query(
    getMlPipelineQueriesCollection(ugid),
    where('ml-pipeline.ml-pipeline-kind', '==', 'Augmentation')
  )
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )
    totalCountQuery = query(
      totalCountQuery,
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  }
  const totalCount = await getCountFromServer(totalCountQuery)
  dispatch(
    datasetAugmentationListActions.setListDisplayCondition({
      ...condition,
      totalCount: totalCount.data().count,
    })
  )
}

// データセットオーグメンテーションの一覧の配列をセット
const getDatasetAugmentationListRelationDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const ugid =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId

  const algorithms = getState().app.domainData.algorithms
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.datasetAugmentationListState.domainData
      .currentDatasetAugmentationListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      datasetAugmentationListActions.setCurrentDatasetAugmentationListSnapshot(
        undefined
      )
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.datasetAugmentationListState.domainData
      .datasetAugmentationListDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery = query(
    getMlPipelineQueriesCollection(ugid),
    limit(condition.displayNumber),
    where('ml-pipeline.ml-pipeline-kind', '==', 'Augmentation')
  )

  // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、MLPipelineIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('ml-pipeline.ml-pipeline-id', 'asc'),
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  } else {
    commonQuery = query(
      commonQuery,
      orderBy(condition.sortKey, condition.sortOrder)
    )
  }

  const mlPipelineIds =
    getState().pages.datasetAugmentationListState.domainData.mlPipelineIds
  // 取得済みのオーグメンテーションの最後尾
  let mlPipeLineDoc: DocumentData | undefined = undefined
  if (mlPipelineIds.length > 0) {
    mlPipeLineDoc = await getDoc(
      doc(
        getMlPipelineQueriesCollection(ugid),
        mlPipelineIds[mlPipelineIds.length - 1]
      )
    )
  }

  // 取得済みのオーグメンテーションが存在する場合は、取得済みの最後尾以降のデータから取得する
  if (mlPipeLineDoc) {
    commonQuery = query(commonQuery, startAfter(mlPipeLineDoc))
  }

  const datasetTemplates = getState().app.domainData.datasetTemplates

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      onUpdateMlPipelineQueryDocument(
        dispatch,
        ugid,
        snapshot,
        algorithms,
        mlPipelineIds,
        condition,
        mlPipeLineDoc,
        datasetTemplates
      )
    }
  )

  // 現在、表示中のオーグメンテーション（ML Pipeline Docsの Snapshot を保持）
  dispatch(
    datasetAugmentationListActions.setCurrentDatasetAugmentationListSnapshot(
      snapshot
    )
  )
}

export const datasetAugmentationListOperations = {
  /** リストを取得する */
  getDatasetAugmentationList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetAugmentationListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentMlPipelineIds: string[] =
          getState().pages.datasetAugmentationListState.domainData.mlPipelineIds
        const condition =
          getState().pages.datasetAugmentationListState.domainData
            .datasetAugmentationListDisplayCondition
        const mlPipelineIds = currentMlPipelineIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(
          datasetAugmentationListActions.setMLPipeLineIdList(mlPipelineIds)
        )

        // データセットオーグメンテーションの一覧に必要な関連Docsを取得
        await getDatasetAugmentationListRelationDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetAugmentationListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.datasetAugmentationListState.domainData
            .currentDatasetAugmentationListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
