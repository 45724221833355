import { Dispatch } from 'redux'
import { State } from 'state/store'
import { modelUploadActions } from './'
import {
  getTrainedModelGroupQueriesCollection,
  getTrainedModelQueriesCollection,
  getDatasetQueryCollection,
  getClassSetCollection,
  getClassSetMetaDataCollection,
} from 'state/firebase'

import {
  ModelUploadStateKindArray,
  MetaData,
  ModelUploadParamsType,
  FileAndProgress,
  TrainingAlgorithmVersion,
  TrainedModelGroup,
  TrainedModelDataItem,
  Dataset,
  TrainedModel,
  ClassSet,
} from './types'
import {
  AlgorithmStructureVersion,
  TrainingAlgorithmVersion as TrainingAlgorithmVersionForDomain,
} from 'state/app/domainData'
import { ModelUploadApi } from './apis'

import { formatDateTimeSec } from 'views/components/utils/date'
import { isUndefined } from 'utils/typeguard'
import { convertByteToMatchUnit } from 'views/containers/utils'
import {
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import {
  ClassSetDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetDocument,
} from 'utils/fireStore/classSet'
import {
  ClassSetMetaDataDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaDataDocument,
} from 'utils/fireStore/classSetMetaData'
import { AxiosProgressEvent } from 'axios'
import { domainDataOperations } from 'state/app/domainData/operations'

const createDatasetData = async (
  snapShot: QuerySnapshot<DocumentData>
): Promise<(Dataset | undefined)[]> =>
  // 関連のDocsを取得し表示用のカスタム学習データを生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const datasetQueryDocData = document.data()
      if (!fireStoreTypeGuardForDatasetQueryDocument(datasetQueryDocData)) {
        return undefined
      }
      return {
        datasetId: datasetQueryDocData['dataset-id'],
        name: datasetQueryDocData
          ? datasetQueryDocData['dataset-name']
            ? datasetQueryDocData['dataset-name']
            : ''
          : '',
        createdAt: datasetQueryDocData['created-at'] ?? undefined,
        remarks: datasetQueryDocData
          ? datasetQueryDocData['dataset-remarks']
            ? datasetQueryDocData['dataset-remarks']
            : ''
          : '',
      } as Dataset
    })
  )

// データセット一覧の配列をセット
const getDatasetDocs = async (dispatch: Dispatch, getState: () => State) => {
  // データセット一覧取得
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId

  const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
    dispatch,
    getState
  )

  const userGroupIdOfBaseModelGroup =
    getState().pages.modelUploadState.domainData.selectedBaseModelGroup
      ?.userGroupId

  const baseModelId =
    getState().pages.modelUploadState.domainData.selectedBaseModel
      ?.trainedModelId

  const trainedModelDoc = (
    await getDocs(
      userGroupIdOfBaseModelGroup === sharedUserGroupId
        ? query(
            getTrainedModelQueriesCollection(sharedUserGroupId),
            where('trained-model-id', '==', baseModelId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        : query(
            getTrainedModelQueriesCollection(userGroupId),
            where('trained-model-id', '==', baseModelId)
          )
    )
  ).docs[0]?.data()

  if (!trainedModelDoc) {
    console.error('Failed to find the trained-model document')
    return
  }

  if (!fireStoreTypeGuardForModelQueryDocument(trainedModelDoc)) {
    return
  }

  const algorithmId = trainedModelDoc['algorithm-id']
  const algorithmStructureId = trainedModelDoc['algorithm-structure-id']
  const algorithmStructureVersion =
    trainedModelDoc['algorithm-structure-version']['display-name']
  const trainingAlgorithmVersion =
    trainedModelDoc['training-algorithm-version']['display-name']

  const algorithm = getState().app.domainData.algorithms.find(
    (algorithm) => algorithm.algorithmId === algorithmId
  )
  const algorithmVersion =
    algorithm?.trainingAlgorithm.trainingAlgorithmVersions.find(
      (version) => version.trainingAlgorithmVersion === trainingAlgorithmVersion
    )
  const structure = algorithmVersion?.algorithmStructures.find(
    (structure) => structure.algorithmStructureId === algorithmStructureId
  )
  const structureVersion = structure?.algorithmStructureVersions.find(
    (structureVersion) =>
      structureVersion.algorithmStructureVersion.displayName ===
      algorithmStructureVersion
  )
  const datasetTemplateId = structureVersion?.datasetTemplateId

  if (!datasetTemplateId) {
    console.error('Failed to find the dataset template id')
  } // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.modelUploadState.domainData.currentDatasetListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(modelUploadActions.setCurrentDatasetListSnapshot(undefined))
  }

  // ベースのQuery（表示件数分指定）
  const commonQuery = query(
    getDatasetQueryCollection(userGroupId),
    where(
      'algorithm-id',
      '==',
      getState().pages.modelUploadState.domainData.selectedTrainingAlgorithm
        ?.algorithmId
    ),
    where('generated-for', '==', 'Training'),
    where('dataset-template-id', '==', datasetTemplateId ?? '')
  )

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      const datasetData = await createDatasetData(snapshot)

      dispatch(
        modelUploadActions.setDatasetList(
          datasetData.filter((item) => item !== undefined) as Dataset[]
        )
      )
    }
  )

  // 現在、表示中のデータセット（Dataset Queries Collectionの Snapshot を保持）
  dispatch(modelUploadActions.setCurrentDatasetListSnapshot(snapshot))
}

const getRelatedLatestTrainedModelByTrainedModelGroup = async (
  trainedModelGroupData: DocumentData
): Promise<TrainedModelGroup | undefined> => {
  const latestTrainedModel = trainedModelGroupData['trained-model-list'].find(
    (data: TrainedModelDataItem) =>
      data['trained-model-group-version']['display-name'] ===
      `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
  )
  const trainedModelCount = trainedModelGroupData['trained-model-count']
  return {
    trainedModelGroupId: trainedModelGroupData['trained-model-group-id'],
    trainedModelGroupName: trainedModelGroupData['trained-model-group-name'],
    trainedModelCount: trainedModelCount,
    latestTrainedModelVersion:
      trainedModelCount > 0
        ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
        : undefined,
    latestTrainedModelName: latestTrainedModel
      ? latestTrainedModel['trained-model-name']
      : '',
    updatedAt: trainedModelGroupData['updated-at'],
    createdAt: trainedModelGroupData['created-at'],
    createdBy: trainedModelGroupData['created-by'],
    trainedModels: trainedModelGroupData['trained-model-list'].map(
      (content: TrainedModelDataItem) => {
        return {
          trainedModelId: content['trained-model-id'],
          trainedModelName: content['trained-model-name'],
          trainedModelGroupVersion: {
            displayName: content['trained-model-group-version']['display-name'],
            major: content['trained-model-group-version']['major'],
            minor: content['trained-model-group-version']['minor'],
            patch: content['trained-model-group-version']['patch'],
          },
          transactionStatus: content['transaction-status'],
        }
      }
    ),
    userGroupId: trainedModelGroupData['user-group-id'],
  } as TrainedModelGroup
}

const createTrainedModelGroupData = async (
  snapShot: QuerySnapshot<DocumentData>
): Promise<(TrainedModelGroup | undefined)[]> =>
  // 関連のDocsを取得し表示用のカスタム学習データを生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const trainedModelGroupData = document.data()
      if (
        !fireStoreTypeGuardForModelGroupQueryDocument(trainedModelGroupData)
      ) {
        return undefined
      }

      const trainedModelGroup =
        await getRelatedLatestTrainedModelByTrainedModelGroup(
          trainedModelGroupData
        )

      return trainedModelGroup
    })
  )

export const modelUploadOperations = {
  getTrainedModelGroupList:
    (selectedTrainedModelGroupId?: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // ベースモデルの一覧とベースモデルのメタデータの一覧を取得して整形
      try {
        dispatch(modelUploadActions.setInProgress(true))

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        // モデルグループ一覧取得
        let trainedModelGroup = undefined
        if (selectedTrainedModelGroupId) {
          trainedModelGroup = await getDocs(
            query(
              getTrainedModelGroupQueriesCollection(userGroupId),
              where('trained-model-group-id', '==', selectedTrainedModelGroupId)
            )
          )

          if (
            trainedModelGroup.docs.length > 0 &&
            trainedModelGroup.docs[0].data()
          ) {
            const modelGroupData = trainedModelGroup.docs[0].data()
            dispatch(
              modelUploadActions.setSelectedAlgorithmId(
                modelGroupData['algorithm-id']
              )
            )
          }
        } else {
          const selectedClassSetId =
            getState().pages.modelUploadState.domainData.selectedClassSet
              ?.classSetId
          trainedModelGroup = selectedClassSetId
            ? await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  where(
                    'algorithm-id',
                    '==',
                    getState().pages.modelUploadState.domainData
                      .selectedTrainingAlgorithm?.algorithmId
                  ),
                  where(
                    'extended.object-classification.class-set.class-set-id',
                    '==',
                    selectedClassSetId
                  )
                )
              )
            : await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  where(
                    'algorithm-id',
                    '==',
                    getState().pages.modelUploadState.domainData
                      .selectedTrainingAlgorithm?.algorithmId
                  )
                )
              )
        }

        if (!trainedModelGroup) return

        const trainedModelGroups: (TrainedModelGroup | undefined)[] =
          await Promise.all(
            trainedModelGroup.docs.map(async (item) => {
              const trainedModelGroupData = item.data()
              if (
                !fireStoreTypeGuardForModelGroupQueryDocument(
                  trainedModelGroupData
                )
              ) {
                return undefined
              }
              const latestTrainedModel = trainedModelGroupData[
                'trained-model-list'
              ].find(
                (data: TrainedModelDataItem) =>
                  data['trained-model-group-version']['display-name'] ===
                  `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
              )
              const trainedModelCount =
                trainedModelGroupData['trained-model-count']

              const trainedModels: (TrainedModel | undefined)[] =
                await Promise.all(
                  trainedModelGroupData['trained-model-list'].map(
                    async (content: TrainedModelDataItem) => {
                      const modelQuery = (
                        await getDoc(
                          doc(
                            getTrainedModelQueriesCollection(userGroupId),
                            content['trained-model-id']
                          )
                        )
                      ).data()

                      if (modelQuery) {
                        return {
                          trainedModelId: content['trained-model-id'],
                          trainedModelName: content['trained-model-name'],
                          trainedModelGroupVersion: {
                            displayName:
                              content['trained-model-group-version'][
                                'display-name'
                              ],
                            major:
                              content['trained-model-group-version']['major'],
                            minor:
                              content['trained-model-group-version']['minor'],
                            patch:
                              content['trained-model-group-version']['patch'],
                          },
                          transactionStatus: content['transaction-status'],
                        }
                      }
                    }
                  )
                )

              return {
                trainedModelGroupId:
                  trainedModelGroupData['trained-model-group-id'],
                trainedModelGroupName:
                  trainedModelGroupData['trained-model-group-name'],
                trainedModelCount: trainedModelCount,
                latestTrainedModelVersion:
                  trainedModelCount > 0
                    ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                    : undefined,
                latestTrainedModelName: latestTrainedModel
                  ? latestTrainedModel['trained-model-name']
                  : '',
                updatedAt: trainedModelGroupData['updated-at'],
                createdAt: trainedModelGroupData['created-at'],
                createdBy: trainedModelGroupData['created-by'],
                trainedModels: trainedModels,
                userGroupId: trainedModelGroupData['user-group-id'],
                isSharedUserGroup: trainedModelGroupData['access-control']
                  ? trainedModelGroupData['access-control']['is-shared'] ===
                    true
                  : false,
              } as TrainedModelGroup
            })
          )
        dispatch(
          modelUploadActions.setModelGroupList(
            trainedModelGroups.filter(
              (trainedModelGroup) => trainedModelGroup !== undefined
            ) as TrainedModelGroup[]
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelUploadActions.setInProgress(false))
      }
    },
  getBaseModelGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelUploadActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            modelUploadActions.setBaseModelGroupDisplayCondition({
              ...getState().pages.modelUploadState.domainData
                .baseModelGroupDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const selectedDestinationTrainedModelGroup =
          getState().pages.modelUploadState.domainData
            .selectedDestinationTrainedModelGroup

        if (!selectedDestinationTrainedModelGroup) {
          dispatch(modelUploadActions.setInProgress(false))
          return
        }
        const selectedAlgorithmId =
          getState().pages.modelUploadState.domainData.selectedTrainingAlgorithm
            ?.algorithmId

        if (!selectedAlgorithmId) {
          dispatch(modelUploadActions.setInProgress(false))
          return
        }

        if (selectedDestinationTrainedModelGroup.trainedModels.length === 0) {
          const userGroupTrainedModelGroup = await getDocs(
            query(
              getTrainedModelGroupQueriesCollection(userGroupId),
              where('algorithm-id', '==', selectedAlgorithmId)
            )
          )

          const sharedUserGroupTrainedModelGroup = hasSharedUserGroup
            ? await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(sharedUserGroupId),
                  where('access-control.is-shared', '==', true),
                  where(
                    'access-control.share-permissions.webapp',
                    '==',
                    'list'
                  ),
                  where('algorithm-id', '==', selectedAlgorithmId)
                )
              )
            : { docs: [] }

          if (!userGroupTrainedModelGroup || !sharedUserGroupTrainedModelGroup)
            return

          const userGroupTrainedModelGroups: (TrainedModelGroup | undefined)[] =
            await createTrainedModelGroupData(userGroupTrainedModelGroup)

          const sharedUserGroupTrainedModelGroups: (
            | TrainedModelGroup
            | undefined
          )[] =
            sharedUserGroupTrainedModelGroup.docs.length > 0
              ? await createTrainedModelGroupData(
                  sharedUserGroupTrainedModelGroup as QuerySnapshot<DocumentData>
                )
              : []

          dispatch(
            modelUploadActions.setBaseModelGroupList({
              userGroup: userGroupTrainedModelGroups.filter(
                (trainedModelGroup) => trainedModelGroup !== undefined
              ) as TrainedModelGroup[],
              sharedUserGroup: sharedUserGroupTrainedModelGroups.filter(
                (trainedModelGroup) => trainedModelGroup !== undefined
              ) as TrainedModelGroup[],
            })
          )
        } else {
          const trainedModelGroups: (TrainedModelGroup | undefined)[] =
            await Promise.all(
              selectedDestinationTrainedModelGroup.trainedModels.map(
                async (trainedModel) => {
                  const trainedModelData = (
                    await getDoc(
                      doc(
                        getTrainedModelQueriesCollection(userGroupId),
                        trainedModel.trainedModelId
                      )
                    )
                  ).data()

                  if (!trainedModelData) {
                    return
                  }
                  const baseTrainedModelData = (
                    await getDocs(
                      query(
                        getTrainedModelQueriesCollection(sharedUserGroupId),
                        where(
                          'trained-model-id',
                          '==',
                          trainedModelData['base-model-id']
                            ? trainedModelData['base-model-id']
                            : trainedModelData['base-model']['trained-model-id']
                        ),
                        where('access-control.is-shared', '==', true),
                        where(
                          'access-control.share-permissions.webapp',
                          '==',
                          'list'
                        )
                      )
                    )
                  ).docs[0]?.data()

                  if (!baseTrainedModelData) {
                    return
                  }

                  const trainedModelGroupData = (
                    await getDocs(
                      query(
                        getTrainedModelGroupQueriesCollection(
                          sharedUserGroupId
                        ),
                        where(
                          'trained-model-group-id',
                          '==',
                          baseTrainedModelData['trained-model-group-id']
                        ),
                        where('access-control.is-shared', '==', true),
                        where(
                          'access-control.share-permissions.webapp',
                          '==',
                          'list'
                        )
                      )
                    )
                  ).docs[0]?.data()

                  if (!trainedModelGroupData) {
                    return
                  }

                  const trainedModelGroup =
                    await getRelatedLatestTrainedModelByTrainedModelGroup(
                      trainedModelGroupData
                    )
                  return trainedModelGroup
                }
              )
            )

          const filteredTrainedGroupModel = trainedModelGroups.filter(
            (trainedModelGroup) => trainedModelGroup !== undefined
          ) as TrainedModelGroup[]

          const uniqueTrainedModelGroup = filteredTrainedGroupModel.filter(
            (trainedModelGroup, index, self) =>
              self.findIndex(
                (e) =>
                  e.trainedModelGroupId ===
                  trainedModelGroup.trainedModelGroupId
              ) === index
          )

          dispatch(
            modelUploadActions.setBaseModelGroupList({
              userGroup: [selectedDestinationTrainedModelGroup],
              sharedUserGroup: uniqueTrainedModelGroup,
            })
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelUploadActions.setInProgress(false))
      }
    },
  getDatasetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // データセットの一覧とデータセットメタデータの一覧を取得して整形
      try {
        dispatch(modelUploadActions.setInProgress(true))

        // データセット一覧に必要な関連Docsを取得
        await getDatasetDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelUploadActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribeDatasetList:
    () =>
    async (_dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.modelUploadState.domainData
            .currentDatasetListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
  setSelectedDestinationTrainedModelGroup:
    (modelGroup?: TrainedModelGroup) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        modelUploadActions.setSelectedDestinationTrainedModelGroup(modelGroup)
      )

      dispatch(
        modelUploadActions.setSelectedModelVersion(
          modelGroup?.latestTrainedModelVersion
        )
      )

      if (modelGroup) {
        dispatch(modelUploadActions.setModelGroupSubState('Selected'))
      } else {
        dispatch(modelUploadActions.setModelGroupSubState('Unselected'))
      }
    },
  setSelectedBaseModel:
    (baseModel?: TrainedModel) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(modelUploadActions.setSelectedBaseModel(baseModel))

      if (
        baseModel &&
        getState().pages.modelUploadState.domainData.selectedBaseModelGroup
      ) {
        dispatch(modelUploadActions.setBaseModelSubState('Selected'))
      } else {
        dispatch(modelUploadActions.setBaseModelSubState('Unselected'))
      }
    },
  setSelectedDataset:
    (dataset?: Dataset) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(modelUploadActions.setSelectedDataset(dataset))

      if (dataset) {
        dispatch(modelUploadActions.setDatasetSubState('Selected'))
      } else {
        dispatch(modelUploadActions.setDatasetSubState('Unselected'))
      }
    },
  /** ファイルを選択して入力する */
  setInputFiles:
    (files: File[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // storeに保持しているファイル
      const currentImages = [
        ...getState().pages.modelUploadState.domainData
          .uploadTargetTrainedModel,
      ]
      files.forEach((newFile) => {
        const index = currentImages.findIndex(
          (currentFile) =>
            currentFile.file.name.split('.').pop() ===
            newFile.name.split('.').pop()
        )
        const addFile: FileAndProgress = {
          file: newFile,
          progress: 0,
          uploadStatus: 'beforeUpload',
        }
        if (index < 0) {
          currentImages.push(addFile)
        } else {
          currentImages.splice(index, 1, addFile)
        }
      })
      dispatch(modelUploadActions.addInputFiles(currentImages))
    },
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        modelUploadActions.setModelUploadState(
          ModelUploadStateKindArray[currentStep + 1]
        )
      )

      // メタデータの入力ステップ時にモデルの表示名のデフォルト値を設定
      if (ModelUploadStateKindArray[currentStep + 1] === 'MetaDataState') {
        const files =
          getState().pages.modelUploadState.domainData.uploadTargetTrainedModel
        let fileInfo = ''
        for (const item of files) {
          fileInfo =
            fileInfo +
            `- ファイル: ${
              item.file.name
            }\n - ファイルサイズ: ${convertByteToMatchUnit(
              item.file.size
            )}\n - ファイル形式: ${
              item.file.type ? item.file.type : item.file.name.split('.').pop()
            }\n\n`
        }
        const regex = /\/|\s+|:/g
        dispatch(
          modelUploadActions.setModelMetaData({
            name: `モデル_${formatDateTimeSec(new Date()).replace(regex, '')}`,
            remarks: fileInfo,
          })
        )

        if (
          getState().pages.modelUploadState.domainData.modelMetaData?.name &&
          getState().pages.modelUploadState.domainData.selectedModelKind
        ) {
          dispatch(modelUploadActions.setMetaDataSubState('InputRequired'))
        }
      }
    },
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 0:
          return
        case 1:
          dispatch(
            modelUploadActions.setSelectedDestinationTrainedModelGroup(
              undefined
            )
          )
          dispatch(modelUploadActions.setModelGroupList([]))
          dispatch(modelUploadActions.setModelGroupSubState('Unselected'))
          break
        case 2:
          dispatch(modelUploadActions.setSelectedBaseModel(undefined))
          dispatch(modelUploadActions.setSelectedBaseModelGroup(undefined))
          dispatch(
            modelUploadActions.setBaseModelGroupList({
              userGroup: [],
              sharedUserGroup: [],
            })
          )
          dispatch(modelUploadActions.setBaseModelSubState('Unselected'))
          break
        case 3:
          modelUploadOperations.unsubscribeDatasetList()
          dispatch(modelUploadActions.setDatasetList([]))
          dispatch(modelUploadActions.setSelectedDataset(undefined))
          dispatch(modelUploadActions.setDatasetSubState('Unselected'))
          break
        case 4:
          dispatch(modelUploadActions.addInputFiles([]))
          dispatch(modelUploadActions.setModelUploadSubState('Unselected'))
          break
        case 5:
          dispatch(modelUploadActions.setModelMetaData(undefined))
          dispatch(modelUploadActions.setSelectedModelKind(undefined))
          dispatch(modelUploadActions.setMetaDataSubState('EmptyRequired'))
          break
        default:
          break
      }

      dispatch(
        modelUploadActions.setModelUploadState(
          ModelUploadStateKindArray[currentStep - 1]
        )
      )
    },
  setSelectedAlgorithmId:
    (algorithmId: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(modelUploadActions.setSelectedAlgorithmId(algorithmId))

      if (algorithmId) {
        dispatch(modelUploadActions.setTrainingAlgorithmSubState('Selected'))
      } else {
        dispatch(modelUploadActions.setTrainingAlgorithmSubState('Unselected'))
      }
    },
  setSelectedTrainingAlgorithmVersion:
    (algorithm?: TrainingAlgorithmVersionForDomain) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      if (!isUndefined(algorithm)) {
        const convertAlgorithm: TrainingAlgorithmVersion = {
          trainingAlgorithmVersion: algorithm.trainingAlgorithmVersion,
          algorithmVersion: {
            displayName: algorithm.algorithmVersion.displayName,
            major: algorithm.algorithmVersion.major,
            minor: algorithm.algorithmVersion.minor,
            patch: algorithm.algorithmVersion.patch,
            preRelease: algorithm.algorithmVersion.preRelease,
          },
          metadata: {
            remarks: algorithm.metadata.remarks.ja,
          },
          releasedAt: algorithm.releasedAt,
          algorithmStructures: algorithm.algorithmStructures,
        }
        dispatch(
          modelUploadActions.setSelectedTrainingAlgorithmVersion(
            convertAlgorithm
          )
        )
      } else {
        dispatch(
          modelUploadActions.setSelectedTrainingAlgorithmVersion(undefined)
        )
      }

      if (
        algorithm &&
        getState().pages.modelUploadState.domainData.selectedTrainingAlgorithm
          .algorithmId &&
        getState().pages.modelUploadState.domainData
          .selectedAlgorithmStructureVersion
      ) {
        dispatch(modelUploadActions.setTrainingAlgorithmSubState('Selected'))
      } else {
        dispatch(modelUploadActions.setTrainingAlgorithmSubState('Unselected'))
      }
    },
  setSelectedTrainingAlgorithmStructureVersion:
    (algorithmStructure?: AlgorithmStructureVersion) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        modelUploadActions.setSelectedTrainingAlgorithmStructureVersion(
          algorithmStructure
        )
      )

      if (
        algorithmStructure &&
        getState().pages.modelUploadState.domainData.selectedTrainingAlgorithm
          .algorithmId &&
        getState().pages.modelUploadState.domainData
          .selectedTrainingAlgorithmVersion
      ) {
        dispatch(modelUploadActions.setTrainingAlgorithmSubState('Selected'))
      } else {
        dispatch(modelUploadActions.setTrainingAlgorithmSubState('Unselected'))
      }
    },
  setModelMetaData:
    (modelMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(modelUploadActions.setModelMetaData(modelMetaData))

      if (
        modelMetaData &&
        getState().pages.modelUploadState.domainData.selectedModelKind &&
        getState().pages.modelUploadState.domainData.modelMetaData?.name
      ) {
        dispatch(modelUploadActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(modelUploadActions.setMetaDataSubState('EmptyRequired'))
      }
    },
  setSelectedModelKind:
    (modelKind?: 'Generic' | 'Custom' | 'Specified' | undefined) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(modelUploadActions.setSelectedModelKind(modelKind))
      if (
        modelKind &&
        getState().pages.modelUploadState.domainData.selectedModelKind &&
        getState().pages.modelUploadState.domainData.modelMetaData?.name
      ) {
        dispatch(modelUploadActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(modelUploadActions.setMetaDataSubState('EmptyRequired'))
      }
    },
  executeModelUpload:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(modelUploadActions.setInProgress(true))
      try {
        const {
          selectedTrainingAlgorithm,
          selectedTrainingAlgorithmVersion,
          uploadTargetTrainedModel,
          selectedModelKind,
          modelMetaData,
          selectedDestinationTrainedModelGroup,
          selectedVersion,
          selectedAlgorithmStructureVersion,
          selectedBaseModel,
          selectedDataset,
          selectedClassSet,
        } = getState().pages.modelUploadState.domainData

        if (
          !(
            selectedAlgorithmStructureVersion &&
            selectedTrainingAlgorithm &&
            selectedTrainingAlgorithmVersion?.algorithmVersion &&
            modelMetaData?.name &&
            selectedModelKind !== undefined &&
            uploadTargetTrainedModel.length > 0 &&
            selectedDestinationTrainedModelGroup?.trainedModelGroupId &&
            selectedVersion
          )
        ) {
          console.error('Error invalid request')
          dispatch(modelUploadActions.executeModelUploadFailure())
          return
        }

        const trainedModel: ModelUploadParamsType = {
          modelKind: selectedModelKind as 'Generic' | 'Custom' | 'Specified',
          trainingAlgorithmId: selectedTrainingAlgorithm.algorithmId,
          trainingAlgorithmVersion:
            selectedTrainingAlgorithmVersion.algorithmVersion,
          metadata: {
            name: modelMetaData.name,
            remarks: modelMetaData.remarks,
          },
          modelGroupId:
            selectedDestinationTrainedModelGroup?.trainedModelGroupId,
          modelVersion: selectedVersion,
          algorithmStructureId:
            selectedAlgorithmStructureVersion.algorithmStructureId,
          algorithmStructureVersion: {
            displayName:
              selectedAlgorithmStructureVersion.algorithmStructureVersion
                .displayName,
            major:
              selectedAlgorithmStructureVersion.algorithmStructureVersion.major,
            minor:
              selectedAlgorithmStructureVersion.algorithmStructureVersion.minor,
            patch:
              selectedAlgorithmStructureVersion.algorithmStructureVersion.patch,
          },
          baseModel: selectedBaseModel
            ? {
                trainedModelId: selectedBaseModel.trainedModelId,
                userGroupId:
                  getState().pages.modelUploadState.domainData
                    .baseModelGroupDisplayCondition.selectedUserGroupKind ===
                  'UserGroup'
                    ? getState().app.domainData.authedUser.auth.customClaims
                        .userGroupId
                    : getState().app.domainData.authedUser.auth.customClaims
                        .sharedList[0],
              }
            : undefined,
          datasetId: selectedDataset?.datasetId,
          extended: selectedClassSet
            ? {
                objectClassification: {
                  classSet: {
                    classSetId: selectedClassSet.classSetId,
                    userGroupId: selectedClassSet.userGroupId,
                  },
                },
              }
            : undefined,
          isSharedUserGroup:
            !!selectedDestinationTrainedModelGroup.isSharedUserGroup,
        }

        let result = undefined
        if (
          isUndefined(
            getState().pages.modelUploadState.domainData.executedModelId
          )
        ) {
          result = await ModelUploadApi.executeModelUpload(trainedModel)
        } else {
          result = {
            data: {
              trainedModelId:
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getState().pages.modelUploadState.domainData.executedModelId!,
            },
          }
        }

        const files =
          getState().pages.modelUploadState.domainData.uploadTargetTrainedModel
        const signedUrl = await ModelUploadApi.getSignedUrl(
          result.data.trainedModelId,
          files.map((item) => {
            return {
              // pop() の戻り値として undefined が返るユースケースが存在しないため、unwrapする
              // pop() で undefined となるユースケースとしては、対象の配列が空配列の場合のみ
              // split() で空配列が返却されるユースケースとしては、
              // 対象の文字列が空文字かつ、splitの文字が空文字の場合のみ
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              modelFormat: item.file.name.split('.').pop()!,
              fileType: item.file.type,
              fileName: item.file.name,
            }
          })
        )
        const url = signedUrl.data
        const fileUpload = uploadTargetTrainedModel
          .filter((file) => file.uploadStatus !== 'completed')
          .map(async (fileAndProgress, index) => {
            const config = {
              onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                  files[index].progress = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  )
                } else {
                  files[index].progress = 0
                }
                dispatch(modelUploadActions.addInputFiles(files))
              },
            }

            try {
              await ModelUploadApi.uploadModelFile(
                url[files[index].file.name].url,
                fileAndProgress.file,
                config
              )
              files[index].uploadStatus = 'completed'
            } catch {
              files[index].uploadStatus = 'uploadError'
              files[index].progress = 0
            }
          })

        await Promise.all(fileUpload)

        dispatch(modelUploadActions.addInputFiles(files))

        const fileUploadResult =
          getState().pages.modelUploadState.domainData.uploadTargetTrainedModel

        if (
          fileUploadResult.findIndex(
            (item) => item.uploadStatus !== 'completed'
          ) >= 0
        ) {
          dispatch(modelUploadActions.setFileUploadResult('failed'))
        } else {
          dispatch(modelUploadActions.setFileUploadResult('success'))
        }

        // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
        const uploadFailedList = getState()
          .pages.modelUploadState.domainData.uploadTargetTrainedModel.filter(
            (file) => file.uploadStatus !== 'completed'
          )
          .map((file) => file.file.name)
        if (uploadFailedList.length > 0) {
          const displayNameCnt = 3
          const targets = uploadFailedList
            .slice(0, displayNameCnt)
            .concat(
              uploadFailedList.length > displayNameCnt
                ? [`他${uploadFailedList.length - displayNameCnt}件`]
                : []
            )
          dispatch(
            modelUploadActions.setToastInfo({
              type: 'error',
              title: 'ファイルアップロードに失敗しました',
              targets,
            })
          )
          dispatch(modelUploadActions.executeModelUploadFailure())
          return
        }

        dispatch(modelUploadActions.executeModelUploadSuccess())
        dispatch(
          modelUploadActions.setExecutedModelId(result.data.trainedModelId)
        )
      } catch (error) {
        console.error(error)
        dispatch(modelUploadActions.executeModelUploadFailure())
      } finally {
        dispatch(modelUploadActions.setInProgress(false))
      }
    },

  getClassSets:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelUploadActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            modelUploadActions.setClassSetDisplayCondition({
              ...getState().pages.modelUploadState.domainData
                .classSetDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.modelUploadState.domainData.classSetDisplayCondition
            .selectedUserGroupKind === 'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : domainDataOperations.getSharedUserGroupId()(dispatch, getState)

        const getClassSetListQuery =
          getState().pages.modelUploadState.domainData.classSetDisplayCondition
            .selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetCollection(userGroupId))
            : query(
                getClassSetCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        const classSetDataListDocs = (await getDocs(getClassSetListQuery)).docs
        const classSetDataList: ClassSetDocument[] = classSetDataListDocs
          .map((classSetDoc) => {
            const classSetData = classSetDoc.data()

            if (!fireStoreTypeGuardForClassSetDocument(classSetData)) {
              return
            }

            return classSetData as ClassSetDocument
          })
          .filter(
            (classSetData) => !isUndefined(classSetData)
          ) as ClassSetDocument[]

        if (!classSetDataList) return

        const getClassSetMetaDataListQuery =
          getState().pages.modelUploadState.domainData.classSetDisplayCondition
            .selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetMetaDataCollection(userGroupId))
            : query(
                getClassSetMetaDataCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
        const classSetMetaDataList: ClassSetMetaDataDocument[] = (
          await getDocs(getClassSetMetaDataListQuery)
        ).docs
          .map((doc) => {
            const classSetMetaDataData = doc.data()

            if (
              !fireStoreTypeGuardForClassSetMetaDataDocument(
                classSetMetaDataData
              )
            ) {
              return
            }

            return classSetMetaDataData as ClassSetMetaDataDocument
          })
          .filter(
            (classSetMetaData) => !isUndefined(classSetMetaData)
          ) as ClassSetMetaDataDocument[]

        const classSetList: ClassSet[] = classSetDataList.map(
          (classSetData) => {
            const classSetMetaData = classSetMetaDataList.find(
              (item) => item['class-set-id'] === classSetData['class-set-id']
            )
            return {
              classSetId: classSetData['class-set-id'],
              classSetName: classSetMetaData?.name ?? '',
              classSetRemarks: classSetMetaData?.remarks ?? '',
              classList: classSetData['class-list'],
              createdAt: classSetData['created-at'],
              userGroupId: classSetData['user-group-id'],
            }
          }
        )

        dispatch(modelUploadActions.setClassSets(classSetList))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelUploadActions.setInProgress(false))
      }
    },

  setSelectedClassSet:
    (classSet?: ClassSet) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(modelUploadActions.setSelectedClassSet(classSet))

      if (classSet) {
        dispatch(modelUploadActions.setClassSetSubState('Selected'))
      } else {
        dispatch(modelUploadActions.setClassSetSubState('Unselected'))
      }
    },
}
