import { Dispatch } from 'redux'
import {
  IdTokenResult,
  User,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  RecaptchaVerifier,
  getMultiFactorResolver,
  ParsedToken,
} from 'firebase/auth'
import { getDoc, doc, getDocs, query, where } from 'firebase/firestore'

import { loginActions } from './actions'
import { LoginOperationsApi } from './apis'

import { AuthedUser, domainDataActions } from 'state/app/domainData'
import { appStateActions } from 'state/app/appState'
import {
  authInstance,
  getAccountsCollection,
  getAccountGroupCollection,
  getAccountSettingCollection,
  getCustomerCollection,
  getAccountCustomerRelationsCollection,
} from 'state/firebase'

import { State } from 'state/store'
import { LoginError } from './types'

import { getCustomerList } from 'state/utils/customer'
import { Customer } from 'utils/ducks/customerChange/types'
import { CustomerChangeApi } from 'state/ducks/customerChange/apis'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountCustomerRelationDocument } from 'utils/fireStore/accountCustomerRelation'

const preLoginOperations = async (
  dispatch: Dispatch,
  idToken: IdTokenResult
) => {
  // 初回ログイン判定
  const accountsDoc = (
    await getDoc(
      doc(
        getAccountsCollection(idToken.claims['account-group-id'] as string),
        idToken.claims['account-id'] as string
      )
    )
  ).data()

  const passwordReset = accountsDoc ? accountsDoc['password-reset'] : false

  if (passwordReset) {
    // パスワード設定画面に遷移させる
    dispatch(loginActions.setLoginState('PasswordUpdate'))
    return true
  }

  const firstLoginTime = accountsDoc
    ? accountsDoc['first-login-time'].seconds
    : 0
  const isFirstLogin = firstLoginTime === 0

  // 初回ログインフラグをセット
  if (isFirstLogin) {
    // アカウントグループ
    const accountsGroupDoc = (
      await getDoc(
        doc(
          getAccountGroupCollection(),
          idToken.claims['account-group-id'] as string
        )
      )
    ).data()

    // アカウントの設定
    const accountSettingDoc = (
      await getDoc(
        doc(
          getAccountSettingCollection(
            idToken.claims['account-group-id'] as string
          ),
          idToken.claims['account-id'] as string
        )
      )
    ).data()

    if (
      (accountsGroupDoc &&
        accountsGroupDoc['mfa-group-setting'] === 'required') ||
      (accountsGroupDoc &&
        accountsGroupDoc['mfa-group-setting'] === 'optional' &&
        accountSettingDoc &&
        !accountSettingDoc['is-mfa'])
    ) {
      // MFA設定画面に遷移させる
      dispatch(loginActions.setLoginState('MfaSetting'))
      return true
    }
  }

  return false
}

/**
 * 対象のカスタマーIDに参照権限があるか
 */
const hasPrivilegeOfCustomer = (
  customerId: string,
  customerList: Customer[]
): boolean => {
  const customerIndex = customerList.findIndex(
    (customer) => customer.customerId === customerId
  )
  if (customerIndex < 0) {
    // カスタマーへの参照権限が無い場合
    return false
  }

  return true
}

/**
 * ユーザーグループIDに紐づくカスタマーIDを取得する
 * @param userGroupId ユーザーグループID
 * @returns カスタマーID
 */
const getCustomerId = async (userGroupId: string): Promise<string> => {
  const customerDocs = await getDocs(
    query(getCustomerCollection(), where('user-group-id', '==', userGroupId))
  )
  return customerDocs.docs.length > 0
    ? customerDocs.docs[0].data()['customer-id']
    : ''
}

const changeCustomerWithAuth = async (
  uid: string,
  email: string,
  firebaseCurrentUser: User,
  customerList: Customer[]
): Promise<AuthedUser> => {
  const customerDoc = (
    await getDoc(doc(getCustomerCollection(), customerList[0].customerId))
  ).data()

  const updateParam = {
    ['user-group-id']: customerDoc ? customerDoc['user-group-id'] : '',
    ['shared-list']:
      customerDoc && customerDoc['shared-user-group-list']
        ? customerDoc['shared-user-group-list']
        : [],
  }

  await CustomerChangeApi.updateCustomClaim(uid, updateParam)
  const result = await firebaseCurrentUser.getIdTokenResult(true)
  return {
    mailAddress: email,
    userId: uid,
    customers: customerList,
    auth: {
      token: result.token,
      customClaims: {
        accountId: result.claims['account-id'] as string,
        accountGroupId: result.claims['account-group-id'] as string,
        role: result.claims['role'] as string,
        sharedList: (result.claims['shared-list'] as string[]) ?? [],
        superUser: result.claims['super-user'] as boolean,
        userGroupId: customerDoc ? customerDoc['user-group-id'] : '',
      },
    },
  }
}

const getGrantedSharedListCustomClaimAuthedUser = async (
  uid: string,
  email: string,
  firebaseCurrentUser: User,
  customerList: Customer[],
  userGroupId: string
): Promise<AuthedUser> => {
  const customerDocs = await getDocs(
    query(getCustomerCollection(), where('user-group-id', '==', userGroupId))
  )
  const customerDoc =
    customerDocs.docs.length > 0 ? customerDocs.docs[0].data() : undefined

  const updateParam = {
    ['user-group-id']: customerDoc ? customerDoc['user-group-id'] : '',
    ['shared-list']: customerDoc ? customerDoc['shared-user-group-list'] : [],
  }

  await CustomerChangeApi.updateCustomClaim(uid, updateParam)
  const result = await firebaseCurrentUser.getIdTokenResult(true)
  return {
    mailAddress: email,
    userId: uid,
    customers: customerList,
    auth: {
      token: result.token,
      customClaims: {
        accountId: result.claims['account-id'] as string,
        accountGroupId: result.claims['account-group-id'] as string,
        role: result.claims['role'] as string,
        sharedList: (result.claims['shared-list'] as string[]) ?? [],
        superUser: result.claims['super-user'] as boolean,
        userGroupId: userGroupId,
      },
    },
  }
}

const generateAuthedUser = async (
  uid: string,
  email: string,
  firebaseCurrentUser: User,
  claims: ParsedToken
): Promise<AuthedUser> => {
  // カスタマーリストを取得する
  const customerList = await getCustomerList(
    claims['account-id'] as string,
    claims['account-group-id'] as string,
    claims['super-user'] as boolean
  )

  const currentCustomerId = await getCustomerId(
    claims['user-group-id'] as string
  )

  const privilegeOfCustomer = hasPrivilegeOfCustomer(
    currentCustomerId,
    customerList
  )

  let updatedAuthedUser: AuthedUser | undefined = undefined

  if (!privilegeOfCustomer) {
    // 有効なカスタマーに切り替え
    // 切り替え後のユーザ情報を取得
    updatedAuthedUser = await changeCustomerWithAuth(
      uid,
      email,
      firebaseCurrentUser,
      customerList
    )
  } else {
    // TODO: 共有データの機能リリースにおけるマイグレーション対応
    // shared-listのカスタムクレームを付与
    // 切り替え後のユーザ情報を取得
    updatedAuthedUser = await getGrantedSharedListCustomClaimAuthedUser(
      uid,
      email,
      firebaseCurrentUser,
      customerList,
      claims['user-group-id'] as string
    )
  }

  return updatedAuthedUser
}

/**
 * ユーザーが1件以上カスタマーと紐づいているか取得する
 * @returns `true`の場合、1件以上カスタマーが紐づいている
 */
const hasUserCustomerList = async (claims: ParsedToken) => {
  const accountGroupId = claims['account-group-id'] as string
  const accountId = claims['account-id'] as string

  const accountCustomerRelationDoc = (
    await getDoc(
      doc(getAccountCustomerRelationsCollection(accountGroupId), accountId)
    )
  ).data()
  if (
    !fireStoreTypeGuardForAccountCustomerRelationDocument(
      accountCustomerRelationDoc
    )
  ) {
    return false
  }

  return (
    accountCustomerRelationDoc?.['customer-list'] != null &&
    accountCustomerRelationDoc?.['customer-list'].length > 0
  )
}

export const loginOperations = {
  login:
    (email: string, password: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(loginActions.setInProgress(true))
      dispatch(loginActions.setLoginState('Loading'))
      try {
        await LoginOperationsApi.signInWithEmailAndPassword(email, password)
          .then(async () => {
            const firebaseCurrentUser: User | null = authInstance.currentUser
            if (firebaseCurrentUser) {
              await LoginOperationsApi.updateIsLastLoginStatus()
              const email = firebaseCurrentUser.email
              const uid = firebaseCurrentUser.uid

              if (email && uid) {
                const result = await firebaseCurrentUser.getIdTokenResult()

                const hasCustomerList = await hasUserCustomerList(result.claims)
                if (!hasCustomerList) {
                  // ログイン失敗
                  dispatch(appStateActions.setAuthed(false))
                  dispatch(loginActions.setLoginState('NoCustomerList'))
                  return
                }

                // パスワード変更画面 or MFA設定画面に遷移必要かチェックし、必要であれば遷移する
                const isNeedPasswordOrMfaSetting = await preLoginOperations(
                  dispatch,
                  result
                )

                if (isNeedPasswordOrMfaSetting) return

                const authedUser = await generateAuthedUser(
                  uid,
                  email,
                  firebaseCurrentUser,
                  result.claims
                )

                await CustomerChangeApi.updateLastAccessed(
                  await getCustomerId(authedUser.auth.customClaims.userGroupId)
                )

                await LoginOperationsApi.updateIsLastLoginStatus()
                dispatch(appStateActions.setAuthed(true))
                dispatch(domainDataActions.setAuthedUser(authedUser))
                dispatch(loginActions.setLoginState('Loggedin'))
              } else {
                // ログイン失敗
                dispatch(appStateActions.setAuthed(false))
                dispatch(loginActions.setLoginState('LoginFail'))
              }
            } else {
              // ログイン失敗
              dispatch(appStateActions.setAuthed(false))
              dispatch(loginActions.setLoginState('LoginFail'))
            }
          })
          .catch(async (error) => {
            // MFA認証が有効な場合
            if (error.code === 'auth/multi-factor-auth-required') {
              dispatch(loginActions.setLoginState('NeedMfa'))
              const resolver = getMultiFactorResolver(authInstance, error)
              const loginError: LoginError = {
                errorCode: error.code,
                resolver: resolver,
              }
              dispatch(loginActions.setLoginError(loginError))
              if (
                PhoneMultiFactorGenerator.FACTOR_ID ===
                resolver.hints[0].factorId
              ) {
                const phoneInfoOptions = {
                  multiFactorHint: resolver.hints[0],
                  session: resolver.session,
                }
                const phoneAuthProvider = new PhoneAuthProvider(authInstance)
                const storedRecaptchaVerifier =
                  getState().pages.loginState.appState.recaptchaVerifier
                const recaptchaVerifier = storedRecaptchaVerifier
                  ? storedRecaptchaVerifier
                  : new RecaptchaVerifier(authInstance, 'login-button', {
                      size: 'invisible',
                    })
                dispatch(loginActions.setRecaptchaVerifier(recaptchaVerifier))
                const verificationId =
                  await phoneAuthProvider.verifyPhoneNumber(
                    phoneInfoOptions,
                    recaptchaVerifier
                  )
                dispatch(loginActions.setVerificationId(verificationId))
              }
            } else if (
              error.code === 'auth/password-does-not-meet-requirements'
            ) {
              // パスワードポリシーに準拠していない場合
              // パスワードリセット画面に遷移させる
              dispatch(appStateActions.setAuthed(false))
              dispatch(loginActions.setLoginState('PasswordReset'))
            } else {
              dispatch(appStateActions.setAuthed(false))
              dispatch(loginActions.setLoginState('LoginFail'))
            }
          })
      } catch (error) {
        // エラー発生
        console.error(error)
        dispatch(appStateActions.setAuthed(false))
        dispatch(loginActions.setLoginState('LoginFail'))
      } finally {
        dispatch(loginActions.setInProgress(false))
      }
    },
  // MFA認証処理
  executeAuthenticationCode:
    (authenticationCode: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(loginActions.setInProgress(true))
      try {
        const resolver =
          getState().pages.loginState.appState.loginError.resolver
        const verificationId =
          getState().pages.loginState.appState.verificationId

        const cred = PhoneAuthProvider.credential(
          verificationId,
          authenticationCode
        )
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

        if (!resolver) {
          throw new TypeError('resolver not found')
        }

        await resolver
          .resolveSignIn(multiFactorAssertion)
          .then(async () => {
            const firebaseCurrentUser: User | null = authInstance.currentUser
            if (firebaseCurrentUser) {
              await LoginOperationsApi.updateIsLastLoginStatus()
              const email = firebaseCurrentUser.email
              const uid = firebaseCurrentUser.uid
              if (email && uid) {
                const result = await firebaseCurrentUser.getIdTokenResult()

                const hasCustomerList = await hasUserCustomerList(result.claims)
                if (!hasCustomerList) {
                  // ログイン失敗
                  dispatch(appStateActions.setAuthed(false))
                  dispatch(loginActions.setLoginState('NoCustomerList'))
                  await authInstance.signOut()
                  return
                }

                // パスワードリセットフラグ
                const accountsDoc = (
                  await getDoc(
                    doc(
                      getAccountsCollection(
                        result.claims['account-group-id'] as string
                      ),
                      result.claims['account-id'] as string
                    )
                  )
                ).data()

                const passwordReset = accountsDoc
                  ? accountsDoc['password-reset']
                  : false
                if (passwordReset) {
                  dispatch(loginActions.setLoginState('PasswordUpdate'))
                  return
                }

                const authedUser = await generateAuthedUser(
                  uid,
                  email,
                  firebaseCurrentUser,
                  result.claims
                )

                dispatch(appStateActions.setAuthed(true))
                dispatch(domainDataActions.setAuthedUser(authedUser))
                dispatch(loginActions.setLoginState('Loggedin'))
              } else {
                // ログイン失敗
                dispatch(appStateActions.setAuthed(false))
                dispatch(loginActions.setLoginState('LoginFail'))
              }
            } else {
              // ログイン失敗
              dispatch(appStateActions.setAuthed(false))
              dispatch(loginActions.setLoginState('LoginFail'))
            }
          })
          .catch((error: { code: string }) => {
            console.error(error.code)
            if (error.code === 'auth/invalid-verification-code') {
              dispatch(
                loginActions.setErrorMessage(
                  '入力された認証コードが間違っています。\n送信された認証コードを再度入力してください'
                )
              )
            } else if (error.code === 'auth/too-many-requests') {
              dispatch(loginActions.setFailTooManyRequestsState())
            } else if (error.code === 'auth/code-expired') {
              dispatch(loginActions.setLoginState('MfaFail'))
            } else {
              console.error('予期しないエラー', error)
            }
          })
      } catch (error) {
        console.error(error)
        alert('Login Failed')
      } finally {
        dispatch(loginActions.setInProgress(false))
      }
    },
  // ログアウト時にdomainData, appStateをクリア
  clearAuthedUser:
    () =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(domainDataActions.clearDomainData())
      dispatch(appStateActions.clearAppState())
    },
}
