import {
  isArray,
  isNumber,
  isObject,
  isString,
  isNumbers,
} from 'utils/typeguard'
import {
  ObjectRecognitionInferenceResultV1,
  GetLogResponse,
  InferenceResultV1,
  Log,
  GetProcessedUrlResponse,
  GetResultFileResponse,
  Result as ResultFile,
  GetMetricsResponse,
  GetMetricsItemResponse,
} from './types'

/** Logかどうか */
export function isLog(object: unknown): object is Log {
  return (
    isObject(object) &&
    isString(object.name) &&
    isNumber(object.size) &&
    isNumber(object.createdAt) &&
    isString(object.fileNameForDownload)
  )
}

/** Metricsかどうか */
export function isMetrics(object: unknown): object is Log {
  return (
    isObject(object) &&
    isString(object.name) &&
    isNumber(object.size) &&
    isNumber(object.createdAt) &&
    isString(object.url)
  )
}

export function isGetMetricsItemResponse(
  object: unknown
): object is GetMetricsItemResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    (object.data.item == null || isMetrics(object.data.item))
  )
}

/** GetLogResponseかどうか */
export function isGetLogResponse(object: unknown): object is GetLogResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isLog(element)
    })
  )
}

/** GetMetricsResponseかどうか */
export function isGetMetricsResponse(
  object: unknown
): object is GetMetricsResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isMetrics(element)
    })
  )
}

export function isResultFile(object: unknown): object is ResultFile {
  return (
    isObject(object) &&
    isString(object.name) &&
    isNumber(object.size) &&
    isNumber(object.createdAt) &&
    isString(object.fileNameForDownload)
  )
}

export function isGetResultFileResponse(
  object: unknown
): object is GetResultFileResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isResultFile(element)
    })
  )
}
export function isInferenceResult(
  object: unknown
): object is InferenceResultV1 {
  return (
    isObject(object) &&
    isNumbers(object.box) &&
    isNumber(object.score) &&
    isObject(object.label) &&
    isString(object.label.id) &&
    isString(object.label.name)
  )
}

/** ObjectRecognitionInferenceResultV1かどうか */
export function isObjectRecognitionInferenceResultV1(
  object: unknown
): object is ObjectRecognitionInferenceResultV1 {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.results) &&
    object.data.results.every((element) => {
      return isInferenceResult(element)
    })
  )
}

/** GetProcessedUrlResponseかどうか */
export function isGetProcessedUrlResponse(
  object: unknown
): object is GetProcessedUrlResponse {
  return isObject(object) && isObject(object.data) && isString(object.data.url)
}
