import axios from 'axios'
import { Dispatch } from 'redux'
import {
  getMlPipelineQueriesCollection,
  getTrainedModelQueriesCollection,
  getAccountSettingCollection,
  getBaseInferenceContainerImageCollection,
} from 'state/firebase'
import {
  EdgeContainerImage,
  EdgeContainerImageForMlPipelineQuery,
  SystemEvaluationFile,
  TransferStatus,
} from './types'
import { buildDetailActions } from './actions'
import { BuildDetailApi } from './apis'
import { State } from 'state/store'
import { saveAs } from 'file-saver'
import { doc, getDoc, getDocs, query, where } from 'firebase/firestore'

import { fireStoreTypeGuard as fireStoreTypeGuardForBuildMLPipelineQueryDocument } from 'utils/fireStore/buildMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import {
  convertProgressRateByTransactionStatusForBuild,
  TransactionStatusKind,
} from 'state/utils'
import { isGetResultFileResponse } from 'state/utils/typeguard'
import { domainDataOperations } from 'state/app/domainData/operations'

const getProgressFromBuildAndTransferStepStatus = (
  buildStepStatus: string,
  TransferStepStatus?: string
): TransactionStatusKind => {
  if (!TransferStepStatus) {
    return buildStepStatus as TransactionStatusKind
  }

  if (buildStepStatus === 'Completed') {
    if (TransferStepStatus === 'Starting' || TransferStepStatus === 'Pending') {
      return 'Transferring' as TransactionStatusKind
    } else {
      return TransferStepStatus as TransactionStatusKind
    }
  } else {
    return buildStepStatus as TransactionStatusKind
  }
}

const getTransferStepStatus = (
  buildStepStatus: string,
  transferStepStatus?: string
): string => {
  if (!transferStepStatus) {
    return 'NotApplicable'
  }

  if (buildStepStatus === 'Failed') {
    return 'NotTransfer'
  }

  if (transferStepStatus === 'Completed') {
    return 'Transferred'
  } else if (
    transferStepStatus === 'Failed' &&
    buildStepStatus === 'Completed'
  ) {
    return 'Failed'
  } else {
    return 'Waiting'
  }
}
/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    buildDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

export const BuildDetailOperations = {
  /** ビルド詳細で表示パラメータを取得する */
  getBuildDetail:
    (mlPipelineId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(buildDetailActions.setInProgressForGettingBuildDetail(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId
        const algorithms = getState().app.domainData.algorithms

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        /** mlPipelineのデータを取得 */
        const mlPipeline = await getDoc(
          doc(getMlPipelineQueriesCollection(userGroupId), mlPipelineId)
        )
        const mlPipelineData = mlPipeline.data()

        if (!mlPipelineData) {
          dispatch(
            buildDetailActions.setBuildDetailState({
              ...getState().pages.buildDetailState.appState.buildDetailState,
              mlPipelineDataState: 'NotFoundProcessed',
            })
          )
          return
        }

        if (
          !fireStoreTypeGuardForBuildMLPipelineQueryDocument(mlPipelineData)
        ) {
          dispatch(
            buildDetailActions.setBuildDetailState({
              ...getState().pages.buildDetailState.appState.buildDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        /** アルゴリズムのデータを取得 */
        const algorithmId = mlPipelineData['build-step']['src']['algorithm-id']

        const algorithm = algorithms.find((algorithm) => {
          return algorithm.algorithmId === algorithmId
        })

        /** アカウント設定のデータを取得 */
        let accountSetting = undefined

        try {
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                mlPipelineData['created-by']
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(
            buildDetailActions.setBuildDetailState({
              ...getState().pages.buildDetailState.appState.buildDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        const trainedModelUserGroupId =
          mlPipelineData['build-step']?.['src']?.['trained-model'][
            'user-group-id'
          ] ?? userGroupId

        const trainedModelId =
          mlPipelineData['build-step']['src']['trained-model'][
            'trained-model-id'
          ]
        /** trained-modelのデータ */
        const trainedModelData = (
          await getDocs(
            userGroupId === trainedModelUserGroupId
              ? query(
                  getTrainedModelQueriesCollection(trainedModelUserGroupId),
                  where('trained-model-id', '==', trainedModelId)
                )
              : query(
                  getTrainedModelQueriesCollection(trainedModelUserGroupId),
                  where('trained-model-id', '==', trainedModelId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
          )
        ).docs[0]?.data()

        const isSharedUserGroupModel =
          trainedModelUserGroupId === sharedUserGroupId

        const containerInterfaceVersion =
          mlPipelineData['build-step']['src']['container-interface-version']

        /** ベース推論コンテナーイメージの一覧のデータを取得 */
        const allBaseInferenceContainerImage = await getDocs(
          query(
            getBaseInferenceContainerImageCollection(),
            where('algorithm-id', '==', algorithmId),
            where(
              'inference-algorithm-version.display-name',
              '==',
              mlPipelineData['build-step']['src'][
                'inference-algorithm-version'
              ]['display-name']
            ),
            where(
              'container-interface-version.display-name',
              '==',
              containerInterfaceVersion['display-name']
            ),
            where('usage-type', '==', 'edge')
          )
        )

        const filteredContainerImageList =
          allBaseInferenceContainerImage.docs.filter(
            (baseInferenceContainerImage) =>
              mlPipelineData['build-step']['src'][
                'base-inference-container-image-list'
              ].includes(baseInferenceContainerImage.id)
          )

        const containerImagelist = filteredContainerImageList.map((doc) => {
          const baseInferenceContainerImageData = doc.data()
          return {
            baseInferenceContainerImageId:
              baseInferenceContainerImageData[
                'base-inference-container-image-id'
              ],
            containerImagePlatform:
              baseInferenceContainerImageData['container-image-platform'],
            containerImageTags:
              baseInferenceContainerImageData['container-image-tags'],
          }
        })

        const edgeContainerImagelist = mlPipelineData['build-step']['dest'][
          'edge-container-image-list'
        ]
          ? mlPipelineData['build-step']['dest']['edge-container-image-list']
              .map(
                (edgeContainerImage: EdgeContainerImageForMlPipelineQuery) => ({
                  edgeContainerImageId:
                    edgeContainerImage['edge-container-image-id'],
                  edgeContainerImagePlatform:
                    edgeContainerImage['edge-container-image-platform'],
                  edgeContainerImageTags:
                    edgeContainerImage['edge-container-image-tags'],
                })
              )
              .sort(compareEdgeContainerImages)
          : []
        const progress = getProgressFromBuildAndTransferStepStatus(
          mlPipelineData['build-step']['step-status'],
          mlPipelineData['transfer-step']?.['step-status']
        )

        const inferenceCodeVersion =
          filteredContainerImageList[0].data()['inference-code-version']

        dispatch(
          buildDetailActions.setCurrentBuildDetail({
            mlPipelineId: mlPipelineData
              ? mlPipelineData['ml-pipeline']['ml-pipeline-id']
              : '',
            mlPipelineName: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['name']
              : '',
            mlPipelineRemarks: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['remarks']
              : '',
            trainingAlgorithm: {
              algorithmId: algorithm ? algorithm.algorithmId : '',
              algorithmName: algorithm ? algorithm.metadata.name.ja : '',
              trainingAlgorithmVersion: {
                displayName: trainedModelData
                  ? trainedModelData['training-algorithm-version'][
                      'display-name'
                    ]
                  : '',
                major: trainedModelData
                  ? trainedModelData['training-algorithm-version']['major']
                  : 0,
                minor: trainedModelData
                  ? trainedModelData['training-algorithm-version']['minor']
                  : 0,
                patch: trainedModelData
                  ? trainedModelData['training-algorithm-version']['patch']
                  : 0,
                preRelease: trainedModelData
                  ? trainedModelData['training-algorithm-version'][
                      'pre-release'
                    ]
                  : 0,
              },
            },
            progress: {
              transactionStatus: progress,
              progressRate:
                convertProgressRateByTransactionStatusForBuild(progress),
            },
            mlPipelineStartedAt: mlPipelineData
              ? mlPipelineData['ml-pipeline']['started-at']
              : '',
            mlPipelineEndedAt:
              mlPipelineData &&
              mlPipelineData['ml-pipeline']['ended-at'] &&
              mlPipelineData['ml-pipeline']['ended-at'].seconds === 0
                ? undefined
                : mlPipelineData['ml-pipeline']['ended-at'],
            buildEndedAt:
              mlPipelineData['build-step']['step-status'] === 'Completed'
                ? mlPipelineData['build-step']['ended-at']
                : undefined,
            transferEndedAt:
              mlPipelineData['transfer-step']?.['step-status'] === 'Completed'
                ? mlPipelineData['transfer-step']?.['ended-at']
                : undefined,
            transferStatus: getTransferStepStatus(
              mlPipelineData['build-step']['step-status'],
              mlPipelineData['transfer-step']?.['step-status']
            ) as TransferStatus,
            trainedModel: {
              trainedModelId: trainedModelData
                ? trainedModelData['trained-model-id']
                  ? trainedModelData['trained-model-id']
                  : ''
                : '',
              trainedModelName: trainedModelData
                ? trainedModelData['trained-model-name']
                : '',
              trainedModelGroupId: trainedModelData
                ? trainedModelData['trained-model-group-id']
                  ? trainedModelData['trained-model-group-id']
                  : ''
                : '',
              isSharedUserGroupModel: isSharedUserGroupModel,
            },
            isTransfer: mlPipelineData['transfer-step'] ? true : false,
            isSystemEvaluation: mlPipelineData['build-step'][
              'system-evaluation'
            ]
              ? mlPipelineData['build-step']['system-evaluation']['enabled'] ??
                false
              : false,
            containerInterfaceVersion: {
              displayName: containerInterfaceVersion['display-name'],
              major: containerInterfaceVersion['major'],
              minor: containerInterfaceVersion['minor'],
              patch: containerInterfaceVersion['patch'],
            },
            inferenceAlgorithmVersion: {
              displayName:
                mlPipelineData['build-step']['src'][
                  'inference-algorithm-version'
                ]['display-name'],
              major:
                mlPipelineData['build-step']['src'][
                  'inference-algorithm-version'
                ]['major'],
              minor:
                mlPipelineData['build-step']['src'][
                  'inference-algorithm-version'
                ]['minor'],
              patch:
                mlPipelineData['build-step']['src'][
                  'inference-algorithm-version'
                ]['patch'],
              preRelease: mlPipelineData['build-step']['src'][
                'inference-algorithm-version'
              ]['pre-release']
                ? mlPipelineData['build-step']['src'][
                    'inference-algorithm-version'
                  ]['pre-release']
                : 99,
            },
            inferenceCodeVersion: inferenceCodeVersion
              ? {
                  displayName: inferenceCodeVersion['display-name'],
                  major: inferenceCodeVersion['major'],
                  minor: inferenceCodeVersion['minor'],
                  patch: inferenceCodeVersion['patch'],
                  build: inferenceCodeVersion['build'],
                }
              : undefined,
            baseContainerImagelist: containerImagelist,
            edgeImageContainerImageGroup:
              mlPipelineData['build-step']['dest'][
                'edge-container-image-list'
              ] &&
              mlPipelineData['build-step']['dest']['edge-container-image-list']
                .length > 0
                ? {
                    edgeImageContainerImageGroupId:
                      mlPipelineData['build-step']['dest'][
                        'edge-container-image-list'
                      ][0]['edge-container-image-group-id'],
                    edgeImageContainerImageGroupVersion: {
                      displayName:
                        mlPipelineData['build-step']['dest'][
                          'edge-container-image-list'
                        ][0]['edge-container-image-group-version'][
                          'display-name'
                        ],
                      major:
                        mlPipelineData['build-step']['dest'][
                          'edge-container-image-list'
                        ][0]['edge-container-image-group-version']['major'],
                      minor:
                        mlPipelineData['build-step']['dest'][
                          'edge-container-image-list'
                        ][0]['edge-container-image-group-version']['minor'],
                      patch:
                        mlPipelineData['build-step']['dest'][
                          'edge-container-image-list'
                        ][0]['edge-container-image-group-version']['patch'],
                    },
                  }
                : undefined,
            edgeContainerImagelist: edgeContainerImagelist,
            createdBy: accountSetting
              ? `${accountSetting['first-name'] ?? ''} ${
                  accountSetting['family-name'] ?? ''
                }`
              : mlPipelineData
              ? mlPipelineData['created-by']
              : '',
          })
        )
        dispatch(
          buildDetailActions.setBuildDetailState({
            ...getState().pages.buildDetailState.appState.buildDetailState,
            mlPipelineDataState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          buildDetailActions.setBuildDetailState({
            ...getState().pages.buildDetailState.appState.buildDetailState,
            mlPipelineDataState: 'Failed',
          })
        )
      } finally {
        dispatch(buildDetailActions.setInProgressForGettingBuildDetail(false))
      }
    },
  /** システム評価結果の取得 */
  downloadSystemEvaluationFile:
    (link: SystemEvaluationFile) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(buildDetailActions.setInProgressForDownloading(true))
        // ファイルDL失敗リスト
        const failedModelFileList: string[] = []
        try {
          await axios
            .get(link.fileLink, {
              responseType: 'blob',
            })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: response.data.type,
              })
              saveAs(blob, link.fileName)
            })
        } catch {
          failedModelFileList.push(link.fileName)
        }
        // 失敗したファイルがあれば、Storeに保持
        if (failedModelFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedModelFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(buildDetailActions.setInProgressForDownloading(false))
      }
    },
  /** resultファイルをダウンロードする */
  downloadAllSystemEvaluationFiles:
    (systemEvaluationFiles: SystemEvaluationFile[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(buildDetailActions.setInProgressForDownloading(true))
        // ファイルDL失敗リスト
        const failedResultFileList: string[] = []
        await Promise.all(
          systemEvaluationFiles.map(async (systemEvaluationFile) => {
            try {
              await axios
                .get(systemEvaluationFile.fileLink, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, systemEvaluationFile.fileName)
                })
            } catch {
              failedResultFileList.push(systemEvaluationFile.fileName)
            }
          })
        )
        // 失敗したファイルがあれば、Storeに保持
        if (failedResultFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedResultFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(buildDetailActions.setInProgressForDownloading(false))
      }
    },
  /** ファイルデータの取得 */
  getFileData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(
          buildDetailActions.setInProgressForGettingSystemEvaluation(true)
        )
        const requestMlPipelineId =
          getState().pages.buildDetailState.domainData.currentBuildDetail
            ?.mlPipelineId
        let resultFileList: SystemEvaluationFile[] = []
        // リザルトファイル用キャッチ
        try {
          const systemEvaluationFilesResponse =
            await BuildDetailApi.getResultFiles(
              requestMlPipelineId ? requestMlPipelineId : ''
            )
          if (isGetResultFileResponse(systemEvaluationFilesResponse)) {
            resultFileList = systemEvaluationFilesResponse.data.items.map(
              (result) => ({
                fileName: result.name,
                fileLink: result.fileNameForDownload,
                fileSize: result.size,
                createdAt: new Date(result.createdAt),
              })
            )

            dispatch(
              buildDetailActions.setBuildDetailState({
                ...getState().pages.buildDetailState.appState.buildDetailState,
                systemEvaluationFileSubState: 'Loaded',
              })
            )
          }
        } catch {
          dispatch(
            buildDetailActions.setBuildDetailState({
              ...getState().pages.buildDetailState.appState.buildDetailState,
              systemEvaluationFileSubState: 'Failed',
            })
          )
        }

        const currentMlPipelineId =
          getState().pages.buildDetailState.domainData.currentBuildDetail
            ?.mlPipelineId

        if (requestMlPipelineId === currentMlPipelineId) {
          dispatch(buildDetailActions.setSystemEvaluationFiles(resultFileList))
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(
          buildDetailActions.setInProgressForGettingSystemEvaluation(false)
        )
      }
    },
  clearCurrentBuildDetail:
    () =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(buildDetailActions.clearCurrentBuildDetail())
      } catch (error) {
        console.error(error)
      }
    },
}
/** EdgeContainerImage比較 */
const compareEdgeContainerImages = (
  image1: EdgeContainerImage,
  image2: EdgeContainerImage
) => {
  const tags1 = image1.edgeContainerImageTags.join(',')
  const tags2 = image2.edgeContainerImageTags.join(',')
  if (tags1 === tags2) {
    const platform1 = `${image1.edgeContainerImagePlatform.os}/${image1.edgeContainerImagePlatform.architecture}`
    const platform2 = `${image2.edgeContainerImagePlatform.os}/${image2.edgeContainerImagePlatform.architecture}`
    return platform1 !== platform2 ? (platform1 < platform2 ? -1 : 1) : 0
  }
  return tags1 < tags2 ? -1 : 1
}
