import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { yellow } from '@mui/material/colors'

import { EmphasizedMessageProps } from './types'

const useStyles = makeStyles()((theme) => ({
  root: {
    border: '1px solid',
    borderColor: yellow[500],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: yellow[100],
    whiteSpace: 'pre-wrap',
  },
}))

export const EmphasizedMessage: React.FC<EmphasizedMessageProps> = (
  props: EmphasizedMessageProps
) => {
  const { classes } = useStyles()

  return (
    <Box p={1} className={classes.root}>
      <Typography variant='body1'>{props.message}</Typography>
    </Box>
  )
}
