import { BuildListAction } from './actions'
import { BuildListActionType, BuildListState } from './types'

const initialState: BuildListState = {
  domainData: {
    currentBuildList: [],
    buildDisplayCondition: {
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    currentBuildListSnapshot: undefined,
    mlPipelineIds: [],
  },
  appState: {
    inProgress: false,
  },
}

export const BuildListReducer = (
  state: BuildListState = initialState,
  action: BuildListAction
): BuildListState => {
  switch (action.type) {
    case BuildListActionType.SET_BUILD_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentBuildList: action.payload.currentBuildList,
        },
      }
    case BuildListActionType.SET_BUILD_DISPLAY_CONDITION:
      if (action.payload.buildDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            buildDisplayCondition: action.payload.buildDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case BuildListActionType.CLEAR_BUILD_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          buildDisplayCondition: {
            ...initialState.domainData.buildDisplayCondition,
          },
        },
      }
    case BuildListActionType.SET_IN_PROGRESS_FOR_BUILD_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case BuildListActionType.SET_ML_PIPELINE_IDS_FOR_BUILD_LIST:
      if (action.payload.mlPipelineIds) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            mlPipelineIds: action.payload.mlPipelineIds,
          },
        }
      } else {
        return { ...state }
      }
    case BuildListActionType.SET_CURRENT_BUILD_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentBuildListSnapshot: action.payload.currentBuildListSnapshot,
        },
      }
    case BuildListActionType.CLEAR_BUILD_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
