import { Dispatch } from 'redux'
import { adminModelListActions } from './'
import { ModelInfo } from './types'
import { State } from 'state/store'
import {
  getMlPipelineQueriesCollection,
  getTrainedModelQueriesCollection,
} from 'state/firebase'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument } from 'utils/fireStore/customTrainingMLPipelineQuery'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { domainDataOperations } from 'state/app/domainData/operations'

// モデル一覧を生成
const createModelList = async (
  userGroupId: string,
  modelQuery: Query<DocumentData>
): Promise<(ModelInfo | undefined)[]> =>
  await Promise.all(
    // モデルリストの生成
    (
      await getDocs(modelQuery)
    ).docs.map(async (document: DocumentData) => {
      const modelQueryDocData = document.data()
      if (!fireStoreTypeGuardForModelQueryDocument(modelQueryDocData)) {
        return undefined
      }
      let mlPipeline = undefined
      if (modelQueryDocData['ml-pipeline-id']) {
        mlPipeline = (
          await getDoc(
            doc(
              getMlPipelineQueriesCollection(userGroupId),
              modelQueryDocData['ml-pipeline-id']
            )
          )
        ).data()
      }

      if (
        mlPipeline &&
        !fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument(mlPipeline)
      ) {
        return undefined
      }

      // モデル一覧を返す
      return {
        modelId: modelQueryDocData['trained-model-id'],
        modelName: modelQueryDocData
          ? modelQueryDocData['trained-model-name']
          : '',
        modelKind: modelQueryDocData['model-kind'],
        modelGroupVersion: {
          displayName:
            modelQueryDocData['trained-model-group-version']['display-name'],
          major: modelQueryDocData['trained-model-group-version']['major'],
          minor: modelQueryDocData['trained-model-group-version']['minor'],
          patch: modelQueryDocData['trained-model-group-version']['patch'],
        },
        modelGroupId: modelQueryDocData['trained-model-group-id'],
        generatedAt: modelQueryDocData['generated-at'] ?? undefined,
        mlPipeline: {
          mlPipelineId: modelQueryDocData['ml-pipeline-id'],
          mlPipelineKind: mlPipeline
            ? mlPipeline['ml-pipeline']['ml-pipeline-kind']
            : '',
        },
        uid: modelQueryDocData['created-by'],
      } as ModelInfo
    })
  )

export const adminModelListOperations = {
  /** リストを取得する */
  getModelList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(adminModelListActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            adminModelListActions.setListDisplayCondition({
              ...getState().pages.adminModelListState.domainData
                .modelListDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.adminModelListState.domainData
            .modelListDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]

        // 表示条件取得
        const condition =
          getState().pages.adminModelListState.domainData
            .modelListDisplayCondition

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentModelList =
          getState().pages.adminModelListState.domainData.currentModelList

        const limitCount =
          condition.displayNumber * (condition.pageNumber + 1) -
          currentModelList.length

        let totalCountQuery =
          getState().pages.adminModelListState.domainData
            .modelListDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? query(getTrainedModelQueriesCollection(userGroupId))
            : query(
                getTrainedModelQueriesCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        if (condition.algorithmId) {
          totalCountQuery = query(
            totalCountQuery,
            where('algorithm-id', '==', condition.algorithmId)
          )
        }

        // trained-modelsを表示件数分取得
        let modelQuery = query(totalCountQuery, limit(limitCount))

        // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          modelQuery = query(
            modelQuery,
            orderBy('trained-model-id', 'asc'),
            where('trained-model-id', '>=', startCode),
            where('trained-model-id', '<=', endCode)
          )
        } else {
          if (condition.sortKey === 'modelGroupVersion') {
            modelQuery = query(
              modelQuery,
              orderBy('trained-model-group-version.major', condition.sortOrder),
              orderBy('trained-model-group-version.minor', condition.sortOrder),
              orderBy('trained-model-group-version.patch', condition.sortOrder)
            )
          } else {
            modelQuery = query(
              modelQuery,
              orderBy(condition.sortKey, condition.sortOrder)
            )
          }
        }

        // 既に取得していれば最後の要素から取得
        let lastItem: DocumentData | undefined = undefined
        if (currentModelList.length) {
          lastItem = await getDoc(
            doc(
              getTrainedModelQueriesCollection(userGroupId),
              currentModelList[currentModelList.length - 1].modelId
            )
          )
          modelQuery = query(modelQuery, startAfter(lastItem))
        }

        // モデル一覧を取得
        const newModelList: (ModelInfo | undefined)[] = await createModelList(
          userGroupId,
          modelQuery
        )
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          totalCountQuery = query(
            totalCountQuery,
            where('trained-model-id', '>=', startCode),
            where('trained-model-id', '<=', endCode)
          )
        }
        const totalCount = await getCountFromServer(totalCountQuery)

        dispatch(
          adminModelListActions.setListDisplayCondition({
            ...condition,
            totalCount: totalCount.data().count,
          })
        )

        dispatch(
          adminModelListActions.setModelList([
            ...currentModelList,
            ...(newModelList.filter(
              (item) => item !== undefined
            ) as ModelInfo[]),
          ])
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(adminModelListActions.setInProgress(false))
      }
    },
}
