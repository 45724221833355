import { Dispatch } from 'redux'
import {
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  multiFactor,
  RecaptchaVerifier,
} from 'firebase/auth'
import { doc, updateDoc } from 'firebase/firestore'
import { mfaSettingActions } from './'
import { domainDataActions, AuthedUser } from '../../app/domainData'
import { MfaSettingApi } from './apis'
import { MfaSettingStateKindArray, ToastInfo } from './types'
import { State } from 'state/store'
import { authInstance } from 'state/firebase'
import { appStateActions } from 'state/app/appState'
import { loginActions } from '../login/actions'
import { getCustomerList } from 'state/utils/customer'
import { getAccountSettingCollection } from 'state/firebase'

export const mfaSettingOperations = {
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        mfaSettingActions.setFirstLoginStateKind(
          MfaSettingStateKindArray[currentStep + 1]
        )
      )
    },
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        mfaSettingActions.setFirstLoginStateKind(
          MfaSettingStateKindArray[currentStep - 1]
        )
      )
    },
  requestAuthenticationCode:
    (convertPhoneNumber: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(mfaSettingActions.setInProgress(true))
        const user = authInstance.currentUser
        if (!user) return
        const storedRecaptchaVerifier =
          getState().pages.mfaSettingState.appState.recaptchaVerifier
        const recaptchaVerifier = storedRecaptchaVerifier
          ? storedRecaptchaVerifier
          : new RecaptchaVerifier(authInstance, 'mfa-register', {
              size: 'invisible',
            })
        dispatch(mfaSettingActions.setRecaptchaVerifier(recaptchaVerifier))

        const session = await multiFactor(user).getSession()
        const phoneInfoOptions = {
          phoneNumber: convertPhoneNumber,
          session: session,
        }
        const phoneAuthProvider = new PhoneAuthProvider(authInstance)
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        )

        dispatch(mfaSettingActions.setVerificationId(verificationId))
      } catch (error) {
        const toastInfo: ToastInfo = {
          type: 'error',
          title: '',
          targets: ['認証コード送信に失敗しました'],
        }
        dispatch(mfaSettingActions.setToastInfo(toastInfo))
        console.error(error)
      } finally {
        dispatch(mfaSettingActions.setInProgress(false))
      }
    },
  executeAuthCode:
    (authenticationCode: string, phoneNumber: string, localeId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(mfaSettingActions.setInProgress(true))
        const cred = PhoneAuthProvider.credential(
          getState().pages.mfaSettingState.appState.verificationId,
          authenticationCode
        )
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
        const user = authInstance.currentUser

        if (!user) return
        const email = getState().app.domainData.authedUser.mailAddress
        const uid = getState().app.domainData.authedUser.userId
        const result = await user.getIdTokenResult()
        const authedUser: AuthedUser = {
          mailAddress: email ? email : user.email ?? '',
          phoneNumber: phoneNumber,
          locale: localeId,
          customers: [],
          userId: uid ? uid : user.uid,
          auth: {
            token: await user.getIdToken(),
            customClaims: {
              accountId: result.claims['account-id'] as string,
              accountGroupId: result.claims['account-group-id'] as string,
              role: result.claims['role'] as string,
              sharedList: (result.claims['shared-list'] as string[]) ?? [],
              superUser: result.claims['super-user'] as boolean,
              userGroupId: result.claims['user-group-id'] as string,
            },
          },
        }

        // カスタマーリストを取得する
        const customerList = await getCustomerList(
          result.claims['account-id'] as string,
          result.claims['account-group-id'] as string,
          result.claims['super-user'] as boolean
        )
        authedUser.customers = customerList

        // is-mfaの更新
        await updateDoc(
          doc(
            getAccountSettingCollection(
              result.claims['account-group-id'] as string
            ),
            result.claims['account-id'] as string
          ),
          {
            'is-mfa': true,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
        await MfaSettingApi.updateIsFirstLoginStatus()
        await MfaSettingApi.updatePhoneNumber(phoneNumber)
        const toastInfo: ToastInfo = {
          type: 'info',
          title: '',
          targets: ['MFAの設定が完了しました'],
        }
        dispatch(mfaSettingActions.setToastInfo(toastInfo))
        dispatch(mfaSettingActions.setMfaSettingStateCompleted())
        dispatch(domainDataActions.setAuthedUser(authedUser))
        dispatch(appStateActions.setAuthed(true))
        return multiFactor(user).enroll(multiFactorAssertion)
      } catch (error) {
        const toastInfo: ToastInfo = {
          type: 'error',
          title: '',
          targets: ['認証に失敗しました'],
        }
        dispatch(mfaSettingActions.setToastInfo(toastInfo))
        dispatch(appStateActions.setAuthed(false))
        dispatch(loginActions.setLoginState('LoginFail'))
        console.error(error)
      } finally {
        dispatch(mfaSettingActions.setInProgress(false))
      }
    },
}
