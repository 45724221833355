import { AlgorithmDocument } from './types'
import { fireStoreTypeGuard } from './typeguard'

// InferenceAlgorithmVersion Documentに変換
export const convertDocument = (data: unknown): AlgorithmDocument => {
  const algorithmData = data as AlgorithmDocument
  return {
    'algorithm-id': algorithmData['algorithm-id'],
    'algorithm-purpose': algorithmData['algorithm-purpose'],
    metadata: {
      name: {
        en: algorithmData['metadata']['name']['en'],
        ja: algorithmData['metadata']['name']['ja'],
      },
      overview: {
        en: algorithmData['metadata']['overview']['en'],
        ja: algorithmData['metadata']['overview']['ja'],
      },
    },
  }
}

// 型チェックしてAlgorithm Documentに変換
export const convertDocumentWithDataCheck = (
  data: unknown
): AlgorithmDocument | undefined => {
  const algorithmData = data as AlgorithmDocument
  const isAlgorithmDocument = fireStoreTypeGuard(algorithmData)
  if (!isAlgorithmDocument) {
    return undefined
  }

  return {
    'algorithm-id': algorithmData['algorithm-id'],
    'algorithm-purpose': algorithmData['algorithm-purpose'],
    metadata: {
      name: {
        en: algorithmData['metadata']['name']['en'],
        ja: algorithmData['metadata']['name']['ja'],
      },
      overview: {
        en: algorithmData['metadata']['overview']['en'],
        ja: algorithmData['metadata']['overview']['ja'],
      },
    },
  }
}
