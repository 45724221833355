import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { StepperLayoutProps, StepPropsType } from './types'
import { CommonStepper } from 'views/components/molecules/commonStepper'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: '#fafafa',
    '& > *': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  buttonDiv: {
    '& > button': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  contentDiv: {
    clear: 'both',
  },
}))

export const StepperLayout: React.FC<StepperLayoutProps> = (
  props: StepperLayoutProps
) => {
  const { classes } = useStyles()
  const getStepLabels = () =>
    props.stepProps.map((step) => (step.label ? step.label : ''))

  return (
    <Box className={classes.root}>
      <CommonStepper
        activeStepIndex={props.activeStepIndex}
        stepLabels={getStepLabels()}
      />
      {((stepPropsItem: StepPropsType) =>
        stepPropsItem ? (
          <Box>
            <div className={classes.contentDiv}>
              {stepPropsItem.stepContent}
            </div>
            <div className={classes.buttonDiv}>
              {stepPropsItem.previousButtonProps?.visibility ? (
                <div></div>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={stepPropsItem.previousButtonProps?.disabled}
                  onClick={stepPropsItem.previousButtonProps?.onClick}
                  className={classes.leftButton}
                >
                  {stepPropsItem.previousButtonProps?.label || '戻る'}
                </Button>
              )}
              <Button
                variant='contained'
                color='primary'
                disabled={stepPropsItem.nextButtonProps?.disabled}
                onClick={stepPropsItem.nextButtonProps?.onClick}
                className={classes.rightButton}
                data-testid={`${props['data-testid']}-button-next`}
              >
                {stepPropsItem.nextButtonProps?.label || '次へ'}
              </Button>
            </div>
            <div className={classes.contentDiv}>
              {stepPropsItem.informationContent}
            </div>
          </Box>
        ) : (
          <Typography>Invalid Index!</Typography>
        ))(props.stepProps[props.activeStepIndex])}
    </Box>
  )
}
