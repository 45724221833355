import { getAuth, User } from 'firebase/auth'
import React, { createContext, ReactFragment, useEffect, useState } from 'react'
import { AuthorizationOperations } from 'state/ducks/authorization'
import { AuthorizationResult } from 'types/StateTypes'
import { GlobalLoading } from 'views/components'
import { KYOCERA_ROBOTICS_SITE_URL } from 'state/utils/urls'

interface IAuthContext {
  currentUser: User | null | undefined
}

export type Prop = {
  children: ReactFragment
}

const AuthContext = createContext<IAuthContext>({ currentUser: undefined })

const AuthProvider: React.FC<Prop> = (props: Prop) => {
  const [authorizationResult, setAuthorizationResult] = useState<
    AuthorizationResult | undefined
  >(undefined)
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined
  )

  useEffect(() => {
    // 認可チェック
    const authorizationApp = async () => {
      const result = await AuthorizationOperations.authorizationApp()
      setAuthorizationResult(result)

      if (result?.result.permission === 'Deny') {
        window.location.href =
          result.result['redirect-url'] ?? KYOCERA_ROBOTICS_SITE_URL
      }
    }

    authorizationApp()
  }, [])

  useEffect(() => {
    const auth = getAuth()
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
    })
  }, [])

  if (authorizationResult?.result.permission === 'Allow') {
    return (
      <AuthContext.Provider
        value={{
          currentUser: currentUser,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    )
  }

  return <GlobalLoading open={true} />
}

export { AuthContext, AuthProvider }
