import React from 'react'
import Button from '@mui/material/Button'
import NavigateNext from '@mui/icons-material/NavigateNext'

import { NextButtonProps } from './types'

export const NextButton: React.FC<NextButtonProps> = (
  props: NextButtonProps
) => {
  return (
    <Button
      variant='contained'
      data-testid={props['data-testid']}
      disabled={props.disabled}
      onClick={props.onClick}
      className={props.className}
      color={
        props.color && props.color === 'blue'
          ? 'primary'
          : props.color && props.color === 'red'
          ? 'secondary'
          : 'inherit'
      }
    >
      <NavigateNext fontSize='large' />
    </Button>
  )
}
