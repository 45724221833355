import { AccountGroupsActions } from './actions'
import { AccountGroups, AccountGroupActionType } from './types'

const initialState: AccountGroups = {
  domainData: {
    accountGroupList: [],
    customerList: [],
    superUserList: [],
    selectedAccountGroupId: '',
    selectedAccountGroupMfaGroupSetting: '',
    customerListDisplayCondition: {
      displayNumber: 10,
      pageNumber: 0,
    },
    superUserListDisplayCondition: {
      sortKey: 'email',
      sortOrder: 'asc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    inProgress: false,
    inProgressForGettingSuperUserList: false,
    inProgressForGettingMfaSetting: false,
    accountGroupUpdateCustomerListSubState: 'BeforeUpdateCustomerList',
    customerListPagingState: {
      nextPageSubState: 'Enable',
    },
    superUserListSubState: 'BeforeLoading',
    mfaGroupSettingSubState: 'BeforeLoading',
  },
}

export const AccountGroupsReducer = (
  state: AccountGroups = initialState,
  action: AccountGroupsActions
): AccountGroups => {
  switch (action.type) {
    case AccountGroupActionType.SET_ACCOUNT_GROUP_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountGroupList: action.payload.accountGroupList,
        },
      }
    case AccountGroupActionType.SET_CUSTOMER_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          customerList: action.payload.customerList,
        },
      }
    case AccountGroupActionType.SET_SUPER_USER_LIST_FOR_ACCOUNT_GROUPS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          superUserList: action.payload.superUserList,
        },
      }
    case AccountGroupActionType.SET_SELECTED_ACCOUNT_GROUP_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAccountGroupId: action.payload.selectedAccountGroupId,
        },
      }
    case AccountGroupActionType.SET_SELECTED_ACCOUNT_GROUP_MFA_GROUP_SETTING_FOR_ACCOUNT_GROUP:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAccountGroupMfaGroupSetting: action.payload.mfaGroupSetting,
        },
      }
    case AccountGroupActionType.SET_CUSTOMER_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          customerListDisplayCondition:
            action.payload.customerListDisplayCondition,
        },
      }
    case AccountGroupActionType.SET_SUPER_USER_LIST_DISPLAY_CONDITION_FOR_ACCOUNT_GROUPS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          superUserListDisplayCondition:
            action.payload.superUserListDisplayCondition,
        },
      }
    case AccountGroupActionType.SET_CUSTOMER_LIST_PAGING_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customerListPagingState: action.payload.customerListPagingState,
        },
      }
    case AccountGroupActionType.SET_SUPER_USER_LIST_SUB_STATE_FOR_ACCOUNT_GROUP:
      return {
        ...state,
        appState: {
          ...state.appState,
          superUserListSubState: action.payload.data,
        },
      }
    case AccountGroupActionType.SET_MFA_GROUP_SETTING_SUB_STATE_FOR_ACCOUNT_GROUP:
      return {
        ...state,
        appState: {
          ...state.appState,
          mfaGroupSettingSubState: action.payload.data,
        },
      }
    case AccountGroupActionType.UPDATE_ACCOUNT_GROUP_CUSTOMER_RELATIONS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountGroupList: state.domainData.accountGroupList.map(
            (accountGroup) => {
              return {
                ...accountGroup,
                customerList:
                  accountGroup.accountGroupId === action.payload.accountGroupId
                    ? action.payload.customerList
                    : accountGroup.customerList,
              }
            }
          ),
        },
      }
    case AccountGroupActionType.SET_IN_PROGRESS_FOR_ACCOUNT_GROUP:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case AccountGroupActionType.SET_IN_PROGRESS_FOR_GETTING_SUPER_USER_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgressForGettingSuperUserList: action.payload.inProgress,
        },
      }
    case AccountGroupActionType.SET_IN_PROGRESS_FOR_GETTING_MFA_GROUP_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgressForGettingMfaSetting: action.payload.inProgress,
        },
      }
    case AccountGroupActionType.CLEAR_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          customerListDisplayCondition: {
            ...initialState.domainData.customerListDisplayCondition,
          },
        },
      }
    case AccountGroupActionType.CLEAR_ACCOUNT_GROUP_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
