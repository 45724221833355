import { Timestamp } from 'state/firebase'
import { InferenceResultV1 } from 'state/utils'
import { ResourceLoadingState, TransactionStatusKind } from 'state/utils/types'

export const InferenceDetailActionType = {
  SET_CURRENT_INFERENCE_DETAIL: 'SET_CURRENT_INFERENCE_DETAIL',
  SET_CURRENT_INFERENCE_RESULTS: 'SET_CURRENT_INFERENCE_RESULTS',
  SET_INFERENCE_DETAIL_STATE: 'SET_INFERENCE_DETAIL_STATE',
  SET_IN_PROGRESS_FOR_GETTING_INFERENCE_DETAIL:
    'SET_IN_PROGRESS_FOR_GETTING_INFERENCE_DETAIL',
  CLEAR_INFERENCE_DETAIL_STATE: 'CLEAR_INFERENCE_DETAIL_STATE',
  SET_TOAST_INFO_FOR_INFERENCE_DETAIL: 'SET_TOAST_INFO_FOR_INFERENCE_DETAIL',
  SET_RESULT_FILES: 'SET_RESULT_FILES',
  SET_ML_PIPELINE_LOG_FILES: 'SET_ML_PIPELINE_LOG_FILES',
  SET_INFERENCE_RESULT_DISPLAY_CONDITION:
    'SET_INFERENCE_RESULT_DISPLAY_CONDITION',
  SET_IN_PROGRESS_FOR_GETTING_INFERENCE_RESULT:
    'SET_IN_PROGRESS_FOR_GETTING_INFERENCE_RESULT',
  SET_IN_PROGRESS_FOR_DOWNLOADING: 'SET_IN_PROGRESS_FOR_DOWNLOADING',
  SET_IN_PROGRESS_FOR_RENDERING: 'SET_IN_PROGRESS_FOR_RENDERING',
  SET_SELECTED_IMAGE_ID: 'SET_SELECTED_IMAGE_ID',
} as const

interface Algorithm {
  algorithmName: string // アルゴリズム名
  inferenceAlgorithmVersion: {
    displayName: string
    major: number // メジャーバージョン
    minor: number // マイナーバージョン
    patch: number // パッチバージョン
  }
}

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface trainedModel {
  trainedModelId: string
  trainedModelName: string
  trainingAlgorithmVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
    preRelease: number
  }
  isSharedUserGroupModel: boolean
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface InferenceResultFile {
  fileName: string
  fileLink: string
  fileSize: number
  createdAt: Date
}

export interface MlPipelineLogFile {
  fileName: string
  fileLink: string
  fileSize: number
  createdAt: Date
}

export interface TrainingData {
  id: string
  fileName: string
  thumbnailUrl: string
  processedUrl: string
}

interface GroupedData {
  groupedDataId: string
  groupedDataName: string
  datasetId: string
  annotationSetId: string
  imageListCount: number
  trainingDataList: TrainingData[]
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface InferenceResultDisplayCondition {
  mask: boolean
  bbox: boolean
  label: boolean
  score?: boolean
  selectedLabelIds: string[]
  disabled?: boolean
}

export interface CurrentInferenceDetail {
  mlPipelineId: string
  mlPipelineName: string
  mlPipelineRemarks: string
  groupedData: GroupedData
  progress: Progress
  startedAt: Timestamp
  endedAt: Timestamp
  trainedModel: trainedModel
  inferenceAlgorithm: Algorithm
  createdBy: AccountName
}

export interface InferenceDetailState {
  processedTrainingDataUrlSubState: ResourceLoadingState
  inferenceResultSubState: ResourceLoadingState
  inferenceResultDlLinkSubState: ResourceLoadingState
  mlPipelineLogSubState: ResourceLoadingState
  mlPipelineDataState: ResourceLoadingState
}

export interface InferenceDetailDomainData {
  currentInferenceDetail?: CurrentInferenceDetail
  resultFiles: InferenceResultFile[]
  mlPipelineLogFiles: MlPipelineLogFile[]
  selectedTrainingDataId: string
  currentInferenceResults?: InferenceResultV1[]
  inferenceResultDisplayCondition: InferenceResultDisplayCondition
}

export interface InferenceDetailAppState {
  inferenceDetailState: InferenceDetailState
  toastInfo: ToastInfo | undefined
  isInProgressForGettingInferenceDetail: boolean
  isInProgressForGettingInferenceResult: boolean
  isInProgressForDownloading: boolean
  isInProgressForRendering: boolean
}

export interface InferenceDetail {
  domainData: InferenceDetailDomainData
  appState: InferenceDetailAppState
}
