import { PasswordUpdateAction } from './actions'
import { PasswordUpdateActionType, PasswordUpdateState } from './types'

const initialState: PasswordUpdateState = {
  appState: {
    toastInfo: undefined,
    isPasswordUpdated: false,
    inProgress: false,
    needMfa: undefined,
  },
}

export const PasswordUpdateReducer = (
  state: PasswordUpdateState = initialState,
  action: PasswordUpdateAction
): PasswordUpdateState => {
  switch (action.type) {
    case PasswordUpdateActionType.SET_TOAST_INFO_FOR_PASSWORD_UPDATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case PasswordUpdateActionType.SET_IS_PASSWORD_UPDATED:
      return {
        ...state,
        appState: {
          ...state.appState,
          isPasswordUpdated: action.payload.isPasswordUpdated,
        },
      }
    case PasswordUpdateActionType.SET_IN_PROGRESS_FOR_PASSWORD_UPDATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case PasswordUpdateActionType.SET_NEED_MFA:
      return {
        ...state,
        appState: {
          ...state.appState,
          needMfa: action.payload.needMfa,
        },
      }
    case PasswordUpdateActionType.CLEAR_PASSWORD_UPDATE_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
