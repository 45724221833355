import { AppInfoActions } from './actions'
import { AppInfoActionType } from './types'

export interface AppInfo {
  version: string
}

const initialState: AppInfo = {
  version: '',
}

export const AppInfoReducer = (
  state: AppInfo = initialState,
  action: AppInfoActions
): AppInfo => {
  switch (action.type) {
    case AppInfoActionType.SET_VERSION:
      return {
        ...state,
        version: action.payload.data,
      }
    default:
      return state
  }
}
