import React, { useEffect, useRef } from 'react'
import { FocusedInferenceResultViewProps } from './types'
import { decode, rleFromString } from 'utils/RLE.js'
import { convertHexToRgb } from 'utils/colors'

export const FocusedInferenceResultView: React.FC<
  FocusedInferenceResultViewProps
> = (props: FocusedInferenceResultViewProps) => {
  const outRef = useRef<HTMLCanvasElement>(null)
  const padding = props.padding ?? 16

  /** Imageの描画 */
  useEffect(() => {
    const img = new Image()
    img.src = props.url

    img.onload = function () {
      if (outRef.current === null) return

      const canvas = outRef.current
      canvas.width = canvas.clientWidth
      canvas.height = canvas.clientHeight

      const context = outRef.current?.getContext('2d')

      if (context) {
        // canvas の高さと画像の高さから割り出した比率を画像幅に掛け合わせた、canvas に入る画像サイズの算出
        // 倍率が低い方を採用する (倍率が高い方を使用するとはみ出るため)
        // BBOX 表示領域より余白分(8px, 8px)表示領域を大きくするために -16 して縮小率を上げている
        const ratio =
          canvas.height / props.canvasInfo.height >
          canvas.width / props.canvasInfo.width
            ? (canvas.width - padding) / props.canvasInfo.width
            : (canvas.height - padding) / props.canvasInfo.height

        // Image の描画
        // BBOX 表示位置の中心座標が canvas の中心の位置に来るように描画
        context.drawImage(
          img,
          // x座標の計算 : BBOX 表示位置の x座標 を canvas の x:0 の位置に移動させ、そこから canvas の横幅の半分から BBOX の半分の幅を引いて左側の余白を算出し、移動させたBox表示位置を余白分ずらす
          props.canvasInfo.x * ratio * -1 +
            canvas.width / 2 -
            (props.canvasInfo.width / 2) * ratio,
          // y座標の計算 : BBOX 表示位置の y座標 を canvas の y:0 の位置に移動させ、そこから canvas の縦幅の半分から BBOX の半分の幅を引いて上側の余白を算出し、移動させたBox表示位置を余白分ずらす
          props.canvasInfo.y * ratio * -1 +
            canvas.height / 2 -
            (props.canvasInfo.height / 2) * ratio,
          img.width * ratio,
          img.height * ratio
        )

        context.strokeStyle = props.canvasInfo.color
        context.lineWidth = 2

        // BBOX の描画
        context.strokeRect(
          canvas.width / 2 - (props.canvasInfo.width / 2) * ratio,
          canvas.height / 2 - (props.canvasInfo.height / 2) * ratio,
          props.canvasInfo.width * ratio,
          props.canvasInfo.height * ratio
        )

        // Mask の描画
        if (props.canvasInfo.mask.counts.length === 0) return

        const decoded =
          typeof props.canvasInfo.mask.counts === 'string'
            ? decode(
                rleFromString(
                  new TextEncoder().encode(props.canvasInfo.mask.counts)
                )
              )
            : decode(props.canvasInfo.mask.counts)
        const maskColor = convertHexToRgb(props.canvasInfo.color)
        const dataWithAdditionalLayers: number[] = new Array(
          decoded.length * maskColor.length
        )
        const redIndex = 0
        const greenIndex = 1
        const blueIndex = 2
        decoded.forEach((_, i) => {
          dataWithAdditionalLayers[i * 4] = maskColor[redIndex] // red
          dataWithAdditionalLayers[i * 4 + 1] = maskColor[greenIndex] // green
          dataWithAdditionalLayers[i * 4 + 2] = maskColor[blueIndex] // blue
          dataWithAdditionalLayers[i * 4 + 3] = // alpha
            decoded[
              (((i * props.canvasInfo.mask.size[0]) / decoded.length) | 0) + // Integer division
                ((i * props.canvasInfo.mask.size[0]) % decoded.length) // Index of the transposed matrix
            ] & 100 // transmittance rate
        })

        const maskImg = new ImageData(
          new Uint8ClampedArray(dataWithAdditionalLayers),
          props.canvasInfo.mask.size[1],
          props.canvasInfo.mask.size[0]
        )

        const renderer = document.createElement('canvas')
        renderer.width = props.canvasInfo.mask.size[1]
        renderer.height = props.canvasInfo.mask.size[0]
        const ctx = renderer.getContext('2d')
        if (ctx) {
          ctx.putImageData(maskImg, 0, 0)
          // Mask は画像と同じサイズの canvas を作成し、作成した canvas に Mask を作って、メインの canvas に重ね合わせている
          // そのためx座標もy座標も BBOX 表示領域と同じ位置に Mask 用に作成した canvas をずらして描画している
          context.drawImage(
            renderer,
            props.canvasInfo.x * ratio * -1 +
              canvas.width / 2 -
              (props.canvasInfo.width / 2) * ratio,
            props.canvasInfo.y * ratio * -1 +
              canvas.height / 2 -
              (props.canvasInfo.height / 2) * ratio,
            img.width * ratio,
            img.height * ratio
          )
        }
      }
    }
  }, [props.url, outRef.current !== null])

  return <canvas ref={outRef} width={props.width} height={props.height} />
}
