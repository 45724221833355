import {
  PhoneNumberUtil,
  PhoneNumber,
  PhoneNumberFormat,
} from 'google-libphonenumber'

/** 電話番号の有効の可否 */
export const isValidNumberForRegion = (
  phoneNumber: string,
  countryCode: string
): boolean => {
  try {
    const util: PhoneNumberUtil = PhoneNumberUtil.getInstance()
    const number: PhoneNumber = util.parse(phoneNumber, countryCode)
    return util.isValidNumber(number)
  } catch (e) {
    // perseのタイミングで失敗した場合を考慮 (電話番号のフォーマットに一致しない)
    // console.error(e)
    return false
  }
}

/** 電話番号を国コードに合わせて変換 */
export const convertCountryCodePhoneNumber = (
  phoneNumber: string,
  countryCode: string
): string => {
  try {
    const util: PhoneNumberUtil = PhoneNumberUtil.getInstance()
    const number: PhoneNumber = util.parse(phoneNumber, countryCode)
    return util.format(number, PhoneNumberFormat.E164)
  } catch (e) {
    // perseのタイミングで失敗した場合を考慮 (電話番号のフォーマットに一致しない)
    // console.error(e)
    return ''
  }
}

/** 国コード付きの電話番号を通常の電話番号に変換して返す */
export const convertPhoneNumber = (phoneNumber: string): string => {
  try {
    const util: PhoneNumberUtil = PhoneNumberUtil.getInstance()
    const num = util.parse(phoneNumber)
    const num2 = util.format(num, PhoneNumberFormat.NATIONAL)
    return num2
  } catch (e) {
    // perseのタイミングで失敗した場合を考慮 (電話番号のフォーマットに一致しない)
    console.error(e)
    return ''
  }
}
