import { ModelDetailAction } from './actions'
import { ModelDetailActionType, ModelDetail } from './types'

const initialState: ModelDetail = {
  domainData: {
    currentTrainedModelDetail: {
      trainedModelGroupId: '',
      trainedModelGroupName: '',
      trainedModelVersion: '',
      relatedTrainedModelList: [],
      modelListDisplayCondition: {
        sortKey: 'modelVersion',
        sortOrder: 'desc',
        displayNumber: 10,
        pageNumber: 0,
      },
      trainedModelId: '',
      trainedModelName: '',
      trainedModelRemarks: '',
      trainedModelKind: 'Generic',
      evaluationStatus: 'Ne',
      baseModel: {
        baseModelId: '',
        baseModelGroupId: '',
        baseModelName: '',
        isSharedUserGroupBaseModel: true,
      },
      inheritedVersion: {
        trainedModelGroupId: '',
        trainedModelGroupVersion: {
          displayName: '',
          major: 0,
          minor: 0,
          patch: 0,
        },
        trainedModelId: '',
        userGroupId: '',
      },
      datasetList: [],
      inheritedDatasetList: [],
      setting: {
        settingId: '',
        settingName: '',
      },
      mlPipeline: {
        mlPipelineId: '',
        mlPipelineName: '',
      },
      trainedModelDlLinks: [],
      trainingAlgorithm: {
        algorithmId: '',
        metadata: {
          name: {
            ja: '',
            en: '',
          },
        },
        trainingAlgorithmVersion: '',
      },
      createdAt: undefined,
      createdUserName: {
        firstName: '',
        familyName: '',
      },
    },
  },
  appState: {
    inProgress: false,
    modelDetailState: {
      trainedModelDlLinkSubState: 'BeforeLoading',
      trainedModelDataState: 'BeforeLoading',
    },
    toastInfo: undefined,
    tableNextPageSubState: 'Unable',
  },
}

export const ModelDetailReducer = (
  state: ModelDetail = initialState,
  action: ModelDetailAction
): ModelDetail => {
  switch (action.type) {
    case ModelDetailActionType.SET_CURRENT_MODEL_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentTrainedModelDetail: action.payload.currentTrainedModelDetail,
        },
      }
    case ModelDetailActionType.SET_MODEL_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelDetailState: action.payload.modelDetailState,
        },
      }
    case ModelDetailActionType.SET_IN_PROGRESS_FOR_MODEL_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ModelDetailActionType.CLEAR_MODEL_DETAIL_STATE:
      return {
        ...initialState,
      }
    case ModelDetailActionType.SET_TOAST_INFO_FOR_MODEL_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case ModelDetailActionType.SET_TRAINED_MODEL_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentTrainedModelDetail: {
            ...state.domainData.currentTrainedModelDetail,
            modelListDisplayCondition: action.payload.condition,
          },
        },
      }
    case ModelDetailActionType.SET_TABLE_NEXT_PAGE_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          tableNextPageSubState: action.payload.nextPageSubState,
        },
      }
    default:
      return state
  }
}
