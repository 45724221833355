import { DatasetAugmentationDetailAction } from './actions'
import {
  DatasetAugmentationDetailActionType,
  DatasetAugmentationDetail,
} from './types'

const initialState: DatasetAugmentationDetail = {
  domainData: {
    currentDatasetAugmentationDetail: undefined,
    mlPipelineLogFiles: [],
  },
  appState: {
    datasetAugmentationDetailState: {
      mlPipelineDataState: 'BeforeLoading',
      mlPipelineLogSubState: 'BeforeLoading',
    },
    toastInfo: undefined,
    inProgress: false,
  },
}

export const DatasetAugmentationDetailReducer = (
  state: DatasetAugmentationDetail = initialState,
  action: DatasetAugmentationDetailAction
): DatasetAugmentationDetail => {
  switch (action.type) {
    case DatasetAugmentationDetailActionType.SET_CURRENT_DATASET_AUGMENTATION_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetAugmentationDetail:
            action.payload.currentDatasetAugmentationDetail,
        },
      }
    case DatasetAugmentationDetailActionType.SET_DATASET_AUGMENTATION_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetAugmentationDetailState:
            action.payload.datasetAugmentationDetailState,
        },
      }
    case DatasetAugmentationDetailActionType.SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case DatasetAugmentationDetailActionType.CLEAR_DATASET_AUGMENTATION_DETAIL_STATE:
      return {
        ...initialState,
      }
    case DatasetAugmentationDetailActionType.SET_TOAST_INFO_FOR_DATASET_AUGMENTATION_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case DatasetAugmentationDetailActionType.SET_ML_PIPELINE_LOG_FILES_FOR_DATASET_AUGMENTATION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelineLogFiles: action.payload.mlPipelineLogFiles,
        },
      }
    default:
      return state
  }
}
