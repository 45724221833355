import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { EditableTextFieldProps } from './types'

const useStyles = makeStyles()((theme) => ({
  input: {
    flex: 1,
    fontSize: '1.5rem',
    position: 'absolute',
    top: '18px',
    width: '100%',
  },
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  iconButton: {
    height: '40px',
    padding: theme.spacing(1),
    color: theme.palette.common.black,
  },
}))

export const EditableTextField: React.FC<EditableTextFieldProps> = (
  props: EditableTextFieldProps
) => {
  const { classes } = useStyles()
  const [canEdit, setCanEdit] = useState(false)

  return (
    <>
      {canEdit ? (
        <Box display='flex' alignItems='center' height={'100%'}>
          <Box style={{ position: 'relative', maxWidth: 'calc(100% - 40px)' }}>
            <InputBase
              value={props.value}
              onChange={props.onChange}
              disabled={!canEdit}
              onBlur={() => {
                props.onBlur()
                setCanEdit(false)
              }}
              className={classes.input}
              autoFocus={canEdit}
              inputProps={{ 'data-testid': 'input-name' }}
            />
            <Typography component='div' style={{ opacity: 0 }}>
              <h2 className={classes.text}>{props.value}</h2>
            </Typography>
          </Box>
          <input type='text' style={{ display: 'none' }} />
          <IconButton
            className={classes.iconButton}
            onClick={() => setCanEdit(true)}
          >
            <EditIcon />
          </IconButton>
        </Box>
      ) : props.value === '' ? (
        <Box display='flex' alignItems='center' height={'100%'}>
          <Box
            sx={{ color: 'text.secondary' }}
            style={
              props.disabled
                ? { maxWidth: '100%' }
                : { maxWidth: 'calc(100% - 40px)' }
            }
          >
            <Typography component='div'>
              <h2 className={classes.text}>N/A</h2>
            </Typography>
          </Box>
          {!props.disabled && (
            <IconButton
              className={classes.iconButton}
              onClick={() => setCanEdit(true)}
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      ) : (
        <Box display='flex' alignItems='center' height={'100%'}>
          <Box
            sx={{ color: 'text.primary' }}
            style={
              props.disabled
                ? { maxWidth: '100%' }
                : { maxWidth: 'calc(100% - 40px)' }
            }
          >
            <Typography component='div'>
              <h2 className={classes.text}>{props.value}</h2>
            </Typography>
          </Box>
          {!props.disabled && (
            <IconButton
              data-testid='can-edit-name'
              className={classes.iconButton}
              onClick={() => setCanEdit(true)}
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      )}
    </>
  )
}
