import { Timestamp } from 'state/firebase'

// ActionType
export const DatasetAugmentationActionType = {
  SET_UPLOADED_CAD_FILE: 'SET_UPLOADED_CAD_FILE',
  SET_UPLOADED_CAD_DATA: 'SET_UPLOADED_CAD_DATA',
  SET_UPLOADED_PLACEMENT_DATA: 'SET_UPLOADED_PLACEMENT_DATA',
  SET_TEXTURE_LIST: 'SET_TEXTURE_LIST',
  CLEAR_TEXTURE_LIST: 'CLEAR_TEXTURE_LIST',
  SET_TEXTURE_LIST_DISPLAY_CONDITION: 'SET_TEXTURE_LIST_DISPLAY_CONDITION',
  CLEAR_TEXTURE_LIST_DISPLAY_CONDITION: 'CLEAR_TEXTURE_LIST_DISPLAY_CONDITION',
  SET_PRESET_LIST_DISPLAY_CONDITION: 'SET_PRESET_LIST_DISPLAY_CONDITION',
  SET_PRESET_LIST: 'SET_PRESET_LIST',
  SET_BACKGROUNDS: 'SET_BACKGROUNDS',
  SET_SELECTED_BACKGROUNDS: 'SET_SELECTED_BACKGROUNDS',
  SET_LIGHT_GROUPS: 'SET_LIGHT_GROUPS',
  SET_SELECTED_LIGHT_GROUPS: 'SET_SELECTED_LIGHT_GROUPS',
  SET_CAMERAS: 'SET_CAMERAS',
  SET_SELECTED_CAMERAS: 'SET_SELECTED_CAMERAS',
  CLEAR_PRESET_LIST_DISPLAY_CONDITION: 'CLEAR_PRESET_LIST_DISPLAY_CONDITION',
  SET_SELECTED_PRESET: 'SET_SELECTED_PRESET',
  CLEAR_PRESET_LIST: 'CLEAR_PRESET_LIST',
  SET_LAYOUTS: 'SET_LAYOUTS',
  SET_SELECTED_LAYOUT: 'SET_SELECTED_LAYOUT',
  SET_LAYOUT_SEED: 'SET_LAYOUT_SEED',
  SET_RENDERERS_INPUT: 'SET_RENDERERS_INPUT',
  SET_AUGMENTATION_OUTPUT_FORMAT: 'SET_AUGMENTATION_OUTPUT_FORMAT',
  SET_GENERATE_DATASET_META_DATA: 'SET_GENERATE_DATASET_META_DATA',
  SET_AUGMENTATION_META_DATA: 'SET_AUGMENTATION_META_DATA',
  SET_DATASET_AUGMENTATION_SUB_STATE: 'SET_DATASET_AUGMENTATION_SUB_STATE',
  CLEAR_CURRENT_AUGMENTATION_STATE: 'CLEAR_CURRENT_AUGMENTATION_STATE',
  SET_DATASET_AUGMENTATION_STATE: 'SET_DATASET_AUGMENTATION_STATE',
  SET_TOAST_INFO_FOR_AUGMENTATION: 'SET_TOAST_INFO_FOR_AUGMENTATION',
  SET_IN_PROGRESS_FOR_AUGMENTATION: 'SET_IN_PROGRESS_FOR_AUGMENTATION',
  SET_AUG_SI_OBJECT_ROTATION: 'SET_AUG_SI_OBJECT_ROTATION',
  SET_EXECUTED_INFO_FOR_AUGMENTATION: 'SET_EXECUTED_INFO_FOR_AUGMENTATION',
} as const

export const DatasetAugmentationStateKindArray = [
  '3dDataState',
  'RenderSettingState',
  'PlacementState',
  'MetaDataState',
  'ExecuteState',
]

export type DatasetAugmentationStateKind =
  (typeof DatasetAugmentationStateKindArray)[number]

export interface DatasetAugmentationSubState {
  '3dDataSubState': 'EmptyRequired' | 'InputRequired'
  renderSettingSubState: 'EmptyRequired' | 'InputRequired'
  placementSubState: 'EmptyRequired' | 'InputRequired'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState:
    | 'BeforeExecute'
    | 'ExecuteInProgress'
    | 'Executed'
    | 'ExecuteError'
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface Label {
  id: string
  name: string
  category: string
}

export interface TextureListDisplayCondition {
  displayNumber: number
  pageNumber: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface Texture {
  id: string
  name: string
  overview: string
  userGroupId: string
  userGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface PresetListDisplayCondition {
  displayNumber: number
  pageNumber: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  searchValue: string
}

export interface ScenePartialData {
  id: string
  name: string
  userGroupId: string
}

export interface Preset {
  id: string
  name: string
  overview: string
  backgrounds: ScenePartialData[]
  lightGroups: ScenePartialData[]
  cameras: ScenePartialData[]
  createdAt: Timestamp
}

export interface PresetBackgroundDocument {
  'aug-3d-scene-background-id': string
  'user-group-id': string
}

export interface PresetCameraDocument {
  'aug-3d-scene-camera-id': string
  'user-group-id': string
}

export interface PresetLightGroupsDocument {
  'aug-3d-scene-light-group-id': string
  'user-group-id': string
}

export interface Layout {
  id: string
  name: string
  userGroupId: string
}

export interface RenderersInput {
  augmentationNumber: number
  size: {
    width: number
    height: number
  }
  depth: {
    min: number
    max: number
  }
}

export interface MetaData {
  name?: string
  remarks?: string
}

export type UploadStatus = 'beforeUpload' | 'completed' | 'uploadError'

export interface Uploaded3dData {
  file: File
  uploadStatus: UploadStatus
  aug3dObjectId: string
  label: Label
  texture?: Texture
  userUploadedTexture?: {
    id: string
    uploadStatus: UploadStatus
    file: File
  }
  targetId?: string
}

export interface ExecutionInfo {
  mlPipelineId?: string
  stepId?: string
}

export interface UploadedPlacementData {
  file: File
  augPlacementId: string
  targetIds: string[]
}

export interface Rotation {
  userGroupId: string
  augSiObjectRotationGroupId: string
}

export interface OutputFormat {
  datasetTemplateId: string
  trainValidRatio?: number
}

export interface CurrentDatasetAugmentationDomainData {
  uploaded3dData: Uploaded3dData[]
  textureList: Texture[]
  textureListDisplayCondition: TextureListDisplayCondition
  uploadedPlacementData?: UploadedPlacementData
  presetList: Preset[]
  selectedPreset?: Preset
  backgrounds: ScenePartialData[]
  selectedBackgrounds: ScenePartialData[]
  selectedLightGroups: ScenePartialData[]
  lightGroups: ScenePartialData[]
  cameras: ScenePartialData[]
  selectedCameras: ScenePartialData[]
  layouts: Layout[]
  selectedLayout?: Layout
  layoutSeed: number
  rotation: Rotation
  postProcessing: string
  presetListDisplayCondition: PresetListDisplayCondition
  renderersInput: RenderersInput
  augmentationMetaData?: MetaData
  generateDatasetMetaData?: MetaData
  outputFormat?: OutputFormat
  executionInfo?: ExecutionInfo
}

export interface GenerateRequestFunctionArgsType {
  mlPipelineMetadata: {
    name: string
    remarks: string
  }
  datasetMetadata: {
    name: string
    remarks: string
  }
  targets: {
    ['3dObject']: {
      aug3dObjectId: string
      userGroupId: string
    }
    label: {
      labelId: string
      name: string
      supercategory: string
    }
    texture: {
      augTextureId: string
      userGroupId: string
    }
  }[]
  placement: {
    layouts: {
      augSiObjectLayoutId: string
      userGroupId: string
      siObject: {
        augSiObjectPlacementId: string
        userGroupId: string
        relations: {
          targetId: number
          aug3dObjectId: string
          userGroupId: string
        }[]
      }
      seed: number
      rotation: {
        userGroupId: string
        augSiObjectRotationGroupId: string
      }
    }[]
  }
  ['3dScene']: {
    backgrounds: {
      aug3dSceneBackgroundId: string
      userGroupId: string
    }[]
    lightGroups: {
      aug3dSceneLightGroupId: string
      userGroupId: string
    }[]
    cameras: {
      aug3dSceneCameraId: string
      userGroupId: string
    }[]
  }
  renderers: {
    augmentationNumber: number
    size: {
      width: number
      height: number
    }
    depth: {
      min: number
      max: number
    }
    postProcessing: {
      type: string
    }
  }[]
  dataset: {
    format: {
      algorithmId: string
      datasetTemplateId: string
      annotationFormatId: string
      annotationFormatVersion: {
        displayName: string
        major: number
        minor: number
        patch: number
      }
    }
    extended?: {
      trainValid?: {
        ratio?: number
      }
    }
  }
}

export interface Input3dObject {
  aug3dObjectId: string
  aug3dObjectKind: string
  isSaved: boolean
  fileName: string
}

export interface InputTexture {
  augTextureId: string
  augTextureKind: string
  isSaved: boolean
  name: string
  overview: string
  revision: {
    textureRevision: number
    config: {
      name: string
      type: string
    }
    fileNames: string[]
  }
}

export interface InputPlacement {
  augPlacementId: string
  isSaved: boolean
  name: string
  overview: string
  revision: {
    placementRevision: number
    fileNames: string[]
  }
}

export interface CurrentDatasetAugmentationAppState {
  datasetAugmentationState: DatasetAugmentationStateKind
  datasetAugmentationSubState: DatasetAugmentationSubState
  inProgress: boolean
  toastInfo?: ToastInfo
}

export interface CurrentDatasetAugmentationState {
  domainData: CurrentDatasetAugmentationDomainData
  appState: CurrentDatasetAugmentationAppState
}
