import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { AccountGroupCustomer } from './types'

const timeoutLimitSeconds = 15

export const AccountGroupsApi = {
  updateCustomers: (
    accountGroupId: string,
    customers: AccountGroupCustomer[]
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-group-admin-update-customers',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      accountGroupId,
      customers: customers.map((customer) => ({
        customerId: customer.customerId,
      })),
    }),
}
