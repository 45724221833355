import { Dispatch } from 'redux'
import {
  AuthedUser,
  domainDataActions,
  Algorithm,
  DatasetTemplate,
  AnnotationFormat,
  FeatureDataFormat,
  AnnotationSetKindDocument,
} from 'state/app/domainData'
import {
  getAccountCustomerRelationsCollection,
  getAccountSettingCollection,
  getCustomerCollection,
  getCustomerMetadataCollection,
  getAlgorithmsCollection,
  getAlgorithmTypesCollection,
  getAlgorithmVersionCollection,
  getDatasetTemplatesCollection,
  getAnnotationFormatsCollection,
  getAnnotationFormatsVersionCollection,
  getAccountsCollection,
  getAccountGroupCollection,
  getFeatureDataFormatsCollection,
  getAccountGroupMetadataCollection,
} from 'state/firebase'
import { authInstance } from 'state/firebase'
import { createAlgorithmData } from 'state/utils/algorithms'
import { convertDocumentWithDataCheck as convertDocumentWithDataCheckForAlgorithm } from 'utils/fireStore/algorithm'
import {
  InferenceAlgorithmVersionDocument,
  convertDocumentWithDataCheck as convertDocumentWithDataCheckForInferenceAlgorithmVersion,
} from 'utils/fireStore/inferenceAlgorithmVersion'
import {
  TrainingAlgorithmVersionDocument,
  convertDocumentWithDataCheck as convertDocumentWithDataCheckForTrainingAlgorithmVersion,
} from 'utils/fireStore/trainingAlgorithmVersion'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomersMetadataDocument } from 'utils/fireStore/customersMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountsDocument } from 'utils/fireStore/account'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountCustomerRelationDocument } from 'utils/fireStore/accountCustomerRelation'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupMetadataDocument } from 'utils/fireStore/accountGroupMetadata'
import { isUndefined } from 'utils/typeguard'
import { State } from 'state/store'
import { doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import {
  User,
  IdTokenResult,
  getAuth,
  signOut,
  ParsedToken,
} from 'firebase/auth'
import { LoginOperationsApi } from 'state/ducks/login/apis'
import { appStateActions } from 'state/app/appState'

import { getCustomerList } from 'state/utils/customer'
import { Customer } from 'utils/ducks/customerChange/types'
import { CustomerChangeApi } from 'state/ducks/customerChange/apis'
import { loginActions } from 'state/ducks/login'
import { CustomerRole } from 'views/utils/types'
import { ChangeCustomerDialogState } from 'views/App'
import { ChangeUrlProps } from 'views/containers/pages'

const preLoginOperations = async (
  dispatch: Dispatch,
  idToken: IdTokenResult
) => {
  // 初回ログイン判定
  const accountsDoc = (
    await getDoc(
      doc(
        getAccountsCollection(idToken.claims['account-group-id'] as string),
        idToken.claims['account-id'] as string
      )
    )
  ).data()

  const passwordReset = accountsDoc ? accountsDoc['password-reset'] : false

  if (passwordReset) {
    // パスワード設定画面に遷移させる
    dispatch(loginActions.setLoginState('PasswordUpdate'))
    return true
  }

  const firstLoginTime = accountsDoc
    ? accountsDoc['first-login-time'].seconds
    : 0
  const isFirstLogin = firstLoginTime === 0

  // 初回ログインフラグをセット
  if (isFirstLogin) {
    // アカウントグループ
    const accountsGroupDoc = (
      await getDoc(
        doc(
          getAccountGroupCollection(),
          idToken.claims['account-group-id'] as string
        )
      )
    ).data()

    // アカウントの設定
    const accountSettingDoc = (
      await getDoc(
        doc(
          getAccountSettingCollection(
            idToken.claims['account-group-id'] as string
          ),
          idToken.claims['account-id'] as string
        )
      )
    ).data()

    if (
      (accountsGroupDoc &&
        accountsGroupDoc['mfa-group-setting'] === 'required') ||
      (accountsGroupDoc &&
        accountsGroupDoc['mfa-group-setting'] === 'optional' &&
        accountSettingDoc &&
        !accountSettingDoc['is-mfa'])
    ) {
      // MFA設定画面に遷移させる
      dispatch(loginActions.setLoginState('MfaSetting'))
      return true
    }
  }

  return false
}

/**
 * カスタマー変更確認ダイアログを表示する必要があるかチェックする
 * @param urlCustomerId URLに含まれるカスタマーID
 * @param state ダイアログ表示状態
 * @param claims カスタムクレーム
 * @returns true: ダイアログを表示する false: ダイアログを表示しない
 */
const isConfirmationRequired = async (
  urlCustomerId: string,
  state: ChangeCustomerDialogState,
  claims: ParsedToken
) => {
  // カスタマーリストを取得する
  const customerList = await getCustomerList(
    claims['account-id'] as string,
    claims['account-group-id'] as string,
    claims['super-user'] as boolean
  )

  const privilegedUrlCustomerId = hasPrivilegeOfCustomer(
    urlCustomerId ?? '',
    customerList
  )

  if (!privilegedUrlCustomerId) return false

  const currentCustomerId = await getCustomerId(
    claims['user-group-id'] as string
  )
  if (urlCustomerId !== currentCustomerId) {
    if (state === 'Unselected') return true
    return false
  }
  return false
}

/**
 * 対象のカスタマーIDに参照権限があるか
 */
const hasPrivilegeOfCustomer = (
  customerId: string,
  customerList: Customer[]
): boolean => {
  const customerIndex = customerList.findIndex(
    (customer) => customer.customerId === customerId
  )
  if (customerIndex < 0) {
    // カスタマーへの参照権限が無い場合
    return false
  }

  return true
}

/**
 * ユーザーグループIDに紐づくカスタマーIDを取得する
 * @param userGroupId ユーザーグループID
 * @returns カスタマーID
 */
const getCustomerId = async (userGroupId: string): Promise<string> => {
  const customerDocs = await getDocs(
    query(getCustomerCollection(), where('user-group-id', '==', userGroupId))
  )
  return customerDocs.docs.length > 0
    ? customerDocs.docs[0].data()['customer-id']
    : ''
}

const getCustomerName = async (customerId: string): Promise<string> => {
  const customerMetadataDocData = (
    await getDoc(doc(getCustomerMetadataCollection(), customerId))
  ).data()
  return customerMetadataDocData?.['name'] ?? ''
}

const changeCustomerWithAuth = async (
  uid: string,
  email: string,
  firebaseCurrentUser: User,
  customerList: Customer[],
  customerId: string
): Promise<AuthedUser> => {
  const customerDoc = (
    await getDoc(doc(getCustomerCollection(), customerId))
  ).data()

  const updateParam = {
    ['user-group-id']: customerDoc ? customerDoc['user-group-id'] : '',
  }

  await CustomerChangeApi.updateCustomClaim(uid, updateParam)
  const result = await firebaseCurrentUser.getIdTokenResult(true)
  return {
    mailAddress: email,
    userId: uid,
    customers: customerList,
    auth: {
      token: result.token,
      customClaims: {
        role: result.claims['role'] as string,
        accountId: result.claims['account-id'] as string,
        accountGroupId: result.claims['account-group-id'] as string,
        userGroupId: customerDoc ? customerDoc['user-group-id'] : '',
        sharedList: (result.claims['shared-list'] as string[]) ?? [],
        superUser: result.claims['super-user'] as boolean,
      },
    },
  }
}

const generateAuthedUser = async (
  uid: string,
  email: string,
  firebaseCurrentUser: User,
  claims: ParsedToken,
  changeUrl: (props: ChangeUrlProps) => void,
  isCustomerChanged: boolean | undefined,
  /** URLのパスに含まれているカスタマーID */
  urlCustomerId?: string
): Promise<AuthedUser> => {
  // カスタマーリストを取得する
  const customerList = await getCustomerList(
    claims['account-id'] as string,
    claims['account-group-id'] as string,
    claims['super-user'] as boolean
  )
  const currentCustomerId = await getCustomerId(
    claims['user-group-id'] as string
  )

  // 参照するカスタマーID（デフォルトはClaimに含まれるカスタマーID）
  let updateCustomerId = currentCustomerId

  const privilegedUrlCustomerId = hasPrivilegeOfCustomer(
    urlCustomerId ?? '',
    customerList
  )

  if (urlCustomerId && privilegedUrlCustomerId) {
    // URLにカスタマーIDが含まれる場合。
    // かつ、URLに含まれるカスタマーIDの参照権限がある場合。
    // ※Claimに含まれるカスタマーIDと同じ値の可能がある
    if (isCustomerChanged) {
      updateCustomerId = urlCustomerId
    }
  } else {
    // URLにカスタマーIDが含まれていない場合。
    // または、URLに含まれるカスタマーIDの参照権限がない場合。
    const privilegedCurrentCustomerId = hasPrivilegeOfCustomer(
      currentCustomerId,
      customerList
    )
    if (!privilegedCurrentCustomerId) {
      // Claimに含まれるカスタマーIDに権限がない場合
      updateCustomerId = customerList[0].customerId
    }
  }

  let updatedAuthedUser: AuthedUser | undefined = undefined

  // Claimの更新
  if (currentCustomerId !== updateCustomerId) {
    // Claimに含まれるカスタマーIDとURLのカスタマーIDが違う場合
    updatedAuthedUser = await changeCustomerWithAuth(
      uid,
      email,
      firebaseCurrentUser,
      customerList,
      updateCustomerId
    )
  }

  // URLにカスタマーIDの付与
  if (!urlCustomerId || currentCustomerId !== updateCustomerId) {
    // URLのカスタマーIDが含まれていない場合、または、Claimに含まれるカスタマーIDとURLのカスタマーIDが違う場合
    changeUrl({
      customerId: updateCustomerId,
      isInsertCustomerId: !urlCustomerId ? true : false,
    })
  }

  // カスタマーを変更しない場合、claimsに含まれるユーザーグループIDのカスタマーにリダイレクトする
  if (isCustomerChanged === false) {
    changeUrl({
      customerId: updateCustomerId,
      isInsertCustomerId: false,
      isTransitionHome: true,
    })
  }

  const authedUser: AuthedUser = {
    mailAddress: email,
    userId: uid,
    customers: customerList,
    auth: {
      token: await firebaseCurrentUser.getIdToken(),
      customClaims: {
        role: claims['role'] as string,
        accountId: claims['account-id'] as string,
        accountGroupId: claims['account-group-id'] as string,
        userGroupId: claims['user-group-id'] as string,
        sharedList: (claims['shared-list'] as string[]) ?? [],
        superUser: claims['super-user'] as boolean,
      },
    },
    ...updatedAuthedUser,
  }

  return authedUser
}

/**
 * ユーザーが1件以上カスタマーと紐づいているか取得する
 * @returns `true`の場合、1件以上カスタマーが紐づいている
 */
const hasUserCustomerList = async (claims: ParsedToken) => {
  const accountCustomerRelationDoc = (
    await getDoc(
      doc(
        getAccountCustomerRelationsCollection(
          claims['account-group-id'] as string
        ),
        claims['account-id'] as string
      )
    )
  ).data()
  if (
    !fireStoreTypeGuardForAccountCustomerRelationDocument(
      accountCustomerRelationDoc
    )
  ) {
    return false
  }

  return (
    accountCustomerRelationDoc?.['customer-list'] != null &&
    accountCustomerRelationDoc?.['customer-list'].length > 0
  )
}

export const domainDataOperations = {
  getDomainAlgorithmList:
    () =>
    async (dispatch: Dispatch): Promise<void> => {
      // 学習アルゴリズム一覧と学習アルゴリズムメタデータの一覧を取得して整形
      try {
        // 学習アルゴリズム一覧取得
        // algorithm一覧取得
        const algorithmListDocs = await getDocs(
          query(getAlgorithmsCollection())
        )
        const algorithmList = algorithmListDocs.docs.map((item) => item.data())

        // 作成する学習アルゴリズムオブジェクト
        const algorithms: (Algorithm | undefined)[] = await Promise.all(
          algorithmList.map(async (item) => {
            const inferenceAlgorithm = (
              await getDoc(
                doc(
                  getAlgorithmTypesCollection(item['algorithm-id']),
                  'inference'
                )
              )
            ).data()

            const trainingAlgorithm = (
              await getDoc(
                doc(
                  getAlgorithmTypesCollection(item['algorithm-id']),
                  'training'
                )
              )
            ).data()

            const inferenceAlgorithmVersions = await getDocs(
              query(
                getAlgorithmVersionCollection(item['algorithm-id'], 'inference')
              )
            )

            const trainingAlgorithmVersions = await getDocs(
              query(
                getAlgorithmVersionCollection(item['algorithm-id'], 'training')
              )
            )

            // 推論バージョンの配列
            const allInferenceAlgorithmVersionsConvert =
              inferenceAlgorithmVersions.docs.map((doc) => doc.data())
            const inferenceVersionData: InferenceAlgorithmVersionDocument[] = []
            const trainingVersionData: TrainingAlgorithmVersionDocument[] = []

            allInferenceAlgorithmVersionsConvert.forEach((version) => {
              const data =
                convertDocumentWithDataCheckForInferenceAlgorithmVersion(
                  version
                )

              if (!isUndefined(data)) {
                inferenceVersionData.push(data)
              }
            })

            // 学習バージョンの配列
            const allTrainingAlgorithmVersionsConvert =
              trainingAlgorithmVersions.docs.map((doc) => doc.data())

            allTrainingAlgorithmVersionsConvert.forEach((version) => {
              const data =
                convertDocumentWithDataCheckForTrainingAlgorithmVersion(version)

              if (!isUndefined(data)) {
                trainingVersionData.push(data)
              }
            })

            const algorithmDocumentData =
              convertDocumentWithDataCheckForAlgorithm(item)

            if (!isUndefined(algorithmDocumentData)) {
              return await createAlgorithmData(
                algorithmDocumentData,
                inferenceAlgorithm
                  ? inferenceAlgorithm['inference-code-id']
                  : '',
                trainingAlgorithm ? trainingAlgorithm['inference-code-id'] : '',
                inferenceVersionData,
                trainingVersionData
              )
            }
            return undefined
          })
        )
        dispatch(
          domainDataActions.setAlgorithms(
            algorithms.filter(
              (algorithm) => !isUndefined(algorithm)
            ) as Algorithm[]
          )
        )
      } catch (error) {
        console.error(error)
      }
    },
  getDomainDatasetTemplateList:
    () =>
    async (dispatch: Dispatch): Promise<void> => {
      // データセットテンプレート一覧とデータセットテンプレートメタデータの一覧を取得して整形
      try {
        // データセットテンプレート一覧取得
        // dataset-template一覧取得
        const datasetTemplateListDocs = await getDocs(
          query(getDatasetTemplatesCollection())
        )
        const datasetTemplateList = datasetTemplateListDocs.docs.map((item) =>
          item.data()
        )

        // 作成するデータセットテンプレートオブジェクト
        const datasetTemplates: DatasetTemplate[] = []
        await Promise.all(
          datasetTemplateList.map(async (datasetTemplateItem) => {
            const datasetTemplate = {
              datasetTemplateId: datasetTemplateItem['dataset-template-id'],
              algorithmId: datasetTemplateItem['algorithm-id'],
              annotationSetKindList: datasetTemplateItem[
                'annotation-set-kind-list'
              ].map((annotationSetKindItem: AnnotationSetKindDocument) => {
                return {
                  annotationSetKind:
                    annotationSetKindItem['annotation-set-kind'],
                  conditions:
                    annotationSetKindItem['conditions'] &&
                    annotationSetKindItem['conditions']['train-kind']
                      ? {
                          trainKind:
                            annotationSetKindItem['conditions']['train-kind'],
                        }
                      : undefined,
                  isUserInput:
                    annotationSetKindItem['is-user-input'] !== undefined
                      ? annotationSetKindItem['is-user-input']
                      : true,
                  name: annotationSetKindItem['name'],
                }
              }),
              metadata: datasetTemplateItem['metadata'],
              createdAt: datasetTemplateItem['created-at'],
              createdBy: datasetTemplateItem['created-by'],
              updatedAt: datasetTemplateItem['updated-at'],
              updatedBy: datasetTemplateItem['updated-by'],
            }
            datasetTemplates.push(datasetTemplate)
          })
        )
        dispatch(domainDataActions.setDatasetTemplates(datasetTemplates))
        const annotationFormatListDocs = await getDocs(
          query(getAnnotationFormatsCollection())
        )
        const annotationFormats: AnnotationFormat[] = []
        await Promise.all(
          annotationFormatListDocs.docs.map(async (item) => {
            const versions = (
              await getDocs(
                query(getAnnotationFormatsVersionCollection(item.id))
              )
            ).docs.map((version) => {
              const versionData = version.data()
              return {
                annotationFormatId: versionData['annotation-format-id'],
                annotationFormatVersion: {
                  displayName:
                    versionData['annotation-format-version']['display-name'],
                  major: parseInt(
                    versionData['annotation-format-version']['major']
                  ),
                  minor: parseInt(
                    versionData['annotation-format-version']['minor']
                  ),
                  patch: parseInt(
                    versionData['annotation-format-version']['patch']
                  ),
                },
                availableVersion: {
                  lowerLimit: {
                    displayName:
                      versionData['available-version']['lower-limit'][
                        'display-name'
                      ],
                    major: parseInt(
                      versionData['available-version']['lower-limit']['major']
                    ),
                    minor: parseInt(
                      versionData['available-version']['lower-limit']['minor']
                    ),
                    patch: parseInt(
                      versionData['available-version']['lower-limit']['patch']
                    ),
                  },
                  upperLimit: {
                    displayName:
                      versionData['available-version']['upper-limit'][
                        'display-name'
                      ],
                    major: parseInt(
                      versionData['available-version']['upper-limit']['major']
                    ),
                    minor: parseInt(
                      versionData['available-version']['upper-limit']['minor']
                    ),
                    patch: parseInt(
                      versionData['available-version']['upper-limit']['patch']
                    ),
                  },
                },
              }
            })
            const annotationFormatItem = item.data()
            const annotationFormat: AnnotationFormat = {
              annotationFormatId: annotationFormatItem['annotation-format-id'],
              annotationFormatKind:
                annotationFormatItem['annotation-format-kind'],
              annotationFormatVersions: versions,
            }
            annotationFormats.push(annotationFormat)
          })
        )
        dispatch(domainDataActions.setAnnotationFormats(annotationFormats))
      } catch (error) {
        console.error(error)
      }
    },
  extractDomainDatasetTemplateList:
    (annotationSetKindCondition: string[][]) =>
    (_: Dispatch, getState: () => State): DatasetTemplate[] => {
      const datasetTemplates = getState().app.domainData.datasetTemplates

      // 条件（annotationSetKindCondition）に一致した datasetTemplate の配列を返却
      // ex.) annotationSetKindCondition が [['All'], ['Train', 'Valid']] の場合
      // datasetTemplate.annotationSetKindList に annotationSetKind = 'All' のみのもの。と
      // datasetTemplate.annotationSetKindList に annotationSetKind = 'Train' / 'Valid' の
      // datasetTemplate を返却
      const matchDatasetTemplate: DatasetTemplate[] = []

      datasetTemplates.forEach((datasetTemplate) => {
        annotationSetKindCondition.forEach((condition) => {
          if (
            datasetTemplate.annotationSetKindList.length === condition.length
          ) {
            const annotationSetKinds =
              datasetTemplate.annotationSetKindList.map(
                (annotationSetKind) => annotationSetKind.annotationSetKind
              )
            if (
              condition.sort().toString() ===
              annotationSetKinds.sort().toString()
            ) {
              matchDatasetTemplate.push(datasetTemplate)
            }
          }
        })
      })

      return matchDatasetTemplate
    },
  getDomainFeatureDataFormatList:
    () =>
    async (dispatch: Dispatch): Promise<void> => {
      // 特徴量データのフォーマットを取得
      try {
        // 特徴量データのフォーマット一覧取得
        // dataset-template一覧取得
        const featureDataFormatListDocs = await getDocs(
          query(getFeatureDataFormatsCollection())
        )
        const featureDataFormatList = featureDataFormatListDocs.docs.map(
          (item) => item.data()
        )

        // 作成する特徴量データフォーマットのオブジェクト
        const featureDataFormats: FeatureDataFormat[] =
          featureDataFormatList.map((featureDataFormatItem) => {
            return {
              featureDataFormatId:
                featureDataFormatItem['feature-data-format-id'],
              featureDataFormatKind:
                featureDataFormatItem['feature-data-format-kind'],
              fileType: featureDataFormatItem['file-type'],
            }
          })

        dispatch(domainDataActions.setFeatureDataFormats(featureDataFormats))
      } catch (error) {
        console.error(error)
      }
    },
  getUserInfo:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const firebaseCurrentUser: User | null = authInstance.currentUser
        if (firebaseCurrentUser) {
          const accountId =
            getState().app.domainData.authedUser.auth.customClaims.accountId
          const accountGroupId =
            getState().app.domainData.authedUser.auth.customClaims
              .accountGroupId
          const userGroupId =
            getState().app.domainData.authedUser.auth.customClaims.userGroupId
          const isSuperUser =
            getState().app.domainData.authedUser.auth.customClaims.superUser

          const accountsDoc = (
            await getDoc(doc(getAccountsCollection(accountGroupId), accountId))
          ).data()
          if (!fireStoreTypeGuardForAccountsDocument(accountsDoc)) {
            return
          }

          const accountSettingDoc = (
            await getDoc(
              doc(getAccountSettingCollection(accountGroupId), accountId)
            )
          ).data()
          if (!fireStoreTypeGuardForAccountSettingDocument(accountSettingDoc)) {
            return
          }

          const accountGroupMetadataDoc = (
            await getDoc(
              doc(getAccountGroupMetadataCollection(), accountGroupId)
            )
          ).data()
          if (
            !fireStoreTypeGuardForAccountGroupMetadataDocument(
              accountGroupMetadataDoc
            )
          ) {
            return
          }

          const accountCustomerRelationDoc = (
            await getDoc(
              doc(
                getAccountCustomerRelationsCollection(accountGroupId),
                accountId
              )
            )
          ).data()
          if (
            !fireStoreTypeGuardForAccountCustomerRelationDocument(
              accountCustomerRelationDoc
            )
          ) {
            return
          }
          const customerDoc = await getDocs(
            query(
              getCustomerCollection(),
              where('user-group-id', '==', userGroupId)
            )
          )

          const customerMetadataDoc = (
            await getDoc(
              doc(getCustomerMetadataCollection(), customerDoc.docs[0].id)
            )
          ).data()

          if (
            !fireStoreTypeGuardForCustomersMetadataDocument(customerMetadataDoc)
          ) {
            return
          }

          const customerId = customerMetadataDoc
            ? customerMetadataDoc['customer-id']
            : ''
          let role: CustomerRole = 'user'
          if (isSuperUser) {
            role = 'admin'
          } else {
            role =
              accountCustomerRelationDoc != null
                ? (accountCustomerRelationDoc['customer-list'].find(
                    (item: { 'customer-id': string; role: string }) =>
                      item['customer-id'] === customerId
                  )?.role as CustomerRole) ?? 'user'
                : 'user'
          }

          dispatch(
            domainDataActions.setUserProfile({
              firstName: accountSettingDoc
                ? accountSettingDoc['first-name']
                  ? accountSettingDoc['first-name']
                  : ''
                : '',
              familyName: accountSettingDoc
                ? accountSettingDoc['family-name']
                  ? accountSettingDoc['family-name']
                  : ''
                : '',
              accountGroupName: accountGroupMetadataDoc?.['name'] ?? '',
              language: accountSettingDoc ? accountSettingDoc['language'] : '',
              // TODO 今は固定
              locate: 'JP',
              role,
              customerId,
              customerName: customerMetadataDoc
                ? customerMetadataDoc['name']
                : '',
              accountGroupRole: accountsDoc
                ? accountsDoc['account-group-role']
                : '',
            })
          )
        } else {
          console.error('ログイン中のユーザーがいない')
        }
      } catch (error) {
        console.error(error)
      }
    },
  certified:
    (
      contextUid: string,
      changeUrl: (props: ChangeUrlProps) => void,
      changeCustomerDialog: {
        state: ChangeCustomerDialogState
        actions: {
          setCurrentCustomerName: (customerName: string) => void
          setNextCustomerName: (customerName: string) => void
        }
      },
      nextCustomerId?: string
    ) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(domainDataActions.setInProgress(true))
        const firebaseCurrentUser: User | null = authInstance.currentUser
        const auth = getAuth()
        if (firebaseCurrentUser) {
          await LoginOperationsApi.updateIsLastLoginStatus()
          const email = firebaseCurrentUser.email
          const uid = firebaseCurrentUser.uid

          if (email && uid && contextUid === uid) {
            const result = await firebaseCurrentUser.getIdTokenResult()

            if (!(await hasUserCustomerList(result.claims))) {
              dispatch(loginActions.setLoginState('NoCustomerList'))
              await authInstance.signOut()
              return
            }

            // パスワード変更画面 or MFA設定画面に遷移必要かチェックし、必要であれば遷移する
            const isNeedPasswordOrMfaSetting = await preLoginOperations(
              dispatch,
              result
            )
            if (isNeedPasswordOrMfaSetting) return

            let isDisplayDialog = false
            if (nextCustomerId) {
              isDisplayDialog = await isConfirmationRequired(
                nextCustomerId,
                changeCustomerDialog.state,
                result.claims
              )
            }
            if (isDisplayDialog) {
              const currentCustomerId = await getCustomerId(
                result.claims['user-group-id'] as string
              )
              const currentCustomerName = await getCustomerName(
                currentCustomerId ?? ''
              )
              const nextCustomerName = await getCustomerName(
                nextCustomerId ?? ''
              )
              changeCustomerDialog.actions.setCurrentCustomerName(
                currentCustomerName
              )
              changeCustomerDialog.actions.setNextCustomerName(nextCustomerName)
              return
            }
            let isCustomerChanged = undefined
            if (changeCustomerDialog.state === 'Change') {
              isCustomerChanged = true
            } else if (changeCustomerDialog.state === 'Unchange') {
              isCustomerChanged = false
            }

            const authedUser = await generateAuthedUser(
              uid,
              email,
              firebaseCurrentUser,
              result.claims,
              changeUrl,
              isCustomerChanged,
              nextCustomerId
            )

            await CustomerChangeApi.updateLastAccessed(
              await getCustomerId(authedUser.auth.customClaims.userGroupId)
            )

            dispatch(loginActions.setLoginState('Loggedin'))
            dispatch(appStateActions.setAuthed(true))
            dispatch(domainDataActions.setAuthedUser(authedUser))
          } else {
            // 認証失敗
            signOut(auth)
          }
        } else {
          // 認証失敗
          signOut(auth)
        }
      } catch (error) {
        // エラー発生
        const auth = getAuth()
        signOut(auth)
        console.error(error)
      } finally {
        dispatch(domainDataActions.setInProgress(false))
      }
    },
  getSharedUserGroupId:
    () =>
    (_: Dispatch, getState: () => State): string => {
      const sharedList =
        getState().app.domainData.authedUser.auth.customClaims.sharedList
      return sharedList.length > 0
        ? sharedList[0]
        : getState().app.domainData.authedUser.auth.customClaims.userGroupId
    },
  hasSharedUserGroup:
    () =>
    (_: Dispatch, getState: () => State): boolean => {
      const sharedList =
        getState().app.domainData.authedUser.auth.customClaims.sharedList
      return sharedList.length > 0
    },
}
