import { FeatureDataListActions } from './actions'
import { FeatureDataInfoList, FeatureDataListActionType } from './types'

const initialState: FeatureDataInfoList = {
  domainData: {
    featureDataGroupId: '',
    featureDataGroupName: '',
    currentFeatureDataList: [],
    featureDataListDisplayCondition: {
      searchValue: '',
      sortKey: 'feature-data-group-version',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    inProgress: false,
    featureDataGroupSubState: 'BeforeLoading',
  },
}

export const FeatureDataListReducer = (
  state: FeatureDataInfoList = initialState,
  action: FeatureDataListActions
): FeatureDataInfoList => {
  switch (action.type) {
    case FeatureDataListActionType.SET_FEATURE_DATA_GROUP_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupId: action.payload.featureDataGroupId,
        },
      }
    case FeatureDataListActionType.SET_FEATURE_DATA_GROUP_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupName: action.payload.featureDataGroupName,
        },
      }
    case FeatureDataListActionType.SET_FEATURE_DATA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataList: action.payload.featureDataList,
        },
      }
    case FeatureDataListActionType.CLEAR_FEATURE_DATA_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataList: [],
        },
      }
    case FeatureDataListActionType.SET_FEATURE_DATA_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataListDisplayCondition:
            action.payload.featureDataListDisplayCondition,
        },
      }
    case FeatureDataListActionType.SET_FEATURE_DATA_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGroupSubState: action.payload.featureDataGroupSubState,
        },
      }
    case FeatureDataListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataListActionType.CLEAR_FEATURE_DATA_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
