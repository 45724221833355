import { AlgorithmStructureVersion } from 'state/app/domainData'
import {
  ToastInfo,
  CreateAction,
  ActionsUnion,
  Action,
  ActionWithPayload,
} from 'state/utils'
import {
  FeatureDataUploadActionType,
  AlgorithmDisplayCondition,
  MetaData,
  FeatureDataUploadStateKind,
  FileAndProgress,
  FeatureDataGroupListDisplayCondition,
  FeatureDataGroup,
  TrainingAlgorithmVersion,
} from './types'

export type FeatureDataUploadAction = ActionsUnion<
  typeof featureDataUploadActions
>

export const featureDataUploadActions = {
  setAlgorithmDisplayCondition: (
    algorithmDisplayCondition?: AlgorithmDisplayCondition
  ): ActionWithPayload<
    'SET_ALGORITHM_DISPLAY_CONDITION',
    { algorithmDisplayCondition?: AlgorithmDisplayCondition }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_ALGORITHM_DISPLAY_CONDITION, {
      algorithmDisplayCondition,
    }),
  setFeatureDataGroupListDisplayCondition: (
    featureDataGroupListDisplayCondition?: FeatureDataGroupListDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD',
    {
      featureDataGroupListDisplayCondition?: FeatureDataGroupListDisplayCondition
    }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD,
      {
        featureDataGroupListDisplayCondition,
      }
    ),
  setSelectedFeatureDataGroup: (
    selectedFeatureDataGroup?: FeatureDataGroup
  ): ActionWithPayload<
    'SET_SELECTED_FEATURE_DATA_GROUP',
    { selectedFeatureDataGroup?: FeatureDataGroup }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_SELECTED_FEATURE_DATA_GROUP, {
      selectedFeatureDataGroup,
    }),
  setFeatureDataGroupList: (
    featureDataGroupList: FeatureDataGroup[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_UPLOAD',
    { featureDataGroupList: FeatureDataGroup[] }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_UPLOAD,
      {
        featureDataGroupList,
      }
    ),
  clearFeatureDataGroupDisplayCondition:
    (): Action<'CLEAR_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD'> =>
      CreateAction(
        FeatureDataUploadActionType.CLEAR_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD
      ),
  setFeatureDataGroupSubState: (
    featureDataGroupSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_SUB_STATE',
    { featureDataGroupSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_FEATURE_DATA_GROUP_SUB_STATE, {
      featureDataGroupSubState,
    }),
  setSelectedAlgorithmId: (
    algorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_ID_FOR_FEATURE_DATA_UPLOAD',
    { algorithmId: string }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_SELECTED_ALGORITHM_ID_FOR_FEATURE_DATA_UPLOAD,
      {
        algorithmId,
      }
    ),
  setSelectedTrainingAlgorithmVersion: (
    selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_UPLOAD',
    { selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_UPLOAD,
      {
        selectedTrainingAlgorithmVersion,
      }
    ),
  setSelectedTrainingAlgorithmStructureVersion: (
    selectedTrainingAlgorithmStructureVersion?: AlgorithmStructureVersion
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_UPLOAD',
    { selectedTrainingAlgorithmStructureVersion?: AlgorithmStructureVersion }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_UPLOAD,
      {
        selectedTrainingAlgorithmStructureVersion,
      }
    ),
  setFeatureDataMetaData: (
    featureDataMetaData?: MetaData
  ): ActionWithPayload<
    'SET_FEATURE_DATA_META_DATA',
    { featureDataMetaData?: MetaData }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_FEATURE_DATA_META_DATA, {
      featureDataMetaData,
    }),
  setSelectedFeatureDataVersion: (
    selectedVersion?: string
  ): ActionWithPayload<
    'SET_SELECTED_FEATURE_DATA_VERSION',
    { selectedVersion?: string }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_SELECTED_FEATURE_DATA_VERSION,
      {
        selectedVersion,
      }
    ),
  setTrainingAlgorithmSubState: (
    trainingAlgorithmSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_ALGORITHM_SUB_STATE',
    { trainingAlgorithmSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_ALGORITHM_SUB_STATE, {
      trainingAlgorithmSubState,
    }),
  setFeatureDataUploadSubState: (
    featureDataUploadSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_FEATURE_DATA_UPLOAD_SUB_STATE',
    { featureDataUploadSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_FEATURE_DATA_UPLOAD_SUB_STATE,
      {
        featureDataUploadSubState,
      }
    ),
  setMetaDataSubState: (
    metaDataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_FEATURE_DATA_META_DATA_SUB_STATE',
    { metaDataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_FEATURE_DATA_META_DATA_SUB_STATE,
      {
        metaDataSubState,
      }
    ),
  setFeatureDataUploadState: (
    featureDataUploadStateKind: FeatureDataUploadStateKind
  ): ActionWithPayload<
    'SET_SEND_FEATURE_DATA_STATE',
    { featureDataUploadStateKind: FeatureDataUploadStateKind }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_SEND_FEATURE_DATA_STATE, {
      featureDataUploadStateKind,
    }),
  clearFeatureDataUploadState: (): Action<'CLEAR_FEATURE_DATA_UPLOAD_STATE'> =>
    CreateAction(FeatureDataUploadActionType.CLEAR_FEATURE_DATA_UPLOAD_STATE),
  executeFeatureDataUploadSuccess:
    (): Action<'EXEC_FEATURE_DATA_SEND_SUCCESS'> =>
      CreateAction(FeatureDataUploadActionType.EXEC_FEATURE_DATA_SEND_SUCCESS),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_UPLOAD',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_UPLOAD,
      {
        inProgress,
      }
    ),
  setExecutedFeatureDataId: (
    executedFeatureDataId?: string
  ): ActionWithPayload<
    'SET_EXECUTED_FEATURE_DATA_ID',
    { executedFeatureDataId?: string }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_EXECUTED_FEATURE_DATA_ID, {
      executedFeatureDataId,
    }),
  addInputFiles: (
    files: FileAndProgress[]
  ): ActionWithPayload<
    'ADD_IMAGE_SET_FILE_FOR_FEATURE_DATA_UPLOAD',
    { files: FileAndProgress[] }
  > =>
    CreateAction(
      FeatureDataUploadActionType.ADD_IMAGE_SET_FILE_FOR_FEATURE_DATA_UPLOAD,
      {
        files,
      }
    ),
  executeFeatureDataUploadFailure:
    (): Action<'EXEC_FEATURE_DATA_SEND_FAILURE'> =>
      CreateAction(FeatureDataUploadActionType.EXEC_FEATURE_DATA_SEND_FAILURE),
  setFileUploadResult: (
    status: 'beforeUpload' | 'failed' | 'success'
  ): ActionWithPayload<
    'SET_FILE_UPLOAD_RESULT',
    { status: 'beforeUpload' | 'failed' | 'success' }
  > =>
    CreateAction(FeatureDataUploadActionType.SET_FILE_UPLOAD_RESULT, {
      status,
    }),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_FEATURE_DATA_UPLOAD',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      FeatureDataUploadActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_UPLOAD,
      {
        toastInfo,
      }
    ),
}
