import { TrainingData } from 'state/utils/types'

export const CreateImageSetActionType = {
  ADD_ANNOTATION_SET: 'ADD_ANNOTATION_SET',
  ADD_IMAGE_SET_FILE: 'ADD_IMAGE_SET_FILE',
  CREATE_IMAGE_SET: 'CREATE_IMAGE_SET',
  SET_IMAGE_SET_METADATA_NAME: 'SET_IMAGE_SET_METADATA_NAME',
  SET_IMAGE_SET_METADATA_REMARKS: 'SET_IMAGE_SET_METADATA_REMARKS',
  SET_IMAGE_SET_STATE: 'SET_IMAGE_SET_STATE',
  CLEAR_IMAGE_SET_STATE: 'CLEAR_IMAGE_SET_STATE',
  ADD_IMAGE_FILE: 'ADD_IMAGE_FILE',
  SET_GROUPED_DATA_ID: 'SET_GROUPED_DATA_ID',
  SET_IN_PROGRESS_FOR_CREATE_IMAGE_SET: 'SET_IN_PROGRESS_FOR_CREATE_IMAGE_SET',
  SET_SELECTED_ALGORITHM_ID_FOR_CREATE_IMAGE_SET:
    'SET_SELECTED_ALGORITHM_ID_FOR_CREATE_IMAGE_SET',
  SET_ALGORITHM_SUB_STATE_FOR_CREATE_IMAGE_SET:
    'SET_ALGORITHM_SUB_STATE_FOR_CREATE_IMAGE_SET',
} as const

export type UploadType = 'Uploading' | 'Completed' | 'UploadError'
export type InputRequireType = 'EmptyRequired' | 'InputRequired'

export interface ImageSetMetadata {
  name?: string
  remarks?: string
}

export const ImageSetStateKindArray = [
  'AlgorithmState',
  'InputFileState',
  'MetadataState',
  'UploadState',
]

export type ImageSetState = (typeof ImageSetStateKindArray)[number]

export interface ImageSetSubState {
  algorithmSubState: 'Unselected' | 'Selected'
  imageSetFileState: UploadType
  metadataSubState: InputRequireType
  uploadSubState: 'BeforeUpload' | UploadType | 'DocumentError'
}

export interface ImageSet {
  files: File[]
  imageSetMetaData: ImageSetMetadata
}

export interface AnnotationSet {
  annotationSetId: string
  groupedData: {
    trainingDataList: TrainingData[]
  }
}

export interface CurrentCreateImageSetDomainData {
  imageSetFiles: File[] // インプットされた画像
  imageSet: ImageSet // 登録する画像セット
  imageSetMetaData: ImageSetMetadata // 画像セットのメタデータ
  annotationSet: AnnotationSet // アップロードするアノテーション
  groupedDataId?: string // groupedDataId
  selectedAlgorithm?: string // 選択したアルゴリズム
}

export interface CurrentCreateImageSetAppState {
  inProgress: boolean // 画像セット作成中フラグ
  imageSetState: ImageSetState // 画像セットの画面遷移状態
  imageSetSubState: ImageSetSubState // 各画面で入力状態
}

export interface CurrentCreateImageSetState {
  domainData: CurrentCreateImageSetDomainData
  appState: CurrentCreateImageSetAppState
}
