import {
  CreateAction,
  ActionsUnion,
  Action,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  BuildEntryActionType,
  MetaData,
  TrainedModelGroupListDisplayCondition,
  TrainedModelGroupInfo,
  BuildEntryStateKind,
  TrainedModelInfo,
  TrainedModelListDisplayCondition,
  InferenceAlgorithmVersion,
  ExecutionInfo,
  TrainedModelGroupLists,
} from './types'

export type BuildEntryAction = ActionsUnion<typeof buildEntryActions>

export const buildEntryActions = {
  setTrainedModelGroupListDisplayCondition: (
    trainedModelGroupListDisplayCondition?: TrainedModelGroupListDisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY',
    {
      trainedModelGroupListDisplayCondition?: TrainedModelGroupListDisplayCondition & {
        selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
      }
    }
  > =>
    CreateAction(
      BuildEntryActionType.SET_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY,
      {
        trainedModelGroupListDisplayCondition,
      }
    ),
  setTrainedModelListDisplayCondition: (
    trainedModelListDisplayCondition?: TrainedModelListDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY',
    {
      trainedModelListDisplayCondition?: TrainedModelListDisplayCondition
    }
  > =>
    CreateAction(
      BuildEntryActionType.SET_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY,
      {
        trainedModelListDisplayCondition,
      }
    ),
  setSelectedTrainedModelGroup: (
    selectedTrainedModelGroup?: TrainedModelGroupInfo
  ): ActionWithPayload<
    'SET_SELECTED_TRAINED_MODEL_GROUP_FOR_BUILD_ENTRY',
    { selectedTrainedModelGroup?: TrainedModelGroupInfo }
  > =>
    CreateAction(
      BuildEntryActionType.SET_SELECTED_TRAINED_MODEL_GROUP_FOR_BUILD_ENTRY,
      {
        selectedTrainedModelGroup,
      }
    ),
  setSelectedTrainedModel: (
    selectedTrainedModel?: TrainedModelInfo
  ): ActionWithPayload<
    'SET_SELECTED_TRAINED_MODEL_FOR_BUILD_ENTRY',
    { selectedTrainedModel?: TrainedModelInfo }
  > =>
    CreateAction(
      BuildEntryActionType.SET_SELECTED_TRAINED_MODEL_FOR_BUILD_ENTRY,
      {
        selectedTrainedModel,
      }
    ),
  setTrainedModelGroupList: (
    trainedModelGroupList: TrainedModelGroupLists
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_GROUP_LIST_FOR_BUILD_ENTRY',
    { trainedModelGroupList: TrainedModelGroupLists }
  > =>
    CreateAction(
      BuildEntryActionType.SET_TRAINED_MODEL_GROUP_LIST_FOR_BUILD_ENTRY,
      {
        trainedModelGroupList,
      }
    ),
  setInferenceAlgorithmVersions: (
    inferenceAlgorithmVersions: InferenceAlgorithmVersion[]
  ): ActionWithPayload<
    'SET_INFERENCE_ALGORITHM_VERSIONS_FOR_BUILD_ENTRY',
    {
      inferenceAlgorithmVersions: InferenceAlgorithmVersion[]
    }
  > =>
    CreateAction(
      BuildEntryActionType.SET_INFERENCE_ALGORITHM_VERSIONS_FOR_BUILD_ENTRY,
      {
        inferenceAlgorithmVersions,
      }
    ),
  clearModelGroupDisplayCondition:
    (): Action<'CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY'> =>
      CreateAction(
        BuildEntryActionType.CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY
      ),
  clearTrainedModelDisplayCondition:
    (): Action<'CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY'> =>
      CreateAction(
        BuildEntryActionType.CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY
      ),
  setTrainedModelGroupSubState: (
    trainedModelGroupSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_GROUP_SUB_STATE',
    { trainedModelGroupSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(BuildEntryActionType.SET_TRAINED_MODEL_GROUP_SUB_STATE, {
      trainedModelGroupSubState,
    }),
  setTrainedModelSubState: (
    trainedModelSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_SUB_STATE',
    { trainedModelSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(BuildEntryActionType.SET_TRAINED_MODEL_SUB_STATE, {
      trainedModelSubState,
    }),
  setSelectedAlgorithmId: (
    selectedAlgorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_ID_FOR_BUILD_ENTRY',
    { selectedAlgorithmId: string }
  > =>
    CreateAction(
      BuildEntryActionType.SET_SELECTED_ALGORITHM_ID_FOR_BUILD_ENTRY,
      {
        selectedAlgorithmId,
      }
    ),
  setSelectedInferenceAlgorithmVersion: (
    selectedInferenceAlgorithmVersion?: InferenceAlgorithmVersion
  ): ActionWithPayload<
    'SET_SELECTED_INFERENCE_ALGORITHM_VERSION_FOR_BUILD_ENTRY',
    {
      selectedInferenceAlgorithmVersion?: InferenceAlgorithmVersion
    }
  > =>
    CreateAction(
      BuildEntryActionType.SET_SELECTED_INFERENCE_ALGORITHM_VERSION_FOR_BUILD_ENTRY,
      {
        selectedInferenceAlgorithmVersion,
      }
    ),
  setBuildMetaData: (
    buildMetaData: MetaData
  ): ActionWithPayload<'SET_BUILD_META_DATA', { buildMetaData: MetaData }> =>
    CreateAction(BuildEntryActionType.SET_BUILD_META_DATA, {
      buildMetaData,
    }),
  setEdgeImageMetaData: (
    edgeImageMetaData: MetaData
  ): ActionWithPayload<
    'SET_EDGE_IMAGE_META_DATA',
    { edgeImageMetaData: MetaData }
  > =>
    CreateAction(BuildEntryActionType.SET_EDGE_IMAGE_META_DATA, {
      edgeImageMetaData,
    }),
  setMetaDataSubState: (
    metaDataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_META_DATA_SUB_STATE_FOR_BUILD_ENTRY',
    { metaDataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(BuildEntryActionType.SET_META_DATA_SUB_STATE_FOR_BUILD_ENTRY, {
      metaDataSubState,
    }),
  setEdgeImageSubStateSubState: (
    edgeImageSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_EDGE_IMAGE_SUB_STATE_FOR_BUILD_ENTRY',
    { edgeImageSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      BuildEntryActionType.SET_EDGE_IMAGE_SUB_STATE_FOR_BUILD_ENTRY,
      {
        edgeImageSubState,
      }
    ),
  setIsTransfer: (
    isTransfer: boolean
  ): ActionWithPayload<'SET_IS_TRANSFER', { isTransfer: boolean }> =>
    CreateAction(BuildEntryActionType.SET_IS_TRANSFER, {
      isTransfer,
    }),
  setIsSystemEvaluation: (
    isSystemEvaluation: boolean
  ): ActionWithPayload<
    'SET_IS_SYSTEM_EVALUATION',
    { isSystemEvaluation: boolean }
  > =>
    CreateAction(BuildEntryActionType.SET_IS_SYSTEM_EVALUATION, {
      isSystemEvaluation,
    }),
  setBuildEntryState: (
    buildEntryStateKind: BuildEntryStateKind
  ): ActionWithPayload<
    'SET_BUILD_ENTRY_STATE',
    { buildEntryStateKind: BuildEntryStateKind }
  > =>
    CreateAction(BuildEntryActionType.SET_BUILD_ENTRY_STATE, {
      buildEntryStateKind,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_BUILD_ENTRY',
    { inProgress: boolean }
  > =>
    CreateAction(BuildEntryActionType.SET_IN_PROGRESS_FOR_BUILD_ENTRY, {
      inProgress,
    }),
  setIsSelected: (
    selectedBaseInferenceContainerImageId: string[]
  ): ActionWithPayload<
    'SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID',
    { selectedBaseInferenceContainerImageId: string[] }
  > =>
    CreateAction(
      BuildEntryActionType.SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID,
      {
        selectedBaseInferenceContainerImageId,
      }
    ),
  clearBuildEntryState: (): Action<'CLEAR_BUILD_ENTRY_STATE'> =>
    CreateAction(BuildEntryActionType.CLEAR_BUILD_ENTRY_STATE),
  setExecutedInfo: (
    executionInfo: ExecutionInfo
  ): ActionWithPayload<
    'SET_EXECUTION_INFO_FOR_BUILD_ENTRY',
    { executionInfo: ExecutionInfo }
  > =>
    CreateAction(BuildEntryActionType.SET_EXECUTION_INFO_FOR_BUILD_ENTRY, {
      executionInfo,
    }),
  executeBuildEntry: (): Action<'EXEC_BUILD_ENTRY_SUCCESS'> =>
    CreateAction(BuildEntryActionType.EXEC_BUILD_ENTRY_SUCCESS),
  executeBuildEntryFailure: (): Action<'EXEC_BUILD_ENTRY_FAILURE'> =>
    CreateAction(BuildEntryActionType.EXEC_BUILD_ENTRY_FAILURE),
}
