import { Timestamp } from 'state/firebase'
import { TransactionStatusKind } from 'state/utils/types'

export const CustomTrainingListActionType = {
  SET_CUSTOM_TRAINING_LIST: 'SET_CUSTOM_TRAINING_LIST',
  SET_CUSTOM_TRAINING_DISPLAY_CONDITION:
    'SET_CUSTOM_TRAINING_DISPLAY_CONDITION',
  CLEAR_CUSTOM_TRAINING_DISPLAY_CONDITION:
    'CLEAR_CUSTOM_TRAINING_DISPLAY_CONDITION',
  CLEAR_CUSTOM_TRAINING_PAGING_STATE: 'CLEAR_CUSTOM_TRAINING_PAGING_STATE',
  SET_ML_PIPELINE_IDS_FOR_CUSTOM_TRAINING_LIST:
    'SET_ML_PIPELINE_IDS_FOR_CUSTOM_TRAINING_LIST',
  SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_LIST:
    'SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_LIST',
  SET_CURRENT_CUSTOM_TRAINING_LIST_SNAPSHOT:
    'SET_CURRENT_CUSTOM_TRAINING_LIST_SNAPSHOT',
  CLEAR_CUSTOM_TRAINING_LIST_STATE: 'CLEAR_CUSTOM_TRAINING_LIST_STATE',
} as const

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface Dataset {
  datasetId: string
  datasetName: string
}

interface TrainedModel {
  trainedModelId: string
  trainedModelName: string
}

export interface CustomTraining {
  mlPipelineId: string
  mlPipelineName: string
  algorithmName: string
  progress: Progress
  startedAt: Timestamp
  endedAt?: Timestamp
  dataset: Dataset
  trainedModel: TrainedModel
  accountId: string
}

export interface CustomTrainingDisplayCondition {
  algorithmId?: string
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface CustomTraininglistDomainData {
  currentCustomTrainingList: CustomTraining[]
  customTrainingDisplayCondition: CustomTrainingDisplayCondition
  mlPipelineIds: string[]
  currentCustomTrainingListSnapshot?: () => void
}

export interface CustomTraininglistAppState {
  inProgress: boolean
}

export interface CustomTraininglist {
  domainData: CustomTraininglistDomainData
  appState: CustomTraininglistAppState
}
