import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'

export const SceneCameraEntryApi = {
  createSceneCameraValidate: (params: {
    'aug-3d-scene-camera': unknown
  }): Promise<HttpsCallableResult<{ 'is-valid': boolean }>> =>
    httpsCallable<unknown, { 'is-valid': boolean }>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-aug-3d-scene-camera-validate'
    )({
      ...params,
    }),
  createSceneCamera: (params: {
    'aug-3d-scene-camera': {
      name: string
      overview: string
      revision: {
        schema_version: string
        camera: {
          location: number[]
          fov: number[]
          'look-at': number[]
        }
      }
    }
  }): Promise<HttpsCallableResult<{ 'aug-3d-scene-camera-id': string }>> =>
    httpsCallable<unknown, { 'aug-3d-scene-camera-id': string }>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-aug-3d-scene-camera-firestore'
    )({
      ...params,
    }),

  createSceneCameraRevision: (params: {
    'aug-3d-scene-camera-revision': {
      'aug-3d-scene-camera-id': string
      revision: {
        schema_version: string
        camera: {
          location: number[]
          fov: number[]
          'look-at': number[]
        }
      }
    }
  }): Promise<HttpsCallableResult<{ 'aug-3d-scene-camera-id': string }>> =>
    httpsCallable<unknown, { 'aug-3d-scene-camera-id': string }>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-aug-3d-scene-camera-rev-firestore'
    )({
      ...params,
    }),
}
