import { InferenceAlgorithmVersionDocument } from './types'
import {
  preReleaseVersionSchema,
  timeStampSchema,
  versionRangeSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** inference/algorithm-version documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as InferenceAlgorithmVersionDocument
  const schema = {
    required: [
      'algorithm-id',
      'algorithm-version',
      'available-version',
      'inference-algorithm-version',
      'inference-code-version',
      'metadata',
      'released-at',
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'algorithm-version': {
        ...preReleaseVersionSchema,
      },
      'algorithm-purpose': {
        type: 'string',
      },
      'available-version': {
        required: ['training-algorithm'],
        type: 'object',
        properties: {
          'training-algorithm': {
            ...versionRangeSchema,
          },
        },
      },
      'commit-hash': {
        type: 'string',
      },
      'inference-algorithm-version': {
        type: 'string',
      },
      'inference-code-version': {
        ...versionSchema,
      },
      metadata: {
        required: ['remarks'],
        type: 'object',
        properties: {
          remarks: {
            required: ['en', 'ja'],
            type: 'object',
            properties: {
              en: {
                type: 'string',
              },
              ja: {
                type: 'string',
              },
            },
          },
        },
      },
      'released-at': {
        ...timeStampSchema,
      },
    },
  }

  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'inference-algorithm-version',
      value: data as unknown as DataObject,
    })
    return false
  }
}
