import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  DatasetDetailActionType,
  CurrentDatasetDetail,
  DatasetDetailState,
  ToastInfo,
  AnnotationSet,
} from './types'

export type DatasetDetailAction = ActionsUnion<typeof datasetDetailActions>

export const datasetDetailActions = {
  setCurrentDatasetDetail: (
    currentDatasetDetail: CurrentDatasetDetail
  ): ActionWithPayload<
    'SET_CURRENT_DATASET_DETAIL',
    { currentDatasetDetail: CurrentDatasetDetail }
  > =>
    CreateAction(DatasetDetailActionType.SET_CURRENT_DATASET_DETAIL, {
      currentDatasetDetail,
    }),
  setAnnotationList: (
    annotationSet: AnnotationSet[]
  ): ActionWithPayload<
    'SET_ANNOTATION_SET_LIST',
    { annotationSet: AnnotationSet[] }
  > =>
    CreateAction(DatasetDetailActionType.SET_ANNOTATION_SET_LIST, {
      annotationSet,
    }),
  setDatasetDetailState: (
    datasetDetailState: DatasetDetailState
  ): ActionWithPayload<
    'SET_DATASET_DETAIL_STATE',
    { datasetDetailState: DatasetDetailState }
  > =>
    CreateAction(DatasetDetailActionType.SET_DATASET_DETAIL_STATE, {
      datasetDetailState,
    }),
  setInProgress: (
    isInProgressForGettingDatasetDetail: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_DATASET_DETAIL',
    { isInProgressForGettingDatasetDetail: boolean }
  > =>
    CreateAction(DatasetDetailActionType.SET_IN_PROGRESS_FOR_DATASET_DETAIL, {
      isInProgressForGettingDatasetDetail,
    }),
  setInProgressForAnnotationSet: (
    isInProgressForGettingAnnotationSet: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ANNOTATION_SET',
    { isInProgressForGettingAnnotationSet: boolean }
  > =>
    CreateAction(DatasetDetailActionType.SET_IN_PROGRESS_FOR_ANNOTATION_SET, {
      isInProgressForGettingAnnotationSet,
    }),
  clearDatasetDetailState: (): Action<'CLEAR_DATASET_DETAIL_STATE'> =>
    CreateAction(DatasetDetailActionType.CLEAR_DATASET_DETAIL_STATE),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_DATASET_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(DatasetDetailActionType.SET_TOAST_INFO_FOR_DATASET_DETAIL, {
      toastInfo,
    }),
  setSelectedGroupedDataId: (
    selectedGroupedDataId?: string
  ): ActionWithPayload<
    'SET_SELECTED_GROUPED_DATA_ID',
    { selectedGroupedDataId?: string }
  > =>
    CreateAction(DatasetDetailActionType.SET_SELECTED_GROUPED_DATA_ID, {
      selectedGroupedDataId,
    }),
}
