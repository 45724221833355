import { DatasetAugmentationMLPipelineQueryDocument } from './types'

import {
  mlPipelineDocumentSchema,
  timeStampSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** ml-pipeline-query (データセット生成 (3D CAD データ)) documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as DatasetAugmentationMLPipelineQueryDocument
  const schema = {
    ...mlPipelineDocumentSchema,
    required: [...mlPipelineDocumentSchema.required, 'augmentation-step'],
  }
  const augmentationStepSchema = {
    required: [
      'step-id',
      'step-kind',
      'step-status',
      'started-at',
      'ended-at',
      'src',
    ],
    type: 'object',
    properties: {
      'step-id': {
        type: 'string',
      },
      'step-kind': {
        type: 'string',
      },
      'step-status': {
        type: 'string',
      },
      'started-at': {
        ...timeStampSchema,
      },
      'ended-at': {
        ...timeStampSchema,
      },
      src: {
        required: [
          'targets',
          'placement',
          '3d-scene',
          'renderers',
          'dataset',
          'metadata',
        ],
        type: 'object',
        properties: {
          targets: {
            type: 'array',
            items: {
              required: ['3d-object', 'label', 'texture'],
              type: 'object',
              properties: {
                '3d-object': {
                  required: ['aug-3d-object-id', 'user-group-id'],
                  type: 'object',
                  properties: {
                    'aug-3d-object-id': {
                      type: 'string',
                    },
                    'user-group-id': {
                      type: 'string',
                    },
                  },
                },
                label: {
                  required: ['label-id', 'name', 'supercategory'],
                  type: 'object',
                  properties: {
                    'label-id': {
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                    supercategory: {
                      type: 'string',
                    },
                  },
                },
                texture: {
                  required: [
                    'aug-texture-id',
                    'aug-texture-revision',
                    'user-group-id',
                  ],
                  type: 'object',
                  properties: {
                    'aug-texture-id': {
                      type: 'string',
                    },
                    'aug-texture-revision': {
                      type: 'number',
                    },
                    'user-group-id': {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
          placement: {
            required: ['layouts'],
            type: 'object',
            properties: {
              layouts: {
                type: 'array',
                items: {
                  required: [
                    'aug-si-object-layout-id',
                    'user-group-id',
                    'si-object',
                    'seed',
                    'rotation',
                  ],
                  type: 'object',
                  properties: {
                    'aug-si-object-layout-id': {
                      type: 'string',
                    },
                    'user-group-id': {
                      type: 'string',
                    },
                    'si-object': {
                      required: [
                        'aug-si-object-placement-id',
                        'aug-si-object-placement-revision',
                        'user-group-id',
                        'relations',
                      ],
                      type: 'object',
                      properties: {
                        'aug-si-object-placement-id': {
                          type: 'string',
                        },
                        'aug-si-object-placement-revision': {
                          type: 'number',
                        },
                        'user-group-id': {
                          type: 'string',
                        },
                        relations: {
                          type: 'array',
                          items: {
                            required: [
                              'target-id',
                              'aug-3d-object-id',
                              'user-group-id',
                            ],
                            type: 'object',
                            properties: {
                              'target-id': {
                                type: 'number',
                              },
                              'aug-3d-object-id': {
                                type: 'string',
                              },
                              'user-group-id': {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                    },
                    seed: {
                      type: 'number',
                    },
                    rotation: {
                      required: [
                        'aug-si-object-rotation-group-id',
                        'user-group-id',
                      ],
                      type: 'object',
                      properties: {
                        'aug-si-object-rotation-group-id': {
                          type: 'string',
                        },
                        'user-group-id': {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          '3d-scene': {
            required: ['backgrounds', 'cameras', 'lights'],
            type: 'object',
            properties: {
              backgrounds: {
                type: 'array',
                items: {
                  required: [
                    'aug-3d-scene-background-id',
                    'aug-3d-scene-background-revision',
                    'user-group-id',
                  ],
                  type: 'object',
                  properties: {
                    'aug-3d-scene-background-id': {
                      type: 'string',
                    },
                    'aug-3d-scene-background-revision': {
                      type: 'number',
                    },
                    'user-group-id': {
                      type: 'string',
                    },
                  },
                },
              },
              cameras: {
                type: 'array',
                items: {
                  required: [
                    'aug-3d-scene-camera-id',
                    'aug-3d-scene-camera-revision',
                    'user-group-id',
                  ],
                  type: 'object',
                  properties: {
                    'aug-3d-scene-camera-id': {
                      type: 'string',
                    },
                    'aug-3d-scene-camera-revision': {
                      type: 'number',
                    },
                    'user-group-id': {
                      type: 'string',
                    },
                  },
                },
              },
              lights: {
                type: 'array',
                items: {
                  required: ['aug-3d-scene-light-group-id', 'groups'],
                  type: 'object',
                  properties: {
                    'aug-3d-scene-light-group-id': {
                      type: 'string',
                    },
                    groups: {
                      type: 'array',
                      items: {
                        required: [
                          'aug-3d-scene-light-id',
                          'aug-3d-scene-light-revision',
                          'user-group-id',
                        ],
                        type: 'object',
                        properties: {
                          'aug-3d-scene-light-id': {
                            type: 'string',
                          },
                          'aug-3d-scene-light-revision': {
                            type: 'number',
                          },
                          'user-group-id': {
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          renderers: {
            type: 'array',
            items: {
              required: [
                'augmentation-number',
                'size',
                'depth',
                'post-processing',
              ],
              type: 'object',
              properties: {
                'augmentation-number': {
                  type: 'number',
                },
                size: {
                  required: ['width', 'height'],
                  type: 'object',
                  properties: {
                    width: {
                      type: 'number',
                    },
                    height: {
                      type: 'number',
                    },
                  },
                },
                depth: {
                  required: ['min', 'max'],
                  type: 'object',
                  properties: {
                    min: {
                      type: 'number',
                    },
                    max: {
                      type: 'number',
                    },
                  },
                },
                'post-processing': {
                  required: ['type'],
                  type: 'object',
                  properties: {
                    type: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
          dataset: {
            required: ['format'],
            type: 'object',
            properties: {
              format: {
                type: 'object',
                required: [
                  'algorithm-id',
                  'annotation-format-id',
                  'annotation-format-version',
                  'dataset-template-id',
                ],
                properties: {
                  'algorithm-id': {
                    type: 'string',
                  },
                  'annotation-format-id': {
                    type: 'string',
                  },
                  'annotation-format-version': {
                    ...versionSchema,
                  },
                  'dataset-template-id': {
                    type: 'string',
                  },
                },
              },
              extended: {
                type: 'object',
                properties: {
                  'train-valid': {
                    type: 'object',
                    properties: {
                      ratio: {
                        type: 'number',
                        minimum: 0,
                        maximum: 100,
                      },
                    },
                  },
                },
              },
            },
          },
          metadata: {
            required: ['dataset'],
            type: 'object',
            properties: {
              dataset: {
                type: 'object',
                required: ['name', 'remarks'],
                properties: {
                  name: {
                    type: 'string',
                  },
                  remarks: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
      dest: {
        required: [],
        type: 'object',
        properties: {
          results: {
            type: 'array',
            items: {
              required: ['dataset-id', 'combination'],
              type: 'object',
              properties: {
                'dataset-id': {
                  type: 'string',
                },
                combination: {
                  required: ['targets', 'placement', '3d-scene', 'renderer'],
                  type: 'object',
                  properties: {
                    targets: {
                      type: 'array',
                      items: {
                        required: ['3d-object', 'label', 'texture'],
                        type: 'object',
                        properties: {
                          '3d-object': {
                            required: ['aug-3d-object-id', 'user-group-id'],
                            type: 'object',
                            properties: {
                              'aug-3d-object-id': {
                                type: 'string',
                              },
                              'user-group-id': {
                                type: 'string',
                              },
                            },
                          },
                          label: {
                            required: ['label-id', 'name', 'supercategory'],
                            type: 'object',
                            properties: {
                              'label-id': {
                                type: 'string',
                              },
                              name: {
                                type: 'string',
                              },
                              supercategory: {
                                type: 'string',
                              },
                            },
                          },
                          texture: {
                            required: [
                              'aug-texture-id',
                              'aug-texture-revision',
                              'user-group-id',
                            ],
                            type: 'object',
                            properties: {
                              'aug-texture-id': {
                                type: 'string',
                              },
                              'aug-texture-revision': {
                                type: 'number',
                              },
                              'user-group-id': {
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                    },
                    placement: {
                      required: ['layout'],
                      type: 'object',
                      properties: {
                        layout: {
                          required: [
                            'aug-si-object-layout-id',
                            'user-group-id',
                            'seed',
                            'rotation',
                          ],
                          type: 'object',
                          properties: {
                            'aug-si-object-layout-id': {
                              type: 'string',
                            },
                            'user-group-id': {
                              type: 'string',
                            },
                            'si-object': {
                              required: [
                                'aug-si-object-placement-id',
                                'aug-si-object-placement-revision',
                                'user-group-id',
                                'relations',
                              ],
                              type: 'object',
                              properties: {
                                'aug-si-object-placement-id': {
                                  type: 'string',
                                },
                                'aug-si-object-placement-revision': {
                                  type: 'number',
                                },
                                'user-group-id': {
                                  type: 'string',
                                },
                                relations: {
                                  type: 'array',
                                  items: {
                                    required: [
                                      'target-id',
                                      'aug-3d-object-id',
                                      'user-group-id',
                                    ],
                                    type: 'object',
                                    properties: {
                                      'target-id': {
                                        type: 'number',
                                      },
                                      'aug-3d-object-id': {
                                        type: 'string',
                                      },
                                      'user-group-id': {
                                        type: 'string',
                                      },
                                    },
                                  },
                                },
                              },
                            },
                            seed: {
                              type: 'number',
                            },
                            rotation: {
                              required: ['aug-si-object-rotation-group-id'],
                              type: 'object',
                              properties: {
                                'aug-si-object-rotation-group-id': {
                                  type: 'string',
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    '3d-scene': {
                      required: ['background', 'camera', 'light'],
                      type: 'object',
                      properties: {
                        background: {
                          required: [
                            'aug-3d-scene-background-id',
                            'aug-3d-scene-background-revision',
                            'user-group-id',
                          ],
                          type: 'object',
                          properties: {
                            'aug-3d-scene-background-id': {
                              type: 'string',
                            },
                            'aug-3d-scene-background-revision': {
                              type: 'number',
                            },
                            'user-group-id': {
                              type: 'string',
                            },
                          },
                        },
                        camera: {
                          required: [
                            'aug-3d-scene-camera-id',
                            'aug-3d-scene-camera-revision',
                            'user-group-id',
                          ],
                          type: 'object',
                          properties: {
                            'aug-3d-scene-camera-id': {
                              type: 'string',
                            },
                            'aug-3d-scene-camera-revision': {
                              type: 'number',
                            },
                            'user-group-id': {
                              type: 'string',
                            },
                          },
                        },
                        light: {
                          required: ['groups'],
                          type: 'object',
                          properties: {
                            groups: {
                              type: 'array',
                              items: {
                                required: [
                                  'aug-3d-scene-light-id',
                                  'aug-3d-scene-light-revision',
                                  'user-group-id',
                                ],
                                type: 'object',
                                properties: {
                                  'aug-3d-scene-light-id': {
                                    type: 'string',
                                  },
                                  'aug-3d-scene-light-revision': {
                                    type: 'number',
                                  },
                                  'user-group-id': {
                                    type: 'string',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    renderer: {
                      required: [
                        'augmentation-number',
                        'size',
                        'depth',
                        'post-processing',
                      ],
                      type: 'object',
                      properties: {
                        'augmentation-number': {
                          type: 'number',
                        },
                        size: {
                          required: ['width', 'height'],
                          type: 'object',
                          properties: {
                            width: {
                              type: 'number',
                            },
                            height: {
                              type: 'number',
                            },
                          },
                        },
                        depth: {
                          required: ['min', 'max'],
                          type: 'object',
                          properties: {
                            min: {
                              type: 'number',
                            },
                            max: {
                              type: 'number',
                            },
                          },
                        },
                        'post-processing': {
                          required: ['type'],
                          type: 'object',
                          properties: {
                            type: {
                              type: 'string',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  }

  const validationSequences = [
    { schema: schema },
    {
      schema: augmentationStepSchema,
      dataIndex: 'augmentation-step',
    },
  ]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'ml-pipeline-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
