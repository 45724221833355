import React from 'react'
import { OnsiteInferenceResultWithImageProps } from './types'
import { Card, Typography, CardContent, Box, Checkbox } from '@mui/material'
import { ColorChip } from 'views/components/atoms'
import { FocusedInferenceResultView } from 'views/components/molecules/focusedInferenceResultView'

const CANVAS_WIDTH = '140px'
const CANVAS_HEIGHT = '105px'

export const OnsiteInferenceResultWithImage: React.FC<
  OnsiteInferenceResultWithImageProps
> = (props: OnsiteInferenceResultWithImageProps) => {
  return (
    <Card
      style={{ cursor: !props.isAnnotationSetData ? 'pointer' : 'default' }}
      sx={{ minWidth: 275 }}
      onClick={props.onClickCard}
      data-testid={`on-click-card-${props.trainedModelId}-${props.canvasInfo.id}`}
    >
      <CardContent
        sx={{ ':last-child': { paddingBottom: (theme) => theme.spacing(2) } }}
      >
        <Box display='flex' alignItems='center'>
          <Checkbox
            checked={props.checked}
            color='secondary'
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              e.stopPropagation()
            }
            onChange={props.onClickCheckbox}
          />
          <Box
            width={CANVAS_WIDTH}
            height={CANVAS_HEIGHT}
            ml={2}
            mr={4}
            sx={{ border: 'solid 1px #000' }}
          >
            <FocusedInferenceResultView
              canvasInfo={props.canvasInfo}
              url={props.url}
              width={CANVAS_WIDTH}
              height={CANVAS_HEIGHT}
            />
          </Box>
          <Box display='flex' flexDirection='column'>
            {!props.isAnnotationSetData && (
              <Typography
                variant='body2'
                color='textSecondary'
                sx={{ marginBottom: (theme) => theme.spacing(1) }}
              >
                Detected by
              </Typography>
            )}
            <Typography
              color='inherit'
              sx={{
                textDecoration: !props.isAnnotationSetData
                  ? 'underline'
                  : 'none',
                cursor: !props.isAnnotationSetData ? 'pointer' : 'default',
                marginBottom: (theme) => theme.spacing(1),
              }}
              data-testid={`${props.trainedModelId}-${props.canvasInfo.id}`}
              onClick={() => props.onClickLink(props.trainedModelId)}
            >
              {!props.isAnnotationSetData
                ? `${props.trainedModelName} - ${props.trainedModelVersion}`
                : props.trainedModelName}
            </Typography>
            <ColorChip
              key={props.canvasInfo.id}
              label={props.canvasInfo.id.substring(0, 8)}
              size='small'
              variant='filled'
              customColor={props.canvasInfo.color}
              sx={{
                marginBottom: (theme) => theme.spacing(1),
              }}
            />
            {!props.isAnnotationSetData && (
              <Typography variant='body2' color='textSecondary'>
                {`${props.canvasInfo.score * 100}%`}
              </Typography>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
