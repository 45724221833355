import { Timestamp } from 'state/firebase'
import { Version } from 'types/StateTypes'

// ActionType
export const InferenceActionType = {
  SET_SELECTED_ALGORITHM_ID_FOR_INFERENCE:
    'SET_SELECTED_ALGORITHM_ID_FOR_INFERENCE',
  SET_TRAINED_MODEL_GROUP_LIST_FOR_INFERENCE:
    'SET_TRAINED_MODEL_GROUP_LIST_FOR_INFERENCE',
  SET_SELECTED_TRAINED_MODEL_FOR_INFERENCE:
    'SET_SELECTED_TRAINED_MODEL_FOR_INFERENCE',
  SET_SELECTED_TRAINED_MODEL_GROUP_FOR_INFERENCE:
    'SET_SELECTED_TRAINED_MODEL_GROUP_FOR_INFERENCE',
  SET_IMAGE_SET_LIST: 'SET_IMAGE_SET_LIST',
  SET_MODEL_GROUP_DISPLAY_CONDITION_FOR_INFERENCE:
    'SET_MODEL_GROUP_DISPLAY_CONDITION_FOR_INFERENCE',
  SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_INFERENCE:
    'SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_INFERENCE',
  SET_IMAGE_SET_DISPLAY_CONDITION: 'SET_IMAGE_SET_DISPLAY_CONDITION',
  SET_INFERENCE_STATE: 'SET_INFERENCE_STATE',
  SET_INFERENCE_METADATA: 'SET_INFERENCE_METADATA',
  SET_INFERENCE_ALGORITHM_SUB_STATE: 'SET_INFERENCE_ALGORITHM_SUB_STATE',
  SET_SELECTED_IMAGE_SET: 'SET_SELECTED_IMAGE_SET',
  SET_IMAGE_SET_SUB_STATE: 'SET_IMAGE_SET_SUB_STATE',
  SET_META_DATA_SUB_STATE: 'SET_META_DATA_SUB_STATE',
  EXEC_INFERENCE_SUCCESS: 'EXEC_INFERENCE_SUCCESS',
  EXEC_INFERENCE_FAILURE: 'EXEC_INFERENCE_FAILURE',
  SET_EXECUTED_ML_PIPELINE_ID_FOR_INFERENCE:
    'SET_EXECUTED_ML_PIPELINE_ID_FOR_INFERENCE',
  CLEAR_CURRENT_INFERENCE_STATE: 'CLEAR_CURRENT_INFERENCE_STATE',
  CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_INFERENCE:
    'CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_INFERENCE',
  CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_INFERENCE:
    'CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_INFERENCE',
  SET_IN_PROGRESS_FOR_INFERENCE_START: 'SET_IN_PROGRESS_FOR_INFERENCE_START',
  SET_CURRENT_DATASET_LIST_SNAPSHOT: 'SET_CURRENT_DATASET_LIST_SNAPSHOT',
} as const

export interface GroupedDataDocument {
  id: string
  'user-group-id': string
  'grouped-data-id': string
  'training-data-list': string[]
  'created-at': Timestamp
  'created-by': string
  'updated-at': Timestamp
  'updated-by': string
}

export interface GroupedDataMetadataDocument {
  id: string
  'grouped-data-id': string
  name: string
  remarks: string
  'created-at': Timestamp
  'created-by': string
  'updated-at': Timestamp
  'updated-by': string
}

export interface InferenceAlgorithm {
  algorithmId?: string
}

export interface InferenceParamsType {
  algorithmId: string
  algorithmVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  trainedModel: {
    trainedModelGroupId: string
    trainedModelId: string
    userGroupId: string
  }
  datasetId: string
  inputFilesId: string
  mlPipelineMetadata: Metadata
  annotationSetId: string[]
}

export interface InferenceAlgorithmKindList {
  [algorithmKind: string]: TrainedModel[]
}

export interface TrainedModel {
  trainedModelId: string
  trainedModelGroupVersion: Version
  trainedModelName: string
  transactionStatus: string
}

export interface TrainedModelGroup {
  trainedModelGroupId: string // モデルグループID
  trainedModelGroupName: string // モデルグループ名
  trainedModelCount: number // 登録モデル数
  latestTrainedModelVersion: string // 最新モデルバージョン
  latestTrainedModelName: string // 最新モデル名
  updatedAt?: Timestamp // 更新日
  createdAt?: Timestamp // 作成日
  createdBy: string // 作成者
  trainedModels: TrainedModel[] // モデルの配列
  userGroupId: string
}

export interface GroupedImage {
  groupedDataId: string
  groupedDataName: string
  remarks: string
  generatedAt?: Timestamp
  createdBy: string
}

export interface InferenceDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface GroupedImageDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface TrainedModelDataItem {
  'trained-model-id': string
  'trained-model-name': string
  'trained-model-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
  'user-group-id': string
}

export interface TrainingAlgorithmVersion {
  ['display-name']: string
  major: number
  minor: number
  patch: number
  ['pre-release']: number
}

export interface AvailableVersion {
  displayName: string
  major: number
  minor: number
  patch: number
}

export interface Metadata {
  name: string
  remarks?: string
}

export interface ExecuteInfo {
  mlPipelineId?: string
  inferenceStepId?: string
}

export interface InferenceAlgorithmState {
  trainedModelSubState: 'Unselected' | 'Selected'
  groupedDataSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState:
    | 'BeforeExecute'
    | 'ExecuteInProgress'
    | 'Executed'
    | 'ExecuteError'
}

export const InferenceStateKindArray = [
  'TrainedModelListState',
  'GroupedDataState',
  'MetaDataState',
  'ExecuteState',
]

export type InferenceStateKind = (typeof InferenceStateKindArray)[number]
export interface TrainedModelGroupLists {
  sharedUserGroup: TrainedModelGroup[]
  userGroup: TrainedModelGroup[]
}

export interface CurrentInferenceDomainData {
  selectedInferenceAlgorithm?: InferenceAlgorithm // 選択されたアルゴリズム
  trainedModelGroups: TrainedModelGroupLists // モデルグループの配列
  selectedTrainedModelGroup?: TrainedModelGroup // 選択されたベースモデルのモデルグループの情報
  selectedTrainedModel?: TrainedModel // 選択中のベースモデル
  groupedImageList: GroupedImage[] // 画像セット一覧
  selectedGroupedImage?: GroupedImage // 選択された画像セット
  trainedModelDisplayCondition: InferenceDisplayCondition // ベースモデルの検索条件
  trainedModelGroupDisplayCondition: InferenceDisplayCondition & {
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  } // ベースモデルのモデルグループの検索条件
  groupedImageDisplayCondition: GroupedImageDisplayCondition // 画像セットテーブルの表示条件
  inferenceMetaData?: Metadata // 推論指示のメタデータ
  executeInfo: {
    mlPipelineId?: string // 推論実行時のmlPipelineId
    inferenceStepId?: string // 推論実行時のstepId
  }
  currentDatasetListSnapshot?: () => void
}

export interface CurrentInferenceAppState {
  inferenceState: InferenceStateKind // カスタム学習画面のSTEP
  inferenceAlgorithmState: InferenceAlgorithmState // カスタム学習画面の各種STEPの状態
  inProgress: boolean // データ取得中、カスタム学習実行中フラグ
}

export interface CurrentInferenceState {
  domainData: CurrentInferenceDomainData
  appState: CurrentInferenceAppState
}
