import { CustomTrainingMLPipelineQueryDocument as BuildMLPipelineQueryDocument } from './types'

import {
  buildVersionSchema,
  mlPipelineDocumentSchema,
  timeStampSchema,
  trainedModelSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** account-setting documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as BuildMLPipelineQueryDocument
  const schema = {
    ...mlPipelineDocumentSchema,
    required: [...mlPipelineDocumentSchema.required, 'build-step'],
  }

  const buildStepSchema = {
    required: [
      'ended-at',
      'started-at',
      'step-id',
      'step-kind',
      'step-status',
      'src',
    ],
    type: 'object',
    properties: {
      'ended-at': {
        ...timeStampSchema,
      },
      'started-at': {
        ...timeStampSchema,
      },
      'step-id': {
        type: 'string',
      },
      'step-kind': {
        type: 'string',
      },
      'step-status': {
        type: 'string',
      },
      src: {
        required: [
          'algorithm-id',
          'base-inference-container-image-list',
          'inference-algorithm-version',
          'trained-model',
        ],
        type: 'object',
        properties: {
          'algorithm-id': {
            type: 'string',
          },
          'base-inference-container-image-list': {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          'container-interface-version': {
            ...versionSchema,
          },
          'inference-algorithm-version': {
            ...versionSchema,
          },
          'trained-model': {
            ...trainedModelSchema,
          },
        },
      },
      dest: {
        required: ['edge-container-image-list'],
        type: 'object',
        properties: {
          'edge-container-image-list': {
            type: 'array',
            items: {
              required: [
                'built-at',
                'edge-container-image-group-id',
                'edge-container-image-group-version',
                'edge-container-image-id',
                'edge-container-image-requirements',
                'edge-container-image-tags',
              ],
              type: 'object',
              properties: {
                'built-at': {
                  ...timeStampSchema,
                },
                'edge-container-image-group-id': {
                  type: 'string',
                },
                'edge-container-image-group-version': {
                  ...buildVersionSchema,
                },
                'edge-container-image-id': {
                  type: 'string',
                },
                'edge-container-image-requirements': {
                  type: 'object',
                },
                'edge-container-image-tags': {
                  type: 'array',
                  items: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
      'system-evaluation': {
        required: ['enabled'],
        type: 'object',
        properties: {
          enabled: {
            type: 'boolean',
          },
        },
      },
    },
  }
  const validationSequences = [
    { schema: schema },
    {
      schema: buildStepSchema,
      dataIndex: 'build-step',
    },
  ]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'ml-pipeline-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
