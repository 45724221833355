import { FeatureDataQueryDocument } from './types'

import {
  preReleaseVersionSchema,
  timeStampSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** feature-data-query documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as FeatureDataQueryDocument
  const schema = {
    required: [
      'feature-data-id',
      'evaluation-status',
      'feature-data-group-id',
      'feature-data-group-version',
      'training-algorithm-version',
      'algorithm-id',
      'algorithm-structure-id',
      'algorithm-structure-version',
      'dataset-list',
      'setting-id',
      'generated-at',
      'step-id',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
      'feature-data-name',
      'feature-data-remarks',
    ],
    type: 'object',
    properties: {
      'feature-data-id': {
        type: 'string',
      },
      'evaluation-status': {
        type: 'string',
      },
      'feature-data-group-id': {
        type: 'string',
      },
      'feature-data-group-version': {
        ...versionSchema,
      },
      'training-algorithm-version': {
        ...preReleaseVersionSchema,
      },
      'algorithm-id': {
        type: 'string',
      },
      'algorithm-structure-id': {
        type: 'string',
      },
      'algorithm-structure-version': {
        ...versionSchema,
      },
      'dataset-list': {
        type: 'array',
        items: {
          required: ['generation', 'user-group-id', 'dataset-id'],
          type: 'object',
          properties: {
            generation: {
              type: 'number',
            },
            'user-group-id': {
              type: 'string',
            },
            'dataset-id': {
              type: 'string',
            },
          },
        },
      },
      'setting-id': {
        type: 'string',
      },
      'generated-at': {
        ...timeStampSchema,
      },
      'generated-by': {
        type: 'string',
      },
      'step-id': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
      'feature-data-name': {
        type: 'string',
      },
      'feature-data-remarks': {
        type: 'string',
      },
    },
  }
  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error(errors)
    return false
  }
}
