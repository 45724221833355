import React, { useEffect, useMemo, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'
import SortIcon from '@mui/icons-material/Sort'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import ListIcon from '@mui/icons-material/List'
import Fab from '@mui/material/Fab'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { State } from 'state/store'
import {
  DatasetInfo,
  datasetListActions,
  DatasetListActions,
  datasetListOperations,
} from 'state/ducks/datasetList'
import {
  DatasetListDisplayCondition,
  DatasetListDisplayMode,
} from 'state/ducks/datasetList/types'

import {
  DatasetIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  GlobalLoading,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  TooltipLink,
  TileLayout,
  DateTimeRangeInput,
  BreadcrumbsComponent,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { isNull } from 'utils/typeguard'
import TablePagination from '@mui/material/TablePagination'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

const mapStateToProps = (state: State) => ({
  ...state.pages.datasetListState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, DatasetListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** データセット一覧取得 */
  getDatasetList: () => dispatch(datasetListOperations.getDatasetList()),
  /** サムネイル取得 */
  getThumbnails: () => dispatch(datasetListOperations.getThumbnails()),
  /** データセット一覧をクリア */
  clearDatasetList: () => dispatch(datasetListActions.clearDatasetList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: DatasetListDisplayCondition) =>
    dispatch(datasetListActions.setListDisplayCondition(listCondition)),
  /** Stateのクリア */
  clearDatasetListState: () =>
    dispatch(datasetListActions.clearDatasetListState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '80%',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(0),
    zIndex: 1,
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  filterSelectBox: {
    marginTop: '16px',
    width: '100%',
  },
  filterSettingButton: {
    backgroundColor: '#D9E5FF',
  },
  cancelButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  contentBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'datasetId',
    title: 'データセットID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'datasetName',
    title: 'データセット名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'annotationSetKind',
    title: 'アノテーション種別',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'format',
    title: 'フォーマット',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: '備考',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'created-by',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** 作成者のフィルタ選択肢 */
const FILTER_CREATED_BY = [
  {
    label: '全て',
    value: 'all',
  },
  {
    label: '自分',
    value: 'own',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const DatasetList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()
  const [displayMode, setDisplayMode] =
    useState<DatasetListDisplayMode>('listMode')
  const contentRef = useRef<HTMLDivElement>(null)
  const [openFilterDialog, setOpenFilterDialog] = useState(false)
  const [createdAtFrom, setCreatedAtFrom] = useState(false)
  const [createdAtFromTime, setCreatedAtFromTime] = useState('')
  const [createdAtFromDate, setCreatedAtFromDate] = useState('')
  const [createdAtTo, setCreatedAtTo] = useState(false)
  const [createdAtToTime, setCreatedAtToTime] = useState('')
  const [createdAtToDate, setCreatedAtToDate] = useState('')
  const [generatedFor, setGeneratedFor] = useState<'Training' | 'Inference'>(
    'Training'
  )
  const [datasetTemplateId, setDatasetTemplateId] = useState('all')
  const [createdBy, setCreatedBy] = useState('all')
  const [isValidDateRange, setIsValidDateRange] = useState(false)

  useEffect(() => {
    const date = new Date()
    const toDateString = formatDateTimeSec(date)
    date.setMonth(date.getMonth() - 1)
    const toFromString = formatDateTimeSec(date)

    setCreatedAtFromDate(toFromString.substring(0, 10))
    setCreatedAtFromTime('00:00:00')

    setCreatedAtToDate(toDateString.substring(0, 10))
    setCreatedAtToTime(toDateString.substring(11, 19))
  }, [])

  useEffect(() => {
    if (props.algorithms.length > 0) {
      selectedAlgorithm(props.algorithms[0].algorithmId)
      return
    }

    return () => {
      props.clearDatasetListState()
    }
  }, [props.authedUser.auth.customClaims.userGroupId])

  useEffect(() => {
    props.getThumbnails()
  }, [props.domainData.currentDatasetList])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
    filteredTableContent,
  } = tableActions(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.datasetListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.datasetListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.datasetListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentDatasetList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentDatasetList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.datasetListDisplayCondition,
    props.domainData.currentDatasetList,
  ])
  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList = tableContent.map((datasetList: DatasetInfo) => {
      return {
        datasetId: datasetList.datasetId,
        datasetName: datasetList.datasetName,
        createdAt: datasetList.createdAt
          ? formatDateTimeSec(datasetList.createdAt.toDate())
          : '',
        annotationSetKind: datasetList.datasetTemplateName,
        format: datasetList.annotationFormatKind,
        remarks: datasetList.datasetRemarks,
        createdBy: datasetList.createdBy,
      }
    })

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'datasetId') {
          if (value && typeof value === 'string') {
            return (
              <TooltipLink
                key={key}
                data-testid={`dataset-${value}`}
                title={value}
                placement='right-start'
                onClick={() => {
                  history.push(`datasets/${value}`)
                }}
              />
            )
          } else {
            return <Typography key={key}>{value}</Typography>
          }
        } else if (key === 'datasetName') {
          if (value) {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value}</Typography>
              </Tooltip>
            )
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'createdAt') {
          if (value) {
            return (
              <Typography key={key}>
                {value ? value : TABLE_CELL_NOT_APPLICABLE}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'annotationSetKind') {
          if (value) {
            return (
              <Typography key={key}>
                {value ? value : TABLE_CELL_NOT_APPLICABLE}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'format') {
          if (value) {
            return (
              <Typography key={key}>
                {value ? value : TABLE_CELL_NOT_APPLICABLE}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'remarks') {
          if (value) {
            return (
              <Typography key={key}>
                {value ? value : TABLE_CELL_NOT_APPLICABLE}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'createdBy') {
          if (value) {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else {
          return <Typography key={key}>{TABLE_CELL_NOT_APPLICABLE}</Typography>
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const tableModeView = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.datasetListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.datasetListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.datasetListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.datasetListDisplayCondition.sortKey,
          order: props.domainData.datasetListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.datasetListDisplayCondition,
    props.appState.inProgress,
  ])

  const tileModeView = useMemo(() => {
    return (
      <Box position='relative'>
        <Box mt={2} mb={2} height='400px' overflow='scroll' ref={contentRef}>
          <TileLayout
            tileList={tableContent.map((dataset: DatasetInfo) => {
              return {
                ['data-testid']: dataset.datasetId,
                thumbnailUrl:
                  props.domainData.thumbnails[dataset.datasetId] ?? '',
                name: dataset.datasetName,
                date: dataset.createdAt,
                description:
                  dataset.datasetTemplateName || TABLE_CELL_NOT_APPLICABLE,
                onClickCard: () =>
                  history.push(`datasets/${dataset.datasetId}`),
              }
            })}
          />
        </Box>
        <TablePagination
          component='div'
          count={props.domainData.datasetListDisplayCondition.totalCount ?? 0}
          rowsPerPage={
            props.domainData.datasetListDisplayCondition.displayNumber
          }
          page={props.domainData.datasetListDisplayCondition.pageNumber}
          onPageChange={(event, page) => pageChange(page)}
          labelRowsPerPage={'ページあたりのアイテム数'}
          rowsPerPageOptions={[10, 30, 50, 100]}
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeDisplayNumber(parseInt(event.target.value, 10))
          }
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} / ${count}`
          }
        />
        <Fab className={classes.fab}>
          <IconButton
            onClick={() => {
              if (!isNull(contentRef.current)) {
                contentRef.current.scrollTop = 0
              }
            }}
            component='div'
          >
            <ExpandLessIcon />
          </IconButton>
        </Fab>
      </Box>
    )
  }, [
    tableContent,
    props.domainData.thumbnails,
    props.domainData.datasetListDisplayCondition,
  ])

  const datasetTemplatesFilter = useMemo(() => {
    const datasetTemplates = props.datasetTemplates.map((datasetTemplate) => {
      return {
        id: datasetTemplate.datasetTemplateId,
        name: datasetTemplate.metadata.name.ja,
      }
    })

    datasetTemplates.unshift({
      id: 'all',
      name: '全て',
    })

    return datasetTemplates
  }, [props.datasetTemplates])

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'データセット一覧',
              path: 'datasets',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <DatasetIcon
              className={classes.pageIcon}
              data-testid='DatasetListTitleIcon'
            />
            <h2 data-testid='dataset-list-title'>データセット</h2>
          </Box>
          <Box display='flex'>
            <Add />
            <Link data-testid='dataset-entry' to={`${url}/entry`}>
              <Typography>データセット作成</Typography>
            </Link>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <FormControl
            variant='outlined'
            className={classes.algorithmSelectBox}
          >
            <InputLabel id='datasetListAlgorithm'>アルゴリズム</InputLabel>
            <Select
              data-testid='select'
              labelId='datasetListAlgorithm-label'
              id='datasetListAlgorithm-outlined'
              value={
                props.domainData.datasetListDisplayCondition.selectedAlgorithmId
              }
              onChange={(e: SelectChangeEvent<string>) =>
                selectedAlgorithm(e.target.value as string)
              }
              label='Select Algorithm'
            >
              {props.algorithms.map((algorithm) => {
                return (
                  <MenuItem
                    data-testid={algorithm.algorithmId}
                    value={algorithm.algorithmId}
                    key={algorithm.algorithmId}
                  >
                    {algorithm.metadata.name.ja}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph title={''}>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (データセットID)'
                value={props.domainData.datasetListDisplayCondition.searchValue}
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
                filter={true}
                onClickFilter={() => setOpenFilterDialog(true)}
              />
            </div>
            <Box display='flex' ml={2}>
              <Box display='flex' alignItems='center'>
                <Typography>作成日時順</Typography>
                <IconButton
                  style={
                    props.domainData.datasetListDisplayCondition.sortOrder ===
                    'desc'
                      ? undefined
                      : {
                          transform: 'rotateX(180deg)',
                        }
                  }
                  onClick={() => {
                    changeTableSortOrder(
                      props.domainData.datasetListDisplayCondition.sortKey
                    )
                  }}
                >
                  <SortIcon />
                </IconButton>
              </Box>
              <Box display='flex'>
                <IconButton
                  onClick={() => {
                    setDisplayMode(
                      displayMode === 'listMode' ? 'tileMode' : 'listMode'
                    )
                  }}
                >
                  {displayMode === 'tileMode' ? (
                    <ViewCompactIcon />
                  ) : (
                    <ListIcon />
                  )}
                </IconButton>
              </Box>
            </Box>
          </div>
          {displayMode === 'listMode' ? tableModeView : tileModeView}
        </CustomTrainingPageParagraph>
        <Dialog maxWidth='md' fullWidth={true} open={openFilterDialog}>
          <DialogContent>
            <Box m={2} display='flex' alignItems='center'>
              <FilterAltIcon style={{ marginRight: '24px' }} />
              <Typography component='div'>フィルタ</Typography>
            </Box>
            <Box ml={2} mb={2}>
              <Accordion data-testid='open-accordion'>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Box display='flex' alignItems='center'>
                    <CalendarMonthIcon style={{ marginRight: '4px' }} />
                    <Typography component='div'>作成日時 範囲設定</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <DateTimeRangeInput
                    fromDate={{
                      date: createdAtFromDate,
                      setDate: (value: string) => setCreatedAtFromDate(value),
                      time: createdAtFromTime,
                      setTime: (value: string) => setCreatedAtFromTime(value),
                      enabled: createdAtFrom,
                      setEnable: (value: boolean) => setCreatedAtFrom(value),
                    }}
                    toDate={{
                      date: createdAtToDate,
                      setDate: (value: string) => setCreatedAtToDate(value),
                      time: createdAtToTime,
                      setTime: (value: string) => setCreatedAtToTime(value),
                      enabled: createdAtTo,
                      setEnable: (value: boolean) => setCreatedAtTo(value),
                    }}
                    setIsValidDateRange={(value: boolean) =>
                      setIsValidDateRange(value)
                    }
                  />
                </AccordionDetails>
              </Accordion>
              <Box mt={2} display='flex'>
                用途
              </Box>
              <RadioGroup
                value={generatedFor}
                row
                onChange={(event) => {
                  setGeneratedFor(
                    event.target.value as 'Training' | 'Inference'
                  )
                  if (event.target.value === 'Inference') {
                    setDatasetTemplateId('all')
                  }
                }}
              >
                <FormControlLabel
                  value='Training'
                  control={<Radio size='small' color='secondary' />}
                  label='学習'
                />
                <FormControlLabel
                  value='Inference'
                  control={<Radio size='small' color='secondary' />}
                  label='推論'
                />
              </RadioGroup>
              <Box mt={2} display='flex'>
                データセット情報
              </Box>
              <Select
                data-testid='select-dataset-template'
                className={classes.filterSelectBox}
                value={datasetTemplateId}
                onChange={(e) => setDatasetTemplateId(e.target.value as string)}
                disabled={generatedFor === 'Inference'}
              >
                {datasetTemplatesFilter.map((datasetTemplate) => {
                  return (
                    <MenuItem
                      data-testid={datasetTemplate.id}
                      value={datasetTemplate.id}
                      key={datasetTemplate.id}
                    >
                      {datasetTemplate.name}
                    </MenuItem>
                  )
                })}
              </Select>
              <Box mt={2} display='flex'>
                作成者
              </Box>
              <Select
                data-testid='select-created-by'
                className={classes.filterSelectBox}
                value={createdBy}
                onChange={(e) => setCreatedBy(e.target.value)}
              >
                {FILTER_CREATED_BY.map((createdBy) => {
                  return (
                    <MenuItem
                      data-testid={createdBy.value}
                      value={createdBy.value}
                      key={createdBy.value}
                    >
                      {createdBy.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>
            <div className={classes.contentBtn}>
              <Button
                className={classes.cancelButton}
                onClick={() => setOpenFilterDialog(false)}
                color={'inherit'}
              >
                閉じる
              </Button>
              <Button
                data-testid='set-filter'
                className={classes.filterSettingButton}
                disabled={!isValidDateRange}
                onClick={() => {
                  filteredTableContent(
                    createdAtFromDate,
                    createdAtFromTime,
                    createdAtFrom,
                    createdAtToDate,
                    createdAtToTime,
                    createdAtTo,
                    generatedFor,
                    datasetTemplateId,
                    createdBy
                  )
                  setOpenFilterDialog(false)
                }}
              >
                <Typography>設定</Typography>
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>

      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const tableActions = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.datasetListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentDatasetList.length >
      props.domainData.datasetListDisplayCondition.pageNumber * displayNumber
        ? props.domainData.datasetListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentDatasetList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.datasetListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getDatasetList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearDatasetList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.datasetListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.datasetListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getDatasetList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.datasetListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getDatasetList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearDatasetList()
    props.setListDisplayCondition({
      ...props.domainData.datasetListDisplayCondition,
      pageNumber: 0,
    })

    props.getDatasetList()
  }

  /** フィルタの設定 */
  const filteredTableContent = (
    createdAtFromDate: string,
    createdAtFromTime: string,
    createdAtFrom: boolean,
    createdAtToDate: string,
    createdAtToTime: string,
    createdAtTo: boolean,
    generatedFor: 'Training' | 'Inference',
    datasetTemplateId: string,
    createdBy: string
  ) => {
    props.clearDatasetList()
    props.setListDisplayCondition({
      ...props.domainData.datasetListDisplayCondition,
      pageNumber: 0,
      searchValue: '',
      createdAt: {
        from: {
          date: createdAtFromDate,
          time: createdAtFromTime,
          enabled: createdAtFrom,
        },
        to: {
          date: createdAtToDate,
          time: createdAtToTime,
          enabled: createdAtTo,
        },
      },
      generatedFor: generatedFor,
      datasetTemplateId:
        datasetTemplateId === 'all' ? undefined : datasetTemplateId,
      createdBy:
        createdBy === 'all'
          ? undefined
          : props.authedUser.auth.customClaims.accountId,
    })

    props.getDatasetList()
  }

  /** アルゴリズムの選択 */
  const selectedAlgorithm = (algorithm: string) => {
    props.clearDatasetList()
    props.setListDisplayCondition({
      ...props.domainData.datasetListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'created-at',
      sortOrder: 'desc',
      selectedAlgorithmId: algorithm,
    })
    props.getDatasetList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
    filteredTableContent,
  }
}

export const DatasetListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetList))
