import React, { useRef, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'

import { makeStyles } from 'tss-react/mui'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { State } from 'state/store'
import {
  FeatureDataUploadAction,
  featureDataUploadActions,
  featureDataUploadOperations,
  MetaData,
  FeatureDataUploadStateKindArray,
  AlgorithmDisplayCondition,
  FeatureDataGroupListDisplayCondition,
  FeatureDataGroup,
  TrainingAlgorithmVersion,
  AlgorithmStructureTableData,
} from 'state/ducks/featureData'
import {
  AlgorithmStructureVersion,
  TrainingAlgorithmVersion as TrainingAlgorithmVersionForDomain,
} from 'state/app/domainData'
import {
  SelectableTable,
  AccordionLabel,
  CommonCompleteDialog,
  CommonStepper,
  MetadataInput,
  CustomTrainingPageParagraph,
  ErrorMessage,
  GlobalLoading,
  TableDialog,
  FileSelectableDropzone,
  showToast,
  Toast,
  AccordionProgressLabel,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  SearchInput,
  TooltipLink,
  BreadcrumbsComponent,
} from 'views/components'

import {
  convertByteToMatchUnit,
  mappingKindLanguage,
} from 'views/containers/utils'
import { isUndefined } from 'utils/typeguard'
import { formatDateTimeSec } from 'views/components/utils/date'
import { FileRejection } from 'react-dropzone'
import { compareVersions, compareVersionsWithPreRelease } from 'utils/versions'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { isVersionWithPreRelease } from 'views/containers/utils/typeguard'
import { useTheme } from '@mui/material/styles'
import { hasSharedUserGroupQueryParameter } from 'views/containers/utils/queryParams'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataUploadState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataUploadAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (
    featureDataUploadStateKind: string,
    isSharedUserGroup: boolean,
    selectedFeatureDataGroupId?: string
  ) => {
    switch (featureDataUploadStateKind) {
      case 'FeatureDataGroup':
        dispatch(
          featureDataUploadOperations.getFeatureDataGroupList(
            isSharedUserGroup,
            selectedFeatureDataGroupId
          )
        )
        break
      default:
        break
    }
  },
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) => {
    if (currentStep >= FeatureDataUploadStateKindArray.length - 1) {
      /** 特徴量データアップロード */
      dispatch(featureDataUploadOperations.executeFeatureDataUpload())
    } else {
      dispatch(featureDataUploadOperations.nextStep(currentStep))
    }
  },
  /** 特徴量データグループを取得する */
  getCurrentFeatureDataGroup: (
    isSharedUserGroup: boolean,
    selectedFeatureDataGroupId: string
  ) => {
    dispatch(
      featureDataUploadOperations.getFeatureDataGroupList(
        isSharedUserGroup,
        selectedFeatureDataGroupId
      )
    )
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) => {
    dispatch(featureDataUploadOperations.prevStep(currentStep))
  },
  /** 選んだアルゴリズムをセットする */
  setSelectedAlgorithmId: (algorithmId: string) => {
    dispatch(featureDataUploadOperations.setSelectedAlgorithmId(algorithmId))
  },
  /** 選んだアルゴリズムバージョンをセットする */
  setSelectedAlgorithm: (algorithm?: TrainingAlgorithmVersionForDomain) => {
    dispatch(
      featureDataUploadOperations.setSelectedTrainingAlgorithmVersion(algorithm)
    )
  },
  /** 選んだアルゴリズムストラクチャーをセットする */
  setSelectedAlgorithmStructure: (
    algorithmStructure?: AlgorithmStructureVersion
  ) => {
    dispatch(
      featureDataUploadOperations.setSelectedTrainingAlgorithmStructureVersion(
        algorithmStructure
      )
    )
  },
  /** 選んだリストをセットする(FeatureDataGroup) */
  setSelectedFeatureDataGroup: (data?: FeatureDataGroup) =>
    dispatch(featureDataUploadOperations.setSelectedFeatureDataGroup(data)),
  /** 特徴量データバージョンの選択 */
  setSelectedFeatureDataVersion: (featureDataVersion?: string) => {
    dispatch(
      featureDataUploadActions.setSelectedFeatureDataVersion(featureDataVersion)
    )
  },
  /** 入力したメタデータをセットする */
  setFeatureDataMetaData: (data?: MetaData) =>
    dispatch(featureDataUploadOperations.setFeatureDataMetaData(data)),
  clearFeatureDataUploadState: () =>
    dispatch(featureDataUploadActions.clearFeatureDataUploadState()),
  /** アルゴリズムの表示条件の変更 */
  setAlgorithmDisplayCondition: (
    algorithmDisplayCondition: AlgorithmDisplayCondition
  ) =>
    dispatch(
      featureDataUploadActions.setAlgorithmDisplayCondition(
        algorithmDisplayCondition
      )
    ),
  /** 特徴量データグループの表示条件の変更 */
  setFeatureDataGroupListDisplayCondition: (
    featureDataGroupListDisplayCondition: FeatureDataGroupListDisplayCondition
  ) =>
    dispatch(
      featureDataUploadActions.setFeatureDataGroupListDisplayCondition(
        featureDataGroupListDisplayCondition
      )
    ),
  /** 画像セットファイル追加 */
  setInputFiles: (files: File[]) =>
    dispatch(featureDataUploadOperations.setInputFiles(files)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(featureDataUploadActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  algorithmSelectBox: {
    width: '100%',
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

/** ステップの名称 */
const STEP_NAMES = [
  'アルゴリズム',
  '特徴量データグループ',
  '特徴量データ',
  'メタデータ',
  '確認',
]
/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** アドバンスド設定のテーブルのヘッダー */
const ALGORITHM_STRUCTURE_LIST: SelectableTableHeader[] = [
  {
    id: 'config',
    title: 'コンフィグ',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'version',
    title: 'バージョン',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** アルゴリズムのテーブルのヘッダー */
const ALGORITHM_HEADERS: SelectableTableHeader[] = [
  {
    id: 'version',
    title: 'バージョン',
    width: 60,
    sortable: false,
    position: 'center',
  },
  {
    id: 'releasedAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのヘッダー */
const MODEL_GROUP_HEADERS: SelectableTableHeader[] = [
  {
    id: 'feature-data-group-id',
    title: '特徴量データグループ ID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'feature-data-group-name',
    title: '特徴量データグループ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'trained-feature-data-group-version-latest',
    title: '最新バージョン',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-feature-data-group-name-latest',
    title: '最新特徴量データ名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-feature-data-count',
    title: '登録特徴量データ数',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'updated-at',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-user-id',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const FeatureDataEntry: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  const [featureDataVersionList, setFeatureDataVersionList] = useState<
    string[]
  >([])

  /** パスパラメータのtrained-feature-data-group-idを取得 */
  const [selectedFeatureDataGroupId, setSelectedFeatureDataGroupId] = useState<
    string | undefined
  >(undefined)

  /** カレントのステップ */
  const currentStep = useMemo(
    () =>
      FeatureDataUploadStateKindArray.indexOf(
        props.appState.featureDataUploadStateKind
      ),
    [props.appState.featureDataUploadStateKind]
  )
  /** 各種一覧取得 */
  useEffect(() => {
    props.getParams(
      props.appState.featureDataUploadStateKind,
      isSharedUserGroup,
      selectedFeatureDataGroupId
    )
  }, [props.appState.featureDataUploadStateKind, selectedFeatureDataGroupId])

  useEffect(() => {
    // NOTE: 特徴量データグループIDを取得する
    const featureDataGroupId = (
      props.match.params as { [key: string]: string }
    )['featureDataGroupId']
    if (featureDataGroupId) {
      setSelectedFeatureDataGroupId(featureDataGroupId)
      props.getCurrentFeatureDataGroup(isSharedUserGroup, featureDataGroupId)
    } else {
      props.setSelectedAlgorithmId(
        props.algorithms.filter(
          (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
        )[0].algorithmId
      )
    }

    return () => {
      props.clearFeatureDataUploadState()
    }
  }, [])

  /** バージョンの配列セット */
  useEffect(() => {
    const versionNumberList =
      !isUndefined(props.domainData.selectedFeatureDataGroup) &&
      !isUndefined(props.domainData.selectedFeatureDataGroup.latestVersion) &&
      props.domainData.selectedFeatureDataGroup.latestVersion !== '0'
        ? props.domainData.selectedFeatureDataGroup.latestVersion.split('.')
        : '0.0.0'.split('.')
    const major = Number(versionNumberList[0])
    const minor = Number(versionNumberList[1])
    const patch = Number(versionNumberList[2])
    props.setSelectedFeatureDataVersion(`${major}.${minor}.${patch + 1}`)
    setFeatureDataVersionList([
      `${major}.${minor}.${patch + 1}`,
      `${major}.${minor + 1}.0`,
      `${major + 1}.0.0`,
    ])
  }, [props.domainData.selectedFeatureDataGroup])

  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(
        props.appState.toastInfo.title,
        props.appState.toastInfo.targets
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** File詳細変換 */
  const uploadFileToDetail = (file?: File) => {
    if (isUndefined(file)) return undefined
    return {
      ['ファイルサイズ']: convertByteToMatchUnit(file.size),
      ['ファイル形式']: file.name.split('.').pop(),
    }
  }

  const showErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='featureDataUploadErrorToastFileName'>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  const fileUploadErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  /** 特徴量データの表示名更新処理 */
  const updateFeatureDataMetaDataName = (name: string) => {
    props.setFeatureDataMetaData({
      ...props.domainData.featureDataMetaData,
      name,
    })
  }

  /** 特徴量データの備考更新処理 */
  const updateFeatureDataMetaDataRemarks = (remarks: string) => {
    props.setFeatureDataMetaData({
      ...props.domainData.featureDataMetaData,
      remarks,
    })
  }

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.featureDataUploadStateKind) {
      case 'AlgorithmState':
        return (
          props.appState.featureDataUploadSubStateKind.algorithmSubState ===
          'Selected'
        )
      case 'FeatureDataGroup':
        return (
          props.appState.featureDataUploadSubStateKind
            .featureDataGroupSubState === 'Selected'
        )
      case 'FeatureDataUpload':
        return (
          props.appState.featureDataUploadSubStateKind
            .featureDataUploadSubState === 'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.featureDataUploadSubStateKind.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    props.appState.featureDataUploadStateKind,
    props.appState.featureDataUploadSubStateKind,
  ])

  /** ステップ更新時のスクロール操作 */
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }, [currentStep])

  const fileType = useMemo(() => {
    const selectedAlgorithmStructure =
      props.domainData.selectedTrainingAlgorithmVersion?.algorithmStructures.find(
        (algorithmStructure) =>
          algorithmStructure.algorithmStructureId ===
          props.domainData.selectedAlgorithmStructureVersion
            ?.algorithmStructureId
      )

    const featureDataFormat = props.featureDataFormats.find(
      (featureDataFormat) =>
        selectedAlgorithmStructure?.featureDataFormatId ===
        featureDataFormat.featureDataFormatId
    )

    return featureDataFormat?.fileType
      ? `.${featureDataFormat?.fileType}`
      : '.json'
  }, [
    props.domainData.selectedTrainingAlgorithmVersion,
    props.domainData.selectedAlgorithmStructureVersion,
  ])

  /** 特徴量データアップロードの失敗時メッセージ */
  const FeatureDataUploadErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.featureDataUploadSubStateKind.executeSubState ===
      'ExecuteError'
    ) {
      errorMessages.push('特徴量データの送信に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  const {
    selectedAlgorithmIndex,
    selectAlgorithmTableRadio,
    trainingAlgorithmRows,
    trainingAlgorithmToDetail,
    setSelectRowIndex,
    tableDialog,
    selectedAlgorithmTableOrder,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    changeTableSortOrder,
    pageChange,
    featureDataGroupListRows,
    searchedFeatureDataGroupListLength,
    selectedFeatureDataGroupIndex,
    handleChangeDisplayNumber,
    selectFeatureDataGroupTableRadio,
    featureDataGroupToDetail,
    trainingAlgorithmStructureRows,
    selectAlgorithmStructureTableRadio,
    selectedAlgorithmStructureIndex,
  } = useTableActions(props)

  /** アルゴリズム選択 */
  const setSelectedAlgorithmId = (id: string) => {
    props.setSelectedAlgorithm(undefined)
    props.setSelectedAlgorithmStructure(undefined)
    props.setSelectedFeatureDataGroup(undefined)
    props.setSelectedAlgorithmId(id)
  }

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.featureDataUploadStateKind) {
      case 'AlgorithmState':
        if (isUndefined(props.algorithms)) {
          return <></>
        }
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='featureDataUpload'>アルゴリズム</InputLabel>
                <Select
                  data-testid='select'
                  labelId='featureDataUpload-label'
                  id='featureDataUpload-outlined'
                  value={props.domainData.selectedTrainingAlgorithm.algorithmId}
                  onChange={(e) =>
                    setSelectedAlgorithmId(e.target.value as string)
                  }
                  label='Select Algorithm'
                  disabled={'featureDataGroupId' in props.match.params}
                >
                  {props.algorithms
                    .filter(
                      (algorithm) =>
                        algorithm.algorithmPurpose === 'TemplateMatching'
                    )
                    .map((algorithm) => {
                      return (
                        <MenuItem
                          data-testid={algorithm.algorithmId}
                          value={algorithm.algorithmId}
                          key={algorithm.algorithmId}
                        >
                          {algorithm.metadata.name.ja}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              {!isUndefined(
                props.domainData.selectedTrainingAlgorithm.algorithmId
              ) && (
                <SelectableTable
                  headers={ALGORITHM_HEADERS}
                  fixedColumnNumber={0}
                  selectedRowNumber={selectedAlgorithmIndex}
                  page={0}
                  sortOrder={{
                    key: props.domainData.algorithmDisplayCondition.sortKey,
                    order: props.domainData.algorithmDisplayCondition.sortOrder,
                  }}
                  rows={trainingAlgorithmRows}
                  onClickOrderChange={(key: string) =>
                    selectedAlgorithmTableOrder(key)
                  }
                  onClickRadio={(row: number) => selectAlgorithmTableRadio(row)}
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                />
              )}
            </CustomTrainingPageParagraph>
            <Box mt={2} mb={2}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body1' className={classes.labelText}>
                    アドバンスド設定
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width='100%'>
                    <SelectableTable
                      headers={ALGORITHM_STRUCTURE_LIST}
                      fixedColumnNumber={0}
                      selectedRowNumber={selectedAlgorithmStructureIndex}
                      page={0}
                      rows={trainingAlgorithmStructureRows}
                      onClickRadio={(row: number) =>
                        selectAlgorithmStructureTableRadio(row)
                      }
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
        )
      case 'FeatureDataGroup':
        if (isUndefined(props.domainData.featureDataGroupList)) {
          return <></>
        }
        return (
          <div className={classes.stepContainer}>
            <div className={classes.searchForm}>
              <div className={classes.searchField}>
                <SearchInput
                  placeholder='検索（特徴量データグループID）'
                  value={tableSearchValue}
                  onChangeValue={(event) =>
                    handleChangeSearchValue(event.target.value)
                  }
                  onClickSearch={() => searchTableContent()}
                  onPressEnter={() => searchTableContent()}
                />
              </div>
            </div>
            <SelectableTable
              displayNumber={
                props.domainData.featureDataGroupListDisplayCondition
                  .displayNumber
              }
              headers={MODEL_GROUP_HEADERS}
              rows={featureDataGroupListRows}
              totalCount={
                searchedFeatureDataGroupListLength ??
                props.domainData.featureDataGroupList.length
              }
              tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
              onChangeDisplayNumber={(displayNumber: number) =>
                handleChangeDisplayNumber(displayNumber)
              }
              selectedRowNumber={selectedFeatureDataGroupIndex}
              fixedColumnNumber={0}
              page={
                props.domainData.featureDataGroupListDisplayCondition.pageNumber
              }
              sortOrder={{
                key: props.domainData.featureDataGroupListDisplayCondition
                  .sortKey,
                order:
                  props.domainData.featureDataGroupListDisplayCondition
                    .sortOrder,
              }}
              onClickRadio={(row: number) =>
                selectFeatureDataGroupTableRadio(row)
              }
              onClickOrderChange={(key: string) => changeTableSortOrder(key)}
              onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
            />
          </div>
        )
      case 'FeatureDataUpload':
        return (
          <div className={classes.stepContainer}>
            <div className={classes.stepTitle}>
              <Typography>特徴量データを選択します。 </Typography>
            </div>
            <FileSelectableDropzone
              acceptFileOperation={(files: File[]) =>
                props.setInputFiles(files)
              }
              rejectedFileOperation={(fileRejections: FileRejection[]) =>
                fileUploadErrors(fileRejections)
              }
              fileType={fileType}
              data-testid='featureDataFileDropZone'
            />
            {props.domainData.inputFiles.map((fileAndProgress, index) => (
              <Box key={index} mt={2}>
                <AccordionLabel
                  label={fileAndProgress.file.name}
                  details={uploadFileToDetail(fileAndProgress.file)}
                  prefix={'-'}
                  delimiter={':'}
                  defaultExpand={true}
                />
              </Box>
            ))}
          </div>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <Box mb={3}>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='featureDataVersionInput'>
                  特徴量データバージョン
                </InputLabel>
                <Select
                  data-testid='select'
                  labelId='featureDataVersionInput-label'
                  id='featureDataVersionInput-outlined'
                  value={props.domainData.selectedVersion}
                  onChange={(e) =>
                    props.setSelectedFeatureDataVersion(
                      e.target.value as string
                    )
                  }
                  label='Select FeatureData Version'
                >
                  {featureDataVersionList.map((value) => (
                    <MenuItem
                      data-testid={`select${value}`}
                      value={value}
                      key={value}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <MetadataInput
              nameProps={{
                label: '表示名',
                value: props.domainData.featureDataMetaData?.name,
                variant: 'outlined',
                readOnly: false,
                onChange: (e) => updateFeatureDataMetaDataName(e.target.value),
              }}
              remarksProps={{
                label: '備考',
                value: props.domainData.featureDataMetaData?.remarks,
                variant: 'outlined',
                readOnly: false,
                onChange: (e) =>
                  updateFeatureDataMetaDataRemarks(e.target.value),
                rowNum: 0,
              }}
              helperText=''
              data-testid={'featureDataUploadInput'}
            />
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <FeatureDataUploadErrorMessage {...props} />
            <Box component={Paper} mt={1} p={'24px 32px 32px'}>
              <Typography>特徴量データグループ</Typography>
              <Box mt={1}>
                <AccordionLabel
                  label={
                    props.domainData.selectedFeatureDataGroup
                      ?.featureDataGroupName
                  }
                  details={featureDataGroupToDetail(
                    props.domainData.selectedFeatureDataGroup
                  )}
                  prefix={'-'}
                  delimiter={':'}
                />
              </Box>
            </Box>
            <Box component={Paper} mt={2} p={'24px 32px 32px'}>
              <Typography>特徴量データ情報</Typography>
              <Box mt={1}>
                {props.domainData.inputFiles.map((fileAndProgress, index) => (
                  <Box key={index} mt={2}>
                    <AccordionProgressLabel
                      label={fileAndProgress.file.name}
                      details={uploadFileToDetail(fileAndProgress.file)}
                      prefix={'-'}
                      delimiter={':'}
                      progressValue={fileAndProgress.progress}
                      progressColor={'green'}
                      progressCompletedColor={'green'}
                      progressWaitingColor={'green'}
                    />
                  </Box>
                ))}
              </Box>
              <Box mt={2}>
                {props.domainData.selectedVersion && (
                  <TextField
                    id='featureDataVersion'
                    label='バージョン'
                    className={classes.textField}
                    variant='standard'
                    InputProps={{
                      readOnly: true,
                    }}
                    value={props.domainData.selectedVersion}
                    key={props.domainData.selectedVersion}
                  />
                )}
              </Box>
              <Box mt={2}>
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.featureDataMetaData?.name,
                    readOnly: true,
                    variant: 'standard',
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.featureDataMetaData?.remarks,
                    readOnly: true,
                    rowNum: 0,
                    variant: 'standard',
                  }}
                  textFieldSpace={0}
                  helperText=''
                  data-testid={'featureDataInputPreview'}
                />
              </Box>
            </Box>
            <Box component={Paper} mt={2} p={'24px 32px 32px'} mb={1}>
              <Typography>アルゴリズム</Typography>
              <Box mt={1}>
                {props.domainData.selectedTrainingAlgorithm.algorithmId && (
                  <TextField
                    id='algorithm'
                    className={classes.textField}
                    variant='standard'
                    label='アルゴリズム種別'
                    InputProps={{
                      readOnly: true,
                    }}
                    value={mappingKindLanguage(
                      props.domainData.selectedTrainingAlgorithm.algorithmId
                    )}
                    key={props.domainData.selectedTrainingAlgorithm.algorithmId}
                  />
                )}
              </Box>
              <Box mt={2}>
                <InputLabel shrink>バージョン</InputLabel>
                <Box mt={1}>
                  <AccordionLabel
                    label={''}
                    details={trainingAlgorithmToDetail(
                      props.domainData.selectedTrainingAlgorithmVersion
                    )}
                    prefix={'-'}
                    delimiter={':'}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        {(props.match.params as { [key: string]: string })[
          'featureDataGroupId'
        ] && (
          <Box pl={7}>
            <BreadcrumbsComponent
              breadcrumbsPath={[
                {
                  name: '特徴量データグループ一覧',
                  path: 'feature-data-groups',
                },
                {
                  name:
                    props.domainData.selectedFeatureDataGroup
                      ?.featureDataGroupName !== ''
                      ? `${props.domainData.selectedFeatureDataGroup?.featureDataGroupName}`
                      : `${props.domainData.selectedFeatureDataGroup?.featureDataGroupId}`,
                  path: `${props.domainData.selectedFeatureDataGroup?.featureDataGroupId}`,
                },
                {
                  name: '特徴量データ一覧',
                  path: 'feature-data',
                },
                {
                  name: '特徴量データ作成',
                  path: 'entry',
                },
              ]}
            />
          </Box>
        )}
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.head}>
            <h2
              className={classes.pageTitle}
              data-testid='feature-data-upload-title'
            >
              特徴量データ
            </h2>
            <div className={classes.stepper}>
              <CommonStepper
                stepLabels={STEP_NAMES}
                activeStepIndex={currentStep}
              />
            </div>
          </div>
          <div className={classes.content} ref={contentRef}>
            {getStepContent(props)}
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              {props.appState.featureDataUploadStateKind !==
              'AlgorithmState' ? (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={false}
                  onClick={() => props.prevStep(currentStep)}
                  className={classes.leftButton}
                >
                  {'戻る'}
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                data-testid='next-step'
                variant='contained'
                color='primary'
                disabled={!enableNextButton}
                onClick={() => props.nextStep(currentStep)}
                className={classes.rightButton}
              >
                {props.appState.featureDataUploadStateKind === 'ExecuteState'
                  ? '開始'
                  : '次へ'}
              </Button>
            </div>
          </div>
          <CommonCompleteDialog
            open={
              !isUndefined(props.domainData.executedFeatureDataId) &&
              props.domainData.fileUploadResult === 'success'
            }
            value={props.domainData.executedFeatureDataId ?? ''}
            handleClose={() => {
              if (selectedFeatureDataGroupId) {
                return history.replace(
                  `/feature-data-groups/${selectedFeatureDataGroupId}/feature-data`
                )
              } else {
                return history.replace('/feature-data')
              }
            }}
            label={'特徴量データID'}
            dialogText={'正常に特徴量データを送信しました。'}
            data-testid={'exec-feature-data-id'}
          />
          {tableDialog}
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

export const FeatureDataPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataEntry))

const useTableActions = (props: Props) => {
  const history = useHistory()
  const globalTheme = useTheme()
  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')
  /** 検索結果結果の配列の長さを保持(FeatureDataGroup) */
  const [
    searchedFeatureDataGroupListLength,
    setSearchedFeatureDataGroupListLength,
  ] = useState<number | undefined>(undefined)
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }

  /** 特徴量データグループのラジオボタンの選択された時に切り替える処理 */
  const selectFeatureDataGroupTableRadio = (row: number) => {
    const featureDataGroup = adjustFeatureDataGroupTableRow[row]
    props.setSelectedFeatureDataGroup(featureDataGroup)
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const maxPageNumber =
      Math.ceil(props.domainData.featureDataGroupList.length / displayNumber) -
      1
    if (
      props.domainData.featureDataGroupListDisplayCondition.pageNumber <=
      maxPageNumber
    ) {
      /** 表示数を変更 */
      props.setFeatureDataGroupListDisplayCondition({
        ...props.domainData.featureDataGroupListDisplayCondition,
        displayNumber: displayNumber ?? 5,
      })
    } else {
      /** 表示数を変更 */
      props.setFeatureDataGroupListDisplayCondition({
        ...props.domainData.featureDataGroupListDisplayCondition,
        pageNumber: maxPageNumber,
        displayNumber: displayNumber ?? 5,
      })
    }
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    /** ソートキー、ソートオーダーをセット */
    props.setFeatureDataGroupListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      pageNumber: 0,
      sortKey: key,
      sortOrder:
        props.domainData.featureDataGroupListDisplayCondition.sortKey === key
          ? props.domainData.featureDataGroupListDisplayCondition.sortOrder ===
            'asc'
            ? 'desc'
            : 'asc'
          : props.domainData.featureDataGroupListDisplayCondition.sortOrder,
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setFeatureDataGroupListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** アルゴリズムのテーブルをソートして返す */
  const trainingAlgorithmTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId)) {
      const dataList = props.algorithms.find(
        (item) =>
          item.algorithmId ===
          props.domainData.selectedTrainingAlgorithm.algorithmId
      )
      if (isUndefined(dataList)) {
        return
      }
      let newTrainingAlgorithmArray: TrainingAlgorithmVersionForDomain[] = []
      switch (props.domainData.algorithmDisplayCondition.sortKey) {
        case 'releasedAt':
          newTrainingAlgorithmArray =
            dataList.trainingAlgorithm.trainingAlgorithmVersions.sort(
              (item, item2) => {
                return (
                  ((item2.releasedAt
                    ? item2.releasedAt
                    : Timestamp.fromMillis(0)
                  )
                    .toDate()
                    .getTime() -
                    (item.releasedAt
                      ? item.releasedAt
                      : Timestamp.fromMillis(0)
                    )
                      .toDate()
                      .getTime()) *
                  (props.domainData.algorithmDisplayCondition.sortOrder ===
                  'desc'
                    ? 1
                    : -1)
                )
              }
            )
          return newTrainingAlgorithmArray
        case 'version':
          newTrainingAlgorithmArray =
            dataList.trainingAlgorithm.trainingAlgorithmVersions.sort(
              (item, item2) => {
                return compareVersionsWithPreRelease(
                  item.algorithmVersion,
                  item2.algorithmVersion,
                  props.domainData.algorithmDisplayCondition.sortOrder
                )
              }
            )
      }
    }
    return []
  }, [
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.algorithmDisplayCondition.sortOrder,
    props.domainData.algorithmDisplayCondition.sortKey,
  ])

  /** アルゴリズムのテーブルをソートして返す */
  const trainingAlgorithmRows = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId)) {
      const dataList = props.algorithms.find(
        (item) =>
          item.algorithmId ===
          props.domainData.selectedTrainingAlgorithm.algorithmId
      )
      if (isUndefined(dataList)) {
        return []
      }
      const rowData = dataList.trainingAlgorithm.trainingAlgorithmVersions.map(
        (data) => {
          return {
            version: data.algorithmVersion,
            releasedAt: data.releasedAt
              ? formatDateTimeSec(data.releasedAt.toDate())
              : undefined,
            remarks: data.metadata.remarks.ja,
          }
        }
      )

      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '' || isUndefined(value)) {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            if (key == 'version') {
              if (isVersionWithPreRelease(value)) {
                return <Typography key={key}>{value.displayName}</Typography>
              } else {
                return (
                  <Box
                    key={key}
                    sx={{ color: globalTheme.palette.text.secondary }}
                  >
                    <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                  </Box>
                )
              }
            }
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [
    trainingAlgorithmTableRow,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.algorithmDisplayCondition.sortOrder,
    props.domainData.algorithmDisplayCondition.sortKey,
  ])

  /** アルゴリズムストラクチャー */
  const trainingAlgorithmStructureRow = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithmVersion)) {
      const selectedAlgorithm = props.algorithms.find(
        (item) =>
          item.algorithmId ===
          props.domainData.selectedTrainingAlgorithm.algorithmId
      )
      if (isUndefined(selectedAlgorithm)) {
        return []
      }

      const selectedVersion =
        selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.find(
          (item) =>
            item.trainingAlgorithmVersion ===
            props.domainData.selectedTrainingAlgorithmVersion
              ?.trainingAlgorithmVersion
        )
      if (isUndefined(selectedVersion)) {
        return []
      }

      const structureVersions: AlgorithmStructureVersion[] = []

      selectedVersion.algorithmStructures.forEach((algorithmStructures) => {
        algorithmStructures.algorithmStructureVersions.forEach(
          (structureVersion) => {
            structureVersions.push(structureVersion)
          }
        )
      })

      let newStructureVersionsArray = structureVersions
      newStructureVersionsArray = newStructureVersionsArray.sort(
        (item, item2) => {
          return compareVersions(
            item.algorithmStructureVersion,
            item2.algorithmStructureVersion,
            'desc'
          )
        }
      )

      return newStructureVersionsArray
    }
    return []
  }, [props.domainData.selectedTrainingAlgorithmVersion])

  /** アルゴリズムストラクチャーのテーブル */
  const trainingAlgorithmStructureRows = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithmVersion)) {
      const rowData: AlgorithmStructureTableData[] = []

      trainingAlgorithmStructureRow.map((structureVersion) => {
        rowData.push({
          config: structureVersion.metadata.name.ja,
          remarks: structureVersion.metadata.remarks.ja,
          version: structureVersion.algorithmStructureVersion.displayName,
          createdAt: structureVersion.createdAt
            ? formatDateTimeSec(structureVersion.createdAt.toDate())
            : '',
        })
      })

      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '') {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [
    trainingAlgorithmStructureRow,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
  ])

  /** アルゴリズム詳細変換 */
  const trainingAlgorithmToDetail = (version?: TrainingAlgorithmVersion) => {
    if (isUndefined(version)) return undefined
    return {
      ['アルゴリズムバージョン']: version?.algorithmVersion.displayName
        ? version?.algorithmVersion.displayName
        : '',
      ['登録日時']: version.releasedAt
        ? formatDateTimeSec(version?.releasedAt.toDate())
        : '',
      ['Remarks']: version.metadata.remarks,
    }
  }

  /** アルゴリズム詳細変換(domain) */
  const trainingAlgorithmForDomainToDetail = (
    version?: TrainingAlgorithmVersionForDomain
  ) => {
    if (isUndefined(version)) return undefined
    return {
      ['アルゴリズムバージョン']: version?.algorithmVersion.displayName
        ? version?.algorithmVersion.displayName
        : '',
      ['登録日時']: version.releasedAt
        ? formatDateTimeSec(version?.releasedAt.toDate())
        : '',
      ['Remarks']: version.metadata.remarks.ja,
    }
  }

  /** 特徴量データグループ詳細変換 */
  const featureDataGroupToDetail = (featureDataGroup?: FeatureDataGroup) => {
    if (isUndefined(featureDataGroup)) return undefined
    return {
      ['特徴量データグループID']: featureDataGroup.featureDataGroupId,
      ['登録特徴量データ数']: `${featureDataGroup.featureDataCount}`,
      ['最新バージョン']: featureDataGroup.latestVersion,
      ['最新特徴量データ名']: featureDataGroup.latestFeatureDataName,
      ['更新日時']: featureDataGroup.updatedAt
        ? formatDateTimeSec(featureDataGroup.updatedAt.toDate())
        : '',
      ['作成日時']: featureDataGroup.createdAt
        ? formatDateTimeSec(featureDataGroup.createdAt.toDate())
        : '',
      ['作成ユーザーID']: featureDataGroup.createdUserId,
    }
  }

  /** 選択中のテーブルの行 */
  const [selectRowIndex, setSelectRowIndex] = useState<number | undefined>(
    undefined
  )
  /** tableDialogの選択したrowのデータを出力用に変換する */
  const convertToDetail = () => {
    if (isUndefined(selectRowIndex)) return

    if (
      isUndefined(trainingAlgorithmTableRow) ||
      isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId)
    ) {
      return undefined
    }
    return trainingAlgorithmForDomainToDetail(
      trainingAlgorithmTableRow[selectRowIndex]
    )
  }

  /** テーブルの列選択時のダイアログ */
  const tableDialog = useMemo(() => {
    if (isUndefined(selectRowIndex)) return
    /**  表示するものをここで受け取る */
    const detailItems = convertToDetail()
    if (isUndefined(detailItems)) {
      setSelectRowIndex(undefined)
      return
    }
    return (
      <TableDialog
        open={!isUndefined(selectRowIndex)}
        handleClose={() => setSelectRowIndex(undefined)}
        items={detailItems}
      />
    )
  }, [selectRowIndex])

  /** アルゴリズムテーブルのソート設定 */
  const selectedAlgorithmTableOrder = (key: string) => {
    props.setAlgorithmDisplayCondition({
      sortKey: key,
      sortOrder:
        props.domainData.algorithmDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
    })
  }

  /** アルゴリズムの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(trainingAlgorithmTableRow) &&
      isUndefined(props.domainData.selectedTrainingAlgorithmVersion)
    ) {
      const trainingAlgorithm = trainingAlgorithmTableRow[0]
      props.setSelectedAlgorithm(trainingAlgorithm)
    }
  }, [
    trainingAlgorithmTableRow,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
  ])

  /** アルゴリズムストラクチャーの初期値選択 */
  useEffect(() => {
    if (
      trainingAlgorithmStructureRow.length !== 0 &&
      isUndefined(props.domainData.selectedAlgorithmStructureVersion)
    ) {
      const trainingAlgorithmStructure = trainingAlgorithmStructureRow[0]
      props.setSelectedAlgorithmStructure(trainingAlgorithmStructure)
    }
  }, [
    trainingAlgorithmStructureRow,
    props.domainData.selectedAlgorithmStructureVersion,
  ])

  /** アルゴリズムのラジオボタンクリック */
  const selectAlgorithmTableRadio = (row: number) => {
    if (!isUndefined(trainingAlgorithmTableRow)) {
      const trainingAlgorithm = trainingAlgorithmTableRow[row]
      props.setSelectedAlgorithm(trainingAlgorithm)
    }
  }

  /** アルゴリズムテーブルの選択状態 */
  const selectedAlgorithmIndex = useMemo(() => {
    if (
      isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId) ||
      isUndefined(props.domainData.selectedTrainingAlgorithmVersion) ||
      isUndefined(trainingAlgorithmTableRow)
    )
      return -1
    return trainingAlgorithmTableRow.findIndex(
      (algorithm) =>
        algorithm.trainingAlgorithmVersion ===
        props.domainData.selectedTrainingAlgorithmVersion
          ?.trainingAlgorithmVersion
    )
  }, [
    props.algorithms,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
    trainingAlgorithmTableRow,
    props.domainData.algorithmDisplayCondition.sortOrder,
    props.domainData.algorithmDisplayCondition.sortKey,
  ])

  /** アルゴリズムテーブルの選択状態 */
  const selectedAlgorithmStructureIndex = useMemo(() => {
    if (
      isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId) ||
      isUndefined(props.domainData.selectedTrainingAlgorithmVersion) ||
      isUndefined(props.domainData.selectedAlgorithmStructureVersion) ||
      trainingAlgorithmStructureRow.length === 0
    )
      return -1
    let foundIndex = -1
    foundIndex = trainingAlgorithmStructureRow.findIndex(
      (version) =>
        version.algorithmStructureVersion.displayName ===
          props.domainData.selectedAlgorithmStructureVersion
            ?.algorithmStructureVersion.displayName &&
        version.algorithmStructureId ===
          props.domainData.selectedAlgorithmStructureVersion
            .algorithmStructureId
    )
    return foundIndex
  }, [
    props.algorithms,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
    props.domainData.selectedAlgorithmStructureVersion,
    trainingAlgorithmStructureRow,
  ])

  /** アルゴリズムストラクチャーのラジオボタンクリック */
  const selectAlgorithmStructureTableRadio = (row: number) => {
    if (trainingAlgorithmStructureRow.length !== 0) {
      props.setSelectedAlgorithmStructure(trainingAlgorithmStructureRow[row])
    }
  }

  /** 表示対象の特徴量データグループデータ */
  const adjustFeatureDataGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.featureDataGroupListDisplayCondition)) {
      let newFeatureDataGroupArray = props.domainData.featureDataGroupList
      /** 検索ワードから検索 */
      if (props.domainData.featureDataGroupListDisplayCondition.searchValue) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.filter((item) =>
          item.featureDataGroupId.startsWith(
            props.domainData.featureDataGroupListDisplayCondition.searchValue
          )
        )
        /** 検索後の配列の長さをセット */
        setSearchedFeatureDataGroupListLength(newFeatureDataGroupArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedFeatureDataGroupListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.featureDataGroupListDisplayCondition.sortKey ===
        'updated-at'
      ) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.featureDataGroupListDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1)
            )
          }
        )
      } else if (
        props.domainData.featureDataGroupListDisplayCondition.sortKey ===
        'created-at'
      ) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.featureDataGroupListDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1)
            )
          }
        )
      }
      /** 表示する特徴量データグループの整形 */
      return newFeatureDataGroupArray.slice(
        props.domainData.featureDataGroupListDisplayCondition.displayNumber *
          props.domainData.featureDataGroupListDisplayCondition.pageNumber,
        (props.domainData.featureDataGroupListDisplayCondition.pageNumber + 1) *
          props.domainData.featureDataGroupListDisplayCondition.displayNumber
      )
    }
    return props.domainData.featureDataGroupList
  }, [
    props.domainData.featureDataGroupListDisplayCondition,
    props.domainData.featureDataGroupList,
  ])

  /** テーブルに表示特徴量データグループのJSXの２次元配列 */
  const featureDataGroupListRows = useMemo(() => {
    const rowData: FeatureDataGroup[] = adjustFeatureDataGroupTableRow.map(
      (featureDataGroupList) => {
        return {
          featureDataGroupId: featureDataGroupList.featureDataGroupId,
          featureDataGroupName: featureDataGroupList.featureDataGroupName,
          latestVersion: featureDataGroupList.latestVersion,
          latestFeatureDataName: featureDataGroupList.latestFeatureDataName,
          featureDataCount: featureDataGroupList.featureDataCount,
          updatedAt: featureDataGroupList.updatedAt,
          createdAt: featureDataGroupList.createdAt,
          createdUserId: featureDataGroupList.createdUserId,
        }
      }
    )

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataGroupId') {
          return (
            <TooltipLink
              key={key}
              data-testid={`feature-data-groups-${value}`}
              title={value}
              placement='bottom'
              onClick={() => {
                history.push(`feature-data-groups/${value}`)
              }}
            />
          )
        } else if (key === 'latestVersion') {
          if (value && value !== '0') {
            return <Typography key={key}>{`v${value}`}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'updatedAt' || key === 'createdAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'createdUserId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else {
          if (value || value === 0) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustFeatureDataGroupTableRow])

  /** 特徴量データグループのテーブルの選択状態 */
  const selectedFeatureDataGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedFeatureDataGroup)) return -1
    return adjustFeatureDataGroupTableRow.findIndex(
      (featureDataGroup) =>
        featureDataGroup.featureDataGroupId ===
        props.domainData.selectedFeatureDataGroup?.featureDataGroupId
    )
  }, [
    adjustFeatureDataGroupTableRow,
    props.domainData.selectedFeatureDataGroup,
  ])

  /** 検索処理 */
  const searchTableContent = () => {
    props.setFeatureDataGroupListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      searchValue: tableSearchValue,
      pageNumber: 0,
    })
  }

  /** 特徴量データグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustFeatureDataGroupTableRow) &&
      isUndefined(props.domainData.selectedFeatureDataGroup)
    ) {
      const featureDataGroup = adjustFeatureDataGroupTableRow[0]
      props.setSelectedFeatureDataGroup(featureDataGroup)
    }
  }, [adjustFeatureDataGroupTableRow])

  return {
    selectedAlgorithmIndex,
    selectAlgorithmTableRadio,
    trainingAlgorithmRows,
    trainingAlgorithmToDetail,
    setSelectRowIndex,
    tableDialog,
    selectedAlgorithmTableOrder,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    changeTableSortOrder,
    pageChange,
    featureDataGroupListRows,
    searchedFeatureDataGroupListLength,
    selectedFeatureDataGroupIndex,
    handleChangeDisplayNumber,
    selectFeatureDataGroupTableRadio,
    featureDataGroupToDetail,
    trainingAlgorithmStructureRows,
    selectAlgorithmStructureTableRadio,
    selectedAlgorithmStructureIndex,
  }
}
