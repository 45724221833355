import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl, getArrayChunk } from 'state/utils'
import { GetSignedUrlsFunctionType } from '../datasetDetail'

const timeoutLimitSeconds = 15
const timeoutLimitSecondsForGetLogs = 30

export const InferenceDetailApi = {
  getResultLogs: (mlPipelineId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'inference-results',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
    }),
  getLogs: (mlPipelineId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-logs',
      {
        timeout: timeoutLimitSecondsForGetLogs * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
    }),
  downloadLog: (
    mlPipelineId: string,
    resourcePath: string
  ): Promise<HttpsCallableResult<{ blob: BlobPart; type: string }>> =>
    httpsCallable<
      { mlPipelineId: string; resourcePath: string },
      { blob: BlobPart; type: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-download-log',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
      resourcePath: resourcePath,
    }),
  getResult: (
    mlPipelineId: string,
    trainingDataId: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'inference-result',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
      trainingDataId: trainingDataId,
    }),
  // Cloud Functions
  getSignedUrls: async (
    fileInfoList: { id: string; fileName: string }[]
  ): Promise<{ [id: string]: string }> => {
    // signedUrl取得の単位を500ファイルずつに分割
    const fileInfoListChunks = getArrayChunk(fileInfoList, 500)

    // 取得
    const signedUrlPromises: Promise<{
      [id: string]: string
    }>[] = fileInfoListChunks.map(async (fileInfoListChunk) => {
      const getReadableSignedUrlRecordsFunction: GetSignedUrlsFunctionType =
        httpsCallable(
          functionsInstance,
          `${AidpCloudFunctionsUrl()}data-manipulation-read-thumbnail`
        )
      return (
        await getReadableSignedUrlRecordsFunction({
          fileInfoList: fileInfoListChunk,
        })
      ).data
    })

    return Object.assign({}, ...(await Promise.all(signedUrlPromises)))
  },

  getProcessedSignedUrl: async (
    userGroupId: string,
    mlPipelineId: string,
    trainingImageId: string,
    trainingImageName: string,
    expireMinutes?: number
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'data-manipulation-read-processed',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      userGroupId,
      mlPipelineId,
      trainingImageId,
      trainingImageName,
      expireMinutes,
    }),
}
