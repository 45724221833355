import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Paper from '@mui/material/Paper'
import { red } from '@mui/material/colors'

import { ErrorMessageProps } from './types'

const useStyles = makeStyles()((theme) => ({
  contentStyle: {
    padding: theme.spacing(2),
    paddingInlineStart: '5px',
    color: red[500],
    whiteSpace: 'pre-wrap',
  },
  ulStyle: {
    margin: '0 0 0 0',
    paddingInlineStart: '25px',
  },
  paperColor: {
    backgroundColor: red[50],
  },
}))

export const ErrorMessage: React.FC<ErrorMessageProps> = (
  props: ErrorMessageProps
) => {
  const { classes } = useStyles()

  return (
    <div>
      <Paper elevation={0} square={false} className={classes.paperColor}>
        <div className={classes.contentStyle}>
          {props.title}
          {props.targets ? (
            <ul className={classes.ulStyle}>
              {props.targets.map((target, index) => (
                <li key={index}>{target}</li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
      </Paper>
    </div>
  )
}
