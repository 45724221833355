import { Dispatch } from 'redux'
import { State } from 'state/store'
import { datasetAugmentationActions, initialState } from './'

import {
  PresetCameraDocument,
  PresetBackgroundDocument,
  PresetLightGroupsDocument,
  DatasetAugmentationStateKindArray,
  GenerateRequestFunctionArgsType,
  Layout,
  MetaData,
  Preset,
  Texture,
  ScenePartialData,
  Label,
  RenderersInput,
  Uploaded3dData,
  OutputFormat,
} from './types'

import { getDocs, query, where } from 'firebase/firestore'
import {
  getAugTexturesCollection,
  getAugParamTemplatesCollection,
  getAugSIObjectLayoutsCollection,
  getAugSIObjectRotationGroupsObjectsCollection,
  getAug3DSceneBackgroundsCollection,
  getAug3DSceneCamerasCollection,
  getAug3dSceneLightGroupsCollection,
} from 'state/firebase'
import { fireStoreTypeGuard as fireStoreTypeGuardForAugTextureDocument } from 'utils/fireStore/augTexture'
import { fireStoreTypeGuard as fireStoreTypeGuardForAugParamTemplateDocument } from 'utils/fireStore/augParamTemplate'
import { fireStoreTypeGuard as fireStoreTypeGuardForAugSIObjectLayoutDocument } from 'utils/fireStore/augSIObjectLayout'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneBackgroundDocument } from 'utils/fireStore/aug3dSceneBackground'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraDocument } from 'utils/fireStore/aug3dSceneCamera'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneLightGroupDocument } from 'utils/fireStore/aug3dSceneLightGroup'
import { formatDateTimeSec } from 'views/components/utils/date'
import { DatasetAugmentationApi } from './apis'
import { HttpsCallableResult } from 'firebase/functions'
import { compareVersions } from 'utils/versions'
import { domainDataOperations } from 'state/app/domainData/operations'
import { isUUIDv4 } from 'utils/typeguard'

const MAX_IMAGE_COUNT = 2000

const updateRenderSettingSubState = (
  dispatch: Dispatch,
  getState: () => State
) => {
  const selectedBackgrounds =
    getState().pages.currentDatasetAugmentationState.domainData
      .selectedBackgrounds

  const selectedLightGroups =
    getState().pages.currentDatasetAugmentationState.domainData
      .selectedLightGroups

  const selectedCameras =
    getState().pages.currentDatasetAugmentationState.domainData.selectedCameras

  const augmentationNumber =
    getState().pages.currentDatasetAugmentationState.domainData.renderersInput
      .augmentationNumber

  const generatedImageCount =
    selectedBackgrounds.length *
    selectedCameras.length *
    selectedLightGroups.length *
    augmentationNumber

  if (generatedImageCount > MAX_IMAGE_COUNT) {
    dispatch(
      datasetAugmentationActions.setToastInfo({
        type: 'error',
        title: `画像枚数が ${MAX_IMAGE_COUNT} 枚以下となるよう設定を変更してください`,
        targets: [],
      })
    )
  }

  const min =
    getState().pages.currentDatasetAugmentationState.domainData.renderersInput
      .depth.min

  const max =
    getState().pages.currentDatasetAugmentationState.domainData.renderersInput
      .depth.max

  const datasetTemplateId =
    getState().pages.currentDatasetAugmentationState.domainData.outputFormat
      ?.datasetTemplateId

  const trainValidRatio =
    getState().pages.currentDatasetAugmentationState.domainData.outputFormat
      ?.trainValidRatio

  const trainValidDatasetTemplates =
    domainDataOperations.extractDomainDatasetTemplateList([['Train', 'Valid']])(
      dispatch,
      getState
    )

  const trainValidDatasetTemplateId =
    trainValidDatasetTemplates.length > 0
      ? trainValidDatasetTemplates[0].datasetTemplateId
      : undefined

  // datasetTemplate が Train / Valid の場合は、Train Valid の比率が 0 ~ 100 で入力されていることをチェック
  const datasetTemplateValid =
    trainValidDatasetTemplateId === datasetTemplateId
      ? trainValidRatio != null &&
        trainValidRatio >= 0 &&
        trainValidRatio <= 100
      : true

  const valid =
    selectedBackgrounds.length > 0 &&
    selectedLightGroups.length > 0 &&
    selectedCameras.length > 0 &&
    max > min &&
    generatedImageCount <= MAX_IMAGE_COUNT &&
    datasetTemplateId &&
    datasetTemplateValid

  dispatch(
    datasetAugmentationActions.setDatasetAugmentationSubState({
      ...getState().pages.currentDatasetAugmentationState.appState
        .datasetAugmentationSubState,
      renderSettingSubState: valid ? 'InputRequired' : 'EmptyRequired',
    })
  )
}

export const datasetAugmentationOperations = {
  getTextureList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetAugmentationActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            datasetAugmentationActions.setTextureListDisplayCondition({
              ...getState().pages.currentDatasetAugmentationState.domainData
                .textureListDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const selectedUserGroupKind =
          getState().pages.currentDatasetAugmentationState.domainData
            .textureListDisplayCondition.selectedUserGroupKind
        const userGroupId =
          selectedUserGroupKind === 'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]

        const texture = await getDocs(
          selectedUserGroupKind === 'SharedUserGroup'
            ? query(
                getAugTexturesCollection(userGroupId),
                where('is-saved', '==', true),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            : query(
                getAugTexturesCollection(userGroupId),
                where('is-saved', '==', true)
              )
        )

        if (!texture) return

        const textures: (Texture | undefined)[] = texture.docs.map((item) => {
          const textureData = item.data()
          if (!fireStoreTypeGuardForAugTextureDocument(textureData)) {
            return undefined
          }

          return {
            id: item.id,
            name: textureData['name'],
            overview: textureData['overview'],
            userGroupId: textureData['user-group-id'],
            userGroupKind: selectedUserGroupKind,
          } as Texture
        })

        dispatch(
          datasetAugmentationActions.setTextureList(
            textures.filter((texture) => texture !== undefined) as Texture[]
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetAugmentationActions.setInProgress(false))
      }
    },
  setUploadCadData:
    (files: File[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      files.map((file) =>
        dispatch(
          datasetAugmentationActions.setUploadedCadFile({
            file: file,
            uploadStatus: 'beforeUpload',
            aug3dObjectId: '',
            label: {
              id: '',
              name: '',
              category: '',
            },
            texture: undefined,
            userUploadedTexture: undefined,
            targetId: undefined,
          })
        )
      )
      dispatch(
        datasetAugmentationActions.setDatasetAugmentationSubState({
          ...getState().pages.currentDatasetAugmentationState.appState
            .datasetAugmentationSubState,
          '3dDataSubState': 'EmptyRequired',
        })
      )
    },
  setPlacementData:
    (file: File) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetAugmentationActions.setInProgress(true))
        const augPlacementSignedUrls =
          await DatasetAugmentationApi.getPlacementSignedUrl({
            fileType: file.type,
            fileName: file.name,
          })

        const augPlacementSignedUrl = augPlacementSignedUrls.data

        try {
          await DatasetAugmentationApi.uploadFile(
            augPlacementSignedUrl.url,
            file
          )
        } catch {
          dispatch(
            datasetAugmentationActions.setToastInfo({
              type: 'error',
              title: 'ファイルアップロードに失敗しました',
              targets: [file.name],
            })
          )
          return
        }

        const result = await DatasetAugmentationApi.validatePlacement(
          0,
          augPlacementSignedUrl.id,
          file.name
        )

        if (result.data.isValid) {
          const content = JSON.parse(await file.text())
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const targetIds = content['placement'].map((placement: any) => {
            return placement['tgt_id']
          })
          const uniqTrgIds = [...new Set(targetIds)] as string[]

          const fileAndData =
            getState().pages.currentDatasetAugmentationState.domainData
              .uploaded3dData

          if (uniqTrgIds.length > fileAndData.length) {
            dispatch(
              datasetAugmentationActions.setToastInfo({
                type: 'error',
                title: '配置データの情報に対して 3Dデータの数が不足しています',
                targets: [file.name],
              })
            )
            return
          }

          dispatch(
            datasetAugmentationActions.setPlacementData({
              file: file,
              augPlacementId: augPlacementSignedUrl.id,
              targetIds: uniqTrgIds,
            })
          )
        } else {
          dispatch(
            datasetAugmentationActions.setToastInfo({
              type: 'error',
              title: 'jsonファイルの情報が不足しています',
              targets: [file.name],
            })
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetAugmentationActions.setInProgress(false))
      }
    },
  setSelectedTrgId:
    (fileName: string, targetId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const fileAndData =
        getState().pages.currentDatasetAugmentationState.domainData
          .uploaded3dData

      const reFormData = fileAndData.map((fileData) => {
        if (fileData.file.name === fileName) {
          return {
            ...fileData,
            targetId: targetId,
          } as Uploaded3dData
        } else {
          if (fileData.targetId === targetId) {
            return {
              ...fileData,
              targetId: undefined,
            } as Uploaded3dData
          } else {
            return fileData
          }
        }
      })

      dispatch(datasetAugmentationActions.setUploadedCadFileData(reFormData))

      reFormData.map((fileData, index) => {
        if (fileData.targetId === undefined) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              placementSubState: 'EmptyRequired',
            })
          )
          return
        }
        if (reFormData.length === index + 1) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              placementSubState: 'InputRequired',
            })
          )
        }
      })
    },
  setUploadTextureData:
    (fileIndex: number, file?: File) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const fileAndData =
        getState().pages.currentDatasetAugmentationState.domainData
          .uploaded3dData

      const reFormData = fileAndData.map((fileData, index) => {
        if (index === fileIndex) {
          return {
            file: fileData.file,
            uploadStatus: fileData.uploadStatus,
            aug3dObjectId: fileData.aug3dObjectId,
            label: fileData.label,
            userUploadedTexture: file
              ? {
                  id: '',
                  uploadStatus: 'beforeUpload',
                  file: file,
                }
              : undefined,
            texture: undefined,
            targetId: fileData.targetId,
          } as Uploaded3dData
        } else {
          return fileData
        }
      })

      dispatch(datasetAugmentationActions.setUploadedCadFileData(reFormData))

      reFormData.map((fileData, index) => {
        if (
          !isUUIDv4(fileData.label.id) ||
          fileData.label.name === '' ||
          (fileData.userUploadedTexture === undefined &&
            fileData.texture === undefined)
        ) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              '3dDataSubState': 'EmptyRequired',
            })
          )
          return
        }
        if (reFormData.length === index + 1) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              '3dDataSubState': 'InputRequired',
            })
          )
        }
      })
    },
  setSelectedTexture:
    (texture: Texture | undefined, fileIndex: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const fileAndData =
        getState().pages.currentDatasetAugmentationState.domainData
          .uploaded3dData

      const reFormData = fileAndData.map((fileData, index) => {
        if (index === fileIndex) {
          return {
            file: fileData.file,
            uploadStatus: fileData.uploadStatus,
            aug3dObjectId: fileData.aug3dObjectId,
            label: fileData.label,
            texture: texture,
            userUploadedTexture: texture
              ? undefined
              : fileData.userUploadedTexture,
            targetId: fileData.targetId,
          }
        } else {
          return fileData
        }
      })

      dispatch(datasetAugmentationActions.setUploadedCadFileData(reFormData))

      reFormData.map((fileData, index) => {
        if (
          !isUUIDv4(fileData.label.id) ||
          fileData.label.name === '' ||
          (fileData.userUploadedTexture === undefined &&
            fileData.texture === undefined)
        ) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              '3dDataSubState': 'EmptyRequired',
            })
          )
          return
        }
        if (reFormData.length === index + 1) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              '3dDataSubState': 'InputRequired',
            })
          )
        }
      })
    },
  deleteSelectedTexture:
    (fileIndex: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const fileAndData =
        getState().pages.currentDatasetAugmentationState.domainData
          .uploaded3dData

      const reFormData = fileAndData.map((fileData, index) => {
        if (index === fileIndex) {
          return {
            file: fileData.file,
            uploadStatus: fileData.uploadStatus,
            aug3dObjectId: fileData.aug3dObjectId,
            label: fileData.label,
            selectedTexture: undefined,
            targetId: fileData.targetId,
          }
        } else {
          return fileData
        }
      })

      dispatch(datasetAugmentationActions.setUploadedCadFileData(reFormData))

      dispatch(
        datasetAugmentationActions.setDatasetAugmentationSubState({
          ...getState().pages.currentDatasetAugmentationState.appState
            .datasetAugmentationSubState,
          '3dDataSubState': 'EmptyRequired',
        })
      )
    },
  setLabel:
    (label: Label, fileIndex: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const fileAndData =
        getState().pages.currentDatasetAugmentationState.domainData
          .uploaded3dData

      const reFormData = fileAndData.map((fileData, index) => {
        if (index === fileIndex) {
          return {
            file: fileData.file,
            uploadStatus: fileData.uploadStatus,
            aug3dObjectId: fileData.aug3dObjectId,
            label: label,
            texture: fileData.texture,
            userUploadedTexture: fileData.userUploadedTexture,
          }
        } else {
          return fileData
        }
      })

      dispatch(datasetAugmentationActions.setUploadedCadFileData(reFormData))

      reFormData.map((fileData, index) => {
        if (
          !isUUIDv4(fileData.label.id) ||
          fileData.label.name === '' ||
          (fileData.texture === undefined &&
            fileData.userUploadedTexture === undefined)
        ) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              '3dDataSubState': 'EmptyRequired',
            })
          )
          return
        }
        if (reFormData.length === index + 1) {
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              '3dDataSubState': 'InputRequired',
            })
          )
        }
      })
    },
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        datasetAugmentationActions.setDatasetAugmentationState(
          DatasetAugmentationStateKindArray[currentStep + 1]
        )
      )
      switch (currentStep) {
        case 1:
          // eslint-disable-next-line no-case-declarations
          const layouts =
            getState().pages.currentDatasetAugmentationState.domainData.layouts
          dispatch(datasetAugmentationActions.setSelectedLayout(layouts[0]))
          break
        case 2:
          // eslint-disable-next-line no-case-declarations
          const presetName =
            getState().pages.currentDatasetAugmentationState.domainData
              .selectedPreset?.name
          // eslint-disable-next-line no-case-declarations
          const regex = /\/|\s+|:/g
          dispatch(
            datasetAugmentationActions.setAugmentationMetaData({
              name: `${presetName}_${formatDateTimeSec(new Date()).replace(
                regex,
                ''
              )}`,
              remarks:
                getState().pages.currentDatasetAugmentationState.domainData
                  .augmentationMetaData?.remarks,
            })
          )
          dispatch(
            datasetAugmentationActions.setGenerateDatasetMetaData({
              name: presetName,
              remarks:
                getState().pages.currentDatasetAugmentationState.domainData
                  .generateDatasetMetaData?.remarks,
            })
          )

          if (presetName) {
            dispatch(
              datasetAugmentationActions.setDatasetAugmentationSubState({
                ...getState().pages.currentDatasetAugmentationState.appState
                  .datasetAugmentationSubState,
                metaDataSubState: 'InputRequired',
              })
            )
          }
          break
        default:
          break
      }
    },
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        datasetAugmentationActions.setDatasetAugmentationState(
          DatasetAugmentationStateKindArray[currentStep - 1]
        )
      )

      switch (currentStep) {
        case 1:
          dispatch(
            datasetAugmentationActions.setRenderersInput(
              initialState.domainData.renderersInput
            )
          )
          dispatch(datasetAugmentationActions.setOutputFormat(undefined))
          dispatch(datasetAugmentationActions.setSelectedBackgrounds([]))
          dispatch(datasetAugmentationActions.setSelectedLightGroups([]))
          dispatch(datasetAugmentationActions.setSelectedCameras([]))
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              renderSettingSubState: 'EmptyRequired',
            })
          )
          break
        case 2:
          // eslint-disable-next-line no-case-declarations
          const fileAndData =
            getState().pages.currentDatasetAugmentationState.domainData
              .uploaded3dData

          // eslint-disable-next-line no-case-declarations
          const reFormData = fileAndData.map((fileData) => {
            return {
              ...fileData,
              targetId: undefined,
            }
          })

          dispatch(
            datasetAugmentationActions.setUploadedCadFileData(reFormData)
          )

          dispatch(datasetAugmentationActions.setPlacementData(undefined))

          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              placementSubState: 'EmptyRequired',
            })
          )
          break
        case 3:
          dispatch(
            datasetAugmentationActions.setAugmentationMetaData(undefined)
          )
          dispatch(
            datasetAugmentationActions.setGenerateDatasetMetaData(undefined)
          )

          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              metaDataSubState: 'EmptyRequired',
            })
          )
          break
        default:
          break
      }
    },
  getPresetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetAugmentationActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            datasetAugmentationActions.setPresetListDisplayCondition({
              ...getState().pages.currentDatasetAugmentationState.domainData
                .presetListDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.currentDatasetAugmentationState.domainData
            .presetListDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]

        const staticUGID =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const preset = await getDocs(
          userGroupId === sharedUserGroupId
            ? query(
                getAugParamTemplatesCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            : query(getAugParamTemplatesCollection(userGroupId))
        )

        const background = await getDocs(
          query(getAug3DSceneBackgroundsCollection(staticUGID))
        )

        const sharedBackground = await getDocs(
          query(
            getAug3DSceneBackgroundsCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        const lightGroup = await getDocs(
          query(getAug3dSceneLightGroupsCollection(staticUGID))
        )

        const sharedLightGroup = await getDocs(
          query(
            getAug3dSceneLightGroupsCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        const camera = await getDocs(
          query(getAug3DSceneCamerasCollection(staticUGID))
        )

        const sharedCamera = await getDocs(
          query(
            getAug3DSceneCamerasCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        const backgrounds: (ScenePartialData | undefined)[] =
          sharedBackground.docs.concat(background.docs).map((item) => {
            const backgroundData = item.data()
            if (
              !fireStoreTypeGuardForAug3dSceneBackgroundDocument(backgroundData)
            ) {
              return undefined
            }

            return {
              id: item.id,
              name: backgroundData['name'],
              userGroupId: backgroundData['user-group-id'],
            } as ScenePartialData
          })

        dispatch(
          datasetAugmentationActions.setBackgrounds(
            backgrounds.filter(
              (background) => background !== undefined
            ) as ScenePartialData[]
          )
        )

        const lightGroups: (ScenePartialData | undefined)[] =
          sharedLightGroup.docs.concat(lightGroup.docs).map((item) => {
            const lightGroupData = item.data()
            if (
              !fireStoreTypeGuardForAug3dSceneLightGroupDocument(lightGroupData)
            ) {
              return undefined
            }

            return {
              id: item.id,
              name: lightGroupData['metadata']['name'],
              userGroupId: lightGroupData['user-group-id'],
            } as ScenePartialData
          })

        dispatch(
          datasetAugmentationActions.setLightGroups(
            lightGroups.filter(
              (lightGroup) => lightGroup !== undefined
            ) as ScenePartialData[]
          )
        )

        const cameras: (ScenePartialData | undefined)[] = sharedCamera.docs
          .concat(camera.docs)
          .map((item) => {
            const cameraData = item.data()
            if (!fireStoreTypeGuardForAug3dSceneCameraDocument(cameraData)) {
              return undefined
            }

            return {
              id: item.id,
              name: cameraData['name'],
              userGroupId: cameraData['user-group-id'],
            } as ScenePartialData
          })

        dispatch(
          datasetAugmentationActions.setCameras(
            cameras.filter(
              (camera) => camera !== undefined
            ) as ScenePartialData[]
          )
        )

        if (!preset) return

        const presets: (Preset | undefined)[] = preset.docs.map((item) => {
          const presetData = item.data()
          if (!fireStoreTypeGuardForAugParamTemplateDocument(presetData)) {
            return undefined
          }

          const presetBackgrounds: ScenePartialData[] = []
          const presetCameras: ScenePartialData[] = []
          const presetLightGroups: ScenePartialData[] = []

          presetData['3d-scene']['backgrounds'].map(
            (presetBackground: PresetBackgroundDocument) => {
              const background = backgrounds.find(
                (backgroundData) =>
                  backgroundData &&
                  presetBackground['aug-3d-scene-background-id'] ===
                    backgroundData.id
              )
              if (!background) return
              presetBackgrounds.push(background)
            }
          )

          presetData['3d-scene']['cameras'].map(
            (presetCamera: PresetCameraDocument) => {
              const camera = cameras.find(
                (cameraData) =>
                  cameraData &&
                  presetCamera['aug-3d-scene-camera-id'] === cameraData.id
              )
              if (!camera) return
              presetCameras.push(camera)
            }
          )

          presetData['3d-scene']['light-groups'].map(
            (presetLightGroup: PresetLightGroupsDocument) => {
              const lightGroup = lightGroups.find(
                (lightGroupData) =>
                  lightGroupData &&
                  presetLightGroup['aug-3d-scene-light-group-id'] ===
                    lightGroupData.id
              )
              if (!lightGroup) return
              presetLightGroups.push(lightGroup)
            }
          )

          return {
            id: item.id,
            name: presetData['metadata']['name'],
            overview: presetData['metadata']['overview'],
            backgrounds: presetBackgrounds,
            cameras: presetCameras,
            lightGroups: presetLightGroups,
            createdAt: presetData['created-at'],
          } as Preset
        })

        dispatch(
          datasetAugmentationActions.setPresetList(
            presets.filter((preset) => preset !== undefined) as Preset[]
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetAugmentationActions.setInProgress(false))
      }
    },
  setSelectedPreset:
    (preset?: Preset) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(datasetAugmentationActions.setSelectedPreset(preset))

      if (preset) {
        dispatch(
          datasetAugmentationActions.setSelectedBackgrounds(preset.backgrounds)
        )
        dispatch(
          datasetAugmentationActions.setSelectedLightGroups(preset.lightGroups)
        )
        dispatch(datasetAugmentationActions.setSelectedCameras(preset.cameras))
      }

      updateRenderSettingSubState(dispatch, getState)
    },
  setSelectedBackgrounds:
    (selectedBackgrounds: ScenePartialData[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        datasetAugmentationActions.setSelectedBackgrounds(selectedBackgrounds)
      )

      updateRenderSettingSubState(dispatch, getState)
    },
  setSelectedLightGroups:
    (selectedLightGroups: ScenePartialData[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        datasetAugmentationActions.setSelectedLightGroups(selectedLightGroups)
      )

      updateRenderSettingSubState(dispatch, getState)
    },
  setSelectedCameras:
    (selectedCameras: ScenePartialData[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(datasetAugmentationActions.setSelectedCameras(selectedCameras))

      updateRenderSettingSubState(dispatch, getState)
    },
  getLayouts:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetAugmentationActions.setInProgress(true))

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        // 共有データの aug-si-object-rotation-groups を指定（固定）
        const sharedRotationGroups = await getDocs(
          query(
            getAugSIObjectRotationGroupsObjectsCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        const sharedRotationGroup = sharedRotationGroups.docs[0].data()

        dispatch(
          datasetAugmentationActions.setAugSiObjectRotation({
            userGroupId: sharedRotationGroup['user-group-id'],
            augSiObjectRotationGroupId:
              sharedRotationGroup['aug-si-object-rotation-group-id'],
          })
        )

        const sharedLayout = await getDocs(
          query(
            getAugSIObjectLayoutsCollection(sharedUserGroupId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
        )

        if (sharedLayout.docs.length === 0) return

        const layouts: (Layout | undefined)[] = sharedLayout.docs.map(
          (layout) => {
            const layoutData = layout.data()
            if (!fireStoreTypeGuardForAugSIObjectLayoutDocument(layoutData)) {
              return undefined
            }

            return {
              id: layout.id,
              name: layoutData['name'],
              userGroupId: layoutData['user-group-id'],
            } as Layout
          }
        )

        dispatch(
          datasetAugmentationActions.setLayouts(
            layouts.filter((layout) => layout !== undefined) as Layout[]
          )
        )
        dispatch(datasetAugmentationActions.setSelectedLayout(layouts[0]))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetAugmentationActions.setInProgress(false))
      }
    },
  setSelectedLayout:
    (selectedLayout: Layout) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(datasetAugmentationActions.setSelectedLayout(selectedLayout))
    },
  setRenderersInput:
    (renderersInput: RenderersInput) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(datasetAugmentationActions.setRenderersInput(renderersInput))

      updateRenderSettingSubState(dispatch, getState)
    },
  setOutputFormat:
    (outputFormat: OutputFormat) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(datasetAugmentationActions.setOutputFormat(outputFormat))

      updateRenderSettingSubState(dispatch, getState)
    },
  setAugmentationMetaData:
    (augmentationMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        datasetAugmentationActions.setAugmentationMetaData(augmentationMetaData)
      )

      if (
        augmentationMetaData?.name &&
        getState().pages.currentDatasetAugmentationState.domainData
          .generateDatasetMetaData?.name
      ) {
        dispatch(
          datasetAugmentationActions.setDatasetAugmentationSubState({
            ...getState().pages.currentDatasetAugmentationState.appState
              .datasetAugmentationSubState,
            metaDataSubState: 'InputRequired',
          })
        )
      } else {
        dispatch(
          datasetAugmentationActions.setDatasetAugmentationSubState({
            ...getState().pages.currentDatasetAugmentationState.appState
              .datasetAugmentationSubState,
            metaDataSubState: 'EmptyRequired',
          })
        )
      }
    },
  setGenerateDatasetMetaData:
    (generateDatasetMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        datasetAugmentationActions.setGenerateDatasetMetaData(
          generateDatasetMetaData
        )
      )

      if (
        generateDatasetMetaData?.name &&
        getState().pages.currentDatasetAugmentationState.domainData
          .augmentationMetaData?.name
      ) {
        dispatch(
          datasetAugmentationActions.setDatasetAugmentationSubState({
            ...getState().pages.currentDatasetAugmentationState.appState
              .datasetAugmentationSubState,
            metaDataSubState: 'InputRequired',
          })
        )
      } else {
        dispatch(
          datasetAugmentationActions.setDatasetAugmentationSubState({
            ...getState().pages.currentDatasetAugmentationState.appState
              .datasetAugmentationSubState,
            metaDataSubState: 'EmptyRequired',
          })
        )
      }
    },
  executeDatasetAugmentation:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(datasetAugmentationActions.setInProgress(true))
      try {
        const {
          uploaded3dData,
          augmentationMetaData,
          generateDatasetMetaData,
          selectedLayout,
          renderersInput,
          layoutSeed,
          rotation,
          postProcessing,
          selectedBackgrounds,
          selectedCameras,
          selectedLightGroups,
          uploadedPlacementData,
          outputFormat,
        } = getState().pages.currentDatasetAugmentationState.domainData

        if (
          !(
            uploaded3dData &&
            augmentationMetaData?.name &&
            generateDatasetMetaData?.name &&
            layoutSeed &&
            selectedLayout &&
            renderersInput &&
            selectedBackgrounds &&
            selectedCameras &&
            selectedLightGroups &&
            rotation &&
            uploadedPlacementData &&
            outputFormat?.datasetTemplateId
          )
        ) {
          console.error('Error invalid request')
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              executeSubState: 'ExecuteError',
            })
          )
          return
        }

        const filteredUploaded3dData = uploaded3dData.filter(
          (fileAndData) => fileAndData.targetId !== undefined
        )

        const uploadTextures = filteredUploaded3dData.map((fileData) => {
          if (fileData.userUploadedTexture) {
            return {
              fileType: fileData.userUploadedTexture.file.type,
              fileName: fileData.userUploadedTexture.file.name,
            }
          }
        })

        const augTextureSignedUrls =
          await DatasetAugmentationApi.getTextureSignedUrl(
            uploadTextures.filter(
              (uploadTextures) => uploadTextures !== undefined
            ) as { fileType: string; fileName: string }[]
          )

        const augTextureSignedUrl = augTextureSignedUrls.data

        const textureFileUpload = filteredUploaded3dData.map(
          async (fileAndData, index) => {
            try {
              if (fileAndData.userUploadedTexture) {
                await DatasetAugmentationApi.uploadFile(
                  augTextureSignedUrl[fileAndData.userUploadedTexture.file.name]
                    .url,
                  fileAndData.userUploadedTexture.file
                )

                filteredUploaded3dData[index].userUploadedTexture = {
                  id: augTextureSignedUrl[
                    fileAndData.userUploadedTexture.file.name
                  ].id,
                  uploadStatus: 'completed',
                  file: fileAndData.userUploadedTexture.file,
                }

                dispatch(
                  datasetAugmentationActions.setUploadedCadFileData(
                    filteredUploaded3dData
                  )
                )
              }
            } catch {
              if (fileAndData.userUploadedTexture) {
                filteredUploaded3dData[index].userUploadedTexture = {
                  id: fileAndData.userUploadedTexture.id,
                  uploadStatus: 'uploadError',
                  file: fileAndData.userUploadedTexture.file,
                }

                dispatch(
                  datasetAugmentationActions.setUploadedCadFileData(
                    filteredUploaded3dData
                  )
                )
              }
            }
          }
        )

        await Promise.all(textureFileUpload)

        // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
        const uploadTextureFailedList = filteredUploaded3dData
          .filter(
            (file) =>
              file.userUploadedTexture &&
              file.userUploadedTexture.uploadStatus !== 'completed'
          )
          .map((file) => file.userUploadedTexture?.file.name) as string[]

        if (uploadTextureFailedList.length > 0) {
          const displayNameCnt = 3
          const targets = uploadTextureFailedList
            .slice(0, displayNameCnt)
            .concat(
              uploadTextureFailedList.length > displayNameCnt
                ? [`他${uploadTextureFailedList.length - displayNameCnt}件`]
                : []
            )
          dispatch(
            datasetAugmentationActions.setToastInfo({
              type: 'error',
              title: 'ファイルアップロードに失敗しました',
              targets,
            })
          )
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              executeSubState: 'ExecuteError',
            })
          )
          return
        }

        await Promise.all(
          filteredUploaded3dData.map(async (fileData) => {
            try {
              if (fileData.userUploadedTexture) {
                await DatasetAugmentationApi.executeTextureUpload({
                  augTextureId: fileData.userUploadedTexture
                    ? fileData.userUploadedTexture.id
                    : '',
                  augTextureKind: '',
                  isSaved: false,
                  name: '',
                  overview: '',
                  revision: {
                    textureRevision: 0,
                    config: {
                      name: '',
                      type: '',
                    },
                    fileNames: [fileData.userUploadedTexture.file.name],
                  },
                })
              }
            } catch {
              throw new Error('Failed to create Aug Texture')
            }
          })
        )

        const aug3DObjectSignedUrls = await DatasetAugmentationApi.getSignedUrl(
          filteredUploaded3dData.map((fileData) => {
            return {
              fileType: fileData.file.type,
              fileName: fileData.file.name,
            }
          })
        )

        const url = aug3DObjectSignedUrls.data

        const fileUpload = filteredUploaded3dData
          .filter((fileAndData) => fileAndData.uploadStatus !== 'completed')
          .map(async (fileAndData, index) => {
            try {
              await DatasetAugmentationApi.uploadFile(
                url[fileAndData.file.name].url,
                fileAndData.file
              )
              filteredUploaded3dData[index].aug3dObjectId =
                url[fileAndData.file.name].id
              filteredUploaded3dData[index].uploadStatus = 'completed'
            } catch {
              filteredUploaded3dData[index].uploadStatus = 'uploadError'
            }
          })

        await Promise.all(fileUpload)

        dispatch(
          datasetAugmentationActions.setUploadedCadFileData(
            filteredUploaded3dData
          )
        )

        // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
        const uploadFailedList = filteredUploaded3dData
          .filter((file) => file.uploadStatus !== 'completed')
          .map((file) => file.file.name)

        if (uploadFailedList.length > 0) {
          const displayNameCnt = 3
          const targets = uploadFailedList
            .slice(0, displayNameCnt)
            .concat(
              uploadFailedList.length > displayNameCnt
                ? [`他${uploadFailedList.length - displayNameCnt}件`]
                : []
            )
          dispatch(
            datasetAugmentationActions.setToastInfo({
              type: 'error',
              title: 'ファイルアップロードに失敗しました',
              targets,
            })
          )
          dispatch(
            datasetAugmentationActions.setDatasetAugmentationSubState({
              ...getState().pages.currentDatasetAugmentationState.appState
                .datasetAugmentationSubState,
              executeSubState: 'ExecuteError',
            })
          )
          return
        }

        await Promise.all(
          filteredUploaded3dData.map(async (fileData) => {
            try {
              await DatasetAugmentationApi.executeCadUpload({
                aug3dObjectId: fileData.aug3dObjectId,
                aug3dObjectKind: fileData.file.name.split('.').pop() ?? '',
                isSaved: false,
                fileName: fileData.file.name,
              })
            } catch {
              throw new Error('Failed to create Aug 3D Objects')
            }
          })
        )

        try {
          const placementData =
            getState().pages.currentDatasetAugmentationState.domainData
              .uploadedPlacementData

          await DatasetAugmentationApi.executePlacementUpload({
            augPlacementId: placementData ? placementData.augPlacementId : '',
            isSaved: false,
            name: '',
            overview: '',
            revision: {
              placementRevision: 0,
              fileNames: [placementData ? placementData.file.name : ''],
            },
          })
        } catch {
          throw new Error('Failed to create Aug Placement')
        }

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const appDomainData = getState().app.domainData

        const annotationFormat = appDomainData.annotationFormats.find(
          (annotationFormat) => annotationFormat.annotationFormatKind === 'coco'
        )

        const annotationFormatVersion =
          annotationFormat?.annotationFormatVersions.sort((item, item2) => {
            return compareVersions(
              item.annotationFormatVersion,
              item2.annotationFormatVersion,
              'desc'
            )
          })[0].annotationFormatVersion

        const generateRequestParams: GenerateRequestFunctionArgsType = {
          mlPipelineMetadata: {
            name: augmentationMetaData.name,
            remarks: augmentationMetaData.remarks
              ? augmentationMetaData.remarks
              : '',
          },
          datasetMetadata: {
            name: generateDatasetMetaData.name,
            remarks: generateDatasetMetaData.remarks
              ? generateDatasetMetaData.remarks
              : '',
          },
          targets: filteredUploaded3dData.map((fileAndData) => {
            return {
              ['3dObject']: {
                aug3dObjectId: fileAndData.aug3dObjectId,
                userGroupId: userGroupId,
              },
              label: {
                labelId: fileAndData.label.id,
                name: fileAndData.label.name,
                supercategory: fileAndData.label.category,
              },
              texture: {
                augTextureId: fileAndData.texture
                  ? fileAndData.texture.id
                  : fileAndData.userUploadedTexture
                  ? fileAndData.userUploadedTexture.id
                  : '',
                userGroupId: fileAndData.texture
                  ? fileAndData.texture.userGroupId
                  : userGroupId,
              },
            }
          }),
          placement: {
            layouts: [
              {
                augSiObjectLayoutId: selectedLayout.id,
                userGroupId: selectedLayout.userGroupId,
                siObject: {
                  augSiObjectPlacementId: uploadedPlacementData.augPlacementId,
                  userGroupId: userGroupId,
                  relations: filteredUploaded3dData.map((fileAndData) => {
                    return {
                      targetId: Number(fileAndData.targetId),
                      aug3dObjectId: fileAndData.aug3dObjectId,
                      userGroupId: userGroupId,
                    }
                  }),
                },
                seed: layoutSeed,
                rotation: {
                  userGroupId: rotation.userGroupId,
                  augSiObjectRotationGroupId:
                    rotation.augSiObjectRotationGroupId,
                },
              },
            ],
          },
          ['3dScene']: {
            backgrounds: selectedBackgrounds.map((background) => {
              return {
                aug3dSceneBackgroundId: background.id,
                userGroupId: background.userGroupId,
              }
            }),
            lightGroups: selectedLightGroups.map((lightGroup) => {
              return {
                aug3dSceneLightGroupId: lightGroup.id,
                userGroupId: lightGroup.userGroupId,
              }
            }),
            cameras: selectedCameras.map((camera) => {
              return {
                aug3dSceneCameraId: camera.id,
                userGroupId: camera.userGroupId,
              }
            }),
          },
          renderers: [
            {
              augmentationNumber: renderersInput.augmentationNumber,
              size: {
                width: renderersInput.size.width,
                height: renderersInput.size.height,
              },
              depth: {
                min: renderersInput.depth.min,
                max: renderersInput.depth.max,
              },
              postProcessing: {
                type: postProcessing,
              },
            },
          ],
          dataset: {
            format: {
              algorithmId:
                appDomainData.algorithms.find(
                  (algorithm) =>
                    algorithm.algorithmPurpose === 'ObjectRecognition'
                )?.algorithmId ?? '',
              datasetTemplateId: outputFormat.datasetTemplateId,
              annotationFormatId: annotationFormat
                ? annotationFormat.annotationFormatId
                : '',
              annotationFormatVersion: {
                displayName: annotationFormatVersion?.displayName ?? '',
                major: annotationFormatVersion?.major ?? 0,
                minor: annotationFormatVersion?.minor ?? 0,
                patch: annotationFormatVersion?.patch ?? 0,
              },
            },
            extended:
              outputFormat.trainValidRatio != null
                ? {
                    trainValid: {
                      ratio: outputFormat.trainValidRatio,
                    },
                  }
                : undefined,
          },
        }

        const result = (await DatasetAugmentationApi.executeDatasetAugmentation(
          generateRequestParams
        )) as HttpsCallableResult<{
          mlPipelineId: string
          stepId: string
        }>

        dispatch(
          datasetAugmentationActions.setDatasetAugmentationSubState({
            ...getState().pages.currentDatasetAugmentationState.appState
              .datasetAugmentationSubState,
            executeSubState: 'Executed',
          })
        )

        dispatch(
          datasetAugmentationActions.setExecutedInfo({
            mlPipelineId: result.data.mlPipelineId,
            stepId: result.data.stepId,
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          datasetAugmentationActions.setDatasetAugmentationSubState({
            ...getState().pages.currentDatasetAugmentationState.appState
              .datasetAugmentationSubState,
            executeSubState: 'ExecuteError',
          })
        )
      } finally {
        dispatch(datasetAugmentationActions.setInProgress(false))
      }
    },
}
