import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  AccountDetailInfo,
  AccountGroup,
  AccountListActionType,
  AccountListPagingState,
} from './types'

import { AccountListDisplayCondition } from 'utils/ducks/accountList/types'

export type AccountListActions = ActionsUnion<typeof accountListActions>

export const accountListActions = {
  setAccountList: (
    accountList: AccountDetailInfo[]
  ): ActionWithPayload<
    'SET_ACCOUNT_LIST',
    { accountList: AccountDetailInfo[] }
  > =>
    CreateAction(AccountListActionType.SET_ACCOUNT_LIST, {
      accountList,
    }),
  setAccountGroupList: (
    accountGroupList: AccountGroup[]
  ): ActionWithPayload<
    'SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_LIST',
    { accountGroupList: AccountGroup[] }
  > =>
    CreateAction(
      AccountListActionType.SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_LIST,
      {
        accountGroupList,
      }
    ),
  setSelectedAccountGroupId: (
    accountGroupId: string
  ): ActionWithPayload<
    'SET_SELECTED_ACCOUNT_GROUP_ID_FOR_ACCOUNT_LIST',
    { accountGroupId: string }
  > =>
    CreateAction(
      AccountListActionType.SET_SELECTED_ACCOUNT_GROUP_ID_FOR_ACCOUNT_LIST,
      {
        accountGroupId,
      }
    ),
  setListDisplayCondition: (
    accountListDisplayCondition: AccountListDisplayCondition
  ): ActionWithPayload<
    'SET_ACCOUNT_LIST_DISPLAY_CONDITION',
    { accountListDisplayCondition: AccountListDisplayCondition }
  > =>
    CreateAction(AccountListActionType.SET_ACCOUNT_LIST_DISPLAY_CONDITION, {
      accountListDisplayCondition,
    }),
  setAccountListPagingState: (
    accountListPageState: AccountListPagingState
  ): ActionWithPayload<
    'SET_ACCOUNT_LIST_PAGING_STATE',
    { accountListPageState: AccountListPagingState }
  > =>
    CreateAction(AccountListActionType.SET_ACCOUNT_LIST_PAGING_STATE, {
      accountListPageState,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ACCOUNT_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(AccountListActionType.SET_IN_PROGRESS_FOR_ACCOUNT_LIST, {
      inProgress,
    }),
  clearAccountListState: (): Action<'CLEAR_ACCOUNT_LIST_STATE'> =>
    CreateAction(AccountListActionType.CLEAR_ACCOUNT_LIST_STATE),
  clearDisplayCondition: (): Action<'CLEAR_DISPLAY_CONDITION'> =>
    CreateAction(AccountListActionType.CLEAR_DISPLAY_CONDITION),
}
