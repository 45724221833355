import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'

import { ChangeCustomerDialogState } from 'views/App'

interface Props {
  currentCustomerName: string
  nextCustomerName: string
  dialogState: ChangeCustomerDialogState
  onClickOk: () => void
  onClickCancel: () => void
}

const useStyles = makeStyles()((theme) => ({
  dialogStyle: {
    maxWidth: '720px',
  },
  okButton: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    backgroundColor: '#D9E5FF',
    '&:hover': {
      backgroundColor: '#D9E5FF',
    },
  },
  cancelButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  contentBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

export const CustomerChangeConfirmDialog: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()

  return (
    <>
      <Dialog maxWidth='lg' open={true}>
        <Box m={2} className={classes.dialogStyle}>
          <DialogContent>
            <Typography>{`カスタマーを ${props.nextCustomerName} に切り替えますか？`}</Typography>
            <Typography>
              {`現在の ${props.currentCustomerName} で操作途中のものがある場合は、最初からやり直す必要があります。`}
            </Typography>
          </DialogContent>
          <Box className={classes.contentBtn}>
            <Button
              className={classes.cancelButton}
              onClick={props.onClickCancel}
              color={'inherit'}
            >
              キャンセル
            </Button>
            <Button
              className={classes.okButton}
              variant='outlined'
              onClick={props.onClickOk}
            >
              <Typography color='primary'>OK</Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}
