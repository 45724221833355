import React from 'react'
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined'
import IconButton from '@mui/material/IconButton'

import { DatasetIconProps } from './types'

export const DatasetIcon: React.FC<DatasetIconProps> = (
  props: DatasetIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <TableChartOutlinedIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
