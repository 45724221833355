import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { CopyableLabelProps } from './types'

const useStyles = makeStyles()(() => ({
  linkButton: {
    textTransform: 'none',
  },
}))

export const CopyableLabel: React.FC<CopyableLabelProps> = (
  props: CopyableLabelProps
) => {
  const { classes } = useStyles()

  if (!props.value) {
    return <></>
  }

  return (
    <>
      {props.isTooltip ? (
        <Tooltip title={props.value} placement={props.placement}>
          <Button
            onClick={() => navigator.clipboard.writeText(props.value)}
            className={classes.linkButton}
            sx={{ color: 'text.primary' }}
          >
            {props.value.substring(0, 8)}
            <ContentCopyIcon style={{ marginLeft: '4px' }} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={() => navigator.clipboard.writeText(props.value)}
          className={classes.linkButton}
          sx={{ color: 'text.primary' }}
        >
          {props.value.substring(0, 8)}
          <ContentCopyIcon style={{ marginLeft: '4px' }} />
        </Button>
      )}
    </>
  )
}
