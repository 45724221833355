import { Timestamp } from 'state/firebase'

export const AdminModelListActionType = {
  GET_MODEL_LIST: 'GET_MODEL_LIST',
  SET_MODEL_LIST: 'SET_MODEL_LIST',
  CLEAR_MODEL_LIST: 'CLEAR_MODEL_LIST',
  SET_MODEL_LIST_DISPLAY_CONDITION: 'SET_MODEL_LIST_DISPLAY_CONDITION',
  SET_IN_PROGRESS_FOR_MODEL_LIST: 'SET_IN_PROGRESS_FOR_MODEL_LIST',
  CLEAR_MODEL_LIST_STATE: 'CLEAR_MODEL_LIST_STATE',
} as const

export interface ModelGroupVersion {
  displayName: string
  major: number
  minor: number
  patch: number
}

export interface ModelInfo {
  modelId: string
  modelName: string
  modelKind: 'Generic' | 'Custom' | 'Specified'
  modelGroupVersion: ModelGroupVersion
  modelGroupId: string
  generatedAt?: Timestamp
  mlPipeline: {
    mlPipelineId: string
    mlPipelineKind?: 'CustomTraining' | 'Inference' | 'Build' | 'PreProcessing'
  }
  uid: string
}

export interface ModelListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  algorithmId?: string
  totalCount?: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface ModelListDomainData {
  currentModelList: ModelInfo[]
  modelListDisplayCondition: ModelListDisplayCondition
}

export interface ModelListAppState {
  inProgress: boolean
}

export interface AdminModelInfoList {
  domainData: ModelListDomainData
  appState: ModelListAppState
}
