import React, { useRef, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import clsx from 'clsx'

import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { State } from 'state/store'
import {
  FeatureDataGroupEntryAction,
  featureDataGroupEntryActions,
  featureDataGroupEntryOperations,
  FeatureDataGroupEntryStateKindArray,
  FeatureDataGroupEntryStateKind,
} from 'state/ducks/featureDataGroupEntry'
import {
  CommonCompleteDialog,
  CommonStepper,
  CustomTrainingPageParagraph,
  GlobalLoading,
  showToast,
  Toast,
  FileUploadViewer,
  BreadcrumbsComponent,
} from 'views/components'

import { isUndefined } from 'utils/typeguard'
import { FileRejection } from 'react-dropzone'
import { ToastInfo } from 'state/utils'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataGroupEntryState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataGroupEntryAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データグループ作成の状態をクリア */
  clearFeatureDataGroupEntryState: () =>
    dispatch(featureDataGroupEntryActions.clearFeatureDataGroupEntryState()),
  /** 特徴量データグループ作成のアルゴリズムをセット */
  setSelectedAlgorithm: (selectedAlgorithm: string) =>
    dispatch(
      featureDataGroupEntryActions.setSelectedAlgorithm(selectedAlgorithm)
    ),
  /** 特徴量データグループ名をセット */
  setFeatureDataGroupMetadataName: (featureDataGroupName: string) =>
    dispatch(
      featureDataGroupEntryActions.setFeatureDataGroupMetadataName(
        featureDataGroupName
      )
    ),
  /** 特徴量データグループの概要をセット */
  setFeatureDataGroupOverView: (overView: string) =>
    dispatch(
      featureDataGroupEntryActions.setFeatureDataGroupOverView(overView)
    ),
  /** 特徴量データグループのアイコンをセット */
  setFeatureDataGroupIcon: (featureDataGroupIcon: File | undefined) =>
    dispatch(
      featureDataGroupEntryActions.setFeatureDataGroupIcon(featureDataGroupIcon)
    ),
  /** 特徴量データグループのremarksをセット */
  setFeatureDataGroupRemarks: (remarks: string) =>
    dispatch(featureDataGroupEntryActions.setFeatureDataGroupRemarks(remarks)),
  /** 特徴量データグループ作成の進む戻る */
  setFeatureDataGroupState: (step: FeatureDataGroupEntryStateKind) =>
    dispatch(featureDataGroupEntryActions.setFeatureDataGroupState(step)),
  /** アルゴリズム選択状態 */
  setAlgorithmSubState: (algorithmSubState: 'Unselected' | 'Selected') =>
    dispatch(
      featureDataGroupEntryActions.setAlgorithmSubState(algorithmSubState)
    ),
  /** メタデータ入力状態 */
  setMetadataSubState: (metadataSubState: 'EmptyRequired' | 'InputRequired') =>
    dispatch(
      featureDataGroupEntryActions.setMetadataSubState(metadataSubState)
    ),
  /** 特徴量データグループ作成 */
  createFeatureDataGroup: () =>
    dispatch(featureDataGroupEntryOperations.createFeatureDataGroup()),
  /** トーストの情報をセット */
  setToastInfo: (toastInfo?: ToastInfo) =>
    dispatch(featureDataGroupEntryActions.setToastInfo(toastInfo)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  algorithmSelectBox: {
    width: '100%',
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  featureDataGroupParamsTitle: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  featureDataGroupParamsContent: {
    width: '100%',
  },
  iconDropZone: {
    width: '200px',
    position: 'relative',
  },
  floatButtons: {
    zIndex: 1,
    position: 'absolute',
    color: theme.palette.common.black,
  },
  menuIcon: {
    top: theme.spacing(-1),
    right: theme.spacing(-8),
  },
  deleteItem: { color: theme.palette.secondary.main },
  iconPreview: {
    objectFit: 'contain',
    display: 'block',
    width: '200px',
    height: '200px',
  },
  iconNoImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #333',
    width: '200px',
    height: '200px',
  },
  notApplicable: {
    color: theme.palette.text.secondary,
  },
}))

/** ステップの名称 */
const STEP_NAMES = ['アルゴリズム', 'メタデータ', '確認']
/** テーブルのセルのデータ未存在時の表示 */
const FEATURE_DATA_GROUP_PARAM_NOT_APPLICABLE = 'N/A'

const FeatureDataGroupEntry: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  /** step */
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [canShowNameError, setCanShowNameError] = useState(false)
  const getErrorStatus = (canShowErrorState: boolean, prop?: string) =>
    canShowErrorState && (!prop || prop === '')

  /** fileのbase64文字列を保持 */
  const [fileToBase64, setFileToBase64] = useState<string>('')

  useEffect(() => {
    // アルゴリズムの一覧を取得
    props.setSelectedAlgorithm(
      props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
      )[0].algorithmId
    )
    return () => {
      props.clearFeatureDataGroupEntryState()
    }
  }, [])

  /** currentStepが変更されらstepを変更 */
  useEffect(() => {
    props.setFeatureDataGroupState(
      FeatureDataGroupEntryStateKindArray[currentStep]
    )
  }, [currentStep])

  /** アルゴリズム選択時に状態を変更 */
  useEffect(() => {
    if (props.domainData.selectedAlgorithm) {
      props.setAlgorithmSubState('Selected')
    } else {
      props.setAlgorithmSubState('Unselected')
    }
  }, [props.domainData.selectedAlgorithm])

  /** メタデータの名前変更時、モデル種別変更時に状態を変更 */
  useEffect(() => {
    if (props.domainData.featureDataGroupMetaData.featureDataGroupName) {
      props.setMetadataSubState('InputRequired')
    } else {
      props.setMetadataSubState('EmptyRequired')
    }
  }, [props.domainData.featureDataGroupMetaData.featureDataGroupName])

  /** メタデータのアイコン変更時に状態を変更 */
  useEffect(() => {
    if (!props.domainData.featureDataGroupMetaData.featureDataGroupIcon) {
      return setFileToBase64('')
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      if (e && e.target && e.target.result) {
        setFileToBase64(e.target.result as string)
      }
    }
    reader.readAsDataURL(
      props.domainData.featureDataGroupMetaData.featureDataGroupIcon
    )
  }, [props.domainData.featureDataGroupMetaData.featureDataGroupIcon])

  /** エラートースト */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(props.appState.toastInfo.title, [])
      props.setToastInfo(undefined)
    }
  }, [props.appState.toastInfo])

  /** 次へボタンの活性非活性 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.featureDataGroupEntryStateKind) {
      case 'AlgorithmState':
        return (
          props.appState.featureDataGroupEntrySubStateKind.algorithmSubState ===
          'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.featureDataGroupEntrySubStateKind.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
    }
  }, [
    props.appState.featureDataGroupEntryStateKind,
    props.appState.featureDataGroupEntrySubStateKind,
  ])

  const showErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='featureDataUploadErrorToastFileName'>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  /** ファイル削除 */
  const featureDataGroupIconDelete = () => {
    props.setFeatureDataGroupIcon(undefined)
  }

  const fileUploadErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.featureDataGroupEntryStateKind) {
      case 'AlgorithmState':
        if (isUndefined(props.algorithms)) {
          return <></>
        }
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='featureDataGroupEntry'>アルゴリズム</InputLabel>
                <Select
                  data-testid='select'
                  labelId='featureDataGroupEntry-label'
                  id='featureDataGroupEntry-outlined'
                  defaultValue={
                    props.algorithms.filter(
                      (algorithm) =>
                        algorithm.algorithmPurpose === 'TemplateMatching'
                    )[0].algorithmId
                  }
                  value={props.domainData.selectedAlgorithm}
                  onChange={(e) =>
                    props.setSelectedAlgorithm(e.target.value as string)
                  }
                  label='Select Algorithm'
                >
                  {props.algorithms
                    .filter(
                      (algorithm) =>
                        algorithm.algorithmPurpose === 'TemplateMatching'
                    )
                    .map((algorithm) => (
                      <MenuItem
                        data-testid={algorithm.algorithmId}
                        value={algorithm.algorithmId}
                        key={algorithm.algorithmId}
                      >
                        {algorithm.metadata.name.ja}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <div className={classes.featureDataGroupParamsTitle}>
                <Typography>特徴量データグループ名</Typography>
              </div>
              <TextField
                className={classes.featureDataGroupParamsContent}
                value={
                  props.domainData.featureDataGroupMetaData.featureDataGroupName
                }
                onChange={(e) =>
                  props.setFeatureDataGroupMetadataName(e.target.value)
                }
                onBlur={() => setCanShowNameError(true)}
                error={getErrorStatus(
                  canShowNameError,
                  props.domainData.featureDataGroupMetaData.featureDataGroupName
                )}
                helperText={
                  getErrorStatus(
                    canShowNameError,
                    props.domainData.featureDataGroupMetaData
                      .featureDataGroupName
                  ) && ''
                }
                variant='outlined'
                rows={1}
                type={'text'}
                required
                data-testid={'feature-data-group-name-input'}
              />
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              <div className={classes.featureDataGroupParamsTitle}>
                <Typography>概要</Typography>
              </div>
              <TextField
                className={classes.featureDataGroupParamsTitle}
                value={props.domainData.featureDataGroupMetaData.overView}
                onChange={(e) =>
                  props.setFeatureDataGroupOverView(e.target.value)
                }
                variant='outlined'
                minRows={1}
                multiline
                type={'text'}
                data-testid={'over-view-input'}
              />
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              <div className={classes.featureDataGroupParamsTitle}>
                <Typography>アイコン</Typography>
              </div>
              <div className={classes.iconDropZone}>
                <FileUploadViewer
                  fileType={'.jpg,.png'}
                  maxSize={256000}
                  file={
                    props.domainData.featureDataGroupMetaData
                      .featureDataGroupIcon
                  }
                  fileDeleteAction={featureDataGroupIconDelete}
                  acceptFileOperation={(files: File[]) =>
                    props.setFeatureDataGroupIcon(files[0])
                  }
                  rejectedFileOperation={(fileRejections: FileRejection[]) =>
                    fileUploadErrors(fileRejections)
                  }
                  data-testid={'feature-data-group'}
                />
              </div>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              <div className={classes.featureDataGroupParamsTitle}>
                <Typography>備考</Typography>
              </div>
              <TextField
                className={classes.featureDataGroupParamsContent}
                value={props.domainData.featureDataGroupMetaData.remarks}
                onChange={(e) =>
                  props.setFeatureDataGroupRemarks(e.target.value)
                }
                variant='outlined'
                minRows={1}
                multiline
                type={'text'}
                data-testid={'remarks-input'}
              />
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <Box component={Paper} mt={1} p={'24px 32px 32px'} mb={1}>
              <Typography>特徴量データグループ情報</Typography>
              <Box mt={1}>
                <TextField
                  id='algorithm'
                  className={classes.textField}
                  variant='standard'
                  label='アルゴリズム'
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    props.domainData.selectedAlgorithm
                      ? props.algorithms.find(
                          (algorithm) =>
                            algorithm.algorithmId ===
                            props.domainData.selectedAlgorithm
                        )?.metadata.name.ja
                      : ''
                  }
                  key={props.domainData.selectedAlgorithm}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id='feature-data-group-name'
                  className={classes.textField}
                  variant='standard'
                  label={'特徴量データグループ名'}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    props.domainData.featureDataGroupMetaData
                      .featureDataGroupName
                  }
                  key={
                    props.domainData.featureDataGroupMetaData
                      .featureDataGroupName
                  }
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id='over-view'
                  className={clsx(classes.textField, {
                    [classes.notApplicable]:
                      !props.domainData.featureDataGroupMetaData.overView,
                  })}
                  variant='standard'
                  label={'概要'}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    props.domainData.featureDataGroupMetaData.overView ??
                    FEATURE_DATA_GROUP_PARAM_NOT_APPLICABLE
                  }
                  key={props.domainData.featureDataGroupMetaData.overView}
                />
              </Box>
              <Box mt={2}>
                <InputLabel shrink>アイコン</InputLabel>
                {fileToBase64 ? (
                  <Box mt={1}>
                    <img
                      src={fileToBase64}
                      className={`${classes.iconPreview} ${classes.notApplicable}`}
                    />
                  </Box>
                ) : (
                  <Box mt={1} className={classes.iconNoImage}>
                    <Typography>No image</Typography>
                  </Box>
                )}
              </Box>
              <Box mt={2}>
                <TextField
                  id='feature-data-group-remarks'
                  className={clsx(classes.textField, {
                    [classes.notApplicable]:
                      !props.domainData.featureDataGroupMetaData.overView,
                  })}
                  variant='standard'
                  label={'備考'}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    props.domainData.featureDataGroupMetaData.remarks ??
                    FEATURE_DATA_GROUP_PARAM_NOT_APPLICABLE
                  }
                  key={props.domainData.featureDataGroupMetaData.remarks}
                />
              </Box>
            </Box>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Box pl={7}>
          <BreadcrumbsComponent
            breadcrumbsPath={[
              {
                name: '特徴量データグループ一覧',
                path: 'feature-data-groups',
              },
              {
                name: '特徴量データグループ作成',
                path: 'entry',
              },
            ]}
          />
        </Box>
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.head}>
            <h2
              className={classes.pageTitle}
              data-testid='feature-data-group-entry-title'
            >
              特徴量データグループ
            </h2>
            <div className={classes.stepper}>
              <CommonStepper
                stepLabels={STEP_NAMES}
                activeStepIndex={currentStep}
              />
            </div>
          </div>
          <div className={classes.content} ref={contentRef}>
            {getStepContent(props)}
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              {props.appState.featureDataGroupEntryStateKind !==
              'AlgorithmState' ? (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className={classes.leftButton}
                >
                  {'戻る'}
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                data-testid='next-step'
                variant='contained'
                color='primary'
                disabled={!enableNextButton}
                onClick={() =>
                  props.appState.featureDataGroupEntryStateKind ===
                  'ExecuteState'
                    ? props.createFeatureDataGroup()
                    : setCurrentStep(currentStep + 1)
                }
                className={classes.rightButton}
              >
                {props.appState.featureDataGroupEntryStateKind ===
                'ExecuteState'
                  ? '作成'
                  : '次へ'}
              </Button>
            </div>
          </div>
          <CommonCompleteDialog
            open={
              !isUndefined(props.domainData.generatedFeatureDataGroupId) &&
              props.appState.featureDataGroupEntrySubStateKind
                .executeSubState === 'Executed'
            }
            value={props.domainData.generatedFeatureDataGroupId ?? ''}
            handleClose={() => history.push('/feature-data-groups')}
            label={'特徴量データグループID'}
            dialogText={'正常に特徴量データグループを作成しました。'}
            data-testid={'exec-feature-data-group-id'}
          />
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

export const FeatureDataGroupEntryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataGroupEntry))
