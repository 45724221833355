import { AidpCloudFunctionsUrl, getArrayChunk } from 'state/utils'
import { functionsInstance } from 'state/firebase'
import { httpsCallable } from 'firebase/functions'
import { GetSignedUrlsFunctionType } from './types'

export const getSignedUrls = async (
  fileInfoList: { id: string; fileName: string }[]
): Promise<{ [id: string]: string }> => {
  // signedUrl取得の単位を500ファイルずつに分割
  const fileInfoListChunks = getArrayChunk(fileInfoList, 500)

  // 取得
  const signedUrlPromises: Promise<{
    [id: string]: string
  }>[] = fileInfoListChunks.map(async (fileInfoListChunk) => {
    const getReadableSignedUrlRecordsFunction: GetSignedUrlsFunctionType =
      httpsCallable(
        functionsInstance,
        `${AidpCloudFunctionsUrl()}data-manipulation-read-thumbnail`
      )
    return (
      await getReadableSignedUrlRecordsFunction({
        fileInfoList: fileInfoListChunk,
      })
    ).data
  })

  return Object.assign({}, ...(await Promise.all(signedUrlPromises)))
}
