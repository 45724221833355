import { Dispatch } from 'redux'
import {
  AccountGroup,
  accountGroupActions,
  AccountGroupCustomer,
  Customer,
  SuperUser,
} from '.'
import {
  getAccountGroupCollection,
  getAccountGroupCustomerRelationsCollection,
  getAccountGroupMetadataCollection,
  getAccountsCollection,
  getAccountSettingCollection,
  getCustomerCollection,
  getCustomerMetadataCollection,
} from 'state/firebase'
import { State } from 'state/store'
import { AccountGroupsApi } from './apis'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountDocument } from 'utils/fireStore/account'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupDocument } from 'utils/fireStore/accountGroup'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupMetadataDocument } from 'utils/fireStore/accountGroupMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupCustomerRelationDocument } from 'utils/fireStore/accountGroupCustomerRelation'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomerDocument } from 'utils/fireStore/customer'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomersMetadataDocument } from 'utils/fireStore/customersMetadata'
import { doc, getDoc, getDocs, query, where } from 'firebase/firestore'

export const AccountGroupsOperations = {
  /** リストを取得する */
  getAccountGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountGroupActions.setInProgress(true))

        const isSuperUser =
          getState().app.domainData.authedUser.auth.customClaims.superUser
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        // アカウントグループdoc取得
        const allAccountGroupList = isSuperUser
          ? await getDocs(query(getAccountGroupCollection()))
          : await getDocs(
              query(
                getAccountGroupCollection(),
                where('account-group-id', '==', accountGroupId)
              )
            )

        // アカウントグループメタデータdoc取得する
        const allAccountGroupMetadataList = isSuperUser
          ? await getDocs(query(getAccountGroupMetadataCollection()))
          : await getDocs(
              query(
                getAccountGroupMetadataCollection(),
                where('account-group-id', '==', accountGroupId)
              )
            )

        // アカウントグループメタデータdoc取得する
        const allAccountGroupCustomerRelationsList = isSuperUser
          ? await getDocs(query(getAccountGroupCustomerRelationsCollection()))
          : await getDocs(
              query(
                getAccountGroupCustomerRelationsCollection(),
                where('account-group-id', '==', accountGroupId)
              )
            )

        // アカウントグループリスト作成
        const allAccountGroupListConvert = (
          await Promise.all(
            allAccountGroupList.docs.map(async (docItem) => {
              const accountGroup = docItem.data()
              const isAccountGroupDocValid =
                fireStoreTypeGuardForAccountGroupDocument(accountGroup)

              if (!isAccountGroupDocValid) {
                return
              }

              const metadata = allAccountGroupMetadataList.docs
                .map((metadataDoc) => metadataDoc.data())
                .find(
                  (metadata) =>
                    metadata['account-group-id'] ===
                    accountGroup['account-group-id']
                )

              const isAccountGroupMetadataDocValid =
                metadata &&
                fireStoreTypeGuardForAccountGroupMetadataDocument(metadata)

              if (!isAccountGroupMetadataDocValid) {
                return
              }

              const customerRelations =
                allAccountGroupCustomerRelationsList.docs
                  .map((customerRelationsDoc) => customerRelationsDoc.data())
                  .find(
                    (customerRelations) =>
                      customerRelations['account-group-id'] ===
                      accountGroup['account-group-id']
                  )

              const isAccountGroupCustomerRelationsDocValid =
                customerRelations &&
                fireStoreTypeGuardForAccountGroupCustomerRelationDocument(
                  customerRelations
                )

              if (!isAccountGroupCustomerRelationsDocValid) {
                return
              }

              return {
                accountGroupId: accountGroup['account-group-id'],
                name: metadata ? metadata['name'] : '',
                customerList: customerRelations
                  ? customerRelations['customer-list'].map(
                      (customer: { 'customer-id': string }) => ({
                        customerId: customer['customer-id'],
                      })
                    )
                  : [],
              } as AccountGroup
            })
          )
        ).filter(
          (item: AccountGroup | undefined) => item !== undefined
        ) as AccountGroup[]
        dispatch(
          accountGroupActions.setAccountGroupList(allAccountGroupListConvert)
        )
        dispatch(accountGroupActions.setSelectedAccountGroupId(accountGroupId))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountGroupActions.setInProgress(false))
      }
    },
  /** アカウントグループのカスタマー情報を取得する */
  getCustomerList:
    () =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(accountGroupActions.setInProgress(true))
      try {
        // カスタマーdoc取得
        const allCustomerList = await getDocs(query(getCustomerCollection()))
        // カスタマーメタデータdoc取得する
        const allCustomerMetadataList = await getDocs(
          query(getCustomerMetadataCollection())
        )
        // カスタマーリスト作成
        const allCustomerListConvert = (
          await Promise.all(
            allCustomerList.docs.map(async (docItem) => {
              const customer = docItem.data()
              const isCustomerDocValid =
                customer && fireStoreTypeGuardForCustomerDocument(customer)

              if (!isCustomerDocValid) {
                return
              }

              const metadata = allCustomerMetadataList.docs
                .map((metadataDoc) => metadataDoc.data())
                .find(
                  (metadata) =>
                    metadata['customer-id'] === customer['customer-id']
                )

              const isCustomerMetadataDocValid =
                metadata &&
                fireStoreTypeGuardForCustomersMetadataDocument(metadata)

              if (!isCustomerMetadataDocValid) {
                return
              }

              return {
                customerId: customer['customer-id'],
                customerName: metadata ? metadata['name'] : '',
                userGroupId: customer['user-group-id'],
                createdAt: customer['created-at'],
              } as Customer
            })
          )
        ).filter(
          (item: Customer | undefined) => item !== undefined
        ) as Customer[]

        dispatch(accountGroupActions.setCustomerList(allCustomerListConvert))
        dispatch(accountGroupActions.clearDisplayCondition())
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountGroupActions.setInProgress(false))
      }
    },
  setSelectedAccountGroupId:
    (selectedAccountGroupId: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(accountGroupActions.setInProgress(true))
      try {
        dispatch(
          accountGroupActions.setSelectedAccountGroupId(selectedAccountGroupId)
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountGroupActions.setInProgress(false))
      }
    },
  /** */
  updateAccountGroupCustomerRelations:
    (accountGroupId: string, accountCustomers: AccountGroupCustomer[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(accountGroupActions.setInProgress(true))
        //
        await AccountGroupsApi.updateCustomers(accountGroupId, accountCustomers)
          .then(() => {
            dispatch(
              accountGroupActions.updateAccountGroupCustomerRelations(
                accountGroupId,
                accountCustomers
              )
            )
            dispatch(
              accountGroupActions.setAccountGroupUpdateCustomerListState(
                'UpdateCustomerListSuccess'
              )
            )
          })
          .catch((error) => {
            console.error(error)
            dispatch(
              accountGroupActions.setAccountGroupUpdateCustomerListState(
                'UpdateCustomerListError'
              )
            )
          })
      } catch (error) {
        console.error(error)
        dispatch(
          accountGroupActions.setAccountGroupUpdateCustomerListState(
            'UpdateCustomerListError'
          )
        )
      } finally {
        dispatch(accountGroupActions.setInProgress(false))
        dispatch(
          accountGroupActions.setAccountGroupUpdateCustomerListState(
            'BeforeUpdateCustomerList'
          )
        )
      }
    },
  getSuperUserList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountGroupActions.setInProgressForGettingSuperUserList(true))

        const isSuperUser =
          getState().app.domainData.authedUser.auth.customClaims.superUser
        const accountGroupId =
          getState().pages.accountGroupsState.domainData.selectedAccountGroupId
        if (accountGroupId === '') {
          return
        }

        const accountDocs = isSuperUser
          ? (
              await getDocs(
                query(
                  getAccountsCollection(accountGroupId),
                  where('super-user', '==', true)
                )
              )
            ).docs
          : (
              await getDocs(
                query(
                  getAccountsCollection(accountGroupId),
                  where('super-user', '==', true),
                  where('account-group-id', '==', accountGroupId)
                )
              )
            ).docs

        const accountSettingDocs = await getDocs(
          query(getAccountSettingCollection(accountGroupId))
        )

        const superUserList = (
          await Promise.all(
            accountDocs.map(async (docItem) => {
              const account = docItem.data()
              if (!fireStoreTypeGuardForAccountDocument(account)) {
                return
              }

              const accountSetting = accountSettingDocs.docs
                .map((accountSettingDoc) => accountSettingDoc.data())
                .find(
                  (setting) => setting['account-id'] === account['account-id']
                )
              if (
                !accountSetting ||
                !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
              ) {
                return
              }

              const superUser: SuperUser = {
                accountId: account['account-id'],
                email: account['mail-address'],
                firstName: accountSetting?.['first-name'] ?? '',
                familyName: accountSetting?.['family-name'] ?? '',
                lastLoginTime: account['last-login-time'],
                createdAt: account['created-at'],
              }
              return superUser
            })
          )
        ).filter((item) => item !== undefined) as SuperUser[]

        dispatch(accountGroupActions.setSuperUserList(superUserList))
        dispatch(accountGroupActions.setSuperUserListSubState('Loaded'))
      } catch (error) {
        console.error(error)
        dispatch(accountGroupActions.setSuperUserListSubState('Failed'))
      } finally {
        dispatch(
          accountGroupActions.setInProgressForGettingSuperUserList(false)
        )
      }
    },
  getMfaGroupSetting:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountGroupActions.setInProgressForGettingMfaSetting(true))

        const accountGroupId =
          getState().pages.accountGroupsState.domainData.selectedAccountGroupId
        if (accountGroupId === '') {
          return
        }

        const accountsGroupDoc = (
          await getDoc(doc(getAccountGroupCollection(), accountGroupId))
        ).data()
        if (!fireStoreTypeGuardForAccountGroupDocument(accountsGroupDoc)) {
          dispatch(accountGroupActions.setMfaGroupSettingSubState('Failed'))
          return
        }

        dispatch(
          accountGroupActions.setMfaGroupSetting(
            accountsGroupDoc?.['mfa-group-setting'] ?? ''
          )
        )
        dispatch(accountGroupActions.setMfaGroupSettingSubState('Loaded'))
      } catch (error) {
        console.error(error)
        dispatch(accountGroupActions.setMfaGroupSettingSubState('Failed'))
      } finally {
        dispatch(accountGroupActions.setInProgressForGettingMfaSetting(false))
      }
    },
}
