import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  CameraSetting,
  Metadata,
  SceneCamera,
  SceneCameraEntryActionType,
  SceneCameraEntryStateKind,
  SceneCameraEntrySubState,
  SceneCameraListDisplayCondition,
  ToastInfo,
} from './types'

export type SceneCameraEntryAction = ActionsUnion<
  typeof sceneCameraEntryActions
>

export const sceneCameraEntryActions = {
  setIsUpdateRevision: (
    isUpdateRevision: boolean
  ): ActionWithPayload<
    'SET_IS_UPDATE_REVISION',
    { isUpdateRevision: boolean }
  > =>
    CreateAction(SceneCameraEntryActionType.SET_IS_UPDATE_REVISION, {
      isUpdateRevision,
    }),

  setSceneCameraIds: (
    sceneCameraIds: string[]
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_ENTRY',
    { sceneCameraIds: string[] }
  > =>
    CreateAction(
      SceneCameraEntryActionType.SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_ENTRY,
      {
        sceneCameraIds,
      }
    ),

  setCurrentSceneCameraListSnapshot: (
    currentSceneCameraListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT_FOR_SCENE_CAMERA_ENTRY',
    { currentSceneCameraListSnapshot?: () => void }
  > =>
    CreateAction(
      SceneCameraEntryActionType.SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT_FOR_SCENE_CAMERA_ENTRY,
      {
        currentSceneCameraListSnapshot,
      }
    ),

  setSceneCameraList: (
    sceneCameraList: SceneCamera[]
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY',
    { sceneCameraList: SceneCamera[] }
  > =>
    CreateAction(
      SceneCameraEntryActionType.SET_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY,
      {
        sceneCameraList,
      }
    ),

  clearSceneCameraList:
    (): Action<'CLEAR_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY'> =>
      CreateAction(
        SceneCameraEntryActionType.CLEAR_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY
      ),

  setSelectedSceneCamera: (
    selectedSceneCamera?: SceneCamera
  ): ActionWithPayload<
    'SET_SELECTED_SCENE_CAMERA',
    { selectedSceneCamera?: SceneCamera }
  > =>
    CreateAction(SceneCameraEntryActionType.SET_SELECTED_SCENE_CAMERA, {
      selectedSceneCamera,
    }),

  setCameraSettingFile: (
    cameraSettingFile?: File
  ): ActionWithPayload<
    'SET_CAMERA_SETTING_FILE',
    { cameraSettingFile?: File }
  > =>
    CreateAction(SceneCameraEntryActionType.SET_CAMERA_SETTING_FILE, {
      cameraSettingFile,
    }),

  setCameraSetting: (
    cameraSetting: CameraSetting
  ): ActionWithPayload<
    'SET_CAMERA_SETTING',
    { cameraSetting: CameraSetting }
  > =>
    CreateAction(SceneCameraEntryActionType.SET_CAMERA_SETTING, {
      cameraSetting,
    }),

  setMetadata: (
    metadata: Metadata
  ): ActionWithPayload<
    'SET_METADATA_FOR_SCENE_CAMERA_ENTRY',
    { metadata: Metadata }
  > =>
    CreateAction(
      SceneCameraEntryActionType.SET_METADATA_FOR_SCENE_CAMERA_ENTRY,
      {
        metadata,
      }
    ),

  setSceneCameraEntryState: (
    sceneCameraEntryState: SceneCameraEntryStateKind
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_ENTRY_STATE',
    { sceneCameraEntryState: SceneCameraEntryStateKind }
  > =>
    CreateAction(SceneCameraEntryActionType.SET_SCENE_CAMERA_ENTRY_STATE, {
      sceneCameraEntryState,
    }),

  setCreatedCameraId: (
    createdCameraId?: string
  ): ActionWithPayload<'SET_CREATED_CAMERA_ID', { createdCameraId?: string }> =>
    CreateAction(SceneCameraEntryActionType.SET_CREATED_CAMERA_ID, {
      createdCameraId,
    }),

  setSceneCameraListDisplayCondition: (
    sceneCameraListDisplayCondition: SceneCameraListDisplayCondition
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION_FOR_SCENE_CAMERA_ENTRY',
    { sceneCameraListDisplayCondition: SceneCameraListDisplayCondition }
  > =>
    CreateAction(
      SceneCameraEntryActionType.SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION_FOR_SCENE_CAMERA_ENTRY,
      {
        sceneCameraListDisplayCondition,
      }
    ),

  setSceneCameraEntrySubState: (
    sceneCameraEntrySubState: SceneCameraEntrySubState
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_ENTRY_SUB_STATE',
    { sceneCameraEntrySubState: SceneCameraEntrySubState }
  > =>
    CreateAction(SceneCameraEntryActionType.SET_SCENE_CAMERA_ENTRY_SUB_STATE, {
      sceneCameraEntrySubState,
    }),

  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_SCENE_CAMERA_ENTRY',
    { inProgress: boolean }
  > =>
    CreateAction(
      SceneCameraEntryActionType.SET_IN_PROGRESS_FOR_SCENE_CAMERA_ENTRY,
      {
        inProgress,
      }
    ),

  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_SCENE_CAMERA_ENTRY',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      SceneCameraEntryActionType.SET_TOAST_INFO_FOR_SCENE_CAMERA_ENTRY,
      {
        toastInfo,
      }
    ),

  clearSceneCameraEntryState: (): Action<'CLEAR_SCENE_CAMERA_ENTRY_STATE'> =>
    CreateAction(SceneCameraEntryActionType.CLEAR_SCENE_CAMERA_ENTRY_STATE),
}
