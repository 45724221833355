import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'

import {
  CreateSettingActionType,
  Setting,
  SettingMetadata,
  SettingState,
  UploadType,
} from './types'

export type CreateSettingAction = ActionsUnion<typeof createSettingActions>
export const createSettingActions = {
  addSettingFile: (
    settingFile?: File
  ): ActionWithPayload<'ADD_SETTING_FILE', { settingFile?: File }> =>
    CreateAction(CreateSettingActionType.ADD_SETTING_FILE, {
      settingFile,
    }),
  setSettingMetadata: (
    settingMetadata?: SettingMetadata
  ): ActionWithPayload<
    'SET_SETTING_METADATA',
    { settingMetadata?: SettingMetadata }
  > =>
    CreateAction(CreateSettingActionType.SET_SETTING_METADATA, {
      settingMetadata,
    }),
  setSettingState: (
    settingState: SettingState
  ): ActionWithPayload<'SET_SETTING_STATE', { settingState: SettingState }> =>
    CreateAction(CreateSettingActionType.SET_SETTING_STATE, {
      settingState,
    }),
  setSetting: (
    setting: Setting
  ): ActionWithPayload<'SET_SETTING', { setting: Setting }> =>
    CreateAction(CreateSettingActionType.SET_SETTING, {
      setting,
    }),
  setSettingId: (
    settingId?: string
  ): ActionWithPayload<
    'CREATE_SETTING_SET_SETTINGID',
    { settingId?: string }
  > =>
    CreateAction(CreateSettingActionType.CREATE_SETTING_SET_SETTINGID, {
      settingId,
    }),
  clearCurrentSettingState: (): Action<'CLEAR_CURRENT_SETTING_STATE'> =>
    CreateAction(CreateSettingActionType.CLEAR_CURRENT_SETTING_STATE),
  documentError: (): ActionWithPayload<
    'CREATE_SETTING_DOCUMENT_ERROR',
    { uploadSubState: 'BeforeUpload' | UploadType | 'DocumentError' }
  > =>
    CreateAction(CreateSettingActionType.CREATE_SETTING_DOCUMENT_ERROR, {
      uploadSubState: 'DocumentError',
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_CREATE_SETTING',
    { inProgress: boolean }
  > =>
    CreateAction(CreateSettingActionType.SET_IN_PROGRESS_FOR_CREATE_SETTING, {
      inProgress,
    }),
}
