import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils'
import {
  DomainDataActionType,
  AuthedUser,
  Glossaries,
  Algorithm,
  UserProfile,
  DatasetTemplate,
  AnnotationFormat,
  FeatureDataFormat,
} from './types'

export type DomainDataActions = ActionsUnion<typeof domainDataActions>

export const domainDataActions = {
  setAuthedUser: (
    data: AuthedUser
  ): ActionWithPayload<'SET_AUTHED_USER', { data: AuthedUser }> =>
    CreateAction(DomainDataActionType.SET_AUTHED_USER, { data }),
  setGlossaries: (
    data: Glossaries
  ): ActionWithPayload<'SET_GLOSSARIES', { data: Glossaries }> =>
    CreateAction(DomainDataActionType.SET_GLOSSARIES, { data }),
  setAlgorithms: (
    data: Algorithm[]
  ): ActionWithPayload<'SET_ALGORITHMS', { data: Algorithm[] }> =>
    CreateAction(DomainDataActionType.SET_ALGORITHMS, { data }),
  setDatasetTemplates: (
    data: DatasetTemplate[]
  ): ActionWithPayload<'SET_DATASET_TEMPLATES', { data: DatasetTemplate[] }> =>
    CreateAction(DomainDataActionType.SET_DATASET_TEMPLATES, { data }),
  setAnnotationFormats: (
    data: AnnotationFormat[]
  ): ActionWithPayload<
    'SET_ANNOTATION_FORMATS',
    { data: AnnotationFormat[] }
  > => CreateAction(DomainDataActionType.SET_ANNOTATION_FORMATS, { data }),
  setFeatureDataFormats: (
    data: FeatureDataFormat[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_FORMATS',
    { data: FeatureDataFormat[] }
  > => CreateAction(DomainDataActionType.SET_FEATURE_DATA_FORMATS, { data }),
  setUserProfile: (
    data: UserProfile
  ): ActionWithPayload<'SET_USER_PROFILE', { data: UserProfile }> =>
    CreateAction(DomainDataActionType.SET_USER_PROFILE, { data }),
  setInProgress: (
    data: boolean
  ): ActionWithPayload<'SET_IN_PROGRESS', { data: boolean }> =>
    CreateAction(DomainDataActionType.SET_IN_PROGRESS, { data }),
  clearDomainData: (): Action<'CLEAR_DOMAIN_DATA'> =>
    CreateAction(DomainDataActionType.CLEAR_DOMAIN_DATA),
}
