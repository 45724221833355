import { combineReducers } from 'redux'
import { DomainDataReducer, DomainData } from 'state/app/domainData'
import { AppStateReducer, AppState } from 'state/app/appState'
import { AppInfoReducer, AppInfo } from 'state/app/appInfo'

export type State = {
  domainData: DomainData
  appState: AppState
  appInfo: AppInfo
}

export const reducers = combineReducers<State>({
  domainData: DomainDataReducer,
  appState: AppStateReducer,
  appInfo: AppInfoReducer,
})
