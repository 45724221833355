export const timeStampSchema = {
  required: ['nanoseconds', 'seconds'],
  type: 'object',
  properties: {
    nanoseconds: {
      type: 'number',
    },
    seconds: {
      type: 'number',
    },
  },
}

export const mlPipelineDocumentSchema = {
  required: [
    'ml-pipeline',
    'ml-pipeline-metadata',
    'user-group-id',
    'created-at',
    'created-by',
    'updated-at',
    'updated-by',
  ],
  type: 'object',
  properties: {
    'ml-pipeline': {
      required: [
        'account-group-id',
        'account-id',
        'ml-pipeline-id',
        'ended-at',
        'ml-pipeline-kind',
        'started-at',
        'transaction-status',
      ],
      type: 'object',
      properties: {
        'account-group-id': {
          type: 'string',
        },
        'account-id': {
          type: 'string',
        },
        'ml-pipeline-id': {
          type: 'string',
        },
        'ended-at': {
          ...timeStampSchema,
        },
        'ml-pipeline-kind': {
          type: 'string',
        },
        'started-at': {
          ...timeStampSchema,
        },
        'transaction-status': {
          type: 'string',
        },
      },
    },
    'ml-pipeline-metadata': {
      required: ['name', 'remarks'],
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        remarks: {
          type: 'string',
        },
      },
    },
    'augmentation-step': {
      type: 'object',
    },
    'build-step': {
      type: 'object',
    },
    'inference-step': {
      type: 'object',
    },
    'training-step': {
      type: 'object',
    },
    'transfer-step': {
      type: 'object',
    },
    'user-group-id': {
      type: 'string',
    },
    'created-at': {
      ...timeStampSchema,
    },
    'created-by': {
      type: 'string',
    },
    'updated-at': {
      ...timeStampSchema,
    },
    'updated-by': {
      type: 'string',
    },
  },
}

export const rawVersionSchema = {
  required: ['major', 'minor', 'patch'],
  type: 'object',
  properties: {
    major: {
      type: 'number',
    },
    minor: {
      type: 'number',
    },
    patch: {
      type: 'number',
    },
  },
}

export const versionSchema = {
  required: ['display-name', 'major', 'minor', 'patch'],
  type: 'object',
  properties: {
    'display-name': {
      type: 'string',
    },
    major: {
      type: 'number',
    },
    minor: {
      type: 'number',
    },
    patch: {
      type: 'number',
    },
  },
}

export const optionalVersionSchema = {
  required: [],
  type: 'object',
  properties: {
    'display-name': {
      type: 'string',
    },
    major: {
      type: 'number',
    },
    minor: {
      type: 'number',
    },
    patch: {
      type: 'number',
    },
  },
}

export const preReleaseVersionSchema = {
  required: ['display-name', 'major', 'minor', 'patch', 'pre-release'],
  type: 'object',
  properties: {
    'display-name': {
      type: 'string',
    },
    major: {
      type: 'number',
    },
    minor: {
      type: 'number',
    },
    patch: {
      type: 'number',
    },
    'pre-release': {
      type: 'number',
    },
  },
}

export const optionalPreReleaseVersionSchema = {
  required: ['display-name', 'major', 'minor', 'patch'],
  type: 'object',
  properties: {
    'display-name': {
      type: 'string',
    },
    major: {
      type: 'number',
    },
    minor: {
      type: 'number',
    },
    patch: {
      type: 'number',
    },
    'pre-release': {
      type: 'number',
    },
  },
}

export const buildVersionSchema = {
  required: ['display-name', 'major', 'minor', 'patch', 'build'],
  type: 'object',
  properties: {
    'display-name': {
      type: 'string',
    },
    major: {
      type: 'number',
    },
    minor: {
      type: 'number',
    },
    patch: {
      type: 'number',
    },
    build: {
      type: 'number',
    },
  },
}

export const versionRangeSchema = {
  required: ['lower-limit', 'upper-limit'],
  type: 'object',
  properties: {
    'lower-limit': {
      ...preReleaseVersionSchema,
    },
    'upper-limit': {
      ...preReleaseVersionSchema,
    },
  },
}

export const trainedModelSchema = {
  type: 'object',
  required: [
    'training-algorithm-version',
    'trained-model-id',
    'trained-model-group-id',
    'model-kind',
  ],
  properties: {
    'training-algorithm-version': {
      ...preReleaseVersionSchema,
    },
    'trained-model-id': {
      type: 'string',
    },
    'trained-model-group-id': {
      type: 'string',
    },
    'model-kind': {
      type: 'string',
    },
    'user-group-id': {
      type: 'string',
    },
  },
}

export const trainingStepSrcExtendedObjectClassificationSchema = {
  type: 'object',
  required: ['class-set', 'setting'],
  properties: {
    'class-set': {
      required: ['class-set-id', 'user-group-id'],
      type: 'object',
      properties: {
        'class-set-id': {
          type: 'string',
        },
        'user-group-id': {
          type: 'string',
        },
      },
    },
    setting: {
      required: ['pre-process-kind', 'base-size'],
      type: 'object',
      properties: {
        'pre-process-kind': {
          type: 'string',
        },
        'base-size': {
          required: ['height', 'width'],
          type: 'object',
          properties: {
            height: {
              type: 'number',
            },
            width: {
              type: 'number',
            },
          },
        },
        'setting-format-id': {
          type: 'string',
        },
        'setting-format-version': {
          ...optionalVersionSchema,
        },
      },
    },
  },
}

export const extendedObjectClassificationForQuerySchema = {
  type: 'object',
  required: ['class-set'],
  properties: {
    'class-set': {
      required: ['class-set-id', 'user-group-id', 'class-list'],
      type: 'object',
      properties: {
        'class-set-id': {
          type: 'string',
        },
        'user-group-id': {
          type: 'string',
        },
        'class-list': {
          type: 'array',
          items: {
            type: 'string',
          },
          minItems: 2,
        },
      },
    },
  },
}

export const extendedObjectClassificationSchema = {
  type: 'object',
  required: ['class-set'],
  properties: {
    'class-set': {
      required: ['class-set-id', 'user-group-id'],
      type: 'object',
      properties: {
        'class-set-id': {
          type: 'string',
        },
        'user-group-id': {
          type: 'string',
        },
      },
    },
  },
}
