import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { ExpandPaperProps } from './types'

const useStyles = makeStyles()((theme) => ({
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  listItem: {
    padding: 0,
  },
  detailText: {
    display: 'table-cell',
  },
  detailValueText: {
    paddingLeft: theme.spacing(1),
  },
}))

export const ExpandPaper: React.FC<ExpandPaperProps> = (
  props: ExpandPaperProps
) => {
  const { classes } = useStyles()

  const paperItems = () => {
    const items = props.contents.map((content) => {
      return (
        <ListItem dense className={classes.listItem} key={content.id}>
          <Box display='block'>{content.element}</Box>
        </ListItem>
      )
    })
    return items
  }

  return (
    <div>
      <Accordion defaultExpanded={props.defaultExpand}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography data-testid='expand-label' className={classes.labelText}>
            {props.paperTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>{paperItems()}</List>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
