import { Timestamp } from 'state/firebase'
import { TransactionStatusKind } from 'state/utils/types'

export const FeatureDataGeneratingListActionType = {
  SET_FEATURE_DATA_GENERATING_LIST: 'SET_FEATURE_DATA_GENERATING_LIST',
  SET_FEATURE_DATA_GENERATING_DISPLAY_CONDITION:
    'SET_FEATURE_DATA_GENERATING_DISPLAY_CONDITION',
  CLEAR_FEATURE_DATA_GENERATING_DISPLAY_CONDITION:
    'CLEAR_FEATURE_DATA_GENERATING_DISPLAY_CONDITION',
  CLEAR_FEATURE_DATA_GENERATING_PAGING_STATE:
    'CLEAR_FEATURE_DATA_GENERATING_PAGING_STATE',
  SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_GENERATING_LIST:
    'SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_GENERATING_LIST',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_LIST:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_LIST',
  SET_CURRENT_FEATURE_DATA_GENERATING_LIST_SNAPSHOT:
    'SET_CURRENT_FEATURE_DATA_GENERATING_LIST_SNAPSHOT',
  CLEAR_FEATURE_DATA_GENERATING_LIST_STATE:
    'CLEAR_FEATURE_DATA_GENERATING_LIST_STATE',
} as const

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface Dataset {
  datasetId: string
  datasetName: string
}

interface FeatureData {
  featureDataId: string
  featureDataName: string
}

export interface FeatureDataGenerating {
  mlPipelineId: string
  mlPipelineName: string
  algorithmName: string
  progress: Progress
  startedAt: Timestamp
  endedAt?: Timestamp
  dataset: Dataset
  featureData: FeatureData
  accountId: string
}

export interface FeatureDataGeneratingDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface FeatureDataGeneratingListDomainData {
  currentFeatureDataGeneratingList: FeatureDataGenerating[]
  featureDataGeneratingDisplayCondition: FeatureDataGeneratingDisplayCondition
  mlPipelineIds: string[]
  currentFeatureDataGeneratingListSnapshot?: () => void
}

export interface FeatureDataGeneratingListAppState {
  inProgress: boolean
}

export interface FeatureDataGeneratingListState {
  domainData: FeatureDataGeneratingListDomainData
  appState: FeatureDataGeneratingListAppState
}
