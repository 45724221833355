import { UserSettingAction } from './actions'
import { UserSettingActionType, UserSettingState } from './types'

const initialState: UserSettingState = {
  domainData: {
    userInfo: {
      mail: '',
      phoneNumber: '',
      firstName: '',
      familyName: '',
      accountGroupName: '',
      language: '',
      locate: '',
      role: '',
      mfaGroupSetting: 'none',
      isMfa: false,
    },
  },
  appState: {
    inProgress: false,
    passwordUpdateSubState: 'BeforeUpdate',
    passwordUpdateErrorMessage: '',
  },
}

export const UserSettingStateReducer = (
  state: UserSettingState = initialState,
  action: UserSettingAction
): UserSettingState => {
  switch (action.type) {
    case UserSettingActionType.SET_USER_INFO:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          userInfo: action.payload.data,
        },
      }
    case UserSettingActionType.SET_IN_PROGRESS_FOR_USER_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case UserSettingActionType.SET_PASSWORD_UPDATED_STATE_FOR_USER_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          passwordUpdateSubState: action.payload.data,
        },
      }
    case UserSettingActionType.SET_PASSWORD_UPDATE_ERROR_MESSAGE_FOR_USER_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          passwordUpdateErrorMessage: action.payload.data,
        },
      }
    case UserSettingActionType.SET_TOAST_INFO_FOR_USER_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case UserSettingActionType.CLEAR_USER_SETTING_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
