import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { State } from 'state/store'
import getTheme from 'views/theme'
import { PageLayout, PageLayoutProps } from 'views/components'
import {
  Auth,
  Login,
  MfaSettingPage,
  PasswordUpdatePage,
  PasswordReset,
  CustomerChangeDialogPage,
  CustomerRouter,
} from 'views/containers/pages'
import './App.css'
import { DomainDataProvider, AuthProvider } from './containers/providers'
import { getMenu } from 'routes'

const theme = getTheme()

const mapStateToProps = (state: State) => ({
  ...state.app.domainData,
  ...state.app.domainData.authedUser.auth.customClaims,
  ...state.app.appState,
  loginState: state.pages.loginState.appState.loginState,
})

type StateProps = ReturnType<typeof mapStateToProps>

export type ChangeCustomerDialogState = 'Unselected' | 'Change' | 'Unchange'

type AppProps = PageLayoutProps & StateProps
const App: React.FC<AppProps> = (props: AppProps) => {
  /** カスタマー変更ダイアログ表示状態 */
  const [openCustomerChangeDialog, setOpenCustomerChangeDialog] =
    React.useState(false)

  const menu = React.useMemo(
    () =>
      props.userProfile?.customerId != null
        ? getMenu(props.userProfile?.customerId)
        : props.menu,
    [props.userProfile?.customerId]
  )

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename='webapp'>
        <AuthProvider>
          <Route exact path='/login' component={Login} />
          <Route exact path='/password-update' component={PasswordUpdatePage} />
          <Route exact path='/mfa-setting' component={MfaSettingPage} />
          <Route exact path='/password-reset' component={PasswordReset} />
          <Auth
            authed={props.authed}
            loginState={props.loginState}
            isLoading={props.isLoading}
          >
            <DomainDataProvider
              domainData={{
                algorithms: props.algorithms,
                userProfile: props.userProfile,
                datasetTemplates: props.datasetTemplates,
                annotationFormats: props.annotationFormats,
                featureDataFormats: props.featureDataFormats,
              }}
            >
              <PageLayout
                userGroupId={props.userGroupId}
                authed={props.authed}
                mailAddress={props.authedUser.mailAddress}
                userId={props.authedUser.userId}
                menu={menu}
                superUser={props.authedUser.auth.customClaims.superUser}
                firstName={props.userProfile?.firstName ?? ''}
                familyName={props.userProfile?.familyName ?? ''}
                customerId={props.userProfile?.customerId ?? ''}
                customerName={props.userProfile?.customerName ?? ''}
                customerRole={props.userProfile?.role ?? 'user'}
                accountGroupRole={props.userProfile?.accountGroupRole ?? 'user'}
                handleClickCustomer={() => setOpenCustomerChangeDialog(true)}
              >
                <>
                  {openCustomerChangeDialog && (
                    <CustomerChangeDialogPage
                      handleCloseDialog={() =>
                        setOpenCustomerChangeDialog(false)
                      }
                    />
                  )}
                  <CustomerRouter
                    menu={menu}
                    superUser={props.authedUser.auth.customClaims.superUser}
                    customerId={props.userProfile?.customerId ?? ''}
                    accountGroupRole={props.userProfile?.accountGroupRole ?? ''}
                    customerRole={props.userProfile?.role ?? 'user'}
                  />
                </>
              </PageLayout>
            </DomainDataProvider>
          </Auth>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default connect(mapStateToProps)(App)
