import { HttpsCallableResult, httpsCallable } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { AuthorizationResult } from 'types/StateTypes'

const timeoutLimitSeconds = 15

export const AuthorizationApi = {
  authorizationApp: (): Promise<HttpsCallableResult<AuthorizationResult>> =>
    httpsCallable<void, AuthorizationResult>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'authorization-app',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )(),
}
