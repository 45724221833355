import { Dispatch } from 'redux'
import axios from 'axios'
import { saveAs } from 'file-saver'
import {
  getSettingsMetaDataCollection,
  getTrainedModelGroupQueriesCollection,
  getSettingsCollection,
  getSettingFormatsCollection,
  getAccountSettingCollection,
  getAlgorithmStructuresCollection,
} from 'state/firebase'
import {
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'

import { settingDetailActions } from './'
import {
  MediaLink,
  SettingFileDlLink,
  GetSettingFilesResponse,
  CurrentSettingDetail,
} from './types'
import { SettingDetailApi } from './apis'
import { State } from 'state/store'

import { isObject, isString, isNumber, isArray } from 'utils/typeguard'
import { fireStoreTypeGuard as fireStoreTypeGuardForSettingsDocument } from 'utils/fireStore/settings'
import { fireStoreTypeGuard as fireStoreTypeGuardForSettingMetadataDocument } from 'utils/fireStore/settingMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { domainDataOperations } from 'state/app/domainData/operations'

/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    settingDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

/** MediaLink[]かどうか */
function isMediaLinks(object: unknown): object is MediaLink[] {
  return (
    isArray(object) &&
    object.every((element) => {
      return (
        isString(element.mediaName) &&
        isString(element.mediaUrl) &&
        isNumber(element.mediaSize)
      )
    })
  )
}

/** GetSettingFilesResponseかどうか */
function isGetSettingFilesResponse(
  object: unknown
): object is GetSettingFilesResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isString(element.linkName) && isMediaLinks(element.mediaLinks)
    })
  )
}

export const SettingDetailOperations = {
  /** モデル詳細で表示パラメータを取得する */
  getSettingDetail:
    (settingId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(settingDetailActions.setInProgress(true))

        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const settingData = (
          await getDoc(doc(getSettingsCollection(userGroupId), settingId))
        ).data()
        if (
          !fireStoreTypeGuardForSettingsDocument(settingData) ||
          settingData === undefined
        ) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDataState: 'Failed',
            })
          )
          return
        }

        const settingMetadataData = (
          await getDoc(
            doc(getSettingsMetaDataCollection(userGroupId), settingId)
          )
        ).data()
        if (
          !fireStoreTypeGuardForSettingMetadataDocument(settingMetadataData) ||
          settingMetadataData === undefined
        ) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDataState: 'Failed',
            })
          )
          return
        }

        const modelGroupQueryDocs = (
          await getDocs(
            isSharedUserGroup
              ? query(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  where(
                    'constraint.setting.setting-group-id',
                    '==',
                    settingData['setting-group-id']
                  ),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              : query(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  where(
                    'constraint.setting.setting-group-id',
                    '==',
                    settingData['setting-group-id']
                  )
                )
          )
        ).docs
        const modelGroupQueryDatas = modelGroupQueryDocs
          .map((item) => {
            const data = item.data()
            if (!fireStoreTypeGuardForModelGroupQueryDocument(data)) {
              return undefined
            }
            return data
          })
          .filter((item) => item !== undefined)
        if (modelGroupQueryDatas.length === 0) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDataState: 'Failed',
            })
          )
          return
        }
        const modelGroupQueryData = modelGroupQueryDatas[0]
        if (modelGroupQueryData === undefined) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDataState: 'Failed',
            })
          )
          return
        }

        const settingFormatsData = (
          await getDoc(
            doc(getSettingFormatsCollection(), settingData['setting-format-id'])
          )
        ).data()
        if (settingFormatsData === undefined) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDataState: 'Failed',
            })
          )
          return
        }

        const algorithmStructureId =
          settingFormatsData['algorithm-structure-id']
        const algorithmStructureData = (
          await getDoc(
            doc(getAlgorithmStructuresCollection(), algorithmStructureId)
          )
        ).data()
        if (algorithmStructureData === undefined) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDataState: 'Failed',
            })
          )
          return
        }

        let accountSetting = undefined
        try {
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                settingData['created-by']
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }
        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDataState: 'Failed',
            })
          )
          return
        }

        const settingDlLinks: SettingFileDlLink[] = []
        try {
          const settingFiles = await SettingDetailApi.getSettingFiles(settingId)
          if (isGetSettingFilesResponse(settingFiles)) {
            settingFiles.data.items.map((item) => {
              settingDlLinks.push({
                linkName: item.linkName,
                mediaLinks: item.mediaLinks,
              })
            })
            dispatch(
              settingDetailActions.setSettingDetailState({
                ...getState().pages.settingDetailState.appState
                  .settingDetailState,
                settingDlLinkSubState: 'Loaded',
              })
            )
          }
        } catch (error) {
          dispatch(
            settingDetailActions.setSettingDetailState({
              ...getState().pages.settingDetailState.appState
                .settingDetailState,
              settingDlLinkSubState: 'Failed',
            })
          )
        }

        const currentSettingDetail: CurrentSettingDetail = {
          settingId,
          settingName: settingMetadataData['name'],
          algorithmId: settingData['algorithm-id'],
          createdAt: settingData['created-at'],
          createdBy: accountSetting
            ? `${accountSetting['first-name']} ${accountSetting['family-name']}`
            : settingData['created-by'],
          remarks: settingMetadataData['remarks'],
          settingGroup: {
            settingGroupId: settingData['setting-group-id'],
            settingGroupName: modelGroupQueryData['trained-model-group-name'],
            settingGroupVersion: {
              displayName: settingData['setting-group-version']['display-name'],
              major: settingData['setting-group-version']['major'],
              minor: settingData['setting-group-version']['minor'],
              patch: settingData['setting-group-version']['patch'],
            },
          },
          settingFormat: {
            settingFormatKind:
              algorithmStructureData['algorithm-structure-kind'],
            settingFormatVersion: {
              displayName:
                settingData['setting-format-version']['display-name'],
              major: settingData['setting-format-version']['major'],
              minor: settingData['setting-format-version']['minor'],
              patch: settingData['setting-format-version']['patch'],
            },
          },
          datasetTemplate: {
            datasetTemplateName:
              getState().app.domainData.datasetTemplates.find(
                (datasetTemplate) =>
                  datasetTemplate.datasetTemplateId ===
                  settingData['dataset-template-id']
              )?.metadata.name.ja ?? '',
          },
          trainedModelGroup: {
            trainedModelGroupId: modelGroupQueryData['trained-model-group-id'],
          },
          settingDlLinks,
        }

        dispatch(
          settingDetailActions.setCurrentSettingDetail(currentSettingDetail)
        )
        dispatch(
          settingDetailActions.setSettingDetailState({
            ...getState().pages.settingDetailState.appState.settingDetailState,
            settingDataState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(settingDetailActions.setInProgress(false))
      }
    },

  /** セッティング名を更新 */
  updateSettingName:
    (settingId: string, name: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(settingDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        await updateDoc(
          doc(getSettingsMetaDataCollection(userGroupId), settingId),
          {
            name,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )

        dispatch(
          settingDetailActions.setCurrentSettingDetail({
            ...getState().pages.settingDetailState.domainData
              .currentSettingDetail,
            settingName: name,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(settingDetailActions.setInProgress(false))
      }
    },

  /** セッティングの備考を更新 */
  updateRemarks:
    (settingId: string, remarks: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(settingDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        await updateDoc(
          doc(getSettingsMetaDataCollection(userGroupId), settingId),
          {
            remarks: remarks,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )

        dispatch(
          settingDetailActions.setCurrentSettingDetail({
            ...getState().pages.settingDetailState.domainData
              .currentSettingDetail,
            remarks,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(settingDetailActions.setInProgress(false))
      }
    },

  /** セッティングファイルのダウンロード */
  downloadSettingFile:
    (links: MediaLink[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(settingDetailActions.setInProgress(true))
        // ファイルDL失敗リスト
        const failedSettingFileList: string[] = []
        await Promise.all(
          links.map(async (link) => {
            try {
              await axios
                .get(link.mediaUrl, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, link.mediaName)
                })
            } catch (error) {
              failedSettingFileList.push(link.mediaName)
            }
          })
        )
        // 失敗したセッティングファイルがあれば、Storeに保持
        if (failedSettingFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedSettingFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(settingDetailActions.setInProgress(false))
      }
    },
}
