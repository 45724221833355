import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  FeatureDataInfo,
  FeatureDataListActionType,
  FeatureDataListDisplayCondition,
} from './types'
import { ResourceLoadingState } from 'state/utils/types'

export type FeatureDataListActions = ActionsUnion<typeof featureDataListActions>

export const featureDataListActions = {
  setFeatureDataGroupId: (
    featureDataGroupId: string
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_ID',
    { featureDataGroupId: string }
  > =>
    CreateAction(FeatureDataListActionType.SET_FEATURE_DATA_GROUP_ID, {
      featureDataGroupId,
    }),
  setFeatureDataGroupName: (
    featureDataGroupName: string
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_NAME',
    { featureDataGroupName: string }
  > =>
    CreateAction(FeatureDataListActionType.SET_FEATURE_DATA_GROUP_NAME, {
      featureDataGroupName,
    }),
  setFeatureDataList: (
    featureDataList: FeatureDataInfo[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_LIST',
    { featureDataList: FeatureDataInfo[] }
  > =>
    CreateAction(FeatureDataListActionType.SET_FEATURE_DATA_LIST, {
      featureDataList,
    }),
  clearFeatureDataList: (): Action<'CLEAR_FEATURE_DATA_LIST'> =>
    CreateAction(FeatureDataListActionType.CLEAR_FEATURE_DATA_LIST),
  setListDisplayCondition: (
    featureDataListDisplayCondition: FeatureDataListDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_LIST_DISPLAY_CONDITION',
    { featureDataListDisplayCondition: FeatureDataListDisplayCondition }
  > =>
    CreateAction(
      FeatureDataListActionType.SET_FEATURE_DATA_LIST_DISPLAY_CONDITION,
      {
        featureDataListDisplayCondition,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST,
      {
        inProgress,
      }
    ),
  setFeatureDataGroupSubState: (
    featureDataGroupSubState: ResourceLoadingState
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_SUB_STATE',
    { featureDataGroupSubState: ResourceLoadingState }
  > =>
    CreateAction(FeatureDataListActionType.SET_FEATURE_DATA_GROUP_SUB_STATE, {
      featureDataGroupSubState,
    }),
  clearFeatureDataListState: (): Action<'CLEAR_FEATURE_DATA_LIST_STATE'> =>
    CreateAction(FeatureDataListActionType.CLEAR_FEATURE_DATA_LIST_STATE),
}
