import React from 'react'

import { SpacerProps } from './types'

export const Spacer: React.FC<SpacerProps> = (props: SpacerProps) => {
  const width = props.axis === 'vertical' ? 1 : props.size
  const height = props.axis === 'horizontal' ? 1 : props.size

  return (
    <div
      style={{
        display: 'block',
        width,
        minWidth: width,
        height,
        minHeight: height,
      }}
    />
  )
}
