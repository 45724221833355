import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'

import { InferenceResultViewProps } from './types'
import { decode, rleFromString } from 'utils/RLE.js'
import { convertHexToRgb } from 'utils/colors'
import clsx from 'clsx'
import { CanvasInfo } from 'views/utils/types'

const CANVAS_SIZE_HEIGHT = 300

const useStyles = makeStyles()(() => ({
  canvasImage: {
    width: CANVAS_SIZE_HEIGHT * 1.78, // 720px x 1280 px の比率で横幅を指定
    height: CANVAS_SIZE_HEIGHT,
  },
  defaultCanvasImage: {
    width: 1280,
    height: 720,
  },
  canvasBox: {
    position: 'absolute',
    height: CANVAS_SIZE_HEIGHT,
  },
  defaultCanvasBox: {
    position: 'absolute',
    height: 720,
  },
}))

const getObjectText = (
  canvasInfo: CanvasInfo,
  label: boolean,
  score: boolean | undefined
): string => {
  if (label && score) {
    const toFixedScore = Number((canvasInfo.score * 100).toFixed(3))
    return `${canvasInfo.label}: ${toFixedScore}%`
  } else if (label) {
    return canvasInfo.label
  } else if (score) {
    const toFixedScore = Number((canvasInfo.score * 100).toFixed(3))
    return `${toFixedScore}%`
  }

  return ''
}

export const InferenceResultView: React.FC<InferenceResultViewProps> = (
  props: InferenceResultViewProps
) => {
  const { classes } = useStyles()
  const canvasImageRef = useRef<HTMLCanvasElement>(null)
  const canvasBoxRef = useRef<HTMLCanvasElement>(null)
  // const canvasImageMaskRef = useRef<HTMLCanvasElement>(null)
  const [resizeRateWidth, setResizeRateWidth] = useState<{
    rate: number
    width: number
  }>({ rate: 0, width: 0 })

  /** Imageの描画 */
  useEffect(() => {
    // 切り替わり時は、初期化する
    setResizeRateWidth({ rate: 0, width: 0 })
    const img = new Image()
    img.src = props.url

    img.onload = function () {
      if (canvasImageRef.current === null) return

      const canvas = canvasImageRef.current
      canvas.width = canvas.clientWidth
      canvas.height = canvas.clientHeight
      // canvas の高さと画像の高さから割り出した比率を画像幅に掛け合わせた、canvas に入る画像サイズの算出
      const resizedImageWidth = img.width * (canvas.height / img.height)
      // Boxの描画時に比率が必要なため保持
      setResizeRateWidth({
        rate: canvas.height / img.height,
        width: resizedImageWidth,
      })

      const context = canvas?.getContext('2d')
      if (context) {
        // Canvasの中心を x:0, y:0 にする
        context.translate(canvas.width / 2, canvas.height / 2)
        // Imageの描画
        context.drawImage(
          img,
          (resizedImageWidth / 2) * -1,
          (canvas.height / 2) * -1,
          resizedImageWidth,
          canvas.height
        )
      }
    }
  }, [props.url, canvasImageRef.current !== null])

  /** Box / Mask の描画 */
  useEffect(() => {
    if (canvasBoxRef.current === null) return
    const canvas = canvasBoxRef.current
    canvas.width = resizeRateWidth.width
    canvas.height = canvas.clientHeight

    const context = canvas?.getContext('2d')
    if (context) {
      if (resizeRateWidth.rate === 0 || resizeRateWidth.width === 0) {
        // resizeRateが初期値の場合は、Boxをクリア
        // 画像の切り替わり時もresizeRateが初期化されるためクリア
        context.clearRect(
          ((CANVAS_SIZE_HEIGHT * 2) / 2) * -1,
          (CANVAS_SIZE_HEIGHT / 2) * -1,
          CANVAS_SIZE_HEIGHT * 2,
          CANVAS_SIZE_HEIGHT
        )
        return
      }

      // clearRect後は、beginPath()が必須なため実行
      // https://developer.mozilla.org/ja/docs/Web/API/CanvasRenderingContext2D/clearRect
      context.beginPath()

      props.canvasInfos.forEach((canvasInfo) => {
        context.strokeStyle = canvasInfo.color
        context.lineWidth = 2
        // Boxの描画
        if (props.canvasDisplayCondition.bbox) {
          context.strokeRect(
            canvasInfo.x * resizeRateWidth.rate,
            canvasInfo.y * resizeRateWidth.rate,
            canvasInfo.width * resizeRateWidth.rate,
            canvasInfo.height * resizeRateWidth.rate
          )
        }

        // Label / score の描画
        if (
          props.canvasDisplayCondition.label ||
          props.canvasDisplayCondition.score
        ) {
          const text = getObjectText(
            canvasInfo,
            props.canvasDisplayCondition.label,
            props.canvasDisplayCondition.score
          )
          const labelWidth = context.measureText(text).width
          context.fillStyle = canvasInfo.color
          context.fillRect(
            canvasInfo.x * resizeRateWidth.rate - 1,
            canvasInfo.y * resizeRateWidth.rate - 16,
            labelWidth + 8,
            16
          )

          context.fillStyle = '#000000'
          context.fillText(
            text,
            canvasInfo.x * resizeRateWidth.rate + 4,
            canvasInfo.y * resizeRateWidth.rate - 4
          )
        }

        // Maskの描画
        if (canvasInfo.mask.counts.length === 0) return

        if (props.canvasDisplayCondition.mask) {
          const decoded =
            typeof canvasInfo.mask.counts === 'string'
              ? decode(
                  rleFromString(
                    new TextEncoder().encode(canvasInfo.mask.counts)
                  )
                )
              : decode(canvasInfo.mask.counts)

          const maskColor = convertHexToRgb(canvasInfo.color)
          const dataWithAdditionalLayers: number[] = new Array(
            decoded.length * maskColor.length
          )
          const redIndex = 0
          const greenIndex = 1
          const blueIndex = 2
          decoded.forEach((_, i) => {
            dataWithAdditionalLayers[i * 4] = maskColor[redIndex] // red
            dataWithAdditionalLayers[i * 4 + 1] = maskColor[greenIndex] // green
            dataWithAdditionalLayers[i * 4 + 2] = maskColor[blueIndex] // blue
            dataWithAdditionalLayers[i * 4 + 3] = // alpha
              decoded[
                (((i * canvasInfo.mask.size[0]) / decoded.length) | 0) + // Integer division
                  ((i * canvasInfo.mask.size[0]) % decoded.length) // Index of the transposed matrix
              ] & 100 // transmittance rate
          })

          const img = new ImageData(
            new Uint8ClampedArray(dataWithAdditionalLayers),
            canvasInfo.mask.size[1],
            canvasInfo.mask.size[0]
          )

          const renderer = document.createElement('canvas')
          renderer.width = canvasInfo.mask.size[1]
          renderer.height = canvasInfo.mask.size[0]
          const ctx = renderer.getContext('2d')
          if (ctx) {
            ctx.putImageData(img, 0, 0)
            context.drawImage(
              renderer,
              0,
              0,
              resizeRateWidth.width,
              canvas.height
            )
          }
        }
      })
    }
  }, [
    props.canvasInfos,
    canvasBoxRef.current !== null,
    resizeRateWidth,
    props.canvasDisplayCondition,
  ])

  return (
    <Box display='flex' justifyContent='center'>
      <canvas
        ref={canvasImageRef}
        style={
          props.imageHeight
            ? { height: props.imageHeight, width: props.imageHeight * 1.78 }
            : undefined
        }
        className={
          props.imageHeight
            ? undefined
            : clsx(
                props.isDialog
                  ? classes.defaultCanvasImage
                  : classes.canvasImage
              )
        }
      />
      <canvas
        ref={canvasBoxRef}
        style={
          props.imageHeight
            ? { height: props.imageHeight, position: 'absolute' }
            : undefined
        }
        className={
          props.imageHeight
            ? undefined
            : clsx(
                props.isDialog ? classes.defaultCanvasBox : classes.canvasBox
              )
        }
      />
    </Box>
  )
}
