import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Link as MuiLink } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'

import { isUndefined } from 'utils/typeguard'
import {
  GlobalLoading,
  EditableTextField,
  CopyableLabel,
  DataDetailItem,
  showToast,
  Toast,
  BreadcrumbsComponent,
} from 'views/components'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import {
  MediaLink,
  SettingDetailAction,
  settingDetailActions,
  SettingDetailOperations,
} from 'state/ducks/settingDetail'
import { convertByteToMatchUnit } from 'views/containers/utils'
import { SettingIcon } from 'views/components/atoms/icon/settingIcon'
import { hasSharedUserGroupQueryParameter } from 'views/containers/utils/queryParams'
import { formatDateTimeSec } from 'views/components/utils/date'

const mapStateToProps = (state: State) => ({
  ...state.pages.settingDetailState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, SettingDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** セッティング詳細取得 */
  getSettingDetail: (settingId: string, isSharedUserGroup: boolean) =>
    dispatch(
      SettingDetailOperations.getSettingDetail(settingId, isSharedUserGroup)
    ),
  /** Stateのクリア */
  clearSettingDetailState: () =>
    dispatch(settingDetailActions.clearSettingDetailState()),
  /** setting名を更新する */
  updateSettingName: (settingId: string, name: string) =>
    dispatch(SettingDetailOperations.updateSettingName(settingId, name)),
  /** remarksを更新する */
  updateRemarks: (settingId: string, remarks: string) =>
    dispatch(SettingDetailOperations.updateRemarks(settingId, remarks)),
  /** セッティングファイルをダウンロードする */
  downloadSettingFile: (links: MediaLink[]) =>
    dispatch(SettingDetailOperations.downloadSettingFile(links)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(settingDetailActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkButton: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  mt2Box: {
    marginTop: '16px',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
}))

const SettingDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )
  // パスパラメータからセッティングIDを取得
  const [selectedSettingId, setSelectedSettingId] = useState('')

  /** 初期実行 */
  useEffect(() => {
    return () => {
      props.clearSettingDetailState()
    }
  }, [])

  useEffect(() => {
    setSelectedSettingId(
      (props.match.params as { [key: string]: string })['settingId']
    )
    // 表示用詳細取得
    props.getSettingDetail(
      (props.match.params as { [key: string]: string })['settingId'] ?? '',
      isSharedUserGroup
    )
  }, [props.match.params])

  /** セッティング詳細が変更時にセットする */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.currentSettingDetail) &&
      !isUndefined(props.domainData.currentSettingDetail.remarks)
    ) {
      setEditingSettingName(props.domainData.currentSettingDetail.settingName)
      setEditingSettingRemarks(props.domainData.currentSettingDetail.remarks)
    }
  }, [props.domainData.currentSettingDetail])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  const algorithmName = useMemo(
    () =>
      props.algorithms.find(
        (algorithm) =>
          algorithm.algorithmId ===
          props.domainData.currentSettingDetail.algorithmId
      )?.metadata.name.ja ?? '',
    [props.domainData.currentSettingDetail.algorithmId]
  )

  /** セッティングファイルの取得エラー時Toast */
  useEffect(() => {
    if (props.appState.settingDetailState.settingDlLinkSubState === 'Failed') {
      showErrorToast('セッティングファイルの取得に失敗しました。')
    }
  }, [props.appState.settingDetailState.settingDlLinkSubState])

  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  const [nowTab, setNowTab] = useState(0)

  const [editingSettingName, setEditingSettingName] = useState('')
  const [editingSettingRemarks, setEditingSettingRemarks] = useState('')

  // 一般情報コンテンツ
  const infoTab: TabItems = {
    label: '一般情報',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <div className={classes.flexAndBetween}>
              <Box display='flex'>
                <Typography>セッティングファイル</Typography>
              </Box>
              <Box display='flex'>
                <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                <MuiLink
                  data-testid='all-download-setting-file'
                  style={{ cursor: 'pointer' }}
                  underline='none'
                  onClick={() => {
                    if (props.domainData.currentSettingDetail) {
                      let mediaLinks: MediaLink[] = []
                      props.domainData.currentSettingDetail.settingDlLinks.forEach(
                        (link) => {
                          mediaLinks = mediaLinks.concat(link.mediaLinks)
                        }
                      )
                      props.downloadSettingFile(mediaLinks)
                    }
                  }}
                >
                  一括ダウンロード
                </MuiLink>
              </Box>
            </div>
            <div className={classes.flexAndBetween} style={{ marginTop: 5 }}>
              <Box display='flex' flexWrap='wrap'>
                {props.domainData.currentSettingDetail
                  ? props.domainData.currentSettingDetail.settingDlLinks.map(
                      (info, index) => (
                        <Box mr={2} key={index}>
                          <Button
                            data-testid='all-download-setting-file'
                            variant='outlined'
                            style={{
                              backgroundColor: '#D9E5FF',
                            }}
                            onClick={() =>
                              props.downloadSettingFile(info.mediaLinks)
                            }
                          >
                            <FileDownloadOutlinedIcon
                              style={{ marginRight: '4px' }}
                            />
                            <Typography variant='subtitle2' color='primary'>
                              {`${info.linkName}`}
                            </Typography>
                          </Button>
                          <Box className={classes.mediaSizeText}>
                            (
                            {convertByteToMatchUnit(
                              info.mediaLinks.reduce(
                                (prev, current) => prev + current.mediaSize,
                                0
                              )
                            )}
                            )
                          </Box>
                        </Box>
                      )
                    )
                  : []}
              </Box>
            </div>
          </Box>
        </Box>
        <Box component={Paper} my={2}>
          <Box p={'24px 32px 32px'}>
            <DataDetailItem
              formHelperText='セッティンググループ'
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentSettingDetail.settingGroup
                      .settingGroupId
                  }
                />
              }
              startAdornment={
                <MuiLink
                  data-testid='model-group-detail'
                  variant='body1'
                  className={classes.linkButton}
                  underline='none'
                  onClick={() => {
                    history.push({
                      pathname: `/model-groups/${
                        props.domainData.currentSettingDetail.trainedModelGroup
                          .trainedModelGroupId
                      }${isSharedUserGroup ? '?shared-user-group=true' : ''}#2`,
                    })
                  }}
                >
                  {
                    props.domainData.currentSettingDetail.settingGroup
                      .settingGroupName
                  }
                </MuiLink>
              }
            />
            <Box mt={1}>
              <DataDetailItem
                formHelperText='セッティンググループバージョン'
                startAdornment={
                  <Typography>
                    {
                      props.domainData.currentSettingDetail.settingGroup
                        .settingGroupVersion.displayName
                    }
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='セッティングフォーマット'
                startAdornment={
                  <Typography>
                    {
                      props.domainData.currentSettingDetail.settingFormat
                        .settingFormatKind
                    }
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='セッティングフォーマットバージョン'
                startAdornment={
                  <Typography>
                    {
                      props.domainData.currentSettingDetail.settingFormat
                        .settingFormatVersion.displayName
                    }
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='データセットテンプレート'
                startAdornment={
                  <Typography>
                    {
                      props.domainData.currentSettingDetail.datasetTemplate
                        .datasetTemplateName
                    }
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
  }

  // 備考タブコンテンツ
  const noteTab: TabItems = {
    label: '備考',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <TextField
              style={{ width: '100%' }}
              value={editingSettingRemarks}
              onChange={(event) => setEditingSettingRemarks(event.target.value)}
              variant='outlined'
              multiline
              minRows={5}
              disabled={isSharedUserGroup}
              inputProps={{
                'data-testid': 'input-remarks',
              }}
            />
            {!isSharedUserGroup && (
              <Box
                display='flex'
                justifyContent='flex-end'
                width='100%'
                className={classes.mt2Box}
              >
                <Button
                  variant='outlined'
                  onClick={() =>
                    props.updateRemarks(
                      selectedSettingId,
                      editingSettingRemarks
                    )
                  }
                  data-testid='remarks-edit'
                  className={classes.noteTabButton}
                >
                  <Typography color={'primary'}>保存</Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </>
    ),
  }

  const tabItems: TabItems[] = [infoTab, noteTab]

  return (
    <>
      <div className={classes.container}>
        <Toast containerOptions={{ limit: 20 }}>
          <Box
            style={{
              position: 'sticky',
              top: '64px',
              backgroundColor: '#fafafa',
              zIndex: 10,
            }}
          >
            <Box className={classes.innerContainer}>
              <Box pt={3}>
                <BreadcrumbsComponent
                  breadcrumbsPath={[
                    {
                      name: 'モデルグループ一覧',
                      path: 'model-groups',
                    },
                    {
                      name: props.domainData.currentSettingDetail.settingGroup
                        .settingGroupName,
                      path: `${
                        props.domainData.currentSettingDetail.trainedModelGroup
                          .trainedModelGroupId
                      }${isSharedUserGroup ? '?shared-user-group=true' : ''}`,
                    },
                    {
                      name: props.domainData.currentSettingDetail.settingName,
                      path: '',
                    },
                  ]}
                />
              </Box>
              <div className={classes.flexAndBetween}>
                <Box
                  display='flex'
                  style={{
                    maxWidth: 'calc(100% - 280px)',
                    overflow: 'hidden',
                  }}
                >
                  <SettingIcon
                    className={classes.pageIcon}
                    data-testid='modelListTitleIcon'
                  />
                  <Box
                    height={76}
                    data-testid='model-detail-title'
                    style={{
                      overflow: 'hidden',
                    }}
                  >
                    <EditableTextField
                      value={editingSettingName}
                      disabled={isSharedUserGroup}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEditingSettingName(e.target.value)
                      }
                      onBlur={() => {
                        if (
                          props.domainData.currentSettingDetail.settingName !==
                          editingSettingName
                        ) {
                          props.updateSettingName(
                            selectedSettingId,
                            editingSettingName
                          )
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box mr={3}>
                  <CopyableLabel
                    value={selectedSettingId}
                    isTooltip
                    placement='top'
                  />
                </Box>
              </div>
              <Box p={1}>
                {props.domainData.currentSettingDetail &&
                props.domainData.currentSettingDetail.createdAt ? (
                  <Box height={24} display='flex' justifyContent='end'>
                    <TimerIcon style={{ marginRight: '4px' }} />
                    <Typography>
                      {formatDateTimeSec(
                        props.domainData.currentSettingDetail.createdAt?.toDate()
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <Box height={24} display='flex' justifyContent='end'></Box>
                )}
                <div className={classes.flexAndBetween}>
                  <Box display='flex' alignItems='center'>
                    <HandymanIcon
                      style={{ marginRight: '4px' }}
                      sx={{ color: 'text.secondary' }}
                    />
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography component='div'>
                        <h4>{algorithmName}</h4>
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    <Typography>
                      {props.domainData.currentSettingDetail?.createdBy}
                    </Typography>
                  </Box>
                </div>
              </Box>
              <Box
                style={{
                  backgroundColor: '#fafafa',
                }}
              >
                <Tabs
                  indicatorColor='primary'
                  value={nowTab}
                  style={{
                    paddingBottom: '16px',
                    marginBottom: '1px',
                  }}
                  onChange={(_, value) => setNowTab(value)}
                >
                  {tabItems.map((item, index) => (
                    <Tab
                      style={{
                        width: `${100 / tabItems.length}%`,
                        maxWidth: '1200px',
                      }}
                      key={index}
                      className={clsx(nowTab === index && classes.nowTab)}
                      label={item.label}
                      data-testid={`change-tab-${index}`}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className={classes.innerContainer}>
            <Paper elevation={0}>
              <Box>{tabItems[nowTab].displayInfo}</Box>
            </Paper>
          </Box>
        </Toast>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const SettingDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SettingDetail))
