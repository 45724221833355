import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'

import { AccountEntryActionType, AccountGroup, ToastInfo } from './types'

export type AccountEntryAction = ActionsUnion<typeof accountEntryActions>
export const accountEntryActions = {
  setAccountGroupList: (
    accountGroupList: AccountGroup[]
  ): ActionWithPayload<
    'SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_ENTRY',
    { accountGroupList: AccountGroup[] }
  > =>
    CreateAction(
      AccountEntryActionType.SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_ENTRY,
      {
        accountGroupList,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_ACCOUNT_ENTRY',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(AccountEntryActionType.SET_TOAST_INFO_FOR_ACCOUNT_ENTRY, {
      toastInfo,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ACCOUNT_ENTRY',
    { inProgress: boolean }
  > =>
    CreateAction(AccountEntryActionType.SET_IN_PROGRESS_FOR_ACCOUNT_ENTRY, {
      inProgress,
    }),
  setAccountEntryResult: (
    result: boolean
  ): ActionWithPayload<
    'SET_ACCOUNT_ENTRY_RESULT_FOR_ACCOUNT_ENTRY',
    { result: boolean }
  > =>
    CreateAction(
      AccountEntryActionType.SET_ACCOUNT_ENTRY_RESULT_FOR_ACCOUNT_ENTRY,
      {
        result,
      }
    ),
  clearAccountEntryState: (): Action<'CLEAR_ACCOUNT_ENTRY_STATE'> =>
    CreateAction(AccountEntryActionType.CLEAR_ACCOUNT_ENTRY_STATE),
}
