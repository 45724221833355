import { ModelQueryDocument } from './types'

import {
  extendedObjectClassificationForQuerySchema,
  preReleaseVersionSchema,
  timeStampSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** trained-model-query documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as ModelQueryDocument
  const schema = {
    required: [
      'algorithm-id',
      'algorithm-structure-id',
      'algorithm-structure-version',
      'generated-at',
      'model-kind',
      'trained-model-group-id',
      'trained-model-group-version',
      'trained-model-id',
      'trained-model-name',
      'trained-model-remarks',
      'training-algorithm-version',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    allOf: [
      {
        anyOf: [{ required: ['dataset-list'] }, { required: ['dataset-id'] }],
      },
      {
        anyOf: [{ required: ['base-model'] }, { required: ['base-model-id'] }],
      },
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'algorithm-structure-id': {
        type: 'string',
      },
      'algorithm-structure-version': {
        ...versionSchema,
      },
      'base-model-id': {
        type: 'string',
      },
      'base-model': {
        required: ['trained-model-id', 'user-group-id'],
        type: 'object',
        properties: {
          'trained-model-id': {
            type: 'string',
          },
          'user-group-id': {
            type: 'string',
          },
        },
      },
      'dataset-id': {
        type: 'string',
      },
      'dataset-list': {
        type: 'array',
        items: {
          required: ['generation', 'user-group-id', 'dataset-id'],
          type: 'object',
          properties: {
            generation: {
              type: 'number',
            },
            'user-group-id': {
              type: 'string',
            },
            'dataset-id': {
              type: 'string',
            },
          },
        },
      },
      'evaluation-status': {
        type: 'string',
      },
      'generation-type': {
        type: 'string',
        enum: ['Upload', 'Training', 'Unknown'],
      },
      'generated-at': {
        ...timeStampSchema,
      },
      'ml-pipeline-id': {
        type: 'string',
      },
      'model-kind': {
        type: 'string',
      },
      'setting-id': {
        type: 'string',
      },
      'step-id': {
        type: 'string',
      },
      'trained-model-group-id': {
        type: 'string',
      },
      'trained-model-group-version': {
        ...versionSchema,
      },
      'trained-model-id': {
        type: 'string',
      },
      'trained-model-name': {
        type: 'string',
      },
      'trained-model-remarks': {
        type: 'string',
      },
      'training-algorithm-version': {
        ...preReleaseVersionSchema,
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
      extended: {
        type: 'object',
        properties: {
          'object-classification': {
            ...extendedObjectClassificationForQuerySchema,
          },
        },
      },
    },
  }
  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'trained-model-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
