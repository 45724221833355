import { Timestamp } from 'state/firebase'
import { TransactionStatusKind } from 'state/utils/types'

export const InferenceListActionType = {
  SET_INFERENCE_LIST: 'SET_INFERENCE_LIST',
  SET_INFERENCE_DISPLAY_CONDITION: 'SET_INFERENCE_DISPLAY_CONDITION',
  CLEAR_INFERENCE_DISPLAY_CONDITION: 'CLEAR_INFERENCE_DISPLAY_CONDITION',
  CLEAR_INFERENCE_PAGING_STATE: 'CLEAR_INFERENCE_PAGING_STATE',
  SET_ML_PIPELINE_IDS_FOR_INFERENCE_LIST:
    'SET_ML_PIPELINE_IDS_FOR_INFERENCE_LIST',
  SET_IN_PROGRESS_FOR_INFERENCE_LIST: 'SET_IN_PROGRESS_FOR_INFERENCE_LIST',
  SET_CURRENT_INFERENCE_LIST_SNAPSHOT: 'SET_CURRENT_INFERENCE_LIST_SNAPSHOT',
  CLEAR_INFERENCE_LIST_STATE: 'CLEAR_INFERENCE_LIST_STATE',
} as const

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface TrainedModel {
  trainedModelId: string
  trainedModelName: string
}

interface GroupedData {
  groupedDataId: string
  groupedDataName: string
}

export interface Inference {
  mlPipelineId: string
  mlPipelineName: string
  algorithmName: string
  progress: Progress
  startedAt: Timestamp
  endedAt?: Timestamp
  groupedData: GroupedData
  trainedModel: TrainedModel
  accountId: string
}

export interface InferenceDisplayCondition {
  algorithmId?: string
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface InferenceListDomainData {
  currentInferenceList: Inference[]
  inferenceDisplayCondition: InferenceDisplayCondition
  mlPipelineIds: string[]
  currentInferenceListSnapshot?: () => void
}

export interface InferenceListAppState {
  inProgress: boolean
}

export interface InferenceList {
  domainData: InferenceListDomainData
  appState: InferenceListAppState
}
