import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { CustomTrainingParamsType, SettingSchema } from '.'
import axios from 'axios'

const timeoutLimitSeconds = 15

export const CustomTrainingApi = {
  executeCustomTraining: (
    customTrainingParams: CustomTrainingParamsType
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-execute',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      ...customTrainingParams,
    }),

  /** セッティング付加情報取得の署名付きURLを取得する */
  getSettingFormatSchemaSignedUrl: (
    settingFormatId: string,
    settingFormatVersion: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'setting-format-schema-file'
    )({
      settingFormatId,
      settingFormatVersion,
    }),

  /** セッティング付加情報を取得する */
  getSettingSchema: async (url: string) =>
    (
      await axios.get<SettingSchema>(url, {
        headers: { 'Content-Type': 'application/json' },
      })
    ).data,
}
