import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'

import { DataDetailItemProps } from './types'

const useStyles = makeStyles()(() => ({
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const DataDetailItem: React.FC<DataDetailItemProps> = (
  props: DataDetailItemProps
) => {
  const { classes } = useStyles()
  return (
    <Box display='flex' flexDirection='column'>
      <FormHelperText>{props.formHelperText}</FormHelperText>
      {props.endAdornment ? (
        <Box borderBottom='1px solid black' height='36px'>
          <Typography component='div' className={classes.flexAndBetween}>
            <div>{props.startAdornment}</div>
            <div>{props.endAdornment}</div>
          </Typography>
        </Box>
      ) : (
        <Box
          borderBottom='1px solid black'
          height='36px'
          display='flex'
          alignItems='center'
        >
          <Typography component='div'>
            <div>{props.startAdornment}</div>
          </Typography>
        </Box>
      )}
    </Box>
  )
}
