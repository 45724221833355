import { CreateDatasetAction } from 'state/ducks/createDataset/actions'
import { TrainingData } from 'state/utils/types'
import {
  CreateDatasetState,
  CreateDatasetActionType,
  AddedAnnotationSetList,
} from './types'

const initialState: CreateDatasetState = {
  domainData: {
    generatedFor: 'Training',
    datasetMetadata: { name: '', remarks: '' },
    selectedAlgorithmId: undefined,
    selectedAlgorithmVersion: undefined,
    availableDatasetTemplates: [],
    selectedAnnotationFormat: undefined,
    selectedDatasetTemplate: undefined,
    addedAnnotationSetList: [],
    createdDatasetId: '',
    trainingAlgorithmVersionDisplayCondition: {
      sortKey: 'version',
      sortOrder: 'desc',
    },
    classSets: [],
    selectedClassSet: undefined,
    classSetDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
    },
  },
  appState: {
    datasetState: 'AlgorithmState',
    datasetSubState: {
      algorithmSubState: 'unselected',
      datasetTemplateSubState: 'unselected',
      annotationSubState: 'unselected',
      inputDataSubState: 'unselected',
      classSetSubState: 'unselected',
      metadataSubState: 'emptyRequired',
      uploadSubState: 'beforeUpload',
    },
    inProgress: false,
    toastInfo: undefined,
  },
}

export const CreateDatasetReducer = (
  state: CreateDatasetState = initialState,
  action: CreateDatasetAction
): CreateDatasetState => {
  switch (action.type) {
    case CreateDatasetActionType.SET_TRAINING_ALGORITHM_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainingAlgorithmVersionDisplayCondition:
            action.payload.trainingAlgorithmDisplayCondition,
        },
      }
    case CreateDatasetActionType.SET_ALGORITHM_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            algorithmSubState: action.payload.algorithmSubState,
          },
        },
      }
    case CreateDatasetActionType.SET_DATASET_TEMPLATE_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            datasetTemplateSubState: action.payload.datasetTemplateSubState,
          },
        },
      }
    case CreateDatasetActionType.SET_SELECTED_ALGORITHM_ID_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithmId: action.payload.algorithmId,
        },
      }
    case CreateDatasetActionType.SET_SELECTED_ALGORITHM_VERSION_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithmVersion: action.payload.selectedAlgorithmVersion,
        },
      }
    case CreateDatasetActionType.SET_AVAILABLE_DATASET_TEMPLATES:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          availableDatasetTemplates: action.payload.availableDatasetTemplates,
        },
      }
    case CreateDatasetActionType.SET_SELECTED_ANNOTATION_FORMAT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAnnotationFormat: action.payload.selectedAnnotationFormat,
        },
      }
    case CreateDatasetActionType.SET_SELECTED_DATASET_TEMPLATE_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDatasetTemplate: action.payload.selectedDatasetTemplate,
        },
      }

    case CreateDatasetActionType.ADD_ANNOTATION_FILE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          addedAnnotationSetList: action.payload.annotationSetList,
        },
      }
    case CreateDatasetActionType.COMPLETE_IMAGE_FILE_SAVE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            inputDataSubState: action.payload.inputDataSubState,
          },
        },
      }
    case CreateDatasetActionType.SET_DATASET_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetMetadata: {
            remarks: state.domainData.datasetMetadata.remarks,
            name: action.payload.datasetName,
          },
        },
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            metadataSubState: action.payload.datasetName
              ? 'inputRequired'
              : 'emptyRequired',
          },
        },
      }
    case CreateDatasetActionType.SET_DATSET_REMARKS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetMetadata: {
            remarks: action.payload.remarks,
            name: state.domainData.datasetMetadata.name,
          },
        },
      }
    case CreateDatasetActionType.SET_FILE_SIZE_ERROR:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          addedAnnotationSetList: state.domainData.addedAnnotationSetList.map(
            (annotationSet): AddedAnnotationSetList => {
              return {
                ...annotationSet,
                groupedData: {
                  trainingDataList:
                    annotationSet.groupedData.trainingDataList.map(
                      (trainingData): TrainingData =>
                        trainingData.file?.name ===
                        action.payload.errorFile.name
                          ? {
                              ...trainingData,
                              fileStatus: 'checkError',
                            }
                          : trainingData
                    ),
                },
              }
            }
          ),
        },
      }
    case CreateDatasetActionType.CLEAR_CREATE_DATASET_STATE:
      return { ...initialState }
    case CreateDatasetActionType.SET_DATASET_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetState: action.payload.datasetState,
        },
      }
    case CreateDatasetActionType.SET_ANNOTATION_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            annotationSubState: action.payload.annotationSubState,
          },
        },
      }
    case CreateDatasetActionType.CLEAR_METADATA_STEP_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetMetadata: action.payload.metadata,
          addedAnnotationSetList: action.payload.annotationSetList,
        },
        appState: {
          datasetState: action.payload.datasetState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            inputDataSubState: action.payload.inputDataSubState,
            metadataSubState: action.payload.metadataSubState,
          },
          inProgress: state.appState.inProgress,
        },
      }
    case CreateDatasetActionType.CLEAR_UPLOAD_STEP_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetMetadata: {
            ...action.payload.metadata,
          },
        },
        appState: {
          datasetState: action.payload.datasetState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            metadataSubState: action.payload.metadataSubState,
          },
          inProgress: state.appState.inProgress,
        },
      }
    case CreateDatasetActionType.UPLOAD_COMPLETED:
    case CreateDatasetActionType.CREATE_DATASET_DOCUMENT_ERROR:
    case CreateDatasetActionType.UPLOAD_ERROR:
    case CreateDatasetActionType.CREATE_DATASET_UPLOADING:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            uploadSubState: action.payload.uploadSubState,
          },
        },
      }
    case CreateDatasetActionType.CREATE_DATASET_SET_DATASETID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          createdDatasetId: action.payload.datasetId,
        },
      }
    case CreateDatasetActionType.UPLOAD_ERROR_ALL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          addedAnnotationSetList: action.payload.annotationSetList,
        },
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            uploadSubState: action.payload.uploadSubState,
          },
        },
      }
    case CreateDatasetActionType.CLEAR_DATASET_TEMPLATE_STEP_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDatasetTemplate:
            initialState.domainData.selectedDatasetTemplate,
        },
        appState: {
          ...state.appState,
          datasetState: 'AlgorithmState',
          datasetSubState: {
            ...state.appState.datasetSubState,
            datasetTemplateSubState: 'unselected',
          },
        },
      }
    case CreateDatasetActionType.CLEAR_ANNOTATION_STEP_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          addedAnnotationSetList:
            initialState.domainData.addedAnnotationSetList,
        },
        appState: {
          ...state.appState,
          datasetState: 'DatasetTemplateState',
          datasetSubState: {
            ...state.appState.datasetSubState,
            annotationSubState: 'unselected',
          },
        },
      }
    case CreateDatasetActionType.CLEAR_INPUT_STEP_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          addedAnnotationSetList:
            initialState.domainData.addedAnnotationSetList,
        },
        appState: {
          ...state.appState,
          datasetState: 'AnnotationState',
          datasetSubState: {
            ...state.appState.datasetSubState,
            annotationSubState: 'unselected',
            inputDataSubState: 'unselected',
          },
        },
      }
    case CreateDatasetActionType.SET_IN_PROGRESS:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case CreateDatasetActionType.SET_CLASS_SET_LIST_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: action.payload.classSets,
        },
      }
    case CreateDatasetActionType.CLEAR_CLASS_SET_LIST_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: initialState.domainData.classSets,
        },
      }

    case CreateDatasetActionType.SET_SELECTED_CLASS_SET_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedClassSet: action.payload.classSet,
        },
      }
    case CreateDatasetActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: {
            ...initialState.domainData.classSetDisplayCondition,
          },
        },
      }
    case CreateDatasetActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: action.payload.displayCondition,
        },
      }
    case CreateDatasetActionType.SET_CLASS_SET_SUB_STATE_FOR_CREATE_DATASET:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetSubState: {
            ...state.appState.datasetSubState,
            classSetSubState: action.payload.classSetSubState,
          },
        },
      }
    case CreateDatasetActionType.CLEAR_CLASS_SET_STEP_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedClassSet: initialState.domainData.selectedClassSet,
          classSets: initialState.domainData.classSets,
          classSetDisplayCondition:
            initialState.domainData.classSetDisplayCondition,
        },
        appState: {
          datasetState: 'AlgorithmState',
          datasetSubState: {
            ...state.appState.datasetSubState,
            classSetSubState: 'unselected',
          },
          inProgress: state.appState.inProgress,
        },
      }
    case CreateDatasetActionType.SET_TOAST_INFO_FOR_CREATE_DATASET:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    default:
      return state
  }
}
