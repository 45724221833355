import { InferenceDetailAction } from './actions'
import { InferenceDetailActionType, InferenceDetail } from './types'

const initialState: InferenceDetail = {
  domainData: {
    currentInferenceDetail: undefined,
    resultFiles: [],
    mlPipelineLogFiles: [],
    currentInferenceResults: undefined,
    selectedTrainingDataId: '',
    inferenceResultDisplayCondition: {
      mask: false,
      bbox: true,
      label: true,
      score: true,
      selectedLabelIds: [],
    },
  },
  appState: {
    inferenceDetailState: {
      inferenceResultSubState: 'BeforeLoading',
      processedTrainingDataUrlSubState: 'BeforeLoading',
      inferenceResultDlLinkSubState: 'BeforeLoading',
      mlPipelineLogSubState: 'BeforeLoading',
      mlPipelineDataState: 'BeforeLoading',
    },
    toastInfo: undefined,
    isInProgressForGettingInferenceDetail: false,
    isInProgressForGettingInferenceResult: false,
    isInProgressForDownloading: false,
    isInProgressForRendering: false,
  },
}

export const InferenceDetailReducer = (
  state: InferenceDetail = initialState,
  action: InferenceDetailAction
): InferenceDetail => {
  switch (action.type) {
    case InferenceDetailActionType.SET_CURRENT_INFERENCE_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentInferenceDetail: action.payload.currentInferenceDetail,
        },
      }
    case InferenceDetailActionType.SET_CURRENT_INFERENCE_RESULTS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentInferenceResults: action.payload.currentInferenceResults,
        },
      }
    case InferenceDetailActionType.SET_INFERENCE_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          inferenceDetailState: action.payload.inferenceDetailState,
        },
      }
    case InferenceDetailActionType.SET_IN_PROGRESS_FOR_GETTING_INFERENCE_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingInferenceDetail:
            action.payload.isInProgressForGettingInferenceDetail,
        },
      }
    case InferenceDetailActionType.CLEAR_INFERENCE_DETAIL_STATE:
      return {
        ...initialState,
      }
    case InferenceDetailActionType.SET_TOAST_INFO_FOR_INFERENCE_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case InferenceDetailActionType.SET_INFERENCE_RESULT_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceResultDisplayCondition: action.payload.displayCondition,
        },
      }
    case InferenceDetailActionType.SET_IN_PROGRESS_FOR_GETTING_INFERENCE_RESULT:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingInferenceResult:
            action.payload.isInProgressForGettingInferenceResult,
        },
      }
    case InferenceDetailActionType.SET_IN_PROGRESS_FOR_RENDERING:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForRendering: action.payload.isInProgressForRendering,
        },
      }
    case InferenceDetailActionType.SET_IN_PROGRESS_FOR_DOWNLOADING:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForDownloading: action.payload.isInProgressForDownloading,
        },
      }
    case InferenceDetailActionType.SET_SELECTED_IMAGE_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingDataId: action.payload.imageId,
        },
      }
    case InferenceDetailActionType.SET_RESULT_FILES:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          resultFiles: action.payload.resultLogFiles,
        },
      }
    case InferenceDetailActionType.SET_ML_PIPELINE_LOG_FILES:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelineLogFiles: action.payload.mlPipelineLogFiles,
        },
      }
    default:
      return state
  }
}
