import { BaseInferenceContainerImageDocument } from './types'

import {
  optionalPreReleaseVersionSchema,
  timeStampSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** base-inference-container-image documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as BaseInferenceContainerImageDocument
  const schema = {
    required: [
      'algorithm-id',
      'inference-algorithm-version',
      'base-inference-container-image-id',
      'built-at',
      'container-image-digest',
      'container-image-name',
      'container-image-platform',
      'container-image-tags',
      'container-interface-id',
      'container-interface-version',
      'requirements',
      'usage-type',
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'inference-algorithm-version': {
        ...optionalPreReleaseVersionSchema,
      },
      'base-inference-container-image-id': {
        type: 'string',
      },
      'built-at': {
        ...timeStampSchema,
      },
      'container-image-digest': {
        type: 'string',
      },
      'container-image-name': {
        type: 'string',
      },
      'container-image-platform': {
        required: ['architecture', 'os'],
        type: 'object',
        properties: {
          architecture: {
            type: 'string',
          },
          os: {
            type: 'string',
          },
        },
      },
      'container-image-tags': {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      'container-interface-id': {
        type: 'string',
      },
      'container-interface-version': {
        ...versionSchema,
      },
      'dockerfile-path': {
        type: 'string',
      },
      requirements: {
        type: 'object',
      },
      'usage-type': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'base-inference-container-image',
      value: data as unknown as DataObject,
    })
    return false
  }
}
