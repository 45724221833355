import { Dispatch } from 'redux'
import { createImageSetActions } from './actions'
import { State } from 'state/store'
import { ImageSetStateKindArray, AnnotationSet } from './types'
import { FileUploadApi } from 'state/ducks/createDataset/apis'
import {
  AnnotationSetListRequestType,
  CreateDatasetRequestType,
  GroupedImage,
} from '../createDataset'
import { isUndefined } from 'utils/typeguard'
import { TrainingData } from 'state/utils/types'

export const createImageSetOperations = {
  /** アップロードするデータを作成し、FireStoreに作成GCSにアップロード */
  uploadImageSet:
    (files: File[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(createImageSetActions.setInProgress(true))
      try {
        // アノテーションを作成
        const annotation: AnnotationSet = {
          annotationSetId: '',
          groupedData: {
            trainingDataList: [],
          },
        }

        // fileの数作成
        for (const file of files) {
          const groupedImage: TrainingData = {
            file: file,
            trainingDataId: '',
            dataKind: 'Image',
            fileStatus: 'uploading',
          }
          annotation.groupedData.trainingDataList.push(groupedImage)
        }
        dispatch(createImageSetActions.addAnnotation(annotation))

        // ファイルのデータ
        const fileInfoList: {
          fileType: string | undefined
          fileName: string | undefined
        }[] =
          getState().pages.currentCreateImageSetState.domainData.annotationSet.groupedData.trainingDataList.map(
            (item) => {
              return {
                fileType: item.file?.type,
                fileName: item.file?.name,
              }
            }
          )
        // 画像 fileUpload
        // GCSへの署名付きURLを取得する
        const res = await FileUploadApi.getFileUploadSignedUrl(fileInfoList)
        // 画像ごとの署名付きURLとtrainingImageId
        const signedUrlMap: {
          [fileName: string]: { url: string; trainingImageId: string }
        } = res.data

        const groupedImageList =
          getState().pages.currentCreateImageSetState.domainData.annotationSet
            .groupedData.trainingDataList
        // ファイルをGCSにアップロード
        await Promise.all(
          groupedImageList
            .filter((groupedImage) => groupedImage.fileStatus === 'uploading')
            .map(async (groupedImage) => {
              if (groupedImage.file && signedUrlMap[groupedImage.file.name]) {
                const apiInfo = await FileUploadApi.uploadFile(
                  signedUrlMap[groupedImage.file.name].url,
                  groupedImage.file
                )
                if (
                  apiInfo.hasError &&
                  apiInfo.fileName === groupedImage.file.name
                ) {
                  dispatch(
                    createImageSetActions.addAnnotation({
                      ...getState().pages.currentCreateImageSetState.domainData
                        .annotationSet,
                      groupedData: {
                        ...getState().pages.currentCreateImageSetState
                          .domainData.annotationSet.groupedData,
                        trainingDataList:
                          getState().pages.currentCreateImageSetState.domainData.annotationSet.groupedData.trainingDataList.map(
                            (trainingData) => {
                              return {
                                file: trainingData.file,
                                trainingDataId: groupedImage.file
                                  ? signedUrlMap[groupedImage.file.name]
                                      .trainingImageId
                                  : '',
                                dataKind: trainingData.dataKind,
                                fileStatus: 'uploadError',
                              }
                            }
                          ),
                      },
                    })
                  )
                } else {
                  dispatch(
                    createImageSetActions.addAnnotation({
                      ...getState().pages.currentCreateImageSetState.domainData
                        .annotationSet,
                      groupedData: {
                        ...getState().pages.currentCreateImageSetState
                          .domainData.annotationSet.groupedData,
                        trainingDataList:
                          getState().pages.currentCreateImageSetState.domainData.annotationSet.groupedData.trainingDataList.map(
                            (groupedImage) => {
                              return {
                                file: groupedImage.file,
                                trainingDataId: groupedImage.file
                                  ? signedUrlMap[groupedImage.file.name]
                                      .trainingImageId
                                  : '',
                                dataKind: groupedImage.dataKind,
                                fileStatus: 'completed',
                              }
                            }
                          ),
                      },
                    })
                  )
                }
              } else {
                return false
              }
            })
        )

        const datasetMetadata: { name: string; remarks: string } = {
          name:
            getState().pages.currentCreateImageSetState.domainData
              .imageSetMetaData.name ?? '',
          remarks:
            getState().pages.currentCreateImageSetState.domainData
              .imageSetMetaData.remarks ?? '',
        }

        const selectedAlgorithmId =
          getState().pages.currentCreateImageSetState.domainData
            .selectedAlgorithm

        if (isUndefined(selectedAlgorithmId)) {
          return
        }

        // リクエストするアノテーションリストを作成
        const annotationSetList: AnnotationSetListRequestType = {
          annotationSetKind: 'Inference',
          annotationData: {
            annotationId: '',
            metadata: {
              name: '',
            },
          },
          groupedData: {
            groupedImageList: [
              ...getState()
                .pages.currentCreateImageSetState.domainData.annotationSet.groupedData.trainingDataList.filter(
                  (item) => item.fileStatus === 'completed'
                )
                .map((item) => {
                  return {
                    trainingDataId: item.trainingDataId,
                    fileName: item.file?.name ? item.file?.name : '',
                    fileType: item.file?.type ? item.file?.type : '',
                    dataKind: 'Image',
                  } as GroupedImage
                }),
            ],
          },
        }

        // DatasetのDocを作成する
        const dataSet: CreateDatasetRequestType = {
          algorithmId: selectedAlgorithmId,
          metadata: datasetMetadata,
          annotationSetList: [annotationSetList],
          annotationFormatId: '',
          generatedFor: 'Inference',
          datasetTemplateId: '', // FIXME: 画像セットの修正のタイミングで対応
        }
        const datasetResult = await FileUploadApi.dataSetFireStore(dataSet)

        // 取得したGroupedDataIdをセット
        dispatch(
          createImageSetActions.setGroupedDataId(
            datasetResult.data.groupedDataId
          )
        )
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(createImageSetActions.setInProgress(false))
      }
    },
  /** 画像を選択して入力する */
  setInputFiles:
    (files: File[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const currentImages = [
        ...getState().pages.currentCreateImageSetState.domainData.imageSetFiles,
        ...files,
      ]
      dispatch(createImageSetActions.addImageSetFile(currentImages))
    },
  /** 次へ */
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        createImageSetActions.setImageSetState(
          ImageSetStateKindArray[currentStep + 1]
        )
      )
    },
  /** 前へ */
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 0:
          return
        case 2:
          dispatch(createImageSetActions.setImageSetMetadataName(undefined))
          dispatch(createImageSetActions.setImageSetMetadataRemarks(undefined))
          break
        default:
          break
      }

      dispatch(
        createImageSetActions.setImageSetState(
          ImageSetStateKindArray[currentStep - 1]
        )
      )
    },
  /** メタデータの備考入力 */
  setImageSetMetadataRemarks:
    (remarks?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(createImageSetActions.setImageSetMetadataRemarks(remarks))
    },
  /** メタデータの名前入力 */
  setImageSetMetadataName:
    (name?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(createImageSetActions.setImageSetMetadataName(name))
    },
  /** 状態をクリア */
  clearCreateImageSetState:
    () =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(createImageSetActions.clearImageSetState())
    },
}
