import React from 'react'

import PsychologyIcon from '@mui/icons-material/Psychology'
import IconButton from '@mui/material/IconButton'

import { ModelIconProps } from './types'

export const ModelIcon: React.FC<ModelIconProps> = (props: ModelIconProps) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <PsychologyIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
