import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
const timeoutLimitSeconds = 15

export const CustomerChangeApi = {
  updateCustomClaim: (
    uid: string,
    updateParams: {
      [key: string]: unknown
    }
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-update-customclaim',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      uid,
      updateParams,
    }),
  updateLastAccessed: (customerId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'update-user-customer-accessed-last',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )(customerId),
}
