import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import { ResourceLoadingState } from 'state/utils/types'
import {
  ModelGroupDetailActionType,
  CurrentTrainedModelGroupDetail,
  TrainedModelListDisplayCondition,
  ToastInfo,
  Setting,
  SettingListDisplayCondition,
} from './types'

export type ModelGroupDetailAction = ActionsUnion<
  typeof modelGroupDetailActions
>

export const modelGroupDetailActions = {
  setCurrentModelGroupDetail: (
    currentTrainedModelGroupDetail: CurrentTrainedModelGroupDetail
  ): ActionWithPayload<
    'SET_CURRENT_MODEL_GROUP_DETAIL',
    { currentTrainedModelGroupDetail: CurrentTrainedModelGroupDetail }
  > =>
    CreateAction(ModelGroupDetailActionType.SET_CURRENT_MODEL_GROUP_DETAIL, {
      currentTrainedModelGroupDetail,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MODEL_GROUP_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      ModelGroupDetailActionType.SET_IN_PROGRESS_FOR_MODEL_GROUP_DETAIL,
      {
        inProgress,
      }
    ),
  clearModelGroupDetailState: (): Action<'CLEAR_MODEL_GROUP_DETAIL_STATE'> =>
    CreateAction(ModelGroupDetailActionType.CLEAR_MODEL_GROUP_DETAIL_STATE),
  setListDisplayCondition: (
    condition: TrainedModelListDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_MODEL_GROUP_DETAIL',
    { condition: TrainedModelListDisplayCondition }
  > =>
    CreateAction(
      ModelGroupDetailActionType.SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_MODEL_GROUP_DETAIL,
      {
        condition,
      }
    ),
  setModelListPagingState: (
    nextPageSubState: 'Enable' | 'Unable'
  ): ActionWithPayload<
    'SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_MODEL_GROUP_DETAIL',
    { nextPageSubState: 'Enable' | 'Unable' }
  > =>
    CreateAction(
      ModelGroupDetailActionType.SET_TABLE_NEXT_PAGE_SUB_STATE_FOR_MODEL_GROUP_DETAIL,
      {
        nextPageSubState,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_MODEL_GROUP_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      ModelGroupDetailActionType.SET_TOAST_INFO_FOR_MODEL_GROUP_DETAIL,
      {
        toastInfo,
      }
    ),
  setModelGroupDataState: (
    data: ResourceLoadingState
  ): ActionWithPayload<
    'SET_MODEL_GROUP_DATA_STATE',
    { data: ResourceLoadingState }
  > =>
    CreateAction(ModelGroupDetailActionType.SET_MODEL_GROUP_DATA_STATE, {
      data,
    }),
  setIconUrl: (
    iconUrl: string
  ): ActionWithPayload<'SET_ICON_URL', { iconUrl: string }> =>
    CreateAction(ModelGroupDetailActionType.SET_ICON_URL, {
      iconUrl,
    }),

  setSettingList: (
    settingList: Setting[]
  ): ActionWithPayload<
    'SET_SETTING_LIST_FOR_MODEL_GROUP_DETAIL',
    { settingList: Setting[] }
  > =>
    CreateAction(
      ModelGroupDetailActionType.SET_SETTING_LIST_FOR_MODEL_GROUP_DETAIL,
      {
        settingList,
      }
    ),

  setSettingListDisplayCondition: (
    settingListDisplayCondition: SettingListDisplayCondition
  ): ActionWithPayload<
    'SET_SETTING_LIST_DISPLAY_CONDITION',
    { settingListDisplayCondition: SettingListDisplayCondition }
  > =>
    CreateAction(
      ModelGroupDetailActionType.SET_SETTING_LIST_DISPLAY_CONDITION,
      {
        settingListDisplayCondition,
      }
    ),

  setIsInProgressForGettingSettingList: (
    isInProgressForGettingSettingList: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_SETTING_LIST',
    { isInProgressForGettingSettingList: boolean }
  > =>
    CreateAction(
      ModelGroupDetailActionType.SET_IN_PROGRESS_FOR_GETTING_SETTING_LIST,
      {
        isInProgressForGettingSettingList,
      }
    ),
}
