import { Dispatch } from 'redux'
import {
  getFeatureDataGroupQueriesCollection,
  getAccountSettingCollection,
  getFeatureDataGroupMetadataCollection,
} from 'state/firebase'
import { featureDataGroupDetailActions, RelatedFeatureData } from './'
import { State } from 'state/store'
import {
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { compareVersions } from 'utils/versions'
import { FeatureDataGroupDetailApi } from './apis'
import { HttpsCallableResult } from 'firebase/functions'
import { isUndefined } from 'utils/typeguard'
import { ToastInfo } from 'state/utils'
import { domainDataOperations } from 'state/app/domainData/operations'

type FeatureDataVersion = {
  ['display-name']: string
  ['major']: number
  ['minor']: number
  ['patch']: number
}

type FeatureData = {
  ['feature-data-group-version']: FeatureDataVersion
  ['feature-data-id']: string
  ['feature-data-name']: string
}

const getFeatureDataList = (list: FeatureData[]): RelatedFeatureData[] => {
  const featureDataList = list.map((item) => {
    return {
      featureDataId: item ? item['feature-data-id'] : '',
      featureDataVersion: {
        displayName: item
          ? item['feature-data-group-version']['display-name']
          : '',
        major: item ? item['feature-data-group-version']['major'] : 0,
        minor: item ? item['feature-data-group-version']['minor'] : 0,
        patch: item ? item['feature-data-group-version']['patch'] : 0,
      },
      featureDataName: item ? item['feature-data-name'] : '',
    }
  })

  if (featureDataList.length === 1) return featureDataList
  // バージョンでソートして返す
  return featureDataList.sort((a, b) =>
    compareVersions(a.featureDataVersion, b.featureDataVersion, 'desc')
  )
}

export const FeatureDataGroupDetailOperations = {
  /** 特徴量データ詳細で表示パラメータを取得する */
  getFeatureDataGroupDetail:
    (featureDataGroupId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupDetailActions.setInProgress(true))
        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        /** feature-data-query document */
        const featureDataGroupQuery = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where('feature-data-group-id', '==', featureDataGroupId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((featureDataGroup) => featureDataGroup.data())[0]
          : (
              await getDoc(
                doc(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  featureDataGroupId
                )
              )
            ).data()

        if (!featureDataGroupQuery) {
          dispatch(
            featureDataGroupDetailActions.setFeatureDataGroupDataState(
              'NotFoundProcessed'
            )
          )
          return
        }

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                featureDataGroupQuery ? featureDataGroupQuery['created-by'] : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        let iconUrl = undefined

        try {
          iconUrl = (await FeatureDataGroupDetailApi.getSignedUrl(
            featureDataGroupQuery
              ? featureDataGroupQuery['feature-data-group-id']
              : '',
            featureDataGroupQuery
              ? featureDataGroupQuery['icon-name'] ?? ''
              : ''
          )) as HttpsCallableResult<{ url: string }>
        } catch {
          iconUrl = undefined
        }

        const algorithmDoc = getState().app.domainData.algorithms.find(
          (item) => item.algorithmId === featureDataGroupQuery['algorithm-id']
        )

        dispatch(
          featureDataGroupDetailActions.setCurrentFeatureDataGroupDetail({
            featureDataGroupId: featureDataGroupQuery
              ? featureDataGroupQuery['feature-data-group-id']
              : '',
            featureDataGroupName: featureDataGroupQuery
              ? featureDataGroupQuery['feature-data-group-name']
              : '',
            relatedFeatureDataList: featureDataGroupQuery
              ? getFeatureDataList(featureDataGroupQuery['feature-data-list'])
              : [],
            remarks: featureDataGroupQuery
              ? featureDataGroupQuery.remarks ?? ''
              : '',
            trainingAlgorithm: {
              algorithmId: featureDataGroupQuery
                ? featureDataGroupQuery['algorithm-id'] ?? ''
                : '',
              algorithmName: algorithmDoc
                ? algorithmDoc.metadata?.name.ja ?? ''
                : '',
            },
            createdAt: featureDataGroupQuery
              ? featureDataGroupQuery['created-at']
              : undefined,
            createdBy: accountSetting
              ? {
                  firstName: accountSetting['first-name'] ?? '',
                  familyName: accountSetting['family-name'] ?? '',
                }
              : featureDataGroupQuery
              ? featureDataGroupQuery['created-by'] ?? ''
              : '',
            overview: featureDataGroupQuery
              ? featureDataGroupQuery['overview'] ?? ''
              : '',
            iconName: featureDataGroupQuery
              ? featureDataGroupQuery['icon-name'] ?? ''
              : '',
            iconFileType: featureDataGroupQuery
              ? featureDataGroupQuery['icon-filetype'] ?? ''
              : '',
            iconUrl: iconUrl ? iconUrl.data.url : '',
            featureDataListDisplayCondition: {
              sortKey: 'featureDataVersion',
              sortOrder: 'desc',
              displayNumber: 10,
              pageNumber: 0,
            },
          })
        )
        dispatch(
          featureDataGroupDetailActions.setFeatureDataGroupDataState('Loaded')
        )
      } catch (error) {
        console.error(error)
        dispatch(
          featureDataGroupDetailActions.setFeatureDataGroupDataState('Failed')
        )
      } finally {
        dispatch(featureDataGroupDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データグループの名前を更新 */
  updateFeatureDataGroupDetailName:
    (docId: string, name: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        await updateDoc(
          doc(getFeatureDataGroupMetadataCollection(userGroupId), docId),
          {
            name: name,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )

        dispatch(
          featureDataGroupDetailActions.setCurrentFeatureDataGroupDetail({
            ...getState().pages.featureDataGroupDetailState.domainData
              .currentFeatureDataGroupDetail,
            featureDataGroupName: name,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGroupDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データグループの備考を更新 */
  updateFeatureDataGroupDetailRemarks:
    (docId: string, remarks: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Feature Data Group IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getFeatureDataGroupMetadataCollection(userGroupId), docId),
          {
            remarks: remarks,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGroupDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データグループの概要を更新 */
  updateFeatureDataGroupOverview:
    (docId: string, overview: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Feature Data Group IDと一致したdocの概要を更新する
        await updateDoc(
          doc(getFeatureDataGroupMetadataCollection(userGroupId), docId),
          {
            overview: overview,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGroupDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データグループのアイコンを更新 */
  updateFeatureDataGroupIcon:
    (docId: string, uploadFeatureDataGroupIcon: File) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // アップロードされている画像のsignedUrl
        const thumbnailUrl =
          getState().pages.featureDataGroupDetailState.domainData
            .currentFeatureDataGroupDetail.iconUrl
        // アップロードされている画像の名前
        const iconName =
          getState().pages.featureDataGroupDetailState.domainData
            .currentFeatureDataGroupDetail.iconName

        // アップロードされている元の画像を削除
        if (thumbnailUrl !== '') {
          const deleteUrl = (await FeatureDataGroupDetailApi.getDeleteSignedUrl(
            docId,
            iconName
          )) as HttpsCallableResult<{ url: string }>
          await FeatureDataGroupDetailApi.deleteUploadedIcon(deleteUrl.data.url)
          // ドキュメントを更新
          await updateDoc(
            doc(getFeatureDataGroupMetadataCollection(userGroupId), docId),
            {
              'icon-name': '',
              'icon-filetype': '',
              ['updated-by']:
                getState().app.domainData.authedUser.auth.customClaims
                  .accountId,
              ['updated-at']: new Date(),
            }
          )
        }
        // アップロードのsignedUrl取得
        const response =
          (await FeatureDataGroupDetailApi.getFileUploadSignedUrl(
            docId,
            uploadFeatureDataGroupIcon.name
          )) as HttpsCallableResult<{ url: string }>
        try {
          // アップロード処理
          // 成功時にドキュメントを更新する
          // 失敗時にアップロードしたiconを削除する
          await FeatureDataGroupDetailApi.executeFileUpload(
            response.data.url,
            uploadFeatureDataGroupIcon
          )
          const toastInfo: ToastInfo = {
            type: 'info',
            title: 'アイコンをアップロードしました。',
            targets: [],
          }
          dispatch(featureDataGroupDetailActions.setToastInfo(toastInfo))
          try {
            // ドキュメントを更新
            await updateDoc(
              doc(getFeatureDataGroupMetadataCollection(userGroupId), docId),
              {
                'icon-name': uploadFeatureDataGroupIcon.name,
                'icon-filetype': uploadFeatureDataGroupIcon.type,
                ['updated-by']:
                  getState().app.domainData.authedUser.auth.customClaims
                    .accountId,
                ['updated-at']: new Date(),
              }
            )
            let thumbnailUrl = undefined

            try {
              thumbnailUrl = (await FeatureDataGroupDetailApi.getSignedUrl(
                docId,
                uploadFeatureDataGroupIcon.name
              )) as HttpsCallableResult<{ url: string }>
              if (!isUndefined(thumbnailUrl)) {
                dispatch(
                  featureDataGroupDetailActions.setIconUrl(
                    thumbnailUrl.data.url
                  )
                )
              }
            } catch (error) {
              // アップロードされた画像の取得失敗時処理
              console.error(error)
              const toastInfo: ToastInfo = {
                type: 'error',
                title: '画像の取得に失敗しました。',
                targets: [],
              }
              dispatch(featureDataGroupDetailActions.setToastInfo(toastInfo))
            }
          } catch (error) {
            // ドキュメント更新失敗時処理
            console.error(error)
            const toastInfo: ToastInfo = {
              type: 'error',
              title: 'ドキュメントの更新に失敗しました。',
              targets: [],
            }
            dispatch(featureDataGroupDetailActions.setToastInfo(toastInfo))
            // アップロードしたiconを削除する
            const deleteUrl =
              (await FeatureDataGroupDetailApi.getDeleteSignedUrl(
                docId,
                uploadFeatureDataGroupIcon.name
              )) as HttpsCallableResult<{ url: string }>
            await FeatureDataGroupDetailApi.deleteUploadedIcon(
              deleteUrl.data.url
            )
          }
        } catch (error) {
          // アップロード失敗処理
          console.error(error)
          const toastInfo: ToastInfo = {
            type: 'error',
            title: 'アップロードに失敗しました。',
            targets: [],
          }
          dispatch(featureDataGroupDetailActions.setToastInfo(toastInfo))
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGroupDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データグループのアイコンを削除 */
  deleteFeatureDataGroupIcon:
    (docId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // アップロードされている画像のsignedUrl
        const iconName =
          getState().pages.featureDataGroupDetailState.domainData
            .currentFeatureDataGroupDetail.iconName

        try {
          const deleteUrl = (await FeatureDataGroupDetailApi.getDeleteSignedUrl(
            docId,
            iconName
          )) as HttpsCallableResult<{ url: string }>
          await FeatureDataGroupDetailApi.deleteUploadedIcon(deleteUrl.data.url)
          // ドキュメントを更新
          await updateDoc(
            doc(getFeatureDataGroupMetadataCollection(userGroupId), docId),
            {
              'icon-name': '',
              'icon-filetype': '',
              ['updated-by']:
                getState().app.domainData.authedUser.auth.customClaims
                  .accountId,
              ['updated-at']: new Date(),
            }
          )
          dispatch(featureDataGroupDetailActions.setIconUrl(''))
          const toastInfo: ToastInfo = {
            type: 'info',
            title: 'アイコンを削除しました。',
            targets: [],
          }
          dispatch(featureDataGroupDetailActions.setToastInfo(toastInfo))
        } catch (error) {
          // ドキュメント作成失敗時処理
          console.error(error)
          const toastInfo: ToastInfo = {
            type: 'error',
            title: 'アイコンの削除に失敗しました。',
            targets: [],
          }
          dispatch(featureDataGroupDetailActions.setToastInfo(toastInfo))
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGroupDetailActions.setInProgress(false))
      }
    },
}
