import { ScenePresetListActions } from './actions'
import { ScenePresetListState, ScenePresetListActionType } from './types'

const initialState: ScenePresetListState = {
  domainData: {
    augParamTemplateIdList: [],
    scenePresetCreationDialogData: {
      createdScenePresetId: '',
      backgrounds: [],
      selectedBackgrounds: [],
      cameras: [],
      selectedCameras: [],
      lightGroups: [],
      selectedLightGroups: [],
      name: '',
      overview: '',
    },
    currentScenePresetList: [],
    scenePresetListDisplayCondition: {
      searchValue: '',
      sortKey: 'updated-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'UserGroup',
    },
  },
  appState: {
    scenePresetState: 'SceneSettingState',
    scenePresetSubState: {
      sceneSettingSubState: 'EmptyRequired',
      metadataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
  },
}

export const ScenePresetListReducer = (
  state: ScenePresetListState = initialState,
  action: ScenePresetListActions
): ScenePresetListState => {
  switch (action.type) {
    case ScenePresetListActionType.SET_AUG_PARAM_TEMPLATE_IDS_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          augParamTemplateIdList: action.payload.augParamTemplateIds,
        },
      }
    case ScenePresetListActionType.SET_SCENE_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentScenePresetList: action.payload.scenePresetList,
        },
      }
    case ScenePresetListActionType.SET_CURRENT_SCENE_PRESET_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentScenePresetListSnapshot:
            action.payload.currentScenePresetListSnapshot,
        },
      }
    case ScenePresetListActionType.CLEAR_SCENE_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentScenePresetList: [],
        },
      }
    case ScenePresetListActionType.SET_SCENE_PRESET_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetListDisplayCondition:
            action.payload.scenePresetListDisplayCondition,
        },
      }
    case ScenePresetListActionType.SET_IN_PROGRESS_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ScenePresetListActionType.CLEAR_SCENE_PRESET_LIST_STATE:
      return {
        ...initialState,
      }
    case ScenePresetListActionType.CLEAR_CREATE_SCENE_PRESET_DIALOG_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...initialState.domainData.scenePresetCreationDialogData,
          },
        },
      }
    case ScenePresetListActionType.SET_BACKGROUNDS_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            backgrounds: action.payload.backgrounds,
          },
        },
      }
    case ScenePresetListActionType.SET_CAMERAS_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            cameras: action.payload.cameras,
          },
        },
      }
    case ScenePresetListActionType.SET_LIGHT_GROUPS_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            lightGroups: action.payload.lightGroups,
          },
        },
      }
    case ScenePresetListActionType.SET_SCENE_PRESET_BACKGROUNDS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            selectedBackgrounds: action.payload.selectedBackgrounds,
          },
        },
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            sceneSettingSubState:
              action.payload.selectedBackgrounds.length > 0 &&
              state.domainData.scenePresetCreationDialogData.selectedCameras
                .length > 0 &&
              state.domainData.scenePresetCreationDialogData.selectedLightGroups
                .length > 0
                ? 'InputRequired'
                : 'EmptyRequired',
          },
        },
      }
    case ScenePresetListActionType.SET_SCENE_PRESET_CAMERAS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            selectedCameras: action.payload.selectedCameras,
          },
        },
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            sceneSettingSubState:
              state.domainData.scenePresetCreationDialogData.selectedBackgrounds
                .length > 0 &&
              action.payload.selectedCameras.length > 0 &&
              state.domainData.scenePresetCreationDialogData.selectedLightGroups
                .length > 0
                ? 'InputRequired'
                : 'EmptyRequired',
          },
        },
      }
    case ScenePresetListActionType.SET_SCENE_PRESET_LIGHT_GROUPS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            selectedLightGroups: action.payload.selectedLightGroups,
          },
        },
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            sceneSettingSubState:
              state.domainData.scenePresetCreationDialogData.selectedBackgrounds
                .length > 0 &&
              state.domainData.scenePresetCreationDialogData.selectedCameras
                .length > 0 &&
              action.payload.selectedLightGroups.length > 0
                ? 'InputRequired'
                : 'EmptyRequired',
          },
        },
      }
    case ScenePresetListActionType.SET_SCENE_PRESET_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            name: action.payload.scenePresetName,
          },
        },
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            metadataSubState: action.payload.scenePresetName
              ? 'InputRequired'
              : 'EmptyRequired',
          },
        },
      }
    case ScenePresetListActionType.SET_SCENE_PRESET_OVERVIEW:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            overview: action.payload.scenePresetOverview,
          },
        },
      }
    case ScenePresetListActionType.SET_CREATED_SCENE_PRESET_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            createdScenePresetId: action.payload.createdScenePresetId,
          },
        },
      }

    case ScenePresetListActionType.CLEAR_SCENE_PRESET_STATE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          scenePresetCreationDialogData: {
            ...state.domainData.scenePresetCreationDialogData,
            createdScenePresetId: '',
            selectedBackgrounds: [],
            selectedLightGroups: [],
            selectedCameras: [],
            name: '',
            overview: '',
          },
        },
        appState: {
          ...initialState.appState,
        },
      }
    case ScenePresetListActionType.CLEAR_SCENE_SETTING_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            sceneSettingSubState: 'EmptyRequired',
          },
        },
      }
    case ScenePresetListActionType.CLEAR_METADATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            metadataSubState: 'EmptyRequired',
          },
        },
      }
    case ScenePresetListActionType.CLEAR_EXECUTE_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            executeSubState: 'BeforeExecute',
          },
        },
      }

    case ScenePresetListActionType.SET_SCENE_PRESET_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          scenePresetState: action.payload.scenePresetState,
        },
      }
    case ScenePresetListActionType.SET_SCENE_SETTING_SUB_STATE_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            sceneSettingSubState: action.payload.sceneSettingSubState,
          },
        },
      }
    case ScenePresetListActionType.SET_METADATA_SUB_STATE_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            metadataSubState: action.payload.metadataSubState,
          },
        },
      }
    case ScenePresetListActionType.SET_EXECUTE_SUB_STATE_FOR_SCENE_PRESET_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          scenePresetSubState: {
            ...state.appState.scenePresetSubState,
            executeSubState: action.payload.executeSubState,
          },
        },
      }
    default:
      return state
  }
}
