import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'

import {
  RobotDataAnalysisEntryActionType,
  ModelGroup,
  ToastInfo,
  QueryConditions,
  Count,
  UserInput,
} from './types'

export type RobotDataAnalysisEntryAction = ActionsUnion<
  typeof robotDataAnalysisEntryActions
>
export const robotDataAnalysisEntryActions = {
  setModelGroupList: (
    modelGroupList: ModelGroup[]
  ): ActionWithPayload<
    'SET_MODEL_GROUP_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
    { modelGroupList: ModelGroup[] }
  > =>
    CreateAction(
      RobotDataAnalysisEntryActionType.SET_MODEL_GROUP_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY,
      {
        modelGroupList,
      }
    ),
  setQueryConditions: (
    queryConditions: QueryConditions
  ): ActionWithPayload<
    'SET_QUERY_CONDITIONS_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
    { queryConditions: QueryConditions }
  > =>
    CreateAction(
      RobotDataAnalysisEntryActionType.SET_QUERY_CONDITIONS_FOR_ROBOT_DATA_ANALYSIS_ENTRY,
      {
        queryConditions,
      }
    ),
  setCount: (
    count: Count
  ): ActionWithPayload<
    'SET_COUNT_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
    { count: Count }
  > =>
    CreateAction(
      RobotDataAnalysisEntryActionType.SET_COUNT_FOR_ROBOT_DATA_ANALYSIS_ENTRY,
      {
        count,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      RobotDataAnalysisEntryActionType.SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_ENTRY,
      {
        toastInfo,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
    { inProgress: boolean }
  > =>
    CreateAction(
      RobotDataAnalysisEntryActionType.SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_ENTRY,
      {
        inProgress,
      }
    ),
  setUserInputList: (
    userInputList: UserInput[]
  ): ActionWithPayload<
    'SET_USER_INPUT_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
    { userInputList: UserInput[] }
  > =>
    CreateAction(
      RobotDataAnalysisEntryActionType.SET_USER_INPUT_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY,
      {
        userInputList,
      }
    ),
  clearRobotDataAnalysisEntryState:
    (): Action<'CLEAR_ROBOT_DATA_ANALYSIS_ENTRY_STATE'> =>
      CreateAction(
        RobotDataAnalysisEntryActionType.CLEAR_ROBOT_DATA_ANALYSIS_ENTRY_STATE
      ),
}
