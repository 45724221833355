import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { getAxiosInstance } from 'state/utils'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import { ModelUploadParamsType } from '.'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'

const timeoutLimitSeconds = 15

export const ModelUploadApi = {
  uploadModelFile: (
    signed_url: string,
    file: File,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> =>
    getAxiosInstance().upload(signed_url, file, config),
  getSignedUrl: (
    trainedModelId: string,
    trainedModelFiles: {
      modelFormat: string
      fileType: string
      fileName: string
    }[]
  ): Promise<HttpsCallableResult<{ [x: string]: { url: string } }>> =>
    httpsCallable<
      {
        trainedModelId: string
        trainedModelFiles: {
          modelFormat: string
          fileType: string
          fileName: string
        }[]
      },
      { [x: string]: { url: string } }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-model-get-signed-url'
    )({
      trainedModelId,
      trainedModelFiles,
    }),
  executeModelUpload: (
    trainedModel: ModelUploadParamsType
  ): Promise<HttpsCallableResult<{ trainedModelId: string }>> =>
    httpsCallable<
      { trainedModel: ModelUploadParamsType },
      { trainedModelId: string }
    >(functionsInstance, AidpCloudFunctionsUrl() + 'create-model-firestore', {
      timeout: timeoutLimitSeconds * 1000,
    })({
      trainedModel,
    }),
}
