import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'

import { State } from 'state/store'
import {
  ModelGroupInfo,
  ModelGroupListActions,
  modelGroupListActions,
  modelGroupListOperations,
  ModelGroupListDisplayCondition,
} from 'state/ducks/modelGroupList'

import {
  SelectableTable,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  CustomTrainingPageParagraph,
  SearchInput,
  GlobalLoading,
  ModelGroupsIcon,
  BreadcrumbsComponent,
  AlgorithmSelect,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { getUserGroupKindList } from 'views/containers/utils'
import { useTheme } from '@mui/material/styles'

const mapStateToProps = (state: State) => ({
  ...state.pages.modelGroupListState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ModelGroupListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** モデルグループ一覧取得 */
  getModelGroupList: () => {
    dispatch(modelGroupListOperations.getModelGroupList())
  },
  /** モデルグループ一覧をクリア */
  clearModelGroupList: () =>
    dispatch(modelGroupListActions.clearModelGroupList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: ModelGroupListDisplayCondition) =>
    dispatch(modelGroupListActions.setListDisplayCondition(listCondition)),
  /** Stateのクリア */
  clearModelGroupListState: () =>
    dispatch(modelGroupListActions.clearModelGroupListState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  explanation: {
    fontSize: theme.typography.pxToRem(18),
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'model-group-id',
    title: 'モデルグループ ID',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'model-group-name',
    title: 'モデルグループ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'algorithm-name',
    title: 'アルゴリズム名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-model-count',
    title: '登録モデル数',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'updated-at',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-user-id',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const ModelGroupList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  useEffect(() => {
    props.getModelGroupList()
    return () => {
      props.clearModelGroupListState()
    }
  }, [props.authedUser.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
    selectTrainedModel,
    selectedUserGroupKind: selectUserGroupKind,
  } = tableActions(props, history)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.modelGroupListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.modelGroupListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.modelGroupListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentModelGroupList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentModelGroupList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.modelGroupListDisplayCondition,
    props.domainData.currentModelGroupList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: ModelGroupInfo[] = tableContent.map(
      (modelGroupList: ModelGroupInfo) => {
        return {
          modelGroupId: modelGroupList.modelGroupId,
          modelGroupName: modelGroupList.modelGroupName,
          algorithmName: modelGroupList.algorithmName,
          modelCount: modelGroupList.modelCount,
          updatedAt: modelGroupList.updatedAt,
          createAt: modelGroupList.createAt,
          createdUserId: modelGroupList.createdUserId,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'modelGroupId') {
          return (
            <TooltipLink
              key={key}
              data-testid={`model-groups-${value}`}
              title={value}
              placement='right-start'
              onClick={(
                e: React.MouseEvent<HTMLElement, MouseEvent> | undefined
              ) => {
                if (e) {
                  e.stopPropagation()
                }
                history.push(
                  `model-groups/${value}${
                    props.domainData.modelGroupListDisplayCondition
                      .selectedUserGroupKind === 'UserGroup'
                      ? ''
                      : '?shared-user-group=true'
                  }`
                )
              }}
            />
          )
        } else if (key === 'updatedAt' || key === 'createAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'createdUserId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const modelGroupListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.modelGroupListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.modelGroupListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.modelGroupListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.modelGroupListDisplayCondition.sortKey,
          order: props.domainData.modelGroupListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        isPointer={true}
        onClickRow={(index: number) => selectTrainedModel(index)}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.modelGroupListDisplayCondition,
    props.appState.inProgress,
  ])

  const userGroupKindList = getUserGroupKindList(
    props.authedUser.auth.customClaims.sharedList
  )

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'モデルグループ一覧',
              path: 'model-groups',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <ModelGroupsIcon
              className={classes.pageIcon}
              data-testid='modelListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='model-group-list-title'>モデルグループ</h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link data-testid='model-groups-entry' to={`${url}/entry`}>
              <Typography>モデルグループ作成</Typography>
            </Link>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <FormControl
            variant='outlined'
            className={classes.algorithmSelectBox}
          >
            <InputLabel id='modelListUserGroupKind'>データ種別</InputLabel>
            <Select
              labelId='modelListUserGroupKind-label'
              id='modelListUserGroupKind-outlined'
              value={
                props.domainData.modelGroupListDisplayCondition
                  .selectedUserGroupKind
              }
              onChange={(
                e: SelectChangeEvent<'UserGroup' | 'SharedUserGroup'>
              ) =>
                selectUserGroupKind(
                  e.target.value as 'UserGroup' | 'SharedUserGroup'
                )
              }
              label='Select User Group Kind'
            >
              {userGroupKindList.map((kind) => (
                <MenuItem
                  data-testid={kind.kind}
                  value={kind.kind}
                  key={kind.kind}
                >
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <AlgorithmSelect
            data-testid='model-group-list-algorithm'
            type='TRAINED_MODEL'
            algorithms={props.algorithms}
            value={props.domainData.modelGroupListDisplayCondition.algorithmId}
            onChange={(value?: string) => selectedAlgorithm(value)}
            hasAll={true}
          />
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (モデルグループID)'
                value={
                  props.domainData.modelGroupListDisplayCondition.searchValue
                }
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {modelGroupListTable}
        </CustomTrainingPageParagraph>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tableActions = (props: Props, history: any) => {
  /** テーブルの行選択 */
  const selectTrainedModel = (index: number) => {
    const itemNumber =
      props.domainData.modelGroupListDisplayCondition.displayNumber *
        props.domainData.modelGroupListDisplayCondition.pageNumber +
      index
    history.push(
      `model-groups/${
        props.domainData.currentModelGroupList[itemNumber].modelGroupId
      }/models${
        props.domainData.modelGroupListDisplayCondition
          .selectedUserGroupKind === 'UserGroup'
          ? ''
          : '?shared-user-group=true'
      }`
    )
  }
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.modelGroupListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentModelGroupList.length >
      props.domainData.modelGroupListDisplayCondition.pageNumber * displayNumber
        ? props.domainData.modelGroupListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentModelGroupList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.modelGroupListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getModelGroupList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearModelGroupList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.modelGroupListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.modelGroupListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getModelGroupList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.modelGroupListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getModelGroupList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearModelGroupList()
    props.setListDisplayCondition({
      ...props.domainData.modelGroupListDisplayCondition,
      pageNumber: 0,
    })

    props.getModelGroupList()
  }

  /** アルゴリズムの選択 */
  const selectedAlgorithm = (algorithm?: string) => {
    props.clearModelGroupList()
    props.setListDisplayCondition({
      ...props.domainData.modelGroupListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'generated-at',
      sortOrder: 'desc',
      algorithmId: algorithm,
    })
    props.getModelGroupList()
  }

  /** ユーザーグループ種別の選択 */
  const selectedUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearModelGroupList()
    props.setListDisplayCondition({
      ...props.domainData.modelGroupListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'generated-at',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getModelGroupList()
  }
  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
    selectTrainedModel,
    selectedUserGroupKind,
  }
}

export const ModelGroupListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelGroupList))
