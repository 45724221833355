import { collection } from 'firebase/firestore'

import { firestoreInstance } from './client'
import { CollectionReference } from './types'

const collectionPathPrefix = 'aidp/v0'
const collectionPathPrefixV1 = 'aidp/v1'

const getCollectionReference = (
  collections: {
    collectionName: string
    docId?: string
  }[]
) =>
  collection(
    firestoreInstance,
    `${collectionPathPrefixV1}` +
      collections
        .map((collection) => {
          return collection.docId
            ? `/${collection.collectionName}/${collection.docId}`
            : `/${collection.collectionName}`
        })
        .join('')
  )

const getUserGroupsCollectionReference = (
  userGroupId: string,
  collectionName: string
) =>
  collection(
    firestoreInstance,
    `${collectionPathPrefixV1}/user-groups/${userGroupId}/${collectionName}`
  )

const getAccountGroupsCollectionReference = (
  accountGroupId: string,
  collectionName: string
) =>
  collection(
    firestoreInstance,
    `${collectionPathPrefix}/account-groups/${accountGroupId}/${collectionName}`
  )

export const getUserGroupsCollection = (): CollectionReference =>
  collection(firestoreInstance, `${collectionPathPrefixV1}/user-groups`)

export const getTrainingDataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'training-data')

export const getTrainingDataMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'training-data-metadata')

export const getTrainingImagesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'training-images')

export const getTrainingImagesMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'training-images-metadata')

export const getThumbnailsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'thumbnails')

export const getGroupedDataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'grouped-data')

export const getGroupedDataMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'grouped-data-metadata')

export const getTrainedModelsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'trained-models')

export const getTrainedModelsMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'trained-model-metadata')

export const getMlPipelinesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'ml-pipelines')

export const getMlPipelinesMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'ml-pipeline-metadata')

export const getMlPipelineQueriesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'ml-pipeline-queries')

export const getTrainingImageQueriesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'training-image-queries')

export const getAlgorithmsCollection = (): CollectionReference =>
  getCollectionReference([{ collectionName: 'algorithms' }])

export const getAlgorithmTypesCollection = (
  algoId: string
): CollectionReference =>
  getCollectionReference([
    { collectionName: 'algorithms', docId: algoId },
    { collectionName: 'algorithm-types' },
  ])

export const getAlgorithmVersionCollection = (
  algoId: string,
  algorithmType: 'inference' | 'training'
): CollectionReference =>
  getCollectionReference([
    { collectionName: 'algorithms', docId: algoId },
    { collectionName: 'algorithm-types', docId: algorithmType },
    { collectionName: 'algorithm-versions' },
  ])

export const getClassSetCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'class-sets')

export const getClassSetMetaDataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'class-set-metadata')

export const getAnnotationSetsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'annotation-sets')

export const getSettingsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'settings')

export const getDatasetsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'datasets')

export const getDatasetsMetaDataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'dataset-metadata')

export const getDatasetQueryCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'dataset-queries')

export const getDatasetTemplatesCollection = (): CollectionReference =>
  getCollectionReference([{ collectionName: 'dataset-templates' }])

export const getAnnotationFormatsCollection = (): CollectionReference =>
  getCollectionReference([{ collectionName: 'annotation-formats' }])

export const getAnnotationFormatsVersionCollection = (
  annotationFormatId: string
): CollectionReference =>
  getCollectionReference([
    { collectionName: 'annotation-formats', docId: annotationFormatId },
    { collectionName: 'annotation-format-versions' },
  ])

export const getSettingsMetaDataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'setting-metadata')

export const getAccountsCollection = (
  accountGroupId: string
): CollectionReference =>
  getAccountGroupsCollectionReference(accountGroupId, 'accounts')

export const getAccountSettingCollection = (
  accountGroupId: string
): CollectionReference =>
  getAccountGroupsCollectionReference(accountGroupId, 'account-settings')

export const getAccountCustomerRelationsCollection = (
  accountGroupId: string
): CollectionReference =>
  getAccountGroupsCollectionReference(
    accountGroupId,
    'account-customer-relations'
  )

export const getTrainedModelGroupsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'trained-model-groups')

export const getTrainedModelGroupMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'trained-model-group-metadata')

export const getTrainedModelGroupQueriesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'trained-model-group-queries')

export const getFeatureDataGroupQueriesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'feature-data-group-queries')

export const getFeatureDataGroupMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'feature-data-group-metadata')

export const getFeatureDataQueriesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'feature-data-queries')

export const getFeatureDataMetadataCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'feature-data-metadata')

export const getTrainedModelQueriesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'trained-model-queries')

export const getAccountGroupCollection = (): CollectionReference =>
  collection(firestoreInstance, `${collectionPathPrefix}/account-groups`)

export const getAccountGroupMetadataCollection = (): CollectionReference =>
  collection(
    firestoreInstance,
    `${collectionPathPrefix}/account-group-metadata`
  )
export const getAccountGroupCustomerRelationsCollection =
  (): CollectionReference =>
    collection(
      firestoreInstance,
      `${collectionPathPrefix}/account-group-customer-relations`
    )

export const getCustomerCollection = (): CollectionReference =>
  collection(firestoreInstance, `${collectionPathPrefix}/customers`)

export const getCustomerMetadataCollection = (): CollectionReference =>
  collection(firestoreInstance, `${collectionPathPrefix}/customers-metadata`)

export const getBaseInferenceContainerImageCollection =
  (): CollectionReference =>
    getCollectionReference([
      { collectionName: 'base-inference-container-images' },
    ])

export const getAlgorithmStructureRelationsCollection =
  (): CollectionReference =>
    getCollectionReference([
      { collectionName: 'algorithm-structure-relations' },
    ])

export const getAlgorithmStructuresCollection = (): CollectionReference =>
  getCollectionReference([{ collectionName: 'algorithm-structures' }])

export const getAlgorithmStructuresVersionCollection = (
  algorithmStructureId: string
): CollectionReference =>
  getCollectionReference([
    { collectionName: 'algorithm-structures', docId: algorithmStructureId },
    { collectionName: 'algorithm-structure-versions' },
  ])

export const getFeatureDataFormatsCollection = (): CollectionReference =>
  getCollectionReference([{ collectionName: 'feature-data-formats' }])

export const getAug3DObjectsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-3d-objects')

export const getAug3DSceneBackgroundsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-3d-scene-backgrounds')
export const getAug3DSceneBackgroundRevisionsCollection = (
  userGroupId: string,
  aug3DSceneBackgroundId: string
): CollectionReference =>
  getCollectionReference([
    {
      collectionName: 'user-groups',
      docId: userGroupId,
    },
    {
      collectionName: 'aug-3d-scene-backgrounds',
      docId: aug3DSceneBackgroundId,
    },
    { collectionName: 'aug-3d-scene-background-revisions' },
  ])

export const getAug3DSceneCamerasCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-3d-scene-cameras')
export const getAug3DSceneCameraRevisionsCollection = (
  userGroupId: string,
  aug3DSceneCameraId: string
): CollectionReference =>
  getCollectionReference([
    {
      collectionName: 'user-groups',
      docId: userGroupId,
    },
    { collectionName: 'aug-3d-scene-cameras', docId: aug3DSceneCameraId },
    { collectionName: 'aug-3d-scene-camera-revisions' },
  ])

export const getAug3DSceneLightsObjectsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-3d-scene-lights')
export const getAug3DSceneLightRevisionsCollection = (
  userGroupId: string,
  aug3DSceneLightId: string
): CollectionReference =>
  getCollectionReference([
    {
      collectionName: 'user-groups',
      docId: userGroupId,
    },
    { collectionName: 'aug-3d-scene-lights', docId: aug3DSceneLightId },
    { collectionName: 'aug-3d-scene-light-revisions' },
  ])

export const getAug3dSceneLightGroupsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-3d-scene-light-groups')

export const getAugParamTemplatesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-param-templates')

export const getAugSIObjectLayoutsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-si-object-layouts')

export const getAugSIObjectRotationGroupsObjectsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-si-object-rotation-groups')

export const getAugTexturesCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'aug-textures')
export const getAugTextureRevisionsCollection = (
  userGroupId: string,
  augTextureId: string
): CollectionReference =>
  getCollectionReference([
    {
      collectionName: 'user-groups',
      docId: userGroupId,
    },
    { collectionName: 'aug-textures', docId: augTextureId },
    { collectionName: 'aug-texture-revisions' },
  ])

export const getAugmentationStepsCollection = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'augmentation-steps')

export const getRobotExecutionDataQueryCollectionRef = (
  userGroupId: string
): CollectionReference =>
  getUserGroupsCollectionReference(userGroupId, 'robot-execution-data-queries')

export const getSettingFormatsCollection = (): CollectionReference =>
  getCollectionReference([{ collectionName: 'setting-formats' }])

export const getSettingFormatVersionsCollection = (
  settingFormatId: string
): CollectionReference =>
  getCollectionReference([
    {
      collectionName: 'setting-formats',
      docId: settingFormatId,
    },
    {
      collectionName: 'setting-format-versions',
    },
  ])
