import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { red, green } from '@mui/material/colors'

import { MessageProps } from './types'

const useStyles = makeStyles()(() => ({
  failedColor: {
    color: red[500],
  },
  succeededColor: {
    color: green[500],
  },
}))

export const Message: React.FC<MessageProps> = (props: MessageProps) => {
  const { classes } = useStyles()

  return (
    <>
      <Grid item>
        {(() => {
          if (props.isInProgress) {
            return (
              <CircularProgress
                data-testid={props['data-testid'] + 'IsInProgress'}
              />
            )
          } else if (props.isSucceeded) {
            return (
              <CheckCircle
                className={classes.succeededColor}
                data-testid={props['data-testid'] + 'IsSucceeded'}
              />
            )
          } else if (props.isFailed) {
            return (
              <Typography>
                <Box
                  className={classes.failedColor}
                  data-testid={props['data-testid'] + 'IsFailed'}
                >
                  <b>
                    {((message: string | undefined) => {
                      if (message) {
                        return message
                      } else return 'FAILED!!'
                    })(props.message)}
                  </b>
                </Box>
              </Typography>
            )
          }
        })()}
      </Grid>
    </>
  )
}
