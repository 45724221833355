import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import { PasswordUpdateActionType, ToastInfo } from './types'

export type PasswordUpdateAction = ActionsUnion<typeof passwordUpdateActions>

export const passwordUpdateActions = {
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_PASSWORD_UPDATE',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(PasswordUpdateActionType.SET_TOAST_INFO_FOR_PASSWORD_UPDATE, {
      toastInfo,
    }),
  setNeedMfa: (
    needMfa: boolean
  ): ActionWithPayload<'SET_NEED_MFA', { needMfa: boolean }> =>
    CreateAction(PasswordUpdateActionType.SET_NEED_MFA, {
      needMfa,
    }),
  setIsPasswordUpdated: (
    isPasswordUpdated: boolean
  ): ActionWithPayload<
    'SET_IS_PASSWORD_UPDATED',
    { isPasswordUpdated: boolean }
  > =>
    CreateAction(PasswordUpdateActionType.SET_IS_PASSWORD_UPDATED, {
      isPasswordUpdated,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_PASSWORD_UPDATE',
    { inProgress: boolean }
  > =>
    CreateAction(PasswordUpdateActionType.SET_IN_PROGRESS_FOR_PASSWORD_UPDATE, {
      inProgress,
    }),
  clearState: (): Action<'CLEAR_PASSWORD_UPDATE_STATE'> =>
    CreateAction(PasswordUpdateActionType.CLEAR_PASSWORD_UPDATE_STATE),
}
