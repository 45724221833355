import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Divider,
} from '@mui/material'
import { OnsiteInferenceResultDialogProps } from './types'
import { ColorBox, CheckButton } from 'views/components/atoms'
import { FocusedInferenceResultView } from 'views/components/molecules'

export const OnsiteInferenceResultDialog: React.FC<
  OnsiteInferenceResultDialogProps
> = (props: OnsiteInferenceResultDialogProps) => {
  return (
    <Dialog onClose={props.handleClose} open={props.open} fullWidth={true}>
      <Box m={2}>
        <DialogTitle
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{
            padding: (theme) => `0 0 ${theme.spacing(1)} 0`,
          }}
        >
          <Box p={1} display='flex' alignItems='center'>
            <ColorBox
              color={props.canvasInfo.color}
              sx={{ marginRight: (theme) => theme.spacing(1) }}
            />
            <Typography>{props.canvasInfo.id.substring(0, 8)}</Typography>
            <Typography
              sx={{ marginLeft: (theme) => theme.spacing(1) }}
              variant='body2'
              color='textSecondary'
            >
              {`${props.canvasInfo.score * 100}%`}
            </Typography>
          </Box>
          <CheckButton
            checked={props.checked}
            uncheckedLabel='MARK'
            checkedLabel='MARKED'
            onClick={props.handleClickCheck}
          />
        </DialogTitle>
        <Divider sx={{ marginBottom: (theme) => theme.spacing(2) }} />
        <DialogContent
          sx={{
            padding: 0,
            marginBottom: (theme) => theme.spacing(3),
            root: {
              padding: (theme) => theme.spacing(2),
            },
          }}
        >
          <Box mb={1} display='flex' alignItems='center'>
            <Typography
              variant='body2'
              color='textSecondary'
              sx={{ marginRight: (theme) => theme.spacing(1) }}
            >
              Detected by:
            </Typography>
            <Typography
              color='inherit'
              variant='body2'
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => props.handleClickLink(props.trainedModelId)}
              data-testid={`dialog-${props.trainedModelId}-${props.canvasInfo.id}`}
            >{`${props.trainedModelName} - ${props.trainedModelVersion}`}</Typography>
          </Box>
          <Box
            sx={{
              border: 'solid 1px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <FocusedInferenceResultView
              url={props.url}
              canvasInfo={props.canvasInfo}
              width='480px'
              height='270px'
              padding={32}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Button
            variant='contained'
            onClick={props.handleClose}
            color={'inherit'}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
