import { SceneCameraDetailAction } from './actions'
import { SceneCameraDetailActionType, SceneCameraDetailState } from './types'
import { Timestamp } from 'firebase/firestore'

const initialState: SceneCameraDetailState = {
  domainData: {
    currentSceneCameraDetail: {
      id: '',
      name: '',
      revision: 0,
      overview: '',
      createdAt: Timestamp.fromMillis(0),
      createdBy: '',
      location: [],
      fov: [],
      lookAt: [],
      formatVersion: {
        displayName: '',
        major: 0,
        minor: 0,
        patch: 0,
      },
    },
  },
  appState: {
    inProgress: false,
    sceneCameraDetailSubState: {
      sceneCameraDataSubState: 'BeforeLoading',
    },
  },
}

export const SceneCameraDetailReducer = (
  state: SceneCameraDetailState = initialState,
  action: SceneCameraDetailAction
): SceneCameraDetailState => {
  switch (action.type) {
    case SceneCameraDetailActionType.SET_CURRENT_SCENE_CAMERA_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSceneCameraDetail: action.payload.currentSceneCameraDetail,
        },
      }
    case SceneCameraDetailActionType.SET_IN_PROGRESS_FOR_SCENE_CAMERA_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case SceneCameraDetailActionType.SET_SCENE_CAMERA_DETAIL_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          sceneCameraDetailSubState: action.payload.sceneCameraDetailSubState,
        },
      }
    case SceneCameraDetailActionType.CLEAR_SCENE_CAMERA_DETAIL_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
