import { RecaptchaVerifier } from 'firebase/auth'
import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils'

import {
  LoginActionType,
  LoginStateType,
  AuthStateType,
  LoginError,
} from './types'

export type LoginAction = ActionsUnion<typeof loginActions>

export const loginActions = {
  setRecaptchaVerifier: (
    recaptchaVerifier: RecaptchaVerifier | undefined
  ): ActionWithPayload<
    'SET_RECAPCHA_VERIFIER',
    {
      recaptchaVerifier: RecaptchaVerifier | undefined
    }
  > =>
    CreateAction(LoginActionType.SET_RECAPCHA_VERIFIER, {
      recaptchaVerifier,
    }),
  setLoginState: (
    data: LoginStateType
  ): ActionWithPayload<'SET_LOGIN_STATE', { data: LoginStateType }> =>
    CreateAction(LoginActionType.SET_LOGIN_STATE, { data }),
  setAuthState: (
    data: AuthStateType
  ): ActionWithPayload<'SET_AUTH_STATE', { data: AuthStateType }> =>
    CreateAction(LoginActionType.SET_AUTH_STATE, { data }),
  setVerificationId: (
    verificationId: string
  ): ActionWithPayload<'SET_VERIFICATION_ID', { verificationId: string }> =>
    CreateAction(LoginActionType.SET_VERIFICATION_ID, { verificationId }),
  setLoginError: (
    loginError: LoginError
  ): ActionWithPayload<'SET_LOGIN_ERROR', { loginError: LoginError }> =>
    CreateAction(LoginActionType.SET_LOGIN_ERROR, { loginError }),
  setErrorMessage: (
    data: string
  ): ActionWithPayload<'SET_ERROR_MESSAGE', { data: string }> =>
    CreateAction(LoginActionType.SET_ERROR_MESSAGE, { data }),
  setFailTooManyRequestsState: (): Action<'SET_FAIL_TOO_MANY_REQUESTS_STATE'> =>
    CreateAction(LoginActionType.SET_FAIL_TOO_MANY_REQUESTS_STATE),
  clearLoginState: (): Action<'CLEAR_LOGIN_STATE'> =>
    CreateAction(LoginActionType.CLEAR_LOGIN_STATE),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<'SET_IN_PROGRESS_FOR_LOGIN', { inProgress: boolean }> =>
    CreateAction(LoginActionType.SET_IN_PROGRESS_FOR_LOGIN, {
      inProgress,
    }),
}
