import { MfaSettingAction } from './actions'
import { MfaSettingActionType, MfaSettingState } from './types'

const initialState: MfaSettingState = {
  appState: {
    toastInfo: undefined,
    mfaSettingStateKind: 'phoneNumberState',
    verificationId: '',
    recaptchaVerifier: undefined,
    mfaSettingState: 'NotSet',
    inProgress: false,
  },
}

export const MfaSettingReducer = (
  state: MfaSettingState = initialState,
  action: MfaSettingAction
): MfaSettingState => {
  switch (action.type) {
    case MfaSettingActionType.SET_RECAPCHA_VERIFIER:
      return {
        ...state,
        appState: {
          ...state.appState,
          recaptchaVerifier: action.payload.recaptchaVerifier,
        },
      }
    case MfaSettingActionType.SET_MFA_SETTING_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          mfaSettingStateKind: action.payload.mfaSettingStateKind,
        },
      }
    case MfaSettingActionType.MFA_SETTING_COMPLETED:
      return {
        ...state,
        appState: {
          ...state.appState,
          mfaSettingState: 'Completed',
        },
      }
    case MfaSettingActionType.SET_VERIFICATION_ID:
      return {
        ...state,
        appState: {
          ...state.appState,
          verificationId: action.payload.verificationId,
        },
      }
    case MfaSettingActionType.SET_TOAST_INFO_FOR_MFA_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case MfaSettingActionType.SET_IN_PROGRESS_FOR_MFA_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    default:
      return state
  }
}
