import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Chip from '@mui/material/Chip'
import { ColorChipProps } from './types'

const useStyles = (size: 'small' | 'medium' | undefined) =>
  makeStyles()((theme) => {
    const maxTagWidth =
      size === 'medium' ? theme.spacing(16) : theme.spacing(12)
    return {
      colorChip: {
        maxWidth: maxTagWidth,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    }
  })

export const ColorChip: React.FC<ColorChipProps> = (props: ColorChipProps) => {
  const { classes } = useStyles(props.size)()

  return (
    <Chip
      label={props.label}
      color={props.color}
      variant={props.variant ? props.variant : undefined}
      size={props.size ? props.size : 'small'}
      className={!props.isStretch ? classes.colorChip : undefined}
      sx={{
        background: `${props.customColor}66`,
        border: `solid 2px ${props.customColor}`,
        ...props.sx,
      }}
      onDelete={props.onDelete}
    />
  )
}
