import axios from 'axios'
import { Dispatch } from 'redux'
import {
  getDatasetQueryCollection,
  getSettingsMetaDataCollection,
  getMlPipelineQueriesCollection,
  getTrainedModelsMetadataCollection,
  getTrainedModelQueriesCollection,
  getTrainedModelGroupQueriesCollection,
  getAccountSettingCollection,
  getClassSetMetaDataCollection,
} from 'state/firebase'
import { modelDetailActions } from './'
import {
  MediaLink,
  TrainedModelDlLink,
  GetModelsFilesResponse,
  RelatedTrainedModel,
  CurrentDataset,
  CurrentTrainedModelDetail,
  DatasetListItemData,
  InheritedDatasetListItem,
  Extended,
} from './types'
import { ModelDetailApi } from './apis'
import {
  isObject,
  isString,
  isNumber,
  isArray,
  isUndefined,
} from 'utils/typeguard'
import { compareVersions } from 'utils/versions'
import { State } from 'state/store'
import { saveAs } from 'file-saver'
import {
  DocumentData,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'

import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument } from 'utils/fireStore/customTrainingMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaDataDocument } from 'utils/fireStore/classSetMetaData'
import { domainDataOperations } from 'state/app/domainData/operations'

type TrainedModelVersion = {
  ['display-name']: string
  ['major']: number
  ['minor']: number
  ['patch']: number
}

type TrainedModel = {
  ['trained-model-group-version']: TrainedModelVersion
  ['trained-model-id']: string
  ['trained-model-name']: string
  ['inherited-version']: {
    ['trained-model-group-id']: string
    ['trained-model-group-version']: {
      ['display-name']: string
      ['major']: number
      ['minor']: number
      ['patch']: number
    }
    ['trained-model-id']: string
    ['user-group-id']: string
  }
  ['transaction-status']: string
}

const getTrainedModelList = async (
  userGroupId: string,
  isSharedUserGroup: boolean,
  list: TrainedModel[]
): Promise<RelatedTrainedModel[]> => {
  const modelList = await Promise.all(
    list.map(async (item) => {
      const relatedTrainedModelData = (
        await getDocs(
          isSharedUserGroup
            ? query(
                getTrainedModelQueriesCollection(userGroupId),
                where('trained-model-id', '==', item['trained-model-id']),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            : query(
                getTrainedModelQueriesCollection(userGroupId),
                where('trained-model-id', '==', item['trained-model-id'])
              )
        )
      ).docs[0]?.data()

      return {
        trainedModelId: item ? item['trained-model-id'] : '',
        trainedModelVersion: {
          displayName: item
            ? item['trained-model-group-version']['display-name']
            : '',
          major: item ? item['trained-model-group-version']['major'] : 0,
          minor: item ? item['trained-model-group-version']['minor'] : 0,
          patch: item ? item['trained-model-group-version']['patch'] : 0,
        },
        trainedModelName: item ? item['trained-model-name'] : '',
        inheritedVersion:
          relatedTrainedModelData &&
          relatedTrainedModelData['inherited-version'] &&
          relatedTrainedModelData['inherited-version'][
            'trained-model-group-version'
          ]['display-name'] !== '0.0.0'
            ? {
                displayName:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['display-name'],
                major:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['major'],
                minor:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['minor'],
                patch:
                  relatedTrainedModelData['inherited-version'][
                    'trained-model-group-version'
                  ]['patch'],
              }
            : {
                displayName: '',
                major: 0,
                minor: 0,
                patch: 0,
              },
        transactionStatus: item
          ? (item['transaction-status'] as
              | 'trained'
              | 'built'
              | 'transfered'
              | undefined)
          : undefined,
      }
    })
  )
  if (modelList.length === 1) return modelList
  // バージョンでソートして返す
  return await modelList.sort((a, b) =>
    compareVersions(a.trainedModelVersion, b.trainedModelVersion, 'desc')
  )
}
/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    modelDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

/** MediaLink[]かどうか */
function isMediaLinks(object: unknown): object is MediaLink[] {
  return (
    isArray(object) &&
    object.every((element) => {
      return (
        isString(element.mediaName) &&
        isString(element.mediaUrl) &&
        isNumber(element.mediaSize)
      )
    })
  )
}

/** GetModelsFilesResponseかどうか */
function isGetModelsFilesResponse(
  object: unknown
): object is GetModelsFilesResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isString(element.linkName) && isMediaLinks(element.mediaLinks)
    })
  )
}

const getExtendedField = async (
  dispatch: Dispatch,
  getState: () => State,
  trainedModelQuery: DocumentData,
  userGroupId: string,
  isSharedUserGroup: boolean
): Promise<Extended | undefined> => {
  // 物体クラス分類の場合
  if (trainedModelQuery['extended']['object-classification']) {
    const classSetId =
      (trainedModelQuery['extended']['object-classification']['class-set'][
        'class-set-id'
      ] as string) ?? ''
    const classSetUserGroupId =
      (trainedModelQuery['extended']['object-classification']['class-set'][
        'user-group-id'
      ] as string) ?? ''
    const classSetMetaData = (
      await getDocs(
        userGroupId === classSetUserGroupId && !isSharedUserGroup
          ? query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId)
            )
          : query(
              getClassSetMetaDataCollection(classSetUserGroupId),
              where('class-set-id', '==', classSetId),
              where('access-control.is-shared', '==', true),
              where('access-control.share-permissions.webapp', '==', 'list')
            )
      )
    ).docs[0].data()
    if (!fireStoreTypeGuardForClassSetMetaDataDocument(classSetMetaData)) {
      return
    }

    const classSetName = classSetMetaData['name']

    return {
      objectClassification: {
        classSet: {
          classSetId,
          classSetName,
          userGroupId: classSetUserGroupId,
        },
      },
    }
  }

  return undefined
}

export const ModelDetailOperations = {
  /** モデル詳細で表示パラメータを取得する */
  getModelDetail:
    (modelId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelDetailActions.setInProgress(true))
        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )
        const userGroupId = isSharedUserGroup
          ? sharedUserGroupId
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        /** trained-model-query document */
        const trainedModelQuery = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getTrainedModelQueriesCollection(userGroupId),
                  where('trained-model-id', '==', modelId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((trainedModel) => trainedModel.data())[0]
          : (
              await getDoc(
                doc(getTrainedModelQueriesCollection(userGroupId), modelId)
              )
            ).data()
        if (!trainedModelQuery) {
          dispatch(
            modelDetailActions.setModelDetailState({
              ...getState().pages.modelDetailState.appState.modelDetailState,
              trainedModelDataState: 'NotFoundProcessed',
            })
          )
          return
        }

        if (!fireStoreTypeGuardForModelQueryDocument(trainedModelQuery)) {
          dispatch(
            modelDetailActions.setModelDetailState({
              ...getState().pages.modelDetailState.appState.modelDetailState,
              trainedModelDataState: 'Failed',
            })
          )
          return
        }

        /** trained-model-group-query document */
        const trainedModelGroupQuery = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  where(
                    'trained-model-group-id',
                    '==',
                    trainedModelQuery['trained-model-group-id']
                  ),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((modelGroup) => modelGroup.data())[0]
          : (
              await getDoc(
                doc(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  trainedModelQuery['trained-model-group-id']
                )
              )
            ).data()

        if (
          !fireStoreTypeGuardForModelGroupQueryDocument(trainedModelGroupQuery)
        ) {
          dispatch(
            modelDetailActions.setModelDetailState({
              ...getState().pages.modelDetailState.appState.modelDetailState,
              trainedModelDataState: 'Failed',
            })
          )
          return
        }

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                trainedModelQuery ? trainedModelQuery['created-by'] : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(
            modelDetailActions.setModelDetailState({
              ...getState().pages.modelDetailState.appState.modelDetailState,
              trainedModelDataState: 'Failed',
            })
          )
          return
        }

        const datasetList: CurrentDataset[] = []
        let settingMetadata = undefined
        let mlPipeLineData = undefined
        let baseModelData = undefined
        let isSharedUserGroupBaseModel = true
        const modelsFilesItems: TrainedModelDlLink[] = []
        const inheritedDatasetList: InheritedDatasetListItem[] = []

        // カスタマーの学習モデルかつ、汎用モデル以外の場合のみ
        // データセット、セッティング、ML Pipeline、ベースモデル、モデルファイルの取得を実行
        if (
          !isSharedUserGroup &&
          trainedModelQuery['model-kind'] !== 'Generic'
        ) {
          const datasetDict: { [key: string]: CurrentDataset } = {}
          const datasetDataList: DatasetListItemData[] = []
          if (
            trainedModelQuery['dataset-list'] &&
            trainedModelQuery['dataset-list'].length > 0
          ) {
            const currentDataset = trainedModelQuery['dataset-list'].find(
              (dataset: DatasetListItemData) => dataset['generation'] === 0
            )
            datasetDataList.push(currentDataset)
          } else if (trainedModelQuery['dataset-id']) {
            datasetDataList.push({
              'dataset-id': trainedModelQuery['dataset-id'],
              generation: 0,
              'user-group-id': userGroupId,
            })
          }

          const datasetIdList = datasetDataList.map((dataset) => {
            const datasetId = dataset['dataset-id']
            datasetDict[datasetId] = {
              datasetId: datasetId,
              datasetName: '',
            }
            return datasetId
          })
          const datasetDataPromises = datasetDataList.map(async (dataset) => {
            const datasetData = (
              await getDoc(
                doc(
                  getDatasetQueryCollection(dataset['user-group-id']),
                  dataset['dataset-id']
                )
              )
            ).data()

            if (datasetData) {
              if (!fireStoreTypeGuardForDatasetQueryDocument(datasetData)) {
                dispatch(
                  modelDetailActions.setModelDetailState({
                    ...getState().pages.modelDetailState.appState
                      .modelDetailState,
                    trainedModelDataState: 'Failed',
                  })
                )
                return
              } else {
                datasetDict[dataset['dataset-id']].datasetName =
                  datasetData['dataset-name']
              }
            }
          })
          await Promise.all(datasetDataPromises)
          datasetIdList.forEach((datasetId: string) =>
            datasetList.push(datasetDict[datasetId])
          )
          /** セッティングのメタデータ */
          settingMetadata =
            trainedModelQuery && trainedModelQuery['setting-id']
              ? (
                  await getDoc(
                    doc(
                      getSettingsMetaDataCollection(userGroupId),
                      trainedModelQuery['setting-id']
                    )
                  )
                ).data()
              : undefined

          /** mlPipelineQuery */
          mlPipeLineData =
            trainedModelQuery && trainedModelQuery['ml-pipeline-id']
              ? (
                  await getDoc(
                    doc(
                      getMlPipelineQueriesCollection(userGroupId),
                      trainedModelQuery['ml-pipeline-id']
                    )
                  )
                ).data()
              : undefined

          if (
            mlPipeLineData &&
            !fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument(
              mlPipeLineData
            )
          ) {
            dispatch(
              modelDetailActions.setModelDetailState({
                ...getState().pages.modelDetailState.appState.modelDetailState,
                trainedModelDataState: 'Failed',
              })
            )
            return
          }

          /** ベースモデルのメタデータ */
          if (
            trainedModelQuery &&
            (trainedModelQuery['base-model-id'] ||
              trainedModelQuery['base-model']) &&
            sharedUserGroupId
          ) {
            const baseModelUserGroupId = trainedModelQuery['base-model']
              ? trainedModelQuery['base-model']['user-group-id']
              : userGroupId

            isSharedUserGroupBaseModel =
              baseModelUserGroupId ===
              getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]

            const baseModelId = trainedModelQuery['base-model']
              ? trainedModelQuery['base-model']['trained-model-id']
              : trainedModelQuery['base-model-id']

            // ベースモデルの情報を取得
            baseModelData = (
              await getDocs(
                userGroupId === baseModelUserGroupId
                  ? query(
                      getTrainedModelQueriesCollection(baseModelUserGroupId),
                      where('trained-model-id', '==', baseModelId)
                    )
                  : query(
                      getTrainedModelQueriesCollection(baseModelUserGroupId),
                      where('trained-model-id', '==', baseModelId),
                      where('access-control.is-shared', '==', true),
                      where(
                        'access-control.share-permissions.webapp',
                        '==',
                        'list'
                      )
                    )
              )
            ).docs[0]?.data()

            if (baseModelData) {
              if (!fireStoreTypeGuardForModelQueryDocument(baseModelData)) {
                dispatch(
                  modelDetailActions.setModelDetailState({
                    ...getState().pages.modelDetailState.appState
                      .modelDetailState,
                    trainedModelDataState: 'Failed',
                  })
                )
                return
              }
            }
            if (!fireStoreTypeGuardForModelQueryDocument(trainedModelQuery)) {
              dispatch(
                modelDetailActions.setModelDetailState({
                  ...getState().pages.modelDetailState.appState
                    .modelDetailState,
                  trainedModelDataState: 'Failed',
                })
              )
            }
          }

          // モデルファイル用キャッチ
          try {
            const getModelsFilesResponse = await ModelDetailApi.getModelsFiles(
              modelId
            )

            if (isGetModelsFilesResponse(getModelsFilesResponse)) {
              getModelsFilesResponse.data.items.map((file) => {
                modelsFilesItems.push({
                  linkName: file.linkName,
                  mediaLinks: file.mediaLinks,
                  totalMediaSize: file.mediaLinks.reduce(
                    (prev, current) => prev + current.mediaSize,
                    0
                  ),
                })
              })

              dispatch(
                modelDetailActions.setModelDetailState({
                  ...getState().pages.modelDetailState.appState
                    .modelDetailState,
                  trainedModelDlLinkSubState: 'Loaded',
                })
              )
            }
          } catch {
            dispatch(
              modelDetailActions.setModelDetailState({
                ...getState().pages.modelDetailState.appState.modelDetailState,
                trainedModelDlLinkSubState: 'Failed',
              })
            )
          }
        }

        /** アルゴリズムデータの取得 */
        const trainingAlgorithmVersionDisplayName =
          trainedModelQuery['training-algorithm-version']['display-name']
        const foundAlgorithm = getState().app.domainData.algorithms.find(
          (algorithm) =>
            algorithm.algorithmId === trainedModelQuery['algorithm-id']
        )
        const foundTrainingAlgorithmVersion =
          foundAlgorithm?.trainingAlgorithm.trainingAlgorithmVersions.find(
            (trainingAlgorithmVersion) =>
              trainingAlgorithmVersion.algorithmVersion.displayName ===
              trainingAlgorithmVersionDisplayName
          )

        /** 継承モデルバージョンの取得 */
        const inheritedVersion = trainedModelQuery['inherited-version']
        if (
          inheritedVersion?.['trained-model-group-version'] &&
          inheritedVersion?.['trained-model-group-version']['display-name'] !==
            '0.0.0'
        ) {
          const inheritedVersionTrainedModelData = (
            await getDocs(
              inheritedVersion['user-group-id'] === sharedUserGroupId
                ? query(
                    getTrainedModelQueriesCollection(sharedUserGroupId),
                    where(
                      'trained-model-id',
                      '==',
                      inheritedVersion['trained-model-id']
                    ),
                    where('access-control.is-shared', '==', true),
                    where(
                      'access-control.share-permissions.webapp',
                      '==',
                      'list'
                    )
                  )
                : query(
                    getTrainedModelQueriesCollection(userGroupId),
                    where(
                      'trained-model-id',
                      '==',
                      inheritedVersion['trained-model-id']
                    )
                  )
            )
          ).docs[0]?.data()

          if (inheritedVersionTrainedModelData) {
            if (
              !fireStoreTypeGuardForModelQueryDocument(
                inheritedVersionTrainedModelData
              )
            ) {
              dispatch(
                modelDetailActions.setModelDetailState({
                  ...getState().pages.modelDetailState.appState
                    .modelDetailState,
                  trainedModelDataState: 'Failed',
                })
              )
              return
            }

            const datasetList = inheritedVersionTrainedModelData['dataset-list']
              ? inheritedVersionTrainedModelData['dataset-list']
              : inheritedVersionTrainedModelData['dataset-id']
              ? [
                  {
                    'dataset-id':
                      inheritedVersionTrainedModelData['dataset-id'],
                    generation: 0,
                    'user-group-id':
                      inheritedVersionTrainedModelData['user-group-id'],
                  },
                ]
              : []

            await Promise.all(
              datasetList.map(async (dataset: DatasetListItemData) => {
                const datasetData = (
                  await getDocs(
                    dataset['user-group-id'] === sharedUserGroupId
                      ? query(
                          getDatasetQueryCollection(dataset['user-group-id']),
                          where('dataset-id', '==', dataset['dataset-id']),
                          where('access-control.is-shared', '==', true),
                          where(
                            'access-control.share-permissions.webapp',
                            '==',
                            'list'
                          )
                        )
                      : query(
                          getDatasetQueryCollection(dataset['user-group-id']),
                          where('dataset-id', '==', dataset['dataset-id'])
                        )
                  )
                ).docs[0]?.data()

                if (datasetData) {
                  if (!fireStoreTypeGuardForDatasetQueryDocument(datasetData)) {
                    return
                  } else {
                    inheritedDatasetList.push({
                      createdAt: datasetData['created-at'],
                      datasetId: datasetData['dataset-id'],
                      name: datasetData['dataset-name'],
                      generation: dataset['generation'],
                      remarks: datasetData['dataset-remarks'] ?? '',
                    })
                  }
                }
              })
            )
          }
        }

        let extended: Extended | undefined = undefined
        if (
          !isUndefined(trainedModelQuery) &&
          !isUndefined(trainedModelQuery?.['extended'])
        ) {
          extended = await getExtendedField(
            dispatch,
            getState,
            trainedModelQuery,
            userGroupId,
            isSharedUserGroup
          )
        }

        /** モデルの詳細情報を生成 */
        const modelDetailProps: CurrentTrainedModelDetail = {
          trainedModelGroupId: trainedModelQuery
            ? trainedModelQuery['trained-model-group-id']
            : '',
          trainedModelGroupName: trainedModelGroupQuery
            ? trainedModelGroupQuery['trained-model-group-name']
            : '',
          trainedModelVersion: trainedModelQuery
            ? trainedModelQuery['trained-model-group-version']['display-name']
            : '',
          relatedTrainedModelList: trainedModelGroupQuery
            ? await getTrainedModelList(
                userGroupId,
                isSharedUserGroup,
                trainedModelGroupQuery['trained-model-list']
              )
            : [],
          trainedModelId: trainedModelQuery
            ? trainedModelQuery['trained-model-id']
            : '',
          trainedModelName: trainedModelQuery
            ? trainedModelQuery['trained-model-name']
            : '',
          trainedModelRemarks: trainedModelQuery
            ? trainedModelQuery['trained-model-remarks']
            : '',
          trainedModelKind: trainedModelQuery
            ? trainedModelQuery['model-kind']
            : '',
          evaluationStatus: trainedModelQuery
            ? trainedModelQuery['evaluation-status']
            : '',
          baseModel: {
            baseModelId: baseModelData ? baseModelData['trained-model-id'] : '',
            baseModelGroupId: baseModelData
              ? baseModelData['trained-model-group-id']
              : '',
            baseModelName: baseModelData
              ? baseModelData['trained-model-name']
              : '',
            isSharedUserGroupBaseModel: isSharedUserGroupBaseModel,
          },
          inheritedVersion:
            inheritedVersion &&
            inheritedVersion['trained-model-group-version']['display-name'] !==
              '0.0.0'
              ? {
                  trainedModelGroupId:
                    inheritedVersion['trained-model-group-id'],
                  trainedModelGroupVersion: {
                    displayName:
                      inheritedVersion['trained-model-group-version'][
                        'display-name'
                      ],
                    major:
                      inheritedVersion['trained-model-group-version']['major'],
                    minor:
                      inheritedVersion['trained-model-group-version']['minor'],
                    patch:
                      inheritedVersion['trained-model-group-version']['patch'],
                  },
                  trainedModelId: inheritedVersion['trained-model-id'],
                  userGroupId: inheritedVersion['user-group-id'],
                }
              : undefined,
          inheritedDatasetList: inheritedDatasetList,
          datasetList: datasetList,
          setting: {
            settingId: trainedModelQuery ? trainedModelQuery['setting-id'] : '',
            settingName: settingMetadata ? settingMetadata['name'] : '',
          },
          trainedModelDlLinks: modelsFilesItems,
          trainingAlgorithm: {
            algorithmId: foundAlgorithm ? foundAlgorithm.algorithmId : '',
            metadata:
              foundAlgorithm && foundAlgorithm.metadata
                ? { name: foundAlgorithm.metadata.name }
                : {
                    name: {
                      ja: '',
                      en: '',
                    },
                  },
            trainingAlgorithmVersion: foundTrainingAlgorithmVersion
              ? foundTrainingAlgorithmVersion.algorithmVersion.displayName
              : '',
          },
          mlPipeline: {
            mlPipelineId:
              trainedModelQuery && trainedModelQuery['ml-pipeline-id']
                ? trainedModelQuery['ml-pipeline-id']
                : '',
            mlPipelineName: mlPipeLineData
              ? mlPipeLineData['ml-pipeline-metadata'].name
              : '',
          },
          createdAt: trainedModelQuery
            ? trainedModelQuery['created-at']
            : undefined,
          createdUserName: accountSetting
            ? {
                firstName: accountSetting['first-name'] ?? '',
                familyName: accountSetting['family-name'] ?? '',
              }
            : trainedModelQuery['created-by'] ?? '',
          modelListDisplayCondition: {
            sortKey: 'modelVersion',
            sortOrder: 'desc',
            displayNumber: 10,
            pageNumber: 0,
          },
          extended,
        }

        dispatch(modelDetailActions.setCurrentModelDetail(modelDetailProps))
        dispatch(
          modelDetailActions.setModelDetailState({
            ...getState().pages.modelDetailState.appState.modelDetailState,
            trainedModelDataState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          modelDetailActions.setModelDetailState({
            ...getState().pages.modelDetailState.appState.modelDetailState,
            trainedModelDataState: 'Failed',
          })
        )
      } finally {
        dispatch(modelDetailActions.setInProgress(false))
      }
    },
  /** 学習モデルの名前を更新 */
  updateModelDetailName:
    (docId: string, name: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Model IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getTrainedModelsMetadataCollection(userGroupId), docId),
          {
            name: name,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )

        dispatch(
          modelDetailActions.setCurrentModelDetail({
            ...getState().pages.modelDetailState.domainData
              .currentTrainedModelDetail,
            trainedModelName: name,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelDetailActions.setInProgress(false))
      }
    },
  /** カスタム学習の備考を更新 */
  updateModelDetailRemarks:
    (docId: string, remarks: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // Model IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getTrainedModelsMetadataCollection(userGroupId), docId),
          {
            remarks: remarks,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelDetailActions.setInProgress(false))
      }
    },
  /** モデルファイルのダウンロード */
  downloadModelFile:
    (links: MediaLink[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(modelDetailActions.setInProgress(true))
        // ファイルDL失敗リスト
        const failedModelFileList: string[] = []
        await Promise.all(
          links.map(async (link) => {
            try {
              await axios
                .get(link.mediaUrl, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, link.mediaName)
                })
            } catch {
              failedModelFileList.push(link.mediaName)
            }
          })
        )
        // 失敗した画像があれば、Storeに保持
        if (failedModelFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedModelFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelDetailActions.setInProgress(false))
      }
    },
}
