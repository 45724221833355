import { FeatureDataUploadAction } from './actions'
import { FeatureDataUploadActionType, FeatureDataUploadState } from './types'

const initialState: FeatureDataUploadState = {
  domainData: {
    algorithmDisplayCondition: {
      sortKey: 'releasedAt',
      sortOrder: 'desc',
    },
    selectedAlgorithmStructureVersion: undefined,
    selectedTrainingAlgorithm: {
      algorithmId: '',
    },
    selectedTrainingAlgorithmVersion: undefined,
    featureDataGroupList: [],
    featureDataGroupListDisplayCondition: {
      searchValue: '',
      sortKey: 'updated-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    selectedFeatureDataGroup: undefined,
    featureDataMetaData: {
      name: '',
      remarks: '',
    },
    executedFeatureDataId: undefined,
    inputFiles: [],
    fileUploadResult: 'beforeUpload',
  },
  appState: {
    featureDataUploadStateKind: 'AlgorithmState',
    featureDataUploadSubStateKind: {
      algorithmSubState: 'Unselected',
      featureDataGroupSubState: 'Unselected',
      featureDataUploadSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
    toastInfo: undefined,
  },
}

export const FeatureDataUploadReducer = (
  state: FeatureDataUploadState = initialState,
  action: FeatureDataUploadAction
): FeatureDataUploadState => {
  switch (action.type) {
    case FeatureDataUploadActionType.ADD_IMAGE_SET_FILE_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inputFiles: action.payload.files,
        },
        appState: {
          ...state.appState,
          featureDataUploadSubStateKind: {
            ...state.appState.featureDataUploadSubStateKind,
            featureDataUploadSubState: action.payload.files
              ? 'Selected'
              : 'Unselected',
          },
        },
      }
    case FeatureDataUploadActionType.SET_ALGORITHM_DISPLAY_CONDITION:
      if (action.payload.algorithmDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            algorithmDisplayCondition: action.payload.algorithmDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataUploadActionType.SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD:
      if (action.payload.featureDataGroupListDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            featureDataGroupListDisplayCondition:
              action.payload.featureDataGroupListDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataUploadActionType.SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupList: action.payload.featureDataGroupList,
        },
      }
    case FeatureDataUploadActionType.SET_SELECTED_FEATURE_DATA_GROUP:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedFeatureDataGroup: action.payload.selectedFeatureDataGroup,
        },
      }
    case FeatureDataUploadActionType.SET_FEATURE_DATA_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataUploadSubStateKind: {
            ...state.appState.featureDataUploadSubStateKind,
            featureDataGroupSubState: action.payload.featureDataGroupSubState,
          },
        },
      }
    case FeatureDataUploadActionType.CLEAR_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupListDisplayCondition: {
            ...initialState.domainData.featureDataGroupListDisplayCondition,
          },
        },
      }
    case FeatureDataUploadActionType.SET_SELECTED_ALGORITHM_ID_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithm: {
            algorithmId: action.payload.algorithmId,
          },
        },
      }
    case FeatureDataUploadActionType.SET_SELECTED_FEATURE_DATA_VERSION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedVersion: action.payload.selectedVersion,
        },
      }
    case FeatureDataUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithmVersion:
            action.payload.selectedTrainingAlgorithmVersion,
        },
      }
    case FeatureDataUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithmStructureVersion:
            action.payload.selectedTrainingAlgorithmStructureVersion,
        },
      }
    case FeatureDataUploadActionType.SET_FEATURE_DATA_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataMetaData: action.payload.featureDataMetaData,
        },
      }
    case FeatureDataUploadActionType.SET_ALGORITHM_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataUploadSubStateKind: {
            ...state.appState.featureDataUploadSubStateKind,
            algorithmSubState: action.payload.trainingAlgorithmSubState,
          },
        },
      }
    case FeatureDataUploadActionType.SET_FEATURE_DATA_UPLOAD_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataUploadSubStateKind: {
            ...state.appState.featureDataUploadSubStateKind,
            featureDataUploadSubState: action.payload.featureDataUploadSubState,
          },
        },
      }
    case FeatureDataUploadActionType.SET_FEATURE_DATA_META_DATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataUploadSubStateKind: {
            ...state.appState.featureDataUploadSubStateKind,
            metaDataSubState: action.payload.metaDataSubState,
          },
        },
      }
    case FeatureDataUploadActionType.SET_SEND_FEATURE_DATA_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataUploadStateKind: action.payload.featureDataUploadStateKind,
        },
      }
    case FeatureDataUploadActionType.CLEAR_FEATURE_DATA_UPLOAD_STATE:
      return {
        ...initialState,
      }
    case FeatureDataUploadActionType.EXEC_FEATURE_DATA_SEND_SUCCESS:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataUploadSubStateKind: {
            ...state.appState.featureDataUploadSubStateKind,
            executeSubState: 'Executed',
          },
        },
      }
    case FeatureDataUploadActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataUploadActionType.EXEC_FEATURE_DATA_SEND_FAILURE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataUploadSubStateKind: {
            ...state.appState.featureDataUploadSubStateKind,
            executeSubState: 'ExecuteError',
          },
        },
      }
    case FeatureDataUploadActionType.SET_EXECUTED_FEATURE_DATA_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executedFeatureDataId: action.payload.executedFeatureDataId,
        },
      }
    case FeatureDataUploadActionType.SET_FILE_UPLOAD_RESULT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          fileUploadResult: action.payload.status,
        },
      }
    case FeatureDataUploadActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_UPLOAD:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    default:
      return state
  }
}
