import { PasswordResetAction } from './actions'
import { PasswordResetActionType, PasswordResetState } from './types'

const initialState: PasswordResetState = {
  appState: {
    passwordResetMailSendSubState: 'BeforeSending',
    passwordResetMailSendErrorMessage: '',
  },
}

export const PasswordResetReducer = (
  state: PasswordResetState = initialState,
  action: PasswordResetAction
): PasswordResetState => {
  switch (action.type) {
    case PasswordResetActionType.SET_PASSWORD_RESET_MAIL_SEND_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          passwordResetMailSendSubState: action.payload.data,
        },
      }
    case PasswordResetActionType.SET_PASSWORD_RESET_MAIL_SEND_ERROR_MESSAGE:
      return {
        ...state,
        appState: {
          ...state.appState,
          passwordResetMailSendErrorMessage: action.payload.data,
        },
      }
    case PasswordResetActionType.CLEAR_PASSWORD_RESET_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
