import { Dispatch } from 'redux'
import { State } from 'state/store'
import { featureDataTransferringListActions } from './actions'
import {
  getMlPipelineQueriesCollection,
  getFeatureDataQueriesCollection,
} from 'state/firebase'
import {
  FeatureDataTransferring,
  FeatureDataTransferringDisplayCondition,
} from './types'
import {
  convertProgressRateByTransactionStatusForFeatureDataTransferring,
  convertQueryStartEndCodeBySearchValue,
} from 'state/utils'
import { isUndefined } from 'utils/typeguard'
import { Algorithm } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataTransferringMLPipelineQueryDocument } from 'utils/fireStore/featureDataTransferringMLPipelineQuery'
import {
  FeatureDataQueryDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataQueryDocument,
} from 'utils/fireStore/featureDataQuery'

const createFeatureDataTransferringData = async (
  snapShot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[]
): Promise<(FeatureDataTransferring | undefined)[]> =>
  // 関連のDocsを取得し表示用の特徴量データ生成一覧を生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const mlPipelineQueryDocData = document.data()

      if (
        !fireStoreTypeGuardForFeatureDataTransferringMLPipelineQueryDocument(
          mlPipelineQueryDocData
        )
      ) {
        return undefined
      }

      // 生成特徴量データ名取得
      let featureDataData: FeatureDataQueryDocument | undefined = undefined

      if (
        mlPipelineQueryDocData['transfer-step']?.['src']?.[
          'feature-data-list'
        ] &&
        mlPipelineQueryDocData['transfer-step']['src']['feature-data-list']
          .length > 0 &&
        mlPipelineQueryDocData['transfer-step']['src']['feature-data-list'][0][
          'feature-data-id'
        ] &&
        mlPipelineQueryDocData['transfer-step']['src']['feature-data-list'][0][
          'user-group-id'
        ]
      ) {
        const featureDataId =
          mlPipelineQueryDocData['transfer-step']['src'][
            'feature-data-list'
          ][0]['feature-data-id']
        const userGroupId =
          mlPipelineQueryDocData['transfer-step']['src'][
            'feature-data-list'
          ][0]['user-group-id']

        featureDataData = (
          await getDoc(
            doc(getFeatureDataQueriesCollection(userGroupId), featureDataId)
          )
        ).data() as FeatureDataQueryDocument

        if (!fireStoreTypeGuardForFeatureDataQueryDocument(featureDataData)) {
          return undefined
        }
      }

      // 特徴量データ生成名取得
      const algorithm = !isUndefined(featureDataData)
        ? algorithms.find((algorithm) => {
            return algorithm.algorithmId === featureDataData?.['algorithm-id']
          })
        : undefined

      return {
        mlPipelineId: mlPipelineQueryDocData['ml-pipeline']['ml-pipeline-id'],
        mlPipelineName: mlPipelineQueryDocData['ml-pipeline-metadata']['name'],
        algorithmName: algorithm ? algorithm.metadata.name.ja : '',
        progress: {
          transactionStatus:
            mlPipelineQueryDocData['ml-pipeline']['transaction-status'],
          progressRate:
            convertProgressRateByTransactionStatusForFeatureDataTransferring(
              mlPipelineQueryDocData['ml-pipeline']['transaction-status']
            ),
        },
        startedAt: mlPipelineQueryDocData['ml-pipeline']['started-at'],
        endedAt:
          mlPipelineQueryDocData['ml-pipeline']['ended-at'] &&
          mlPipelineQueryDocData['ml-pipeline']['ended-at'].seconds === 0
            ? undefined
            : mlPipelineQueryDocData['ml-pipeline']['ended-at'],
        featureData: {
          featureDataId: !isUndefined(featureDataData)
            ? featureDataData['feature-data-id']
            : '',
          featureDataName: !isUndefined(featureDataData)
            ? featureDataData['feature-data-name']
            : '',
        },
        accountId: mlPipelineQueryDocData['created-by'],
      } as FeatureDataTransferring
    })
  )

export const onUpdateMlPipelineQueryDocument = async (
  dispatch: Dispatch,
  ugid: string,
  snapshot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[],
  mlPipelineIds: string[],
  condition: FeatureDataTransferringDisplayCondition,
  mlPipeLineDoc: DocumentData | undefined
) => {
  const featureDataTransferringData = await createFeatureDataTransferringData(
    snapshot,
    algorithms
  )

  // ML Pipeline IDを保持
  // すでに保持しているIDが存在する場合は、現状の検索位置以降のIDを一度破棄し
  // 新たに取得したML Pipeline IDを保持する
  if (featureDataTransferringData.length >= 0) {
    if (mlPipeLineDoc) {
      const index = mlPipelineIds.findIndex((id) => id === mlPipeLineDoc?.id)
      const beforePageIds = mlPipelineIds.slice(0, index + 1)
      dispatch(
        featureDataTransferringListActions.setMLPipeLineIdList([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        featureDataTransferringListActions.setMLPipeLineIdList([
          ...mlPipelineIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
  }

  // 取得した特徴量データ生成の一覧を保持
  dispatch(
    featureDataTransferringListActions.setList(
      featureDataTransferringData.filter(
        (item) => item !== undefined
      ) as FeatureDataTransferring[]
    )
  )
  let totalCountQuery = query(
    getMlPipelineQueriesCollection(ugid),
    where('ml-pipeline.ml-pipeline-kind', '==', 'FeatureDataTransferring')
  )
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )
    totalCountQuery = query(
      totalCountQuery,
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  }
  const totalCount = await getCountFromServer(totalCountQuery)
  dispatch(
    featureDataTransferringListActions.setListDisplayCondition({
      ...condition,
      totalCount: totalCount.data().count,
    })
  )
}

// 特徴量データ転送一覧の配列をセット
const getFeatureDataTransferringRelationDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const ugid =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId

  const algorithms = getState().app.domainData.algorithms
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.featureDataTransferringListState.domainData
      .currentFeatureDataTransferringListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      featureDataTransferringListActions.setCurrentFeatureDataTransferringListSnapshot(
        undefined
      )
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.featureDataTransferringListState.domainData
      .featureDataTransferringDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery = query(
    getMlPipelineQueriesCollection(ugid),
    limit(condition.displayNumber),
    where('ml-pipeline.ml-pipeline-kind', '==', 'FeatureDataTransferring')
  )

  // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、MLPipelineIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('ml-pipeline.ml-pipeline-id', 'asc'),
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<', endCode)
    )
  } else {
    commonQuery = query(
      commonQuery,
      orderBy(condition.sortKey, condition.sortOrder)
    )
  }

  const mlPipelineIds =
    getState().pages.featureDataTransferringListState.domainData.mlPipelineIds
  // 取得済みの特徴量データ生成の最後尾
  let mlPipeLineDoc: DocumentData | undefined = undefined
  if (mlPipelineIds.length > 0) {
    mlPipeLineDoc = await getDoc(
      doc(
        getMlPipelineQueriesCollection(ugid),
        mlPipelineIds[mlPipelineIds.length - 1]
      )
    )
  }

  // 取得済みの特徴量データ生成が存在する場合は、取得済みの最後尾以降のデータから取得する
  if (mlPipeLineDoc) {
    commonQuery = query(commonQuery, startAfter(mlPipeLineDoc))
  }

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      onUpdateMlPipelineQueryDocument(
        dispatch,
        ugid,
        snapshot,
        algorithms,
        mlPipelineIds,
        condition,
        mlPipeLineDoc
      )
    }
  )

  // 現在、表示中の特徴量データ生成（ML Pipeline Docsの Snapshot を保持）
  dispatch(
    featureDataTransferringListActions.setCurrentFeatureDataTransferringListSnapshot(
      snapshot
    )
  )
}

export const featureDataTransferringListOperations = {
  /** リストを取得する */
  getFeatureDataTransferringList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataTransferringListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentMlPipelineIds: string[] =
          getState().pages.featureDataTransferringListState.domainData
            .mlPipelineIds
        const condition =
          getState().pages.featureDataTransferringListState.domainData
            .featureDataTransferringDisplayCondition
        const mlPipelineIds = currentMlPipelineIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(
          featureDataTransferringListActions.setMLPipeLineIdList(mlPipelineIds)
        )

        // 特徴量データ生成一覧に必要な関連Docsを取得
        await getFeatureDataTransferringRelationDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataTransferringListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.featureDataTransferringListState.domainData
            .currentFeatureDataTransferringListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
