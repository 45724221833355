import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'

import { State } from 'state/store'

import {
  GlobalLoading,
  EditableTextField,
  CopyableLabel,
  Toast,
  FeatureDataGroupsIcon,
  SelectableTable,
  SelectableTableHeader,
  TooltipLink,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  showToast,
  FileUploadViewer,
  BreadcrumbsComponent,
} from 'views/components'

import {
  FeatureDataGroupDetailAction,
  featureDataGroupDetailActions,
  FeatureDataGroupDetailOperations,
  RelatedFeatureData,
  FeatureDataListDisplayCondition,
} from 'state/ducks/featureDataGroupDetail'

import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import { useTheme } from '@mui/material/styles'

import { isUndefined } from 'utils/typeguard'

import { formatDateTimeSec } from 'views/components/utils/date'
import { FileRejection } from 'react-dropzone'
import { hasSharedUserGroupQueryParameter } from 'views/containers/utils/queryParams'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataGroupDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataGroupDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データグループ詳細取得 */
  getFeatureDataGroupDetail: (
    featureDataGroupId: string,
    isSharedUserGroup: boolean
  ) =>
    dispatch(
      FeatureDataGroupDetailOperations.getFeatureDataGroupDetail(
        featureDataGroupId,
        isSharedUserGroup
      )
    ),
  /** Stateのクリア */
  clearFeatureDataGroupDetailState: () =>
    dispatch(featureDataGroupDetailActions.clearFeatureDataGroupDetailState()),
  /** 特徴量データグループの名前を更新する */
  updateFeatureDataGroupName: (docId: string, name: string) =>
    dispatch(
      FeatureDataGroupDetailOperations.updateFeatureDataGroupDetailName(
        docId,
        name
      )
    ),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: FeatureDataListDisplayCondition) =>
    dispatch(
      featureDataGroupDetailActions.setListDisplayCondition(listCondition)
    ),
  /** 次ページの活性非活性の切り替え */
  setFeatureDataListPagingState: (tableNextPageSubState: 'Enable' | 'Unable') =>
    dispatch(
      featureDataGroupDetailActions.setFeatureDataListPagingState(
        tableNextPageSubState
      )
    ),
  /** 特徴量データグループのremarksを更新する */
  updateFeatureDataGroupRemarks: (docId: string, remarks: string) =>
    dispatch(
      FeatureDataGroupDetailOperations.updateFeatureDataGroupDetailRemarks(
        docId,
        remarks
      )
    ),
  /** 特徴量データグループのoverviewを更新する */
  updateFeatureDataGroupOverview: (docId: string, overview: string) =>
    dispatch(
      FeatureDataGroupDetailOperations.updateFeatureDataGroupOverview(
        docId,
        overview
      )
    ),
  /** 特徴量データグループのモデルアイコンを更新する */
  updateModelGroupIcon: (docId: string, uploadGroupIcon: File) =>
    dispatch(
      FeatureDataGroupDetailOperations.updateFeatureDataGroupIcon(
        docId,
        uploadGroupIcon
      )
    ),
  /** アイコンを削除 */
  deleteModelGroupIcon: (docId: string) =>
    dispatch(
      FeatureDataGroupDetailOperations.deleteFeatureDataGroupIcon(docId)
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(featureDataGroupDetailActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'featureDataId',
    title: '特徴量データ ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureDataVersion',
    title: 'バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureDataName',
    title: '特徴量データ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
]

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabContents: {
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  noteTabButtonDisabled: {
    backgroundColor: theme.palette.grey[100],
  },
  mt2Box: {
    marginTop: '16px',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionContent: {
    marginBottom: '16px',
  },
  iconDropZone: {
    width: '200px',
    position: 'relative',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
}))

const FeatureDataGroupDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()
  const globalTheme = useTheme()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  const showErrorToast = (fileName: string, messages?: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='featureDataGroupIconErrorToastFileName'>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages ? (
            messages.map((message) => <li key={message}>{message}</li>)
          ) : (
            <></>
          )}
        </div>
      </div>
    )

  const showInfoToast = (title: string) =>
    showToast(
      'info',
      <div>
        <div>{'メッセージ種別: info'}</div>
        <div data-testid='featureDataGroupIconUploadSuccess'>{title}</div>
      </div>
    )

  const fileUploadErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  // パスパラメータから特徴量データグループIDを取得
  const [selectedFeatureDataGroupId, setSelectedFeatureDataGroupId] =
    useState<string>('')

  /** 初期実行 */
  useEffect(() => {
    return () => {
      props.clearFeatureDataGroupDetailState()
    }
  }, [])

  useEffect(() => {
    // NOTE: featureDataGroupIdがprops.match.paramsに存在しないためwarningとなるが特徴量データグループ詳細に遷移する際は存在する
    setSelectedFeatureDataGroupId(
      (props.match.params as { [key: string]: string })['featureDataGroupId']
    )
    // 表示用詳細取得
    props.getFeatureDataGroupDetail(
      (props.match.params as { [key: string]: string })['featureDataGroupId'],
      isSharedUserGroup
    )
  }, [props.match.params])

  useEffect(() => {
    if (props.appState.toastInfo && props.appState.toastInfo.type === 'error') {
      showErrorToast(props.appState.toastInfo.title)
    } else if (
      props.appState.toastInfo &&
      props.appState.toastInfo.type === 'info'
    ) {
      showInfoToast(props.appState.toastInfo.title)
    }
    props.deleteToastInfo()
  }, [props.appState.toastInfo])

  /** 特徴量データグループ詳細遷移時に変更 */
  useEffect(() => {
    if (!isUndefined(props.domainData.currentFeatureDataGroupDetail)) {
      setEditingFeatureDataGroupRemarks(
        props.domainData.currentFeatureDataGroupDetail.remarks
      )
      setEditingFeatureDataGroupName(
        props.domainData.currentFeatureDataGroupDetail.featureDataGroupName
      )
      setEditingFeatureDataGroupOverview(
        props.domainData.currentFeatureDataGroupDetail.overview
      )
    }
  }, [props.domainData.currentFeatureDataGroupDetail])

  /** 対象のIDのデータがない時はhomeに戻る */
  useEffect(() => {
    if (props.appState.featureDataGroupDataState === 'NotFoundProcessed') {
      history.replace('/')
    }
  }, [props.appState.featureDataGroupDataState])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition =
      props.domainData.currentFeatureDataGroupDetail
        .featureDataListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList =
      props.domainData.currentFeatureDataGroupDetail.relatedFeatureDataList.slice(
        condition.displayNumber * condition.pageNumber,
        condition.displayNumber * condition.pageNumber + condition.displayNumber
      )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentFeatureDataGroupDetail.relatedFeatureDataList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    if (
      condition.displayNumber * (condition.pageNumber + 1) >=
      props.domainData.currentFeatureDataGroupDetail.relatedFeatureDataList
        .length
    ) {
      props.setFeatureDataListPagingState('Unable')
    } else {
      props.setFeatureDataListPagingState('Enable')
    }
    return displayList
  }, [
    props.domainData.currentFeatureDataGroupDetail
      .featureDataListDisplayCondition,
    props.domainData.currentFeatureDataGroupDetail.relatedFeatureDataList,
  ])

  /** テーブルに表示する特徴量データのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    if (!tableContent) return
    const convertedList: RelatedFeatureData[] = tableContent.map(
      (featureData: RelatedFeatureData) => {
        return {
          featureDataId: featureData.featureDataId,
          featureDataVersion: featureData.featureDataVersion,
          featureDataName: featureData.featureDataName,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataId') {
          if (value) {
            return (
              <TooltipLink
                key={key}
                data-testid={`feature-data-${value}`}
                title={value ?? 'abc'}
                placement='right-start'
                onClick={() =>
                  history.push(
                    `/feature-data-groups/${selectedFeatureDataGroupId}/feature-data/${value}${
                      isSharedUserGroup ? '?shared-user-group=true' : ''
                    }`
                  )
                }
              />
            )
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'featureDataVersion') {
          if (value) {
            return <Typography key={key}>{value.displayName}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'featureDataName') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const featureDataListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.currentFeatureDataGroupDetail
            ? props.domainData.currentFeatureDataGroupDetail
                .featureDataListDisplayCondition.displayNumber
            : 10
        }
        headers={TABLE_HEADERS}
        rows={tableRows ?? []}
        totalCount={
          props.domainData.currentFeatureDataGroupDetail.relatedFeatureDataList
            .length
        }
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={
          props.domainData.currentFeatureDataGroupDetail
            ? props.domainData.currentFeatureDataGroupDetail
                .featureDataListDisplayCondition.pageNumber
            : 0
        }
        sortOrder={{
          key: 'featureDataVersion',
          order: 'desc',
        }}
        displayNoneRadio={true}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.appState.tableNextPageSubState,
    props.domainData.currentFeatureDataGroupDetail
      .featureDataListDisplayCondition,
  ])

  const [editingFeatureDataGroupName, setEditingFeatureDataGroupName] =
    useState<string>('')
  const [editingFeatureDataGroupRemarks, setEditingFeatureDataGroupRemarks] =
    useState<string>('')
  const [editingFeatureDataGroupOverview, setEditingFeatureDataGroupOverview] =
    useState<string>('')
  const [nowTab, setNowTab] = useState(0)

  /** urlから取得したfeatureDataGroupId */
  const featureDataGroupId = props.match.params
    ? // NOTE: featureDataGroupIdがprops.match.paramsに存在しないためwarningとなるが特徴量データグループ詳細に遷移する際は存在する
      (props.match.params as { [key: string]: string })['featureDataGroupId']
    : ''
  if (featureDataGroupId === '') {
    console.error('Error Invalid Model Group ID')
    return <></>
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.currentFeatureDataGroupDetail
        .featureDataListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentFeatureDataGroupDetail.relatedFeatureDataList
        .length >
      props.domainData.currentFeatureDataGroupDetail
        .featureDataListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.currentFeatureDataGroupDetail
            .featureDataListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentFeatureDataGroupDetail
              .relatedFeatureDataList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.currentFeatureDataGroupDetail
        .featureDataListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })
  }

  const tabItems: TabItems[] = [
    // 一般情報コンテンツ
    {
      label: '一般情報',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <Box mb={2}>
                <Typography>アイコン</Typography>
              </Box>
              <Box ml={2}>
                <div className={classes.iconDropZone}>
                  <FileUploadViewer
                    fileType={'.jpg,.png'}
                    maxSize={256000}
                    file={
                      props.domainData.currentFeatureDataGroupDetail.iconUrl
                    }
                    fileDeleteAction={() =>
                      props.deleteModelGroupIcon(featureDataGroupId)
                    }
                    acceptFileOperation={(files: File[]) =>
                      props.updateModelGroupIcon(featureDataGroupId, files[0])
                    }
                    rejectedFileOperation={(fileRejections: FileRejection[]) =>
                      fileUploadErrors(fileRejections)
                    }
                    disabled={isSharedUserGroup}
                    data-testid={'feature-data-group'}
                  />
                </div>
              </Box>
              <Box ml={2} mt={3}>
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>
                    ※ファイル形式はpng、jpg。画像サイズは256KBまでアップロード可能です
                  </Typography>
                </Box>
              </Box>
              <Box mt={2} mb={2}>
                <Typography>概要</Typography>
              </Box>
              <TextField
                style={{ width: '100%' }}
                value={editingFeatureDataGroupOverview}
                onChange={(event) =>
                  setEditingFeatureDataGroupOverview(event.target.value)
                }
                variant='outlined'
                multiline
                minRows={5}
                inputProps={{
                  'data-testid': 'input-remarks',
                }}
                disabled={isSharedUserGroup}
              />
              <Box
                display='flex'
                justifyContent='flex-end'
                width='100%'
                className={classes.mt2Box}
              >
                <Button
                  variant='outlined'
                  onClick={() =>
                    props.updateFeatureDataGroupOverview(
                      featureDataGroupId,
                      editingFeatureDataGroupOverview
                    )
                  }
                  disabled={isSharedUserGroup}
                  className={
                    isSharedUserGroup
                      ? classes.noteTabButtonDisabled
                      : classes.noteTabButton
                  }
                  data-testid='remarks-edit'
                >
                  <Typography color='primary'>保存</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ),
    },
    // 特徴量データ一覧
    {
      label: '特徴量データ一覧',
      displayInfo: (
        <>
          <Box component={Paper} p={'24px 32px 32px'}>
            <Box
              display={'flex'}
              justifyContent={'right'}
            >{`登録特徴量データ数 ${props.domainData.currentFeatureDataGroupDetail.relatedFeatureDataList.length}`}</Box>
            <Box my={2}>{featureDataListTable}</Box>
          </Box>
        </>
      ),
    },
    // 備考タブコンテンツ
    {
      label: '備考',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                style={{ width: '100%' }}
                value={editingFeatureDataGroupRemarks}
                onChange={(event) =>
                  setEditingFeatureDataGroupRemarks(event.target.value)
                }
                variant='outlined'
                multiline
                minRows={5}
                inputProps={{
                  'data-testid': 'input-remarks',
                }}
                disabled={isSharedUserGroup}
              />
              <Box
                display='flex'
                justifyContent='flex-end'
                width='100%'
                className={classes.mt2Box}
              >
                <Button
                  variant='outlined'
                  onClick={() =>
                    props.updateFeatureDataGroupRemarks(
                      featureDataGroupId,
                      editingFeatureDataGroupRemarks
                    )
                  }
                  disabled={isSharedUserGroup}
                  className={
                    isSharedUserGroup
                      ? classes.noteTabButtonDisabled
                      : classes.noteTabButton
                  }
                  data-testid='remarks-edit'
                >
                  <Typography color='primary'>保存</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ),
    },
  ]

  return (
    <>
      <div className={classes.container}>
        <Toast containerOptions={{ limit: 20 }}>
          <Box
            style={{
              position: 'sticky',
              top: '64px',
              backgroundColor: '#fafafa',
              zIndex: 10,
            }}
          >
            <Box className={classes.innerContainer}>
              <Box pt={3}>
                <BreadcrumbsComponent
                  breadcrumbsPath={[
                    {
                      name: '特徴量データグループ一覧',
                      path: 'feature-data-groups',
                    },
                    {
                      name:
                        props.domainData.currentFeatureDataGroupDetail
                          .featureDataGroupName !== ''
                          ? `${props.domainData.currentFeatureDataGroupDetail.featureDataGroupName}`
                          : `${props.domainData.currentFeatureDataGroupDetail.featureDataGroupId}`,
                      path: `${props.domainData.currentFeatureDataGroupDetail.featureDataGroupId}`,
                    },
                  ]}
                />
              </Box>
              <div className={classes.flexAndBetween}>
                <Box
                  display='flex'
                  style={{
                    maxWidth: 'calc(100% - 130px)',
                    overflow: 'hidden',
                  }}
                >
                  <FeatureDataGroupsIcon
                    className={classes.pageIcon}
                    data-testid='feature-data-groups-icon'
                  />
                  <Box
                    height={76}
                    data-testid='feature-data-group-detail-title'
                    style={{
                      overflow: 'hidden',
                    }}
                  >
                    <EditableTextField
                      value={editingFeatureDataGroupName}
                      disabled={isSharedUserGroup}
                      onChange={(e) =>
                        setEditingFeatureDataGroupName(e.target.value)
                      }
                      onBlur={() => {
                        if (
                          props.domainData.currentFeatureDataGroupDetail
                            .featureDataGroupName !==
                          editingFeatureDataGroupName
                        ) {
                          props.updateFeatureDataGroupName(
                            featureDataGroupId,
                            editingFeatureDataGroupName
                          )
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box display='flex'>
                  <CopyableLabel
                    value={featureDataGroupId}
                    isTooltip
                    placement='top'
                  />
                </Box>
              </div>
              <Box p={1}>
                {props.domainData.currentFeatureDataGroupDetail &&
                props.domainData.currentFeatureDataGroupDetail.createdAt ? (
                  <Box height={24} display='flex' justifyContent='end'>
                    <TimerIcon style={{ marginRight: '4px' }} />
                    <Typography>
                      {formatDateTimeSec(
                        props.domainData.currentFeatureDataGroupDetail.createdAt?.toDate()
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <Box height={24} display='flex' justifyContent='end'></Box>
                )}
                <div className={classes.flexAndBetween}>
                  <Box height={64} display='flex' alignItems='center'>
                    <HandymanIcon
                      style={{ marginRight: '4px' }}
                      sx={{ color: globalTheme.palette.text.secondary }}
                    />
                    <Box sx={{ color: globalTheme.palette.text.secondary }}>
                      <Typography component='div'>
                        <h4>
                          {`${props.domainData.currentFeatureDataGroupDetail.trainingAlgorithm.algorithmName}`}
                        </h4>
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    {typeof props.domainData.currentFeatureDataGroupDetail
                      ?.createdBy === 'string' ? (
                      <Typography>
                        {
                          props.domainData.currentFeatureDataGroupDetail
                            ?.createdBy
                        }
                      </Typography>
                    ) : (
                      <Typography>
                        {
                          props.domainData.currentFeatureDataGroupDetail
                            ?.createdBy.firstName
                        }{' '}
                        {
                          props.domainData.currentFeatureDataGroupDetail
                            ?.createdBy.familyName
                        }
                      </Typography>
                    )}
                  </Box>
                </div>
              </Box>
              <Box
                style={{
                  backgroundColor: '#fafafa',
                }}
              >
                <Tabs
                  indicatorColor='primary'
                  value={nowTab}
                  style={{
                    paddingBottom: '16px',
                    marginBottom: '1px',
                  }}
                  onChange={(_, value) => setNowTab(value)}
                >
                  {tabItems.map((item, index) => (
                    <Tab
                      style={{
                        width: `${100 / tabItems.length}%`,
                        maxWidth: '1200px',
                      }}
                      key={index}
                      className={clsx(nowTab === index && classes.nowTab)}
                      label={item.label}
                      data-testid={`change-tab-${index}`}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className={classes.tabContents}>
            <Box className={classes.innerContainer}>
              <Paper elevation={0}>
                <Box>{tabItems[nowTab].displayInfo}</Box>
              </Paper>
            </Box>
          </Box>
        </Toast>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const FeatureDataGroupDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataGroupDetail))
