import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
const timeoutLimitSeconds = 15

export const UserSettingApi = {
  setPasswordUpdateTime: (): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'update-user-password-status',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )(),
}
