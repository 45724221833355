import React, { useContext } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { isNull, isUUIDv4, isUndefined } from 'utils/typeguard'
import { useDispatch } from 'react-redux'
import { AuthContext } from 'views/containers/providers'
import { domainDataOperations } from 'state/app/domainData/operations'
import { domainDataActions } from 'state/app/domainData'
import { appStateActions } from 'state/app/appState'
import { GlobalLoading } from 'views/components'
import { LoginStateType } from 'state/ducks/login'
import { ChangeCustomerDialogState } from 'views/App'
import { CustomerChangeConfirmDialog } from './customerChangeConfirm'

type Prop = {
  children: JSX.Element
  authed: boolean
  loginState: LoginStateType
  isLoading: boolean
}

const IS_ENABLE_UNAUTHED_PATH = [
  '/password-update',
  '/mfa-setting',
  '/password-reset',
]

/** URLの切り替えが不必要なパス名 */
const notTransitionPathNames = [
  'login',
  'password-update',
  'mfa-setting',
  'password-reset',
]

/**
 * URL変更
 */
export interface ChangeUrlProps {
  /** カスタマーID */
  customerId: string
  /** URLの先頭にカスタマーIDを付与する */
  isInsertCustomerId?: boolean
  /** ホーム画面へ遷移する */
  isTransitionHome?: boolean
}

export const Auth: React.FC<Prop> = (props: Prop) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const {
    currentCustomerName,
    nextCustomerName,
    changeCustomerDialogState,
    setCurrentCustomerName,
    setNextCustomerName,
    setChangeCustomerDialogState,
  } = useCustomerChangeDialog()

  const urlCustomerId = getCustomerId(location.pathname.split('/').at(1))
  const changeUrl = ({
    customerId,
    isInsertCustomerId = false,
    isTransitionHome = false,
  }: ChangeUrlProps) => {
    const pathName = history.location.pathname.split('/').at(1)
    if (pathName != null && notTransitionPathNames.includes(pathName)) return
    history.push(
      getChangeUrl({
        pathname: history.location.pathname,
        customerId,
        isInsertCustomerId,
        isTransitionHome,
      })
    )
  }

  const { currentUser } = useContext(AuthContext)

  if (isUndefined(currentUser)) {
    return <></>
  } else if (!isNull(currentUser)) {
    if (props.loginState === 'PasswordUpdate') {
      return <Redirect to={'/password-update'} />
    } else if (props.loginState === 'MfaSetting') {
      return <Redirect to={'/mfa-setting'} />
    } else if (props.loginState === 'NoCustomerList') {
      return <Redirect to={'/login'} />
    }

    if (
      currentCustomerName != '' &&
      nextCustomerName != '' &&
      changeCustomerDialogState === 'Unselected'
    ) {
      return (
        <CustomerChangeConfirmDialog
          currentCustomerName={currentCustomerName}
          nextCustomerName={nextCustomerName}
          dialogState={changeCustomerDialogState}
          onClickCancel={() => setChangeCustomerDialogState('Unchange')}
          onClickOk={() => setChangeCustomerDialogState('Change')}
        />
      )
    }

    if (!props.authed) {
      dispatch(
        domainDataOperations.certified(
          currentUser.uid,
          changeUrl,
          {
            state: changeCustomerDialogState,
            actions: {
              setCurrentCustomerName,
              setNextCustomerName,
            },
          },
          urlCustomerId
        )
      )
    }
    return (
      <>
        {!IS_ENABLE_UNAUTHED_PATH.includes(location.pathname) ? (
          props.authed ? (
            <>{props.children}</>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <GlobalLoading open={props.isLoading} />
      </>
    )
  } else {
    dispatch(domainDataActions.clearDomainData())
    dispatch(appStateActions.clearAppState())
  }

  return <Redirect to={'/login'} />
}

interface GetChangeUrlProps {
  /** 現在のURLのパス(history.location.pathname) */
  pathname: string
  /** 切り替え先のカスタマーID */
  customerId: string
  /** パスの先頭にカスタマーIDを挿入する(false、未指定の場合は先頭のカスタマーIDを置換する) */
  isInsertCustomerId?: boolean
  /** ホーム画面へ遷移するか */
  isTransitionHome?: boolean
}

/**
 * カスタマー切り替え後の新しいURLのパスを取得する
 * @param pathname 現在のURLのパス(history.location.pathname)
 * @param customerId 切り替え先のカスタマーID
 * @param isInsertCustomerId パスの先頭にカスタマーIDを挿入する(false、未指定の場合は先頭のカスタマーIDを置換する)
 * @returns 切り替え先のURLのパス
 */
const getChangeUrl = ({
  pathname,
  customerId,
  isInsertCustomerId = false,
  isTransitionHome = false,
}: GetChangeUrlProps) => {
  if (isInsertCustomerId && getCustomerId(pathname.split('/')[1]) != null) {
    // パスの先頭にカスタマーIDを挿入する際、既にパスの先頭にカスタマーIDが存在する場合は挿入しない
    return pathname
  }
  const changedUrl = pathname.split('/')
  isInsertCustomerId
    ? changedUrl.splice(1, 0, customerId)
    : (changedUrl[1] = customerId)

  if (isTransitionHome) return '/'

  return changedUrl.join('/')
}

/**
 * カスタマーIDがUUIDv4形式の場合、カスタマーIDを返す
 * @param customerId 検査したい文字列
 * @returns customerIdがUUIDv4形式の場合はcustomerId、UUIDv4形式でない場合はundefined
 */
const getCustomerId = (customerId: string | undefined): string | undefined => {
  if (customerId == null) return undefined
  return isUUIDv4(customerId) ? customerId : undefined
}

const useCustomerChangeDialog = () => {
  /** カスタマー変更確認ダイアログ表示状態 */
  const [changeCustomerDialogState, setChangeCustomerDialogState] =
    React.useState<ChangeCustomerDialogState>('Unselected')
  const [currentCustomerName, setCurrentCustomerName] = React.useState('')
  const [nextCustomerName, setNextCustomerName] = React.useState('')

  return {
    currentCustomerName,
    nextCustomerName,
    changeCustomerDialogState,
    setCurrentCustomerName,
    setNextCustomerName,
    setChangeCustomerDialogState,
  }
}
