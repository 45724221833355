import { Dispatch } from 'redux'
import { State } from 'state/store'

import { robotDataAnalysisEntryActions } from './actions'
import { RobotDataAnalysisEntryApi } from './apis'
import { ExecutionDate, ExecutionStatus, ModelGroup } from './types'
import { getTrainedModelGroupQueriesCollection } from 'state/firebase'
import { getDocs, query, where } from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { isUndefined } from 'utils/typeguard'
import { HttpsCallableResult } from 'firebase/functions'
import { convertISO8601 } from 'state/utils/converts'
import { TransformedRobotExecutionData } from 'state/utils/types'

export const THRESHOLD = 10000

export const robotDataAnalysisEntryOperations = {
  /** リストを取得する */
  getModelGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(robotDataAnalysisEntryActions.setInProgress(true))
        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        // TODO: 現仕様では、対象物認識のみであるため ObjectRecognition の algorithmIdを取得
        const algorithmId = getState().app.domainData.algorithms.find(
          (algorithm) => algorithm.algorithmPurpose === 'ObjectRecognition'
        )?.algorithmId

        const trainedModelGroupDocs = await getDocs(
          query(
            getTrainedModelGroupQueriesCollection(userGroupId),
            where('algorithm-id', '==', algorithmId)
          )
        )

        const modelGroups: ModelGroup[] = trainedModelGroupDocs.docs
          .map((doc) => {
            const data = doc.data()
            if (!fireStoreTypeGuardForModelGroupQueryDocument(data)) {
              return undefined
            }

            return {
              modelGroupId: data['trained-model-group-id'],
              modelGroupName: data['trained-model-group-name'],
            }
          })
          .filter((data) => !isUndefined(data)) as ModelGroup[]

        dispatch(robotDataAnalysisEntryActions.setModelGroupList(modelGroups))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(robotDataAnalysisEntryActions.setInProgress(false))
      }
    },
  getCount:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        // 入力情報を取得
        const queryConditions =
          getState().pages.robotDataAnalysisEntryState.domainData
            .queryConditions
        // バリデーション情報
        const userInputList =
          getState().pages.robotDataAnalysisEntryState.appState.userInputList

        // uuid v4 、 日付のバリデーションが通らなかった場合は実行しない
        if (!userInputList.every((obj) => obj.isValid)) {
          return
        }

        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const executionResults = Object.keys(
          queryConditions.executionStatus
        ).filter(
          (key) => queryConditions.executionStatus[key as keyof ExecutionStatus]
        )

        let executedAt: ExecutionDate | undefined

        if (queryConditions.executionDate?.from) {
          executedAt = {
            from: convertISO8601(queryConditions.executionDate.from),
          }
        }
        if (queryConditions.executionDate?.to) {
          executedAt = {
            ...executedAt,
            to: convertISO8601(queryConditions.executionDate.to, '23:59:59'),
          }
        }

        const params = {
          'execution-results': executionResults,
          'executed-at': executedAt,
          'execution-id-list':
            queryConditions.executionIdList &&
            queryConditions.executionIdList[0] != ''
              ? queryConditions.executionIdList
              : [],
          'robot-id-list':
            queryConditions.robotIdList && queryConditions.robotIdList[0] != ''
              ? queryConditions.robotIdList
              : [],
          'model-group-id-list': queryConditions.modelGroupIdList
            ? queryConditions.modelGroupIdList
            : [],
          'user-group-id-list': [userGroupId],
          confirmed: queryConditions.confirmed,
          extended: {
            'object-recognition': {
              'has-result': queryConditions.objectRecognitionResults,
            },
            config: {
              'collection-count-threshold': THRESHOLD,
            },
          },
        }

        const res = (await RobotDataAnalysisEntryApi.getCount(
          params
        )) as HttpsCallableResult<{
          status: string
          result: {
            'is-over': boolean
            'count-number': number
          }
        }>

        dispatch(
          robotDataAnalysisEntryActions.setCount({
            countNumber: res.data.result['count-number'],
            isOver: res.data.result['is-over'],
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          robotDataAnalysisEntryActions.setToastInfo({
            type: 'error',
            title: 'クエリが失敗しました',
            targets: [''],
          })
        )
      }
    },
  getTrainingDataId:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<string> => {
      try {
        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const queryConditions =
          getState().pages.robotDataAnalysisEntryState.domainData
            .queryConditions

        let executedAt: ExecutionDate | undefined

        if (queryConditions.executionDate) {
          if (queryConditions.executionDate.from) {
            executedAt = {
              from: convertISO8601(queryConditions.executionDate.from),
            }
          }
          if (queryConditions.executionDate.to) {
            executedAt = {
              ...executedAt,
              to: convertISO8601(queryConditions.executionDate.to, '23:59:59'),
            }
          }
        }

        const executionStatus: string[] = []

        Object.entries(queryConditions.executionStatus).forEach(
          ([key, value]) => {
            return value ? executionStatus.push(key) : ''
          }
        )

        const params = {
          'execution-results': executionStatus,
          'executed-at': executedAt,
          'execution-id-list': queryConditions.executionIdList ?? [],
          'robot-id-list': queryConditions.robotIdList ?? [],
          'model-group-id-list': queryConditions.modelGroupIdList ?? [],
          'user-group-id-list': [userGroupId],
          confirmed: queryConditions.confirmed,
          extended: {
            'object-recognition': {
              'has-result': queryConditions.objectRecognitionResults,
            },
          },
        }
        const executionDataList =
          (await RobotDataAnalysisEntryApi.getExecutionDataList(
            params
          )) as HttpsCallableResult<{
            status: string
            result: TransformedRobotExecutionData[]
          }>
        return executionDataList.data.result[0]['training-data-id']
      } catch (error) {
        console.error(error)
      }
      return ''
    },
}
