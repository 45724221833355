import { Dispatch } from 'redux'
import { modelListActions } from './'
import { ModelInfo } from './types'
import { State } from 'state/store'
import {
  getMlPipelineQueriesCollection,
  getTrainedModelQueriesCollection,
  getTrainedModelGroupQueriesCollection,
} from 'state/firebase'

import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument } from 'utils/fireStore/customTrainingMLPipelineQuery'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { domainDataOperations } from 'state/app/domainData/operations'

// モデル一覧を生成
const createModelList = async (
  userGroupId: string,
  modelQuery: Query<DocumentData>
): Promise<(ModelInfo | undefined)[]> =>
  await Promise.all(
    // モデルリストの生成
    await (
      await getDocs(modelQuery)
    ).docs.map(async (document: DocumentData) => {
      const modelQueryDocData = document.data()
      if (!fireStoreTypeGuardForModelQueryDocument(modelQueryDocData)) {
        return undefined
      }
      let mlPipeline = undefined
      if (modelQueryDocData['ml-pipeline-id']) {
        mlPipeline = (
          await getDoc(
            doc(
              getMlPipelineQueriesCollection(userGroupId),
              modelQueryDocData['ml-pipeline-id']
            )
          )
        ).data()
      }

      if (
        mlPipeline &&
        !fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument(mlPipeline)
      ) {
        return
      }

      // モデル一覧を返す
      return {
        modelId: modelQueryDocData['trained-model-id'],
        modelName: modelQueryDocData
          ? modelQueryDocData['trained-model-name']
          : '',
        modelKind: modelQueryDocData['model-kind'],
        modelGroupVersion: {
          displayName:
            modelQueryDocData['trained-model-group-version']['display-name'],
          major: modelQueryDocData['trained-model-group-version']['major'],
          minor: modelQueryDocData['trained-model-group-version']['minor'],
          patch: modelQueryDocData['trained-model-group-version']['patch'],
        },
        generatedAt: modelQueryDocData['generated-at'] ?? undefined,
        mlPipeline: {
          mlPipelineId: modelQueryDocData['ml-pipeline-id'],
          mlPipelineKind: mlPipeline
            ? mlPipeline['ml-pipeline']['ml-pipeline-kind']
            : '',
        },
        uid: modelQueryDocData['created-by'],
      } as ModelInfo
    })
  )

export const modelListOperations = {
  /** リストを取得する */
  getModelList:
    (trainedModelGroupId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelListActions.setInProgress(true))
        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // 表示条件取得
        const condition =
          getState().pages.modelListState.domainData.modelListDisplayCondition
        const modelGroup = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  where('trained-model-group-id', '==', trainedModelGroupId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((modelGroup) => modelGroup.data())[0]
          : (
              await getDoc(
                doc(
                  getTrainedModelGroupQueriesCollection(userGroupId),
                  trainedModelGroupId
                )
              )
            ).data()
        if (!modelGroup) {
          dispatch(modelListActions.setModelGroupSubState('GettingNotFound'))
        }
        if (!fireStoreTypeGuardForModelGroupQueryDocument(modelGroup)) {
          return
        }
        const modelGroupName = modelGroup
          ? modelGroup['trained-model-group-name']
          : ''
        if (
          getState().pages.modelListState.domainData.trainedModelGroupId === ''
        ) {
          dispatch(modelListActions.setTrainedModelGroupId(trainedModelGroupId))
        }
        if (
          getState().pages.modelListState.domainData.trainedModelGroupName ===
          ''
        ) {
          dispatch(modelListActions.setTrainedModelGroupName(modelGroupName))
        }
        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentModelList =
          getState().pages.modelListState.domainData.currentModelList

        const qLimit =
          condition.displayNumber * (condition.pageNumber + 1) -
          currentModelList.length
        let totalCountQuery = isSharedUserGroup
          ? query(
              getTrainedModelQueriesCollection(userGroupId),
              where('trained-model-group-id', '==', trainedModelGroupId),
              where('access-control.is-shared', '==', true),
              where('access-control.share-permissions.webapp', '==', 'list')
            )
          : query(
              getTrainedModelQueriesCollection(userGroupId),
              where('trained-model-group-id', '==', trainedModelGroupId)
            )
        // trained-modelsを表示件数分取得
        let modelQuery = query(totalCountQuery, limit(qLimit))

        // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )

          modelQuery = query(
            modelQuery,
            orderBy('trained-model-id', 'asc'),
            where('trained-model-id', '>=', startCode),
            where('trained-model-id', '<=', endCode)
          )
        } else {
          if (condition.sortKey === 'modelGroupVersion') {
            modelQuery = query(
              modelQuery,
              orderBy('trained-model-group-version.major', condition.sortOrder),
              orderBy('trained-model-group-version.minor', condition.sortOrder),
              orderBy('trained-model-group-version.patch', condition.sortOrder)
            )
          } else {
            modelQuery = query(
              modelQuery,
              orderBy(condition.sortKey, condition.sortOrder)
            )
          }
        }

        // 既に取得していれば最後の要素から取得
        let lastItem: DocumentData | undefined = undefined
        if (currentModelList.length) {
          lastItem = isSharedUserGroup
            ? (
                await getDocs(
                  query(
                    getTrainedModelQueriesCollection(userGroupId),
                    where(
                      'trained-model-id',
                      '==',
                      currentModelList[currentModelList.length - 1].modelId
                    ),
                    where('access-control.is-shared', '==', true),
                    where(
                      'access-control.share-permissions.webapp',
                      '==',
                      'list'
                    )
                  )
                )
              ).docs[0]
            : await getDoc(
                doc(
                  getTrainedModelQueriesCollection(userGroupId),
                  currentModelList[currentModelList.length - 1].modelId
                )
              )
          modelQuery = query(modelQuery, startAfter(lastItem))
        }

        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          totalCountQuery = query(
            totalCountQuery,
            where('trained-model-id', '>=', startCode),
            where('trained-model-id', '<=', endCode)
          )
        }
        const totalCount = await getCountFromServer(totalCountQuery)

        dispatch(
          modelListActions.setListDisplayCondition({
            ...condition,
            totalCount: totalCount.data().count,
          })
        )

        // モデル一覧を取得
        const newModelList: (ModelInfo | undefined)[] = await createModelList(
          userGroupId,
          modelQuery
        )

        dispatch(
          modelListActions.setModelList([
            ...currentModelList,
            ...(newModelList.filter(
              (item) => item !== undefined
            ) as ModelInfo[]),
          ])
        )
        dispatch(modelListActions.setModelGroupSubState('GettingSuccess'))
      } catch (error) {
        console.error(error)
        dispatch(modelListActions.setModelGroupSubState('GettingError'))
      } finally {
        dispatch(modelListActions.setInProgress(false))
      }
    },
}
