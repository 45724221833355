import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  FeatureDataInfo,
  AdminFeatureDataListActionType,
  FeatureDataListDisplayCondition,
} from './types'

export type AdminFeatureDataListActions = ActionsUnion<
  typeof adminFeatureDataListActions
>

export const adminFeatureDataListActions = {
  setFeatureDataList: (
    featureDataList: FeatureDataInfo[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_LIST',
    { featureDataList: FeatureDataInfo[] }
  > =>
    CreateAction(AdminFeatureDataListActionType.SET_FEATURE_DATA_LIST, {
      featureDataList,
    }),
  clearFeatureDataList: (): Action<'CLEAR_FEATURE_DATA_LIST'> =>
    CreateAction(AdminFeatureDataListActionType.CLEAR_FEATURE_DATA_LIST),
  setListDisplayCondition: (
    featureDataListDisplayCondition: FeatureDataListDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_LIST_DISPLAY_CONDITION',
    { featureDataListDisplayCondition: FeatureDataListDisplayCondition }
  > =>
    CreateAction(
      AdminFeatureDataListActionType.SET_FEATURE_DATA_LIST_DISPLAY_CONDITION,
      {
        featureDataListDisplayCondition,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      AdminFeatureDataListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST,
      {
        inProgress,
      }
    ),
  clearFeatureDataListState: (): Action<'CLEAR_FEATURE_DATA_LIST_STATE'> =>
    CreateAction(AdminFeatureDataListActionType.CLEAR_FEATURE_DATA_LIST_STATE),
}
