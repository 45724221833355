import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

import { RunningTimeLabelProps } from './types'
import TimerIcon from '@mui/icons-material/Timer'
import { formatTimeSecByMillSecond } from 'views/components/utils/date'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles()((theme) => ({
  labelCenter: {
    color: 'green',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: 'black',
    marginRight: theme.spacing(0.4),
  },
}))

export const RunningTimeLabel: React.FC<RunningTimeLabelProps> = (
  props: RunningTimeLabelProps
) => {
  const { classes } = useStyles()
  const [runningTime, setRunningTime] = useState('--:--:--')

  useEffect(() => {
    const interval = setInterval(() => {
      const nowTimeUTC = Date.now()
      const startAtUTC = new Date().setTime(props.startedAt.getTime())
      const timeDiff = nowTimeUTC - startAtUTC
      setRunningTime(formatTimeSecByMillSecond(timeDiff))
    }, 1000)
    return () => clearInterval(interval)
  }, [props.startedAt])

  return (
    <Typography className={classes.labelCenter}>
      <TimerIcon className={classes.icon} />
      {runningTime}
    </Typography>
  )
}
