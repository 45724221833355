import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { Header, SideMenu, Spacer } from 'views/components'

import { PageLayoutProps } from './types'

export const PageLayout: React.FC<React.PropsWithChildren<PageLayoutProps>> = (
  props: React.PropsWithChildren<PageLayoutProps>
) => {
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = React.useState(true)
  const theme = useTheme()

  const handleMenuListOpen = () => {
    setOpen(true)
  }

  const handleMenuListClose = () => {
    setOpen(false)
  }

  const handleClickMenu = () => {
    setOpen(!open)
  }

  const generalPath = useMemo(() => {
    return [...props.menu.general.categories]
      .flatMap((menu) => {
        if (menu.section) {
          return menu.section.itemList.map((r) => r.path)
        }
        if (menu.item) {
          return menu.item.path
        }
      })
      .filter((x) => x !== '/')
  }, [props.menu])

  const [currentUserGroupId, setCurrentUserGroupId] = React.useState(
    props.userGroupId
  )

  useEffect(() => {
    if (currentUserGroupId !== props.userGroupId) {
      const currentPath = location.pathname.replace('/webapp', '')
      // ビルド画面の場合はモデル一覧に戻す
      // それ以外のパスの場合は、現在のパスの機能別の上位パスに遷移
      const replacePath = currentPath.startsWith('/builds')
        ? '/models'
        : generalPath.find(
            (path) =>
              currentPath !== path && path && currentPath.startsWith(path)
          )

      if (replacePath) {
        history.replace(replacePath)
      }
      setCurrentUserGroupId(props.userGroupId)
    }
  }, [props.userGroupId])

  const {
    children,
    mailAddress,
    userId,
    menu,
    superUser,
    customerRole,
    accountGroupRole,
    firstName,
    familyName,
    customerName,
    handleClickCustomer,
  } = props

  return (
    <Box minHeight='100vh' display='flex'>
      <Header
        userName={`${firstName} ${familyName}`}
        mailAddress={mailAddress}
        userId={userId}
        menu={menu}
        customerName={customerName}
        handleClickMenu={handleClickMenu}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        handleClickCustomer={handleClickCustomer!}
      />
      <Box width='100%' display='flex' flexDirection='row'>
        <SideMenu
          sideMenuWidth={theme.custom.sideMenu.width as number}
          isOpen={open}
          menu={menu}
          superUser={superUser}
          customerRole={customerRole}
          accountGroupRole={accountGroupRole}
          onClickClose={handleMenuListClose}
          onClickOpen={handleMenuListOpen}
        />
        <Box
          width={`calc(100% - ${theme.custom.sideMenu.width as number}px)`}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          flexGrow={1}
          style={{ backgroundColor: '#fafafa' }}
        >
          <Box
            height='100vh'
            component='main'
            flexGrow={1}
            width='100%'
            maxWidth='1200px'
            display='flex'
            flexDirection='column'
            style={{
              overflow: 'hidden',
              overflowY: 'auto',
            }}
          >
            <Spacer axis='vertical' size={64} />
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
