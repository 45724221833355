import React from 'react'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import IconButton from '@mui/material/IconButton'

import { DatasetAugmentationIconProps } from './types'

export const DatasetAugmentationIcon: React.FC<DatasetAugmentationIconProps> = (
  props: DatasetAugmentationIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <ViewInArIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
