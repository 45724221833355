import { BuildDetailAction } from './actions'
import { BuildDetailActionType, BuildDetailState } from './types'

const initialState: BuildDetailState = {
  domainData: {
    currentBuildDetail: undefined,
    systemEvaluationFiles: [],
  },
  appState: {
    buildDetailState: {
      mlPipelineDataState: 'BeforeLoading',
      systemEvaluationFileSubState: 'BeforeLoading',
    },
    toastInfo: undefined,
    isInProgressForGettingBuildDetail: false,
    isInProgressForGettingSystemEvaluation: false,
    isInProgressForDownloading: false,
  },
}

export const BuildDetailReducer = (
  state: BuildDetailState = initialState,
  action: BuildDetailAction
): BuildDetailState => {
  switch (action.type) {
    case BuildDetailActionType.SET_CURRENT_BUILD_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentBuildDetail: action.payload.currentBuildDetail,
        },
      }
    case BuildDetailActionType.SET_BUILD_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          buildDetailState: action.payload.buildDetailState,
        },
      }
    case BuildDetailActionType.SET_IN_PROGRESS_FOR_DOWNLOADING:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForDownloading: action.payload.isInProgressForDownloading,
        },
      }
    case BuildDetailActionType.SET_IN_PROGRESS_FOR_GETTING_BUILD_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingBuildDetail:
            action.payload.isInProgressForGettingBuildDetail,
        },
      }
    case BuildDetailActionType.SET_IN_PROGRESS_FOR_GETTING_SYSTEM_EVALUATION:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingSystemEvaluation:
            action.payload.isInProgressForGettingSystemEvaluation,
        },
      }
    case BuildDetailActionType.CLEAR_CURRENT_BUILD_DETAIL:
      return {
        ...initialState,
      }
    case BuildDetailActionType.SET_TOAST_INFO_FOR_BUILD_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case BuildDetailActionType.SET_SYSTEM_EVALUATION_FILES:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          systemEvaluationFiles: action.payload.systemEvaluationFiles,
        },
      }
    default:
      return state
  }
}
