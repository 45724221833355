import {
  RobotDataAnalysisExtractedImageDetailState,
  RobotDataAnalysisExtractedImageDetailActionType,
} from './types'
import { RobotDataAnalysisExtractedImageDetailAction } from './actions'

const initialState: RobotDataAnalysisExtractedImageDetailState = {
  domainData: {
    executionData: undefined,
    inferenceResultDisplayCondition: {
      mask: false,
      bbox: true,
      label: true,
      selectedIds: [],
    },
    inferenceResults: [],
  },
  appState: {
    inProgress: false,
    toastInfo: undefined,
  },
}

export const RobotDataAnalysisExtractedImageDetailReducer = (
  state: RobotDataAnalysisExtractedImageDetailState = initialState,
  action: RobotDataAnalysisExtractedImageDetailAction
): RobotDataAnalysisExtractedImageDetailState => {
  switch (action.type) {
    case RobotDataAnalysisExtractedImageDetailActionType.SET_EXECUTION_DATA_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executionData: action.payload.executionData,
        },
      }
    case RobotDataAnalysisExtractedImageDetailActionType.SET_INFERENCE_RESULTS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceResults: action.payload.inferenceResults,
        },
      }
    case RobotDataAnalysisExtractedImageDetailActionType.SET_INFERENCE_RESULT_DISPLAY_CONDITION_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceResultDisplayCondition:
            action.payload.inferenceResultDisplayCondition,
        },
      }
    case RobotDataAnalysisExtractedImageDetailActionType.SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case RobotDataAnalysisExtractedImageDetailActionType.SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case RobotDataAnalysisExtractedImageDetailActionType.CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
