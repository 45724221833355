import {
  CreateAction,
  ActionsUnion,
  Action,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  FeatureDataGeneratingActionType,
  FeatureDataGroup,
  Dataset,
  Setting,
  DisplayCondition,
  AlgorithmDisplayCondition,
  MetaData,
  FeatureDataGeneratingStateKind,
  ExecutionInfo,
} from './types'
import {
  AlgorithmStructureVersion,
  TrainingAlgorithmVersion,
} from 'state/app/domainData'
import { Version } from 'types/StateTypes'

export type FeatureDataGeneratingAction = ActionsUnion<
  typeof featureDataGeneratingActions
>

export const featureDataGeneratingActions = {
  setFeatureDataGroupList: (
    featureDataGroups: FeatureDataGroup[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_GENERATING',
    { featureDataGroups: FeatureDataGroup[] }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_GENERATING,
      {
        featureDataGroups,
      }
    ),
  setSelectedFeatureDataGroup: (
    selectedFeatureDataGroup?: FeatureDataGroup
  ): ActionWithPayload<
    'SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_GENERATING',
    { selectedFeatureDataGroup?: FeatureDataGroup }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_GENERATING,
      {
        selectedFeatureDataGroup,
      }
    ),
  setSelectedAlgorithmStructureVersion: (
    selectedAlgorithmStructureVersion?: AlgorithmStructureVersion
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_GENERATING',
    { selectedAlgorithmStructureVersion?: AlgorithmStructureVersion }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_GENERATING,
      {
        selectedAlgorithmStructureVersion,
      }
    ),
  setDatasetList: (
    datasetList: Dataset[]
  ): ActionWithPayload<
    'SET_DATASET_LIST_FOR_FEATURE_DATA_GENERATING',
    { datasetList: Dataset[] }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_DATASET_LIST_FOR_FEATURE_DATA_GENERATING,
      {
        datasetList,
      }
    ),
  setSettingList: (
    settingList: Setting[]
  ): ActionWithPayload<
    'SET_SETTING_LIST_FOR_FEATURE_DATA_GENERATING',
    { settingList: Setting[] }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SETTING_LIST_FOR_FEATURE_DATA_GENERATING,
      {
        settingList,
      }
    ),
  setFeatureDataGroupDisplayCondition: (
    featureDataGroupDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
    { featureDataGroupDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING,
      {
        featureDataGroupDisplayCondition,
      }
    ),
  setTrainingAlgorithmDisplayCondition: (
    trainingAlgorithmDisplayCondition?: AlgorithmDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
    { trainingAlgorithmDisplayCondition?: AlgorithmDisplayCondition }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING,
      {
        trainingAlgorithmDisplayCondition,
      }
    ),
  clearTrainingAlgorithmDisplayCondition:
    (): Action<'CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING'> =>
      CreateAction(
        FeatureDataGeneratingActionType.CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING
      ),
  setDatasetDisplayCondition: (
    datasetDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
    { datasetDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING,
      {
        datasetDisplayCondition,
      }
    ),
  clearDatasetDisplayCondition:
    (): Action<'CLEAR_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING'> =>
      CreateAction(
        FeatureDataGeneratingActionType.CLEAR_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING
      ),
  clearFeatureDataGroupDisplayCondition:
    (): Action<'CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING'> =>
      CreateAction(
        FeatureDataGeneratingActionType.CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING
      ),
  setSettingDisplayCondition: (
    settingDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
    { settingDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING,
      {
        settingDisplayCondition,
      }
    ),
  clearSettingDisplayCondition:
    (): Action<'CLEAR_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING'> =>
      CreateAction(
        FeatureDataGeneratingActionType.CLEAR_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING
      ),
  setSelectedTrainingAlgorithmId: (
    selectedTrainingAlgorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_GENERATING',
    { selectedTrainingAlgorithmId: string }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_GENERATING,
      {
        selectedTrainingAlgorithmId,
      }
    ),
  setSelectedTrainingAlgorithmVersion: (
    selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_GENERATING',
    { selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_GENERATING,
      {
        selectedTrainingAlgorithmVersion,
      }
    ),
  setSelectedDataset: (
    selectedDataset?: Dataset
  ): ActionWithPayload<
    'SET_SELECTED_DATASET_FOR_FEATURE_DATA_GENERATING',
    { selectedDataset?: Dataset }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_DATASET_FOR_FEATURE_DATA_GENERATING,
      {
        selectedDataset,
      }
    ),
  setSelectedSetting: (
    selectedSetting?: Setting
  ): ActionWithPayload<
    'SET_SELECTED_SETTING_FOR_FEATURE_DATA_GENERATING',
    { selectedSetting?: Setting }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_SETTING_FOR_FEATURE_DATA_GENERATING,
      {
        selectedSetting,
      }
    ),
  setSelectedFeatureDataGenerateKind: (
    selectedFeatureDataGenerateKind: 'All' | 'FeatureDataOnly'
  ): ActionWithPayload<
    'SET_SELECTED_FEATURE_DATA_GENERATE_KIND_FOR_FEATURE_DATA_GENERATING',
    { selectedFeatureDataGenerateKind: 'All' | 'FeatureDataOnly' }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_FEATURE_DATA_GENERATE_KIND_FOR_FEATURE_DATA_GENERATING,
      {
        selectedFeatureDataGenerateKind,
      }
    ),
  setMlPipelinesMetaData: (
    mlPipelinesMetaData?: MetaData
  ): ActionWithPayload<
    'SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_GENERATING',
    { mlPipelinesMetaData?: MetaData }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_GENERATING,
      {
        mlPipelinesMetaData,
      }
    ),
  setFeatureDataMetaData: (
    featureDataMetaData?: MetaData
  ): ActionWithPayload<
    'SET_FEATURE_DATA_META_DATA_FOR_FEATURE_DATA_GENERATING',
    { featureDataMetaData?: MetaData }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_FEATURE_DATA_META_DATA_FOR_FEATURE_DATA_GENERATING,
      {
        featureDataMetaData,
      }
    ),
  setTrainingAlgorithmSubState: (
    trainingAlgorithmSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_TRAINING_ALGORITHM_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
    { trainingAlgorithmSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_TRAINING_ALGORITHM_SUB_STATE_FOR_FEATURE_DATA_GENERATING,
      {
        trainingAlgorithmSubState,
      }
    ),
  setFeatureDataGroupSubState: (
    featureDataGroupSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
    { featureDataGroupSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_GENERATING,
      {
        featureDataGroupSubState,
      }
    ),
  setDatasetSubState: (
    datasetSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_DATASET_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
    { datasetSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_DATASET_SUB_STATE_FOR_FEATURE_DATA_GENERATING,
      {
        datasetSubState,
      }
    ),
  setSettingSubState: (
    settingSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_SETTING_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
    { settingSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SETTING_SUB_STATE_FOR_FEATURE_DATA_GENERATING,
      {
        settingSubState,
      }
    ),
  setOutputFormatSubState: (
    outputFormatSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_OUTPUT_FORMAT_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
    { outputFormatSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_OUTPUT_FORMAT_SUB_STATE_FOR_FEATURE_DATA_GENERATING,
      {
        outputFormatSubState,
      }
    ),
  setMetaDataSubState: (
    metaDataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
    { metaDataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_GENERATING,
      {
        metaDataSubState,
      }
    ),
  setFeatureDataGeneratingState: (
    featureDataGeneratingStateKind: FeatureDataGeneratingStateKind
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING',
    { featureDataGeneratingStateKind: FeatureDataGeneratingStateKind }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING,
      {
        featureDataGeneratingStateKind,
      }
    ),
  clearFeatureDataGeneratingState:
    (): Action<'CLEAR_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING'> =>
      CreateAction(
        FeatureDataGeneratingActionType.CLEAR_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING
      ),
  executeFeatureDataGenerating: (
    isExecuted: boolean
  ): ActionWithPayload<
    'EXEC_FEATURE_DATA_GENERATING_SUCCESS_FOR_FEATURE_DATA_GENERATING',
    { isExecuted: boolean }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.EXEC_FEATURE_DATA_GENERATING_SUCCESS_FOR_FEATURE_DATA_GENERATING,
      {
        isExecuted,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING,
      {
        inProgress,
      }
    ),
  setExecutedInfo: (
    executedInfo?: ExecutionInfo
  ): ActionWithPayload<
    'SET_EXECUTED_INFO_FOR_FEATURE_DATA_GENERATING',
    { executedInfo?: ExecutionInfo }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_EXECUTED_INFO_FOR_FEATURE_DATA_GENERATING,
      {
        executedInfo,
      }
    ),
  setSelectedFeatureDataGroupVersion: (
    selectedVersion?: Version
  ): ActionWithPayload<
    'SET_SELECTED_FEATURE_DATA_GROUP_VERSION_FOR_FEATURE_DATA_GENERATING',
    { selectedVersion?: Version }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_SELECTED_FEATURE_DATA_GROUP_VERSION_FOR_FEATURE_DATA_GENERATING,
      {
        selectedVersion,
      }
    ),
  executeFeatureDataGeneratingFailure:
    (): Action<'EXEC_FEATURE_DATA_GENERATING_FAILURE_FOR_FEATURE_DATA_GENERATING'> =>
      CreateAction(
        FeatureDataGeneratingActionType.EXEC_FEATURE_DATA_GENERATING_FAILURE_FOR_FEATURE_DATA_GENERATING
      ),
  setCurrentDatasetListSnapshot: (
    currentDatasetListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_FEATURE_DATA_GENERATING',
    { currentDatasetListSnapshot?: () => void }
  > =>
    CreateAction(
      FeatureDataGeneratingActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_FEATURE_DATA_GENERATING,
      {
        currentDatasetListSnapshot,
      }
    ),
}
