import { Timestamp } from 'state/firebase'
import { VersionWithBuild } from 'types/StateTypes'
import {
  DocumentBuildVersion,
  DocumentPreReleaseVersion,
  DocumentVersion,
} from 'utils/fireStore/common'

// ActionType
export const BuildEntryActionType = {
  SET_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
    'SET_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY',
  SET_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
    'SET_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY',
  SET_SELECTED_TRAINED_MODEL_GROUP_FOR_BUILD_ENTRY:
    'SET_SELECTED_TRAINED_MODEL_GROUP_FOR_BUILD_ENTRY',
  SET_TRAINED_MODEL_GROUP_LIST_FOR_BUILD_ENTRY:
    'SET_TRAINED_MODEL_GROUP_LIST_FOR_BUILD_ENTRY',
  SET_INFERENCE_ALGORITHM_VERSIONS_FOR_BUILD_ENTRY:
    'SET_INFERENCE_ALGORITHM_VERSIONS_FOR_BUILD_ENTRY',
  SET_SELECTED_TRAINED_MODEL_FOR_BUILD_ENTRY:
    'SET_SELECTED_TRAINED_MODEL_FOR_BUILD_ENTRY',
  SET_SELECTED_ALGORITHM_ID_FOR_BUILD_ENTRY:
    'SET_SELECTED_ALGORITHM_ID_FOR_BUILD_ENTRY',
  CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
    'CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY',
  CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY:
    'CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_BUILD_ENTRY',
  SET_TRAINED_MODEL_GROUP_SUB_STATE: 'SET_TRAINED_MODEL_GROUP_SUB_STATE',
  SET_TRAINED_MODEL_SUB_STATE: 'SET_TRAINED_MODEL_SUB_STATE',
  SET_SELECTED_INFERENCE_ALGORITHM_VERSION_FOR_BUILD_ENTRY:
    'SET_SELECTED_INFERENCE_ALGORITHM_VERSION_FOR_BUILD_ENTRY',
  SET_EDGE_IMAGE_SUB_STATE_FOR_BUILD_ENTRY:
    'SET_EDGE_IMAGE_SUB_STATE_FOR_BUILD_ENTRY',
  SET_IS_TRANSFER: 'SET_IS_TRANSFER',
  SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID:
    'SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID',
  EXEC_BUILD_ENTRY_FAILURE: 'EXEC_BUILD_ENTRY_FAILURE',
  SET_BUILD_META_DATA: 'SET_BUILD_META_DATA',
  SET_EDGE_IMAGE_META_DATA: 'SET_EDGE_IMAGE_META_DATA',
  SET_META_DATA_SUB_STATE_FOR_BUILD_ENTRY:
    'SET_META_DATA_SUB_STATE_FOR_BUILD_ENTRY',
  SET_BUILD_ENTRY_STATE: 'SET_BUILD_ENTRY_STATE',
  SET_IN_PROGRESS_FOR_BUILD_ENTRY: 'SET_IN_PROGRESS_FOR_BUILD_ENTRY',
  SET_EXECUTION_INFO_FOR_BUILD_ENTRY: 'SET_EXECUTION_INFO_FOR_BUILD_ENTRY',
  EXEC_BUILD_ENTRY_SUCCESS: 'EXEC_BUILD_ENTRY_SUCCESS',
  SET_IS_SYSTEM_EVALUATION: 'SET_IS_SYSTEM_EVALUATION',
  CLEAR_BUILD_ENTRY_STATE: 'CLEAR_BUILD_ENTRY_STATE',
} as const

export interface BaseInferenceContainerImageDocument {
  id: string
  'algorithm-id': string
  'inference-algorithm-version': DocumentPreReleaseVersion
  'inference-code-version': DocumentBuildVersion
  'base-inference-container-image-id': string
  'built-at': Timestamp
  'container-image-digest': string
  'container-image-name': string
  'container-image-platform': {
    architecture: string
    os: string
  }
  'container-image-tags': string[]
  'container-interface-id': string
  'container-interface-version': DocumentVersion
  'dockerfile-path'?: string
  requirements: unknown
  'usage-type': string
}

export interface MetaData {
  name: string
  remarks?: string
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface BuildEntrySubStateKind {
  trainedModelGroupSubState: 'Unselected' | 'Selected'
  trainedModelSubState: 'Unselected' | 'Selected'
  edgeImageSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError'
}

export interface TrainedModelGroupListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface TrainedModelListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export const BuildEntryStateKindArray = [
  'TrainedModelGroup',
  'TrainedModel',
  'EdgeImageUpload',
  'MetaDataState',
  'ExecuteState',
]

export type BuildEntryStateKind = (typeof BuildEntryStateKindArray)[number]

export interface TrainedModelInfo {
  trainedModelId: string // モデルのID
  trainedModelGroupVersion: {
    displayName: string // 表示するバージョン
    major: number // メジャーバージョン
    minor: number // マイナーバージョン
    patch: number // パッチバージョン
  }
  trainedModelName: string // モデルの名前
  transactionStatus: string // ステータス
}

export interface TrainedModelGroupInfo {
  trainedModelGroupId: string // モデルグループID
  trainedModelGroupName: string // モデルグループ名
  trainedModelCount: number // 登録モデル数
  latestTrainedModelVersion: string // 最新モデルバージョン
  latestTrainedModelName: string // 最新モデル名
  updatedAt?: Timestamp // 更新日
  createdAt?: Timestamp // 作成日
  createdBy: string // 作成者
  trainedModels: TrainedModelInfo[] // モデルの配列
  userGroupId: string
}

export interface TrainedModelGroupLists {
  sharedUserGroup: TrainedModelGroupInfo[]
  userGroup: TrainedModelGroupInfo[]
}

export interface InferenceAlgorithmVersion {
  inferenceAlgorithmVersion: string // 推論アルゴリズムバージョン
  algorithmVersion: {
    displayName: string // 表示するバージョン
    major: number // メジャーバージョン
    minor: number // マイナーバージョン
    patch: number // パッチバージョン
    preRelease: number // プレリリースバージョン
  }
  inferenceCodeVersion: VersionWithBuild
  // メタデータ
  metadata: {
    remarks: string // アルゴリズムの備考
  }
  releasedAt: Timestamp // リリース日時
  baseInferenceContainerImages: ContainerImage[]
  containerInterfaceVersion: {
    displayName: string // 表示するバージョン
    major: number // メジャーバージョン
    minor: number // マイナーバージョン
    patch: number // パッチバージョン
  }
}

export interface ContainerImage {
  baseInferenceContainerImageId: string // 推論コンテナイメージID
  containerImageTags: string[] // 推論コンテナイメージタグ(ex. cpu, cpu/arm64)
  containerImagePlatform: {
    architecture: string
    os: string
  }
}

export interface BuildEntryParamsType {
  trainedModel: {
    trainedModelGroupId: string
    trainedModelId: string
    userGroupId: string
  }
  algorithmId: string
  algorithmVersion: {
    displayName: string // 表示するバージョン
    major: number // メジャーバージョン
    minor: number // マイナーバージョン
    patch: number // パッチバージョン
  }
  baseContainerImageIdList: string[]
  isTransfer: boolean
  isSystemEvaluation: boolean
  edgeImageMetadata: {
    name: string
    remarks?: string
  }
  mlPipelineMetadata: {
    name: string
    remarks?: string
  }
  containerInterfaceVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
}

export interface ExecutionInfo {
  mlPipelineId?: string
  buildStepId?: string
}

export interface BuildEntryDomainData {
  selectedTrainingAlgorithm?: {
    algorithmId: string
  }
  trainedModelGroups: TrainedModelGroupLists // モデルグループの配列
  selectedTrainedModelGroup?: TrainedModelGroupInfo // 選択されたモデルグループ
  selectedTrainedModel?: TrainedModelInfo // 選択されたモデル
  selectedBaseInferenceContainerImageIds: string[] // 選択された推論コンテナイメージID
  inferenceAlgorithmVersions: InferenceAlgorithmVersion[] // 取得したbaseInferenceContainerImageを表示用に加工したもの
  selectedInferenceAlgorithmVersion?: InferenceAlgorithmVersion // 選択された推論アルゴリズムバージョン
  isTransfer: boolean // AWSへの転送可否
  isSystemEvaluation: boolean // docker image を評価するかどうか
  buildInfoMetadata: MetaData // ビルド情報メタデータ
  edgeImageInfoMetadata: MetaData // エッジイメージ情報メタデータ
  executionInfo: ExecutionInfo
}

export interface BuildEntryAppState {
  inProgress: boolean // データ取得中、実行中フラグ
  toastInfo?: ToastInfo // トーストの表示する情報
  buildEntryStateKind: BuildEntryStateKind // ビルド開始画面のSTEP
  buildEntrySubStateKind: BuildEntrySubStateKind // ビルド開始画面の各種STEPの状態
  trainedModelGroupListDisplayCondition: TrainedModelGroupListDisplayCondition & {
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  } // モデルグループ一覧の表示条件
  trainedModelDisplayCondition: TrainedModelListDisplayCondition // モデル一覧の表示条件
}

export interface BuildEntryState {
  domainData: BuildEntryDomainData
  appState: BuildEntryAppState
}

export interface TrainedModelDataItem {
  'trained-model-id': string
  'trained-model-name': string
  'trained-model-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
  'user-group-id': string
}
