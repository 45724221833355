import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { ImageWithInfoProps } from './types'
import { InferenceResultView } from '../inferenceResultView'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { BrokenImage } from '@mui/icons-material'

const useStyles = (backgroundOpacityPercentage: number | undefined) =>
  makeStyles()((theme) => ({
    root: { zIndex: 0, position: 'relative', height: '100%', width: '100%' },
    img: {
      zIndex: 0,
      objectFit: 'contain',
      height: '100%',
      width: '534px',
      margin: 'auto',
      display: 'block',
    },
    info: {
      zIndex: 1,
      position: 'absolute',
      top: 0,
      right: 0,
      width: 'auto',
      flexDirection: 'column',
      color: theme.palette.common.white,
      backgroundColor: `rgba(0, 0, 0, ${
        backgroundOpacityPercentage ? backgroundOpacityPercentage / 100 : 0.5
      })`,
    },
    infoIcon: {
      zIndex: 1,
      position: 'absolute',
      top: 6,
      right: 6,
      width: 'auto',
      flexDirection: 'column',
      color: theme.palette.common.white,
    },
    infoInvisible: {
      display: 'none',
    },
    infoRow: {
      justifyContent: 'space-between',
      paddingRight: theme.spacing(4),
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }))

export const ImageWithInfo: React.FC<ImageWithInfoProps> = (
  props: ImageWithInfoProps
) => {
  const { classes } = useStyles(props.backgroundOpacityPercentage)()

  const [imageInfoDisplayState, setImageInfoDisplayState] = useState<boolean>(
    props.informationsDisplayDefault
  )

  const getInfoRow = (key: string, value: string) => (
    <Grid container direction='row' className={classes.infoRow} key={key}>
      <Grid item>
        <Typography>{key}</Typography>
      </Grid>
      <Grid item>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  )

  const getSecondInfoRow = (key: string, value: string) => (
    <Grid container direction='row' className={classes.infoRow} key={key}>
      <Grid item>
        <Typography>{key}</Typography>
      </Grid>
      <Grid item>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  )

  const getImageRow = () => {
    if (props.url === '') {
      return <BrokenImage className={classes.img} />
    } else if (props.canvasInfos && props.canvasDisplayCondition) {
      return (
        <InferenceResultView
          imageHeight={props.imageHeight}
          isDialog={props.isDialog}
          url={props.url}
          canvasInfos={props.canvasInfos}
          canvasDisplayCondition={props.canvasDisplayCondition}
        />
      )
    } else {
      return <img src={props.url} className={classes.img} />
    }
  }

  return (
    <div
      className={classes.root}
      onClick={() =>
        setImageInfoDisplayState(
          props.informationsDisplayControlWithIcon
            ? false
            : !imageInfoDisplayState
        )
      }
    >
      {getImageRow()}
      <Grid
        container
        className={clsx(classes.info, {
          [classes.infoInvisible]: !imageInfoDisplayState,
        })}
      >
        {props.informations.map((info) => {
          return getInfoRow(info.key, info.value)
        })}
        {props.secondInformations && (
          <>
            {props.secondInformations && (
              <Grid container direction='row' className={classes.infoRow}>
                <Grid item>
                  <Typography>{props.secondInformations.label}</Typography>
                </Grid>
              </Grid>
            )}
            <Box ml={2}>
              {props.secondInformations.infos.map((info) => {
                return getSecondInfoRow(info.key, info.value)
              })}
            </Box>
          </>
        )}
      </Grid>
      {props.informationsDisplayControlWithIcon && (
        <Grid container className={classes.infoIcon}>
          <Box display='flex' justifyContent='flex-end'>
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                setImageInfoDisplayState(!imageInfoDisplayState)
              }}
            >
              <InfoOutlinedIcon sx={{ color: 'white' }} fontSize='small' />
            </IconButton>
          </Box>
        </Grid>
      )}
    </div>
  )
}
