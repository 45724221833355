import { Timestamp } from 'state/firebase'
import { TransactionStatusKind } from 'state/utils/types'

export const BuildListActionType = {
  SET_BUILD_LIST: 'SET_BUILD_LIST',
  SET_BUILD_DISPLAY_CONDITION: 'SET_BUILD_DISPLAY_CONDITION',
  CLEAR_BUILD_DISPLAY_CONDITION: 'CLEAR_BUILD_DISPLAY_CONDITION',
  CLEAR_BUILD_PAGING_STATE: 'CLEAR_BUILD_PAGING_STATE',
  SET_ML_PIPELINE_IDS_FOR_BUILD_LIST: 'SET_ML_PIPELINE_IDS_FOR_BUILD_LIST',
  SET_IN_PROGRESS_FOR_BUILD_LIST: 'SET_IN_PROGRESS_FOR_BUILD_LIST',
  SET_CURRENT_BUILD_LIST_SNAPSHOT: 'SET_CURRENT_BUILD_LIST_SNAPSHOT',
  CLEAR_BUILD_LIST_STATE: 'CLEAR_BUILD_LIST_STATE',
} as const

type TransferStatus =
  | 'Transferred'
  | 'Waiting'
  | 'NotTransfer'
  | 'Failed'
  | 'NotApplicable'

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

export interface Build {
  mlPipelineId: string
  mlPipelineName: string
  algorithmName: string
  progress: Progress
  startedAt: Timestamp
  endedAt?: Timestamp
  aiLibraryId: string
  aiLibraryVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
    build: number
  }
  transferStatus: TransferStatus
  accountId: string
}

export interface BuildDisplayCondition {
  algorithmId?: string
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface BuildListDomainData {
  currentBuildList: Build[]
  buildDisplayCondition: BuildDisplayCondition
  mlPipelineIds: string[]
  currentBuildListSnapshot?: () => void
}

export interface BuildListAppState {
  inProgress: boolean
}

export interface BuildListState {
  domainData: BuildListDomainData
  appState: BuildListAppState
}
