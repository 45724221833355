import { FeatureDataTransferringActions } from './actions'
import {
  FeatureDataTransferringActionType,
  FeatureDataTransferringState,
} from './types'

const initialState: FeatureDataTransferringState = {
  domainData: {
    selectedTrainingAlgorithm: undefined,
    featureDataGroups: [],
    featureDataList: undefined,
    selectedFeatureData: undefined,
    selectedFeatureDataGroup: undefined,
    mlPipelinesMetaData: {
      name: '',
      remarks: '',
    },
    executionInfo: undefined,
    featureDataGroupDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    featureDataDisplayCondition: {
      searchValue: '',
      sortKey: 'version',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    featureDataTransferringState: 'FeatureDataGroupState',
    featureDataTransferringSubState: {
      featureDataSubState: 'Unselected',
      featureDataGroupSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
  },
}

export const FeatureDataTransferringReducer = (
  state: FeatureDataTransferringState = initialState,
  action: FeatureDataTransferringActions
): FeatureDataTransferringState => {
  switch (action.type) {
    case FeatureDataTransferringActionType.SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedFeatureDataGroup: action.payload.selectedFeatureDataGroup,
        },
      }
    case FeatureDataTransferringActionType.SET_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedFeatureData: action.payload.selectedFeatureData,
        },
      }
    case FeatureDataTransferringActionType.SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
      if (action.payload.featureDataGroupDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            featureDataGroupDisplayCondition:
              action.payload.featureDataGroupDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }

    case FeatureDataTransferringActionType.SET_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
      if (action.payload.featureDataDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            featureDataDisplayCondition:
              action.payload.featureDataDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }

    case FeatureDataTransferringActionType.SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroups: action.payload.featureDataGroups,
        },
      }
    case FeatureDataTransferringActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }

    case FeatureDataTransferringActionType.CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupDisplayCondition: {
            ...initialState.domainData.featureDataGroupDisplayCondition,
          },
        },
      }

    case FeatureDataTransferringActionType.CLEAR_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataDisplayCondition: {
            ...initialState.domainData.featureDataDisplayCondition,
          },
        },
      }

    case FeatureDataTransferringActionType.SET_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataTransferringState:
            action.payload.featureDataTransferringSteps,
        },
      }
    case FeatureDataTransferringActionType.SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithm: {
            algorithmId: action.payload.selectedTrainingAlgorithmId,
          },
        },
      }
    case FeatureDataTransferringActionType.SET_CURRENT_FEATURE_DATA_GROUP_DETAIL_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataList: action.payload.currentFeatureDataGroupDetail,
        },
      }
    case FeatureDataTransferringActionType.SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelinesMetaData: action.payload.mlPipelinesMetaData,
        },
      }
    case FeatureDataTransferringActionType.SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataTransferringSubState: {
            ...state.appState.featureDataTransferringSubState,
            metaDataSubState: action.payload.metaDataSubState,
          },
        },
      }
    case FeatureDataTransferringActionType.SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataTransferringSubState: {
            ...state.appState.featureDataTransferringSubState,
            featureDataGroupSubState: action.payload.featureDataGroupSubState,
          },
        },
      }
    case FeatureDataTransferringActionType.SET_FEATURE_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataTransferringSubState: {
            ...state.appState.featureDataTransferringSubState,
            featureDataSubState: action.payload.featureDataSubState,
          },
        },
      }
    case FeatureDataTransferringActionType.CLEAR_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...initialState,
      }
    case FeatureDataTransferringActionType.EXEC_FEATURE_DATA_TRANSFERRING_SUCCESS_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataTransferringSubState: {
            ...state.appState.featureDataTransferringSubState,
            executeSubState: 'Executed',
          },
        },
      }
    case FeatureDataTransferringActionType.EXEC_FEATURE_DATA_TRANSFERRING_FAILURE_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataTransferringSubState: {
            ...state.appState.featureDataTransferringSubState,
            executeSubState: 'ExecuteError',
          },
        },
      }
    case FeatureDataTransferringActionType.SET_EXECUTED_INFO_FOR_FEATURE_DATA_TRANSFERRING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executionInfo: action.payload.executedInfo,
        },
      }
    default:
      return {
        ...state,
      }
  }
}
