import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import BrokenImage from '@mui/icons-material/BrokenImage'
import { getFormattedStringFromTimestamp } from 'views/components/utils'
import { TileProps } from './types'

const useStyles = makeStyles()((theme) => ({
  card: {
    display: 'inline-block',
    width: '100%',
    aspectRatio: '1 / 1',
  },
  main: {
    zIndex: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  thumbnailDiv: {
    width: '100%',
    minHeight: 0,
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
  media: {
    height: '100%',
    width: '100%',
  },
  content: {
    width: '100%',
    boxSizing: 'border-box',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  h6Div: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

export const Tile: React.FC<TileProps> = (props: TileProps) => {
  const { classes } = useStyles()

  return (
    <Card className={classes.card}>
      <CardActionArea
        component='div'
        onClick={() => props.onClickCard()}
        className={classes.main}
      >
        <div className={classes.thumbnailDiv}>
          {props.thumbnailUrl === '' ? (
            <BrokenImage className={classes.media} />
          ) : (
            <CardMedia
              component='img'
              image={props.thumbnailUrl}
              className={classes.media}
            />
          )}
        </div>
        <CardContent className={classes.content}>
          <Typography variant='h6' component='div' className={classes.h6Div}>
            {props.name}
          </Typography>

          <Typography
            gutterBottom
            variant='body2'
            color='textSecondary'
            component='div'
          >
            {getFormattedStringFromTimestamp(props.date)}
          </Typography>
          <Typography variant='h6' component='div' className={classes.h6Div}>
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
