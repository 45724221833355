import { Timestamp } from 'firebase/firestore'

export const getFormattedStringFromTimestamp = (
  timestamp?: Timestamp,
  format?: string
): string => {
  if (!timestamp) {
    console.warn('No timestamp!')
    return 'N/A'
  }
  const dateObj = timestamp.toDate()
  const baseStr = format || 'YYYY-MM-DD hh:mm'

  const toDoubleDigits = (num: number): string => `0${num}`.slice(-2)

  return baseStr
    .replace(/YYYY/, dateObj.getFullYear().toString())
    .replace(/MM/, toDoubleDigits(dateObj.getMonth() + 1))
    .replace(/DD/, toDoubleDigits(dateObj.getDate()))
    .replace(/hh/, toDoubleDigits(dateObj.getHours()))
    .replace(/mm/, toDoubleDigits(dateObj.getMinutes()))
    .replace(/ss/, toDoubleDigits(dateObj.getSeconds()))
}
