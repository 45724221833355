import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'

import { State } from 'state/store'
import {
  AdminFeatureDataListActions,
  adminFeatureDataListActions,
  adminFeatureDataListOperations,
} from 'state/ducks/adminFeatureDataList'

import {
  FeatureDataIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  GlobalLoading,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
} from 'views/components'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'

import { getUserGroupKindList } from 'views/containers/utils'
import { formatDateTimeSec } from 'views/components/utils/date'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {
  FeatureDataListDisplayCondition,
  FeatureDataInfo,
} from 'state/ducks/adminFeatureDataList/types'
import { CustomerAuthorizer } from 'views/components/organisms/customerAuthorizer'

const mapStateToProps = (state: State) => ({
  ...state.pages.adminFeatureDataListState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, AdminFeatureDataListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データ一覧取得 */
  getFeatureDataList: () =>
    dispatch(adminFeatureDataListOperations.getFeatureDataList()),
  /** 特徴量データ一覧をクリア */
  clearFeatureDataList: () =>
    dispatch(adminFeatureDataListActions.clearFeatureDataList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: FeatureDataListDisplayCondition) =>
    dispatch(
      adminFeatureDataListActions.setListDisplayCondition(listCondition)
    ),
  /** Stateのクリア */
  clearFeatureDataListState: () =>
    dispatch(adminFeatureDataListActions.clearFeatureDataListState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  explanation: {
    fontSize: theme.typography.pxToRem(18),
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'featureDataId',
    title: '特徴量データ ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureDataName',
    title: '特徴量データ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'feature-data-group-version',
    title: 'バージョン',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'featureDataGroupId',
    title: '特徴量データグループID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'generated-at',
    title: '生成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'generatedUser',
    title: '生成ユーザーID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const AdminFeatureDataList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const { classes } = useStyles()
  const globalTheme = useTheme()
  const history = useHistory()

  useEffect(() => {
    if (props.algorithms.length > 0) {
      selectedAlgorithm(
        props.algorithms.filter(
          (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
        )[0].algorithmId
      )
      return
    }
    console.error('the array "props.algorithms" is empty')

    return () => {
      props.clearFeatureDataListState()
    }
  }, [props.authedUser.auth.customClaims.userGroupId])

  useEffect(() => {
    return () => {
      props.clearFeatureDataListState()
    }
  }, [])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
    selectedUserGroupKind,
  } = tableActions(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.featureDataListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.featureDataListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.featureDataListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentFeatureDataList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentFeatureDataList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.featureDataListDisplayCondition,
    props.domainData.currentFeatureDataList,
  ])

  /** テーブルに表示する特徴量データのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: FeatureDataInfo[] = tableContent.map(
      (featureDataList: FeatureDataInfo) => {
        return {
          featureDataId: featureDataList.featureDataId,
          featureDataName: featureDataList.featureDataName,
          featureDataGroupVersion: {
            displayName: featureDataList.featureDataGroupVersion.displayName,
            major: featureDataList.featureDataGroupVersion.major,
            minor: featureDataList.featureDataGroupVersion.minor,
            patch: featureDataList.featureDataGroupVersion.patch,
          },
          featureDataGroupId: featureDataList.featureDataGroupId,
          generatedAt: featureDataList.generatedAt,
          uid: featureDataList.uid,
        }
      }
    )
    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataId') {
          return (
            <TooltipLink
              key={key}
              data-testid={`feature-data-${value}`}
              title={value}
              placement='right-start'
              onClick={() => {
                history.push(
                  `feature-data/${value}${
                    props.domainData.featureDataListDisplayCondition
                      .selectedUserGroupKind === 'UserGroup'
                      ? ''
                      : '?shared-user-group=true'
                  }`
                )
              }}
            />
          )
        } else if (key === 'featureDataGroupVersion') {
          if (value.displayName) {
            return (
              <Typography key={key} align='center'>
                v{value.displayName}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'featureDataGroupId') {
          if (value) {
            return (
              <TooltipLink
                key={key}
                title={value}
                placement='right-start'
                onClick={() => {
                  history.push(
                    `/feature-data-groups/${value}${
                      props.domainData.featureDataListDisplayCondition
                        .selectedUserGroupKind === 'UserGroup'
                        ? ''
                        : '?shared-user-group=true'
                    }`
                  )
                }}
                data-testid={`featureData-groups-${value}`}
              />
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'generatedAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'featureDataName') {
          if (value) {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value}</Typography>
              </Tooltip>
            )
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'uid') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else {
          return <Typography key={key}>{TABLE_CELL_NOT_APPLICABLE}</Typography>
        }
      })
    )
  }, [tableContent, props.domainData.featureDataListDisplayCondition])

  /** テーブル */
  const featureDataListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.featureDataListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.featureDataListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.featureDataListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.featureDataListDisplayCondition.sortKey,
          order: props.domainData.featureDataListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.featureDataListDisplayCondition,
    props.appState.inProgress,
  ])

  const userGroupKindList = getUserGroupKindList(
    props.authedUser.auth.customClaims.sharedList
  )

  return (
    <>
      <div className={classes.stepContainer}>
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <FeatureDataIcon
              className={classes.pageIcon}
              data-testid='featureDataListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='feature-data-list-title'>特徴量データ</h2>
            </Typography>
          </Box>
          <CustomerAuthorizer
            type='UPLOAD_MODEL'
            auth={props.userProfile?.role ?? ''}
          >
            <Box display='flex'>
              <CloudUploadIcon />
              <Link data-testid='feature-data-entry' to={`${url}/entry`}>
                <Typography>特徴量データアップロード</Typography>
              </Link>
            </Box>
          </CustomerAuthorizer>
        </div>
        <CustomTrainingPageParagraph>
          <FormControl
            variant='outlined'
            className={classes.algorithmSelectBox}
          >
            <InputLabel id='featureDataListUserGroupKind'>
              データ種別
            </InputLabel>
            <Select
              labelId='featureDataListUserGroupKind-label'
              id='featureDataListUserGroupKind-outlined'
              value={
                props.domainData.featureDataListDisplayCondition
                  .selectedUserGroupKind
              }
              onChange={(e) =>
                selectedUserGroupKind(
                  e.target.value as 'UserGroup' | 'SharedUserGroup'
                )
              }
              label='Select User Group Kind'
            >
              {userGroupKindList.map((kind) => (
                <MenuItem
                  data-testid={kind.kind}
                  value={kind.kind}
                  key={kind.kind}
                >
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <FormControl
            variant='outlined'
            className={classes.algorithmSelectBox}
          >
            <InputLabel id='featureDataListAlgorithm'>アルゴリズム</InputLabel>
            <Select
              labelId='featureDataListAlgorithm-label'
              id='featureDataListAlgorithm-outlined'
              value={
                props.domainData.featureDataListDisplayCondition
                  .selectedAlgorithmId
              }
              onChange={(e) => selectedAlgorithm(e.target.value as string)}
              label='Select Algorithm'
            >
              {props.algorithms
                .filter(
                  (algorithm) =>
                    algorithm.algorithmPurpose === 'TemplateMatching'
                )
                .map((algorithm) => {
                  return (
                    <MenuItem
                      data-testid={algorithm.algorithmId}
                      value={algorithm.algorithmId}
                      key={algorithm.algorithmId}
                    >
                      {algorithm.metadata.name.ja}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (特徴量データID)'
                value={
                  props.domainData.featureDataListDisplayCondition.searchValue
                }
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {featureDataListTable}
        </CustomTrainingPageParagraph>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const tableActions = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    props.clearFeatureDataList()
    const pageNumber =
      props.domainData.currentFeatureDataList.length >
      props.domainData.featureDataListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.featureDataListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentFeatureDataList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getFeatureDataList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearFeatureDataList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.featureDataListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getFeatureDataList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getFeatureDataList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearFeatureDataList()
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: 0,
    })

    props.getFeatureDataList()
  }

  /** アルゴリズムの選択 */
  const selectedAlgorithm = (algorithm: string) => {
    props.clearFeatureDataList()
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'generated-at',
      sortOrder: 'desc',
      selectedAlgorithmId: algorithm,
    })
    props.getFeatureDataList()
  }

  /** ユーザーグループ種別の選択 */
  const selectedUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearFeatureDataList()
    props.setListDisplayCondition({
      ...props.domainData.featureDataListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'generated-at',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getFeatureDataList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
    selectedUserGroupKind,
  }
}

export const AdminFeatureDataListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminFeatureDataList))
