import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'

const timeoutLimitSeconds = 15
const timeoutLimitSecondsForGetLogs = 30

export const CustomTrainingDetailApi = {
  getModelsFiles: (trainedModelId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-models',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      // userGroupId: userGroupId,
      trainedModelId: trainedModelId,
    }),
  getLogs: (mlPipelineId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-logs',
      {
        timeout: timeoutLimitSecondsForGetLogs * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
    }),
  downloadLog: (
    mlPipelineId: string,
    resourcePath: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-download-log',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
      resourcePath: resourcePath,
    }),
  getAnalyzeResults: (mlPipelineId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-analyze-results'
    )({
      mlPipelineId,
    }),
  getConfigs: (mlPipelineId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'custom-training-configs'
    )({
      mlPipelineId,
    }),
}
