// ActionType
export const PasswordUpdateActionType = {
  SET_TOAST_INFO_FOR_PASSWORD_UPDATE: 'SET_TOAST_INFO_FOR_PASSWORD_UPDATE',
  SET_IS_PASSWORD_UPDATED: 'SET_IS_PASSWORD_UPDATED',
  SET_IN_PROGRESS_FOR_PASSWORD_UPDATE: 'SET_IN_PROGRESS_FOR_PASSWORD_UPDATE',
  SET_NEED_MFA: 'SET_NEED_MFA',
  CLEAR_PASSWORD_UPDATE_STATE: 'CLEAR_PASSWORD_UPDATE_STATE',
} as const

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface PasswordUpdateAppState {
  toastInfo?: ToastInfo // トーストの表示する情報
  isPasswordUpdated: boolean // パスワードアップデート実行の可否
  inProgress: boolean // グローバルローディングの表示可否
  needMfa?: boolean
}
export interface PasswordUpdateState {
  appState: PasswordUpdateAppState
}
