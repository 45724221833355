import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  CurrentSceneCameraDetail,
  SceneCameraDetailActionType,
  SceneCameraDetailSubState,
} from './types'

export type SceneCameraDetailAction = ActionsUnion<
  typeof sceneCameraDetailActions
>

export const sceneCameraDetailActions = {
  setCurrentSceneCameraDetail: (
    currentSceneCameraDetail: CurrentSceneCameraDetail
  ): ActionWithPayload<
    'SET_CURRENT_SCENE_CAMERA_DETAIL',
    { currentSceneCameraDetail: CurrentSceneCameraDetail }
  > =>
    CreateAction(SceneCameraDetailActionType.SET_CURRENT_SCENE_CAMERA_DETAIL, {
      currentSceneCameraDetail,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_SCENE_CAMERA_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      SceneCameraDetailActionType.SET_IN_PROGRESS_FOR_SCENE_CAMERA_DETAIL,
      {
        inProgress,
      }
    ),
  clearSceneCameraDetailState: (): Action<'CLEAR_SCENE_CAMERA_DETAIL_STATE'> =>
    CreateAction(SceneCameraDetailActionType.CLEAR_SCENE_CAMERA_DETAIL_STATE),
  setSceneCameraDetailState: (
    sceneCameraDetailSubState: SceneCameraDetailSubState
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_DETAIL_SUB_STATE',
    { sceneCameraDetailSubState: SceneCameraDetailSubState }
  > =>
    CreateAction(
      SceneCameraDetailActionType.SET_SCENE_CAMERA_DETAIL_SUB_STATE,
      {
        sceneCameraDetailSubState,
      }
    ),
}
