import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

import { ActionDialogProps } from './types'

const useStyles = makeStyles()((theme) => ({
  contentBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  deleteButton: {
    border: `1px solid ${theme.palette.error}`,
    backgroundColor: '#fccccc',
    '&:hover': {
      backgroundColor: '#fccccc',
    },
  },
  cancelButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}))

export const ActionDialog: React.FC<ActionDialogProps> = (
  props: ActionDialogProps
) => {
  const { classes } = useStyles()

  const DialogContent = styled(MuiDialogContent)({
    root: {
      padding: useTheme().spacing(2),
    },
  })

  return (
    <div>
      <Dialog onClose={props.handleClose} open={props.open} fullWidth={true}>
        <Box m={2}>
          {props.title && <DialogTitle>{props.title}</DialogTitle>}
          <DialogContent>
            <Box textAlign='center'>{props.message}</Box>
          </DialogContent>
          <div className={classes.contentBtn}>
            <Button
              className={classes.cancelButton}
              onClick={props.handleClose}
              color={'inherit'}
            >
              {props.closeButtonText}
            </Button>
            <Button
              className={classes.deleteButton}
              onClick={() => {
                props.dialogAction()
                props.handleClose()
              }}
            >
              <Typography color='error'>{props.actionButtonText}</Typography>
            </Button>
          </div>
        </Box>
      </Dialog>
    </div>
  )
}
