import { AuthorizationResult } from 'types/StateTypes'
import { AuthorizationApi } from './apis'

export const AuthorizationOperations = {
  /** 認可チェック */
  authorizationApp: async (): Promise<AuthorizationResult | undefined> => {
    try {
      const result = await AuthorizationApi.authorizationApp()

      return result.data
    } catch (error) {
      console.error(error)
    }
  },
}
