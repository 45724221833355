import {
  Version,
  VersionWithPreRelease,
  VersionWithBuild,
} from 'types/StateTypes'

/** バージョン比較 */
export const compareVersions = (
  origin: Version,
  destination: Version,
  order?: 'asc' | 'desc'
): number => {
  const originVersionNumberList = [origin.major, origin.minor, origin.patch]
  const destinationVersionNumberList = [
    destination.major,
    destination.minor,
    destination.patch,
  ]

  return compare(originVersionNumberList, destinationVersionNumberList, order)
}

/** バージョン比較（preReleaseを含むバージョン） */
export const compareVersionsWithPreRelease = (
  origin: VersionWithPreRelease,
  destination: VersionWithPreRelease,
  order?: 'asc' | 'desc'
): number => {
  const originVersionNumberList = [
    origin.major,
    origin.minor,
    origin.patch,
    origin.preRelease,
  ]
  const destinationVersionNumberList = [
    destination.major,
    destination.minor,
    destination.patch,
    origin.preRelease,
  ]

  return compare(originVersionNumberList, destinationVersionNumberList, order)
}

/** バージョン比較（buildを含むバージョン） */
export const compareVersionsWithBuild = (
  origin: VersionWithBuild,
  destination: VersionWithBuild,
  order?: 'asc' | 'desc'
): number => {
  const originVersionNumberList = [
    origin.major,
    origin.minor,
    origin.patch,
    origin.build,
  ]
  const destinationVersionNumberList = [
    destination.major,
    destination.minor,
    destination.patch,
    destination.build,
  ]

  return compare(originVersionNumberList, destinationVersionNumberList, order)
}

const compare = (
  originVersionNumberList: number[],
  destinationVersionNumberList: number[],
  order?: 'asc' | 'desc'
): number => {
  // loop while the components are equal
  for (let i = 0; i < originVersionNumberList.length; i++) {
    // A bigger than B
    if (destinationVersionNumberList[i] > originVersionNumberList[i]) {
      return order ? (order === 'desc' ? 1 : -1) : 1
    }

    // B bigger than A
    if (destinationVersionNumberList[i] < originVersionNumberList[i]) {
      return order ? (order === 'desc' ? -1 : 1) : -1
    }
  }

  // If one's a prefix of the other, the longer one is greater.
  if (destinationVersionNumberList.length > originVersionNumberList.length) {
    return order ? (order === 'desc' ? 1 : -1) : 1
  }

  if (destinationVersionNumberList.length < originVersionNumberList.length) {
    return order ? (order === 'desc' ? -1 : 1) : -1
  }

  return 0
}

/**
 * 対象のバージョンが指定したバージョンの範囲内かチェックする
 * @param target 範囲内か確認したいバージョン
 * @param lower 範囲の下限のバージョン
 * @param upper 範囲の上限のバージョン
 * @returns `true`の場合、targetで指定したバージョンはlowerとupperの範囲内
 */
export const isInRange = (
  target: Version,
  lower: Version,
  upper: Version
): boolean => {
  if (target.major < lower.major || target.major > upper.major) return false

  if (target.major === lower.major && target.minor < lower.minor) return false
  if (target.major === upper.major && target.minor > upper.minor) return false

  if (
    target.major === lower.major &&
    target.minor === lower.minor &&
    target.patch < lower.patch
  )
    return false
  if (
    target.major === upper.major &&
    target.minor === upper.minor &&
    target.patch > upper.patch
  )
    return false

  return true
}

/**
 * 対象のバージョンが指定したバージョンの範囲内かチェックする(preRelease付き)
 * @param target 範囲内か確認したいバージョン
 * @param lower 範囲の下限のバージョン
 * @param upper 範囲の上限のバージョン
 * @returns `true`の場合、targetで指定したバージョンはlowerとupperの範囲内
 */
export const isInRangeWithPreRelease = (
  target: VersionWithPreRelease,
  lower: VersionWithPreRelease,
  upper: VersionWithPreRelease
): boolean => {
  if (target.major < lower.major || target.major > upper.major) return false

  if (target.major === lower.major && target.minor < lower.minor) return false
  if (target.major === upper.major && target.minor > upper.minor) return false

  if (
    target.major === lower.major &&
    target.minor === lower.minor &&
    target.patch < lower.patch
  )
    return false
  if (
    target.major === upper.major &&
    target.minor === upper.minor &&
    target.patch > upper.patch
  )
    return false

  if (
    target.major === lower.major &&
    target.minor === lower.minor &&
    target.patch === lower.patch &&
    target.preRelease < lower.preRelease
  )
    return false

  if (
    target.major === upper.major &&
    target.minor === upper.minor &&
    target.patch === upper.patch &&
    target.preRelease > upper.preRelease
  )
    return false

  return true
}
