import { AccountGroupDocument } from './types'
import { validateData, DataObject } from '../common/validations'

/** account-group documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as AccountGroupDocument
  const schema = {
    required: ['account-group-id', 'mfa-group-setting'],
    type: 'object',
    properties: {
      'account-group-id': {
        type: 'string',
      },
      'mfa-group-setting': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'account-group',
      value: data as unknown as DataObject,
    })
    return false
  }
}
