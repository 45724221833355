import { AugTextureRevision } from './types'

import { timeStampSchema, versionSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** aug-texture-revisions documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as AugTextureRevision
  const schema = {
    required: [
      'aug-texture-id',
      'aug-texture-revision',
      'config',
      'files',
      'storage-path',
    ],
    type: 'object',
    properties: {
      'aug-texture-id': {
        type: 'string',
      },
      'aug-texture-revision': {
        type: 'number',
      },
      config: {
        required: ['name', 'type'],
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          type: {
            type: 'string',
          },
          'format-version': {
            ...versionSchema,
          },
          extended: {
            type: 'object',
            properties: {},
            additionalProperties: true,
          },
        },
      },
      files: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      'storage-path': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'aug-texture-revision',
      value: data as unknown as DataObject,
    })
    return false
  }
}
