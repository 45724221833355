import {
  validatePassword as $validatePassword,
  Auth,
  PasswordValidationStatus,
} from 'firebase/auth'
import { decideEnvironment } from '../urls'

/**
 * パスワードポリシーを満たしているか確認する
 * @param auth FirebaseのAuthインスタンス
 * @param password 検査対象のパスワード
 */
export const validatePassword = async (
  auth: Auth,
  password: string
): Promise<PasswordValidationStatus | undefined> => {
  if (decideEnvironment() !== 'local') {
    // ローカルの場合はvalidatePasswordをエミュレーターが対応していないため実行しない
    const validationStatus = await $validatePassword(auth, password)
    return validationStatus
  }
}

/**
 * validatePasswordでエラーが発生した際のエラーメッセージを生成する
 * @param status validatePasswordの戻り値
 * @returns エラーメッセージの配列
 */
export const generateInvalidPasswordErrorMessage = (
  status: PasswordValidationStatus
): string[] => {
  const errorMessages: string[] = []

  if (status.meetsMinPasswordLength === false)
    errorMessages.push('12文字以上で設定してください')
  if (status.containsLowercaseLetter === false)
    errorMessages.push('英小文字が含まれていません')
  if (status.containsUppercaseLetter === false)
    errorMessages.push('英大文字が含まれていません')
  if (status.containsNumericCharacter === false)
    errorMessages.push('数字が含まれていません')
  if (status.containsNonAlphanumericCharacter === false)
    errorMessages.push('記号が含まれていません')

  return errorMessages
}
