import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'

export const RobotDataAnalysisEntryApi = {
  getCount: (params: {
    'execution-results': string[]
    'executed-at'?: {
      from?: string
      to?: string
    }
    'execution-id-list': string[]
    'robot-id-list': string[]
    'model-group-id-list': string[]
    'user-group-id-list': string[]
    confirmed: boolean
    extended: {
      'object-recognition': {
        'has-result': boolean
      }
      config: {
        'collection-count-threshold': number
      }
    }
  }): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'robot-execution-data-count'
    )({
      ...params,
    }),

  getExecutionDataList: (params: {
    'execution-results': string[]
    'executed-at'?: {
      from?: string
      to?: string
    }
    'execution-id-list': string[]
    'robot-id-list': string[]
    'model-group-id-list': string[]
    'user-group-id-list': string[]
    confirmed: boolean
    extended: {
      'object-recognition': {
        'has-result': boolean
      }
    }
  }): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'robot-execution-data-transforming'
    )({
      ...params,
    }),
}
