import { Dispatch } from 'redux'
import { adminFeatureDataListActions } from './'
import { FeatureDataInfo } from './types'
import { State } from 'state/store'
import { getFeatureDataQueriesCollection } from 'state/firebase'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataQueryDocument } from 'utils/fireStore/featureDataQuery'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { domainDataOperations } from 'state/app/domainData/operations'

// 特徴量データ一覧を生成
const createFeatureDataList = async (
  userGroupId: string,
  featureDataQuery: Query<DocumentData>
): Promise<(FeatureDataInfo | undefined)[]> =>
  await Promise.all(
    // 特徴量データリストの生成
    (
      await getDocs(featureDataQuery)
    ).docs.map(async (document: DocumentData) => {
      const featureDataQueryDocData = document.data()
      if (
        !fireStoreTypeGuardForFeatureDataQueryDocument(featureDataQueryDocData)
      ) {
        console.error(`FeatureDataQuery document ${document.id} is invalid`)
        return undefined
      }

      // 特徴量データ一覧を返す
      return {
        featureDataId: featureDataQueryDocData['feature-data-id'],
        featureDataName: featureDataQueryDocData
          ? featureDataQueryDocData['feature-data-name']
          : '',
        featureDataGroupVersion: {
          displayName:
            featureDataQueryDocData['feature-data-group-version'][
              'display-name'
            ],
          major: featureDataQueryDocData['feature-data-group-version']['major'],
          minor: featureDataQueryDocData['feature-data-group-version']['minor'],
          patch: featureDataQueryDocData['feature-data-group-version']['patch'],
        },
        featureDataGroupId: featureDataQueryDocData['feature-data-group-id'],
        generatedAt: featureDataQueryDocData['generated-at'] ?? undefined,
        uid: featureDataQueryDocData['created-by'],
      } as FeatureDataInfo
    })
  )

export const adminFeatureDataListOperations = {
  /** リストを取得する */
  getFeatureDataList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(adminFeatureDataListActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            adminFeatureDataListActions.setListDisplayCondition({
              ...getState().pages.adminFeatureDataListState.domainData
                .featureDataListDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.adminFeatureDataListState.domainData
            .featureDataListDisplayCondition.selectedUserGroupKind ===
          'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]

        // 表示条件取得
        const condition =
          getState().pages.adminFeatureDataListState.domainData
            .featureDataListDisplayCondition

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentFeatureDataList =
          getState().pages.adminFeatureDataListState.domainData
            .currentFeatureDataList

        const limitCount =
          condition.displayNumber * (condition.pageNumber + 1) -
          currentFeatureDataList.length

        let totalCountQuery =
          getState().pages.adminFeatureDataListState.domainData
            .featureDataListDisplayCondition.selectedUserGroupKind ===
          'UserGroup'
            ? query(
                getFeatureDataQueriesCollection(userGroupId),
                where('algorithm-id', '==', condition.selectedAlgorithmId)
              )
            : query(
                getFeatureDataQueriesCollection(userGroupId),
                where('algorithm-id', '==', condition.selectedAlgorithmId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        // featureDataを表示件数分取得
        let featureDataQuery = query(totalCountQuery, limit(limitCount))

        // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          featureDataQuery = query(
            featureDataQuery,
            orderBy('feature-data-id', 'asc'),
            where('feature-data-id', '>=', startCode),
            where('feature-data-id', '<=', endCode)
          )
        } else {
          if (condition.sortKey === 'feature-data-group-version') {
            featureDataQuery = query(
              featureDataQuery,
              orderBy('feature-data-group-version.major', condition.sortOrder),
              orderBy('feature-data-group-version.minor', condition.sortOrder),
              orderBy('feature-data-group-version.patch', condition.sortOrder)
            )
          } else {
            featureDataQuery = query(
              featureDataQuery,
              orderBy(condition.sortKey, condition.sortOrder)
            )
          }
        }

        // 既に取得していれば最後の要素から取得
        let lastItem: DocumentData | undefined = undefined
        if (currentFeatureDataList.length) {
          lastItem = await getDoc(
            doc(
              getFeatureDataQueriesCollection(userGroupId),
              currentFeatureDataList[currentFeatureDataList.length - 1]
                .featureDataId
            )
          )
          featureDataQuery = query(featureDataQuery, startAfter(lastItem))
        }

        // 特徴量データ一覧を取得
        const newFeatureDataList: (FeatureDataInfo | undefined)[] =
          await createFeatureDataList(userGroupId, featureDataQuery)
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          totalCountQuery = query(
            totalCountQuery,
            where('feature-data-id', '>=', startCode),
            where('feature-data-id', '<=', endCode)
          )
        }
        const totalCount = await getCountFromServer(totalCountQuery)

        dispatch(
          adminFeatureDataListActions.setListDisplayCondition({
            ...condition,
            totalCount: totalCount.data().count,
          })
        )

        dispatch(
          adminFeatureDataListActions.setFeatureDataList([
            ...currentFeatureDataList,
            ...(newFeatureDataList.filter(
              (item) => item !== undefined
            ) as FeatureDataInfo[]),
          ])
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(adminFeatureDataListActions.setInProgress(false))
      }
    },
}
