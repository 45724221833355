import React, { useState, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { FileListViewerProps } from './types'
import { AnnotationListItem } from 'views/components/molecules/annotationListItem'

const useStyles = (
  maxHeight: number | undefined,
  hideScrollBar: boolean | undefined
) =>
  makeStyles()((theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      maxHeight: maxHeight ? maxHeight : 300,
      overflow: 'auto',
      outline: 'solid 1px',
      position: 'relative',
      zIndex: 0,
      '&::-webkit-scrollbar': {
        // for Chrome, Edge
        display: hideScrollBar ? 'none' : undefined,
      },
      // for Firefox
      scrollbarWidth: hideScrollBar ? 'none' : undefined,
    },
    list: {
      clear: 'both',
      padding: 0,
    },
    stickyListItem: {
      position: 'sticky',
      zIndex: 1,
    },
  }))

export const FileListViewer: React.FC<FileListViewerProps> = (
  props: FileListViewerProps
) => {
  const { classes } = useStyles(props.maxHeight, props.hideScrollBar)()
  const [openArray, setOpenArray] = useState(false)

  const fileArray = useMemo(() => {
    if (props.files) {
      const fileNames: { name: string }[] = props.files.map((file) => {
        return {
          name: file.name,
        }
      })
      return fileNames
    }
    return []
  }, [props.files])

  return (
    <div className={classes.root}>
      <List aria-labelledby='nested-list-subheader' className={classes.list}>
        <div className={classes.stickyListItem}>
          <AnnotationListItem
            statusText={
              <Box>
                {props.listLabel}（{props.files.length}）
              </Box>
            }
            endIcon={
              props.files.length > 0 ? (
                openArray ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : undefined
            }
            onClick={() => setOpenArray(!openArray)}
            data-testid={'FileListViewerHeader'}
          />
        </div>
        <Collapse
          in={openArray}
          timeout='auto'
          unmountOnExit
          data-testid={'ImageListArrow'}
        >
          <List disablePadding>
            {fileArray.map((item, index) => (
              <AnnotationListItem
                key={index}
                nested
                statusText={<Box>{item.name}</Box>}
                data-testid={`file.name${index + 1}`}
              />
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  )
}
