import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { blue, green, grey, red } from '@mui/material/colors'
import MoreVert from '@mui/icons-material/MoreVert'
import { PhotoCamera } from 'views/components'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { isString } from 'utils/typeguard'

import { FileUploadViewerProps } from './types'

export const FileUploadViewer: React.FC<FileUploadViewerProps> = (
  props: FileUploadViewerProps
) => {
  const useStyles = makeStyles()((theme) => ({
    root: {
      display: 'block',
      width: '200px',
      height: '200px',
      border: 'solid 1px #333',
      position: 'relative',
    },
    iconPreview: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    deleteItem: { color: theme.palette.secondary.main },
    uploadIcon: {
      position: 'absolute',
      right: '-224px',
      top: '150px',
      borderRadius: '50%',
      display: 'block',
      width: theme.spacing(7),
      height: theme.spacing(7),
      background: '#fff',
      boxShadow: '0 10px 25px 0 rgba(0, 0, 0, .2)',
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
    },
    defaultDropzoneFrame: {
      borderColor: grey[500],
    },
    activeFileDialogDropzoneFrame: {
      borderColor: blue[500],
    },
    acceptedDropzoneFrame: {
      borderColor: green[500],
    },
    rejectedDropzoneFrame: {
      borderColor: red[500],
    },
    message: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      '&>*': {
        fontSize: '0.875em',
        color: grey[600],
        display: 'inline-block',
      },
      '&>button': {
        '&:hover': {
          color: blue[500] + '!important',
        },
      },
    },
    floatButtons: {
      zIndex: 1,
      position: 'absolute',
      color: theme.palette.common.black,
    },
    menuIcon: {
      top: theme.spacing(-1),
      right: theme.spacing(-8),
    },
  }))
  const { classes } = useStyles()

  /** fileのbase64文字列を保持 */
  const [fileToBase64, setFileToBase64] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(undefined)
  const inputRef = useRef<DropzoneRef>(null)

  /** メタデータのアイコン変更時に状態を変更 */
  useEffect(() => {
    if (!props.file) {
      return setFileToBase64('')
    }
    if (isString(props.file)) {
      return setFileToBase64(props.file)
    }
    const reader = new FileReader()
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e && e.target && e.target.result) {
        setFileToBase64(e.target.result as string)
      }
    }
    reader.readAsDataURL(props.file)
  }, [props.file])

  const selectContentsStyle = (
    isFileDialogActive: boolean,
    isDragAccept: boolean,
    isDragReject: boolean
  ) => {
    if (isDragAccept) {
      console.log('accept')
      return classes.acceptedDropzoneFrame
    } else if (isDragReject) {
      console.log('reject')
      return classes.rejectedDropzoneFrame
    } else if (isFileDialogActive) {
      return classes.activeFileDialogDropzoneFrame
    }
    return classes.defaultDropzoneFrame
  }

  return (
    <>
      <Box className={classes.root}>
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            handleClick(e)
          }}
          className={`${classes.floatButtons} ${classes.menuIcon}`}
          data-testid={`${props['data-testid']}-icon-menu`}
        >
          <MoreVert />
        </IconButton>
        <div
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.open()
                }
                handleClose()
              }}
              disabled={props.disabled}
              data-testid={`${props['data-testid']}-icon-menu-upload`}
            >
              アップロード
            </MenuItem>
            <MenuItem
              onClick={() => {
                props.fileDeleteAction()
                handleClose()
              }}
              className={classes.deleteItem}
              data-testid={`${props['data-testid']}-icon-menu-delete`}
              disabled={props.disabled || fileToBase64 === ''}
            >
              削除
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              data-testid={`${props['data-testid']}-icon-menu-cancel`}
            >
              キャンセル
            </MenuItem>
          </Menu>
        </div>
        {fileToBase64 !== '' && (
          <img src={fileToBase64} className={classes.iconPreview} />
        )}
        <Box display={fileToBase64 === '' ? undefined : 'none'}>
          <Dropzone
            ref={inputRef}
            onDropAccepted={(acceptedFiles) => {
              if (acceptedFiles !== undefined) {
                props.acceptFileOperation(acceptedFiles)
              }
            }}
            onDropRejected={(fileRejections) => {
              if (
                fileRejections !== undefined &&
                props.rejectedFileOperation !== undefined
              ) {
                props.rejectedFileOperation(fileRejections)
              }
            }}
            // fileRejectionsではじいたファイルを取得
            accept={props.fileType}
            maxSize={props.maxSize}
            noClick={true}
            noKeyboard={true}
            noDrag={props.disabled}
          >
            {({
              getRootProps,
              getInputProps,
              isFileDialogActive,
              isDragAccept,
              isDragReject,
              open,
            }) => (
              <div
                {...getRootProps()}
                className={selectContentsStyle(
                  isFileDialogActive,
                  isDragAccept,
                  isDragReject
                )}
              >
                <input {...getInputProps()} />
                <div className={classes.message}>
                  <div>ファイルをドロップ</div>
                  <div>&nbsp;or&nbsp;</div>
                  <Link
                    component='button'
                    underline='always'
                    onClick={open}
                    disabled={props.disabled}
                    data-testid={props['data-testid']}
                    color={'text.secondary'}
                  >
                    ファイルを選択
                  </Link>
                </div>
                <Link
                  component='button'
                  underline='always'
                  onClick={open}
                  disabled={props.disabled}
                  data-testid='select-file-link'
                >
                  <PhotoCamera
                    data-testid='photo-camera-icon'
                    className={classes.uploadIcon}
                  />
                </Link>
              </div>
            )}
          </Dropzone>
        </Box>
      </Box>
    </>
  )
}
