import { HttpsCallableResult } from 'firebase/functions'
import { doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { Dispatch } from 'redux'
import { State } from 'state/store'
import { modelGroupEntryActions } from './'

import { ModelGroupEntryApi } from './apis'
import { ClassSet, ToastInfo } from './types'
import { domainDataOperations } from 'state/app/domainData/operations'
import {
  getClassSetCollection,
  getClassSetMetaDataCollection,
  getUserGroupsCollection,
} from 'state/firebase'
import { isUndefined } from 'utils/typeguard'
import {
  ClassSetDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetDocument,
} from 'utils/fireStore/classSet'
import {
  ClassSetMetaDataDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaDataDocument,
} from 'utils/fireStore/classSetMetaData'

export const modelGroupEntryOperations = {
  createModelGroup:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupEntryActions.setInProgress(true))
        // アルゴリズムID
        const algorithmId =
          getState().pages.modelGroupEntryState.domainData.selectedAlgorithm ??
          ''
        // モデルグループ名
        const name =
          getState().pages.modelGroupEntryState.domainData.modelGroupMetaData
            .modelGroupName
        // 備考
        const remarks =
          getState().pages.modelGroupEntryState.domainData.modelGroupMetaData
            .remarks ?? ''
        // 概要
        const overview =
          getState().pages.modelGroupEntryState.domainData.modelGroupMetaData
            .overView ?? ''
        // アイコン
        const modelGroupIcon =
          getState().pages.modelGroupEntryState.domainData.modelGroupMetaData
            .modelGroupIcon
        // アイコンのファイル名
        const iconName = modelGroupIcon ? modelGroupIcon.name : ''
        // アイコンのファイルタイプ
        const iconFileType = modelGroupIcon ? modelGroupIcon.type : ''
        // モデルグループのモデル種別
        const modelKind =
          getState().pages.modelGroupEntryState.domainData
            .selectedTrainedModelKind
        if (!modelKind) {
          return
        }

        // 選択したクラスセット
        const selectedClassSet =
          getState().pages.modelGroupEntryState.domainData.selectedClassSet
        // アルゴリズム特有の付加情報
        const extended = selectedClassSet
          ? {
              objectClassification: {
                classSet: {
                  classSetId: selectedClassSet.classSetId,
                  userGroupId: selectedClassSet.userGroupId,
                },
              },
            }
          : undefined

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        // ドキュメントIDを取得
        const result = (await ModelGroupEntryApi.getDocumentId(
          userGroupId,
          'trained-model-groups'
        )) as HttpsCallableResult<{ documentId: string }>

        // 共有データからの判定
        const userGroupDoc = (
          await getDoc(doc(getUserGroupsCollection(), userGroupId))
        ).data()
        const isSharedUserGroup = !!(
          userGroupDoc && userGroupDoc['access-control']?.['is-shared'] === true
        )

        // アイコンがある場合GCSにアップロード
        if (modelGroupIcon) {
          const response = (await ModelGroupEntryApi.getFileUploadSignedUrl(
            result.data.documentId,
            iconName
          )) as HttpsCallableResult<{ url: string }>
          try {
            // アップロード処理
            // 成功時にドキュメントを作成する
            // 失敗時にアップロードしたiconを削除する
            await ModelGroupEntryApi.executeFileUpload(
              response.data.url,
              modelGroupIcon
            )
            try {
              // ドキュメント作成
              await ModelGroupEntryApi.createModelGroup({
                trainedModelGroupId: result.data.documentId,
                algorithmId,
                name,
                modelKind,
                remarks,
                overview,
                iconName,
                iconFileType,
                extended,
                isSharedUserGroup,
              })
              dispatch(
                modelGroupEntryActions.setExecutedModeGroupId(
                  result.data.documentId
                )
              )
              dispatch(modelGroupEntryActions.setExecuteSubState('Executed'))
            } catch (error) {
              // ドキュメント作成失敗時処理
              console.error(error)
              const toastInfo: ToastInfo = {
                type: 'error',
                title: 'ドキュメントの作成に失敗しました。',
                targets: [],
              }
              dispatch(modelGroupEntryActions.setToastInfo(toastInfo))
              // アップロードしたiconを削除する
              const deleteUrl = (await ModelGroupEntryApi.getDeleteSignedUrl(
                result.data.documentId,
                iconName
              )) as HttpsCallableResult<{ url: string }>
              await ModelGroupEntryApi.deleteUploadedIcon(deleteUrl.data.url)
            }
          } catch (error) {
            // アップロード失敗処理
            console.error(error)
            const toastInfo: ToastInfo = {
              type: 'error',
              title: 'アップロードに失敗しました。',
              targets: [],
            }
            dispatch(modelGroupEntryActions.setToastInfo(toastInfo))
          }
        } else {
          try {
            // iconのアップロードがない場合
            // ドキュメント作成
            await ModelGroupEntryApi.createModelGroup({
              trainedModelGroupId: result.data.documentId,
              algorithmId,
              name,
              modelKind,
              remarks,
              overview,
              iconName,
              extended,
              isSharedUserGroup,
            })
            dispatch(
              modelGroupEntryActions.setExecutedModeGroupId(
                result.data.documentId
              )
            )
            dispatch(modelGroupEntryActions.setExecuteSubState('Executed'))
          } catch (error) {
            // ドキュメント作成失敗時処理
            console.error(error)
            const toastInfo: ToastInfo = {
              type: 'error',
              title: 'モデルグループの作成に失敗しました。',
              targets: [],
            }
            dispatch(modelGroupEntryActions.setToastInfo(toastInfo))
          }
        }
      } catch (error) {
        console.error(error)
        const toastInfo: ToastInfo = {
          type: 'error',
          title: 'モデルグループの作成に失敗しました。',
          targets: [],
        }
        dispatch(modelGroupEntryActions.setToastInfo(toastInfo))
      } finally {
        dispatch(modelGroupEntryActions.setInProgress(false))
      }
    },

  getClassSets:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupEntryActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            modelGroupEntryActions.setClassSetDisplayCondition({
              ...getState().pages.modelGroupEntryState.domainData
                .classSetDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.modelGroupEntryState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : domainDataOperations.getSharedUserGroupId()(dispatch, getState)

        const getClassSetListQuery =
          getState().pages.modelGroupEntryState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetCollection(userGroupId))
            : query(
                getClassSetCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        const classSetDataListDocs = (await getDocs(getClassSetListQuery)).docs
        const classSetDataList: ClassSetDocument[] = classSetDataListDocs
          .map((classSetDoc) => {
            const classSetData = classSetDoc.data()

            if (!fireStoreTypeGuardForClassSetDocument(classSetData)) {
              return
            }

            return classSetData as ClassSetDocument
          })
          .filter(
            (classSetData) => !isUndefined(classSetData)
          ) as ClassSetDocument[]

        if (!classSetDataList) return

        const getClassSetMetaDataListQuery =
          getState().pages.modelGroupEntryState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetMetaDataCollection(userGroupId))
            : query(
                getClassSetMetaDataCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
        const classSetMetaDataList: ClassSetMetaDataDocument[] = (
          await getDocs(getClassSetMetaDataListQuery)
        ).docs
          .map((doc) => {
            const classSetMetaDataData = doc.data()

            if (
              !fireStoreTypeGuardForClassSetMetaDataDocument(
                classSetMetaDataData
              )
            ) {
              return
            }

            return classSetMetaDataData as ClassSetMetaDataDocument
          })
          .filter(
            (classSetMetaData) => !isUndefined(classSetMetaData)
          ) as ClassSetMetaDataDocument[]

        const classSetList: ClassSet[] = classSetDataList.map(
          (classSetData) => {
            const classSetMetaData = classSetMetaDataList.find(
              (item) => item['class-set-id'] === classSetData['class-set-id']
            )
            return {
              classSetId: classSetData['class-set-id'],
              classSetName: classSetMetaData?.name ?? '',
              classSetRemarks: classSetMetaData?.remarks ?? '',
              classList: classSetData['class-list'],
              createdAt: classSetData['created-at'],
              userGroupId: classSetData['user-group-id'],
            }
          }
        )

        dispatch(modelGroupEntryActions.setClassSets(classSetList))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelGroupEntryActions.setInProgress(false))
      }
    },

  setSelectedClassSet:
    (classSet?: ClassSet) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(modelGroupEntryActions.setSelectedClassSet(classSet))

      if (classSet) {
        dispatch(modelGroupEntryActions.setClassSetSubState('Selected'))
      } else {
        dispatch(modelGroupEntryActions.setClassSetSubState('Unselected'))
      }
    },
}
