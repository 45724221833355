import { Dispatch } from 'redux'
import {
  getAccountSettingCollection,
  getDatasetQueryCollection,
  getMlPipelineQueriesCollection,
  getAug3DSceneBackgroundsCollection,
  getAug3DSceneCamerasCollection,
  getAug3dSceneLightGroupsCollection,
  getAugSIObjectLayoutsCollection,
  getAugTexturesCollection,
  getAug3DObjectsCollection,
  getAugTextureRevisionsCollection,
} from 'state/firebase'
import { State } from 'state/store'
import { datasetAugmentationDetailActions } from './'
import {
  MlPipelineLogFile,
  TargetDocument,
  ThreeDimensionalData,
  SceneBackGroundDocument,
  SceneCameraDocument,
  SceneLightGroupDocument,
} from './types'
import { DatasetAugmentationDetailApi } from './apis'
import { convertProgressRateByTransactionStatusForDatasetAugmentationList } from 'state/utils'
import { isGetLogResponse } from 'state/utils/typeguard'
import { GetLogResponse } from 'state/utils/types'
import { doc, getDoc, where, query, getDocs } from 'firebase/firestore'
import { HttpsCallableResult } from 'firebase/functions'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetAugmentationMLPipelineQueryDocument } from 'utils/fireStore/datasetAugmentationMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneBackgroundDocument } from 'utils/fireStore/aug3dSceneBackground'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneCameraDocument } from 'utils/fireStore/aug3dSceneCamera'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3dSceneLightGroupDocument } from 'utils/fireStore/aug3dSceneLightGroup'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForAugSIObjectLayoutDocument } from 'utils/fireStore/augSIObjectLayout'
import { fireStoreTypeGuard as fireStoreTypeGuardForAug3DObjectDocument } from 'utils/fireStore/aug3dObject'
import { fireStoreTypeGuard as fireStoreTypeGuardForAugTextureDocument } from 'utils/fireStore/augTexture'
import { fireStoreTypeGuard as fireStoreTypeGuardForAugTextureRevisionDocument } from 'utils/fireStore/augTextureRevision'
import saveAs from 'file-saver'
import { domainDataOperations } from 'state/app/domainData/operations'

/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    datasetAugmentationDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

export const datasetAugmentationDetailOperations = {
  /** データを取得する */
  getDatasetAugmentationDetail:
    (mlPipelineId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetAugmentationDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        /** mlPipelineのデータ */
        const mlPipeline = await getDoc(
          doc(getMlPipelineQueriesCollection(userGroupId), mlPipelineId)
        )
        const mlPipelineData = mlPipeline.data()

        if (
          !fireStoreTypeGuardForDatasetAugmentationMLPipelineQueryDocument(
            mlPipelineData
          ) ||
          !mlPipelineData
        ) {
          dispatch(
            datasetAugmentationDetailActions.setDatasetAugmentationDetailState({
              ...getState().pages.datasetAugmentationDetailState.appState
                .datasetAugmentationDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        let datasetQueryData

        /** データセットのデータ */
        if (
          mlPipelineData?.['augmentation-step']?.['dest']?.['results']?.[0]?.[
            'dataset-id'
          ]
        ) {
          datasetQueryData = (
            await getDoc(
              doc(
                getDatasetQueryCollection(userGroupId),
                mlPipelineData
                  ? mlPipelineData['augmentation-step']['dest']['results'][0][
                      'dataset-id'
                    ]
                  : ''
              )
            )
          ).data()
        }

        if (
          datasetQueryData &&
          !fireStoreTypeGuardForDatasetQueryDocument(datasetQueryData)
        ) {
          dispatch(
            datasetAugmentationDetailActions.setDatasetAugmentationDetailState({
              ...getState().pages.datasetAugmentationDetailState.appState
                .datasetAugmentationDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                mlPipelineData ? mlPipelineData['created-by'] : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(
            datasetAugmentationDetailActions.setDatasetAugmentationDetailState({
              ...getState().pages.datasetAugmentationDetailState.appState
                .datasetAugmentationDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        const threeDimensionalData: ThreeDimensionalData[] = []

        await Promise.all(
          mlPipelineData &&
            mlPipelineData['augmentation-step']['src']['targets'].map(
              async (target: TargetDocument) => {
                const augObjectDoc = await getDoc(
                  doc(
                    getAug3DObjectsCollection(
                      target['3d-object']['user-group-id']
                    ),
                    target['3d-object']['aug-3d-object-id']
                  )
                )

                const augObjectDocData = augObjectDoc.data()

                if (
                  !fireStoreTypeGuardForAug3DObjectDocument(augObjectDocData) ||
                  !augObjectDocData
                ) {
                  return
                }

                const augTextureDocData =
                  target.texture['user-group-id'] === sharedUserGroupId
                    ? (
                        await getDocs(
                          query(
                            getAugTexturesCollection(
                              target.texture['user-group-id']
                            ),
                            where(
                              'aug-texture-id',
                              '==',
                              target['texture']['aug-texture-id']
                            ),
                            where('access-control.is-shared', '==', true),
                            where(
                              'access-control.share-permissions.webapp',
                              '==',
                              'list'
                            )
                          )
                        )
                      ).docs.map((textureDoc) => textureDoc.data())[0]
                    : (
                        await getDoc(
                          doc(
                            getAugTexturesCollection(
                              target.texture['user-group-id']
                            ),
                            target['texture']['aug-texture-id']
                          )
                        )
                      ).data()

                if (
                  !fireStoreTypeGuardForAugTextureDocument(augTextureDocData) ||
                  !augTextureDocData
                ) {
                  return
                }

                const augTextureRevisionsDoc = await getDoc(
                  doc(
                    getAugTextureRevisionsCollection(
                      target.texture['user-group-id'],
                      target['texture']['aug-texture-id']
                    ),
                    '0'
                  )
                )

                const augTextureRevisionsDocData = augTextureRevisionsDoc.data()

                if (
                  !fireStoreTypeGuardForAugTextureRevisionDocument(
                    augTextureRevisionsDocData
                  ) ||
                  !augTextureRevisionsDocData
                ) {
                  return
                }

                threeDimensionalData.push({
                  fileName: augObjectDocData['file-name'],
                  aug3dObjectId: target['texture']['aug-texture-id'],
                  label: {
                    id: target.label['label-id'],
                    name: target.label.name,
                    category: target.label.supercategory,
                  },
                  texture: {
                    id: target.texture['aug-texture-id'],
                    name: augTextureDocData['is-saved']
                      ? augTextureDocData['name']
                      : augTextureRevisionsDocData['files'][0],
                    overview: augTextureDocData['overview'],
                    isSaved: augTextureDocData['is-saved'],
                  },
                })
              }
            )
        )

        const backgrounds: {
          id: string
          name: string
          userGroupId: string
        }[] = []

        await Promise.all(
          mlPipelineData &&
            mlPipelineData['augmentation-step']['src']['3d-scene'][
              'backgrounds'
            ].map(async (background: SceneBackGroundDocument) => {
              const backgroundDocData =
                background['user-group-id'] === sharedUserGroupId
                  ? (
                      await getDocs(
                        query(
                          getAug3DSceneBackgroundsCollection(
                            background['user-group-id']
                          ),
                          where(
                            'aug-3d-scene-background-id',
                            '==',
                            background['aug-3d-scene-background-id']
                          ),
                          where('access-control.is-shared', '==', true),
                          where(
                            'access-control.share-permissions.webapp',
                            '==',
                            'list'
                          )
                        )
                      )
                    ).docs.map((backgroundDoc) => backgroundDoc.data())[0]
                  : (
                      await getDoc(
                        doc(
                          getAug3DSceneBackgroundsCollection(
                            background['user-group-id']
                          ),
                          background['aug-3d-scene-background-id']
                        )
                      )
                    ).data()

              if (
                !fireStoreTypeGuardForAug3dSceneBackgroundDocument(
                  backgroundDocData
                ) ||
                !backgroundDocData
              ) {
                return
              }

              backgrounds.push({
                id: backgroundDocData['aug-3d-scene-background-id'],
                name: backgroundDocData['name'],
                userGroupId: background['user-group-id'],
              })
            })
        )

        const cameras: {
          id: string
          name: string
          userGroupId: string
        }[] = []

        await Promise.all(
          mlPipelineData &&
            mlPipelineData['augmentation-step']['src']['3d-scene'][
              'cameras'
            ].map(async (camera: SceneCameraDocument) => {
              const cameraDocData =
                camera['user-group-id'] === sharedUserGroupId
                  ? (
                      await getDocs(
                        query(
                          getAug3DSceneCamerasCollection(
                            camera['user-group-id']
                          ),
                          where(
                            'aug-3d-scene-camera-id',
                            '==',
                            camera['aug-3d-scene-camera-id']
                          ),
                          where('access-control.is-shared', '==', true),
                          where(
                            'access-control.share-permissions.webapp',
                            '==',
                            'list'
                          )
                        )
                      )
                    ).docs.map((backgroundDoc) => backgroundDoc.data())[0]
                  : (
                      await getDoc(
                        doc(
                          getAug3DSceneCamerasCollection(
                            camera['user-group-id']
                          ),
                          camera['aug-3d-scene-camera-id']
                        )
                      )
                    ).data()

              if (
                !fireStoreTypeGuardForAug3dSceneCameraDocument(cameraDocData) ||
                !cameraDocData
              ) {
                return undefined
              }

              cameras.push({
                id: cameraDocData['aug-3d-scene-camera-id'],
                name: cameraDocData['name'],
                userGroupId: camera['user-group-id'],
              })
            })
        )

        const lightGroups: {
          id: string
          name: string
          userGroupId: string
        }[] = []

        await Promise.all(
          mlPipelineData &&
            mlPipelineData['augmentation-step']['src']['3d-scene'][
              'lights'
            ].map(async (lightGroup: SceneLightGroupDocument) => {
              const lightGroupDocData =
                lightGroup.groups[0]['user-group-id'] === sharedUserGroupId
                  ? (
                      await getDocs(
                        query(
                          getAug3dSceneLightGroupsCollection(
                            lightGroup.groups[0]['user-group-id']
                          ),
                          where(
                            'aug-3d-scene-light-group-id',
                            '==',
                            lightGroup['aug-3d-scene-light-group-id']
                          ),
                          where('access-control.is-shared', '==', true),
                          where(
                            'access-control.share-permissions.webapp',
                            '==',
                            'list'
                          )
                        )
                      )
                    ).docs.map((backgroundDoc) => backgroundDoc.data())[0]
                  : (
                      await getDoc(
                        doc(
                          getAug3dSceneLightGroupsCollection(
                            lightGroup.groups[0]['user-group-id']
                          ),
                          lightGroup['aug-3d-scene-light-group-id']
                        )
                      )
                    ).data()

              if (
                !fireStoreTypeGuardForAug3dSceneLightGroupDocument(
                  lightGroupDocData
                ) ||
                !lightGroupDocData
              ) {
                return undefined
              }

              lightGroups.push({
                id: lightGroupDocData['aug-3d-scene-light-group-id'],
                name: lightGroupDocData['metadata']['name'],
                userGroupId: lightGroup['groups'][0]['user-group-id'],
              })
            })
        )

        const layout =
          mlPipelineData['augmentation-step']['src']['placement']['layouts'][0]

        const layoutData =
          layout['user-group-id'] === sharedUserGroupId
            ? (
                await getDocs(
                  query(
                    getAugSIObjectLayoutsCollection(layout['user-group-id']),
                    where(
                      'aug-si-object-layout-id',
                      '==',
                      layout['aug-si-object-layout-id']
                    ),
                    where('access-control.is-shared', '==', true),
                    where(
                      'access-control.share-permissions.webapp',
                      '==',
                      'list'
                    )
                  )
                )
              ).docs.map((layoutDoc) => layoutDoc.data())[0]
            : (
                await getDoc(
                  doc(
                    getAugSIObjectLayoutsCollection(layout['user-group-id']),
                    layout['aug-si-object-layout-id']
                  )
                )
              ).data()

        if (
          !fireStoreTypeGuardForAugSIObjectLayoutDocument(layoutData) ||
          !layoutData
        ) {
          dispatch(
            datasetAugmentationDetailActions.setDatasetAugmentationDetailState({
              ...getState().pages.datasetAugmentationDetailState.appState
                .datasetAugmentationDetailState,
              mlPipelineDataState: 'Failed',
            })
          )
          return
        }

        const datasetTemplateId =
          mlPipelineData['augmentation-step']['src']['dataset']['format'][
            'dataset-template-id'
          ]

        const datasetTemplate = getState().app.domainData.datasetTemplates.find(
          (template) => template.datasetTemplateId === datasetTemplateId
        )

        dispatch(
          datasetAugmentationDetailActions.setCurrentDatasetAugmentationDetail({
            mlPipelineId: mlPipelineData
              ? mlPipelineData['ml-pipeline']['ml-pipeline-id']
              : '',
            mlPipelineName: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['name']
              : '',
            mlPipelineRemarks: mlPipelineData
              ? mlPipelineData['ml-pipeline-metadata']['remarks']
              : '',
            progress: {
              transactionStatus: mlPipelineData
                ? mlPipelineData['ml-pipeline']['transaction-status']
                : '',
              progressRate:
                convertProgressRateByTransactionStatusForDatasetAugmentationList(
                  mlPipelineData
                    ? mlPipelineData['ml-pipeline']['transaction-status']
                    : ''
                ),
            },
            startedAt: mlPipelineData
              ? mlPipelineData['ml-pipeline']['started-at']
              : '',
            endedAt:
              mlPipelineData &&
              mlPipelineData['ml-pipeline']['ended-at'] &&
              mlPipelineData['ml-pipeline']['ended-at'].seconds === 0
                ? undefined
                : mlPipelineData
                ? mlPipelineData['ml-pipeline']['ended-at']
                : '',
            '3dData': threeDimensionalData,
            renderersSettings: {
              augmentationNumber: mlPipelineData
                ? backgrounds.length *
                  cameras.length *
                  lightGroups.length *
                  mlPipelineData['augmentation-step']['src']['renderers'][0][
                    'augmentation-number'
                  ]
                : 0,
              size: {
                width: mlPipelineData
                  ? mlPipelineData['augmentation-step']['src']['renderers'][0][
                      'size'
                    ]['width']
                  : '',
                height: mlPipelineData
                  ? mlPipelineData['augmentation-step']['src']['renderers'][0][
                      'size'
                    ]['height']
                  : '',
              },
              depth: {
                min: mlPipelineData
                  ? mlPipelineData['augmentation-step']['src']['renderers'][0][
                      'depth'
                    ]['min']
                  : '',
                max: mlPipelineData
                  ? mlPipelineData['augmentation-step']['src']['renderers'][0][
                      'depth'
                    ]['max']
                  : '',
              },
            },
            dataset: {
              datasetId: datasetQueryData ? datasetQueryData['dataset-id'] : '',
              datasetName: datasetQueryData
                ? datasetQueryData['dataset-name']
                : '',
            },
            placement: {
              seed: mlPipelineData
                ? mlPipelineData['augmentation-step']['src']['placement'][
                    'layouts'
                  ][0]['seed']
                : 0,
              layout: {
                id: mlPipelineData
                  ? mlPipelineData['augmentation-step']['src']['placement'][
                      'layouts'
                    ][0]['aug-si-object-layout-id']
                  : '',
                name: layoutData['name'],
              },
            },
            outputFormat: {
              datasetTemplateName: datasetTemplate?.metadata.name.ja ?? '',
              trainValidRatio:
                mlPipelineData['augmentation-step']['src']['dataset'][
                  'extended'
                ]?.['train-valid']?.['ratio'],
            },
            scene: {
              backgrounds: backgrounds,
              cameras: cameras,
              lightGroups: lightGroups,
            },
            createdBy: accountSetting
              ? {
                  firstName: accountSetting['first-name'] ?? '',
                  familyName: accountSetting['family-name'] ?? '',
                }
              : mlPipelineData
              ? mlPipelineData['created-by']
              : '',
          })
        )
        dispatch(
          datasetAugmentationDetailActions.setDatasetAugmentationDetailState({
            ...getState().pages.datasetAugmentationDetailState.appState
              .datasetAugmentationDetailState,
            mlPipelineDataState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          datasetAugmentationDetailActions.setDatasetAugmentationDetailState({
            ...getState().pages.datasetAugmentationDetailState.appState
              .datasetAugmentationDetailState,
            mlPipelineDataState: 'Failed',
          })
        )
      } finally {
        dispatch(datasetAugmentationDetailActions.setInProgress(false))
      }
    },
  /** ファイルデータの取得 */
  getFileData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const requestMlPipelineId =
          getState().pages.datasetAugmentationDetailState.domainData
            .currentDatasetAugmentationDetail?.mlPipelineId

        const currentDatasetAugmentationDetail =
          getState().pages.datasetAugmentationDetailState.domainData
            .currentDatasetAugmentationDetail
        if (!currentDatasetAugmentationDetail) {
          return
        }
        let logItems: MlPipelineLogFile[] = []

        // ログファイル用キャッチ
        try {
          const logResponse = await DatasetAugmentationDetailApi.getLogs(
            requestMlPipelineId ? requestMlPipelineId : ''
          )
          if (isGetLogResponse(logResponse)) {
            logItems = (logResponse as GetLogResponse).data.items.map(
              (log) => ({
                fileName: log.fileNameForDownload,
                fileLink: log.name,
                fileSize: log.size,
                createdAt: new Date(log.createdAt),
              })
            )

            dispatch(
              datasetAugmentationDetailActions.setDatasetAugmentationDetailState(
                {
                  ...getState().pages.datasetAugmentationDetailState.appState
                    .datasetAugmentationDetailState,
                  mlPipelineLogSubState: 'Loaded',
                }
              )
            )
          }
        } catch {
          dispatch(
            datasetAugmentationDetailActions.setDatasetAugmentationDetailState({
              ...getState().pages.datasetAugmentationDetailState.appState
                .datasetAugmentationDetailState,
              mlPipelineLogSubState: 'Failed',
            })
          )
        }

        const currentMlPipelineId =
          getState().pages.datasetAugmentationDetailState.domainData
            .currentDatasetAugmentationDetail?.mlPipelineId
        if (requestMlPipelineId === currentMlPipelineId) {
          dispatch(
            datasetAugmentationDetailActions.setMlPipelineLogFiles(logItems)
          )
        }
      } catch (error) {
        console.error(error)
      }
    },
  /** ログファイルのダウンロード */
  logFileDownload:
    (mlPipelineId: string, logFiles: MlPipelineLogFile[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(datasetAugmentationDetailActions.setInProgress(true))

        // DL失敗の画像のリスト
        const failedLogFileList: string[] = []

        await Promise.all(
          logFiles.map(async (logFile) => {
            try {
              const res = (await DatasetAugmentationDetailApi.downloadLog(
                mlPipelineId,
                logFile.fileLink
              )) as HttpsCallableResult<{ blob: BlobPart; type: string }>
              const blob = new Blob([res.data.blob], {
                type: res.data.type,
              })
              saveAs(blob, logFile.fileName)
            } catch {
              failedLogFileList.push(logFile.fileName)
            }
          })
        )

        // 失敗した画像があれば、Storeに保持
        if (failedLogFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedLogFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetAugmentationDetailActions.setInProgress(false))
      }
    },
}
