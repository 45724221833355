import { Timestamp } from 'state/firebase'
import { TransactionStatusKind } from 'state/utils/types'

/** 特徴量データ転送指示一覧画面のアクション */
export const FeatureDataTransferringListActionType = {
  /** 特徴量データ転送指示一覧 */
  SET_FEATURE_DATA_TRANSFERRING_LIST: 'SET_FEATURE_DATA_TRANSFERRING_LIST',

  /** 特徴量データ転送指示一覧の表示条件 */
  SET_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION:
    'SET_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION',

  /** 特徴量データ転送指示一覧の表示条件 */
  CLEAR_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION:
    'CLEAR_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION',

  /** 特徴量データ転送指示一覧のページ状態 */
  CLEAR_FEATURE_DATA_TRANSFERRING_PAGING_STATE:
    'CLEAR_FEATURE_DATA_TRANSFERRING_PAGING_STATE',

  /** 特徴量データ転送指示一覧のML Pipeline IDを設定する */
  SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_TRANSFERRING_LIST:
    'SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_TRANSFERRING_LIST',

  /** 特徴量データ転送指示一覧画面のロード中フラグを設定する */
  SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING_LIST:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING_LIST',

  SET_CURRENT_FEATURE_DATA_TRANSFERRING_LIST_SNAPSHOT:
    'SET_CURRENT_FEATURE_DATA_TRANSFERRING_LIST_SNAPSHOT',

  /** 特徴量データ転送指示一覧のデータを削除する */
  CLEAR_FEATURE_DATA_TRANSFERRING_LIST_STATE:
    'CLEAR_FEATURE_DATA_TRANSFERRING_LIST_STATE',
} as const

/**
 * 進捗
 */
interface Progress {
  /** 進捗名 */
  transactionStatus: TransactionStatusKind
  /** 進捗率 */
  progressRate: number
}

/**
 * 特徴量データ情報
 */
interface FeatureData {
  /** 特徴量データID */
  featureDataId: string
  /** 特徴量データ名 */
  featureDataName: string
}

/**
 * 特徴量データ転送指示データ
 */
export interface FeatureDataTransferring {
  /** ML Pipeline ID */
  mlPipelineId: string
  /** 特徴量データ転送指示名 */
  mlPipelineName: string
  /** 種別 */
  algorithmName: string
  /** 進捗 */
  progress: Progress
  /** 開始日時 */
  startedAt: Timestamp
  /** 終了日時 */
  endedAt?: Timestamp
  /** 転送モデル */
  featureData: FeatureData
  /** 実行ユーザーID */
  accountId: string
}

/**
 * 特徴量データ転送指示一覧の表示条件
 */
export interface FeatureDataTransferringDisplayCondition {
  /** 検索文字列 */
  searchValue: string
  /** ソートする列のキー */
  sortKey: string
  /** ソート順 */
  sortOrder: 'asc' | 'desc'
  /** 一覧に表示するデータ数 */
  displayNumber: number
  /** 表示中のページ番号 */
  pageNumber: number
  /** データの累計数 */
  totalCount?: number
}

/**
 * 特徴量データ転送指示一覧 ドメイン情報
 */
export interface FeatureDataTransferringListDomainData {
  /** 特徴量データ転送指示のデータ一覧 */
  currentFeatureDataTransferringList: FeatureDataTransferring[]
  /** 特徴量データ転送指示のデータ一覧の表示条件 */
  featureDataTransferringDisplayCondition: FeatureDataTransferringDisplayCondition
  /** 特徴量データ転送指示のID一覧 */
  mlPipelineIds: string[]
  currentFeatureDataTransferringListSnapshot?: () => void
}

/**
 * 特徴量データ転送指示一覧 画面情報
 */
export interface FeatureDataTransferringListAppState {
  /** 読み込み中 */
  inProgress: boolean
}

/**
 * 特徴量データ指示一覧画面の状態
 */
export interface FeatureDataTransferringListState {
  /** データ情報 */
  domainData: FeatureDataTransferringListDomainData
  /** 画面情報 */
  appState: FeatureDataTransferringListAppState
}
