import React from 'react'
import { makeStyles } from 'tss-react/mui'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { AnnotationListItemProps } from './types'

const useStyles = makeStyles()((theme) => ({
  listItem: {
    backgroundColor: theme.palette.background.default,
    height: '40px',
    border: 0,
    outline: 'solid 1px',
  },
  nestedListItem: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    marginLeft: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    height: '40px',
    border: 0,
    outline: 'solid 1px',
  },
  listItemText: {
    '& > .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}))

export const AnnotationListItem: React.FC<AnnotationListItemProps> = (
  props: AnnotationListItemProps
) => {
  const { classes } = useStyles()

  const getListItemClass = (nested?: boolean) =>
    nested ? classes.nestedListItem : classes.listItem

  return (
    <ListItem
      dense
      onClick={props.onClick}
      className={getListItemClass(props.nested)}
      data-testid={props['data-testid']}
    >
      <ListItemIcon>{props.statusIcon}</ListItemIcon>
      <ListItemText
        className={classes.listItemText}
        primary={props.statusText}
      />
      {props.kindSelect}
      {props.endIcon}
    </ListItem>
  )
}
