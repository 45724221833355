import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  DatasetInfo,
  DatasetListActionType,
  DatasetListDisplayCondition,
} from './types'

export type DatasetListActions = ActionsUnion<typeof datasetListActions>

export const datasetListActions = {
  setDatasetList: (
    datasetList: DatasetInfo[]
  ): ActionWithPayload<'SET_DATASET_LIST', { datasetList: DatasetInfo[] }> =>
    CreateAction(DatasetListActionType.SET_DATASET_LIST, {
      datasetList,
    }),
  clearDatasetList: (): Action<'CLEAR_DATASET_LIST'> =>
    CreateAction(DatasetListActionType.CLEAR_DATASET_LIST),
  setListDisplayCondition: (
    datasetListDisplayCondition: DatasetListDisplayCondition
  ): ActionWithPayload<
    'SET_DATASET_LIST_DISPLAY_CONDITION',
    { datasetListDisplayCondition: DatasetListDisplayCondition }
  > =>
    CreateAction(DatasetListActionType.SET_DATASET_LIST_DISPLAY_CONDITION, {
      datasetListDisplayCondition,
    }),
  setThumbnails: (thumbnails: {
    [key: string]: string
  }): ActionWithPayload<
    'SET_THUMBNAILS_FOR_DATASET_LIST',
    { thumbnails: { [key: string]: string } }
  > =>
    CreateAction(DatasetListActionType.SET_THUMBNAILS_FOR_DATASET_LIST, {
      thumbnails,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_DATASET_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(DatasetListActionType.SET_IN_PROGRESS_FOR_DATASET_LIST, {
      inProgress,
    }),
  clearDatasetListState: (): Action<'CLEAR_DATASET_LIST_STATE'> =>
    CreateAction(DatasetListActionType.CLEAR_DATASET_LIST_STATE),
}
