import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { makeStyles } from 'tss-react/mui'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'

import { State } from 'state/store'
import { Algorithm, TrainingAlgorithmVersion } from 'state/app/domainData'
import {
  TrainedModel,
  CustomTrainingAction,
  customTrainingActions,
  customTrainingOperations,
  MetaData,
  Dataset,
  Setting,
  CustomTrainingStateKindArray,
  DisplayCondition,
  SortDisplayCondition,
  TrainedModelGroup,
  InferenceAlgorithmVersion,
  ContainerImage,
  CustomTrainingStateKind,
  ClassSet,
  ClassSetDisplayCondition,
  ObjectClassificationExtendedSetting,
  PreProcessKind,
  DatasetTemplate,
  ObjectRecognitionExtendedSetting,
} from 'state/ducks/customTraining'

import { Version, VersionWithPreRelease } from 'types/StateTypes'
import { isUndefined, isNull, isString } from 'utils/typeguard'
import {
  compareVersions,
  compareVersionsWithBuild,
  compareVersionsWithPreRelease,
} from 'utils/versions'
import {
  SelectableTable,
  AccordionLabel,
  SearchInput,
  MLPipelineCompleteDialog,
  CommonStepper,
  MetadataInput,
  PostAddIconButton,
  CustomTrainingPageParagraph,
  ErrorMessage,
  GlobalLoading,
  InformationDialog,
  TableDialog,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  ConfirmViewerDialog,
  CheckableTable,
  CheckableTableHeader,
  BreadcrumbsComponent,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
} from 'views/components'
import { CreateDatasetPage } from 'views/containers/pages'
import { formatDateTimeSec } from 'views/components/utils/date'
import { isVersionWithPreRelease } from 'views/containers/utils/typeguard'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Switch from '@mui/material/Switch'
import { useTheme } from '@mui/material/styles'
import { getUserGroupKindList } from 'views/containers/utils'
import {
  CustomTrainingQueryParameters,
  getCustomTrainingQueryParameters,
} from 'views/containers/utils/queryParams'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.currentCustomTrainingState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, CustomTrainingAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (customTrainingStateKind: CustomTrainingStateKind) => {
    switch (customTrainingStateKind) {
      case 'TrainingAlgorithmState':
        dispatch(customTrainingOperations.getClassSetList())
        break
      case 'ModelGroupState':
        dispatch(customTrainingOperations.getTrainedModelGroupList())
        break
      case 'BaseModelState':
        dispatch(customTrainingOperations.getSourceTrainedModelGroupList())
        break
      case 'DatasetState':
        dispatch(customTrainingOperations.getDatasetList())
        break
      case 'SettingState':
        dispatch(customTrainingOperations.getSettingList())
        break
      case 'OutputFormatState':
        dispatch(customTrainingOperations.getOutputFormatData())
        break
      default:
        break
    }
  },
  /** データセットの一覧取得 */
  getDatasetList: () => {
    dispatch(customTrainingOperations.getDatasetList())
  },
  /** データセットの一覧取得 */
  getSettingList: () => {
    dispatch(customTrainingOperations.getSettingList())
  },
  /** セッティング付加情報の取得 */
  getObjectClassificationExtendedSetting: () => {
    dispatch(customTrainingOperations.getObjectClassificationExtendedSetting())
  },
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) => {
    if (currentStep >= CustomTrainingStateKindArray.length - 1) {
      /** カスタム学習開始 */
      dispatch(customTrainingOperations.executeCustomTraining())
    } else {
      dispatch(customTrainingOperations.nextStep(currentStep))
    }
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) => {
    dispatch(customTrainingOperations.prevStep(currentStep))
  },
  /** 選んだアルゴリズムIDをセットする(Training Algorithm Id) */
  setSelectedTrainingAlgorithmId: (algorithmId: string) => {
    dispatch(customTrainingActions.setSelectedTrainingAlgorithmId(algorithmId))
  },
  /** 選んだリストをセットする(Training Algorithm) */
  setSelectedTrainingAlgorithmVersion: (
    algorithmVersion: TrainingAlgorithmVersion
  ) => {
    dispatch(
      customTrainingOperations.setSelectedTrainingAlgorithmVersion(
        algorithmVersion
      )
    )
  },
  /** 選んだリストをセットする(ClassSet) */
  setSelectedClassSet: (data?: ClassSet) =>
    dispatch(customTrainingOperations.setSelectedClassSet(data)),
  /** 選んだリストをセットする(BaseModel) */
  setSelectedBaseModel: (data?: TrainedModel) =>
    dispatch(customTrainingOperations.setSelectedBaseModel(data)),
  /** 選んだリストをセットする(Dataset) */
  setSelectedDataset: (data?: Dataset) =>
    dispatch(customTrainingOperations.setSelectedDataset(data)),
  /** 選んだリストをセットする(Setting) */
  setSelectedSetting: (data: Setting | undefined, assignmentSetting: boolean) =>
    dispatch(
      customTrainingOperations.setSelectedSetting(data, assignmentSetting)
    ),
  /** 選んだアウトプット形式をセットする（アウトプット形式） */
  setSelectedCustomTrainingGenerateKind: (
    data: 'EdgeImage' | 'CustomModel'
  ) => {
    dispatch(
      customTrainingOperations.setSelectedCustomTrainingGenerateKind(data)
    )
  },
  /** 入力したメタデータをセットする(カスタム学習情報) */
  setMlPipelinesMetaData: (data?: MetaData) =>
    dispatch(customTrainingOperations.setMlPipelinesMetaData(data)),
  /** 入力したメタデータをセットする(カスタムモデル情報) */
  setCustomModelMetaData: (data?: MetaData) =>
    dispatch(customTrainingOperations.setCustomModelMetaData(data)),
  clearCustomTrainingState: () =>
    dispatch(customTrainingActions.clearCurrentCustomTrainingState()),
  clearClassSetList: () => dispatch(customTrainingActions.clearClassSetList()),
  /** クラスセットの表示条件の変更 */
  setClassSetDisplayCondition: (classSetCondition: ClassSetDisplayCondition) =>
    dispatch(
      customTrainingActions.setClassSetDisplayCondition(classSetCondition)
    ),
  /** データセットの表示条件の変更 */
  setDatasetDisplayCondition: (datasetCondition: DisplayCondition) =>
    dispatch(
      customTrainingActions.setDatasetDisplayCondition(datasetCondition)
    ),
  /** セッティングの表示条件の変更 */
  setSettingDisplayCondition: (settingCondition: DisplayCondition) =>
    dispatch(
      customTrainingActions.setSettingDisplayCondition(settingCondition)
    ),
  /** ベースモデルの表示条件の変更 */
  setSourceModelDisplayCondition: (trainedModelCondition: DisplayCondition) => {
    dispatch(
      customTrainingActions.setSourceModelDisplayCondition(
        trainedModelCondition
      )
    )
  },
  /** 学習アルゴリズムの表示条件の変更 */
  setTrainingDisplayCondition: (
    trainingAlgorithmCondition: SortDisplayCondition
  ) =>
    dispatch(
      customTrainingActions.setTrainingAlgorithmDisplayCondition(
        trainingAlgorithmCondition
      )
    ),
  /** 継承データセットの表示条件の変更 */
  setInheritDatasetDisplayCondition: (
    inheritDatasetDisplayCondition: SortDisplayCondition
  ) =>
    dispatch(
      customTrainingActions.setInheritDatasetDisplayCondition(
        inheritDatasetDisplayCondition
      )
    ),
  /** ベースモデルのモデルグループの選択 */
  setSelectedSourceModelGroup: (modelGroup?: TrainedModelGroup) =>
    dispatch(
      customTrainingActions.setSelectedSourceTrainedModelGroup(modelGroup)
    ),
  /** 登録先モデルグループの選択 */
  setSelectedDestinationTrainedModelGroup: (modelGroup: TrainedModelGroup) =>
    dispatch(
      customTrainingOperations.setSelectedDestinationTrainedModelGroup(
        modelGroup
      )
    ),
  /** ベースモデルのモデルグループ表示条件 */
  setSourceTrainedModelGroupDisplayCondition: (
    condition: DisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ) => {
    dispatch(
      customTrainingActions.setSourceTrainedModelGroupDisplayCondition(
        condition
      )
    )
  },
  /** セッティング付加情報の設定 */
  setObjectClassificationExtendedSetting: (
    objectClassificationExtendedSetting?: ObjectClassificationExtendedSetting
  ) => {
    dispatch(
      customTrainingActions.setObjectClassificationExtendedSetting(
        objectClassificationExtendedSetting
      )
    )
  },
  /** 選んだリストをセットする(InheritModel) */
  setSelectedInheritModel: (data?: TrainedModel) =>
    dispatch(customTrainingActions.setSelectedInheritModel(data)),
  /** 登録先モデルグループの表示条件 */
  setDestinationTrainedModelGroupDisplayCondition: (
    condition: DisplayCondition
  ) => {
    dispatch(
      customTrainingActions.setDestinationTrainedModelGroupDisplayCondition(
        condition
      )
    )
  },
  /** 登録先モデルの表示条件 */
  setDestinationModelDisplayCondition: (condition: DisplayCondition) => {
    dispatch(
      customTrainingActions.setDestinationModelDisplayCondition(condition)
    )
  },
  /** switch押下時処理 */
  setIsTransfer: (isTransfer: boolean) =>
    dispatch(customTrainingActions.setIsTransfer(isTransfer)),
  /** switch押下時処理 */
  setIsSystemEvaluation: (isSystemEvaluation: boolean) =>
    dispatch(customTrainingActions.setIsSystemEvaluation(isSystemEvaluation)),
  /** コンテナアーキテクチャを選択 */
  setContainerImageIdList: (baseInferenceContainerImageIdList: string[]) => {
    dispatch(
      customTrainingActions.setIsSelected(baseInferenceContainerImageIdList)
    )
    dispatch(customTrainingActions.setOutputFormatSubState('Selected'))
  },
  /** モデルバージョンの選択 */
  setSelectedModelVersion: (modelVersion?: Version) => {
    dispatch(customTrainingActions.setSelectedModelVersion(modelVersion))
  },
  setSelectedInferenceAlgorithmVersion: (
    inferenceAlgorithmVersion?: InferenceAlgorithmVersion
  ) => {
    dispatch(
      customTrainingOperations.setSelectedInferenceAlgorithmVersion(
        inferenceAlgorithmVersion
      )
    )
  },
  /** アドバンスド設定のチェックボックス押下 */
  setSelectedContainerArchitecture: (baseInferenceContainerImageId: string) =>
    dispatch(
      customTrainingOperations.setSelectedContainerArchitecture(
        baseInferenceContainerImageId
      )
    ),
  /** ベースモデルのステータス設定 */
  setBaseModelSubState: (state: 'Unselected' | 'Selected' | 'NotFound') => {
    dispatch(customTrainingActions.setBaseModelSubState(state))
  },
  /** snapshotの購読解除 */
  unsubscribeDatasetList: () =>
    dispatch(customTrainingOperations.unsubscribeDatasetList()),
  setObjectRecognitionExtendedSetting: (
    objectRecognition?: ObjectRecognitionExtendedSetting
  ) =>
    dispatch(
      customTrainingActions.setObjectRecognitionExtendedSetting(
        objectRecognition
      )
    ),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40
const MIN_BASE_SIZE = 1
const MAX_BASE_SIZE = 1024

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  outputFormatSelection: {
    display: 'flex',
    justifyContent: 'felx-start',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  settingSelection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  dataCreateDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  detailListItem: {
    padding: 0,
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
  cbList: {
    width: '100%',
  },
  switch: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}))

/** ステップの名称 */
const STEP_NAMES = [
  '学習アルゴリズム',
  `登録先
  モデルグループ`,
  'ベースモデル',
  'セッティング',
  'データセット',
  'アウトプット形式',
  'メタデータ',
  '確認',
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** アルゴリズムのテーブルの選択状態 */
const ALGORITHM_HEADERS: SelectableTableHeader[] = [
  {
    id: 'version',
    title: 'バージョン',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'releasedAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 400,
    sortable: false,
    position: 'center',
  },
]

/** ベースモデルのテーブルの選択状態 */
const BASE_MODEL_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trainedModelId',
    title: 'モデルID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelGroupVersion',
    title: 'バージョン',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'modelName',
    title: 'モデル名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'transactionStatus',
    title: 'ステータス',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** モデルグループのヘッダー */
const MODEL_GROUP_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trainedModelGroupId',
    title: 'モデルグループID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelGroupName',
    title: 'モデルグループ名',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'trainedModelCount',
    title: '登録モデル数',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'latestTrainedModelVersion',
    title: '最新バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'latestTrainedModelName',
    title: '最新モデル名',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'updatedAt',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'createdBy',
    title: '作成ユーザID',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** 継承するデータセットのテーブルの選択状態 */
const INHERIT_DATASET_HEADERS: SelectableTableHeader[] = [
  {
    id: 'name',
    title: 'データセット名',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'generation',
    title: '世代',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'datasetTemplate',
    title: 'データセットテンプレート',
    width: 300,
    sortable: false,
    position: 'left',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 400,
    sortable: false,
    position: 'center',
  },
  {
    id: 'id',
    title: 'データセットID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** データセットのテーブルの選択状態 */
const DATASET_HEADERS: SelectableTableHeader[] = [
  {
    id: 'datasetName',
    title: 'データセット名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'datasetId',
    title: 'データセットID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

/** セッティングのテーブルのヘッダー */
const SETTING_HEADERS: SelectableTableHeader[] = [
  {
    id: 'settingName',
    title: 'セッティング名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'datasetTemplate',
    title: 'データセットテンプレート',
    width: 300,
    sortable: false,
    position: 'left',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'settingId',
    title: 'セッティングID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

/** 推論アルゴリズムのテーブルの選択状態 */
const INFERENCE_ALGORITHM_HEADERS: SelectableTableHeader[] = [
  {
    id: 'version',
    title: 'バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'builtAt',
    title: 'ビルド日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 400,
    sortable: false,
    position: 'center',
  },
  {
    id: 'containerIfVersion',
    title: 'コンテナI/Fバージョン',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'inference-code-version',
    title: '推論コードバージョン',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** アーキテクチャーのテーブルの選択状態 */
const CONTAINER_ARCHITECTURE_HEADERS: CheckableTableHeader[] = [
  {
    id: 'tags',
    title: 'Tags',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'osArch',
    title: 'OS/ARCH',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'baseImageId',
    title: 'Base Image ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'classSetId',
    title: 'クラスセットID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classSetName',
    title: 'クラスセット名',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classList',
    title: 'クラスセット',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'classSetRemarks',
    title: 'Remarks',
    width: 300,
    sortable: false,
    position: 'left',
  },
]

const CustomTrainingInfoConfirmView: React.FC<Props> = (props: Props) => {
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>カスタム学習情報</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: '表示名',
            value: props.domainData.mlPipelinesMetaData?.name,
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.mlPipelinesMetaData?.remarks,
            readOnly: true,
            variant: 'standard',
            rowNum: 0,
          }}
          textFieldSpace={0}
          helperText=''
          data-testid={'customTrainingInputPreview'}
        />
      </Box>
    </Box>
  )
}

type CustomModelInfoConfirmViewProps = Props & {
  modelGroupToDetail: (modelGroup?: TrainedModelGroup) =>
    | {
        ['モデルグループID']: string
        ['登録モデル数']: string
        ['最新バージョン']: string
        ['最新モデル名']: string
        ['更新日時']: string
        ['作成日時']: string
        ['作成ユーザーID']: string
      }
    | undefined
}

const CustomModelInfoConfirmView: React.FC<CustomModelInfoConfirmViewProps> = (
  props: CustomModelInfoConfirmViewProps
) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>カスタムモデル情報</Typography>
      <Box mt={1}>
        <InputLabel shrink>登録先モデルグループ</InputLabel>
        <Box mt={1} mb={2}>
          <AccordionLabel
            label={
              props.domainData.selectedDestinationTrainedModelGroup
                ?.trainedModelGroupName
            }
            details={props.modelGroupToDetail(
              props.domainData.selectedDestinationTrainedModelGroup
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
        <Box mt={2}>
          <TextField
            id='trainedModelVersion'
            className={classes.textField}
            variant='standard'
            label='モデルバージョン'
            value={props.domainData.selectedCustomModelVersion?.displayName}
            key={props.domainData.selectedCustomModelVersion?.displayName}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <MetadataInput
            nameProps={{
              label: '表示名',
              value: props.domainData.customModelMetaData?.name,
              readOnly: true,
              variant: 'standard',
            }}
            remarksProps={{
              label: '備考',
              value: props.domainData.customModelMetaData?.remarks,
              readOnly: true,
              variant: 'standard',
              rowNum: 0,
            }}
            textFieldSpace={0}
            helperText=''
            data-testid={'customModelInputPreview'}
          />
        </Box>
      </Box>
    </Box>
  )
}

type TrainingAlgorithmConfirmViewProps = Props & {
  trainingAlgorithmToDetail: (
    trainingAlgorithmVersion?: TrainingAlgorithmVersion
  ) =>
    | {
        ['バージョン']: string
        ['登録日時']: string
        ['remarks']: string
      }
    | undefined
}

const TrainingAlgorithmConfirmView: React.FC<
  TrainingAlgorithmConfirmViewProps
> = (props: TrainingAlgorithmConfirmViewProps) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>学習アルゴリズム</Typography>
      <Box mt={1}>
        {props.domainData.selectedTrainingAlgorithm?.algorithmId && (
          <TextField
            id='algorithm'
            className={classes.textField}
            variant='standard'
            label='学習アルゴリズム名'
            value={
              props.algorithms.find(
                (list) =>
                  list.algorithmId ===
                  props.domainData.selectedTrainingAlgorithm?.algorithmId
              )?.metadata.name.ja
            }
            key={props.domainData.selectedTrainingAlgorithm?.algorithmId}
          />
        )}
        <Box mt={2}>
          <InputLabel shrink>バージョン</InputLabel>
          <Box mt={1}>
            <AccordionLabel
              label={
                props.domainData?.selectedTrainingAlgorithmVersion
                  ?.trainingAlgorithmVersion
              }
              details={props.trainingAlgorithmToDetail(
                props.domainData?.selectedTrainingAlgorithmVersion
              )}
              prefix={'-'}
              delimiter={':'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

type AnnotationTargeting = {
  enabled: boolean
  hiddenPercentage: number
}
type ExecutionInfoConfirmViewProps = Props & {
  trainedModelToDetail: (
    baseModel?: TrainedModel,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) =>
    | {
        ['モデルID']: string
        ['データ種別']: string
        ['バージョン']: string
        ['モデル名']: string
        ['ステータス']: string
      }
    | undefined
  datasetToDetail: (dataset?: Dataset) =>
    | {
        ['データセットID']: string
        ['データセット名']: string
        ['登録日時']: string
        ['Remarks']: string
      }
    | undefined
  settingToDetail: (
    setting?: Setting,
    hiddenPercentageInfo?: AnnotationTargeting
  ) => { [key: string]: string } | undefined
  inheritDatasetRows: (JSX.Element | undefined)[][]
}

const ExecutionInfoConfirmView: React.FC<ExecutionInfoConfirmViewProps> = (
  props: ExecutionInfoConfirmViewProps
) => {
  const { classes } = useStyles()
  const globalTheme = useTheme()

  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>実行情報</Typography>
      <Box mt={1}>
        <InputLabel shrink>ベースモデル</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={
              props.domainData.selectedSourceTrainedModel?.trainedModelName
            }
            details={props.trainedModelToDetail(
              props.domainData.selectedSourceTrainedModel,
              props.domainData.sourceModelGroupDisplayCondition
                .selectedUserGroupKind
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      {props.domainData.selectedInheritTrainedModel && (
        <Box mt={2}>
          <InputLabel shrink>継承するモデルバージョン</InputLabel>
          <Box mt={1}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant='body1' className={classes.labelText}>
                  {
                    props.domainData.selectedInheritTrainedModel
                      .trainedModelGroupVersion.displayName
                  }
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List className={classes.cbList}>
                  <Box mb={2}>
                    <Box>{`・モデルID: ${props.domainData.selectedInheritTrainedModel.trainedModelId}`}</Box>
                    <Box>{`・モデル名: ${props.domainData.selectedInheritTrainedModel.trainedModelName}`}</Box>
                    {props.domainData.selectedInheritTrainedModel
                      .transactionStatus === '' ? (
                      <Box sx={{ color: globalTheme.palette.text.secondary }}>
                        {`・ステータス: ${TABLE_CELL_NOT_APPLICABLE}`}
                      </Box>
                    ) : (
                      <Box>{`・ステータス: ${props.domainData.selectedInheritTrainedModel.transactionStatus}`}</Box>
                    )}
                  </Box>
                  <InputLabel shrink>継承するデータセット</InputLabel>
                  <SelectableTable
                    page={0}
                    headers={INHERIT_DATASET_HEADERS}
                    rows={props.inheritDatasetRows}
                    tableHeight={
                      TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT
                    }
                    fixedColumnNumber={0}
                    displayNoneRadio={true}
                    displayNoneEmptyCells={true}
                  />
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      )}
      <Box mt={2}>
        <InputLabel shrink>データセット</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={props.domainData.selectedDataset?.name}
            details={props.datasetToDetail(props.domainData.selectedDataset)}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <InputLabel shrink>セッティング</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={props.domainData.selectedSetting?.name}
            details={props.settingToDetail(
              props.domainData.selectedSetting,
              props.domainData.extendedSetting.objectRecognition
                ? {
                    enabled:
                      props.domainData.extendedSetting.objectRecognition
                        ?.isEnabledAnnotationTargeting,
                    hiddenPercentage:
                      props.domainData.extendedSetting.objectRecognition
                        ?.hiddenPercentage,
                  }
                : undefined
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <TextField
          id='destType'
          className={classes.textField}
          variant='standard'
          label='アウトプット形式'
          value={
            props.domainData.selectedCustomTrainingGenerateKind === 'EdgeImage'
              ? '全部（カスタムモデル＋エッジイメージのビルド）'
              : 'カスタムモデル作成のみ'
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </Box>
  )
}

type EdgeImageInfoConfirmViewProps = Props & {
  selectedAlgorithmContainerImageList: ContainerImage[]
  selectedAlgorithmContainerImageRows: JSX.Element[][]
  selectedAlgorithmContainerImageIds: string[]
  inferenceAlgorithmVersionToDetail: (
    inferenceAlgorithmVersion?: InferenceAlgorithmVersion
  ) =>
    | {
        ['アルゴリズムID']: string | undefined
        ['アルゴリズムバージョン']: string
        ['登録日時']: string
        ['備考']: string
        ['コンテナ I/F バージョン']: string
      }
    | undefined
}

const EdgeImageInfoConfirmView: React.FC<EdgeImageInfoConfirmViewProps> = (
  props: EdgeImageInfoConfirmViewProps
) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} mb={1} width='100%'>
      <Typography>エッジイメージ</Typography>
      <Box mt={1}>
        <TextField
          id='algorithm'
          className={classes.textField}
          variant='standard'
          label='推論アルゴリズム名'
          InputProps={{
            readOnly: true,
          }}
          value={
            props.algorithms.find(
              (list) =>
                list.algorithmId ===
                props.domainData.selectedTrainingAlgorithm?.algorithmId
            )?.metadata.name.ja
          }
        />
      </Box>
      <Box mt={2}>
        <InputLabel shrink>バージョン</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={
              props.domainData.selectedInferenceAlgorithmVersion
                ? props.domainData.selectedInferenceAlgorithmVersion
                    .algorithmVersion.displayName
                : ''
            }
            details={props.inferenceAlgorithmVersionToDetail(
              props.domainData.selectedInferenceAlgorithmVersion
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <InputLabel shrink>アドバンスド設定</InputLabel>
        <Box mt={1}>
          <Accordion>
            <AccordionDetails>
              <List className={classes.cbList}>
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  Container Architecture
                </Box>
                <CheckableTable
                  headers={CONTAINER_ARCHITECTURE_HEADERS}
                  fixedColumnNumber={0}
                  selectedRows={props.selectedAlgorithmContainerImageList.map(
                    (item) =>
                      props.domainData.selectedBaseInferenceContainerImageIds.includes(
                        item.baseInferenceContainerImageId
                      )
                  )}
                  page={0}
                  noEmptyRows={true}
                  disabled={true}
                  rows={props.selectedAlgorithmContainerImageRows ?? []}
                />
                <Box sx={{ color: globalTheme.palette.text.secondary }} mt={2}>
                  System Evaluation
                </Box>
                <Box display='flex' alignItems='center'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.domainData.isSystemEvaluation}
                        disabled
                        color='default'
                      />
                    }
                    label=''
                  />
                  <Box display='flex'>
                    {props.domainData.isSystemEvaluation === true
                      ? 'ON'
                      : 'OFF'}
                  </Box>
                </Box>
                <Box sx={{ color: globalTheme.palette.text.secondary }} mt={2}>
                  Transfer to Connected Service
                </Box>
                <Box display='flex' alignItems='center'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.domainData.isTransfer}
                        disabled
                        color='default'
                      />
                    }
                    label=''
                  />
                  <Box display='flex'>
                    {props.domainData.isTransfer === true ? 'ON' : 'OFF'}
                  </Box>
                </Box>
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  )
}

type ClassSetConfirmViewProps = Props & {
  classSetToDetail: (classSet?: ClassSet) =>
    | {
        ['クラスセットID']: string
        ['クラスセット名']: string
        ['クラス名リスト']: string
        ['登録日時']: string
        ['備考']: string
      }
    | undefined
}

const ClassSetConfirmView: React.FC<ClassSetConfirmViewProps> = (props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} mb={1} width='100%'>
      <Typography>物体クラス分類</Typography>
      <Box mt={2}>
        <InputLabel shrink>クラス分類</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={props.domainData.selectedClassSet?.classSetName ?? ''}
            details={props.classSetToDetail(props.domainData.selectedClassSet)}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <TextField
          id='preprocessKind'
          className={classes.textField}
          variant='standard'
          label='前処理種別'
          value={
            props.domainData.extendedSetting.objectClassification
              ?.preProcessKind ?? ''
          }
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
      <Box mt={2}>
        <InputLabel shrink>アドバンスド設定</InputLabel>
        <Box mt={1}>
          <Accordion>
            <AccordionDetails>
              <List className={classes.cbList}>
                <Box
                  sx={{
                    color: globalTheme.palette.text.secondary,
                  }}
                >
                  Base Size
                </Box>
                <Box display='flex' alignItems='center'>
                  <Box m={2}>
                    <TextField
                      id='width'
                      className={classes.textField}
                      label='Width'
                      type='number'
                      value={
                        props.domainData.extendedSetting.objectClassification
                          ?.baseSize.width ?? ''
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box m={2}>✖️</Box>
                  <Box m={2}>
                    <TextField
                      id='height'
                      className={classes.textField}
                      label='Height'
                      type='number'
                      value={
                        props.domainData.extendedSetting.objectClassification
                          ?.baseSize.height ?? ''
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  )
}

/**
 * 選択したアルゴリズムIDが、対象物認識のアルゴリズムIDがどうかをチェックする
 * @param algorithms アルゴリズム一覧
 * @param selectedAlgorithmId 選択中のアルゴリズムID
 * @returns `true`の場合、選択したアルゴリズムが対象物認識である
 */
const isObjectRecognition = (
  algorithms: Algorithm[],
  selectedAlgorithmId: string
) =>
  algorithms.find(
    (algorithm) => algorithm.algorithmPurpose === 'ObjectRecognition'
  )?.algorithmId === selectedAlgorithmId

const CustomTraining: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const search = useLocation().search
  const query = getCustomTrainingQueryParameters(search)

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  /** 学習アルゴリズムのサブステップ */
  const [trainingAlgorithmSubStep, setTrainingAlgorithmSubStep] =
    useState<boolean>(false)
  /** ベースモデルのモデルグループのサブステップ */
  const [baseModelSubStep, setBaseModelSubStep] = useState<boolean>(false)
  /** データセットのサブステップ */
  const [datasetSubStep, setDatasetSubStep] = useState<boolean>(false)
  /** セッティングのサブステップ */
  const [settingSubStep, setSettingSubStep] = useState<boolean>(false)
  /** 隠れ率の入力値 */
  const [hiddenPercentage, setHiddenPercentage] = useState('100')
  /** 設定するモデルのバージョンの配列 */
  const [modelVersionList, setModelVersionList] = useState<Version[]>([])
  /** 継承するか */
  const [inherit, setInherit] = useState<'inherit' | 'notInherit'>('inherit')
  /** セッティングを設定するか */
  const [assignmentSetting, setAssignmentSetting] = useState<boolean>(false)

  useEffect(() => {
    if (
      props.domainData.selectedDestinationTrainedModelGroup?.trainedModels
        .length === 0
    ) {
      setInherit('notInherit')
    }
  }, [props.domainData.selectedDestinationTrainedModelGroup])

  useEffect(() => {
    return () => {
      props.clearCustomTrainingState()
      props.unsubscribeDatasetList()
    }
  }, [])

  useEffect(() => {
    // クエリパラメータにアルゴリズムIDが含まれる場合は、初期値として設定
    const algorithmId = query['algorithm-id']
    if (algorithmId) {
      props.setSelectedTrainingAlgorithmId(algorithmId)
    } else {
      props.setSelectedTrainingAlgorithmId(props.algorithms[0].algorithmId)
    }
  }, [])

  useEffect(() => {
    if (
      isObjectRecognition(
        props.algorithms,
        props.domainData.selectedTrainingAlgorithm?.algorithmId ?? ''
      )
    ) {
      props.setObjectRecognitionExtendedSetting({
        isEnabledAnnotationTargeting: false,
        hiddenPercentage: 100,
      })
    } else {
      props.setObjectRecognitionExtendedSetting(undefined)
    }
  }, [props.domainData.selectedTrainingAlgorithm?.algorithmId])

  useEffect(() => {
    if (query['shared-user-group'] === 'false') {
      props.setSourceTrainedModelGroupDisplayCondition({
        ...props.domainData.sourceModelGroupDisplayCondition,
        selectedUserGroupKind: 'UserGroup',
      })
    }
  }, [props.domainData.selectedDestinationTrainedModelGroup])

  /**
   * アーキテクチャを全て選択にする
   */
  const [
    allAlgorithmContainerImageList,
    allAlgorithmContainerImageIds,
    allAlgorithmContainerImageRows,
  ] = useMemo(() => {
    if (!props.domainData.selectedInferenceAlgorithmVersion) return []
    const algorithm = props.domainData.selectedInferenceAlgorithmVersion

    const containerImageIds: string[] = []
    let containerImageList: ContainerImage[] = []

    if (algorithm) {
      algorithm.baseInferenceContainerImages.forEach((image) => {
        containerImageIds.push(image.baseInferenceContainerImageId)
      })
      containerImageList = algorithm?.baseInferenceContainerImages
    }

    props.setContainerImageIdList(containerImageIds)
    const tagSortedRowData = containerImageList.sort((prev, next) => {
      const tagStringPrev = prev.containerImageTags.join()
      const tagStringNext = next.containerImageTags.join()
      return tagStringPrev.localeCompare(tagStringNext)
    })

    const osArchSortedRowData = tagSortedRowData.sort((prev, next) => {
      const osArchStringPrev = `${prev.containerImagePlatform.os}/${prev.containerImagePlatform.architecture}`
      const osArchStringNext = `${next.containerImagePlatform.os}/${next.containerImagePlatform.architecture}`
      const tagStringPrev = prev.containerImageTags.join()
      const tagStringNext = next.containerImageTags.join()
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev < osArchStringNext
      ) {
        return -1
      }
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev > osArchStringNext
      ) {
        return 1
      }
      // names must be equal
      return 0
    })

    const rowIds: string[] = []
    const rows: JSX.Element[][] = osArchSortedRowData.map<JSX.Element[]>(
      (rowData) => {
        rowIds.push(rowData.baseInferenceContainerImageId)
        return [
          <Tooltip
            key={'tags'}
            title={rowData.containerImageTags.join(', ')}
            placement='bottom'
          >
            <Typography key={'tags'}>
              {rowData.containerImageTags.join(', ')}
            </Typography>
          </Tooltip>,
          <Typography key={'osArch'}>
            {rowData.containerImagePlatform.os}/
            {rowData.containerImagePlatform.architecture}
          </Typography>,
          <Tooltip
            key={'baseImageId'}
            data-testid={`model-group-${rowData.baseInferenceContainerImageId}`}
            title={rowData.baseInferenceContainerImageId}
            placement='bottom'
          >
            <Typography>
              {rowData.baseInferenceContainerImageId.substring(0, 8)}
            </Typography>
          </Tooltip>,
        ]
      }
    )

    return [containerImageList, rowIds, rows]
  }, [props.domainData.selectedInferenceAlgorithmVersion])

  useEffect(() => {
    props.setContainerImageIdList(
      allAlgorithmContainerImageList?.map(
        (containerimage) => containerimage.baseInferenceContainerImageId
      ) ?? []
    )
  }, [
    allAlgorithmContainerImageList,
    props.domainData.selectedInferenceAlgorithmVersion,
  ])

  /** データセット新規追加ダイアログ表示状態 */
  const [openDatasetDialog, setOpenDatasetDialog] = useState(false)
  const [newDatasetId, setNewDatasetId] = useState<string | undefined>(
    undefined
  )
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  /** データセットの新規追加ボタン押下時処理 */
  const handleClickOpenDatasetDialog = () => {
    setOpenDatasetDialog(true)
  }

  /** データセットの新規追加完了時処理（または、キャンセル） */
  const handleClickCloseDatasetDialog = (id?: string) => {
    setOpenDatasetDialog(false)
    if (isUndefined(id)) return
    setNewDatasetId(id)
    props.getDatasetList()
  }

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  /** バージョンの配列セット */
  useEffect(() => {
    const versionNumberList =
      !isUndefined(props.domainData.selectedDestinationTrainedModelGroup) &&
      props.domainData.selectedDestinationTrainedModelGroup
        .latestTrainedModelVersion !== ''
        ? props.domainData.selectedDestinationTrainedModelGroup.latestTrainedModelVersion.split(
            '.'
          )
        : '0.0.0'.split('.')
    const major = Number(versionNumberList[0])
    const minor = Number(versionNumberList[1])
    const patch = Number(versionNumberList[2])
    props.setSelectedModelVersion({
      displayName: `${major}.${minor}.${patch + 1}`,
      major: major,
      minor: minor,
      patch: patch + 1,
    })
    setModelVersionList([
      {
        displayName: `${major}.${minor}.${patch + 1}`,
        major: major,
        minor: minor,
        patch: patch + 1,
      },
      {
        displayName: `${major}.${minor + 1}.0`,
        major: major,
        minor: minor + 1,
        patch: 0,
      },
      {
        displayName: `${major + 1}.0.0`,
        major: major + 1,
        minor: 0,
        patch: 0,
      },
    ])
  }, [props.domainData.selectedDestinationTrainedModelGroup])

  /** データセットの新規追加完了時処理 */
  useEffect(() => {
    if (isUndefined(newDatasetId)) return
    const newDataset = props.domainData.datasetList.find(
      (item) => item.datasetId === newDatasetId
    )
    if (isUndefined(newDataset)) return
    props.setSelectedDataset(newDataset)
    setNewDatasetId(undefined)
  }, [newDatasetId, props.domainData.datasetList])

  /** カスタム学習情報の表示名更新処理 */
  const updateMlPipelinesMetaDataName = (name: string) => {
    props.setMlPipelinesMetaData({
      ...props.domainData.mlPipelinesMetaData,
      name,
    })
  }

  /** カスタム学習情報の備考更新処理 */
  const updateMlPipelinesMetaDataRemarks = (remarks: string) => {
    props.setMlPipelinesMetaData({
      ...props.domainData.mlPipelinesMetaData,
      remarks,
    })
  }

  /** カスタムモデルの表示名更新処理 */
  const updateCustomModelMetaDataName = (name: string) => {
    props.setCustomModelMetaData({
      ...props.domainData.customModelMetaData,
      name,
    })
  }

  /** カスタムモデルの備考更新処理 */
  const updateCustomModelMetaDataRemarks = (remarks: string) => {
    props.setCustomModelMetaData({
      ...props.domainData.customModelMetaData,
      remarks,
    })
  }

  /** カレントのステップ */
  const currentStep = useMemo(
    () =>
      CustomTrainingStateKindArray.indexOf(props.appState.customTrainingState),
    [props.appState.customTrainingState]
  )

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.customTrainingState) {
      case 'TrainingAlgorithmState':
        return (
          props.appState.customTrainingSubState.trainingAlgorithmSubState ===
          'Selected'
        )
      case 'ModelGroupState':
        return (
          props.appState.customTrainingSubState.modelGroupSubState ===
          'Selected'
        )
      case 'BaseModelState':
        return (
          props.appState.customTrainingSubState.baseModelSubState === 'Selected'
        )
      case 'SettingState':
        return (
          props.appState.customTrainingSubState.settingSubState === 'Selected'
        )
      case 'DatasetState':
        if (inherit === 'inherit') {
          return (
            props.domainData.selectedInheritTrainedModel !== undefined &&
            props.appState.customTrainingSubState.datasetSubState === 'Selected'
          )
        }
        return (
          props.appState.customTrainingSubState.datasetSubState === 'Selected'
        )
      case 'OutputFormatState':
        return (
          props.appState.customTrainingSubState.outputFormatSubState ===
          'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.customTrainingSubState.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    props.appState.customTrainingState,
    props.appState.customTrainingSubState,
    props.domainData.selectedSourceTrainedModelGroup,
    props.domainData.selectedDestinationTrainedModelGroup,
    inherit,
  ])

  /** ステップ更新時のスクロール操作 */
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
      handleChangeSearchValue('')
    }
  }, [currentStep])

  /** 各種一覧取得（学習アルゴリズム/学習モデル/データセット/セッティング/推論アルゴリズム） */
  useEffect(() => {
    props.getParams(props.appState.customTrainingState)
  }, [props.appState.customTrainingState])

  useEffect(() => {
    if (
      props.appState.customTrainingState === 'OutputFormatState' &&
      props.domainData.selectedCustomTrainingGenerateKind === 'CustomModel'
    ) {
      props.setSelectedCustomTrainingGenerateKind('CustomModel')
    }
  }, [props.appState.customTrainingState])

  const handleChangeHiddenPercentage = useCallback(
    (hiddenPercentageString: string) => {
      // 数値以外の入力を除外する
      const hiddenPercentage = hiddenPercentageString.replace(/[^0-9]/g, '')
      setHiddenPercentage(hiddenPercentage)
    },
    []
  )

  const handleBlurHiddenPercentage = useCallback(() => {
    let correctedHiddenPercentage = Number(hiddenPercentage)
    if (isNaN(correctedHiddenPercentage)) correctedHiddenPercentage = 0
    if (correctedHiddenPercentage > 100) correctedHiddenPercentage = 100
    setHiddenPercentage(String(correctedHiddenPercentage))
    if (props.domainData.extendedSetting.objectRecognition) {
      props.setObjectRecognitionExtendedSetting({
        ...props.domainData.extendedSetting.objectRecognition,
        hiddenPercentage: correctedHiddenPercentage,
      })
    }
  }, [hiddenPercentage])

  useEffect(() => {
    if (props.domainData.extendedSetting.objectRecognition) {
      setHiddenPercentage(
        props.domainData.extendedSetting.objectRecognition.hiddenPercentage.toString()
      )
    }
  }, [props.domainData.extendedSetting.objectRecognition?.hiddenPercentage])

  /** 次ページの表示 */
  const nextPageAction = () => {
    switch (props.appState.customTrainingState) {
      case 'TrainingAlgorithmState':
        if (
          !trainingAlgorithmSubStep &&
          props.algorithms
            .filter(
              (algorithm) =>
                algorithm.algorithmPurpose === 'ObjectClassification'
            )
            .map((algorithm) => algorithm.algorithmId)
            .includes(
              props.domainData.selectedTrainingAlgorithm?.algorithmId ?? ''
            )
        ) {
          setTrainingAlgorithmSubStep(true)
          break
        }
        props.nextStep(currentStep)
        break
      case 'BaseModelState':
        if (
          baseModelSubStep &&
          props.domainData.selectedSourceTrainedModelGroup
        ) {
          if (
            props.domainData.selectedDestinationTrainedModelGroup?.trainedModels
              .length !== 0
          ) {
            setInherit('inherit')
          }
          props.nextStep(currentStep)
          break
        } else if (props.domainData.selectedSourceTrainedModelGroup) {
          setBaseModelSubStep(true)
          break
        }
        break
      case 'DatasetState':
        if (datasetSubStep && props.domainData.selectedDataset) {
          props.nextStep(currentStep)
          break
        } else if (props.domainData.selectedDataset) {
          setDatasetSubStep(true)
          break
        }
        break
      case 'SettingState':
        // STEP1で対象物認識を選んだ場合、もしくは選択中のクラスセットがある場合はサブステップを表示する
        if (
          !settingSubStep &&
          (!isUndefined(props.domainData.selectedClassSet) ||
            isObjectRecognition(
              props.algorithms,
              props.domainData.selectedTrainingAlgorithm?.algorithmId ?? ''
            ))
        ) {
          setSettingSubStep(true)
          break
        }
        props.nextStep(currentStep)
        break
      default:
        props.nextStep(currentStep)
        break
    }
  }

  /** 前ページ表示 */
  const prevPageAction = () => {
    switch (props.appState.customTrainingState) {
      case 'TrainingAlgorithmState':
        if (trainingAlgorithmSubStep) {
          setTrainingAlgorithmSubStep(false)
          props.prevStep(currentStep)
        }
        break
      case 'BaseModelState':
        if (baseModelSubStep) {
          setBaseModelSubStep(false)
          break
        } else {
          props.prevStep(currentStep)
          break
        }
      case 'SettingState':
        if (settingSubStep) {
          setSettingSubStep(false)
          if (
            isObjectRecognition(
              props.algorithms,
              props.domainData.selectedTrainingAlgorithm?.algorithmId ?? ''
            )
          ) {
            props.setObjectRecognitionExtendedSetting({
              isEnabledAnnotationTargeting: false,
              hiddenPercentage: 100,
            })
          }
          break
        } else {
          setAssignmentSetting(false)
          props.prevStep(currentStep)
          break
        }
      case 'DatasetState':
        if (datasetSubStep) {
          setDatasetSubStep(false)
          break
        } else {
          props.prevStep(currentStep)
          break
        }
      default:
        props.prevStep(currentStep)
        break
    }
  }

  /** カスタム学習の開始失敗時メッセージ */
  const CustomTrainingErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.customTrainingSubState.executeSubState === 'ExecuteError'
    ) {
      errorMessages.push('学習開始に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  /** クエリのモデルグループが検索失敗時メッセージ */
  const TrainedModelGroupErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.customTrainingSubState.baseModelSubState === 'NotFound'
    ) {
      errorMessages.push(
        'モデル詳細画面で選択したモデルグループに一致するデータが存在しません。'
      )
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  /** ユーザーグループ種別の選択 */
  const selectUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearClassSetList()
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getParams(props.appState.customTrainingState)
  }

  const {
    selectedDestinationTrainedModelTableRadio,
    selectedDestinationTrainedModelGroupIndex,
    destinationTrainedModelGroupRows,
    selectedSourceModelTableRadio,
    selectedSourceModelGroupIndex,
    sourceModelGroupRows,
    selectedTrainingAlgorithmIndex,
    selectTrainingAlgorithmTableRadio,
    selectedInferenceAlgorithmVersionIndex,
    searchedSourceTrainedModelGroupListLength,
    destinationModelRows,
    selectDestinationModelTableRadio,
    selectedBaseModelIndex,
    selectedSettingIndex,
    selectClassSetTableRadio,
    selectBaseModelTableRadio,
    selectSettingTableRadio,
    changeTableSortOrder,
    pageChange,
    trainingAlgorithmRows,
    inferenceAlgorithmVersionRows,
    selectInferenceAlgorithmTableRadio,
    handleChangeDisplayNumber,
    setSelectRowIndex,
    tableDialog,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    searchedSettingListLength,
    datasetRows,
    selectedDatasetIndex,
    selectDatasetTableRadio,
    searchedClassSetListLength,
    searchedDatasetListLength,
    baseModelRows,
    settingsRows,
    settingTableDisableRowNumbers,
    findNextEnabledSetting,
    findNextEnabledInheritModel,
    isOpenDownloadFailedDialog,
    setIsOpenDownloadFailedDialog,
    selectedAlgorithmTableOrder,
    modelGroupToDetail,
    trainingAlgorithmToDetail,
    trainedModelToDetail,
    datasetToDetail,
    settingToDetail,
    inferenceAlgorithmVersionToDetail,
    inheritDatasetRows,
    selectedDestinationModelIndex,
    InheritModelsDisableRowNumbers,
    selectedInheritDatasetTableOrder,
    classSetRows,
    selectedClassSetIndex,
    classSetToDetail,
    onChangeBaseSizeWidth,
    onChangeBaseSizeHeight,
  } = useTable(
    props,
    query,
    trainingAlgorithmSubStep,
    baseModelSubStep,
    datasetSubStep,
    settingSubStep
  )

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.customTrainingState) {
      case 'TrainingAlgorithmState':
        if (isUndefined(props.algorithms)) {
          return <></>
        }
        return trainingAlgorithmSubStep ? (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <Box mt={2}>
                <FormControl
                  variant='outlined'
                  className={classes.algorithmSelectBox}
                >
                  <InputLabel id='class-set-user-group-kind'>
                    データ種別
                  </InputLabel>
                  <Select
                    labelId='class-set-user-group-kind-label'
                    id='class-set-user-group-kind-outlined'
                    value={
                      props.domainData.classSetDisplayCondition
                        .selectedUserGroupKind
                    }
                    onChange={(e) =>
                      selectUserGroupKind(
                        e.target.value as 'UserGroup' | 'SharedUserGroup'
                      )
                    }
                    label='Select User Group Kind'
                  >
                    {userGroupKindList.map((kind) => (
                      <MenuItem
                        data-testid={kind.kind}
                        value={kind.kind}
                        key={kind.kind}
                      >
                        {kind.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <div className={classes.searchForm}>
                  <div className={classes.searchField}>
                    <SearchInput
                      placeholder='検索 (クラスセットIDなど)'
                      value={tableSearchValue}
                      onChangeValue={(event) =>
                        handleChangeSearchValue(event.target.value)
                      }
                      onClickSearch={() => searchTableContent()}
                      onPressEnter={() => searchTableContent()}
                    />
                  </div>
                </div>
              </Box>
              <SelectableTable
                headers={TABLE_HEADERS}
                rows={classSetRows}
                displayNumber={
                  props.domainData.classSetDisplayCondition.displayNumber
                }
                totalCount={
                  searchedClassSetListLength ??
                  props.domainData.classSets.length
                }
                tableHeight={600}
                selectedRowNumber={selectedClassSetIndex}
                fixedColumnNumber={0}
                page={props.domainData.classSetDisplayCondition.pageNumber}
                sortOrder={{
                  key: props.domainData.classSetDisplayCondition.sortKey,
                  order: props.domainData.classSetDisplayCondition.sortOrder,
                }}
                displayNoneRadio={false}
                onChangeDisplayNumber={(displayNumber: number) =>
                  handleChangeDisplayNumber(displayNumber)
                }
                onClickRadio={(row: number) => selectClassSetTableRadio(row)}
                onClickOrderChange={(key: string) => changeTableSortOrder(key)}
                onClickPageChange={(pageNumber: number) =>
                  pageChange(pageNumber)
                }
              />
            </CustomTrainingPageParagraph>
          </div>
        ) : (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <Box mt={2}>
                <FormControl
                  variant='outlined'
                  className={classes.algorithmSelectBox}
                >
                  <InputLabel id='customTrainingModel'>アルゴリズム</InputLabel>
                  <Select
                    data-testid='select'
                    value={
                      props.domainData.selectedTrainingAlgorithm?.algorithmId
                    }
                    onChange={(e: SelectChangeEvent<string>) =>
                      props.setSelectedTrainingAlgorithmId(
                        e.target.value as string
                      )
                    }
                    label='Select Algorithm'
                    defaultValue={
                      query['algorithm-id']
                        ? query['algorithm-id'] ?? undefined
                        : props.algorithms.filter(
                            (algorithm) =>
                              algorithm.algorithmPurpose !== 'TemplateMatching'
                          )[0].algorithmId
                    }
                  >
                    {props.algorithms
                      .filter(
                        (algorithm) =>
                          algorithm.algorithmPurpose !== 'TemplateMatching'
                      )
                      .map((algorithm) => {
                        return (
                          <MenuItem
                            data-testid={algorithm.algorithmId}
                            value={algorithm.algorithmId}
                            key={algorithm.algorithmId}
                          >
                            {algorithm.metadata.name.ja}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              {!isUndefined(
                props.domainData.selectedTrainingAlgorithm?.algorithmId
              ) && (
                <SelectableTable
                  headers={ALGORITHM_HEADERS}
                  fixedColumnNumber={0}
                  selectedRowNumber={selectedTrainingAlgorithmIndex}
                  page={0}
                  rows={trainingAlgorithmRows}
                  tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                  sortOrder={{
                    key: props.domainData
                      .trainingAlgorithmVersionDisplayCondition.sortKey,
                    order:
                      props.domainData.trainingAlgorithmVersionDisplayCondition
                        .sortOrder,
                  }}
                  onClickRadio={(row: number) =>
                    selectTrainingAlgorithmTableRadio(row)
                  }
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                  onClickOrderChange={(key: string) => {
                    return selectedAlgorithmTableOrder(key)
                  }}
                />
              )}
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ModelGroupState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph
              level={'part'}
              title={'カスタムモデルを登録するモデルグループ'}
            >
              <Box mb={3}>
                <FormControl
                  variant='outlined'
                  className={classes.algorithmSelectBox}
                >
                  <InputLabel id='modelVersionInput'>
                    モデルバージョン
                  </InputLabel>
                  <Select
                    data-testid='select-model-version'
                    labelId='modelVersionInput-label'
                    id='modelVersionInput-outlined'
                    value={
                      props.domainData.selectedCustomModelVersion?.displayName
                    }
                    onChange={(e: SelectChangeEvent<string>) =>
                      props.setSelectedModelVersion(
                        modelVersionList.find(
                          (modelVersion) =>
                            modelVersion.displayName === e.target.value
                        ) as Version
                      )
                    }
                    label='Select Model Version'
                  >
                    {modelVersionList.map((value) => (
                      <MenuItem
                        data-testid={`select${value.displayName}`}
                        value={value.displayName}
                        key={value.displayName}
                      >
                        {value.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <CustomTrainingPageParagraph level={'chapter'}>
                <div className={classes.searchForm}>
                  <div className={classes.searchField}>
                    <SearchInput
                      placeholder='検索（モデルグループIDなど）'
                      value={tableSearchValue}
                      onChangeValue={(event) =>
                        handleChangeSearchValue(event.target.value)
                      }
                      onClickSearch={() => searchTableContent()}
                      onPressEnter={() => searchTableContent()}
                    />
                  </div>
                </div>
                <SelectableTable
                  displayNumber={
                    props.domainData.destinationModelGroupDisplayCondition
                      .displayNumber
                  }
                  headers={MODEL_GROUP_HEADERS}
                  rows={destinationTrainedModelGroupRows}
                  totalCount={
                    props.domainData.destinationTrainedModelGroups.length
                  }
                  tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                  onChangeDisplayNumber={(displayNumber: number) =>
                    handleChangeDisplayNumber(displayNumber)
                  }
                  selectedRowNumber={selectedDestinationTrainedModelGroupIndex}
                  fixedColumnNumber={0}
                  page={
                    props.domainData.destinationModelGroupDisplayCondition
                      .pageNumber
                  }
                  sortOrder={{
                    key: props.domainData.destinationModelGroupDisplayCondition
                      .sortKey,
                    order:
                      props.domainData.destinationModelGroupDisplayCondition
                        .sortOrder,
                  }}
                  onClickRadio={(row: number) =>
                    selectedDestinationTrainedModelTableRadio(row)
                  }
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                  onClickOrderChange={(key: string) =>
                    changeTableSortOrder(key)
                  }
                  onClickPageChange={(pageNumber: number) =>
                    pageChange(pageNumber)
                  }
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'BaseModelState':
        if (isUndefined(props.domainData.destinationTrainedModelGroups)) {
          return <></>
        } else {
          return (
            <>
              {!baseModelSubStep ? (
                <div className={classes.stepContainer}>
                  {props.appState.customTrainingSubState.baseModelSubState ===
                  'NotFound' ? (
                    <TrainedModelGroupErrorMessage {...props} />
                  ) : (
                    <CustomTrainingPageParagraph
                      level={'part'}
                      title={'ベースモデルで利用するモデルグループ'}
                    >
                      <CustomTrainingPageParagraph level={'chapter'}>
                        <CustomTrainingPageParagraph>
                          <FormControl
                            variant='outlined'
                            className={classes.algorithmSelectBox}
                          >
                            <InputLabel id='modelListUserGroupKind'>
                              データ種別
                            </InputLabel>
                            <Select
                              data-testid='select-data-kind'
                              labelId='modelListUserGroupKind-label'
                              id='modelListUserGroupKind-outlined'
                              value={
                                props.domainData
                                  .sourceModelGroupDisplayCondition
                                  .selectedUserGroupKind
                              }
                              onChange={(
                                e: SelectChangeEvent<
                                  'UserGroup' | 'SharedUserGroup'
                                >
                              ) => {
                                props.setSourceTrainedModelGroupDisplayCondition(
                                  {
                                    ...props.domainData
                                      .sourceModelDisplayCondition,
                                    selectedUserGroupKind: e.target.value as
                                      | 'UserGroup'
                                      | 'SharedUserGroup',
                                  }
                                )

                                props.setSelectedSourceModelGroup(undefined)
                              }}
                              label='Select User Group Kind'
                            >
                              {userGroupKindList.map((kind) => (
                                <MenuItem
                                  data-testid={kind.kind}
                                  value={kind.kind}
                                  key={kind.kind}
                                >
                                  {kind.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </CustomTrainingPageParagraph>
                        <div className={classes.searchForm}>
                          <div className={classes.searchField}>
                            <SearchInput
                              placeholder='検索（モデルグループIDなど）'
                              value={tableSearchValue}
                              onChangeValue={(event) =>
                                handleChangeSearchValue(event.target.value)
                              }
                              onClickSearch={() => searchTableContent()}
                              onPressEnter={() => searchTableContent()}
                            />
                          </div>
                        </div>
                        <SelectableTable
                          displayNumber={
                            props.domainData.sourceModelGroupDisplayCondition
                              .displayNumber
                          }
                          headers={MODEL_GROUP_HEADERS}
                          rows={sourceModelGroupRows}
                          totalCount={
                            searchedSourceTrainedModelGroupListLength ??
                            props.domainData.sourceModelGroupDisplayCondition
                              .selectedUserGroupKind === 'SharedUserGroup'
                              ? props.domainData.sourceTrainedModelGroups
                                  .sharedUserGroup.length
                              : props.domainData.sourceTrainedModelGroups
                                  .userGroup.length
                          }
                          tableHeight={
                            TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                          }
                          onChangeDisplayNumber={(displayNumber: number) =>
                            handleChangeDisplayNumber(displayNumber)
                          }
                          selectedRowNumber={selectedSourceModelGroupIndex}
                          fixedColumnNumber={0}
                          page={
                            props.domainData.sourceModelGroupDisplayCondition
                              .pageNumber
                          }
                          sortOrder={{
                            key: props.domainData
                              .sourceModelGroupDisplayCondition.sortKey,
                            order:
                              props.domainData.sourceModelGroupDisplayCondition
                                .sortOrder,
                          }}
                          onClickRadio={(row: number) =>
                            selectedSourceModelTableRadio(row)
                          }
                          onClickOrderChange={(key: string) =>
                            changeTableSortOrder(key)
                          }
                          onClickPageChange={(pageNumber: number) =>
                            pageChange(pageNumber)
                          }
                        />
                      </CustomTrainingPageParagraph>
                    </CustomTrainingPageParagraph>
                  )}
                </div>
              ) : (
                <div className={classes.stepContainer}>
                  <SelectableTable
                    displayNumber={
                      props.domainData.sourceModelDisplayCondition.displayNumber
                    }
                    headers={BASE_MODEL_HEADERS}
                    rows={baseModelRows}
                    totalCount={
                      props.domainData.selectedSourceTrainedModelGroup
                        ?.trainedModels.length
                    }
                    tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                    onChangeDisplayNumber={(displayNumber: number) =>
                      handleChangeDisplayNumber(displayNumber)
                    }
                    selectedRowNumber={selectedBaseModelIndex}
                    fixedColumnNumber={0}
                    page={
                      props.domainData.sourceModelDisplayCondition.pageNumber
                    }
                    sortOrder={{
                      key: props.domainData.sourceModelDisplayCondition.sortKey,
                      order:
                        props.domainData.sourceModelDisplayCondition.sortOrder,
                    }}
                    onClickRadio={(row: number) =>
                      selectBaseModelTableRadio(row)
                    }
                    onClickRow={(row: number) => {
                      setSelectRowIndex(row)
                    }}
                    onClickOrderChange={(key: string) =>
                      changeTableSortOrder(key)
                    }
                    onClickPageChange={(pageNumber: number) =>
                      pageChange(pageNumber)
                    }
                  />
                </div>
              )}
            </>
          )
        }
      case 'SettingState':
        if (isUndefined(props.domainData.settingList)) {
          return <></>
        } else {
          return settingSubStep ? (
            isObjectRecognition(
              props.algorithms,
              props.domainData.selectedTrainingAlgorithm?.algorithmId ?? ''
            ) ? (
              <div className={classes.stepContainer}>
                <Box mt={2}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant='body1' className={classes.labelText}>
                        アドバンスド設定
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        className={classes.switch}
                        style={{ alignItems: 'center' }}
                      >
                        <Typography
                          width={'8rem'}
                          sx={{
                            color: globalTheme.palette.text.secondary,
                          }}
                        >
                          対象ワーク限定
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              data-testid='enabled'
                              checked={
                                props.domainData.extendedSetting
                                  .objectRecognition
                                  ?.isEnabledAnnotationTargeting
                              }
                              onChange={(event) => {
                                if (
                                  props.domainData.extendedSetting
                                    .objectRecognition
                                ) {
                                  props.setObjectRecognitionExtendedSetting({
                                    ...props.domainData.extendedSetting
                                      .objectRecognition,
                                    isEnabledAnnotationTargeting:
                                      event.target.checked,
                                  })
                                }
                              }}
                              color='secondary'
                            />
                          }
                          label=''
                        />
                      </Box>
                      {props.domainData.extendedSetting.objectRecognition
                        ?.isEnabledAnnotationTargeting && (
                        <Box
                          className={classes.switch}
                          style={{ alignItems: 'center' }}
                          mt={1}
                        >
                          <Typography
                            width={'8rem'}
                            sx={{
                              color: globalTheme.palette.text.secondary,
                            }}
                          >
                            隠れ率の閾値
                          </Typography>
                          <TextField
                            id='hidden-percentage'
                            className={classes.textField}
                            value={hiddenPercentage}
                            inputProps={{ maxLength: 3 }}
                            style={{ width: '5rem' }}
                            onChange={(e) => {
                              handleChangeHiddenPercentage(e.target.value)
                            }}
                            onBlur={handleBlurHiddenPercentage}
                          />
                          <Typography
                            sx={{
                              color: globalTheme.palette.text.secondary,
                            }}
                          >
                            %
                          </Typography>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </div>
            ) : (
              <div className={classes.stepContainer}>
                <CustomTrainingPageParagraph level={'chapter'}>
                  <Box mt={2}>
                    <FormControl
                      variant='outlined'
                      className={classes.algorithmSelectBox}
                    >
                      <InputLabel id='preprocessKind-label'>
                        前処理種別
                      </InputLabel>
                      <Select
                        defaultValue='NONE'
                        data-testid='preprocess-data-kind'
                        labelId='preprocessKind-label'
                        id='preprocessKind-outlined'
                        value={
                          props.domainData.extendedSetting.objectClassification
                            ?.preProcessKind
                        }
                        onChange={(e: SelectChangeEvent<PreProcessKind>) => {
                          props.setObjectClassificationExtendedSetting(
                            !isUndefined(
                              props.domainData.extendedSetting
                                .objectClassification
                            )
                              ? {
                                  ...props.domainData.extendedSetting
                                    .objectClassification,
                                  preProcessKind: e.target
                                    .value as PreProcessKind,
                                }
                              : undefined
                          )
                        }}
                        label='Select Preprocess Kind'
                      >
                        {props.domainData.preProcessKinds?.map((preProcess) => (
                          <MenuItem
                            data-testid={preProcess}
                            value={preProcess}
                            key={preProcess}
                          >
                            {preProcess}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </CustomTrainingPageParagraph>
                <Box>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant='body1' className={classes.labelText}>
                        アドバンスド設定
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List className={classes.cbList}>
                        <Box
                          sx={{
                            color: globalTheme.palette.text.secondary,
                          }}
                        >
                          Base Size
                        </Box>
                        <Box display='flex' alignItems='center'>
                          <Box m={2}>
                            <TextField
                              id='width'
                              className={classes.textField}
                              label='Width'
                              value={
                                props.domainData.extendedSetting
                                  .objectClassification?.baseSize.width ?? ''
                              }
                              onChange={(e) => {
                                onChangeBaseSizeWidth(e.target.value)
                              }}
                            />
                          </Box>
                          <Box m={2}>✖️</Box>
                          <Box m={2}>
                            <TextField
                              id='height'
                              className={classes.textField}
                              label='Height'
                              value={
                                props.domainData.extendedSetting
                                  .objectClassification?.baseSize.height ?? ''
                              }
                              onChange={(e) => {
                                onChangeBaseSizeHeight(e.target.value)
                              }}
                            />
                          </Box>
                        </Box>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </div>
            )
          ) : (
            <div className={classes.stepContainer}>
              <div className={classes.settingSelection}>
                <CustomTrainingPageParagraph
                  level={'part'}
                  title={'セッティング'}
                >
                  <div className={classes.outputFormatSelection}>
                    <RadioGroup
                      value={assignmentSetting}
                      row
                      onChange={(event) =>
                        setAssignmentSetting(JSON.parse(event.target.value))
                      }
                    >
                      <FormControlLabel
                        data-testid='setting-default'
                        value={false}
                        control={<Radio size='small' color='secondary' />}
                        label='デフォルト'
                        onClick={() =>
                          props.setSelectedSetting(undefined, false)
                        }
                      />
                      <FormControlLabel
                        data-testid='setting-assignment'
                        value={true}
                        control={<Radio size='small' color='secondary' />}
                        disabled={props.domainData.settingList.length === 0}
                        label='設定'
                        onClick={() => {
                          if (props.domainData.settingList.length > 0) {
                            const settingIndex = findNextEnabledSetting(0)
                            props.setSelectedSetting(
                              settingIndex !== undefined
                                ? props.domainData.settingList[settingIndex]
                                : undefined,
                              true
                            )
                          } else {
                            props.setSelectedSetting(
                              undefined,
                              assignmentSetting
                            )
                            setAssignmentSetting(false)
                          }
                        }}
                      />
                    </RadioGroup>
                  </div>
                </CustomTrainingPageParagraph>
              </div>
              {assignmentSetting && (
                <CustomTrainingPageParagraph level={'chapter'}>
                  <div className={classes.searchForm}>
                    <div className={classes.searchField}>
                      <SearchInput
                        placeholder='検索（セッティング名、セッティングIDなど）'
                        value={tableSearchValue}
                        onChangeValue={(event) =>
                          handleChangeSearchValue(event.target.value)
                        }
                        onClickSearch={() => searchTableContent()}
                        onPressEnter={() => searchTableContent()}
                      />
                    </div>
                  </div>
                  <SelectableTable
                    disableRowNumbers={settingTableDisableRowNumbers}
                    displayNumber={
                      props.domainData.settingDisplayCondition.displayNumber
                    }
                    headers={SETTING_HEADERS}
                    rows={settingsRows}
                    totalCount={
                      searchedSettingListLength ??
                      props.domainData.settingList.length
                    }
                    tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                    onChangeDisplayNumber={(displayNumber: number) =>
                      handleChangeDisplayNumber(displayNumber)
                    }
                    selectedRowNumber={selectedSettingIndex}
                    fixedColumnNumber={0}
                    page={props.domainData.settingDisplayCondition.pageNumber}
                    sortOrder={{
                      key: props.domainData.settingDisplayCondition.sortKey,
                      order: props.domainData.settingDisplayCondition.sortOrder,
                    }}
                    onClickRadio={(row: number) => selectSettingTableRadio(row)}
                    onClickRow={(row: number) => {
                      setSelectRowIndex(row)
                    }}
                    onClickOrderChange={(key: string) =>
                      changeTableSortOrder(key)
                    }
                    onClickPageChange={(pageNumber: number) =>
                      pageChange(pageNumber)
                    }
                  />
                </CustomTrainingPageParagraph>
              )}
            </div>
          )
        }
      case 'DatasetState':
        if (isUndefined(props.domainData.datasetList)) {
          return <></>
        } else {
          return (
            <>
              {!datasetSubStep ? (
                <div className={classes.stepContainer}>
                  <CustomTrainingPageParagraph
                    level={'part'}
                    title={'過去のデータセットを継承する'}
                  >
                    <div className={classes.outputFormatSelection}>
                      <RadioGroup
                        value={inherit}
                        row
                        onChange={(event) =>
                          setInherit(
                            event.target.value as 'inherit' | 'notInherit'
                          )
                        }
                      >
                        <FormControlLabel
                          value='inherit'
                          control={<Radio size='small' color='secondary' />}
                          disabled={
                            props.domainData
                              .selectedDestinationTrainedModelGroup
                              ?.trainedModels.length === 0
                          }
                          label='継承する'
                          onClick={() => {
                            const index = findNextEnabledInheritModel(0)
                            props.setSelectedInheritModel(
                              index !== undefined
                                ? props.domainData
                                    .selectedDestinationTrainedModelGroup
                                    ?.trainedModels[index]
                                : undefined
                            )
                          }}
                        />
                        <FormControlLabel
                          value='notInherit'
                          control={<Radio size='small' color='secondary' />}
                          label='継承しない'
                          onClick={() => props.setSelectedInheritModel()}
                        />
                      </RadioGroup>
                    </div>
                  </CustomTrainingPageParagraph>
                  {inherit === 'inherit' && (
                    <>
                      <Box ml={2} mb={2}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                              variant='body1'
                              className={classes.labelText}
                            >
                              継承するデータセット
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List className={classes.cbList}>
                              <SelectableTable
                                page={0}
                                headers={INHERIT_DATASET_HEADERS}
                                rows={inheritDatasetRows}
                                sortOrder={{
                                  key: props.domainData
                                    .inheritDatasetDisplayCondition.sortKey,
                                  order:
                                    props.domainData
                                      .inheritDatasetDisplayCondition.sortOrder,
                                }}
                                tableHeight={
                                  TABLE_HEADER_HEIGHT +
                                  10 * DISPLAY_NONE_RADIO_ROW_HEIGHT
                                }
                                fixedColumnNumber={0}
                                displayNoneRadio={true}
                                displayNoneEmptyCells={true}
                                onClickOrderChange={(key: string) => {
                                  return selectedInheritDatasetTableOrder(key)
                                }}
                              />
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                      <CustomTrainingPageParagraph
                        level={'part'}
                        title={'継承するモデルのバージョン'}
                      >
                        <SelectableTable
                          disableRowNumbers={InheritModelsDisableRowNumbers}
                          displayNumber={
                            props.domainData.destinationModelDisplayCondition
                              .displayNumber
                          }
                          headers={BASE_MODEL_HEADERS}
                          rows={destinationModelRows}
                          totalCount={
                            props.domainData
                              .selectedDestinationTrainedModelGroup
                              ?.trainedModels.length
                          }
                          tableHeight={
                            TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                          }
                          onChangeDisplayNumber={(displayNumber: number) =>
                            handleChangeDisplayNumber(displayNumber)
                          }
                          selectedRowNumber={selectedDestinationModelIndex}
                          fixedColumnNumber={0}
                          page={
                            props.domainData.destinationModelDisplayCondition
                              .pageNumber
                          }
                          sortOrder={{
                            key: props.domainData
                              .destinationModelDisplayCondition.sortKey,
                            order:
                              props.domainData.destinationModelDisplayCondition
                                .sortOrder,
                          }}
                          onClickRadio={(row: number) =>
                            selectDestinationModelTableRadio(row)
                          }
                          onClickRow={(row: number) => {
                            setSelectRowIndex(row)
                          }}
                          onClickOrderChange={(key: string) =>
                            changeTableSortOrder(key)
                          }
                          onClickPageChange={(pageNumber: number) =>
                            pageChange(pageNumber)
                          }
                        />
                      </CustomTrainingPageParagraph>
                    </>
                  )}
                </div>
              ) : (
                <div className={classes.stepContainer}>
                  <Dialog
                    maxWidth='md'
                    fullWidth={true}
                    aria-labelledby='customized-dialog-title'
                    open={openDatasetDialog}
                  >
                    <DialogContent className={classes.dataCreateDialog}>
                      <CreateDatasetPage
                        mode='dialog'
                        handleCloseDialog={(datasetId?: string) =>
                          handleClickCloseDatasetDialog(datasetId)
                        }
                        algorithmId={
                          props.domainData.selectedTrainingAlgorithm
                            ?.algorithmId
                        }
                      />
                    </DialogContent>
                  </Dialog>
                  <div className={classes.postAddButton}>
                    <Typography className={classes.sectionTitle}>
                      {'データセット'}
                    </Typography>
                    <div>
                      <PostAddIconButton
                        label={'新規追加'}
                        onClick={handleClickOpenDatasetDialog}
                      />
                    </div>
                  </div>
                  <CustomTrainingPageParagraph level={'chapter'}>
                    <div className={classes.searchForm}>
                      <div className={classes.searchField}>
                        <SearchInput
                          placeholder='検索（データセット名、データセットIDなど）'
                          value={tableSearchValue}
                          onChangeValue={(event) =>
                            handleChangeSearchValue(event.target.value)
                          }
                          onClickSearch={() => searchTableContent()}
                          onPressEnter={() => searchTableContent()}
                        />
                      </div>
                    </div>
                    <SelectableTable
                      displayNumber={
                        props.domainData.datasetDisplayCondition.displayNumber
                      }
                      headers={DATASET_HEADERS}
                      rows={datasetRows}
                      totalCount={
                        searchedDatasetListLength ??
                        props.domainData.datasetList.length
                      }
                      onChangeDisplayNumber={(displayNumber: number) =>
                        handleChangeDisplayNumber(displayNumber)
                      }
                      tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                      selectedRowNumber={selectedDatasetIndex}
                      fixedColumnNumber={0}
                      page={props.domainData.datasetDisplayCondition.pageNumber}
                      sortOrder={{
                        key: props.domainData.datasetDisplayCondition.sortKey,
                        order:
                          props.domainData.datasetDisplayCondition.sortOrder,
                      }}
                      onClickRadio={(row: number) =>
                        selectDatasetTableRadio(row)
                      }
                      onClickRow={(row: number) => {
                        setSelectRowIndex(row)
                      }}
                      onClickOrderChange={(key: string) =>
                        changeTableSortOrder(key)
                      }
                      onClickPageChange={(pageNumber: number) =>
                        pageChange(pageNumber)
                      }
                    />
                  </CustomTrainingPageParagraph>
                </div>
              )}
            </>
          )
        }
      case 'OutputFormatState':
        return (
          <>
            <div className={classes.stepContainer}>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'アウトプット形式を選択'}
              >
                <div className={classes.outputFormatSelection}>
                  <RadioGroup
                    value={props.domainData.selectedCustomTrainingGenerateKind}
                    row
                    onChange={(event) =>
                      props.setSelectedCustomTrainingGenerateKind(
                        event.target.value as 'EdgeImage' | 'CustomModel'
                      )
                    }
                  >
                    <FormControlLabel
                      data-testid='custom-model-only'
                      value='CustomModel'
                      control={<Radio size='small' color='secondary' />}
                      label='カスタムモデル作成のみ'
                    />
                    <FormControlLabel
                      data-testid='custom-model-all'
                      value='EdgeImage'
                      control={<Radio size='small' color='secondary' />}
                      label='全部（カスタムモデル＋エッジイメージのビルド）'
                    />
                  </RadioGroup>
                </div>
              </CustomTrainingPageParagraph>
              {props.domainData.selectedCustomTrainingGenerateKind ===
                'EdgeImage' && (
                <>
                  <CustomTrainingPageParagraph
                    level={'part'}
                    title={'エッジイメージ'}
                  >
                    <Box ml={2}>
                      <CustomTrainingPageParagraph
                        level={'part'}
                        title={'推論アルゴリズム'}
                      >
                        {props.domainData.selectedTrainingAlgorithm
                          ?.algorithmId && (
                          <Box ml={2} mb={2}>
                            <TextField
                              id='algorithm'
                              className={classes.textField}
                              variant='standard'
                              InputProps={{
                                readOnly: true,
                              }}
                              value={
                                props.algorithms.find(
                                  (algorithm) =>
                                    algorithm.algorithmId ===
                                    props.domainData.selectedTrainingAlgorithm
                                      ?.algorithmId
                                )?.metadata.name.ja
                              }
                            />
                          </Box>
                        )}
                      </CustomTrainingPageParagraph>
                      <CustomTrainingPageParagraph>
                        <SelectableTable
                          headers={INFERENCE_ALGORITHM_HEADERS}
                          fixedColumnNumber={0}
                          selectedRowNumber={
                            selectedInferenceAlgorithmVersionIndex
                          }
                          page={0}
                          rows={inferenceAlgorithmVersionRows}
                          tableHeight={
                            TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                          }
                          onClickRadio={(row: number) =>
                            selectInferenceAlgorithmTableRadio(row)
                          }
                        />
                      </CustomTrainingPageParagraph>
                    </Box>
                  </CustomTrainingPageParagraph>
                  <Box ml={2} mb={2}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant='body1'
                          className={classes.labelText}
                        >
                          アドバンスド設定
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List className={classes.cbList}>
                          <Box
                            sx={{ color: globalTheme.palette.text.secondary }}
                          >
                            Container Architecture
                          </Box>
                          <CheckableTable
                            headers={CONTAINER_ARCHITECTURE_HEADERS}
                            fixedColumnNumber={0}
                            selectedRows={allAlgorithmContainerImageList?.map(
                              (item) =>
                                props.domainData.selectedBaseInferenceContainerImageIds.includes(
                                  item.baseInferenceContainerImageId
                                )
                            )}
                            page={0}
                            noEmptyRows={true}
                            rows={allAlgorithmContainerImageRows ?? []}
                            onClickCheckbox={(row: number) => {
                              const idList = allAlgorithmContainerImageIds ?? []
                              props.setSelectedContainerArchitecture(
                                idList[row]
                              )
                            }}
                          />
                          <Box
                            sx={{ color: globalTheme.palette.text.secondary }}
                            mt={2}
                          >
                            System Evaluation
                          </Box>
                          <Box display='flex' alignItems='center'>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.domainData.isSystemEvaluation}
                                  onChange={(event) =>
                                    props.setIsSystemEvaluation(
                                      event.target.checked
                                    )
                                  }
                                  color='secondary'
                                />
                              }
                              label=''
                            />
                            <Box display='flex'>
                              {props.domainData.isSystemEvaluation === true
                                ? 'ON'
                                : 'OFF'}
                            </Box>
                          </Box>
                          <Box
                            sx={{ color: globalTheme.palette.text.secondary }}
                            mt={2}
                          >
                            Transfer to Connected Service
                          </Box>
                          <Box display='flex' alignItems='center'>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.domainData.isTransfer}
                                  onChange={(event) =>
                                    props.setIsTransfer(event.target.checked)
                                  }
                                  color='secondary'
                                />
                              }
                              label=''
                            />
                            <Box display='flex'>
                              {props.domainData.isTransfer === true
                                ? 'ON'
                                : 'OFF'}
                            </Box>
                          </Box>
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              )}
            </div>
          </>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'}>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'カスタム学習情報'}
              >
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.mlPipelinesMetaData?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateMlPipelinesMetaDataName(e.target.value),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.mlPipelinesMetaData?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateMlPipelinesMetaDataRemarks(e.target.value),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'custom-training-input'}
                />
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'カスタムモデル情報'}
              >
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.customModelMetaData?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateCustomModelMetaDataName(e.target.value),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.customModelMetaData?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateCustomModelMetaDataRemarks(e.target.value),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'custom-model-input'}
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingErrorMessage {...props} />
            <CustomTrainingInfoConfirmView {...props} />
            <CustomModelInfoConfirmView
              {...props}
              modelGroupToDetail={modelGroupToDetail}
            />
            <TrainingAlgorithmConfirmView
              {...props}
              trainingAlgorithmToDetail={trainingAlgorithmToDetail}
            />
            <ExecutionInfoConfirmView
              {...props}
              trainedModelToDetail={trainedModelToDetail}
              datasetToDetail={datasetToDetail}
              settingToDetail={settingToDetail}
              inheritDatasetRows={inheritDatasetRows}
            />
            {!isUndefined(props.domainData.selectedClassSet) && (
              <ClassSetConfirmView
                {...props}
                classSetToDetail={classSetToDetail}
              />
            )}
            {props.domainData.selectedCustomTrainingGenerateKind ===
              'EdgeImage' &&
            props.domainData.selectedInferenceAlgorithmVersion ? (
              <EdgeImageInfoConfirmView
                {...props}
                selectedAlgorithmContainerImageList={
                  allAlgorithmContainerImageList ?? []
                }
                selectedAlgorithmContainerImageRows={
                  allAlgorithmContainerImageRows ?? []
                }
                selectedAlgorithmContainerImageIds={
                  allAlgorithmContainerImageIds ?? []
                }
                inferenceAlgorithmVersionToDetail={
                  inferenceAlgorithmVersionToDetail
                }
              />
            ) : (
              <Box mb={1}></Box>
            )}
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Box pl={7}>
          <BreadcrumbsComponent
            breadcrumbsPath={[
              {
                name: 'カスタム学習一覧',
                path: 'custom-trainings',
              },
              {
                name: 'カスタム学習開始',
                path: 'entry',
              },
            ]}
          />
        </Box>
        <div className={classes.head}>
          <div className={classes.flexAndBetween}>
            <h2
              className={classes.pageTitle}
              data-testid='custom-training-title'
            >
              カスタム学習
            </h2>
            <div className={classes.confirmButton}>
              <Tooltip title='設定情報' placement='bottom'>
                <IconButton
                  onClick={() => {
                    setOpenConfirmDialog(true)
                  }}
                >
                  <ReceiptLongIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={classes.stepper}>
            <CommonStepper
              stepLabels={STEP_NAMES}
              activeStepIndex={currentStep}
            />
          </div>
        </div>
        <div className={classes.content} ref={contentRef}>
          {getStepContent(props)}
        </div>
        <div className={classes.footerButtons}>
          <div className={classes.footer}>
            {!(
              props.appState.customTrainingState === 'TrainingAlgorithmState' &&
              !trainingAlgorithmSubStep
            ) ? (
              <Button
                variant='contained'
                color='primary'
                disabled={false}
                onClick={() => prevPageAction()}
                className={classes.leftButton}
              >
                {'戻る'}
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              data-testid='next-step'
              variant='contained'
              color='primary'
              disabled={!enableNextButton}
              onClick={() => nextPageAction()}
              className={classes.rightButton}
            >
              {props.appState.customTrainingState === 'ExecuteState'
                ? '開始'
                : '次へ'}
            </Button>
          </div>
        </div>
        <MLPipelineCompleteDialog
          open={
            !isUndefined(props.domainData.executionInfo?.mlPipelineId) &&
            !isUndefined(props.domainData.executionInfo?.trainingStepId)
          }
          value={props.domainData.executionInfo?.mlPipelineId ?? ''}
          secondValueItem={{
            label: '学習ステップ ID',
            value: props.domainData.executionInfo?.trainingStepId ?? '',
          }}
          handleClose={() => history.push('/custom-trainings')}
          label={'MLパイプラインID'}
          dialogText={'正常に学習を開始しました。'}
          data-testid={'ml-pipeline-id'}
        />
        {tableDialog}
        <InformationDialog
          onClose={() => setIsOpenDownloadFailedDialog(false)}
          open={isOpenDownloadFailedDialog}
          infoData={[
            {
              item: '現在ダウンロードはできません',
              info: '',
            },
          ]}
        />
        <ConfirmViewerDialog
          maxWidth='md'
          open={openConfirmDialog}
          message={
            <Box width='630px'>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.mlPipelinesMetaData !== undefined &&
                      props.domainData.mlPipelinesMetaData?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <CustomTrainingInfoConfirmView {...props} />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.selectedDestinationTrainedModelGroup !==
                        undefined &&
                      props.domainData.customModelMetaData !== undefined &&
                      props.domainData.customModelMetaData?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <CustomModelInfoConfirmView
                  {...props}
                  modelGroupToDetail={modelGroupToDetail}
                />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.appState.customTrainingSubState
                        .trainingAlgorithmSubState === 'Selected'
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <TrainingAlgorithmConfirmView
                  {...props}
                  trainingAlgorithmToDetail={trainingAlgorithmToDetail}
                />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.appState.customTrainingSubState
                        .baseModelSubState === 'Selected' &&
                      props.appState.customTrainingSubState.datasetSubState ===
                        'Selected' &&
                      props.appState.customTrainingSubState.settingSubState ===
                        'Selected'
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <ExecutionInfoConfirmView
                  {...props}
                  trainedModelToDetail={trainedModelToDetail}
                  datasetToDetail={datasetToDetail}
                  settingToDetail={settingToDetail}
                  inheritDatasetRows={inheritDatasetRows}
                />
              </Box>
              {!isUndefined(props.domainData.selectedClassSet) && (
                <Box display='flex'>
                  <Box display='flex' paddingTop={2}>
                    <Checkbox
                      sx={
                        !isUndefined(
                          props.domainData.extendedSetting.objectClassification
                        ) &&
                        props.appState.customTrainingSubState
                          .settingSubState === 'Selected'
                          ? {
                              color: 'blue',
                              '&.Mui-checked': {
                                color: 'blue',
                              },
                            }
                          : undefined
                      }
                      checkedIcon={<CheckCircleIcon />}
                      disabled
                      checked
                    />
                  </Box>
                  <ClassSetConfirmView
                    {...props}
                    classSetToDetail={classSetToDetail}
                  />
                </Box>
              )}
              {props.domainData.selectedCustomTrainingGenerateKind ===
                'EdgeImage' &&
              props.domainData.selectedInferenceAlgorithmVersion ? (
                <Box display='flex'>
                  <Box display='flex' paddingTop={2}>
                    <Checkbox
                      sx={
                        props.appState.customTrainingSubState
                          .outputFormatSubState === 'Selected'
                          ? {
                              color: 'blue',
                              '&.Mui-checked': {
                                color: 'blue',
                              },
                            }
                          : undefined
                      }
                      checkedIcon={<CheckCircleIcon />}
                      disabled
                      checked
                    />
                  </Box>
                  <EdgeImageInfoConfirmView
                    {...props}
                    selectedAlgorithmContainerImageList={
                      allAlgorithmContainerImageList ?? []
                    }
                    selectedAlgorithmContainerImageRows={
                      allAlgorithmContainerImageRows ?? []
                    }
                    selectedAlgorithmContainerImageIds={
                      allAlgorithmContainerImageIds ?? []
                    }
                    inferenceAlgorithmVersionToDetail={
                      inferenceAlgorithmVersionToDetail
                    }
                  />
                </Box>
              ) : (
                <Box mb={1}></Box>
              )}
            </Box>
          }
          handleClose={() => setOpenConfirmDialog(false)}
        />

        <GlobalLoading open={props.appState.inProgress} />
      </div>
    </>
  )
}

export const CustomTrainingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomTraining))

const useTable = (
  props: Props,
  query: CustomTrainingQueryParameters,
  trainingAlgorithmSubStep?: boolean,
  baseModelSubStep?: boolean,
  datasetSubStep?: boolean,
  settingSubStep?: boolean
) => {
  const globalTheme = useTheme()
  const [isOpenDownloadFailedDialog, setIsOpenDownloadFailedDialog] =
    useState<boolean>(false)

  /** ベースモデルのモデルグループのラジオボタンの選択された時に切り替える処理 */
  const selectedSourceModelTableRadio = (row: number) => {
    const modelGroup = adjustSourceModelGroupTableRow[row]
    props.setSelectedSourceModelGroup(modelGroup)
  }

  /** 登録先モデルグループのラジオボタンの選択された時に切り替える処理 */
  const selectedDestinationTrainedModelTableRadio = (row: number) => {
    const modelGroup = adjustDestinationTrainedModelGroupTableRow[row]
    props.setSelectedDestinationTrainedModelGroup(modelGroup)
  }

  /** ベースモデルのラジオボタンの選択された時に切り替える処理 */
  const selectBaseModelTableRadio = (row: number) => {
    const baseModel = adjustBaseModelTableRow[row]
    props.setSelectedBaseModel(baseModel)
  }

  /** 継承モデルのラジオボタンの選択された時に切り替える処理 */
  const selectDestinationModelTableRadio = (row: number) => {
    const destinationModel = adjustDestinationModelTableRow[row]
    props.setSelectedInheritModel(destinationModel)
  }

  /** クラスセットのラジオボタン押下時処理 */
  const selectClassSetTableRadio = (row: number) => {
    const classSet = adjustClassSetTableRow[row]
    props.setSelectedClassSet(classSet)
  }

  /** データセットのラジオボタン押下時処理 */
  const selectDatasetTableRadio = (row: number) => {
    const dataset = adjustDatasetTableRow[row]
    props.setSelectedDataset(dataset)
  }

  /** セッティングのラジオボタン押下時処理 */
  const selectSettingTableRadio = (row: number) => {
    const setting = adjustSettingTableRow[row]
    props.setSelectedSetting(setting, true)
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    switch (props.appState.customTrainingState) {
      case 'TrainingAlgorithmState':
        if (trainingAlgorithmSubStep) {
          props.setClassSetDisplayCondition({
            ...props.domainData.classSetDisplayCondition,
            sortKey: key,
            sortOrder:
              props.domainData.classSetDisplayCondition.sortKey === key
                ? props.domainData.classSetDisplayCondition.sortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.classSetDisplayCondition.sortOrder,
          })
        }
        /** ソートキー、ソートオーダーをセット */
        props.setTrainingDisplayCondition({
          ...props.domainData.trainingAlgorithmVersionDisplayCondition,
          sortKey: key,
          sortOrder:
            props.domainData.trainingAlgorithmVersionDisplayCondition
              .sortKey === key
              ? props.domainData.trainingAlgorithmVersionDisplayCondition
                  .sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.trainingAlgorithmVersionDisplayCondition
                  .sortOrder,
        })
        break
      case 'ModelGroupState':
        /** ソートキー、ソートオーダーをセット */
        props.setDestinationTrainedModelGroupDisplayCondition({
          ...props.domainData.destinationModelGroupDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.destinationModelGroupDisplayCondition.sortKey ===
            key
              ? props.domainData.destinationModelGroupDisplayCondition
                  .sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.destinationModelGroupDisplayCondition
                  .sortOrder,
        })
        break
      case 'BaseModelState':
        if (baseModelSubStep) {
          /** ソートキー、ソートオーダーをセット */
          props.setSourceModelDisplayCondition({
            ...props.domainData.sourceModelDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.sourceModelDisplayCondition.sortKey === key
                ? props.domainData.sourceModelDisplayCondition.sortOrder ===
                  'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.sourceModelDisplayCondition.sortOrder,
          })
        } else {
          /** ソートキー、ソートオーダーをセット */
          props.setSourceTrainedModelGroupDisplayCondition({
            ...props.domainData.sourceModelGroupDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.sourceModelGroupDisplayCondition.sortKey === key
                ? props.domainData.sourceModelGroupDisplayCondition
                    .sortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.sourceModelGroupDisplayCondition.sortOrder,
          })
        }
        break
      case 'DatasetState':
        if (!datasetSubStep) {
          /** ソートキー、ソートオーダーをセット */
          props.setDestinationModelDisplayCondition({
            ...props.domainData.destinationModelDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.destinationModelDisplayCondition.sortKey === key
                ? props.domainData.destinationModelDisplayCondition
                    .sortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.destinationModelDisplayCondition.sortOrder,
          })
        } else {
          /** ソートキー、ソートオーダーをセット */
          props.setDatasetDisplayCondition({
            ...props.domainData.datasetDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.datasetDisplayCondition.sortKey === key
                ? props.domainData.datasetDisplayCondition.sortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.datasetDisplayCondition.sortOrder,
          })
        }
        break
      case 'SettingState':
        /** ソートキー、ソートオーダーをセット */
        props.setSettingDisplayCondition({
          ...props.domainData.settingDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.settingDisplayCondition.sortKey === key
              ? props.domainData.settingDisplayCondition.sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.settingDisplayCondition.sortOrder,
        })
        break
      default:
        break
    }
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    switch (props.appState.customTrainingState) {
      case 'ModelGroupState':
        props.setDestinationTrainedModelGroupDisplayCondition({
          ...props.domainData.destinationModelGroupDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'BaseModelState':
        if (baseModelSubStep) {
          props.setSourceModelDisplayCondition({
            ...props.domainData.sourceModelDisplayCondition,
            pageNumber: pageNumber,
          })
        } else {
          props.setSourceTrainedModelGroupDisplayCondition({
            ...props.domainData.sourceModelGroupDisplayCondition,
            pageNumber: pageNumber,
          })
        }
        break
      case 'DatasetState':
        if (!datasetSubStep) {
          props.setDestinationModelDisplayCondition({
            ...props.domainData.destinationModelDisplayCondition,
            pageNumber: pageNumber,
          })
        } else {
          props.setDatasetDisplayCondition({
            ...props.domainData.datasetDisplayCondition,
            pageNumber: pageNumber,
          })
        }
        break
      case 'SettingState':
        props.setSettingDisplayCondition({
          ...props.domainData.settingDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      default:
        break
    }
  }

  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')

  /** 検索結果結果の配列の長さを保持(ClassSet) */
  const [searchedClassSetListLength, setSearchedClassSetListLength] = useState<
    number | undefined
  >(undefined)

  /** 検索結果結果の配列の長さを保持(Dataset) */
  const [searchedDatasetListLength, setSearchedDatasetListLength] = useState<
    number | undefined
  >(undefined)

  /** 検索結果結果の配列の長さを保持(Setting) */
  const [searchedSettingListLength, setSearchedSettingListLength] = useState<
    number | undefined
  >(undefined)

  /** 検索結果結果の配列の長さを保持(sourceModelGroupList) */
  const [
    searchedSourceTrainedModelGroupListLength,
    setSearchedSourceTrainedModelGroupListLength,
  ] = useState<number | undefined>(undefined)

  /** 検索結果結果の配列の長さを保持(destinationTrainedModelGroup) */
  const [
    searchedDestinationTrainedModelGroupListLength,
    setSearchedDestinationTrainedModelGroupListLength,
  ] = useState<number | undefined>(undefined)

  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    switch (props.appState.customTrainingState) {
      case 'TrainingAlgorithmState':
        if (trainingAlgorithmSubStep) {
          const maxPageNumber =
            Math.ceil(props.domainData.classSets.length / displayNumber) - 1
          if (
            props.domainData.classSetDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setDestinationTrainedModelGroupDisplayCondition({
              ...props.domainData.classSetDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setDestinationTrainedModelGroupDisplayCondition({
              ...props.domainData.classSetDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        break
      case 'ModelGroupState':
        {
          const maxPageNumber =
            Math.ceil(
              props.domainData.destinationTrainedModelGroups.length /
                displayNumber
            ) - 1
          if (
            props.domainData.destinationModelGroupDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setDestinationTrainedModelGroupDisplayCondition({
              ...props.domainData.destinationModelGroupDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setDestinationTrainedModelGroupDisplayCondition({
              ...props.domainData.destinationModelGroupDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
      case 'BaseModelState':
        if (baseModelSubStep) {
          {
            if (!props.domainData.selectedSourceTrainedModelGroup) return
            const maxPageNumber =
              Math.ceil(
                props.domainData.selectedSourceTrainedModelGroup.trainedModels
                  .length / displayNumber
              ) - 1
            if (
              props.domainData.sourceModelDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setSourceModelDisplayCondition({
                ...props.domainData.sourceModelDisplayCondition,
                displayNumber: displayNumber ?? 5,
              })
            } else {
              /** 表示数を変更 */
              props.setSourceModelDisplayCondition({
                ...props.domainData.sourceModelDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 5,
              })
            }
          }
          return
        } else {
          {
            const maxPageNumber =
              Math.ceil(
                props.domainData.destinationTrainedModelGroups.length /
                  displayNumber
              ) - 1
            if (
              props.domainData.sourceModelGroupDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setSourceTrainedModelGroupDisplayCondition({
                ...props.domainData.sourceModelGroupDisplayCondition,
                displayNumber: displayNumber ?? 5,
              })
            } else {
              /** 表示数を変更 */
              props.setSourceTrainedModelGroupDisplayCondition({
                ...props.domainData.sourceModelGroupDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 5,
              })
            }
          }
          return
        }
      case 'DatasetState':
        if (!datasetSubStep) {
          {
            if (!props.domainData.selectedDestinationTrainedModelGroup) return
            const maxPageNumber =
              Math.ceil(
                props.domainData.selectedDestinationTrainedModelGroup
                  .trainedModels.length / displayNumber
              ) - 1
            if (
              props.domainData.destinationModelDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setDestinationModelDisplayCondition({
                ...props.domainData.destinationModelDisplayCondition,
                displayNumber: displayNumber ?? 10,
              })
            } else {
              /** 表示数を変更 */
              props.setDestinationModelDisplayCondition({
                ...props.domainData.destinationModelDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 10,
              })
            }
          }
          return
        } else {
          {
            const maxPageNumber =
              Math.ceil(props.domainData.datasetList.length / displayNumber) - 1
            if (
              props.domainData.datasetDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setDatasetDisplayCondition({
                ...props.domainData.datasetDisplayCondition,
                displayNumber: displayNumber ?? 10,
              })
            } else {
              /** 表示数を変更 */
              props.setDatasetDisplayCondition({
                ...props.domainData.datasetDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 10,
              })
            }
          }
        }
        return
      case 'SettingState':
        {
          const maxPageNumber =
            Math.ceil(props.domainData.settingList.length / displayNumber) - 1
          if (
            props.domainData.settingDisplayCondition.pageNumber <= maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setSettingDisplayCondition({
              ...props.domainData.settingDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setSettingDisplayCondition({
              ...props.domainData.settingDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
    }
  }

  /** 学習アルゴリズムのテーブルをソートして返す */
  const trainingAlgorithmRows = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm?.algorithmId)) {
      let selectedAlgorithm = props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
      )[0]
      props.algorithms.find((algorithm) => {
        if (
          algorithm.algorithmId ==
          props.domainData.selectedTrainingAlgorithm?.algorithmId
        ) {
          selectedAlgorithm = algorithm
        }
      })
      if (
        selectedAlgorithm.algorithmId !==
        props.domainData.selectedTrainingAlgorithm?.algorithmId
      ) {
        return []
      }
      let rowData =
        selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.map(
          (data) => {
            return {
              version: data.algorithmVersion,
              releasedAt: data.releasedAt
                ? formatDateTimeSec(data.releasedAt.toDate())
                : undefined,
              remarks: data.metadata.remarks.ja,
            }
          }
        )
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.trainingAlgorithmVersionDisplayCondition.sortKey ===
        'version'
      ) {
        rowData = rowData.sort(
          (
            item1: {
              version: VersionWithPreRelease
              releasedAt: string | undefined
              remarks: string
            },
            item2: {
              version: VersionWithPreRelease
              releasedAt: string | undefined
              remarks: string
            }
          ) => {
            return compareVersionsWithPreRelease(
              item1.version,
              item2.version,
              props.domainData.trainingAlgorithmVersionDisplayCondition
                .sortOrder
            )
          }
        )
      }
      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '' || isUndefined(value)) {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            if (key == 'version') {
              if (isVersionWithPreRelease(value)) {
                return <Typography key={key}>{value.displayName}</Typography>
              } else {
                return (
                  <Box key={key} sx={{ color: 'text.secondary' }}>
                    <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                  </Box>
                )
              }
            }
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [
    props.domainData.trainingAlgorithmVersionDisplayCondition,
    props.domainData.selectedTrainingAlgorithm?.algorithmId,
  ])

  /** コンテナイメージのテーブルを返す */
  const inferenceAlgorithmVersionRows = useMemo(() => {
    let newInferenceAlgorithmArray = props.domainData.inferenceAlgorithmVersions
    newInferenceAlgorithmArray = newInferenceAlgorithmArray.sort(
      (item, item2) => {
        return compareVersionsWithPreRelease(
          item.algorithmVersion,
          item2.algorithmVersion,
          'desc'
        )
      }
    )
    newInferenceAlgorithmArray = newInferenceAlgorithmArray.sort(
      (item, item2) => {
        if (
          item.inferenceAlgorithmVersion === item2.inferenceAlgorithmVersion
        ) {
          return compareVersions(
            item.containerInterfaceVersion,
            item2.containerInterfaceVersion,
            'desc'
          )
        }
        return 0
      }
    )

    newInferenceAlgorithmArray = newInferenceAlgorithmArray.sort(
      (item, item2) => {
        if (
          item.containerInterfaceVersion.displayName ===
          item2.containerInterfaceVersion.displayName
        ) {
          return compareVersionsWithBuild(
            item.inferenceCodeVersion,
            item2.inferenceCodeVersion,
            'desc'
          )
        }
        return 0
      }
    )

    const rowData = newInferenceAlgorithmArray.map((data) => {
      return {
        version: data.inferenceAlgorithmVersion,
        releasedAt: data.releasedAt
          ? formatDateTimeSec(data.releasedAt.toDate())
          : undefined,
        remarks: data.metadata?.remarks,
        containerInterfaceVersion: data.containerInterfaceVersion.displayName,
        inferenceCodeVersion: data.inferenceCodeVersion.displayName,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else {
          return <Typography key={key}>{value}</Typography>
        }
      })
    )
  }, [props.domainData.inferenceAlgorithmVersions])

  /** 推論アルゴリズムテーブルの選択状態 */
  const selectedInferenceAlgorithmVersionIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedInferenceAlgorithmVersion))
      return -1

    return props.domainData.inferenceAlgorithmVersions.findIndex(
      (inferenceAlgorithmVersion) =>
        inferenceAlgorithmVersion.inferenceAlgorithmVersion ===
          props.domainData.selectedInferenceAlgorithmVersion
            ?.inferenceAlgorithmVersion &&
        inferenceAlgorithmVersion.containerInterfaceVersion.displayName ===
          props.domainData.selectedInferenceAlgorithmVersion
            ?.containerInterfaceVersion.displayName &&
        inferenceAlgorithmVersion.inferenceCodeVersion.displayName ===
          props.domainData.selectedInferenceAlgorithmVersion
            ?.inferenceCodeVersion.displayName
    )
  }, [
    props.domainData.inferenceAlgorithmVersions,
    props.domainData.selectedInferenceAlgorithmVersion,
  ])

  /** 表示対象のベースモデルデータ */
  const adjustBaseModelTableRow = useMemo(() => {
    if (
      !isUndefined(props.domainData.sourceModelDisplayCondition) &&
      !isUndefined(props.domainData.selectedSourceTrainedModelGroup)
    ) {
      let newBaseModelArray =
        props.domainData.selectedSourceTrainedModelGroup?.trainedModels ?? []
      /** ソートキー、ソートオーダーによる並び替え */
      newBaseModelArray = newBaseModelArray.sort((item, item2) => {
        return compareVersions(
          item.trainedModelGroupVersion,
          item2.trainedModelGroupVersion,
          props.domainData.sourceModelDisplayCondition.sortOrder
        )
      })
      /** 表示するベースモデルの整形 */
      return newBaseModelArray.slice(
        props.domainData.sourceModelDisplayCondition.displayNumber *
          props.domainData.sourceModelDisplayCondition.pageNumber,
        (props.domainData.sourceModelDisplayCondition.pageNumber + 1) *
          props.domainData.sourceModelDisplayCondition.displayNumber
      )
    }
    return props.domainData.selectedSourceTrainedModelGroup?.trainedModels ?? []
  }, [
    props.domainData.sourceModelDisplayCondition,
    props.domainData.selectedSourceTrainedModelGroup?.trainedModels,
  ])

  /** 表示対象のデータセットデータ */
  const adjustDatasetTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.datasetDisplayCondition)) {
      let newDatasetArray = props.domainData.datasetList
      /** 検索ワードから検索 */
      if (props.domainData.datasetDisplayCondition.searchValue) {
        newDatasetArray = newDatasetArray.filter(
          (item) =>
            item.name.startsWith(
              props.domainData.datasetDisplayCondition.searchValue
            ) ||
            item.datasetId.startsWith(
              props.domainData.datasetDisplayCondition.searchValue
            ) ||
            item.datasetId ===
              props.domainData.datasetDisplayCondition.searchValue ||
            item.name === props.domainData.datasetDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedDatasetListLength(newDatasetArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedDatasetListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (props.domainData.datasetDisplayCondition.sortKey === 'createdAt') {
        newDatasetArray = newDatasetArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.datasetDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }
      /** 表示するデータセットの整形 */
      return newDatasetArray.slice(
        props.domainData.datasetDisplayCondition.displayNumber *
          props.domainData.datasetDisplayCondition.pageNumber,
        (props.domainData.datasetDisplayCondition.pageNumber + 1) *
          props.domainData.datasetDisplayCondition.displayNumber
      )
    }
    return props.domainData.datasetList
  }, [props.domainData.datasetDisplayCondition, props.domainData.datasetList])

  /** 表示対象のセッティングデータ */
  const adjustSettingTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.settingDisplayCondition)) {
      let newSettingArray = props.domainData.settingList
      /** 検索ワードから検索 */
      if (props.domainData.settingDisplayCondition.searchValue) {
        newSettingArray = newSettingArray.filter(
          (item) =>
            item.settingId ===
              props.domainData.settingDisplayCondition.searchValue ||
            item.name ===
              props.domainData.settingDisplayCondition.searchValue ||
            item.name.startsWith(
              props.domainData.settingDisplayCondition.searchValue
            ) ||
            item.settingId.startsWith(
              props.domainData.settingDisplayCondition.searchValue
            )
        )
        /** 検索後の配列の長さをセット */
        setSearchedSettingListLength(newSettingArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedSettingListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (props.domainData.settingDisplayCondition.sortKey === 'createdAt') {
        newSettingArray = newSettingArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.settingDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }
      /** 表示するセッティングの整形 */
      return newSettingArray.slice(
        props.domainData.settingDisplayCondition.displayNumber *
          props.domainData.settingDisplayCondition.pageNumber,
        (props.domainData.settingDisplayCondition.pageNumber + 1) *
          props.domainData.settingDisplayCondition.displayNumber
      )
    }
    return props.domainData.settingList
  }, [props.domainData.settingDisplayCondition, props.domainData.settingList])

  /** クラスセットの表示対象のセッティングデータ */
  const adjustClassSetTableRow = useMemo(() => {
    let newClassSetArray = props.domainData.classSets
    /** 検索ワードから検索 */
    if (props.domainData.classSetDisplayCondition.searchValue) {
      newClassSetArray = newClassSetArray.filter(
        (item) =>
          item.classSetId ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetName ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetId.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          ) ||
          item.classSetName.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          )
      )
      /** 検索後の配列の長さをセット */
      setSearchedClassSetListLength(newClassSetArray.length)
    } else {
      /** 検索後の配列の長さをセット */
      setSearchedClassSetListLength(undefined)
    }
    /** ソートキー、ソートオーダーによる並び替え */
    if (props.domainData.classSetDisplayCondition.sortKey === 'createdAt') {
      newClassSetArray = newClassSetArray.sort((item, item2) => {
        return (
          ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
            .toDate()
            .getTime() -
            (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime()) *
          (props.domainData.classSetDisplayCondition.sortOrder === 'asc'
            ? -1
            : 1)
        )
      })
    }

    /** 表示するセッティングの整形 */
    return newClassSetArray.slice(
      props.domainData.classSetDisplayCondition.displayNumber *
        props.domainData.classSetDisplayCondition.pageNumber,
      (props.domainData.classSetDisplayCondition.pageNumber + 1) *
        props.domainData.classSetDisplayCondition.displayNumber
    )
  }, [props.domainData.classSetDisplayCondition, props.domainData.classSets])

  /** テーブルに表示するクラスセットのJSXの２次元配列 */
  const classSetRows = useMemo(() => {
    const dataRows = adjustClassSetTableRow.map((classSet) => ({
      classSetId: classSet.classSetId,
      classSetName: classSet.classSetName,
      classList: classSet.classList.join(', '),
      createdAt: classSet.createdAt
        ? formatDateTimeSec(classSet.createdAt.toDate())
        : '',
      classSetRemarks: classSet.classSetRemarks,
    }))

    return dataRows.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '' || isUndefined(value)) {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'classSetId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`class-set-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return <Typography key={key}>{value}</Typography>
        }
      })
    )
  }, [adjustClassSetTableRow])

  /** クラスセットの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustClassSetTableRow) &&
      isUndefined(props.domainData.selectedClassSet) &&
      trainingAlgorithmSubStep
    ) {
      const classSet = adjustClassSetTableRow[0]
      props.setSelectedClassSet(classSet)
    }
  }, [adjustClassSetTableRow, trainingAlgorithmSubStep])

  /** テーブルに表示ベースモデルのJSXの２次元配列 */
  const baseModelRows = useMemo(() => {
    const rowData = adjustBaseModelTableRow.map((baseModel) => {
      return {
        trainedModelId: baseModel.trainedModelId,
        trainedModelVersion: baseModel.trainedModelGroupVersion.displayName,
        trainedModelName: baseModel.trainedModelName,
        transactionStatus: baseModel.transactionStatus,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'trainedModelId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`model-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return (
            <Typography key={key}>
              {value || TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        }
      })
    )
  }, [adjustBaseModelTableRow])

  /** 表示対象のベースモデルのモデルグループデータ */
  const adjustSourceModelGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.sourceModelGroupDisplayCondition)) {
      let newSourceModelGroupArray =
        props.domainData.sourceModelGroupDisplayCondition
          .selectedUserGroupKind === 'SharedUserGroup'
          ? props.domainData.sourceTrainedModelGroups.sharedUserGroup
          : props.domainData.sourceTrainedModelGroups.userGroup

      /** 検索ワードから検索 */
      if (props.domainData.sourceModelGroupDisplayCondition.searchValue) {
        newSourceModelGroupArray = newSourceModelGroupArray.filter(
          (item) =>
            item.trainedModelGroupId.startsWith(
              props.domainData.sourceModelGroupDisplayCondition.searchValue
            ) ||
            item.trainedModelGroupId ===
              props.domainData.sourceModelGroupDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedSourceTrainedModelGroupListLength(
          newSourceModelGroupArray.length
        )
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedSourceTrainedModelGroupListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.sourceModelGroupDisplayCondition.sortKey ===
        'createdAt'
      ) {
        newSourceModelGroupArray = newSourceModelGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.sourceModelGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else if (
        props.domainData.sourceModelGroupDisplayCondition.sortKey ===
        'updatedAt'
      ) {
        newSourceModelGroupArray = newSourceModelGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.sourceModelGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else {
        newSourceModelGroupArray = newSourceModelGroupArray.sort(
          (item, item2) => {
            if (item.trainedModelGroupName < item2.trainedModelGroupName) {
              return props.domainData.sourceModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1
            }
            if (item.trainedModelGroupName > item2.trainedModelGroupName) {
              return props.domainData.sourceModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? 1
                : -1
            }
            // names must be equal
            return 0
          }
        )
      }
      /** 表示するベースモデルの整形 */
      return newSourceModelGroupArray.slice(
        props.domainData.sourceModelGroupDisplayCondition.displayNumber *
          props.domainData.sourceModelGroupDisplayCondition.pageNumber,
        (props.domainData.sourceModelGroupDisplayCondition.pageNumber + 1) *
          props.domainData.sourceModelGroupDisplayCondition.displayNumber
      )
    }
    return props.domainData.destinationTrainedModelGroups
  }, [
    props.domainData.selectedDestinationTrainedModelGroup,
    props.domainData.sourceModelGroupDisplayCondition,
    props.domainData.sourceTrainedModelGroups,
    props.domainData.destinationTrainedModelGroups,
  ])

  /** 表示対象の登録先モデルグループデータ */
  const adjustDestinationTrainedModelGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.destinationModelGroupDisplayCondition)) {
      let newDestinationTrainedModelGroupArray =
        props.domainData.destinationTrainedModelGroups
      /** 検索ワードから検索 */
      if (props.domainData.destinationModelGroupDisplayCondition.searchValue) {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.filter(
            (item) =>
              item.trainedModelGroupId.startsWith(
                props.domainData.destinationModelGroupDisplayCondition
                  .searchValue
              ) ||
              item.trainedModelGroupId ===
                props.domainData.destinationModelGroupDisplayCondition
                  .searchValue
          )
        /** 検索後の配列の長さをセット */
        setSearchedDestinationTrainedModelGroupListLength(
          newDestinationTrainedModelGroupArray.length
        )
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedDestinationTrainedModelGroupListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.destinationModelGroupDisplayCondition.sortKey ===
        'createdAt'
      ) {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.sort((item, item2) => {
            return (
              ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1)
            )
          })
      } else if (
        props.domainData.destinationModelGroupDisplayCondition.sortKey ===
        'updatedAt'
      ) {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.sort((item, item2) => {
            return (
              ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1)
            )
          })
      } else {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.sort((item, item2) => {
            if (item.trainedModelGroupName < item2.trainedModelGroupName) {
              return props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1
            }
            if (item.trainedModelGroupName > item2.trainedModelGroupName) {
              return props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? 1
                : -1
            }
            // names must be equal
            return 0
          })
      }
      /** 表示するベースモデルの整形 */
      return newDestinationTrainedModelGroupArray.slice(
        props.domainData.destinationModelGroupDisplayCondition.displayNumber *
          props.domainData.destinationModelGroupDisplayCondition.pageNumber,
        (props.domainData.destinationModelGroupDisplayCondition.pageNumber +
          1) *
          props.domainData.destinationModelGroupDisplayCondition.displayNumber
      )
    }
    return props.domainData.destinationTrainedModelGroups
  }, [
    props.domainData.destinationModelGroupDisplayCondition,
    props.domainData.destinationTrainedModelGroups,
  ])

  /** テーブルに表示ベースモデルのモデルグループのJSXの２次元配列 */
  const sourceModelGroupRows = useMemo(() => {
    const rowData = adjustSourceModelGroupTableRow.map((modelGroup) => {
      return {
        trainedModelGroupId: modelGroup.trainedModelGroupId,
        trainedModelGroupName: modelGroup.trainedModelGroupName,
        trainedModelCount: modelGroup.trainedModelCount,
        latestTrainedModelVersion: modelGroup.latestTrainedModelVersion,
        latestTrainedModelName: modelGroup.latestTrainedModelName,
        createdAt: modelGroup.createdAt
          ? formatDateTimeSec(modelGroup.createdAt.toDate())
          : '',
        updatedAt: modelGroup.updatedAt
          ? formatDateTimeSec(modelGroup.updatedAt.toDate())
          : '',
        createdBy: modelGroup.createdBy,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelGroupId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography data-testid={`model-group-${value}`}>
                  {value.substring(0, 8)}
                </Typography>
              </Tooltip>
            )
          } else {
            return (
              <Typography key={key} data-testid={`model-group-${value}`}>
                {value}
              </Typography>
            )
          }
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key !== 'trainedModelGroupId' && value && value !== '') {
          return (
            <Typography key={key} data-testid={`model-group-${value}`}>
              {value}
            </Typography>
          )
        } else if (key === 'trainedModelCount') {
          return <Typography key={key}>{value}</Typography>
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustSourceModelGroupTableRow])

  /** テーブルに表示登録先のモデルグループのJSXの２次元配列 */
  const destinationTrainedModelGroupRows = useMemo(() => {
    const rowData = adjustDestinationTrainedModelGroupTableRow.map(
      (modelGroup) => {
        return {
          trainedModelGroupId: modelGroup.trainedModelGroupId,
          trainedModelGroupName: modelGroup.trainedModelGroupName,
          trainedModelCount: modelGroup.trainedModelCount,
          latestTrainedModelVersion: modelGroup.latestTrainedModelVersion,
          latestTrainedModelName: modelGroup.latestTrainedModelName,
          updatedAt: modelGroup.updatedAt
            ? formatDateTimeSec(modelGroup.updatedAt.toDate())
            : '',
          createdAt: modelGroup.createdAt
            ? formatDateTimeSec(modelGroup.createdAt.toDate())
            : '',
          createdBy: modelGroup.createdBy,
        }
      }
    )

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelGroupId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography data-testid={`model-group-${value}`}>
                  {value.substring(0, 8)}
                </Typography>
              </Tooltip>
            )
          } else {
            return (
              <Typography key={key} data-testid={`model-group-${value}`}>
                {value}
              </Typography>
            )
          }
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key !== 'trainedModelGroupId' && value && value !== '') {
          return (
            <Typography key={key} data-testid={`model-group-${value}`}>
              {value}
            </Typography>
          )
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustDestinationTrainedModelGroupTableRow])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const datasetRows = useMemo(() => {
    const rowData = adjustDatasetTableRow.map((dataset) => {
      return {
        name: dataset.name,
        generatedAt: dataset.createdAt
          ? formatDateTimeSec(dataset.createdAt.toDate())
          : '',
        remarks: dataset.remarks,
        datasetId: dataset.datasetId,
      }
    })
    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'datasetId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          }
        } else {
          return (
            <Typography key={key} data-testid={`datasets-${value}`}>
              {value}
            </Typography>
          )
        }
      })
    )
  }, [adjustDatasetTableRow, props.domainData.datasetList])
  /** データセットのテーブルの選択状態 */
  const selectedDatasetIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedDataset)) return -1
    return adjustDatasetTableRow.findIndex(
      (dataset) =>
        dataset.datasetId === props.domainData.selectedDataset?.datasetId
    )
  }, [adjustDatasetTableRow, props.domainData.selectedDataset])

  /** テーブルに表示するセッティングのJSXの２次元配列 */
  const settingsRows = useMemo(() => {
    const rowData = adjustSettingTableRow.map((setting) => {
      return {
        name: setting.name,
        generatedAt: setting.createdAt
          ? formatDateTimeSec(setting.createdAt.toDate())
          : '',
        datasetTemplate: setting.datasetTemplate.metadata.name.en,
        remarks: setting.remarks,
        settingId: setting.settingId,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'settingId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          }
        } else {
          return (
            <Typography key={key} data-testid={`setting-${value}`}>
              {value || TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        }
      })
    )
  }, [adjustSettingTableRow])

  /** 表示対象のモデルデータ */
  const adjustDestinationModelTableRow = useMemo(() => {
    if (
      !isUndefined(props.domainData.destinationModelDisplayCondition) &&
      !isUndefined(props.domainData.selectedDestinationTrainedModelGroup)
    ) {
      let newBaseModelArray =
        props.domainData.selectedDestinationTrainedModelGroup?.trainedModels ??
        []
      /** ソートキー、ソートオーダーによる並び替え */
      newBaseModelArray = newBaseModelArray.sort((item, item2) => {
        return compareVersions(
          item.trainedModelGroupVersion,
          item2.trainedModelGroupVersion,
          props.domainData.destinationModelDisplayCondition.sortOrder
        )
      })
      /** 表示するベースモデルの整形 */
      return newBaseModelArray.slice(
        props.domainData.destinationModelDisplayCondition.displayNumber *
          props.domainData.destinationModelDisplayCondition.pageNumber,
        (props.domainData.destinationModelDisplayCondition.pageNumber + 1) *
          props.domainData.destinationModelDisplayCondition.displayNumber
      )
    }
    return (
      props.domainData.selectedDestinationTrainedModelGroup?.trainedModels ?? []
    )
  }, [
    props.domainData.destinationModelDisplayCondition,
    props.domainData.selectedDestinationTrainedModelGroup?.trainedModels,
  ])

  const InheritModelsDisableRowNumbers = useMemo(() => {
    const disableRowNumbers: number[] = []
    adjustDestinationModelTableRow.forEach((item, index) => {
      const disableDatasets = item.datasets.filter(
        (dataset) =>
          dataset.datasetTemplate.datasetTemplateId !==
          props.domainData.selectedDatasetTemplateId
      )
      if (item.generationType !== 'Training' || disableDatasets.length > 0) {
        disableRowNumbers.push(index)
      }
    })
    return disableRowNumbers
  }, [
    adjustDestinationModelTableRow,
    props.domainData.selectedDatasetTemplateId,
  ])

  /** テーブルに表示ベースモデルのJSXの２次元配列 */
  const destinationModelRows = useMemo(() => {
    const rowData = adjustDestinationModelTableRow.map((baseModel) => {
      return {
        trainedModelId: baseModel.trainedModelId,
        trainedModelVersion: baseModel.trainedModelGroupVersion.displayName,
        trainedModelName: baseModel.trainedModelName,
        transactionStatus: baseModel.transactionStatus,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'trainedModelId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`model-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return (
            <Typography key={key}>
              {value || TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        }
      })
    )
  }, [adjustDestinationModelTableRow])

  /**
   * セッティング一覧の非活性の行番号
   */
  const settingTableDisableRowNumbers = useMemo(() => {
    const disableRowNumbers: number[] = []
    adjustSettingTableRow.forEach((item, index) => {
      if (item.disabled === true) {
        disableRowNumbers.push(index)
      }
    })
    return disableRowNumbers
  }, [adjustSettingTableRow])

  /**
   * 指定したインデックスがセッティング一覧で`disabled`の場合、次のセッティングの要素番号を返却する
   * 見つからなかった場合は`undefined`を返却する
   */
  const findNextEnabledSetting = useCallback(
    (index: number): number | undefined => {
      if (index > adjustSettingTableRow.length || 0 > index) return undefined
      if (adjustSettingTableRow.at(index)?.disabled === false) return index

      for (let i = index; i < adjustSettingTableRow.length; i++) {
        if (adjustSettingTableRow[i].disabled === false) {
          return i
        }
      }

      return undefined
    },
    [adjustSettingTableRow]
  )

  /**
   * 指定したインデックスが継承するモデル一覧で`disabled`の場合、次のセッティングの要素番号を返却する
   * 見つからなかった場合は`undefined`を返却する
   */
  const findNextEnabledInheritModel = useCallback(
    (index: number): number | undefined => {
      if (
        adjustDestinationModelTableRow.length === 0 ||
        index > adjustDestinationModelTableRow.length ||
        0 > index
      ) {
        return undefined
      }

      for (let i = index; i < adjustDestinationModelTableRow.length; i++) {
        if (adjustDestinationModelTableRow[i].generationType !== 'Training')
          continue

        if (
          checkEnableDataset(
            adjustDestinationModelTableRow[i].datasets,
            props.domainData.selectedDatasetTemplateId ?? ''
          ) === true
        ) {
          return i
        }
      }

      return undefined
    },
    [adjustDestinationModelTableRow, props.domainData.selectedDatasetTemplateId]
  )

  /**
   * 有効なdatasetのみか判定する
   * データセットテンプレートIDが、セッティングステップで選択したセッティングのデータセットテンプレートIDと同じ
   * @param datasets モデルに紐づいているdataset一覧
   * @param datasetTemplateId 比較対象のデータセットテンプレートID
   * @returns `true`の場合、全てのdatasetが有効
   */
  const checkEnableDataset = (
    datasets: {
      datasetId: string
      name: string
      generation: number
      datasetTemplate: DatasetTemplate
      createdAt?: Timestamp
      remarks: string
    }[],
    datasetTemplateId: string
  ) => {
    return datasets.find(
      (dataset) =>
        dataset.datasetTemplate.datasetTemplateId !== datasetTemplateId
    )
      ? false
      : true
  }

  /** 表示対象の継承データセットデータ */
  const adjustInheritDatasetTableRow = useMemo(() => {
    if (
      !isUndefined(props.domainData.inheritDatasetDisplayCondition) &&
      !isUndefined(props.domainData.selectedInheritTrainedModel)
    ) {
      let newDatasetArray =
        props.domainData.selectedInheritTrainedModel.datasets
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.inheritDatasetDisplayCondition.sortKey === 'createdAt'
      ) {
        newDatasetArray = newDatasetArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.inheritDatasetDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }
      return newDatasetArray
    }
    return props.domainData.selectedInheritTrainedModel?.datasets ?? []
  }, [
    props.domainData.inheritDatasetDisplayCondition,
    props.domainData.selectedInheritTrainedModel,
  ])

  /** テーブルに表示する継承データセットのJSXの２次元配列 */
  const inheritDatasetRows = useMemo(() => {
    const rowData = adjustInheritDatasetTableRow.map((dataset) => {
      return {
        name: dataset.name,
        generation: dataset.generation,
        generatedAt: dataset.createdAt
          ? formatDateTimeSec(dataset.createdAt.toDate())
          : '',
        datasetTemplate: dataset.datasetTemplate?.metadata?.name?.en ?? '',
        remarks: dataset.remarks,
        datasetId: dataset.datasetId,
      }
    })
    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'datasetId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          }
        } else {
          return (
            <Typography key={key} data-testid={`datasets-${value}`}>
              {value}
            </Typography>
          )
        }
      })
    )
  }, [
    props.domainData.inheritDatasetDisplayCondition,
    adjustInheritDatasetTableRow,
  ])

  /** ベースモデルのモデルグループ詳細変換 */
  const sourceModelGroupToDetail = (modelGroup?: TrainedModelGroup) => {
    if (isUndefined(modelGroup)) return undefined
    return {
      ['モデルグループID']: modelGroup.trainedModelGroupId,
      ['モデルグループ名']: modelGroup.trainedModelGroupName,
      ['登録モデル数']: String(modelGroup.trainedModelCount),
      ['最新バージョン']: modelGroup.latestTrainedModelVersion,
      ['最新モデル名']: modelGroup.latestTrainedModelName,
      ['更新日時']: modelGroup.updatedAt
        ? formatDateTimeSec(modelGroup.updatedAt.toDate())
        : '',
      ['作成日時']: modelGroup.createdAt
        ? formatDateTimeSec(modelGroup.createdAt.toDate())
        : '',
      ['作成ユーザID']: modelGroup.createdBy,
    }
  }

  /** ベースモデル詳細変換 */
  const trainedModelToDetail = (
    baseModel?: TrainedModel,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) => {
    if (isUndefined(baseModel) || isUndefined(userGroupKind)) return undefined
    return {
      ['データ種別']:
        userGroupKind === 'UserGroup' ? 'カスタマーデータ' : '共有データ',
      ['モデルID']: baseModel.trainedModelId,
      ['バージョン']: baseModel.trainedModelGroupVersion.displayName,
      ['モデル名']: baseModel.trainedModelName,
      ['ステータス']: baseModel.transactionStatus,
    }
  }

  /** データセット詳細変換 */
  const datasetToDetail = (dataset?: Dataset) => {
    if (isUndefined(dataset)) return undefined
    return {
      ['データセットID']: dataset.datasetId,
      ['データセット名']: dataset.name,
      ['登録日時']: dataset.createdAt
        ? formatDateTimeSec(dataset.createdAt.toDate())
        : '',
      ['Remarks']: dataset.remarks,
    }
  }

  /** セッティング詳細変換 */
  const settingToDetail = (
    setting?: Setting,
    hiddenPercentageInfo?: AnnotationTargeting
  ) => {
    const detail: { [key: string]: string } = {}

    if (!isUndefined(setting)) {
      Object.assign(detail, {
        ['セッティングID']: setting.settingId,
        ['セッティング名']: setting.name,
        ['セッティンググループ名']: setting.settingGroupName,
        ['セッティンググループバージョン']:
          setting.settingGroupVersion.displayName,
        ['作成日時']: setting.createdAt
          ? formatDateTimeSec(setting.createdAt.toDate())
          : '',
        ['Remarks']: setting.remarks,
      })
    }

    if (!isUndefined(hiddenPercentageInfo)) {
      Object.assign(detail, {
        ['対象ワーク限定']: hiddenPercentageInfo.enabled ? 'ON' : 'OFF',
        ['隠れ率の閾値']: hiddenPercentageInfo.hiddenPercentage.toString(),
      })
    }

    return detail
  }

  /** クラスセット変換 */
  const classSetToDetail = (classSet?: ClassSet) => {
    if (isUndefined(classSet)) return undefined
    return {
      ['クラスセットID']: classSet.classSetId,
      ['クラスセット名']: classSet.classSetName,
      ['クラス名リスト']: classSet.classList.join(','),
      ['登録日時']: formatDateTimeSec(classSet.createdAt.toDate()),
      ['備考']: classSet.classSetRemarks,
    }
  }

  /** 推論アルゴリズム詳細変換 */
  const inferenceAlgorithmVersionToDetail = (
    inferenceAlgorithmVersion?: InferenceAlgorithmVersion
  ) => {
    if (isUndefined(inferenceAlgorithmVersion)) return undefined
    return {
      ['アルゴリズムID']:
        props.domainData.selectedTrainingAlgorithm?.algorithmId,
      ['アルゴリズムバージョン']: inferenceAlgorithmVersion.algorithmVersion
        ?.displayName
        ? inferenceAlgorithmVersion.algorithmVersion.displayName
        : '',
      ['登録日時']: inferenceAlgorithmVersion.releasedAt
        ? formatDateTimeSec(inferenceAlgorithmVersion.releasedAt.toDate())
        : '',
      ['備考']: inferenceAlgorithmVersion.metadata?.remarks
        ? inferenceAlgorithmVersion.metadata?.remarks
        : '',
      ['コンテナ I/F バージョン']:
        inferenceAlgorithmVersion.containerInterfaceVersion
          ? inferenceAlgorithmVersion.containerInterfaceVersion.displayName
          : '',
      ['推論コードバージョン']: inferenceAlgorithmVersion.inferenceCodeVersion
        ? inferenceAlgorithmVersion.inferenceCodeVersion.displayName
        : '',
    }
  }

  /** 学習アルゴリズム詳細変換 */
  const trainingAlgorithmToDetail = (
    trainingAlgorithmVersion?: TrainingAlgorithmVersion
  ) => {
    if (isUndefined(trainingAlgorithmVersion)) return undefined
    return {
      ['バージョン']: trainingAlgorithmVersion.trainingAlgorithmVersion,
      ['登録日時']: trainingAlgorithmVersion.releasedAt
        ? formatDateTimeSec(trainingAlgorithmVersion.releasedAt.toDate())
        : '',
      ['remarks']: trainingAlgorithmVersion.metadata.remarks.ja,
    }
  }

  /** モデルグループ詳細変換 */
  const modelGroupToDetail = (modelGroup?: TrainedModelGroup) => {
    if (isUndefined(modelGroup)) return undefined
    return {
      ['モデルグループID']: modelGroup.trainedModelGroupId,
      ['登録モデル数']: `${modelGroup.trainedModelCount}`,
      ['最新バージョン']: modelGroup.latestTrainedModelVersion,
      ['最新モデル名']: modelGroup.latestTrainedModelName,
      ['更新日時']: modelGroup.updatedAt
        ? formatDateTimeSec(modelGroup.updatedAt.toDate())
        : '',
      ['作成日時']: modelGroup.createdAt
        ? formatDateTimeSec(modelGroup.createdAt.toDate())
        : '',
      ['作成ユーザーID']: modelGroup.createdBy,
    }
  }

  /** 選択中のテーブルの行 */
  const [selectRowIndex, setSelectRowIndex] = useState<number | undefined>(
    undefined
  )
  /** tableDialogの選択したrowのデータを出力用に変換する */
  const convertToDetail = () => {
    if (isUndefined(selectRowIndex)) return

    switch (props.appState.customTrainingState) {
      case 'BaseModelState':
        if (isUndefined(adjustBaseModelTableRow)) {
          return undefined
        }
        return trainedModelToDetail(adjustBaseModelTableRow[selectRowIndex])
      case 'DatasetState':
        if (isUndefined(adjustDatasetTableRow) || !datasetSubStep) {
          return undefined
        }
        return datasetToDetail(adjustDatasetTableRow[selectRowIndex])
      case 'SettingState':
        if (isUndefined(adjustSettingTableRow)) {
          return undefined
        }
        return settingToDetail(adjustSettingTableRow[selectRowIndex])
      default:
        return undefined
    }
  }

  /** テーブルの列選択時のダイアログ */
  const tableDialog = useMemo(() => {
    if (isUndefined(selectRowIndex)) return
    /**  表示するものをここで受け取る */
    const detailItems = convertToDetail()
    if (isUndefined(detailItems)) {
      setSelectRowIndex(undefined)
      return
    }
    return (
      <TableDialog
        open={!isUndefined(selectRowIndex)}
        handleClose={() => setSelectRowIndex(undefined)}
        items={detailItems}
      />
    )
  }, [selectRowIndex])

  /** 検索処理 */
  const searchTableContent = () => {
    switch (props.appState.customTrainingState) {
      case 'TrainingAlgorithmState':
        props.setClassSetDisplayCondition({
          ...props.domainData.classSetDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      case 'ModelGroupState':
        props.setDestinationTrainedModelGroupDisplayCondition({
          ...props.domainData.destinationModelGroupDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      case 'BaseModelState':
        props.setSourceTrainedModelGroupDisplayCondition({
          ...props.domainData.sourceModelGroupDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      case 'DatasetState':
        props.setDatasetDisplayCondition({
          ...props.domainData.datasetDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        props.getDatasetList()
        break
      case 'SettingState':
        props.setSettingDisplayCondition({
          ...props.domainData.settingDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      default:
        break
    }
  }

  /** 学習アルゴリズムテーブルのソート設定 */
  const selectedAlgorithmTableOrder = (key: string) => {
    props.setTrainingDisplayCondition({
      sortKey: key,
      sortOrder:
        props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder ===
        'desc'
          ? 'asc'
          : 'desc',
    })
  }

  /** 継承データセットテーブルのソート設定 */
  const selectedInheritDatasetTableOrder = (key: string) => {
    props.setInheritDatasetDisplayCondition({
      sortKey: key,
      sortOrder:
        props.domainData.inheritDatasetDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
    })
  }

  /** 学習アルゴリズムの初期値選択 */
  useEffect(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm?.algorithmId)) {
      const algorithmVersion = query['algorithm-version']
      if (!isNull(algorithmVersion)) {
        const trainingAlgorithmVersion = props.algorithms
          .find(
            (algorithm) =>
              algorithm.algorithmId ===
              props.domainData.selectedTrainingAlgorithm?.algorithmId
          )
          ?.trainingAlgorithm.trainingAlgorithmVersions.find(
            (version) =>
              version.algorithmVersion.displayName === algorithmVersion
          )
        if (trainingAlgorithmVersion) {
          props.setSelectedTrainingAlgorithmVersion(trainingAlgorithmVersion)
        }
      } else {
        let selectedAlgorithm = props.algorithms.filter(
          (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
        )[0]
        props.algorithms.find((algorithm) => {
          if (
            algorithm.algorithmId ==
            props.domainData.selectedTrainingAlgorithm?.algorithmId
          ) {
            selectedAlgorithm = algorithm
          }
        })
        if (
          selectedAlgorithm.algorithmId !==
          props.domainData.selectedTrainingAlgorithm?.algorithmId
        ) {
          return
        }
        const trainingAlgorithmVersions =
          selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
            (item1, item2) => {
              return compareVersionsWithPreRelease(
                item1.algorithmVersion,
                item2.algorithmVersion,
                props.domainData.trainingAlgorithmVersionDisplayCondition
                  .sortOrder
              )
            }
          )

        const trainingAlgorithmVersion = trainingAlgorithmVersions[0]
        props.setSelectedTrainingAlgorithmVersion(trainingAlgorithmVersion)
      }
    }
  }, [props.domainData.selectedTrainingAlgorithm?.algorithmId])

  /** ベースモデルのモデルグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.destinationTrainedModelGroups) &&
      props.domainData.sourceTrainedModelGroups.userGroup.length !== 0 &&
      isUndefined(props.domainData.selectedSourceTrainedModelGroup) &&
      props.appState.customTrainingState === 'BaseModelState'
    ) {
      // クエリパラメータにモデルグループIDが含まれる場合は、初期値として設定
      const modelGroupId = query['trained-model-group-id']
      if (modelGroupId) {
        const modelGroup =
          props.domainData.sourceModelGroupDisplayCondition
            .selectedUserGroupKind === 'SharedUserGroup'
            ? props.domainData.sourceTrainedModelGroups.sharedUserGroup
            : props.domainData.sourceTrainedModelGroups.userGroup
        const searchedModelGroup = modelGroup.find(
          (group) => group.trainedModelGroupId === modelGroupId
        )
        if (searchedModelGroup) {
          props.setSelectedSourceModelGroup(searchedModelGroup)
          setTableSearchValue(modelGroupId)
          props.setSourceTrainedModelGroupDisplayCondition({
            ...props.domainData.sourceModelGroupDisplayCondition,
            searchValue: modelGroupId,
          })
        } else {
          const modelGroup =
            props.domainData.sourceModelGroupDisplayCondition
              .selectedUserGroupKind === 'SharedUserGroup'
              ? props.domainData.sourceTrainedModelGroups.userGroup
              : props.domainData.sourceTrainedModelGroups.sharedUserGroup

          const searchedModelGroup = modelGroup.find(
            (group) => group.trainedModelGroupId === modelGroupId
          )
          if (!searchedModelGroup) {
            props.setBaseModelSubState('NotFound')
          }
        }
      } else {
        if (
          props.domainData.sourceModelGroupDisplayCondition
            .selectedUserGroupKind === 'SharedUserGroup' &&
          props.domainData.selectedDestinationTrainedModelGroup
        ) {
          props.setSelectedSourceModelGroup(
            props.domainData.sourceTrainedModelGroups.sharedUserGroup[0]
          )
        } else {
          props.setSelectedSourceModelGroup(
            props.domainData.sourceTrainedModelGroups.userGroup[0]
          )
        }
      }
    }
  }, [
    sourceModelGroupRows,
    props.appState.customTrainingState,
    props.domainData.sourceModelGroupDisplayCondition,
  ])

  /** ベースモデルの初期値選択 */
  useEffect(() => {
    if (!isUndefined(props.domainData.selectedSourceTrainedModelGroup)) {
      // クエリパラメータにモデルIDが含まれる場合は、初期値として設定
      const modelId = query['trained-model-id']
      if (modelId) {
        const trainedModel =
          props.domainData.selectedSourceTrainedModelGroup.trainedModels.find(
            (trainedModel) => trainedModel.trainedModelId === modelId
          )
        if (trainedModel) {
          props.setSelectedBaseModel(trainedModel)
        } else {
          props.setSelectedBaseModel(
            props.domainData.selectedSourceTrainedModelGroup.trainedModels[0]
          )
        }
      } else {
        props.setSelectedBaseModel(
          props.domainData.selectedSourceTrainedModelGroup.trainedModels[0]
        )
      }
    }
  }, [props.domainData.selectedSourceTrainedModelGroup])

  /** 継承モデルの初期値選択 */
  useEffect(() => {
    if (!isUndefined(props.domainData.selectedDestinationTrainedModelGroup)) {
      const index = findNextEnabledInheritModel(0)
      props.setSelectedInheritModel(
        index !== undefined
          ? props.domainData.selectedDestinationTrainedModelGroup.trainedModels[
              index
            ]
          : undefined
      )
    }
  }, [
    props.domainData.selectedDestinationTrainedModelGroup,
    props.domainData.selectedDatasetTemplateId,
  ])

  /** 登録先モデルグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.destinationTrainedModelGroups) &&
      isUndefined(props.domainData.selectedDestinationTrainedModelGroup)
    ) {
      const isSharedUserGroup = query['shared-user-group'] === 'true'
      if (!isSharedUserGroup) {
        // クエリパラメータにモデルグループIDが含まれる場合は、初期値として設定
        const modelGroupId = query['trained-model-group-id']
        if (modelGroupId) {
          const searchedModelGroup =
            props.domainData.destinationTrainedModelGroups.find(
              (group) => group.trainedModelGroupId === modelGroupId
            )
          if (searchedModelGroup) {
            props.setSelectedDestinationTrainedModelGroup(searchedModelGroup)
            setTableSearchValue(modelGroupId)
            props.setDestinationTrainedModelGroupDisplayCondition({
              ...props.domainData.destinationModelGroupDisplayCondition,
              searchValue: modelGroupId,
            })
          } else {
            props.setSelectedDestinationTrainedModelGroup(
              props.domainData.destinationTrainedModelGroups[0]
            )
          }
        } else {
          props.setSelectedDestinationTrainedModelGroup(
            props.domainData.destinationTrainedModelGroups[0]
          )
        }
      } else {
        props.setSelectedDestinationTrainedModelGroup(
          props.domainData.destinationTrainedModelGroups[0]
        )
      }
    }
  }, [
    props.domainData.destinationTrainedModelGroups,
    props.domainData.selectedTrainingAlgorithmVersion,
  ])

  /** データセットの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustDatasetTableRow) &&
      isUndefined(props.domainData.selectedDataset)
    ) {
      const dataset = adjustDatasetTableRow[0]
      props.setSelectedDataset(dataset)
    }
  }, [adjustDatasetTableRow])

  /** セッティングの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustSettingTableRow) &&
      isUndefined(props.domainData.selectedSetting)
    ) {
      props.setSelectedSetting(undefined, false)
    }
  }, [adjustSettingTableRow])

  /** 推論アルゴリズムの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(inferenceAlgorithmVersionRows) &&
      props.domainData.selectedCustomTrainingGenerateKind === 'EdgeImage' &&
      isUndefined(props.domainData.selectedInferenceAlgorithmVersion)
    ) {
      props.setSelectedInferenceAlgorithmVersion(
        props.domainData.inferenceAlgorithmVersions[0]
      )
    }
  }, [
    inferenceAlgorithmVersionRows,
    props.domainData.selectedCustomTrainingGenerateKind,
  ])

  /** 学習アルゴリズムのラジオボタンクリック */
  const selectTrainingAlgorithmTableRadio = (row: number) => {
    let selectedAlgorithm = props.algorithms.filter(
      (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
    )[0]
    props.algorithms.find((algorithm) => {
      if (
        algorithm.algorithmId ==
        props.domainData.selectedTrainingAlgorithm?.algorithmId
      ) {
        selectedAlgorithm = algorithm
      }
    })
    if (
      selectedAlgorithm.algorithmId !==
      props.domainData.selectedTrainingAlgorithm?.algorithmId
    ) {
      return
    }
    const trainingAlgorithmVersions =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
        (item1, item2) => {
          return compareVersionsWithPreRelease(
            item1.algorithmVersion,
            item2.algorithmVersion,
            props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder
          )
        }
      )
    const trainingAlgorithmVersion = trainingAlgorithmVersions[row]
    props.setSelectedTrainingAlgorithmVersion(trainingAlgorithmVersion)
  }

  /** 推論アルゴリズムのラジオボタン選択された時に切り替える処理 */
  const selectInferenceAlgorithmTableRadio = (row: number) => {
    if (!isUndefined(props.domainData.inferenceAlgorithmVersions)) {
      const inferenceAlgorithm =
        props.domainData.inferenceAlgorithmVersions[row]
      props.setSelectedInferenceAlgorithmVersion(inferenceAlgorithm)
    }
  }

  const selectedTrainingAlgorithmIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedTrainingAlgorithmVersion))
      return -1
    let selectedAlgorithm = props.algorithms.filter(
      (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
    )[0]
    props.algorithms.find((algorithm) => {
      if (
        algorithm.algorithmId ==
        props.domainData.selectedTrainingAlgorithm?.algorithmId
      ) {
        selectedAlgorithm = algorithm
      }
    })
    if (
      selectedAlgorithm.algorithmId !==
      props.domainData.selectedTrainingAlgorithm?.algorithmId
    ) {
      return -1
    }

    const trainingAlgorithmVersions =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
        (item1, item2) => {
          return compareVersionsWithPreRelease(
            item1.algorithmVersion,
            item2.algorithmVersion,
            props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder
          )
        }
      )
    return trainingAlgorithmVersions.findIndex(
      (trainingAlgorithmVersion) =>
        trainingAlgorithmVersion.trainingAlgorithmVersion ===
        props.domainData.selectedTrainingAlgorithmVersion
          ?.trainingAlgorithmVersion
    )
  }, [
    trainingAlgorithmRows,
    props.algorithms,
    props.domainData.selectedTrainingAlgorithm?.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
  ])

  /** ベースモデルのモデルグループテーブルの選択状態 */
  const selectedSourceModelGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.destinationTrainedModelGroups)) return -1
    return adjustSourceModelGroupTableRow.findIndex(
      (modelGroup) =>
        modelGroup.trainedModelGroupId ===
        props.domainData.selectedSourceTrainedModelGroup?.trainedModelGroupId
    )
  }, [
    adjustSourceModelGroupTableRow,
    props.domainData.selectedSourceTrainedModelGroup,
  ])

  /** 登録先モデルグループテーブルの選択状態 */
  const selectedDestinationTrainedModelGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.destinationTrainedModelGroups)) return -1
    return adjustDestinationTrainedModelGroupTableRow.findIndex(
      (modelGroup) =>
        modelGroup.trainedModelGroupId ===
        props.domainData.selectedDestinationTrainedModelGroup
          ?.trainedModelGroupId
    )
  }, [
    adjustDestinationTrainedModelGroupTableRow,
    props.domainData.selectedDestinationTrainedModelGroup,
  ])

  const selectedClassSetIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedClassSet)) return -1
    return adjustClassSetTableRow.findIndex(
      (classSet) =>
        classSet.classSetId === props.domainData.selectedClassSet?.classSetId
    )
  }, [adjustClassSetTableRow, props.domainData.selectedClassSet])

  /** ベースモデルのテーブルの選択状態 */
  const selectedBaseModelIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedSourceTrainedModel)) return -1
    return adjustBaseModelTableRow.findIndex(
      (baseModel) =>
        baseModel.trainedModelId ===
        props.domainData.selectedSourceTrainedModel?.trainedModelId
    )
  }, [
    adjustBaseModelTableRow,
    props.domainData.selectedSourceTrainedModel,
    props.domainData.selectedSourceTrainedModelGroup,
  ])

  /** 継承モデルのテーブルの選択状態 */
  const selectedDestinationModelIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedInheritTrainedModel)) return -1
    return adjustDestinationModelTableRow.findIndex(
      (destinationModel) =>
        destinationModel.trainedModelId ===
        props.domainData.selectedInheritTrainedModel?.trainedModelId
    )
  }, [
    adjustDestinationModelTableRow,
    props.domainData.selectedInheritTrainedModel,
    props.domainData.selectedDestinationTrainedModelGroup,
  ])

  /** セッティングのテーブルの選択状態 */
  const selectedSettingIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedSetting)) return -1
    return adjustSettingTableRow.findIndex(
      (setting) =>
        setting.settingId === props.domainData.selectedSetting?.settingId
    )
  }, [adjustSettingTableRow, props.domainData.selectedSetting])

  useEffect(() => {
    if (
      props.appState.customTrainingState === 'SettingState' &&
      settingSubStep &&
      props.algorithms
        .filter(
          (algorithm) => algorithm.algorithmPurpose === 'ObjectClassification'
        )
        .map((algorithm) => algorithm.algorithmId)
        .includes(props.domainData.selectedTrainingAlgorithm?.algorithmId ?? '')
    ) {
      props.getObjectClassificationExtendedSetting()
    }
  }, [props.appState.customTrainingState, settingSubStep])

  /** ベースサイズの横幅変更時 */
  const onChangeBaseSizeWidth = (value: string) => {
    const regex = /^[0-9\b]+$/
    if (value === '' || regex.test(value)) {
      let baseSize = Number(value)
      if (baseSize < MIN_BASE_SIZE) {
        baseSize = MIN_BASE_SIZE
      } else if (baseSize > MAX_BASE_SIZE) {
        baseSize = MAX_BASE_SIZE
      }
      props.setObjectClassificationExtendedSetting(
        !isUndefined(props.domainData.extendedSetting.objectClassification)
          ? {
              ...props.domainData.extendedSetting.objectClassification,
              baseSize: {
                ...props.domainData.extendedSetting.objectClassification
                  .baseSize,
                width: baseSize,
              },
            }
          : undefined
      )
    }
  }

  /** ベースサイズの縦幅変更時 */
  const onChangeBaseSizeHeight = (value: string) => {
    const regex = /^[0-9\b]+$/
    if (value === '' || regex.test(value)) {
      let baseSize = Number(value)
      if (baseSize < MIN_BASE_SIZE) {
        baseSize = MIN_BASE_SIZE
      } else if (baseSize > MAX_BASE_SIZE) {
        baseSize = MAX_BASE_SIZE
      }
      props.setObjectClassificationExtendedSetting(
        !isUndefined(props.domainData.extendedSetting.objectClassification)
          ? {
              ...props.domainData.extendedSetting.objectClassification,
              baseSize: {
                ...props.domainData.extendedSetting.objectClassification
                  .baseSize,
                height: baseSize,
              },
            }
          : undefined
      )
    }
  }

  return {
    selectedDestinationTrainedModelTableRadio,
    selectedDestinationTrainedModelGroupIndex,
    destinationTrainedModelGroupRows,
    selectedTrainingAlgorithmIndex,
    selectTrainingAlgorithmTableRadio,
    selectedBaseModelIndex,
    selectedSettingIndex,
    selectClassSetTableRadio,
    selectBaseModelTableRadio,
    selectDatasetTableRadio,
    selectSettingTableRadio,
    changeTableSortOrder,
    pageChange,
    trainingAlgorithmRows,
    inferenceAlgorithmVersionRows,
    classSetRows,
    selectedClassSetIndex,
    selectedInferenceAlgorithmVersionIndex,
    selectInferenceAlgorithmTableRadio,
    handleChangeDisplayNumber,
    trainedModelToDetail,
    datasetToDetail,
    settingToDetail,
    classSetToDetail,
    inferenceAlgorithmVersionToDetail,
    setSelectRowIndex,
    tableDialog,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    searchedClassSetListLength,
    searchedDatasetListLength,
    searchedSettingListLength,
    destinationModelRows,
    InheritModelsDisableRowNumbers,
    selectDestinationModelTableRadio,
    searchedSourceTrainedModelGroupListLength,
    searchedDestinationTrainedModelGroupListLength,
    datasetRows,
    selectedDatasetIndex,
    baseModelRows,
    settingsRows,
    settingTableDisableRowNumbers,
    findNextEnabledSetting,
    findNextEnabledInheritModel,
    isOpenDownloadFailedDialog,
    setIsOpenDownloadFailedDialog,
    selectedAlgorithmTableOrder,
    sourceModelGroupRows,
    selectedSourceModelGroupIndex,
    selectedSourceModelTableRadio,
    sourceModelGroupToDetail,
    modelGroupToDetail,
    trainingAlgorithmToDetail,
    inheritDatasetRows,
    selectedDestinationModelIndex,
    selectedInheritDatasetTableOrder,
    onChangeBaseSizeWidth,
    onChangeBaseSizeHeight,
  }
}
