import { doc, getDoc } from 'firebase/firestore'
import { getFeatureDataQueriesCollection } from 'state/firebase'
import { isUndefined } from 'utils/typeguard'

/** featureDataGroupIdを取得 */
export const getFeatureDataGroupId = async (
  userGroupId: string,
  featureDataId: string
) => {
  try {
    const featureData = (
      await getDoc(
        doc(getFeatureDataQueriesCollection(userGroupId), featureDataId)
      )
    ).data()
    if (isUndefined(featureData)) return ''
    const featureDataGroupId = featureData['feature-data-group-id']
    return featureDataGroupId
  } catch (error) {
    console.error(error)
    return ''
  }
}
