import { Dispatch } from 'redux'
import {
  AccountDetailInfo,
  AccountDocument,
  AccountGroup,
  AccountSettingsDocument,
  accountListActions,
} from '.'
import {
  getAccountGroupCollection,
  getAccountGroupMetadataCollection,
  getAccountsCollection,
  getAccountSettingCollection,
} from 'state/firebase'
import { State } from 'state/store'
import {
  DocumentData,
  QuerySnapshot,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupDocument } from 'utils/fireStore/accountGroup'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupMetadataDocument } from 'utils/fireStore/accountGroupMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountDocument } from 'utils/fireStore/account'
import { isUndefined } from 'utils/typeguard'

export const AccountListOperations = {
  /** リストを取得する */
  getAccountList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountListActions.setInProgress(true))

        const isSuperUser =
          getState().app.domainData.authedUser.auth.customClaims.superUser
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const accountGroupIdList: string[] = []
        if (isSuperUser) {
          // スーパーユーザーの場合は全てのアカウントグループに所属するアカウントを取得する
          const accountGroupListDocs = await getDocs(
            query(getAccountGroupCollection())
          )
          accountGroupListDocs.docs.map((item) => {
            const accountGroup = item.data()
            if (!fireStoreTypeGuardForAccountGroupDocument(accountGroup)) {
              return
            }
            accountGroupIdList.push(accountGroup['account-group-id'] as string)
          })
        } else {
          accountGroupIdList.push(accountGroupId)
        }

        const querySnapshots: QuerySnapshot<DocumentData>[] = []
        if (isSuperUser) {
          await Promise.all(
            accountGroupIdList.map(async (item) => {
              querySnapshots.push(
                await getDocs(query(getAccountsCollection(item)))
              )
            })
          )
        } else {
          // 一般ユーザーの場合は自身が所属するアカウントグループのアカウントを取得する
          querySnapshots.push(
            await getDocs(query(getAccountsCollection(accountGroupId)))
          )
        }

        // 表示対象のアカウント情報の配列
        const accountInfos: AccountDetailInfo[] = []
        await Promise.all(
          querySnapshots.map(async (querySnapshot) => {
            const allAccountListConvert = querySnapshot.docs
              .map((accountDocument) => {
                const accountsData = accountDocument.data()
                if (!fireStoreTypeGuardForAccountDocument(accountsData)) {
                  return undefined
                } else {
                  return {
                    id: accountDocument.id,
                    ...accountDocument.data(),
                  } as AccountDocument
                }
              })
              .filter((doc) => !isUndefined(doc)) as AccountDocument[]

            // アカウントセッティングを取得
            const allAccountSettingList = await getDocs(
              query(
                getAccountSettingCollection(
                  allAccountListConvert.at(0)?.['account-group-id'] ?? ''
                )
              )
            )

            const allAccountSettingListConvert = allAccountSettingList.docs
              .map((accountSettingDocument) => {
                const accountSettingData = accountSettingDocument.data()
                if (
                  !fireStoreTypeGuardForAccountSettingDocument(
                    accountSettingData
                  )
                ) {
                  return undefined
                } else {
                  return {
                    id: accountSettingDocument.id,
                    ...accountSettingData,
                  } as AccountSettingsDocument
                }
              })
              .filter((doc) => !isUndefined(doc)) as AccountSettingsDocument[]

            allAccountListConvert.forEach((accountDocument) => {
              // 一致したIDでオブジェクトをマージ
              const setting = allAccountSettingListConvert.find(
                (data) => data && data.id === accountDocument.id
              )

              // accounts document に紐づく account-settings document が存在しない場合は
              // 不正データとして、一覧に表示しない
              if (isUndefined(setting)) return

              // アカウントのオブジェクトを作成
              const accountInfo: AccountDetailInfo = {
                accountId: accountDocument['account-id'],
                mailAddress: accountDocument['mail-address'],
                firstName: setting['first-name'],
                familyName: setting['family-name'],
                accountGroupRole:
                  accountDocument['account-group-role'] === 'admin'
                    ? '管理者'
                    : '一般ユーザー',
                lastLoginTime: accountDocument['last-login-time'],
                passwordUpdateAt: accountDocument['password-update-at'],
                registeredDate: accountDocument['created-at'],
                firstOption: accountDocument['first-login-time']
                  ? accountDocument['first-login-time'].seconds === 0
                    ? false
                    : true
                  : false,
                accountGroupId: accountDocument['account-group-id'],
              }
              accountInfos.push(accountInfo)
            })
          })
        )

        dispatch(accountListActions.setAccountList(accountInfos))
        dispatch(accountListActions.clearDisplayCondition())
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountListActions.setInProgress(false))
      }
    },
  /** リストを取得する */
  getAccountGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountListActions.setInProgress(true))

        const isSuperUser =
          getState().app.domainData.authedUser.auth.customClaims.superUser
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        // アカウントグループdoc取得
        const allAccountGroupList = isSuperUser
          ? await getDocs(query(getAccountGroupCollection()))
          : await getDocs(
              query(
                getAccountGroupCollection(),
                where('account-group-id', '==', accountGroupId)
              )
            )

        // アカウントグループメタデータdoc取得する
        const allAccountGroupMetadataList = isSuperUser
          ? await getDocs(query(getAccountGroupMetadataCollection()))
          : await getDocs(
              query(
                getAccountGroupMetadataCollection(),
                where('account-group-id', '==', accountGroupId)
              )
            )

        // アカウントグループリスト作成
        const allAccountGroupListConvert = (
          await Promise.all(
            allAccountGroupList.docs.map(async (docItem) => {
              const accountGroup = docItem.data()
              const isAccountGroupDocValid =
                fireStoreTypeGuardForAccountGroupDocument(accountGroup)

              if (!isAccountGroupDocValid) {
                return
              }

              const metadata = allAccountGroupMetadataList.docs
                .map((metadataDoc) => metadataDoc.data())
                .find(
                  (metadata) =>
                    metadata['account-group-id'] ===
                    accountGroup['account-group-id']
                )

              const isAccountGroupMetadataDocValid =
                metadata &&
                fireStoreTypeGuardForAccountGroupMetadataDocument(metadata)

              if (!isAccountGroupMetadataDocValid) {
                return
              }

              return {
                accountGroupId: accountGroup['account-group-id'],
                name: metadata ? metadata['name'] : '',
              } as AccountGroup
            })
          )
        ).filter(
          (item: AccountGroup | undefined) => item !== undefined
        ) as AccountGroup[]
        dispatch(
          accountListActions.setAccountGroupList(allAccountGroupListConvert)
        )
        dispatch(accountListActions.setSelectedAccountGroupId(accountGroupId))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountListActions.setInProgress(false))
      }
    },
}
