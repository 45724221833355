import React, { useState, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import { GroupedDataDetailedViewerProps } from './types'
import { isUndefined } from 'utils/typeguard'
import {
  CopyableLabel,
  ImageWithInfo,
  ThumbnailInfo,
  ThumbnailSlider,
  ThumbnailSliderProps,
} from 'views/components'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import { TrainingImageInfo } from 'types/StateTypes'

/** 名前がない時はN/A */
const ANNOTATION_FILENAME_NOT_APPLICABLE = 'N/A'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  groupedDataDetailDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
  annotationFileName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tagList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
    '& > div': {
      height: 24,
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      '& > svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  sliderDiv: {
    padding: theme.spacing(1),
    '& > *': {
      height: '100%',
      width: '100%',
    },
  },
  cancelLink: {
    margin: theme.spacing(1),
    float: 'right',
  },
}))

const MemoizedThumbnailSlider: React.FC<ThumbnailSliderProps> = React.memo(
  (props: ThumbnailSliderProps) => <ThumbnailSlider {...props} />
)
MemoizedThumbnailSlider.displayName = 'MemoizedThumbnailSlider'

export const GroupedDataDetailedViewer: React.FC<
  GroupedDataDetailedViewerProps
> = (props: GroupedDataDetailedViewerProps) => {
  const { classes } = useStyles()

  const [selectedImageId, setSelectedImageId] = useState<string | undefined>(
    undefined
  )
  const getThumbnails = (
    trainingImageList: TrainingImageInfo[]
  ): ThumbnailInfo[] =>
    trainingImageList.map((imageData) => {
      return {
        id: imageData.trainingImageId,
        url: imageData.thumbnailUrl,
      }
    }) as ThumbnailInfo[]

  /** 画像セットのタイムスタンプを切り替える */
  const getTags = useMemo(() => {
    const tags: string[] = [props.data.annotationSetKind]
    if (props.data.conditions) {
      tags.push(props.data.conditions.trainKind)
    }
    return tags
  }, [props.data.annotationSetKind, props.data.conditions])

  /** 表示サムネイルとデータ */
  const getImageWithInfo = (trainingImageList: TrainingImageInfo[]) => {
    if (!selectedImageId) {
      if (trainingImageList.length > 0) {
        setSelectedImageId(trainingImageList[0].trainingImageId)
      } else {
        return <></>
      }
    }

    const selectedImage = trainingImageList.find(
      (data) => data.trainingImageId === selectedImageId
    )

    if (isUndefined(selectedImage)) return <></>

    return (
      <ImageWithInfo
        url={selectedImage.originalUrl}
        informations={[
          {
            key: 'ID',
            value: selectedImage.trainingImageId.substring(0, 8),
          },
          {
            key: 'アップロード日',
            value: selectedImage.createdAt
              ? selectedImage.createdAt
                  .toDate()
                  .toISOString()
                  .slice(0, 19)
                  .replace('T', ' ')
              : '',
          },
          {
            key: 'ユーザーID',
            value:
              typeof props.createdBy === 'string'
                ? props.createdBy.substring(0, 8)
                : props.createdBy.id.substring(0, 8),
          },
        ]}
        informationsDisplayDefault={true}
        informationsDisplayControlWithIcon={false}
      />
    )
  }

  return (
    <Dialog
      maxWidth='md'
      fullWidth={true}
      aria-labelledby='customized-dialog-title'
      onClose={props.onClickClose}
      open={props.open}
    >
      <DialogContent className={classes.groupedDataDetailDialog}>
        <Box width='100%' display='flex' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            {props.data.annotationFile.fileName ? (
              <Typography
                component='span'
                className={classes.annotationFileName}
              >
                {props.data.annotationFile.fileName}
              </Typography>
            ) : (
              <Typography
                color='textSecondary'
                component='span'
                className={classes.annotationFileName}
              >
                {ANNOTATION_FILENAME_NOT_APPLICABLE}
              </Typography>
            )}
            {!isUndefined(props.data.trainingImageList) && (
              <Typography variant='body1' component='div'>
                （{`${props.data.trainingImageList.length}枚`}）
              </Typography>
            )}
          </Box>
          <CopyableLabel
            value={props.data.groupedDataId}
            isTooltip
            placement='top'
          />
        </Box>
        <Box className={classes.tagList}>
          {getTags.map((tag, index) => {
            return <Chip color='primary' label={tag} key={index}></Chip>
          })}
        </Box>
        <Box width='100%' display='flex' justifyContent='center'>
          <Box width='auto' height={300}>
            {getImageWithInfo(props.data.trainingImageList ?? [])}
          </Box>
        </Box>
        {!isUndefined(props.data) && (
          <div className={classes.sliderDiv}>
            <ThumbnailSlider
              thumbnails={getThumbnails(props.data.trainingImageList ?? [])}
              rows={2}
              onClickImage={(imageId: string) => {
                if (selectedImageId !== imageId) {
                  setSelectedImageId(imageId)
                }
              }}
              selectedId={selectedImageId}
            />
          </div>
        )}
        <Button
          color='primary'
          onClick={props.onClickClose}
          className={classes.cancelLink}
          data-testid={`${props['data-testid']}-button-cancel`}
        >
          <b>閉じる</b>
        </Button>
      </DialogContent>
    </Dialog>
  )
}
