import { InferenceMLPipelineQueryDocument } from './types'

import {
  mlPipelineDocumentSchema,
  timeStampSchema,
  trainedModelSchema,
  versionSchema,
} from '../common/schemas'
import { DataObject, validateData } from '../common/validations'

/** ml-pipeline-query (推論) documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as InferenceMLPipelineQueryDocument
  const schema = {
    ...mlPipelineDocumentSchema,
    required: [...mlPipelineDocumentSchema.required, 'inference-step'],
  }
  const inferenceStepSchema = {
    required: [
      'ended-at',
      'started-at',
      'step-id',
      'step-kind',
      'step-status',
      'src',
    ],
    type: 'object',
    properties: {
      'ended-at': {
        ...timeStampSchema,
      },
      'started-at': {
        ...timeStampSchema,
      },
      'step-id': {
        type: 'string',
      },
      'step-kind': {
        type: 'string',
      },
      'step-status': {
        type: 'string',
      },
      src: {
        required: [
          'algorithm-id',
          'annotation-set-list',
          'dataset-id',
          'inference-algorithm-version',
          'trained-model',
        ],
        type: 'object',
        properties: {
          'algorithm-id': {
            type: 'string',
          },
          'annotation-set-list': {
            type: 'array',
            items: {
              required: [
                'annotation-set-id',
                'annotation-set-kind',
                'grouped-data-id',
              ],
              type: 'object',
              properties: {
                'annotation-set-id': {
                  type: 'string',
                },
                'annotation-set-kind': {
                  type: 'string',
                },
                'grouped-data-id': {
                  type: 'string',
                },
                'user-group-id': {
                  type: 'string',
                },
              },
            },
          },
          'dataset-id': {
            type: 'string',
          },
          'inference-algorithm-version': {
            ...versionSchema,
          },
          'trained-model': {
            ...trainedModelSchema,
          },
        },
      },
    },
  }
  const validationSequences = [
    { schema: schema },
    {
      schema: inferenceStepSchema,
      dataIndex: 'inference-step',
    },
  ]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'ml-pipeline-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
