import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  AccordionLabel,
  BreadcrumbsComponent,
  CommonStepper,
  ConfirmViewerDialog,
  CustomTrainingPageParagraph,
  ErrorMessage,
  GlobalLoading,
  MLPipelineCompleteDialog,
  MetadataInput,
  RADIO_ROW_HEIGHT,
  SelectableTable,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
} from 'views/components'
import {
  DisplayCondition,
  FeatureDataGroup,
  FeatureDataTransferringStateKind,
  FeatureDataTransferringStateKindArray,
  MetaData,
  FeatureData,
} from 'state/ducks/featureDataTransferring'
import { isNullOrUndefined, isString, isUndefined } from 'utils/typeguard'
import { formatDateTimeSec } from 'views/components/utils/date'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { State } from 'state/store'
import { ThunkDispatch } from 'redux-thunk'
import {
  FeatureDataTransferringActions,
  featureDataTransferringActions,
  featureDataTransferringOperations,
} from 'state/ducks/featureDataTransferring'
import { connect } from 'react-redux'
import { Timestamp } from 'firebase/firestore'
import { Version } from 'types/StateTypes'
import { compareVersions } from 'utils/versions'
import { isVersion } from 'views/containers/utils/typeguard'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { hasSharedUserGroupQueryParameter } from 'views/containers/utils/queryParams'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataTransferringState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataTransferringActions>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (
    featureDataTransferringSteps: FeatureDataTransferringStateKind,
    selectedFeatureDataGroupId?: string,
    isSharedUserGroup?: boolean
  ) => {
    switch (featureDataTransferringSteps) {
      case 'FeatureDataGroupState':
        dispatch(featureDataTransferringOperations.getFeatureDataGroupList())
        break
      case 'FeatureDataState':
        if (
          isNullOrUndefined(selectedFeatureDataGroupId) ||
          isNullOrUndefined(isSharedUserGroup)
        ) {
          break
        }
        dispatch(
          featureDataTransferringOperations.getFeatureDataGroupDetail(
            selectedFeatureDataGroupId,
            isSharedUserGroup
          )
        )
        break
      default:
        break
    }
  },

  /** 次へ、開始ボタン押下時の処理 */
  onClickNextButton: (currentStep: FeatureDataTransferringStateKind) => {
    if (
      FeatureDataTransferringStateKindArray.indexOf(currentStep) >=
      FeatureDataTransferringStateKindArray.length - 1
    ) {
      /** 特徴量データ転送情報開始 */
      dispatch(
        featureDataTransferringOperations.executeFeatureDataTransferring()
      )
    } else {
      dispatch(featureDataTransferringOperations.nextStep(currentStep))
    }
  },

  /** 戻るボタン押下時の処理 */
  onClickPrevButton: (currentStep: FeatureDataTransferringStateKind) => {
    dispatch(featureDataTransferringOperations.prevStep(currentStep))
  },

  /** 選んだアルゴリズムIDをセットする(Training Algorithm Id) */
  setSelectedTrainingAlgorithmId: (algorithmId: string) => {
    dispatch(
      featureDataTransferringActions.setSelectedTrainingAlgorithmId(algorithmId)
    )
  },

  /** 選んだ特徴量データグループを設定する */
  setSelectedFeatureDataGroup: (featureDataGroup: FeatureDataGroup) => {
    dispatch(
      featureDataTransferringOperations.setSelectedFeatureDataGroup(
        featureDataGroup
      )
    )
  },

  /** 選んだ特徴量データグループを設定する */
  setSelectedFeatureData: (featureData: FeatureData) => {
    dispatch(
      featureDataTransferringOperations.setSelectedFeatureData(featureData)
    )
  },

  /** 特徴量データグループの表示条件の変更 */
  setFeatureDataGroupDisplayCondition: (displayCondition: DisplayCondition) =>
    dispatch(
      featureDataTransferringActions.setFeatureDataGroupDisplayCondition(
        displayCondition
      )
    ),

  /** 特徴量データの表示条件の変更 */
  setFeatureDataDisplayCondition: (displayCondition: DisplayCondition) =>
    dispatch(
      featureDataTransferringActions.setFeatureDataDisplayCondition(
        displayCondition
      )
    ),

  /** 入力したメタデータをセットする(特徴量データ情報) */
  setMlPipelinesMetaData: (data?: MetaData) =>
    dispatch(featureDataTransferringOperations.setMlPipelinesMetaData(data)),

  clearFeatureDataTransferringState: () =>
    dispatch(
      featureDataTransferringActions.clearFeatureDataTransferringState()
    ),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40
/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** テーブルのヘッダー */
const FEATURE_DATA_GROUP_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'featureDataGroupId',
    title: '特徴量データグループID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureDataGroupName',
    title: '特徴量データグループ名',
    width: 250,
    sortable: true,
    position: 'left',
  },
  {
    id: 'featureDataCount',
    title: '登録特徴量データ数',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'version',
    title: '最新バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'newFeatureDataName',
    title: '最新特徴量データ名',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'updatedAt',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'createdBy',
    title: '作成ユーザー ID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const FEATURE_DATA_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'featureDataId',
    title: '特徴量データID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'version',
    title: 'バージョン',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'featureDataName',
    title: '特徴量データ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
]

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  outputFormatSelection: {
    display: 'flex',
    justifyContent: 'felx-start',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  dataCreateDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  detailListItem: {
    padding: 0,
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
  cbList: {
    width: '100%',
  },
}))

/** ステップの名称 */
const STEP_NAMES = [
  '特徴量データグループ',
  '特徴量データ',
  'メタデータ',
  '確認',
]

/**
 * データ転送指示画面
 */
export const FeatureDataTransferring: FC<Props> = (props) => {
  const { classes } = useStyles()
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const {
    isDisablePrevButton,
    nextButtonLabel,
    isSharedUserGroup,
    currentStep,
    featureDataGroupToDetail,
    onClickDialogCloseButton,
  } = useTable(props)

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    /** ステップ更新時のスクロール操作 */
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
      // handleChangeSearchValue('')
    }
    /** 各種一覧取得（学習アルゴリズム/学習特徴量データ/データセット/セッティング/推論アルゴリズム） */
    props.getParams(
      props.appState.featureDataTransferringState,
      props.domainData.selectedFeatureDataGroup?.featureDataGroupId,
      isSharedUserGroup
    )
  }, [
    props.appState.featureDataTransferringState,
    props.domainData.selectedFeatureDataGroup?.featureDataGroupId,
  ])

  useEffect(() => {
    return () => {
      props.clearFeatureDataTransferringState()
    }
  }, [])

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.featureDataTransferringState) {
      case 'FeatureDataGroupState':
        return (
          props.appState.featureDataTransferringSubState
            .featureDataGroupSubState === 'Selected'
        )
      case 'FeatureDataState':
        return (
          props.appState.featureDataTransferringSubState.featureDataSubState ===
          'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.featureDataTransferringSubState.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    props.appState.featureDataTransferringSubState,
    props.domainData.selectedFeatureDataGroup,
  ])

  return (
    <div className={classes.container}>
      <Box pl={7}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: '特徴量データ転送指示一覧',
              path: 'feature-data-transferrings',
            },
            {
              name: '特徴量データ転送指示',
              path: 'entry',
            },
          ]}
        />
      </Box>
      <div className={classes.head}>
        <div className={classes.flexAndBetween}>
          <h2
            className={classes.pageTitle}
            data-testid='feature-data-transferring-title'
          >
            特徴量データ転送
          </h2>
          <div className={classes.confirmButton}>
            <Tooltip title='設定情報' placement='bottom'>
              <IconButton onClick={() => setOpenConfirmDialog(true)}>
                <ReceiptLongIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className={classes.stepper}>
          <CommonStepper
            stepLabels={STEP_NAMES}
            activeStepIndex={currentStep}
          />
        </div>
      </div>
      <div className={classes.content} ref={contentRef}>
        <Contents {...props} />
      </div>
      <div className={classes.footerButtons}>
        <div className={classes.footer}>
          {!isDisablePrevButton ? (
            <Button
              variant='contained'
              color='primary'
              disabled={isDisablePrevButton}
              hidden={isDisablePrevButton}
              onClick={() =>
                props.onClickPrevButton(
                  props.appState.featureDataTransferringState
                )
              }
              className={classes.leftButton}
            >
              戻る
            </Button>
          ) : (
            <div></div>
          )}
          <Button
            data-testid='next-step'
            variant='contained'
            color='primary'
            disabled={!enableNextButton}
            onClick={() =>
              props.onClickNextButton(
                props.appState.featureDataTransferringState
              )
            }
            className={classes.rightButton}
          >
            {nextButtonLabel}
          </Button>
        </div>
      </div>
      <MLPipelineCompleteDialog
        open={
          !isUndefined(props.domainData.executionInfo?.mlPipelineId) &&
          !isUndefined(props.domainData.executionInfo?.transferringStepId)
        }
        value={props.domainData.executionInfo?.mlPipelineId ?? ''}
        secondValueItem={{
          label: '転送ステップ ID',
          value: props.domainData.executionInfo?.transferringStepId ?? '',
        }}
        handleClose={onClickDialogCloseButton}
        label={'MLパイプラインID'}
        dialogText={'正常に転送を開始しました。'}
        data-testid={'ml-pipeline-id'}
      />
      <ConfirmViewerDialog
        maxWidth='md'
        open={openConfirmDialog}
        message={
          <Box width='630px'>
            <Box display='flex'>
              <Box display='flex' paddingTop={2}>
                <Checkbox
                  sx={
                    props.domainData.mlPipelinesMetaData !== undefined &&
                    props.domainData.mlPipelinesMetaData?.name !== ''
                      ? {
                          color: 'blue',
                          '&.Mui-checked': {
                            color: 'blue',
                          },
                        }
                      : undefined
                  }
                  checkedIcon={<CheckCircleIcon />}
                  disabled
                  checked
                />
              </Box>
              <FeatureDataTransferringInfoConfirmView {...props} />
            </Box>
            <Box display='flex'>
              <Box display='flex' paddingTop={2}>
                <Checkbox
                  sx={
                    props.domainData.selectedFeatureDataGroup !== undefined &&
                    props.domainData.selectedFeatureData !== undefined
                      ? {
                          color: 'blue',
                          '&.Mui-checked': {
                            color: 'blue',
                          },
                        }
                      : undefined
                  }
                  checkedIcon={<CheckCircleIcon />}
                  disabled
                  checked
                />
              </Box>
              <FeatureDataGroupInfoConfirmView
                {...props}
                featureDataGroupToDetail={featureDataGroupToDetail}
              />
            </Box>
          </Box>
        }
        handleClose={() => setOpenConfirmDialog(false)}
      />

      <GlobalLoading open={props.appState.inProgress} />
    </div>
  )
}

/**
 * ステップ毎に切り替わる内容コンポーネント
 */
const Contents: FC<Props> = (props) => {
  const { appState } = props
  const { featureDataTransferringState } = appState
  const { classes } = useStyles()
  const {
    selectedFeatureDataGroupIndex,
    selectedFeatureDataIndex,
    featureDataGroupTableRows,
    featureDataTableRows,
    featureDataGroupToDetail,
    selectFeatureDataGroupTableRadio,
    selectFeatureDataTableRadio,
    changePage,
    changeTableSortOrder,
    handleChangeDisplayNumber,
    updateMlPipelinesMetaDataName,
    updateMlPipelinesMetaDataRemarks,
  } = useTable(props)

  /** 特徴量データ転送の開始失敗時メッセージ */
  const FeatureDataTransferringErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.featureDataTransferringSubState.executeSubState ===
      'ExecuteError'
    ) {
      errorMessages.push('転送指示に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  switch (featureDataTransferringState) {
    case 'FeatureDataGroupState':
      return (
        <div className={classes.stepContainer}>
          <CustomTrainingPageParagraph>
            <Box mt={2}>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='featureDataTransferringModel'>
                  アルゴリズム
                </InputLabel>
                <Select
                  data-testid='select'
                  value={'テンプレートマッチング'}
                  label='Select Algorithm'
                  defaultValue={'テンプレートマッチング'}
                >
                  {
                    <MenuItem
                      data-testid={'templateMatching'}
                      value={'テンプレートマッチング'}
                    >
                      テンプレートマッチング
                    </MenuItem>
                  }
                </Select>
              </FormControl>
            </Box>
          </CustomTrainingPageParagraph>
          <CustomTrainingPageParagraph>
            <SelectableTable
              displayNumber={
                props.domainData.featureDataGroupDisplayCondition.displayNumber
              }
              headers={FEATURE_DATA_GROUP_TABLE_HEADERS}
              selectedRowNumber={selectedFeatureDataGroupIndex}
              rows={featureDataGroupTableRows}
              totalCount={props.domainData.featureDataGroups.length}
              tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
              fixedColumnNumber={0}
              page={
                props.domainData.featureDataGroupDisplayCondition.pageNumber
              }
              onClickRadio={(index) => {
                selectFeatureDataGroupTableRadio(index)
              }}
              sortOrder={{
                key: props.domainData.featureDataGroupDisplayCondition.sortKey,
                order:
                  props.domainData.featureDataGroupDisplayCondition.sortOrder,
              }}
              onClickOrderChange={(key: string) => changeTableSortOrder(key)}
              onClickPageChange={(pageNumber: number) => changePage(pageNumber)}
              onChangeDisplayNumber={(displayNumber: number) =>
                handleChangeDisplayNumber(displayNumber)
              }
            />
          </CustomTrainingPageParagraph>
        </div>
      )
    case 'FeatureDataState':
      return (
        <div className={classes.stepContainer}>
          <CustomTrainingPageParagraph>
            <SelectableTable
              displayNumber={
                props.domainData.featureDataDisplayCondition.displayNumber
              }
              headers={FEATURE_DATA_TABLE_HEADERS}
              rows={featureDataTableRows ?? []}
              selectedRowNumber={selectedFeatureDataIndex}
              totalCount={props.domainData.featureDataList?.length}
              tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
              fixedColumnNumber={0}
              page={props.domainData.featureDataDisplayCondition.pageNumber}
              sortOrder={{
                key: props.domainData.featureDataDisplayCondition.sortKey,
                order: props.domainData.featureDataDisplayCondition.sortOrder,
              }}
              onClickRadio={(index) => {
                selectFeatureDataTableRadio(index)
              }}
              onClickOrderChange={(key: string) => changeTableSortOrder(key)}
              onClickPageChange={(pageNumber: number) => changePage(pageNumber)}
              onChangeDisplayNumber={(displayNumber: number) =>
                handleChangeDisplayNumber(displayNumber)
              }
            />
          </CustomTrainingPageParagraph>
        </div>
      )

    case 'MetaDataState':
      return (
        <div className={classes.stepContainer}>
          <CustomTrainingPageParagraph
            level={'part'}
            title={'特徴量データ転送指示情報'}
          >
            <MetadataInput
              nameProps={{
                label: '表示名',
                value: props.domainData.mlPipelinesMetaData?.name,
                variant: 'outlined',
                readOnly: false,
                onChange: (e) => updateMlPipelinesMetaDataName(e.target.value),
              }}
              remarksProps={{
                label: '備考',
                value: props.domainData.mlPipelinesMetaData?.remarks,
                variant: 'outlined',
                readOnly: false,
                onChange: (e) =>
                  updateMlPipelinesMetaDataRemarks(e.target.value),
                rowNum: 0,
              }}
              helperText=''
              data-testid={'feature-data-transferring-input'}
            />
          </CustomTrainingPageParagraph>
        </div>
      )
    case 'ExecuteState':
      return (
        <div className={classes.stepContainer}>
          <FeatureDataTransferringErrorMessage {...props} />
          <CustomTrainingPageParagraph
            level={'part'}
            title={'特徴量データ転送指示情報'}
          >
            <FeatureDataTransferringInfoConfirmView {...props} />
            <FeatureDataGroupInfoConfirmView
              {...props}
              featureDataGroupToDetail={featureDataGroupToDetail}
            />
          </CustomTrainingPageParagraph>
        </div>
      )
    default:
      return <></>
  }
}

/**
 * 確認ステップで表示する特徴量データ転送情報
 */
const FeatureDataTransferringInfoConfirmView: FC<Props> = (props) => {
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>特徴量データ転送情報</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: '表示名',
            value: props.domainData.mlPipelinesMetaData?.name,
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.mlPipelinesMetaData?.remarks,
            readOnly: true,
            variant: 'standard',
            rowNum: 0,
          }}
          textFieldSpace={0}
          helperText=''
          data-testid={'featureDataTransferringInputPreview'}
        />
      </Box>
    </Box>
  )
}

type FeatureDataGroupInfoConfirmViewProps = Props & {
  featureDataGroupToDetail: (featureDataGroup?: FeatureDataGroup) =>
    | {
        ['特徴量データグループID']: string
        ['特徴量データグループ名']: string
        ['登録特徴量データ数']: string
        ['最新バージョン']: string
        ['最新特徴量データ名']: string
        ['更新日時']: string
        ['作成日時']: string
        ['作成ユーザーID']: string
      }
    | undefined
}
/**
 * 確認ステップで表示する特徴量データ情報
 */
const FeatureDataGroupInfoConfirmView: FC<
  FeatureDataGroupInfoConfirmViewProps
> = (props) => {
  const { featureDataGroupToDetail } = props
  const { classes } = useStyles()

  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>特徴量データ情報</Typography>
      <Box mt={1}>
        <InputLabel shrink>特徴量データグループ</InputLabel>
        <Box mt={1} mb={2}>
          <AccordionLabel
            label={
              props.domainData.selectedFeatureDataGroup?.featureDataGroupName
            }
            details={featureDataGroupToDetail(
              props.domainData.selectedFeatureDataGroup
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
        <Box mt={2}>
          <TextField
            id='featureDataVersion'
            className={classes.textField}
            variant='standard'
            label='特徴量データ名'
            value={props.domainData.selectedFeatureData?.featureDataName}
            key={props.domainData.selectedFeatureData?.featureDataName}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <TextField
            id='featureDataVersion'
            className={classes.textField}
            variant='standard'
            label='特徴量データバージョン'
            value={
              props.domainData.selectedFeatureData?.featureDataVersion
                .displayName
            }
            key={
              props.domainData.selectedFeatureData?.featureDataVersion
                .displayName
            }
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

/**
 * カスタムフック
 */
const useTable = (props: Props) => {
  const globalTheme = useTheme()
  const history = useHistory()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  /** ダイアログ表示フラグ */
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  /** 戻るボタンの非表示フラグ */
  const isDisablePrevButton = useMemo(
    () =>
      props.appState.featureDataTransferringState === 'FeatureDataGroupState',
    [props.appState.featureDataTransferringState]
  )
  /** カレントのステップ */
  const currentStep = useMemo(
    () =>
      FeatureDataTransferringStateKindArray.indexOf(
        props.appState.featureDataTransferringState
      ),
    [props.appState.featureDataTransferringState]
  )

  /**
   * 次へボタンのラベル
   * 確認ステップの場合は完了、それ以外の場合は次へ
   */
  const nextButtonLabel = useMemo(
    () =>
      props.appState.featureDataTransferringState === 'ExecuteState'
        ? '開始'
        : '次へ',
    [props.appState.featureDataTransferringState]
  )

  /** ダイアログの閉じるボタン押下時の処理 */
  const onClickDialogCloseButton = useCallback(() => {
    history.push('/feature-data-transferrings')
  }, [setIsOpenDialog])

  /** 特徴量データグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.featureDataGroups) &&
      isUndefined(props.domainData.selectedFeatureDataGroup)
    ) {
      props.setSelectedFeatureDataGroup(props.domainData.featureDataGroups[0])
    }
  }, [props.domainData.featureDataGroups])

  /** 特徴量データの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.featureDataList) &&
      isUndefined(props.domainData.selectedFeatureData)
    ) {
      props.setSelectedFeatureData(props.domainData.featureDataList[0])
    }
  }, [props.domainData.featureDataList, props.domainData.selectedFeatureData])

  useEffect(() => {
    props.setSelectedTrainingAlgorithmId(
      props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
      )[0].algorithmId
    )
  }, [])

  /** 特徴量データグループのラジオボタンクリック */
  const selectFeatureDataGroupTableRadio = (row: number) => {
    const featureDataGroup = adjustFeatureDataGroupTableRow[row]
    props.setSelectedFeatureDataGroup(featureDataGroup)
  }

  /** 特徴量データのラジオボタンクリック */
  const selectFeatureDataTableRadio = (row: number) => {
    const featureDataGroup = tableContent[row]
    props.setSelectedFeatureData(featureDataGroup)
  }

  /** 表示対象の特徴量データグループデータ */
  const adjustFeatureDataGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.featureDataGroupDisplayCondition)) {
      let newFeatureDataGroupArray = props.domainData.featureDataGroups
      /** 検索ワードから検索 */
      if (props.domainData.featureDataGroupDisplayCondition.searchValue) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.filter(
          (item) =>
            item.featureDataGroupId.startsWith(
              props.domainData.featureDataGroupDisplayCondition.searchValue
            ) ||
            item.featureDataGroupId ===
              props.domainData.featureDataGroupDisplayCondition.searchValue
        )
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.featureDataGroupDisplayCondition.sortKey ===
        'createdAt'
      ) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.featureDataGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else if (
        props.domainData.featureDataGroupDisplayCondition.sortKey ===
        'updatedAt'
      ) {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            return (
              ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.featureDataGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else {
        newFeatureDataGroupArray = newFeatureDataGroupArray.sort(
          (item, item2) => {
            if (item.featureDataGroupName < item2.featureDataGroupName) {
              return props.domainData.featureDataGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1
            }
            if (item.featureDataGroupName > item2.featureDataGroupName) {
              return props.domainData.featureDataGroupDisplayCondition
                .sortOrder === 'asc'
                ? 1
                : -1
            }
            // names must be equal
            return 0
          }
        )
      }
      /** 表示するベース特徴量データの整形 */
      return newFeatureDataGroupArray.slice(
        props.domainData.featureDataGroupDisplayCondition.displayNumber *
          props.domainData.featureDataGroupDisplayCondition.pageNumber,
        (props.domainData.featureDataGroupDisplayCondition.pageNumber + 1) *
          props.domainData.featureDataGroupDisplayCondition.displayNumber
      )
    }
    return props.domainData.featureDataGroups
  }, [
    props.domainData.featureDataGroupDisplayCondition,
    props.domainData.featureDataGroups,
  ])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const featureDataGroupTableRows = useMemo(() => {
    const rowData = adjustFeatureDataGroupTableRow.map((featureDataGroup) => {
      return {
        featureDataGroupId: featureDataGroup.featureDataGroupId,
        featureDataGroupName: featureDataGroup.featureDataGroupName,
        featureDataCount: featureDataGroup.featureDataCount,
        latestFeatureDataVersion: featureDataGroup.latestFeatureDataVersion,
        latestFeatureDataName: featureDataGroup.latestFeatureDataName,
        updatedAt: featureDataGroup.updatedAt
          ? formatDateTimeSec(featureDataGroup.updatedAt.toDate())
          : '',
        createdAt: featureDataGroup.createdAt
          ? formatDateTimeSec(featureDataGroup.createdAt.toDate())
          : '',
        createdBy: featureDataGroup.createdBy,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataGroupId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography data-testid={`feature-data-group-${value}`}>
                  {value.substring(0, 8)}
                </Typography>
              </Tooltip>
            )
          } else {
            return (
              <Typography key={key} data-testid={`feature-data-group-${value}`}>
                {value}
              </Typography>
            )
          }
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key !== 'featureDataGroupId' && value && value !== '') {
          return (
            <Typography key={key} data-testid={`feature-data-group-${value}`}>
              {value}
            </Typography>
          )
        } else if (key === 'featureDataCount') {
          return <Typography key={key}>{value}</Typography>
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustFeatureDataGroupTableRow])

  /** 特徴量データグループテーブルの選択状態 */
  const selectedFeatureDataGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.featureDataGroups)) return -1
    return adjustFeatureDataGroupTableRow.findIndex(
      (featureDataGroup) =>
        featureDataGroup.featureDataGroupId ===
        props.domainData.selectedFeatureDataGroup?.featureDataGroupId
    )
  }, [
    adjustFeatureDataGroupTableRow,
    props.domainData.selectedFeatureDataGroup,
  ])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    if (isUndefined(props.domainData.featureDataList)) {
      return [] as unknown as FeatureData[]
    }
    // 表示条件
    const condition = props.domainData.featureDataDisplayCondition
    let newFeatureDataArray = props.domainData.featureDataList

    // 並び替え
    newFeatureDataArray = newFeatureDataArray.sort(
      (
        item1: {
          featureDataId: string
          featureDataVersion: Version
          featureDataName: string
        },
        item2: {
          featureDataId: string
          featureDataVersion: Version
          featureDataName: string
        }
      ) => {
        return compareVersions(
          item1.featureDataVersion,
          item2.featureDataVersion,
          props.domainData.featureDataDisplayCondition.sortOrder
        )
      }
    )

    // 表示条件に合わせて配列を加工
    return newFeatureDataArray.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )
  }, [
    props.domainData.featureDataDisplayCondition,
    props.domainData.featureDataList,
  ])

  /** 特徴量データテーブルの選択状態 */
  const selectedFeatureDataIndex = useMemo(() => {
    if (isUndefined(props.domainData.featureDataList)) return -1
    return tableContent.findIndex(
      (featureData) =>
        featureData.featureDataId ===
        props.domainData.selectedFeatureData?.featureDataId
    )
  }, [tableContent, props.domainData.selectedFeatureData])

  /** テーブルに表示する特徴量データのJSXの２次元配列 */
  const featureDataTableRows = useMemo(() => {
    if (!tableContent) return

    // 並び替え
    const rowData = tableContent.map((featureData) => {
      return {
        featureDataId: featureData.featureDataId,
        featureDataVersion: featureData.featureDataVersion,
        featureDataName: featureData.featureDataName,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataId') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'featureDataVersion') {
          if (isVersion(value)) {
            return <Typography key={key}>{value.displayName}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'featureDataName') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    switch (props.appState.featureDataTransferringState) {
      case 'FeatureDataGroupState':
        /** ソートキー、ソートオーダーをセット */
        props.setFeatureDataGroupDisplayCondition({
          ...props.domainData.featureDataGroupDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.featureDataGroupDisplayCondition.sortKey === key
              ? props.domainData.featureDataGroupDisplayCondition.sortOrder ===
                'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.featureDataGroupDisplayCondition.sortOrder,
        })
        break
      case 'FeatureDataState':
        /** ソートキー、ソートオーダーをセット */
        props.setFeatureDataDisplayCondition({
          ...props.domainData.featureDataDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.featureDataDisplayCondition.sortKey === key
              ? props.domainData.featureDataDisplayCondition.sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.featureDataDisplayCondition.sortOrder,
        })
        break
      default:
        break
    }
  }

  /** テーブルのページ切り替え */
  const changePage = (pageNumber: number) => {
    switch (props.appState.featureDataTransferringState) {
      case 'FeatureDataGroupState':
        props.setFeatureDataGroupDisplayCondition({
          ...props.domainData.featureDataGroupDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'FeatureDataState':
        props.setFeatureDataDisplayCondition({
          ...props.domainData.featureDataDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      default:
        break
    }
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    switch (props.appState.featureDataTransferringState) {
      case 'FeatureDataGroupState':
        {
          const maxPageNumber =
            Math.ceil(
              props.domainData.featureDataGroups.length / displayNumber
            ) - 1
          if (
            props.domainData.featureDataGroupDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setFeatureDataGroupDisplayCondition({
              ...props.domainData.featureDataGroupDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setFeatureDataGroupDisplayCondition({
              ...props.domainData.featureDataGroupDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
      case 'FeatureDataState':
        {
          if (isUndefined(props.domainData.featureDataList)) return
          const maxPageNumber =
            Math.ceil(props.domainData.featureDataList.length / displayNumber) -
            1

          if (
            props.domainData.featureDataDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setFeatureDataDisplayCondition({
              ...props.domainData.featureDataDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setFeatureDataDisplayCondition({
              ...props.domainData.featureDataDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
    }
  }

  /** 特徴量データ転送情報の表示名更新処理 */
  const updateMlPipelinesMetaDataName = (name: string) => {
    props.setMlPipelinesMetaData({
      ...props.domainData.mlPipelinesMetaData,
      name,
    })
  }

  /** 特徴量データ転送情報の備考更新処理 */
  const updateMlPipelinesMetaDataRemarks = (remarks: string) => {
    props.setMlPipelinesMetaData({
      ...props.domainData.mlPipelinesMetaData,
      remarks,
    })
  }

  /** 特徴量データグループ詳細変換 */
  const featureDataGroupToDetail = (featureDataGroup?: FeatureDataGroup) => {
    if (isUndefined(featureDataGroup)) return undefined
    return {
      ['特徴量データグループID']: featureDataGroup.featureDataGroupId,
      ['特徴量データグループ名']: featureDataGroup.featureDataGroupName,
      ['登録特徴量データ数']: `${featureDataGroup.featureDataCount}`,
      ['最新バージョン']: featureDataGroup.latestFeatureDataVersion,
      ['最新特徴量データ名']: featureDataGroup.latestFeatureDataName,
      ['更新日時']: featureDataGroup.updatedAt
        ? formatDateTimeSec(featureDataGroup.updatedAt.toDate())
        : '',
      ['作成日時']: featureDataGroup.createdAt
        ? formatDateTimeSec(featureDataGroup.createdAt.toDate())
        : '',
      ['作成ユーザーID']: featureDataGroup.createdBy,
    }
  }

  return {
    featureDataGroupTableRows,
    featureDataTableRows,
    selectedFeatureDataGroupIndex,
    selectedFeatureDataIndex,
    nextButtonLabel,
    isDisablePrevButton,
    isOpenDialog,
    isSharedUserGroup,
    currentStep,
    changeTableSortOrder,
    changePage,
    handleChangeDisplayNumber,
    selectFeatureDataGroupTableRadio,
    selectFeatureDataTableRadio,
    featureDataGroupToDetail,
    onClickDialogCloseButton,
    updateMlPipelinesMetaDataName,
    updateMlPipelinesMetaDataRemarks,
  }
}

export const FeatureDataTransferringPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataTransferring))
