import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import { ChevronLeft, ChevronRight, ExpandMore } from '@mui/icons-material'
import * as MUIcon from '@mui/icons-material'

import {
  SideMenuProps,
  RouteCategoriesKey,
  ROUTE_CATEGORIES_USER,
  ROUTE_CATEGORIES_SUPER_USER,
  Section,
  Item,
  CategoryList,
  ROUTE_CATEGORIES_ADMIN,
} from './types'
import { CustomerAuthorizer } from 'views/components/organisms/customerAuthorizer'
import { AccountAuthorizer } from 'views/components/organisms/accountAuthorizer'
import { AccountGroupRole } from 'views/utils/types'

const useStyles = () =>
  makeStyles()((theme) => ({
    sideMenu: {
      height: '100%',
      position: 'relative',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      zIndex: 10,
      backgroundColor: theme.palette.common.white,
      willChange: 'width',
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      borderWidth: 0,
      borderRightWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[300],
    },
    activeLink: {
      backgroundColor: '#19ABC0',
      color: '#FFFFFF',
      borderRadius: 8,
      '& .MuiSvgIcon-root': {
        color: '#FFFFFF',
        stroke: '#FFFFFF',
        fill: '#19ABC0',
      },
    },
  }))

interface MuiIconProps {
  name?: keyof typeof MUIcon
  sx?: SxProps<Theme>
}

const getEnableRouteCategories = (
  superUser: boolean,
  accountGroupRole: AccountGroupRole
) => {
  if (superUser) return ROUTE_CATEGORIES_SUPER_USER
  if (accountGroupRole === 'admin') return ROUTE_CATEGORIES_ADMIN
  return ROUTE_CATEGORIES_USER
}

const MuiIcon: React.FC<MuiIconProps> = ({ name, sx }) => {
  const IconNode = name && MUIcon[name]
  if (IconNode) {
    return <IconNode sx={sx} />
  }
  return <></>
}

interface SectionProps {
  expandList: string[]
  section: Section
  isOpen: boolean
  onClickOpen: () => void
  onExpand: (panel: string) => void
}

const SectionRoute: React.FC<SectionProps> = (props: SectionProps) => {
  const { expandList, section, isOpen, onClickOpen, onExpand } = props
  const location = useLocation()
  const paths = section.itemList.map((r) => r.path)
  const isActive = paths.some((p) => location.pathname.startsWith(p))

  return (
    <Accordion
      expanded={isOpen && expandList.includes(section.label)}
      sx={{ boxShadow: 'initial' }}
      onClick={() => !isOpen && onClickOpen()}
      onChange={() =>
        (isOpen || (!isOpen && !expandList.includes(section.label))) &&
        onExpand(section.label)
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={section.label}
        id={section.label}
        sx={{
          minHeight: '48px !important',
          justifyContent: 'initial !important',
          backgroundColor: isActive ? '#f5f5f5' : 'initial',
          '.Mui-expanded': {
            marginTop: '12px !important',
            marginBottom: '12px !important',
          },
          ':hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <MuiIcon
          name={section.menuIcon as keyof typeof MUIcon}
          sx={{
            marginRight: (theme) => theme.spacing(2),
          }}
        />
        <Typography variant='body2' sx={{ lineHeight: 1.65 }}>
          {section.label}
        </Typography>
      </AccordionSummary>
      {section.itemList
        .filter((r) => r.sideMenu)
        .map((r) => (
          <ListItemButton
            key={r.menuName}
            component={NavLink}
            to={r.path}
            exact={r.path === '/'}
            sx={{
              '&.active': {
                backgroundColor: (theme) => theme.palette.grey[100],
              },
            }}
            data-testid={r['data-testid'] + '-on-menu-list'}
          >
            <ListItemText
              primary={<Typography variant='body2'>{r.menuName}</Typography>}
              sx={{ marginLeft: (theme) => theme.spacing(5) }}
            />
          </ListItemButton>
        ))}
    </Accordion>
  )
}

interface ItemRouteProps {
  item: Item
}

const ItemRoute: React.FC<ItemRouteProps> = (props: ItemRouteProps) => {
  const { item } = props
  return (
    <ListItemButton
      component={NavLink}
      to={item.path}
      exact={item.path.split('/').at(2) === ''}
      sx={{
        '&.active': {
          backgroundColor: (theme) => theme.palette.grey[100],
        },
      }}
      data-testid={item['data-testid'] + '-on-menu-list'}
    >
      <ListItemIcon
        sx={{ minWidth: '40px', color: (theme) => theme.palette.text.primary }}
      >
        <MuiIcon name={item.menuIcon as keyof typeof MUIcon} />
      </ListItemIcon>
      <ListItemText
        primary={<Typography variant='body2'>{item.menuName}</Typography>}
      />
    </ListItemButton>
  )
}

export const SideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {
  const {
    isOpen,
    menu,
    onClickClose,
    onClickOpen,
    superUser,
    customerRole,
    accountGroupRole,
  } = props
  const { classes } = useStyles()()
  const [expandList, setExpandList] = useState<string[]>(
    Object.values(menu).flatMap((categoryList: CategoryList) =>
      categoryList.categories
        .map((category) => category.section?.label)
        .filter((r): r is string => typeof r == 'string')
    )
  )

  const enableRouteCategories = getEnableRouteCategories(
    superUser,
    accountGroupRole
  )

  const handleOnExpand = (panel: string) => {
    if (expandList.includes(panel)) {
      setExpandList(expandList.filter((val) => val !== panel))
      return
    }
    setExpandList([...expandList, panel])
  }

  useEffect(() => {
    if (!isOpen) {
      setExpandList([])
    }
  }, [isOpen])

  return (
    <Box
      className={classes.sideMenu}
      sx={{
        width: (theme) =>
          isOpen ? props.sideMenuWidth : theme.custom.sideMenu.shrinked.width,
      }}
    >
      <Box
        sx={{
          height: 'calc(100vh - 42px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{ minHeight: (theme) => theme.custom.header.height + 4 }}
        ></Box>
        {(Object.keys(menu) as RouteCategoriesKey[])
          .filter((key) => enableRouteCategories.includes(key))
          .map((key, index) => {
            const menuCategory = menu[key]
            return (
              <Box key={key}>
                {index > 0 && <Divider />}
                {menuCategory.label && isOpen && (
                  <Box mt={1} pl={1} height={20} fontSize={14}>
                    {`${menuCategory.label}`}
                  </Box>
                )}
                {menuCategory.categories.map((category) => (
                  <Box key={category.section?.label ?? category.item?.menuName}>
                    {category.item && category.item.sideMenu && (
                      <AccountAuthorizer
                        type={category.item.accountFunction}
                        superUser={superUser}
                        accountGroupRole={accountGroupRole}
                      >
                        <CustomerAuthorizer
                          type={category.item.customerFunction}
                          auth={customerRole}
                        >
                          <ItemRoute item={category.item} />
                        </CustomerAuthorizer>
                      </AccountAuthorizer>
                    )}
                    {category.section && (
                      <AccountAuthorizer
                        type={category.section.accountFunction}
                        superUser={superUser}
                        accountGroupRole={accountGroupRole}
                      >
                        <CustomerAuthorizer
                          type={category.section.customerFunction}
                          auth={customerRole}
                        >
                          <SectionRoute
                            expandList={expandList}
                            section={category.section}
                            isOpen={isOpen}
                            onClickOpen={onClickOpen}
                            onExpand={handleOnExpand}
                          />
                        </CustomerAuthorizer>
                      </AccountAuthorizer>
                    )}
                  </Box>
                ))}
              </Box>
            )
          })}
      </Box>
      <Box
        sx={{
          height: '42ox',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: (theme) => (isOpen ? theme.spacing(0, 1) : 0),
          justifyContent: isOpen ? 'flex-end' : 'center',
          borderWidth: 0,
          borderTopWidth: 1,
          borderStyle: 'solid',
          borderColor: (theme) => theme.palette.grey[300],
        }}
      >
        <IconButton onClick={isOpen ? onClickClose : onClickOpen}>
          {!isOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Box>
    </Box>
  )
}
