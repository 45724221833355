import { Timestamp } from 'state/firebase'
import { ResourceLoadingState } from 'state/utils'
import { Version } from 'types/StateTypes'

export const SettingDetailActionType = {
  SET_CURRENT_SETTING_DETAIL: 'SET_CURRENT_SETTING_DETAIL',
  CLEAR_SETTING_DETAIL_STATE: 'CLEAR_SETTING_DETAIL_STATE',
  SET_MEDIA_DETAIL_STATE: 'SET_MEDIA_DETAIL_STATE',
  SET_TOAST_INFO_FOR_SETTING_DETAIL: 'SET_TOAST_INFO_FOR_SETTING_DETAIL',
  SET_IN_PROGRESS_FOR_SETTING_DETAIL: 'SET_IN_PROGRESS_FOR_SETTING_DETAIL',
} as const

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface MediaLink {
  mediaName: string
  mediaUrl: string
  mediaSize: number
}

export interface SettingFileDlLink {
  linkName: string
  mediaLinks: MediaLink[]
}

export interface GetSettingFilesResponse {
  data: {
    items: SettingFileDlLink[]
  }
}

export interface CurrentSettingDetail {
  settingId: string
  settingName: string
  algorithmId: string
  remarks: string
  settingGroup: {
    settingGroupId: string
    settingGroupName: string
    settingGroupVersion: Version
  }
  settingFormat: {
    settingFormatKind: string
    settingFormatVersion: Version
  }
  datasetTemplate: {
    datasetTemplateName: string
  }
  settingDlLinks: {
    linkName: string
    mediaLinks: {
      mediaName: string
      mediaUrl: string
      mediaSize: number
    }[]
  }[]
  trainedModelGroup: {
    trainedModelGroupId: string
  }
  createdAt: Timestamp
  createdBy: string
}

export interface SettingDetailDomainData {
  currentSettingDetail: CurrentSettingDetail
}

export interface SettingDetailState {
  settingDlLinkSubState: ResourceLoadingState
  settingDataState: ResourceLoadingState
}

export interface SettingDetailAppState {
  inProgress: boolean
  settingDetailState: SettingDetailState
  toastInfo: ToastInfo | undefined
}

export interface SettingDetail {
  domainData: SettingDetailDomainData
  appState: SettingDetailAppState
}
