import { Dispatch } from 'redux'
import { State } from 'state/store'
import { featureDataTransferringActions } from './actions'
import { doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { getFeatureDataGroupQueriesCollection } from 'state/firebase'
import {
  FeatureDataGroup,
  FeatureDataList,
  FeatureDataTransferringParamsType,
  FeatureDataTransferringStateKind,
  FeatureDataTransferringStateKindArray,
  MetaData,
  FeatureData,
} from './types'
import { domainDataOperations } from 'state/app/domainData/operations'
import { FeatureDataTransferringApi } from './apis'
import { HttpsCallableResult } from 'firebase/functions'
import { compareVersions } from 'utils/versions'
import { DocumentVersion } from 'utils/fireStore/common'
import { formatDateTimeSec } from 'views/components/utils/date'

type FeatureDataType = {
  ['feature-data-group-version']: DocumentVersion
  ['feature-data-id']: string
  ['feature-data-name']: string
}

const getFeatureDataList = (list: FeatureDataType[]): FeatureData[] => {
  const featureDataList = list.map((item) => {
    return {
      featureDataId: item['feature-data-id'],
      featureDataVersion: {
        displayName: item['feature-data-group-version']['display-name'],
        major: item['feature-data-group-version']['major'],
        minor: item['feature-data-group-version']['minor'],
        patch: item['feature-data-group-version']['patch'],
      },
      featureDataName: item['feature-data-name'],
    }
  })

  if (featureDataList.length === 1) return featureDataList
  // バージョンでソートして返す
  return featureDataList.sort((a, b) =>
    compareVersions(a.featureDataVersion, b.featureDataVersion, 'desc')
  )
}

export const featureDataTransferringOperations = {
  /** 特徴量データグループの取得 */
  getFeatureDataGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // ベースモデルの一覧とベースモデルのメタデータの一覧を取得して整形
      try {
        dispatch(featureDataTransferringActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const featureDataGroup = await getDocs(
          query(
            getFeatureDataGroupQueriesCollection(userGroupId),
            where(
              'algorithm-id',
              '==',
              getState().pages.featureDataTransferringState.domainData
                .selectedTrainingAlgorithm?.algorithmId
            )
          )
        )

        if (!featureDataGroup) return

        const featureDataGroups: (FeatureDataGroup | undefined)[] =
          featureDataGroup.docs.map((item) => {
            const featureDataGroupData = item.data()
            const featureData = featureDataGroupData['feature-data-list'].find(
              (data: FeatureDataList) =>
                data['feature-data-group-version']['display-name'] ===
                `${featureDataGroupData['feature-data-group-version-latest']['major']}.${featureDataGroupData['feature-data-group-version-latest']['minor']}.${featureDataGroupData['feature-data-group-version-latest']['patch']}`
            )
            const featureDataCount = featureDataGroupData['feature-data-count']
            return {
              featureDataGroupId: featureDataGroupData['feature-data-group-id'],
              featureDataGroupName:
                featureDataGroupData['feature-data-group-name'],
              featureDataCount: featureDataCount,
              latestFeatureDataVersion:
                featureDataCount > 0
                  ? `${featureDataGroupData['feature-data-group-version-latest']['major']}.${featureDataGroupData['feature-data-group-version-latest']['minor']}.${featureDataGroupData['feature-data-group-version-latest']['patch']}`
                  : '',
              latestFeatureDataName: featureData
                ? featureData['feature-data-name']
                : '',
              updatedAt: featureDataGroupData['updated-at'],
              createdAt: featureDataGroupData['created-at'],
              createdBy: featureDataGroupData['created-by'],
            } as FeatureDataGroup
          })

        dispatch(
          featureDataTransferringActions.setFeatureDataGroupList(
            featureDataGroups.filter(
              (featureDataGroup) => featureDataGroup !== undefined
            ) as FeatureDataGroup[]
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataTransferringActions.setInProgress(false))
      }
    },

  /** 特徴量データ詳細で表示パラメータを取得する */
  getFeatureDataGroupDetail:
    (featureDataGroupId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataTransferringActions.setInProgress(true))
        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId

        /** feature-data-query document */
        const featureDataGroupQuery = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where('feature-data-group-id', '==', featureDataGroupId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((featureDataGroup) => featureDataGroup.data())[0]
          : (
              await getDoc(
                doc(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  featureDataGroupId
                )
              )
            ).data()

        if (!featureDataGroupQuery) {
          dispatch(
            featureDataTransferringActions.setFeatureDataGroupDataState(
              'NotFoundProcessed'
            )
          )
          return
        }
        dispatch(
          featureDataTransferringActions.setCurrentFeatureDataGroupDetail(
            getFeatureDataList(featureDataGroupQuery['feature-data-list'])
          )
        )
        dispatch(
          featureDataTransferringActions.setFeatureDataGroupDataState('Loaded')
        )
        dispatch(
          featureDataTransferringActions.clearFeatureDataDisplayCondition()
        )
      } catch (error) {
        console.error(error)
        dispatch(
          featureDataTransferringActions.setFeatureDataGroupDataState('Failed')
        )
      } finally {
        dispatch(featureDataTransferringActions.setInProgress(false))
      }
    },

  /** 次のステップへ遷移する */
  nextStep:
    (currentStep: FeatureDataTransferringStateKind) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataTransferringActions.setFeatureDataTransferringState(
          FeatureDataTransferringStateKindArray[
            FeatureDataTransferringStateKindArray.indexOf(currentStep) + 1
          ]
        )
      )

      switch (currentStep) {
        case 'FeatureDataState':
          // eslint-disable-next-line no-case-declarations
          const featureDataName =
            getState().pages.featureDataTransferringState.domainData
              .selectedFeatureData?.featureDataName
          // eslint-disable-next-line no-case-declarations
          const regex = /\/|\s+|:/g
          dispatch(
            featureDataTransferringActions.setMlPipelineMetaData({
              name: `${featureDataName}_${formatDateTimeSec(new Date()).replace(
                regex,
                ''
              )}`,
              remarks:
                getState().pages.featureDataTransferringState.domainData
                  .mlPipelinesMetaData?.remarks,
            })
          )

          break
        default:
          break
      }
    },

  /** 前のステップへ戻る */
  prevStep:
    (currentStep: FeatureDataTransferringStateKind) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 'FeatureDataGroupState':
          dispatch(
            featureDataTransferringActions.setSelectedFeatureDataGroup(
              undefined
            )
          )
          dispatch(
            featureDataTransferringActions.setFeatureDataGroupSubState(
              'Unselected'
            )
          )
          break

        case 'FeatureDataState':
          dispatch(
            featureDataTransferringActions.setSelectedFeatureData(undefined)
          )
          dispatch(
            featureDataTransferringActions.setFeatureDataSubState('Unselected')
          )
          break
        case 'MetaDataState':
          dispatch(
            featureDataTransferringActions.setMlPipelineMetaData(undefined)
          )
          dispatch(
            featureDataTransferringActions.setMetaDataSubState('EmptyRequired')
          )
          break
        case 'ExecuteState':
        default:
          break
      }

      dispatch(
        featureDataTransferringActions.setFeatureDataTransferringState(
          FeatureDataTransferringStateKindArray[
            FeatureDataTransferringStateKindArray.indexOf(currentStep) - 1
          ]
        )
      )
    },

  /** 特徴量データ転送メタデータを設定する */
  setMlPipelinesMetaData:
    (mlPipelineMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataTransferringActions.setMlPipelineMetaData(mlPipelineMetaData)
      )

      if (
        mlPipelineMetaData?.name &&
        getState().pages.featureDataTransferringState.domainData
          .mlPipelinesMetaData?.name
      ) {
        dispatch(
          featureDataTransferringActions.setMetaDataSubState('InputRequired')
        )
      } else {
        dispatch(
          featureDataTransferringActions.setMetaDataSubState('EmptyRequired')
        )
      }
    },

  /** 特徴量データグループを設定する */
  setSelectedFeatureDataGroup:
    (featureDataGroup?: FeatureDataGroup) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        featureDataTransferringActions.setSelectedFeatureDataGroup(
          featureDataGroup
        )
      )

      if (featureDataGroup) {
        dispatch(
          featureDataTransferringActions.setFeatureDataGroupSubState('Selected')
        )
      } else {
        dispatch(
          featureDataTransferringActions.setFeatureDataGroupSubState(
            'Unselected'
          )
        )
      }
    },

  /** 特徴量データを設定する */
  setSelectedFeatureData:
    (featureData?: FeatureData) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        featureDataTransferringActions.setSelectedFeatureData(featureData)
      )

      if (featureData) {
        dispatch(
          featureDataTransferringActions.setFeatureDataSubState('Selected')
        )
      } else {
        dispatch(
          featureDataTransferringActions.setFeatureDataSubState('Unselected')
        )
      }
    },

  /** 特徴量データ転送を開始する */
  executeFeatureDataTransferring:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataTransferringActions.setInProgress(true))
        const {
          selectedFeatureDataGroup,
          selectedFeatureData,
          mlPipelinesMetaData: featureDataTransferringMetaData,
        } = getState().pages.featureDataTransferringState.domainData
        const { userGroupId } =
          getState().app.domainData.authedUser.auth.customClaims
        if (
          !(
            selectedFeatureDataGroup?.featureDataGroupId &&
            selectedFeatureData?.featureDataId &&
            featureDataTransferringMetaData?.name
          )
        ) {
          console.error('Error invalid request')
          dispatch(
            featureDataTransferringActions.executeFeatureDataTransferringFailure()
          )
          return
        }

        const featureDataParams: FeatureDataTransferringParamsType = {
          featureData: {
            featureDataId: selectedFeatureData.featureDataId,
            userGroupId,
          },
          mlPipelineMetadata: featureDataTransferringMetaData,
        }

        const result =
          (await FeatureDataTransferringApi.executeFeatureDataTransferring(
            featureDataParams
          )) as HttpsCallableResult<{
            result: boolean
            mlPipelineId: string
            stepId: string
          }>

        dispatch(
          featureDataTransferringActions.executeFeatureDataTransferring(
            result.data.result
          )
        )
        dispatch(
          featureDataTransferringActions.setExecutedInfo({
            mlPipelineId: result.data.mlPipelineId,
            transferringStepId: result.data.stepId,
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          featureDataTransferringActions.executeFeatureDataTransferringFailure()
        )
      } finally {
        dispatch(featureDataTransferringActions.setInProgress(false))
      }
    },
}
