import type { AxiosResponse } from 'axios'
import { getAxiosInstance } from 'state/utils'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'

export const FeatureDataGroupEntryApi = {
  getDocumentId: (
    userGroupId: string,
    collectionName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'common-getdocumentid'
    )({
      userGroupId,
      collectionName,
    }),
  createFeatureDataGroup: (
    featureDataGroupId: string,
    algorithmId: string,
    name: string,
    remarks: string | undefined,
    overview: string | undefined,
    iconName: string | undefined,
    iconFileType: string | undefined,
    isSharedUserGroup: boolean
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredatagroup-firestore'
    )({
      featureDataGroupId,
      algorithmId,
      name,
      remarks,
      overview,
      iconName,
      iconFileType,
      isSharedUserGroup,
    }),
  getDeleteSignedUrl: (
    featureDataGroupId: string,
    iconFileName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredatagroup-delete-signed-url'
    )({
      featureDataGroupId,
      iconFileName,
    }),
  getFileUploadSignedUrl: (
    featureDataGroupId: string,
    iconFileName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredatagroup-get-signed-url'
    )({
      featureDataGroupId,
      iconFileName,
    }),
  deleteUploadedIcon: (signedUrl: string): Promise<AxiosResponse<void>> =>
    getAxiosInstance().delete(signedUrl),
  executeFileUpload: (
    signedUrl: string,
    file: File
  ): Promise<AxiosResponse<void>> => getAxiosInstance().upload(signedUrl, file),
}
