import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Cancel from '@mui/icons-material/Cancel'

import { CancelIconButtonProps } from './types'

const useStyles = makeStyles()((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}))

export const CancelIconButton: React.FC<CancelIconButtonProps> = (
  props: CancelIconButtonProps
) => {
  const { classes } = useStyles()

  return (
    <div>
      <Button
        variant='contained'
        className={classes.button}
        data-testid={props['data-testid']}
        startIcon={<Cancel />}
        disabled={props.disabled}
        onClick={props.onClick}
        color={
          props.color && props.color === 'blue'
            ? 'primary'
            : props.color && props.color === 'red'
            ? 'secondary'
            : 'inherit'
        }
      >
        {props.label}
      </Button>
    </div>
  )
}
