export function isNull(object: unknown): object is null {
  return object === null
}

export function isUndefined(object: unknown): object is undefined {
  return object === undefined
}

export function isNullOrUndefined(object: unknown): object is undefined | null {
  if (object === undefined) {
    return true
  } else if (object === '') {
    return true
  } else {
    return false
  }
}

export function isNumber(object: unknown): object is number {
  return typeof object === 'number'
}

export function isString(object: unknown): object is string {
  return typeof object === 'string'
}

export function isBoolean(object: unknown): object is boolean {
  return typeof object === 'boolean'
}

export function isObject(object: unknown): object is Record<string, unknown> {
  return typeof object === 'object'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArray(object: unknown): object is any[] {
  return Array.isArray(object)
}

export function isStrings(object: unknown): object is string[] {
  return (
    isArray(object) &&
    object.every((element) => {
      return isString(element)
    })
  )
}

export function isNumbers(object: unknown): object is number[] {
  return (
    isArray(object) &&
    object.every((element) => {
      return isNumber(element)
    })
  )
}

// uuid v4 の正規表現
const uuidRegex =
  /^([0-9a-f]{8})-([0-9a-f]{4})-(4[0-9a-f]{3})-([0-9a-f]{4})-([0-9a-f]{12})$/

/** uuid v4 チェック */
export function isUUIDv4(uuid: string): boolean {
  return uuidRegex.test(uuid)
}
