import {
  RobotDataAnalysisEntryState,
  RobotDataAnalysisEntryActionType,
} from './types'
import { RobotDataAnalysisEntryAction } from './actions'

const initialState: RobotDataAnalysisEntryState = {
  domainData: {
    modelGroupList: [],
    queryConditions: {
      executionStatus: {
        success: false,
        fail: true,
        error: true,
      },
      executionDate: undefined,
      executionIdList: undefined,
      robotIdList: undefined,
      modelGroupIdList: undefined,
      confirmed: true,
      objectRecognitionResults: true,
    },
    count: {
      countNumber: 0,
      isOver: false,
    },
  },
  appState: {
    inProgress: false,
    toastInfo: undefined,
    userInputList: [
      {
        name: 'executionDate',
        isValid: true,
      },
      {
        name: 'executionId',
        isValid: true,
      },
      {
        name: 'robotId',
        isValid: true,
      },
    ],
  },
}

export const RobotDataAnalysisEntryReducer = (
  state: RobotDataAnalysisEntryState = initialState,
  action: RobotDataAnalysisEntryAction
): RobotDataAnalysisEntryState => {
  switch (action.type) {
    case RobotDataAnalysisEntryActionType.SET_MODEL_GROUP_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelGroupList: action.payload.modelGroupList,
        },
      }
    case RobotDataAnalysisEntryActionType.SET_QUERY_CONDITIONS_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          queryConditions: action.payload.queryConditions,
        },
      }
    case RobotDataAnalysisEntryActionType.SET_COUNT_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          count: action.payload.count,
        },
      }
    case RobotDataAnalysisEntryActionType.SET_USER_INPUT_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          userInputList: action.payload.userInputList,
        },
      }
    case RobotDataAnalysisEntryActionType.SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case RobotDataAnalysisEntryActionType.SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case RobotDataAnalysisEntryActionType.CLEAR_ROBOT_DATA_ANALYSIS_ENTRY_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
