import { AlgorithmDocument } from 'utils/fireStore/algorithm'
import { validateData, DataObject } from '../common/validations'

/** algorithm documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as AlgorithmDocument
  const schema = {
    required: ['algorithm-id', 'algorithm-purpose', 'metadata'],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'algorithm-purpose': {
        type: 'string',
      },
      metadata: {
        required: ['overview', 'name'],
        type: 'object',
        properties: {
          overview: {
            required: ['en', 'ja'],
            type: 'object',
            properties: {
              en: {
                type: 'string',
              },
              ja: {
                type: 'string',
              },
            },
          },
          name: {
            required: ['en', 'ja'],
            type: 'object',
            properties: {
              en: {
                type: 'string',
              },
              ja: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  }

  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'algorithm',
      value: data as unknown as DataObject,
    })
    return false
  }
}
