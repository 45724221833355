import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import OutlinedInput from '@mui/material/OutlinedInput'
import Switch from '@mui/material/Switch'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { State } from 'state/store'
import {
  UserSettingAction,
  userSettingActions,
  UserSettingOperations,
} from 'state/ducks/userSetting'

import {
  CommonCompleteDialog,
  ErrorMessage,
  GlobalLoading,
  showToast,
  TabLayout,
  Toast,
  UserSettingIcon,
} from 'views/components'
import { List, ListItem, ListItemText } from '@mui/material'

const mapStateToProps = (state: State) => ({
  ...state.pages.userSettingState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, UserSettingAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** ユーザー情報取得 */
  getUserInfo: () => dispatch(UserSettingOperations.getUserInfo()),
  /** プロフィール更新処理 */
  updateProfile: (firstName: string, familyName: string) =>
    dispatch(UserSettingOperations.updateProfile(firstName, familyName)),
  /** 環境設定更新処理 */
  updateEnvironment: (language: string, locate: string) =>
    dispatch(UserSettingOperations.updateEnvironment(language, locate)),
  /** パスワード更新処理 */
  sendPasswordResetEmail: (nowPassword: string, newPassword: string) =>
    dispatch(
      UserSettingOperations.executePasswordUpdate(nowPassword, newPassword)
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(userSettingActions.setToastInfo(undefined)),
  /** Stateのクリア */
  clearUserSettingPageState: () =>
    dispatch(userSettingActions.clearInferenceDetailState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  tabContents: {
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
}))

// FIXME 管理方法の検討
const LANGUAGE_LIST = [
  { key: 'ja', value: '日本' },
  // { key: 'en', value: '英語' },
]
const LOCATE_LIST = [{ key: 'JP', value: '日本' }]

const UserSetting: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const [userFirstName, setUserFirstName] = useState<string>('')
  const [userFamilyName, setUserFamilyName] = useState<string>('')
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('')
  const [userLocate, setUserLocate] = useState<string>('')
  const [userLanguage, setUserLanguage] = useState<string>('')
  const [mfaChecked, setMfaChecked] = React.useState(false)
  /** 入力された現在のパスワード */
  const [nowPassword, setNowPassword] = useState<string>('')
  /** 入力されたパスワード */
  const [newPassword, setNewPassword] = useState<string>('')
  /** 再入力の入力されたパスワード */
  const [newPasswordSecondTime, setNewPasswordSecondTime] = useState<string>('')
  /** パスワードの表示状態 */
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(true)
  /** 再入力の入力されたパスワードのエラーメッセージ */
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] =
    useState<string>('')

  /** 初期実行 */
  useEffect(() => {
    props.getUserInfo()
    return () => {
      props.clearUserSettingPageState()
    }
  }, [])

  useEffect(() => {
    // 初期値セット
    setUserFirstName(props.domainData.userInfo.firstName)
    setUserFamilyName(props.domainData.userInfo.familyName)
    setUserPhoneNumber(props.domainData.userInfo.phoneNumber)
    setUserLocate(props.domainData.userInfo.locate)
    setUserLanguage(props.domainData.userInfo.language)
    setMfaChecked(
      props.domainData.userInfo.mfaGroupSetting === 'required'
        ? true
        : props.domainData.userInfo.isMfa
    )
  }, [props.domainData.userInfo])

  /** パスワード更新失敗時メッセージ */
  const PasswordUpdateErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (props.appState.passwordUpdateSubState === 'UpdateError') {
      errorMessages.push(props.appState.passwordUpdateErrorMessage)
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  /**
   * パスワードが一致しているかつ12文字以上で有効パスワードとする
   * エラーメッセージの設定も同時に行う
   */
  const isValidPassword = useMemo(() => {
    if (newPassword !== newPasswordSecondTime) {
      setNewPasswordErrorMessage('新しいパスワードが一致していません')
    } else if (newPasswordSecondTime.length <= 12 || newPassword.length <= 12) {
      setNewPasswordErrorMessage('12文字以上で入力してください')
    }
    return (
      newPassword.length === 0 ||
      newPasswordSecondTime.length === 0 ||
      (newPassword === newPasswordSecondTime &&
        newPasswordSecondTime.length >= 12 &&
        newPassword.length >= 12)
    )
  }, [newPassword, newPasswordSecondTime])

  /** トーストのコンポーネントを取得する */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** トースト情報があった場合、表示する */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  return (
    <>
      <div className={classes.container}>
        <Toast containerOptions={{ limit: 20 }}>
          <Box mb={2} display='flex'>
            <UserSettingIcon
              className={classes.pageIcon}
              data-testid='user-setting-icon'
            />
            <Typography>
              <h2 data-testid='user-setting-title'>ユーザー設定</h2>
            </Typography>
          </Box>
          <div className={classes.tabContents}>
            <Paper elevation={0}>
              <TabLayout
                contentsMaxHeight='500px'
                defaultTabIndex={0}
                items={[
                  // プロフィールコンテンツ
                  {
                    label: 'プロフィール',
                    displayInfo: (
                      <Box m={2}>
                        <Box height='400px'>
                          <Box display='flex' mb={2}>
                            <TextField
                              label='メールアドレス'
                              variant='outlined'
                              value={props.domainData.userInfo.mail}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              disabled
                            />
                          </Box>
                          <Box display='flex' mb={2}>
                            <Box display='flex' mr={2} width='50%'>
                              <TextField
                                label='名'
                                variant='outlined'
                                value={userFirstName}
                                fullWidth
                                onChange={(event) =>
                                  setUserFirstName(event.target.value)
                                }
                              />
                            </Box>
                            <Box display='flex' width='50%'>
                              <TextField
                                label='姓'
                                variant='outlined'
                                value={userFamilyName}
                                fullWidth
                                onChange={(event) =>
                                  setUserFamilyName(event.target.value)
                                }
                              />
                            </Box>
                          </Box>
                          <Box display='flex' mb={2}>
                            <TextField
                              label='所属'
                              variant='outlined'
                              value={props.domainData.userInfo.accountGroupName}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              disabled
                            />
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Button
                            variant='outlined'
                            style={{
                              backgroundColor: '#D9E5FF',
                              width: '100%',
                            }}
                            // TODO 更新処理
                            onClick={() =>
                              props.updateProfile(userFirstName, userFamilyName)
                            }
                            data-testid='user-profile-update'
                          >
                            <Typography color='primary'>更新</Typography>
                          </Button>
                        </Box>
                      </Box>
                    ),
                  },
                  // 環境設定コンテンツ
                  {
                    label: '環境設定',
                    displayInfo: (
                      <Box m={2}>
                        <Box height='400px'>
                          <Box display='flex' mb={2}>
                            <FormControl variant='outlined' fullWidth>
                              <InputLabel>言語</InputLabel>
                              <Select
                                value={userLanguage}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  setUserLanguage(e.target.value as string)
                                }}
                                label='言語'
                              >
                                {LANGUAGE_LIST.map((language) => (
                                  <MenuItem
                                    value={language.key}
                                    key={language.key}
                                  >
                                    {language.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box display='flex' mb={2}>
                            <FormControl variant='outlined' fullWidth>
                              <InputLabel>地域</InputLabel>
                              <Select
                                disabled
                                value={userLocate}
                                onChange={(e: SelectChangeEvent<string>) =>
                                  setUserLocate(e.target.value as string)
                                }
                                label='地域'
                              >
                                {LOCATE_LIST.map((locate) => (
                                  <MenuItem value={locate.key} key={locate.key}>
                                    {locate.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Button
                            variant='outlined'
                            style={{
                              backgroundColor: '#D9E5FF',
                              width: '100%',
                            }}
                            onClick={() =>
                              props.updateEnvironment(userLanguage, userLocate)
                            }
                            data-testid='user-profile-update'
                          >
                            <Typography color='primary'>更新</Typography>
                          </Button>
                        </Box>
                      </Box>
                    ),
                  },
                  // アカウントコンテンツ
                  {
                    label: 'アカウント',
                    displayInfo: (
                      <Box m={2}>
                        <Box height='400px'>
                          <Box display='flex' mb={2}>
                            <TextField
                              label='アカウントグループロール'
                              variant='outlined'
                              value={
                                props.domainData.userInfo.role === 'admin'
                                  ? '管理者'
                                  : '一般ユーザー'
                              }
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              disabled
                            />
                          </Box>
                          <Box display='flex' mb={2}>
                            <FormControlLabel
                              control={
                                <Switch
                                  color='default'
                                  disabled={
                                    props.domainData.userInfo
                                      .mfaGroupSetting === 'required' ||
                                    props.domainData.userInfo
                                      .mfaGroupSetting === 'none'
                                  }
                                  checked={mfaChecked}
                                  onChange={(event) =>
                                    setMfaChecked(event.target.checked)
                                  }
                                />
                              }
                              label='MFA設定'
                              labelPlacement='start'
                            />
                          </Box>
                          <Box display='flex' mb={2}>
                            <TextField
                              label='電話番号'
                              variant='outlined'
                              value={userPhoneNumber}
                              fullWidth
                              // TODO 現在は参照のみ
                              InputProps={{
                                readOnly: true,
                              }}
                              disabled
                              onChange={(event) =>
                                setUserPhoneNumber(event.target.value)
                              }
                            />
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Button
                            variant='outlined'
                            style={{
                              backgroundColor: '#D9E5FF',
                              width: '100%',
                            }}
                            onClick={() =>
                              // TODO 現在は参照のみ
                              console.log('')
                            }
                            data-testid='user-profile-update'
                          >
                            <Typography color='primary'>更新</Typography>
                          </Button>
                        </Box>
                      </Box>
                    ),
                  },
                  // パスワードコンテンツ
                  {
                    label: 'パスワード',
                    displayInfo: (
                      <Box m={2}>
                        <Box height='400px'>
                          <Box mb={2}>
                            <PasswordUpdateErrorMessage {...props} />
                          </Box>
                          <Box display='flex' mb={2}>
                            <FormControl variant='outlined' fullWidth>
                              <InputLabel htmlFor='outlined-adornment-password'>
                                現在のパスワード
                              </InputLabel>
                              <OutlinedInput
                                error={
                                  props.appState.passwordUpdateSubState ===
                                  'NowPasswordMissing'
                                }
                                label='現在のパスワード'
                                type={isPasswordVisible ? 'password' : 'text'}
                                value={nowPassword}
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => setNowPassword(e.target.value)}
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() =>
                                        setIsPasswordVisible(!isPasswordVisible)
                                      }
                                      edge='end'
                                    >
                                      {isPasswordVisible ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                data-testid={'now-password-input'}
                              />
                              {props.appState.passwordUpdateSubState ===
                                'NowPasswordMissing' && (
                                <FormHelperText error>
                                  現在のパスワードが間違っています
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                          <Box display='flex' mb={2}>
                            <FormControl variant='outlined' fullWidth>
                              <InputLabel htmlFor='outlined-adornment-password'>
                                新しいパスワード
                              </InputLabel>
                              <OutlinedInput
                                error={!isValidPassword}
                                label='新しいパスワード'
                                type={isPasswordVisible ? 'password' : 'text'}
                                value={newPassword}
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => setNewPassword(e.target.value)}
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() =>
                                        setIsPasswordVisible(!isPasswordVisible)
                                      }
                                      edge='end'
                                    >
                                      {isPasswordVisible ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                data-testid={'new-password-input'}
                              />
                              {!isValidPassword && (
                                <FormHelperText error>
                                  {newPasswordErrorMessage}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                          <Box display='flex'>
                            <FormControl variant='outlined' fullWidth>
                              <InputLabel htmlFor='outlined-adornment-password'>
                                新しいパスワード(再入力)
                              </InputLabel>
                              <OutlinedInput
                                error={!isValidPassword}
                                label='新しいパスワード(再入力)'
                                type={isPasswordVisible ? 'password' : 'text'}
                                value={newPasswordSecondTime}
                                onChange={(
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                ) => setNewPasswordSecondTime(e.target.value)}
                                endAdornment={
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={() =>
                                        setIsPasswordVisible(!isPasswordVisible)
                                      }
                                      edge='end'
                                    >
                                      {isPasswordVisible ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                data-testid={'new-password-second-time-input'}
                              />
                              {!isValidPassword && (
                                <FormHelperText error>
                                  {newPasswordErrorMessage}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Button
                            variant='outlined'
                            disabled={
                              !isValidPassword ||
                              newPassword.length === 0 ||
                              newPasswordSecondTime.length === 0
                            }
                            style={{
                              backgroundColor: '#D9E5FF',
                              width: '100%',
                            }}
                            onClick={() =>
                              props.sendPasswordResetEmail(
                                nowPassword,
                                newPassword
                              )
                            }
                            data-testid='user-profile-update'
                          >
                            <Typography color='primary'>更新</Typography>
                          </Button>
                        </Box>
                      </Box>
                    ),
                  },
                ]}
              />
            </Paper>
          </div>
        </Toast>
      </div>
      <CommonCompleteDialog
        open={props.appState.passwordUpdateSubState === 'UpdateSuccess'}
        value={'パスワードの更新に成功しました。'}
        handleClose={() => {
          props.clearUserSettingPageState()
        }}
        label={''}
        dialogText={''}
        data-testid={'password-reset-email'}
      />
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const UserSettingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserSetting))
