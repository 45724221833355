import React from 'react'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { styled } from '@mui/material/styles'

import { ToastOptionsType, ToastProps } from './types'

const StyledContainer = styled(ToastContainer)({
  '.Toastify__toast--error': {
    backgroundColor: '#e57373',
    '& > .Toastify__toast-body': {
      color: '#ffffff',
    },
    '& > .Toastify__progress-bar': {
      backgroundColor: '#d32f2f',
    },
  },
  '.Toastify__toast--warning': {
    backgroundColor: '#ff9800',
    '& > .Toastify__toast-body': {
      color: '#ffffff',
    },
    '& > .Toastify__progress-bar': {
      backgroundColor: '#ed6c02',
    },
  },
  '.Toastify__toast--info': {
    color: '#212121',
    backgroundColor: '#fff',
    '& > .Toastify__progress-bar': {
      backgroundColor: '#bdbdbd',
    },
  },
})

export const Toast: React.FC<ToastProps> = (props: ToastProps) => {
  return (
    <>
      {props.children}
      <StyledContainer {...props.containerOptions} />
    </>
  )
}

// Display Toast Function
export const showToast = (
  type: 'error' | 'warning' | 'info',
  content: React.ReactNode,
  options?: ToastOptionsType
): void => {
  switch (type) {
    case 'error':
      toast.error(content, options)
      break
    case 'warning':
      toast.warning(content, options)
      break
    case 'info':
    default:
      toast.info(content, options)
      break
  }
}
