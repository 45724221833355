import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { State } from 'state/store'
import {
  passwordResetOperations,
  PasswordResetState,
  PasswordResetAction,
  passwordResetActions,
  SendPasswordResetMailSubState,
} from 'state/ducks/passwordReset'

import {
  CommonCompleteDialog,
  EmphasizedMessage,
  ErrorMessage,
} from 'views/components'

type Dispatch = ThunkDispatch<PasswordResetState, void, PasswordResetAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendPasswordResetEmail: (email: string) =>
    dispatch(passwordResetOperations.sendPasswordResetEmail(email)),
  setPasswordResetState: (state: SendPasswordResetMailSubState) =>
    dispatch(passwordResetActions.setPasswordResetState(state)),
  resetState: () => dispatch(passwordResetActions.clearPasswordResetState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  ...state.app.domainData,
  ...state.app.appState,
  ...state.pages.passwordResetState,
})
type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & DispatchProps & RouteComponentProps

const PasswordResetForm: React.FC<Props> = (props: Props) => {
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false)
  const [destinationEmail, setDestinationEmail] = useState('')
  const history = useHistory()

  useEffect(() => {
    return () => {
      props.resetState()
    }
  }, [])

  useEffect(() => {
    if (props.appState.passwordResetMailSendSubState === 'SendingSuccess') {
      setOpenCompleteDialog(true)
    }
  }, [props.appState.passwordResetMailSendSubState])

  const inputDestinationEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDestinationEmail(e.target.value)
  }

  /** リセットメール送信失敗時メッセージ */
  const PasswordResetErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (props.appState.passwordResetMailSendSubState === 'SendingError') {
      errorMessages.push(props.appState.passwordResetMailSendErrorMessage)
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  return (
    <>
      <Box m={5} display='flex' alignItems='center' justifyContent='flex-end'>
        <Button onClick={() => history.push('./login')}>キャンセル</Button>
      </Box>
      <Box height='100vh' display='flex' justifyContent='center'>
        <Box>
          <Box mt={3} height='500px'>
            <Box display='flex' justifyContent='space-between' mb={3}>
              <Typography>パスワードリセット</Typography>
            </Box>
            <EmphasizedMessage
              message={
                'ユーザーのメールアドレス宛てにパスワードリセットメールを送信します\nメールを開いてURLをクリックし、パスワードを変更してください。'
              }
            />
            <Box mt={3}>
              <PasswordResetErrorMessage {...props} />
            </Box>
            <Box mt={5}>
              <FormHelperText>メールアドレス</FormHelperText>
            </Box>
            <Box>
              <TextField
                variant='outlined'
                required
                fullWidth
                placeholder='Email Address'
                value={destinationEmail}
                onChange={inputDestinationEmail}
              />
            </Box>
          </Box>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='outlined'
              style={{
                backgroundColor: '#D9E5FF',
              }}
              onClick={() => props.sendPasswordResetEmail(destinationEmail)}
            >
              <Typography color='primary'>送信</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <CommonCompleteDialog
        open={openCompleteDialog}
        value={'パスワードリセットのメールを送信しました。確認してください'}
        handleClose={() => {
          setOpenCompleteDialog(false)
          history.push('./login')
        }}
        label={''}
        dialogText={''}
        data-testid={'password-reset-email'}
      />
    </>
  )
}

export const PasswordReset = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetForm)
