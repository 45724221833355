import React from 'react'
import IconButton from '@mui/material/IconButton'
import GroupIcon from '@mui/icons-material/Groups'

import { AccountGroupsIconProps } from './types'

export const AccountGroupsIcon: React.FC<AccountGroupsIconProps> = (
  props: AccountGroupsIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <GroupIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
