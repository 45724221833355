import type { AxiosResponse } from 'axios'
import { getAxiosInstance } from 'state/utils'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { CreateModelGroupRequestType } from './types'

export const ModelGroupEntryApi = {
  getDocumentId: (
    userGroupId: string,
    collectionName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'common-getdocumentid'
    )({
      userGroupId,
      collectionName,
    }),
  createModelGroup: (
    modelGroup: CreateModelGroupRequestType
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-modelgroup-firestore'
    )(modelGroup),
  getDeleteSignedUrl: (
    trainedModelGroupId: string,
    iconFileName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-modelgroup-delete-signed-url'
    )({
      trainedModelGroupId,
      iconFileName,
    }),
  getFileUploadSignedUrl: (
    trainedModelGroupId: string,
    iconFileName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-modelgroup-get-signed-url'
    )({
      trainedModelGroupId,
      iconFileName,
    }),
  deleteUploadedIcon: (signedUrl: string): Promise<AxiosResponse<void>> =>
    getAxiosInstance().delete(signedUrl),
  executeFileUpload: (
    signedUrl: string,
    file: File
  ): Promise<AxiosResponse<void>> =>
    getAxiosInstance().upload(signedUrl, file, {
      headers: { 'Content-Type': file.type },
    }),
}
