import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { Customer } from './types'

const timeoutLimitSeconds = 15

export const AccountDetailApi = {
  accountDelete: (
    uid: string,
    accountId: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-delete',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      uid: uid,
      accountId: accountId,
    }),
  forcedAccountPasswordUpdate: (uid: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-password-forced',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      uid: uid,
    }),
  updateAccountName: (
    uid: string,
    firstName: string,
    familyName: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-update-name',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      uid: uid,
      firstName: firstName,
      familyName: familyName,
    }),
  updateAccountGroupRole: (
    uid: string,
    accountGroupRole: string,
    accountGroupId: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-update-accountgrouprole',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      uid: uid,
      accountGroupRole,
      accountGroupId,
    }),
  updateCustomClaim: (
    uid: string,
    updateParams: {
      [key: string]: unknown
    },
    accountId: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-update-customclaim',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      uid,
      updateParams,
      accountId,
    }),
  updateCustomers: (
    uid: string,
    customers: Customer[]
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-update-customers',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      uid,
      customers: customers.map((customer) => ({
        customerId: customer.customerId,
        role: customer.role,
      })),
    }),
}
