import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
const timeoutLimitSeconds = 15

export const BuildDetailApi = {
  getResultFiles: (mlPipelineId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'build-system-evaluation-results',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
    }),
}
