import { Menu, PageLayoutProps } from 'views/components'

import {
  Home,
  CustomTrainingPage,
  CustomTrainingListPage,
  CreateDatasetPage,
  DatasetDetailPage,
  DatasetListPage,
  ModelListPage,
  InferencePage,
  InferenceListPage,
  InferenceDetailPage,
  ModelPage,
  AccountEntryPage,
  AccountListPage,
  ModelGroupEntryPage,
  AccountGroupsPage,
  AdminModelListPage,
  CustomTrainingDetailPage,
  BuildDetailPage,
  ModelDetailPage,
  UserSettingPage,
  AccountDetailPage,
  ModelGroupListPage,
  BuildEntryPage,
  ModelGroupDetailPage,
  BuildListPage,
  FeatureDataGroupListPage,
  FeatureDataGroupDetailPage,
  FeatureDataListPage,
  FeatureDataDetailPage,
  AdminFeatureDataListPage,
  FeatureDataGroupEntryPage,
  FeatureDataPage,
  FeatureDataGeneratingPage,
  FeatureDataGeneratingListPage,
  FeatureDataGeneratingDetailPage,
  DatasetAugmentationListPage,
  DatasetAugmentationPage,
  ScenePresetListPage,
  DatasetAugmentationDetailPage,
  RobotDataAnalysisEntryPage,
  RobotDataAnalysisExtractedImagesPage,
  RobotDataAnalysisExtractedImageDetailPage,
  FeatureDataTransferringListPage,
  FeatureDataTransferringPage,
  DatasetDetailAnnotationPage,
  SettingDetailPage,
  SceneCameraListPage,
  SceneCameraDetailPage,
  SceneCameraEntryPage,
} from 'views/containers/pages'

export const routeProps: PageLayoutProps = {
  authed: true,
  mailAddress: '',
  firstName: '',
  familyName: '',
  customerId: '',
  customerName: '',
  customerRole: 'user',
  accountGroupRole: 'user',
  userGroupId: '',
  userId: '',
  superUser: false,
  menu: {
    general: {
      label: undefined,
      categories: [
        {
          item: {
            sideMenu: true,
            menuName: 'ホーム',
            menuIcon: 'Home',
            exact: true,
            path: '/',
            component: Home,
            nestedPaths: [],
            'data-testid': 'home-link',
          },
        },
        {
          item: {
            sideMenu: false,
            menuName: '',
            exact: true,
            path: '/user-settings/profile',
            component: UserSettingPage,
            nestedPaths: [],
            'data-testid': 'user-setting-link',
          },
        },
        {
          section: {
            label: 'モデル・AIライブラリ',
            menuIcon: 'Psychology',
            itemList: [
              {
                sideMenu: true,
                menuName: 'カスタム学習',
                menuIcon: 'ModelTrainingOutlined',
                exact: false,
                path: '/custom-trainings',
                component: undefined,
                'data-testid': 'custom-training-list',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: CustomTrainingListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: CustomTrainingPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:id',
                    component: CustomTrainingDetailPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: '推論',
                menuIcon: 'BatchPrediction',
                exact: false,
                path: '/inferences',
                component: undefined,
                'data-testid': 'inference-list',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: InferenceListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: InferencePage,
                  },
                  {
                    exact: true,
                    path: '/:p/:id',
                    component: InferenceDetailPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: 'ビルド',
                menuIcon: 'BuildCircle',
                exact: false,
                path: '/builds',
                component: undefined,
                'data-testid': 'builds-link',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: BuildListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: BuildEntryPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:id',
                    component: BuildDetailPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: 'モデルグループ',
                menuIcon: 'WorkspacesOutlined',
                exact: false,
                path: '/model-groups',
                component: undefined,
                'data-testid': 'model-groups-link',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: ModelGroupListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: ModelGroupEntryPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:modelGroupId',
                    component: ModelGroupDetailPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:modelGroupId/models',
                    component: ModelListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:modelGroupId/models/entry',
                    component: ModelPage,
                    customerFunction: 'UPLOAD_MODEL',
                  },
                  {
                    exact: true,
                    path: '/:p/:modelGroupId/models/:modelId',
                    component: ModelDetailPage,
                  },
                ],
              },
            ],
          },
        },
        {
          section: {
            label: 'データセット',
            menuIcon: 'TableChartOutlined',
            itemList: [
              {
                sideMenu: true,
                menuName: 'データセット',
                menuIcon: 'TableChartOutlined',
                exact: false,
                path: '/datasets',
                component: undefined,
                'data-testid': 'datasets-link',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: DatasetListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: CreateDatasetPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:id',
                    component: DatasetDetailPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:id/annotation-sets/:annotationSetId',
                    component: DatasetDetailAnnotationPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: 'オーグメンテーション',
                menuIcon: 'ViewInAr',
                exact: false,
                path: '/dataset-augmentations',
                component: undefined,
                'data-testid': 'dataset-augmentations-link',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: DatasetAugmentationListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: DatasetAugmentationPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:id',
                    component: DatasetAugmentationDetailPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: 'シーンプリセット',
                menuIcon: 'CameraOutdoorOutlined',
                exact: false,
                path: '/scene-presets',
                component: undefined,
                'data-testid': 'scene-presets-link',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: ScenePresetListPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: 'カメラ配置',
                menuIcon: 'VideocamOutlinedIcon',
                exact: false,
                path: '/3d-scene-cameras',
                component: undefined,
                'data-testid': '3d-scene-cameras',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: SceneCameraListPage,
                  },
                  {
                    exact: true,
                    component: SceneCameraEntryPage,
                    path: '/:p/entry',
                  },
                  {
                    exact: true,
                    path: '/:p/:id',
                    component: SceneCameraDetailPage,
                  },
                ],
              },
            ],
          },
        },
        {
          section: {
            label: '特徴量データ',
            menuIcon: 'AutoGraph',
            itemList: [
              {
                sideMenu: true,
                menuName: '特徴量データグループ',
                menuIcon: 'AutoGraph',
                exact: false,
                path: '/feature-data-groups',
                'data-testid': 'feature-data-groups-link',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: FeatureDataGroupListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: FeatureDataGroupEntryPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:featureDataGroupId',
                    component: FeatureDataGroupDetailPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:featureDataGroupId/feature-data',
                    component: FeatureDataListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:featureDataGroupId/feature-data/entry',
                    component: FeatureDataPage,
                    customerFunction: 'UPLOAD_MODEL',
                  },
                  {
                    exact: true,
                    path: '/:p/:featureDataGroupId/feature-data/:featureDataId',
                    component: FeatureDataDetailPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: '特徴量データ生成',
                menuIcon: 'AutoMode',
                exact: false,
                path: '/feature-data-generatings',
                'data-testid': 'feature-data-generating',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: FeatureDataGeneratingListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: FeatureDataGeneratingPage,
                  },
                  {
                    exact: true,
                    path: '/:p/:id',
                    component: FeatureDataGeneratingDetailPage,
                  },
                ],
              },
              {
                sideMenu: true,
                menuName: '特徴量データ転送',
                menuIcon: 'AutoMode',
                exact: false,
                path: '/feature-data-transferrings',
                'data-testid': 'feature-data-transferring',
                nestedPaths: [
                  {
                    exact: true,
                    path: '/:p',
                    component: FeatureDataTransferringListPage,
                  },
                  {
                    exact: true,
                    path: '/:p/entry',
                    component: FeatureDataTransferringPage,
                  },
                ],
              },
            ],
          },
        },
        {
          section: {
            label: '分析',
            menuIcon: 'ImageSearch',
            customerFunction: 'USE_SCREEN_FOR_PATCH_APPLY',
            itemList: [
              {
                sideMenu: true,
                menuName: 'ロボットデータ分析',
                menuIcon: 'ImageSearch',
                exact: true,
                path: '/robot-data-analysis/entry',
                'data-testid': 'robot-data-analysis-entry',
                component: RobotDataAnalysisEntryPage,
                customerFunction: 'USE_SCREEN_FOR_PATCH_APPLY',
                nestedPaths: [],
              },
              {
                sideMenu: false,
                menuName: 'ロボットデータ分析',
                menuIcon: 'ImageSearch',
                exact: true,
                path: '/robot-data-analysis/extracted-images',
                'data-testid': 'robot-data-analysis-extracted-images',
                component: RobotDataAnalysisExtractedImagesPage,
                customerFunction: 'USE_SCREEN_FOR_PATCH_APPLY',
                nestedPaths: [],
              },
              {
                sideMenu: false,
                menuName: 'ロボットデータ分析',
                menuIcon: 'ImageSearch',
                exact: true,
                path: '/robot-data-analysis/extracted-images/:id',
                'data-testid': 'robot-data-analysis-extracted-images-detail',
                component: RobotDataAnalysisExtractedImageDetailPage,
                customerFunction: 'USE_SCREEN_FOR_PATCH_APPLY',
                nestedPaths: [],
              },
            ],
          },
        },
        {
          item: {
            sideMenu: false,
            menuName: '',
            exact: true,
            path: '/setting-groups/:settingGroupId/settings/:settingId',
            component: SettingDetailPage,
            nestedPaths: [],
            'data-testid': 'setting-detail',
          },
        },
      ],
    },
    admin: {
      label: '管理者用',
      categories: [
        {
          item: {
            sideMenu: true,
            menuName: 'モデル',
            menuIcon: 'Psychology',
            exact: false,
            path: '/models',
            'data-testid': 'model-list',
            nestedPaths: [
              {
                exact: true,
                path: '/:p',
                component: AdminModelListPage,
              },
              {
                exact: true,
                path: '/:p/entry',
                component: ModelPage,
              },
              {
                exact: true,
                path: '/:p/:modelId',
                component: ModelDetailPage,
              },
            ],
          },
        },
        {
          item: {
            sideMenu: true,
            menuName: '特徴量データ',
            menuIcon: 'AutoGraph',
            exact: false,
            path: '/feature-data',
            'data-testid': 'feature-data-list',
            nestedPaths: [
              {
                exact: true,
                path: '/:p',
                component: AdminFeatureDataListPage,
              },
              {
                exact: true,
                path: '/:p/entry',
                component: FeatureDataPage,
              },
              {
                exact: true,
                path: '/:p/:featureDataId',
                component: FeatureDataDetailPage,
              },
            ],
          },
        },
        {
          item: {
            sideMenu: true,
            menuName: 'ユーザー管理',
            menuIcon: 'ManageAccounts',
            exact: false,
            path: '/admin/accounts',
            component: undefined,
            'data-testid': 'account-list',
            nestedPaths: [
              {
                exact: true,
                path: '/:p/accounts',
                component: AccountListPage,
              },
              {
                exact: true,
                path: '/:p/accounts/entry',
                component: AccountEntryPage,
              },
              {
                exact: true,
                path: '/:p/accounts/:id',
                component: AccountDetailPage,
              },
            ],
          },
        },
      ],
    },
    superUser: {
      label: 'スーパーユーザー用',
      categories: [
        {
          item: {
            sideMenu: true,
            menuName: 'アカウントグループ管理',
            menuIcon: 'Groups',
            exact: false,
            path: '/admin/account-groups',
            component: undefined,
            'data-testid': 'account-groups',
            nestedPaths: [
              {
                exact: true,
                path: '/:p/account-groups',
                component: AccountGroupsPage,
              },
            ],
          },
        },
      ],
    },
  },
}

/**
 * パスの先頭にカスタマーIDを付与したmenuを取得する
 */
export const getMenu = (customerId: string): Menu => ({
  ...routeProps.menu,
  general: {
    ...routeProps.menu.general,
    categories: routeProps.menu.general.categories.map((category) => ({
      ...category,
      item:
        category.item != null
          ? {
              ...category.item,
              path: `/${customerId}${category.item.path}`,
              nestedPaths: category.item?.nestedPaths.map((nestedPath) => ({
                ...nestedPath,
                path: `/${customerId}${nestedPath.path}`,
              })),
            }
          : undefined,
      section:
        category.section != null
          ? {
              ...category.section,
              itemList: category.section.itemList.map((item) => ({
                ...item,
                path: `/${customerId}${item.path}`,
                nestedPaths: item.nestedPaths.map((nestedPath) => ({
                  ...nestedPath,
                  path: `/${customerId}${nestedPath.path}`,
                })),
              })),
            }
          : undefined,
    })),
  },
  admin: {
    ...routeProps.menu.admin,
    categories: routeProps.menu.admin.categories.map((category) => ({
      ...category,
      item:
        category.item != null
          ? {
              ...category.item,
              path: `/${customerId}${category.item.path}`,
              nestedPaths: category.item?.nestedPaths.map((nestedPath) => ({
                ...nestedPath,
                path: `/${customerId}${nestedPath.path}`,
              })),
            }
          : undefined,
      section:
        category.section != null
          ? {
              ...category.section,
              itemList: category.section.itemList.map((item) => ({
                ...item,
                path: `/${customerId}${item.path}`,
                nestedPaths: item.nestedPaths.map((nestedPath) => ({
                  ...nestedPath,
                  path: `/${customerId}${nestedPath.path}`,
                })),
              })),
            }
          : undefined,
    })),
  },
  superUser: {
    ...routeProps.menu.superUser,
    categories: routeProps.menu.superUser.categories.map((category) => ({
      ...category,
      item:
        category.item != null
          ? {
              ...category.item,
              path: `/${customerId}${category.item.path}`,
              nestedPaths: category.item?.nestedPaths.map((nestedPath) => ({
                ...nestedPath,
                path: `/${customerId}${nestedPath.path}`,
              })),
            }
          : undefined,
      section:
        category.section != null
          ? {
              ...category.section,
              itemList: category.section.itemList.map((item) => ({
                ...item,
                path: `/${customerId}${item.path}`,
                nestedPaths: item.nestedPaths.map((nestedPath) => ({
                  ...nestedPath,
                  path: `/${customerId}${nestedPath.path}`,
                })),
              })),
            }
          : undefined,
    })),
  },
})
