import { Dispatch } from 'redux'
import { State } from 'state/store'
import { buildEntryActions } from './'
import {
  getTrainedModelGroupQueriesCollection,
  getBaseInferenceContainerImageCollection,
  getTrainedModelQueriesCollection,
} from 'state/firebase'

import {
  BuildEntryParamsType,
  BuildEntryStateKindArray,
  MetaData,
  TrainedModelGroupInfo,
  TrainedModelInfo,
  InferenceAlgorithmVersion,
  TrainedModelDataItem,
  BaseInferenceContainerImageDocument,
} from './types'
import { isUndefined } from 'utils/typeguard'
import { formatDateTimeSec } from 'views/components/utils/date'
import { BuildEntryApi } from './apis'
import { InferenceAlgorithmVersion as InferenceAlgorithmVersionForDomain } from 'state/app/domainData'
import { compareVersionsWithPreRelease } from 'utils/versions'
import { createInferenceVersionsData } from 'state/utils/algorithms'
import { getDocs, query, where } from 'firebase/firestore'

import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForBaseInferenceContainerImageDocument } from 'utils/fireStore/baseInferenceContainerImage'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { domainDataOperations } from 'state/app/domainData/operations'

export const buildEntryOperations = {
  getTrainedModelGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // モデルグループの一覧を取得
      try {
        dispatch(buildEntryActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            buildEntryActions.setTrainedModelGroupListDisplayCondition({
              ...getState().pages.buildEntryState.appState
                .trainedModelGroupListDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const selectedAlgorithmId =
          getState().pages.buildEntryState.domainData.selectedTrainingAlgorithm
            ?.algorithmId
        // 学習アルゴリズムが未選択の場合は、早期return
        if (!selectedAlgorithmId) {
          return
        }

        // モデルグループ一覧取得
        const userGroupTrainedModelGroup = await getDocs(
          query(
            getTrainedModelGroupQueriesCollection(userGroupId),
            where('algorithm-id', '==', selectedAlgorithmId)
          )
        )

        // 共有モデルグループ一覧取得
        const sharedUserGroupTrainedModelGroup = hasSharedUserGroup
          ? await getDocs(
              query(
                getTrainedModelGroupQueriesCollection(sharedUserGroupId),
                where('algorithm-id', '==', selectedAlgorithmId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            )
          : { docs: [] }

        if (!userGroupTrainedModelGroup || !sharedUserGroupTrainedModelGroup) {
          return
        }

        // モデルグループのオブジェクトを作成
        const userGroupTrainedModelGroups: (
          | TrainedModelGroupInfo
          | undefined
        )[] = userGroupTrainedModelGroup.docs.map((item) => {
          const trainedModelGroupData = item.data()
          if (
            !fireStoreTypeGuardForModelGroupQueryDocument(trainedModelGroupData)
          ) {
            return undefined
          }
          const trainedModel = trainedModelGroupData['trained-model-list'].find(
            (data: TrainedModelDataItem) =>
              data['trained-model-group-version']['display-name'] ===
              `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
          )
          const trainedModelCount = trainedModelGroupData['trained-model-count']
          return {
            trainedModelGroupId:
              trainedModelGroupData['trained-model-group-id'],
            trainedModelGroupName:
              trainedModelGroupData['trained-model-group-name'],
            trainedModelCount: trainedModelCount,
            latestTrainedModelVersion:
              trainedModelCount > 0
                ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                : '',
            latestTrainedModelName: trainedModel
              ? trainedModel['trained-model-name']
              : '',
            updatedAt: trainedModelGroupData['updated-at'],
            createdAt: trainedModelGroupData['created-at'],
            createdBy: trainedModelGroupData['created-by'],
            trainedModels: trainedModelGroupData['trained-model-list'].map(
              (content: TrainedModelDataItem) => {
                return {
                  trainedModelId: content['trained-model-id'],
                  trainedModelName: content['trained-model-name'],
                  trainedModelGroupVersion: {
                    displayName:
                      content['trained-model-group-version']['display-name'],
                    major: content['trained-model-group-version']['major'],
                    minor: content['trained-model-group-version']['minor'],
                    patch: content['trained-model-group-version']['patch'],
                  },
                  transactionStatus: content['transaction-status'],
                }
              }
            ),
            userGroupId: trainedModelGroupData['user-group-id'],
          } as TrainedModelGroupInfo
        })

        // 共有モデルグループのオブジェクトを作成
        const sharedUserGroupTrainedModelGroups: (
          | TrainedModelGroupInfo
          | undefined
        )[] = sharedUserGroupTrainedModelGroup.docs.map((item) => {
          const trainedModelGroupData = item.data()
          if (
            !fireStoreTypeGuardForModelGroupQueryDocument(trainedModelGroupData)
          ) {
            return undefined
          }
          const trainedModel = trainedModelGroupData['trained-model-list'].find(
            (data: TrainedModelDataItem) =>
              data['trained-model-group-version']['display-name'] ===
              `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
          )
          const trainedModelCount = trainedModelGroupData['trained-model-count']
          return {
            trainedModelGroupId:
              trainedModelGroupData['trained-model-group-id'],
            trainedModelGroupName:
              trainedModelGroupData['trained-model-group-name'],
            trainedModelCount: trainedModelCount,
            latestTrainedModelVersion:
              trainedModelCount > 0
                ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                : '',
            latestTrainedModelName: trainedModel
              ? trainedModel['trained-model-name']
              : '',
            updatedAt: trainedModelGroupData['updated-at'],
            createdAt: trainedModelGroupData['created-at'],
            createdBy: trainedModelGroupData['created-by'],
            trainedModels: trainedModelGroupData['trained-model-list'].map(
              (content: TrainedModelDataItem) => {
                return {
                  trainedModelId: content['trained-model-id'],
                  trainedModelName: content['trained-model-name'],
                  trainedModelGroupVersion: {
                    displayName:
                      content['trained-model-group-version']['display-name'],
                    major: content['trained-model-group-version']['major'],
                    minor: content['trained-model-group-version']['minor'],
                    patch: content['trained-model-group-version']['patch'],
                  },
                  transactionStatus: content['transaction-status'],
                }
              }
            ),
            userGroupId: trainedModelGroupData['user-group-id'],
          } as TrainedModelGroupInfo
        })

        dispatch(
          buildEntryActions.setTrainedModelGroupList({
            userGroup: userGroupTrainedModelGroups.filter(
              (trainedModelGroup) => trainedModelGroup !== undefined
            ) as TrainedModelGroupInfo[],
            sharedUserGroup: sharedUserGroupTrainedModelGroups.filter(
              (trainedModelGroup) => trainedModelGroup !== undefined
            ) as TrainedModelGroupInfo[],
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(buildEntryActions.setInProgress(false))
      }
    },
  getOutputFormatData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(buildEntryActions.setInProgress(true))
        const selectedAlgorithmId =
          getState().pages.buildEntryState.domainData.selectedTrainingAlgorithm
            ?.algorithmId
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const selectedModelId =
          getState().pages.buildEntryState.domainData.selectedTrainedModel
            ?.trainedModelId

        if (isUndefined(selectedAlgorithmId)) {
          console.error('unselected training algorithm')
          return
        }

        /** 選択したモデルのデータ */
        const selectedTrainedModelDocs = await getDocs(
          getState().pages.buildEntryState.domainData.selectedTrainedModelGroup
            ?.userGroupId ===
            getState().app.domainData.authedUser.auth.customClaims.sharedList[0]
            ? query(
                getTrainedModelQueriesCollection(sharedUserGroupId),
                where('trained-model-id', '==', selectedModelId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
            : query(
                getTrainedModelQueriesCollection(userGroupId),
                where('trained-model-id', '==', selectedModelId)
              )
        )
        const selectedTrainedModel = selectedTrainedModelDocs.docs[0]?.data()

        if (isUndefined(selectedTrainedModel)) {
          console.error('unselected trained model')
          return
        }
        if (!fireStoreTypeGuardForModelQueryDocument(selectedTrainedModel)) {
          return
        }
        const algorithmList = getState().app.domainData.algorithms

        const algorithm = algorithmList.find(
          (algorithm) => algorithm.algorithmId === selectedAlgorithmId
        )

        if (isUndefined(algorithm)) {
          console.error('notfound selected training algorithm')
          return
        }

        const matchVersions: InferenceAlgorithmVersionForDomain[] = []
        algorithm.inferenceAlgorithm.inferenceAlgorithmVersions.find(
          (version) => {
            const trainedModelAlgorithmVersion =
              selectedTrainedModel['training-algorithm-version']

            const lowerLimitVersion =
              version.availableVersion['training-algorithm'].lowerLimit
            const lowerLimitCheck = compareVersionsWithPreRelease(
              lowerLimitVersion,
              trainedModelAlgorithmVersion
            )

            const upperLimitVersion =
              version.availableVersion['training-algorithm'].upperLimit
            const upperLimitCheck = compareVersionsWithPreRelease(
              upperLimitVersion,
              trainedModelAlgorithmVersion
            )

            if (
              (lowerLimitCheck === 1 || lowerLimitCheck === 0) &&
              (upperLimitCheck === -1 || upperLimitCheck === 0)
            ) {
              matchVersions.push(version)
            }
          }
        )

        const inferenceVersions: InferenceAlgorithmVersion[] = []
        const confirmedVersion: {
          inferenceAlgorithmVersion: string
          containerInterfaceVersion: string
          inferenceCodeVersion: string
        }[] = []

        await Promise.all(
          matchVersions.map(async (version) => {
            const allBaseInferenceContainerImage = await getDocs(
              query(
                getBaseInferenceContainerImageCollection(),
                where('algorithm-id', '==', selectedAlgorithmId),
                where(
                  'inference-algorithm-version.display-name',
                  '==',
                  version.inferenceAlgorithmVersion
                ),
                where('usage-type', '==', 'edge')
              )
            )

            const allBaseInferenceContainerImageConvert =
              allBaseInferenceContainerImage.docs.map((doc) =>
                doc.data()
              ) as BaseInferenceContainerImageDocument[]

            await Promise.all(
              allBaseInferenceContainerImageConvert.map(
                async (baseInferenceContainerImage) => {
                  if (
                    !fireStoreTypeGuardForBaseInferenceContainerImageDocument(
                      baseInferenceContainerImage
                    )
                  ) {
                    return
                  }

                  const inferenceCodeVersion =
                    baseInferenceContainerImage['inference-code-version']?.[
                      'display-name'
                    ] ?? ''

                  // 確認済みバージョンのインデックス
                  const checkedVersionIndex = confirmedVersion.findIndex(
                    (data) =>
                      data.inferenceAlgorithmVersion ===
                        baseInferenceContainerImage[
                          'inference-algorithm-version'
                        ]['display-name'] &&
                      data.containerInterfaceVersion ===
                        baseInferenceContainerImage[
                          'container-interface-version'
                        ]['display-name'] &&
                      data.inferenceCodeVersion === inferenceCodeVersion
                  )

                  // すでに確認済みのバージョンはスキップする
                  if (checkedVersionIndex !== -1) {
                    return
                  }

                  confirmedVersion.push({
                    inferenceAlgorithmVersion:
                      baseInferenceContainerImage[
                        'inference-algorithm-version'
                      ]['display-name'],
                    containerInterfaceVersion:
                      baseInferenceContainerImage[
                        'container-interface-version'
                      ]['display-name'],
                    inferenceCodeVersion: inferenceCodeVersion,
                  })

                  // 現在のバージョンの配列
                  const nowVersions: BaseInferenceContainerImageDocument[] = []
                  allBaseInferenceContainerImageConvert.map(
                    (data: BaseInferenceContainerImageDocument) => {
                      // inference-algorithm-version, container-interface-version, inference-code-version が一致する
                      // base-inference-container-imageを集約する
                      const inferenceCodeVersionForComparison =
                        data['inference-code-version']?.['display-name'] ?? ''
                      if (
                        data['inference-algorithm-version']['display-name'] ===
                          baseInferenceContainerImage[
                            'inference-algorithm-version'
                          ]['display-name'] &&
                        data['container-interface-version']['display-name'] ===
                          baseInferenceContainerImage[
                            'container-interface-version'
                          ]['display-name'] &&
                        inferenceCodeVersionForComparison ===
                          inferenceCodeVersion
                      ) {
                        nowVersions.push(data)
                      }
                    }
                  )

                  const containerImages = nowVersions.map((item) => {
                    return {
                      baseInferenceContainerImageId:
                        item['base-inference-container-image-id'],
                      containerImageTags: item['container-image-tags'],
                      containerImagePlatform: item['container-image-platform'],
                    }
                  })

                  // 推論アルゴリズムのオブジェクトを作成
                  const data: InferenceAlgorithmVersion =
                    createInferenceVersionsData(
                      version,
                      baseInferenceContainerImage,
                      containerImages
                    )

                  inferenceVersions.push(data)
                }
              )
            )
          })
        )
        dispatch(
          buildEntryActions.setInferenceAlgorithmVersions(inferenceVersions)
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(buildEntryActions.setInProgress(false))
      }
    },
  setSelectedTrainedModelGroup:
    (modelGroup?: TrainedModelGroupInfo) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(buildEntryActions.setSelectedTrainedModelGroup(modelGroup))

      if (modelGroup) {
        dispatch(buildEntryActions.setTrainedModelGroupSubState('Selected'))
      } else {
        dispatch(buildEntryActions.setTrainedModelGroupSubState('Unselected'))
      }
    },
  setSelectedTrainedModel:
    (trainedModel?: TrainedModelInfo) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(buildEntryActions.setSelectedTrainedModel(trainedModel))

      if (trainedModel) {
        dispatch(buildEntryActions.setTrainedModelSubState('Selected'))
      } else {
        dispatch(buildEntryActions.setTrainedModelSubState('Unselected'))
      }
    },
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        buildEntryActions.setBuildEntryState(
          BuildEntryStateKindArray[currentStep + 1]
        )
      )
      // メタデータの入力ステップ時にカスタム学習、カスタムモデルの表示名のデフォルト値を設定
      if (BuildEntryStateKindArray[currentStep + 1] === 'MetaDataState') {
        const modelGroupName =
          getState().pages.buildEntryState.domainData.selectedTrainedModelGroup
            ?.trainedModelGroupName
        const version =
          getState().pages.buildEntryState.domainData
            .selectedInferenceAlgorithmVersion?.algorithmVersion?.displayName
        const regex = /\/|\s+|:/g
        dispatch(
          buildEntryActions.setBuildMetaData({
            name: `${modelGroupName} v${version}_${formatDateTimeSec(
              new Date()
            ).replace(regex, '')}`,
            remarks:
              getState().pages.buildEntryState.domainData.buildInfoMetadata
                ?.remarks,
          })
        )
        dispatch(
          buildEntryActions.setEdgeImageMetaData({
            name: `${modelGroupName} v${version}`,
            remarks:
              getState().pages.buildEntryState.domainData.edgeImageInfoMetadata
                ?.remarks,
          })
        )

        if (modelGroupName && version) {
          dispatch(buildEntryActions.setMetaDataSubState('InputRequired'))
        }
      }
    },
  setSelectedInferenceAlgorithmVersion:
    (selectedInferenceAlgorithmVersion?: InferenceAlgorithmVersion) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        buildEntryActions.setSelectedInferenceAlgorithmVersion(
          selectedInferenceAlgorithmVersion
        )
      )

      const selectedContainerArchitecture =
        getState().pages.buildEntryState.domainData
          .selectedBaseInferenceContainerImageIds

      if (
        selectedInferenceAlgorithmVersion &&
        selectedContainerArchitecture.length > 0
      ) {
        dispatch(buildEntryActions.setEdgeImageSubStateSubState('Selected'))
      } else {
        dispatch(buildEntryActions.setEdgeImageSubStateSubState('Unselected'))
      }
    },
  setSelectedContainerArchitecture:
    (baseInferenceContainerImageId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const selectedIds =
        getState().pages.buildEntryState.domainData
          .selectedBaseInferenceContainerImageIds
      const selectedInferenceAlgorithm =
        getState().pages.buildEntryState.domainData
          .selectedInferenceAlgorithmVersion
      const index = selectedIds.findIndex(
        (id) => id === baseInferenceContainerImageId
      )
      if (index === -1) {
        selectedIds.push(baseInferenceContainerImageId)
      } else {
        selectedIds.splice(index, 1)
      }

      if (selectedInferenceAlgorithm && selectedIds.length > 0) {
        dispatch(buildEntryActions.setEdgeImageSubStateSubState('Selected'))
      } else {
        dispatch(buildEntryActions.setEdgeImageSubStateSubState('Unselected'))
      }
      dispatch(buildEntryActions.setIsSelected(selectedIds))
    },
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 0:
          return
        case 1:
          dispatch(buildEntryActions.setSelectedTrainedModel(undefined))
          dispatch(buildEntryActions.setTrainedModelSubState('Unselected'))
          break
        case 2:
          dispatch(
            buildEntryActions.setSelectedInferenceAlgorithmVersion(undefined)
          )
          dispatch(buildEntryActions.setInferenceAlgorithmVersions([]))
          dispatch(buildEntryActions.setIsSelected([]))
          dispatch(buildEntryActions.setIsTransfer(true))
          dispatch(buildEntryActions.setIsSystemEvaluation(true))
          dispatch(buildEntryActions.setEdgeImageSubStateSubState('Unselected'))
          break
        case 3:
          dispatch(
            buildEntryActions.setBuildMetaData({
              name: '',
              remarks: undefined,
            })
          )
          dispatch(
            buildEntryActions.setEdgeImageMetaData({
              name: '',
              remarks: undefined,
            })
          )
          dispatch(buildEntryActions.setMetaDataSubState('EmptyRequired'))
          break
        case 4:
          break
        default:
          return
      }

      dispatch(
        buildEntryActions.setBuildEntryState(
          BuildEntryStateKindArray[currentStep - 1]
        )
      )
    },
  setSelectedAlgorithmId:
    (algorithmKind: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(buildEntryActions.setSelectedAlgorithmId(algorithmKind))
    },
  setBuildMetaData:
    (buildMetaData: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(buildEntryActions.setBuildMetaData(buildMetaData))

      if (
        buildMetaData &&
        getState().pages.buildEntryState.domainData.edgeImageInfoMetadata?.name
      ) {
        dispatch(buildEntryActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(buildEntryActions.setMetaDataSubState('EmptyRequired'))
      }
    },
  setEdgeImageMetaData:
    (edgeImageMetaData: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(buildEntryActions.setEdgeImageMetaData(edgeImageMetaData))

      if (
        edgeImageMetaData &&
        getState().pages.buildEntryState.domainData.buildInfoMetadata?.name
      ) {
        dispatch(buildEntryActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(buildEntryActions.setMetaDataSubState('EmptyRequired'))
      }
    },
  executeBuildEntry:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(buildEntryActions.setInProgress(true))

        const {
          selectedTrainedModel,
          selectedTrainingAlgorithm,
          selectedInferenceAlgorithmVersion,
          selectedBaseInferenceContainerImageIds:
            selectedBaseInferenceContainerImageId,
          isTransfer,
          isSystemEvaluation,
          buildInfoMetadata,
          edgeImageInfoMetadata,
          selectedTrainedModelGroup,
        } = getState().pages.buildEntryState.domainData

        if (
          !(
            selectedTrainedModel?.trainedModelId &&
            selectedTrainingAlgorithm?.algorithmId &&
            selectedInferenceAlgorithmVersion?.algorithmVersion.displayName &&
            selectedBaseInferenceContainerImageId &&
            buildInfoMetadata?.name &&
            edgeImageInfoMetadata.name &&
            selectedTrainedModelGroup
          )
        ) {
          console.error('Error invalid request')
          dispatch(buildEntryActions.executeBuildEntryFailure())
          return
        }

        const buildEntryParams: BuildEntryParamsType = {
          trainedModel: {
            trainedModelGroupId: selectedTrainedModelGroup.trainedModelGroupId,
            trainedModelId: selectedTrainedModel.trainedModelId,
            userGroupId: selectedTrainedModelGroup.userGroupId,
          },
          algorithmId: selectedTrainingAlgorithm?.algorithmId,
          algorithmVersion: {
            displayName:
              selectedInferenceAlgorithmVersion?.algorithmVersion.displayName,
            major: selectedInferenceAlgorithmVersion?.algorithmVersion.major,
            minor: selectedInferenceAlgorithmVersion?.algorithmVersion.minor,
            patch: selectedInferenceAlgorithmVersion?.algorithmVersion.patch,
          },
          baseContainerImageIdList: selectedBaseInferenceContainerImageId,
          isTransfer: isTransfer,
          isSystemEvaluation: isSystemEvaluation,
          edgeImageMetadata: {
            name: edgeImageInfoMetadata.name,
            remarks: edgeImageInfoMetadata.remarks,
          },
          mlPipelineMetadata: {
            name: buildInfoMetadata.name,
            remarks: buildInfoMetadata.remarks,
          },
          containerInterfaceVersion: {
            displayName:
              selectedInferenceAlgorithmVersion.containerInterfaceVersion
                .displayName,
            major:
              selectedInferenceAlgorithmVersion.containerInterfaceVersion.major,
            minor:
              selectedInferenceAlgorithmVersion.containerInterfaceVersion.minor,
            patch:
              selectedInferenceAlgorithmVersion.containerInterfaceVersion.patch,
          },
        }

        const result = await BuildEntryApi.executeBuildEntry(buildEntryParams)

        dispatch(buildEntryActions.executeBuildEntry())
        dispatch(
          buildEntryActions.setExecutedInfo({
            mlPipelineId: result.data.mlPipelineId,
            buildStepId: result.data.stepId,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(buildEntryActions.setInProgress(false))
      }
    },
}
