import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'
import {
  DatasetAugmentationDetailAction,
  datasetAugmentationDetailActions,
  datasetAugmentationDetailOperations,
  MlPipelineLogFile,
} from 'state/ducks/datasetAugmentationDetail'

import { isUndefined } from 'utils/typeguard'
import {
  DatasetAugmentationIcon,
  StatusProgressBar,
  GlobalLoading,
  CopyableLabel,
  FileDownloadLabel,
  RunningTimeLabel,
  showToast,
  Toast,
  CustomTrainingPageParagraph,
  DataDetailItem,
  BreadcrumbsComponent,
  ColorChip,
} from 'views/components'
import { FileDownloadLabelPropsItems } from 'views/components/molecules/fileDownloadLabel/types'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import {
  convertProgressWord,
  convertProgressColor,
  convertByteToMatchUnit,
  handleResourceNotFound,
  getChipColor,
} from 'views/containers/utils'
import { isDetailPathParams } from 'views/containers/utils/typeguard'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
  formatTimeSecByDate,
  lowerThanDateOnly,
} from 'views/components/utils/date'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'

const mapStateToProps = (state: State) => ({
  ...state.pages.datasetAugmentationDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, DatasetAugmentationDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カスタム学習一覧取得 */
  getDatasetAugmentationDetail: (mlPipelineId: string) =>
    dispatch(
      datasetAugmentationDetailOperations.getDatasetAugmentationDetail(
        mlPipelineId
      )
    ),
  /** ファイルデータ取得 */
  getFileData: () =>
    dispatch(datasetAugmentationDetailOperations.getFileData()),
  /** Stateのクリア */
  clearDatasetAugmentationDetailState: () =>
    dispatch(
      datasetAugmentationDetailActions.clearDatasetAugmentationDetailState()
    ),
  /** ログファイルをダウンロードする */
  logFileDownload: (mlPipelineId: string, logFiles: MlPipelineLogFile[]) =>
    dispatch(
      datasetAugmentationDetailOperations.logFileDownload(
        mlPipelineId,
        logFiles
      )
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(datasetAugmentationDetailActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  mt2Box: {
    marginTop: '16px',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: 'inherit',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  fileDataLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const DatasetAugmentationDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()

  /** 初期実行 */
  useEffect(() => {
    // 表示用詳細取得
    props.getDatasetAugmentationDetail(
      isDetailPathParams(props.match.params) ? props.match.params.id : ''
    )
    return () => {
      props.clearDatasetAugmentationDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.domainData.currentDatasetAugmentationDetail?.mlPipelineId) {
      props.getFileData()
    }
  }, [props.domainData.currentDatasetAugmentationDetail?.mlPipelineId])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.datasetAugmentationDetailState.mlPipelineDataState,
      history
    )
  }, [props.appState.datasetAugmentationDetailState.mlPipelineDataState])

  /** ログファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.datasetAugmentationDetailState.mlPipelineLogSubState ===
      'Failed'
    ) {
      showErrorToast('ログファイルの取得に失敗しました。')
    }
  }, [props.appState.datasetAugmentationDetailState.mlPipelineLogSubState])

  const [nowTab, setNowTab] = useState(0)

  /** ファイルデータ取得中のloading */
  const fileDataLoading = useMemo(() => {
    if (
      props.appState.datasetAugmentationDetailState.mlPipelineLogSubState ===
      'BeforeLoading'
    ) {
      if (nowTab === 1) {
        return (
          <Box className={classes.fileDataLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [props.appState.datasetAugmentationDetailState, nowTab])

  /** urlから取得したmlPipelineId */
  const mlPipelineId = isDetailPathParams(props.match.params)
    ? props.match.params.id
    : ''
  if (mlPipelineId === '') {
    console.error('Error Invalid ML Pipeline ID')
    return <></>
  }

  const fileDownloadLabelPropsItems = (
    logFiles: MlPipelineLogFile[]
  ): FileDownloadLabelPropsItems[] => {
    return logFiles.map((log) => {
      return {
        timeAndCapacity: `${convertByteToMatchUnit(
          log.fileSize
        )} ${formatDateTimeSec(log.createdAt)}`,
        url: log.fileName,
        onClick: () => props.logFileDownload(mlPipelineId, [log]),
      } as FileDownloadLabelPropsItems
    })
  }

  const tabItems: TabItems[] = [
    // 実行情報コンテンツ
    {
      label: '実行情報',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <Box mt={1}>
                <Typography>{`3Dデータ (${props.domainData.currentDatasetAugmentationDetail?.['3dData'].length})`}</Typography>
                {props.domainData.currentDatasetAugmentationDetail?.['3dData']
                  .length !== 0 &&
                  props.domainData.currentDatasetAugmentationDetail?.[
                    '3dData'
                  ].map((fileData, index) => {
                    return (
                      <Box ml={2} mt={2} mb={2} key={index}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                              variant='body1'
                              className={classes.labelText}
                            >
                              {fileData.fileName}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ borderTop: 'dotted 1px' }}>
                            <List style={{ width: '100%' }}>
                              <Typography>ラベル</Typography>
                              <Box ml={2}>
                                <Box
                                  display='flex'
                                  width='100%'
                                  borderBottom={'solid 1px'}
                                >
                                  <Box width='30%'>ラベルID</Box>
                                  <Box>{fileData.label.id}</Box>
                                </Box>
                                <Box mt={2}>
                                  <Box
                                    display='flex'
                                    width='100%'
                                    borderBottom={'solid 1px'}
                                  >
                                    <Box width='30%'>ラベル名</Box>
                                    <Box>{fileData.label.name}</Box>
                                  </Box>
                                </Box>
                                <Box mt={2}>
                                  <Box
                                    display='flex'
                                    width='100%'
                                    borderBottom={'solid 1px'}
                                  >
                                    <Box width='30%'>カテゴリ</Box>
                                    <Box>{fileData.label.category}</Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <Typography>テクスチャ</Typography>
                              </Box>
                              {fileData.texture ? (
                                <Box mt={2} ml={2}>
                                  <Box
                                    display='flex'
                                    width='100%'
                                    borderBottom={'solid 1px'}
                                  >
                                    <Box width='30%'>種別</Box>
                                    <Box>
                                      {fileData.texture.isSaved
                                        ? '既存のテクスチャ'
                                        : 'ユーザーのアップロード'}
                                    </Box>
                                  </Box>
                                  {fileData.texture.isSaved ? (
                                    <>
                                      <Box mt={2}>
                                        <Box
                                          display='flex'
                                          width='100%'
                                          borderBottom={'solid 1px'}
                                        >
                                          <Box width='30%'>名前</Box>
                                          <Box>{fileData.texture.name}</Box>
                                        </Box>
                                      </Box>
                                      <Box mt={2}>
                                        <Box
                                          display='flex'
                                          width='100%'
                                          borderBottom={'solid 1px'}
                                        >
                                          <Box width='30%'>概要</Box>
                                          <Box>{fileData.texture.overview}</Box>
                                        </Box>
                                      </Box>
                                    </>
                                  ) : (
                                    <Box mt={2}>
                                      <Box
                                        display='flex'
                                        width='100%'
                                        borderBottom={'solid 1px'}
                                      >
                                        <Box width='30%'>ファイル名</Box>
                                        <Box>{fileData.texture.name}</Box>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <></>
                              )}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          </Box>
          <Box my={2} component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                label='生成枚数'
                style={{ width: '100%', marginBottom: '16px' }}
                value={
                  props.domainData.currentDatasetAugmentationDetail
                    ?.renderersSettings.augmentationNumber
                }
                variant='standard'
                InputProps={{
                  readOnly: true,
                }}
              />
              <CustomTrainingPageParagraph level={'part'} title={'シーン設定'}>
                <Box m={2}>
                  <Stack spacing={2}>
                    <Box>
                      <Typography>背景</Typography>
                      <Box
                        display='flex'
                        width='100%'
                        minHeight='37px'
                        borderBottom={'solid 1px'}
                        flexWrap={'wrap'}
                      >
                        {props.domainData.currentDatasetAugmentationDetail?.scene.backgrounds.map(
                          (background, index) => {
                            return (
                              <ColorChip
                                key={index}
                                label={background.name}
                                size='medium'
                                isStretch
                                variant='filled'
                                customColor={getChipColor(
                                  background.userGroupId,
                                  props.auth.customClaims
                                )}
                                sx={{
                                  marginRight: (theme) => theme.spacing(1),
                                  marginBottom: (theme) => theme.spacing(0.5),
                                }}
                              />
                            )
                          }
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Typography>光源</Typography>
                      <Box
                        display='flex'
                        width='100%'
                        minHeight='37px'
                        borderBottom={'solid 1px'}
                        flexWrap={'wrap'}
                      >
                        {props.domainData.currentDatasetAugmentationDetail?.scene.lightGroups.map(
                          (lightGroup, index) => {
                            return (
                              <ColorChip
                                key={index}
                                label={lightGroup.name}
                                size='medium'
                                isStretch
                                variant='filled'
                                customColor={getChipColor(
                                  lightGroup.userGroupId,
                                  props.auth.customClaims
                                )}
                                sx={{
                                  marginRight: (theme) => theme.spacing(1),
                                  marginBottom: (theme) => theme.spacing(0.5),
                                }}
                              />
                            )
                          }
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Typography>カメラ配置</Typography>
                      <Box
                        display='flex'
                        width='100%'
                        minHeight='37px'
                        borderBottom={'solid 1px'}
                        flexWrap={'wrap'}
                      >
                        {props.domainData.currentDatasetAugmentationDetail?.scene.cameras.map(
                          (camera, index) => {
                            return (
                              <ColorChip
                                key={index}
                                label={camera.name}
                                size='medium'
                                isStretch
                                variant='filled'
                                customColor={getChipColor(
                                  camera.userGroupId,
                                  props.auth.customClaims
                                )}
                                sx={{
                                  marginRight: (theme) => theme.spacing(1),
                                  marginBottom: (theme) => theme.spacing(0.5),
                                }}
                              />
                            )
                          }
                        )}
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </CustomTrainingPageParagraph>
              <Box mt={2} mb={2}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant='body1' className={classes.labelText}>
                      アドバンスド設定
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box ml={2}>
                      <TextField
                        label='レイアウト'
                        style={{ width: '100%', marginBottom: '16px' }}
                        value={
                          props.domainData.currentDatasetAugmentationDetail
                            ?.placement.layout.name
                        }
                        variant='standard'
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        label='Seed'
                        style={{ width: '100%', marginBottom: '16px' }}
                        value={
                          props.domainData.currentDatasetAugmentationDetail
                            ?.placement.seed
                        }
                        variant='standard'
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                    <Box mt={2} ml={2}>
                      <Typography>レンダラー</Typography>
                      <Box mt={1} ml={1}>
                        <Typography>画像サイズ</Typography>
                      </Box>
                      <Box ml={2}>
                        <TextField
                          label='横'
                          style={{ width: '100%', marginBottom: '16px' }}
                          value={
                            props.domainData.currentDatasetAugmentationDetail
                              ?.renderersSettings.size.width
                          }
                          variant='standard'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          label='縦'
                          style={{ width: '100%', marginBottom: '16px' }}
                          value={
                            props.domainData.currentDatasetAugmentationDetail
                              ?.renderersSettings.size.height
                          }
                          variant='standard'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box mt={2} ml={2}>
                      <Typography>Depth</Typography>
                      <Box ml={2}>
                        <TextField
                          label='Min'
                          style={{ width: '100%', marginBottom: '16px' }}
                          value={
                            props.domainData.currentDatasetAugmentationDetail
                              ?.renderersSettings.depth.min
                          }
                          variant='standard'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          label='Max'
                          style={{ width: '100%', marginBottom: '16px' }}
                          value={
                            props.domainData.currentDatasetAugmentationDetail
                              ?.renderersSettings.depth.max
                          }
                          variant='standard'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box mt={2} ml={2}>
                      <Typography>アウトプット形式</Typography>
                      <Box ml={2}>
                        <TextField
                          label='アノテーション種別'
                          style={{ width: '100%', marginBottom: '16px' }}
                          value={
                            props.domainData.currentDatasetAugmentationDetail
                              ?.outputFormat.datasetTemplateName
                          }
                          variant='standard'
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {!isUndefined(
                          props.domainData.currentDatasetAugmentationDetail
                            ?.outputFormat.trainValidRatio
                        ) && (
                          <TextField
                            label='Train : Valid 比率（%）'
                            style={{ width: '100%', marginBottom: '16px' }}
                            value={
                              props.domainData.currentDatasetAugmentationDetail
                                ?.outputFormat.trainValidRatio
                            }
                            variant='standard'
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
        </>
      ),
    },
    // 処理結果コンテンツ
    {
      label: '処理結果',
      displayInfo: (
        <>
          {props.domainData.currentDatasetAugmentationDetail?.dataset
            .datasetId !== '' ? (
            <Box component={Paper} sx={{ position: 'relative' }}>
              <Box p={'24px 32px 32px'}>
                <DataDetailItem
                  formHelperText='データセット'
                  endAdornment={
                    <CopyableLabel
                      value={
                        props.domainData.currentDatasetAugmentationDetail
                          ? props.domainData.currentDatasetAugmentationDetail
                              .dataset.datasetId
                          : ''
                      }
                    />
                  }
                  startAdornment={
                    <Link
                      variant='body1'
                      className={classes.link}
                      underline='none'
                      onClick={() =>
                        history.push(
                          `/datasets/${props.domainData.currentDatasetAugmentationDetail?.dataset.datasetId}`
                        )
                      }
                      data-testid='generated-dataset'
                    >
                      {props.domainData.currentDatasetAugmentationDetail
                        ?.dataset.datasetName !== ''
                        ? props.domainData.currentDatasetAugmentationDetail
                            ?.dataset.datasetName
                        : NO_NAME}
                    </Link>
                  }
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}
          {fileDataLoading}
          <Box my={2} component={Paper}>
            <Box p={'24px 32px 32px'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box display='flex'>
                  <Typography component='div'>
                    <h4>ログ</h4>
                  </Typography>
                </Box>
                {props.domainData.mlPipelineLogFiles.length > 0 ? (
                  <Box display='flex'>
                    <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                    <Link
                      data-testid='all-download-logs'
                      style={{ cursor: 'pointer' }}
                      underline='none'
                      onClick={() =>
                        props.domainData.currentDatasetAugmentationDetail &&
                        props.logFileDownload(
                          mlPipelineId,
                          props.domainData.mlPipelineLogFiles
                        )
                      }
                    >
                      一括ダウンロード
                    </Link>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
              <Divider />
              <FileDownloadLabel
                items={fileDownloadLabelPropsItems(
                  props.domainData.mlPipelineLogFiles
                )}
              />
            </Box>
          </Box>
        </>
      ),
    },
    // 備考タブコンテンツ
    {
      label: '備考',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                style={{ width: '100%' }}
                value={
                  props.domainData.currentDatasetAugmentationDetail
                    ?.mlPipelineRemarks
                }
                variant='outlined'
                multiline
                disabled
                minRows={5}
                inputProps={{ 'data-testid': 'input-remarks' }}
              />
            </Box>
          </Box>
        </>
      ),
    },
  ]

  return (
    <>
      {!isUndefined(props.domainData.currentDatasetAugmentationDetail) ? (
        <div className={classes.container}>
          <Toast containerOptions={{ limit: 20 }}>
            <Box
              style={{
                position: 'sticky',
                top: '64px',
                backgroundColor: '#fafafa',
                zIndex: 10,
              }}
            >
              <Box className={classes.innerContainer}>
                <Box pt={3}>
                  <BreadcrumbsComponent
                    breadcrumbsPath={[
                      {
                        name: 'オーグメンテーション一覧',
                        path: 'dataset-augmentations',
                      },
                      {
                        name:
                          props.domainData.currentDatasetAugmentationDetail
                            .mlPipelineName !== ''
                            ? `${props.domainData.currentDatasetAugmentationDetail.mlPipelineName}`
                            : `${props.domainData.currentDatasetAugmentationDetail.mlPipelineId}`,
                        path: `${props.domainData.currentDatasetAugmentationDetail.mlPipelineId}`,
                      },
                    ]}
                  />
                </Box>
                <div className={classes.flexAndBetween}>
                  <Box
                    display='flex'
                    style={{
                      maxWidth: 'calc(100% - 280px)',
                      overflow: 'hidden',
                    }}
                  >
                    <DatasetAugmentationIcon
                      className={classes.pageIcon}
                      data-testid='datasetAugmentationDetailTitleIcon'
                    />
                    <Box
                      height={76}
                      data-testid='dataset-augmentation-detail-title'
                      style={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography component='div'>
                        <h2
                          style={{
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {
                            props.domainData.currentDatasetAugmentationDetail
                              .mlPipelineName
                          }
                        </h2>
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    <Box mr={3}>
                      <CopyableLabel
                        value={mlPipelineId}
                        isTooltip
                        placement='top'
                      />
                    </Box>
                    <Box width='150px'>
                      <StatusProgressBar
                        status={convertProgressWord(
                          props.domainData.currentDatasetAugmentationDetail
                            .progress.transactionStatus
                        )}
                        progressRate={
                          props.domainData.currentDatasetAugmentationDetail
                            .progress.progressRate
                        }
                        progressColor={convertProgressColor(
                          props.domainData.currentDatasetAugmentationDetail
                            .progress.transactionStatus
                        )}
                      />
                    </Box>
                  </Box>
                </div>
                <Box p={1}>
                  <div className={classes.flexAndBetween}>
                    <Box display='flex' mb={1}>
                      <TimerIcon style={{ marginRight: '4px' }} />
                      {isUndefined(
                        props.domainData.currentDatasetAugmentationDetail
                          .endedAt
                      ) ? (
                        // endAtがundefinedの場合
                        <Box alignItems='center' display='flex'>
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentDatasetAugmentationDetail.startedAt.toDate()
                          )} ~ `}</Typography>
                          (
                          <RunningTimeLabel
                            startedAt={props.domainData.currentDatasetAugmentationDetail.startedAt.toDate()}
                          />
                          )
                        </Box>
                      ) : lowerThanDateOnly(
                          props.domainData.currentDatasetAugmentationDetail.startedAt.toDate(),
                          props.domainData.currentDatasetAugmentationDetail.endedAt.toDate()
                        ) ? (
                        // 日付が同じ場合
                        <Typography>{`${formatDateTimeSec(
                          props.domainData.currentDatasetAugmentationDetail.startedAt.toDate()
                        )} ~ ${formatTimeSecByDate(
                          props.domainData.currentDatasetAugmentationDetail.endedAt.toDate()
                        )}(${formatTimeSecByMillSecond(
                          props.domainData.currentDatasetAugmentationDetail.endedAt
                            .toDate()
                            .getTime() -
                            props.domainData.currentDatasetAugmentationDetail.startedAt
                              .toDate()
                              .getTime()
                        )})`}</Typography>
                      ) : (
                        // 日付が違う場合
                        <Typography>{`${formatDateTimeSec(
                          props.domainData.currentDatasetAugmentationDetail.startedAt.toDate()
                        )} ~ ${formatDateTimeSec(
                          props.domainData.currentDatasetAugmentationDetail.endedAt.toDate()
                        )}(${formatTimeSecByMillSecond(
                          props.domainData.currentDatasetAugmentationDetail.endedAt
                            .toDate()
                            .getTime() -
                            props.domainData.currentDatasetAugmentationDetail.startedAt
                              .toDate()
                              .getTime()
                        )})`}</Typography>
                      )}
                    </Box>
                    <Box display='flex'>
                      {typeof props.domainData.currentDatasetAugmentationDetail
                        ?.createdBy === 'string' ? (
                        <Typography>
                          {
                            props.domainData.currentDatasetAugmentationDetail
                              ?.createdBy
                          }
                        </Typography>
                      ) : (
                        <Typography>
                          {
                            props.domainData.currentDatasetAugmentationDetail
                              ?.createdBy.firstName
                          }{' '}
                          {
                            props.domainData.currentDatasetAugmentationDetail
                              ?.createdBy.familyName
                          }
                        </Typography>
                      )}
                    </Box>
                  </div>
                </Box>
                <Box
                  style={{
                    backgroundColor: '#fafafa',
                  }}
                >
                  <Tabs
                    indicatorColor='primary'
                    value={nowTab}
                    style={{
                      paddingBottom: '16px',
                      marginBottom: '1px',
                    }}
                    onChange={(_, value) => setNowTab(value)}
                  >
                    {tabItems.map((item, index) => (
                      <Tab
                        style={{
                          width: `${100 / tabItems.length}%`,
                          maxWidth: '1200px',
                        }}
                        key={index}
                        className={clsx(nowTab === index && classes.nowTab)}
                        label={item.label}
                        data-testid={`change-tab-${index}`}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Box>
            <Box className={classes.innerContainer}>
              <Paper elevation={0}>
                <Box>{tabItems[nowTab].displayInfo}</Box>
              </Paper>
            </Box>
          </Toast>
        </div>
      ) : (
        <></>
      )}
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const DatasetAugmentationDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetAugmentationDetail))
