import { DatasetQueryDocument } from './types'

import {
  timeStampSchema,
  optionalVersionSchema,
  extendedObjectClassificationForQuerySchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** dataset-query documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as DatasetQueryDocument
  const schema = {
    required: [
      'algorithm-id',
      'dataset-id',
      'dataset-name',
      'created-at',
      'generated-for',
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'annotation-format-version': {
        ...optionalVersionSchema,
      },
      'annotation-set-list': {
        type: 'array',
        items: {
          required: [
            'annotation-id',
            'annotation-set-id',
            'annotation-set-kind',
            'grouped-data-id',
          ],
          type: 'object',
          properties: {
            'annotation-id': {
              type: 'string',
            },
            'annotation-set-id': {
              type: 'string',
            },
            'annotation-set-kind': {
              type: 'string',
            },
            'grouped-data-id': {
              type: 'string',
            },
          },
        },
      },
      'dataset-id': {
        type: 'string',
      },
      'dataset-name': {
        type: 'string',
      },
      'dataset-remarks': {
        type: 'string',
      },
      'dataset-template-id': {
        type: 'string',
      },
      'generated-for': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
      extended: {
        type: 'object',
        properties: {
          'object-classification': {
            ...extendedObjectClassificationForQuerySchema,
          },
        },
      },
    },
  }

  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'dataset-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
