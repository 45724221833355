import {
  AccountListDisplayCondition,
  AccountInfo,
} from 'utils/ducks/accountList/types'
import { Timestamp } from 'state/firebase'

export const AccountListActionType = {
  SET_ACCOUNT_LIST: 'SET_ACCOUNT_LIST',
  CLEAR_DISPLAY_CONDITION: 'CLEAR_DISPLAY_CONDITION',
  SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_LIST:
    'SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_LIST',
  SET_SELECTED_ACCOUNT_GROUP_ID_FOR_ACCOUNT_LIST:
    'SET_SELECTED_ACCOUNT_GROUP_ID_FOR_ACCOUNT_LIST',
  SET_ACCOUNT_LIST_DISPLAY_CONDITION: 'SET_ACCOUNT_LIST_DISPLAY_CONDITION',
  SET_ACCOUNT_LIST_PAGING_STATE: 'SET_ACCOUNT_LIST_PAGING_STATE',
  SET_IN_PROGRESS_FOR_ACCOUNT_LIST: 'SET_IN_PROGRESS_FOR_ACCOUNT_LIST',
  CLEAR_ACCOUNT_LIST_STATE: 'CLEAR_ACCOUNT_LIST_STATE',
} as const

export interface AccountDocument {
  id: string
  'account-id': string
  'mail-address': string
  uid: string
  'account-group-id': string
  'account-group-role': string
  'password-reset': boolean
  'last-login-time': Timestamp
  'first-login-time': Timestamp
  'super-user': boolean
  'password-update-at': Timestamp
  'created-at': Timestamp
  'created-by': string
  'updated-at': Timestamp
  'updated-by': string
}

export interface AccountSettingsDocument {
  id: string
  'account-id': string
  'family-name': string
  'first-name': string
  'is-mfa': boolean
  language: string
  locale: string
  'phone-numbers': string[]
  'created-at': Timestamp
  'created-by': string
  'updated-at': Timestamp
  'updated-by': string
}
export interface AccountDetailInfo extends AccountInfo {
  accountId: string
  accountGroupRole: string
  mailAddress: string
  firstName?: string
  familyName?: string
  lastLoginTime: Timestamp
  registeredDate: Timestamp
  passwordUpdateAt: Timestamp
  firstOption: boolean
  accountGroupId: string
}

export interface AccountListPagingState {
  nextPageSubState: 'Enable' | 'Unable'
}

export interface AccountGroup {
  accountGroupId: string
  name: string
}

export interface AccountListDomainData {
  currentAccountList: AccountDetailInfo[]
  accountGroupList: AccountGroup[]
  selectedAccountGroupId: string
  accountListDisplayCondition: AccountListDisplayCondition
}

export interface AccountListAppState {
  accountListState: AccountListPagingState
  inProgress: boolean
}

export interface AccountInfoList {
  domainData: AccountListDomainData
  appState: AccountListAppState
}
