import { SettingMetadataDocument } from './types'

import { timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** setting-metadata documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as SettingMetadataDocument
  const schema = {
    required: ['name', 'created-at', 'created-by', 'updated-at', 'updated-by'],
    type: 'object',
    properties: {
      name: {
        type: 'string',
      },
      remarks: {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'setting-metadata',
      value: data as unknown as DataObject,
    })
    return false
  }
}
