import { FeatureDataGeneratingListAction } from './actions'
import {
  FeatureDataGeneratingListActionType,
  FeatureDataGeneratingListState,
} from './types'

const initialState: FeatureDataGeneratingListState = {
  domainData: {
    currentFeatureDataGeneratingList: [],
    featureDataGeneratingDisplayCondition: {
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    mlPipelineIds: [],
    currentFeatureDataGeneratingListSnapshot: undefined,
  },
  appState: {
    inProgress: false,
  },
}

export const FeatureDataGeneratingListReducer = (
  state: FeatureDataGeneratingListState = initialState,
  action: FeatureDataGeneratingListAction
): FeatureDataGeneratingListState => {
  switch (action.type) {
    case FeatureDataGeneratingListActionType.SET_FEATURE_DATA_GENERATING_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGeneratingList:
            action.payload.currentFeatureDataGeneratingList,
        },
      }
    case FeatureDataGeneratingListActionType.SET_FEATURE_DATA_GENERATING_DISPLAY_CONDITION:
      if (action.payload.featureDataGeneratingDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            featureDataGeneratingDisplayCondition:
              action.payload.featureDataGeneratingDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataGeneratingListActionType.CLEAR_FEATURE_DATA_GENERATING_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGeneratingDisplayCondition: {
            ...initialState.domainData.featureDataGeneratingDisplayCondition,
          },
        },
      }
    case FeatureDataGeneratingListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataGeneratingListActionType.SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_GENERATING_LIST:
      if (action.payload.mlPipelineIds) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            mlPipelineIds: action.payload.mlPipelineIds,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataGeneratingListActionType.SET_CURRENT_FEATURE_DATA_GENERATING_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGeneratingListSnapshot:
            action.payload.currentFeatureDataGeneratingListSnapshot,
        },
      }
    case FeatureDataGeneratingListActionType.CLEAR_FEATURE_DATA_GENERATING_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
