import React, { useMemo, useState, useEffect } from 'react'
import { Box, FormControl, FormHelperText, OutlinedInput } from '@mui/material'
import { validateDateString } from 'views/components/utils/date'
import { DateRangeInputProps } from './types'

export const DateRangeInput: React.FC<DateRangeInputProps> = (
  props: DateRangeInputProps
) => {
  /** 日付のバリデートエラーメッセージ */
  const [errorMessage, setErrorMessage] = useState<string>('')

  /** 日付入力フォーマットチェック(fromDate) */
  const isValidDate = useMemo(() => {
    if (
      (props.fromDate.date && !validateDateString(props.fromDate.date)) ||
      (props.toDate.date && !validateDateString(props.toDate.date))
    ) {
      setErrorMessage('yyyy/mm/dd で入力してください')
      return false
    }

    if (
      props.toDate.date &&
      validateDateString(props.toDate.date) &&
      props.fromDate.date &&
      validateDateString(props.fromDate.date)
    ) {
      const fromDate = new Date(props.fromDate.date)
      const toDate = new Date(props.toDate.date)
      if (fromDate > toDate) {
        setErrorMessage(
          '終了日が開始日以降の日付になるように、それぞれ入力してください'
        )
        return false
      }
    }

    return true
  }, [props.toDate.date, props.fromDate.date])

  useEffect(() => {
    props.setEnabledRange(isValidDate)
  }, [isValidDate])

  return (
    <FormControl variant='outlined'>
      <Box display='flex' alignItems='center'>
        <FormControl>
          <OutlinedInput
            inputProps={{ 'data-testid': 'input-from-date' }}
            error={!isValidDate}
            defaultValue={props.fromDate.date}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.fromDate.setDate(e.target.value)}
          />
        </FormControl>
        <Box p={2}>~</Box>
        <FormControl>
          <OutlinedInput
            inputProps={{ 'data-testid': 'input-to-date' }}
            error={!isValidDate}
            defaultValue={props.toDate.date}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.toDate.setDate(e.target.value)}
          />
        </FormControl>
      </Box>
      {!isValidDate && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}
