export interface RobotDataAnalysisExtractedImageQueryConditions {
  executionStatus: {
    success?: boolean
    fail?: boolean
    error?: boolean
  }
  executionDate?: {
    from?: string
    to?: string
  }
  modelGroupIdList?: string[]
  executionIdList?: string[]
  robotIdList?: string[]
  confirmed: boolean
  objectRecognitionResults: boolean
}

const CUSTOM_TRAINING_QUERY_PARAMETER_KEYS = [
  'algorithm-id',
  'shared-user-group',
  'trained-model-group-id',
  'trained-model-id',
  'algorithm-version',
]

export interface CustomTrainingQueryParameters {
  'algorithm-id'?: string | null
  'shared-user-group'?: string | null
  'trained-model-group-id'?: string | null
  'trained-model-id'?: string | null
  'algorithm-version'?: string | null
}

const INFERENCE_QUERY_PARAMETER_KEYS = [
  'algorithm-id',
  'shared-user-group',
  'trained-model-group-id',
  'trained-model-id',
]

export interface InferenceQueryParameters {
  'algorithm-id'?: string | null
  'shared-user-group'?: string | null
  'trained-model-group-id'?: string | null
  'trained-model-id'?: string | null
}

const BUILD_QUERY_PARAMETER_KEYS = [
  'algorithm-id',
  'shared-user-group',
  'trained-model-group-id',
  'trained-model-id',
]

export interface BuildQueryParameters {
  'algorithm-id'?: string | null
  'shared-user-group'?: string | null
  'trained-model-group-id'?: string | null
  'trained-model-id'?: string | null
}

const createArrayParam = (prefix: string, arr?: string[]) => {
  const str = arr?.join(',')
  return `${prefix}=${str ?? ''}&`
}

export const createRobotDataAnalysisExtractedImageQueryParams = (
  queryConditions: RobotDataAnalysisExtractedImageQueryConditions
): string => {
  let params = '?'
  Object.entries(queryConditions).forEach(([key, value]) => {
    switch (key) {
      case 'confirmed':
        params = params + `confirmed=${value}&`
        break
      case 'executionStatus':
        if (Object.keys(value).some((key) => value[key])) {
          params =
            params +
            `execution-status=${Object.keys(value)
              .filter((key) => value[key])
              .join(',')}&`
        } else {
          params = params + 'execution-status=&'
        }
        break
      case 'executionIdList':
        params = params + createArrayParam('execution-id-list', value)
        break
      case 'robotIdList':
        params = params + createArrayParam('robot-id-list', value)
        break
      case 'executionDate':
        params = params + `execution-date-from=${value?.from ?? ''}&`
        params = params + `execution-date-to=${value?.to ?? ''}&`
        break
      case 'modelGroupIdList':
        params = params + createArrayParam('model-group-id-list', value)
        break
      case 'objectRecognitionResults':
        params = params + `object-recognition-results=${value}&`
        break
      default:
        break
    }
  })
  // 不要な末尾の & を削除
  const searchParams = params.slice(0, -1)
  return searchParams
}

export const sharedUserGroupQueryParameter = {
  'shared-user-group': 'true',
}

export const generateQueryParameters = (
  query: { [x: string]: string } | undefined
) => (query ? `?${new URLSearchParams(query).toString()}` : '')

export const hasSharedUserGroupQueryParameter = (query: string) =>
  new URLSearchParams(query).get('shared-user-group') === 'true'

const convertQueryParameterToObject = <T>(
  query: string,
  queryKeys: string[]
): T => {
  let queryObject = {}

  const queryParams = new URLSearchParams(query)

  queryKeys.forEach((key: string) => {
    queryObject = {
      ...queryObject,
      [key]: queryParams.get(key),
    }
  })

  return queryObject as T
}

export const getCustomTrainingQueryParameters = (
  query: string
): CustomTrainingQueryParameters => {
  return convertQueryParameterToObject<CustomTrainingQueryParameters>(
    query,
    CUSTOM_TRAINING_QUERY_PARAMETER_KEYS
  )
}

export const getInferenceQueryParameters = (
  query: string
): InferenceQueryParameters => {
  return convertQueryParameterToObject<InferenceQueryParameters>(
    query,
    INFERENCE_QUERY_PARAMETER_KEYS
  )
}

export const getBuildQueryParameters = (
  query: string
): BuildQueryParameters => {
  return convertQueryParameterToObject<BuildQueryParameters>(
    query,
    BUILD_QUERY_PARAMETER_KEYS
  )
}
