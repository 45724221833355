import { Timestamp } from 'state/firebase'

export const ModelListActionType = {
  SET_TRAINED_MODEL_GROUP_ID: 'SET_TRAINED_MODEL_GROUP_ID',
  SET_TRAINED_MODEL_GROUP_NAME: 'SET_TRAINED_MODEL_GROUP_NAME',
  GET_MODEL_LIST: 'GET_MODEL_LIST',
  SET_MODEL_LIST: 'SET_MODEL_LIST',
  CLEAR_MODEL_LIST: 'CLEAR_MODEL_LIST',
  SET_MODEL_LIST_DISPLAY_CONDITION: 'SET_MODEL_LIST_DISPLAY_CONDITION',
  SET_MODEL_LIST_PAGING_STATE: 'SET_MODEL_LIST_PAGING_STATE',
  SET_IN_PROGRESS_FOR_MODEL_LIST: 'SET_IN_PROGRESS_FOR_MODEL_LIST',
  SET_MODEL_GROUP_SUB_STATE: 'SET_MODEL_GROUP_SUB_STATE',
  CLEAR_MODEL_LIST_STATE: 'CLEAR_MODEL_LIST_STATE',
} as const

export type GetSubState =
  | 'BeforeGetting'
  | 'GettingSuccess'
  | 'GettingError'
  | 'GettingNotFound'

export interface ModelGroupVersion {
  displayName: string
  major: number
  minor: number
  patch: number
}

export interface ModelInfo {
  modelId: string
  modelName: string
  modelKind: 'Generic' | 'Custom' | 'Specified'
  modelGroupVersion: ModelGroupVersion
  generatedAt?: Timestamp
  mlPipeline: {
    mlPipelineId: string
    mlPipelineKind?: 'CustomTraining' | 'Inference' | 'Build' | 'PreProcessing'
  }
  uid: string
}

export interface ModelListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface ModelListDomainData {
  trainedModelGroupId: string
  trainedModelGroupName: string
  currentModelList: ModelInfo[]
  modelListDisplayCondition: ModelListDisplayCondition
}

export interface ModelListAppState {
  inProgress: boolean
  modelGroupSubState: GetSubState
}

export interface ModelInfoList {
  domainData: ModelListDomainData
  appState: ModelListAppState
}
