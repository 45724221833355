import { HttpsCallableResult } from 'firebase/functions'
export const CreateSettingActionType = {
  ADD_SETTING_FILE: 'ADD_SETTING_FILE',
  SET_SETTING_METADATA: 'SET_SETTING_METADATA',
  SET_SETTING_STATE: 'SET_SETTING_STATE',
  SET_SETTING: 'SET_SETTING',
  CLEAR_CURRENT_SETTING_STATE: 'CLEAR_CURRENT_SETTING_STATE',
  CREATE_SETTING_SET_SETTINGID: 'CREATE_SETTING_SET_SETTINGID',
  CREATE_SETTING_DOCUMENT_ERROR: 'CREATE_SETTING_DOCUMENT_ERROR',
  SET_IN_PROGRESS_FOR_CREATE_SETTING: 'SET_IN_PROGRESS_FOR_CREATE_SETTING',
} as const

export type UploadType = 'Uploading' | 'Completed' | 'UploadError'
export type InputRequireType = 'EmptyRequired' | 'InputRequired'

export interface SettingMetadata {
  name?: string
  remarks?: string
}

export const CreateSettingStateKindArray = [
  'SettingFileAndMetadataState',
  'UploadState',
]

export type SettingState = (typeof CreateSettingStateKindArray)[number]

export interface SettingSubState {
  settingFileState: UploadType
  metadataSubState: InputRequireType
  uploadSubState: 'BeforeUpload' | UploadType | 'DocumentError'
}

export interface Setting {
  settingId?: string
  trainingPrameterId?: string
  userGroupId?: string
}

export interface SettingFireStore {
  settingId: string
  metadata: { name: string; remarks: string }
}

export interface CreateSettingFirestoreResult extends HttpsCallableResult {
  readonly data: {
    settingId: string
  }
}

export interface CurrentCreateSettingDomainData {
  setting?: Setting
  settingFile?: File
  settingMetadata?: SettingMetadata
}

export interface CurrentCreateSettingAppState {
  settingState: SettingState
  settingSubState: SettingSubState
  inProgress: boolean
}

export interface CurrentCreateSettingState {
  domainData: CurrentCreateSettingDomainData
  appState: CurrentCreateSettingAppState
}
