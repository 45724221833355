import { Dispatch } from 'redux'
import {
  getAccountCustomerRelationsCollection,
  getAccountSettingCollection,
  getAccountsCollection,
  getAccountGroupCollection,
  getCustomerMetadataCollection,
  getAccountGroupMetadataCollection,
  getAccountGroupCustomerRelationsCollection,
} from 'state/firebase'
import { accountDetailActions } from './actions'
import { convertPhoneNumber } from 'views/containers/utils/phoneNumber'
import {
  AccountRelationCustomer,
  AccountGroupRelationCustomer,
  Customer,
  CustomerRaw,
} from './types'
import { AccountDetailApi } from './apis'
import { domainDataActions } from 'state/app/domainData'
import { State } from 'state/store'
import { doc, getDoc } from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountCustomerRelationDocument } from 'utils/fireStore/accountCustomerRelation'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupDocument } from 'utils/fireStore/accountGroup'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountDocument } from 'utils/fireStore/account'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupCustomerRelationDocument } from 'utils/fireStore/accountGroupCustomerRelation'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupMetadataDocument } from 'utils/fireStore/accountGroupMetadata'
import { AccountGroupRole } from 'views/utils/types'

// CloudFunctionsで自ユーザの削除、次ユーザのロールをユーザに変更しようとした際のエラーコード
const ERROR_ACCOUNT_OPS_PERMISSION_DENIED = 'functions/permission-denied'

export const AccountDetailOperations = {
  /** ユーザー情報を取得する */
  getAccountInfo:
    (accountId: string, queryAccountGroupId?: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(accountDetailActions.setInProgress(true))
      try {
        const accountGroupId =
          queryAccountGroupId ??
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const accountDoc = (
          await getDoc(doc(getAccountsCollection(accountGroupId), accountId))
        ).data()
        if (!accountDoc) {
          dispatch(
            accountDetailActions.setAccountDataSubState('NotFoundProcessed')
          )
        }
        const accountSettingDoc = (
          await getDoc(
            doc(getAccountSettingCollection(accountGroupId), accountId)
          )
        ).data()
        const accountGroup = (
          await getDoc(doc(getAccountGroupCollection(), accountGroupId))
        ).data()
        const accountCustomerRelationDoc = (
          await getDoc(
            doc(
              getAccountCustomerRelationsCollection(accountGroupId),
              accountId
            )
          )
        ).data()

        if (
          !accountCustomerRelationDoc ||
          !fireStoreTypeGuardForAccountCustomerRelationDocument(
            accountCustomerRelationDoc
          ) ||
          !fireStoreTypeGuardForAccountSettingDocument(accountSettingDoc) ||
          !fireStoreTypeGuardForAccountGroupDocument(accountGroup) ||
          !fireStoreTypeGuardForAccountDocument(accountDoc)
        ) {
          dispatch(accountDetailActions.setAccountDataSubState('Failed'))
          return
        }

        // カスタマーIDのリスト
        const customerIdList = accountCustomerRelationDoc['customer-list']
        // カスタマーメタデータのリスト
        const customerMetadataList: {
          name: string
          remarks: string
        }[] = await Promise.all(
          customerIdList.map(async (item: AccountGroupRelationCustomer) => {
            const document = await getDoc(
              doc(getCustomerMetadataCollection(), item['customer-id'])
            )
            return document.data()
          })
        )
        // アカウントグループ名はアカウントグループメタデータdocから取得する
        const accountGroupMetadata = (
          await getDoc(doc(getAccountGroupMetadataCollection(), accountGroupId))
        ).data()

        if (
          !fireStoreTypeGuardForAccountGroupMetadataDocument(
            accountGroupMetadata
          )
        ) {
          dispatch(accountDetailActions.setAccountDataSubState('Failed'))
          return
        }

        const isSuperUser = accountDoc?.['super-user'] ?? false
        const accountGroupName =
          accountGroupMetadata && accountGroupMetadata['name']
            ? accountGroupMetadata['name']
            : ''
        // アカウントのカスタマーリスト作成する
        const accountCustomerList: Customer[] = []
        if (accountCustomerRelationDoc) {
          const relationList: AccountRelationCustomer[] =
            accountCustomerRelationDoc['customer-list']
          relationList.map(async (item, index) => {
            // ベースモデルのオブジェクトを作成
            const accountInfo: Customer = {
              customerId: item['customer-id'],
              customerName: customerMetadataList[index].name,
              role: isSuperUser
                ? 'admin'
                : item['role'] == null || item['role'] === ''
                ? 'user'
                : item['role'],
              lastAccessed: item['last-accessed'],
              linkedDate: item['linked-date'],
            }
            accountCustomerList.push(accountInfo)
          })
        }
        dispatch(
          accountDetailActions.setAccountInfo({
            mail: accountDoc ? accountDoc['mail-address'] : '',
            phoneNumber: accountSettingDoc
              ? accountSettingDoc['phone-numbers'][0]
                ? convertPhoneNumber(accountSettingDoc['phone-numbers'][0])
                : ''
              : '',
            firstName: accountSettingDoc ? accountSettingDoc['first-name'] : '',
            familyName: accountSettingDoc
              ? accountSettingDoc['family-name']
              : '',
            accountGroupName: accountGroupName,
            accountGroupRole: accountDoc
              ? accountDoc['account-group-role']
              : '',
            language: accountSettingDoc ? accountSettingDoc['language'] : '',
            // TODO 今は固定
            locate: 'JP',
            role: accountDoc ? accountDoc['super-user'] : false,
            mfaGroupSetting: accountGroup
              ? accountGroup['mfa-group-setting']
              : '',
            isMfa: accountSettingDoc ? accountSettingDoc['is-mfa'] : false,
            passwordUpdateDate: accountDoc
              ? accountDoc['password-update-at']
              : undefined,
            lastLoginTime: accountDoc
              ? accountDoc['last-login-time']
              : undefined,
            createdAt: accountDoc ? accountDoc['created-at'] : undefined,
            firstLoginTime: accountDoc
              ? accountDoc['first-login-time']
              : undefined,
            uid: accountDoc ? accountDoc['uid'] : '',
            customerList: accountCustomerList,
          })
        )
        dispatch(accountDetailActions.setAccountDataSubState('Loaded'))
      } catch (error) {
        console.error(error)
        dispatch(accountDetailActions.setAccountDataSubState('Failed'))
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
      }
    },
  /** アカウントグループのカスタマー情報を取得する */
  getCustomerList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(accountDetailActions.setInProgress(true))
      try {
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const accountGroupCustomerRelationDoc = (
          await getDoc(
            doc(getAccountGroupCustomerRelationsCollection(), accountGroupId)
          )
        ).data()
        if (
          !accountGroupCustomerRelationDoc ||
          !fireStoreTypeGuardForAccountGroupCustomerRelationDocument(
            accountGroupCustomerRelationDoc
          )
        )
          return
        const customerIdList = accountGroupCustomerRelationDoc['customer-list']
        // カスタマーメタデータのリスト
        const customerMetadataList: {
          name: string
          remarks: string
        }[] = await Promise.all(
          customerIdList.map(async (item: AccountGroupRelationCustomer) => {
            const document = await getDoc(
              doc(getCustomerMetadataCollection(), item['customer-id'])
            )
            return document.data()
          })
        )
        // アカウントグループのカスタマーリスト作成する
        const accountGroupCustomerList: CustomerRaw[] = []
        if (accountGroupCustomerRelationDoc) {
          const relationList: AccountGroupRelationCustomer[] =
            accountGroupCustomerRelationDoc['customer-list']
          relationList.map(async (item, index) => {
            // ベースモデルのオブジェクトを作成
            const customerInfo: CustomerRaw = {
              customerId: item['customer-id'],
              customerName: customerMetadataList[index].name,
            }
            accountGroupCustomerList.push(customerInfo)
          })
        }
        dispatch(accountDetailActions.setCustomerList(accountGroupCustomerList))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
      }
    },
  /** プロフィール更新処理 */
  updateProfile:
    (uid: string, firstName: string, familyName: string, accountId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(accountDetailActions.setInProgress(true))
      try {
        await AccountDetailApi.updateAccountName(uid, firstName, familyName)
        dispatch(
          accountDetailActions.setUpdateAccountName({
            firstName: firstName,
            familyName: familyName,
          })
        )
        const authUserAccountId =
          getState().app.domainData.authedUser.auth.customClaims.accountId
        if (accountId === authUserAccountId) {
          // 保持しているユーザーの名前を更新する
          const userProfile = getState().app.domainData.userProfile
          dispatch(
            domainDataActions.setUserProfile({
              firstName: firstName,
              familyName: familyName,
              customerId: userProfile ? userProfile.customerId : '',
              customerName: userProfile ? userProfile.customerName : '',
              accountGroupName: userProfile ? userProfile.accountGroupName : '',
              language: userProfile ? userProfile.language : '',
              locate: userProfile ? userProfile.locate : '',
              role: userProfile ? userProfile.role : 'user',
              accountGroupRole: userProfile
                ? userProfile.accountGroupRole
                : 'user',
            })
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
      }
    },
  /** アカウントグループロール更新処理 */
  updateAccountGroupRole:
    (uid: string, accountGroupRole: AccountGroupRole, accountId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(accountDetailActions.setInProgress(true))
      try {
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        await AccountDetailApi.updateAccountGroupRole(
          uid,
          accountGroupRole,
          accountGroupId
        )
        dispatch(
          accountDetailActions.setUpdateAccountGroupRole(accountGroupRole)
        )
        const authUserAccountId =
          getState().app.domainData.authedUser.auth.customClaims.accountId
        if (accountId === authUserAccountId) {
          // 保持しているユーザーのアカウントグループロールを更新する
          const userProfile = getState().app.domainData.userProfile
          if (userProfile) {
            dispatch(
              domainDataActions.setUserProfile({
                ...userProfile,
                accountGroupRole,
              })
            )
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
      }
    },
  /** パスワード強制更新 */
  forcedAccountPasswordUpdate:
    (uid: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(accountDetailActions.setInProgress(true))
        await AccountDetailApi.forcedAccountPasswordUpdate(uid)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
      }
    },
  /** アカウント削除 */
  accountDelete:
    (uid: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountDetailActions.setInProgress(true))
        const myAccountId =
          getState().app.domainData.authedUser.auth.customClaims.accountId
        await AccountDetailApi.accountDelete(uid, myAccountId)
          .then(() => {
            dispatch(
              accountDetailActions.setAccountDeleteState('DeleteSuccess')
            )
          })
          .catch((error) => {
            if (error.code === ERROR_ACCOUNT_OPS_PERMISSION_DENIED) {
              dispatch(
                accountDetailActions.setAccountDeleteState(
                  'AccountDeleteAborted'
                )
              )
            } else {
              dispatch(
                accountDetailActions.setAccountDeleteState('DeleteError')
              )
              console.error(error)
            }
          })
      } catch (error) {
        dispatch(accountDetailActions.setAccountDeleteState('DeleteError'))
        console.error(error)
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
        dispatch(accountDetailActions.setAccountDeleteState('BeforeDelete'))
      }
    },
  /** クレームの更新、docの更新 */
  updateCustomClaimSuperUser:
    (uid: string, customClaimKey: string, customClaimValue: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountDetailActions.setInProgress(true))
        const myAccountId =
          getState().app.domainData.authedUser.auth.customClaims.accountId
        const updateParam = {
          [customClaimKey]: customClaimValue,
        }
        // カスタムクレームを更新
        await AccountDetailApi.updateCustomClaim(uid, updateParam, myAccountId)
          .then(() => {
            dispatch(
              accountDetailActions.setAccountUpdateRoleState(
                'UpdateRoleSuccess'
              )
            )
          })
          .catch((error) => {
            if (error.code === ERROR_ACCOUNT_OPS_PERMISSION_DENIED) {
              dispatch(
                accountDetailActions.setAccountUpdateRoleState(
                  'UpdateRoleAborted'
                )
              )
            } else {
              dispatch(
                accountDetailActions.setAccountUpdateRoleState(
                  'UpdateRoleError'
                )
              )
              console.error(error)
            }
          })
      } catch (error) {
        dispatch(
          accountDetailActions.setAccountUpdateRoleState('UpdateRoleError')
        )
        console.error(error)
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
        dispatch(
          accountDetailActions.setAccountUpdateRoleState('BeforeUpdateRole')
        )
      }
    },
  /** */
  updateAccountCustomerRelations:
    (uid: string, accountCustomers: Customer[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(accountDetailActions.setInProgress(true))
        //
        await AccountDetailApi.updateCustomers(uid, accountCustomers)
          .then(() => {
            dispatch(
              accountDetailActions.updateAccountCustomerRelations(
                accountCustomers
              )
            )
            dispatch(
              accountDetailActions.setAccountUpdateCustomerListState(
                'UpdateCustomerListSuccess'
              )
            )
          })
          .catch((error) => {
            console.error(error)
            dispatch(
              accountDetailActions.setAccountUpdateCustomerListState(
                'UpdateCustomerListError'
              )
            )
          })
      } catch (error) {
        console.error(error)
        dispatch(
          accountDetailActions.setAccountUpdateCustomerListState(
            'UpdateCustomerListError'
          )
        )
      } finally {
        dispatch(accountDetailActions.setInProgress(false))
        dispatch(
          accountDetailActions.setAccountUpdateCustomerListState(
            'BeforeUpdateCustomerList'
          )
        )
      }
    },
}
