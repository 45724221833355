import {
  CreateAction,
  ActionsUnion,
  Action,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  DatasetAugmentationActionType,
  DatasetAugmentationStateKind,
  DatasetAugmentationSubState,
  TextureListDisplayCondition,
  Texture,
  PresetListDisplayCondition,
  Preset,
  Layout,
  RenderersInput,
  MetaData,
  Uploaded3dData,
  ToastInfo,
  ScenePartialData,
  ExecutionInfo,
  UploadedPlacementData,
  Rotation,
  OutputFormat,
} from './types'

export type DatasetAugmentationAction = ActionsUnion<
  typeof datasetAugmentationActions
>

export const datasetAugmentationActions = {
  setUploadedCadFile: (
    uploadedCadFile: Uploaded3dData
  ): ActionWithPayload<
    'SET_UPLOADED_CAD_FILE',
    { uploadedCadFile: Uploaded3dData }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_UPLOADED_CAD_FILE, {
      uploadedCadFile,
    }),
  setUploadedCadFileData: (
    uploadedCadData: Uploaded3dData[]
  ): ActionWithPayload<
    'SET_UPLOADED_CAD_DATA',
    { uploadedCadData: Uploaded3dData[] }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_UPLOADED_CAD_DATA, {
      uploadedCadData,
    }),
  setTextureList: (
    textureList: Texture[]
  ): ActionWithPayload<'SET_TEXTURE_LIST', { textureList: Texture[] }> =>
    CreateAction(DatasetAugmentationActionType.SET_TEXTURE_LIST, {
      textureList,
    }),
  setPlacementData: (
    placementData?: UploadedPlacementData
  ): ActionWithPayload<
    'SET_UPLOADED_PLACEMENT_DATA',
    { placementData?: UploadedPlacementData }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_UPLOADED_PLACEMENT_DATA, {
      placementData,
    }),
  clearTextureList: (): Action<'CLEAR_TEXTURE_LIST'> =>
    CreateAction(DatasetAugmentationActionType.CLEAR_TEXTURE_LIST),
  setTextureListDisplayCondition: (
    textureListDisplayCondition?: TextureListDisplayCondition
  ): ActionWithPayload<
    'SET_TEXTURE_LIST_DISPLAY_CONDITION',
    { textureListDisplayCondition?: TextureListDisplayCondition }
  > =>
    CreateAction(
      DatasetAugmentationActionType.SET_TEXTURE_LIST_DISPLAY_CONDITION,
      {
        textureListDisplayCondition,
      }
    ),
  clearTextureListDisplayCondition:
    (): Action<'CLEAR_TEXTURE_LIST_DISPLAY_CONDITION'> =>
      CreateAction(
        DatasetAugmentationActionType.CLEAR_TEXTURE_LIST_DISPLAY_CONDITION
      ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_AUGMENTATION',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      DatasetAugmentationActionType.SET_TOAST_INFO_FOR_AUGMENTATION,
      {
        toastInfo,
      }
    ),
  setDatasetAugmentationState: (
    datasetAugmentationStateKind: DatasetAugmentationStateKind
  ): ActionWithPayload<
    'SET_DATASET_AUGMENTATION_STATE',
    { datasetAugmentationStateKind: DatasetAugmentationStateKind }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_DATASET_AUGMENTATION_STATE, {
      datasetAugmentationStateKind,
    }),
  setBackgrounds: (
    backgrounds: ScenePartialData[]
  ): ActionWithPayload<
    'SET_BACKGROUNDS',
    { backgrounds: ScenePartialData[] }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_BACKGROUNDS, {
      backgrounds,
    }),
  setSelectedBackgrounds: (
    selectedBackgrounds: ScenePartialData[]
  ): ActionWithPayload<
    'SET_SELECTED_BACKGROUNDS',
    { selectedBackgrounds: ScenePartialData[] }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_SELECTED_BACKGROUNDS, {
      selectedBackgrounds,
    }),
  setLightGroups: (
    lightGroups: ScenePartialData[]
  ): ActionWithPayload<
    'SET_LIGHT_GROUPS',
    { lightGroups: ScenePartialData[] }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_LIGHT_GROUPS, {
      lightGroups,
    }),
  setSelectedLightGroups: (
    selectedLightGroups: ScenePartialData[]
  ): ActionWithPayload<
    'SET_SELECTED_LIGHT_GROUPS',
    { selectedLightGroups: ScenePartialData[] }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_SELECTED_LIGHT_GROUPS, {
      selectedLightGroups,
    }),
  setCameras: (
    cameras: ScenePartialData[]
  ): ActionWithPayload<'SET_CAMERAS', { cameras: ScenePartialData[] }> =>
    CreateAction(DatasetAugmentationActionType.SET_CAMERAS, {
      cameras,
    }),
  setSelectedCameras: (
    selectedCameras: ScenePartialData[]
  ): ActionWithPayload<
    'SET_SELECTED_CAMERAS',
    { selectedCameras: ScenePartialData[] }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_SELECTED_CAMERAS, {
      selectedCameras,
    }),
  setPresetList: (
    presetList: Preset[]
  ): ActionWithPayload<'SET_PRESET_LIST', { presetList: Preset[] }> =>
    CreateAction(DatasetAugmentationActionType.SET_PRESET_LIST, {
      presetList,
    }),
  setSelectedPreset: (
    preset?: Preset
  ): ActionWithPayload<'SET_SELECTED_PRESET', { preset?: Preset }> =>
    CreateAction(DatasetAugmentationActionType.SET_SELECTED_PRESET, {
      preset,
    }),
  clearPresetList: (): Action<'CLEAR_PRESET_LIST'> =>
    CreateAction(DatasetAugmentationActionType.CLEAR_PRESET_LIST),
  setPresetListDisplayCondition: (
    presetListDisplayCondition?: PresetListDisplayCondition
  ): ActionWithPayload<
    'SET_PRESET_LIST_DISPLAY_CONDITION',
    { presetListDisplayCondition?: PresetListDisplayCondition }
  > =>
    CreateAction(
      DatasetAugmentationActionType.SET_PRESET_LIST_DISPLAY_CONDITION,
      {
        presetListDisplayCondition,
      }
    ),
  clearPresetListDisplayCondition:
    (): Action<'CLEAR_PRESET_LIST_DISPLAY_CONDITION'> =>
      CreateAction(
        DatasetAugmentationActionType.CLEAR_PRESET_LIST_DISPLAY_CONDITION
      ),
  setLayouts: (
    layouts: Layout[]
  ): ActionWithPayload<'SET_LAYOUTS', { layouts: Layout[] }> =>
    CreateAction(DatasetAugmentationActionType.SET_LAYOUTS, {
      layouts,
    }),
  setSelectedLayout: (
    layout?: Layout
  ): ActionWithPayload<'SET_SELECTED_LAYOUT', { layout?: Layout }> =>
    CreateAction(DatasetAugmentationActionType.SET_SELECTED_LAYOUT, {
      layout,
    }),
  setLayoutSeed: (
    layoutSeed: number
  ): ActionWithPayload<'SET_LAYOUT_SEED', { layoutSeed: number }> =>
    CreateAction(DatasetAugmentationActionType.SET_LAYOUT_SEED, {
      layoutSeed,
    }),
  setRenderersInput: (
    renderersInput: RenderersInput
  ): ActionWithPayload<
    'SET_RENDERERS_INPUT',
    { renderersInput: RenderersInput }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_RENDERERS_INPUT, {
      renderersInput,
    }),
  setOutputFormat: (
    outputFormat?: OutputFormat
  ): ActionWithPayload<
    'SET_AUGMENTATION_OUTPUT_FORMAT',
    { outputFormat?: OutputFormat }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_AUGMENTATION_OUTPUT_FORMAT, {
      outputFormat,
    }),
  setAugmentationMetaData: (
    augmentationMetaData?: MetaData
  ): ActionWithPayload<
    'SET_AUGMENTATION_META_DATA',
    { augmentationMetaData?: MetaData }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_AUGMENTATION_META_DATA, {
      augmentationMetaData,
    }),
  setGenerateDatasetMetaData: (
    generateDatasetMetaData?: MetaData
  ): ActionWithPayload<
    'SET_GENERATE_DATASET_META_DATA',
    { generateDatasetMetaData?: MetaData }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_GENERATE_DATASET_META_DATA, {
      generateDatasetMetaData,
    }),
  setDatasetAugmentationSubState: (
    datasetAugmentationSubState: DatasetAugmentationSubState
  ): ActionWithPayload<
    'SET_DATASET_AUGMENTATION_SUB_STATE',
    { datasetAugmentationSubState: DatasetAugmentationSubState }
  > =>
    CreateAction(
      DatasetAugmentationActionType.SET_DATASET_AUGMENTATION_SUB_STATE,
      {
        datasetAugmentationSubState,
      }
    ),
  clearCurrentDatasetAugmentationState:
    (): Action<'CLEAR_CURRENT_AUGMENTATION_STATE'> =>
      CreateAction(
        DatasetAugmentationActionType.CLEAR_CURRENT_AUGMENTATION_STATE
      ),
  setAugSiObjectRotation: (
    augSiObjectRotation: Rotation
  ): ActionWithPayload<
    'SET_AUG_SI_OBJECT_ROTATION',
    { augSiObjectRotation: Rotation }
  > =>
    CreateAction(DatasetAugmentationActionType.SET_AUG_SI_OBJECT_ROTATION, {
      augSiObjectRotation,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_AUGMENTATION',
    { inProgress: boolean }
  > =>
    CreateAction(
      DatasetAugmentationActionType.SET_IN_PROGRESS_FOR_AUGMENTATION,
      {
        inProgress,
      }
    ),
  setExecutedInfo: (
    executedInfo?: ExecutionInfo
  ): ActionWithPayload<
    'SET_EXECUTED_INFO_FOR_AUGMENTATION',
    { executedInfo?: ExecutionInfo }
  > =>
    CreateAction(
      DatasetAugmentationActionType.SET_EXECUTED_INFO_FOR_AUGMENTATION,
      {
        executedInfo,
      }
    ),
}
