import { AnnotationSetKindAll } from 'state/app/domainData/types'
import { Timestamp } from 'state/firebase'
import { HttpsCallableResult } from 'firebase/functions'

export const DatasetListActionType = {
  GET_DATASET_LIST: 'GET_DATASET_LIST',
  SET_DATASET_LIST: 'SET_DATASET_LIST',
  CLEAR_DATASET_LIST: 'CLEAR_DATASET_LIST',
  SET_DATASET_LIST_DISPLAY_CONDITION: 'SET_DATASET_LIST_DISPLAY_CONDITION',
  SET_IN_PROGRESS_FOR_DATASET_LIST: 'SET_IN_PROGRESS_FOR_DATASET_LIST',
  SET_THUMBNAILS_FOR_DATASET_LIST: 'SET_THUMBNAILS_FOR_DATASET_LIST',
  CLEAR_DATASET_LIST_STATE: 'CLEAR_DATASET_LIST_STATE',
} as const

export interface AnnotationSetList {
  annotationSetId: string
  annotationSetKind: AnnotationSetKindAll
  groupedDataId: string
  annotationId: string
}

export interface DatasetInfo {
  datasetId: string
  datasetName: string
  datasetRemarks: string
  algorithmId: string
  generatedFor: 'Training' | 'Inference'
  datasetTemplateName: string
  annotationFormatId: string
  annotationFormatKind: string
  annotationFormatVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  annotationSetList: AnnotationSetList[]
  updatedAt: Timestamp
  updatedBy: string
  createdAt?: Timestamp
  createdBy: string
}

export interface DatasetListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  selectedAlgorithmId: string
  totalCount?: number
  createdAt: {
    from: {
      date: string
      time: string
      enabled: boolean
    }
    to: {
      date: string
      time: string
      enabled: boolean
    }
  }
  generatedFor: 'Training' | 'Inference'
  datasetTemplateId?: string
  createdBy?: string
}

export interface DatasetListAppState {
  inProgress: boolean
}

export interface DatasetListDomainData {
  currentDatasetList: DatasetInfo[]
  thumbnails: { [key: string]: string }
  datasetListDisplayCondition: DatasetListDisplayCondition
}

export interface DatasetInfoList {
  domainData: DatasetListDomainData
  appState: DatasetListAppState
}

export interface AnnotationSet {
  'annotation-set-id': string
  'annotation-set-kind': string
  'grouped-data-id': string
  'annotation-id': string
  conditions?: {
    'train-kind'?: string
  }
}

export type DatasetListDisplayMode = 'listMode' | 'tileMode'

interface GetSignedUrlsResult extends HttpsCallableResult {
  readonly data: {
    [id: string]: string
  }
}

export type GetSignedUrlsFunctionType = (args: {
  fileInfoList: { id: string; fileName: string }[]
}) => Promise<GetSignedUrlsResult>
