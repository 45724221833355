import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils'

import { AppStateActionType, Router } from './types'

export type AppStateActions = ActionsUnion<typeof appStateActions>

export const appStateActions = {
  setAuthed: (
    data: boolean
  ): ActionWithPayload<'SET_AUTHED', { data: boolean }> =>
    CreateAction(AppStateActionType.SET_AUTHED, { data }),
  setRouter: (
    data: Router
  ): ActionWithPayload<'SET_ROUTER', { data: Router }> =>
    CreateAction(AppStateActionType.SET_ROUTER, { data }),
  clearAppState: (): Action<'CLEAR_APP_STATE'> =>
    CreateAction(AppStateActionType.CLEAR_APP_STATE),
}
