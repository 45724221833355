import { Timestamp } from 'state/firebase'

export const ScenePresetListActionType = {
  CLEAR_SCENE_PRESET_LIST: 'CLEAR_SCENE_PRESET_LIST',
  SET_AUG_PARAM_TEMPLATE_IDS_FOR_SCENE_PRESET_LIST:
    'SET_AUG_PARAM_TEMPLATE_IDS_FOR_SCENE_PRESET_LIST',
  SET_CURRENT_SCENE_PRESET_LIST_SNAPSHOT:
    'SET_CURRENT_SCENE_PRESET_LIST_SNAPSHOT',
  SET_SCENE_PRESET_LIST: 'SET_SCENE_PRESET_LIST',
  SET_SCENE_PRESET_LIST_DISPLAY_CONDITION:
    'SET_SCENE_PRESET_LIST_DISPLAY_CONDITION',
  SET_BACKGROUNDS_FOR_SCENE_PRESET_LIST:
    'SET_BACKGROUNDS_FOR_SCENE_PRESET_LIST',
  SET_CAMERAS_FOR_SCENE_PRESET_LIST: 'SET_CAMERAS_FOR_SCENE_PRESET_LIST',
  SET_LIGHT_GROUPS_FOR_SCENE_PRESET_LIST:
    'SET_LIGHT_GROUPS_FOR_SCENE_PRESET_LIST',
  SET_SCENE_PRESET_BACKGROUNDS: 'SET_SCENE_PRESET_BACKGROUNDS',
  SET_SCENE_PRESET_CAMERAS: 'SET_SCENE_PRESET_CAMERAS',
  SET_SCENE_PRESET_LIGHT_GROUPS: 'SET_SCENE_PRESET_LIGHT_GROUPS',
  SET_SCENE_PRESET_NAME: 'SET_SCENE_PRESET_NAME',
  SET_SCENE_PRESET_OVERVIEW: 'SET_SCENE_PRESET_OVERVIEW',
  SET_CREATED_SCENE_PRESET_ID: 'SET_CREATED_SCENE_PRESET_ID',

  CLEAR_CREATE_SCENE_PRESET_DIALOG_STATE:
    'CLEAR_CREATE_SCENE_PRESET_DIALOG_STATE',
  CLEAR_SCENE_PRESET_LIST_STATE: 'CLEAR_SCENE_PRESET_LIST_STATE',
  CLEAR_SCENE_PRESET_STATE: 'CLEAR_SCENE_PRESET_STATE',
  CLEAR_SCENE_SETTING_SUB_STATE: 'CLEAR_SCENE_SETTING_SUB_STATE',
  CLEAR_METADATA_SUB_STATE: 'CLEAR_METADATA_SUB_STATE',
  CLEAR_EXECUTE_SUB_STATE: 'CLEAR_EXECUTE_SUB_STATE',

  SET_IN_PROGRESS_FOR_SCENE_PRESET_LIST:
    'SET_IN_PROGRESS_FOR_SCENE_PRESET_LIST',
  SET_SCENE_PRESET_STATE: 'SET_SCENE_PRESET_STATE',
  SET_SCENE_SETTING_SUB_STATE_FOR_SCENE_PRESET_LIST:
    'SET_SCENE_SETTING_SUB_STATE_FOR_SCENE_PRESET_LIST',
  SET_METADATA_SUB_STATE_FOR_SCENE_PRESET_LIST:
    'SET_METADATA_SUB_STATE_FOR_SCENE_PRESET_LIST',
  SET_EXECUTE_SUB_STATE_FOR_SCENE_PRESET_LIST:
    'SET_EXECUTE_SUB_STATE_FOR_SCENE_PRESET_LIST',
} as const
export interface ScenePresetInfo {
  augParamTemplateId: string
  backgroundCount: number
  cameraCount: number
  lightGroupCount: number
  name: string
  overview: string
  createdAt: Timestamp
}

export interface ScenePresetListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface ThreeDimensionalData {
  id: string
  name: string
  userGroupId: string
}

export interface ScenePresetCreationDomainData {
  createdScenePresetId: string
  backgrounds: ThreeDimensionalData[]
  selectedBackgrounds: ThreeDimensionalData[]
  lightGroups: ThreeDimensionalData[]
  selectedLightGroups: ThreeDimensionalData[]
  cameras: ThreeDimensionalData[]
  selectedCameras: ThreeDimensionalData[]
  name: string
  overview: string
}
export interface ScenePresetListDomainData {
  augParamTemplateIdList: string[]
  scenePresetCreationDialogData: ScenePresetCreationDomainData
  currentScenePresetList: ScenePresetInfo[]
  currentScenePresetListSnapshot?: () => void
  scenePresetListDisplayCondition: ScenePresetListDisplayCondition
}

export type InputRequireType = 'EmptyRequired' | 'InputRequired'

export type ExecuteSubState =
  | 'BeforeExecute'
  | 'ExecuteInProgress'
  | 'Executed'
  | 'ExecuteError'

export type ScenePresetState =
  | 'SceneSettingState'
  | 'MetadataState'
  | 'ExecuteState'

export interface ScenePresetSubState {
  sceneSettingSubState: InputRequireType
  metadataSubState: InputRequireType
  executeSubState: ExecuteSubState
}
export interface ScenePresetListAppState {
  scenePresetState: ScenePresetState
  scenePresetSubState: ScenePresetSubState
  inProgress: boolean
}

export interface ScenePresetListState {
  domainData: ScenePresetListDomainData
  appState: ScenePresetListAppState
}

export interface CreateAugParamTemplateRequestArgsType {
  '3dScene': {
    backgrounds: {
      aug3dSceneBackgroundId: string
      userGroupId: string
    }[]
    cameras: {
      aug3dSceneCameraId: string
      userGroupId: string
    }[]
    lightGroups: {
      aug3dSceneLightGroupId: string
      userGroupId: string
    }[]
  }
  metadata: {
    name: string
    overview: string
  }
  userGroupId: string
}
