import { Dispatch } from 'redux'
import { State } from 'state/store'
import { featureDataUploadActions } from './'
import { getFeatureDataGroupQueriesCollection } from 'state/firebase'

import {
  FeatureDataUploadStateKindArray,
  MetaData,
  FeatureDataUploadParamsType,
  FileAndProgress,
  FeatureDataGroup,
  FeatureDataList,
  TrainingAlgorithmVersion,
  FeatureDataGroupDocument,
} from './types'
import {
  AlgorithmStructureVersion,
  TrainingAlgorithmVersion as TrainingAlgorithmVersionForDomain,
} from 'state/app/domainData'
import { FeatureDataUploadApi } from './apis'

import { formatDateTimeSec } from 'views/components/utils/date'
import { isUndefined } from 'utils/typeguard'
import { convertByteToMatchUnit } from 'views/containers/utils'
import { getDocs, query, where } from 'firebase/firestore'
import { AxiosProgressEvent } from 'axios'
import { domainDataOperations } from 'state/app/domainData/operations'

export const featureDataUploadOperations = {
  getFeatureDataGroupList:
    (isSharedUserGroup: boolean, selectedFeatureDataGroupId?: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // 特徴量データグループの一覧を取得
      try {
        dispatch(featureDataUploadActions.setInProgress(true))

        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const algorithmId =
          getState().pages.featureDataUploadState.domainData
            .selectedTrainingAlgorithm.algorithmId
        // 特徴量データグループ一覧取得
        let allFeatureDataGroupList = undefined
        if (selectedFeatureDataGroupId) {
          allFeatureDataGroupList = isSharedUserGroup
            ? await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where(
                    'feature-data-group-id',
                    '==',
                    selectedFeatureDataGroupId
                  ),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            : await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where(
                    'feature-data-group-id',
                    '==',
                    selectedFeatureDataGroupId
                  )
                )
              )

          if (
            allFeatureDataGroupList.docs.length > 0 &&
            allFeatureDataGroupList.docs[0].data()
          ) {
            const featureDataGroupData = allFeatureDataGroupList.docs[0].data()
            dispatch(
              featureDataUploadActions.setSelectedAlgorithmId(
                featureDataGroupData['algorithm-id']
              )
            )
          }
        } else {
          allFeatureDataGroupList = isSharedUserGroup
            ? await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where('algorithm-id', '==', algorithmId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            : await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where('algorithm-id', '==', algorithmId)
                )
              )
        }
        const allFeatureDataGroupListConvert = allFeatureDataGroupList.docs.map(
          (item) => {
            return {
              id: item.id,
              ...item.data(),
            } as FeatureDataGroupDocument
          }
        )

        // 作成する特徴量データグループ配列
        const featureDataGroups: FeatureDataGroup[] = []
        allFeatureDataGroupListConvert.forEach((item) => {
          // バージョンが一致するfeatureDataを取得
          const featureData = item['feature-data-list'].find(
            (data: FeatureDataList) =>
              data['feature-data-group-version']['display-name'] ===
              item['feature-data-group-version-latest']['display-name']
          )
          // 特徴量データグループのオブジェクトを作成
          const featureDataGroup: FeatureDataGroup = {
            featureDataGroupId: item['feature-data-group-id'],
            featureDataGroupName: item['feature-data-group-name']
              ? item['feature-data-group-name']
              : '',
            latestVersion:
              item['feature-data-group-version-latest'] &&
              `${item['feature-data-group-version-latest']['major']}.${item['feature-data-group-version-latest']['minor']}.${item['feature-data-group-version-latest']['patch']}`,
            featureDataCount: item['feature-data-count'],
            latestFeatureDataName: featureData
              ? featureData['feature-data-name']
              : '',
            updatedAt: item['updated-at'],
            createdAt: item['created-at'],
            createdUserId: item['created-by'],
            isSharedUserGroup: item['access-control']
              ? item['access-control']['is-shared'] === true
              : false,
          }
          featureDataGroups.push(featureDataGroup)
        })

        dispatch(
          featureDataUploadActions.setFeatureDataGroupList(featureDataGroups)
        )
        dispatch(
          featureDataUploadActions.clearFeatureDataGroupDisplayCondition()
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataUploadActions.setInProgress(false))
      }
    },
  setSelectedFeatureDataGroup:
    (featureDataGroup?: FeatureDataGroup) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        featureDataUploadActions.setSelectedFeatureDataGroup(featureDataGroup)
      )
      dispatch(
        featureDataUploadActions.setSelectedFeatureDataVersion(
          featureDataGroup?.latestVersion
        )
      )

      if (featureDataGroup) {
        dispatch(
          featureDataUploadActions.setFeatureDataGroupSubState('Selected')
        )
      } else {
        dispatch(
          featureDataUploadActions.setFeatureDataGroupSubState('Unselected')
        )
      }
    },
  /** ファイルを選択して入力する */
  setInputFiles:
    (files: File[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // storeに保持しているファイル
      const currentImages = [
        ...getState().pages.featureDataUploadState.domainData.inputFiles,
      ]
      files.forEach((newFile) => {
        const index = currentImages.findIndex(
          (currentFile) =>
            currentFile.file.name.split('.').pop() ===
            newFile.name.split('.').pop()
        )
        const addFile: FileAndProgress = {
          file: newFile,
          progress: 0,
          uploadStatus: 'beforeUpload',
        }
        if (index < 0) {
          currentImages.push(addFile)
        } else {
          currentImages.splice(index, 1, addFile)
        }
      })
      dispatch(featureDataUploadActions.addInputFiles(currentImages))
    },
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataUploadActions.setFeatureDataUploadState(
          FeatureDataUploadStateKindArray[currentStep + 1]
        )
      )

      // メタデータの入力ステップ時に特徴量データの表示名のデフォルト値を設定
      if (
        FeatureDataUploadStateKindArray[currentStep + 1] === 'MetaDataState'
      ) {
        const files =
          getState().pages.featureDataUploadState.domainData.inputFiles
        let fileInfo = ''
        for (const item of files) {
          fileInfo =
            fileInfo +
            `- ファイル: ${
              item.file.name
            }\n - ファイルサイズ: ${convertByteToMatchUnit(
              item.file.size
            )}\n - ファイル形式: ${
              item.file.type ? item.file.type : item.file.name.split('.').pop()
            }\n\n`
        }
        const regex = /\/|\s+|:/g
        dispatch(
          featureDataUploadActions.setFeatureDataMetaData({
            name: `特徴量データ_${formatDateTimeSec(new Date()).replace(
              regex,
              ''
            )}`,
            remarks: fileInfo,
          })
        )

        if (
          getState().pages.featureDataUploadState.domainData.featureDataMetaData
            ?.name
        ) {
          dispatch(
            featureDataUploadActions.setMetaDataSubState('InputRequired')
          )
        }
      }
    },
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 0:
          return
        case 1:
          dispatch(featureDataUploadActions.addInputFiles([]))
          dispatch(
            featureDataUploadActions.setFeatureDataUploadSubState('Unselected')
          )
          break
        case 2:
          dispatch(featureDataUploadActions.setFeatureDataMetaData(undefined))
          dispatch(
            featureDataUploadActions.setMetaDataSubState('EmptyRequired')
          )
          break
        default:
          break
      }

      dispatch(
        featureDataUploadActions.setFeatureDataUploadState(
          FeatureDataUploadStateKindArray[currentStep - 1]
        )
      )
    },
  setSelectedAlgorithmId:
    (algorithmId: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(featureDataUploadActions.setSelectedAlgorithmId(algorithmId))

      if (algorithmId) {
        dispatch(
          featureDataUploadActions.setTrainingAlgorithmSubState('Selected')
        )
      } else {
        dispatch(
          featureDataUploadActions.setTrainingAlgorithmSubState('Unselected')
        )
      }
    },
  setSelectedTrainingAlgorithmVersion:
    (algorithm?: TrainingAlgorithmVersionForDomain) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      if (!isUndefined(algorithm)) {
        const convertAlgorithm: TrainingAlgorithmVersion = {
          trainingAlgorithmVersion: algorithm.trainingAlgorithmVersion,
          algorithmVersion: {
            displayName: algorithm.algorithmVersion.displayName,
            major: algorithm.algorithmVersion.major,
            minor: algorithm.algorithmVersion.minor,
            patch: algorithm.algorithmVersion.patch,
            preRelease: algorithm.algorithmVersion.preRelease,
          },
          metadata: {
            remarks: algorithm.metadata.remarks.ja,
          },
          releasedAt: algorithm.releasedAt,
          algorithmStructures: algorithm.algorithmStructures,
        }
        dispatch(
          featureDataUploadActions.setSelectedTrainingAlgorithmVersion(
            convertAlgorithm
          )
        )
      } else {
        dispatch(
          featureDataUploadActions.setSelectedTrainingAlgorithmVersion(
            undefined
          )
        )
      }

      if (
        algorithm &&
        getState().pages.featureDataUploadState.domainData
          .selectedTrainingAlgorithm.algorithmId &&
        getState().pages.featureDataUploadState.domainData
          .selectedAlgorithmStructureVersion
      ) {
        dispatch(
          featureDataUploadActions.setTrainingAlgorithmSubState('Selected')
        )
      } else {
        dispatch(
          featureDataUploadActions.setTrainingAlgorithmSubState('Unselected')
        )
      }
    },
  setSelectedTrainingAlgorithmStructureVersion:
    (algorithmStructure?: AlgorithmStructureVersion) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataUploadActions.setSelectedTrainingAlgorithmStructureVersion(
          algorithmStructure
        )
      )

      if (
        algorithmStructure &&
        getState().pages.featureDataUploadState.domainData
          .selectedTrainingAlgorithm.algorithmId &&
        getState().pages.featureDataUploadState.domainData
          .selectedTrainingAlgorithmVersion
      ) {
        dispatch(
          featureDataUploadActions.setTrainingAlgorithmSubState('Selected')
        )
      } else {
        dispatch(
          featureDataUploadActions.setTrainingAlgorithmSubState('Unselected')
        )
      }
    },
  setFeatureDataMetaData:
    (featureDataMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataUploadActions.setFeatureDataMetaData(featureDataMetaData)
      )

      if (
        featureDataMetaData &&
        getState().pages.featureDataUploadState.domainData.featureDataMetaData
          ?.name
      ) {
        dispatch(featureDataUploadActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(featureDataUploadActions.setMetaDataSubState('EmptyRequired'))
      }
    },
  executeFeatureDataUpload:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(featureDataUploadActions.setInProgress(true))
      try {
        const {
          selectedTrainingAlgorithm,
          selectedTrainingAlgorithmVersion,
          inputFiles,
          featureDataMetaData,
          selectedFeatureDataGroup,
          selectedVersion,
          selectedAlgorithmStructureVersion,
        } = getState().pages.featureDataUploadState.domainData

        if (
          !(
            selectedAlgorithmStructureVersion &&
            selectedTrainingAlgorithm &&
            selectedTrainingAlgorithmVersion?.algorithmVersion &&
            featureDataMetaData?.name &&
            inputFiles.length > 0 &&
            selectedFeatureDataGroup?.featureDataGroupId &&
            selectedVersion
          )
        ) {
          console.error('Error invalid request')
          dispatch(featureDataUploadActions.executeFeatureDataUploadFailure())
          return
        }

        const featureData: FeatureDataUploadParamsType = {
          trainingAlgorithmId: selectedTrainingAlgorithm.algorithmId,
          trainingAlgorithmVersion:
            selectedTrainingAlgorithmVersion.algorithmVersion,
          metadata: {
            name: featureDataMetaData.name,
            remarks: featureDataMetaData.remarks,
          },
          featureDataGroupId: selectedFeatureDataGroup?.featureDataGroupId,
          featureDataVersion: selectedVersion,
          algorithmStructureId:
            selectedAlgorithmStructureVersion.algorithmStructureId,
          algorithmStructureVersion: {
            displayName:
              selectedAlgorithmStructureVersion.algorithmStructureVersion
                .displayName,
            major:
              selectedAlgorithmStructureVersion.algorithmStructureVersion.major,
            minor:
              selectedAlgorithmStructureVersion.algorithmStructureVersion.minor,
            patch:
              selectedAlgorithmStructureVersion.algorithmStructureVersion.patch,
          },
          isSharedUserGroup: !!selectedFeatureDataGroup.isSharedUserGroup,
        }

        let result = undefined
        if (
          isUndefined(
            getState().pages.featureDataUploadState.domainData
              .executedFeatureDataId
          )
        ) {
          result = await FeatureDataUploadApi.executeFeatureDataUpload(
            featureData
          )
        } else {
          result = {
            data: {
              featureDataId:
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getState().pages.featureDataUploadState.domainData
                  .executedFeatureDataId!,
            },
          }
        }

        const files =
          getState().pages.featureDataUploadState.domainData.inputFiles
        const signedUrl = await FeatureDataUploadApi.getSignedUrl(
          result.data.featureDataId,
          files.map((item) => {
            return {
              // pop() の戻り値として undefined が返るユースケースが存在しないため、unwrapする
              // pop() で undefined となるユースケースとしては、対象の配列が空配列の場合のみ
              // split() で空配列が返却されるユースケースとしては、
              // 対象の文字列が空文字かつ、splitの文字が空文字の場合のみ
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              featureDataFormat: item.file.name.split('.').pop()!,
              fileType: item.file.type,
              fileName: item.file.name,
            }
          })
        )
        const url = signedUrl.data
        const fileUpload = inputFiles
          .filter((file) => file.uploadStatus !== 'completed')
          .map(async (fileAndProgress, index) => {
            const config = {
              onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                if (progressEvent.total) {
                  files[index].progress = Math.floor(
                    (progressEvent.loaded / progressEvent.total) * 100
                  )
                } else {
                  files[index].progress = 0
                }
                dispatch(featureDataUploadActions.addInputFiles(files))
              },
            }

            try {
              await FeatureDataUploadApi.uploadFeatureDataFile(
                url[files[index].file.name].url,
                fileAndProgress.file,
                config
              )
              files[index].uploadStatus = 'completed'
            } catch {
              files[index].uploadStatus = 'uploadError'
              files[index].progress = 0
            }
          })

        await Promise.all(fileUpload)

        dispatch(featureDataUploadActions.addInputFiles(files))

        const fileUploadResult =
          getState().pages.featureDataUploadState.domainData.inputFiles

        if (
          fileUploadResult.findIndex(
            (item) => item.uploadStatus !== 'completed'
          ) >= 0
        ) {
          dispatch(featureDataUploadActions.setFileUploadResult('failed'))
        } else {
          dispatch(featureDataUploadActions.setFileUploadResult('success'))
        }

        // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
        const uploadFailedList = getState()
          .pages.featureDataUploadState.domainData.inputFiles.filter(
            (file) => file.uploadStatus !== 'completed'
          )
          .map((file) => file.file.name)
        if (uploadFailedList.length > 0) {
          const displayNameCnt = 3
          const targets = uploadFailedList
            .slice(0, displayNameCnt)
            .concat(
              uploadFailedList.length > displayNameCnt
                ? [`他${uploadFailedList.length - displayNameCnt}件`]
                : []
            )
          dispatch(
            featureDataUploadActions.setToastInfo({
              type: 'error',
              title: 'ファイルアップロードに失敗しました',
              targets,
            })
          )
          dispatch(featureDataUploadActions.executeFeatureDataUploadFailure())
          return
        }

        dispatch(featureDataUploadActions.executeFeatureDataUploadSuccess())
        dispatch(
          featureDataUploadActions.setExecutedFeatureDataId(
            result.data.featureDataId
          )
        )
      } catch (error) {
        console.error(error)
        dispatch(featureDataUploadActions.executeFeatureDataUploadFailure())
      } finally {
        dispatch(featureDataUploadActions.setInProgress(false))
      }
    },
}
