import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import { State } from 'state/store'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { Link as MuiLink } from '@mui/material'

import {
  accountGroupActions,
  AccountGroupCustomer,
  AccountGroupsActions,
  AccountGroupsOperations,
  Customer,
  CustomerListDisplayCondition,
  CustomerListPagingState,
  SuperUser,
  SuperUserListDisplayCondition,
  SuperUserListSortKey,
} from 'state/ducks/accountGroups'

import {
  CustomTrainingPageParagraph,
  GlobalLoading,
  CheckableTableHeader,
  CheckableTable,
  AccountGroupsIcon,
  CHECKABLE_TABLE_HEADER_HEIGHT,
  showToast,
  CHECKBOX_ROW_HEIGHT,
  SelectableTable,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { isUndefined } from 'utils/typeguard'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import { useTheme } from '@mui/material/styles'
import { AccountAuthorizer } from 'views/components/organisms/accountAuthorizer'
import { Timestamp } from 'firebase/firestore'

const mapStateToProps = (state: State) => ({
  ...state.pages.accountGroupsState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData.userProfile,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, AccountGroupsActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** アカウントグループ一覧取得 */
  getAccountGroupList: () =>
    dispatch(AccountGroupsOperations.getAccountGroupList()),
  /** スーパーユーザー一覧取得 */
  getSuperUserList: () => dispatch(AccountGroupsOperations.getSuperUserList()),
  /** カスタマー一覧取得 */
  getCustomerList: () => dispatch(AccountGroupsOperations.getCustomerList()),
  /** MFA設定取得 */
  getMfaGroupSetting: () =>
    dispatch(AccountGroupsOperations.getMfaGroupSetting()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: CustomerListDisplayCondition) =>
    dispatch(
      accountGroupActions.setCustomerListDisplayCondition(listCondition)
    ),
  /** リストの表示条件の変更 */
  setSuperUserListDisplayCondition: (
    listCondition: SuperUserListDisplayCondition
  ) =>
    dispatch(
      accountGroupActions.setSuperUserListDisplayCondition(listCondition)
    ),
  /** 選択されたアカウントグループ */
  setSelectedAccountGroupId: (accountGroupId: string) =>
    dispatch(accountGroupActions.setSelectedAccountGroupId(accountGroupId)),
  /** アカウントカストマー関係の更新処理 */
  updateAccountGroupCustomerRelations: (
    accountGroupId: string,
    accountGroupCustomers: AccountGroupCustomer[]
  ) => {
    dispatch(
      AccountGroupsOperations.updateAccountGroupCustomerRelations(
        accountGroupId,
        [...accountGroupCustomers]
      )
    )
  },
  /** 次ページの活性非活性の切り替え */
  setAccountGroupsPagingState: (nextPageSubState: CustomerListPagingState) =>
    dispatch(accountGroupActions.setCustomerListPagingState(nextPageSubState)),
  /** Stateのクリア */
  clearAccountGroupsState: () =>
    dispatch(accountGroupActions.clearAccountGroupsState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  contents: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  tabContents: {
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const ASSOCIATE_CUSTOMER_TABLE_HEADERS: CheckableTableHeader[] = [
  {
    id: 'customerId',
    title: 'カスタマーID',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'name',
    title: 'カスタマー名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'userGroupId',
    title: 'ユーザーグループID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const MANAGE_SUPER_USER_TABLE_HEADERS: CheckableTableHeader[] = [
  {
    id: 'email',
    title: 'メールアドレス',
    width: 300,
    sortable: true,
    position: 'left',
  },
  {
    id: 'firstName',
    title: '姓',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'familyName',
    title: '名',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'lastLoginTime',
    title: '最終ログイン',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const AccountGroups: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const [nowTab, setNowTab] = useState(0)
  const [checkedCustomerIds, setCheckedCustomerIds] = useState<string[]>([])

  useEffect(() => {
    props.getAccountGroupList()
    props.getCustomerList()
    return () => {
      props.clearAccountGroupsState()
    }
  }, [])

  useEffect(() => {
    props.getSuperUserList()
    props.getMfaGroupSetting()
  }, [props.domainData.selectedAccountGroupId])

  const {
    pageChange,
    changeSuperUserListPage,
    changeTableSortOrder,
    handleChangeDisplayNumber,
    handleChangeSuperUserListDisplayNumber,
    accountGroupCustomersDisplaySlice,
    tableRows,
    superUserListTableRows,
    superUserListTableLength,
  } = useTableDisplayActions(props)

  const [checkedRows, setCheckedRows] = useState(
    props.domainData.customerList
      ? Array(props.domainData.customerList.length).fill(false)
      : []
  )

  useEffect(() => {
    const accountGroup = props.domainData.accountGroupList.find(
      (accountGroup) =>
        accountGroup.accountGroupId === props.domainData.selectedAccountGroupId
    )
    setCheckedCustomerIds(
      accountGroup
        ? accountGroup.customerList.map(
            (customer: AccountGroupCustomer) => customer.customerId
          )
        : []
    )
  }, [
    props.domainData.accountGroupList,
    props.domainData.selectedAccountGroupId,
  ])

  useEffect(() => {
    setCheckedRows(
      accountGroupCustomersDisplaySlice.map(
        (displayedCustomer: Customer) =>
          checkedCustomerIds.find(
            (customerId) => displayedCustomer.customerId === customerId
          ) !== undefined
      )
    )
  }, [accountGroupCustomersDisplaySlice, checkedCustomerIds])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  /** カスタマーの紐付け更新エラー時Toast */
  useEffect(() => {
    switch (props.appState.accountGroupUpdateCustomerListSubState) {
      case 'UpdateCustomerListError':
        showErrorToast('カスタマーの紐付けの更新に失敗しました。')
        break
      default:
        break
    }
  }, [props.appState.accountGroupUpdateCustomerListSubState])

  useEffect(() => {
    switch (props.appState.superUserListSubState) {
      case 'Failed':
        showErrorToast('スーパーユーザーの取得に失敗しました。')
        break
      default:
        break
    }
  }, [props.appState.superUserListSubState])

  useEffect(() => {
    switch (props.appState.mfaGroupSettingSubState) {
      case 'NotFoundProcessed':
      case 'Failed':
        showErrorToast('MFA設定の取得に失敗しました。')
        break
      default:
        break
    }
  }, [props.appState.mfaGroupSettingSubState])

  /** 検索ワードがある場合日付ソートをさせない */
  const associateCustomerTableHeader = useMemo(() => {
    return ASSOCIATE_CUSTOMER_TABLE_HEADERS.map((header) => {
      return { ...header, sortable: false }
    })
  }, [])

  /** テーブル */
  const accountGroupCustomerTable = useMemo(() => {
    return (
      <CheckableTable
        displayNumber={
          props.domainData.customerListDisplayCondition.displayNumber
        }
        headers={associateCustomerTableHeader}
        rows={tableRows}
        selectedRows={checkedRows}
        totalCount={
          props.domainData.customerList
            ? props.domainData.customerList.length
            : 0
        }
        loading={props.appState.inProgress}
        tableHeight={CHECKABLE_TABLE_HEADER_HEIGHT + 10 * CHECKBOX_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.customerListDisplayCondition.pageNumber}
        displayNoneCheckbox={false}
        onClickCheckbox={(rowIndex: number) =>
          handleAccountGroupCustomerRelationsUpdate(rowIndex)
        }
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.appState.customerListPagingState.nextPageSubState,
    props.domainData.customerListDisplayCondition,
    checkedRows,
  ])

  const superUserTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.superUserListDisplayCondition.displayNumber
        }
        headers={MANAGE_SUPER_USER_TABLE_HEADERS}
        rows={superUserListTableRows}
        totalCount={superUserListTableLength}
        sortOrder={{
          key: props.domainData.superUserListDisplayCondition.sortKey,
          order: props.domainData.superUserListDisplayCondition.sortOrder,
        }}
        loading={props.appState.inProgressForGettingSuperUserList}
        tableHeight={CHECKABLE_TABLE_HEADER_HEIGHT + 10 * CHECKBOX_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.superUserListDisplayCondition.pageNumber}
        displayNoneRadio={true}
        onClickPageChange={(pageNumber: number) =>
          changeSuperUserListPage(pageNumber)
        }
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeSuperUserListDisplayNumber(displayNumber)
        }
        onClickOrderChange={(key: string) =>
          changeTableSortOrder(key as SuperUserListSortKey)
        }
      />
    )
  }, [
    superUserListTableRows,
    props.domainData.superUserListDisplayCondition,
    props.appState.inProgressForGettingSuperUserList,
  ])

  const handleAccountGroupCustomerRelationsUpdate = (rowIndex: number) => {
    const customerIndex =
      props.domainData.customerListDisplayCondition.pageNumber *
        props.domainData.customerListDisplayCondition.displayNumber +
      rowIndex

    const customerId = props.domainData.customerList[customerIndex].customerId
    if (!checkedCustomerIds.includes(customerId)) {
      setCheckedCustomerIds([...checkedCustomerIds, customerId])
    } else {
      setCheckedCustomerIds(
        checkedCustomerIds.filter((id) => id !== customerId)
      )
    }
    const newCheckedRows = [...checkedRows]
    newCheckedRows[rowIndex] = !newCheckedRows[rowIndex]
    setCheckedRows(newCheckedRows)
  }

  const setSelectedAccountGroupId = (accountGroupId: string) => {
    props.setSelectedAccountGroupId(accountGroupId)
    props.setSuperUserListDisplayCondition({
      ...props.domainData.superUserListDisplayCondition,
      pageNumber: 0,
    })
  }

  const tabItems: TabItems[] = [
    // プロフィールコンテンツ
    {
      label: 'カスタマー紐付け',
      displayInfo: (
        <>
          <CustomTrainingPageParagraph>
            <Box maxHeight='500px'>{accountGroupCustomerTable}</Box>
          </CustomTrainingPageParagraph>
          <AccountAuthorizer
            type='ASSOCIATE_CUSTOMER_TO_ACCOUNT_GROUP'
            superUser={props.auth.customClaims.superUser}
            accountGroupRole={props.accountGroupRole ?? ''}
          >
            <CustomTrainingPageParagraph>
              <Box display='flex' mt={2}>
                <Button
                  variant='outlined'
                  style={{
                    backgroundColor: '#D9E5FF',
                    width: '100%',
                  }}
                  onClick={() => {
                    const customerList = checkedCustomerIds.map(
                      (checkedCustomerId) => {
                        return {
                          customerId: checkedCustomerId,
                        }
                      }
                    )
                    props.updateAccountGroupCustomerRelations(
                      props.domainData.selectedAccountGroupId,
                      customerList
                    )
                  }}
                >
                  <Typography color='primary'>更新</Typography>
                </Button>
              </Box>
            </CustomTrainingPageParagraph>
          </AccountAuthorizer>
        </>
      ),
    },
    {
      label: 'スーパーユーザー管理',
      displayInfo: (
        <>
          <CustomTrainingPageParagraph>
            <Box maxHeight='500px'>{superUserTable}</Box>
          </CustomTrainingPageParagraph>
        </>
      ),
    },
    {
      label: 'セキュリティ',
      displayInfo: (
        <>
          <Box mt={2} display='flex' alignItems='center' gap={2}>
            <Box>MFA設定</Box>
            <Box width='5rem'>
              <Select
                disabled
                value={props.domainData.selectedAccountGroupMfaGroupSetting}
                fullWidth
              >
                {[
                  { key: 'required', value: '必須' },
                  { key: 'optional', value: '任意' },
                  { key: 'none', value: TABLE_CELL_NOT_APPLICABLE },
                ].map((language) => (
                  <MenuItem value={language.key} key={language.key}>
                    {language.value}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </>
      ),
    },
  ]
  return (
    <>
      <Box
        style={{
          position: 'sticky',
          top: '64px',
          backgroundColor: '#fafafa',
          zIndex: 10,
        }}
        className={classes.header}
      >
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <AccountGroupsIcon
              className={classes.pageIcon}
              data-testid='accountGroupsTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='account-list-title'>アカウントグループ管理</h2>
            </Typography>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <Box mt={2}>
            <FormControl
              variant='outlined'
              className={classes.algorithmSelectBox}
            >
              <InputLabel id='customTrainingModel'>
                アカウントグループ
              </InputLabel>
              <Select
                value={props.domainData.selectedAccountGroupId}
                onChange={(e: SelectChangeEvent<string>) =>
                  setSelectedAccountGroupId(e.target.value as string)
                }
                label='アカウントグループ'
              >
                {props.domainData.accountGroupList.map((accountGroup) => {
                  return (
                    <MenuItem
                      data-testid={accountGroup.accountGroupId}
                      value={accountGroup.accountGroupId}
                      key={accountGroup.accountGroupId}
                    >
                      {accountGroup.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </CustomTrainingPageParagraph>
        <Box
          style={{
            backgroundColor: '#fafafa',
          }}
        >
          <Tabs
            indicatorColor='primary'
            value={nowTab}
            style={{
              paddingBottom: '16px',
              marginBottom: '1px',
            }}
          >
            {tabItems.map((item, index) => (
              <Tab
                style={{
                  width: `${100 / tabItems.length}%`,
                  maxWidth: '1200px',
                }}
                key={index}
                className={clsx(nowTab === index && classes.nowTab)}
                label={item.label}
                onClick={() => setNowTab(index)}
                data-testid={`change-tab-${index}`}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box className={classes.contents}>
        <Box className={classes.tabContents}>
          <Box>{tabItems[nowTab].displayInfo}</Box>
        </Box>
      </Box>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const useTableDisplayActions = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  const [superUserListTableLength, setSuperUserListTableLength] = useState(0)

  /** 表示対象のベースモデルデータ */
  const accountGroupCustomersDisplaySlice = useMemo(() => {
    if (!isUndefined(props.domainData.customerListDisplayCondition)) {
      /** 表示するベースモデルの整形 */
      return props.domainData.customerList
        ? props.domainData.customerList.slice(
            props.domainData.customerListDisplayCondition.displayNumber *
              props.domainData.customerListDisplayCondition.pageNumber,
            (props.domainData.customerListDisplayCondition.pageNumber + 1) *
              props.domainData.customerListDisplayCondition.displayNumber
          )
        : []
    }
    return props.domainData.customerList ?? []
  }, [
    props.domainData.customerListDisplayCondition,
    props.domainData.customerList,
  ])

  const superUserListDisplaySlice = useMemo(() => {
    if (!isUndefined(props.domainData.superUserListDisplayCondition)) {
      let newSuperUserList = props.domainData.superUserList

      /** ソートキー、ソートオーダーによる並び替え */
      const sortKey = props.domainData.superUserListDisplayCondition.sortKey
      if (sortKey === 'email') {
        newSuperUserList = newSuperUserList.sort((item, item2) => {
          if (item.email < item2.email) {
            return props.domainData.superUserListDisplayCondition.sortOrder ===
              'asc'
              ? -1
              : 1
          }
          if (item.email > item2.email) {
            return props.domainData.superUserListDisplayCondition.sortOrder ===
              'asc'
              ? 1
              : -1
          }
          // names must be equal
          return 0
        })
      } else {
        newSuperUserList = newSuperUserList.sort((item, item2) => {
          const itemA = item[sortKey]
          const itemB = item2[sortKey]
          return (
            ((itemB != null ? itemB : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (itemA != null ? itemA : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.superUserListDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }

      setSuperUserListTableLength(newSuperUserList.length)

      /** 表示するベースモデルの整形 */
      return newSuperUserList.slice(
        props.domainData.superUserListDisplayCondition.displayNumber *
          props.domainData.superUserListDisplayCondition.pageNumber,
        (props.domainData.superUserListDisplayCondition.pageNumber + 1) *
          props.domainData.superUserListDisplayCondition.displayNumber
      )
    }
    return props.domainData.superUserList
  }, [
    props.domainData.superUserListDisplayCondition,
    props.domainData.superUserList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    return accountGroupCustomersDisplaySlice.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'createdAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value) || value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'customerId':
          case 'userGroupId':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          case 'customerName':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value}</Typography>
              </Tooltip>
            )
          default:
            return value ? (
              <Typography>{value}</Typography>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [accountGroupCustomersDisplaySlice])

  const superUserListTableRows = useMemo(() => {
    const convertedList: Omit<SuperUser, 'accountId'>[] =
      superUserListDisplaySlice.map((item) => ({
        email: item.email,
        familyName: item.familyName,
        firstName: item.firstName,
        lastLoginTime: item.lastLoginTime,
        createdAt: item.createdAt,
      }))

    return convertedList.map((data, index) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'lastLoginTime':
          case 'createdAt':
            if (typeof value === 'string') {
              return
            }
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value) || value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return <Typography>{formatDateTimeSec(value.toDate())}</Typography>
          case 'email':
            return (
              <MuiLink
                className={classes.link}
                underline='none'
                onClick={() => {
                  history.push(
                    `accounts/${superUserListDisplaySlice[index].accountId}?account-group-id=${props.domainData.selectedAccountGroupId}`
                  )
                }}
              >
                <Typography>{value}</Typography>
              </MuiLink>
            )
          default:
            return value ? (
              <Typography>{value}</Typography>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [superUserListDisplaySlice, props.domainData.selectedAccountGroupId])

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.customerListDisplayCondition,
      pageNumber:
        props.domainData.customerList.length >
        props.domainData.customerListDisplayCondition.pageNumber * displayNumber
          ? props.domainData.customerListDisplayCondition.pageNumber
          : Math.ceil(props.domainData.customerList.length / displayNumber) - 1,
      displayNumber: displayNumber ?? 10,
    })
  }

  const handleChangeSuperUserListDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setSuperUserListDisplayCondition({
      ...props.domainData.superUserListDisplayCondition,
      pageNumber:
        props.domainData.superUserList.length >
        props.domainData.superUserListDisplayCondition.pageNumber *
          displayNumber
          ? props.domainData.superUserListDisplayCondition.pageNumber
          : Math.ceil(props.domainData.superUserList.length / displayNumber) -
            1,
      displayNumber: displayNumber ?? 10,
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.customerListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  const changeSuperUserListPage = (pageNumber: number) => {
    props.setSuperUserListDisplayCondition({
      ...props.domainData.superUserListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: SuperUserListSortKey) => {
    // ソート時に1ページ目に戻る
    props.setSuperUserListDisplayCondition({
      ...props.domainData.superUserListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.superUserListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })
  }

  return {
    pageChange,
    changeSuperUserListPage,
    changeTableSortOrder,
    handleChangeDisplayNumber,
    handleChangeSuperUserListDisplayNumber,
    accountGroupCustomersDisplaySlice,
    superUserListDisplaySlice,
    tableRows,
    superUserListTableRows,
    superUserListTableLength,
  }
}

export const AccountGroupsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountGroups))
