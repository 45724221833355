export const UserSettingActionType = {
  SET_USER_INFO: 'SET_USER_INFO',
  SET_IN_PROGRESS_FOR_USER_SETTING: 'SET_IN_PROGRESS_FOR_USER_SETTING',
  SET_PASSWORD_UPDATED_STATE_FOR_USER_SETTING:
    'SET_PASSWORD_UPDATED_STATE_FOR_USER_SETTING',
  SET_PASSWORD_UPDATE_ERROR_MESSAGE_FOR_USER_SETTING:
    'SET_PASSWORD_UPDATE_ERROR_MESSAGE_FOR_USER_SETTING',
  SET_TOAST_INFO_FOR_USER_SETTING: 'SET_TOAST_INFO_FOR_USER_SETTING',
  CLEAR_USER_SETTING_STATE: 'CLEAR_USER_SETTING_STATE',
} as const

export type MfaGroupSetting = 'required' | 'optional' | 'none'

export interface UserInfo {
  mail: string
  phoneNumber: string
  firstName: string
  familyName: string
  accountGroupName: string
  language: string
  locate: string
  role: string
  mfaGroupSetting: MfaGroupSetting
  isMfa: boolean
}

export interface UserSettingDomainData {
  userInfo: UserInfo
}

export type PasswordUpdateSubState =
  | 'BeforeUpdate'
  | 'UpdateSuccess'
  | 'UpdateError'
  | 'NowPasswordMissing'

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface UserSettingAppState {
  inProgress: boolean
  passwordUpdateSubState: PasswordUpdateSubState
  passwordUpdateErrorMessage: string
  toastInfo?: ToastInfo
}

export interface UserSettingState {
  domainData: UserSettingDomainData
  appState: UserSettingAppState
}
