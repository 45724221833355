import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  useHistory,
  Link,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import Box from '@mui/material/Box'
import Add from '@mui/icons-material/Add'

import { State } from 'state/store'
import {
  CustomTrainingListAction,
  customTrainingListActions,
  customTrainingListOperations,
  CustomTrainingDisplayCondition,
  CustomTraining,
} from 'state/ducks/customTrainingList'

import { getTrainedModelGroupId } from 'utils/ducks/trainedModelGroup'
import { isUndefined } from 'utils/typeguard'
import {
  TrainingIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  StatusProgressBar,
  RunningTimeLabel,
  GlobalLoading,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
  BreadcrumbsComponent,
  AlgorithmSelect,
} from 'views/components'
import {
  convertProgressWord,
  convertProgressColor,
} from 'views/containers/utils'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
} from 'views/components/utils/date'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { Link as MuiLink } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const mapStateToProps = (state: State) => ({
  ...state.pages.customTrainingListState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, CustomTrainingListAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カスタム学習一覧取得 */
  getCustomTrainingList: () =>
    dispatch(customTrainingListOperations.getCustomTrainingList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: CustomTrainingDisplayCondition) =>
    dispatch(customTrainingListActions.setListDisplayCondition(listCondition)),
  /** Stateのクリア */
  clearCustomTrainingListState: () =>
    dispatch(customTrainingListActions.clearCustomTrainingListState()),
  /** snapshotの購読解除 */
  unsubscribe: () => dispatch(customTrainingListOperations.unsubscribe()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'mlPipelineId',
    title: 'ML Pipeline ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: 'カスタム学習名',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'type',
    title: '種別',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'progress',
    title: '進捗',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'time',
    title: '実行時間',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ml-pipeline.started-at',
    title: '開始日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'ended-at',
    title: '終了日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'dataset',
    title: 'データセット',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'generateModel',
    title: '生成モデル',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'executionUser',
    title: '実行ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const CustomTrainingList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  /** モデルグループIDを取得 */
  const getTrainedModelGroupIdPath = async (
    userGroupId: string,
    modelId: string
  ) => {
    const modelGroupId = await getTrainedModelGroupId(userGroupId, modelId)
    return modelGroupId
  }

  useEffect(() => {
    return () => {
      props.unsubscribe()
    }
  }, [])

  useEffect(() => {
    props.getCustomTrainingList()
    return () => {
      props.clearCustomTrainingListState()
    }
  }, [props.authedUser.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
  } = useTable(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.customTrainingDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.customTrainingDisplayCondition.searchValue])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: CustomTraining[] =
      props.domainData.currentCustomTrainingList.map(
        (customTraining: CustomTraining) => {
          return {
            mlPipelineId: customTraining.mlPipelineId,
            mlPipelineName: customTraining.mlPipelineName,
            algorithmName: customTraining.algorithmName,
            progress: customTraining.progress,
            time: '',
            startedAt: customTraining.startedAt,
            endedAt: customTraining.endedAt,
            dataset: {
              datasetId: customTraining.dataset.datasetId,
              datasetName: customTraining.dataset.datasetName,
            },
            trainedModel: {
              trainedModelId: customTraining.trainedModel.trainedModelId,
              trainedModelName: customTraining.trainedModel.trainedModelName,
            },
            accountId: customTraining.accountId,
          }
        }
      )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'progress':
            return (
              <StatusProgressBar
                status={convertProgressWord(data.progress.transactionStatus)}
                progressRate={data.progress.progressRate}
                progressColor={convertProgressColor(
                  data.progress.transactionStatus
                )}
              />
            )
          case 'mlPipelineId':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            ) : (
              <TooltipLink
                data-testid={`custom-training-${value}`}
                title={value}
                placement='right-start'
                onClick={() => {
                  history.push(`custom-trainings/${value}`)
                }}
              />
            )
          case 'trainedModel':
            return value.trainedModelName ? (
              <MuiLink
                className={classes.link}
                underline='none'
                onClick={async () => {
                  const trainedModelGroupId = await getTrainedModelGroupIdPath(
                    props.authedUser.auth.customClaims.userGroupId,
                    value.trainedModelId
                  )
                  return history.push(
                    `/model-groups/${trainedModelGroupId}/models/${value.trainedModelId}`
                  )
                }}
                data-testid={`model-detail-${value.trainedModelId}`}
              >
                <Typography key={key}>{value.trainedModelName}</Typography>
              </MuiLink>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'dataset':
            return value.datasetName ? (
              <Typography>{value.datasetName}</Typography>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'algorithmName':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography align='center'>
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              </Box>
            ) : (
              <Tooltip title={value} placement='bottom'>
                <Typography align='center'>{value}</Typography>
              </Tooltip>
            )
          case 'startedAt':
          case 'endedAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            } else if (key === 'endedAt' && value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'time':
            // 成功、失敗かつ終了時刻が存在しないまたは、UnixTime0の場合は、N/Aとする
            if (
              (['Completed', 'Failed'].includes(
                data.progress.transactionStatus
              ) &&
                !data.endedAt) ||
              data.endedAt?.seconds === 0
            ) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return !isUndefined(data.startedAt) &&
              !isUndefined(data.endedAt) ? (
              <Typography key={key}>
                {formatTimeSecByMillSecond(
                  data.endedAt.toDate().getTime() -
                    data.startedAt.toDate().getTime()
                )}
              </Typography>
            ) : (
              <RunningTimeLabel startedAt={data.startedAt.toDate()} />
            )
          case 'mlPipelineName':
            if (value) {
              return (
                <Tooltip title={value} placement='bottom'>
                  <Typography>{value}</Typography>
                </Tooltip>
              )
            } else {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          case 'accountId':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          default:
            return (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [props.domainData.currentCustomTrainingList])

  /** テーブル */
  const customTrainingTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.customTrainingDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.customTrainingDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.customTrainingDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.customTrainingDisplayCondition.sortKey,
          order: props.domainData.customTrainingDisplayCondition.sortOrder,
        }}
        includesStatusProgressBar={true}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.customTrainingDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <Box className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'カスタム学習一覧',
              path: 'custom-trainings',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex'>
            <TrainingIcon
              className={classes.pageIcon}
              data-testid='customTrainingListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='custom-training-list-title'>カスタム学習</h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link data-testid='custom-training-entry' to={`${url}/entry`}>
              <Typography>新規学習開始</Typography>
            </Link>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <AlgorithmSelect
            data-testid='model-group-list-algorithm'
            type='TRAINED_MODEL'
            algorithms={props.algorithms}
            value={props.domainData.customTrainingDisplayCondition.algorithmId}
            onChange={(value?: string) => selectedAlgorithm(value)}
            hasAll={true}
          />
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (ML Pipeline ID)'
                value={
                  props.domainData.customTrainingDisplayCondition.searchValue
                }
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {customTrainingTable}
        </CustomTrainingPageParagraph>
      </Box>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const CustomTrainingListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomTrainingList))

const useTable = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    /** 検索ワードを変更 */
    props.setListDisplayCondition({
      ...props.domainData.customTrainingDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.customTrainingDisplayCondition,
      pageNumber: 0,
      displayNumber: displayNumber,
    })

    props.getCustomTrainingList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.customTrainingDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.customTrainingDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getCustomTrainingList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.customTrainingDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getCustomTrainingList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.setListDisplayCondition({
      ...props.domainData.customTrainingDisplayCondition,
      pageNumber: 0,
    })

    props.getCustomTrainingList()
  }

  /** アルゴリズムの選択 */
  const selectedAlgorithm = (algorithm?: string) => {
    props.setListDisplayCondition({
      ...props.domainData.customTrainingDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      algorithmId: algorithm,
    })
    props.getCustomTrainingList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedAlgorithm,
  }
}
