import React from 'react'
import CameraOutdoorOutlined from '@mui/icons-material/CameraOutdoorOutlined'
import IconButton from '@mui/material/IconButton'

import { ScenePresetIconProps } from './types'

export const ScenePresetIcon: React.FC<ScenePresetIconProps> = (
  props: ScenePresetIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <CameraOutdoorOutlined sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
