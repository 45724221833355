import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import BrokenImage from '@mui/icons-material/BrokenImage'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import IconButton from '@mui/material/IconButton'
import { GroupedDataCardProps } from './types'
import { isUndefined } from 'utils/typeguard'

/** 名前がない時はN/A */
const ANNOTATION_FILENAME_NOT_APPLICABLE = 'N/A'

const useStyles = () =>
  makeStyles()((theme) => ({
    card: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    main: {
      zIndex: 0,
      maxHeight: 120,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignContent: 'stretch',
    },
    thumbnailDiv: {
      height: '100%',
      width: 'auto',
      objectFit: 'contain',
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
    },
    media: {
      height: '88px',
      width: '157px',
      objectFit: 'contain',
    },
    content: {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignContent: 'start',
      gap: theme.spacing(1),
    },
    firstLineContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    annotationFileName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    imageNums: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    imageNum: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    classesNum: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
      gap: theme.spacing(1),
    },
    tagList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      gap: theme.spacing(1),
      '& > div': {
        height: 24,
      },
    },
    flexAndBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }))()

export const GroupedDataCard: React.FC<GroupedDataCardProps> = (
  props: GroupedDataCardProps
) => {
  const { classes } = useStyles()

  /** 画像セットのタイムスタンプを切り替える */
  const getTags = useMemo(() => {
    const tags: string[] = [props.groupedData.annotationSetKind]
    if (props.groupedData.conditions) {
      tags.push(props.groupedData.conditions.trainKind)
    }
    return tags
  }, [props.groupedData.annotationSetKind, props.groupedData.conditions])

  return (
    <Card className={classes.card} data-testid={props['data-testid']}>
      <CardActionArea
        component='div'
        onClick={() =>
          props.onClickCard(
            props.groupedData.groupedDataId,
            props.groupedData.annotationSetId
          )
        }
        className={classes.main}
      >
        <div className={classes.thumbnailDiv}>
          {props.groupedData.thumbnailUrl === '' ? (
            <BrokenImage className={classes.media} />
          ) : (
            <CardMedia
              component='img'
              alt={props.groupedData.groupedDataId}
              image={props.groupedData.thumbnailUrl}
              className={classes.media}
            />
          )}
        </div>
        <CardContent className={classes.content}>
          <div className={classes.flexAndBetween}>
            <div className={classes.firstLineContent}>
              {props.groupedData.annotationFile.fileName ? (
                <Typography
                  component='div'
                  className={classes.annotationFileName}
                >
                  {props.groupedData.annotationFile.fileName}
                </Typography>
              ) : (
                <Typography
                  color='textSecondary'
                  component='div'
                  className={classes.annotationFileName}
                >
                  {ANNOTATION_FILENAME_NOT_APPLICABLE}
                </Typography>
              )}
            </div>
            <Box display='flex'>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation()
                  props.onClickDownLoad(props.groupedData.annotationSetId)
                }}
              >
                <FileDownloadOutlinedIcon />
              </IconButton>
            </Box>
          </div>
          <Box className={classes.tagList}>
            {getTags.map((tag, index) => {
              return <Chip color='primary' label={tag} key={index}></Chip>
            })}
          </Box>
          {(!isUndefined(props.groupedData.trainingImageList) ||
            !isUndefined(props.groupedData.extensions)) && (
            <Typography
              variant='body1'
              component='div'
              className={classes.imageNums}
            >
              {!isUndefined(props.groupedData.trainingImageList) && (
                <>
                  <Box className={classes.imageNum}>
                    <PhotoLibraryIcon
                      fontSize='small'
                      style={{ marginRight: '4px' }}
                    />
                    {`${props.groupedData.trainingImageList.length}枚`}
                  </Box>
                  {!isUndefined(props.groupedData.extensions) &&
                    props.groupedData.extensions.extensions.classes.map(
                      (item) => (
                        <Box
                          className={classes.classesNum}
                          key={item.className}
                        >
                          <Typography>{item.className}</Typography>
                          <Typography>{`${item.imageIdList.length}枚`}</Typography>
                        </Box>
                      )
                    )}
                </>
              )}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export const MemoizedGroupedDataCard = React.memo(GroupedDataCard)
