import { Timestamp } from 'firebase/firestore'
import { DatasetDetailAction } from './actions'
import { DatasetDetailActionType, DatasetDetail } from './types'

const initialState: DatasetDetail = {
  domainData: {
    currentDatasetDetail: {
      algorithm: {
        algorithmId: '',
        metadata: { name: { en: '', ja: '' } },
      },
      annotationFormat: {
        annotationFormatId: '',
        annotationFormatKind: '',
        annotationFormatVersion: {
          displayName: '',
          major: 0,
          minor: 0,
          patch: 0,
        },
      },
      createdAt: Timestamp.fromMillis(0),
      createdBy: '',
      datasetId: '',
      datasetName: '',
      datasetRemarks: '',
      datasetTemplate: {
        datasetTemplateId: '',
        metadata: { name: { en: '', ja: '' } },
      },
      generatedFor: 'Training',
    },
    annotationSetList: [],
  },
  appState: {
    datasetDetailState: {
      annotationFileNameSubState: 'BeforeLoading',
      annotationFileSubState: 'BeforeLoading',
      datasetState: 'BeforeLoading',
      annotationSetListSubState: 'BeforeLoading',
      datasetZipSubState: 'BeforeLoading',
      annotationSetZipDlSubState: 'BeforeLoading',
    },
    isInProgressForGettingDatasetDetail: false,
    isInProgressForGettingAnnotationSet: false,
    toastInfo: undefined,
  },
}

export const DatasetDetailReducer = (
  state: DatasetDetail = initialState,
  action: DatasetDetailAction
): DatasetDetail => {
  switch (action.type) {
    case DatasetDetailActionType.SET_CURRENT_DATASET_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetDetail: action.payload.currentDatasetDetail,
        },
      }
    case DatasetDetailActionType.SET_ANNOTATION_SET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          annotationSetList: action.payload.annotationSet,
        },
      }
    case DatasetDetailActionType.SET_DATASET_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          datasetDetailState: action.payload.datasetDetailState,
        },
      }
    case DatasetDetailActionType.SET_IN_PROGRESS_FOR_DATASET_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingDatasetDetail:
            action.payload.isInProgressForGettingDatasetDetail,
        },
      }
    case DatasetDetailActionType.SET_IN_PROGRESS_FOR_ANNOTATION_SET:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingAnnotationSet:
            action.payload.isInProgressForGettingAnnotationSet,
        },
      }
    case DatasetDetailActionType.CLEAR_DATASET_DETAIL_STATE:
      return {
        ...initialState,
      }
    case DatasetDetailActionType.SET_TOAST_INFO_FOR_DATASET_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case DatasetDetailActionType.SET_SELECTED_GROUPED_DATA_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetDetail: {
            ...state.domainData.currentDatasetDetail,
            selectedGroupedDataId: action.payload.selectedGroupedDataId,
          },
        },
      }

    default:
      return state
  }
}
