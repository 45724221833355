import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  AccountGroup,
  AccountGroupActionType,
  AccountGroupCustomer,
  AccountGroupUpdateCustomerListSubState,
  Customer,
  CustomerListDisplayCondition,
  CustomerListPagingState,
  SuperUser,
  SuperUserListDisplayCondition,
} from './types'
import { ResourceLoadingState } from 'state/utils'

export type AccountGroupsActions = ActionsUnion<typeof accountGroupActions>

export const accountGroupActions = {
  setAccountGroupList: (
    accountGroupList: AccountGroup[]
  ): ActionWithPayload<
    'SET_ACCOUNT_GROUP_LIST',
    { accountGroupList: AccountGroup[] }
  > =>
    CreateAction(AccountGroupActionType.SET_ACCOUNT_GROUP_LIST, {
      accountGroupList,
    }),
  setCustomerList: (
    customerList: Customer[]
  ): ActionWithPayload<'SET_CUSTOMER_LIST', { customerList: Customer[] }> =>
    CreateAction(AccountGroupActionType.SET_CUSTOMER_LIST, {
      customerList,
    }),
  setSuperUserList: (
    superUserList: SuperUser[]
  ): ActionWithPayload<
    'SET_SUPER_USER_LIST_FOR_ACCOUNT_GROUPS',
    { superUserList: SuperUser[] }
  > =>
    CreateAction(
      AccountGroupActionType.SET_SUPER_USER_LIST_FOR_ACCOUNT_GROUPS,
      {
        superUserList,
      }
    ),
  setCustomerListDisplayCondition: (
    customerListDisplayCondition: CustomerListDisplayCondition
  ): ActionWithPayload<
    'SET_CUSTOMER_LIST_DISPLAY_CONDITION',
    { customerListDisplayCondition: CustomerListDisplayCondition }
  > =>
    CreateAction(AccountGroupActionType.SET_CUSTOMER_LIST_DISPLAY_CONDITION, {
      customerListDisplayCondition,
    }),
  setSuperUserListDisplayCondition: (
    superUserListDisplayCondition: SuperUserListDisplayCondition
  ): ActionWithPayload<
    'SET_SUPER_USER_LIST_DISPLAY_CONDITION_FOR_ACCOUNT_GROUPS',
    { superUserListDisplayCondition: SuperUserListDisplayCondition }
  > =>
    CreateAction(
      AccountGroupActionType.SET_SUPER_USER_LIST_DISPLAY_CONDITION_FOR_ACCOUNT_GROUPS,
      {
        superUserListDisplayCondition,
      }
    ),
  setSelectedAccountGroupId: (
    selectedAccountGroupId: string
  ): ActionWithPayload<
    'SET_SELECTED_ACCOUNT_GROUP_ID',
    { selectedAccountGroupId: string }
  > =>
    CreateAction(AccountGroupActionType.SET_SELECTED_ACCOUNT_GROUP_ID, {
      selectedAccountGroupId,
    }),
  setMfaGroupSetting: (
    mfaGroupSetting: string
  ): ActionWithPayload<
    'SET_SELECTED_ACCOUNT_GROUP_MFA_GROUP_SETTING_FOR_ACCOUNT_GROUP',
    { mfaGroupSetting: string }
  > =>
    CreateAction(
      AccountGroupActionType.SET_SELECTED_ACCOUNT_GROUP_MFA_GROUP_SETTING_FOR_ACCOUNT_GROUP,
      {
        mfaGroupSetting,
      }
    ),
  setCustomerListPagingState: (
    customerListPagingState: CustomerListPagingState
  ): ActionWithPayload<
    'SET_CUSTOMER_LIST_PAGING_STATE',
    { customerListPagingState: CustomerListPagingState }
  > =>
    CreateAction(AccountGroupActionType.SET_CUSTOMER_LIST_PAGING_STATE, {
      customerListPagingState: customerListPagingState,
    }),
  updateAccountGroupCustomerRelations: (
    accountGroupId: string,
    customerList: AccountGroupCustomer[]
  ): ActionWithPayload<
    'UPDATE_ACCOUNT_GROUP_CUSTOMER_RELATIONS',
    { accountGroupId: string; customerList: AccountGroupCustomer[] }
  > =>
    CreateAction(
      AccountGroupActionType.UPDATE_ACCOUNT_GROUP_CUSTOMER_RELATIONS,
      {
        accountGroupId,
        customerList,
      }
    ),
  setAccountGroupUpdateCustomerListState: (
    data: AccountGroupUpdateCustomerListSubState
  ): ActionWithPayload<
    'SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE',
    { data: AccountGroupUpdateCustomerListSubState }
  > =>
    CreateAction(
      AccountGroupActionType.SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE,
      {
        data,
      }
    ),
  setSuperUserListSubState: (
    data: ResourceLoadingState
  ): ActionWithPayload<
    'SET_SUPER_USER_LIST_SUB_STATE_FOR_ACCOUNT_GROUP',
    { data: ResourceLoadingState }
  > =>
    CreateAction(
      AccountGroupActionType.SET_SUPER_USER_LIST_SUB_STATE_FOR_ACCOUNT_GROUP,
      {
        data,
      }
    ),
  setMfaGroupSettingSubState: (
    data: ResourceLoadingState
  ): ActionWithPayload<
    'SET_MFA_GROUP_SETTING_SUB_STATE_FOR_ACCOUNT_GROUP',
    { data: ResourceLoadingState }
  > =>
    CreateAction(
      AccountGroupActionType.SET_MFA_GROUP_SETTING_SUB_STATE_FOR_ACCOUNT_GROUP,
      {
        data,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ACCOUNT_GROUP',
    { inProgress: boolean }
  > =>
    CreateAction(AccountGroupActionType.SET_IN_PROGRESS_FOR_ACCOUNT_GROUP, {
      inProgress,
    }),
  setInProgressForGettingSuperUserList: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_SUPER_USER_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      AccountGroupActionType.SET_IN_PROGRESS_FOR_GETTING_SUPER_USER_LIST,
      {
        inProgress,
      }
    ),
  setInProgressForGettingMfaSetting: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_MFA_GROUP_SETTING',
    { inProgress: boolean }
  > =>
    CreateAction(
      AccountGroupActionType.SET_IN_PROGRESS_FOR_GETTING_MFA_GROUP_SETTING,
      {
        inProgress,
      }
    ),
  clearAccountGroupsState: (): Action<'CLEAR_ACCOUNT_GROUP_STATE'> =>
    CreateAction(AccountGroupActionType.CLEAR_ACCOUNT_GROUP_STATE),
  clearDisplayCondition: (): Action<'CLEAR_DISPLAY_CONDITION'> =>
    CreateAction(AccountGroupActionType.CLEAR_DISPLAY_CONDITION),
}
