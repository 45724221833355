import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  AccountFunction,
  AccountGroupRole,
  CustomerFunction,
  CustomerRole,
} from 'views/utils/types'

export interface Item {
  sideMenu: boolean
  menuName: string
  menuIcon?: string
  accountFunction?: AccountFunction
  customerFunction?: CustomerFunction
  exact: boolean
  path: string
  component?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  nestedPaths: {
    exact: boolean
    path: string
    component: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
    accountFunction?: AccountFunction
    customerFunction?: CustomerFunction
  }[]
  ['data-testid']: string
}

export type Section = {
  label: string
  menuIcon?: string
  itemList: Item[]
  accountFunction?: AccountFunction
  customerFunction?: CustomerFunction
}

export type Category = {
  section?: Section
  item?: Item
}

export interface CategoryList {
  label?: string
  categories: Category[]
}

export interface Menu {
  general: CategoryList
  admin: CategoryList
  superUser: CategoryList
}

export type RouteCategoriesKey = keyof Menu

export const ROUTE_CATEGORIES_USER = ['general']
export const ROUTE_CATEGORIES_ADMIN = ['general', 'admin']
export const ROUTE_CATEGORIES_SUPER_USER = ['general', 'admin', 'superUser']

export interface SideMenuProps {
  sideMenuWidth: number
  isOpen: boolean
  menu: Menu
  superUser: boolean
  accountGroupRole: AccountGroupRole
  customerRole: CustomerRole
  onClickOpen: () => void
  onClickClose: () => void
}
