// ActionType
export const PasswordResetActionType = {
  SET_PASSWORD_RESET_MAIL_SEND_SUB_STATE:
    'SET_PASSWORD_RESET_MAIL_SEND_SUB_STATE',
  SET_PASSWORD_RESET_MAIL_SEND_ERROR_MESSAGE:
    'SET_PASSWORD_RESET_MAIL_SEND_ERROR_MESSAGE',
  CLEAR_PASSWORD_RESET_STATE: 'CLEAR_PASSWORD_RESET_STATE',
} as const

export type SendPasswordResetMailSubState =
  | 'BeforeSending'
  | 'SendingSuccess'
  | 'SendingError'

export interface PasswordResetState {
  appState: {
    passwordResetMailSendSubState: SendPasswordResetMailSubState
    passwordResetMailSendErrorMessage: string
  }
}
