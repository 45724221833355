import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'

export const AccountEntryApi = {
  createAccount: (
    accountGroupId: string,
    mailAddress: string,
    firstName: string,
    familyName: string,
    accountRole: string,
    firstPassword: string,
    customerIdList: string[]
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'account-admin-entry'
    )({
      accountGroupId,
      mailAddress,
      firstName,
      familyName,
      accountRole,
      firstPassword,
      customerIdList,
    }),
}
