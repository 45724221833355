import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import Box from '@mui/material/Box'

import { State } from 'state/store'
import { loginOperations, LoginAction, loginActions } from 'state/ducks/login'

import { LoginInput, showToast, Toast } from 'views/components'
import { AuthContext } from 'views/containers/providers'
import { PhoneMultiFactorInfo } from 'firebase/auth'

type Dispatch = ThunkDispatch<State, void, LoginAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  handleSubmit: (email: string, password: string) =>
    dispatch(loginOperations.login(email, password)),
  handleSubmitForMfa: (authenticationCode: string) =>
    dispatch(loginOperations.executeAuthenticationCode(authenticationCode)),
  clearLoginState: () => dispatch(loginActions.clearLoginState()),
  setFailTooManyRequestsState: () =>
    dispatch(loginActions.setFailTooManyRequestsState()),
  clearAuthedUser: () => dispatch(loginOperations.clearAuthedUser()),
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  ...state.app.domainData,
  ...state.app.appState,
  ...state.pages.loginState,
})
type StateProps = ReturnType<typeof mapStateToProps>

type Props = StateProps & DispatchProps & RouteComponentProps

const LoginForm: React.FC<Props> = (props: Props) => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [authenticationCode, setAuthenticationCode] = useState('')

  useEffect(() => {
    switch (props.appState.loginState) {
      case 'LoginFail':
        alert('Login Failed')
        break
      case 'PasswordUpdate':
        history.push('/password-update')
        break
      case 'PasswordReset':
        history.push('/password-reset')
        break
      case 'MfaSetting':
        history.push('/mfa-setting')
        break
      case 'Loggedin':
        if (currentUser != null) {
          history.push('/')
        } else {
          props.clearLoginState()
        }
        break
    }
  }, [props.appState.loginState])

  useEffect(() => {
    if (props.appState.authState === 'AuthFail') {
      alert('Authentication Failed')
    }
  }, [props.appState.authState])

  const inputEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const inputPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const inputAuthenticationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthenticationCode(e.target.value)
  }

  const handleSubmit = () => {
    props.appState.loginState !== 'NeedMfa'
      ? props.handleSubmit(email, password)
      : props.handleSubmitForMfa(authenticationCode)
  }

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  /** ログイン画面へ */
  useEffect(() => {
    if (
      props.appState.loginState === 'MfaFail' ||
      props.appState.loginState === 'TooManyRequests'
    ) {
      showErrorToast('認証に失敗しました。ログイン画面へ戻ります')
      setAuthenticationCode('')
      props.appState.loginState === 'TooManyRequests'
        ? props.setFailTooManyRequestsState()
        : props.clearLoginState()
    }
    if (props.appState.loginState === 'NoCustomerList') {
      showErrorToast('参照可能なカスタマーが存在しません。')
    }
  }, [props.appState.loginState])

  return (
    <Toast containerOptions={{ limit: 20 }}>
      <Box
        height='100vh'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <LoginInput
          loading={props.appState.inProgress}
          email={email}
          password={password}
          onClickLoginButton={handleSubmit}
          emailTextHandleChange={inputEmail}
          passwordTextHandleChange={inputPassword}
          openPasswordResetDialog={() => history.push('/password-reset')}
          isMfa={props.appState.loginState === 'NeedMfa'}
          authenticationCode={authenticationCode}
          phoneNumber={
            props.appState.loginError.resolver
              ? (
                  props.appState.loginError.resolver
                    .hints[0] as PhoneMultiFactorInfo
                ).phoneNumber.substring(1)
              : ''
          }
          authenticationCodeChange={inputAuthenticationCode}
          error={props.appState.errorMessage !== ''}
          errorMessage={props.appState.errorMessage}
        />
      </Box>
    </Toast>
  )
}

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginForm)
