import { Timestamp } from 'state/firebase'
import { ResourceLoadingState } from 'state/utils/types'

export const FeatureDataListActionType = {
  SET_FEATURE_DATA_GROUP_ID: 'SET_FEATURE_DATA_GROUP_ID',
  SET_FEATURE_DATA_GROUP_NAME: 'SET_FEATURE_DATA_GROUP_NAME',
  GET_FEATURE_DATA_LIST: 'GET_FEATURE_DATA_LIST',
  SET_FEATURE_DATA_LIST: 'SET_FEATURE_DATA_LIST',
  CLEAR_FEATURE_DATA_LIST: 'CLEAR_FEATURE_DATA_LIST',
  SET_FEATURE_DATA_LIST_DISPLAY_CONDITION:
    'SET_FEATURE_DATA_LIST_DISPLAY_CONDITION',
  SET_FEATURE_DATA_LIST_PAGING_STATE: 'SET_FEATURE_DATA_LIST_PAGING_STATE',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_LIST',
  SET_FEATURE_DATA_GROUP_SUB_STATE: 'SET_FEATURE_DATA_GROUP_SUB_STATE',
  CLEAR_FEATURE_DATA_LIST_STATE: 'CLEAR_FEATURE_DATA_LIST_STATE',
} as const

export interface FeatureDataGroupVersion {
  displayName: string
  major: number
  minor: number
  patch: number
}

export interface FeatureDataInfo {
  featureDataId: string
  featureDataName: string
  featureDataGroupVersion: FeatureDataGroupVersion
  generatedAt?: Timestamp
  uid: string
}

export interface FeatureDataListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface FeatureDataListDomainData {
  featureDataGroupId: string
  featureDataGroupName: string
  currentFeatureDataList: FeatureDataInfo[]
  featureDataListDisplayCondition: FeatureDataListDisplayCondition
}

export interface FeatureDataListAppState {
  inProgress: boolean
  featureDataGroupSubState: ResourceLoadingState
}

export interface FeatureDataInfoList {
  domainData: FeatureDataListDomainData
  appState: FeatureDataListAppState
}
