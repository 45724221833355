import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import HandymanIcon from '@mui/icons-material/Handyman'

import { State } from 'state/store'
import {
  DatasetDetailAction,
  datasetDetailActions,
  DatasetDetailOperations,
} from 'state/ducks/datasetDetail'

import { isUndefined } from 'utils/typeguard'
import {
  DatasetIcon,
  CopyableLabel,
  DataDetailItem,
  showToast,
  Toast,
  EditableTextField,
  GlobalLoading,
  GroupedDataCardList,
  GroupedDataDetailedViewer,
  BreadcrumbsComponent,
} from 'views/components'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import {
  convertDatasetType,
  handleResourceNotFound,
} from 'views/containers/utils'
import { isDetailPathParams } from 'views/containers/utils/typeguard'
import { useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { formatDateTimeSec } from 'views/components/utils/date'
import { CircularProgress, Link as MuiLink } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const mapStateToProps = (state: State) => ({
  ...state.pages.datasetDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, DatasetDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** データセット詳細取得 */
  getDatasetDetail: (mlPipelineId: string) =>
    dispatch(DatasetDetailOperations.getDatasetDetail(mlPipelineId)),
  /** アノテーション情報取得 */
  getAnnotationList: (datasetId: string) =>
    dispatch(DatasetDetailOperations.getAnnotationList(datasetId)),
  /** アノテーションファイル情報取得 */
  getAnnotationFiles: (datasetId: string) =>
    dispatch(DatasetDetailOperations.getAnnotationFiles(datasetId)),
  /** Stateのクリア */
  clearDatasetDetailState: () =>
    dispatch(datasetDetailActions.clearDatasetDetailState()),
  /** 選択したgroupedDataIdのセット */
  setSelectedGroupedDataId: (groupedDataId?: string) =>
    dispatch(datasetDetailActions.setSelectedGroupedDataId(groupedDataId)),
  /** modelの名前を更新する */
  updateDatasetName: (docId: string, name: string) =>
    dispatch(DatasetDetailOperations.updateDatasetName(docId, name)),
  /** pipelineのremarksを更新する */
  updateDatasetRemarks: (docId: string, remarks: string) =>
    dispatch(DatasetDetailOperations.updateDatasetRemarks(docId, remarks)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(datasetDetailActions.setToastInfo(undefined)),
  downloadAnnotationSet: (annotationSetId: string) =>
    dispatch(
      DatasetDetailOperations.downloadAnnotationZip({ annotationSetId })
    ),
  downloadDataset: (datasetId: string) =>
    dispatch(DatasetDetailOperations.downloadDatasetZip({ datasetId })),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  mt2Box: {
    marginTop: '16px',
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  annotationSetLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  slider: {
    width: '30%',
    '& > .MuiSlider-track': {
      border: 'none',
    },
  },
}))

const AlgorithmSpecificContent: React.FC<Props> = (props: Props) => {
  if (isUndefined(props.domainData.currentDatasetDetail?.extended)) return <></>

  if (
    !isUndefined(
      props.domainData.currentDatasetDetail?.extended.objectClassification
    )
  ) {
    return (
      <Box component={Paper} my={2}>
        <Box p={'24px 32px 32px'}>
          <Typography>
            {props.domainData.currentDatasetDetail.algorithm.metadata.name.ja}
          </Typography>
          <DataDetailItem
            formHelperText='クラスセット'
            endAdornment={
              <CopyableLabel
                value={
                  props.domainData.currentDatasetDetail.extended
                    .objectClassification
                    ? props.domainData.currentDatasetDetail.extended
                        .objectClassification.classSet.classSetId
                    : ''
                }
              />
            }
            startAdornment={
              props.domainData.currentDatasetDetail.extended
                .objectClassification.classSet.classSetName ? (
                <Box sx={{ color: 'text.primary' }}>
                  <Typography>
                    {props.domainData.currentDatasetDetail
                      ? props.domainData.currentDatasetDetail.extended
                          .objectClassification.classSet.classSetName
                      : ''}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ color: 'text.secondary' }}>
                  <Typography>{NO_NAME}</Typography>
                </Box>
              )
            }
          />
        </Box>
      </Box>
    )
  }

  return <></>
}

const DatasetDetail: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  /** 初期実行 */
  useEffect(() => {
    // 表示用詳細取得
    props.getDatasetDetail(
      isDetailPathParams(props.match.params) ? props.match.params.id : ''
    )

    return () => {
      props.clearDatasetDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.domainData.currentDatasetDetail?.datasetId) {
      props.getAnnotationList(props.domainData.currentDatasetDetail?.datasetId)
    }
  }, [props.domainData.currentDatasetDetail?.datasetId])

  useEffect(() => {
    if (
      props.domainData.currentDatasetDetail?.datasetId &&
      props.appState.datasetDetailState.annotationSetListSubState === 'Loaded'
    ) {
      props.getAnnotationFiles(props.domainData.currentDatasetDetail.datasetId)
    }
  }, [
    props.domainData.currentDatasetDetail?.datasetId,
    props.appState.datasetDetailState.annotationSetListSubState,
  ])

  /** モデル詳細備考が変更時にセットする */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.currentDatasetDetail) &&
      !isUndefined(props.domainData.currentDatasetDetail.datasetRemarks)
    ) {
      setEditingDatasetRemarks(
        props.domainData.currentDatasetDetail.datasetRemarks
      )
      setEditingDatasetName(props.domainData.currentDatasetDetail.datasetName)
    }
  }, [props.domainData.currentDatasetDetail])
  /** データセット新規追加ダイアログ表示状態 */
  const [openGroupedDataDialog, setOpenGroupedDataDialog] = useState(false)

  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.datasetDetailState.datasetState,
      history
    )
  }, [props.appState.datasetDetailState.datasetState])

  const [nowTab, setNowTab] = useState(1)

  const [editingDatasetName, setEditingDatasetName] = useState<string>('')
  const [editingDatasetRemarks, setEditingDatasetRemarks] = useState<string>('')

  /** urlから取得したdatasetId */
  const datasetId = isDetailPathParams(props.match.params)
    ? props.match.params.id
    : ''
  if (datasetId === '') {
    console.error('Error Invalid Dataset ID')
    return <></>
  }

  // useCallback
  const onClickMemoizedCard = (
    selectedGroupedDataId: string,
    selectedAnnotationSetId: string
  ) => {
    props.setSelectedGroupedDataId(selectedGroupedDataId)
    history.push(
      `/datasets/${props.domainData.currentDatasetDetail.datasetId}/annotation-sets/${selectedAnnotationSetId}`
    )
  }

  const selectedAnnotationSet = useMemo(() => {
    return props.domainData.annotationSetList.find(
      (annotationSet) =>
        annotationSet.groupedDataId ===
        props.domainData.currentDatasetDetail.selectedGroupedDataId
    )
  }, [
    props.domainData.currentDatasetDetail.selectedGroupedDataId,
    props.domainData.annotationSetList,
  ])

  /** アノテーションデータ取得中のloading */
  const annotationSetLoading = useMemo(() => {
    if (props.appState.isInProgressForGettingAnnotationSet) {
      if (nowTab === 1) {
        return (
          <Box className={classes.annotationSetLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [props.appState.isInProgressForGettingAnnotationSet, nowTab])

  const tabItems: TabItems[] = [
    // 実行情報コンテンツ
    {
      label: '一般情報',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <DataDetailItem
                formHelperText='用途'
                startAdornment={
                  props.domainData.currentDatasetDetail &&
                  props.domainData.currentDatasetDetail.generatedFor ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {convertDatasetType(
                          props.domainData.currentDatasetDetail.generatedFor
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
              <Box mt={1}>
                <DataDetailItem
                  formHelperText='アノテーションフォーマット'
                  startAdornment={
                    props.domainData.currentDatasetDetail &&
                    props.domainData.currentDatasetDetail.annotationFormat &&
                    props.domainData.currentDatasetDetail.annotationFormat
                      .annotationFormatVersion ? (
                      <Box sx={{ color: 'text.primary' }}>
                        <Typography>
                          {`${props.domainData.currentDatasetDetail.annotationFormat.annotationFormatKind} v${props.domainData.currentDatasetDetail.annotationFormat.annotationFormatVersion.displayName}`}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ color: 'text.secondary' }}>
                        <Typography>{NO_NAME}</Typography>
                      </Box>
                    )
                  }
                />
              </Box>
              <Box mt={1}>
                <DataDetailItem
                  formHelperText='データセットテンプレート'
                  endAdornment={
                    <CopyableLabel
                      value={
                        props.domainData.currentDatasetDetail
                          ? props.domainData.currentDatasetDetail
                              ?.datasetTemplate.datasetTemplateId
                          : ''
                      }
                      isTooltip
                      placement='top'
                    />
                  }
                  startAdornment={
                    <Box data-testid={'dataset-template-name'}>
                      {props.domainData.currentDatasetDetail.datasetTemplate
                        .datasetTemplateId ? (
                        <Box sx={{ color: 'text.primary' }}>
                          <Typography>
                            {
                              props.domainData.currentDatasetDetail
                                ?.datasetTemplate.metadata.name.ja
                            }
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ color: 'text.secondary' }}>
                          <Typography>{NO_NAME}</Typography>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <Box mt={1}>
                  <DataDetailItem
                    formHelperText='作成日時'
                    startAdornment={
                      props.domainData.currentDatasetDetail &&
                      props.domainData.currentDatasetDetail.createdAt ? (
                        <Box sx={{ color: 'text.primary' }}>
                          <Typography>
                            {formatDateTimeSec(
                              props.domainData.currentDatasetDetail.createdAt.toDate()
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ color: 'text.secondary' }}>
                          <Typography>{NO_NAME}</Typography>
                        </Box>
                      )
                    }
                  />
                </Box>
                <Box mt={1}>
                  <DataDetailItem
                    formHelperText='作成ユーザー'
                    startAdornment={
                      props.domainData.currentDatasetDetail &&
                      props.domainData.currentDatasetDetail.createdBy ? (
                        <Box sx={{ color: 'text.primary' }}>
                          <Typography>
                            {typeof props.domainData.currentDatasetDetail
                              .createdBy === 'string'
                              ? props.domainData.currentDatasetDetail.createdBy
                              : `${props.domainData.currentDatasetDetail.createdBy.firstName} ${props.domainData.currentDatasetDetail.createdBy.familyName}`}
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ color: 'text.secondary' }}>
                          <Typography>{NO_NAME}</Typography>
                        </Box>
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <AlgorithmSpecificContent {...props} />
        </>
      ),
    },
    // 処理結果コンテンツ
    {
      label: 'データセット',
      displayInfo: (
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <Box display='flex' mb={2} justifyContent='flex-end'>
              <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
              <MuiLink
                data-testid='all-download-custom-model'
                style={{ cursor: 'pointer' }}
                underline='none'
                onClick={() =>
                  props.downloadDataset(
                    props.domainData.currentDatasetDetail.datasetId
                  )
                }
              >
                一括ダウンロード
              </MuiLink>
            </Box>
            {selectedAnnotationSet ? (
              <GroupedDataDetailedViewer
                data={selectedAnnotationSet}
                createdBy={props.domainData.currentDatasetDetail.createdBy}
                open={openGroupedDataDialog}
                data-testid={'grouped-data-detail-viewer'}
                onClickClose={() => {
                  setOpenGroupedDataDialog(false)
                  props.setSelectedGroupedDataId(undefined)
                }}
              />
            ) : (
              <></>
            )}
            <GroupedDataCardList
              groupedDataList={props.domainData.annotationSetList}
              onClickCard={onClickMemoizedCard}
              onClickDownLoad={(id: string) => props.downloadAnnotationSet(id)}
              data-testid={'grouped-data-card-list'}
            />
          </Box>
        </Box>
      ),
    },
    // 備考タブコンテンツ
    {
      label: '備考',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                style={{ width: '100%' }}
                value={editingDatasetRemarks}
                onChange={(event) =>
                  setEditingDatasetRemarks(event.target.value)
                }
                variant='outlined'
                multiline
                minRows={5}
                inputProps={{
                  'data-testid': 'input-remarks',
                }}
              />
              <Box
                display='flex'
                justifyContent='flex-end'
                width='100%'
                className={classes.mt2Box}
              >
                <Button
                  variant='outlined'
                  onClick={() => {
                    if (props.domainData.currentDatasetDetail?.datasetId) {
                      props.updateDatasetRemarks(
                        props.domainData.currentDatasetDetail?.datasetId,
                        editingDatasetRemarks
                      )
                    }
                  }}
                  data-testid='remarks-edit'
                  className={classes.noteTabButton}
                >
                  <Typography color={'primary'}>保存</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ),
    },
  ]

  return (
    <>
      {!isUndefined(props.domainData.currentDatasetDetail) ? (
        <>
          <div className={classes.container}>
            <Toast containerOptions={{ limit: 20 }}>
              <Box
                style={{
                  position: 'sticky',
                  top: '64px',
                  backgroundColor: '#fafafa',
                  zIndex: 10,
                }}
              >
                <Box className={classes.innerContainer}>
                  <Box pt={3}>
                    <BreadcrumbsComponent
                      breadcrumbsPath={[
                        {
                          name: 'データセット一覧',
                          path: 'datasets',
                        },
                        {
                          name:
                            props.domainData.currentDatasetDetail
                              .datasetName !== ''
                              ? `${props.domainData.currentDatasetDetail.datasetName}`
                              : `${props.domainData.currentDatasetDetail.datasetId}`,
                          path: `${props.domainData.currentDatasetDetail.datasetId}`,
                        },
                      ]}
                    />
                  </Box>
                  <div className={classes.flexAndBetween}>
                    <Box
                      display='flex'
                      style={{
                        maxWidth: 'calc(100% - 280px)',
                        overflow: 'hidden',
                      }}
                    >
                      <DatasetIcon
                        className={classes.pageIcon}
                        data-testid='DatasetDetailTitleIcon'
                      />
                      <Box
                        height={76}
                        data-testid='dataset-detail-title'
                        style={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <EditableTextField
                          value={editingDatasetName}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setEditingDatasetName(e.target.value)
                          }
                          onBlur={() => {
                            if (
                              props.domainData.currentDatasetDetail
                                ?.datasetName !== editingDatasetName
                            ) {
                              if (
                                props.domainData.currentDatasetDetail?.datasetId
                              ) {
                                props.updateDatasetName(
                                  props.domainData.currentDatasetDetail
                                    ?.datasetId,
                                  editingDatasetName
                                )
                              }
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box mr={3}>
                        <CopyableLabel
                          value={datasetId}
                          isTooltip
                          placement='top'
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box p={1}>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' alignItems='center'>
                        <HandymanIcon
                          style={{ marginRight: '4px' }}
                          sx={{ color: globalTheme.palette.text.secondary }}
                        />
                        <Box sx={{ color: globalTheme.palette.text.secondary }}>
                          <Typography component='div'>
                            <h4>
                              {`${props.domainData.currentDatasetDetail.algorithm.metadata.name.ja}`}
                            </h4>
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: '#fafafa',
                    }}
                  >
                    <Tabs
                      indicatorColor='primary'
                      value={nowTab}
                      style={{
                        paddingBottom: '16px',
                        marginBottom: '1px',
                      }}
                      onChange={(_, value) => setNowTab(value)}
                    >
                      {tabItems.map((item, index) => (
                        <Tab
                          style={{
                            width: `${100 / tabItems.length}%`,
                            maxWidth: '1200px',
                          }}
                          key={index}
                          className={clsx(nowTab === index && classes.nowTab)}
                          label={item.label}
                          data-testid={`change-tab-${index}`}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
            </Toast>
          </div>
        </>
      ) : (
        <></>
      )}
      {annotationSetLoading}
      <GlobalLoading
        open={props.appState.isInProgressForGettingDatasetDetail}
      />
    </>
  )
}

export const DatasetDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetDetail))
