import { ModelUploadAction } from './actions'
import { ModelUploadActionType, ModelUploadState } from './types'

const initialState: ModelUploadState = {
  domainData: {
    algorithmDisplayCondition: {
      sortKey: 'releasedAt',
      sortOrder: 'desc',
    },
    selectedAlgorithmStructureVersion: undefined,
    selectedTrainingAlgorithm: {
      algorithmId: '',
    },
    selectedTrainingAlgorithmVersion: undefined,
    classSets: [],
    selectedClassSet: undefined,
    classSetDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
    },
    destinationModelGroupDisplayCondition: {
      searchValue: '',
      sortKey: 'updatedAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    baseModelGroupDisplayCondition: {
      searchValue: '',
      sortKey: 'updatedAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
    },
    baseModelDisplayCondition: {
      searchValue: '',
      sortKey: 'updatedAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    destinationTrainedModelGroups: [],
    selectedDestinationTrainedModelGroup: undefined,
    baseModelGroups: {
      userGroup: [],
      sharedUserGroup: [],
    },
    datasetDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    selectedBaseModelGroup: undefined,
    selectedBaseModel: undefined,
    datasetList: [],
    modelMetaData: {
      name: '',
      remarks: '',
    },
    selectedModelKind: undefined,
    executedModelId: undefined,
    uploadTargetTrainedModel: [],
    fileUploadResult: 'beforeUpload',
  },
  appState: {
    modelUploadStateKind: 'AlgorithmState',
    modelUploadSubStateKind: {
      algorithmSubState: 'Unselected',
      modelGroupSubState: 'Unselected',
      baseModelSubState: 'Unselected',
      datasetSubState: 'Unselected',
      modelUploadSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
      classSetSubState: 'Unselected',
    },
    inProgress: false,
    toastInfo: undefined,
  },
}

export const ModelUploadReducer = (
  state: ModelUploadState = initialState,
  action: ModelUploadAction
): ModelUploadState => {
  switch (action.type) {
    case ModelUploadActionType.ADD_IMAGE_SET_FILE_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          uploadTargetTrainedModel: action.payload.files,
        },
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            modelUploadSubState: action.payload.files
              ? 'Selected'
              : 'Unselected',
          },
        },
      }
    case ModelUploadActionType.SET_ALGORITHM_DISPLAY_CONDITION:
      if (action.payload.algorithmDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            algorithmDisplayCondition: action.payload.algorithmDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case ModelUploadActionType.SET_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          destinationTrainedModelGroups: action.payload.modelGroupList,
        },
      }
    case ModelUploadActionType.SET_DATASET_LIST_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetList: action.payload.datasetList,
        },
      }
    case ModelUploadActionType.SET_BASE_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          baseModelGroups: action.payload.trainedModelGroups,
        },
      }
    case ModelUploadActionType.SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDestinationTrainedModelGroup:
            action.payload.selectedDestinationTrainedModelGroup,
        },
      }
    case ModelUploadActionType.SET_SELECTED_BASE_MODEL_GROUP_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedBaseModelGroup: action.payload.selectedTrainedModelGroup,
        },
      }
    case ModelUploadActionType.SET_MODEL_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            modelGroupSubState: action.payload.modelGroupSubState,
          },
        },
      }
    case ModelUploadActionType.CLEAR_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          destinationModelGroupDisplayCondition: {
            ...initialState.domainData.destinationModelGroupDisplayCondition,
          },
        },
      }
    case ModelUploadActionType.CLEAR_BASE_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          baseModelGroupDisplayCondition: {
            ...initialState.domainData.baseModelGroupDisplayCondition,
          },
        },
      }
    case ModelUploadActionType.SET_BASE_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          baseModelGroupDisplayCondition: action.payload.condition,
        },
      }
    case ModelUploadActionType.SET_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          baseModelDisplayCondition:
            action.payload.trainedModelDisplayCondition,
        },
      }
    case ModelUploadActionType.SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          destinationModelGroupDisplayCondition: action.payload.condition,
        },
      }
    case ModelUploadActionType.SET_SELECTED_BASE_MODEL_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedBaseModel: action.payload.selectedBaseModel,
        },
      }
    case ModelUploadActionType.SET_SELECTED_DATASET_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDataset: action.payload.selectedDataset,
        },
      }
    case ModelUploadActionType.SET_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      if (action.payload.datasetDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            datasetDisplayCondition: action.payload.datasetDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case ModelUploadActionType.CLEAR_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetDisplayCondition: {
            ...initialState.domainData.datasetDisplayCondition,
          },
        },
      }
    case ModelUploadActionType.SET_SELECTED_ALGORITHM_ID_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithm: {
            algorithmId: action.payload.algorithmId,
          },
        },
      }
    case ModelUploadActionType.SET_SELECTED_MODEL_VERSION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedVersion: action.payload.selectedVersion,
        },
      }
    case ModelUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithmVersion:
            action.payload.selectedTrainingAlgorithmVersion,
        },
      }
    case ModelUploadActionType.SET_MODEL_KIND:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedModelKind: action.payload.modelKind,
        },
      }
    case ModelUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithmStructureVersion:
            action.payload.selectedTrainingAlgorithmStructureVersion,
        },
      }
    case ModelUploadActionType.SET_MODEL_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelMetaData: action.payload.modelMetaData,
        },
      }
    case ModelUploadActionType.SET_ALGORITHM_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            algorithmSubState: action.payload.trainingAlgorithmSubState,
          },
        },
      }
    case ModelUploadActionType.SET_BASE_MODEL_SUB_STATE_FOR_MODEL_UPLOAD:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            baseModelSubState: action.payload.baseModelSubState,
          },
        },
      }
    case ModelUploadActionType.SET_DATASET_SUB_STATE_FOR_MODEL_UPLOAD:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            datasetSubState: action.payload.datasetSubState,
          },
        },
      }
    case ModelUploadActionType.SET_MODEL_UPLOAD_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            modelUploadSubState: action.payload.modelUploadSubState,
          },
        },
      }
    case ModelUploadActionType.SET_MODEL_META_DATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            metaDataSubState: action.payload.metaDataSubState,
          },
        },
      }
    case ModelUploadActionType.SET_SEND_MODEL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadStateKind: action.payload.modelUploadStateKind,
        },
      }
    case ModelUploadActionType.CLEAR_MODEL_UPLOAD_STATE:
      return {
        ...initialState,
      }
    case ModelUploadActionType.EXEC_MODEL_SEND_SUCCESS:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            executeSubState: 'Executed',
          },
        },
      }
    case ModelUploadActionType.SET_IN_PROGRESS_FOR_MODEL_UPLOAD:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ModelUploadActionType.EXEC_MODEL_SEND_FAILURE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            executeSubState: 'ExecuteError',
          },
        },
      }
    case ModelUploadActionType.SET_EXECUTED_MODEL_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executedModelId: action.payload.executedModelId,
        },
      }
    case ModelUploadActionType.SET_FILE_UPLOAD_RESULT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          fileUploadResult: action.payload.status,
        },
      }
    case ModelUploadActionType.SET_TOAST_INFO_FOR_MODEL_UPLOAD:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case ModelUploadActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetListSnapshot: action.payload.currentDatasetListSnapshot,
        },
      }
    case ModelUploadActionType.SET_CLASS_SET_LIST_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: action.payload.classSets,
        },
      }
    case ModelUploadActionType.CLEAR_CLASS_SET_LIST_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: initialState.domainData.classSets,
        },
      }

    case ModelUploadActionType.SET_SELECTED_CLASS_SET_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedClassSet: action.payload.classSet,
        },
      }
    case ModelUploadActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: {
            ...initialState.domainData.classSetDisplayCondition,
          },
        },
      }
    case ModelUploadActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: action.payload.displayCondition,
        },
      }
    case ModelUploadActionType.SET_CLASS_SET_SUB_STATE_FOR_MODEL_UPLOAD:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelUploadSubStateKind: {
            ...state.appState.modelUploadSubStateKind,
            classSetSubState: action.payload.classSetSubState,
          },
        },
      }
    default:
      return state
  }
}
