import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { FileRejection } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { ClassNameMap } from '@mui/material/styles'

import {
  FileSelectableDropzone,
  MetadataInput,
  AnnotationSetListViewer,
  Toast,
  LabeledProgressBar,
  CloudUploadTooltipIcon,
  SyncTooltipIcon,
  CheckTooltipIcon,
  ClearTooltipIcon,
  ErrorTooltipIcon,
  showToast,
  CommonCompleteDialog,
  ErrorMessage,
  AnnotationSetType,
  GroupedImageDataType,
  CustomTrainingPageParagraph,
  SelectableTable,
  SelectableTableHeader,
  BreadcrumbsComponent,
  CommonStepper,
  SearchInput,
  AccordionLabel,
  GlobalLoading,
} from 'views/components'

import { State } from 'state/store'
import {
  AnnotationSetKindAll,
  AnnotationTrainKind,
  DatasetTemplate,
} from 'state/app/domainData/types'
import {
  AddedAnnotationSetList,
  AlgorithmDisplayCondition,
  ClassSet,
  ClassSetDisplayCondition,
  CreateDatasetAction,
  createDatasetActions,
  createDatasetOperations,
  DatasetState,
  DatasetSubState,
  SelectedAnnotationFormat,
} from 'state/ducks/createDataset'

import { isUndefined } from 'utils/typeguard'
import { compareVersionsWithPreRelease } from 'utils/versions'
import { formatDateTimeSec } from 'views/components/utils/date'
import { isVersionWithPreRelease } from 'views/containers/utils/typeguard'
import { useTheme } from '@mui/material/styles'
import { JSX } from '@babel/types'
import { Timestamp } from '@firebase/firestore'
import { Paper, TextField, Tooltip } from '@mui/material'
import { getUserGroupKindList } from 'views/containers/utils'

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

/** テーブルのヘッダー */
const CLASS_SETS_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'classSetId',
    title: 'クラスセットID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classSetName',
    title: 'クラスセット名',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classList',
    title: 'クラスセット',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'classSetRemarks',
    title: 'Remarks',
    width: 300,
    sortable: false,
    position: 'left',
  },
]

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  cancelLink: {
    margin: theme.spacing(1),
    float: 'right',
    color: 'red',
  },
  annotationListDiv: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
}))

/** アルゴリズムのテーブルの選択状態 */
const ALGORITHM_HEADERS: SelectableTableHeader[] = [
  {
    id: 'version',
    title: 'バージョン',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'releasedAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 400,
    sortable: false,
    position: 'center',
  },
]

/** データセットテンプレートのテーブルの選択状態 */
const DATASET_TEMPLATE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'annotationSetKindList',
    title: 'アノテーションの組合せ',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 360,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** アノテーションファイルの上限サイズ */
const ANNOTATION_FILE_MAX_SIZE = 300 * 1000 * 1000

const mapStateToProps = (state: State) => ({
  ...state.pages.createDatasetState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData,
})
type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, CreateDatasetAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 学習アルゴリズムの表示条件の変更 */
  setTrainingDisplayCondition: (
    trainingAlgorithmCondition: AlgorithmDisplayCondition
  ) =>
    dispatch(
      createDatasetActions.setTrainingAlgorithmDisplayCondition(
        trainingAlgorithmCondition
      )
    ),
  checkAnnotations: (
    files: File[],
    annotationSetList: AddedAnnotationSetList[]
  ) =>
    dispatch(createDatasetOperations.checkAnnotation(files, annotationSetList)),
  setInputData: (files: File[], annotationSetList: AddedAnnotationSetList[]) =>
    dispatch(createDatasetOperations.setInputData(files, annotationSetList)),
  setDatasetName: (datasetName: string) =>
    dispatch(createDatasetActions.setDatasetName(datasetName)),
  setRemarks: (remarks: string) =>
    dispatch(createDatasetActions.setRemarks(remarks)),
  createDataset: () => dispatch(createDatasetOperations.createDataset()),
  setFileSizeError: (file: File) =>
    dispatch(createDatasetActions.setFileSizeError(file)),
  clearDataset: () => dispatch(createDatasetActions.clearCreateDataset()),
  setNextStepDatasetState: (
    datasetState: DatasetState,
    datasetSubState: DatasetSubState,
    payload: {
      annotationSetList?: AddedAnnotationSetList[]
      selectedDatasetTemplate?: DatasetTemplate
    }
  ) =>
    dispatch(
      createDatasetOperations.setNextStep(
        datasetState,
        datasetSubState,
        payload
      )
    ),
  setPrevStepState: (props: {
    datasetState: DatasetState
    annotationSetList: AddedAnnotationSetList[]
    trainingAlgorithmSubStep: boolean
  }) => dispatch(createDatasetOperations.setPrevStep(props)),
  setSelectedAlgorithmId: (algorithm: string) =>
    dispatch(createDatasetActions.setSelectedAlgorithmId(algorithm)),
  setSelectedAlgorithmVersion: (algorithmVersion: string) =>
    dispatch(
      createDatasetActions.setSelectedAlgorithmVersion(algorithmVersion)
    ),
  setAvailableDatasetTemplates: (
    algorithmId: string,
    algorithmVersion: string
  ) =>
    dispatch(
      createDatasetOperations.getAvailableDatasetTemplates(
        algorithmId,
        algorithmVersion
      )
    ),
  setSelectedAnnotationFormat: (annotationFormat?: SelectedAnnotationFormat) =>
    dispatch(
      createDatasetOperations.setSelectedAnnotationFormat(annotationFormat)
    ),
  setSelectedDatasetTemplate: (datasetTemplate: DatasetTemplate) =>
    dispatch(
      createDatasetOperations.setSelectedDatasetTemplate(datasetTemplate)
    ),
  setAnnotationSetKind: (
    annotationSetIndex: number,
    annotationSetKind: AnnotationSetKindAll,
    annotationTrainKind: AnnotationTrainKind | undefined
  ) =>
    dispatch(
      createDatasetOperations.setAnnotationSetKind(
        annotationSetIndex,
        annotationSetKind,
        annotationTrainKind
      )
    ),
  /** クラスセット一覧を取得 */
  getClassSets: () => dispatch(createDatasetOperations.getClassSets()),
  /** クラスセット一覧をクリア */
  clearClassSets: () => dispatch(createDatasetActions.clearClassSets()),
  /** クラスセットの表示条件の変更 */
  setClassSetDisplayCondition: (classSetCondition: ClassSetDisplayCondition) =>
    dispatch(
      createDatasetActions.setClassSetDisplayCondition(classSetCondition)
    ),
  /** クラスセットの表示条件をクリア */
  clearClassSetDisplayCondition: () =>
    dispatch(createDatasetActions.clearClassSetDisplayCondition()),
  /** 選んだリストをセットする(ClassSet) */
  setSelectedClassSet: (data?: ClassSet) =>
    dispatch(createDatasetOperations.setSelectedClassSet(data)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps &
  DispatchProps &
  RouteComponentProps & {
    mode?: 'page' | 'dialog'
    handleCloseDialog?: (datasetId?: string) => void
    algorithmId?: string
  }
const AnnotationListComponent: React.FC<Props> = (props: Props) => {
  return (
    <>
      <AnnotationSetListViewer
        listLabel='アノテーション'
        annotationSetList={props.domainData.addedAnnotationSetList
          .filter(
            (annotationSet: AddedAnnotationSetList) =>
              annotationSet.annotationStatus === 'completed'
          )
          .map((annotationSet: AddedAnnotationSetList) => ({
            annotationData: {
              annotationSetKind: annotationSet.annotationSetKind,
              conditions: annotationSet.conditions
                ? annotationSet.conditions
                : undefined,
              metadata: {
                name: annotationSet.annotationMetadata.name,
              },
              formatType: props.domainData.selectedAnnotationFormat
                ?.annotationFormatKind
                ? props.domainData.selectedAnnotationFormat
                    ?.annotationFormatKind
                : '',
            },
            groupedData: {
              groupedImageList: annotationSet.groupedData.trainingDataList.map(
                (image) => ({
                  fileName: image.file ? image.file.name : '',
                  fileStatus: image.fileStatus,
                })
              ),
            },
            uploadProgress: annotationSet.uploadProgress,
            annotationStatus: annotationSet.annotationStatus,
          }))}
        getAnnotationStatusIcon={(
          annotationStatus: string
        ): JSX.Element | undefined => {
          switch (annotationStatus) {
            case 'completed':
              return <CheckTooltipIcon title='Success!' color='green' />
            default:
              return <ErrorTooltipIcon title='Unknown Error!' color='red' />
          }
        }}
        getFileStatusIcon={() => undefined}
        getAnnotationStatusText={(
          annotationSet: AnnotationSetType,
          classes: ClassNameMap<
            | 'selectedName'
            | 'unselectedName'
            | 'usualStatus'
            | 'errorStatus'
            | 'uploadingStatus'
          >
        ) => (
          <>
            <span className={classes.selectedName}>
              {annotationSet.annotationData.metadata.name}
            </span>
            <span className={classes.usualStatus}>
              {annotationSet.annotationData.formatType}
            </span>
          </>
        )}
        getFileStatusText={(
          groupedImageData: GroupedImageDataType,
          classes: ClassNameMap<
            | 'selectedName'
            | 'unselectedName'
            | 'usualStatus'
            | 'errorStatus'
            | 'uploadingStatus'
          >
        ) => (
          <>
            {groupedImageData.fileStatus === 'selected' ? (
              <span className={classes.selectedName}>
                {groupedImageData.fileName}
              </span>
            ) : (
              <span className={classes.unselectedName}>
                {groupedImageData.fileName}
              </span>
            )}
          </>
        )}
        onChangeKind={props.setAnnotationSetKind}
        annotationSetKindList={
          props.domainData.selectedDatasetTemplate
            ? props.domainData.selectedDatasetTemplate.annotationSetKindList
            : []
        }
        isLoading={props.appState.inProgress}
        data-testid='datasetsAnnotation'
      />
    </>
  )
}

const InputListComponent: React.FC<Props> = (props: Props) => (
  <>
    <AnnotationSetListViewer
      listLabel='データ/アノテーション'
      annotationSetList={props.domainData.addedAnnotationSetList
        .filter(
          (annotationSet: AddedAnnotationSetList) =>
            annotationSet.annotationStatus === 'completed'
        )
        .map((annotationSet: AddedAnnotationSetList) => ({
          annotationData: {
            annotationSetKind: annotationSet.annotationSetKind,
            conditions: annotationSet.conditions
              ? annotationSet.conditions
              : undefined,
            metadata: {
              name: annotationSet.annotationMetadata.name,
            },
            formatType: props.domainData.selectedAnnotationFormat
              ?.annotationFormatKind
              ? props.domainData.selectedAnnotationFormat?.annotationFormatKind
              : '',
          },
          groupedData: {
            groupedImageList: annotationSet.groupedData.trainingDataList.map(
              (image) => ({
                fileName: image.file ? image.file.name : '',
                fileStatus: image.fileStatus,
              })
            ),
          },
          uploadProgress: annotationSet.uploadProgress,
          annotationStatus: annotationSet.annotationStatus,
        }))}
      getAnnotationStatusIcon={(
        _annotationStatus: string,
        fileStatusList: string[]
      ): JSX.Element | undefined => {
        if (fileStatusList.every((fileStatus) => fileStatus === 'selected')) {
          return <CheckTooltipIcon title='Success!' color='green' />
        } else if (
          fileStatusList.some((fileStatus) => fileStatus === 'checkError')
        ) {
          return (
            <ClearTooltipIcon title='Failed to File Type Check!' color='red' />
          )
        } else return undefined
      }}
      getFileStatusIcon={(status: string): JSX.Element | undefined => {
        switch (status) {
          case 'unselected':
            return undefined
          case 'selected':
            return <CheckTooltipIcon title='Selected' color='green' />
          case 'uploading':
            return <SyncTooltipIcon title='Uploading...' />
          case 'completed':
            return <SyncTooltipIcon title='Uploading...' />
          case 'checkError':
          case 'uploadError':
          default:
            return <ClearTooltipIcon title='Error!' color='red' />
        }
      }}
      getAnnotationStatusText={(
        annotationSet: AnnotationSetType,
        classes: ClassNameMap<
          | 'selectedName'
          | 'unselectedName'
          | 'usualStatus'
          | 'errorStatus'
          | 'uploadingStatus'
        >
      ) => (
        <>
          {annotationSet.groupedData.groupedImageList
            .map((groupedImageData) => groupedImageData.fileStatus)
            .every((fileStatus) => fileStatus === 'selected') ? (
            <span className={classes.selectedName}>
              {annotationSet.annotationData.metadata.name}
            </span>
          ) : (
            <span className={classes.unselectedName}>
              {annotationSet.annotationData.metadata.name}
            </span>
          )}
          <span className={classes.usualStatus}>
            {annotationSet.annotationData.formatType}
          </span>
          {((fileStatusList) => {
            const unselectedFileCount = fileStatusList.filter(
              (fileStatus) => fileStatus === 'unselected'
            ).length
            const checkErrorFileCount = fileStatusList.filter(
              (fileStatus) => fileStatus === 'checkError'
            ).length

            return unselectedFileCount > 0 || checkErrorFileCount > 0 ? (
              <span className={classes.usualStatus}>
                {' - '}
                {unselectedFileCount > 0 && ` 未選択: ${unselectedFileCount} `}
                {unselectedFileCount > 0 && checkErrorFileCount > 0 && ' / '}
                {checkErrorFileCount > 0 && ` エラー: ${checkErrorFileCount} `}
              </span>
            ) : null
          })(
            annotationSet.groupedData.groupedImageList.map(
              (groupedImageData) => groupedImageData.fileStatus
            )
          )}
        </>
      )}
      getFileStatusText={(
        groupedImageData: GroupedImageDataType,
        classes: ClassNameMap<
          | 'selectedName'
          | 'unselectedName'
          | 'usualStatus'
          | 'errorStatus'
          | 'uploadingStatus'
        >
      ) => (
        <>
          {groupedImageData.fileStatus === 'selected' ? (
            <span className={classes.selectedName}>
              {groupedImageData.fileName}
            </span>
          ) : (
            <span className={classes.unselectedName}>
              {groupedImageData.fileName}
            </span>
          )}
          {((fileStatus) => {
            if (fileStatus === 'unselected') {
              return (
                <span className={classes.usualStatus}>{'- 未選択です'}</span>
              )
            } else if (fileStatus === 'checkError') {
              return (
                <span className={classes.errorStatus}>
                  {'- ファイルサイズが不正です'}
                </span>
              )
            }
          })(groupedImageData.fileStatus)}
        </>
      )}
      filterProps={{
        label: '未選択・エラーのみ',
        isTarget: (status: string) =>
          status === 'unselected' ||
          status === 'checkError' ||
          status === 'uploadError',
      }}
      annotationSetKindList={
        props.domainData.selectedDatasetTemplate?.annotationSetKindList ?? []
      }
      data-testid='datasetsAnnotation'
    />
  </>
)

const UploadListComponent: React.FC<Props> = (props: Props) => (
  <>
    <AnnotationSetListViewer
      listLabel='データ/アノテーション'
      annotationSetList={props.domainData.addedAnnotationSetList.map(
        (annotationSet: AddedAnnotationSetList) => ({
          annotationData: {
            annotationSetKind: annotationSet.annotationSetKind,
            conditions: annotationSet.conditions
              ? annotationSet.conditions
              : undefined,
            metadata: {
              name: annotationSet.annotationMetadata.name,
            },
            formatType: props.domainData.selectedAnnotationFormat
              ?.annotationFormatKind
              ? props.domainData.selectedAnnotationFormat?.annotationFormatKind
              : '',
          },
          groupedData: {
            groupedImageList: annotationSet.groupedData.trainingDataList.map(
              (image) => ({
                fileName: image.file ? image.file.name : '',
                fileStatus: image.fileStatus,
              })
            ),
          },
          uploadProgress: annotationSet.uploadProgress,
          annotationStatus: annotationSet.annotationStatus,
        })
      )}
      getAnnotationStatusIcon={(
        _annotationStatus: string,
        fileStatusList: string[]
      ): JSX.Element | undefined => {
        if (fileStatusList.every((fileStatus) => fileStatus === 'completed')) {
          return <CheckTooltipIcon title='Success!' color='green' />
        } else if (
          fileStatusList.every((fileStatus) => fileStatus === 'selected')
        ) {
          return <CloudUploadTooltipIcon title='Wait for Uploading...' />
        } else if (
          fileStatusList.some((fileStatus) => fileStatus === 'uploading')
        ) {
          return <SyncTooltipIcon title='Uploading...' />
        } else {
          return <ErrorTooltipIcon title='Upload Error!' />
        }
      }}
      getFileStatusIcon={(fileStatus: string): JSX.Element | undefined => {
        switch (fileStatus) {
          case 'uploading':
            return <SyncTooltipIcon title='Uploading...' />
          case 'completed':
            return <CheckTooltipIcon title='Success!' color='green' />
          case 'selected':
            return <CloudUploadTooltipIcon title='Wait for Uploading...' />
          default:
            return <ErrorTooltipIcon title='Error!' />
        }
      }}
      getAnnotationStatusText={(
        annotationSet: AnnotationSetType,
        classes: ClassNameMap<
          | 'selectedName'
          | 'unselectedName'
          | 'usualStatus'
          | 'errorStatus'
          | 'uploadingStatus'
        >
      ) => (
        <>
          <span className={classes.selectedName}>
            {annotationSet.annotationData.metadata.name}
          </span>
          <span className={classes.usualStatus}>
            {annotationSet.annotationData.formatType}
          </span>
          <span className={classes.uploadingStatus}>
            <LabeledProgressBar
              value={annotationSet.uploadProgress}
              progressColor='green'
              completedColor='blue'
            />
          </span>
        </>
      )}
      getFileStatusText={(
        groupedImageData: GroupedImageDataType,
        classes: ClassNameMap<
          | 'selectedName'
          | 'unselectedName'
          | 'usualStatus'
          | 'errorStatus'
          | 'uploadingStatus'
        >
      ) => (
        <>
          <span className={classes.selectedName}>
            {groupedImageData.fileName}
          </span>
        </>
      )}
      annotationSetKindList={
        props.domainData.selectedDatasetTemplate?.annotationSetKindList ?? []
      }
      data-testid='datasetsAnnotation'
    />
  </>
)

const OnPreUploadListComponent: React.FC<Props> = (props: Props) => (
  <>
    <AnnotationSetListViewer
      listLabel='データ/アノテーション'
      annotationSetList={props.domainData.addedAnnotationSetList.map(
        (annotationSet: AddedAnnotationSetList) => ({
          annotationData: {
            annotationSetKind: annotationSet.annotationSetKind,
            conditions: annotationSet.conditions
              ? annotationSet.conditions
              : undefined,
            metadata: {
              name: annotationSet.annotationMetadata.name,
            },
            formatType: props.domainData.selectedAnnotationFormat
              ?.annotationFormatKind
              ? props.domainData.selectedAnnotationFormat?.annotationFormatKind
              : '',
          },
          groupedData: {
            groupedImageList: annotationSet.groupedData.trainingDataList.map(
              (image) => ({
                fileName: image.file ? image.file.name : '',
                fileStatus: image.fileStatus,
              })
            ),
          },
          uploadProgress: annotationSet.uploadProgress,
          annotationStatus: annotationSet.annotationStatus,
        })
      )}
      getAnnotationStatusIcon={() => undefined}
      getFileStatusIcon={() => undefined}
      getAnnotationStatusText={(
        annotationSet: AnnotationSetType,
        classes: ClassNameMap<
          | 'selectedName'
          | 'unselectedName'
          | 'usualStatus'
          | 'errorStatus'
          | 'uploadingStatus'
        >
      ) => (
        <>
          <span className={classes.selectedName}>
            {annotationSet.annotationData.metadata.name}
          </span>
          <span className={classes.usualStatus}>
            {annotationSet.annotationData.formatType}
          </span>
        </>
      )}
      getFileStatusText={(
        groupedImageData: GroupedImageDataType,
        classes: ClassNameMap<
          | 'selectedName'
          | 'unselectedName'
          | 'usualStatus'
          | 'errorStatus'
          | 'uploadingStatus'
        >
      ) => (
        <>
          <span className={classes.selectedName}>
            {groupedImageData.fileName}
          </span>
        </>
      )}
      annotationSetKindList={
        props.domainData.selectedDatasetTemplate?.annotationSetKindList ?? []
      }
      data-testid='datasetsAnnotation'
    />
  </>
)

type TrainingAlgorithmConfirmViewProps = Props & {
  trainingAlgorithmToDetail: (selectedAlgorithm: {
    selectedAlgorithmId: string
    selectedAlgorithmVersion: string
  }) =>
    | {
        ['バージョン']: string
        ['登録日時']: string
        ['remarks']: string
      }
    | undefined
}

const TrainingAlgorithmConfirmView: React.FC<
  TrainingAlgorithmConfirmViewProps
> = (props: TrainingAlgorithmConfirmViewProps) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} my={2} p={'24px 32px 32px'} width='100%'>
      <Typography>アルゴリズム</Typography>
      <Box mt={1}>
        {props.domainData.selectedAlgorithmId && (
          <TextField
            id='algorithm'
            className={classes.textField}
            variant='standard'
            label='アルゴリズム名'
            value={
              props.algorithms.find(
                (list) =>
                  list.algorithmId === props.domainData.selectedAlgorithmId
              )?.metadata.name.ja
            }
            key={props.domainData.selectedAlgorithmId}
          />
        )}
        <Box mt={2}>
          <InputLabel shrink>バージョン</InputLabel>
          <Box mt={1}>
            <AccordionLabel
              label={props.domainData.selectedAlgorithmVersion}
              details={props.trainingAlgorithmToDetail({
                selectedAlgorithmId: props.domainData.selectedAlgorithmId ?? '',
                selectedAlgorithmVersion:
                  props.domainData.selectedAlgorithmVersion ?? '',
              })}
              prefix={'-'}
              delimiter={':'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

type AlgorithmSpecificContentProps = Props & {
  classSetToDetail: (classSet?: ClassSet) =>
    | {
        ['クラスセットID']: string
        ['クラスセット名']: string
        ['クラス名リスト']: string
        ['登録日時']: string
        ['備考']: string
      }
    | undefined
}
const AlgorithmSpecificContent: React.FC<AlgorithmSpecificContentProps> = (
  props: AlgorithmSpecificContentProps
) => {
  if (props.domainData.selectedClassSet != null) {
    return (
      <Box component={Paper} my={2} p={'24px 32px 32px'} width='100%'>
        <Typography>クラスセット</Typography>
        <Box mt={1}>
          <InputLabel shrink>クラスセット名</InputLabel>
          <Box mt={1}>
            <AccordionLabel
              label={props.domainData.selectedClassSet?.classSetName ?? ''}
              details={props.classSetToDetail(
                props.domainData.selectedClassSet
              )}
              prefix={'-'}
              delimiter={':'}
            />
          </Box>
        </Box>
      </Box>
    )
  }
  return <></>
}

const CreateDataset: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()

  /** 学習アルゴリズムのサブステップ */
  const [trainingAlgorithmSubStep, setTrainingAlgorithmSubStep] =
    useState(false)

  const getStepperNum = (datasetState: DatasetState) => {
    switch (datasetState) {
      case 'AlgorithmState':
        return 0
      case 'DatasetTemplateState':
        return 1
      case 'AnnotationState':
        return 1
      case 'InputDataState':
        return 2
      case 'MetadataState':
        return 3
      case 'UploadState':
        return 4
      default:
        return 0
    }
  }

  useEffect(() => {
    // run on unmount
    return () => {
      props.clearDataset()
    }
  }, [])

  useEffect(() => {
    if (
      props.domainData.selectedAlgorithmId === undefined ||
      props.domainData.selectedAlgorithmVersion === undefined
    ) {
      return
    }

    props.setAvailableDatasetTemplates(
      props.domainData.selectedAlgorithmId,
      props.domainData.selectedAlgorithmVersion
    )
  }, [
    props.domainData.selectedAlgorithmId,
    props.domainData.selectedAlgorithmVersion,
  ])

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (props.algorithmId) {
      props.setSelectedAlgorithmId(props.algorithmId)
    } else {
      props.setSelectedAlgorithmId(props.algorithms[0].algorithmId)
    }
  }, [])

  const usePrevAnnotationSetList = (value: AddedAnnotationSetList[]) => {
    const prevAnnotationRef = useRef(value)
    useEffect(() => {
      prevAnnotationRef.current = value
    })
    return prevAnnotationRef.current
  }
  const prevAnnotationList = usePrevAnnotationSetList(
    props.domainData.addedAnnotationSetList
  )

  const {
    classSetRows,
    searchedClassSetsLength,
    tableSearchValue,
    selectedClassSetIndex,
    handleChangeDisplayNumber,
    selectUserGroupKind,
    searchTableContent,
    setTableSearchValue,
    selectClassSetTableRadio,
    changeTableSortOrder,
    pageChange,
    userGroupKindList,
    trainingAlgorithmToDetail,
    classSetToDetail,
  } = useClassSetsTable(props)

  const {
    selectedTrainingAlgorithmIndex,
    selectTrainingAlgorithmTableRadio,
    trainingAlgorithmRows,
    selectedAlgorithmTableOrder,
  } = useAlgorithmTable(props)

  const {
    selectedDatasetTemplateIndex,
    selectDatasetTemplateTableRadio,
    datasetTemplateRows,
  } = useDatasetTemplateTable(props)

  useEffect(
    () =>
      props.domainData.addedAnnotationSetList.forEach(
        (annotationSet: AddedAnnotationSetList) => {
          prevAnnotationList.forEach((prevAnnotationSet) => {
            const isDisplayStep =
              props.appState.datasetState === 'AnnotationState' ||
              props.appState.datasetState === 'InputDataState'
            const isMetadataEqual =
              annotationSet.annotationMetadata.name ===
              prevAnnotationSet.annotationMetadata.name
            const hasChangedAnnotationStatus =
              annotationSet.annotationStatus !==
              prevAnnotationSet.annotationStatus
            const hasValidateError =
              annotationSet.annotationStatus === 'validateError'
            const hasUploadError =
              annotationSet.annotationStatus === 'uploadError'

            if (
              isDisplayStep &&
              isMetadataEqual &&
              hasChangedAnnotationStatus
            ) {
              if (hasValidateError) {
                showFileErrorToast(annotationSet.annotationMetadata.name, [
                  '選択されたファイルの形式が不正です',
                ])
              } else if (hasUploadError) {
                showFileErrorToast(annotationSet.annotationMetadata.name, [
                  'アップロードに失敗しました',
                ])
              }
            }
          })
        }
      ),
    [props.domainData.addedAnnotationSetList]
  )

  const STEP_NAMES = [
    'アルゴリズム',
    'アノテーション',
    'インプットデータ',
    'メタデータ',
    '送信',
  ]

  useEffect(() => {
    if (!props.appState.toastInfo) return

    showToast(
      props.appState.toastInfo.type,
      <div>
        <div>{`メッセージ種別: ${props.appState.toastInfo.type}`}</div>
        <div data-testid='dataset-entry-error-training-image-max-size'>
          {props.appState.toastInfo.title}
        </div>
      </div>
    )
  }, [props.appState.toastInfo])

  const showFileErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <Box>
        <Box>{'メッセージ種別: error'}</Box>
        <Box data-testid='datasetsErrorToastFileName'>{`ファイル名: ${fileName}`}</Box>
        <Box>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </Box>
      </Box>
    )

  const notifyFileErrors = (errorFiles: FileRejection[]) =>
    errorFiles.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const errorFileMessages = errorFile.errors
      errorFileMessages.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            props.setFileSizeError(errorFile.file)
            break
          }
          case 'file-invalid-type': {
            showFileErrorToast(errorFileName, [
              '選択されたファイルの形式が不正です',
            ])
            break
          }
          default:
            break
        }
      })
    })

  const notifyAnnotationErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showFileErrorToast(errorFileName, errorMessage)
    })

  const getMatchedFiles = (acceptedFile: File[]) => {
    const groupedImageFileNames: string[] = []
    props.domainData.addedAnnotationSetList.forEach(
      (annotationSet: AddedAnnotationSetList) =>
        annotationSet.groupedData.trainingDataList.forEach((trainingData) =>
          groupedImageFileNames.push(
            trainingData.file?.name ? trainingData.file?.name : ''
          )
        )
    )

    const matchedFiles: File[] = []
    acceptedFile.forEach((file) => {
      if (groupedImageFileNames.indexOf(file.name) !== -1) {
        matchedFiles.push(file)
      } else {
        showFileErrorToast(file.name, [
          '選択されたファイルはアノテーションに定義されていません',
        ])
      }
    })
    return matchedFiles
  }

  const getStepContent = (props: Props): JSX.Element => {
    const datasetMetadataInputHelper = (metadataSubState: string) =>
      metadataSubState === 'emptyRequired' ? '入力してください。' : ''
    const checkAnnotations = (files: File[]) => {
      props.checkAnnotations(files, props.domainData.addedAnnotationSetList)
    }
    const setInputData = (files: File[]) => {
      props.setInputData(files, props.domainData.addedAnnotationSetList)
    }

    const AnnotationSetErrormessage = (props: Props): JSX.Element => {
      const errorMessages: string[] = []
      if (props.appState.datasetSubState.uploadSubState === 'uploadError') {
        props.domainData.addedAnnotationSetList.forEach(
          (annotationSet: AddedAnnotationSetList) => {
            if (
              annotationSet.groupedData.trainingDataList.some(
                (rainingData) => rainingData.fileStatus === 'uploadError'
              )
            ) {
              errorMessages.push(
                'データの送信に失敗しました。' +
                  `${annotationSet.annotationMetadata.name}`
              )
            }
          }
        )

        return <ErrorMessage title='' targets={errorMessages} />
      } else if (
        props.appState.datasetSubState.uploadSubState === 'documentError'
      ) {
        errorMessages.push('ドキュメントの作成に失敗しました。')
        return <ErrorMessage title='' targets={errorMessages} />
      } else return <></>
    }

    /** アルゴリズム選択 */
    const setSelectedAlgorithmId = (id: string) => {
      props.setSelectedAnnotationFormat(undefined)
      props.setSelectedAlgorithmId(id)
    }

    switch (props.appState.datasetState) {
      case 'AlgorithmState':
        if (trainingAlgorithmSubStep) {
          return (
            <CustomTrainingPageParagraph>
              <Box mt={2}>
                <FormControl
                  variant='outlined'
                  className={classes.algorithmSelectBox}
                >
                  <InputLabel id='class-set-user-group-kind'>
                    データ種別
                  </InputLabel>
                  <Select
                    labelId='class-set-user-group-kind-label'
                    id='class-set-user-group-kind-outlined'
                    value={
                      props.domainData.classSetDisplayCondition
                        .selectedUserGroupKind
                    }
                    onChange={(e) =>
                      selectUserGroupKind(
                        e.target.value as 'UserGroup' | 'SharedUserGroup'
                      )
                    }
                    label='Select User Group Kind'
                  >
                    {userGroupKindList.map((kind) => (
                      <MenuItem
                        data-testid={kind.kind}
                        value={kind.kind}
                        key={kind.kind}
                      >
                        {kind.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <div className={classes.searchForm}>
                  <div className={classes.searchField}>
                    <SearchInput
                      placeholder='検索 (クラスセットIDなど)'
                      value={tableSearchValue}
                      onChangeValue={(event) =>
                        setTableSearchValue(event.target.value)
                      }
                      onClickSearch={() => searchTableContent()}
                      onPressEnter={() => searchTableContent()}
                    />
                  </div>
                </div>
              </Box>
              <SelectableTable
                headers={CLASS_SETS_TABLE_HEADERS}
                rows={classSetRows}
                displayNumber={
                  props.domainData.classSetDisplayCondition.displayNumber
                }
                totalCount={
                  searchedClassSetsLength ?? props.domainData.classSets.length
                }
                tableHeight={600}
                selectedRowNumber={selectedClassSetIndex}
                fixedColumnNumber={0}
                page={props.domainData.classSetDisplayCondition.pageNumber}
                sortOrder={{
                  key: props.domainData.classSetDisplayCondition.sortKey,
                  order: props.domainData.classSetDisplayCondition.sortOrder,
                }}
                displayNoneRadio={false}
                onChangeDisplayNumber={(displayNumber: number) =>
                  handleChangeDisplayNumber(displayNumber)
                }
                onClickRadio={(row: number) => selectClassSetTableRadio(row)}
                onClickOrderChange={(key: string) => changeTableSortOrder(key)}
                onClickPageChange={(pageNumber: number) =>
                  pageChange(pageNumber)
                }
              />
            </CustomTrainingPageParagraph>
          )
        }
        return (
          <Box sx={{ backgroundColor: '#fafafa' }}>
            <CustomTrainingPageParagraph>
              <Box mt={2}>
                <FormControl
                  variant='outlined'
                  className={classes.algorithmSelectBox}
                >
                  <InputLabel id='customTrainingModel'>アルゴリズム</InputLabel>
                  <Select
                    data-testid='select'
                    value={props.domainData.selectedAlgorithmId}
                    onChange={(e: SelectChangeEvent<string>) =>
                      setSelectedAlgorithmId(e.target.value as string)
                    }
                    label='Select Algorithm'
                    defaultValue={
                      props.algorithmId
                        ? props.algorithmId
                        : props.algorithms[0].algorithmId
                    }
                  >
                    {props.algorithms.map((algorithm) => {
                      return (
                        <MenuItem
                          data-testid={algorithm.algorithmId}
                          value={algorithm.algorithmId}
                          key={algorithm.algorithmId}
                        >
                          {algorithm.metadata.name.ja}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              {!isUndefined(props.domainData.selectedAlgorithmId) && (
                <SelectableTable
                  headers={ALGORITHM_HEADERS}
                  fixedColumnNumber={0}
                  selectedRowNumber={selectedTrainingAlgorithmIndex}
                  page={0}
                  rows={trainingAlgorithmRows}
                  sortOrder={{
                    key: props.domainData
                      .trainingAlgorithmVersionDisplayCondition.sortKey,
                    order:
                      props.domainData.trainingAlgorithmVersionDisplayCondition
                        .sortOrder,
                  }}
                  onClickRadio={(row: number) =>
                    selectTrainingAlgorithmTableRadio(row)
                  }
                  onClickOrderChange={(key: string) => {
                    selectedAlgorithmTableOrder(key)
                  }}
                />
              )}
            </CustomTrainingPageParagraph>
          </Box>
        )
      case 'DatasetTemplateState':
      case 'AnnotationState':
        if (props.appState.datasetState === 'DatasetTemplateState') {
          return (
            <Box>
              <Box className={classes.stepTitle}>
                <Typography>データセットテンプレートを選択します。 </Typography>
              </Box>
              <SelectableTable
                headers={DATASET_TEMPLATE_HEADERS}
                fixedColumnNumber={0}
                selectedRowNumber={selectedDatasetTemplateIndex}
                page={0}
                rows={datasetTemplateRows}
                onClickRadio={(row: number) =>
                  selectDatasetTemplateTableRadio(row)
                }
              />
            </Box>
          )
        } else {
          return (
            <Box>
              <Box className={classes.stepTitle}>
                <Typography>アノテーションファイルを選択します。 </Typography>
              </Box>
              <FileSelectableDropzone
                acceptFileOperation={(files: File[]) => checkAnnotations(files)}
                rejectedFileOperation={(fileRejections: FileRejection[]) =>
                  notifyAnnotationErrors(fileRejections)
                }
                fileType='application/json'
                maxSize={ANNOTATION_FILE_MAX_SIZE}
                disable={props.domainData.addedAnnotationSetList.some(
                  (annotationSet: AddedAnnotationSetList) =>
                    annotationSet.annotationStatus === 'uploading'
                )}
                data-testid='annotationFileDropzone'
              />
            </Box>
          )
        }
      case 'InputDataState':
        return (
          <Box>
            <Box className={classes.stepTitle}>
              <Typography>インプットデータを選択します。</Typography>
            </Box>
            <FileSelectableDropzone
              acceptFileOperation={(files: File[]) => {
                setInputData(getMatchedFiles(files))
              }}
              rejectedFileOperation={(errorFile: FileRejection[]) => {
                notifyFileErrors(errorFile)
              }}
              fileType='image/*'
              maxSize={50 * 1000 * 1000}
              disable={props.domainData.addedAnnotationSetList
                .filter(
                  (annotationSet: AddedAnnotationSetList) =>
                    annotationSet.annotationStatus === 'completed'
                )
                .some((annotationSet: AddedAnnotationSetList) =>
                  annotationSet.groupedData.trainingDataList.some(
                    (trainingData) => trainingData.fileStatus === 'uploading'
                  )
                )}
              data-testid='inputDataFileDropzone'
            />
          </Box>
        )
      case 'MetadataState':
        return (
          <Box>
            <Box className={classes.stepTitle}>
              <Typography>メタデータを入力します。</Typography>
            </Box>
            <MetadataInput
              nameProps={{
                label: 'データセット名',
                value: props.domainData.datasetMetadata.name,
                variant: 'outlined',
                readOnly: false,
                onChange: (event) => {
                  props.setDatasetName(event.target.value)
                },
              }}
              remarksProps={{
                label: '備考',
                value: props.domainData.datasetMetadata.remarks,
                variant: 'outlined',
                readOnly: false,
                onChange: (event) => {
                  props.setRemarks(event.target.value)
                },
                rowNum: 4,
              }}
              helperText={datasetMetadataInputHelper(
                props.appState.datasetSubState.metadataSubState
              )}
              data-testid='datasets-input'
            />
          </Box>
        )
      case 'UploadState':
        return (
          <>
            <Box className={classes.stepTitle}>
              <Typography>データを確認してサーバに送信します。</Typography>
            </Box>
            <Box className={classes.errorField}>
              <AnnotationSetErrormessage {...props} />
            </Box>
            <CommonCompleteDialog
              open={
                props.appState.datasetSubState.uploadSubState === 'completed' &&
                props.domainData.createdDatasetId !== ''
              }
              value={props.domainData.createdDatasetId}
              label='データセットID'
              dialogText={'正常にデータを登録しました。'}
              handleClose={
                props.mode === 'dialog'
                  ? () =>
                      props.handleCloseDialog &&
                      props.handleCloseDialog(props.domainData.createdDatasetId)
                  : () => history.push('/datasets')
              }
              data-testid='datasets-dialog'
            />
            <Box component={Paper} my={2} p={'24px 32px 32px'} width='100%'>
              <Typography>データセット情報</Typography>
              <Box mt={1}>
                <MetadataInput
                  nameProps={{
                    label: 'データセット名',
                    value: props.domainData.datasetMetadata.name,
                    readOnly: true,
                    variant: 'standard',
                    onChange: (event) => {
                      props.setDatasetName(event.target.value)
                    },
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.datasetMetadata.remarks,
                    readOnly: true,
                    variant: 'standard',
                    onChange: (event) => {
                      props.setRemarks(event.target.value)
                    },
                    rowNum: 4,
                  }}
                  helperText={datasetMetadataInputHelper(
                    props.appState.datasetSubState.metadataSubState
                  )}
                  data-testid='datasetsInput'
                />
                <Box className={classes.annotationListDiv}>
                  {props.appState.datasetSubState.uploadSubState ===
                  'beforeUpload' ? (
                    <OnPreUploadListComponent {...props} />
                  ) : (
                    <UploadListComponent {...props} />
                  )}
                </Box>
              </Box>
            </Box>
            <TrainingAlgorithmConfirmView
              {...props}
              trainingAlgorithmToDetail={trainingAlgorithmToDetail}
            />
            <AlgorithmSpecificContent
              {...props}
              classSetToDetail={classSetToDetail}
            />
            <GlobalLoading open={props.appState.inProgress} />
          </>
        )
      default:
        return (
          <Box>
            <Typography>Unknown</Typography>
          </Box>
        )
    }
  }

  const getInformationContent = (props: Props): JSX.Element => {
    switch (props.appState.datasetState) {
      case 'DatasetTemplateState':
      case 'AnnotationState':
        if (props.appState.datasetState === 'DatasetTemplateState') {
          return <></>
        } else {
          return (
            <Box mt={2} mb={2}>
              <AnnotationListComponent {...props} />
            </Box>
          )
        }
      case 'InputDataState':
        return (
          <Box mt={2} mb={2}>
            <InputListComponent {...props} />
          </Box>
        )
      default:
        return <></>
    }
  }

  const onClickPrevButton = () => {
    switch (props.appState.datasetState) {
      case 'AlgorithmState':
        if (trainingAlgorithmSubStep) {
          setTrainingAlgorithmSubStep(false)
          setTableSearchValue('')
        }
        break
      default:
        break
    }
    props.setPrevStepState({
      datasetState: props.appState.datasetState,
      annotationSetList: props.domainData.addedAnnotationSetList,
      trainingAlgorithmSubStep,
    })
  }

  const getNextButtonProps = (props: Props) => {
    let disabled = false
    switch (props.appState.datasetState) {
      case 'AlgorithmState':
        if (trainingAlgorithmSubStep) {
          disabled =
            props.appState.datasetSubState.classSetSubState !== 'selected'
        } else {
          disabled =
            props.appState.datasetSubState.algorithmSubState !== 'selected'
        }
        break
      case 'DatasetTemplateState':
        disabled =
          props.appState.datasetSubState.datasetTemplateSubState !== 'selected'
        break
      case 'AnnotationState':
        disabled =
          props.appState.datasetSubState.annotationSubState !== 'selected'
        break
      case 'InputDataState':
        disabled =
          props.appState.datasetSubState.inputDataSubState !== 'selected'
        break
      case 'MetadataState':
        disabled =
          props.appState.datasetSubState.metadataSubState !== 'inputRequired'
        break
      case 'UploadState':
        disabled = props.appState.datasetSubState.uploadSubState === 'uploading'
        break
      default:
        disabled = false
    }

    const onClick = () => {
      const selectedAlgorithmId = props.domainData.selectedAlgorithmId ?? ''
      const objectClassificationAlgorithmIds = props.algorithms
        .filter(
          (algorithm) => algorithm.algorithmPurpose === 'ObjectClassification'
        )
        .map((algorithm) => algorithm.algorithmId)

      switch (props.appState.datasetState) {
        case 'AlgorithmState':
          if (
            objectClassificationAlgorithmIds.includes(selectedAlgorithmId) &&
            !trainingAlgorithmSubStep
          ) {
            setTrainingAlgorithmSubStep(true)
            break
          }
          props.setNextStepDatasetState(
            props.appState.datasetState,
            props.appState.datasetSubState,
            { annotationSetList: props.domainData.addedAnnotationSetList }
          )
          break
        case 'UploadState':
          props.createDataset()
          break
        default:
          props.setNextStepDatasetState(
            props.appState.datasetState,
            props.appState.datasetSubState,
            { annotationSetList: props.domainData.addedAnnotationSetList }
          )
          break
      }
    }

    return {
      disabled,
      onClick,
    }
  }

  /** クラスセット一覧取得 */
  useEffect(() => {
    if (
      props.appState.datasetState === 'AlgorithmState' &&
      trainingAlgorithmSubStep
    ) {
      props.getClassSets()
    }
  }, [props.appState.datasetState, trainingAlgorithmSubStep])

  return (
    <>
      <Box className={classes.container}>
        {props.mode !== 'dialog' && (
          <Box pl={7}>
            <BreadcrumbsComponent
              breadcrumbsPath={[
                {
                  name: 'データセット一覧',
                  path: 'datasets',
                },
                {
                  name: 'データセット作成',
                  path: 'entry',
                },
              ]}
            />
          </Box>
        )}
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.head}>
            <Box className={classes.flexAndBetween}>
              <h2 className={classes.pageTitle} data-testid='datasets-title'>
                データセット
              </h2>
              {props.mode === 'dialog' && (
                <Box className={classes.confirmButton}>
                  <Button
                    onClick={
                      props.mode === 'dialog'
                        ? () =>
                            props.handleCloseDialog && props.handleCloseDialog()
                        : props.history.goBack
                    }
                    className={classes.cancelLink}
                    data-testid='datesetsButtonCancel'
                  >
                    <Typography>キャンセル</Typography>
                  </Button>
                </Box>
              )}
            </Box>
            <Box className={classes.stepper}>
              <CommonStepper
                stepLabels={STEP_NAMES}
                activeStepIndex={getStepperNum(props.appState.datasetState)}
              />
            </Box>
          </div>
          <Box className={classes.content} ref={contentRef}>
            {getStepContent(props)}
            {getInformationContent(props)}
          </Box>
          <Box className={classes.footerButtons}>
            <Box className={classes.footer}>
              {props.appState.datasetState === 'AlgorithmState' &&
              !trainingAlgorithmSubStep ? (
                <Box></Box>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={false}
                  onClick={onClickPrevButton}
                  className={classes.leftButton}
                >
                  {'戻る'}
                </Button>
              )}
              <Button
                data-testid='datasets-button-next'
                variant='contained'
                color='primary'
                disabled={getNextButtonProps(props).disabled}
                onClick={() => getNextButtonProps(props).onClick()}
                className={classes.rightButton}
              >
                {props.appState.datasetState === 'UploadState'
                  ? '開始'
                  : '次へ'}
              </Button>
            </Box>
          </Box>
        </Toast>
      </Box>
    </>
  )
}

export const CreateDatasetPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateDataset))

const useClassSetsTable = (props: Props) => {
  const globalTheme = useTheme()
  const [searchedClassSetsLength, setSearchedClassSetsLength] = useState<
    number | undefined
  >(0)
  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')

  /** クラスセットの表示対象のセッティングデータ */
  const adjustClassSetTableRow = useMemo(() => {
    let newClassSetArray = props.domainData.classSets
    /** 検索ワードから検索 */
    if (props.domainData.classSetDisplayCondition.searchValue) {
      newClassSetArray = newClassSetArray.filter(
        (item) =>
          item.classSetId ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetName ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetId.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          ) ||
          item.classSetName.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          )
      )
      /** 検索後の配列の長さをセット */
      setSearchedClassSetsLength(newClassSetArray.length)
    } else {
      /** 検索後の配列の長さをセット */
      setSearchedClassSetsLength(undefined)
    }
    /** ソートキー、ソートオーダーによる並び替え */
    if (props.domainData.classSetDisplayCondition.sortKey === 'createdAt') {
      newClassSetArray = newClassSetArray.sort((item, item2) => {
        return (
          ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
            .toDate()
            .getTime() -
            (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime()) *
          (props.domainData.classSetDisplayCondition.sortOrder === 'asc'
            ? -1
            : 1)
        )
      })
    }

    /** 表示するセッティングの整形 */
    return newClassSetArray.slice(
      props.domainData.classSetDisplayCondition.displayNumber *
        props.domainData.classSetDisplayCondition.pageNumber,
      (props.domainData.classSetDisplayCondition.pageNumber + 1) *
        props.domainData.classSetDisplayCondition.displayNumber
    )
  }, [props.domainData.classSetDisplayCondition, props.domainData.classSets])

  /** テーブルに表示するクラスセットのJSXの２次元配列 */
  const classSetRows = useMemo(() => {
    const dataRows = adjustClassSetTableRow.map((classSet) => ({
      classSetId: classSet.classSetId,
      classSetName: classSet.classSetName,
      classList: classSet.classList.join(', '),
      createdAt: classSet.createdAt
        ? formatDateTimeSec(classSet.createdAt.toDate())
        : '',
      classSetRemarks: classSet.classSetRemarks,
    }))

    return dataRows.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '' || isUndefined(value)) {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'classSetId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`class-set-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return <Typography key={key}>{value}</Typography>
        }
      })
    )
  }, [adjustClassSetTableRow])

  useEffect(() => {
    if (
      isUndefined(props.domainData.selectedClassSet) &&
      props.domainData.classSets.length > 0
    ) {
      props.setSelectedClassSet(adjustClassSetTableRow[0])
    }
  }, [adjustClassSetTableRow])

  /** 検索処理 */
  const searchTableContent = () => {
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      searchValue: tableSearchValue,
      pageNumber: 0,
    })
  }

  /** クラスセット選択のユーザーグループ種別の選択 */
  const selectUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearClassSets()
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getClassSets()
  }

  const selectedClassSetIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedClassSet)) return -1
    return adjustClassSetTableRow.findIndex(
      (classSet) =>
        classSet.classSetId === props.domainData.selectedClassSet?.classSetId
    )
  }, [adjustClassSetTableRow, props.domainData.selectedClassSet])

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const maxPageNumber =
      Math.ceil(props.domainData.classSets.length / displayNumber) - 1
    if (props.domainData.classSetDisplayCondition.pageNumber <= maxPageNumber) {
      /** 表示数を変更 */
      props.setClassSetDisplayCondition({
        ...props.domainData.classSetDisplayCondition,
        displayNumber: displayNumber,
      })
    } else {
      /** 表示数を変更 */
      props.setClassSetDisplayCondition({
        ...props.domainData.classSetDisplayCondition,
        pageNumber: maxPageNumber,
        displayNumber: displayNumber,
      })
    }
  }

  /** クラスセットのラジオボタン押下時処理 */
  const selectClassSetTableRadio = (row: number) => {
    const classSet = adjustClassSetTableRow[row]
    props.setSelectedClassSet(classSet)
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.classSetDisplayCondition.sortKey === key
          ? props.domainData.classSetDisplayCondition.sortOrder === 'asc'
            ? 'desc'
            : 'asc'
          : props.domainData.classSetDisplayCondition.sortOrder,
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  /** 学習アルゴリズム詳細変換 */
  const trainingAlgorithmToDetail = (algorithm: {
    selectedAlgorithmId: string
    selectedAlgorithmVersion: string
  }) => {
    const { selectedAlgorithmId, selectedAlgorithmVersion } = algorithm
    const selectedAlgorithm = props.algorithms.find(
      (algorithm) => algorithm.algorithmId === selectedAlgorithmId
    )
    if (selectedAlgorithm == null) {
      return
    }

    const selectedAlgorithmVersionData =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.find(
        (algorithmVersion) =>
          algorithmVersion.algorithmVersion.displayName ===
          selectedAlgorithmVersion
      )
    if (selectedAlgorithmVersionData == null) {
      return
    }

    return {
      ['バージョン']: selectedAlgorithmVersionData.trainingAlgorithmVersion,
      ['登録日時']: selectedAlgorithmVersionData.releasedAt
        ? formatDateTimeSec(selectedAlgorithmVersionData.releasedAt.toDate())
        : '',
      ['remarks']: selectedAlgorithmVersionData.metadata.remarks.ja,
    }
  }

  /** クラスセット変換 */
  const classSetToDetail = (classSet?: ClassSet) => {
    if (isUndefined(classSet)) return undefined
    return {
      ['クラスセットID']: classSet.classSetId,
      ['クラスセット名']: classSet.classSetName,
      ['クラス名リスト']: classSet.classList.join(','),
      ['登録日時']: formatDateTimeSec(classSet.createdAt.toDate()),
      ['備考']: classSet.classSetRemarks,
    }
  }

  return {
    searchedClassSetsLength,
    classSetRows,
    tableSearchValue,
    selectedClassSetIndex,
    userGroupKindList,
    selectClassSetTableRadio,
    handleChangeDisplayNumber,
    searchTableContent,
    selectUserGroupKind,
    setTableSearchValue,
    changeTableSortOrder,
    pageChange,
    trainingAlgorithmToDetail,
    classSetToDetail,
  }
}

const useAlgorithmTable = (props: Props) => {
  const globalTheme = useTheme()
  /** 学習アルゴリズムのテーブルをソートして返す */
  const trainingAlgorithmRows = useMemo(() => {
    if (!isUndefined(props.domainData.selectedAlgorithmId)) {
      let selectedAlgorithm = props.algorithms[0]
      props.algorithms.find((algorithm) => {
        if (algorithm.algorithmId == props.domainData.selectedAlgorithmId) {
          selectedAlgorithm = algorithm
        }
      })
      if (
        selectedAlgorithm.algorithmId !== props.domainData.selectedAlgorithmId
      ) {
        return []
      }
      let rowData =
        selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.map(
          (data) => {
            return {
              version: data.algorithmVersion,
              releasedAt: data.releasedAt
                ? formatDateTimeSec(data.releasedAt.toDate())
                : undefined,
              remarks: data.metadata.remarks.ja,
            }
          }
        )
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.trainingAlgorithmVersionDisplayCondition.sortKey ===
        'version'
      ) {
        rowData = rowData.sort(
          (
            item1: {
              version: {
                displayName: string
                major: number
                minor: number
                patch: number
                preRelease: number
              }
              releasedAt: string | undefined
              remarks: string
            },
            item2: {
              version: {
                displayName: string
                major: number
                minor: number
                patch: number
                preRelease: number
              }
              releasedAt: string | undefined
              remarks: string
            }
          ) => {
            return compareVersionsWithPreRelease(
              item1.version,
              item2.version,
              props.domainData.trainingAlgorithmVersionDisplayCondition
                .sortOrder
            )
          }
        )
      }

      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '' || isUndefined(value)) {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            if (key == 'version') {
              if (isVersionWithPreRelease(value)) {
                return <Typography key={key}>{value.displayName}</Typography>
              } else {
                return (
                  <Box key={key} sx={{ color: 'text.secondary' }}>
                    <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                  </Box>
                )
              }
            }
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [
    props.domainData.trainingAlgorithmVersionDisplayCondition,
    props.domainData.selectedAlgorithmId,
  ])

  /** 学習アルゴリズムテーブルのソート設定 */
  const selectedAlgorithmTableOrder = (key: string) => {
    props.setTrainingDisplayCondition({
      sortKey: key,
      sortOrder:
        props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder ===
        'desc'
          ? 'asc'
          : 'desc',
    })
  }

  /** 学習アルゴリズムの初期値選択 */
  useEffect(() => {
    if (isUndefined(props.domainData.selectedAnnotationFormat)) {
      let selectedAlgorithm = props.algorithms[0]
      props.algorithms.find((algorithm) => {
        if (algorithm.algorithmId == props.domainData.selectedAlgorithmId) {
          selectedAlgorithm = algorithm
        }
      })
      if (
        selectedAlgorithm.algorithmId !== props.domainData.selectedAlgorithmId
      ) {
        return
      }

      const trainingAlgorithmVersions =
        selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
          (item1, item2) => {
            return compareVersionsWithPreRelease(
              item1.algorithmVersion,
              item2.algorithmVersion,
              props.domainData.trainingAlgorithmVersionDisplayCondition
                .sortOrder
            )
          }
        )

      const trainingAlgorithmVersion = trainingAlgorithmVersions[0]
      props.setSelectedAlgorithmVersion(
        trainingAlgorithmVersion.algorithmVersion.displayName
      )
      const annotationFormat = props.annotationFormats.find(
        (annotationFormat) =>
          annotationFormat.annotationFormatId ===
          trainingAlgorithmVersion.annotationFormatId
      )
      if (annotationFormat) {
        props.setSelectedAnnotationFormat({
          annotationFormatId: annotationFormat.annotationFormatId,
          annotationFormatKind: annotationFormat.annotationFormatKind,
          annotationFormatVersion: {
            displayName:
              trainingAlgorithmVersion.annotationFormatVersion.displayName,
            major: trainingAlgorithmVersion.annotationFormatVersion.major,
            minor: trainingAlgorithmVersion.annotationFormatVersion.minor,
            patch: trainingAlgorithmVersion.annotationFormatVersion.patch,
          },
        })
      }
    }
  }, [props.domainData.selectedAlgorithmId])

  /** 学習アルゴリズムのラジオボタンクリック */
  const selectTrainingAlgorithmTableRadio = (row: number) => {
    let selectedAlgorithm = props.algorithms[0]
    props.algorithms.find((algorithm) => {
      if (algorithm.algorithmId == props.domainData.selectedAlgorithmId) {
        selectedAlgorithm = algorithm
      }
    })
    if (
      selectedAlgorithm.algorithmId !== props.domainData.selectedAlgorithmId
    ) {
      return
    }
    const trainingAlgorithmVersions =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
        (item1, item2) => {
          return compareVersionsWithPreRelease(
            item1.algorithmVersion,
            item2.algorithmVersion,
            props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder
          )
        }
      )
    const trainingAlgorithmVersion = trainingAlgorithmVersions[row]

    props.setSelectedAlgorithmVersion(
      trainingAlgorithmVersion.algorithmVersion.displayName
    )
    const annotationFormat = props.annotationFormats.find(
      (annotationFormat) =>
        annotationFormat.annotationFormatId ===
        trainingAlgorithmVersion.annotationFormatId
    )
    if (annotationFormat) {
      props.setSelectedAnnotationFormat({
        annotationFormatId: annotationFormat.annotationFormatId,
        annotationFormatKind: annotationFormat.annotationFormatKind,
        annotationFormatVersion: {
          displayName:
            trainingAlgorithmVersion.annotationFormatVersion.displayName,
          major: trainingAlgorithmVersion.annotationFormatVersion.major,
          minor: trainingAlgorithmVersion.annotationFormatVersion.minor,
          patch: trainingAlgorithmVersion.annotationFormatVersion.patch,
        },
      })
    }
  }

  /** 学習アルゴリズムテーブルの選択状態 */
  const selectedTrainingAlgorithmIndex = useMemo(() => {
    if (
      isUndefined(props.domainData.selectedAlgorithmId) ||
      isUndefined(props.domainData.selectedAlgorithmVersion)
    )
      return -1
    let selectedAlgorithm = props.algorithms[0]
    props.algorithms.find((algorithm) => {
      if (algorithm.algorithmId == props.domainData.selectedAlgorithmId) {
        selectedAlgorithm = algorithm
      }
    })
    if (
      selectedAlgorithm.algorithmId !== props.domainData.selectedAlgorithmId
    ) {
      return -1
    }
    const trainingAlgorithmVersions =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.sort(
        (item1, item2) => {
          return compareVersionsWithPreRelease(
            item1.algorithmVersion,
            item2.algorithmVersion,
            props.domainData.trainingAlgorithmVersionDisplayCondition.sortOrder
          )
        }
      )

    return trainingAlgorithmVersions.findIndex(
      (trainingAlgorithmVersion) =>
        trainingAlgorithmVersion.trainingAlgorithmVersion ===
        props.domainData.selectedAlgorithmVersion
    )
  }, [
    trainingAlgorithmRows,
    props.algorithms,
    props.domainData.selectedAlgorithmId,
    props.domainData.selectedAnnotationFormat,
  ])

  return {
    selectedTrainingAlgorithmIndex,
    selectTrainingAlgorithmTableRadio,
    trainingAlgorithmRows,
    selectedAlgorithmTableOrder,
  }
}

const useDatasetTemplateTable = (props: Props) => {
  const globalTheme = useTheme()
  /** データセットテンプレートのテーブルをソートして返す */
  const datasetTemplateRows = useMemo(() => {
    if (props.domainData.availableDatasetTemplates.length > 0) {
      const rowData = props.domainData.availableDatasetTemplates.map((data) => {
        return {
          annotationSetKindList: data.metadata.name.ja,
          remarks: data.metadata.remarks.ja,
        }
      })

      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '') {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [props.domainData.availableDatasetTemplates])

  /** データセットテンプレートの初期値選択 */
  useEffect(() => {
    if (
      props.appState.datasetState === 'DatasetTemplateState' &&
      isUndefined(props.domainData.selectedDatasetTemplate) &&
      props.domainData.availableDatasetTemplates.length > 0
    ) {
      const selectedDatasetTemplate =
        props.domainData.availableDatasetTemplates[0]
      props.setSelectedDatasetTemplate(selectedDatasetTemplate)
    }
  }, [datasetTemplateRows, props.appState.datasetState])

  /** データセットテンプレートのラジオボタンクリック */
  const selectDatasetTemplateTableRadio = (row: number) => {
    const selectedDatasetTemplates = props.domainData.availableDatasetTemplates
    props.setSelectedDatasetTemplate(selectedDatasetTemplates[row])
  }

  /** データセットテンプレートテーブルの選択状態 */
  const selectedDatasetTemplateIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedDatasetTemplate)) {
      return -1
    }

    return props.domainData.availableDatasetTemplates.findIndex(
      (datasetTemplate) =>
        datasetTemplate.datasetTemplateId ===
        props.domainData.selectedDatasetTemplate?.datasetTemplateId
    )
  }, [
    props.algorithms,
    props.domainData.availableDatasetTemplates,
    props.domainData.selectedDatasetTemplate,
  ])

  return {
    selectedDatasetTemplateIndex,
    selectDatasetTemplateTableRadio,
    datasetTemplateRows,
  }
}
