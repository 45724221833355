import { AnnotationTrainKind } from 'state/app/domainData/types'
import { HttpsCallableResult } from 'firebase/functions'
import { Timestamp } from 'state/firebase'
import {
  AnnotationSetKind,
  GroupedData,
  I18nString,
  Version,
} from 'types/StateTypes'

export const DatasetDetailActionType = {
  SET_CURRENT_DATASET_DETAIL: 'SET_CURRENT_DATASET_DETAIL',
  SET_ANNOTATION_SET_LIST: 'SET_ANNOTATION_SET_LIST',
  SET_DATASET_DETAIL_STATE: 'SET_DATASET_DETAIL_STATE',
  CLEAR_DATASET_DETAIL_STATE: 'CLEAR_DATASET_DETAIL_STATE',
  SET_TOAST_INFO_FOR_DATASET_DETAIL: 'SET_TOAST_INFO_FOR_DATASET_DETAIL',
  SET_IN_PROGRESS_FOR_DATASET_DETAIL: 'SET_IN_PROGRESS_FOR_DATASET_DETAIL',
  SET_IN_PROGRESS_FOR_ANNOTATION_SET: 'SET_IN_PROGRESS_FOR_ANNOTATION_SET',
  SET_SELECTED_GROUPED_DATA_ID: 'SET_SELECTED_GROUPED_DATA_ID',
} as const

interface GetSignedUrlsResult extends HttpsCallableResult {
  readonly data: {
    [id: string]: string
  }
}

export type GetSignedUrlsFunctionType = (args: {
  fileInfoList: { id: string; fileName: string }[]
}) => Promise<GetSignedUrlsResult>

export interface AnnotationSetDocument {
  id: string
  'annotation-set-id': string
  'annotation-set-kind': AnnotationSetKind
  conditions?: {
    'train-kind'?: AnnotationTrainKind
  }
  'annotation-id': string
  'grouped-data-id': string
  'user-group-id': string
  'created-at': Timestamp
  'created-by': string
  'update-at': Timestamp
  'update-by': string
}

export interface Algorithm {
  algorithmId: string
  metadata: {
    name: I18nString
  }
}

export interface AnnotationFormat {
  annotationFormatId: string // アノテーションフォーマットID
  annotationFormatKind?: string // アノテーションフォーマット種別
  annotationFormatVersion?: Version
}

export interface DatasetTemplate {
  datasetTemplateId: string
  metadata: {
    name: I18nString
  }
}

export type DatasetType = 'Training' | 'Inference'

export type AnnotationSet = GroupedData

/**
 * アルゴリズム固有の情報
 */
export interface Extended {
  /** 物体クラス分類情報 */
  objectClassification?: {
    /** クラスセット情報 */
    classSet: {
      /** クラスセットID */
      classSetId: string
      /** クラスセット名 */
      classSetName: string
      /** ユーザーグループID */
      userGroupId: string
    }
  }
}

export interface CurrentDatasetDetail {
  algorithm: Algorithm
  annotationFormat: AnnotationFormat
  createdAt: Timestamp
  createdBy: string | { id: string; firstName: string; familyName: string }
  datasetId: string
  datasetName: string
  datasetRemarks: string
  datasetTemplate: DatasetTemplate
  generatedFor: DatasetType // データセットの用途
  selectedGroupedDataId?: string // 選択中の画像グループのID
  extended?: Extended
}

export interface DatasetDetailState {
  annotationFileNameSubState:
    | 'BeforeLoading'
    | 'Loaded'
    | 'NotFoundProcessed'
    | 'Failed'
  annotationFileSubState:
    | 'BeforeLoading'
    | 'Loaded'
    | 'NotFoundProcessed'
    | 'Failed'
  datasetState: 'BeforeLoading' | 'Loaded' | 'NotFoundProcessed' | 'Failed'
  annotationSetListSubState: 'BeforeLoading' | 'Loaded' | 'Failed'
  datasetZipSubState:
    | 'BeforeLoading'
    | 'Loaded'
    | 'NotFoundProcessed'
    | 'Failed'
  annotationSetZipDlSubState:
    | 'BeforeLoading'
    | 'Loaded'
    | 'NotFoundProcessed'
    | 'Failed'
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface DatasetDetailAppState {
  datasetDetailState: DatasetDetailState
  isInProgressForGettingDatasetDetail: boolean
  isInProgressForGettingAnnotationSet: boolean
  toastInfo: ToastInfo | undefined
}

export interface DatasetDetailDomainData {
  currentDatasetDetail: CurrentDatasetDetail
  annotationSetList: AnnotationSet[]
}

export interface DatasetDetail {
  appState: DatasetDetailAppState
  domainData: DatasetDetailDomainData
}
