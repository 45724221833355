import { ActionWithPayload, ActionsUnion, CreateAction } from 'state/utils'
import {
  FeatureDataTransferring,
  FeatureDataTransferringDisplayCondition,
  FeatureDataTransferringListActionType,
} from './types'
import { Action } from 'redux'

export type FeatureDataTransferringListActions = ActionsUnion<
  typeof featureDataTransferringListActions
>

/**
 * 特徴量データ転送指示一覧画面のアクション
 */
export const featureDataTransferringListActions = {
  /** 特徴量データ転送指示一覧を設定する */
  setList: (
    currentFeatureDataTransferringList: FeatureDataTransferring[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_TRANSFERRING_LIST',
    { currentFeatureDataTransferringList: FeatureDataTransferring[] }
  > =>
    CreateAction(
      FeatureDataTransferringListActionType.SET_FEATURE_DATA_TRANSFERRING_LIST,
      {
        currentFeatureDataTransferringList,
      }
    ),

  /** 特徴量データ転送指示一覧の表示条件を設定する */
  setListDisplayCondition: (
    featureDataTransferringDisplayCondition?: FeatureDataTransferringDisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION',
    {
      featureDataTransferringDisplayCondition?: FeatureDataTransferringDisplayCondition
    }
  > =>
    CreateAction(
      FeatureDataTransferringListActionType.SET_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION,
      {
        featureDataTransferringDisplayCondition,
      }
    ),

  /** 特徴量データ転送指示一覧の表示条件を削除する */
  clearListDisplayCondition:
    (): Action<'CLEAR_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION'> =>
      CreateAction(
        FeatureDataTransferringListActionType.CLEAR_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION
      ),

  /** ML Pileline ID一覧を設定する */
  setMLPipeLineIdList: (
    mlPipelineIds: string[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_TRANSFERRING_LIST',
    { mlPipelineIds: string[] }
  > =>
    CreateAction(
      FeatureDataTransferringListActionType.SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_TRANSFERRING_LIST,
      {
        mlPipelineIds,
      }
    ),

  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataTransferringListActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING_LIST,
      {
        inProgress,
      }
    ),

  /** スナップショットを設定する */
  setCurrentFeatureDataTransferringListSnapshot: (
    currentFeatureDataTransferringListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_FEATURE_DATA_TRANSFERRING_LIST_SNAPSHOT',
    { currentFeatureDataTransferringListSnapshot?: () => void }
  > =>
    CreateAction(
      FeatureDataTransferringListActionType.SET_CURRENT_FEATURE_DATA_TRANSFERRING_LIST_SNAPSHOT,
      {
        currentFeatureDataTransferringListSnapshot,
      }
    ),

  /** 特徴量データ転送指示一覧を削除する */
  clearList: (): Action<'CLEAR_FEATURE_DATA_TRANSFERRING_LIST_STATE'> =>
    CreateAction(
      FeatureDataTransferringListActionType.CLEAR_FEATURE_DATA_TRANSFERRING_LIST_STATE
    ),
}
