import React, { useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'

import { StatusProgressBarProps } from './types'
import { Box } from '@mui/material'

const useStyles = () =>
  makeStyles()((theme) => ({
    root: {
      width: '100%',
      height: theme.spacing(4),
    },
    content: {
      background: '#fff',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
    },
  }))()

export const StatusProgressBar: React.FC<StatusProgressBarProps> = (
  props: StatusProgressBarProps
) => {
  const { classes } = useStyles()

  const progressStyle = useMemo(() => {
    switch (props.progressColor) {
      case 'blue':
        return {
          border: '1px solid #3f51b5',
          color: '#3f51b5',
        }
      case 'red':
        return {
          border: '1px solid #f44336',
          color: '#f44336',
        }
      case 'green':
        return {
          border: '1px solid #8bc34a',
          color: '#618833',
          backgroundImage: `linear-gradient(90deg, rgba(139, 195, 74, 0.4) 0% ${props.progressRate}%, #fff ${props.progressRate}% 100%) !important`,
        }
      case 'grey':
        return {
          border: '1px solid #9e9e9e',
          color: '#9e9e9e',
        }
      default:
        return {
          border: '1px solid #8bc34a',
          color: '#618833',
          backgroundImage: `linear-gradient(90deg, rgba(139, 195, 74, 0.4) 0% ${props.progressRate}%, #fff ${props.progressRate}% 100%) !important`,
        }
    }
  }, [props.progressColor, props.progressRate])

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.content} sx={progressStyle}>
          <Typography variant='body1'>{props.status}</Typography>
        </Box>
      </Box>
    </>
  )
}
