import { RecaptchaVerifier } from 'firebase/auth'
import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import { MfaSettingActionType, ToastInfo } from './types'

export type MfaSettingAction = ActionsUnion<typeof mfaSettingActions>

export const mfaSettingActions = {
  setRecaptchaVerifier: (
    recaptchaVerifier: RecaptchaVerifier | undefined
  ): ActionWithPayload<
    'SET_RECAPCHA_VERIFIER',
    {
      recaptchaVerifier: RecaptchaVerifier | undefined
    }
  > =>
    CreateAction(MfaSettingActionType.SET_RECAPCHA_VERIFIER, {
      recaptchaVerifier,
    }),
  setFirstLoginStateKind: (
    mfaSettingStateKind: string
  ): ActionWithPayload<
    'SET_MFA_SETTING_STATE',
    {
      mfaSettingStateKind: string
    }
  > =>
    CreateAction(MfaSettingActionType.SET_MFA_SETTING_STATE, {
      mfaSettingStateKind,
    }),
  setMfaSettingStateCompleted: (): Action<'MFA_SETTING_COMPLETED'> =>
    CreateAction(MfaSettingActionType.MFA_SETTING_COMPLETED),
  setVerificationId: (
    verificationId: string
  ): ActionWithPayload<
    'SET_VERIFICATION_ID',
    {
      verificationId: string
    }
  > =>
    CreateAction(MfaSettingActionType.SET_VERIFICATION_ID, {
      verificationId,
    }),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_MFA_SETTING',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(MfaSettingActionType.SET_TOAST_INFO_FOR_MFA_SETTING, {
      toastInfo,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MFA_SETTING',
    { inProgress: boolean }
  > =>
    CreateAction(MfaSettingActionType.SET_IN_PROGRESS_FOR_MFA_SETTING, {
      inProgress,
    }),
}
