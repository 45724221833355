import React from 'react'
import IconButton from '@mui/material/IconButton'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'

import { PhotoCameraIconProps } from './types'

export const PhotoCamera: React.FC<PhotoCameraIconProps> = (
  props: PhotoCameraIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
    component='div'
  >
    <PhotoCameraIcon sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
