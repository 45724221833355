import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { LogoIconProps } from './types'

import Logo from './img/logo.png'
import Box from '@mui/material/Box'

const useStyles = makeStyles()(() => ({
  logoImage: {
    width: 128,
  },
}))
export const LogoIcon: React.FC<LogoIconProps> = (props: LogoIconProps) => {
  const { classes } = useStyles()

  return (
    <Box
      display='flex'
      className={props.className}
      data-testid={props['data-testid']}
    >
      <img src={Logo} alt={'logo'} className={classes.logoImage} />
    </Box>
  )
}
