import { FeatureDataGeneratingMLPipelineQueryDocument } from './types'

import {
  mlPipelineDocumentSchema,
  preReleaseVersionSchema,
  timeStampSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** ml-pipeline-query (特徴量データ生成) documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as FeatureDataGeneratingMLPipelineQueryDocument
  const schema = {
    ...mlPipelineDocumentSchema,
    required: [...mlPipelineDocumentSchema.required, 'training-step'],
  }

  const trainingStepSchema = {
    required: [
      'ended-at',
      'started-at',
      'step-id',
      'step-kind',
      'step-status',
      'src',
    ],
    type: 'object',
    properties: {
      'ended-at': {
        ...timeStampSchema,
      },
      'started-at': {
        ...timeStampSchema,
      },
      'step-id': {
        type: 'string',
      },
      'step-kind': {
        type: 'string',
      },
      'step-status': {
        type: 'string',
      },
      src: {
        required: [
          'algorithm-id',
          'annotation-set-list',
          'dataset-id',
          'setting-id',
          'training-algorithm-version',
        ],
        type: 'object',
        properties: {
          'algorithm-id': {
            type: 'string',
          },
          'algorithm-sructure-id': {
            type: 'string',
          },
          'algorithm-sructure-version': {
            ...versionSchema,
          },
          'annotation-set-list': {
            type: 'array',
            items: {
              required: [
                'annotation-id',
                'annotation-set-id',
                'annotation-set-kind',
                'grouped-data-id',
              ],
              type: 'object',
              properties: {
                'annotation-id': {
                  type: 'string',
                },
                'annotation-set-id': {
                  type: 'string',
                },
                'annotation-set-kind': {
                  type: 'string',
                },
                'grouped-data-id': {
                  type: 'string',
                },
              },
            },
          },
          'dataset-id': {
            type: 'string',
          },
          'setting-id': {
            type: 'string',
          },
          'training-algorithm-version': {
            ...preReleaseVersionSchema,
          },
        },
      },
      dest: {
        required: [],
        type: 'object',
        properties: {
          'feature-data-group-id': {
            type: 'string',
          },
          'feature-data-group-version': {
            type: 'object',
          },
          'feature-data-id': {
            type: 'string',
          },
        },
      },
    },
  }
  const validationSequences = [
    { schema: schema },
    {
      schema: trainingStepSchema,
      dataIndex: 'training-step',
    },
  ]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'ml-pipeline-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
