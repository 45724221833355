import { FeatureDataDetailAction } from './actions'
import { FeatureDataDetailActionType, FeatureDataDetail } from './types'

const initialState: FeatureDataDetail = {
  domainData: {
    currentFeatureDataDetail: {
      featureDataGroupId: '',
      featureDataGroupName: '',
      featureDataVersion: '',
      relatedFeatureDataList: [],
      featureDataListDisplayCondition: {
        sortKey: 'featureDataVersion',
        sortOrder: 'desc',
        displayNumber: 10,
        pageNumber: 0,
      },
      featureDataId: '',
      featureDataName: '',
      featureDataRemarks: '',
      evaluationStatus: 'Ne',
      datasetList: [],
      setting: {
        settingId: '',
        settingName: '',
      },
      mlPipeline: {
        mlPipelineId: '',
        mlPipelineName: '',
      },
      trainingAlgorithm: {
        algorithmId: '',
        metadata: {
          name: {
            ja: '',
            en: '',
          },
        },
        trainingAlgorithmVersion: '',
      },
      createdAt: undefined,
      createdUserName: {
        firstName: '',
        familyName: '',
      },
    },
    featureDataDlLinks: [],
    mlPipelineGenerateFiles: [],
    mlPipelineList: [],
    mlPipelineListDisplayCondition: {
      sortKey: 'startedAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    inProgress: false,
    isInProgressForDownloading: false,
    isInProgressForGettingFiles: false,
    isInProgressForEventHistories: false,
    featureDataDetailState: {
      featureDataGenerateFileSubState: 'BeforeLoading',
      featureDataDlLinkSubState: 'BeforeLoading',
      featureDataDataState: 'BeforeLoading',
      featureDataEventHistoryState: 'BeforeLoading',
    },
    toastInfo: undefined,
    relatedFeatureDataTableNextPageSubState: 'Unable',
    mlPipelineListTableNextPageSubState: 'Unable',
  },
}

export const FeatureDataDetailReducer = (
  state: FeatureDataDetail = initialState,
  action: FeatureDataDetailAction
): FeatureDataDetail => {
  switch (action.type) {
    case FeatureDataDetailActionType.SET_CURRENT_FEATURE_DATA_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataDetail: action.payload.currentFeatureDataDetail,
        },
      }
    case FeatureDataDetailActionType.SET_FEATURE_DATA_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataDetailState: action.payload.featureDataDetailState,
        },
      }
    case FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_DOWNLOADING:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForDownloading: action.payload.isInProgressForDownloading,
        },
      }
    case FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_GETTING_FILES:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForGettingFiles:
            action.payload.isInProgressForGettingGenerateFiles,
        },
      }
    case FeatureDataDetailActionType.SET_IN_PROGRESS_FOR_EVENT_HISTORIES_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInProgressForEventHistories:
            action.payload.isInProgressForEventHistories,
        },
      }
    case FeatureDataDetailActionType.CLEAR_FEATURE_DATA_DETAIL_STATE:
      return {
        ...initialState,
      }
    case FeatureDataDetailActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case FeatureDataDetailActionType.SET_FEATURE_DATA_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataDetail: {
            ...state.domainData.currentFeatureDataDetail,
            featureDataListDisplayCondition: action.payload.condition,
          },
        },
      }
    case FeatureDataDetailActionType.SET_RELATED_FEATURE_DATA_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          relatedFeatureDataTableNextPageSubState:
            action.payload.nextPageSubState,
        },
      }
    case FeatureDataDetailActionType.SET_FEATURE_DATA_DL_LINKS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataDlLinks: action.payload.featureDataDlLinks,
        },
      }
    case FeatureDataDetailActionType.SET_ML_PIPELINE_GENERATE_FILES_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelineGenerateFiles: action.payload.mlPipelineGenerateFiles,
        },
      }
    case FeatureDataDetailActionType.SET_ML_PIPELINE_LIST_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelineList: action.payload.mlPipelineList,
        },
      }
    case FeatureDataDetailActionType.SET_ML_PIPELINE_LIST_DISPLAY_CONDITION_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelineListDisplayCondition: action.payload.condition,
        },
      }
    case FeatureDataDetailActionType.SET_ML_PIPELINE_LIST_TABLE_NEXT_PAGE_SUB_STATE_FOR_FEATURE_DATA_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          mlPipelineListTableNextPageSubState: action.payload.nextPageSubState,
        },
      }
    default:
      return state
  }
}
