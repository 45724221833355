import React from 'react'
import VideocamOutlined from '@mui/icons-material/VideocamOutlined'
import IconButton from '@mui/material/IconButton'

import { SceneCameraIconProps } from './types'

export const SceneCameraIcon: React.FC<SceneCameraIconProps> = (
  props: SceneCameraIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <VideocamOutlined sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
