import React, { FC, useMemo } from 'react'
import { CustomerAuthorizerProps } from './types'
import { hasAuthCustomerFunction } from 'utils/auth'

/**
 * customer毎の権限によってchildrenの表示/非表示を管理する
 */
export const CustomerAuthorizer: FC<CustomerAuthorizerProps> = (props) => {
  const { isAuthed } = useCustomerAuthorizer(props)
  return isAuthed ? <>{props.children}</> : <></>
}

const useCustomerAuthorizer = (props: CustomerAuthorizerProps) => {
  const { auth, type } = props

  const isAuthed = useMemo(
    () => hasAuthCustomerFunction({ auth, type }),
    [props.type, props.auth]
  )

  return { isAuthed }
}
