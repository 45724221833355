export const AccountEntryActionType = {
  SET_TOAST_INFO_FOR_ACCOUNT_ENTRY: 'SET_TOAST_INFO_FOR_ACCOUNT_ENTRY',
  SET_IN_PROGRESS_FOR_ACCOUNT_ENTRY: 'SET_IN_PROGRESS_FOR_ACCOUNT_ENTRY',
  SET_ACCOUNT_ENTRY_RESULT_FOR_ACCOUNT_ENTRY:
    'SET_ACCOUNT_ENTRY_RESULT_FOR_ACCOUNT_ENTRY',
  SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_ENTRY:
    'SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_ENTRY',
  CLEAR_ACCOUNT_ENTRY_STATE: 'CLEAR_ACCOUNT_ENTRY_STATE',
} as const

export interface AccountGroupCustomer {
  customerId: string
}

export interface AccountGroup {
  accountGroupId: string
  name: string
  customerList: AccountGroupCustomer[]
}

export interface AccountEntryDomainData {
  accountGroupList: AccountGroup[]
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface AccountEntryAppState {
  inProgress: boolean
  toastInfo: ToastInfo | undefined
  accountEntryResult: boolean
}

export interface AccountEntryState {
  domainData: AccountEntryDomainData
  appState: AccountEntryAppState
}
