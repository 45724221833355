import { ModelGroupQueryDocument } from './types'

import {
  extendedObjectClassificationForQuerySchema,
  rawVersionSchema,
  timeStampSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** trained-model-group-query documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as ModelGroupQueryDocument
  const schema = {
    required: [
      'algorithm-id',
      'icon-filetype',
      'icon-name',
      'overview',
      'remarks',
      'trained-model-count',
      'trained-model-group-id',
      'trained-model-group-name',
      'trained-model-group-version-latest',
      'trained-model-list',
      'user-group-id',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'icon-filetype': {
        type: 'string',
      },
      'icon-name': {
        type: 'string',
      },
      overview: {
        type: 'string',
      },
      remarks: {
        type: 'string',
      },
      'trained-model-count': {
        type: 'integer',
      },
      'trained-model-group-id': {
        type: 'string',
      },
      'trained-model-group-name': {
        type: 'string',
      },
      'trained-model-group-version-latest': {
        ...rawVersionSchema,
      },
      'trained-model-list': {
        type: 'array',
        items: {
          required: [
            'trained-model-group-version',
            'trained-model-id',
            'trained-model-name',
            'transaction-status',
          ],
          type: 'object',
          properties: {
            'trained-model-group-version': {
              ...versionSchema,
            },
            'trained-model-id': {
              type: 'string',
            },
            'trained-model-name': {
              type: 'string',
            },
            'transaction-status': {
              type: 'string',
            },
          },
        },
      },
      constraint: {
        type: 'object',
        properties: {
          setting: {
            type: 'object',
            properties: {
              'setting-group-id': {
                type: 'string',
              },
              'user-group-id': {
                type: 'string',
              },
            },
          },
        },
      },
      'user-group-id': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
      extended: {
        type: 'object',
        properties: {
          'object-classification': {
            ...extendedObjectClassificationForQuerySchema,
          },
        },
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'trained-model-group-query',
      value: data as unknown as DataObject,
    })
    return false
  }
}
