import { TrainingAlgorithmVersionDocument } from './types'
import {
  preReleaseVersionSchema,
  timeStampSchema,
  versionRangeSchema,
  versionSchema,
} from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** training/algorithm-version documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as TrainingAlgorithmVersionDocument
  const schema = {
    required: [
      'algorithm-id',
      'algorithm-structure-relation-id',
      'algorithm-version',
      'annotation-format-id',
      'annotation-format-version',
      'available-version',
      'inference-code-version',
      'metadata',
      'released-at',
      'training-algorithm-version',
    ],
    type: 'object',
    properties: {
      'algorithm-id': {
        type: 'string',
      },
      'algorithm-structure-relation-id': {
        type: 'string',
      },
      'algorithm-version': {
        ...preReleaseVersionSchema,
      },
      'annotation-format-id': {
        type: 'string',
      },
      'annotation-format-version': {
        ...versionSchema,
      },
      'available-version': {
        required: ['trained-model'],
        type: 'object',
        properties: {
          'trained-model': {
            ...versionRangeSchema,
          },
        },
      },
      'commit-hash': {
        type: 'string',
      },
      'inference-code-version': {
        ...versionSchema,
      },
      metadata: {
        required: ['remarks'],
        type: 'object',
        properties: {
          remarks: {
            required: ['en', 'ja'],
            type: 'object',
            properties: {
              en: {
                type: 'string',
              },
              ja: {
                type: 'string',
              },
            },
          },
        },
      },
      'ml-framework': {
        type: 'object',
      },
      'released-at': {
        ...timeStampSchema,
      },
      'training-algorithm-version': {
        type: 'string',
      },
    },
  }
  const validationSequences = [{ schema: schema }]

  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'training-algorithm-version',
      value: data as unknown as DataObject,
    })
    return false
  }
}
