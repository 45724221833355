export const RobotDataAnalysisEntryActionType = {
  SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
    'SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
  SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
    'SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
  SET_MODEL_GROUP_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
    'SET_MODEL_GROUP_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
  SET_QUERY_CONDITIONS_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
    'SET_QUERY_CONDITIONS_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
  SET_COUNT_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
    'SET_COUNT_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
  SET_USER_INPUT_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY:
    'SET_USER_INPUT_LIST_FOR_ROBOT_DATA_ANALYSIS_ENTRY',
  CLEAR_ROBOT_DATA_ANALYSIS_ENTRY_STATE:
    'CLEAR_ROBOT_DATA_ANALYSIS_ENTRY_STATE',
} as const

export interface ExecutionStatus {
  success?: boolean
  fail?: boolean
  error?: boolean
}

export interface ExecutionDate {
  from?: string
  to?: string
}
export interface ModelGroup {
  modelGroupId: string
  modelGroupName: string
}

export interface QueryConditions {
  executionStatus: ExecutionStatus
  executionDate?: ExecutionDate
  modelGroupIdList?: string[]
  executionIdList?: string[]
  robotIdList?: string[]
  confirmed: boolean
  objectRecognitionResults: boolean
}

export interface Count {
  countNumber: number
  isOver: boolean
}

export interface RobotDataAnalysisEntryDomainData {
  modelGroupList: ModelGroup[]
  queryConditions: QueryConditions
  count: Count
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface UserInput {
  name: string
  isValid: boolean
}

export interface RobotDataAnalysisEntryAppState {
  inProgress: boolean
  toastInfo: ToastInfo | undefined
  userInputList: UserInput[]
}

export interface RobotDataAnalysisEntryState {
  domainData: RobotDataAnalysisEntryDomainData
  appState: RobotDataAnalysisEntryAppState
}
