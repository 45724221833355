import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'

import {
  ImageSet,
  ImageSetState,
  CreateImageSetActionType,
  AnnotationSet,
} from './types'

export type CreateImageSetAction = ActionsUnion<typeof createImageSetActions>
export const createImageSetActions = {
  addAnnotation: (
    annotation: AnnotationSet
  ): ActionWithPayload<'ADD_ANNOTATION_SET', { annotation: AnnotationSet }> =>
    CreateAction(CreateImageSetActionType.ADD_ANNOTATION_SET, {
      annotation,
    }),
  addImageSetFile: (
    files: File[]
  ): ActionWithPayload<'ADD_IMAGE_SET_FILE', { files: File[] }> =>
    CreateAction(CreateImageSetActionType.ADD_IMAGE_SET_FILE, {
      files,
    }),
  setImageSetState: (
    imageSetState: ImageSetState
  ): ActionWithPayload<
    'SET_IMAGE_SET_STATE',
    { imageSetState: ImageSetState }
  > =>
    CreateAction(CreateImageSetActionType.SET_IMAGE_SET_STATE, {
      imageSetState,
    }),
  setImageSetMetadataName: (
    name?: string
  ): ActionWithPayload<'SET_IMAGE_SET_METADATA_NAME', { name?: string }> =>
    CreateAction(CreateImageSetActionType.SET_IMAGE_SET_METADATA_NAME, {
      name,
    }),
  setImageSetMetadataRemarks: (
    remarks?: string
  ): ActionWithPayload<
    'SET_IMAGE_SET_METADATA_REMARKS',
    { remarks?: string }
  > =>
    CreateAction(CreateImageSetActionType.SET_IMAGE_SET_METADATA_REMARKS, {
      remarks,
    }),
  createImageSet: (
    imageSet: ImageSet
  ): ActionWithPayload<'CREATE_IMAGE_SET', { imageSet: ImageSet }> =>
    CreateAction(CreateImageSetActionType.CREATE_IMAGE_SET, {
      imageSet,
    }),
  setGroupedDataId: (
    groupedDataId: string
  ): ActionWithPayload<'SET_GROUPED_DATA_ID', { groupedDataId: string }> =>
    CreateAction(CreateImageSetActionType.SET_GROUPED_DATA_ID, {
      groupedDataId,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_CREATE_IMAGE_SET',
    { inProgress: boolean }
  > =>
    CreateAction(
      CreateImageSetActionType.SET_IN_PROGRESS_FOR_CREATE_IMAGE_SET,
      {
        inProgress,
      }
    ),
  clearImageSetState: (): Action<'CLEAR_IMAGE_SET_STATE'> =>
    CreateAction(CreateImageSetActionType.CLEAR_IMAGE_SET_STATE),
  setAlgorithmSubState: (
    algorithmSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_ALGORITHM_SUB_STATE_FOR_CREATE_IMAGE_SET',
    { algorithmSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      CreateImageSetActionType.SET_ALGORITHM_SUB_STATE_FOR_CREATE_IMAGE_SET,
      {
        algorithmSubState,
      }
    ),
  setSelectedAlgorithm: (
    algorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_ID_FOR_CREATE_IMAGE_SET',
    { algorithmId: string }
  > =>
    CreateAction(
      CreateImageSetActionType.SET_SELECTED_ALGORITHM_ID_FOR_CREATE_IMAGE_SET,
      {
        algorithmId,
      }
    ),
}
