interface CheckedRobotData {
  [x: string]: { [x: string]: string[] }
}
interface CheckedAnnotationData {
  [x: string]: { [x: string]: string[] }
}

export const setCheckedRobotData = (
  accountGroupId: string,
  accountId: string,
  userGroupId: string,
  data: CheckedRobotData
) => {
  const key = `robot-data-analysis.extracted-images.checked.${accountGroupId}.${accountId}.${userGroupId}`

  localStorage.setItem(key, JSON.stringify(data))
}

export const getCheckedRobotData = (
  accountGroupId: string,
  accountId: string,
  userGroupId: string
) => {
  const key = `robot-data-analysis.extracted-images.checked.${accountGroupId}.${accountId}.${userGroupId}`

  const checkedRobotData = localStorage.getItem(key)

  return checkedRobotData !== null
    ? JSON.parse(checkedRobotData)
    : checkedRobotData
}

export const setCheckedAnnotationData = (
  accountId: string,
  userGroupId: string,
  data: CheckedAnnotationData
) => {
  const key = `annotation-set.training-data.checked.${accountId}.${userGroupId}`

  localStorage.setItem(key, JSON.stringify(data))
}

export const getCheckedAnnotationData = (
  accountId: string,
  userGroupId: string
) => {
  const key = `annotation-set.training-data.checked.${accountId}.${userGroupId}`

  const checkedAnnotationData = localStorage.getItem(key)

  return checkedAnnotationData !== null
    ? JSON.parse(checkedAnnotationData)
    : checkedAnnotationData
}
