import { Timestamp } from 'state/firebase'
import { ResourceLoadingState } from 'state/utils'
import { Version } from 'types/StateTypes'

export const SceneCameraDetailActionType = {
  SET_CURRENT_SCENE_CAMERA_DETAIL: 'SET_CURRENT_SCENE_CAMERA_DETAIL',
  CLEAR_SCENE_CAMERA_DETAIL_STATE: 'CLEAR_SCENE_CAMERA_DETAIL_STATE',
  SET_SCENE_CAMERA_DETAIL_SUB_STATE: 'SET_SCENE_CAMERA_DETAIL_SUB_STATE',
  SET_IN_PROGRESS_FOR_SCENE_CAMERA_DETAIL:
    'SET_IN_PROGRESS_FOR_SCENE_CAMERA_DETAIL',
} as const

export interface CurrentSceneCameraDetail {
  id: string
  name: string
  revision: number
  overview: string
  createdAt: Timestamp
  createdBy: string
  location: number[]
  fov: number[]
  lookAt: number[]
  formatVersion?: Version
}

export interface SceneCameraDetailDomainData {
  currentSceneCameraDetail: CurrentSceneCameraDetail
}

export interface SceneCameraDetailSubState {
  sceneCameraDataSubState: ResourceLoadingState
}

export interface SceneCameraDetailAppState {
  inProgress: boolean
  sceneCameraDetailSubState: SceneCameraDetailSubState
}

export interface SceneCameraDetailState {
  domainData: SceneCameraDetailDomainData
  appState: SceneCameraDetailAppState
}
