import { FeatureDataGeneratingDetailAction } from './actions'
import {
  FeatureDataGeneratingDetailActionType,
  FeatureDataGeneratingDetail,
} from './types'

const initialState: FeatureDataGeneratingDetail = {
  domainData: {
    currentFeatureDataGeneratingDetail: undefined,
    featureDataDlLinks: [],
    mlPipelineLogFiles: [],
  },
  appState: {
    featureDataGeneratingDetailState: {
      mlPipelineDataState: 'BeforeLoading',
      featureDataDlLinkSubState: 'BeforeLoading',
      mlPipelineLogSubState: 'BeforeLoading',
    },
    toastInfo: undefined,
    inProgress: false,
  },
}

export const FeatureDataGeneratingDetailReducer = (
  state: FeatureDataGeneratingDetail = initialState,
  action: FeatureDataGeneratingDetailAction
): FeatureDataGeneratingDetail => {
  switch (action.type) {
    case FeatureDataGeneratingDetailActionType.SET_CURRENT_FEATURE_DATA_GENERATING_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataGeneratingDetail:
            action.payload.currentFeatureDataGeneratingDetail,
        },
      }
    case FeatureDataGeneratingDetailActionType.SET_FEATURE_DATA_GENERATING_DETAIL_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGeneratingDetailState:
            action.payload.featureDataGeneratingDetailState,
        },
      }
    case FeatureDataGeneratingDetailActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataGeneratingDetailActionType.CLEAR_FEATURE_DATA_GENERATING_DETAIL_STATE:
      return {
        ...initialState,
      }
    case FeatureDataGeneratingDetailActionType.SET_TOAST_INFO_FOT_FEATURE_DATA_GENERATING:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case FeatureDataGeneratingDetailActionType.SET_FEATURE_DATA_DL_LINKS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataDlLinks: action.payload.featureDataDlLinks,
        },
      }
    case FeatureDataGeneratingDetailActionType.SET_ML_PIPELINE_LOG_FILES_FOR_FEATURE_DATA_GENERATING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelineLogFiles: action.payload.mlPipelineLogFiles,
        },
      }
    default:
      return state
  }
}
