import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  ClassSet,
  ClassSetDisplayCondition,
  ModelGroupEntryActionType,
  ModelGroupEntryStateKind,
  ToastInfo,
} from './types'

export type ModelGroupEntryAction = ActionsUnion<typeof modelGroupEntryActions>

export const modelGroupEntryActions = {
  setSelectedAlgorithm: (
    selectedAlgorithm: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM',
    { selectedAlgorithm: string }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_SELECTED_ALGORITHM, {
      selectedAlgorithm,
    }),
  setSelectedTrainedModelKind: (
    selectedTrainedModelKind?: 'Custom' | 'Specified'
  ): ActionWithPayload<
    'SET_SELECTED_MODEL_KIND',
    { selectedTrainedModelKind?: 'Custom' | 'Specified' }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_SELECTED_MODEL_KIND, {
      selectedTrainedModelKind,
    }),
  setModelGroupMetadataName: (
    modelGroupName: string
  ): ActionWithPayload<
    'SET_MODEL_GROUP_META_DATA_NAME',
    { modelGroupName: string }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_NAME, {
      modelGroupName,
    }),
  setModelGroupOverView: (
    overView: string
  ): ActionWithPayload<
    'SET_MODEL_GROUP_META_DATA_OVER_VIEW',
    { overView: string }
  > =>
    CreateAction(
      ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_OVER_VIEW,
      {
        overView,
      }
    ),
  setModelGroupIcon: (
    modelGroupIcon: File | undefined
  ): ActionWithPayload<
    'SET_MODEL_GROUP_META_DATA_ICON',
    { modelGroupIcon: File | undefined }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_ICON, {
      modelGroupIcon,
    }),
  setModelGroupRemarks: (
    remarks?: string
  ): ActionWithPayload<
    'SET_MODEL_GROUP_META_DATA_REMARKS',
    { remarks?: string }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_REMARKS, {
      remarks,
    }),
  setModelGroupState: (
    modelGroupEntryStateKind: ModelGroupEntryStateKind
  ): ActionWithPayload<
    'SET_MODEL_GROUP_STATE',
    { modelGroupEntryStateKind: ModelGroupEntryStateKind }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_MODEL_GROUP_STATE, {
      modelGroupEntryStateKind,
    }),
  setAlgorithmSubState: (
    algorithmSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_ALGORITHM_SUB_STATE',
    { algorithmSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_ALGORITHM_SUB_STATE, {
      algorithmSubState,
    }),
  setMetadataSubState: (
    metadataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_METADATA_SUB_STATE',
    { metadataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_METADATA_SUB_STATE, {
      metadataSubState,
    }),
  setExecuteSubState: (
    executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError'
  ): ActionWithPayload<
    'SET_EXECUTE_MODEL_GROUP_SUB_STATE',
    { executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError' }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_EXECUTE_MODEL_GROUP_SUB_STATE, {
      executeSubState,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MODEL_GROUP_ENTRY',
    { inProgress: boolean }
  > =>
    CreateAction(
      ModelGroupEntryActionType.SET_IN_PROGRESS_FOR_MODEL_GROUP_ENTRY,
      {
        inProgress,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_MODEL_GROUP_ENTRY',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      ModelGroupEntryActionType.SET_TOAST_INFO_FOR_MODEL_GROUP_ENTRY,
      {
        toastInfo,
      }
    ),
  setExecutedModeGroupId: (
    executedModeGroupId: string
  ): ActionWithPayload<
    'SET_EXECUTE_MODEL_GROUP_ID',
    { executedModeGroupId: string }
  > =>
    CreateAction(ModelGroupEntryActionType.SET_EXECUTE_MODEL_GROUP_ID, {
      executedModeGroupId,
    }),
  clearModelGroupEntryState: (): Action<'CLEAR_MODEL_GROUP_ENTRY_STATE'> =>
    CreateAction(ModelGroupEntryActionType.CLEAR_MODEL_GROUP_ENTRY_STATE),

  /** クラスセット一覧を設定する */
  setClassSets: (
    classSets: ClassSet[]
  ): ActionWithPayload<
    'SET_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY',
    { classSets: ClassSet[] }
  > =>
    CreateAction(
      ModelGroupEntryActionType.SET_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY,
      {
        classSets,
      }
    ),

  /** クラスセット一覧をクリアする */
  clearClassSets: (): Action<'CLEAR_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY'> =>
    CreateAction(
      ModelGroupEntryActionType.CLEAR_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY
    ),

  /** 選択中のクラスセットを設定する */
  setSelectedClassSet: (
    classSet?: ClassSet
  ): ActionWithPayload<
    'SET_SELECTED_CLASS_SET_FOR_MODEL_GROUP_ENTRY',
    { classSet?: ClassSet }
  > =>
    CreateAction(
      ModelGroupEntryActionType.SET_SELECTED_CLASS_SET_FOR_MODEL_GROUP_ENTRY,
      {
        classSet,
      }
    ),

  /** クラスセットの表示条件をクリアする */
  clearClassSetDisplayCondition:
    (): Action<'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY'> =>
      CreateAction(
        ModelGroupEntryActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY
      ),

  /** クラスセットの表示条件を設定する */
  setClassSetDisplayCondition: (
    displayCondition: ClassSetDisplayCondition
  ): ActionWithPayload<
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY',
    { displayCondition: ClassSetDisplayCondition }
  > =>
    CreateAction(
      ModelGroupEntryActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY,
      {
        displayCondition,
      }
    ),

  /** クラスセット選択ステップのステータスを設定する */
  setClassSetSubState: (
    classSetSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_CLASS_SET_SUB_STATE_FOR_MODEL_GROUP_ENTRY',
    { classSetSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      ModelGroupEntryActionType.SET_CLASS_SET_SUB_STATE_FOR_MODEL_GROUP_ENTRY,
      {
        classSetSubState,
      }
    ),
}
