import React from 'react'

import { ModelTrainingOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'

import { TrainingIconProps } from './types'

export const TrainingIcon: React.FC<TrainingIconProps> = (
  props: TrainingIconProps
) => (
  <IconButton
    className={props.className}
    data-testid={props['data-testid']}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <ModelTrainingOutlined sx={{ color: 'text.primary' }} fontSize='large' />
  </IconButton>
)
