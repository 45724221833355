import type { AxiosResponse } from 'axios'

import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import {
  getAxiosInstance,
  AidpCloudFunctionsUrl,
  getArrayChunk,
} from 'state/utils'
import { GetSignedUrlsFunctionType } from './types'

const timeoutLimitSeconds = 15
const timeoutLimitSecondsForGetZipSignedUrl = 540

export const GetObjectApi = {
  get: (url: string): Promise<AxiosResponse<Blob>> =>
    getAxiosInstance().get(url, {
      responseType: 'blob',
    }),
}

export const DatasetDetailApi = {
  getAnnotationFile: (annotationId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'annotation-file',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      annotationId: annotationId,
    }),
  // Cloud Function
  getSignedUrls: async (
    fileInfoList: { id: string; fileName: string }[],
    action: 'read' | 'delete',
    type: 'thumbnail' | 'original'
  ): Promise<{ [id: string]: string }> => {
    // signedUrl取得の単位を500ファイルずつに分割
    const fileInfoListChunks = getArrayChunk(fileInfoList, 500)

    // 取得
    const signedUrlPromises: Promise<{
      [id: string]: string
    }>[] = fileInfoListChunks.map(async (fileInfoListChunk) => {
      const getReadableSignedUrlRecordsFunction: GetSignedUrlsFunctionType =
        httpsCallable(
          functionsInstance,
          `${AidpCloudFunctionsUrl()}data-manipulation-${action}-${type}`
        )
      return (
        await getReadableSignedUrlRecordsFunction({
          fileInfoList: fileInfoListChunk,
        })
      ).data
    })

    return Object.assign({}, ...(await Promise.all(signedUrlPromises)))
  },
  downloadZip: async (
    accountGroupId: string,
    accountId: string,
    userGroupId: string,
    datasetId?: string,
    annotationSetId?: string
  ): Promise<HttpsCallableResult<{ signedUrl: string; fileName: string }>> =>
    httpsCallable<
      {
        accountGroupId: string
        accountId: string
        userGroupId: string
        datasetId?: string
        annotationSetId?: string
      },
      { signedUrl: string; fileName: string }
    >(functionsInstance, AidpCloudFunctionsUrl() + 'zip-download', {
      timeout: timeoutLimitSecondsForGetZipSignedUrl * 1000,
    })({
      accountGroupId,
      accountId,
      userGroupId,
      datasetId,
      annotationSetId,
    }),
}
