import axios from 'axios'
import { Dispatch } from 'redux'
import {
  getDatasetQueryCollection,
  getFeatureDataMetadataCollection,
  getFeatureDataQueriesCollection,
  getFeatureDataGroupQueriesCollection,
  getAccountSettingCollection,
  getSettingsMetaDataCollection,
  getMlPipelineQueriesCollection,
} from 'state/firebase'
import { featureDataDetailActions } from './'
import {
  FeatureDataDlLink,
  GetFeatureDataFilesResponse,
  RelatedFeatureData,
  Dataset,
  MlPipelineGenerateFile,
  EventHistoryData,
} from './types'
import { FeatureDataDetailApi } from './apis'
import { isObject, isString, isNumber, isArray } from 'utils/typeguard'
import { compareVersions } from 'utils/versions'
import { State } from 'state/store'
import { saveAs } from 'file-saver'
import {
  Timestamp,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataGeneratingMLPipelineQueryDocument } from 'utils/fireStore/featureDataGeneratingMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataTransFerringMLPipelineQueryDocument } from 'utils/fireStore/featureDataTransferringMLPipelineQuery'
import { MediaLink } from 'types/StateTypes'
import { isGetResultFileResponse } from 'state/utils/typeguard'
import { domainDataOperations } from 'state/app/domainData/operations'
import {
  TransactionStatusKind,
  convertProgressRateByTransactionStatusForFeatureDataGenerating,
  convertProgressRateByTransactionStatusForFeatureDataTransferring,
} from 'state/utils'

type FeatureDataVersion = {
  ['display-name']: string
  ['major']: number
  ['minor']: number
  ['patch']: number
}

type FeatureData = {
  ['feature-data-group-version']: FeatureDataVersion
  ['feature-data-id']: string
  ['feature-data-name']: string
}

const getFeatureDataList = (list: FeatureData[]): RelatedFeatureData[] => {
  const featureDataList = list.map((item) => {
    return {
      featureDataId: item ? item['feature-data-id'] : '',
      featureDataVersion: {
        displayName: item
          ? item['feature-data-group-version']['display-name']
          : '',
        major: item ? item['feature-data-group-version']['major'] : 0,
        minor: item ? item['feature-data-group-version']['minor'] : 0,
        patch: item ? item['feature-data-group-version']['patch'] : 0,
      },
      featureDataName: item ? item['feature-data-name'] : '',
    }
  })
  if (featureDataList.length === 1) return featureDataList
  // バージョンでソートして返す
  return featureDataList.sort((a, b) =>
    compareVersions(a.featureDataVersion, b.featureDataVersion, 'desc')
  )
}
/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    featureDataDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

/** MediaLink[]かどうか */
function isMediaLinks(object: unknown): object is MediaLink[] {
  return (
    isArray(object) &&
    object.every((element) => {
      return (
        isString(element.mediaName) &&
        isString(element.mediaUrl) &&
        isNumber(element.mediaSize)
      )
    })
  )
}

/** GetFeatureDataFilesResponseかどうか */
function isGetFeatureDataFilesResponse(
  object: unknown
): object is GetFeatureDataFilesResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isString(element.linkName) && isMediaLinks(element.mediaLinks)
    })
  )
}

export const FeatureDataDetailOperations = {
  /** 特徴量データ詳細で表示パラメータを取得する */
  getFeatureDataDetail:
    (featureDataId: string, isSharedUserGroup: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataDetailActions.setInProgress(true))
        const userGroupId = isSharedUserGroup
          ? domainDataOperations.getSharedUserGroupId()(dispatch, getState)
          : getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        /** feature-data-query document */
        const featureDataQuery = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getFeatureDataQueriesCollection(userGroupId),
                  where('feature-data-id', '==', featureDataId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((featureData) => featureData.data())[0]
          : (
              await getDoc(
                doc(getFeatureDataQueriesCollection(userGroupId), featureDataId)
              )
            ).data()

        if (!featureDataQuery) {
          dispatch(
            featureDataDetailActions.setFeatureDataDetailState({
              ...getState().pages.featureDataDetailState.appState
                .featureDataDetailState,
              featureDataDataState: 'NotFoundProcessed',
            })
          )
          return
        }

        /** feature-data-group-query document */
        const featureDataGroupQuery = isSharedUserGroup
          ? (
              await getDocs(
                query(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  where(
                    'feature-data-group-id',
                    '==',
                    featureDataQuery['feature-data-group-id']
                  ),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              )
            ).docs.map((featureDataGroup) => featureDataGroup.data())[0]
          : (
              await getDoc(
                doc(
                  getFeatureDataGroupQueriesCollection(userGroupId),
                  featureDataQuery['feature-data-group-id']
                )
              )
            ).data()

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                featureDataQuery ? featureDataQuery['created-by'] : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        const datasetList: Dataset[] = []
        let settingMetadata = undefined
        let mlPipeLineData = undefined

        // データセット、セッティング、特徴量データファイルの取得を実行
        if (!isSharedUserGroup) {
          const datasetDataPromises = featureDataQuery['dataset-list'].map(
            async (dataset: {
              'dataset-id': string
              generation: 0 | 1
              'user-group-id': string
            }) => {
              const datasetData = (
                await getDoc(
                  doc(
                    getDatasetQueryCollection(dataset['user-group-id']),
                    dataset['dataset-id']
                  )
                )
              ).data()
              if (datasetData) {
                datasetList.push({
                  datasetId: dataset['dataset-id'],
                  datasetName: datasetData['dataset-name'],
                })
              }
            }
          )
          await Promise.all(datasetDataPromises)

          /** セッティングのメタデータ */
          settingMetadata =
            featureDataQuery && featureDataQuery['setting-id']
              ? (
                  await getDoc(
                    doc(
                      getSettingsMetaDataCollection(userGroupId),
                      featureDataQuery['setting-id']
                    )
                  )
                ).data()
              : undefined

          /** mlPipelineQuery */
          mlPipeLineData =
            featureDataQuery && featureDataQuery['ml-pipeline-id']
              ? (
                  await getDoc(
                    doc(
                      getMlPipelineQueriesCollection(userGroupId),
                      featureDataQuery['ml-pipeline-id']
                    )
                  )
                ).data()
              : undefined

          if (
            mlPipeLineData &&
            !fireStoreTypeGuardForFeatureDataGeneratingMLPipelineQueryDocument(
              mlPipeLineData
            )
          ) {
            dispatch(
              featureDataDetailActions.setFeatureDataDetailState({
                ...getState().pages.featureDataDetailState.appState
                  .featureDataDetailState,
                featureDataDataState: 'Failed',
              })
            )
            return
          }
        }

        /** アルゴリズムデータの取得 */
        const trainingAlgorithmVersionDisplayName =
          featureDataQuery['training-algorithm-version']['display-name']
        const foundAlgorithm = getState().app.domainData.algorithms.find(
          (algorithm) =>
            algorithm.algorithmId === featureDataQuery['algorithm-id']
        )
        const foundTrainingAlgorithmVersion =
          foundAlgorithm?.trainingAlgorithm.trainingAlgorithmVersions.find(
            (trainingAlgorithmVersion) =>
              trainingAlgorithmVersion.algorithmVersion.displayName ===
              trainingAlgorithmVersionDisplayName
          )
        const requestMlPipelineId =
          featureDataQuery && featureDataQuery['ml-pipeline-id']
            ? featureDataQuery['ml-pipeline-id']
            : ''
        dispatch(
          featureDataDetailActions.setCurrentFeatureDataDetail({
            featureDataGroupId: featureDataQuery
              ? featureDataQuery['feature-data-group-id']
              : '',
            featureDataGroupName: featureDataGroupQuery
              ? featureDataGroupQuery['feature-data-group-name']
              : '',
            featureDataVersion: featureDataQuery
              ? featureDataQuery['feature-data-group-version']['display-name']
              : '',
            relatedFeatureDataList: featureDataGroupQuery
              ? getFeatureDataList(featureDataGroupQuery['feature-data-list'])
              : [],
            featureDataId: featureDataQuery
              ? featureDataQuery['feature-data-id']
              : '',
            featureDataName: featureDataQuery
              ? featureDataQuery['feature-data-name']
              : '',
            featureDataRemarks: featureDataQuery
              ? featureDataQuery['feature-data-remarks']
              : '',
            evaluationStatus: featureDataQuery
              ? featureDataQuery['evaluation-status']
              : '',
            datasetList: datasetList,
            setting: {
              settingId: featureDataQuery ? featureDataQuery['setting-id'] : '',
              settingName: settingMetadata ? settingMetadata['name'] : '',
            },
            trainingAlgorithm: {
              algorithmId: foundAlgorithm ? foundAlgorithm.algorithmId : '',
              metadata:
                foundAlgorithm && foundAlgorithm.metadata
                  ? { name: foundAlgorithm.metadata.name }
                  : {
                      name: {
                        ja: '',
                        en: '',
                      },
                    },
              trainingAlgorithmVersion: foundTrainingAlgorithmVersion
                ? foundTrainingAlgorithmVersion.algorithmVersion.displayName
                : '',
            },
            mlPipeline: {
              mlPipelineId: requestMlPipelineId,
              mlPipelineName: mlPipeLineData
                ? mlPipeLineData['ml-pipeline-metadata'].name
                : '',
            },
            createdAt: featureDataQuery
              ? featureDataQuery['created-at']
              : undefined,
            createdUserName: accountSetting
              ? {
                  firstName: accountSetting['first-name'] ?? '',
                  familyName: accountSetting['family-name'] ?? '',
                }
              : featureDataQuery['created-by'] ?? '',
            featureDataListDisplayCondition: {
              sortKey: 'feature-data-version',
              sortOrder: 'desc',
              displayNumber: 10,
              pageNumber: 0,
            },
          })
        )
        dispatch(
          featureDataDetailActions.setFeatureDataDetailState({
            ...getState().pages.featureDataDetailState.appState
              .featureDataDetailState,
            featureDataDataState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          featureDataDetailActions.setFeatureDataDetailState({
            ...getState().pages.featureDataDetailState.appState
              .featureDataDetailState,
            featureDataDataState: 'Failed',
          })
        )
      } finally {
        dispatch(featureDataDetailActions.setInProgress(false))
      }
    },
  getEventHistories:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataDetailActions.setInProgressForEventHistories(true))

        const mlPipelineId =
          getState().pages.featureDataDetailState.domainData
            .currentFeatureDataDetail.mlPipeline.mlPipelineId

        if (!mlPipelineId) return

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const mlPipelineDataForGenerating = (
          await getDoc(
            doc(getMlPipelineQueriesCollection(userGroupId), mlPipelineId)
          )
        ).data()

        if (
          !mlPipelineDataForGenerating ||
          !fireStoreTypeGuardForFeatureDataGeneratingMLPipelineQueryDocument(
            mlPipelineDataForGenerating
          )
        ) {
          dispatch(
            featureDataDetailActions.setFeatureDataDetailState({
              ...getState().pages.featureDataDetailState.appState
                .featureDataDetailState,
              featureDataEventHistoryState: 'Failed',
            })
          )
          return
        }

        const convertedMlPipelineForGenerating: EventHistoryData = {
          mlPipelineKind: mlPipelineDataForGenerating['ml-pipeline'][
            'ml-pipeline-kind'
          ] as 'FeatureDataGenerating' | 'FeatureDataTransferring',
          mlPipelineId:
            mlPipelineDataForGenerating['ml-pipeline']['ml-pipeline-id'],
          progress: {
            transactionStatus: mlPipelineDataForGenerating['ml-pipeline'][
              'transaction-status'
            ] as TransactionStatusKind,
            progressRate:
              convertProgressRateByTransactionStatusForFeatureDataGenerating(
                mlPipelineDataForGenerating['ml-pipeline']['transaction-status']
              ),
          },
          startedAt: mlPipelineDataForGenerating['ml-pipeline'][
            'started-at'
          ] as Timestamp,
          accountId: mlPipelineDataForGenerating['ml-pipeline']['account-id'],
        }

        const featureDataId =
          getState().pages.featureDataDetailState.domainData
            .currentFeatureDataDetail.featureDataId

        const mlPipelineDocsForTransferring = await getDocs(
          query(
            getMlPipelineQueriesCollection(userGroupId),
            where('transfer-step.src.feature-data-list', 'array-contains', {
              'feature-data-id': featureDataId,
              'user-group-id': userGroupId,
            })
          )
        )

        const mlPipelineDataForTransferring = mlPipelineDocsForTransferring.docs
          .map((doc) => doc.data())
          .filter((data) =>
            fireStoreTypeGuardForFeatureDataTransFerringMLPipelineQueryDocument(
              data
            )
          )

        const convertedMlPipelinesForTransferring: EventHistoryData[] =
          mlPipelineDataForTransferring.map((data) => {
            return {
              mlPipelineKind: data['ml-pipeline']['ml-pipeline-kind'] as
                | 'FeatureDataGenerating'
                | 'FeatureDataTransferring',
              mlPipelineId: data['ml-pipeline']['ml-pipeline-id'],
              progress: {
                transactionStatus: data['ml-pipeline'][
                  'transaction-status'
                ] as TransactionStatusKind,
                progressRate:
                  convertProgressRateByTransactionStatusForFeatureDataTransferring(
                    data['ml-pipeline']['transaction-status']
                  ),
              },
              startedAt: data['ml-pipeline']['started-at'] as Timestamp,
              accountId: data['ml-pipeline']['account-id'],
            }
          })

        const mlPipelineList = [
          convertedMlPipelineForGenerating,
          ...convertedMlPipelinesForTransferring,
        ].sort((prev, next) => {
          if (prev.startedAt.toDate() > next.startedAt.toDate()) {
            return -1
          } else if (prev.startedAt.toDate() < next.startedAt.toDate()) {
            return 1
          }

          return 0
        })

        dispatch(featureDataDetailActions.setMlPipelineList(mlPipelineList))
        dispatch(
          featureDataDetailActions.setFeatureDataDetailState({
            ...getState().pages.featureDataDetailState.appState
              .featureDataDetailState,
            featureDataEventHistoryState: 'Loaded',
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          featureDataDetailActions.setFeatureDataDetailState({
            ...getState().pages.featureDataDetailState.appState
              .featureDataDetailState,
            featureDataEventHistoryState: 'Failed',
          })
        )
      } finally {
        dispatch(featureDataDetailActions.setInProgressForEventHistories(false))
      }
    },
  /** 特徴量データの名前を更新 */
  updateFeatureDataDetailName:
    (docId: string, name: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // FeatureData IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getFeatureDataMetadataCollection(userGroupId), docId),
          {
            name: name,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )

        dispatch(
          featureDataDetailActions.setCurrentFeatureDataDetail({
            ...getState().pages.featureDataDetailState.domainData
              .currentFeatureDataDetail,
            featureDataName: name,
          })
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データの備考を更新 */
  updateFeatureDataDetailRemarks:
    (docId: string, remarks: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // FeatureData IDと一致したdocの名前を更新する
        await updateDoc(
          doc(getFeatureDataMetadataCollection(userGroupId), docId),
          {
            remarks: remarks,
            ['updated-by']:
              getState().app.domainData.authedUser.auth.customClaims.accountId,
            ['updated-at']: new Date(),
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データファイルのダウンロード */
  downloadFeatureDataFile:
    (links: MediaLink[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(featureDataDetailActions.setInProgress(true))
        // ファイルDL失敗リスト
        const failedFeatureDataFileList: string[] = []
        await Promise.all(
          links.map(async (link) => {
            try {
              await axios
                .get(link.mediaUrl, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, link.mediaName)
                })
            } catch {
              failedFeatureDataFileList.push(link.mediaName)
            }
          })
        )
        // 失敗した画像があれば、Storeに保持
        if (failedFeatureDataFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedFeatureDataFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataDetailActions.setInProgress(false))
      }
    },
  /** 特徴量データパイプライン生成ファイルの取得 */
  downloadMlPipelineGenerateFile:
    (link: MlPipelineGenerateFile) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(featureDataDetailActions.setInProgressForDownloading(true))
        // ファイルDL失敗リスト
        const failedModelFileList: string[] = []
        try {
          await axios
            .get(link.fileLink, {
              responseType: 'blob',
            })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: response.data.type,
              })
              saveAs(blob, link.fileName)
            })
        } catch {
          failedModelFileList.push(link.fileName)
        }
        // 失敗したファイルがあれば、Storeに保持
        if (failedModelFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedModelFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataDetailActions.setInProgressForDownloading(false))
      }
    },
  /** 特徴量データパイプライン生成ファイルをダウンロードする */
  downloadAllMlPipelineGenerateFiles:
    (mlPipelineGenerateFiles: MlPipelineGenerateFile[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(featureDataDetailActions.setInProgressForDownloading(true))
        // ファイルDL失敗リスト
        const failedResultFileList: string[] = []
        await Promise.all(
          mlPipelineGenerateFiles.map(async (mlPipelineGenerateFile) => {
            try {
              await axios
                .get(mlPipelineGenerateFile.fileLink, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, mlPipelineGenerateFile.fileName)
                })
            } catch {
              failedResultFileList.push(mlPipelineGenerateFile.fileName)
            }
          })
        )
        // 失敗したファイルがあれば、Storeに保持
        if (failedResultFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedResultFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataDetailActions.setInProgressForDownloading(false))
      }
    },
  /** ファイルデータの取得 */
  getFileData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(
          featureDataDetailActions.setInProgressForGettingGenerateFiles(true)
        )

        const featureDataId =
          getState().pages.featureDataDetailState.domainData
            .currentFeatureDataDetail?.featureDataId

        let mlPipelineGenerateFileList: MlPipelineGenerateFile[] = []
        const featureDataFilesItems: FeatureDataDlLink[] = []

        // 特徴量データファイル用キャッチ
        try {
          const getFeatureDataFilesResponse =
            await FeatureDataDetailApi.getFeatureDataFiles(
              featureDataId ? featureDataId : ''
            )

          if (isGetFeatureDataFilesResponse(getFeatureDataFilesResponse)) {
            getFeatureDataFilesResponse.data.items.map((file) => {
              featureDataFilesItems.push({
                linkName: file.linkName,
                mediaLinks: file.mediaLinks,
                totalMediaSize: file.mediaLinks.reduce(
                  (prev, current) => prev + current.mediaSize,
                  0
                ),
              })
            })

            dispatch(
              featureDataDetailActions.setFeatureDataDetailState({
                ...getState().pages.featureDataDetailState.appState
                  .featureDataDetailState,
                featureDataDlLinkSubState: 'Loaded',
              })
            )
          }
        } catch {
          dispatch(
            featureDataDetailActions.setFeatureDataDetailState({
              ...getState().pages.featureDataDetailState.appState
                .featureDataDetailState,
              featureDataDlLinkSubState: 'Failed',
            })
          )
        }

        const requestMlPipelineId =
          getState().pages.featureDataDetailState.domainData
            .currentFeatureDataDetail?.mlPipeline.mlPipelineId

        // ml-pipeline-id が存在しない場合（特徴量データのアップロードで作成された特徴量データの場合）は、パイプライン生成物のファイルを取得しない
        if (requestMlPipelineId) {
          // リザルトファイル用キャッチ
          try {
            const mlPipelineGenerateFilesResponse =
              await FeatureDataDetailApi.getMlPipelineGenerateFiles(
                requestMlPipelineId
              )

            if (isGetResultFileResponse(mlPipelineGenerateFilesResponse)) {
              mlPipelineGenerateFileList =
                mlPipelineGenerateFilesResponse.data.items.map((result) => ({
                  fileName: result.name,
                  fileLink: result.fileNameForDownload,
                  fileSize: result.size,
                  createdAt: new Date(result.createdAt),
                }))

              dispatch(
                featureDataDetailActions.setFeatureDataDetailState({
                  ...getState().pages.featureDataDetailState.appState
                    .featureDataDetailState,
                  featureDataGenerateFileSubState: 'Loaded',
                })
              )
            }
          } catch {
            dispatch(
              featureDataDetailActions.setFeatureDataDetailState({
                ...getState().pages.featureDataDetailState.appState
                  .featureDataDetailState,
                featureDataGenerateFileSubState: 'Failed',
              })
            )
          }
        }

        const currentMlPipelineId =
          getState().pages.featureDataDetailState.domainData
            .currentFeatureDataDetail?.mlPipeline.mlPipelineId
        if (requestMlPipelineId === currentMlPipelineId) {
          dispatch(
            featureDataDetailActions.setFeatureDataDlLinks(
              featureDataFilesItems
            )
          )
          dispatch(
            featureDataDetailActions.setMlPipelineGenerateFiles(
              mlPipelineGenerateFileList
            )
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(
          featureDataDetailActions.setInProgressForGettingGenerateFiles(false)
        )
      }
    },
}
