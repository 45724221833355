import { Dispatch } from 'redux'
import { featureDataGroupListActions } from '.'
import { FeatureDataGroupInfo } from './types'
import { State } from 'state/store'
import { getFeatureDataGroupQueriesCollection } from 'state/firebase'

import { Algorithm } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataGroupQueryDocument } from 'utils/fireStore/featureDataGroupQuery'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { domainDataOperations } from 'state/app/domainData/operations'

const createFeatureDataGroupList = async (
  algorithmList: Algorithm[],
  featureDataGroupQuery: Query<DocumentData>
): Promise<(FeatureDataGroupInfo | undefined)[]> =>
  await Promise.all(
    // モデルグループリストの生成
    await (
      await getDocs(featureDataGroupQuery)
    ).docs.map(async (doc: DocumentData) => {
      const featureDataGroupQueryDocData = doc.data()
      if (
        !fireStoreTypeGuardForFeatureDataGroupQueryDocument(
          featureDataGroupQueryDocData
        )
      ) {
        console.error(`FeatureDataGroupQuery document ${doc.id} is invalid`)
        return undefined
      }
      let algorithm = undefined
      algorithmList.map((info) => {
        if (info.algorithmId === featureDataGroupQueryDocData['algorithm-id']) {
          algorithm = info
        }
      })

      // モデルグループ一覧を返す
      return {
        featureDataGroupId:
          featureDataGroupQueryDocData['feature-data-group-id'],
        algorithmName: algorithm ? algorithm['metadata']['name']['ja'] : '',
        name: featureDataGroupQueryDocData['feature-data-group-name'],
        remarks: featureDataGroupQueryDocData['remarks'],
        overview: featureDataGroupQueryDocData['overview'],
        updatedAt: featureDataGroupQueryDocData['updated-at'],
        createAt: featureDataGroupQueryDocData['created-at'],
        createdUserId: featureDataGroupQueryDocData['created-by'],
      } as FeatureDataGroupInfo
    })
  )

export const featureDataGroupListOperations = {
  /** リストを取得する */
  getFeatureDataGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGroupListActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            featureDataGroupListActions.setListDisplayCondition({
              ...getState().pages.featureDataGroupListState.domainData
                .featureDataGroupListDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.featureDataGroupListState.domainData
            .featureDataGroupListDisplayCondition.selectedUserGroupKind ===
          'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]
        // 表示条件取得
        const condition =
          getState().pages.featureDataGroupListState.domainData
            .featureDataGroupListDisplayCondition
        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentFeatureDataGroupList =
          getState().pages.featureDataGroupListState.domainData
            .currentFeatureDataGroupList
        const qLimit =
          condition.displayNumber * (condition.pageNumber + 1) -
          currentFeatureDataGroupList.length

        let totalCountQuery =
          getState().pages.featureDataGroupListState.domainData
            .featureDataGroupListDisplayCondition.selectedUserGroupKind ===
          'UserGroup'
            ? query(getFeatureDataGroupQueriesCollection(userGroupId))
            : query(
                getFeatureDataGroupQueriesCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        // feature-data-groupsを表示件数分取得
        let featureDataGroupQuery = query(totalCountQuery, limit(qLimit))

        // 文字列検索が存在する場合は、feature-data-group-idの前方一致条件をQueryに設定
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          featureDataGroupQuery = query(
            featureDataGroupQuery,
            orderBy('feature-data-group-id', 'asc'),
            where('feature-data-group-id', '>=', startCode),
            where('feature-data-group-id', '<=', endCode)
          )
        } else {
          const sortKey =
            condition.sortKey === 'generated-at'
              ? 'created-at'
              : condition.sortKey
          featureDataGroupQuery = query(
            featureDataGroupQuery,
            orderBy(sortKey, condition.sortOrder)
          )
        }
        // 既に取得していれば最後の要素から取得
        let lastItem: DocumentData | undefined = undefined
        const algorithms = getState().app.domainData.algorithms
        if (currentFeatureDataGroupList.length) {
          lastItem =
            getState().pages.featureDataGroupListState.domainData
              .featureDataGroupListDisplayCondition.selectedUserGroupKind ===
            'UserGroup'
              ? await getDoc(
                  doc(
                    getFeatureDataGroupQueriesCollection(userGroupId),
                    currentFeatureDataGroupList[
                      currentFeatureDataGroupList.length - 1
                    ].featureDataGroupId
                  )
                )
              : (
                  await getDocs(
                    query(
                      getFeatureDataGroupQueriesCollection(userGroupId),
                      where(
                        'feature-data-group-id',
                        '==',
                        currentFeatureDataGroupList[
                          currentFeatureDataGroupList.length - 1
                        ].featureDataGroupId
                      ),
                      where('access-control.is-shared', '==', true),
                      where(
                        'access-control.share-permissions.webapp',
                        '==',
                        'list'
                      )
                    )
                  )
                ).docs.map((modelGroup) => modelGroup.data())[0]
          featureDataGroupQuery = query(
            featureDataGroupQuery,
            startAfter(lastItem)
          )
        }

        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          totalCountQuery = query(
            totalCountQuery,
            where('feature-data-group-id', '>=', startCode),
            where('feature-data-group-id', '<=', endCode)
          )
        }
        const totalCount = await getCountFromServer(totalCountQuery)

        dispatch(
          featureDataGroupListActions.setListDisplayCondition({
            ...condition,
            totalCount: totalCount.data().count,
          })
        )

        // モデルグループ一覧を取得
        const newFeatureDataGroupList: (FeatureDataGroupInfo | undefined)[] =
          await createFeatureDataGroupList(algorithms, featureDataGroupQuery)

        dispatch(
          featureDataGroupListActions.setFeatureDataGroupList([
            ...currentFeatureDataGroupList,
            ...(newFeatureDataGroupList.filter(
              (item) => item !== undefined
            ) as FeatureDataGroupInfo[]),
          ])
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGroupListActions.setInProgress(false))
      }
    },
}
