import { RecaptchaVerifier } from 'firebase/auth'
// ActionType
export const MfaSettingActionType = {
  SET_RECAPCHA_VERIFIER: 'SET_RECAPCHA_VERIFIER',
  SET_MFA_SETTING_STATE: 'SET_MFA_SETTING_STATE',
  SET_VERIFICATION_ID: 'SET_VERIFICATION_ID',
  SET_TOAST_INFO_FOR_MFA_SETTING: 'SET_TOAST_INFO_FOR_MFA_SETTING',
  MFA_SETTING_COMPLETED: 'MFA_SETTING_COMPLETED',
  SET_IN_PROGRESS_FOR_MFA_SETTING: 'SET_IN_PROGRESS_FOR_MFA_SETTING',
} as const

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export const MfaSettingStateKindArray = ['phoneNumberState', 'mfaSettingState']

export type MfaSettingStateKind = (typeof MfaSettingStateKindArray)[number]

export interface MfaSettingAppState {
  toastInfo?: ToastInfo // トーストの表示する情報
  mfaSettingStateKind: MfaSettingStateKind // 初回ログインの状態
  verificationId: string // MFA認証のID
  recaptchaVerifier?: RecaptchaVerifier
  mfaSettingState: 'Completed' | 'NotSet'
  inProgress: boolean // グローバルローディングの表示可否
}

export interface MfaSettingState {
  appState: MfaSettingAppState
}
