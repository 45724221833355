import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Link as MuiLink,
  Select,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import Check from '@mui/icons-material/Check'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'

import { State } from 'state/store'
import {
  accountListActions,
  AccountListActions,
  AccountListOperations,
  AccountListPagingState,
  AccountDetailInfo,
} from 'state/ducks/accountList'

import {
  AccountInfo,
  AccountListDisplayCondition,
  AccountListSortKey,
} from 'utils/ducks/accountList/types'
import { isUndefined } from 'utils/typeguard'
import {
  AccountListIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  GlobalLoading,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
} from 'views/components'

import { formatDateTimeSec } from 'views/components/utils/date'
import { useTheme } from '@mui/material/styles'
import { AccountAuthorizer } from 'views/components/organisms/accountAuthorizer'

const mapStateToProps = (state: State) => ({
  ...state.pages.accountListState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData.userProfile,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, AccountListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** アカウントグループ一覧取得 */
  getAccountGroupList: () =>
    dispatch(AccountListOperations.getAccountGroupList()),
  /** ユーザー一覧取得 */
  getAccountList: () => dispatch(AccountListOperations.getAccountList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: AccountListDisplayCondition) =>
    dispatch(accountListActions.setListDisplayCondition(listCondition)),
  /** 次ページの活性非活性の切り替え */
  setAccountListPagingState: (nextPageSubState: AccountListPagingState) =>
    dispatch(accountListActions.setAccountListPagingState(nextPageSubState)),
  setSelectedAccountGroupId: (accountGroupId: string) =>
    dispatch(accountListActions.setSelectedAccountGroupId(accountGroupId)),
  /** Stateのクリア */
  clearAccountListState: () =>
    dispatch(accountListActions.clearAccountListState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  explanation: {
    fontSize: theme.typography.pxToRem(18),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'mailAddress',
    title: 'メールアドレス',
    width: 300,
    sortable: true,
    position: 'left',
  },
  {
    id: 'firstName',
    title: '姓',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'familyName',
    title: '名',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'accountGroupRole',
    title: 'アカウントグループロール',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'lastLoginTime',
    title: '最終ログイン',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'registeredDate',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'firstOption',
    title: '初期設定',
    width: 100,
    sortable: false,
    position: 'center',
  },
  {
    id: 'passwordUpdateAt',
    title: 'パスワード更新日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const AccountList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const { classes } = useStyles()

  useEffect(() => {
    props.getAccountList()
    props.getAccountGroupList()
    return () => {
      props.clearAccountListState()
    }
  }, [])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    tableRows,
    searchedAccountListLength,
    tableSearchValue,
  } = useTableDisplayActions(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.accountListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.accountListDisplayCondition.searchValue])

  /** テーブル */
  const accountListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.accountListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={
          searchedAccountListLength ??
          props.domainData.currentAccountList.length
        }
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.accountListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.accountListDisplayCondition.sortKey,
          order: props.domainData.accountListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) =>
          changeTableSortOrder(key as AccountListSortKey)
        }
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    searchedAccountListLength,
    props.appState.accountListState.nextPageSubState,
    props.domainData.accountListDisplayCondition,
  ])

  return (
    <>
      <div className={classes.stepContainer}>
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <AccountListIcon
              className={classes.pageIcon}
              data-testid='accountListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='account-list-title'>ユーザー管理</h2>
            </Typography>
          </Box>
          <AccountAuthorizer
            type='CREATE_NEW_ACCOUNT_IN_ACCOUNT_GROUP'
            superUser={props.auth.customClaims.superUser}
            accountGroupRole={props.accountGroupRole ?? ''}
          >
            <Box display='flex'>
              <PersonAddAltRoundedIcon />
              <Link data-testid='Account-entry' to={`${url}/entry`}>
                <Typography>ユーザー登録</Typography>
              </Link>
            </Box>
          </AccountAuthorizer>
        </div>
        <CustomTrainingPageParagraph>
          <Box mt={2}>
            <FormControl
              variant='outlined'
              className={classes.algorithmSelectBox}
            >
              <InputLabel>アカウントグループ</InputLabel>
              <Select
                value={props.domainData.selectedAccountGroupId}
                onChange={(e) =>
                  props.setSelectedAccountGroupId(e.target.value as string)
                }
                label='アカウントグループ'
              >
                {props.auth.customClaims.superUser && (
                  <MenuItem value='all'>全て</MenuItem>
                )}
                {props.domainData.accountGroupList.map((accountGroupItem) => {
                  return (
                    <MenuItem
                      value={accountGroupItem.accountGroupId}
                      key={accountGroupItem.accountGroupId}
                    >
                      {accountGroupItem.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
        </CustomTrainingPageParagraph>
        <CustomTrainingPageParagraph>
          <div className={classes.searchForm}>
            <div className={classes.searchField}>
              <SearchInput
                placeholder='キーワード (メールアドレス、姓、名)'
                value={tableSearchValue}
                onChangeValue={(event) =>
                  handleChangeSearchValue(event.target.value)
                }
                onClickSearch={() => searchTableContent()}
                onPressEnter={() => searchTableContent()}
              />
            </div>
          </div>
          {accountListTable}
        </CustomTrainingPageParagraph>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const useTableDisplayActions = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()
  /** 検索結果結果の配列の長さを保持(Account) */
  const [searchedAccountListLength, setSearchedAccountListLength] = useState<
    number | undefined
  >(undefined)
  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')
  /** 表示対象のアカウントデータ */
  const adjustAccountTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.accountListDisplayCondition)) {
      let newAccountArray = props.domainData.currentAccountList

      /** 選択中のアカウントグループから検索 */
      newAccountArray =
        props.domainData.selectedAccountGroupId !== 'all'
          ? newAccountArray.filter(
              (item) =>
                item.accountGroupId === props.domainData.selectedAccountGroupId
            )
          : newAccountArray

      setSearchedAccountListLength(newAccountArray.length)

      /** 検索ワードから検索 */
      if (props.domainData.accountListDisplayCondition.searchValue) {
        newAccountArray = newAccountArray.filter(
          (item) =>
            item.mailAddress.indexOf(
              props.domainData.accountListDisplayCondition.searchValue
            ) >= 0 ||
            (item.firstName &&
              item.firstName.indexOf(
                props.domainData.accountListDisplayCondition.searchValue
              ) >= 0) ||
            (item.familyName &&
              item.familyName.indexOf(
                props.domainData.accountListDisplayCondition.searchValue
              ) >= 0)
        )
        /** 検索後の配列の長さをセット */
        setSearchedAccountListLength(newAccountArray.length)
      }

      /** ソートキー、ソートオーダーによる並び替え */
      const sortKey = props.domainData.accountListDisplayCondition.sortKey
      if (sortKey === 'mailAddress') {
        newAccountArray = newAccountArray.sort((item, item2) => {
          if (item.mailAddress < item2.mailAddress) {
            return props.domainData.accountListDisplayCondition.sortOrder ===
              'asc'
              ? -1
              : 1
          }
          if (item.mailAddress > item2.mailAddress) {
            return props.domainData.accountListDisplayCondition.sortOrder ===
              'asc'
              ? 1
              : -1
          }
          // names must be equal
          return 0
        })
      } else {
        newAccountArray = newAccountArray.sort((item, item2) => {
          return (
            ((item2[sortKey] ? item2[sortKey] : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item[sortKey] ? item[sortKey] : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.accountListDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }

      /** 表示するアカウントの整形 */
      return newAccountArray.slice(
        props.domainData.accountListDisplayCondition.displayNumber *
          props.domainData.accountListDisplayCondition.pageNumber,
        (props.domainData.accountListDisplayCondition.pageNumber + 1) *
          props.domainData.accountListDisplayCondition.displayNumber
      )
    }
    return props.domainData.currentAccountList
  }, [
    props.domainData.accountListDisplayCondition,
    props.domainData.currentAccountList,
    props.domainData.selectedAccountGroupId,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: AccountInfo[] = adjustAccountTableRow.map(
      (accountList: AccountDetailInfo) => ({
        mailAddress: accountList.mailAddress,
        familyName: accountList.familyName,
        firstName: accountList.firstName,
        accountGroupRole: accountList.accountGroupRole,
        lastLoginTime: accountList.lastLoginTime,
        registeredDate: accountList.registeredDate,
        firstOption: accountList.firstOption,
        passwordUpdateAt: accountList.passwordUpdateAt,
      })
    )

    const accountIds: string[] = []

    adjustAccountTableRow.forEach((item) => {
      accountIds.push(item.accountId)
    })

    return convertedList.map((data, index) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'mailAddress':
            return (
              <MuiLink
                className={classes.link}
                underline='none'
                onClick={() => {
                  history.push(
                    `accounts/${accountIds[index]}?account-group-id=${adjustAccountTableRow[index].accountGroupId}`
                  )
                }}
              >
                <Typography>{value}</Typography>
              </MuiLink>
            )
          case 'lastLoginTime':
          case 'registeredDate':
          case 'passwordUpdateAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            } else if (
              (key === 'registeredDate' ||
                key === 'lastLoginTime' ||
                key === 'passwordUpdateAt') &&
              value.seconds === 0
            ) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'firstOption':
            return value ? (
              <Box height={20}>
                <Check fontSize='small'></Check>
              </Box>
            ) : (
              <></>
            )
          case 'firstName':
          case 'familyName':
          default:
            return value ? (
              <Typography>{value}</Typography>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [adjustAccountTableRow])

  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const maxPageNumber =
      Math.ceil(props.domainData.currentAccountList.length / displayNumber) - 1
    if (
      props.domainData.accountListDisplayCondition.pageNumber <= maxPageNumber
    ) {
      /** 表示数を変更 */
      props.setListDisplayCondition({
        ...props.domainData.accountListDisplayCondition,
        displayNumber: displayNumber ?? 10,
      })
    } else {
      /** 表示数を変更 */
      props.setListDisplayCondition({
        ...props.domainData.accountListDisplayCondition,
        pageNumber: maxPageNumber,
        displayNumber: displayNumber ?? 10,
      })
    }
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: AccountListSortKey) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.accountListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.accountListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.accountListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.setListDisplayCondition({
      ...props.domainData.accountListDisplayCondition,
      searchValue: tableSearchValue,
      pageNumber: 0,
    })
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    tableRows,
    searchedAccountListLength,
    tableSearchValue,
  }
}

export const AccountListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountList))
