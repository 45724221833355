import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  FeatureDataGeneratingDetailActionType,
  CurrentFeatureDataGeneratingDetail,
  FeatureDataGeneratingDetailState,
  ToastInfo,
  FeatureDataDlLink,
  MlPipelineLogFile,
} from './types'

export type FeatureDataGeneratingDetailAction = ActionsUnion<
  typeof featureDataGeneratingDetailActions
>

export const featureDataGeneratingDetailActions = {
  setCurrentFeatureDataGeneratingDetail: (
    currentFeatureDataGeneratingDetail: CurrentFeatureDataGeneratingDetail
  ): ActionWithPayload<
    'SET_CURRENT_FEATURE_DATA_GENERATING_DETAIL',
    { currentFeatureDataGeneratingDetail: CurrentFeatureDataGeneratingDetail }
  > =>
    CreateAction(
      FeatureDataGeneratingDetailActionType.SET_CURRENT_FEATURE_DATA_GENERATING_DETAIL,
      {
        currentFeatureDataGeneratingDetail,
      }
    ),
  setFeatureDataGeneratingDetailState: (
    featureDataGeneratingDetailState: FeatureDataGeneratingDetailState
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GENERATING_DETAIL_STATE',
    { featureDataGeneratingDetailState: FeatureDataGeneratingDetailState }
  > =>
    CreateAction(
      FeatureDataGeneratingDetailActionType.SET_FEATURE_DATA_GENERATING_DETAIL_STATE,
      {
        featureDataGeneratingDetailState,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataGeneratingDetailActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING_DETAIL,
      {
        inProgress,
      }
    ),
  clearFeatureDataGeneratingDetailState:
    (): Action<'CLEAR_FEATURE_DATA_GENERATING_DETAIL_STATE'> =>
      CreateAction(
        FeatureDataGeneratingDetailActionType.CLEAR_FEATURE_DATA_GENERATING_DETAIL_STATE
      ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOT_FEATURE_DATA_GENERATING',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      FeatureDataGeneratingDetailActionType.SET_TOAST_INFO_FOT_FEATURE_DATA_GENERATING,
      {
        toastInfo,
      }
    ),
  setFeatureDataDlLinks: (
    featureDataDlLinks: FeatureDataDlLink[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_DL_LINKS',
    { featureDataDlLinks: FeatureDataDlLink[] }
  > =>
    CreateAction(
      FeatureDataGeneratingDetailActionType.SET_FEATURE_DATA_DL_LINKS,
      {
        featureDataDlLinks,
      }
    ),
  setMlPipelineLogFiles: (
    mlPipelineLogFiles: MlPipelineLogFile[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_LOG_FILES_FOR_FEATURE_DATA_GENERATING',
    { mlPipelineLogFiles: MlPipelineLogFile[] }
  > =>
    CreateAction(
      FeatureDataGeneratingDetailActionType.SET_ML_PIPELINE_LOG_FILES_FOR_FEATURE_DATA_GENERATING,
      {
        mlPipelineLogFiles,
      }
    ),
}
