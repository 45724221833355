import { doc, getDoc } from 'firebase/firestore'
import { getTrainedModelQueriesCollection } from 'state/firebase'
import { isUndefined } from 'utils/typeguard'

/** trainedModelGroupIdを取得 */
export const getTrainedModelGroupId = async (
  userGroupId: string,
  trainedModelId: string
) => {
  try {
    const trainedModel = (
      await getDoc(
        doc(getTrainedModelQueriesCollection(userGroupId), trainedModelId)
      )
    ).data()
    if (isUndefined(trainedModel)) return ''
    const trainedModelGroupId = trainedModel['trained-model-group-id']
    return trainedModelGroupId
  } catch (error) {
    console.error(error)
    return ''
  }
}
