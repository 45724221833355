import { Dispatch } from 'redux'
import { State } from 'state/store'
import { buildListActions } from './'
import { getMlPipelineQueriesCollection } from 'state/firebase'
import { Build } from './types'
import {
  convertProgressRateByTransactionStatusForBuild,
  convertQueryStartEndCodeBySearchValue,
} from '../../utils'
import { isUndefined } from 'utils/typeguard'
import { Algorithm } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForBuildMLPipelineQueryDocument } from 'utils/fireStore/buildMLPipelineQuery'

const getProgressFromBuildAndTransferStepStatus = (
  buildStepStatus: string,
  transferStepStatus?: string
): string => {
  if (!transferStepStatus) {
    return buildStepStatus
  }

  if (buildStepStatus === 'Completed') {
    if (transferStepStatus === 'Starting' || transferStepStatus === 'Pending') {
      return 'Transferring'
    } else {
      return transferStepStatus
    }
  } else {
    return buildStepStatus
  }
}

const getTransferStepStatus = (
  buildStepStatus: string,
  transferStepStatus?: string
): string => {
  if (!transferStepStatus) {
    return 'NotApplicable'
  }

  if (buildStepStatus === 'Failed') {
    return 'NotTransfer'
  }

  if (transferStepStatus === 'Completed') {
    return 'Transferred'
  } else if (
    transferStepStatus === 'Failed' &&
    buildStepStatus === 'Completed'
  ) {
    return 'Failed'
  } else {
    return 'Waiting'
  }
}

const createBuildData = async (
  snapshot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[]
): Promise<(Build | undefined)[]> =>
  // 関連のDocsを取得し表示用のビルド学習データを生成
  await Promise.all(
    snapshot.docs.map(async (doc: DocumentData) => {
      const mlPipelineQueryDocData = doc.data()
      if (
        !fireStoreTypeGuardForBuildMLPipelineQueryDocument(
          mlPipelineQueryDocData
        )
      ) {
        return undefined
      }
      let algorithm
      if (mlPipelineQueryDocData['build-step']) {
        /** アルゴリズムを取得 */
        algorithm = algorithms.find((algorithm) => {
          return (
            algorithm.algorithmId ===
            mlPipelineQueryDocData['build-step']['src']['algorithm-id']
          )
        })
      }

      const progress = getProgressFromBuildAndTransferStepStatus(
        mlPipelineQueryDocData['build-step']['step-status'],
        mlPipelineQueryDocData['transfer-step']?.['step-status']
      )

      const edgeContainerImageList = mlPipelineQueryDocData['build-step'][
        'dest'
      ]
        ? mlPipelineQueryDocData['build-step']['dest'][
            'edge-container-image-list'
          ]
        : []

      return {
        mlPipelineId: mlPipelineQueryDocData['ml-pipeline']['ml-pipeline-id'],
        mlPipelineName: mlPipelineQueryDocData
          ? mlPipelineQueryDocData['ml-pipeline-metadata']
            ? mlPipelineQueryDocData['ml-pipeline-metadata']['name']
            : ''
          : '',
        algorithmName: algorithm
          ? algorithm['metadata']['name']['ja']
            ? algorithm['metadata']['name']['ja']
            : ''
          : '',
        progress: {
          transactionStatus: progress,
          progressRate:
            convertProgressRateByTransactionStatusForBuild(progress),
        },
        startedAt: mlPipelineQueryDocData['ml-pipeline']['started-at'],
        endedAt:
          mlPipelineQueryDocData['ml-pipeline']['ended-at'] &&
          mlPipelineQueryDocData['ml-pipeline']['ended-at'].seconds === 0
            ? undefined
            : mlPipelineQueryDocData['ml-pipeline']['ended-at'],
        aiLibraryId:
          mlPipelineQueryDocData['build-step']['src']['trained-model'][
            'trained-model-group-id'
          ],
        aiLibraryVersion: {
          displayName:
            edgeContainerImageList.length > 0
              ? edgeContainerImageList[0]['edge-container-image-group-version'][
                  'display-name'
                ]
              : '',
          major:
            edgeContainerImageList.length > 0
              ? edgeContainerImageList[0]['edge-container-image-group-version'][
                  'major'
                ]
              : 0,
          minor:
            edgeContainerImageList.length > 0
              ? edgeContainerImageList[0]['edge-container-image-group-version'][
                  'minor'
                ]
              : 0,
          patch:
            edgeContainerImageList.length > 0
              ? edgeContainerImageList[0]['edge-container-image-group-version'][
                  'patch'
                ]
              : 0,
          build:
            edgeContainerImageList.length > 0
              ? edgeContainerImageList[0]['edge-container-image-group-version'][
                  'build'
                ]
              : 0,
        },
        transferStatus: getTransferStepStatus(
          mlPipelineQueryDocData['build-step']['step-status'],
          mlPipelineQueryDocData['transfer-step']?.['step-status']
        ),
        accountId: mlPipelineQueryDocData['created-by'],
      } as Build
    })
  )

// ビルド一覧の配列をセット
const getBuildListRelationDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.buildListState.domainData.currentBuildListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(buildListActions.setCurrentBuildListSnapshot(undefined))
  }
  // 表示条件取得
  const condition =
    getState().pages.buildListState.domainData.buildDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery = query(
    getMlPipelineQueriesCollection(userGroupId),
    limit(condition.displayNumber),
    where('build-step.step-kind', '==', 'Build')
  )

  if (condition.algorithmId) {
    commonQuery = query(
      commonQuery,
      where('build-step.src.algorithm-id', '==', condition.algorithmId)
    )
  }

  // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、MLPipelineIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('ml-pipeline.ml-pipeline-id', 'asc'),
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  } else {
    commonQuery = query(
      commonQuery,
      orderBy(condition.sortKey, condition.sortOrder)
    )
  }

  const mlPipelineIds = getState().pages.buildListState.domainData.mlPipelineIds
  // 取得済みのビルドの最後尾
  let mlPipeLineDoc: DocumentData | undefined = undefined
  if (mlPipelineIds.length > 0) {
    mlPipeLineDoc = await getDoc(
      doc(
        getMlPipelineQueriesCollection(userGroupId),
        mlPipelineIds[mlPipelineIds.length - 1]
      )
    )
  }

  // 取得済みのビルドが存在する場合は、取得済みの最後尾以降のデータから取得する
  if (mlPipeLineDoc) {
    commonQuery = query(commonQuery, startAfter(mlPipeLineDoc))
  }

  const algorithms = getState().app.domainData.algorithms

  const snapshot = onSnapshot(commonQuery, async (snapshot: QuerySnapshot) => {
    const buildData = await createBuildData(snapshot, algorithms)
    // ML Pipeline IDを保持
    // すでに保持しているIDが存在する場合は、現状の検索位置以降のIDを一度破棄し
    // 新たに取得したML Pipeline IDを保持する
    if (mlPipeLineDoc) {
      const index = mlPipelineIds.findIndex((id) => id === mlPipeLineDoc?.id)
      const beforePageIds = mlPipelineIds.slice(0, index + 1)
      dispatch(
        buildListActions.setMLPipeLineIdList([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        buildListActions.setMLPipeLineIdList([
          ...mlPipelineIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
    // 取得したビルドの一覧を保持
    dispatch(
      buildListActions.setList(
        buildData.filter((item) => item !== undefined) as Build[]
      )
    )
    let totalCountQuery = query(
      getMlPipelineQueriesCollection(userGroupId),
      where('build-step.step-kind', '==', 'Build')
    )

    if (condition.algorithmId) {
      totalCountQuery = query(
        totalCountQuery,
        where('build-step.src.algorithm-id', '==', condition.algorithmId)
      )
    }

    if (condition.searchValue) {
      const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
        condition.searchValue
      )
      totalCountQuery = query(
        totalCountQuery,
        where('ml-pipeline.ml-pipeline-id', '>=', startCode),
        where('ml-pipeline.ml-pipeline-id', '<=', endCode)
      )
    }

    const totalCount = await getCountFromServer(totalCountQuery)
    dispatch(
      buildListActions.setListDisplayCondition({
        ...condition,
        totalCount: totalCount.data().count,
      })
    )
  })

  // 現在、表示中のビルド一覧（ML Pipeline Docsの Snapshot を保持）
  dispatch(buildListActions.setCurrentBuildListSnapshot(snapshot))
}

export const buildListOperations = {
  /** リストを取得する */
  getBuildList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(buildListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentMlPipelineIds: string[] =
          getState().pages.buildListState.domainData.mlPipelineIds
        const condition =
          getState().pages.buildListState.domainData.buildDisplayCondition
        const mlPipelineIds = currentMlPipelineIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )

        dispatch(buildListActions.setMLPipeLineIdList(mlPipelineIds))

        // ビルド一覧に必要な関連Docsを取得
        await getBuildListRelationDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(buildListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.buildListState.domainData.currentBuildListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
