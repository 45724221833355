import { DatasetTemplate } from 'state/app/domainData/types'
import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  AddedAnnotationSetList,
  AlgorithmDisplayCondition,
  ClassSet,
  ClassSetDisplayCondition,
  CreateDatasetActionType,
  DatasetState,
  InputRequireType,
  SelectedAnnotationFormat,
  SelectType,
  ToastInfo,
  UploadSubState,
} from './types'

export type CreateDatasetAction = ActionsUnion<typeof createDatasetActions>
export const createDatasetActions = {
  setTrainingAlgorithmDisplayCondition: (
    trainingAlgorithmDisplayCondition: AlgorithmDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINING_ALGORITHM_DISPLAY_CONDITION',
    { trainingAlgorithmDisplayCondition: AlgorithmDisplayCondition }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_TRAINING_ALGORITHM_DISPLAY_CONDITION,
      {
        trainingAlgorithmDisplayCondition,
      }
    ),
  setAlgorithmSubState: (
    algorithmSubState: SelectType
  ): ActionWithPayload<
    'SET_ALGORITHM_SUB_STATE',
    { algorithmSubState: SelectType }
  > =>
    CreateAction(CreateDatasetActionType.SET_ALGORITHM_SUB_STATE, {
      algorithmSubState: algorithmSubState,
    }),
  setDatasetTemplateSubState: (
    datasetTemplateSubState: SelectType
  ): ActionWithPayload<
    'SET_DATASET_TEMPLATE_SUB_STATE',
    { datasetTemplateSubState: SelectType }
  > =>
    CreateAction(CreateDatasetActionType.SET_DATASET_TEMPLATE_SUB_STATE, {
      datasetTemplateSubState: datasetTemplateSubState,
    }),
  completeImageFileSave: (): ActionWithPayload<
    'COMPLETE_IMAGE_FILE_SAVE',
    { inputDataSubState: SelectType }
  > =>
    CreateAction(CreateDatasetActionType.COMPLETE_IMAGE_FILE_SAVE, {
      inputDataSubState: 'selected',
    }),
  setDatasetName: (
    datasetName: string
  ): ActionWithPayload<'SET_DATASET_NAME', { datasetName: string }> =>
    CreateAction(CreateDatasetActionType.SET_DATASET_NAME, {
      datasetName,
    }),
  setSelectedAlgorithmId: (
    algorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_ID_FOR_CREATE_DATASET',
    { algorithmId: string }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_SELECTED_ALGORITHM_ID_FOR_CREATE_DATASET,
      {
        algorithmId,
      }
    ),
  setSelectedAlgorithmVersion: (
    selectedAlgorithmVersion: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_VERSION_FOR_CREATE_DATASET',
    { selectedAlgorithmVersion: string }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_SELECTED_ALGORITHM_VERSION_FOR_CREATE_DATASET,
      {
        selectedAlgorithmVersion,
      }
    ),
  setAvailableDatasetTemplates: (
    availableDatasetTemplates: DatasetTemplate[]
  ): ActionWithPayload<
    'SET_AVAILABLE_DATASET_TEMPLATES',
    { availableDatasetTemplates: DatasetTemplate[] }
  > =>
    CreateAction(CreateDatasetActionType.SET_AVAILABLE_DATASET_TEMPLATES, {
      availableDatasetTemplates,
    }),
  setSelectedAnnotationFormat: (
    selectedAnnotationFormat?: SelectedAnnotationFormat
  ): ActionWithPayload<
    'SET_SELECTED_ANNOTATION_FORMAT',
    { selectedAnnotationFormat?: SelectedAnnotationFormat }
  > =>
    CreateAction(CreateDatasetActionType.SET_SELECTED_ANNOTATION_FORMAT, {
      selectedAnnotationFormat,
    }),
  setSelectedDatasetTemplate: (
    selectedDatasetTemplate: DatasetTemplate
  ): ActionWithPayload<
    'SET_SELECTED_DATASET_TEMPLATE_FOR_CREATE_DATASET',
    { selectedDatasetTemplate: DatasetTemplate }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_SELECTED_DATASET_TEMPLATE_FOR_CREATE_DATASET,
      {
        selectedDatasetTemplate,
      }
    ),
  setRemarks: (
    remarks: string
  ): ActionWithPayload<'SET_DATASET_REMARKS', { remarks: string }> =>
    CreateAction(CreateDatasetActionType.SET_DATSET_REMARKS, {
      remarks,
    }),
  uploadCompleted: (): ActionWithPayload<
    'UPLOAD_COMPLETED',
    { uploadSubState: UploadSubState }
  > =>
    CreateAction(CreateDatasetActionType.UPLOAD_COMPLETED, {
      uploadSubState: 'completed',
    }),
  setDatasetId: (
    datasetId: string
  ): ActionWithPayload<'CREATE_DATASET_SET_DATASETID', { datasetId: string }> =>
    CreateAction(CreateDatasetActionType.CREATE_DATASET_SET_DATASETID, {
      datasetId,
    }),
  uploadError: (): ActionWithPayload<
    'UPLOAD_ERROR',
    { uploadSubState: UploadSubState }
  > =>
    CreateAction(CreateDatasetActionType.UPLOAD_ERROR, {
      uploadSubState: 'uploadError',
    }),
  uploadErrorAll: (
    annotationSetList: AddedAnnotationSetList[]
  ): ActionWithPayload<
    'UPLOAD_ERROR_ALL',
    {
      uploadSubState: UploadSubState
      annotationSetList: AddedAnnotationSetList[]
    }
  > =>
    CreateAction(CreateDatasetActionType.UPLOAD_ERROR_ALL, {
      uploadSubState: 'uploadError',
      annotationSetList,
    }),
  uploading: (): ActionWithPayload<
    'CREATE_DATASET_UPLOADING',
    { uploadSubState: UploadSubState }
  > =>
    CreateAction(CreateDatasetActionType.CREATE_DATASET_UPLOADING, {
      uploadSubState: 'uploading',
    }),
  documentError: (): ActionWithPayload<
    'CREATE_DATASET_DOCUMENT_ERROR',
    { uploadSubState: UploadSubState }
  > =>
    CreateAction(CreateDatasetActionType.CREATE_DATASET_DOCUMENT_ERROR, {
      uploadSubState: 'documentError',
    }),
  setFileSizeError: (
    errorFile: File
  ): ActionWithPayload<'SET_FILE_SIZE_ERROR', { errorFile: File }> =>
    CreateAction(CreateDatasetActionType.SET_FILE_SIZE_ERROR, { errorFile }),
  clearCreateDataset: (): Action<'CLEAR_CREATE_DATASET_STATE'> =>
    CreateAction(CreateDatasetActionType.CLEAR_CREATE_DATASET_STATE),
  addAnnotationFile: (
    annotationSetList: AddedAnnotationSetList[]
  ): ActionWithPayload<
    'ADD_ANNOTATION_FILE',
    { annotationSetList: AddedAnnotationSetList[] }
  > =>
    CreateAction(CreateDatasetActionType.ADD_ANNOTATION_FILE, {
      annotationSetList,
    }),
  setAnnotationSubState: (
    annotationSubState: SelectType
  ): ActionWithPayload<
    'SET_ANNOTATION_SUB_STATE',
    { annotationSubState: SelectType }
  > =>
    CreateAction(CreateDatasetActionType.SET_ANNOTATION_SUB_STATE, {
      annotationSubState: annotationSubState,
    }),
  setDatasetState: (
    datasetState: DatasetState
  ): ActionWithPayload<'SET_DATASET_STATE', { datasetState: DatasetState }> =>
    CreateAction(CreateDatasetActionType.SET_DATASET_STATE, {
      datasetState,
    }),
  clearInputStepState: (): Action<'CLEAR_INPUT_STEP_STATE'> =>
    CreateAction(CreateDatasetActionType.CLEAR_INPUT_STEP_STATE),
  clearClassSetStepState: (): Action<'CLEAR_CLASS_SET_STEP_STATE'> =>
    CreateAction(CreateDatasetActionType.CLEAR_CLASS_SET_STEP_STATE),
  clearDatasetTemplateStepState:
    (): Action<'CLEAR_DATASET_TEMPLATE_STEP_STATE'> =>
      CreateAction(CreateDatasetActionType.CLEAR_DATASET_TEMPLATE_STEP_STATE),
  clearAnnotationStepState: (): Action<'CLEAR_ANNOTATION_STEP_STATE'> =>
    CreateAction(CreateDatasetActionType.CLEAR_ANNOTATION_STEP_STATE),
  clearMetadataStepState: (
    annotationSetList: AddedAnnotationSetList[]
  ): ActionWithPayload<
    'CLEAR_METADATA_STEP_STATE',
    {
      metadata: { name: string; remarks: string }
      annotationSetList: AddedAnnotationSetList[]
      datasetState: DatasetState
      inputDataSubState: SelectType
      metadataSubState: InputRequireType
    }
  > =>
    CreateAction(CreateDatasetActionType.CLEAR_METADATA_STEP_STATE, {
      metadata: {
        name: '',
        remarks: '',
      },
      annotationSetList,
      datasetState: 'InputDataState',
      inputDataSubState: 'unselected',
      metadataSubState: 'emptyRequired',
    }),
  clearUploadStepState: (): ActionWithPayload<
    'CLEAR_UPLOAD_STEP_STATE',
    {
      metadata: { name: string; remarks: string }
      datasetState: DatasetState
      metadataSubState: InputRequireType
    }
  > =>
    CreateAction(CreateDatasetActionType.CLEAR_UPLOAD_STEP_STATE, {
      metadata: {
        name: '',
        remarks: '',
      },
      datasetState: 'MetadataState',
      metadataSubState: 'emptyRequired',
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<'SET_IN_PROGRESS', { inProgress: boolean }> =>
    CreateAction(CreateDatasetActionType.SET_IN_PROGRESS, {
      inProgress,
    }),

  /** クラスセット一覧を設定する */
  setClassSets: (
    classSets: ClassSet[]
  ): ActionWithPayload<
    'SET_CLASS_SET_LIST_FOR_CREATE_DATASET',
    { classSets: ClassSet[] }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_CLASS_SET_LIST_FOR_CREATE_DATASET,
      {
        classSets,
      }
    ),

  /** クラスセット一覧をクリアする */
  clearClassSets: (): Action<'CLEAR_CLASS_SET_LIST_FOR_CREATE_DATASET'> =>
    CreateAction(
      CreateDatasetActionType.CLEAR_CLASS_SET_LIST_FOR_CREATE_DATASET
    ),

  /** 選択中のクラスセットを設定する */
  setSelectedClassSet: (
    classSet?: ClassSet
  ): ActionWithPayload<
    'SET_SELECTED_CLASS_SET_FOR_CREATE_DATASET',
    { classSet?: ClassSet }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_SELECTED_CLASS_SET_FOR_CREATE_DATASET,
      {
        classSet,
      }
    ),

  /** クラスセットの表示条件をクリアする */
  clearClassSetDisplayCondition:
    (): Action<'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET'> =>
      CreateAction(
        CreateDatasetActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET
      ),

  /** クラスセットの表示条件を設定する */
  setClassSetDisplayCondition: (
    displayCondition: ClassSetDisplayCondition
  ): ActionWithPayload<
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET',
    { displayCondition: ClassSetDisplayCondition }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_CREATE_DATASET,
      {
        displayCondition,
      }
    ),

  setClassSetSubState: (
    classSetSubState: SelectType
  ): ActionWithPayload<
    'SET_CLASS_SET_SUB_STATE_FOR_CREATE_DATASET',
    { classSetSubState: SelectType }
  > =>
    CreateAction(
      CreateDatasetActionType.SET_CLASS_SET_SUB_STATE_FOR_CREATE_DATASET,
      {
        classSetSubState,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_CREATE_DATASET',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(CreateDatasetActionType.SET_TOAST_INFO_FOR_CREATE_DATASET, {
      toastInfo,
    }),
}
