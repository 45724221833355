import { Dispatch } from 'redux'
import { doc, getDoc } from 'firebase/firestore'

import {
  authInstance,
  getAccountGroupCollection,
  getAccountGroupCustomerRelationsCollection,
  getAccountGroupMetadataCollection,
} from 'state/firebase'
import { State } from 'state/store'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupDocument } from 'utils/fireStore/accountGroup'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupMetadataDocument } from 'utils/fireStore/accountGroupMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupCustomerRelationDocument } from 'utils/fireStore/accountGroupCustomerRelation'

import { accountEntryActions } from './actions'
import { AccountEntryApi } from './apis'
import { AccountGroup } from './types'
import {
  generateInvalidPasswordErrorMessage,
  validatePassword,
} from 'state/utils'

export const accountEntryOperations = {
  /** リストを取得する */
  getAccountGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(accountEntryActions.setInProgress(true))

        const accountGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId

        const accountGroupDoc = await getDoc(
          doc(getAccountGroupCollection(), accountGroupId)
        )
        const accountGroup = accountGroupDoc.data()
        const isAccountGroupDocValid =
          fireStoreTypeGuardForAccountGroupDocument(accountGroup)
        if (!isAccountGroupDocValid) {
          return
        }

        const accountGroupMetadataDoc = await getDoc(
          doc(getAccountGroupMetadataCollection(), accountGroupId)
        )
        const metadata = accountGroupMetadataDoc.data()
        const isAccountGroupMetadataDocValid =
          metadata &&
          fireStoreTypeGuardForAccountGroupMetadataDocument(metadata)
        if (!isAccountGroupMetadataDocValid) {
          return
        }

        const accountGroupCustomerRelationsDoc = await getDoc(
          doc(getAccountGroupCustomerRelationsCollection(), accountGroupId)
        )
        const customerRelations = accountGroupCustomerRelationsDoc.data()
        const isAccountGroupCustomerRelationsDocValid =
          customerRelations &&
          fireStoreTypeGuardForAccountGroupCustomerRelationDocument(
            customerRelations
          )
        if (!isAccountGroupCustomerRelationsDocValid) {
          return
        }

        // アカウントグループリスト作成
        const allAccountGroupListConvert: AccountGroup[] = accountGroup
          ? [
              {
                accountGroupId: accountGroup['account-group-id'],
                name: metadata ? metadata['name'] : '',
                customerList: customerRelations
                  ? customerRelations['customer-list'].map(
                      (customer: { 'customer-id': string }) => ({
                        customerId: customer['customer-id'],
                      })
                    )
                  : [],
              } as AccountGroup,
            ]
          : []

        dispatch(
          accountEntryActions.setAccountGroupList(allAccountGroupListConvert)
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(accountEntryActions.setInProgress(false))
      }
    },
  createAccount:
    (
      accountGroupId: string,
      mailAddress: string,
      firstName: string,
      familyName: string,
      accountRole: string,
      firstPassword: string,
      firstPasswordSecondTime: string
    ) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(accountEntryActions.setInProgress(true))
        if (firstPassword !== firstPasswordSecondTime) {
          dispatch(
            accountEntryActions.setToastInfo({
              type: 'error',
              title: 'パスワードが不正です',
              targets: [],
            })
          )
          dispatch(accountEntryActions.setAccountEntryResult(false))
          return
        }

        const validationStatus = await validatePassword(
          authInstance,
          firstPassword
        )
        if (validationStatus != null && validationStatus.isValid === false) {
          dispatch(
            accountEntryActions.setToastInfo({
              type: 'error',
              title: 'パスワードが正しく設定されていません',
              targets: generateInvalidPasswordErrorMessage(validationStatus),
            })
          )
          return
        }

        const customerIdList: string[] = []

        await AccountEntryApi.createAccount(
          accountGroupId,
          mailAddress,
          firstName,
          familyName,
          accountRole,
          firstPassword,
          customerIdList
        )
        dispatch(accountEntryActions.setAccountEntryResult(true))
      } catch (error) {
        console.error(error)
        dispatch(
          accountEntryActions.setToastInfo({
            type: 'error',
            title: 'アカウントの登録に失敗しました',
            targets: [],
          })
        )
        dispatch(accountEntryActions.setAccountEntryResult(false))
      } finally {
        dispatch(accountEntryActions.setInProgress(false))
      }
    },
}
