import React from 'react'
import Dropzone from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import Link from '@mui/material/Link'
import { blue, green, grey, red } from '@mui/material/colors'

import { fileSelectableDropzoneProps } from './types'

const useStyles = makeStyles()(() => ({
  root: {
    height: '200px',
    width: '100%',
    boxSizing: 'border-box',
    '&>*': {
      height: '100%',
      width: '100%',
      alignItems: 'center',
      outline: 'none',
      boxSizing: 'border-box',
      position: 'relative',
      borderWidth: '2px',
      borderStyle: 'dotted',
    },
  },
  defaultDropzoneFrame: {
    borderColor: grey[500],
  },
  activeFileDialogDropzoneFrame: {
    borderColor: blue[500],
  },
  acceptedDropzoneFrame: {
    borderColor: green[500],
  },
  rejectedDropzoneFrame: {
    borderColor: red[500],
  },
  message: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    '&>*': {
      fontSize: '0.875em',
      color: grey[600],
      display: 'inline-block',
    },
    '&>button': {
      '&:hover': {
        color: blue[500] + '!important',
      },
    },
  },
}))

export const FileSelectableDropzone: React.FC<fileSelectableDropzoneProps> = (
  props: fileSelectableDropzoneProps
) => {
  const { classes } = useStyles()

  const selectContentsStyle = (
    isFileDialogActive: boolean,
    isDragAccept: boolean,
    isDragReject: boolean
  ) => {
    if (isDragAccept) {
      console.log('accept')
      return classes.acceptedDropzoneFrame
    } else if (isDragReject) {
      console.log('reject')
      return classes.rejectedDropzoneFrame
    } else if (isFileDialogActive) {
      return classes.activeFileDialogDropzoneFrame
    }
    return classes.defaultDropzoneFrame
  }
  return (
    <div className={classes.root}>
      <Dropzone
        onDropAccepted={(acceptedFiles) => {
          if (acceptedFiles !== undefined) {
            props.acceptFileOperation(acceptedFiles)
          }
        }}
        onDropRejected={(fileRejections) => {
          if (
            fileRejections !== undefined &&
            props.rejectedFileOperation !== undefined
          ) {
            props.rejectedFileOperation(fileRejections)
          }
        }}
        // fileRejectionsではじいたファイルを取得
        accept={props.fileType}
        maxSize={props.maxSize}
        noClick={true}
        noKeyboard={true}
        noDrag={props.disable}
      >
        {({
          getRootProps,
          getInputProps,
          isFileDialogActive,
          isDragAccept,
          isDragReject,
          open,
        }) => (
          <div
            {...getRootProps()}
            className={selectContentsStyle(
              isFileDialogActive,
              isDragAccept,
              isDragReject
            )}
          >
            <input {...getInputProps()} />
            <div className={classes.message}>
              <div>ファイルをドロップ</div>
              <div>&nbsp;or&nbsp;</div>
              <Link
                component='button'
                underline='always'
                onClick={open}
                disabled={props.disable}
                data-testid={props['data-testid']}
                color='text.secondary'
              >
                ファイルを選択
              </Link>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  )
}
