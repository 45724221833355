import React, { useRef, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { FileRejection } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'
import FormControlLabel from '@mui/material/FormControlLabel'

import { State } from 'state/store'
import {
  AlgorithmStructureVersion,
  TrainingAlgorithmVersion as TrainingAlgorithmVersionForDomain,
} from 'state/app/domainData'
import {
  ModelUploadAction,
  modelUploadActions,
  modelUploadOperations,
  MetaData,
  ModelUploadStateKindArray,
  AlgorithmDisplayCondition,
  DisplayCondition,
  TrainedModelGroup,
  TrainingAlgorithmVersion,
  AlgorithmStructureTableData,
  ModelUploadStateKind,
  TrainedModel,
  Dataset,
  ClassSetDisplayCondition,
  ClassSet,
} from 'state/ducks/model'

import { isString, isUndefined } from 'utils/typeguard'
import { compareVersions, compareVersionsWithPreRelease } from 'utils/versions'
import {
  SelectableTable,
  AccordionLabel,
  CommonCompleteDialog,
  CommonStepper,
  MetadataInput,
  CustomTrainingPageParagraph,
  ErrorMessage,
  GlobalLoading,
  TableDialog,
  FileSelectableDropzone,
  showToast,
  Toast,
  AccordionProgressLabel,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  SearchInput,
  BreadcrumbsComponent,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import {
  convertByteToMatchUnit,
  convertModelKindWord,
  getAllowedModelFileType,
  getUserGroupKindList,
  mappingKindLanguage,
} from 'views/containers/utils'
import { useTheme } from '@mui/material/styles'
import { isVersionWithPreRelease } from 'views/containers/utils/typeguard'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.modelUploadState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ModelUploadAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (props: {
    modelUploadStateKind: ModelUploadStateKind
    selectedTrainedModelGroupId?: string
    trainingAlgorithmSubStep?: boolean
  }) => {
    const {
      modelUploadStateKind,
      selectedTrainedModelGroupId,
      trainingAlgorithmSubStep,
    } = props
    switch (modelUploadStateKind) {
      case 'AlgorithmState':
        if (trainingAlgorithmSubStep) {
          dispatch(modelUploadOperations.getClassSets())
        }
        break
      case 'ModelGroup':
        if (!selectedTrainedModelGroupId) {
          dispatch(modelUploadOperations.getTrainedModelGroupList())
        }
        break
      case 'BaseModelState':
        dispatch(modelUploadOperations.getBaseModelGroupList())
        break
      case 'DatasetState':
        dispatch(modelUploadOperations.getDatasetList())
        break
      default:
        break
    }
  },
  /** 登録先モデルグループの一覧取得 */
  getTrainedModelGroupList: (selectedTrainedModelGroupId?: string) => {
    dispatch(
      modelUploadOperations.getTrainedModelGroupList(
        selectedTrainedModelGroupId
      )
    )
  },
  /** データセットの一覧取得 */
  getDatasetList: () => {
    dispatch(modelUploadOperations.getDatasetList())
  },
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) => {
    if (currentStep >= ModelUploadStateKindArray.length - 1) {
      /** モデルアップロード */
      dispatch(modelUploadOperations.executeModelUpload())
    } else {
      dispatch(modelUploadOperations.nextStep(currentStep))
    }
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) => {
    dispatch(modelUploadOperations.prevStep(currentStep))
  },
  /** 選んだアルゴリズムをセットする */
  setSelectedAlgorithmId: (algorithmId: string) => {
    dispatch(modelUploadOperations.setSelectedAlgorithmId(algorithmId))
  },
  /** 選んだアルゴリズムバージョンをセットする */
  setSelectedAlgorithm: (algorithm?: TrainingAlgorithmVersionForDomain) => {
    dispatch(
      modelUploadOperations.setSelectedTrainingAlgorithmVersion(algorithm)
    )
  },
  /** 選んだアルゴリズムストラクチャーをセットする */
  setSelectedAlgorithmStructure: (
    algorithmStructure?: AlgorithmStructureVersion
  ) => {
    dispatch(
      modelUploadOperations.setSelectedTrainingAlgorithmStructureVersion(
        algorithmStructure
      )
    )
  },
  /** モデル種別の選択 */
  setSelectedModelKind: (
    modelKind?: 'Generic' | 'Custom' | 'Specified' | undefined
  ) => {
    dispatch(modelUploadOperations.setSelectedModelKind(modelKind))
  },
  /** モデルバージョンの選択 */
  setSelectedModelVersion: (modelVersion?: string) => {
    dispatch(modelUploadActions.setSelectedModelVersion(modelVersion))
  },
  /** 入力したメタデータをセットする */
  setModelMetaData: (data?: MetaData) =>
    dispatch(modelUploadOperations.setModelMetaData(data)),
  clearModelUploadState: () =>
    dispatch(modelUploadActions.clearModelUploadState()),
  /** アルゴリズムの表示条件の変更 */
  setAlgorithmDisplayCondition: (
    algorithmDisplayCondition: AlgorithmDisplayCondition
  ) =>
    dispatch(
      modelUploadActions.setAlgorithmDisplayCondition(algorithmDisplayCondition)
    ),
  /** 選んだリストをセットする(BaseModel) */
  setSelectedBaseModel: (data?: TrainedModel) =>
    dispatch(modelUploadOperations.setSelectedBaseModel(data)),
  /** 選んだリストをセットする(Dataset) */
  setSelectedDataset: (data?: Dataset) =>
    dispatch(modelUploadOperations.setSelectedDataset(data)),
  /** データセットの表示条件の変更 */
  setDatasetDisplayCondition: (datasetCondition: DisplayCondition) =>
    dispatch(modelUploadActions.setDatasetDisplayCondition(datasetCondition)),
  /** ベースモデルの表示条件の変更 */
  setBaseModelDisplayCondition: (trainedModelCondition: DisplayCondition) => {
    dispatch(
      modelUploadActions.setBaseModelDisplayCondition(trainedModelCondition)
    )
  },
  /** ベースモデルのモデルグループの選択 */
  setSelectedBaseModelGroup: (modelGroup?: TrainedModelGroup) =>
    dispatch(modelUploadActions.setSelectedBaseModelGroup(modelGroup)),
  /** 登録先モデルグループの選択 */
  setSelectedDestinationTrainedModelGroup: (modelGroup?: TrainedModelGroup) =>
    dispatch(
      modelUploadOperations.setSelectedDestinationTrainedModelGroup(modelGroup)
    ),
  /** ベースモデルのモデルグループ表示条件 */
  setBaseModelGroupDisplayCondition: (
    condition: DisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ) => {
    dispatch(modelUploadActions.setBaseModelGroupDisplayCondition(condition))
  },
  /** 登録先モデルグループの表示条件 */
  setDestinationTrainedModelGroupDisplayCondition: (
    condition: DisplayCondition
  ) => {
    dispatch(
      modelUploadActions.setDestinationTrainedModelGroupDisplayCondition(
        condition
      )
    )
  },
  clearBaseModelGroupDisplayCondition: () =>
    dispatch(modelUploadActions.clearBaseModelGroupDisplayCondition()),
  /** 画像セットファイル追加 */
  setInputFiles: (files: File[]) =>
    dispatch(modelUploadOperations.setInputFiles(files)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(modelUploadActions.setToastInfo(undefined)),
  /** クラスセット一覧を取得 */
  getClassSets: () => dispatch(modelUploadOperations.getClassSets()),
  /** クラスセット一覧をクリア */
  clearClassSets: () => dispatch(modelUploadActions.clearClassSets()),
  /** クラスセットの表示条件の変更 */
  setClassSetDisplayCondition: (classSetCondition: ClassSetDisplayCondition) =>
    dispatch(modelUploadActions.setClassSetDisplayCondition(classSetCondition)),
  /** クラスセットの表示条件をクリア */
  clearClassSetDisplayCondition: () =>
    dispatch(modelUploadActions.clearClassSetDisplayCondition()),
  /** 選んだリストをセットする(ClassSet) */
  setSelectedClassSet: (data?: ClassSet) =>
    dispatch(modelUploadOperations.setSelectedClassSet(data)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

/** テーブルのヘッダー */
const CLASS_SETS_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'classSetId',
    title: 'クラスセットID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classSetName',
    title: 'クラスセット名',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classList',
    title: 'クラスセット',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'classSetRemarks',
    title: 'Remarks',
    width: 300,
    sortable: false,
    position: 'left',
  },
]

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  algorithmSelectBox: {
    width: '100%',
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  dataCreateDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
}))

/** ステップの名称 */
const STEP_NAMES = [
  'アルゴリズム',
  'モデルグループ',
  'ベースモデル',
  'データセット',
  'モデル',
  'メタデータ',
  '確認',
]
/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** モデル種別一覧 */
const MODEL_KIND_LIST = [
  {
    kind: 'Generic',
    name: '汎用',
  },
  {
    kind: 'Custom',
    name: 'カスタム',
  },
  {
    kind: 'Specified',
    name: 'スペシファイド',
  },
]

/** アドバンスド設定のテーブルのヘッダー */
const ALGORITHM_STRUCTURE_LIST: SelectableTableHeader[] = [
  {
    id: 'config',
    title: 'コンフィグ',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'version',
    title: 'バージョン',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** アルゴリズムのテーブルのヘッダー */
const ALGORITHM_HEADERS: SelectableTableHeader[] = [
  {
    id: 'version',
    title: 'バージョン',
    width: 60,
    sortable: false,
    position: 'center',
  },
  {
    id: 'releasedAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのヘッダー */
const MODEL_GROUP_HEADERS: SelectableTableHeader[] = [
  {
    id: 'model-group-id',
    title: 'モデルグループ ID',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'model-group-name',
    title: 'モデルグループ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'trained-model-group-version-latest',
    title: '最新バージョン',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-model-group-name-latest',
    title: '最新モデル名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-model-count',
    title: '登録モデル数',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'updatedAt',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-user-id',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** ベースモデルのテーブルの選択状態 */
const BASE_MODEL_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trainedModelId',
    title: 'モデルID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelGroupVersion',
    title: 'バージョン',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'modelName',
    title: 'モデル名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'transactionStatus',
    title: 'ステータス',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** データセットのテーブルの選択状態 */
const DATASET_HEADERS: SelectableTableHeader[] = [
  {
    id: 'datasetName',
    title: 'データセット名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'datasetId',
    title: 'データセットID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

type AlgorithmSpecificContentProps = Props & {
  classSetToDetail: (classSet?: ClassSet) =>
    | {
        ['クラスセットID']: string
        ['クラスセット名']: string
        ['クラス名リスト']: string
        ['登録日時']: string
        ['備考']: string
      }
    | undefined
}
const AlgorithmSpecificContent: React.FC<AlgorithmSpecificContentProps> = (
  props: AlgorithmSpecificContentProps
) => {
  if (props.domainData.selectedClassSet != null) {
    return (
      <Box component={Paper} my={2} p={'24px 32px 32px'} width='100%'>
        <Typography>クラスセット</Typography>
        <Box mt={1}>
          <InputLabel shrink>クラスセット名</InputLabel>
          <Box mt={1}>
            <AccordionLabel
              label={props.domainData.selectedClassSet?.classSetName ?? ''}
              details={props.classSetToDetail(
                props.domainData.selectedClassSet
              )}
              prefix={'-'}
              delimiter={':'}
            />
          </Box>
        </Box>
      </Box>
    )
  }
  return <></>
}

const ModelEntry: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  const [modelVersionList, setModelVersionList] = useState<string[]>([])

  /** 学習アルゴリズムのサブステップ */
  const [trainingAlgorithmSubStep, setTrainingAlgorithmSubStep] =
    useState(false)

  /** ベースモデルのモデルグループのサブステップ */
  const [baseModelSubStep, setBaseModelSubStep] = useState<boolean>(false)

  const [settingBaseModel, setSettingBaseModel] = useState<boolean>(false)

  const [settingDataset, setSettingDataset] = useState<boolean>(false)

  /** パスパラメータのtrained-model-group-idを取得 */
  const [selectedTrainedModelGroupId, setSelectedTrainedModelGroupId] =
    useState<string | undefined>(undefined)

  /** カレントのステップ */
  const currentStep = useMemo(
    () =>
      ModelUploadStateKindArray.indexOf(props.appState.modelUploadStateKind),
    [props.appState.modelUploadStateKind]
  )
  /** 各種一覧取得 */
  useEffect(() => {
    props.getParams({
      modelUploadStateKind: props.appState.modelUploadStateKind,
      selectedTrainedModelGroupId,
      trainingAlgorithmSubStep,
    })
  }, [
    props.appState.modelUploadStateKind,
    selectedTrainedModelGroupId,
    trainingAlgorithmSubStep,
  ])

  useEffect(() => {
    // NOTE: モデルグループIDを取得する
    const modelGroupId = (props.match.params as { [key: string]: string })[
      'modelGroupId'
    ]
    if (modelGroupId) {
      setSelectedTrainedModelGroupId(modelGroupId)
      props.getTrainedModelGroupList(modelGroupId)
    } else {
      props.setSelectedAlgorithmId(props.algorithms[0].algorithmId)
    }

    return () => {
      props.clearModelUploadState()
    }
  }, [])

  /** バージョンの配列セット */
  useEffect(() => {
    const versionNumberList =
      !isUndefined(props.domainData.selectedDestinationTrainedModelGroup) &&
      !isUndefined(
        props.domainData.selectedDestinationTrainedModelGroup
          .latestTrainedModelVersion
      ) &&
      props.domainData.selectedDestinationTrainedModelGroup
        .latestTrainedModelVersion !== '0'
        ? props.domainData.selectedDestinationTrainedModelGroup.latestTrainedModelVersion.split(
            '.'
          )
        : '0.0.0'.split('.')
    const major = Number(versionNumberList[0])
    const minor = Number(versionNumberList[1])
    const patch = Number(versionNumberList[2])
    props.setSelectedModelVersion(`${major}.${minor}.${patch + 1}`)
    setModelVersionList([
      `${major}.${minor}.${patch + 1}`,
      `${major}.${minor + 1}.0`,
      `${major + 1}.0.0`,
    ])
  }, [props.domainData.selectedDestinationTrainedModelGroup])

  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(
        props.appState.toastInfo.title,
        props.appState.toastInfo.targets
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** File詳細変換 */
  const uploadFileToDetail = (file?: File) => {
    if (isUndefined(file)) return undefined
    return {
      ['ファイルサイズ']: convertByteToMatchUnit(file.size),
      ['ファイル形式']: file.name.split('.').pop(),
    }
  }

  const showErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='modelUploadErrorToastFileName'>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  const fileUploadErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  /** モデルの表示名更新処理 */
  const updateModelMetaDataName = (name: string) => {
    props.setModelMetaData({
      ...props.domainData.modelMetaData,
      name,
    })
  }

  /** モデルの備考更新処理 */
  const updateModelMetaDataRemarks = (remarks: string) => {
    props.setModelMetaData({
      ...props.domainData.modelMetaData,
      remarks,
    })
  }

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        if (trainingAlgorithmSubStep) {
          return (
            props.appState.modelUploadSubStateKind.classSetSubState ===
            'Selected'
          )
        }
        return (
          props.appState.modelUploadSubStateKind.algorithmSubState ===
          'Selected'
        )
      case 'ModelGroup':
        return (
          props.appState.modelUploadSubStateKind.modelGroupSubState ===
          'Selected'
        )
      case 'BaseModelState':
        if (!settingBaseModel) {
          return true
        } else {
          if (baseModelSubStep) {
            return (
              props.appState.modelUploadSubStateKind.baseModelSubState ===
              'Selected'
            )
          } else {
            return props.domainData.selectedBaseModelGroup !== undefined
          }
        }
      case 'DatasetState':
        if (!settingDataset) {
          return true
        } else {
          return (
            props.appState.modelUploadSubStateKind.datasetSubState ===
            'Selected'
          )
        }
      case 'ModelUpload':
        return (
          props.appState.modelUploadSubStateKind.modelUploadSubState ===
          'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.modelUploadSubStateKind.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    baseModelSubStep,
    settingBaseModel,
    props.appState.modelUploadStateKind,
    props.appState.modelUploadSubStateKind,
  ])

  /** ステップ更新時のスクロール操作 */
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
      handleChangeSearchValue('')
    }
  }, [currentStep])

  /** 次ページの表示 */
  const nextPageAction = () => {
    const selectedAlgorithmId =
      props.domainData.selectedTrainingAlgorithm.algorithmId ?? ''
    const objectClassificationAlgorithmIds = props.algorithms
      .filter(
        (algorithm) => algorithm.algorithmPurpose === 'ObjectClassification'
      )
      .map((algorithm) => algorithm.algorithmId)

    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        if (
          objectClassificationAlgorithmIds.includes(selectedAlgorithmId) &&
          !trainingAlgorithmSubStep
        ) {
          setTrainingAlgorithmSubStep(true)
          break
        }
        props.nextStep(currentStep)
        break
      case 'BaseModelState':
        if (!settingBaseModel) {
          props.nextStep(currentStep)
        } else {
          if (baseModelSubStep && props.domainData.selectedBaseModelGroup) {
            props.nextStep(currentStep)
            break
          } else if (props.domainData.selectedBaseModelGroup) {
            setBaseModelSubStep(true)
            break
          }
        }
        break
      default:
        props.nextStep(currentStep)
        break
    }
  }

  /** 前ページ表示 */
  const prevPageAction = () => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        if (trainingAlgorithmSubStep) {
          setTrainingAlgorithmSubStep(false)
          props.clearClassSets()
          props.setSelectedClassSet(undefined)
          props.clearClassSetDisplayCondition()
          handleChangeSearchValue('')
          break
        }
        props.prevStep(currentStep)
        break
      case 'BaseModelState':
        if (baseModelSubStep) {
          setBaseModelSubStep(false)
          break
        } else {
          props.prevStep(currentStep)
          break
        }
      default:
        props.prevStep(currentStep)
        break
    }
  }

  /** モデルアップロードの失敗時メッセージ */
  const ModelUploadErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.modelUploadSubStateKind.executeSubState === 'ExecuteError'
    ) {
      errorMessages.push('モデルの送信に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  /** クエリのモデルグループが検索失敗時メッセージ */
  const TrainedModelGroupErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.modelUploadSubStateKind.baseModelSubState === 'NotFound'
    ) {
      errorMessages.push(
        'モデル詳細画面で選択したモデルグループに一致するデータが存在しません。'
      )
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  const {
    selectedAlgorithmIndex,
    selectAlgorithmTableRadio,
    trainingAlgorithmRows,
    trainingAlgorithmToDetail,
    setSelectRowIndex,
    tableDialog,
    selectedAlgorithmTableOrder,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    changeTableSortOrder,
    pageChange,
    selectedDestinationTrainedModelGroupIndex,
    handleChangeDisplayNumber,
    modelGroupToDetail,
    trainingAlgorithmStructureRows,
    selectAlgorithmStructureTableRadio,
    selectedAlgorithmStructureIndex,
    baseModelGroupRows,
    searchedDatasetListLength,
    searchedBaseModelGroupListLength,
    searchedDestinationTrainedModelGroupListLength,
    selectedBaseModelTableRadio,
    selectedDestinationTrainedModelTableRadio,
    selectBaseModelTableRadio,
    selectDatasetTableRadio,
    selectedBaseModelIndex,
    selectedBaseModelGroupIndex,
    selectedDatasetIndex,
    datasetRows,
    destinationTrainedModelGroupRows,
    baseModelRows,
    trainedModelToDetail,
    datasetToDetail,
    selectUserGroupKind,
    classSetRows,
    searchedClassSetsLength,
    selectedClassSetIndex,
    selectClassSetTableRadio,
    classSetToDetail,
  } = useTableActions(props, baseModelSubStep, settingBaseModel, settingDataset)

  /** アルゴリズム選択 */
  const setSelectedAlgorithmId = (id: string) => {
    props.setSelectedAlgorithm(undefined)
    props.setSelectedAlgorithmStructure(undefined)
    props.setSelectedDestinationTrainedModelGroup(undefined)
    props.setSelectedAlgorithmId(id)
  }

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        if (isUndefined(props.algorithms)) {
          return <></>
        }

        if (trainingAlgorithmSubStep) {
          return (
            <div className={classes.stepContainer}>
              <CustomTrainingPageParagraph>
                <Box mt={2}>
                  <FormControl
                    variant='outlined'
                    className={classes.algorithmSelectBox}
                  >
                    <InputLabel id='class-set-user-group-kind'>
                      データ種別
                    </InputLabel>
                    <Select
                      labelId='class-set-user-group-kind-label'
                      id='class-set-user-group-kind-outlined'
                      value={
                        props.domainData.classSetDisplayCondition
                          .selectedUserGroupKind
                      }
                      onChange={(e) =>
                        selectUserGroupKind(
                          e.target.value as 'UserGroup' | 'SharedUserGroup'
                        )
                      }
                      label='Select User Group Kind'
                    >
                      {userGroupKindList.map((kind) => (
                        <MenuItem
                          data-testid={kind.kind}
                          value={kind.kind}
                          key={kind.kind}
                        >
                          {kind.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={2}>
                  <div className={classes.searchForm}>
                    <div className={classes.searchField}>
                      <SearchInput
                        placeholder='検索 (クラスセットIDなど)'
                        value={tableSearchValue}
                        onChangeValue={(event) =>
                          handleChangeSearchValue(event.target.value)
                        }
                        onClickSearch={() => searchTableContent()}
                        onPressEnter={() => searchTableContent()}
                      />
                    </div>
                  </div>
                </Box>
                <SelectableTable
                  headers={CLASS_SETS_TABLE_HEADERS}
                  rows={classSetRows}
                  displayNumber={
                    props.domainData.classSetDisplayCondition.displayNumber
                  }
                  totalCount={
                    searchedClassSetsLength ?? props.domainData.classSets.length
                  }
                  tableHeight={600}
                  selectedRowNumber={selectedClassSetIndex}
                  fixedColumnNumber={0}
                  page={props.domainData.classSetDisplayCondition.pageNumber}
                  sortOrder={{
                    key: props.domainData.classSetDisplayCondition.sortKey,
                    order: props.domainData.classSetDisplayCondition.sortOrder,
                  }}
                  displayNoneRadio={false}
                  onChangeDisplayNumber={(displayNumber: number) =>
                    handleChangeDisplayNumber(displayNumber)
                  }
                  onClickRadio={(row: number) => selectClassSetTableRadio(row)}
                  onClickOrderChange={(key: string) =>
                    changeTableSortOrder(key)
                  }
                  onClickPageChange={(pageNumber: number) =>
                    pageChange(pageNumber)
                  }
                />
              </CustomTrainingPageParagraph>
            </div>
          )
        }
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelUpload'>アルゴリズム</InputLabel>
                <Select
                  data-testid='select'
                  labelId='modelUpload-label'
                  id='modelUpload-outlined'
                  value={props.domainData.selectedTrainingAlgorithm.algorithmId}
                  onChange={(e: SelectChangeEvent<string>) =>
                    setSelectedAlgorithmId(e.target.value as string)
                  }
                  label='Select Algorithm'
                  disabled={'modelGroupId' in props.match.params}
                >
                  {props.algorithms.map((algorithm) => {
                    return (
                      <MenuItem
                        data-testid={algorithm.algorithmId}
                        value={algorithm.algorithmId}
                        key={algorithm.algorithmId}
                      >
                        {algorithm.metadata.name.ja}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              {!isUndefined(
                props.domainData.selectedTrainingAlgorithm.algorithmId
              ) && (
                <SelectableTable
                  headers={ALGORITHM_HEADERS}
                  fixedColumnNumber={0}
                  selectedRowNumber={selectedAlgorithmIndex}
                  page={0}
                  sortOrder={{
                    key: props.domainData.algorithmDisplayCondition.sortKey,
                    order: props.domainData.algorithmDisplayCondition.sortOrder,
                  }}
                  rows={trainingAlgorithmRows}
                  tableHeight={4 + TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                  onClickOrderChange={(key: string) =>
                    selectedAlgorithmTableOrder(key)
                  }
                  onClickRadio={(row: number) => selectAlgorithmTableRadio(row)}
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                />
              )}
            </CustomTrainingPageParagraph>
            <Box mt={2} mb={2}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body1' className={classes.labelText}>
                    アドバンスド設定
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width='100%'>
                    <SelectableTable
                      headers={ALGORITHM_STRUCTURE_LIST}
                      fixedColumnNumber={0}
                      selectedRowNumber={selectedAlgorithmStructureIndex}
                      page={0}
                      rows={trainingAlgorithmStructureRows}
                      tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                      onClickRadio={(row: number) =>
                        selectAlgorithmStructureTableRadio(row)
                      }
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
        )
      case 'ModelGroup':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'} title={''}>
              <CustomTrainingPageParagraph level={'chapter'}>
                <div className={classes.searchForm}>
                  <div className={classes.searchField}>
                    <SearchInput
                      placeholder='検索（モデルグループIDなど）'
                      value={tableSearchValue}
                      onChangeValue={(event) =>
                        handleChangeSearchValue(event.target.value)
                      }
                      onClickSearch={() => searchTableContent()}
                      onPressEnter={() => searchTableContent()}
                    />
                  </div>
                </div>
                <SelectableTable
                  displayNumber={
                    props.domainData.destinationModelGroupDisplayCondition
                      .displayNumber
                  }
                  headers={MODEL_GROUP_HEADERS}
                  rows={destinationTrainedModelGroupRows}
                  totalCount={
                    searchedDestinationTrainedModelGroupListLength ??
                    props.domainData.destinationTrainedModelGroups.length
                  }
                  tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                  onChangeDisplayNumber={(displayNumber: number) =>
                    handleChangeDisplayNumber(displayNumber)
                  }
                  selectedRowNumber={selectedDestinationTrainedModelGroupIndex}
                  fixedColumnNumber={0}
                  page={
                    props.domainData.destinationModelGroupDisplayCondition
                      .pageNumber
                  }
                  sortOrder={{
                    key: props.domainData.destinationModelGroupDisplayCondition
                      .sortKey,
                    order:
                      props.domainData.destinationModelGroupDisplayCondition
                        .sortOrder,
                  }}
                  onClickRadio={(row: number) =>
                    selectedDestinationTrainedModelTableRadio(row)
                  }
                  onClickRow={(row: number) => {
                    setSelectRowIndex(row)
                  }}
                  onClickOrderChange={(key: string) =>
                    changeTableSortOrder(key)
                  }
                  onClickPageChange={(pageNumber: number) =>
                    pageChange(pageNumber)
                  }
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'BaseModelState':
        if (isUndefined(props.domainData.destinationTrainedModelGroups)) {
          return <></>
        } else {
          return (
            <>
              {!baseModelSubStep ? (
                <div className={classes.stepContainer}>
                  {props.appState.modelUploadSubStateKind.baseModelSubState ===
                  'NotFound' ? (
                    <TrainedModelGroupErrorMessage {...props} />
                  ) : (
                    <CustomTrainingPageParagraph
                      level={'part'}
                      title={'ベースモデルを設定する'}
                    >
                      <Box mb={2}>
                        <RadioGroup value={settingBaseModel} row>
                          <FormControlLabel
                            data-testid='not-set-base-model'
                            value={false}
                            control={<Radio size='small' color='secondary' />}
                            label='設定しない'
                            onClick={() => {
                              setSettingBaseModel(false)
                              setSettingDataset(false)
                              props.setSelectedBaseModelGroup()
                              props.clearBaseModelGroupDisplayCondition()
                            }}
                          />
                          <FormControlLabel
                            data-testid='set-base-model'
                            value={true}
                            control={<Radio size='small' color='secondary' />}
                            label='設定する'
                            onClick={() => {
                              setSettingBaseModel(true)
                              props.setSelectedBaseModelGroup(
                                props.domainData.baseModelGroups
                                  .sharedUserGroup[0]
                              )
                            }}
                          />
                        </RadioGroup>
                      </Box>
                      {settingBaseModel && (
                        <CustomTrainingPageParagraph level={'chapter'}>
                          <CustomTrainingPageParagraph>
                            <FormControl
                              variant='outlined'
                              className={classes.algorithmSelectBox}
                            >
                              <InputLabel id='modelListUserGroupKind'>
                                データ種別
                              </InputLabel>
                              <Select
                                labelId='modelListUserGroupKind-label'
                                id='modelListUserGroupKind-outlined'
                                value={
                                  props.domainData
                                    .baseModelGroupDisplayCondition
                                    .selectedUserGroupKind
                                }
                                onChange={(e) =>
                                  selectUserGroupKind(
                                    e.target.value as
                                      | 'UserGroup'
                                      | 'SharedUserGroup'
                                  )
                                }
                                label='Select User Group Kind'
                              >
                                {userGroupKindList.map((kind) => (
                                  <MenuItem
                                    data-testid={kind.kind}
                                    value={kind.kind}
                                    key={kind.kind}
                                  >
                                    {kind.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </CustomTrainingPageParagraph>
                          <div className={classes.searchForm}>
                            <div className={classes.searchField}>
                              <SearchInput
                                placeholder='検索（モデルグループIDなど）'
                                value={tableSearchValue}
                                onChangeValue={(event) =>
                                  handleChangeSearchValue(event.target.value)
                                }
                                onClickSearch={() => searchTableContent()}
                                onPressEnter={() => searchTableContent()}
                              />
                            </div>
                          </div>
                          <SelectableTable
                            displayNumber={
                              props.domainData.baseModelGroupDisplayCondition
                                .displayNumber
                            }
                            headers={MODEL_GROUP_HEADERS}
                            rows={baseModelGroupRows}
                            totalCount={
                              searchedBaseModelGroupListLength ??
                              props.domainData.baseModelGroupDisplayCondition
                                .selectedUserGroupKind === 'SharedUserGroup'
                                ? props.domainData.baseModelGroups
                                    .sharedUserGroup.length
                                : props.domainData.baseModelGroups.userGroup
                                    .length
                            }
                            tableHeight={
                              TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                            }
                            onChangeDisplayNumber={(displayNumber: number) =>
                              handleChangeDisplayNumber(displayNumber)
                            }
                            selectedRowNumber={selectedBaseModelGroupIndex}
                            fixedColumnNumber={0}
                            page={
                              props.domainData.baseModelGroupDisplayCondition
                                .pageNumber
                            }
                            sortOrder={{
                              key: props.domainData
                                .baseModelGroupDisplayCondition.sortKey,
                              order:
                                props.domainData.baseModelGroupDisplayCondition
                                  .sortOrder,
                            }}
                            onClickRadio={(row: number) =>
                              selectedBaseModelTableRadio(row)
                            }
                            onClickOrderChange={(key: string) =>
                              changeTableSortOrder(key)
                            }
                            onClickPageChange={(pageNumber: number) =>
                              pageChange(pageNumber)
                            }
                          />
                        </CustomTrainingPageParagraph>
                      )}
                    </CustomTrainingPageParagraph>
                  )}
                </div>
              ) : (
                <div className={classes.stepContainer}>
                  <SelectableTable
                    displayNumber={
                      props.domainData.baseModelDisplayCondition.displayNumber
                    }
                    headers={BASE_MODEL_HEADERS}
                    rows={baseModelRows}
                    totalCount={
                      props.domainData.selectedBaseModelGroup?.trainedModels
                        .length
                    }
                    tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                    onChangeDisplayNumber={(displayNumber: number) =>
                      handleChangeDisplayNumber(displayNumber)
                    }
                    selectedRowNumber={selectedBaseModelIndex}
                    fixedColumnNumber={0}
                    page={props.domainData.baseModelDisplayCondition.pageNumber}
                    sortOrder={{
                      key: props.domainData.baseModelDisplayCondition.sortKey,
                      order:
                        props.domainData.baseModelDisplayCondition.sortOrder,
                    }}
                    onClickRadio={(row: number) =>
                      selectBaseModelTableRadio(row)
                    }
                    onClickRow={(row: number) => {
                      setSelectRowIndex(row)
                    }}
                    onClickOrderChange={(key: string) =>
                      changeTableSortOrder(key)
                    }
                    onClickPageChange={(pageNumber: number) =>
                      pageChange(pageNumber)
                    }
                  />
                </div>
              )}
            </>
          )
        }
      case 'DatasetState':
        if (isUndefined(props.domainData.datasetList)) {
          return <></>
        } else {
          return (
            <>
              <div className={classes.stepContainer}>
                <CustomTrainingPageParagraph
                  level={'part'}
                  title={'データセットを設定する'}
                >
                  <Box mb={2}>
                    <RadioGroup value={settingDataset} row>
                      <FormControlLabel
                        data-testid='not-set-dataset'
                        value={false}
                        control={<Radio size='small' color='secondary' />}
                        label='設定しない'
                        onClick={() => {
                          setSettingDataset(false)
                          props.setSelectedDataset()
                        }}
                      />
                      <FormControlLabel
                        data-testid='set-dataset'
                        value={true}
                        control={<Radio size='small' color='secondary' />}
                        label='設定する'
                        disabled={!settingBaseModel}
                        onClick={() => {
                          !settingBaseModel
                            ? undefined
                            : setSettingDataset(true)
                          props.setSelectedDataset(
                            props.domainData.datasetList[0]
                          )
                        }}
                      />
                    </RadioGroup>
                  </Box>
                  {settingDataset && (
                    <CustomTrainingPageParagraph level={'chapter'}>
                      <div className={classes.searchForm}>
                        <div className={classes.searchField}>
                          <SearchInput
                            placeholder='検索（データセット名、データセットIDなど）'
                            value={tableSearchValue}
                            onChangeValue={(event) =>
                              handleChangeSearchValue(event.target.value)
                            }
                            onClickSearch={() => searchTableContent()}
                            onPressEnter={() => searchTableContent()}
                          />
                        </div>
                      </div>
                      <SelectableTable
                        displayNumber={
                          props.domainData.datasetDisplayCondition.displayNumber
                        }
                        headers={DATASET_HEADERS}
                        rows={datasetRows}
                        totalCount={
                          searchedDatasetListLength ??
                          props.domainData.datasetList.length
                        }
                        onChangeDisplayNumber={(displayNumber: number) =>
                          handleChangeDisplayNumber(displayNumber)
                        }
                        tableHeight={
                          TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                        }
                        selectedRowNumber={selectedDatasetIndex}
                        fixedColumnNumber={0}
                        page={
                          props.domainData.datasetDisplayCondition.pageNumber
                        }
                        sortOrder={{
                          key: props.domainData.datasetDisplayCondition.sortKey,
                          order:
                            props.domainData.datasetDisplayCondition.sortOrder,
                        }}
                        onClickRadio={(row: number) =>
                          selectDatasetTableRadio(row)
                        }
                        onClickRow={(row: number) => {
                          setSelectRowIndex(row)
                        }}
                        onClickOrderChange={(key: string) =>
                          changeTableSortOrder(key)
                        }
                        onClickPageChange={(pageNumber: number) =>
                          pageChange(pageNumber)
                        }
                      />
                    </CustomTrainingPageParagraph>
                  )}
                </CustomTrainingPageParagraph>
              </div>
            </>
          )
        }
      case 'ModelUpload':
        return (
          <div className={classes.stepContainer}>
            <div className={classes.stepTitle}>
              <Typography>モデルを選択します。 </Typography>
            </div>
            <FileSelectableDropzone
              acceptFileOperation={(files: File[]) =>
                props.setInputFiles(files)
              }
              rejectedFileOperation={(fileRejections: FileRejection[]) =>
                fileUploadErrors(fileRejections)
              }
              fileType={getAllowedModelFileType(
                props.algorithms.find((algorithm) => {
                  return (
                    algorithm.algorithmId ===
                    props.domainData.selectedTrainingAlgorithm.algorithmId
                  )
                })?.algorithmPurpose ?? ''
              )}
              data-testid='modelFileDropZone'
            />
            {props.domainData.uploadTargetTrainedModel.map(
              (fileAndProgress, index) => (
                <Box key={index} mt={2}>
                  <AccordionLabel
                    label={fileAndProgress.file.name}
                    details={uploadFileToDetail(fileAndProgress.file)}
                    prefix={'-'}
                    delimiter={':'}
                    defaultExpand={true}
                  />
                </Box>
              )
            )}
          </div>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'} title={'モデル種別'}>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelUploadInput'>モデル種別</InputLabel>
                <Select
                  data-testid='select'
                  labelId='modelUploadInput-label'
                  id='modelUploadInput-outlined'
                  value={props.domainData.selectedModelKind}
                  onChange={(
                    e: SelectChangeEvent<'Generic' | 'Custom' | 'Specified'>
                  ) =>
                    props.setSelectedModelKind(
                      e.target.value as
                        | 'Generic'
                        | 'Custom'
                        | 'Specified'
                        | undefined
                    )
                  }
                  label='Select Algorithm'
                >
                  {MODEL_KIND_LIST.map((kind) => (
                    <MenuItem
                      data-testid={`select${kind.kind}`}
                      value={kind.kind}
                      key={kind.name}
                    >
                      {kind.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
            <Box mb={3}>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelVersionInput'>モデルバージョン</InputLabel>
                <Select
                  data-testid='select'
                  labelId='modelVersionInput-label'
                  id='modelVersionInput-outlined'
                  value={props.domainData.selectedVersion}
                  onChange={(e: SelectChangeEvent<string>) =>
                    props.setSelectedModelVersion(e.target.value as string)
                  }
                  label='Select Model Version'
                >
                  {modelVersionList.map((value) => (
                    <MenuItem
                      data-testid={`select${value}`}
                      value={value}
                      key={value}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <MetadataInput
              nameProps={{
                label: '表示名',
                value: props.domainData.modelMetaData?.name,
                variant: 'outlined',
                readOnly: false,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  updateModelMetaDataName(e.target.value),
              }}
              remarksProps={{
                label: '備考',
                value: props.domainData.modelMetaData?.remarks,
                variant: 'outlined',
                readOnly: false,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  updateModelMetaDataRemarks(e.target.value),
                rowNum: 0,
              }}
              helperText=''
              data-testid={'modelUploadInput'}
            />
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <ModelUploadErrorMessage {...props} />
            <Box component={Paper} mt={1} p={'24px 32px 32px'}>
              <Typography>登録先モデルグループ</Typography>
              <Box mt={1}>
                <AccordionLabel
                  label={
                    props.domainData.selectedDestinationTrainedModelGroup
                      ?.trainedModelGroupName
                  }
                  details={modelGroupToDetail(
                    props.domainData.selectedDestinationTrainedModelGroup
                  )}
                  prefix={'-'}
                  delimiter={':'}
                />
              </Box>
              {props.domainData.selectedBaseModel && (
                <Box mt={1}>
                  <Typography>ベースモデル</Typography>
                  <Box mt={1}>
                    <AccordionLabel
                      label={
                        props.domainData.selectedBaseModel?.trainedModelName
                      }
                      details={trainedModelToDetail(
                        props.domainData.selectedBaseModel,
                        props.domainData.baseModelGroupDisplayCondition
                          .selectedUserGroupKind
                      )}
                      prefix={'-'}
                      delimiter={':'}
                    />
                  </Box>
                </Box>
              )}
              {props.domainData.selectedDataset && (
                <Box mt={1}>
                  <Typography>データセット</Typography>
                  <Box mt={1}>
                    <AccordionLabel
                      label={props.domainData.selectedDataset?.name}
                      details={datasetToDetail(
                        props.domainData.selectedDataset
                      )}
                      prefix={'-'}
                      delimiter={':'}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box component={Paper} mt={2} p={'24px 32px 32px'}>
              <Typography>モデル情報</Typography>
              <Box mt={1}>
                {props.domainData.uploadTargetTrainedModel.map(
                  (fileAndProgress, index) => (
                    <Box key={index} mt={2}>
                      <AccordionProgressLabel
                        label={fileAndProgress.file.name}
                        details={uploadFileToDetail(fileAndProgress.file)}
                        prefix={'-'}
                        delimiter={':'}
                        progressValue={fileAndProgress.progress}
                        progressColor={'green'}
                        progressCompletedColor={'green'}
                        progressWaitingColor={'green'}
                      />
                    </Box>
                  )
                )}
              </Box>
              {props.domainData.selectedModelKind && (
                <Box mt={2}>
                  <TextField
                    id='modelKind'
                    label='種別'
                    className={classes.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={convertModelKindWord(
                      props.domainData.selectedModelKind
                    )}
                    key={props.domainData.selectedTrainingAlgorithm.algorithmId}
                    variant='standard'
                  />
                </Box>
              )}
              <Box mt={2}>
                {props.domainData.selectedVersion && (
                  <TextField
                    id='modelVersion'
                    label='バージョン'
                    className={classes.textField}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={props.domainData.selectedVersion}
                    key={props.domainData.selectedVersion}
                    variant='standard'
                  />
                )}
              </Box>
              <Box mt={2}>
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.modelMetaData?.name,
                    readOnly: true,
                    variant: 'standard',
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.modelMetaData?.remarks,
                    readOnly: true,
                    rowNum: 0,
                    variant: 'standard',
                  }}
                  textFieldSpace={0}
                  helperText=''
                  data-testid={'modelInputPreview'}
                />
              </Box>
            </Box>
            <Box component={Paper} mt={2} p={'24px 32px 32px'} mb={1}>
              <Typography>アルゴリズム</Typography>
              <Box mt={1}>
                {props.domainData.selectedTrainingAlgorithm.algorithmId && (
                  <TextField
                    id='algorithm'
                    className={classes.textField}
                    label='アルゴリズム種別'
                    InputProps={{
                      readOnly: true,
                    }}
                    value={mappingKindLanguage(
                      props.domainData.selectedTrainingAlgorithm.algorithmId
                    )}
                    key={props.domainData.selectedTrainingAlgorithm.algorithmId}
                    variant='standard'
                  />
                )}
              </Box>
              <Box mt={2}>
                <InputLabel shrink>バージョン</InputLabel>
                <Box mt={1}>
                  <AccordionLabel
                    label={''}
                    details={trainingAlgorithmToDetail(
                      props.domainData.selectedTrainingAlgorithmVersion
                    )}
                    prefix={'-'}
                    delimiter={':'}
                  />
                </Box>
              </Box>
            </Box>
            <AlgorithmSpecificContent
              {...props}
              classSetToDetail={classSetToDetail}
            />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Box pl={7}>
          {(props.match.params as { [key: string]: string })[
            'modelGroupId'
          ] && (
            <BreadcrumbsComponent
              breadcrumbsPath={[
                {
                  name: 'モデルグループ一覧',
                  path: 'model-groups',
                },
                {
                  name:
                    props.domainData.selectedDestinationTrainedModelGroup
                      ?.trainedModelGroupName !== ''
                      ? `${props.domainData.selectedDestinationTrainedModelGroup?.trainedModelGroupName}`
                      : `${props.domainData.selectedDestinationTrainedModelGroup?.trainedModelGroupId}`,
                  path: `${props.domainData.selectedDestinationTrainedModelGroup?.trainedModelGroupId}`,
                },
                {
                  name: 'モデル一覧',
                  path: 'models',
                },
                {
                  name: 'モデル作成',
                  path: 'entry',
                },
              ]}
            />
          )}
        </Box>
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.head}>
            <h2 className={classes.pageTitle} data-testid='model-upload-title'>
              モデル
            </h2>
            <div className={classes.stepper}>
              <CommonStepper
                stepLabels={STEP_NAMES}
                activeStepIndex={currentStep}
              />
            </div>
          </div>
          <div className={classes.content} ref={contentRef}>
            {getStepContent(props)}
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              {!(
                props.appState.modelUploadStateKind === 'AlgorithmState' &&
                !trainingAlgorithmSubStep
              ) ? (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={false}
                  onClick={() => prevPageAction()}
                  className={classes.leftButton}
                >
                  {'戻る'}
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                data-testid='next-step'
                variant='contained'
                color='primary'
                disabled={!enableNextButton}
                onClick={() => nextPageAction()}
                className={classes.rightButton}
              >
                {props.appState.modelUploadStateKind === 'ExecuteState'
                  ? '開始'
                  : '次へ'}
              </Button>
            </div>
          </div>
          <CommonCompleteDialog
            open={
              !isUndefined(props.domainData.executedModelId) &&
              props.domainData.fileUploadResult === 'success'
            }
            value={props.domainData.executedModelId ?? ''}
            handleClose={() => {
              if (selectedTrainedModelGroupId) {
                return history.replace(
                  `/model-groups/${selectedTrainedModelGroupId}/models`
                )
              } else {
                return history.replace('/models')
              }
            }}
            label={'モデルID'}
            dialogText={'正常にモデルを送信しました。'}
            data-testid={'exec-model-id'}
          />
          {tableDialog}
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

export const ModelPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelEntry))

const useTableActions = (
  props: Props,
  baseModelSubStep?: boolean,
  settingBaseModel?: boolean,
  settingDataset?: boolean
) => {
  const globalTheme = useTheme()
  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')
  /** 検索結果結果の配列の長さを保持(Dataset) */
  const [searchedDatasetListLength, setSearchedDatasetListLength] = useState<
    number | undefined
  >(undefined)

  /** 検索結果結果の配列の長さを保持(baseModelGroupList) */
  const [
    searchedBaseModelGroupListLength,
    setSearchedBaseModelGroupListLength,
  ] = useState<number | undefined>(undefined)

  /** 検索結果結果の配列の長さを保持(destinationTrainedModelGroup) */
  const [
    searchedDestinationTrainedModelGroupListLength,
    setSearchedDestinationTrainedModelGroupListLength,
  ] = useState<number | undefined>(undefined)
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }

  /** ベースモデルのモデルグループのラジオボタンの選択された時に切り替える処理 */
  const selectedBaseModelTableRadio = (row: number) => {
    const modelGroup = adjustBaseModelGroupTableRow[row]
    props.setSelectedBaseModelGroup(modelGroup)
  }

  /** 登録先モデルグループのラジオボタンの選択された時に切り替える処理 */
  const selectedDestinationTrainedModelTableRadio = (row: number) => {
    const modelGroup = adjustDestinationTrainedModelGroupTableRow[row]
    props.setSelectedDestinationTrainedModelGroup(modelGroup)
  }

  /** ベースモデルのラジオボタンの選択された時に切り替える処理 */
  const selectBaseModelTableRadio = (row: number) => {
    const baseModel = adjustBaseModelTableRow[row]
    props.setSelectedBaseModel(baseModel)
  }

  /** データセットのラジオボタン押下時処理 */
  const selectDatasetTableRadio = (row: number) => {
    const dataset = adjustDatasetTableRow[row]
    props.setSelectedDataset(dataset)
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        {
          const maxPageNumber =
            Math.ceil(props.domainData.classSets.length / displayNumber) - 1
          if (
            props.domainData.classSetDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setClassSetDisplayCondition({
              ...props.domainData.classSetDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setClassSetDisplayCondition({
              ...props.domainData.classSetDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
      case 'ModelGroup':
        {
          const maxPageNumber =
            Math.ceil(
              props.domainData.destinationTrainedModelGroups.length /
                displayNumber
            ) - 1
          if (
            props.domainData.destinationModelGroupDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setDestinationTrainedModelGroupDisplayCondition({
              ...props.domainData.destinationModelGroupDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setDestinationTrainedModelGroupDisplayCondition({
              ...props.domainData.destinationModelGroupDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
      case 'BaseModelState':
        if (baseModelSubStep) {
          {
            if (!props.domainData.selectedBaseModelGroup) return
            const maxPageNumber =
              Math.ceil(
                props.domainData.selectedBaseModelGroup.trainedModels.length /
                  displayNumber
              ) - 1
            if (
              props.domainData.baseModelDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setBaseModelDisplayCondition({
                ...props.domainData.baseModelDisplayCondition,
                displayNumber: displayNumber ?? 5,
              })
            } else {
              /** 表示数を変更 */
              props.setBaseModelDisplayCondition({
                ...props.domainData.baseModelDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 5,
              })
            }
          }
          return
        } else {
          {
            const maxPageNumber =
              Math.ceil(
                props.domainData.destinationTrainedModelGroups.length /
                  displayNumber
              ) - 1
            if (
              props.domainData.baseModelGroupDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setBaseModelGroupDisplayCondition({
                ...props.domainData.baseModelGroupDisplayCondition,
                displayNumber: displayNumber ?? 5,
              })
            } else {
              /** 表示数を変更 */
              props.setBaseModelGroupDisplayCondition({
                ...props.domainData.baseModelGroupDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 5,
              })
            }
          }
          return
        }
      case 'DatasetState':
        {
          const maxPageNumber =
            Math.ceil(props.domainData.datasetList.length / displayNumber) - 1
          if (
            props.domainData.datasetDisplayCondition.pageNumber <= maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setDatasetDisplayCondition({
              ...props.domainData.datasetDisplayCondition,
              displayNumber: displayNumber ?? 10,
            })
          } else {
            /** 表示数を変更 */
            props.setDatasetDisplayCondition({
              ...props.domainData.datasetDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber ?? 10,
            })
          }
        }
        return
    }
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        /** ソートキー、ソートオーダーをセット */
        props.setClassSetDisplayCondition({
          ...props.domainData.classSetDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.classSetDisplayCondition.sortKey === key
              ? props.domainData.classSetDisplayCondition.sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.classSetDisplayCondition.sortOrder,
        })
        break
      case 'ModelGroup':
        /** ソートキー、ソートオーダーをセット */
        props.setDestinationTrainedModelGroupDisplayCondition({
          ...props.domainData.destinationModelGroupDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.destinationModelGroupDisplayCondition.sortKey ===
            key
              ? props.domainData.destinationModelGroupDisplayCondition
                  .sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.destinationModelGroupDisplayCondition
                  .sortOrder,
        })
        break
      case 'BaseModelState':
        if (baseModelSubStep) {
          /** ソートキー、ソートオーダーをセット */
          props.setBaseModelDisplayCondition({
            ...props.domainData.baseModelDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.baseModelDisplayCondition.sortKey === key
                ? props.domainData.baseModelDisplayCondition.sortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.baseModelDisplayCondition.sortOrder,
          })
        } else {
          /** ソートキー、ソートオーダーをセット */
          props.setBaseModelGroupDisplayCondition({
            ...props.domainData.baseModelGroupDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.baseModelGroupDisplayCondition.sortKey === key
                ? props.domainData.baseModelGroupDisplayCondition.sortOrder ===
                  'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.baseModelGroupDisplayCondition.sortOrder,
          })
        }
        break
      case 'DatasetState':
        props.setDatasetDisplayCondition({
          ...props.domainData.datasetDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.datasetDisplayCondition.sortKey === key
              ? props.domainData.datasetDisplayCondition.sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.domainData.datasetDisplayCondition.sortOrder,
        })
        break
      default:
        break
    }
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        props.setClassSetDisplayCondition({
          ...props.domainData.classSetDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'ModelGroup':
        props.setDestinationTrainedModelGroupDisplayCondition({
          ...props.domainData.destinationModelGroupDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'BaseModelState':
        if (baseModelSubStep) {
          props.setBaseModelDisplayCondition({
            ...props.domainData.baseModelDisplayCondition,
            pageNumber: pageNumber,
          })
        } else {
          props.setBaseModelGroupDisplayCondition({
            ...props.domainData.baseModelGroupDisplayCondition,
            pageNumber: pageNumber,
          })
        }
        break
      case 'DatasetState':
        props.setDatasetDisplayCondition({
          ...props.domainData.datasetDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      default:
        break
    }
  }

  /** アルゴリズムのテーブルをソートして返す */
  const trainingAlgorithmTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId)) {
      const dataList = props.algorithms.find(
        (item) =>
          item.algorithmId ===
          props.domainData.selectedTrainingAlgorithm.algorithmId
      )
      if (isUndefined(dataList)) {
        return
      }
      let newTrainingAlgorithmArray: TrainingAlgorithmVersionForDomain[] = []
      switch (props.domainData.algorithmDisplayCondition.sortKey) {
        case 'releasedAt':
          newTrainingAlgorithmArray =
            dataList.trainingAlgorithm.trainingAlgorithmVersions.sort(
              (item, item2) => {
                return (
                  ((item2.releasedAt
                    ? item2.releasedAt
                    : Timestamp.fromMillis(0)
                  )
                    .toDate()
                    .getTime() -
                    (item.releasedAt
                      ? item.releasedAt
                      : Timestamp.fromMillis(0)
                    )
                      .toDate()
                      .getTime()) *
                  (props.domainData.algorithmDisplayCondition.sortOrder ===
                  'desc'
                    ? 1
                    : -1)
                )
              }
            )
          return newTrainingAlgorithmArray
        case 'version':
          newTrainingAlgorithmArray =
            dataList.trainingAlgorithm.trainingAlgorithmVersions.sort(
              (item, item2) => {
                return compareVersionsWithPreRelease(
                  item.algorithmVersion,
                  item2.algorithmVersion,
                  props.domainData.algorithmDisplayCondition.sortOrder
                )
              }
            )
      }
    }
    return []
  }, [
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.algorithmDisplayCondition.sortOrder,
    props.domainData.algorithmDisplayCondition.sortKey,
  ])

  /** アルゴリズムのテーブルをソートして返す */
  const trainingAlgorithmRows = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId)) {
      const dataList = props.algorithms.find(
        (item) =>
          item.algorithmId ===
          props.domainData.selectedTrainingAlgorithm.algorithmId
      )
      if (isUndefined(dataList)) {
        return []
      }
      const rowData = dataList.trainingAlgorithm.trainingAlgorithmVersions.map(
        (data) => {
          return {
            version: data.algorithmVersion,
            releasedAt: data.releasedAt
              ? formatDateTimeSec(data.releasedAt.toDate())
              : undefined,
            remarks: data.metadata.remarks.ja,
          }
        }
      )

      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '' || isUndefined(value)) {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            if (key == 'version') {
              if (isVersionWithPreRelease(value)) {
                return <Typography key={key}>{value.displayName}</Typography>
              } else {
                return (
                  <Box key={key} sx={{ color: 'text.secondary' }}>
                    <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                  </Box>
                )
              }
            }
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [
    trainingAlgorithmTableRow,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.algorithmDisplayCondition.sortOrder,
    props.domainData.algorithmDisplayCondition.sortKey,
  ])

  /** アルゴリズムストラクチャー */
  const trainingAlgorithmStructureRow = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithmVersion)) {
      const selectedAlgorithm = props.algorithms.find(
        (item) =>
          item.algorithmId ===
          props.domainData.selectedTrainingAlgorithm.algorithmId
      )
      if (isUndefined(selectedAlgorithm)) {
        return []
      }

      const selectedVersion =
        selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.find(
          (item) =>
            item.trainingAlgorithmVersion ===
            props.domainData.selectedTrainingAlgorithmVersion
              ?.trainingAlgorithmVersion
        )
      if (isUndefined(selectedVersion)) {
        return []
      }

      const structureVersions: AlgorithmStructureVersion[] = []

      selectedVersion.algorithmStructures.forEach((algorithmStructures) => {
        algorithmStructures.algorithmStructureVersions.forEach(
          (structureVersion) => {
            structureVersions.push(structureVersion)
          }
        )
      })

      let newStructureVersionsArray = structureVersions
      newStructureVersionsArray = newStructureVersionsArray.sort(
        (item, item2) => {
          return compareVersions(
            item.algorithmStructureVersion,
            item2.algorithmStructureVersion,
            'desc'
          )
        }
      )

      return newStructureVersionsArray
    }
    return []
  }, [props.domainData.selectedTrainingAlgorithmVersion])

  /** アルゴリズムストラクチャーのテーブル */
  const trainingAlgorithmStructureRows = useMemo(() => {
    if (!isUndefined(props.domainData.selectedTrainingAlgorithmVersion)) {
      const rowData: AlgorithmStructureTableData[] = []

      trainingAlgorithmStructureRow.map((structureVersion) => {
        rowData.push({
          config: structureVersion.metadata.name.ja,
          remarks: structureVersion.metadata.remarks.ja,
          version: structureVersion.algorithmStructureVersion.displayName,
          createdAt: structureVersion.createdAt
            ? formatDateTimeSec(structureVersion.createdAt.toDate())
            : '',
        })
      })

      return rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          if (value === '') {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          } else {
            return <Typography key={key}>{value}</Typography>
          }
        })
      )
    }
    return []
  }, [
    trainingAlgorithmStructureRow,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
  ])

  /** アルゴリズム詳細変換 */
  const trainingAlgorithmToDetail = (version?: TrainingAlgorithmVersion) => {
    if (isUndefined(version)) return undefined
    return {
      ['アルゴリズムバージョン']: version?.algorithmVersion.displayName
        ? version?.algorithmVersion.displayName
        : '',
      ['登録日時']: version.releasedAt
        ? formatDateTimeSec(version?.releasedAt.toDate())
        : '',
      ['Remarks']: version.metadata.remarks,
    }
  }

  /** アルゴリズム詳細変換(domain) */
  const trainingAlgorithmForDomainToDetail = (
    version?: TrainingAlgorithmVersionForDomain
  ) => {
    if (isUndefined(version)) return undefined
    return {
      ['アルゴリズムバージョン']: version?.algorithmVersion.displayName
        ? version?.algorithmVersion.displayName
        : '',
      ['登録日時']: version.releasedAt
        ? formatDateTimeSec(version?.releasedAt.toDate())
        : '',
      ['Remarks']: version.metadata.remarks.ja,
    }
  }

  /** モデルグループ詳細変換 */
  const modelGroupToDetail = (modelGroup?: TrainedModelGroup) => {
    if (isUndefined(modelGroup)) return undefined
    return {
      ['モデルグループID']: modelGroup.trainedModelGroupId,
      ['登録モデル数']: `${modelGroup.trainedModelCount}`,
      ['最新バージョン']: modelGroup.latestTrainedModelVersion,
      ['最新モデル名']: modelGroup.latestTrainedModelName,
      ['更新日時']: modelGroup.updatedAt
        ? formatDateTimeSec(modelGroup.updatedAt.toDate())
        : '',
      ['作成日時']: modelGroup.createdAt
        ? formatDateTimeSec(modelGroup.createdAt.toDate())
        : '',
      ['作成ユーザーID']: modelGroup.createdBy,
    }
  }

  /** ベースモデル詳細変換 */
  const trainedModelToDetail = (
    baseModel?: TrainedModel,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) => {
    if (isUndefined(baseModel) || isUndefined(userGroupKind)) return undefined
    return {
      ['データ種別']:
        userGroupKind === 'UserGroup' ? 'カスタマーデータ' : '共有データ',
      ['モデルID']: baseModel.trainedModelId,
      ['バージョン']: baseModel.trainedModelGroupVersion.displayName,
      ['モデル名']: baseModel.trainedModelName,
      ['ステータス']: baseModel.transactionStatus,
    }
  }

  /** データセット詳細変換 */
  const datasetToDetail = (dataset?: Dataset) => {
    if (isUndefined(dataset)) return undefined
    return {
      ['データセットID']: dataset.datasetId,
      ['データセット名']: dataset.name,
      ['登録日時']: dataset.createdAt
        ? formatDateTimeSec(dataset.createdAt.toDate())
        : '',
      ['Remarks']: dataset.remarks,
    }
  }

  /** 選択中のテーブルの行 */
  const [selectRowIndex, setSelectRowIndex] = useState<number | undefined>(
    undefined
  )

  /** アルゴリズムテーブルのソート設定 */
  const selectedAlgorithmTableOrder = (key: string) => {
    props.setAlgorithmDisplayCondition({
      sortKey: key,
      sortOrder:
        props.domainData.algorithmDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
    })
  }

  /** アルゴリズムの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(trainingAlgorithmTableRow) &&
      isUndefined(props.domainData.selectedTrainingAlgorithmVersion)
    ) {
      const trainingAlgorithm = trainingAlgorithmTableRow[0]
      props.setSelectedAlgorithm(trainingAlgorithm)
    }
  }, [
    trainingAlgorithmTableRow,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
  ])

  /** アルゴリズムストラクチャーの初期値選択 */
  useEffect(() => {
    if (
      trainingAlgorithmStructureRow.length !== 0 &&
      isUndefined(props.domainData.selectedAlgorithmStructureVersion)
    ) {
      const trainingAlgorithmStructure = trainingAlgorithmStructureRow[0]
      props.setSelectedAlgorithmStructure(trainingAlgorithmStructure)
    }
  }, [
    trainingAlgorithmStructureRow,
    props.domainData.selectedAlgorithmStructureVersion,
  ])

  /** アルゴリズムのラジオボタンクリック */
  const selectAlgorithmTableRadio = (row: number) => {
    if (!isUndefined(trainingAlgorithmTableRow)) {
      const trainingAlgorithm = trainingAlgorithmTableRow[row]
      props.setSelectedAlgorithm(trainingAlgorithm)
    }
  }

  /** アルゴリズムテーブルの選択状態 */
  const selectedAlgorithmIndex = useMemo(() => {
    if (
      isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId) ||
      isUndefined(props.domainData.selectedTrainingAlgorithmVersion) ||
      isUndefined(trainingAlgorithmTableRow)
    )
      return -1
    return trainingAlgorithmTableRow.findIndex(
      (algorithm) =>
        algorithm.trainingAlgorithmVersion ===
        props.domainData.selectedTrainingAlgorithmVersion
          ?.trainingAlgorithmVersion
    )
  }, [
    props.algorithms,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
    trainingAlgorithmTableRow,
    props.domainData.algorithmDisplayCondition.sortOrder,
    props.domainData.algorithmDisplayCondition.sortKey,
  ])

  /** アルゴリズムテーブルの選択状態 */
  const selectedAlgorithmStructureIndex = useMemo(() => {
    if (
      isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId) ||
      isUndefined(props.domainData.selectedTrainingAlgorithmVersion) ||
      isUndefined(props.domainData.selectedAlgorithmStructureVersion) ||
      trainingAlgorithmStructureRow.length === 0
    )
      return -1
    let foundIndex = -1
    foundIndex = trainingAlgorithmStructureRow.findIndex(
      (version) =>
        version.algorithmStructureVersion.displayName ===
          props.domainData.selectedAlgorithmStructureVersion
            ?.algorithmStructureVersion.displayName &&
        version.algorithmStructureId ===
          props.domainData.selectedAlgorithmStructureVersion
            .algorithmStructureId
    )
    return foundIndex
  }, [
    props.algorithms,
    props.domainData.selectedTrainingAlgorithm.algorithmId,
    props.domainData.selectedTrainingAlgorithmVersion,
    props.domainData.selectedAlgorithmStructureVersion,
    trainingAlgorithmStructureRow,
  ])

  /** アルゴリズムストラクチャーのラジオボタンクリック */
  const selectAlgorithmStructureTableRadio = (row: number) => {
    if (trainingAlgorithmStructureRow.length !== 0) {
      props.setSelectedAlgorithmStructure(trainingAlgorithmStructureRow[row])
    }
  }

  /** 表示対象のベースモデルデータ */
  const adjustBaseModelTableRow = useMemo(() => {
    if (
      !isUndefined(props.domainData.baseModelDisplayCondition) &&
      !isUndefined(props.domainData.selectedBaseModelGroup)
    ) {
      let newBaseModelArray =
        props.domainData.selectedBaseModelGroup?.trainedModels ?? []
      /** ソートキー、ソートオーダーによる並び替え */
      newBaseModelArray = newBaseModelArray.sort((item, item2) => {
        return compareVersions(
          item.trainedModelGroupVersion,
          item2.trainedModelGroupVersion,
          props.domainData.baseModelDisplayCondition.sortOrder
        )
      })
      /** 表示するベースモデルの整形 */
      return newBaseModelArray.slice(
        props.domainData.baseModelDisplayCondition.displayNumber *
          props.domainData.baseModelDisplayCondition.pageNumber,
        (props.domainData.baseModelDisplayCondition.pageNumber + 1) *
          props.domainData.baseModelDisplayCondition.displayNumber
      )
    }
    return props.domainData.selectedBaseModelGroup?.trainedModels ?? []
  }, [
    props.domainData.baseModelDisplayCondition,
    props.domainData.selectedBaseModelGroup?.trainedModels,
  ])

  /** 表示対象のデータセットデータ */
  const adjustDatasetTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.datasetDisplayCondition)) {
      let newDatasetArray = props.domainData.datasetList
      /** 検索ワードから検索 */
      if (props.domainData.datasetDisplayCondition.searchValue) {
        newDatasetArray = newDatasetArray.filter(
          (item) =>
            item.name.startsWith(
              props.domainData.datasetDisplayCondition.searchValue
            ) ||
            item.datasetId.startsWith(
              props.domainData.datasetDisplayCondition.searchValue
            ) ||
            item.datasetId ===
              props.domainData.datasetDisplayCondition.searchValue ||
            item.name === props.domainData.datasetDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedDatasetListLength(newDatasetArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedDatasetListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (props.domainData.datasetDisplayCondition.sortKey === 'createdAt') {
        newDatasetArray = newDatasetArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.datasetDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      }
      /** 表示するデータセットの整形 */
      return newDatasetArray.slice(
        props.domainData.datasetDisplayCondition.displayNumber *
          props.domainData.datasetDisplayCondition.pageNumber,
        (props.domainData.datasetDisplayCondition.pageNumber + 1) *
          props.domainData.datasetDisplayCondition.displayNumber
      )
    }
    return props.domainData.datasetList
  }, [props.domainData.datasetDisplayCondition, props.domainData.datasetList])

  /** テーブルに表示ベースモデルのJSXの２次元配列 */
  const baseModelRows = useMemo(() => {
    const rowData = adjustBaseModelTableRow.map((baseModel) => {
      return {
        trainedModelId: baseModel.trainedModelId,
        trainedModelVersion: baseModel.trainedModelGroupVersion.displayName,
        trainedModelName: baseModel.trainedModelName,
        transactionStatus: baseModel.transactionStatus,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'trainedModelId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`model-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return (
            <Typography key={key}>
              {value || TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        }
      })
    )
  }, [adjustBaseModelTableRow])

  /** 表示対象のベースモデルのモデルグループデータ */
  const adjustBaseModelGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.baseModelGroupDisplayCondition)) {
      let newBaseModelGroupArray =
        props.domainData.baseModelGroupDisplayCondition
          .selectedUserGroupKind === 'SharedUserGroup'
          ? props.domainData.baseModelGroups.sharedUserGroup
          : props.domainData.baseModelGroups.userGroup

      /** 検索ワードから検索 */
      if (props.domainData.baseModelGroupDisplayCondition.searchValue) {
        newBaseModelGroupArray = newBaseModelGroupArray.filter(
          (item) =>
            item.trainedModelGroupId.startsWith(
              props.domainData.baseModelGroupDisplayCondition.searchValue
            ) ||
            item.trainedModelGroupId ===
              props.domainData.baseModelGroupDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedBaseModelGroupListLength(newBaseModelGroupArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedBaseModelGroupListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.baseModelGroupDisplayCondition.sortKey === 'createdAt'
      ) {
        newBaseModelGroupArray = newBaseModelGroupArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.baseModelGroupDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      } else if (
        props.domainData.baseModelGroupDisplayCondition.sortKey === 'updatedAt'
      ) {
        newBaseModelGroupArray = newBaseModelGroupArray.sort((item, item2) => {
          return (
            ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.domainData.baseModelGroupDisplayCondition.sortOrder === 'asc'
              ? -1
              : 1)
          )
        })
      } else {
        newBaseModelGroupArray = newBaseModelGroupArray.sort((item, item2) => {
          if (item.trainedModelGroupName < item2.trainedModelGroupName) {
            return props.domainData.baseModelGroupDisplayCondition.sortOrder ===
              'asc'
              ? -1
              : 1
          }
          if (item.trainedModelGroupName > item2.trainedModelGroupName) {
            return props.domainData.baseModelGroupDisplayCondition.sortOrder ===
              'asc'
              ? 1
              : -1
          }
          // names must be equal
          return 0
        })
      }
      /** 表示するベースモデルの整形 */
      return newBaseModelGroupArray.slice(
        props.domainData.baseModelGroupDisplayCondition.displayNumber *
          props.domainData.baseModelGroupDisplayCondition.pageNumber,
        (props.domainData.baseModelGroupDisplayCondition.pageNumber + 1) *
          props.domainData.baseModelGroupDisplayCondition.displayNumber
      )
    }
    return props.domainData.destinationTrainedModelGroups
  }, [
    props.domainData.selectedDestinationTrainedModelGroup,
    props.domainData.baseModelGroupDisplayCondition,
    props.domainData.baseModelGroups,
    props.domainData.destinationTrainedModelGroups,
  ])

  /** 表示対象の登録先モデルグループデータ */
  const adjustDestinationTrainedModelGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.destinationModelGroupDisplayCondition)) {
      let newDestinationTrainedModelGroupArray =
        props.domainData.destinationTrainedModelGroups
      /** 検索ワードから検索 */
      if (props.domainData.destinationModelGroupDisplayCondition.searchValue) {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.filter(
            (item) =>
              item.trainedModelGroupId.startsWith(
                props.domainData.destinationModelGroupDisplayCondition
                  .searchValue
              ) ||
              item.trainedModelGroupId ===
                props.domainData.destinationModelGroupDisplayCondition
                  .searchValue
          )
        /** 検索後の配列の長さをセット */
        setSearchedDestinationTrainedModelGroupListLength(
          newDestinationTrainedModelGroupArray.length
        )
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedDestinationTrainedModelGroupListLength(undefined)
      }
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.destinationModelGroupDisplayCondition.sortKey ===
        'createdAt'
      ) {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.sort((item, item2) => {
            return (
              ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1)
            )
          })
      } else if (
        props.domainData.destinationModelGroupDisplayCondition.sortKey ===
        'updatedAt'
      ) {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.sort((item, item2) => {
            return (
              ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime() -
                (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                  .toDate()
                  .getTime()) *
              (props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1)
            )
          })
      } else {
        newDestinationTrainedModelGroupArray =
          newDestinationTrainedModelGroupArray.sort((item, item2) => {
            if (item.trainedModelGroupName < item2.trainedModelGroupName) {
              return props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1
            }
            if (item.trainedModelGroupName > item2.trainedModelGroupName) {
              return props.domainData.destinationModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? 1
                : -1
            }
            // names must be equal
            return 0
          })
      }
      /** 表示するベースモデルの整形 */
      return newDestinationTrainedModelGroupArray.slice(
        props.domainData.destinationModelGroupDisplayCondition.displayNumber *
          props.domainData.destinationModelGroupDisplayCondition.pageNumber,
        (props.domainData.destinationModelGroupDisplayCondition.pageNumber +
          1) *
          props.domainData.destinationModelGroupDisplayCondition.displayNumber
      )
    }
    return props.domainData.destinationTrainedModelGroups
  }, [
    props.domainData.destinationModelGroupDisplayCondition,
    props.domainData.destinationTrainedModelGroups,
  ])

  /** テーブルに表示ベースモデルのモデルグループのJSXの２次元配列 */
  const baseModelGroupRows = useMemo(() => {
    const rowData = adjustBaseModelGroupTableRow.map((modelGroup) => {
      return {
        trainedModelGroupId: modelGroup.trainedModelGroupId,
        trainedModelGroupName: modelGroup.trainedModelGroupName,
        latestTrainedModelVersion: modelGroup.latestTrainedModelVersion,
        latestTrainedModelName: modelGroup.latestTrainedModelName,
        trainedModelCount: modelGroup.trainedModelCount,
        updatedAt: modelGroup.updatedAt
          ? formatDateTimeSec(modelGroup.updatedAt.toDate())
          : '',
        createdAt: modelGroup.createdAt
          ? formatDateTimeSec(modelGroup.createdAt.toDate())
          : '',
        createdBy: modelGroup.createdBy,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelGroupId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography data-testid={`model-group-${value}`}>
                  {value.substring(0, 8)}
                </Typography>
              </Tooltip>
            )
          } else {
            return (
              <Typography key={key} data-testid={`model-group-${value}`}>
                {value}
              </Typography>
            )
          }
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key !== 'trainedModelGroupId' && value && value !== '') {
          return (
            <Typography key={key} data-testid={`model-group-${value}`}>
              {value}
            </Typography>
          )
        } else if (key === 'trainedModelCount') {
          return <Typography key={key}>{value}</Typography>
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustBaseModelGroupTableRow])

  /** テーブルに表示登録先のモデルグループのJSXの２次元配列 */
  const destinationTrainedModelGroupRows = useMemo(() => {
    const rowData = adjustDestinationTrainedModelGroupTableRow.map(
      (modelGroup) => {
        return {
          trainedModelGroupId: modelGroup.trainedModelGroupId,
          trainedModelGroupName: modelGroup.trainedModelGroupName,
          latestTrainedModelVersion: modelGroup.latestTrainedModelVersion,
          latestTrainedModelName: modelGroup.latestTrainedModelName,
          trainedModelCount: modelGroup.trainedModelCount,
          updatedAt: modelGroup.updatedAt
            ? formatDateTimeSec(modelGroup.updatedAt.toDate())
            : '',
          createdAt: modelGroup.createdAt
            ? formatDateTimeSec(modelGroup.createdAt.toDate())
            : '',
          createdBy: modelGroup.createdBy,
        }
      }
    )

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelGroupId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography data-testid={`model-group-${value}`}>
                  {value.substring(0, 8)}
                </Typography>
              </Tooltip>
            )
          } else {
            return (
              <Typography key={key} data-testid={`model-group-${value}`}>
                {value}
              </Typography>
            )
          }
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key !== 'trainedModelGroupId' && value && value !== '') {
          return (
            <Typography key={key} data-testid={`model-group-${value}`}>
              {value}
            </Typography>
          )
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustDestinationTrainedModelGroupTableRow])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const datasetRows = useMemo(() => {
    const rowData = adjustDatasetTableRow.map((dataset) => {
      return {
        name: dataset.name,
        generatedAt: dataset.createdAt
          ? formatDateTimeSec(dataset.createdAt.toDate())
          : '',
        remarks: dataset.remarks,
        datasetId: dataset.datasetId,
      }
    })
    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'datasetId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          }
        } else {
          return (
            <Typography key={key} data-testid={`datasets-${value}`}>
              {value}
            </Typography>
          )
        }
      })
    )
  }, [adjustDatasetTableRow, props.domainData.datasetList])

  /** 検索処理 */
  const searchTableContent = () => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        props.setClassSetDisplayCondition({
          ...props.domainData.classSetDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      case 'ModelGroup':
        props.setDestinationTrainedModelGroupDisplayCondition({
          ...props.domainData.destinationModelGroupDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      case 'BaseModelState':
        props.setBaseModelGroupDisplayCondition({
          ...props.domainData.baseModelGroupDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      case 'DatasetState':
        props.setDatasetDisplayCondition({
          ...props.domainData.datasetDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      default:
        break
    }
  }
  /** ベースモデルのモデルグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.destinationTrainedModelGroups) &&
      props.domainData.baseModelGroups.userGroup.length !== 0 &&
      isUndefined(props.domainData.selectedBaseModelGroup) &&
      settingBaseModel &&
      props.appState.modelUploadStateKind === 'BaseModelState'
    ) {
      if (
        props.domainData.baseModelGroupDisplayCondition
          .selectedUserGroupKind === 'SharedUserGroup' &&
        props.domainData.selectedDestinationTrainedModelGroup
      ) {
        props.setSelectedBaseModelGroup(
          props.domainData.baseModelGroups.sharedUserGroup[0]
        )
      } else {
        props.setSelectedBaseModelGroup(
          props.domainData.baseModelGroups.userGroup[0]
        )
      }
    }
  }, [
    baseModelGroupRows,
    props.appState.modelUploadStateKind,
    props.domainData.baseModelGroupDisplayCondition,
  ])

  /** ベースモデルの初期値選択 */
  useEffect(() => {
    if (!isUndefined(props.domainData.selectedBaseModelGroup)) {
      props.setSelectedBaseModel(
        props.domainData.selectedBaseModelGroup.trainedModels[0]
      )
    }
  }, [props.domainData.selectedBaseModelGroup])

  /** 登録先モデルグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.destinationTrainedModelGroups) &&
      isUndefined(props.domainData.selectedDestinationTrainedModelGroup)
    ) {
      const modelGroup = adjustDestinationTrainedModelGroupTableRow[0]
      props.setSelectedDestinationTrainedModelGroup(modelGroup)
    }
  }, [
    props.domainData.destinationTrainedModelGroups,
    props.domainData.selectedTrainingAlgorithmVersion,
  ])

  /** データセットの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustDatasetTableRow) &&
      settingDataset &&
      isUndefined(props.domainData.selectedDataset)
    ) {
      const dataset = adjustDatasetTableRow[0]
      props.setSelectedDataset(dataset)
    }
  }, [adjustDatasetTableRow])

  /** ベースモデルのモデルグループテーブルの選択状態 */
  const selectedBaseModelGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.destinationTrainedModelGroups)) return -1
    return adjustBaseModelGroupTableRow.findIndex(
      (modelGroup) =>
        modelGroup.trainedModelGroupId ===
        props.domainData.selectedBaseModelGroup?.trainedModelGroupId
    )
  }, [adjustBaseModelGroupTableRow, props.domainData.selectedBaseModelGroup])

  /** 登録先モデルグループテーブルの選択状態 */
  const selectedDestinationTrainedModelGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.destinationTrainedModelGroups)) return -1
    return adjustDestinationTrainedModelGroupTableRow.findIndex(
      (modelGroup) =>
        modelGroup.trainedModelGroupId ===
        props.domainData.selectedDestinationTrainedModelGroup
          ?.trainedModelGroupId
    )
  }, [
    adjustDestinationTrainedModelGroupTableRow,
    props.domainData.selectedDestinationTrainedModelGroup,
  ])

  /** ベースモデルのテーブルの選択状態 */
  const selectedBaseModelIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedBaseModel)) return -1
    return adjustBaseModelTableRow.findIndex(
      (baseModel) =>
        baseModel.trainedModelId ===
        props.domainData.selectedBaseModel?.trainedModelId
    )
  }, [
    adjustBaseModelTableRow,
    props.domainData.selectedBaseModel,
    props.domainData.selectedBaseModelGroup,
  ])

  /** データセットのテーブルの選択状態 */
  const selectedDatasetIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedDataset)) return -1
    return adjustDatasetTableRow.findIndex(
      (dataset) =>
        dataset.datasetId === props.domainData.selectedDataset?.datasetId
    )
  }, [adjustDatasetTableRow, props.domainData.selectedDataset])

  /** tableDialogの選択したrowのデータを出力用に変換する */
  const convertToDetail = () => {
    if (isUndefined(selectRowIndex)) return

    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        if (
          isUndefined(trainingAlgorithmTableRow) ||
          isUndefined(props.domainData.selectedTrainingAlgorithm.algorithmId)
        ) {
          return undefined
        }
        return trainingAlgorithmForDomainToDetail(
          trainingAlgorithmTableRow[selectRowIndex]
        )
      case 'BaseModelState':
        if (isUndefined(adjustBaseModelTableRow)) {
          return undefined
        }
        return trainedModelToDetail(adjustBaseModelTableRow[selectRowIndex])
      case 'DatasetState':
        if (isUndefined(adjustDatasetTableRow)) {
          return undefined
        }
        return datasetToDetail(adjustDatasetTableRow[selectRowIndex])
      default:
        return undefined
    }
  }

  /** テーブルの列選択時のダイアログ */
  const tableDialog = useMemo(() => {
    if (isUndefined(selectRowIndex)) return
    /**  表示するものをここで受け取る */
    const detailItems = convertToDetail()
    if (isUndefined(detailItems)) {
      setSelectRowIndex(undefined)
      return
    }
    return (
      <TableDialog
        open={!isUndefined(selectRowIndex)}
        handleClose={() => setSelectRowIndex(undefined)}
        items={detailItems}
      />
    )
  }, [selectRowIndex])

  /** ユーザーグループ種別の選択 */
  const selectUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    switch (props.appState.modelUploadStateKind) {
      case 'AlgorithmState':
        props.clearClassSets()
        props.setClassSetDisplayCondition({
          ...props.domainData.classSetDisplayCondition,
          selectedUserGroupKind,
        })
        props.getParams({
          modelUploadStateKind: props.appState.modelUploadStateKind,
          trainingAlgorithmSubStep: true,
        })
        break
      case 'BaseModelState':
        props.setBaseModelGroupDisplayCondition({
          ...props.domainData.baseModelDisplayCondition,
          selectedUserGroupKind,
        })
        props.setSelectedBaseModelGroup(undefined)
        break
      default:
        break
    }
  }

  const [searchedClassSetsLength, setSearchedClassSetsLength] = useState<
    number | undefined
  >(0)

  /** クラスセットの表示対象のセッティングデータ */
  const adjustClassSetTableRow = useMemo(() => {
    let newClassSetArray = props.domainData.classSets
    /** 検索ワードから検索 */
    if (props.domainData.classSetDisplayCondition.searchValue) {
      newClassSetArray = newClassSetArray.filter(
        (item) =>
          item.classSetId ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetName ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetId.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          ) ||
          item.classSetName.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          )
      )
      /** 検索後の配列の長さをセット */
      setSearchedClassSetsLength(newClassSetArray.length)
    } else {
      /** 検索後の配列の長さをセット */
      setSearchedClassSetsLength(undefined)
    }
    /** ソートキー、ソートオーダーによる並び替え */
    if (props.domainData.classSetDisplayCondition.sortKey === 'createdAt') {
      newClassSetArray = newClassSetArray.sort((item, item2) => {
        return (
          ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
            .toDate()
            .getTime() -
            (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime()) *
          (props.domainData.classSetDisplayCondition.sortOrder === 'asc'
            ? -1
            : 1)
        )
      })
    }

    /** 表示するセッティングの整形 */
    return newClassSetArray.slice(
      props.domainData.classSetDisplayCondition.displayNumber *
        props.domainData.classSetDisplayCondition.pageNumber,
      (props.domainData.classSetDisplayCondition.pageNumber + 1) *
        props.domainData.classSetDisplayCondition.displayNumber
    )
  }, [props.domainData.classSetDisplayCondition, props.domainData.classSets])

  /** テーブルに表示するクラスセットのJSXの２次元配列 */
  const classSetRows = useMemo(() => {
    const dataRows = adjustClassSetTableRow.map((classSet) => ({
      classSetId: classSet.classSetId,
      classSetName: classSet.classSetName,
      classList: classSet.classList.join(', '),
      createdAt: classSet.createdAt
        ? formatDateTimeSec(classSet.createdAt.toDate())
        : '',
      classSetRemarks: classSet.classSetRemarks,
    }))

    return dataRows.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '' || isUndefined(value)) {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'classSetId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`class-set-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return <Typography key={key}>{value}</Typography>
        }
      })
    )
  }, [adjustClassSetTableRow])

  useEffect(() => {
    if (isUndefined(props.domainData.selectedClassSet)) {
      props.setSelectedClassSet(adjustClassSetTableRow[0])
    }
  }, [adjustClassSetTableRow])

  const selectedClassSetIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedClassSet)) return -1
    return adjustClassSetTableRow.findIndex(
      (classSet) =>
        classSet.classSetId === props.domainData.selectedClassSet?.classSetId
    )
  }, [adjustClassSetTableRow, props.domainData.selectedClassSet])

  /** クラスセットのラジオボタン押下時処理 */
  const selectClassSetTableRadio = (row: number) => {
    const classSet = adjustClassSetTableRow[row]
    props.setSelectedClassSet(classSet)
  }

  /** クラスセット変換 */
  const classSetToDetail = (classSet?: ClassSet) => {
    if (isUndefined(classSet)) return undefined
    return {
      ['クラスセットID']: classSet.classSetId,
      ['クラスセット名']: classSet.classSetName,
      ['クラス名リスト']: classSet.classList.join(','),
      ['登録日時']: formatDateTimeSec(classSet.createdAt.toDate()),
      ['備考']: classSet.classSetRemarks,
    }
  }

  return {
    selectedAlgorithmIndex,
    selectAlgorithmTableRadio,
    trainingAlgorithmRows,
    trainingAlgorithmToDetail,
    setSelectRowIndex,
    tableDialog,
    selectedAlgorithmTableOrder,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    changeTableSortOrder,
    pageChange,
    selectedDestinationTrainedModelGroupIndex,
    handleChangeDisplayNumber,
    modelGroupToDetail,
    trainingAlgorithmStructureRows,
    selectAlgorithmStructureTableRadio,
    selectedAlgorithmStructureIndex,
    baseModelGroupRows,
    searchedDatasetListLength,
    searchedBaseModelGroupListLength,
    searchedDestinationTrainedModelGroupListLength,
    selectedBaseModelTableRadio,
    selectedDestinationTrainedModelTableRadio,
    selectBaseModelTableRadio,
    selectDatasetTableRadio,
    selectedBaseModelIndex,
    selectedBaseModelGroupIndex,
    selectedDatasetIndex,
    datasetRows,
    destinationTrainedModelGroupRows,
    baseModelRows,
    trainedModelToDetail,
    datasetToDetail,
    selectUserGroupKind,
    classSetRows,
    searchedClassSetsLength,
    selectedClassSetIndex,
    selectClassSetTableRadio,
    classSetToDetail,
  }
}
