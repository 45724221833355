import {
  AlgorithmStructure,
  AlgorithmStructureVersion,
} from 'state/app/domainData'
import { Timestamp } from 'state/firebase'
import { DocumentTrainedModel, DocumentVersion } from 'utils/fireStore/common'

// ActionType
export const ModelUploadActionType = {
  SET_ALGORITHM_DISPLAY_CONDITION: 'SET_ALGORITHM_DISPLAY_CONDITION',
  SET_BASE_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD:
    'SET_BASE_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD',
  SET_SELECTED_ALGORITHM_ID_FOR_MODEL_UPLOAD:
    'SET_SELECTED_ALGORITHM_ID_FOR_MODEL_UPLOAD',
  SET_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD:
    'SET_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD',
  SET_DATASET_LIST_FOR_MODEL_UPLOAD: 'SET_DATASET_LIST_FOR_MODEL_UPLOAD',
  CLEAR_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'CLEAR_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_MODEL_GROUP_SUB_STATE: 'SET_MODEL_GROUP_SUB_STATE',
  SET_SELECTED_MODEL_VERSION: 'SET_SELECTED_MODEL_VERSION',
  SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_MODEL_UPLOAD:
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_MODEL_UPLOAD',
  SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_MODEL_UPLOAD:
    'SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_MODEL_UPLOAD',
  SET_MODEL_META_DATA: 'SET_MODEL_META_DATA',
  SET_MODEL_KIND: 'SET_MODEL_KIND',
  SET_ALGORITHM_SUB_STATE: 'SET_ALGORITHM_SUB_STATE',
  SET_MODEL_UPLOAD_SUB_STATE: 'SET_MODEL_UPLOAD_SUB_STATE',
  SET_MODEL_META_DATA_SUB_STATE: 'SET_MODEL_META_DATA_SUB_STATE',
  SET_SEND_MODEL_STATE: 'SET_SEND_MODEL_STATE',
  CLEAR_MODEL_UPLOAD_STATE: 'CLEAR_MODEL_UPLOAD_STATE',
  EXEC_MODEL_SEND_SUCCESS: 'EXEC_MODEL_SEND_SUCCESS',
  EXEC_MODEL_SEND_FAILURE: 'EXEC_MODEL_SEND_FAILURE',
  SET_EXECUTED_MODEL_ID: 'SET_EXECUTED_MODEL_ID',
  ADD_IMAGE_SET_FILE_FOR_MODEL_UPLOAD: 'ADD_IMAGE_SET_FILE_FOR_MODEL_UPLOAD',
  SET_IN_PROGRESS_FOR_MODEL_UPLOAD: 'SET_IN_PROGRESS_FOR_MODEL_UPLOAD',
  SET_FILE_UPLOAD_RESULT: 'SET_FILE_UPLOAD_RESULT',
  SET_TOAST_INFO_FOR_MODEL_UPLOAD: 'SET_TOAST_INFO_FOR_MODEL_UPLOAD',
  SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_MODEL_UPLOAD:
    'SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_MODEL_UPLOAD',
  SET_SELECTED_DATASET_FOR_MODEL_UPLOAD:
    'SET_SELECTED_DATASET_FOR_MODEL_UPLOAD',
  SET_SELECTED_BASE_MODEL_FOR_MODEL_UPLOAD:
    'SET_SELECTED_BASE_MODEL_FOR_MODEL_UPLOAD',
  SET_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'SET_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  CLEAR_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'CLEAR_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  CLEAR_BASE_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'CLEAR_BASE_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'SET_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_SELECTED_BASE_MODEL_GROUP_FOR_MODEL_UPLOAD:
    'SET_SELECTED_BASE_MODEL_GROUP_FOR_MODEL_UPLOAD',
  SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP_FOR_MODEL_UPLOAD:
    'SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP_FOR_MODEL_UPLOAD',
  CLEAR_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'CLEAR_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_BASE_MODEL_SUB_STATE_FOR_MODEL_UPLOAD:
    'SET_BASE_MODEL_SUB_STATE_FOR_MODEL_UPLOAD',
  SET_DATASET_SUB_STATE_FOR_MODEL_UPLOAD:
    'SET_DATASET_SUB_STATE_FOR_MODEL_UPLOAD',
  SET_BASE_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'SET_BASE_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_SELECTED_CLASS_SET_FOR_MODEL_UPLOAD:
    'SET_SELECTED_CLASS_SET_FOR_MODEL_UPLOAD',
  CLEAR_CLASS_SET_LIST_FOR_MODEL_UPLOAD:
    'CLEAR_CLASS_SET_LIST_FOR_MODEL_UPLOAD',
  SET_CLASS_SET_LIST_FOR_MODEL_UPLOAD: 'SET_CLASS_SET_LIST_FOR_MODEL_UPLOAD',
  CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD:
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
  SET_CLASS_SET_SUB_STATE_FOR_MODEL_UPLOAD:
    'SET_CLASS_SET_SUB_STATE_FOR_MODEL_UPLOAD',
} as const

export interface ModelGroupQueryDocument {
  id: string
  'algorithm-id': string
  'icon-filetype': string
  'icon-name': string
  overview: string
  remarks: string
  'trained-model-count': number
  'trained-model-group-id': string
  'trained-model-group-name': string
  'trained-model-group-version-latest': DocumentVersion
  'trained-model-list': DocumentTrainedModel[]
  'user-group-id': string
  'created-at': Timestamp
  'created-by': string
  'updated-at': Timestamp
  'updated-by': string
}

export interface MetaData {
  name?: string
  remarks?: string
}

export interface ModelUploadParamsType {
  modelKind: 'Generic' | 'Custom' | 'Specified' | undefined
  trainingAlgorithmId: string
  trainingAlgorithmVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
    preRelease: number
  }
  modelGroupId: string
  modelVersion: string
  metadata: MetaData
  algorithmStructureId: string
  algorithmStructureVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  baseModel?: {
    trainedModelId: string
    userGroupId: string
  }
  datasetId?: string
  extended?: {
    objectClassification?: {
      classSet: {
        classSetId: string
        userGroupId: string
      }
    }
  }
  isSharedUserGroup: boolean
}

export interface AlgorithmDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface ModelUploadSubStateKind {
  algorithmSubState: 'Unselected' | 'Selected'
  modelGroupSubState: 'Unselected' | 'Selected'
  baseModelSubState: 'Unselected' | 'Selected' | 'NotFound'
  datasetSubState: 'Unselected' | 'Selected'
  modelUploadSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError'
  classSetSubState: 'Unselected' | 'Selected'
}

export interface TrainedModelDataItem {
  'trained-model-id': string
  'trained-model-name': string
  'trained-model-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
}

export interface TrainedModelList {
  'trained-model-id': string
  'trained-model-name': string
  'trained-model-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
}

export interface DisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export const ModelUploadStateKindArray = [
  'AlgorithmState',
  'ModelGroup',
  'BaseModelState',
  'DatasetState',
  'ModelUpload',
  'MetaDataState',
  'ExecuteState',
]

export interface FileAndProgress {
  progress: number
  file: File
  uploadStatus: 'beforeUpload' | 'completed' | 'uploadError'
}

export interface AlgorithmStructureTableData {
  config: string
  createdAt: string
  version: string
  remarks: string
}

export interface TrainedModel {
  trainedModelId: string
  trainedModelGroupVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  trainedModelName: string
  transactionStatus: string
}

export interface Dataset {
  datasetId: string
  name: string
  createdAt?: Timestamp
  remarks: string
}

export interface TrainedModelGroup {
  trainedModelGroupId: string // モデルグループID
  trainedModelGroupName: string // モデルグループ名
  trainedModelCount: number // 登録モデル数
  latestTrainedModelVersion?: string // 最新モデルバージョン
  latestTrainedModelName: string // 最新モデル名
  updatedAt?: Timestamp // 更新日
  createdAt?: Timestamp // 作成日
  createdBy: string // 作成者
  trainedModels: TrainedModel[] // モデルの配列
  userGroupId: string // ユーザグループID
  isSharedUserGroup?: boolean // 共有データかどうか
}

export interface TrainedModelGroupList {
  sharedUserGroup: TrainedModelGroup[]
  userGroup: TrainedModelGroup[]
}

export type ModelUploadStateKind = (typeof ModelUploadStateKindArray)[number]

export interface TrainingAlgorithmVersion {
  trainingAlgorithmVersion: string // 学習アルゴリズムバージョン
  algorithmVersion: {
    // 学習アルゴリズムバージョン
    displayName: string // 表示名
    major: number // メジャーバージョン
    minor: number // マイナーバージョン
    patch: number // パッチバージョン
    preRelease: number // プレリリースバージョン
  }
  // メタデータ
  metadata: {
    remarks: string // アルゴリズムの備考
  }
  releasedAt: Timestamp // リリース日時
  algorithmStructures: AlgorithmStructure[]
}

/**
 * クラスセット選択の表示条件
 */
export interface ClassSetDisplayCondition {
  /** 検索文字列 */
  searchValue: string
  /** ソートする項目のキー名 */
  sortKey: string
  /** ソート順 */
  sortOrder: 'asc' | 'desc'
  /** 表示データ数 */
  displayNumber: number
  /** 表示ページ番号 */
  pageNumber: number
  /** データ種別 */
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

/** クラスセット */
export interface ClassSet {
  /** クラスセットID */
  classSetId: string
  /** クラスセット名 */
  classSetName: string
  /** クラスセット備考 */
  classSetRemarks: string
  /** クラス名リスト */
  classList: string[]
  /** 登録日時 */
  createdAt: Timestamp
  /** ユーザグループ ID */
  userGroupId: string
}

export interface ModelUploadDomainData {
  algorithmDisplayCondition: AlgorithmDisplayCondition // アルゴリズムの表示条件
  // selectedAlgorithmKind?: string // 選択中のアルゴリズム種別
  selectedTrainingAlgorithm: {
    // 選択中のアルゴリズム
    algorithmId: string
  }
  selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion // 選択中のアルゴリズムの情報
  selectedAlgorithmStructureVersion?: AlgorithmStructureVersion // 選択中のアルゴリズムストラクチャーバージョン
  destinationTrainedModelGroups: TrainedModelGroup[] // モデルグループ一覧
  baseModelGroups: TrainedModelGroupList // ベースモデルのモデルグループ
  selectedBaseModelGroup?: TrainedModelGroup //  選択されたモデルグループ
  selectedBaseModel?: TrainedModel // 選択中のモデル
  datasetList: Dataset[] // データセット一覧
  destinationModelGroupDisplayCondition: DisplayCondition // 登録先モデルグループの検索条件
  baseModelGroupDisplayCondition: DisplayCondition & {
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  } // ベースモデルのモデルグループの検索条件
  baseModelDisplayCondition: DisplayCondition // ベースモデルのモデルの検索条件
  datasetDisplayCondition: DisplayCondition // データセットの検索条件
  selectedDataset?: Dataset // 選択中のデータセット
  selectedDestinationTrainedModelGroup?: TrainedModelGroup // 選択されたモデルグループ
  modelMetaData?: MetaData // 入力されたモデルのメタデータ
  selectedModelKind?: 'Generic' | 'Custom' | 'Specified' | undefined // 選択されたモデル種別
  executedModelId?: string // モデルのアップロードを実行時に生成された モデルのID
  uploadTargetTrainedModel: FileAndProgress[] //アップロードされたファイル
  fileUploadResult: 'beforeUpload' | 'failed' | 'success' // ファイルアップロードステータス
  selectedVersion?: string // 選択されたモデルバージョン
  currentDatasetListSnapshot?: () => void
  /** クラスセット一覧 */
  classSets: ClassSet[]
  /** 選択中のクラスセット */
  selectedClassSet?: ClassSet
  /** クラスセット選択の表示条件 */
  classSetDisplayCondition: ClassSetDisplayCondition
}

export interface ModelUploadAppState {
  inProgress: boolean // データ取得中、実行中フラグ
  toastInfo?: ToastInfo // トーストの表示する情報
  modelUploadStateKind: ModelUploadStateKind // モデルアップロード画面のSTEP
  modelUploadSubStateKind: ModelUploadSubStateKind // モデルアップロード画面の各種STEPの状態
}

export interface ModelUploadState {
  domainData: ModelUploadDomainData
  appState: ModelUploadAppState
}
