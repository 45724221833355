import { CustomTrainingAction } from './actions'
import { CustomTrainingActionType, CurrentCustomTrainingState } from './types'

const initialState: CurrentCustomTrainingState = {
  domainData: {
    selectedTrainingAlgorithm: undefined,
    selectedTrainingAlgorithmVersion: undefined,
    selectedClassSet: undefined,
    extendedSetting: {},
    destinationTrainedModelGroups: [],
    sourceTrainedModelGroups: {
      userGroup: [],
      sharedUserGroup: [],
    },
    selectedSourceTrainedModelGroup: undefined,
    selectedSourceTrainedModel: undefined,
    classSets: [],
    datasetList: [],
    settingList: [],
    selectedDataset: undefined,
    selectedSetting: undefined,
    selectedSettingFormat: undefined,
    selectedDatasetTemplateId: '',
    preProcessKinds: undefined,
    selectedDestinationTrainedModelGroup: undefined,
    selectedCustomTrainingGenerateKind: 'CustomModel',
    inferenceAlgorithmVersions: [],
    selectedInferenceAlgorithmVersion: undefined,
    selectedBaseInferenceContainerImageIds: [],
    isTransfer: true,
    isSystemEvaluation: true,
    mlPipelinesMetaData: {
      name: '',
      remarks: '',
    },
    customModelMetaData: {
      name: '',
      remarks: '',
    },
    selectedCustomModelVersion: undefined,
    executionInfo: undefined,
    trainingAlgorithmVersionDisplayCondition: {
      sortKey: 'version',
      sortOrder: 'desc',
    },
    classSetDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
    },
    sourceModelGroupDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
    },
    sourceModelDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    destinationModelDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    datasetDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    settingDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    destinationModelGroupDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    inheritDatasetDisplayCondition: {
      sortKey: 'generation',
      sortOrder: 'desc',
    },
    currentDatasetListSnapshot: undefined,
  },
  appState: {
    customTrainingState: 'TrainingAlgorithmState',
    customTrainingSubState: {
      trainingAlgorithmSubState: 'Unselected',
      classSetSubState: 'Unselected',
      modelGroupSubState: 'Unselected',
      baseModelSubState: 'Unselected',
      datasetSubState: 'Unselected',
      settingSubState: 'Unselected',
      outputFormatSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
  },
}

export const CustomTrainingReducer = (
  state: CurrentCustomTrainingState = initialState,
  action: CustomTrainingAction
): CurrentCustomTrainingState => {
  switch (action.type) {
    case CustomTrainingActionType.SET_TRAINED_MODEL_GROUP_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          destinationTrainedModelGroups: action.payload.trainedModelGroups,
        },
      }
    case CustomTrainingActionType.SET_SOURCE_TRAINED_MODEL_GROUP_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sourceTrainedModelGroups: action.payload.trainedModelGroups,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_SOURCE_TRAINED_MODEL_GROUP:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedSourceTrainedModelGroup:
            action.payload.selectedTrainedModelGroup,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDestinationTrainedModelGroup:
            action.payload.selectedDestinationTrainedModelGroup,
        },
      }
    case CustomTrainingActionType.SET_DATASET_LIST_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetList: action.payload.datasetList,
        },
      }
    case CustomTrainingActionType.SET_SETTING_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingList: action.payload.settingList,
        },
      }
    case CustomTrainingActionType.SET_INFERENCE_ALGORITHM_VERSIONS_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceAlgorithmVersions: action.payload.inferenceAlgorithmVersions,
        },
      }
    case CustomTrainingActionType.SET_INFERENCE_ALGORITHM_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceAlgorithmVersions: action.payload.inferenceAlgorithmList,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_INFERENCE_ALGORITHM:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedInferenceAlgorithmVersion:
            action.payload.selectedInferenceAlgorithm,
        },
      }
    case CustomTrainingActionType.SET_SOURCE_MODEL_DISPLAY_CONDITION:
      if (action.payload.trainedModelDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            sourceModelDisplayCondition:
              action.payload.trainedModelDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingActionType.SET_DESTINATION_MODEL_DISPLAY_CONDITION:
      if (action.payload.trainedModelDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            destinationModelDisplayCondition:
              action.payload.trainedModelDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingActionType.CLEAR_BASE_MODEL_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sourceModelDisplayCondition: {
            ...initialState.domainData.sourceModelDisplayCondition,
          },
        },
      }
    case CustomTrainingActionType.SET_TRAINING_ALGORITHM_DISPLAY_CONDITION:
      if (action.payload.trainingAlgorithmDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            trainingAlgorithmVersionDisplayCondition: {
              ...action.payload.trainingAlgorithmDisplayCondition,
            },
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingActionType.CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainingAlgorithmVersionDisplayCondition: {
            ...initialState.domainData.trainingAlgorithmVersionDisplayCondition,
          },
        },
      }
    case CustomTrainingActionType.SET_INHERIT_DATASET_DISPLAY_CONDITION:
      if (action.payload.inheritDatasetDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            inheritDatasetDisplayCondition: {
              ...action.payload.inheritDatasetDisplayCondition,
            },
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingActionType.SET_DATASET_DISPLAY_CONDITION:
      if (action.payload.datasetDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            datasetDisplayCondition: action.payload.datasetDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingActionType.CLEAR_DATASET_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          datasetDisplayCondition: {
            ...initialState.domainData.datasetDisplayCondition,
          },
        },
      }
    case CustomTrainingActionType.SET_SETTING_DISPLAY_CONDITION:
      if (action.payload.settingDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            settingDisplayCondition: action.payload.settingDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case CustomTrainingActionType.CLEAR_SETTING_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingDisplayCondition: {
            ...initialState.domainData.settingDisplayCondition,
          },
        },
      }
    case CustomTrainingActionType.SET_SELECTED_TRAINING_ALGORITHM_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithm: {
            algorithmId: action.payload.selectedTrainingAlgorithmId,
          },
        },
      }
    case CustomTrainingActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainingAlgorithmVersion:
            action.payload.selectedTrainingAlgorithmVersion,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_BASE_MODEL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedSourceTrainedModel: action.payload.selectedBaseModel,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_INHERIT_MODEL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedInheritTrainedModel: action.payload.selectedInheritModel,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_DATASET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDataset: action.payload.selectedDataset,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_SETTING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedSetting: action.payload.selectedSetting,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_SETTING_FORMAT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedSettingFormat: action.payload.selectedSettingFormat,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_DATASET_TEMPLATE_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedDatasetTemplateId: action.payload.datasetTemplateId,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_CUSTOM_TRAINING_GENERATE_KIND: {
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedCustomTrainingGenerateKind:
            action.payload.selectedCustomTrainingGenerateKind,
        },
      }
    }
    case CustomTrainingActionType.SET_ML_PIPELINE_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          mlPipelinesMetaData: action.payload.mlPipelinesMetaData,
        },
      }
    case CustomTrainingActionType.SET_CUSTOM_MODEL_META_DATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          customModelMetaData: action.payload.customModelMetaData,
        },
      }
    case CustomTrainingActionType.SET_TRAINING_ALGORITHM_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            trainingAlgorithmSubState: action.payload.trainingAlgorithmSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_CLASS_SET_SUB_STATE_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            classSetSubState: action.payload.classSetSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_BASE_MODEL_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            baseModelSubState: action.payload.baseModelSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_MODEL_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            modelGroupSubState: action.payload.modelGroupSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_DATASET_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            datasetSubState: action.payload.datasetSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_SETTING_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            settingSubState: action.payload.settingSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_OUTPUT_FORMAT_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            outputFormatSubState: action.payload.outputFormatSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_META_DATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            metaDataSubState: action.payload.metaDataSubState,
          },
        },
      }
    case CustomTrainingActionType.SET_CUSTOM_TRAINING_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingState: action.payload.customTrainingStateKind,
        },
      }
    case CustomTrainingActionType.CLEAR_CURRENT_CUSTOM_TRAINING_STATE:
      return {
        ...initialState,
      }
    case CustomTrainingActionType.EXEC_CUSTOM_TRAINING_SUCCESS:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            executeSubState: 'Executed',
          },
        },
      }
    case CustomTrainingActionType.SET_IN_PROGRESS:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case CustomTrainingActionType.EXEC_CUSTOM_TRAINING_FAILURE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customTrainingSubState: {
            ...state.appState.customTrainingSubState,
            executeSubState: 'ExecuteError',
          },
        },
      }
    case CustomTrainingActionType.SET_EXECUTED_INFO:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executionInfo: action.payload.executedInfo,
        },
      }
    case CustomTrainingActionType.SET_SOURCE_MODEL_GROUP_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sourceModelGroupDisplayCondition: action.payload.condition,
        },
      }
    case CustomTrainingActionType.SET_IS_TRANSFER_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          isTransfer: action.payload.isTransfer,
        },
      }
    case CustomTrainingActionType.SET_IS_SYSTEM_EVALUATION_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          isSystemEvaluation: action.payload.isSystemEvaluation,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedBaseInferenceContainerImageIds:
            action.payload.selectedBaseInferenceContainerImageId,
        },
      }
    case CustomTrainingActionType.SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          destinationModelGroupDisplayCondition: action.payload.condition,
        },
      }
    case CustomTrainingActionType.CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          destinationModelGroupDisplayCondition: {
            ...initialState.domainData.destinationModelGroupDisplayCondition,
          },
        },
      }
    case CustomTrainingActionType.CLEAR_SOURCE_MODEL_GROUP_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sourceModelGroupDisplayCondition: {
            ...initialState.domainData.sourceModelGroupDisplayCondition,
          },
        },
      }
    case CustomTrainingActionType.SET_SELECTED_TRAINED_MODEL_VERSION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedCustomModelVersion: action.payload.selectedVersion,
        },
      }
    case CustomTrainingActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetListSnapshot: action.payload.currentDatasetListSnapshot,
        },
      }
    case CustomTrainingActionType.SET_CLASS_SET_LIST_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: action.payload.classSets,
        },
      }
    case CustomTrainingActionType.SET_SELECTED_CLASS_SET_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedClassSet: action.payload.selectedClassSet,
        },
      }
    case CustomTrainingActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: action.payload.classSetDisplayCondition,
        },
      }
    case CustomTrainingActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: {
            ...initialState.domainData.classSetDisplayCondition,
          },
        },
      }
    case CustomTrainingActionType.CLEAR_CLASS_SET_LIST_FOR_CUSTOM_TRAINING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: [...initialState.domainData.classSets],
        },
      }
    case CustomTrainingActionType.SET_OBJECT_CLASSIFICATION_EXTENDED_SETTING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          extendedSetting: {
            ...state.domainData.extendedSetting,
            objectClassification:
              action.payload.objectClassificationExtendedSetting,
          },
        },
      }
    case CustomTrainingActionType.CLEAR_OBJECT_CLASSIFICATION_EXTENDED_SETTING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          extendedSetting: {
            ...state.domainData.extendedSetting,
            objectClassification:
              initialState.domainData.extendedSetting.objectClassification,
          },
        },
      }
    case CustomTrainingActionType.SET_PRE_PROCESS_KIND:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          preProcessKinds: action.payload.preProcessKinds,
        },
      }
    case CustomTrainingActionType.SET_OBJECT_RECOGNITION_EXTENDED_SETTING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          extendedSetting: {
            ...state.domainData.extendedSetting,
            objectRecognition: action.payload.objectRecognition,
          },
        },
      }
    default:
      return state
  }
}
