import { Dispatch } from 'redux'
import { modelGroupListActions } from '.'
import { ModelGroupInfo } from './types'
import { State } from 'state/store'
import { getTrainedModelGroupQueriesCollection } from 'state/firebase'

import { Algorithm } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { domainDataOperations } from 'state/app/domainData/operations'

const createModelGroupList = async (
  algorithmList: Algorithm[],
  trainedModelGroupQuery: Query<DocumentData>
): Promise<(ModelGroupInfo | undefined)[]> =>
  await Promise.all(
    // モデルグループリストの生成
    (
      await getDocs(trainedModelGroupQuery)
    ).docs.map(async (doc: DocumentData) => {
      const trainedModelGroupQueryDocData = doc.data()
      if (
        !fireStoreTypeGuardForModelGroupQueryDocument(
          trainedModelGroupQueryDocData
        )
      ) {
        return undefined
      }
      let algorithm = undefined
      algorithmList.map((info) => {
        if (
          info.algorithmId === trainedModelGroupQueryDocData['algorithm-id']
        ) {
          algorithm = info
        }
      })

      // モデルグループ一覧を返す
      return {
        modelGroupId: trainedModelGroupQueryDocData['trained-model-group-id'],
        modelGroupName:
          trainedModelGroupQueryDocData['trained-model-group-name'],
        algorithmName: algorithm ? algorithm['metadata']['name']['ja'] : '',
        modelCount: trainedModelGroupQueryDocData['trained-model-count'],
        updatedAt: trainedModelGroupQueryDocData['updated-at'],
        createAt: trainedModelGroupQueryDocData['created-at'],
        createdUserId: trainedModelGroupQueryDocData['created-by'],
      } as ModelGroupInfo
    })
  )

export const modelGroupListOperations = {
  /** リストを取得する */
  getModelGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(modelGroupListActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            modelGroupListActions.setListDisplayCondition({
              ...getState().pages.modelGroupListState.domainData
                .modelGroupListDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.modelGroupListState.domainData
            .modelGroupListDisplayCondition.selectedUserGroupKind ===
          'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]
        // 表示条件取得
        const condition =
          getState().pages.modelGroupListState.domainData
            .modelGroupListDisplayCondition
        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentModelGroupList =
          getState().pages.modelGroupListState.domainData.currentModelGroupList
        const qLimit =
          condition.displayNumber * (condition.pageNumber + 1) -
          currentModelGroupList.length

        let totalCountQuery =
          getState().pages.modelGroupListState.domainData
            .modelGroupListDisplayCondition.selectedUserGroupKind ===
          'UserGroup'
            ? query(getTrainedModelGroupQueriesCollection(userGroupId))
            : query(
                getTrainedModelGroupQueriesCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        if (condition.algorithmId) {
          totalCountQuery = query(
            totalCountQuery,
            where('algorithm-id', '==', condition.algorithmId)
          )
        }

        // model-groupsを表示件数分取得
        let trainedModelGroupQuery = query(totalCountQuery, limit(qLimit))

        // 文字列検索が存在する場合は、trained-model-group-idの前方一致条件をQueryに設定
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          trainedModelGroupQuery = query(
            trainedModelGroupQuery,
            orderBy('trained-model-group-id', 'asc'),
            where('trained-model-group-id', '>=', startCode),
            where('trained-model-group-id', '<=', endCode)
          )
        } else {
          const sortKey =
            condition.sortKey === 'generated-at'
              ? 'created-at'
              : condition.sortKey
          trainedModelGroupQuery = query(
            trainedModelGroupQuery,
            orderBy(sortKey, condition.sortOrder)
          )
        }
        // 既に取得していれば最後の要素から取得
        let lastItem: DocumentData | undefined = undefined
        const algorithms = getState().app.domainData.algorithms
        if (currentModelGroupList.length) {
          lastItem =
            getState().pages.modelGroupListState.domainData
              .modelGroupListDisplayCondition.selectedUserGroupKind ===
            'UserGroup'
              ? await getDoc(
                  doc(
                    getTrainedModelGroupQueriesCollection(userGroupId),
                    currentModelGroupList[currentModelGroupList.length - 1]
                      .modelGroupId
                  )
                )
              : (
                  await getDocs(
                    query(
                      getTrainedModelGroupQueriesCollection(userGroupId),
                      where(
                        'trained-model-group-id',
                        '==',
                        currentModelGroupList[currentModelGroupList.length - 1]
                          .modelGroupId
                      ),
                      where('access-control.is-shared', '==', true),
                      where(
                        'access-control.share-permissions.webapp',
                        '==',
                        'list'
                      )
                    )
                  )
                ).docs.map((modelGroup) => modelGroup.data())[0]
          trainedModelGroupQuery = query(
            trainedModelGroupQuery,
            startAfter(lastItem)
          )
        }

        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )
          totalCountQuery = query(
            totalCountQuery,
            where('trained-model-group-id', '>=', startCode),
            where('trained-model-group-id', '<=', endCode)
          )
        }
        const totalCount = await getCountFromServer(totalCountQuery)

        dispatch(
          modelGroupListActions.setListDisplayCondition({
            ...condition,
            totalCount: totalCount.data().count,
          })
        )

        // モデルグループ一覧を取得
        const newModelGroupList: (ModelGroupInfo | undefined)[] =
          await createModelGroupList(algorithms, trainedModelGroupQuery)

        dispatch(
          modelGroupListActions.setModelGroupList([
            ...currentModelGroupList,
            ...(newModelGroupList.filter(
              (item) => item !== undefined
            ) as ModelGroupInfo[]),
          ])
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(modelGroupListActions.setInProgress(false))
      }
    },
}
