import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import { BreadcrumbsProps } from './types'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Typography from '@mui/material/Typography'
import { generateQueryParameters } from 'views/containers/utils/queryParams'

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '250px',
  },
}))

export const BreadcrumbsComponent: React.FC<BreadcrumbsProps> = (
  props: BreadcrumbsProps
) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />}>
        {props.breadcrumbsPath.map((breadcrumb, index) => {
          const path =
            breadcrumb.path.charAt(0) === '/'
              ? breadcrumb.path.slice(1)
              : props.breadcrumbsPath
                  .slice(0, index + 1)
                  .map((breadcrumb) => breadcrumb.path)
                  .join('/')
          return (
            <div key={breadcrumb.name}>
              {props.breadcrumbsPath.length === index + 1 ? (
                <Typography
                  key={index}
                  variant='body1'
                  className={classes.text}
                >
                  {breadcrumb.name}
                </Typography>
              ) : (
                <Link
                  key={index}
                  to={`/${path}${generateQueryParameters(breadcrumb.query)}`}
                >
                  <Typography variant='body1' className={classes.text}>
                    {breadcrumb.name}
                  </Typography>
                </Link>
              )}
            </div>
          )
        })}
      </Breadcrumbs>
    </Box>
  )
}
