import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { GlobalLoadingProps } from './types'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: theme.zIndex.snackbar,
  },
}))

export const GlobalLoading: React.FC<GlobalLoadingProps> = (
  props: GlobalLoadingProps
) => {
  const { classes } = useStyles()

  return (
    <Backdrop className={classes.root} open={props.open}>
      <CircularProgress size={64} />
    </Backdrop>
  )
}
