import { authInstance, getAccountsCollection } from 'state/firebase'
import { Dispatch } from 'redux'
import { Customer } from '.'
import {
  getAccountCustomerRelationsCollection,
  getCustomerMetadataCollection,
  getCustomerCollection,
} from 'state/firebase'
import { AuthedUser, domainDataActions } from 'state/app/domainData'
import { State } from 'state/store'
import { CustomerChangeApi } from './apis'
import { isUndefined } from 'utils/typeguard'
import { doc, getDoc } from 'firebase/firestore'
import { User } from 'firebase/auth'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountsDocument } from 'utils/fireStore/account'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomerMetadataDocument } from 'utils/fireStore/customersMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomerDocument } from 'utils/fireStore/customer'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountCustomerRelationDocument } from 'utils/fireStore/accountCustomerRelation'

export const CustomerChangeOperations = {
  /** カスタマーを変更する */
  changeCustomer:
    (customerId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(domainDataActions.setInProgress(true))
        const customerMetadataDoc = (
          await getDoc(doc(getCustomerMetadataCollection(), customerId))
        ).data()

        const customerDoc = (
          await getDoc(doc(getCustomerCollection(), customerId))
        ).data()

        if (
          !fireStoreTypeGuardForCustomerMetadataDocument(customerMetadataDoc) ||
          !fireStoreTypeGuardForCustomerDocument(customerDoc)
        ) {
          return
        }

        const firebaseCurrentUser: User | null = authInstance.currentUser
        if (firebaseCurrentUser) {
          const email = firebaseCurrentUser.email
          const uid = firebaseCurrentUser.uid
          if (email && uid) {
            // カスタマーIDが含まれているかチェック
            const currentAccountId =
              getState().app.domainData.authedUser.auth.customClaims.accountId
            const currentAccountGroupId =
              getState().app.domainData.authedUser.auth.customClaims
                .accountGroupId

            const accountsDoc = (
              await getDoc(
                doc(
                  getAccountsCollection(currentAccountGroupId),
                  currentAccountId
                )
              )
            ).data()
            if (!fireStoreTypeGuardForAccountsDocument(accountsDoc)) {
              return
            }

            const relationDoc = (
              await getDoc(
                doc(
                  getAccountCustomerRelationsCollection(currentAccountGroupId),
                  currentAccountId
                )
              )
            ).data()

            if (
              !fireStoreTypeGuardForAccountCustomerRelationDocument(relationDoc)
            ) {
              return
            }

            const currentCustomerList = relationDoc
              ? relationDoc['customer-list']
              : []
            // カスタマーIDが含まれているかチェック
            const customerIndex = currentCustomerList.findIndex(
              (item: Customer) => item['customer-id'] === customerId
            )
            if (customerIndex < 0) return

            const updateParam = {
              ['user-group-id']: customerDoc
                ? customerDoc['user-group-id']
                : '',
              ['shared-list']:
                customerDoc && customerDoc['shared-user-group-list']
                  ? customerDoc['shared-user-group-list']
                  : [],
            }
            // カスタムクレームを更新(firestore)
            await CustomerChangeApi.updateCustomClaim(uid, updateParam)
            const result = await firebaseCurrentUser.getIdTokenResult(true)
            const authedUser: AuthedUser = {
              mailAddress: email,
              userId: uid,
              customers: getState().app.domainData.authedUser.customers,
              auth: {
                token: result.token,
                customClaims: {
                  accountId: result.claims['account-id'] as string,
                  accountGroupId: result.claims['account-group-id'] as string,
                  role: result.claims['role'] as string,
                  sharedList:
                    customerDoc && customerDoc['shared-user-group-list']
                      ? customerDoc['shared-user-group-list']
                      : [],
                  superUser: result.claims['super-user'] as boolean,
                  userGroupId: customerDoc ? customerDoc['user-group-id'] : '',
                },
              },
            }
            // カスタムクレームを変更する(state)
            dispatch(domainDataActions.setAuthedUser(authedUser))
            const accountId =
              getState().app.domainData.authedUser.auth.customClaims.accountId
            const accountGroupId =
              getState().app.domainData.authedUser.auth.customClaims
                .accountGroupId

            const customerRelationDoc = (
              await getDoc(
                doc(
                  getAccountCustomerRelationsCollection(accountGroupId),
                  accountId
                )
              )
            ).data()

            if (
              isUndefined(customerRelationDoc) ||
              !fireStoreTypeGuardForAccountCustomerRelationDocument(
                customerRelationDoc
              )
            ) {
              return
            }

            const customerList = customerRelationDoc
              ? customerRelationDoc['customer-list']
              : []

            // 一致したIDでオブジェクトをマージ
            const customer = customerList.find(
              (data: Customer) => data['customer-id'] === customerId
            )

            CustomerChangeApi.updateLastAccessed(customerId)

            const userProfile = getState().app.domainData.userProfile

            dispatch(
              domainDataActions.setUserProfile({
                firstName: userProfile
                  ? userProfile.firstName
                    ? userProfile.firstName
                    : ''
                  : '',
                familyName: userProfile
                  ? userProfile.familyName
                    ? userProfile.familyName
                    : ''
                  : '',
                // TODO: 将来的にdocから取得する
                accountGroupName: userProfile
                  ? userProfile.accountGroupName
                    ? userProfile.accountGroupName
                    : ''
                  : '',
                language: userProfile ? userProfile.language : '',
                locate: userProfile
                  ? userProfile.locate
                    ? userProfile.locate
                    : ''
                  : '',
                role: result.claims['super-user']
                  ? 'admin'
                  : customer?.role ?? 'user',
                customerId: customerMetadataDoc
                  ? customerMetadataDoc['customer-id']
                  : '',
                customerName: customerMetadataDoc
                  ? customerMetadataDoc['name']
                  : '',
                accountGroupRole: accountsDoc
                  ? accountsDoc['account-group-role']
                  : '',
              })
            )
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(domainDataActions.setInProgress(false))
      }
    },
}
