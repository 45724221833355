import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import { ListStateHandlerProps } from './types'

const useStyles = makeStyles()((theme) => ({
  breadcrumbs: {
    display: 'inline-block',
  },
  linkItem: {
    fontSize: '1rem',
    color: theme.palette.common.black,
  },
}))

export const ListStateHandler: React.FC<ListStateHandlerProps> = (
  props: ListStateHandlerProps
) => {
  const { classes } = useStyles()
  return (
    <span>
      <Breadcrumbs className={classes.breadcrumbs}>
        <Link
          className={classes.linkItem}
          component='button'
          underline='always'
          onClick={props.onClickOpen}
          sx={{ textDecorationColor: 'inherit' }}
        >
          全て開く
        </Link>
        <Link
          className={classes.linkItem}
          component='button'
          underline='always'
          onClick={props.onClickClose}
          sx={{ textDecorationColor: 'inherit' }}
        >
          全て閉じる
        </Link>
      </Breadcrumbs>
    </span>
  )
}
