import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  CurrentSettingDetail,
  SettingDetailState,
  SettingDetailActionType,
  ToastInfo,
} from './types'

export type SettingDetailAction = ActionsUnion<typeof settingDetailActions>

export const settingDetailActions = {
  setCurrentSettingDetail: (
    currentSettingDetail: CurrentSettingDetail
  ): ActionWithPayload<
    'SET_CURRENT_SETTING_DETAIL',
    { currentSettingDetail: CurrentSettingDetail }
  > =>
    CreateAction(SettingDetailActionType.SET_CURRENT_SETTING_DETAIL, {
      currentSettingDetail,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_SETTING_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(SettingDetailActionType.SET_IN_PROGRESS_FOR_SETTING_DETAIL, {
      inProgress,
    }),
  clearSettingDetailState: (): Action<'CLEAR_SETTING_DETAIL_STATE'> =>
    CreateAction(SettingDetailActionType.CLEAR_SETTING_DETAIL_STATE),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_SETTING_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(SettingDetailActionType.SET_TOAST_INFO_FOR_SETTING_DETAIL, {
      toastInfo,
    }),
  setSettingDetailState: (
    settingDetailState: SettingDetailState
  ): ActionWithPayload<
    'SET_MEDIA_DETAIL_STATE',
    { settingDetailState: SettingDetailState }
  > =>
    CreateAction(SettingDetailActionType.SET_MEDIA_DETAIL_STATE, {
      settingDetailState,
    }),
}
