import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { getAxiosInstance } from 'state/utils'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'

import { FeatureDataUploadParamsType } from '.'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'

const timeoutLimitSeconds = 15

export const FeatureDataUploadApi = {
  uploadFeatureDataFile: (
    signed_url: string,
    file: File,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<void>> =>
    getAxiosInstance().upload(signed_url, file, {
      ...config,
      headers: { 'Content-Type': file.type },
    }),
  getSignedUrl: (
    featureDataId: string,
    featureDataFiles: {
      featureDataFormat: string
      fileType: string
      fileName: string
    }[]
  ): Promise<HttpsCallableResult<{ [x: string]: { url: string } }>> =>
    httpsCallable<
      {
        featureDataId: string
        featureDataFiles: {
          featureDataFormat: string
          fileType: string
          fileName: string
        }[]
      },
      { [x: string]: { url: string } }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredata-get-signed-url'
    )({
      featureDataId,
      featureDataFiles,
    }),
  executeFeatureDataUpload: (
    featureData: FeatureDataUploadParamsType
  ): Promise<HttpsCallableResult<{ featureDataId: string }>> =>
    httpsCallable<
      { featureData: FeatureDataUploadParamsType },
      { featureDataId: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-featuredata-firestore',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      featureData,
    }),
}
