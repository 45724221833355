import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  BuildDetailActionType,
  CurrentBuildDetail,
  CurrentBuildDetailState,
  ToastInfo,
  SystemEvaluationFile,
} from './types'

export type BuildDetailAction = ActionsUnion<typeof buildDetailActions>

export const buildDetailActions = {
  setCurrentBuildDetail: (
    currentBuildDetail: CurrentBuildDetail
  ): ActionWithPayload<
    'SET_CURRENT_BUILD_DETAIL',
    { currentBuildDetail: CurrentBuildDetail }
  > =>
    CreateAction(BuildDetailActionType.SET_CURRENT_BUILD_DETAIL, {
      currentBuildDetail,
    }),
  setBuildDetailState: (
    buildDetailState: CurrentBuildDetailState
  ): ActionWithPayload<
    'SET_BUILD_DETAIL_STATE',
    { buildDetailState: CurrentBuildDetailState }
  > =>
    CreateAction(BuildDetailActionType.SET_BUILD_DETAIL_STATE, {
      buildDetailState: buildDetailState,
    }),
  setInProgressForDownloading: (
    isInProgressForDownloading: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_DOWNLOADING',
    { isInProgressForDownloading: boolean }
  > =>
    CreateAction(BuildDetailActionType.SET_IN_PROGRESS_FOR_DOWNLOADING, {
      isInProgressForDownloading,
    }),
  setInProgressForGettingBuildDetail: (
    isInProgressForGettingBuildDetail: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_BUILD_DETAIL',
    { isInProgressForGettingBuildDetail: boolean }
  > =>
    CreateAction(
      BuildDetailActionType.SET_IN_PROGRESS_FOR_GETTING_BUILD_DETAIL,
      {
        isInProgressForGettingBuildDetail,
      }
    ),
  setInProgressForGettingSystemEvaluation: (
    isInProgressForGettingSystemEvaluation: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_GETTING_SYSTEM_EVALUATION',
    { isInProgressForGettingSystemEvaluation: boolean }
  > =>
    CreateAction(
      BuildDetailActionType.SET_IN_PROGRESS_FOR_GETTING_SYSTEM_EVALUATION,
      {
        isInProgressForGettingSystemEvaluation,
      }
    ),
  clearCurrentBuildDetail: (): Action<'CLEAR_CURRENT_BUILD_DETAIL'> =>
    CreateAction(BuildDetailActionType.CLEAR_CURRENT_BUILD_DETAIL),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_BUILD_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(BuildDetailActionType.SET_TOAST_INFO_FOR_BUILD_DETAIL, {
      toastInfo,
    }),
  setSystemEvaluationFiles: (
    systemEvaluationFiles: SystemEvaluationFile[]
  ): ActionWithPayload<
    'SET_SYSTEM_EVALUATION_FILES',
    { systemEvaluationFiles: SystemEvaluationFile[] }
  > =>
    CreateAction(BuildDetailActionType.SET_SYSTEM_EVALUATION_FILES, {
      systemEvaluationFiles: systemEvaluationFiles,
    }),
}
