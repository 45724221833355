import React from 'react'
import Button from '@mui/material/Button'
import NavigateBefore from '@mui/icons-material/NavigateBefore'

import { BackButtonProps } from './types'

export const BackButton: React.FC<BackButtonProps> = (
  props: BackButtonProps
) => {
  return (
    <Button
      variant='contained'
      data-testid={props['data-testid']}
      disabled={props.disabled}
      onClick={props.onClick}
      color={
        props.color && props.color === 'blue'
          ? 'primary'
          : props.color && props.color === 'red'
          ? 'secondary'
          : 'inherit'
      }
    >
      <NavigateBefore fontSize='large' />
    </Button>
  )
}
