import { CreateImageSetAction } from './actions'
import { CurrentCreateImageSetState, CreateImageSetActionType } from './types'

const initialState: CurrentCreateImageSetState = {
  domainData: {
    imageSetFiles: [],
    imageSetMetaData: {
      name: undefined,
      remarks: undefined,
    },
    imageSet: {
      files: [],
      imageSetMetaData: {
        name: undefined,
        remarks: undefined,
      },
    },
    annotationSet: {
      annotationSetId: '',
      groupedData: {
        trainingDataList: [],
      },
    },
    groupedDataId: undefined,
    selectedAlgorithm: undefined,
  },
  appState: {
    inProgress: false,
    imageSetState: 'AlgorithmState',
    imageSetSubState: {
      algorithmSubState: 'Unselected',
      imageSetFileState: 'Uploading',
      metadataSubState: 'EmptyRequired',
      uploadSubState: 'BeforeUpload',
    },
  },
}

export const CreateImageSetReducer = (
  state: CurrentCreateImageSetState = initialState,
  action: CreateImageSetAction
): CurrentCreateImageSetState => {
  switch (action.type) {
    case CreateImageSetActionType.ADD_ANNOTATION_SET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          annotationSet: action.payload.annotation,
        },
      }
    case CreateImageSetActionType.ADD_IMAGE_SET_FILE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          imageSetFiles: action.payload.files,
        },
        appState: {
          ...state.appState,
          imageSetSubState: {
            ...state.appState.imageSetSubState,
            imageSetFileState: 'Completed',
          },
        },
      }
    case CreateImageSetActionType.SET_IMAGE_SET_METADATA_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          imageSetMetaData: {
            ...state.domainData.imageSetMetaData,
            name: action.payload.name,
          },
        },
        appState: {
          ...state.appState,
          imageSetSubState: {
            ...state.appState.imageSetSubState,
            metadataSubState: action.payload.name
              ? 'InputRequired'
              : 'EmptyRequired',
          },
        },
      }
    case CreateImageSetActionType.SET_IMAGE_SET_METADATA_REMARKS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          imageSetMetaData: {
            ...state.domainData.imageSetMetaData,
            remarks: action.payload.remarks,
          },
        },
      }
    case CreateImageSetActionType.SET_IMAGE_SET_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          imageSetState: action.payload.imageSetState,
        },
      }
    case CreateImageSetActionType.CREATE_IMAGE_SET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          imageSet: action.payload.imageSet,
        },
      }
    case CreateImageSetActionType.SET_GROUPED_DATA_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          groupedDataId: action.payload.groupedDataId,
        },
      }
    case CreateImageSetActionType.SET_IN_PROGRESS_FOR_CREATE_IMAGE_SET:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case CreateImageSetActionType.CLEAR_IMAGE_SET_STATE:
      return {
        ...initialState,
      }
    case CreateImageSetActionType.SET_ALGORITHM_SUB_STATE_FOR_CREATE_IMAGE_SET:
      return {
        ...state,
        appState: {
          ...state.appState,
          imageSetSubState: {
            ...state.appState.imageSetSubState,
            algorithmSubState: action.payload.algorithmSubState,
          },
        },
      }
    case CreateImageSetActionType.SET_SELECTED_ALGORITHM_ID_FOR_CREATE_IMAGE_SET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithm: action.payload.algorithmId,
        },
      }
    default:
      return state
  }
}
