import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
const timeoutLimitSeconds = 15

export const SettingDetailApi = {
  getSettingFiles: (settingId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'setting-files',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      settingId,
    }),
}
