import React from 'react'

export interface SelectableTableHeader {
  id: string
  title: string
  width: number
  sortable: boolean
  position: 'left' | 'right' | 'inherit' | 'center' | 'justify'
}

export interface SelectableTableSortOrder {
  key: string
  order: 'asc' | 'desc'
}

export interface SelectableTableProps {
  headers: SelectableTableHeader[]
  rows: React.ReactNode[][]
  totalCount?: number
  tableHeight?: number
  selectedRowNumber?: number
  fixedColumnNumber: number
  sortOrder?: SelectableTableSortOrder
  page: number
  displayNumber?: number
  displayNoneRadio?: boolean
  displayNoneEmptyCells?: boolean
  includesStatusProgressBar?: boolean
  isPointer?: boolean
  loading?: boolean
  disableRowNumbers?: number[]
  onClickRadio?: (index: number) => void
  onClickRow?: (index: number) => void
  onClickPageChange?: (page: number) => void
  onClickOrderChange?: (key: string) => void
  onChangeDisplayNumber?: (displayNumber: number) => void
}

/** ヘッダーの高さ */
export const TABLE_HEADER_HEIGHT = 48

// rowの高さ
/** StatusProgressBarありのrowの高さ */
export const STATUS_PROGRESS_BAR_ROW_HEIGHT = 45 /** ラジオあり(StatusProgressBarなし)のrowの高さ */
export const RADIO_ROW_HEIGHT = 43
/** StatusProgressBarなし(ラジオもなし)のrowの高さ */
export const DISPLAY_NONE_RADIO_ROW_HEIGHT = 37
