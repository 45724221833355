import { Dispatch } from 'redux'
import { State } from 'state/store'
import { featureDataGeneratingActions } from '.'
import {
  getDatasetQueryCollection,
  getSettingsCollection,
  getSettingsMetaDataCollection,
  getFeatureDataGroupQueriesCollection,
} from 'state/firebase'

import { FeatureDataGeneratingApi } from './apis'

import {
  FeatureDataGroup,
  Dataset,
  Setting,
  FeatureDataGeneratingStateKindArray,
  FeatureDataGeneratingParamsType,
  MetaData,
  FeatureDataList,
  FeatureDataGeneratingStateKind,
} from './types'

import {
  AlgorithmStructureVersion,
  TrainingAlgorithmVersion,
} from 'state/app/domainData'

import { formatDateTimeSec } from 'views/components/utils/date'
import { isUndefined } from 'utils/typeguard'
import {
  DocumentData,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  Timestamp,
  where,
} from 'firebase/firestore'
import { HttpsCallableResult } from 'firebase/functions'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'

const createDatasetData = async (
  snapShot: QuerySnapshot<DocumentData>
): Promise<(Dataset | undefined)[]> =>
  // データセットのテーブルデータを生成するための処理
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const datasetQueryDocData = document.data()
      if (!fireStoreTypeGuardForDatasetQueryDocument(datasetQueryDocData)) {
        return undefined
      }
      return {
        datasetId: datasetQueryDocData['dataset-id'],
        name: datasetQueryDocData
          ? datasetQueryDocData['dataset-name']
            ? datasetQueryDocData['dataset-name']
            : ''
          : '',
        createdAt: datasetQueryDocData['created-at'] ?? undefined,
        remarks: datasetQueryDocData
          ? datasetQueryDocData['dataset-remarks']
            ? datasetQueryDocData['dataset-remarks']
            : ''
          : '',
      } as Dataset
    })
  )

// データセット一覧の配列をセット
const getDatasetDocs = async (dispatch: Dispatch, getState: () => State) => {
  // データセット一覧取得
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId
  const structureVersion =
    getState().pages.featureDataGeneratingState.domainData
      .selectedAlgorithmStructureVersion

  const datasetTemplateId = structureVersion?.datasetTemplateId

  if (!datasetTemplateId) {
    console.error('Failed to find the dataset template id')
  }
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.featureDataGeneratingState.domainData
      .currentDatasetListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      featureDataGeneratingActions.setCurrentDatasetListSnapshot(undefined)
    )
  }

  // ベースのQuery（表示件数分指定）
  const commonQuery = query(
    getDatasetQueryCollection(userGroupId),
    where(
      'algorithm-id',
      '==',
      getState().pages.featureDataGeneratingState.domainData
        .selectedTrainingAlgorithm?.algorithmId
    ),
    where('generated-for', '==', 'Training'),
    where('dataset-template-id', '==', datasetTemplateId)
  )

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      const datasetData = await createDatasetData(snapshot)

      dispatch(
        featureDataGeneratingActions.setDatasetList(
          datasetData.filter((item) => item !== undefined) as Dataset[]
        )
      )
    }
  )

  // 現在、表示中のデータセット（Dataset Queries Collectionの Snapshot を保持）
  dispatch(featureDataGeneratingActions.setCurrentDatasetListSnapshot(snapshot))
}

export const featureDataGeneratingOperations = {
  getFeatureDataGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // ベースモデルの一覧とベースモデルのメタデータの一覧を取得して整形
      try {
        dispatch(featureDataGeneratingActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const featureDataGroup = await getDocs(
          query(
            getFeatureDataGroupQueriesCollection(userGroupId),
            where(
              'algorithm-id',
              '==',
              getState().pages.featureDataGeneratingState.domainData
                .selectedTrainingAlgorithm?.algorithmId
            )
          )
        )

        if (!featureDataGroup) return

        const featureDataGroups: (FeatureDataGroup | undefined)[] =
          featureDataGroup.docs.map((item) => {
            const featureDataGroupData = item.data()
            const featureData = featureDataGroupData['feature-data-list'].find(
              (data: FeatureDataList) =>
                data['feature-data-group-version']['display-name'] ===
                `${featureDataGroupData['feature-data-group-version-latest']['major']}.${featureDataGroupData['feature-data-group-version-latest']['minor']}.${featureDataGroupData['feature-data-group-version-latest']['patch']}`
            )
            const featureDataCount = featureDataGroupData['feature-data-count']
            return {
              featureDataGroupId: featureDataGroupData['feature-data-group-id'],
              featureDataGroupName:
                featureDataGroupData['feature-data-group-name'],
              featureDataCount: featureDataCount,
              latestFeatureDataVersion:
                featureDataCount > 0
                  ? `${featureDataGroupData['feature-data-group-version-latest']['major']}.${featureDataGroupData['feature-data-group-version-latest']['minor']}.${featureDataGroupData['feature-data-group-version-latest']['patch']}`
                  : '',
              latestFeatureDataName: featureData
                ? featureData['feature-data-name']
                : '',
              updatedAt: featureDataGroupData['updated-at'],
              createdAt: featureDataGroupData['created-at'],
              createdBy: featureDataGroupData['created-by'],
            } as FeatureDataGroup
          })

        dispatch(
          featureDataGeneratingActions.setFeatureDataGroupList(
            featureDataGroups.filter(
              (featureDataGroup) => featureDataGroup !== undefined
            ) as FeatureDataGroup[]
          )
        )
        dispatch(
          featureDataGeneratingActions.clearFeatureDataGroupDisplayCondition()
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGeneratingActions.setInProgress(false))
      }
    },

  getDatasetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // データセットの一覧とデータセットメタデータの一覧を取得して整形
      try {
        dispatch(featureDataGeneratingActions.setInProgress(true))

        // データセット一覧に必要な関連Docsを取得
        await getDatasetDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGeneratingActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribeDatasetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.featureDataGeneratingState.domainData
            .currentDatasetListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },

  getSettingList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // セッティングの一覧とセッティングメタデータの一覧を取得して整形
      try {
        dispatch(featureDataGeneratingActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // セッティング一覧取得
        const allSettingsList = await getDocs(
          getSettingsCollection(userGroupId)
        )
        const allSettingsListConvert = allSettingsList.docs.map((item) => {
          return {
            id: item.id,
            ...item.data(),
          } as { id: string; ['created-at']: Timestamp }
        })

        // メタデータ一覧を取得
        const allSettingsMetadataList = await getDocs(
          query(getSettingsMetaDataCollection(userGroupId))
        )
        const allSettingsMetadataListConvert = allSettingsMetadataList.docs.map(
          (item) => {
            return {
              id: item.id,
              ...item.data(),
            } as { id: string; ['name']: string; ['remarks']: string }
          }
        )

        // 作成するセッティング配列
        const settings: Setting[] = []
        allSettingsListConvert.forEach((item) => {
          // 一致したIDでオブジェクトをマージ
          const metaData = allSettingsMetadataListConvert.find(
            (data) => data.id === item.id
          )
          // Settingのオブジェクトを作成
          const setting: Setting = {
            settingId: item.id,
            name: metaData ? (metaData['name'] ? metaData['name'] : '') : '',
            createdAt: item['created-at'] ? item['created-at'] : undefined,
            remarks: metaData
              ? metaData['remarks']
                ? metaData['remarks']
                : ''
              : '',
          }
          settings.push(setting)
        })

        dispatch(featureDataGeneratingActions.setSettingList(settings))
        dispatch(featureDataGeneratingActions.clearSettingDisplayCondition())
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGeneratingActions.setInProgress(false))
      }
    },
  nextStep:
    (currentStep: FeatureDataGeneratingStateKind) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataGeneratingActions.setFeatureDataGeneratingState(
          FeatureDataGeneratingStateKindArray[
            FeatureDataGeneratingStateKindArray.indexOf(currentStep) + 1
          ]
        )
      )
      switch (currentStep) {
        case 'DatasetState':
          featureDataGeneratingOperations.unsubscribeDatasetList()
          break
        case 'SettingState':
          dispatch(
            featureDataGeneratingActions.setOutputFormatSubState('Selected')
          )
          break
        case 'OutputFormatState':
          // メタデータの入力ステップ時にカスタム学習、カスタムモデルの表示名のデフォルト値を設定
          // eslint-disable-next-line no-case-declarations
          const featureDataName =
            getState().pages.featureDataGeneratingState.domainData
              .selectedFeatureDataGroup?.latestFeatureDataName
          // eslint-disable-next-line no-case-declarations
          const regex = /\/|\s+|:/g
          dispatch(
            featureDataGeneratingActions.setMlPipelinesMetaData({
              name: `${featureDataName}_${formatDateTimeSec(new Date()).replace(
                regex,
                ''
              )}`,
              remarks:
                getState().pages.featureDataGeneratingState.domainData
                  .mlPipelinesMetaData?.remarks,
            })
          )
          dispatch(
            featureDataGeneratingActions.setFeatureDataMetaData({
              name: featureDataName,
              remarks:
                getState().pages.featureDataGeneratingState.domainData
                  .featureDataMetaData?.remarks,
            })
          )

          if (featureDataName) {
            dispatch(
              featureDataGeneratingActions.setMetaDataSubState('InputRequired')
            )
          }
          break
        default:
          break
      }
    },
  prevStep:
    (currentStep: FeatureDataGeneratingStateKind) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 'FeatureDataGroupState':
          dispatch(
            featureDataGeneratingActions.setSelectedFeatureDataGroup(undefined)
          )
          dispatch(
            featureDataGeneratingActions.setFeatureDataGroupSubState(
              'Unselected'
            )
          )
          break
        case 'DatasetState':
          featureDataGeneratingOperations.unsubscribeDatasetList()
          dispatch(featureDataGeneratingActions.setSelectedDataset(undefined))
          dispatch(
            featureDataGeneratingActions.setDatasetSubState('Unselected')
          )
          break
        case 'SettingState':
          dispatch(featureDataGeneratingActions.setSelectedSetting(undefined))
          dispatch(
            featureDataGeneratingActions.setSettingSubState('Unselected')
          )
          break
        case 'OutputFormatState':
          dispatch(
            featureDataGeneratingActions.setSelectedFeatureDataGenerateKind(
              'FeatureDataOnly'
            )
          )
          dispatch(
            featureDataGeneratingActions.setOutputFormatSubState('Unselected')
          )
          break
        case 'MetaDataState':
          dispatch(
            featureDataGeneratingActions.setMlPipelinesMetaData(undefined)
          )
          dispatch(
            featureDataGeneratingActions.setFeatureDataMetaData(undefined)
          )
          dispatch(
            featureDataGeneratingActions.setMetaDataSubState('EmptyRequired')
          )
          break
        case 'ExecuteState':
          break
        default:
          break
      }

      dispatch(
        featureDataGeneratingActions.setFeatureDataGeneratingState(
          FeatureDataGeneratingStateKindArray[
            FeatureDataGeneratingStateKindArray.indexOf(currentStep) - 1
          ]
        )
      )
    },
  setSelectedTrainingAlgorithmVersion:
    (trainingAlgorithmVersion?: TrainingAlgorithmVersion) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataGeneratingActions.setSelectedTrainingAlgorithmVersion(
          trainingAlgorithmVersion
        )
      )

      if (
        trainingAlgorithmVersion &&
        getState().pages.featureDataGeneratingState.domainData
          .selectedTrainingAlgorithm?.algorithmId &&
        getState().pages.featureDataGeneratingState.domainData
          .selectedAlgorithmStructureVersion
      ) {
        dispatch(
          featureDataGeneratingActions.setTrainingAlgorithmSubState('Selected')
        )
      } else {
        dispatch(
          featureDataGeneratingActions.setTrainingAlgorithmSubState(
            'Unselected'
          )
        )
      }
    },
  setSelectedAlgorithmStructureVersion:
    (algorithmStructureVersion?: AlgorithmStructureVersion) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      if (
        algorithmStructureVersion &&
        getState().pages.featureDataGeneratingState.domainData
          .selectedTrainingAlgorithm?.algorithmId
      ) {
        dispatch(
          featureDataGeneratingActions.setSelectedAlgorithmStructureVersion(
            algorithmStructureVersion
          )
        )
        dispatch(
          featureDataGeneratingActions.setTrainingAlgorithmSubState('Selected')
        )
      } else {
        dispatch(
          featureDataGeneratingActions.setTrainingAlgorithmSubState(
            'Unselected'
          )
        )
      }
    },
  setSelectedFeatureDataGroup:
    (featureDataGroup?: FeatureDataGroup) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        featureDataGeneratingActions.setSelectedFeatureDataGroup(
          featureDataGroup
        )
      )

      if (featureDataGroup) {
        dispatch(
          featureDataGeneratingActions.setFeatureDataGroupSubState('Selected')
        )
      } else {
        dispatch(
          featureDataGeneratingActions.setFeatureDataGroupSubState('Unselected')
        )
      }
    },
  setSelectedDataset:
    (dataset?: Dataset) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(featureDataGeneratingActions.setSelectedDataset(dataset))

      if (dataset) {
        dispatch(featureDataGeneratingActions.setDatasetSubState('Selected'))
      } else {
        dispatch(featureDataGeneratingActions.setDatasetSubState('Unselected'))
      }
    },
  setSelectedSetting:
    (setting: Setting | undefined, assignmentSetting: boolean) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(featureDataGeneratingActions.setSelectedSetting(setting))

      if (setting) {
        dispatch(featureDataGeneratingActions.setSettingSubState('Selected'))
      } else {
        dispatch(
          featureDataGeneratingActions.setSettingSubState(
            assignmentSetting ? 'Unselected' : 'Selected'
          )
        )
      }
    },
  setMlPipelinesMetaData:
    (mlPipelinesMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataGeneratingActions.setMlPipelinesMetaData(mlPipelinesMetaData)
      )

      if (
        mlPipelinesMetaData?.name &&
        getState().pages.featureDataGeneratingState.domainData
          .featureDataMetaData?.name
      ) {
        dispatch(
          featureDataGeneratingActions.setMetaDataSubState('InputRequired')
        )
      } else {
        dispatch(
          featureDataGeneratingActions.setMetaDataSubState('EmptyRequired')
        )
      }
    },
  setFeatureDataMetaData:
    (featureDataMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        featureDataGeneratingActions.setFeatureDataMetaData(featureDataMetaData)
      )

      if (
        featureDataMetaData?.name &&
        getState().pages.featureDataGeneratingState.domainData
          .mlPipelinesMetaData?.name
      ) {
        dispatch(
          featureDataGeneratingActions.setMetaDataSubState('InputRequired')
        )
      } else {
        dispatch(
          featureDataGeneratingActions.setMetaDataSubState('EmptyRequired')
        )
      }
    },
  executeFeatureDataGenerating:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGeneratingActions.setInProgress(true))
        const {
          selectedFeatureDataGroup,
          selectedTrainingAlgorithm,
          selectedTrainingAlgorithmVersion,
          selectedDataset,
          selectedSetting,
          mlPipelinesMetaData,
          featureDataMetaData,
          selectedAlgorithmStructureVersion,
          // selectedFeatureDataGenerateKind,
          selectedFeatureDataGroupVersion,
        } = getState().pages.featureDataGeneratingState.domainData

        if (
          !(
            selectedTrainingAlgorithmVersion &&
            selectedTrainingAlgorithm?.algorithmId &&
            selectedDataset?.datasetId &&
            mlPipelinesMetaData?.name &&
            featureDataMetaData?.name &&
            selectedFeatureDataGroup &&
            selectedAlgorithmStructureVersion &&
            selectedFeatureDataGroupVersion
          )
        ) {
          console.error('Error invalid request')
          dispatch(
            featureDataGeneratingActions.executeFeatureDataGeneratingFailure()
          )
          return
        }

        const featureDataParams: FeatureDataGeneratingParamsType = {
          trainingAlgorithmId: selectedTrainingAlgorithm?.algorithmId,
          algorithmVersion: selectedTrainingAlgorithmVersion.algorithmVersion,
          datasetId: selectedDataset?.datasetId,
          settingId: selectedSetting?.settingId ?? '',
          featureDataGroupId: selectedFeatureDataGroup?.featureDataGroupId,
          featureDataGroupVersion: selectedFeatureDataGroupVersion,
          mlPipelineMetadata: mlPipelinesMetaData,
          featureDataMetadata: featureDataMetaData,
          algorithmStructureId:
            selectedAlgorithmStructureVersion.algorithmStructureId,
          algorithmStructureVersion:
            selectedAlgorithmStructureVersion.algorithmStructureVersion,
        }

        const result =
          (await FeatureDataGeneratingApi.executeFeatureDataGenerating(
            featureDataParams
          )) as HttpsCallableResult<{
            result: boolean
            mlPipelineId: string
            stepId: string
          }>

        dispatch(
          featureDataGeneratingActions.executeFeatureDataGenerating(
            result.data.result
          )
        )
        dispatch(
          featureDataGeneratingActions.setExecutedInfo({
            mlPipelineId: result.data.mlPipelineId,
            trainingStepId: result.data.stepId,
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(
          featureDataGeneratingActions.executeFeatureDataGeneratingFailure()
        )
      } finally {
        dispatch(featureDataGeneratingActions.setInProgress(false))
      }
    },
}
