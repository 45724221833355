import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DomainData } from 'state/app/domainData'
import { domainDataOperations } from 'state/app/domainData/operations'
import { isUndefined } from 'utils/typeguard'

type Prop = {
  children: JSX.Element
  domainData: Omit<DomainData, 'glossaries' | 'authedUser' | 'isLoading'>
}

export const DomainDataProvider: React.FC<Prop> = (props: Prop) => {
  const dispatch = useDispatch()
  useEffect(() => {
    // アルゴリズムを取得
    dispatch(domainDataOperations.getDomainAlgorithmList())
    // データセットテンプレートを取得
    dispatch(domainDataOperations.getDomainDatasetTemplateList())
    // 特徴量データフォーマットを取得
    dispatch(domainDataOperations.getDomainFeatureDataFormatList())
    // ユーザーのプロフィールを取得する
    dispatch(domainDataOperations.getUserInfo())
  }, [])

  if (
    props.domainData.algorithms.length === 0 ||
    props.domainData.datasetTemplates.length === 0 ||
    props.domainData.annotationFormats.length === 0 ||
    props.domainData.featureDataFormats.length === 0 ||
    isUndefined(props.domainData.userProfile)
  ) {
    return <></>
  } else {
    return props.children
  }
}
