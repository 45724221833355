import React from 'react'
import { ColorBoxProps } from './types'
import { Box } from '@mui/material'

export const ColorBox: React.FC<ColorBoxProps> = (props: ColorBoxProps) => {
  return (
    <Box
      sx={{
        width: props.width ?? '10px',
        height: props.height ?? '10px',
        background: `${props.color}66`,
        border: `solid 2px ${props.color}`,
        ...props.sx,
      }}
    />
  )
}
