import { AppStateActions } from './actions'
import { AppStateActionType, Router } from './types'

export interface AppState {
  authed: boolean
  router: Router
}

const initialState: AppState = {
  authed: false,
  router: {
    location: '',
  },
}

export const AppStateReducer = (
  state: AppState = initialState,
  action: AppStateActions
): AppState => {
  switch (action.type) {
    case AppStateActionType.SET_AUTHED:
      return {
        ...state,
        authed: action.payload.data,
      }
    case AppStateActionType.SET_ROUTER:
      return {
        ...state,
        router: action.payload.data,
      }
    case AppStateActionType.CLEAR_APP_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
