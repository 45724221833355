import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Tooltip from '@mui/material/Tooltip'
import CloudUpload from '@mui/icons-material/CloudUpload'
import { blue, green, red } from '@mui/material/colors'

import { CloudUploadTooltipIconProps } from './types'

const useStyles = makeStyles()(() => ({
  blueIcon: {
    color: blue[500],
  },
  greenIcon: {
    color: green[500],
  },
  redIcon: {
    color: red[500],
  },
}))

export const CloudUploadTooltipIcon: React.FC<CloudUploadTooltipIconProps> = (
  props: CloudUploadTooltipIconProps
) => {
  const { classes } = useStyles()

  const getClassName = (color?: string) => {
    switch (color) {
      case 'blue':
        return classes.blueIcon
      case 'green':
        return classes.greenIcon
      case 'red':
        return classes.redIcon
      default:
        return undefined
    }
  }

  return (
    <Tooltip title={props.title}>
      <CloudUpload className={getClassName(props.color)} />
    </Tooltip>
  )
}
