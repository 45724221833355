import {
  ActionWithPayload,
  ActionsUnion,
  CreateAction,
  ResourceLoadingState,
} from 'state/utils'
import {
  DisplayCondition,
  ExecutionInfo,
  FeatureDataGroup,
  FeatureDataTransferringActionType,
  FeatureDataTransferringStateKind,
  MetaData,
  FeatureData,
} from './types'
import { Action } from 'redux'

export type FeatureDataTransferringActions = ActionsUnion<
  typeof featureDataTransferringActions
>

export const featureDataTransferringActions = {
  /** 特徴量データグループ一覧を設定する */
  setFeatureDataGroupList: (
    featureDataGroups: FeatureDataGroup[]
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_TRANSFERRING',
    { featureDataGroups: FeatureDataGroup[] }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_TRANSFERRING,
      {
        featureDataGroups,
      }
    ),

  /** 特徴量データグループを設定する */
  setSelectedFeatureDataGroup: (
    selectedFeatureDataGroup?: FeatureDataGroup
  ): ActionWithPayload<
    'SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_TRANSFERRING',
    { selectedFeatureDataGroup?: FeatureDataGroup }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_TRANSFERRING,
      {
        selectedFeatureDataGroup,
      }
    ),

  /** 特徴量データを設定する */
  setSelectedFeatureData: (
    selectedFeatureData?: FeatureData
  ): ActionWithPayload<
    'SET_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_TRANSFERRING',
    { selectedFeatureData?: FeatureData }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_TRANSFERRING,
      {
        selectedFeatureData,
      }
    ),

  /** 特徴量データグループの表示条件を設定する */
  setFeatureDataGroupDisplayCondition: (
    featureDataGroupDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING',
    { featureDataGroupDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING,
      {
        featureDataGroupDisplayCondition,
      }
    ),

  /** 特徴量データの表示条件を設定する */
  setFeatureDataDisplayCondition: (
    featureDataDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING',
    { featureDataDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING,
      {
        featureDataDisplayCondition,
      }
    ),

  /** ロード中フラグを設定する */
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING,
      {
        inProgress,
      }
    ),

  /** 特徴量データグループの表示条件をクリアする */
  clearFeatureDataGroupDisplayCondition:
    (): Action<'CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING'> =>
      CreateAction(
        FeatureDataTransferringActionType.CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING
      ),

  /** 特徴量データの表示条件をクリアする */
  clearFeatureDataDisplayCondition:
    (): Action<'CLEAR_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING'> =>
      CreateAction(
        FeatureDataTransferringActionType.CLEAR_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING
      ),

  /** ステップを設定する */
  setFeatureDataTransferringState: (
    featureDataTransferringSteps: FeatureDataTransferringStateKind
  ): ActionWithPayload<
    'SET_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING',
    { featureDataTransferringSteps: FeatureDataTransferringStateKind }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING,
      {
        featureDataTransferringSteps,
      }
    ),

  /** メタデータを設定する */
  setFeatureDataTransferringMetaData: (
    mlPipelinesMetaData?: MetaData
  ): ActionWithPayload<
    'SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_TRANSFERRING',
    { mlPipelinesMetaData?: MetaData }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_TRANSFERRING,
      {
        mlPipelinesMetaData,
      }
    ),

  /** 選択したアルゴリズムIDを設定する */
  setSelectedTrainingAlgorithmId: (
    selectedTrainingAlgorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_TRANSFERRING',
    { selectedTrainingAlgorithmId: string }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_TRANSFERRING,
      {
        selectedTrainingAlgorithmId,
      }
    ),

  /** 特徴量データグループ詳細を設定する */
  setCurrentFeatureDataGroupDetail: (
    currentFeatureDataGroupDetail: FeatureData[]
  ): ActionWithPayload<
    'SET_CURRENT_FEATURE_DATA_GROUP_DETAIL_FOR_FEATURE_DATA_TRANSFERRING',
    {
      currentFeatureDataGroupDetail: FeatureData[]
    }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_CURRENT_FEATURE_DATA_GROUP_DETAIL_FOR_FEATURE_DATA_TRANSFERRING,
      {
        currentFeatureDataGroupDetail,
      }
    ),

  /** 特徴量データグループ選択ステップの状態を設定する */
  setFeatureDataGroupDataState: (
    data: ResourceLoadingState
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_DATA_STATE_FOR_FEATURE_DATA_TRANSFERRING',
    { data: ResourceLoadingState }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_FEATURE_DATA_GROUP_DATA_STATE_FOR_FEATURE_DATA_TRANSFERRING,
      {
        data,
      }
    ),

  /** ML Pipeline メタデータを設定する */
  setMlPipelineMetaData: (
    mlPipelinesMetaData?: MetaData
  ): ActionWithPayload<
    'SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_TRANSFERRING',
    { mlPipelinesMetaData?: MetaData }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_TRANSFERRING,
      {
        mlPipelinesMetaData,
      }
    ),

  /** 特徴量データグループ選択ステップの状態を設定する */
  setFeatureDataGroupSubState: (
    featureDataGroupSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING',
    { featureDataGroupSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING,
      {
        featureDataGroupSubState,
      }
    ),

  /** 特徴量データ選択ステップの状態を設定する */
  setFeatureDataSubState: (
    featureDataSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_FEATURE_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING',
    { featureDataSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_FEATURE_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING,
      {
        featureDataSubState,
      }
    ),

  /** メタデータ設定ステップの状態を設定する */
  setMetaDataSubState: (
    metaDataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING',
    { metaDataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING,
      {
        metaDataSubState,
      }
    ),

  /** 特徴量データ転送を実行する */
  executeFeatureDataTransferring: (
    isExecuted: boolean
  ): ActionWithPayload<
    'EXEC_FEATURE_DATA_TRANSFERRING_SUCCESS_FOR_FEATURE_DATA_TRANSFERRING',
    { isExecuted: boolean }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.EXEC_FEATURE_DATA_TRANSFERRING_SUCCESS_FOR_FEATURE_DATA_TRANSFERRING,
      {
        isExecuted,
      }
    ),

  /** 特徴量データ転送失敗ステータスを設定する */
  executeFeatureDataTransferringFailure:
    (): Action<'EXEC_FEATURE_DATA_TRANSFERRING_FAILURE_FOR_FEATURE_DATA_TRANSFERRING'> =>
      CreateAction(
        FeatureDataTransferringActionType.EXEC_FEATURE_DATA_TRANSFERRING_FAILURE_FOR_FEATURE_DATA_TRANSFERRING
      ),

  /** 特徴量データ転送結果を設定する */
  setExecutedInfo: (
    executedInfo?: ExecutionInfo
  ): ActionWithPayload<
    'SET_EXECUTED_INFO_FOR_FEATURE_DATA_TRANSFERRING',
    { executedInfo?: ExecutionInfo }
  > =>
    CreateAction(
      FeatureDataTransferringActionType.SET_EXECUTED_INFO_FOR_FEATURE_DATA_TRANSFERRING,
      {
        executedInfo,
      }
    ),

  /** 特徴量データ転送指示生成画面の状態を全てクリアする  */
  clearFeatureDataTransferringState:
    (): Action<'CLEAR_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING'> =>
      CreateAction(
        FeatureDataTransferringActionType.CLEAR_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING
      ),
}
