import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'
import {
  InferenceDetailAction,
  inferenceDetailActions,
  InferenceDetailOperations,
  MlPipelineLogFile,
  InferenceResultFile,
  InferenceResultDisplayCondition,
} from 'state/ducks/inferenceDetail'

import { createColor } from 'utils/colors'
import { getTrainedModelGroupId } from 'utils/ducks/trainedModelGroup'
import { isUndefined, isNullOrUndefined } from 'utils/typeguard'
import {
  InferenceIcon,
  StatusProgressBar,
  GlobalLoading,
  CopyableLabel,
  FileDownloadLabel,
  DataDetailItem,
  RunningTimeLabel,
  showToast,
  Toast,
  ConfirmViewerDialog,
  LabeledButton,
  InferenceResultsViewer,
  BreadcrumbsComponent,
} from 'views/components'
import { FileDownloadLabelPropsItems } from 'views/components/molecules/fileDownloadLabel/types'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
  formatTimeSecByDate,
  lowerThanDateOnly,
} from 'views/components/utils/date'
import {
  convertProgressWord,
  convertProgressColor,
  convertByteToMatchUnit,
  handleResourceNotFound,
} from 'views/containers/utils'
import { isDetailPathParams } from 'views/containers/utils/typeguard'
import { useTheme } from '@mui/material/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const mapStateToProps = (state: State) => ({
  ...state.pages.inferenceDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, InferenceDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 推論詳細取得 */
  getInferenceDetail: (mlPipelineId: string) =>
    dispatch(InferenceDetailOperations.getInferenceDetail(mlPipelineId)),
  /** ファイルデータ取得 */
  getFileData: () => dispatch(InferenceDetailOperations.getFileData()),
  /** Stateのクリア */
  clearInferenceDetailState: () =>
    dispatch(inferenceDetailActions.clearInferenceDetailState()),
  /** resultファイルをダウンロードする */
  resultFilesDownload: (link: MlPipelineLogFile) =>
    dispatch(InferenceDetailOperations.resultFilesDownload(link)),
  /** ログファイルをダウンロードする */
  logFileDownload: (mlPipelineId: string, logFiles: MlPipelineLogFile[]) =>
    dispatch(InferenceDetailOperations.logFileDownload(mlPipelineId, logFiles)),
  /** 学推論結果を取得する */
  getResult: async (mlPipelineId: string, trainingDataId: string) =>
    await dispatch(
      InferenceDetailOperations.getResult(mlPipelineId, trainingDataId)
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(inferenceDetailActions.setToastInfo(undefined)),
  /** 推論結果の表示情報を変更する */
  changeInferenceResultDisplayCondition: (
    inferenceResultDisplayCondition: InferenceResultDisplayCondition
  ) =>
    dispatch(
      inferenceDetailActions.setInferenceResultDisplayCondition(
        inferenceResultDisplayCondition
      )
    ),
  /** 学推論結果を取得する */
  setSelectedImageId: (imageId: string) =>
    dispatch(inferenceDetailActions.setSelectedImageId(imageId)),
  /** 加工画像urlを取得する */
  getProcessedUrl: async () =>
    await dispatch(InferenceDetailOperations.getProcessedUrl()),
  /** 推論結果の情報を空にする */
  setEmptyInferenceResult: () =>
    dispatch(inferenceDetailActions.setCurrentInferenceResults([])),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  fileDataLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}))

const InferenceDetail: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()
  const [openDialog, setOpenDialog] = useState(false)
  const [control, setControl] = useState(false)

  /** 初期実行 */
  useEffect(() => {
    // 表示用詳細取得
    props.getInferenceDetail(
      isDetailPathParams(props.match.params) ? props.match.params.id : ''
    )
    return () => {
      props.clearInferenceDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.domainData.currentInferenceDetail?.mlPipelineId) {
      props.getFileData()
    }
  }, [props.domainData.currentInferenceDetail?.mlPipelineId])

  useEffect(() => {
    const execute = async () => {
      if (
        isUndefined(props.domainData.currentInferenceDetail) ||
        props.domainData.currentInferenceDetail.groupedData.trainingDataList
          .length === 0
      )
        return
      await props.getProcessedUrl()
      await props.getResult(
        props.domainData.currentInferenceDetail.mlPipelineId,
        props.domainData.selectedTrainingDataId ??
          props.domainData.currentInferenceDetail.groupedData
            .trainingDataList[0].id
      )
    }
    execute()
  }, [
    props.domainData.selectedTrainingDataId,
    props.domainData.currentInferenceDetail,
  ])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )
  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
  /** モデルグループIDを取得 */
  const getTrainedModelGroupIdPath = async (
    userGroupId: string,
    modelId: string
  ) => {
    const modelGroupId = await getTrainedModelGroupId(userGroupId, modelId)
    return modelGroupId
  }
  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** ログファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.inferenceDetailState.mlPipelineLogSubState === 'Failed'
    ) {
      showErrorToast('ログファイルの取得に失敗しました。')
    }
  }, [props.appState.inferenceDetailState.mlPipelineLogSubState])

  /** 推論結果画像(学習画像)ファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.inferenceDetailState.processedTrainingDataUrlSubState ===
      'Failed'
    ) {
      showErrorToast('推論結果画像の取得に失敗しました。')
    }
  }, [props.appState.inferenceDetailState.processedTrainingDataUrlSubState])

  /** 推論結果ファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.inferenceDetailState.inferenceResultSubState === 'Failed'
    ) {
      showErrorToast('推論結果ファイルの取得に失敗しました。')
    }
  }, [props.appState.inferenceDetailState.inferenceResultSubState])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.inferenceDetailState.mlPipelineDataState,
      history
    )
  }, [props.appState.inferenceDetailState.mlPipelineDataState])

  const onClickImage = useCallback((imageId: string) => {
    props.setEmptyInferenceResult()
    props.setSelectedImageId(imageId)
  }, [])

  const canvasInfos = useMemo(() => {
    if (isUndefined(props.domainData.currentInferenceResults)) return []
    const uniqueIds = Array.from(
      new Set(
        props.domainData.currentInferenceResults?.map(
          (result) => result.label.id
        )
      )
    )
    let idColorMapping: { [x: string]: string } = {}
    uniqueIds.forEach((id) => {
      idColorMapping = {
        ...idColorMapping,
        [`${id}`]: createColor(id).hex(),
      }
    })

    return props.domainData.currentInferenceResults?.map((result) => {
      return {
        id: result.label.id,
        color: idColorMapping[result.label.id],
        score: result.score,
        label: result.label.name ?? result.label.id.substring(0, 8),
        x: result.box[0],
        y: result.box[1],
        width: result.box[2] - result.box[0],
        height: result.box[3] - result.box[1],
        mask: result.mask,
      }
    })
  }, [props.domainData.currentInferenceResults])

  const [nowTab, setNowTab] = useState(0)

  /** ファイルデータ取得中のloading */
  const fileDataLoading = useMemo(() => {
    if (props.appState.isInProgressForGettingInferenceResult) {
      if (nowTab === 1) {
        return (
          <Box className={classes.fileDataLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [props.appState.isInProgressForGettingInferenceResult, nowTab])

  /** urlから取得したmlPipelineId */
  const mlPipelineId = isDetailPathParams(props.match.params)
    ? props.match.params.id
    : ''
  if (mlPipelineId === '') {
    console.error('Error Invalid ML Pipeline ID')
    return <></>
  }

  const fileDownloadLabelPropsItems = (
    files: MlPipelineLogFile[] | InferenceResultFile[],
    isResultFile: boolean
  ): FileDownloadLabelPropsItems[] => {
    if (isResultFile) {
      return files.map((file) => {
        return {
          timeAndCapacity: `${convertByteToMatchUnit(
            file.fileSize
          )} ${formatDateTimeSec(file.createdAt)}`,
          url: file.fileName,
          onClick: () => props.resultFilesDownload(file),
        } as FileDownloadLabelPropsItems
      })
    } else {
      return files.map((file) => {
        return {
          timeAndCapacity: `${convertByteToMatchUnit(
            file.fileSize
          )} ${formatDateTimeSec(file.createdAt)}`,
          url: file.fileName,
          onClick: () => props.logFileDownload(mlPipelineId, [file]),
        } as FileDownloadLabelPropsItems
      })
    }
  }

  const tabItems: TabItems[] = [
    // 実行情報コンテンツ
    {
      label: '実行情報',
      displayInfo: (
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <DataDetailItem
              formHelperText='モデル'
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentInferenceDetail
                      ? props.domainData.currentInferenceDetail?.trainedModel
                          .trainedModelId
                      : ''
                  }
                />
              }
              startAdornment={
                <Link
                  variant='body1'
                  data-testid='base-model-detail'
                  className={classes.link}
                  underline='none'
                  onClick={async () => {
                    if (props.domainData.currentInferenceDetail) {
                      const trainedModelGroupId =
                        await getTrainedModelGroupIdPath(
                          props.domainData.currentInferenceDetail?.trainedModel
                            .isSharedUserGroupModel
                            ? props.auth.customClaims.sharedList[0]
                            : props.auth.customClaims.userGroupId,
                          props.domainData.currentInferenceDetail.trainedModel
                            .trainedModelId
                        )
                      return history.push(
                        `/model-groups/${trainedModelGroupId}/models/${
                          props.domainData.currentInferenceDetail.trainedModel
                            .trainedModelId
                        }${
                          props.domainData.currentInferenceDetail?.trainedModel
                            .isSharedUserGroupModel
                            ? '?shared-user-group=true'
                            : ''
                        }`
                      )
                    }
                  }}
                >
                  {!isNullOrUndefined(
                    props.domainData.currentInferenceDetail?.trainedModel
                      .trainedModelId
                  )
                    ? !isNullOrUndefined(
                        props.domainData.currentInferenceDetail?.trainedModel
                          .trainedModelName
                      )
                      ? props.domainData.currentInferenceDetail?.trainedModel
                          .trainedModelName
                      : NO_NAME
                    : ''}
                </Link>
              }
            />
            <Box mt={1}>
              <DataDetailItem
                formHelperText='画像セット'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentInferenceDetail
                        ? props.domainData.currentInferenceDetail?.groupedData
                            .annotationSetId
                        : ''
                    }
                  />
                }
                startAdornment={
                  <Link
                    variant='body1'
                    className={classes.link}
                    underline='none'
                    onClick={() => {
                      history.push(
                        `/datasets/${props.domainData.currentInferenceDetail?.groupedData.datasetId}/annotation-sets/${props.domainData.currentInferenceDetail?.groupedData.annotationSetId}`
                      )
                    }}
                  >
                    {`${props.domainData.currentInferenceDetail?.groupedData.groupedDataName} (${props.domainData.currentInferenceDetail?.groupedData.imageListCount}枚)`}
                  </Link>
                }
              />
            </Box>
          </Box>
        </Box>
      ),
    },
    // 処理結果コンテンツ
    {
      label: '処理結果',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <div className={classes.flexAndBetween}>
                <Box display='flex' alignItems='center'>
                  <Typography>
                    <h4>推論結果</h4>
                  </Typography>
                </Box>
                {props.domainData.currentInferenceDetail &&
                  props.domainData.currentInferenceDetail.groupedData
                    .trainingDataList.length > 0 && (
                    <Box display='flex'>
                      <LabeledButton
                        label='拡大表示'
                        onClick={() => setOpenDialog(true)}
                        color='blue'
                      />
                    </Box>
                  )}
              </div>
              <Divider />
              {props.domainData.currentInferenceDetail &&
                props.domainData.currentInferenceDetail.groupedData
                  .trainingDataList.length > 0 && (
                  <Box m={2}>
                    <InferenceResultsViewer
                      loading={props.appState.isInProgressForRendering}
                      selectedImageId={
                        props.domainData.selectedTrainingDataId ??
                        props.domainData.currentInferenceDetail.groupedData
                          .trainingDataList[0].id
                      }
                      imageData={
                        props.domainData.currentInferenceDetail.groupedData
                          .trainingDataList
                      }
                      canvasInfos={canvasInfos}
                      sliderRows={2}
                      onClickImage={(imageId: string) => {
                        if (
                          props.domainData.selectedTrainingDataId !== imageId
                        ) {
                          onClickImage(imageId)
                        }
                      }}
                      isDisplayBoxesCount
                      inferenceResultDisplayCondition={
                        props.domainData.inferenceResultDisplayCondition
                      }
                      changeInferenceResultDisplayCondition={
                        props.changeInferenceResultDisplayCondition
                      }
                    />
                  </Box>
                )}
              <FileDownloadLabel
                items={fileDownloadLabelPropsItems(
                  props.domainData.resultFiles,
                  true
                )}
              />
            </Box>
          </Box>
          <Box component={Paper} mt={2}>
            <Box p={'24px 32px 32px'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box display='flex'>
                  <Typography>
                    <h4>ログ</h4>
                  </Typography>
                </Box>
                {props.domainData.mlPipelineLogFiles.length > 0 ? (
                  <Box display='flex'>
                    <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                    <Link
                      data-testid='all-download-logs'
                      style={{ cursor: 'pointer' }}
                      underline='none'
                      onClick={() =>
                        props.domainData.currentInferenceDetail &&
                        props.logFileDownload(
                          mlPipelineId,
                          props.domainData.mlPipelineLogFiles
                        )
                      }
                    >
                      一括ダウンロード
                    </Link>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
              <Divider />
              <FileDownloadLabel
                items={fileDownloadLabelPropsItems(
                  props.domainData.mlPipelineLogFiles,
                  false
                )}
              />
            </Box>
          </Box>
        </>
      ),
    },
    // 備考タブコンテンツ
    {
      label: '備考',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                style={{ width: '100%' }}
                value={
                  props.domainData.currentInferenceDetail?.mlPipelineRemarks
                }
                variant='outlined'
                multiline
                disabled
                minRows={5}
                inputProps={{
                  'data-testid': 'input-remarks',
                }}
              />
            </Box>
          </Box>
        </>
      ),
    },
  ]

  return (
    <>
      {!isUndefined(props.domainData.currentInferenceDetail) ? (
        <>
          <div className={classes.container}>
            <Toast containerOptions={{ limit: 20 }}>
              <Box
                style={{
                  position: 'sticky',
                  top: '64px',
                  backgroundColor: '#fafafa',
                  zIndex: 10,
                }}
              >
                <Box className={classes.innerContainer}>
                  <Box pt={3}>
                    <BreadcrumbsComponent
                      breadcrumbsPath={[
                        {
                          name: '推論一覧',
                          path: 'inferences',
                        },
                        {
                          name:
                            props.domainData.currentInferenceDetail
                              .mlPipelineName !== ''
                              ? `${props.domainData.currentInferenceDetail.mlPipelineName}`
                              : `${props.domainData.currentInferenceDetail.mlPipelineId}`,
                          path: `${props.domainData.currentInferenceDetail.mlPipelineId}`,
                        },
                      ]}
                    />
                  </Box>
                  <div className={classes.flexAndBetween}>
                    <Box
                      display='flex'
                      style={{
                        maxWidth: 'calc(100% - 280px)',
                        overflow: 'hidden',
                      }}
                    >
                      <InferenceIcon
                        className={classes.pageIcon}
                        data-testid='InferenceDetailTitleIcon'
                      />
                      <Box
                        height={76}
                        data-testid='inference-detail-title'
                        style={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <Typography component='div'>
                          <h2
                            style={{
                              maxWidth: '100%',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {
                              props.domainData.currentInferenceDetail
                                .mlPipelineName
                            }
                          </h2>
                        </Typography>
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box mr={3}>
                        <CopyableLabel
                          value={mlPipelineId}
                          isTooltip
                          placement='top'
                        />
                      </Box>
                      <Box width='150px'>
                        <StatusProgressBar
                          status={convertProgressWord(
                            props.domainData.currentInferenceDetail.progress
                              .transactionStatus
                          )}
                          progressRate={
                            props.domainData.currentInferenceDetail.progress
                              .progressRate
                          }
                          progressColor={convertProgressColor(
                            props.domainData.currentInferenceDetail.progress
                              .transactionStatus
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box p={1}>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' alignItems='center'>
                        <HandymanIcon
                          style={{ marginRight: '4px' }}
                          sx={{ color: globalTheme.palette.text.secondary }}
                        />
                        <Box sx={{ color: globalTheme.palette.text.secondary }}>
                          <Typography component='div'>
                            <h4>
                              {`${props.domainData.currentInferenceDetail.inferenceAlgorithm.algorithmName} v 
                              ${props.domainData.currentInferenceDetail.inferenceAlgorithm.inferenceAlgorithmVersion.displayName}`}
                            </h4>
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' mb={1}>
                        <TimerIcon style={{ marginRight: '4px' }} />
                        {isUndefined(
                          props.domainData.currentInferenceDetail.endedAt
                        ) ? (
                          // endAtがundefinedの場合
                          <Box alignItems='center' display='flex'>
                            <Typography>{`${formatDateTimeSec(
                              props.domainData.currentInferenceDetail.startedAt.toDate()
                            )} ~ `}</Typography>
                            (
                            <RunningTimeLabel
                              startedAt={props.domainData.currentInferenceDetail.startedAt.toDate()}
                            />
                            )
                          </Box>
                        ) : lowerThanDateOnly(
                            props.domainData.currentInferenceDetail.startedAt.toDate(),
                            props.domainData.currentInferenceDetail.endedAt.toDate()
                          ) ? (
                          // 日付が同じ場合
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentInferenceDetail.startedAt.toDate()
                          )} ~ ${formatTimeSecByDate(
                            props.domainData.currentInferenceDetail.endedAt.toDate()
                          )}(${formatTimeSecByMillSecond(
                            props.domainData.currentInferenceDetail.endedAt
                              .toDate()
                              .getTime() -
                              props.domainData.currentInferenceDetail.startedAt
                                .toDate()
                                .getTime()
                          )})`}</Typography>
                        ) : (
                          // 日付が違う場合
                          <Typography>{`${formatDateTimeSec(
                            props.domainData.currentInferenceDetail.startedAt.toDate()
                          )} ~ ${formatDateTimeSec(
                            props.domainData.currentInferenceDetail.endedAt.toDate()
                          )}(${formatTimeSecByMillSecond(
                            props.domainData.currentInferenceDetail.endedAt
                              .toDate()
                              .getTime() -
                              props.domainData.currentInferenceDetail.startedAt
                                .toDate()
                                .getTime()
                          )})`}</Typography>
                        )}
                      </Box>
                      <Box display='flex'>
                        {typeof props.domainData.currentInferenceDetail
                          ?.createdBy === 'string' ? (
                          <Typography>
                            {props.domainData.currentInferenceDetail?.createdBy}
                          </Typography>
                        ) : (
                          <Typography>
                            {
                              props.domainData.currentInferenceDetail?.createdBy
                                .firstName
                            }{' '}
                            {
                              props.domainData.currentInferenceDetail?.createdBy
                                .familyName
                            }
                          </Typography>
                        )}
                      </Box>
                    </div>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: '#fafafa',
                    }}
                  >
                    <Tabs
                      indicatorColor='primary'
                      value={nowTab}
                      style={{
                        paddingBottom: '16px',
                        marginBottom: '1px',
                      }}
                      onChange={(_, value) => setNowTab(value)}
                    >
                      {tabItems.map((item, index) => (
                        <Tab
                          style={{
                            width: `${100 / tabItems.length}%`,
                            maxWidth: '1500px',
                          }}
                          key={index}
                          className={clsx(nowTab === index && classes.nowTab)}
                          label={item.label}
                          data-testid={`change-tab-${index}`}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
              <ConfirmViewerDialog
                maxWidth='xl'
                open={openDialog}
                title='推論結果'
                control={
                  <>
                    <Box display='flex' alignItems='center' pr={2}>
                      <Box display='flex'>操作パネル</Box>
                    </Box>
                    <Box display='flex'>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={control}
                            onChange={(event) =>
                              setControl(event.target.checked)
                            }
                            color='secondary'
                          />
                        }
                        label=''
                      />
                    </Box>
                  </>
                }
                message={
                  <>
                    {props.domainData.currentInferenceDetail &&
                      props.domainData.currentInferenceDetail.groupedData
                        .trainingDataList.length > 0 && (
                        <InferenceResultsViewer
                          isDialog={true}
                          control={control}
                          loading={props.appState.isInProgressForRendering}
                          selectedImageId={
                            props.domainData.selectedTrainingDataId ??
                            props.domainData.currentInferenceDetail.groupedData
                              .trainingDataList[0].id
                          }
                          imageData={
                            props.domainData.currentInferenceDetail.groupedData
                              .trainingDataList
                          }
                          canvasInfos={canvasInfos}
                          sliderRows={2}
                          onClickImage={(imageId: string) => {
                            if (
                              props.domainData.selectedTrainingDataId !==
                              imageId
                            ) {
                              onClickImage(imageId)
                            }
                          }}
                          isDisplayBoxesCount
                          inferenceResultDisplayCondition={
                            props.domainData.inferenceResultDisplayCondition
                          }
                          changeInferenceResultDisplayCondition={
                            props.changeInferenceResultDisplayCondition
                          }
                        />
                      )}
                  </>
                }
                handleClose={() => setOpenDialog(false)}
              />
            </Toast>
          </div>
        </>
      ) : (
        <></>
      )}
      {fileDataLoading}
      <GlobalLoading
        open={
          props.appState.isInProgressForGettingInferenceDetail ||
          props.appState.isInProgressForDownloading
        }
      />
    </>
  )
}

export const InferenceDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InferenceDetail))
