import axios from 'axios'
import { Dispatch } from 'redux'
import {
  getAccountSettingCollection,
  getDatasetQueryCollection,
  getMlPipelineQueriesCollection,
  getSettingsMetaDataCollection,
  getFeatureDataQueriesCollection,
} from 'state/firebase'
import { isObject, isString, isNumber, isArray } from 'utils/typeguard'
import { State } from 'state/store'
import { featureDataGeneratingDetailActions } from './'
import {
  FeatureDataDlLink,
  MlPipelineLogFile,
  GetFeatureDataFilesResponse,
  MediaLink,
} from './types'
import { FeatureDataGeneratingDetailApi } from './apis'
import { convertProgressRateByTransactionStatusForFeatureDataGenerating } from 'state/utils'
import { isGetLogResponse } from 'state/utils/typeguard'
import { GetLogResponse } from 'state/utils/types'
import { doc, getDoc } from 'firebase/firestore'
import { HttpsCallableResult } from 'firebase/functions'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataGeneratingMLPipelineQueryDocument } from 'utils/fireStore/featureDataGeneratingMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import saveAs from 'file-saver'

/** ダウンロード失敗時にStateの失敗したファイルを保持 */
function holdDownloadFailedFiles(
  dispatch: Dispatch,
  downloadFailedFileList: string[]
) {
  // 失敗ファイルの頭3件は名称を表示し、残りは"他n件"という形で表示する
  const displayNameCnt = 3
  const targets = downloadFailedFileList
    .slice(0, displayNameCnt)
    .concat(
      downloadFailedFileList.length > displayNameCnt
        ? [`他${downloadFailedFileList.length - displayNameCnt}件`]
        : []
    )
  dispatch(
    featureDataGeneratingDetailActions.setToastInfo({
      type: 'error',
      title: 'ダウンロードに失敗しました',
      targets,
    })
  )
}

/** MediaLink[]かどうか */
function isMediaLinks(object: unknown): object is MediaLink[] {
  return (
    isArray(object) &&
    object.every((element) => {
      return (
        isString(element.mediaName) &&
        isString(element.mediaUrl) &&
        isNumber(element.mediaSize)
      )
    })
  )
}

/** GetFeatureDataFilesResponseかどうか */
function isGetFeatureDataFilesResponse(
  object: unknown
): object is GetFeatureDataFilesResponse {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isString(element.linkName) && isMediaLinks(element.mediaLinks)
    })
  )
}

export const featureDataGeneratingDetailOperations = {
  /** リストを取得する */
  getFeatureDataGeneratingDetail:
    (mlPipelineId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGeneratingDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const accountGroupId =
          getState().app.domainData.authedUser.auth.customClaims.accountGroupId
        const algorithms = getState().app.domainData.algorithms

        /** mlPipelineのデータ */
        const mlPipeline = await getDoc(
          doc(getMlPipelineQueriesCollection(userGroupId), mlPipelineId)
        )
        const mlPipelineData = mlPipeline.data()

        if (!mlPipelineData) {
          dispatch(
            featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
              {
                ...getState().pages.featureDataGeneratingDetailState.appState
                  .featureDataGeneratingDetailState,
                mlPipelineDataState: 'NotFoundProcessed',
              }
            )
          )
        }

        if (
          !fireStoreTypeGuardForFeatureDataGeneratingMLPipelineQueryDocument(
            mlPipelineData
          )
        ) {
          dispatch(
            featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
              {
                ...getState().pages.featureDataGeneratingDetailState.appState
                  .featureDataGeneratingDetailState,
                mlPipelineDataState: 'Failed',
              }
            )
          )
          return
        }

        const algorithmId = mlPipelineData
          ? mlPipelineData['training-step']['src']['algorithm-id']
          : ''
        // アルゴリズム取得
        const algorithm = algorithms.find((algorithm) => {
          return algorithm.algorithmId === algorithmId
        })

        /** データセットのメタデータ */
        const datasetQueryData = (
          await getDoc(
            doc(
              getDatasetQueryCollection(userGroupId),
              mlPipelineData
                ? mlPipelineData['training-step']['src']['dataset-id']
                : ''
            )
          )
        ).data()

        if (!fireStoreTypeGuardForDatasetQueryDocument(datasetQueryData)) {
          dispatch(
            featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
              {
                ...getState().pages.featureDataGeneratingDetailState.appState
                  .featureDataGeneratingDetailState,
                mlPipelineDataState: 'Failed',
              }
            )
          )
          return
        }
        /** セッティングのメタデータ */
        const settingMetadata =
          mlPipelineData && mlPipelineData['training-step']['src']['setting-id']
            ? (
                await getDoc(
                  doc(
                    getSettingsMetaDataCollection(userGroupId),
                    mlPipelineData['training-step']['src']['setting-id']
                  )
                )
              ).data()
            : undefined

        let accountSetting = undefined

        try {
          /** accountSetting */
          accountSetting = (
            await getDoc(
              doc(
                getAccountSettingCollection(accountGroupId),
                mlPipelineData ? mlPipelineData['created-by'] : ''
              )
            )
          ).data()
        } catch {
          accountSetting = undefined
        }

        if (
          accountSetting &&
          !fireStoreTypeGuardForAccountSettingDocument(accountSetting)
        ) {
          dispatch(
            featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
              {
                ...getState().pages.featureDataGeneratingDetailState.appState
                  .featureDataGeneratingDetailState,
                mlPipelineDataState: 'Failed',
              }
            )
          )
          return
        }

        /** 特徴量データ名を取得 */
        let featureDataQuery = undefined
        if (mlPipelineData?.['training-step']?.['dest']?.['feature-data-id']) {
          featureDataQuery = await getDoc(
            doc(
              getFeatureDataQueriesCollection(userGroupId),
              mlPipelineData['training-step']['dest']['feature-data-id']
            )
          )
        }

        const featureDataQueryData = featureDataQuery
          ? featureDataQuery.data()
          : undefined

        const steps = {
          trainingStep: {
            stepId: mlPipelineData
              ? mlPipelineData['training-step']['step-id']
              : '',
            stepStatus: mlPipelineData
              ? mlPipelineData['training-step']['step-status']
              : '',
          },
        }

        dispatch(
          featureDataGeneratingDetailActions.setCurrentFeatureDataGeneratingDetail(
            {
              mlPipelineId: mlPipelineData
                ? mlPipelineData['ml-pipeline']['ml-pipeline-id']
                : '',
              mlPipelineName: mlPipelineData
                ? mlPipelineData['ml-pipeline-metadata']['name']
                : '',
              mlPipelineRemarks: mlPipelineData
                ? mlPipelineData['ml-pipeline-metadata']['remarks']
                : '',
              trainingAlgorithm: {
                algorithmName: algorithm ? algorithm.metadata.name.ja : '',
                trainingAlgorithmVersion: {
                  displayName: mlPipelineData
                    ? mlPipelineData['training-step']['src'][
                        'training-algorithm-version'
                      ]['display-name']
                    : '0.0.0',
                  major: mlPipelineData
                    ? mlPipelineData['training-step']['src'][
                        'training-algorithm-version'
                      ]['major']
                    : 0,
                  minor: mlPipelineData
                    ? mlPipelineData['training-step']['src'][
                        'training-algorithm-version'
                      ]['minor']
                    : 0,
                  patch: mlPipelineData
                    ? mlPipelineData['training-step']['src'][
                        'training-algorithm-version'
                      ]['patch']
                    : 0,
                  preRelease: mlPipelineData
                    ? mlPipelineData['training-step']['src'][
                        'training-algorithm-version'
                      ]['pre-release']
                    : 0,
                },
              },
              progress: {
                transactionStatus: mlPipelineData
                  ? mlPipelineData['ml-pipeline']['transaction-status']
                  : '',
                progressRate:
                  convertProgressRateByTransactionStatusForFeatureDataGenerating(
                    mlPipelineData
                      ? mlPipelineData['ml-pipeline']['transaction-status']
                      : ''
                  ),
              },
              startedAt: mlPipelineData
                ? mlPipelineData['ml-pipeline']['started-at']
                : '',
              endedAt:
                mlPipelineData &&
                mlPipelineData['ml-pipeline']['ended-at'] &&
                mlPipelineData['ml-pipeline']['ended-at'].seconds === 0
                  ? undefined
                  : mlPipelineData
                  ? mlPipelineData['ml-pipeline']['ended-at']
                  : '',
              dataset: {
                datasetId: mlPipelineData
                  ? mlPipelineData['training-step']['src']['dataset-id']
                  : '',
                datasetName: datasetQueryData
                  ? datasetQueryData['dataset-name']
                  : '',
              },
              setting: {
                settingId: mlPipelineData
                  ? mlPipelineData['training-step']['src']['setting-id']
                  : '',
                settingName: settingMetadata ? settingMetadata['name'] : '',
              },
              featureData: {
                featureDataId: featureDataQueryData
                  ? featureDataQueryData['feature-data-id']
                    ? featureDataQueryData['feature-data-id']
                    : ''
                  : '',
                featureDataName: featureDataQueryData
                  ? featureDataQueryData['feature-data-name']
                  : '',
              },
              destType: mlPipelineData
                ? mlPipelineData['transfer-step']
                  ? 'All'
                  : 'FeatureDataOnly'
                : 'FeatureDataOnly',
              steps: steps,
              createdBy: accountSetting
                ? {
                    firstName: accountSetting['first-name'] ?? '',
                    familyName: accountSetting['family-name'] ?? '',
                  }
                : mlPipelineData
                ? mlPipelineData['created-by']
                : '',
            }
          )
        )
        dispatch(
          featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
            {
              ...getState().pages.featureDataGeneratingDetailState.appState
                .featureDataGeneratingDetailState,
              mlPipelineDataState: 'Loaded',
            }
          )
        )
      } catch (error) {
        console.error(error)
        dispatch(
          featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
            {
              ...getState().pages.featureDataGeneratingDetailState.appState
                .featureDataGeneratingDetailState,
              mlPipelineDataState: 'Failed',
            }
          )
        )
      } finally {
        dispatch(featureDataGeneratingDetailActions.setInProgress(false))
      }
    },
  /** ファイルデータの取得 */
  getFileData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const requestMlPipelineId =
          getState().pages.featureDataGeneratingDetailState.domainData
            .currentFeatureDataGeneratingDetail?.mlPipelineId
        const featureDataId =
          getState().pages.featureDataGeneratingDetailState.domainData
            .currentFeatureDataGeneratingDetail?.featureData.featureDataId

        const currentFeatureDataGeneratingDetail =
          getState().pages.featureDataGeneratingDetailState.domainData
            .currentFeatureDataGeneratingDetail
        if (!currentFeatureDataGeneratingDetail) {
          return
        }
        let logItems: MlPipelineLogFile[] = []
        const featureDataFilesItems: FeatureDataDlLink[] = []
        // 特徴量データファイル用キャッチ
        try {
          const getFeatureDataFilesResponse =
            await FeatureDataGeneratingDetailApi.getFeatureDataFiles(
              featureDataId ? featureDataId : ''
            )

          if (isGetFeatureDataFilesResponse(getFeatureDataFilesResponse)) {
            getFeatureDataFilesResponse.data.items.map((file) => {
              featureDataFilesItems.push({
                linkName: file.linkName,
                mediaLinks: file.mediaLinks,
                totalMediaSize: file.mediaLinks.reduce(
                  (prev, current) => prev + current.mediaSize,
                  0
                ),
              })
            })

            dispatch(
              featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
                {
                  ...getState().pages.featureDataGeneratingDetailState.appState
                    .featureDataGeneratingDetailState,
                  featureDataDlLinkSubState: 'Loaded',
                }
              )
            )
          }
        } catch {
          dispatch(
            featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
              {
                ...getState().pages.featureDataGeneratingDetailState.appState
                  .featureDataGeneratingDetailState,
                featureDataDlLinkSubState: 'Failed',
              }
            )
          )
        }

        // ログファイル用キャッチ
        try {
          const logResponse = await FeatureDataGeneratingDetailApi.getLogs(
            requestMlPipelineId ? requestMlPipelineId : ''
          )
          if (isGetLogResponse(logResponse)) {
            logItems = (logResponse as GetLogResponse).data.items.map(
              (log) => ({
                fileName: log.fileNameForDownload,
                fileLink: log.name,
                fileSize: log.size,
                createdAt: new Date(log.createdAt),
              })
            )

            dispatch(
              featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
                {
                  ...getState().pages.featureDataGeneratingDetailState.appState
                    .featureDataGeneratingDetailState,
                  mlPipelineLogSubState: 'Loaded',
                }
              )
            )
          }
        } catch {
          dispatch(
            featureDataGeneratingDetailActions.setFeatureDataGeneratingDetailState(
              {
                ...getState().pages.featureDataGeneratingDetailState.appState
                  .featureDataGeneratingDetailState,
                mlPipelineLogSubState: 'Failed',
              }
            )
          )
        }

        const currentMlPipelineId =
          getState().pages.featureDataGeneratingDetailState.domainData
            .currentFeatureDataGeneratingDetail?.mlPipelineId
        if (requestMlPipelineId === currentMlPipelineId) {
          dispatch(
            featureDataGeneratingDetailActions.setFeatureDataDlLinks(
              featureDataFilesItems
            )
          )
          dispatch(
            featureDataGeneratingDetailActions.setMlPipelineLogFiles(logItems)
          )
        }
      } catch (error) {
        console.error(error)
      }
    },
  /** 特徴量データファイルのダウンロード */
  featureDataFileDownload:
    (links: MediaLink[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(featureDataGeneratingDetailActions.setInProgress(true))

        // ファイルDL失敗リスト
        const failedModelFileList: string[] = []
        await Promise.all(
          links.map(async (link) => {
            try {
              await axios
                .get(link.mediaUrl, {
                  responseType: 'blob',
                })
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: response.data.type,
                  })
                  saveAs(blob, link.mediaName)
                })
            } catch {
              failedModelFileList.push(link.mediaName)
            }
          })
        )
        // 失敗した画像があれば、Storeに保持
        if (failedModelFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedModelFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGeneratingDetailActions.setInProgress(false))
      }
    },
  /** ログファイルのダウンロード */
  logFileDownload:
    (mlPipelineId: string, logFiles: MlPipelineLogFile[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(featureDataGeneratingDetailActions.setInProgress(true))

        // DL失敗の画像のリスト
        const failedLogFileList: string[] = []

        await Promise.all(
          logFiles.map(async (logFile) => {
            try {
              const res = (await FeatureDataGeneratingDetailApi.downloadLog(
                mlPipelineId,
                logFile.fileLink
              )) as HttpsCallableResult<{ blob: BlobPart; type: string }>
              const blob = new Blob([res.data.blob], {
                type: res.data.type,
              })
              saveAs(blob, logFile.fileName)
            } catch {
              failedLogFileList.push(logFile.fileName)
            }
          })
        )

        // 失敗した画像があれば、Storeに保持
        if (failedLogFileList.length > 0) {
          holdDownloadFailedFiles(dispatch, failedLogFileList)
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGeneratingDetailActions.setInProgress(false))
      }
    },
}
