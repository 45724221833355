import React from 'react'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { AccordionProgressLabelProps } from './types'
import { LabeledProgressBar } from 'views/components'

const useStyles = makeStyles()((theme) => ({
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  detailListItem: {
    padding: 0,
  },
  detailText: {
    display: 'table-cell',
  },
  detailValueText: {
    paddingLeft: theme.spacing(1),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}))

export const AccordionProgressLabel: React.FC<AccordionProgressLabelProps> = (
  props: AccordionProgressLabelProps
) => {
  const { classes } = useStyles()

  const detailListItems = () => {
    const details = Object.entries(props.details ? props.details : {}).map(
      (tupple) => {
        return (
          <ListItem dense className={classes.detailListItem} key={tupple[0]}>
            <Box display='table'>
              <Typography
                noWrap
                className={classes.detailText}
              >{`${props.prefix}${tupple[0]}${props.delimiter}`}</Typography>
              <Typography
                className={clsx(classes.detailText, classes.detailValueText)}
              >
                {tupple[1]}
              </Typography>
            </Box>
          </ListItem>
        )
      }
    )
    return details
  }

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div className={classes.flexAndBetween}>
            <Box display='flex'>
              <Typography variant='body1' className={classes.labelText}>
                {props.label ? props.label : '---'}
              </Typography>
            </Box>
            <LabeledProgressBar
              value={props.progressValue}
              progressColor={props.progressColor}
              completedColor={props.progressCompletedColor}
              waitingColor={props.progressWaitingColor}
              width='200px'
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <List>{detailListItems()}</List>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
