import Color from 'color'
import seedrandom from 'seedrandom'

/**
 * カラーコードからRGB変換
 * @param string Hexカラーコード
 */
export const convertHexToRgb = (hex: string): number[] => {
  if (hex.slice(0, 1) == '#') hex = hex.slice(1)
  if (hex.length == 3)
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3)

  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (
    str
  ) {
    return parseInt(str, 16)
  })
}

export const createColor = (seed: string): Color =>
  Color(`hsl(${seedrandom(seed)() * 360}, 80%, 50%)`)
