import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { blue, green, red, grey } from '@mui/material/colors'

import { LabeledProgressBarProps, ProgressBarColorType } from './types'

const getColorArr = (color?: ProgressBarColorType) => {
  switch (color) {
    case 'blue':
      return blue
    case 'green':
      return green
    case 'red':
      return red
    case 'grey':
    default:
      return grey
  }
}
const getColorStyles = (color?: ProgressBarColorType) => ({
  height: '100%',
  outline: `solid 2px ${getColorArr(color)[500]}`,
  backgroundColor: getColorArr(color)[50],
  '& > .MuiLinearProgress-bar': {
    height: '100%',
    backgroundColor: getColorArr(color)[500],
    opacity: 0.6,
  },
})
const useStyles = (
  completedColor?: ProgressBarColorType,
  progressColor?: ProgressBarColorType,
  waitingColor?: ProgressBarColorType
) =>
  makeStyles()(() => ({
    rootBox: {
      display: 'flex',
      alignItems: 'center',
    },
    progressBar: {
      position: 'relative',
      width: '100%',
      height: '20px',
    },
    completedLinearProgress: getColorStyles(completedColor),
    waitingLinearProgress: getColorStyles(waitingColor),
    linearProgress: getColorStyles(progressColor),
    progressValue: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }))()

export const LabeledProgressBar: React.FC<LabeledProgressBarProps> = (
  props: LabeledProgressBarProps
) => {
  const { classes } = useStyles(
    props.completedColor,
    props.progressColor,
    props.waitingColor
  )

  const getPercentageValue = (rawVal: number) =>
    rawVal > 100 ? 100 : rawVal < 0 ? 0 : rawVal

  const linearProgressColor = (value: number) => {
    if (value === 100) {
      return classes.completedLinearProgress
    } else if (value === 0) {
      return classes.waitingLinearProgress
    } else return classes.linearProgress
  }

  return (
    <Box className={classes.rootBox}>
      <Box
        mr={1}
        className={classes.progressBar}
        style={{ width: props.width }}
      >
        <LinearProgress
          value={getPercentageValue(props.value)}
          variant='determinate'
          className={linearProgressColor(getPercentageValue(props.value))}
        />
        <div className={classes.progressValue}>
          {`${Math.round(getPercentageValue(props.value))}%`}
        </div>
      </Box>
    </Box>
  )
}
