import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ClearIcon from '@mui/icons-material/Clear'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import MoreVert from '@mui/icons-material/MoreVert'

import { ThumbnailCardControllerProps } from './types'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  buttons: {
    position: 'absolute',
    color: theme.palette.common.white,
  },
  menuIcon: {
    right: 0,
  },
}))

export const ThumbnailCardController: React.FC<ThumbnailCardControllerProps> = (
  props: ThumbnailCardControllerProps
) => {
  const { classes } = useStyles()

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(undefined)

  return (
    <div>
      <Checkbox
        className={classes.buttons}
        checked={props.checked}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation()
        }
        onChange={() => props.onClickCheckbox(props.imageDataId)}
      />
      <IconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
          handleClick(e)
        }}
        className={`${classes.buttons} ${classes.menuIcon}`}
        data-testid={`${props['data-testid']}-button-menu-${props.imageDataId}`}
      >
        <MoreVert />
      </IconButton>
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              props.onClickDownload(props.imageDataId)
              handleClose()
            }}
            data-testid={`${props['data-testid']}-button-menu-download-${props.imageDataId}`}
          >
            <Box display='flex'>
              <FileDownloadOutlinedIcon />
              ダウンロード
            </Box>
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            data-testid={`${props['data-testid']}-button-menu-cancel-${props.imageDataId}`}
          >
            <Box display='flex'>
              <ClearIcon />
              キャンセル
            </Box>
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}
