import { DestType } from 'state/ducks/customTrainingDetail/types'
import * as H from 'history'
import { DatasetType } from 'state/ducks/datasetDetail/types'
import { CustomClaims } from 'state/app/domainData'

/** algorithmKindの和名変換 */
export const convertAlgorithmKind = (kind: string): string => {
  switch (kind) {
    case 'RecognitionWorkObject':
      return '対象物認識'
    default:
      return kind
  }
}

/** ML Pipelineのステータスの和名変換 */
export const convertProgressWord = (
  status: string,
  isFeatureDataGenerating?: boolean
): string => {
  switch (status) {
    case 'Completed':
      return '成功'
    case 'Failed':
      return '失敗'
    case 'Starting':
      return '開始'
    case 'PreProcessing':
      return '前処理'
    case 'CustomTraining':
      return isFeatureDataGenerating ? '生成中' : '学習中(1回目)'
    case 'Inferring':
      return '推論中(1回目)'
    case 'Evaluating':
      return '評価中(1回目)'
    case 'PostProcessing':
      return '後処理'
    case 'CreatingAILibrary':
      return 'ビルド'
    case 'EvaluatingAILibrary':
      return 'ビルド結果評価中'
    case 'Building':
      return 'ビルド中'
    case 'Transferring':
      return '転送中'
    case 'Pending':
      return '保留'
    case 'BuildingEdgeImage':
      return 'ビルド中'
    case 'EvaluatingEdgeImage':
      return '評価中'
    case 'Augmentation':
      return '処理中'
    default:
      return ''
  }
}

/** ML Pipelineのステータスのカラー変換 */
export const convertProgressColor = (
  transactionStatus: string
): 'blue' | 'red' | 'green' => {
  switch (transactionStatus) {
    case 'Completed':
      return 'blue'
    case 'Failed':
      return 'red'
    default:
      return 'green'
  }
}

/** DestTypeの日本語変換 */
export const convertDestType = (destType: DestType): string => {
  switch (destType) {
    case 'EdgeImage':
      return '全部(カスタムモデル+エッジイメージのビルド)'
    default:
      return 'カスタムモデル作成のみ'
  }
}

/** DestTypeの日本語変換 */
export const convertDestTypeForFeatureData = (destType: string): string => {
  switch (destType) {
    case 'FeatureDataOnly':
      return '生成のみ'
    default:
      return '全部'
  }
}

/** DatasetTypeの日本語変換 */
export const convertDatasetType = (datasetType: DatasetType): string => {
  switch (datasetType) {
    case 'Inference':
      return '推論'
    case 'Training':
      return 'カスタム学習'
    default:
      return 'N/A'
  }
}

/** Trained Modelのモデル種別の和名変換 */
export const convertModelKindWord = (kind: string): string => {
  switch (kind) {
    case 'Generic':
      return '汎用'
    case 'Custom':
      return 'カスタム'
    case 'Specified':
      return 'スペシファイド'
    default:
      return ''
  }
}

/** Trained Modelの評価結果の和名変換 */
export const convertEvaluationStatusWord = (
  evaluationStatus: string
): string => {
  switch (evaluationStatus) {
    case 'Passed':
      return '評価 OK'
    case 'Failed':
      return '評価 NG'
    case 'Ne':
    default:
      return '未評価'
  }
}

/** Trained Modelの評価結果ののカラー変換 */
export const convertEvaluationStatusColor = (
  evaluationStatus: string
): 'blue' | 'red' | 'grey' => {
  switch (evaluationStatus) {
    case 'Passed':
      return 'blue'
    case 'Failed':
      return 'red'
    case 'Ne':
    default:
      return 'grey'
  }
}

/* byte サイズを 単位を付与した文字列に変換 */
export const convertByteToMatchUnit = (byte: number): string => {
  const kb = 1024
  const mb = Math.pow(kb, 2)
  const tb = Math.pow(kb, 4)
  let target = null
  let unit = 'byte'

  if (byte >= tb) {
    target = tb
    unit = 'TB'
  } else if (byte >= mb) {
    target = mb
    unit = 'MB'
  } else if (byte >= kb) {
    target = kb
    unit = 'KB'
  }
  const res = target !== null ? Math.floor((byte / target) * 100) / 100 : byte
  return res + unit
}

/** アルゴリズムの種別（和名変換） */
export const mappingKindLanguage = (kind: string): string => {
  switch (kind) {
    case '48afa22a-bb54-4da9-a404-c803116768c5':
      return '対象物認識'
    default:
      return kind
  }
}

/** ML Pipelineのステータスの和名変換 */
export const convertTransferStatusWord = (
  transactionStatus: string
): string => {
  switch (transactionStatus) {
    case 'Transferred':
      return '転送済み'
    case 'Waiting':
      return '転送待ち'
    case 'NotTransfer':
      return '未転送'
    case 'Failed':
      return '転送失敗'
    case 'NotApplicable':
      return '対象外'
    default:
      console.error(`Unknown transfer status: ${transactionStatus}`)
      return ''
  }
}

/** ユーザーグループ種別定義 */
export const USER_GROUP_KIND_LIST = [
  {
    kind: 'UserGroup',
    name: 'カスタマーデータ',
  },
  {
    kind: 'SharedUserGroup',
    name: '共有データ',
  },
]

/** ユーザーグループ種別定義 for uuid */
export const getUserGroupKind = (customClaims: CustomClaims) => {
  return [
    {
      id: customClaims.userGroupId,
      name: 'カスタマーデータ',
    },
    {
      id: customClaims.sharedList[0],
      name: '共有データ',
    },
  ]
}

/** ユーザーグループ種別一覧の取得 */
export const getUserGroupKindList = (sharedList?: string[]) =>
  sharedList && sharedList.length > 0
    ? USER_GROUP_KIND_LIST
    : USER_GROUP_KIND_LIST.slice(0, 1)

/** 対象のデータが存在しないステータスの場合はhomeに戻る */
export const handleResourceNotFound = (
  subState: string,
  history: H.History
): void => {
  switch (subState) {
    case 'NotFoundProcessed':
    case 'Failed':
      history.replace('/')
      break
    default:
      return
  }
}

/** チップのカラーコードを返す */
export const getChipColor = (key: string, customClaims: CustomClaims) => {
  switch (key) {
    case 'カスタマーデータ':
    case customClaims.userGroupId:
      return '#D9E5FF'
    case '共有データ':
    case customClaims.sharedList[0]:
      return '#ffdab9'
    default:
      ''
  }
}

/** モデルの許可拡張子を返す */
export const getAllowedModelFileType = (type: string) => {
  switch (type) {
    case 'ObjectRecognition':
    case 'ObjectClassification':
      return '.pth,.onnx'
    default:
      return '.onnx'
  }
}
