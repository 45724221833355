import React from 'react'
import Grid from '@mui/material/Grid'

import { GroupedDataCard } from 'views/components/molecules/groupedDataCard'

import { GroupedDataCardListProps } from './types'

export const GroupedDataCardList: React.FC<GroupedDataCardListProps> = (
  props: GroupedDataCardListProps
) => {
  return (
    <Grid container spacing={2}>
      {props.groupedDataList.map((groupedData, index) => {
        return (
          <Grid item xs={12} key={index}>
            <GroupedDataCard
              groupedData={groupedData}
              onClickCard={props.onClickCard}
              onClickDownLoad={props.onClickDownLoad}
              data-testid={`grouped-data-${groupedData.groupedDataId}-card`}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
