import { Timestamp } from 'state/firebase'

export const SceneCameraListActionType = {
  CLEAR_SCENE_CAMERA_LIST: 'CLEAR_SCENE_CAMERA_LIST',
  SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_LIST:
    'SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_LIST',
  SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT:
    'SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT',
  SET_SCENE_CAMERA_LIST: 'SET_SCENE_CAMERA_LIST',
  SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION:
    'SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION',
  CLEAR_CREATE_SCENE_CAMERA_DIALOG_STATE:
    'CLEAR_CREATE_SCENE_CAMERA_DIALOG_STATE',
  CLEAR_SCENE_CAMERA_LIST_STATE: 'CLEAR_SCENE_CAMERA_LIST_STATE',
  CLEAR_SCENE_CAMERA_STATE: 'CLEAR_SCENE_CAMERA_STATE',
  SET_IN_PROGRESS_FOR_SCENE_CAMERA_LIST:
    'SET_IN_PROGRESS_FOR_SCENE_CAMERA_LIST',
} as const

export interface SceneCamera {
  id: string
  name: string
  overview: string
  createdAt: Timestamp
  createdBy: string
}

export interface SceneCameraListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface SceneCameraListDomainData {
  currentSceneCameraList: SceneCamera[]
  sceneCameraIds: string[]
  currentSceneCameraListSnapshot?: () => void
  sceneCameraListDisplayCondition: SceneCameraListDisplayCondition
}

export interface SceneCameraListAppState {
  inProgress: boolean
}

export interface SceneCameraListState {
  domainData: SceneCameraListDomainData
  appState: SceneCameraListAppState
}
