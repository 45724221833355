import { doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import {
  Algorithm,
  AlgorithmStructure,
  AlgorithmStructureVersion,
  InferenceAlgorithmVersion as InferenceAlgorithmVersionForDomain,
  TrainingAlgorithmVersion,
} from 'state/app/domainData'
import {
  BaseInferenceContainerImageDocument,
  ContainerImage,
  InferenceAlgorithmVersion,
} from 'state/ducks/build/types'
import {
  getAlgorithmStructureRelationsCollection,
  getAlgorithmStructuresCollection,
  getAlgorithmStructuresVersionCollection,
} from 'state/firebase'

import { AlgorithmDocument } from 'utils/fireStore/algorithm/types'
import { DocumentVersion } from 'utils/fireStore/common/types'
import { InferenceAlgorithmVersionDocument } from 'utils/fireStore/inferenceAlgorithmVersion/types'
import { TrainingAlgorithmVersionDocument } from 'utils/fireStore/trainingAlgorithmVersion/types'

// NOTE: 下のコメントアウトしている部分で使用しているためコメントアウトして残す
/** バージョンを数値化する */
// const converterAlgorithmVersion = (
//   major: number,
//   minor: number,
//   patch: number,
//   preRelease: number
// ): number => {
//   // プレリリースが数値に変換可能
//   if (
//     !isNaN(Number(major)) &&
//     !isNaN(Number(minor)) &&
//     !isNaN(Number(patch)) &&
//     !isNaN(Number(preRelease))
//   ) {
//     // 各 major/minor/pathバージョンにバイアスを適用し合計する
//     const majorShift = major * 1000 ** 3
//     const minorShift = minor * 1000 ** 2
//     const patchShift = patch * 1000
//     return majorShift + minorShift + patchShift + preRelease
//   } else {
//     // 数値に変換できない場合 0 として扱う
//     return 0
//   }
// }

/** アルゴリズムとアルゴリズムバージョンデータからオブジェクト生成 */
export const createAlgorithmData = async (
  mainData: AlgorithmDocument,
  inferenceAlgorithmInferenceCodeId: string,
  trainingAlgorithmInferenceCodeId: string,
  inferenceVersionDataList: InferenceAlgorithmVersionDocument[],
  trainingVersionDataList: TrainingAlgorithmVersionDocument[]
): Promise<Algorithm> => {
  const inferenceVersionListConvert: InferenceAlgorithmVersionForDomain[] = []
  inferenceVersionDataList.forEach((inferenceVersionData) => {
    inferenceVersionListConvert.push({
      inferenceAlgorithmVersion:
        inferenceVersionData['inference-algorithm-version'],
      algorithmVersion: {
        displayName: inferenceVersionData['algorithm-version']['display-name'],
        major: inferenceVersionData['algorithm-version']['major'],
        minor: inferenceVersionData['algorithm-version']['minor'],
        patch: inferenceVersionData['algorithm-version']['patch'],
        preRelease: inferenceVersionData['algorithm-version']['pre-release'],
      },
      inferenceCodeVersion: {
        displayName:
          inferenceVersionData['inference-code-version']['display-name'],
        major: inferenceVersionData['inference-code-version']['major'],
        minor: inferenceVersionData['inference-code-version']['minor'],
        patch: inferenceVersionData['inference-code-version']['patch'],
      },
      metadata: {
        remarks: inferenceVersionData['metadata']['remarks'],
      },
      releasedAt: inferenceVersionData['released-at'],
      availableVersion: {
        ['training-algorithm']: {
          lowerLimit: {
            displayName:
              inferenceVersionData['available-version']['training-algorithm'][
                'lower-limit'
              ]['display-name'],
            major:
              inferenceVersionData['available-version']['training-algorithm'][
                'lower-limit'
              ]['major'],
            minor:
              inferenceVersionData['available-version']['training-algorithm'][
                'lower-limit'
              ]['minor'],
            patch:
              inferenceVersionData['available-version']['training-algorithm'][
                'lower-limit'
              ]['patch'],
            preRelease:
              inferenceVersionData['available-version']['training-algorithm'][
                'lower-limit'
              ]['pre-release'],
          },
          upperLimit: {
            displayName:
              inferenceVersionData['available-version']['training-algorithm'][
                'upper-limit'
              ]['display-name'],
            major:
              inferenceVersionData['available-version']['training-algorithm'][
                'upper-limit'
              ]['major'],
            minor:
              inferenceVersionData['available-version']['training-algorithm'][
                'upper-limit'
              ]['minor'],
            patch:
              inferenceVersionData['available-version']['training-algorithm'][
                'upper-limit'
              ]['patch'],
            preRelease:
              inferenceVersionData['available-version']['training-algorithm'][
                'upper-limit'
              ]['pre-release'],
          },
        },
      },
    })
  })

  const trainingVersionDataListConvert: TrainingAlgorithmVersion[] =
    await Promise.all(
      trainingVersionDataList.map(async (trainingVersionData) => {
        const mlFramework: { [key: string]: string } = {}
        Object.entries(trainingVersionData['ml-framework']).map(
          ([key, value]) => {
            Object.assign(mlFramework, {
              [key]: value,
            })
          }
        )

        const algorithmStructureRelations = trainingVersionData[
          'algorithm-structure-relation-id'
        ]
          ? (
              await getDoc(
                doc(
                  getAlgorithmStructureRelationsCollection(),
                  trainingVersionData['algorithm-structure-relation-id']
                )
              )
            ).data()
          : undefined

        const algorithmStructureList = algorithmStructureRelations
          ? algorithmStructureRelations['algorithm-structure-list']
          : []

        const algorithmStructures: AlgorithmStructure[] = []

        await Promise.all(
          algorithmStructureList.map(
            async (algorithmStructure: {
              'algorithm-structure-id': string
              'algorithm-structure-version': DocumentVersion
            }) => {
              const algorithmStructureDoc = (
                await getDoc(
                  doc(
                    getAlgorithmStructuresCollection(),
                    algorithmStructure['algorithm-structure-id']
                  )
                )
              ).data()

              const algorithmStructureVersion = await getDocs(
                query(
                  getAlgorithmStructuresVersionCollection(
                    algorithmStructure['algorithm-structure-id']
                  ),
                  where(
                    'algorithm-structure-version.display-name',
                    '==',
                    algorithmStructure['algorithm-structure-version'][
                      'display-name'
                    ]
                  )
                )
              )

              const algorithmStructureVersionsConvert =
                algorithmStructureVersion.docs.map((doc) => doc.data())

              if (algorithmStructureVersionsConvert.length !== 0) {
                const structureVersion: AlgorithmStructureVersion = {
                  algorithmStructureId:
                    algorithmStructureVersionsConvert[0][
                      'algorithm-structure-id'
                    ],
                  algorithmStructureVersion: {
                    displayName:
                      algorithmStructureVersionsConvert[0][
                        'algorithm-structure-version'
                      ]['display-name'],
                    major:
                      algorithmStructureVersionsConvert[0][
                        'algorithm-structure-version'
                      ]['major'],
                    minor:
                      algorithmStructureVersionsConvert[0][
                        'algorithm-structure-version'
                      ]['minor'],
                    patch:
                      algorithmStructureVersionsConvert[0][
                        'algorithm-structure-version'
                      ]['patch'],
                  },
                  datasetTemplateId:
                    algorithmStructureVersionsConvert[0]['dataset-template-id'],
                  metadata: {
                    name: {
                      ja: algorithmStructureVersionsConvert[0].metadata.name.ja,
                      en: algorithmStructureVersionsConvert[0].metadata.name.en,
                    },
                    remarks: {
                      ja: algorithmStructureVersionsConvert[0].metadata.remarks
                        .ja,
                      en: algorithmStructureVersionsConvert[0].metadata.remarks
                        .en,
                    },
                  },
                  createdAt: algorithmStructureVersionsConvert[0]['created-at'],
                }

                if (algorithmStructures.length > 0) {
                  algorithmStructures.map((algorithmStructure, index) => {
                    if (
                      algorithmStructure.algorithmStructureId ===
                      structureVersion.algorithmStructureId
                    ) {
                      algorithmStructures[index] = {
                        ...algorithmStructure,
                        algorithmStructureVersions: [
                          ...algorithmStructure.algorithmStructureVersions,
                          structureVersion,
                        ],
                      }
                    } else if (index === algorithmStructures.length - 1) {
                      if (algorithmStructureDoc) {
                        algorithmStructures.push({
                          algorithmStructureId: algorithmStructureDoc
                            ? algorithmStructureDoc['algorithm-structure-id']
                            : '',
                          mlFramework: algorithmStructureDoc
                            ? algorithmStructureDoc['ml-framework']
                            : '',
                          algorithmStructureKind: algorithmStructureDoc
                            ? algorithmStructureDoc['algorithm-structure-kind']
                            : '',
                          algorithmStructureVersions: [structureVersion],
                          featureDataFormatId:
                            algorithmStructureDoc &&
                            algorithmStructureDoc['feature-data-format-id'],
                        })
                      }
                    }
                  })
                } else {
                  if (algorithmStructureDoc) {
                    algorithmStructures.push({
                      algorithmStructureId: algorithmStructureDoc
                        ? algorithmStructureDoc['algorithm-structure-id']
                        : '',
                      mlFramework: algorithmStructureDoc
                        ? algorithmStructureDoc['ml-framework']
                        : '',
                      algorithmStructureKind: algorithmStructureDoc
                        ? algorithmStructureDoc['algorithm-structure-kind']
                        : '',
                      algorithmStructureVersions: [structureVersion],
                      featureDataFormatId:
                        algorithmStructureDoc &&
                        algorithmStructureDoc['feature-data-format-id'],
                    })
                  }
                }
              }
            }
          )
        )

        return {
          trainingAlgorithmVersion:
            trainingVersionData['training-algorithm-version'],
          algorithmVersion: {
            displayName:
              trainingVersionData['algorithm-version']['display-name'],
            major: trainingVersionData['algorithm-version']['major'],
            minor: trainingVersionData['algorithm-version']['minor'],
            patch: trainingVersionData['algorithm-version']['patch'],
            preRelease: trainingVersionData['algorithm-version']['pre-release'],
          },
          mlFramework: mlFramework,
          inferenceCodeVersion: {
            displayName:
              trainingVersionData['inference-code-version']['display-name'],
            major: trainingVersionData['inference-code-version']['major'],
            minor: trainingVersionData['inference-code-version']['minor'],
            patch: trainingVersionData['inference-code-version']['patch'],
          },
          annotationFormatId: trainingVersionData['annotation-format-id'],
          annotationFormatVersion: {
            displayName:
              trainingVersionData['annotation-format-version']['display-name'],
            major: trainingVersionData['annotation-format-version']['major'],
            minor: trainingVersionData['annotation-format-version']['minor'],
            patch: trainingVersionData['annotation-format-version']['patch'],
          },
          algorithmStructureRelationId:
            trainingVersionData['algorithm-structure-relation-id'],
          metadata: {
            remarks: trainingVersionData['metadata']['remarks'],
          },
          releasedAt: trainingVersionData['released-at'],
          availableVersion: {
            ['trained-model']: {
              lowerLimit: {
                displayName:
                  trainingVersionData['available-version']['trained-model'][
                    'lower-limit'
                  ]['display-name'],
                major:
                  trainingVersionData['available-version']['trained-model'][
                    'lower-limit'
                  ]['major'],
                minor:
                  trainingVersionData['available-version']['trained-model'][
                    'lower-limit'
                  ]['minor'],
                patch:
                  trainingVersionData['available-version']['trained-model'][
                    'lower-limit'
                  ]['patch'],
                preRelease:
                  trainingVersionData['available-version']['trained-model'][
                    'lower-limit'
                  ]['pre-release'],
              },
              upperLimit: {
                displayName:
                  trainingVersionData['available-version']['trained-model'][
                    'upper-limit'
                  ]['display-name'],
                major:
                  trainingVersionData['available-version']['trained-model'][
                    'upper-limit'
                  ]['major'],
                minor:
                  trainingVersionData['available-version']['trained-model'][
                    'upper-limit'
                  ]['minor'],
                patch:
                  trainingVersionData['available-version']['trained-model'][
                    'upper-limit'
                  ]['patch'],
                preRelease:
                  trainingVersionData['available-version']['trained-model'][
                    'upper-limit'
                  ]['pre-release'],
              },
            },
          },
          algorithmStructures: algorithmStructures,
        }
      })
    )
  return {
    algorithmId: mainData['algorithm-id'],
    algorithmPurpose: mainData['algorithm-purpose'],
    metadata: mainData.metadata,
    inferenceAlgorithm: {
      inferenceCodeId: inferenceAlgorithmInferenceCodeId,
      inferenceAlgorithmVersions: inferenceVersionListConvert,
    },
    trainingAlgorithm: {
      inferenceCodeId: trainingAlgorithmInferenceCodeId,
      trainingAlgorithmVersions: trainingVersionDataListConvert,
    },
  }
}

/** アルゴリズムとアルゴリズムバージョンデータからオブジェクト生成 */
export const createInferenceVersionsData = (
  version: InferenceAlgorithmVersionForDomain,
  baseInferenceContainerImage: BaseInferenceContainerImageDocument,
  containerImages: ContainerImage[]
): InferenceAlgorithmVersion => {
  return {
    inferenceAlgorithmVersion: version.inferenceAlgorithmVersion,
    algorithmVersion: {
      displayName:
        baseInferenceContainerImage['inference-algorithm-version'][
          'display-name'
        ],
      major:
        baseInferenceContainerImage['inference-algorithm-version']['major'],
      minor:
        baseInferenceContainerImage['inference-algorithm-version']['minor'],
      patch:
        baseInferenceContainerImage['inference-algorithm-version']['patch'],
      preRelease:
        baseInferenceContainerImage['inference-algorithm-version'][
          'pre-release'
        ] ?? 99,
    },
    inferenceCodeVersion: {
      displayName:
        baseInferenceContainerImage['inference-code-version']?.[
          'display-name'
        ] ?? '',
      major:
        baseInferenceContainerImage['inference-code-version']?.['major'] ?? 0,
      minor:
        baseInferenceContainerImage['inference-code-version']?.['minor'] ?? 0,
      patch:
        baseInferenceContainerImage['inference-code-version']?.['patch'] ?? 0,
      build:
        baseInferenceContainerImage['inference-code-version']?.['build'] ?? 0,
    },
    releasedAt: version.releasedAt,
    metadata: {
      remarks: version.metadata.remarks.ja,
    },
    baseInferenceContainerImages: containerImages,
    containerInterfaceVersion: {
      displayName:
        baseInferenceContainerImage['container-interface-version'][
          'display-name'
        ],
      major:
        baseInferenceContainerImage['container-interface-version']['major'],
      minor:
        baseInferenceContainerImage['container-interface-version']['minor'],
      patch:
        baseInferenceContainerImage['container-interface-version']['patch'],
    },
  }
}

/** アルゴリズムとアルゴリズムバージョンデータからオブジェクト生成 */
// export const createInferenceAlgorithmVersionData = (
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   algorithm: { [x: string]: any },
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   version: { [x: string]: any },
//   versionIf: string
// ): InferenceAlgorithmVersion => {
//   return {
//     // algorithmId: algorithm['algorithm-id'],
//     // relatedAlgorithm: algorithm['related-algorithm']
//     //   ? algorithm['related-algorithm'].map(
//     //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     //       (relatedAlgorithm: { [x: string]: any }) => {
//     //         return {
//     //           algorithmId: relatedAlgorithm['algorithm-id'],
//     //           mlProcessKind: relatedAlgorithm['ml-process-kind'],
//     //         }
//     //       }
//     //     )
//     //   : undefined,
//     // metadata: algorithm['metadata']
//     //   ? {
//     //       name: algorithm['metadata']['name']
//     //         ? algorithm['metadata']['name']['ja']
//     //         : '',
//     //       remarks: algorithm['metadata']['remarks']
//     //         ? algorithm['metadata']['remarks']['ja']
//     //         : '',
//     //     }
//     //   : undefined,
//     // version: {
//     //   algorithmId: version['algorithm-id'],
//     //   version: version['version']
//     //     ? {
//     //         displayName: version['version']['display-name'],
//     //         major: version['version']['major'],
//     //         minor: version['version']['minor'],
//     //         patch: version['version']['patch'],
//     //         preRelease: version['version']['pre-release'],
//     //       }
//     //     : undefined,
//     //   remarks: version['remarks'] ? version['remarks']['ja'] : undefined,
//     //   releasedAt: version['released-at'],
//     //   availableVersion: version['available-version']
//     //     ? {
//     //         lowerLimit: version['available-version']['lower-limit']
//     //           ? {
//     //               displayName: version['version']['display-name'],
//     //               major: version['version']['major'],
//     //               minor: version['version']['minor'],
//     //               patch: version['version']['patch'],
//     //               preRelease: version['version']['pre-release'],
//     //             }
//     //           : undefined,
//     //         upperLimit: version['available-version']['upper-limit']
//     //           ? {
//     //               displayName: version['version']['display-name'],
//     //               major: version['version']['major'],
//     //               minor: version['version']['minor'],
//     //               patch: version['version']['patch'],
//     //               preRelease: version['version']['pre-release'],
//     //             }
//     //           : undefined,
//     //       }
//     //     : undefined,
//     // },
//     // sortVersionNumber: version['version']
//     //   ? converterAlgorithmVersion(
//     //       version['version']['major'],
//     //       version['version']['minor'],
//     //       version['version']['patch'],
//     //       version['version']['pre-release']
//     //     )
//     //   : 0,
//     // containerIfVersion: versionIf,
//   }
// }
