import React, { useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { State } from 'state/store'
import { accountEntryOperations } from 'state/ducks/accountEntry/operations'
import {
  AccountEntryAction,
  accountEntryActions,
} from 'state/ducks/accountEntry/actions'
import {
  CustomTrainingPageParagraph,
  GlobalLoading,
  Toast,
  showToast,
  CommonCompleteDialog,
} from 'views/components'
import { List, ListItem, ListItemText } from '@mui/material'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.accountEntryState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, AccountEntryAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** ユーザー一覧取得 */
  getAccountGroupList: () =>
    dispatch(accountEntryOperations.getAccountGroupList()),
  /** アカウント登録API */
  createAccount: (
    accountGroupId: string,
    mailAddress: string,
    firstName: string,
    familyName: string,
    accountRole: string,
    firstPassword: string,
    firstPasswordSecondTime: string
  ) =>
    dispatch(
      accountEntryOperations.createAccount(
        accountGroupId,
        mailAddress,
        firstName,
        familyName,
        accountRole,
        firstPassword,
        firstPasswordSecondTime
      )
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(accountEntryActions.setToastInfo(undefined)),
  /** アカウントの作成の成功可否 */
  setAccountEntryResult: (result: boolean) =>
    dispatch(accountEntryActions.setAccountEntryResult(result)),
  /** Stateのクリア */
  clearAccountEntryState: () =>
    dispatch(accountEntryActions.clearAccountEntryState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const FOOTER_HEIGHT = 100

const ACCOUNT_ROLE_LIST = [
  {
    id: 'user',
    name: '一般ユーザー',
  },
  {
    id: 'admin',
    name: '管理者',
  },
]

const useStyles = makeStyles()((theme) => ({
  pageTitle: {
    width: '100%',
    height: theme.spacing(4),
  },
  contentScroll: {
    width: '100%',
    height: 'calc(100% - 132px)', // FOOTER_HEIGHT+ページタイトルの高さ
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  accountEntryContentWrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  entryButton: {
    width: '100%',
  },
  accountEntryContent: {
    width: '100%',
  },
  accountPropertyTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  accountPropertyTitleSmall: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
  },
  stepContainer: {
    overflow: 'hidden',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  accountNameWrapper: {
    width: '100%',
    display: 'flex',
    gap: '2em',
    justifyContent: 'space-between',
  },
  accountNamePartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '50%',
  },
  accountFirstName: {
    width: '100%',
  },
  accountFamilyName: {
    width: '100%',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
}))

const AccountEntry: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()

  useEffect(() => {
    props.getAccountGroupList()
    return () => {
      props.clearAccountEntryState()
    }
  }, [])

  /** 選択されたアカウントグループ */
  const [accountGroupId, setAccountGroupId] = useState<string>('')
  /** 入力されたメールアドレス */
  const [emailAddress, setEmailAddress] = useState<string>('')
  /** 入力された名 */
  const [accountFirstName, setAccountFirstName] = useState<string>('')
  /** 入力された姓 */
  const [accountFamilyName, setAccountFamilyName] = useState<string>('')
  /** 選択されたロール */
  const [selectedRole, setSelectedRole] = useState<string>(
    ACCOUNT_ROLE_LIST[0].id
  )
  /** 入力された初期パスワード */
  const [firstPassword, setFirstPassword] = useState<string>('')
  /** 再入力の入力された初期パスワード */
  const [firstPasswordSecondTime, setFirstPasswordSecondTime] =
    useState<string>('')
  /** パスワードの表示状態 */
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(true)

  /** 全項目入力済みチェック */
  const isAllParamsCompleted = useMemo(() => {
    // メールアドレスの正規表現チェック
    const regex =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
    return accountGroupId &&
      selectedRole &&
      regex.test(emailAddress) &&
      accountFamilyName &&
      accountFirstName &&
      firstPassword.length >= 12 &&
      firstPasswordSecondTime.length >= 12 &&
      firstPassword === firstPasswordSecondTime
      ? false
      : true
  }, [
    accountGroupId,
    selectedRole,
    emailAddress,
    accountFamilyName,
    accountFirstName,
    firstPassword,
    firstPasswordSecondTime,
  ])

  useEffect(() => {
    /** ユーザ作成が完了したら初期化 */
    if (props.domainData.accountGroupList.length > 0) {
      setAccountGroupId(props.domainData.accountGroupList[0].accountGroupId)
    }
  }, [props.domainData.accountGroupList])
  useEffect(() => {
    /** ユーザ作成が完了したら初期化 */
    if (props.appState.accountEntryResult) {
      setAccountGroupId(props.domainData.accountGroupList[0].accountGroupId)
      setEmailAddress('')
      setAccountFirstName('')
      setAccountFamilyName('')
      setSelectedRole(ACCOUNT_ROLE_LIST[0].id)
      setFirstPassword('')
      setFirstPasswordSecondTime('')
    }
  }, [props.appState.accountEntryResult])

  /** トーストのコンポーネントを取得する */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** トースト情報があった場合、表示する */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  return (
    <>
      <div className={classes.stepContainer}>
        <Toast containerOptions={{ limit: 20 }}>
          <CommonCompleteDialog
            open={props.appState.accountEntryResult}
            value={'アカウントを作成しました。'}
            label={''}
            dialogText={''}
            handleClose={() => props.setAccountEntryResult(false)}
            data-testid='account-entry-success-dialog'
          />
          <h2 data-testid='account-entry-title' className={classes.pageTitle}>
            ユーザー登録
          </h2>
          <div className={classes.contentScroll}>
            <div className={classes.accountEntryContentWrapper}>
              <CustomTrainingPageParagraph>
                <FormControl
                  variant='outlined'
                  className={classes.accountEntryContent}
                >
                  <div className={classes.accountPropertyTitleSmall}>
                    <Typography>所属</Typography>
                  </div>
                  <Select
                    data-testid='select-account-group'
                    id='account-group-label-outlined'
                    value={accountGroupId}
                    onChange={(e: SelectChangeEvent<string>) =>
                      setAccountGroupId(e.target.value as string)
                    }
                  >
                    {props.domainData.accountGroupList.map((accountGroup) => {
                      return (
                        <MenuItem
                          data-testid={`select-${accountGroup.accountGroupId}`}
                          value={accountGroup.accountGroupId}
                          key={accountGroup.accountGroupId}
                        >
                          {accountGroup.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph>
                <div className={classes.accountPropertyTitle}>
                  <Typography>メールアドレス</Typography>
                </div>
                <TextField
                  className={classes.accountEntryContent}
                  value={emailAddress}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setEmailAddress(e.target.value)}
                  variant='outlined'
                  rows={1}
                  type={'email'}
                  data-testid={'mail-address-input'}
                />
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph>
                <div className={classes.accountNameWrapper}>
                  <div className={classes.accountNamePartWrapper}>
                    <div className={classes.accountPropertyTitleSmall}>
                      <Typography>姓</Typography>
                    </div>
                    <TextField
                      className={classes.accountFamilyName}
                      value={accountFamilyName}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => setAccountFamilyName(e.target.value)}
                      variant='outlined'
                      rows={1}
                      type={'text'}
                      data-testid={'family-name-input'}
                    />
                  </div>
                  <div className={classes.accountNamePartWrapper}>
                    <div className={classes.accountPropertyTitleSmall}>
                      <Typography>名</Typography>
                    </div>
                    <TextField
                      className={classes.accountFirstName}
                      value={accountFirstName}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => setAccountFirstName(e.target.value)}
                      variant='outlined'
                      rows={1}
                      type={'text'}
                      data-testid={'first-name-input'}
                    />
                  </div>
                </div>
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph>
                <FormControl
                  variant='outlined'
                  className={classes.accountEntryContent}
                >
                  <div className={classes.accountPropertyTitleSmall}>
                    <Typography>アカウントグループロール</Typography>
                  </div>
                  <Select
                    data-testid='select-role'
                    labelId='account-role-label'
                    id='account-role-label-outlined'
                    value={selectedRole}
                    onChange={(e: SelectChangeEvent<string>) =>
                      setSelectedRole(e.target.value as string)
                    }
                  >
                    {ACCOUNT_ROLE_LIST.map((item) => (
                      <MenuItem
                        data-testid={`select-${item.id}`}
                        value={item.id}
                        key={item.name}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph>
                <div className={classes.accountPropertyTitle}>
                  <Typography>初期パスワード</Typography>
                </div>
                <OutlinedInput
                  className={classes.accountEntryContent}
                  type={isPasswordVisible ? 'password' : 'text'}
                  value={firstPassword}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setFirstPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        edge='end'
                      >
                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  data-testid={'new-password-input'}
                />
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph>
                <div className={classes.accountPropertyTitle}>
                  <Typography>初期パスワード(再入力)</Typography>
                </div>
                <OutlinedInput
                  className={classes.accountEntryContent}
                  type={isPasswordVisible ? 'password' : 'text'}
                  value={firstPasswordSecondTime}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setFirstPasswordSecondTime(e.target.value)}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        edge='end'
                      >
                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  data-testid={'new-password-input'}
                />
              </CustomTrainingPageParagraph>
            </div>
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              <Button
                data-testid='account-entry-button'
                variant='contained'
                color='primary'
                disabled={isAllParamsCompleted}
                onClick={() =>
                  props.createAccount(
                    accountGroupId,
                    emailAddress,
                    accountFirstName,
                    accountFamilyName,
                    selectedRole,
                    firstPassword,
                    firstPasswordSecondTime
                  )
                }
                className={classes.entryButton}
              >
                {'登録'}
              </Button>
            </div>
          </div>
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

export const AccountEntryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountEntry))
