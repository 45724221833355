import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  CustomTraining,
  CustomTrainingListActionType,
  CustomTrainingDisplayCondition,
} from './types'

export type CustomTrainingListAction = ActionsUnion<
  typeof customTrainingListActions
>

export const customTrainingListActions = {
  setList: (
    currentCustomTrainingList: CustomTraining[]
  ): ActionWithPayload<
    'SET_CUSTOM_TRAINING_LIST',
    { currentCustomTrainingList: CustomTraining[] }
  > =>
    CreateAction(CustomTrainingListActionType.SET_CUSTOM_TRAINING_LIST, {
      currentCustomTrainingList,
    }),
  setListDisplayCondition: (
    customTrainingDisplayCondition?: CustomTrainingDisplayCondition
  ): ActionWithPayload<
    'SET_CUSTOM_TRAINING_DISPLAY_CONDITION',
    { customTrainingDisplayCondition?: CustomTrainingDisplayCondition }
  > =>
    CreateAction(
      CustomTrainingListActionType.SET_CUSTOM_TRAINING_DISPLAY_CONDITION,
      {
        customTrainingDisplayCondition,
      }
    ),
  clearDatasetDisplayCondition:
    (): Action<'CLEAR_CUSTOM_TRAINING_DISPLAY_CONDITION'> =>
      CreateAction(
        CustomTrainingListActionType.CLEAR_CUSTOM_TRAINING_DISPLAY_CONDITION
      ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      CustomTrainingListActionType.SET_IN_PROGRESS_FOR_CUSTOM_TRAINING_LIST,
      {
        inProgress,
      }
    ),
  setMLPipeLineIdList: (
    mlPipelineIds: string[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_IDS_FOR_CUSTOM_TRAINING_LIST',
    { mlPipelineIds: string[] }
  > =>
    CreateAction(
      CustomTrainingListActionType.SET_ML_PIPELINE_IDS_FOR_CUSTOM_TRAINING_LIST,
      {
        mlPipelineIds,
      }
    ),
  setCurrentCustomTrainingListSnapshot: (
    currentCustomTrainingListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_CUSTOM_TRAINING_LIST_SNAPSHOT',
    { currentCustomTrainingListSnapshot?: () => void }
  > =>
    CreateAction(
      CustomTrainingListActionType.SET_CURRENT_CUSTOM_TRAINING_LIST_SNAPSHOT,
      {
        currentCustomTrainingListSnapshot,
      }
    ),
  clearCustomTrainingListState:
    (): Action<'CLEAR_CUSTOM_TRAINING_LIST_STATE'> =>
      CreateAction(
        CustomTrainingListActionType.CLEAR_CUSTOM_TRAINING_LIST_STATE
      ),
}
