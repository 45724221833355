import React, { useRef, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { makeStyles } from 'tss-react/mui'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { State } from 'state/store'
import {
  BuildEntryAction,
  buildEntryActions,
  buildEntryOperations,
  MetaData,
  TrainedModelGroupListDisplayCondition,
  BuildEntryStateKindArray,
  TrainedModelGroupInfo,
  TrainedModelInfo,
  TrainedModelListDisplayCondition,
  InferenceAlgorithmVersion,
  ContainerImage,
  BuildEntryStateKind,
} from 'state/ducks/build'

import { isNull, isString, isUndefined } from 'utils/typeguard'
import {
  compareVersions,
  compareVersionsWithBuild,
  compareVersionsWithPreRelease,
} from 'utils/versions'
import {
  SelectableTable,
  CommonStepper,
  GlobalLoading,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  CustomTrainingPageParagraph,
  MetadataInput,
  ErrorMessage,
  AccordionLabel,
  MLPipelineCompleteDialog,
  ConfirmViewerDialog,
  CheckableTableHeader,
  CheckableTable,
  BreadcrumbsComponent,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { useTheme } from '@mui/material/styles'
import { getUserGroupKindList } from 'views/containers/utils'
import {
  BuildQueryParameters,
  getBuildQueryParameters,
} from 'views/containers/utils/queryParams'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.buildEntryState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, BuildEntryAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (buildEntryStateKind: BuildEntryStateKind) => {
    switch (buildEntryStateKind) {
      case 'TrainedModelGroup':
        dispatch(buildEntryOperations.getTrainedModelGroupList())
        break
      case 'EdgeImageUpload':
        dispatch(buildEntryOperations.getOutputFormatData())
        break
      default:
        break
    }
  },
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) => {
    if (currentStep >= BuildEntryStateKindArray.length - 1) {
      dispatch(buildEntryOperations.executeBuildEntry())
    } else {
      dispatch(buildEntryOperations.nextStep(currentStep))
    }
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) => {
    dispatch(buildEntryOperations.prevStep(currentStep))
  },
  /** 選んだアルゴリズムをセットする */
  setSelectedAlgorithmId: (algorithmId: string) => {
    dispatch(buildEntryOperations.setSelectedAlgorithmId(algorithmId))
  },
  /** 選択されたアルゴリズムに紐づいた配列を取得 */
  getTrainedModelGroupList: () =>
    dispatch(buildEntryOperations.getTrainedModelGroupList()),
  /** 選んだリストをセットする(ModelGroup) */
  setSelectedTrainedModelGroup: (data?: TrainedModelGroupInfo) =>
    dispatch(buildEntryOperations.setSelectedTrainedModelGroup(data)),
  /** 選んだリストをセットする(trainedModel) */
  setSelectedTrainedModel: (data?: TrainedModelInfo) =>
    dispatch(buildEntryOperations.setSelectedTrainedModel(data)),
  /** 選んだリストをセットする(Inference Algorithm) */
  setSelectedInferenceAlgorithmVersion: (
    inferenceAlgorithm?: InferenceAlgorithmVersion
  ) => {
    dispatch(
      buildEntryOperations.setSelectedInferenceAlgorithmVersion(
        inferenceAlgorithm
      )
    )
  },
  /** 入力したメタデータをセットする */
  setBuildMetaData: (data: MetaData) =>
    dispatch(buildEntryOperations.setBuildMetaData(data)),
  /** 入力したメタデータをセットする */
  setEdgeImageMetaData: (data: MetaData) =>
    dispatch(buildEntryOperations.setEdgeImageMetaData(data)),
  clearBuildEntryState: () =>
    dispatch(buildEntryActions.clearBuildEntryState()),
  /** モデルグループの表示条件の変更 */
  setTrainedModelGroupListDisplayCondition: (
    trainedModelGroupListDisplayCondition: TrainedModelGroupListDisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ) =>
    dispatch(
      buildEntryActions.setTrainedModelGroupListDisplayCondition(
        trainedModelGroupListDisplayCondition
      )
    ),
  /** モデルの表示条件の変更 */
  setTrainedModelListDisplayCondition: (
    trainedModelListDisplayCondition: TrainedModelListDisplayCondition
  ) =>
    dispatch(
      buildEntryActions.setTrainedModelListDisplayCondition(
        trainedModelListDisplayCondition
      )
    ),
  /** アドバンスド設定のチェックボックス押下 */
  setSelectedContainerArchitecture: (baseInferenceContainerImageId: string) =>
    dispatch(
      buildEntryOperations.setSelectedContainerArchitecture(
        baseInferenceContainerImageId
      )
    ),
  /** switch押下時処理 */
  setIsTransfer: (isTransfer: boolean) =>
    dispatch(buildEntryActions.setIsTransfer(isTransfer)),
  /** switch押下時処理 */
  setIsSystemEvaluation: (isSystemEvaluation: boolean) =>
    dispatch(buildEntryActions.setIsSystemEvaluation(isSystemEvaluation)),
  /** コンテナアーキテクチャを選択 */
  setContainerImageIdList: (baseInferenceContainerImageIdList: string[]) => {
    dispatch(buildEntryActions.setIsSelected(baseInferenceContainerImageIdList))
    dispatch(buildEntryActions.setEdgeImageSubStateSubState('Selected'))
  },
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  algorithmSelectBox: {
    width: '100%',
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  detailListItem: {
    padding: 0,
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
  cbList: {
    width: '100%',
  },
}))
/** ステップの名称 */
const STEP_NAMES = [
  'モデルグループ',
  'モデル',
  'アウトプット形式',
  'メタデータ',
  '確認',
]
/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** モデルグループテーブルのヘッダー */
const MODEL_GROUP_HEADERS: SelectableTableHeader[] = [
  {
    id: 'model-group-id',
    title: 'モデルグループ ID',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'model-group-name',
    title: 'モデルグループ名',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'trained-model-count',
    title: '登録モデル数',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-model-group-version-latest',
    title: '最新バージョン',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-model-group-name-latest',
    title: '最新モデル名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'updated-at',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-user-id',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** モデルテーブルのヘッダー */
const TRAINED_MODEL_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trained-model-id',
    title: 'モデル ID',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trained-model-version',
    title: 'バージョン',
    width: 180,
    sortable: true,
    position: 'center',
  },
  {
    id: 'trained-model-name',
    title: 'モデル名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'status',
    title: 'ステータス',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** アルゴリズムのテーブルの選択状態 */
const ALGORITHM_HEADERS: SelectableTableHeader[] = [
  {
    id: 'version',
    title: 'バージョン',
    width: 120,
    sortable: false,
    position: 'center',
  },
  {
    id: 'releasedAt',
    title: '登録日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: '備考',
    width: 400,
    sortable: false,
    position: 'center',
  },
  {
    id: 'container-if-version',
    title: 'コンテナ I/F バージョン',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'inference-code-version',
    title: '推論コードバージョン',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** アーキテクチャーのテーブルの選択状態 */
const CONTAINER_ARCHITECTURE_HEADERS: CheckableTableHeader[] = [
  {
    id: 'tags',
    title: 'Tags',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'osArch',
    title: 'OS/ARCH',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'baseImageId',
    title: 'Base Image ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

const BuildInfoConfirmView: React.FC<Props> = (props: Props) => {
  return (
    <Box component={Paper} mt={1} p={'24px 32px 32px'} width='100%'>
      <Typography>ビルド情報</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: '表示名',
            value: props.domainData.buildInfoMetadata?.name,
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.buildInfoMetadata?.remarks,
            readOnly: true,
            rowNum: 0,
            variant: 'standard',
          }}
          textFieldSpace={0}
          helperText=''
          data-testid='build-metadata'
        />
      </Box>
    </Box>
  )
}

const EdgeImageInfoConfirmView: React.FC<Props> = (props: Props) => {
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>エッジイメージ情報</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: '表示名',
            value: props.domainData.edgeImageInfoMetadata?.name,
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.edgeImageInfoMetadata?.remarks,
            readOnly: true,
            rowNum: 0,
            variant: 'standard',
          }}
          textFieldSpace={0}
          helperText=''
          data-testid='edge-image-metadata'
        />
      </Box>
    </Box>
  )
}

type ModelGroupConfirmViewProps = Props & {
  modelGroupToDetail: (
    modelGroup?: TrainedModelGroupInfo,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) =>
    | {
        ['データ種別']: string
        ['モデルグループID']: string
        ['登録モデル数']: string
        ['最新バージョン']: string
        ['最新モデル名']: string
        ['更新日時']: string
        ['作成日時']: string
        ['作成ユーザーID']: string
      }
    | undefined
}

const ModelGroupConfirmView: React.FC<ModelGroupConfirmViewProps> = (
  props: ModelGroupConfirmViewProps
) => {
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>モデルグループ</Typography>
      <Box mt={1}>
        <AccordionLabel
          label={
            props.domainData.selectedTrainedModelGroup?.trainedModelGroupName
          }
          details={props.modelGroupToDetail(
            props.domainData.selectedTrainedModelGroup,
            props.appState.trainedModelGroupListDisplayCondition
              .selectedUserGroupKind
          )}
          prefix={'-'}
          delimiter={':'}
        />
      </Box>
    </Box>
  )
}

type ModelConfirmViewProps = Props & {
  trainedModelToDetail: (
    trainedModel?: TrainedModelInfo,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) =>
    | {
        ['データ種別']: string
        ['モデルID']: string
        ['バージョン']: string
        ['ステータス']: string
      }
    | undefined
}

const ModelConfirmView: React.FC<ModelConfirmViewProps> = (
  props: ModelConfirmViewProps
) => {
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>モデル</Typography>
      <Box mt={1}>
        <AccordionLabel
          label={props.domainData.selectedTrainedModel?.trainedModelName}
          details={props.trainedModelToDetail(
            props.domainData.selectedTrainedModel,
            props.appState.trainedModelGroupListDisplayCondition
              .selectedUserGroupKind
          )}
          prefix={'-'}
          delimiter={':'}
        />
      </Box>
    </Box>
  )
}

type EdgeImageConfirmViewProps = Props & {
  selectedAlgorithmContainerImageList: ContainerImage[]
  selectedAlgorithmContainerImageRows: JSX.Element[][]
  selectedAlgorithmContainerImageIds: string[]
  inferenceAlgorithmVersionToDetail: (
    inferenceAlgorithmVersion?: InferenceAlgorithmVersion
  ) =>
    | {
        ['アルゴリズムID']: string | undefined
        ['アルゴリズムバージョン']: string
        ['登録日時']: string
        ['備考']: string
        ['コンテナ I/F バージョン']: string
      }
    | undefined
}

const EdgeImageConfirmView: React.FC<EdgeImageConfirmViewProps> = (
  props: EdgeImageConfirmViewProps
) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} mb={1} width='100%'>
      <Typography>エッジイメージ</Typography>
      {props.domainData.selectedTrainingAlgorithm?.algorithmId && (
        <Box mt={1}>
          <TextField
            id='algorithm'
            label='推論アルゴリズム名'
            className={classes.textField}
            InputProps={{
              readOnly: true,
            }}
            value={
              props.algorithms.find(
                (algorithm) =>
                  algorithm.algorithmId ===
                  props.domainData.selectedTrainingAlgorithm?.algorithmId
              )?.metadata.name.ja
            }
            variant='standard'
          />
        </Box>
      )}
      <Box mt={2}>
        <InputLabel shrink>バージョン</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={
              props.domainData.selectedInferenceAlgorithmVersion
                ?.algorithmVersion?.displayName
            }
            details={props.inferenceAlgorithmVersionToDetail(
              props.domainData.selectedInferenceAlgorithmVersion
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <InputLabel shrink>アドバンスド設定</InputLabel>
        <Box mt={1}>
          <Accordion>
            <AccordionDetails>
              <List className={classes.cbList}>
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  Container Architecture
                </Box>
                <CheckableTable
                  headers={CONTAINER_ARCHITECTURE_HEADERS}
                  fixedColumnNumber={0}
                  selectedRows={props.selectedAlgorithmContainerImageList.map(
                    (item) =>
                      props.domainData.selectedBaseInferenceContainerImageIds.includes(
                        item.baseInferenceContainerImageId
                      )
                  )}
                  page={0}
                  noEmptyRows={true}
                  disabled={true}
                  rows={props.selectedAlgorithmContainerImageRows ?? []}
                />
                <Box sx={{ color: globalTheme.palette.text.secondary }} mt={2}>
                  System Evaluation
                </Box>
                <Box display='flex' alignItems='center'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.domainData.isSystemEvaluation}
                        disabled
                        color='default'
                      />
                    }
                    label=''
                  />
                  <Box display='flex'>
                    {props.domainData.isSystemEvaluation === true
                      ? 'ON'
                      : 'OFF'}
                  </Box>
                </Box>
                <Box sx={{ color: globalTheme.palette.text.secondary }} mt={2}>
                  Transfer to Connected Service
                </Box>
                <Box display='flex' alignItems='center'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.domainData.isTransfer}
                        disabled
                        color='default'
                      />
                    }
                    label=''
                  />
                  <Box display='flex'>
                    {props.domainData.isTransfer === true ? 'ON' : 'OFF'}
                  </Box>
                </Box>
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  )
}

const BuildEntry: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()
  const search = useLocation().search
  const query = getBuildQueryParameters(search)

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return () => {
      props.clearBuildEntryState()
    }
  }, [])

  useEffect(() => {
    if (query['shared-user-group'] === 'true') {
      props.setTrainedModelGroupListDisplayCondition({
        ...props.appState.trainedModelGroupListDisplayCondition,
        selectedUserGroupKind: 'SharedUserGroup',
      })
    }
  }, [props.match.params])

  // /** ビルドの表示名更新処理 */
  const updateBuildMetaDataName = (name: string) => {
    props.setBuildMetaData({
      ...props.domainData.buildInfoMetadata,
      name,
    })
  }

  // /** ビルドの備考更新処理 */
  const updateBuildMetaDataRemarks = (remarks: string) => {
    props.setBuildMetaData({
      ...props.domainData.buildInfoMetadata,
      remarks,
    })
  }

  /** エッジイメージの表示名更新処理 */
  const updateEdgeImageMetaDataName = (name: string) => {
    props.setEdgeImageMetaData({
      ...props.domainData.edgeImageInfoMetadata,
      name,
    })
  }

  /** エッジイメージの備考更新処理 */
  const updateEdgeImageMetaDataRemarks = (remarks: string) => {
    props.setEdgeImageMetaData({
      ...props.domainData.edgeImageInfoMetadata,
      remarks,
    })
  }

  const [
    allAlgorithmContainerImageList,
    allAlgorithmContainerImageIds,
    allAlgorithmContainerImageRows,
  ] = useMemo(() => {
    if (!props.domainData.selectedInferenceAlgorithmVersion) return []
    const algorithm = props.domainData.selectedInferenceAlgorithmVersion

    let containerImageList: ContainerImage[] = []

    if (algorithm) {
      containerImageList = algorithm?.baseInferenceContainerImages
    }

    const tagSortedRowData = containerImageList.sort((prev, next) => {
      const tagStringPrev = prev.containerImageTags.join()
      const tagStringNext = next.containerImageTags.join()
      return tagStringPrev.localeCompare(tagStringNext)
    })

    const osArchSortedRowData = tagSortedRowData.sort((prev, next) => {
      const osArchStringPrev = `${prev.containerImagePlatform.os}/${prev.containerImagePlatform.architecture}`
      const osArchStringNext = `${next.containerImagePlatform.os}/${next.containerImagePlatform.architecture}`
      const tagStringPrev = prev.containerImageTags.join()
      const tagStringNext = next.containerImageTags.join()
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev < osArchStringNext
      ) {
        return -1
      }
      if (
        tagStringPrev === tagStringNext &&
        osArchStringPrev > osArchStringNext
      ) {
        return 1
      }
      // names must be equal
      return 0
    })

    const rowIds: string[] = []
    const rows: JSX.Element[][] = osArchSortedRowData.map<JSX.Element[]>(
      (item) => {
        rowIds.push(item.baseInferenceContainerImageId)
        return [
          <Tooltip
            key={'tags'}
            title={item.containerImageTags.join(', ')}
            placement='bottom'
          >
            <Typography key={'tags'}>
              {item.containerImageTags.join(', ')}
            </Typography>
          </Tooltip>,
          <Typography key={'osArch'}>
            {item.containerImagePlatform.os}/
            {item.containerImagePlatform.architecture}
          </Typography>,
          <Tooltip
            key={'baseImageId'}
            data-testid={`model-group-${item.baseInferenceContainerImageId}`}
            title={item.baseInferenceContainerImageId}
            placement='bottom'
          >
            <Typography>
              {item.baseInferenceContainerImageId.substring(0, 8)}
            </Typography>
          </Tooltip>,
        ]
      }
    )

    return [containerImageList, rowIds, rows]
  }, [props.domainData.selectedInferenceAlgorithmVersion])

  /**
   * アーキテクチャを全て選択にする
   */
  useEffect(() => {
    props.setContainerImageIdList(
      allAlgorithmContainerImageList?.map(
        (containerImage) => containerImage.baseInferenceContainerImageId
      ) ?? []
    )
  }, [
    allAlgorithmContainerImageList,
    props.domainData.selectedInferenceAlgorithmVersion,
  ])

  /** カレントのステップ */
  const currentStep = useMemo(
    () => BuildEntryStateKindArray.indexOf(props.appState.buildEntryStateKind),
    [props.appState.buildEntryStateKind]
  )

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.buildEntryStateKind) {
      case 'TrainedModelGroup':
        return (
          props.appState.buildEntrySubStateKind.trainedModelGroupSubState ===
            'Selected' &&
          props.domainData.selectedTrainedModelGroup &&
          props.domainData.selectedTrainedModelGroup?.trainedModels.length > 0
        )
      case 'TrainedModel':
        return (
          props.appState.buildEntrySubStateKind.trainedModelSubState ===
          'Selected'
        )
      case 'EdgeImageUpload':
        return (
          props.appState.buildEntrySubStateKind.edgeImageSubState === 'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.buildEntrySubStateKind.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    props.appState.buildEntryStateKind,
    props.appState.buildEntrySubStateKind,
  ])

  /** ステップ更新時のスクロール操作 */
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }, [currentStep])

  /** 各種一覧取得 */
  useEffect(() => {
    props.getParams(props.appState.buildEntryStateKind)
  }, [props.appState.buildEntryStateKind])

  /** アルゴリズム選択 */
  const setSelectedAlgorithmId = (id: string) => {
    props.setSelectedTrainedModelGroup(undefined)
    props.setSelectedAlgorithmId(id)
    props.getTrainedModelGroupList()
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  useEffect(() => {
    // クエリパラメータにアルゴリズムIDが含まれる場合は、初期値として設定
    const algorithmId = query['algorithm-id']
    if (algorithmId) {
      setSelectedAlgorithmId(algorithmId)
    } else if (
      props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
      ).length > 0 &&
      props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
      )[0].algorithmId
    ) {
      setSelectedAlgorithmId(
        props.algorithms.filter(
          (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
        )[0].algorithmId
      )
    }
  }, [props.algorithms])

  /** ビルド作成の失敗時メッセージ */
  const BuildEntryErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.buildEntrySubStateKind.executeSubState === 'ExecuteError'
    ) {
      errorMessages.push('モデルの送信に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  const {
    changeTableSortOrder,
    pageChange,
    trainedModelGroupsRows,
    selectedTrainedModelGroupIndex,
    handleChangeDisplayNumber,
    selectModelGroupTableRadio,
    trainedModelRows,
    selectedTrainedModelIndex,
    selectTrainedModelTableRadio,
    selectInferenceAlgorithmTableRadio,
    selectedInferenceAlgorithmVersionIndex,
    inferenceAlgorithmVersionRows,
    modelGroupToDetail,
    trainedModelToDetail,
    inferenceAlgorithmVersionToDetail,
  } = useTableActions(props, query)

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    const userGroupKindList = getUserGroupKindList(
      props.authedUser.auth.customClaims.sharedList
    )
    switch (props.appState.buildEntryStateKind) {
      case 'TrainedModelGroup':
        if (isUndefined(props.domainData.trainedModelGroups)) {
          return <></>
        }
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelListUserGroupKind'>データ種別</InputLabel>
                <Select
                  labelId='modelListUserGroupKind-label'
                  id='modelListUserGroupKind-outlined'
                  value={
                    props.appState.trainedModelGroupListDisplayCondition
                      .selectedUserGroupKind
                  }
                  onChange={(
                    e: SelectChangeEvent<'UserGroup' | 'SharedUserGroup'>
                  ) => {
                    props.setSelectedTrainedModelGroup(undefined)
                    props.setSelectedTrainedModel(undefined)
                    props.setTrainedModelGroupListDisplayCondition({
                      ...props.appState.trainedModelGroupListDisplayCondition,
                      selectedUserGroupKind: e.target.value as
                        | 'UserGroup'
                        | 'SharedUserGroup',
                    })
                  }}
                  label='Select User Group Kind'
                >
                  {userGroupKindList.map((kind) => (
                    <MenuItem
                      data-testid={kind.kind}
                      value={kind.kind}
                      key={kind.kind}
                    >
                      {kind.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>{' '}
            <CustomTrainingPageParagraph>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelListAlgorithm'>アルゴリズム</InputLabel>
                <Select
                  labelId='modelListAlgorithm-label'
                  id='modelListAlgorithm-outlined'
                  defaultValue={
                    query['algorithm-id']
                      ? query['algorithm-id'] ?? undefined
                      : props.algorithms.filter(
                          (algorithm) =>
                            algorithm.algorithmPurpose !== 'TemplateMatching'
                        )[0].algorithmId
                  }
                  value={
                    props.domainData.selectedTrainingAlgorithm?.algorithmId
                  }
                  onChange={(e: SelectChangeEvent<string>) =>
                    setSelectedAlgorithmId(e.target.value as string)
                  }
                  label='Select Algorithm'
                >
                  {props.algorithms
                    .filter(
                      (algorithm) =>
                        algorithm.algorithmPurpose !== 'TemplateMatching'
                    )
                    .map((algorithm) => {
                      return (
                        <MenuItem
                          data-testid={algorithm.algorithmId}
                          value={algorithm.algorithmId}
                          key={algorithm.algorithmId}
                        >
                          {algorithm.metadata.name.ja}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
            <SelectableTable
              displayNumber={
                props.appState.trainedModelGroupListDisplayCondition
                  .displayNumber
              }
              headers={MODEL_GROUP_HEADERS}
              rows={trainedModelGroupsRows}
              totalCount={
                props.appState.trainedModelGroupListDisplayCondition
                  .selectedUserGroupKind === 'SharedUserGroup'
                  ? props.domainData.trainedModelGroups.sharedUserGroup.length
                  : props.domainData.trainedModelGroups.userGroup.length
              }
              tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
              onChangeDisplayNumber={(displayNumber: number) =>
                handleChangeDisplayNumber(displayNumber)
              }
              selectedRowNumber={selectedTrainedModelGroupIndex}
              fixedColumnNumber={0}
              page={
                props.appState.trainedModelGroupListDisplayCondition.pageNumber
              }
              sortOrder={{
                key: props.appState.trainedModelGroupListDisplayCondition
                  .sortKey,
                order:
                  props.appState.trainedModelGroupListDisplayCondition
                    .sortOrder,
              }}
              onClickRadio={(row: number) => selectModelGroupTableRadio(row)}
              onClickOrderChange={(key: string) => changeTableSortOrder(key)}
              onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
            />
          </div>
        )
      case 'TrainedModel':
        if (isUndefined(props.domainData.selectedTrainedModelGroup)) {
          return <></>
        }

        return (
          <div className={classes.stepContainer}>
            <SelectableTable
              displayNumber={
                props.appState.trainedModelDisplayCondition.displayNumber
              }
              headers={TRAINED_MODEL_HEADERS}
              rows={trainedModelRows}
              totalCount={
                props.domainData.selectedTrainedModelGroup.trainedModels.length
              }
              tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
              onChangeDisplayNumber={(displayNumber: number) =>
                handleChangeDisplayNumber(displayNumber)
              }
              selectedRowNumber={selectedTrainedModelIndex}
              fixedColumnNumber={0}
              page={props.appState.trainedModelDisplayCondition.pageNumber}
              sortOrder={{
                key: props.appState.trainedModelDisplayCondition.sortKey,
                order: props.appState.trainedModelDisplayCondition.sortOrder,
              }}
              onClickRadio={(row: number) => selectTrainedModelTableRadio(row)}
              onClickOrderChange={(key: string) => changeTableSortOrder(key)}
              onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
            />
          </div>
        )

      case 'EdgeImageUpload':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph
              level={'part'}
              title={'エッジイメージ'}
            >
              <Box ml={2} mb={2}>
                <CustomTrainingPageParagraph
                  level={'part'}
                  title={'推論アルゴリズム'}
                >
                  {props.domainData.selectedTrainingAlgorithm?.algorithmId && (
                    <Box ml={2}>
                      <TextField
                        id='algorithm'
                        className={classes.textField}
                        variant='standard'
                        InputProps={{
                          readOnly: true,
                        }}
                        value={
                          props.algorithms.find(
                            (algorithm) =>
                              algorithm.algorithmId ===
                              props.domainData.selectedTrainingAlgorithm
                                ?.algorithmId
                          )?.metadata.name.ja
                        }
                      />
                    </Box>
                  )}
                </CustomTrainingPageParagraph>
                <CustomTrainingPageParagraph>
                  <SelectableTable
                    headers={ALGORITHM_HEADERS}
                    fixedColumnNumber={0}
                    selectedRowNumber={selectedInferenceAlgorithmVersionIndex}
                    page={0}
                    rows={inferenceAlgorithmVersionRows}
                    tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                    onClickRadio={(row: number) =>
                      selectInferenceAlgorithmTableRadio(row)
                    }
                  />
                </CustomTrainingPageParagraph>
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              <Box ml={2} mb={2}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant='body1' className={classes.labelText}>
                      アドバンスド設定
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List className={classes.cbList}>
                      <Box sx={{ color: globalTheme.palette.text.secondary }}>
                        Container Architecture
                      </Box>
                      <CheckableTable
                        headers={CONTAINER_ARCHITECTURE_HEADERS}
                        fixedColumnNumber={0}
                        selectedRows={allAlgorithmContainerImageList?.map(
                          (item) =>
                            props.domainData.selectedBaseInferenceContainerImageIds.includes(
                              item.baseInferenceContainerImageId
                            )
                        )}
                        page={0}
                        noEmptyRows={true}
                        tableHeight={TABLE_HEADER_HEIGHT + 10 * 45}
                        rows={allAlgorithmContainerImageRows ?? []}
                        onClickCheckbox={(row: number) => {
                          const idList = allAlgorithmContainerImageIds ?? []
                          props.setSelectedContainerArchitecture(idList[row])
                        }}
                      />
                      <Box
                        sx={{ color: globalTheme.palette.text.secondary }}
                        mt={2}
                      >
                        System Evaluation
                      </Box>
                      <Box display='flex' alignItems='center'>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.domainData.isSystemEvaluation}
                              onChange={(event) =>
                                props.setIsSystemEvaluation(
                                  event.target.checked
                                )
                              }
                              color='secondary'
                            />
                          }
                          label=''
                        />
                        <Box display='flex'>
                          {props.domainData.isSystemEvaluation === true
                            ? 'ON'
                            : 'OFF'}
                        </Box>
                      </Box>
                      <Box
                        sx={{ color: globalTheme.palette.text.secondary }}
                        mt={2}
                      >
                        Transfer to Connected Service
                      </Box>
                      <Box display='flex' alignItems='center'>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.domainData.isTransfer}
                              onChange={(event) =>
                                props.setIsTransfer(event.target.checked)
                              }
                              color='secondary'
                            />
                          }
                          label=''
                        />
                        <Box display='flex'>
                          {props.domainData.isTransfer === true ? 'ON' : 'OFF'}
                        </Box>
                      </Box>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'}>
              <CustomTrainingPageParagraph level={'part'} title={'ビルド情報'}>
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.buildInfoMetadata?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateBuildMetaDataName(e.target.value),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.buildInfoMetadata?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateBuildMetaDataRemarks(e.target.value),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'custom-training-input'}
                />
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'エッジイメージ情報'}
              >
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.edgeImageInfoMetadata?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateEdgeImageMetaDataName(e.target.value),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.edgeImageInfoMetadata?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      updateEdgeImageMetaDataRemarks(e.target.value),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'custom-model-input'}
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <BuildEntryErrorMessage {...props} />
            <BuildInfoConfirmView {...props} />
            <EdgeImageInfoConfirmView {...props} />
            <ModelGroupConfirmView
              {...props}
              modelGroupToDetail={modelGroupToDetail}
            />
            <ModelConfirmView
              {...props}
              trainedModelToDetail={trainedModelToDetail}
            />
            <EdgeImageConfirmView
              {...props}
              selectedAlgorithmContainerImageList={
                allAlgorithmContainerImageList ?? []
              }
              selectedAlgorithmContainerImageRows={
                allAlgorithmContainerImageRows ?? []
              }
              selectedAlgorithmContainerImageIds={
                allAlgorithmContainerImageIds ?? []
              }
              inferenceAlgorithmVersionToDetail={
                inferenceAlgorithmVersionToDetail
              }
            />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Box pl={7}>
          <BreadcrumbsComponent
            breadcrumbsPath={[
              {
                name: 'ビルド一覧',
                path: 'builds',
              },
              {
                name: 'ビルド開始',
                path: 'entry',
              },
            ]}
          />
        </Box>
        <div className={classes.head}>
          <div className={classes.flexAndBetween}>
            <h2 className={classes.pageTitle} data-testid='build-entry-title'>
              ビルド
            </h2>
            <div className={classes.confirmButton}>
              <Tooltip title='設定情報' placement='bottom'>
                <IconButton
                  onClick={() => {
                    setOpenConfirmDialog(true)
                  }}
                >
                  <ReceiptLongIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={classes.stepper}>
            <CommonStepper
              stepLabels={STEP_NAMES}
              activeStepIndex={currentStep}
            />
          </div>
        </div>
        <div className={classes.content} ref={contentRef}>
          {getStepContent(props)}
        </div>
        <div className={classes.footerButtons}>
          <div className={classes.footer}>
            {props.appState.buildEntryStateKind !== 'TrainedModelGroup' ? (
              <Button
                variant='contained'
                color='primary'
                disabled={false}
                onClick={() => props.prevStep(currentStep)}
                className={classes.leftButton}
              >
                {'戻る'}
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              data-testid='next-step'
              variant='contained'
              color='primary'
              disabled={!enableNextButton}
              onClick={() => props.nextStep(currentStep)}
              className={classes.rightButton}
            >
              {props.appState.buildEntryStateKind === 'ExecuteState'
                ? '開始'
                : '次へ'}
            </Button>
          </div>
        </div>
        <MLPipelineCompleteDialog
          open={
            !isUndefined(props.domainData.executionInfo.mlPipelineId) &&
            !isUndefined(props.domainData.executionInfo.buildStepId)
          }
          value={props.domainData.executionInfo.mlPipelineId ?? ''}
          secondValueItem={{
            label: 'ビルドステップ ID',
            value: props.domainData.executionInfo.buildStepId ?? '',
          }}
          handleClose={() => history.push('/builds')}
          label={'MLパイプラインID'}
          dialogText={'正常にビルドを開始しました。'}
          data-testid={'ml-pipeline-id'}
        />
        <ConfirmViewerDialog
          maxWidth='md'
          open={openConfirmDialog}
          message={
            <Box width='630px'>
              <BuildEntryErrorMessage {...props} />
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.buildInfoMetadata !== undefined &&
                      props.domainData.buildInfoMetadata?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <BuildInfoConfirmView {...props} />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.edgeImageInfoMetadata !== undefined &&
                      props.domainData.edgeImageInfoMetadata?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <EdgeImageInfoConfirmView {...props} />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.selectedTrainedModelGroup !== undefined
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <ModelGroupConfirmView
                  {...props}
                  modelGroupToDetail={modelGroupToDetail}
                />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.selectedTrainedModel !== undefined
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <ModelConfirmView
                  {...props}
                  trainedModelToDetail={trainedModelToDetail}
                />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.appState.buildEntrySubStateKind
                        .edgeImageSubState === 'Selected'
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <EdgeImageConfirmView
                  {...props}
                  selectedAlgorithmContainerImageList={
                    allAlgorithmContainerImageList ?? []
                  }
                  selectedAlgorithmContainerImageRows={
                    allAlgorithmContainerImageRows ?? []
                  }
                  selectedAlgorithmContainerImageIds={
                    allAlgorithmContainerImageIds ?? []
                  }
                  inferenceAlgorithmVersionToDetail={
                    inferenceAlgorithmVersionToDetail
                  }
                />
              </Box>
            </Box>
          }
          handleClose={() => setOpenConfirmDialog(false)}
        />
        <GlobalLoading open={props.appState.inProgress} />
      </div>
    </>
  )
}

export const BuildEntryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuildEntry))

const useTableActions = (props: Props, query: BuildQueryParameters) => {
  const globalTheme = useTheme()
  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')

  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }

  /** モデルグループのラジオボタンの選択された時に切り替える処理 */
  const selectModelGroupTableRadio = (row: number) => {
    const modelGroup = adjustModelGroupTableRow[row]
    props.setSelectedTrainedModelGroup(modelGroup)
    props.setSelectedTrainedModel(undefined)
  }

  /** モデルのラジオボタンの選択された時に切り替える処理 */
  const selectTrainedModelTableRadio = (row: number) => {
    const trainedModel = adjustTrainedModelTableRow[row]
    props.setSelectedTrainedModel(trainedModel)
  }

  /** 推論アルゴリズムのラジオボタン選択された時に切り替える処理 */
  const selectInferenceAlgorithmTableRadio = (row: number) => {
    if (!isUndefined(props.domainData.inferenceAlgorithmVersions)) {
      const inferenceAlgorithm =
        props.domainData.inferenceAlgorithmVersions[row]
      props.setSelectedInferenceAlgorithmVersion(inferenceAlgorithm)
    }
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    switch (props.appState.buildEntryStateKind) {
      case 'TrainedModelGroup':
        {
          const trainedModelGrouplistLength =
            props.appState.trainedModelGroupListDisplayCondition
              .selectedUserGroupKind === 'SharedUserGroup'
              ? props.domainData.trainedModelGroups.sharedUserGroup.length
              : props.domainData.trainedModelGroups.userGroup.length
          const maxPageNumber =
            Math.ceil(trainedModelGrouplistLength / displayNumber) - 1
          if (
            props.appState.trainedModelGroupListDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setTrainedModelGroupListDisplayCondition({
              ...props.appState.trainedModelGroupListDisplayCondition,
              displayNumber: displayNumber ?? 5,
            })
          } else {
            /** 表示数を変更 */
            props.setTrainedModelGroupListDisplayCondition({
              ...props.appState.trainedModelGroupListDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber ?? 5,
            })
          }
        }
        return
      case 'TrainedModel':
        if (isUndefined(props.domainData.selectedTrainedModelGroup)) {
          return
        }
        {
          const maxPageNumber =
            Math.ceil(
              props.domainData.selectedTrainedModelGroup.trainedModels.length /
                displayNumber
            ) - 1
          if (
            props.appState.trainedModelDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setTrainedModelListDisplayCondition({
              ...props.appState.trainedModelDisplayCondition,
              displayNumber: displayNumber ?? 5,
            })
          } else {
            /** 表示数を変更 */
            props.setTrainedModelListDisplayCondition({
              ...props.appState.trainedModelDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber ?? 5,
            })
          }
        }
        return
    }
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    switch (props.appState.buildEntryStateKind) {
      case 'TrainedModelGroup':
        /** ソートキー、ソートオーダーをセット */
        props.setTrainedModelGroupListDisplayCondition({
          ...props.appState.trainedModelGroupListDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.appState.trainedModelGroupListDisplayCondition.sortKey === key
              ? props.appState.trainedModelGroupListDisplayCondition
                  .sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.appState.trainedModelGroupListDisplayCondition.sortOrder,
        })
        break
      case 'TrainedModel':
        /** ソートキー、ソートオーダーをセット */
        props.setTrainedModelListDisplayCondition({
          ...props.appState.trainedModelDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.appState.trainedModelDisplayCondition.sortKey === key
              ? props.appState.trainedModelDisplayCondition.sortOrder === 'asc'
                ? 'desc'
                : 'asc'
              : props.appState.trainedModelDisplayCondition.sortOrder,
        })
        break
      default:
        break
    }
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    switch (props.appState.buildEntryStateKind) {
      case 'TrainedModelGroup':
        props.setTrainedModelGroupListDisplayCondition({
          ...props.appState.trainedModelGroupListDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'TrainedModel':
        props.setTrainedModelListDisplayCondition({
          ...props.appState.trainedModelDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      default:
        break
    }
  }

  /** モデルグループ詳細変換 */
  const modelGroupToDetail = (
    modelGroup?: TrainedModelGroupInfo,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) => {
    if (isUndefined(modelGroup)) return undefined
    return {
      ['データ種別']:
        userGroupKind === 'UserGroup' ? 'カスタマーデータ' : '共有データ',
      ['モデルグループID']: modelGroup.trainedModelGroupId,
      ['登録モデル数']: `${modelGroup.trainedModelCount}`,
      ['最新バージョン']: modelGroup.latestTrainedModelVersion,
      ['最新モデル名']: modelGroup.latestTrainedModelName,
      ['更新日時']: modelGroup.updatedAt
        ? formatDateTimeSec(modelGroup.updatedAt.toDate())
        : '',
      ['作成日時']: modelGroup.createdAt
        ? formatDateTimeSec(modelGroup.createdAt.toDate())
        : '',
      ['作成ユーザーID']: modelGroup.createdBy,
    }
  }

  /** モデル詳細変換 */
  const trainedModelToDetail = (
    trainedModel?: TrainedModelInfo,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) => {
    if (isUndefined(trainedModel)) return undefined
    return {
      ['データ種別']:
        userGroupKind === 'UserGroup' ? 'カスタマーデータ' : '共有データ',
      ['モデルID']: trainedModel.trainedModelId,
      ['バージョン']: trainedModel.trainedModelGroupVersion.displayName,
      ['ステータス']: trainedModel.transactionStatus,
    }
  }

  /** 推論アルゴリズム詳細変換 */
  const inferenceAlgorithmVersionToDetail = (
    inferenceAlgorithmVersion?: InferenceAlgorithmVersion
  ) => {
    if (isUndefined(inferenceAlgorithmVersion)) return undefined
    return {
      ['アルゴリズムID']:
        props.domainData.selectedTrainingAlgorithm?.algorithmId,
      ['アルゴリズムバージョン']: inferenceAlgorithmVersion.algorithmVersion
        ?.displayName
        ? inferenceAlgorithmVersion.algorithmVersion.displayName
        : '',
      ['登録日時']: inferenceAlgorithmVersion.releasedAt
        ? formatDateTimeSec(inferenceAlgorithmVersion.releasedAt.toDate())
        : '',
      ['備考']: inferenceAlgorithmVersion.metadata?.remarks
        ? inferenceAlgorithmVersion.metadata?.remarks
        : '',
      ['コンテナ I/F バージョン']:
        inferenceAlgorithmVersion.containerInterfaceVersion
          ? inferenceAlgorithmVersion.containerInterfaceVersion.displayName
          : '',
      ['推論コードバージョン']: inferenceAlgorithmVersion.inferenceCodeVersion
        ? inferenceAlgorithmVersion.inferenceCodeVersion.displayName
        : '',
    }
  }

  /** 表示対象のモデルグループデータ */
  const adjustModelGroupTableRow = useMemo(() => {
    if (!isUndefined(props.appState.trainedModelGroupListDisplayCondition)) {
      let newModelGroupArray: TrainedModelGroupInfo[] =
        props.appState.trainedModelGroupListDisplayCondition
          .selectedUserGroupKind === 'SharedUserGroup'
          ? props.domainData.trainedModelGroups.sharedUserGroup
          : props.domainData.trainedModelGroups.userGroup

      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.appState.trainedModelGroupListDisplayCondition.sortKey ===
        'updated-at'
      ) {
        newModelGroupArray = newModelGroupArray.sort((item, item2) => {
          return (
            ((item2.updatedAt ? item2.updatedAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.updatedAt ? item.updatedAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.appState.trainedModelGroupListDisplayCondition.sortOrder ===
            'asc'
              ? -1
              : 1)
          )
        })
      } else if (
        props.appState.trainedModelGroupListDisplayCondition.sortKey ===
        'created-at'
      ) {
        newModelGroupArray = newModelGroupArray.sort((item, item2) => {
          return (
            ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime() -
              (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
                .toDate()
                .getTime()) *
            (props.appState.trainedModelGroupListDisplayCondition.sortOrder ===
            'asc'
              ? -1
              : 1)
          )
        })
      } else {
        newModelGroupArray = newModelGroupArray.sort((item, item2) => {
          if (item.trainedModelGroupName < item2.trainedModelGroupName) {
            return props.appState.trainedModelGroupListDisplayCondition
              .sortOrder === 'asc'
              ? -1
              : 1
          }
          if (item.trainedModelGroupName > item2.trainedModelGroupName) {
            return props.appState.trainedModelGroupListDisplayCondition
              .sortOrder === 'asc'
              ? 1
              : -1
          }
          // names must be equal
          return 0
        })
      }
      /** 表示するモデルグループの整形 */
      return newModelGroupArray.slice(
        props.appState.trainedModelGroupListDisplayCondition.displayNumber *
          props.appState.trainedModelGroupListDisplayCondition.pageNumber,
        (props.appState.trainedModelGroupListDisplayCondition.pageNumber + 1) *
          props.appState.trainedModelGroupListDisplayCondition.displayNumber
      )
    }
    return []
  }, [
    props.appState.trainedModelGroupListDisplayCondition,
    props.domainData.trainedModelGroups,
  ])

  /** 表示対象のモデルデータ */
  const adjustTrainedModelTableRow = useMemo(() => {
    if (
      !isUndefined(props.appState.trainedModelDisplayCondition) &&
      !isUndefined(props.domainData.selectedTrainedModelGroup)
    ) {
      let newTrainedModelArray =
        props.domainData.selectedTrainedModelGroup.trainedModels
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.appState.trainedModelDisplayCondition.sortKey ===
        'trained-model-version'
      ) {
        newTrainedModelArray = newTrainedModelArray.sort((item, item2) => {
          return compareVersions(
            item.trainedModelGroupVersion,
            item2.trainedModelGroupVersion,
            props.appState.trainedModelDisplayCondition.sortOrder
          )
        })
      }
      /** 表示するベースモデルの整形 */
      return newTrainedModelArray.slice(
        props.appState.trainedModelDisplayCondition.displayNumber *
          props.appState.trainedModelDisplayCondition.pageNumber,
        (props.appState.trainedModelDisplayCondition.pageNumber + 1) *
          props.appState.trainedModelDisplayCondition.displayNumber
      )
    }
    return []
  }, [
    props.appState.trainedModelDisplayCondition,
    props.domainData.selectedTrainedModelGroup?.trainedModels,
  ])

  /** テーブルに表示モデルグループのJSXの２次元配列 */
  const trainedModelGroupsRows = useMemo(() => {
    const rowData = adjustModelGroupTableRow.map(
      (trainedModelGroups: TrainedModelGroupInfo) => {
        return {
          trainedModelGroupId: trainedModelGroups.trainedModelGroupId,
          trainedModelGroupName: trainedModelGroups.trainedModelGroupName,
          trainedModelCount: trainedModelGroups.trainedModelCount,
          latestTrainedModelVersion:
            trainedModelGroups.latestTrainedModelVersion,
          latestTrainedModelName: trainedModelGroups.latestTrainedModelName,
          updatedAt: trainedModelGroups.updatedAt
            ? formatDateTimeSec(trainedModelGroups.updatedAt.toDate())
            : '',
          createdAt: trainedModelGroups.createdAt
            ? formatDateTimeSec(trainedModelGroups.createdAt.toDate())
            : '',
          createdBy: trainedModelGroups.createdBy,
        }
      }
    )

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelGroupId' && isString(value)) {
          return (
            <Tooltip
              key={key}
              data-testid={`model-group-${value}`}
              title={value}
              placement='bottom'
            >
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key === 'latestVersion') {
          if (value && value !== '0') {
            return <Typography key={key}>{`v${value}`}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else {
          if (value || value === 0) {
            return (
              <Typography data-testid={`model-group-${value}`} key={key}>
                {value}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustModelGroupTableRow])

  /** テーブルに表示モデルのJSXの２次元配列 */
  const trainedModelRows = useMemo(() => {
    const rowData = adjustTrainedModelTableRow.map((trainedModel) => {
      return {
        trainedModelId: trainedModel.trainedModelId,
        trainedModelGroupVersion:
          trainedModel.trainedModelGroupVersion.displayName,
        trainedModelName: trainedModel.trainedModelName,
        transactionStatus: trainedModel.transactionStatus,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'trainedModelId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`model-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return (
            <Typography key={key}>
              {value || TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        }
      })
    )
  }, [adjustTrainedModelTableRow])

  /** テーブルに表示モデルのJSXの２次元配列 */
  const inferenceAlgorithmVersionRows = useMemo(() => {
    let newInferenceAlgorithmArray = props.domainData.inferenceAlgorithmVersions
    newInferenceAlgorithmArray = newInferenceAlgorithmArray.sort(
      (item, item2) => {
        return compareVersionsWithPreRelease(
          item.algorithmVersion,
          item2.algorithmVersion,
          'desc'
        )
      }
    )
    newInferenceAlgorithmArray = newInferenceAlgorithmArray.sort(
      (item, item2) => {
        if (
          item.inferenceAlgorithmVersion === item2.inferenceAlgorithmVersion
        ) {
          return compareVersions(
            item.containerInterfaceVersion,
            item2.containerInterfaceVersion,
            'desc'
          )
        }
        return 0
      }
    )

    newInferenceAlgorithmArray = newInferenceAlgorithmArray.sort(
      (item, item2) => {
        if (
          item.containerInterfaceVersion.displayName ===
          item2.containerInterfaceVersion.displayName
        ) {
          return compareVersionsWithBuild(
            item.inferenceCodeVersion,
            item2.inferenceCodeVersion,
            'desc'
          )
        }
        return 0
      }
    )

    const rowData = newInferenceAlgorithmArray.map((data) => {
      return {
        version: data.inferenceAlgorithmVersion,
        releasedAt: data.releasedAt
          ? formatDateTimeSec(data.releasedAt.toDate())
          : undefined,
        remarks: data.metadata?.remarks,
        containerInterfaceVersion: data.containerInterfaceVersion.displayName,
        inferenceCodeVersion: data.inferenceCodeVersion.displayName,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else {
          return <Typography key={key}>{value}</Typography>
        }
      })
    )
  }, [props.domainData.inferenceAlgorithmVersions])

  /** モデルグループのテーブルの選択状態 */
  const selectedTrainedModelGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedTrainedModelGroup)) return -1
    return adjustModelGroupTableRow.findIndex(
      (trainedModelGroup) =>
        trainedModelGroup.trainedModelGroupId ===
        props.domainData.selectedTrainedModelGroup?.trainedModelGroupId
    )
  }, [adjustModelGroupTableRow, props.domainData.selectedTrainedModelGroup])

  /** モデルのテーブルの選択状態 */
  const selectedTrainedModelIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedTrainedModel)) return -1
    return adjustTrainedModelTableRow.findIndex(
      (trainedModel) =>
        trainedModel.trainedModelId ===
        props.domainData.selectedTrainedModel?.trainedModelId
    )
  }, [adjustTrainedModelTableRow, props.domainData.selectedTrainedModel])

  /** 推論アルゴリズムテーブルの選択状態 */
  const selectedInferenceAlgorithmVersionIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedInferenceAlgorithmVersion))
      return -1

    return props.domainData.inferenceAlgorithmVersions.findIndex(
      (inferenceAlgorithmVersion) =>
        inferenceAlgorithmVersion.inferenceAlgorithmVersion ===
          props.domainData.selectedInferenceAlgorithmVersion
            ?.inferenceAlgorithmVersion &&
        inferenceAlgorithmVersion.containerInterfaceVersion.displayName ===
          props.domainData.selectedInferenceAlgorithmVersion
            ?.containerInterfaceVersion.displayName &&
        inferenceAlgorithmVersion.inferenceCodeVersion.displayName ===
          props.domainData.selectedInferenceAlgorithmVersion
            ?.inferenceCodeVersion.displayName
    )
  }, [
    props.domainData.inferenceAlgorithmVersions,
    props.domainData.selectedInferenceAlgorithmVersion,
  ])

  /** モデルグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustModelGroupTableRow) &&
      isUndefined(props.domainData.selectedTrainedModelGroup)
    ) {
      // クエリパラメータにモデルIDが含まれる場合は、初期値として設定
      const trainedModelGroupId = query['trained-model-group-id']
      if (!isNull(trainedModelGroupId)) {
        const trainedModelGroups =
          props.appState.trainedModelGroupListDisplayCondition
            .selectedUserGroupKind === 'SharedUserGroup'
            ? props.domainData.trainedModelGroups.sharedUserGroup
            : props.domainData.trainedModelGroups.userGroup
        const trainedModelGroup = trainedModelGroups.find(
          (trainedModelGroups: TrainedModelGroupInfo) =>
            trainedModelGroups.trainedModelGroupId === trainedModelGroupId
        )
        if (!isUndefined(trainedModelGroup)) {
          props.setSelectedTrainedModelGroup(trainedModelGroup)
          return
        }
      }
      const trainedModelGroup = adjustModelGroupTableRow[0]
      props.setSelectedTrainedModelGroup(trainedModelGroup)
    }
  }, [adjustModelGroupTableRow])

  /** モデルの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(adjustTrainedModelTableRow) &&
      !isUndefined(props.domainData.selectedTrainedModelGroup) &&
      isUndefined(props.domainData.selectedTrainedModel)
    ) {
      // クエリパラメータにモデルIDが含まれる場合は、初期値として設定
      const trainedModelId = query['trained-model-id']
      if (!isNull(trainedModelId)) {
        const trainedModel =
          props.domainData.selectedTrainedModelGroup.trainedModels.find(
            (trainedModel) => trainedModel.trainedModelId === trainedModelId
          )
        if (!isUndefined(trainedModel)) {
          props.setSelectedTrainedModel(trainedModel)
          return
        }
      }
      const trainedModel = adjustTrainedModelTableRow[0]
      props.setSelectedTrainedModel(trainedModel)
    }
  }, [adjustTrainedModelTableRow, props.domainData.selectedTrainedModel])

  /** 推論アルゴリズムの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(inferenceAlgorithmVersionRows) &&
      isUndefined(props.domainData.selectedInferenceAlgorithmVersion)
    ) {
      props.setSelectedInferenceAlgorithmVersion(
        props.domainData.inferenceAlgorithmVersions[0]
      )
    }
  }, [inferenceAlgorithmVersionRows])

  return {
    handleChangeSearchValue,
    tableSearchValue,
    changeTableSortOrder,
    pageChange,
    trainedModelGroupsRows,
    selectedTrainedModelGroupIndex,
    handleChangeDisplayNumber,
    selectModelGroupTableRadio,
    trainedModelRows,
    selectedTrainedModelIndex,
    selectTrainedModelTableRadio,
    modelGroupToDetail,
    trainedModelToDetail,
    selectInferenceAlgorithmTableRadio,
    selectedInferenceAlgorithmVersionIndex,
    inferenceAlgorithmVersionRows,
    inferenceAlgorithmVersionToDetail,
  }
}
