import { AccountDetailAction } from './actions'
import { AccountDetailActionType, AccountDetail } from './types'

const initialState: AccountDetail = {
  domainData: {
    accountInfo: {
      mail: '',
      phoneNumber: '',
      firstName: '',
      familyName: '',
      accountGroupName: '',
      accountGroupRole: 'user',
      language: '',
      locate: '',
      role: false,
      mfaGroupSetting: 'none',
      isMfa: false,
      uid: '',
      customerList: [],
    },
    customerList: [],
    customerListDisplayCondition: {
      sortKey: 'lastAccessed',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    inProgress: false,
    accountDeleteSubState: 'BeforeDelete',
    accountUpdateRoleSubState: 'BeforeUpdateRole',
    accountUpdateCustomerListSubState: 'BeforeUpdateCustomerList',
    customerListState: {
      nextPageSubState: 'Enable',
    },
    accountDataSubState: 'BeforeLoading',
  },
}

export const AccountDetailReducer = (
  state: AccountDetail = initialState,
  action: AccountDetailAction
): AccountDetail => {
  switch (action.type) {
    case AccountDetailActionType.SET_ACCOUNT_INFO:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountInfo: action.payload.data,
        },
      }
    case AccountDetailActionType.SET_CUSTOMER_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          customerList: action.payload.data,
        },
      }
    case AccountDetailActionType.SET_IN_PROGRESS_FOR_ACCOUNT_DETAIL:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case AccountDetailActionType.SET_CUSTOMER_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          customerListDisplayCondition:
            action.payload.customerListDisplayCondition,
        },
      }
    case AccountDetailActionType.SET_CUSTOMER_LIST_PAGING_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          customerListState: action.payload.customerListPageState,
        },
      }
    case AccountDetailActionType.SET_ACCOUNT_DELETE_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          accountDeleteSubState: action.payload.data,
        },
      }
    case AccountDetailActionType.SET_ACCOUNT_UPDATE_ROLE_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          accountUpdateRoleSubState: action.payload.data,
        },
      }
    case AccountDetailActionType.SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          accountUpdateCustomerListSubState: action.payload.data,
        },
      }
    case AccountDetailActionType.SET_UPDATE_ACCOUNT_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountInfo: {
            ...state.domainData.accountInfo,
            firstName: action.payload.data.firstName,
            familyName: action.payload.data.familyName,
          },
        },
      }
    case AccountDetailActionType.SET_UPDATE_ACCOUNT_GROUP_ROLE_FOR_ACCOUNT_DETAIL:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountInfo: {
            ...state.domainData.accountInfo,
            accountGroupRole: action.payload.data,
          },
        },
      }
    case AccountDetailActionType.UPDATE_ACCOUNT_CUSTOMER_RELATIONS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountInfo: {
            ...state.domainData.accountInfo,
            customerList: action.payload.data.map((customer) => customer),
          },
        },
      }
    case AccountDetailActionType.SET_ACCOUNT_DATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          accountDataSubState: action.payload.data,
        },
      }
    case AccountDetailActionType.CLEAR_ACCOUNT_DETAIL_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
