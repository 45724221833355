import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { getAxiosInstance } from 'state/utils'
import type { AxiosResponse } from 'axios'

import {
  GenerateRequestFunctionArgsType,
  Input3dObject,
  InputTexture,
  InputPlacement,
} from '.'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'

const timeoutLimitSeconds = 15

export const DatasetAugmentationApi = {
  uploadFile: (signed_url: string, file: File): Promise<AxiosResponse<void>> =>
    getAxiosInstance().upload(signed_url, file, {
      headers: { 'Content-Type': file.type },
    }),
  getSignedUrl: (
    aug3dObjectFiles: {
      fileType: string
      fileName: string
    }[]
  ): Promise<
    HttpsCallableResult<{ [fileName: string]: { id: string; url: string } }>
  > =>
    httpsCallable<
      {
        aug3dObjectFiles: {
          fileType: string
          fileName: string
        }[]
      },
      { [fileName: string]: { id: string; url: string } }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-3d-object-get-signed-url'
    )({
      aug3dObjectFiles,
    }),
  getTextureSignedUrl: (
    augTextureFiles: {
      fileType: string
      fileName: string
    }[]
  ): Promise<
    HttpsCallableResult<{ [fileName: string]: { id: string; url: string } }>
  > =>
    httpsCallable<
      {
        augTextureFiles: {
          fileType: string
          fileName: string
        }[]
      },
      { [fileName: string]: { id: string; url: string } }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-texture-get-signed-url'
    )({
      augTextureFiles,
    }),
  validatePlacement: (
    placementRevision: number,
    placementId: string,
    fileName: string
  ): Promise<HttpsCallableResult<{ isValid: boolean }>> =>
    httpsCallable<
      {
        placementRevision: number
        placementId: string
        fileName: string
      },
      { isValid: boolean }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-placement-validate'
    )({
      placementRevision,
      placementId,
      fileName,
    }),
  getPlacementSignedUrl: (augPlacementFile: {
    fileType: string
    fileName: string
  }): Promise<HttpsCallableResult<{ id: string; url: string }>> =>
    httpsCallable<
      {
        augPlacementFile: {
          fileType: string
          fileName: string
        }
      },
      { id: string; url: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-placement-get-signed-url'
    )({
      augPlacementFile,
    }),
  executeCadUpload: (
    aug3dObject: Input3dObject
  ): Promise<HttpsCallableResult<{ aug3dObjectId: string }>> =>
    httpsCallable<{ aug3dObject: Input3dObject }, { aug3dObjectId: string }>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-3d-object-firestore',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      aug3dObject,
    }),
  executeTextureUpload: (
    augTexture: InputTexture
  ): Promise<HttpsCallableResult<{ augTextureId: string }>> =>
    httpsCallable<{ augTexture: InputTexture }, { augTextureId: string }>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-texture-firestore',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      augTexture,
    }),
  executePlacementUpload: (
    augPlacement: InputPlacement
  ): Promise<HttpsCallableResult<{ augPlacementId: string }>> =>
    httpsCallable<{ augPlacement: InputPlacement }, { augPlacementId: string }>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-placement-firestore',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      augPlacement,
    }),
  executeDatasetAugmentation: (
    datasetAugmentationRequestParams: GenerateRequestFunctionArgsType
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'augmentation-execute',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      ...datasetAugmentationRequestParams,
    }),
}
