import { Timestamp } from 'state/firebase'
import { ResourceLoadingState, TransactionStatusKind } from 'state/utils/types'
import {
  Version,
  VersionWithBuild,
  VersionWithPreRelease,
} from 'types/StateTypes'

export const BuildDetailActionType = {
  SET_CURRENT_BUILD_DETAIL: 'SET_CURRENT_BUILD_DETAIL',
  SET_BUILD_DETAIL_STATE: 'SET_BUILD_DETAIL_STATE',
  SET_IN_PROGRESS_FOR_GETTING_BUILD_DETAIL:
    'SET_IN_PROGRESS_FOR_GETTING_BUILD_DETAIL',
  SET_IN_PROGRESS_FOR_GETTING_SYSTEM_EVALUATION:
    'SET_IN_PROGRESS_FOR_GETTING_SYSTEM_EVALUATION',
  SET_IN_PROGRESS_FOR_DOWNLOADING: 'SET_IN_PROGRESS_FOR_DOWNLOADING',
  CLEAR_CURRENT_BUILD_DETAIL: 'CLEAR_CURRENT_BUILD_DETAIL',
  SET_TOAST_INFO_FOR_BUILD_DETAIL: 'SET_TOAST_INFO_FOR_BUILD_DETAIL',
  SET_SYSTEM_EVALUATION_FILES: 'SET_SYSTEM_EVALUATION_FILES',
} as const

export type TransferStatus =
  | 'Transferred'
  | 'Waiting'
  | 'NotTransfer'
  | 'Failed'
  | 'NotApplicable'

export interface EdgeContainerImageForMlPipelineQuery {
  ['edge-container-image-id']: string
  ['edge-container-image-group-id']: string
  ['edge-container-image-group-version']: {
    ['display-name']: string
    ['major']: number
    ['minor']: number
    ['patch']: number
    ['build']: number
  }
  ['edge-container-image-platform']: {
    architecture: string
    os: string
  }
  ['edge-container-image-tags']: string[]
  // FIXME: 型が未定のため後々修正する
  ['edge-container-image-requirements']: { [x: string]: string }
  ['built-at']: Date
}

interface TrainingAlgorithm {
  algorithmId: string
  algorithmName: string
  trainingAlgorithmVersion: VersionWithPreRelease
}

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface TrainedModel {
  trainedModelGroupId: string
  trainedModelId: string
  trainedModelName: string
  isSharedUserGroupModel: boolean
}

export interface ContainerImage {
  baseInferenceContainerImageId: string
  containerImagePlatform: { os: string; architecture: string }
  containerImageTags: string[]
}

export interface EdgeContainerImage {
  edgeContainerImageId: string
  edgeContainerImagePlatform: { os: string; architecture: string }
  edgeContainerImageTags: string[]
}

export interface CurrentBuildDetail {
  mlPipelineId: string
  mlPipelineName: string
  mlPipelineRemarks: string
  mlPipelineStartedAt: Timestamp
  mlPipelineEndedAt: Timestamp
  trainingAlgorithm: TrainingAlgorithm
  progress: Progress
  buildEndedAt?: Timestamp
  transferEndedAt?: Timestamp
  transferStatus: TransferStatus
  trainedModel: TrainedModel
  isTransfer: boolean
  isSystemEvaluation: boolean
  containerInterfaceVersion: Version
  inferenceCodeVersion?: VersionWithBuild
  inferenceAlgorithmVersion: VersionWithPreRelease
  baseContainerImagelist: ContainerImage[]
  edgeImageContainerImageGroup?: {
    edgeImageContainerImageGroupId: string
    edgeImageContainerImageGroupVersion: Version
  }
  edgeContainerImagelist: EdgeContainerImage[]
  createdBy: string
}

export interface SystemEvaluationFile {
  fileName: string
  fileLink: string
  fileSize: number
  createdAt: Date
}

export interface BuildDetailDomainData {
  currentBuildDetail?: CurrentBuildDetail
  systemEvaluationFiles: SystemEvaluationFile[]
}

export interface CurrentBuildDetailState {
  mlPipelineDataState: ResourceLoadingState
  systemEvaluationFileSubState: ResourceLoadingState
}

export interface ToastInfo {
  targets: string[]
  title: string
  type: 'info' | 'warning' | 'error'
}

export interface BuildDetailAppState {
  isInProgressForDownloading: boolean
  isInProgressForGettingBuildDetail: boolean
  isInProgressForGettingSystemEvaluation: boolean
  buildDetailState: CurrentBuildDetailState
  toastInfo: ToastInfo | undefined
}

export interface BuildDetailState {
  domainData: BuildDetailDomainData
  appState: BuildDetailAppState
}

export interface BuildResult {
  name: string
  size: number
  createdAt: number
  fileNameForDownload: string
}
