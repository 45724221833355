import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  ExecutionDataItem,
  RobotDataAnalysisExtractedImagesActionType,
  RobotDataAnalysisExtractedImagesDisplayCondition,
} from './types'

export type RobotDataAnalysisExtractedImagesActions = ActionsUnion<
  typeof robotDataAnalysisExtractedImagesActions
>

export const robotDataAnalysisExtractedImagesActions = {
  setList: (
    executionDataList: ExecutionDataItem[]
  ): ActionWithPayload<
    'SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES',
    { executionDataList: ExecutionDataItem[] }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImagesActionType.SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES,
      {
        executionDataList,
      }
    ),
  setListDisplayCondition: (
    robotDataAnalysisExtractedImagesDisplayCondition?: RobotDataAnalysisExtractedImagesDisplayCondition
  ): ActionWithPayload<
    'SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION',
    {
      robotDataAnalysisExtractedImagesDisplayCondition?: RobotDataAnalysisExtractedImagesDisplayCondition
    }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImagesActionType.SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION,
      {
        robotDataAnalysisExtractedImagesDisplayCondition,
      }
    ),
  clearDatasetDisplayCondition:
    (): Action<'CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION'> =>
      CreateAction(
        RobotDataAnalysisExtractedImagesActionType.CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION
      ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES',
    { inProgress: boolean }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImagesActionType.SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES,
      {
        inProgress,
      }
    ),
  clearRobotDataAnalysisExtractedImagesState:
    (): Action<'CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_STATE'> =>
      CreateAction(
        RobotDataAnalysisExtractedImagesActionType.CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_STATE
      ),
}
