import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  Inference,
  InferenceListActionType,
  InferenceDisplayCondition,
} from './types'

export type InferenceListAction = ActionsUnion<typeof inferenceListActions>

export const inferenceListActions = {
  setList: (
    currentInferenceList: Inference[]
  ): ActionWithPayload<
    'SET_INFERENCE_LIST',
    { currentInferenceList: Inference[] }
  > =>
    CreateAction(InferenceListActionType.SET_INFERENCE_LIST, {
      currentInferenceList,
    }),
  setListDisplayCondition: (
    inferenceDisplayCondition?: InferenceDisplayCondition
  ): ActionWithPayload<
    'SET_INFERENCE_DISPLAY_CONDITION',
    { inferenceDisplayCondition?: InferenceDisplayCondition }
  > =>
    CreateAction(InferenceListActionType.SET_INFERENCE_DISPLAY_CONDITION, {
      inferenceDisplayCondition,
    }),
  clearDatasetDisplayCondition:
    (): Action<'CLEAR_INFERENCE_DISPLAY_CONDITION'> =>
      CreateAction(InferenceListActionType.CLEAR_INFERENCE_DISPLAY_CONDITION),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_INFERENCE_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(InferenceListActionType.SET_IN_PROGRESS_FOR_INFERENCE_LIST, {
      inProgress,
    }),
  setMLPipeLineIdList: (
    mlPipelineIds: string[]
  ): ActionWithPayload<
    'SET_ML_PIPELINE_IDS_FOR_INFERENCE_LIST',
    { mlPipelineIds: string[] }
  > =>
    CreateAction(
      InferenceListActionType.SET_ML_PIPELINE_IDS_FOR_INFERENCE_LIST,
      {
        mlPipelineIds,
      }
    ),
  clearInferenceListState: (): Action<'CLEAR_INFERENCE_LIST_STATE'> =>
    CreateAction(InferenceListActionType.CLEAR_INFERENCE_LIST_STATE),
  setCurrentInferenceListSnapshot: (
    currentInferenceListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_INFERENCE_LIST_SNAPSHOT',
    { currentInferenceListSnapshot?: () => void }
  > =>
    CreateAction(InferenceListActionType.SET_CURRENT_INFERENCE_LIST_SNAPSHOT, {
      currentInferenceListSnapshot,
    }),
}
