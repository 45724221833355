import { Timestamp } from 'state/firebase'
import { TransactionStatusKind } from 'state/utils/types'

export const DatasetAugmentationListActionType = {
  SET_DATASET_AUGMENTATION_LIST: 'SET_DATASET_AUGMENTATION_LIST',
  SET_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION:
    'SET_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION',
  CLEAR_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION:
    'CLEAR_DATASET_AUGMENTATION_LIST_DISPLAY_CONDITION',
  CLEAR_DATASET_AUGMENTATION_PAGING_STATE:
    'CLEAR_DATASET_AUGMENTATION_PAGING_STATE',
  SET_ML_PIPELINE_IDS_FOR_DATASET_AUGMENTATION_LIST:
    'SET_ML_PIPELINE_IDS_FOR_DATASET_AUGMENTATION_LIST',
  SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_LIST:
    'SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_LIST',
  SET_CURRENT_DATASET_AUGMENTATION_LIST_SNAPSHOT:
    'SET_CURRENT_DATASET_AUGMENTATION_LIST_SNAPSHOT',
  CLEAR_DATASET_AUGMENTATION_LIST_STATE:
    'CLEAR_DATASET_AUGMENTATION_LIST_STATE',
} as const

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

interface Dataset {
  datasetId: string
  datasetName: string
}

export interface DatasetAugmentation {
  mlPipelineId: string
  augmentationName: string
  progress: Progress
  startedAt: Timestamp
  endedAt?: Timestamp
  generatedDataset: Dataset
  annotationFormatKind: string
  accountId: string
}

export interface DatasetAugmentationListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface DatasetAugmentationListDomainData {
  currentDatasetAugmentationList: DatasetAugmentation[]
  datasetAugmentationListDisplayCondition: DatasetAugmentationListDisplayCondition
  mlPipelineIds: string[]
  currentDatasetAugmentationListSnapshot?: () => void
}

export interface DatasetAugmentationListAppState {
  inProgress: boolean
}

export interface DatasetAugmentationList {
  domainData: DatasetAugmentationListDomainData
  appState: DatasetAugmentationListAppState
}
