import { ModelGroupEntryAction } from './actions'
import { ModelGroupEntryActionType, ModelGroupEntryState } from './types'

const initialState: ModelGroupEntryState = {
  domainData: {
    selectedAlgorithm: undefined,
    selectedTrainedModelKind: undefined,
    classSets: [],
    selectedClassSet: undefined,
    classSetDisplayCondition: {
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'SharedUserGroup',
    },
    modelGroupMetaData: {
      modelGroupName: '',
      overView: undefined,
      modelGroupIcon: undefined,
      remarks: undefined,
    },
    executedModeGroupId: '',
  },
  appState: {
    modelGroupEntryStateKind: 'AlgorithmState',
    modelGroupEntrySubStateKind: {
      algorithmSubState: 'Unselected',
      classSetSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
    toastInfo: undefined,
  },
}

export const ModelGroupEntryReducer = (
  state: ModelGroupEntryState = initialState,
  action: ModelGroupEntryAction
): ModelGroupEntryState => {
  switch (action.type) {
    case ModelGroupEntryActionType.SET_SELECTED_ALGORITHM:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithm: action.payload.selectedAlgorithm,
        },
      }
    case ModelGroupEntryActionType.SET_SELECTED_MODEL_KIND:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainedModelKind: action.payload.selectedTrainedModelKind,
        },
      }
    case ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelGroupMetaData: {
            ...state.domainData.modelGroupMetaData,
            modelGroupName: action.payload.modelGroupName,
          },
        },
      }
    case ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_OVER_VIEW:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelGroupMetaData: {
            ...state.domainData.modelGroupMetaData,
            overView: action.payload.overView,
          },
        },
      }
    case ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_ICON:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelGroupMetaData: {
            ...state.domainData.modelGroupMetaData,
            modelGroupIcon: action.payload.modelGroupIcon,
          },
        },
      }
    case ModelGroupEntryActionType.SET_MODEL_GROUP_META_DATA_REMARKS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelGroupMetaData: {
            ...state.domainData.modelGroupMetaData,
            remarks: action.payload.remarks,
          },
        },
      }
    case ModelGroupEntryActionType.SET_MODEL_GROUP_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelGroupEntryStateKind: action.payload.modelGroupEntryStateKind,
        },
      }
    case ModelGroupEntryActionType.SET_ALGORITHM_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelGroupEntrySubStateKind: {
            ...state.appState.modelGroupEntrySubStateKind,
            algorithmSubState: action.payload.algorithmSubState,
          },
        },
      }
    case ModelGroupEntryActionType.SET_METADATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelGroupEntrySubStateKind: {
            ...state.appState.modelGroupEntrySubStateKind,
            metaDataSubState: action.payload.metadataSubState,
          },
        },
      }
    case ModelGroupEntryActionType.SET_EXECUTE_MODEL_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelGroupEntrySubStateKind: {
            ...state.appState.modelGroupEntrySubStateKind,
            executeSubState: action.payload.executeSubState,
          },
        },
      }
    case ModelGroupEntryActionType.SET_IN_PROGRESS_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ModelGroupEntryActionType.SET_TOAST_INFO_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case ModelGroupEntryActionType.SET_EXECUTE_MODEL_GROUP_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executedModeGroupId: action.payload.executedModeGroupId,
        },
      }
    case ModelGroupEntryActionType.CLEAR_MODEL_GROUP_ENTRY_STATE:
      return {
        ...initialState,
      }
    case ModelGroupEntryActionType.SET_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: action.payload.classSets,
        },
      }
    case ModelGroupEntryActionType.CLEAR_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSets: initialState.domainData.classSets,
        },
      }

    case ModelGroupEntryActionType.SET_SELECTED_CLASS_SET_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedClassSet: action.payload.classSet,
        },
      }
    case ModelGroupEntryActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: {
            ...initialState.domainData.classSetDisplayCondition,
          },
        },
      }
    case ModelGroupEntryActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          classSetDisplayCondition: action.payload.displayCondition,
        },
      }
    case ModelGroupEntryActionType.SET_CLASS_SET_SUB_STATE_FOR_MODEL_GROUP_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelGroupEntrySubStateKind: {
            ...state.appState.modelGroupEntrySubStateKind,
            classSetSubState: action.payload.classSetSubState,
          },
        },
      }
    default:
      return state
  }
}
