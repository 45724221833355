import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { FileRejection } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { State } from 'state/store'
import {
  Setting,
  CreateSettingAction,
  createSettingActions,
  createSettingOperations,
  SettingMetadata,
  SettingState,
} from 'state/ducks/createSetting'

import {
  FileSelectableDropzone,
  MetadataInput,
  Toast,
  showToast,
  CommonCompleteDialog,
  ErrorMessage,
  GlobalLoading,
} from 'views/components'
import Box from '@mui/material/Box'

// How to simplify the description of react-redux.
const mapStateToProps = (state: State) => ({
  ...state.pages.currentCreateSettingState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, CreateSettingAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) =>
    dispatch(createSettingOperations.nextStep(currentStep)),
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) =>
    dispatch(createSettingOperations.prevStep(currentStep)),
  /** セッティング作成 */
  createSetting: (
    setting: Setting,
    settingMetadata: SettingMetadata,
    settingGroupId?: string,
    algorithmId?: string
  ) =>
    dispatch(
      createSettingOperations.createSetting(
        setting,
        settingMetadata,
        settingGroupId,
        algorithmId
      )
    ),
  /** セッティングファイル追加 */
  setInputData: (file: File) =>
    dispatch(createSettingOperations.setInputData(file)),
  /** 入力したメタデータをセットする */
  setSettingMetaData: (data?: SettingMetadata) =>
    dispatch(createSettingActions.setSettingMetadata(data)),
  /** STEPを更新 */
  setSettingState: (data: SettingState) =>
    dispatch(createSettingActions.setSettingState(data)),
  /** 入力内容をすべてクリア */
  clearCreateSettingState: () =>
    dispatch(createSettingActions.clearCurrentSettingState()),
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps &
  DispatchProps &
  RouteComponentProps &
  RouteComponentProps & {
    mode?: 'page' | 'dialog'
    settingGroupId?: string
    algorithmId?: string
    handleCloseDialog?: (settingId?: string) => void
  }

const useStyles = makeStyles()((theme) => ({
  pageTitle: { width: '98%', margin: theme.spacing(1) },
  stepperDiv: {
    backgroundColor: '#fafafa',
    margin: theme.spacing(3),
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  cancelLink: {
    margin: theme.spacing(1),
    float: 'right',
    color: 'red',
  },
  annotationListDiv: {
    marginTop: theme.spacing(3),
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  fileNameForm: {
    width: '100%',
    height: '70px',
    marginBottom: theme.spacing(2),
  },
  invisible: {
    display: 'none',
  },
  fileNameTextField: {
    width: '100%',
  },
  buttonDiv: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  contentDiv: {
    clear: 'both',
  },
}))

const CreateSetting: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()

  useEffect(() => {
    return () => {
      props.clearCreateSettingState()
    }
  }, [])

  const showErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  const notifySettingErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  const SettingErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (props.appState.settingSubState.uploadSubState === 'DocumentError') {
      errorMessages.push('ドキュメントの作成に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }
  return (
    <Box>
      <Toast containerOptions={{ limit: 20 }}>
        <Button
          className={classes.cancelLink}
          onClick={() => props.handleCloseDialog && props.handleCloseDialog()}
        >
          <Typography>キャンセル</Typography>
        </Button>
        <Typography
          variant='h5'
          data-testid='createSettingTitle'
          className={classes.pageTitle}
        >
          Setting
        </Typography>
        <Box className={classes.stepperDiv}>
          <>
            <div className={classes.contentDiv} data-testid='settings'>
              <div className={classes.stepTitle}>
                <Typography>セッティングファイルを選択します。 </Typography>
              </div>
              <FileSelectableDropzone
                acceptFileOperation={(files: File[]) =>
                  props.setInputData(files[0])
                }
                rejectedFileOperation={(fileRejections: FileRejection[]) =>
                  notifySettingErrors(fileRejections)
                }
                fileType='application/json'
                // TODO: Settingのファイルサイズが決まり次第変更
                maxSize={5 * 1000 * 1000}
                data-testid={'inputSettingFileDropzone'}
              />
              <div className={classes.errorField}>
                <SettingErrorMessage {...props} />
              </div>
              <div
                className={
                  props.domainData.settingFile
                    ? classes.fileNameForm
                    : classes.invisible
                }
              >
                <TextField
                  className={classes.fileNameTextField}
                  variant='standard'
                  id='filename'
                  label='ファイル名'
                  value={props.domainData.settingFile?.name ?? '   '}
                  InputProps={{
                    readOnly: true,
                  }}
                  required
                />
              </div>
              <div>
                <div className={classes.stepTitle}>
                  <Typography>メタデータを入力します。</Typography>
                </div>
                <MetadataInput
                  nameProps={{
                    label: 'セッティング名',
                    value: props.domainData.settingMetadata?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (event) => {
                      props.setSettingMetaData({
                        ...props.domainData.settingMetadata,
                        name: event.target.value,
                      })
                    },
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.settingMetadata?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (event) => {
                      props.setSettingMetaData({
                        ...props.domainData.settingMetadata,
                        remarks: event.target.value,
                      })
                    },
                    rowNum: 0,
                  }}
                  data-testid={'setting-input'}
                  helperText=''
                />
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                variant='contained'
                color='primary'
                disabled={
                  props.appState.settingSubState.settingFileState !==
                    'Completed' ||
                  props.appState.settingSubState.metadataSubState !==
                    'InputRequired' ||
                  props.appState.settingSubState.uploadSubState === 'Completed'
                }
                onClick={() => {
                  if (
                    props.domainData.setting &&
                    props.domainData.settingMetadata
                  ) {
                    props.createSetting(
                      props.domainData.setting,
                      props.domainData.settingMetadata,
                      props.settingGroupId,
                      props.algorithmId
                    )
                  }
                }}
                className={classes.rightButton}
                data-testid={'settings-button-next'}
              >
                {'送信'}
              </Button>
            </div>
            <CommonCompleteDialog
              open={
                props.appState.settingSubState.uploadSubState === 'Completed' &&
                props.domainData.setting?.settingId !== ''
              }
              value={props.domainData.setting?.settingId ?? ''}
              handleClose={() =>
                props.handleCloseDialog &&
                props.handleCloseDialog(props.domainData.setting?.settingId)
              }
              label={'セッティングID'}
              dialogText={'正常にデータを登録しました。'}
              data-testid={'settingInputPreview'}
            />
          </>
        </Box>
      </Toast>
      <GlobalLoading open={props.appState.inProgress} />
    </Box>
  )
}

export const CreateSettingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateSetting))
