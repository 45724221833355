import { ModelListActions } from './actions'
import { ModelInfoList, ModelListActionType } from './types'

const initialState: ModelInfoList = {
  domainData: {
    trainedModelGroupId: '',
    trainedModelGroupName: '',
    currentModelList: [],
    modelListDisplayCondition: {
      searchValue: '',
      sortKey: 'modelGroupVersion',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    inProgress: false,
    modelGroupSubState: 'BeforeGetting',
  },
}

export const ModelListReducer = (
  state: ModelInfoList = initialState,
  action: ModelListActions
): ModelInfoList => {
  switch (action.type) {
    case ModelListActionType.SET_TRAINED_MODEL_GROUP_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelGroupId: action.payload.trainedModelGroupId,
        },
      }
    case ModelListActionType.SET_TRAINED_MODEL_GROUP_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelGroupName: action.payload.trainedModelGroupName,
        },
      }
    case ModelListActionType.SET_MODEL_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentModelList: action.payload.modelList,
        },
      }
    case ModelListActionType.CLEAR_MODEL_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentModelList: [],
        },
      }
    case ModelListActionType.SET_MODEL_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelListDisplayCondition: action.payload.modelListDisplayCondition,
        },
      }
    case ModelListActionType.SET_MODEL_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          modelGroupSubState: action.payload.modelGroupSubState,
        },
      }
    case ModelListActionType.SET_IN_PROGRESS_FOR_MODEL_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ModelListActionType.CLEAR_MODEL_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
