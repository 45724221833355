import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { decideEnvironment } from '../urls'

interface APIInterface {
  upload: (
    url: string,
    data: File,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<void>>
  get: (
    url: string,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<Blob>>
  post: (
    url: string,
    data: unknown,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<void>>
  delete: (
    url: string,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<void>>
}

export const getAxiosInstance = (
  config?: AxiosRequestConfig, // 呼び出し元での個別設定を行わないなら不要？
  envKeyword: string = decideEnvironment()
): APIInterface => {
  switch (envKeyword) {
    case 'local': {
      const axiosInstance = axios.create({
        ...config,
      })
      return {
        upload: axiosInstance.post,
        get: axiosInstance.get,
        post: axiosInstance.post,
        delete: (url: string, config?: AxiosRequestConfig) =>
          axiosInstance.delete(url, {
            // 存在しないファイルに対しても削除urlを発行してしまうため
            validateStatus: (status) =>
              (status >= 200 && status < 300) || status === 404,
            ...config,
          }),
      }
    }
    case 'dev':
    case 'stg':
    case 'prd':
    default: {
      const axiosInstance = axios.create(config)
      return {
        upload: axiosInstance.put,
        get: axiosInstance.get,
        post: axiosInstance.post,
        delete: (url: string, config?: AxiosRequestConfig) =>
          axiosInstance.delete(url, {
            validateStatus: (status) =>
              (status >= 200 && status < 300) || status === 404,
            ...config,
          }),
      }
    }
  }
}
