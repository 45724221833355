export const RobotDataAnalysisExtractedImagesActionType = {
  SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES:
    'SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES',
  SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION:
    'SET_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION',
  CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION:
    'CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_DISPLAY_CONDITION',
  CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_PAGING_STATE:
    'CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_PAGING_STATE',
  SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES:
    'SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES',
  CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_STATE:
    'CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGES_STATE',
} as const

export interface QueryConditions {
  executionStatus: string[]
  executionDateTo?: string
  executionDateFrom?: string
  modelGroupIdList: string[]
  executionIdList: string[]
  robotIdList: string[]
  confirmed: boolean
  objectRecognitionResults: boolean
}

export interface ExecutionStatus {
  success?: boolean
  fail?: boolean
  error?: boolean
}

export interface ExecutionDate {
  from?: string
  to?: string
}
export interface ModelGroup {
  modelGroupId: string
  modelGroupName: string
}

export type Confirmed = 'unConfirmed' | 'otherConfirmed' | 'selfConfirmed'

export interface ExecutionDataItem {
  confirmed: Confirmed
  trainingData: {
    id: string
    name: string
  }
  executionStatus: string
  executedAt: string
  modelGroups: ModelGroup[]
  executionId: string
  robotId: string
}

export interface RobotDataAnalysisExtractedImagesDisplayCondition {
  sortKey: keyof ExecutionDataItem
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
}

export interface RobotDataAnalysisExtractedImagesDomainData {
  executionDataList: ExecutionDataItem[]
  robotDataAnalysisExtractedImagesDisplayCondition: RobotDataAnalysisExtractedImagesDisplayCondition
}

export interface RobotDataAnalysisExtractedImagesAppState {
  inProgress: boolean
}

export interface RobotDataAnalysisExtractedImagesState {
  domainData: RobotDataAnalysisExtractedImagesDomainData
  appState: RobotDataAnalysisExtractedImagesAppState
}
