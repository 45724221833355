import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React from 'react'

import { InformationDialogProps } from './types'

export const InformationDialog: React.FC<InformationDialogProps> = (
  props: InformationDialogProps
) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={'sm'}>
      <DialogTitle>Info</DialogTitle>
      <div>
        <Table>
          <TableBody>
            {props.infoData.map((row, index) => (
              <TableRow key={index}>
                <TableCell align='left'>{row.item}</TableCell>
                <TableCell align='left'>{row.info}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Dialog>
  )
}
