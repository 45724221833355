import React from 'react'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { CustomTrainingPageParagraphProps } from './types'

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    backgroundColor: 'inherit',
  },
  title: {
    marginBottom: theme.spacing(2),
    whiteSpace: 'pre-wrap',
  },
  part: {
    fontSize: theme.typography.pxToRem(18),
  },
  chapter: {
    fontSize: theme.typography.pxToRem(16),
  },
  section: {
    fontSize: theme.typography.pxToRem(14),
  },
  item: {
    fontSize: theme.typography.pxToRem(12),
  },
}))

export const CustomTrainingPageParagraph: React.FC<
  CustomTrainingPageParagraphProps
> = (props: CustomTrainingPageParagraphProps) => {
  const { classes } = useStyles()

  const titleClass = () => {
    switch (props.level) {
      case 'part':
        return classes.part
      case 'chapter':
        return classes.chapter
      case 'section':
        return classes.section
      case 'item':
        return classes.item
      default:
        return classes.part
    }
  }

  return (
    <Paper elevation={0} className={classes.root}>
      {props.title && (
        <Typography variant='h4' className={clsx(classes.title, titleClass())}>
          {props.title}
        </Typography>
      )}
      {props.children}
    </Paper>
  )
}
