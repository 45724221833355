import { ModelGroupListActions } from './actions'
import { ModelGroupInfoListState, ModelGroupListActionType } from './types'

const initialState: ModelGroupInfoListState = {
  domainData: {
    currentModelGroupList: [],
    modelGroupListDisplayCondition: {
      searchValue: '',
      sortKey: 'updated-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
      selectedUserGroupKind: 'UserGroup',
    },
  },
  appState: {
    inProgress: false,
  },
}

export const ModelGroupListReducer = (
  state: ModelGroupInfoListState = initialState,
  action: ModelGroupListActions
): ModelGroupInfoListState => {
  switch (action.type) {
    case ModelGroupListActionType.SET_MODEL_GROUP_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentModelGroupList: action.payload.modelGroupList,
        },
      }
    case ModelGroupListActionType.CLEAR_MODEL_GROUP_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentModelGroupList: [],
        },
      }
    case ModelGroupListActionType.SET_MODEL_GROUP_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          modelGroupListDisplayCondition:
            action.payload.modelGroupListDisplayCondition,
        },
      }
    case ModelGroupListActionType.SET_IN_PROGRESS_FOR_MODEL_GROUP_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case ModelGroupListActionType.CLEAR_MODEL_GROUP_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
