import { AccountListActions } from './actions'
import { AccountInfoList, AccountListActionType } from './types'

const initialState: AccountInfoList = {
  domainData: {
    currentAccountList: [],
    accountGroupList: [],
    selectedAccountGroupId: '',
    accountListDisplayCondition: {
      searchValue: '',
      sortKey: 'registeredDate',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    inProgress: false,
    accountListState: {
      nextPageSubState: 'Enable',
    },
  },
}

export const AccountListReducer = (
  state: AccountInfoList = initialState,
  action: AccountListActions
): AccountInfoList => {
  switch (action.type) {
    case AccountListActionType.SET_ACCOUNT_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentAccountList: action.payload.accountList,
        },
      }
    case AccountListActionType.SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountGroupList: action.payload.accountGroupList,
        },
      }
    case AccountListActionType.SET_SELECTED_ACCOUNT_GROUP_ID_FOR_ACCOUNT_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAccountGroupId: action.payload.accountGroupId,
        },
      }
    case AccountListActionType.SET_ACCOUNT_LIST_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountListDisplayCondition:
            action.payload.accountListDisplayCondition,
        },
      }
    case AccountListActionType.SET_ACCOUNT_LIST_PAGING_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          accountListState: action.payload.accountListPageState,
        },
      }
    case AccountListActionType.SET_IN_PROGRESS_FOR_ACCOUNT_LIST:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case AccountListActionType.CLEAR_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountListDisplayCondition: {
            ...initialState.domainData.accountListDisplayCondition,
          },
        },
      }
    case AccountListActionType.CLEAR_ACCOUNT_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
