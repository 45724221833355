import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'

import {
  RobotDataAnalysisExtractedImageDetailActionType,
  ToastInfo,
  ExecutionData,
  InferenceResult,
  InferenceResultDisplayCondition,
} from './types'

export type RobotDataAnalysisExtractedImageDetailAction = ActionsUnion<
  typeof robotDataAnalysisExtractedImageDetailActions
>
export const robotDataAnalysisExtractedImageDetailActions = {
  setExecutionData: (
    executionData: ExecutionData
  ): ActionWithPayload<
    'SET_EXECUTION_DATA_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
    { executionData: ExecutionData }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImageDetailActionType.SET_EXECUTION_DATA_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL,
      {
        executionData,
      }
    ),
  setInferenceResults: (
    inferenceResults: InferenceResult[]
  ): ActionWithPayload<
    'SET_INFERENCE_RESULTS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
    { inferenceResults: InferenceResult[] }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImageDetailActionType.SET_INFERENCE_RESULTS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL,
      {
        inferenceResults,
      }
    ),
  setInferenceResultDisplayCondition: (
    inferenceResultDisplayCondition: InferenceResultDisplayCondition
  ): ActionWithPayload<
    'SET_INFERENCE_RESULT_DISPLAY_CONDITION_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
    { inferenceResultDisplayCondition: InferenceResultDisplayCondition }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImageDetailActionType.SET_INFERENCE_RESULT_DISPLAY_CONDITION_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL,
      {
        inferenceResultDisplayCondition,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImageDetailActionType.SET_TOAST_INFO_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL,
      {
        toastInfo,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(
      RobotDataAnalysisExtractedImageDetailActionType.SET_IN_PROGRESS_FOR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL,
      {
        inProgress,
      }
    ),
  clearRobotDataAnalysisExtractedImageDetailState:
    (): Action<'CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL_STATE'> =>
      CreateAction(
        RobotDataAnalysisExtractedImageDetailActionType.CLEAR_ROBOT_DATA_ANALYSIS_EXTRACTED_IMAGE_DETAIL_STATE
      ),
}
