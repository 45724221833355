import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'

import { CommonStepperProps } from './types'
import Box from '@mui/material/Box'

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: '#fafafa',
    '& > *': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  stepper: {
    clear: 'both',
    marginBottom: theme.spacing(2),
    '& > *': {
      backgroundColor: '#fafafa',
    },
  },
  stepLabel: {
    whiteSpace: 'pre-wrap',
  },
}))

export const CommonStepper: React.FC<CommonStepperProps> = (
  props: CommonStepperProps
) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.root}>
      <Box className={classes.stepper}>
        <Stepper activeStep={props.activeStepIndex} alternativeLabel>
          {props.stepLabels.map((label) => (
            <Step key={label}>
              <StepLabel className={classes.stepLabel}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  )
}
