import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  ModelGroupInfo,
  ModelGroupListActionType,
  ModelGroupListDisplayCondition,
} from './types'

export type ModelGroupListActions = ActionsUnion<typeof modelGroupListActions>

export const modelGroupListActions = {
  setModelGroupList: (
    modelGroupList: ModelGroupInfo[]
  ): ActionWithPayload<
    'SET_MODEL_GROUP_LIST',
    { modelGroupList: ModelGroupInfo[] }
  > =>
    CreateAction(ModelGroupListActionType.SET_MODEL_GROUP_LIST, {
      modelGroupList,
    }),
  clearModelGroupList: (): Action<'CLEAR_MODEL_GROUP_LIST'> =>
    CreateAction(ModelGroupListActionType.CLEAR_MODEL_GROUP_LIST),
  setListDisplayCondition: (
    modelGroupListDisplayCondition: ModelGroupListDisplayCondition
  ): ActionWithPayload<
    'SET_MODEL_GROUP_LIST_DISPLAY_CONDITION',
    { modelGroupListDisplayCondition: ModelGroupListDisplayCondition }
  > =>
    CreateAction(
      ModelGroupListActionType.SET_MODEL_GROUP_LIST_DISPLAY_CONDITION,
      {
        modelGroupListDisplayCondition,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MODEL_GROUP_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      ModelGroupListActionType.SET_IN_PROGRESS_FOR_MODEL_GROUP_LIST,
      {
        inProgress,
      }
    ),
  clearModelGroupListState: (): Action<'CLEAR_MODEL_GROUP_LIST_STATE'> =>
    CreateAction(ModelGroupListActionType.CLEAR_MODEL_GROUP_LIST_STATE),
}
