import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils'

import { PasswordResetActionType, SendPasswordResetMailSubState } from './types'

export type PasswordResetAction = ActionsUnion<typeof passwordResetActions>

export const passwordResetActions = {
  setPasswordResetState: (
    data: SendPasswordResetMailSubState
  ): ActionWithPayload<
    'SET_PASSWORD_RESET_MAIL_SEND_SUB_STATE',
    { data: SendPasswordResetMailSubState }
  > =>
    CreateAction(
      PasswordResetActionType.SET_PASSWORD_RESET_MAIL_SEND_SUB_STATE,
      {
        data,
      }
    ),
  setPasswordResetErrorMessage: (
    data: string
  ): ActionWithPayload<
    'SET_PASSWORD_RESET_MAIL_SEND_ERROR_MESSAGE',
    { data: string }
  > =>
    CreateAction(
      PasswordResetActionType.SET_PASSWORD_RESET_MAIL_SEND_ERROR_MESSAGE,
      {
        data,
      }
    ),
  clearPasswordResetState: (): Action<'CLEAR_PASSWORD_RESET_STATE'> =>
    CreateAction(PasswordResetActionType.CLEAR_PASSWORD_RESET_STATE),
}
