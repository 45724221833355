import { Timestamp } from 'state/firebase'
import { Version } from 'types/StateTypes'

// ActionType
export const FeatureDataTransferringActionType = {
  /** 特徴量グループ一覧を設定する */
  SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_TRANSFERRING',

  /** 選択中の特徴量グループを設定する */
  SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_TRANSFERRING',

  /** 選択中の特徴量データを設定する */
  SET_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データグループ一覧の表示条件をクリアする */
  CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
    'CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データ一覧の表示条件をクリアする */
  CLEAR_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
    'CLEAR_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データグループ一覧の表示条件を設定する */
  SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING',
  /** 特徴量データ一覧の表示条件を設定する */
  SET_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_FEATURE_DATA_DISPLAY_CONDITION_FOR_FEATURE_DATA_TRANSFERRING',

  /** 選択中のアルゴリズムを設定する */
  SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_TRANSFERRING',

  /** メタデータを設定する */
  SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データグループ選択ステップの状態を設定する */
  SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データ選択ステップの状態を設定する */
  SET_FEATURE_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_FEATURE_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING',

  /** メタデータ選択ステップの状態を設定する */
  SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データ転送画面の状態を設定する */
  SET_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データ転送画面の状態をクリアする */
  CLEAR_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING:
    'CLEAR_FEATURE_DATA_TRANSFERRING_STATE_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データ転送の状態を成功にする */
  EXEC_FEATURE_DATA_TRANSFERRING_SUCCESS_FOR_FEATURE_DATA_TRANSFERRING:
    'EXEC_FEATURE_DATA_TRANSFERRING_SUCCESS_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データ転送の状態を失敗にする */
  EXEC_FEATURE_DATA_TRANSFERRING_FAILURE_FOR_FEATURE_DATA_TRANSFERRING:
    'EXEC_FEATURE_DATA_TRANSFERRING_FAILURE_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データ転送の結果を設定にする */
  SET_EXECUTED_INFO_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_EXECUTED_INFO_FOR_FEATURE_DATA_TRANSFERRING',

  /** ロード中フラグを設定にする */
  SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データグループ詳細を設定にする */
  SET_CURRENT_FEATURE_DATA_GROUP_DETAIL_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_CURRENT_FEATURE_DATA_GROUP_DETAIL_FOR_FEATURE_DATA_TRANSFERRING',

  /** 特徴量データグループ詳細の状態を設定にする */
  SET_FEATURE_DATA_GROUP_DATA_STATE_FOR_FEATURE_DATA_TRANSFERRING:
    'SET_FEATURE_DATA_GROUP_DATA_STATE_FOR_FEATURE_DATA_TRANSFERRING',
} as const

interface Algorithm {
  algorithmId: string
  algorithmName: string
}

export interface FeatureData {
  featureDataId: string
  featureDataName: string
  featureDataVersion: Version
}

export interface FeatureDataListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface CurrentFeatureDataGroupDetail {
  featureDataGroupId: string
  featureDataGroupName: string
  relatedFeatureDataList: FeatureData[]
  featureDataListDisplayCondition: FeatureDataListDisplayCondition
  remarks: string
  trainingAlgorithm: Algorithm
  createdAt?: Timestamp
  createdBy: AccountName
  overview: string
}

export const FeatureDataTransferringStateKindArray = [
  'FeatureDataGroupState',
  'FeatureDataState',
  'MetaDataState',
  'ExecuteState',
]

/**
 * 特徴量データ転送 ステップ
 */
export type FeatureDataTransferringStateKind =
  (typeof FeatureDataTransferringStateKindArray)[number]

/**
 * 各ステップでの状態
 */
export interface FeatureDataState {
  featureDataGroupSubState: 'Unselected' | 'Selected'
  featureDataSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState:
    | 'BeforeExecute'
    | 'ExecuteInProgress'
    | 'Executed'
    | 'ExecuteError'
}

/**
 * メタデータステップで入力される内容
 */
export interface MetaData {
  /** 表示名 */
  name?: string
  /** 備考 */
  remarks?: string
}

/**
 * 転送時に生成される情報
 */
export interface ExecutionInfo {
  /** ML Pipeline ID */
  mlPipelineId?: string
  /** ステップID */
  transferringStepId?: string
}

/**
 * 特徴量データ転送指示
 */
export interface FeatureDataTransferringParamsType {
  /** 特徴量データ情報 */
  featureData: {
    /** 特徴量データID */
    featureDataId: string
    /** ユーザーグループID */
    userGroupId: string
  }
  /** ML Pipelineメタデータ */
  mlPipelineMetadata: {
    /** 表示名 */
    name?: string
    /** 備考 */
    remarks?: string
  }
}

/**
 * 特徴量データ転送指示作成 ドメイン情報
 */
export interface FeatureDataTransferringDomainData {
  /** 選択中の学習アルゴリズム */
  selectedTrainingAlgorithm?: {
    algorithmId: string
  }
  /** 特徴量データグループの配列 */
  featureDataGroups: FeatureDataGroup[]
  /** 選択された特徴量データグループ */
  selectedFeatureDataGroup?: FeatureDataGroup
  /** 特徴量データの配列 */
  featureDataList?: FeatureData[]
  /** 選択された特徴量データ */
  selectedFeatureData?: FeatureData
  /** 入力された特徴量データ転送情報のメタデータ */
  mlPipelinesMetaData?: MetaData
  /** 特徴量データを実行時に生成された ML PipelineのID */
  executionInfo?: ExecutionInfo
  /** 特徴量データグループの表示条件 */
  featureDataGroupDisplayCondition: DisplayCondition
  /** 特徴量データの表示条件 */
  featureDataDisplayCondition: DisplayCondition
}

/**
 *  特徴量データグループ情報
 */
export interface FeatureDataGroup {
  /** 特徴量データグループID */
  featureDataGroupId: string
  /** 特徴量データグループ名 */
  featureDataGroupName: string
  /** 登録特徴量データ数 */
  featureDataCount: number
  /** 最新特徴量データバージョン */
  latestFeatureDataVersion: string
  /** 最新特徴量データ名 */
  latestFeatureDataName: string
  /** 更新日 */
  updatedAt?: Timestamp
  /** 作成日 */
  createdAt?: Timestamp
  /** 作成者 */
  createdBy: string
  /** ユーザーグループID */
  userGroupId: string
}

export interface FeatureDataTransferringAppState {
  /** 特徴量データ画面のSTEP */
  featureDataTransferringState: FeatureDataTransferringStateKind
  /** 特徴量データ画面の各種STEPの状態 */
  featureDataTransferringSubState: FeatureDataState
  /** データ取得中、特徴量データ実行中フラグ */
  inProgress: boolean
}

export interface AlgorithmDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
}

export interface DisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface FeatureDataList {
  'feature-data-id': string
  'feature-data-name': string
  'feature-data-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
}

export interface FeatureDataTransferringState {
  domainData: FeatureDataTransferringDomainData
  appState: FeatureDataTransferringAppState
}
