import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import {
  PasswordUpdateSubState,
  ToastInfo,
  UserInfo,
  UserSettingActionType,
} from './types'

export type UserSettingAction = ActionsUnion<typeof userSettingActions>

export const userSettingActions = {
  setUserInfo: (
    data: UserInfo
  ): ActionWithPayload<'SET_USER_INFO', { data: UserInfo }> =>
    CreateAction(UserSettingActionType.SET_USER_INFO, {
      data,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_USER_SETTING',
    { inProgress: boolean }
  > =>
    CreateAction(UserSettingActionType.SET_IN_PROGRESS_FOR_USER_SETTING, {
      inProgress,
    }),
  setPasswordUpdatedState: (
    data: PasswordUpdateSubState
  ): ActionWithPayload<
    'SET_PASSWORD_UPDATED_STATE_FOR_USER_SETTING',
    { data: PasswordUpdateSubState }
  > =>
    CreateAction(
      UserSettingActionType.SET_PASSWORD_UPDATED_STATE_FOR_USER_SETTING,
      {
        data,
      }
    ),
  setPasswordUpdateErrorMessage: (
    data: string
  ): ActionWithPayload<
    'SET_PASSWORD_UPDATE_ERROR_MESSAGE_FOR_USER_SETTING',
    { data: string }
  > =>
    CreateAction(
      UserSettingActionType.SET_PASSWORD_UPDATE_ERROR_MESSAGE_FOR_USER_SETTING,
      {
        data,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_USER_SETTING',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(UserSettingActionType.SET_TOAST_INFO_FOR_USER_SETTING, {
      toastInfo,
    }),
  clearInferenceDetailState: (): Action<'CLEAR_USER_SETTING_STATE'> =>
    CreateAction(UserSettingActionType.CLEAR_USER_SETTING_STATE),
}
