import { AlgorithmStructureVersion } from 'state/app/domainData'
import {
  CreateAction,
  ActionsUnion,
  Action,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  ModelUploadActionType,
  AlgorithmDisplayCondition,
  MetaData,
  ModelUploadStateKind,
  FileAndProgress,
  ToastInfo,
  DisplayCondition,
  TrainedModelGroup,
  TrainingAlgorithmVersion,
  TrainedModelGroupList,
  Dataset,
  TrainedModel,
  ClassSet,
  ClassSetDisplayCondition,
} from './types'

export type ModelUploadAction = ActionsUnion<typeof modelUploadActions>

export const modelUploadActions = {
  setAlgorithmDisplayCondition: (
    algorithmDisplayCondition?: AlgorithmDisplayCondition
  ): ActionWithPayload<
    'SET_ALGORITHM_DISPLAY_CONDITION',
    { algorithmDisplayCondition?: AlgorithmDisplayCondition }
  > =>
    CreateAction(ModelUploadActionType.SET_ALGORITHM_DISPLAY_CONDITION, {
      algorithmDisplayCondition,
    }),
  setSelectedBaseModelGroup: (
    selectedTrainedModelGroup?: TrainedModelGroup
  ): ActionWithPayload<
    'SET_SELECTED_BASE_MODEL_GROUP_FOR_MODEL_UPLOAD',
    { selectedTrainedModelGroup?: TrainedModelGroup }
  > =>
    CreateAction(
      ModelUploadActionType.SET_SELECTED_BASE_MODEL_GROUP_FOR_MODEL_UPLOAD,
      {
        selectedTrainedModelGroup,
      }
    ),
  setSelectedDestinationTrainedModelGroup: (
    selectedDestinationTrainedModelGroup?: TrainedModelGroup
  ): ActionWithPayload<
    'SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP_FOR_MODEL_UPLOAD',
    { selectedDestinationTrainedModelGroup?: TrainedModelGroup }
  > =>
    CreateAction(
      ModelUploadActionType.SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP_FOR_MODEL_UPLOAD,
      {
        selectedDestinationTrainedModelGroup,
      }
    ),
  setDatasetList: (
    datasetList: Dataset[]
  ): ActionWithPayload<
    'SET_DATASET_LIST_FOR_MODEL_UPLOAD',
    { datasetList: Dataset[] }
  > =>
    CreateAction(ModelUploadActionType.SET_DATASET_LIST_FOR_MODEL_UPLOAD, {
      datasetList,
    }),
  setDestinationTrainedModelGroupDisplayCondition: (
    condition: DisplayCondition
  ): ActionWithPayload<
    'SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
    { condition: DisplayCondition }
  > =>
    CreateAction(
      ModelUploadActionType.SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD,
      {
        condition,
      }
    ),
  clearDestinationTrainedModelGroupDisplayCondition:
    (): Action<'CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD'> =>
      CreateAction(
        ModelUploadActionType.CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD
      ),
  setBaseModelGroupDisplayCondition: (
    condition: DisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ): ActionWithPayload<
    'SET_BASE_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
    {
      condition: DisplayCondition & {
        selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
      }
    }
  > =>
    CreateAction(
      ModelUploadActionType.SET_BASE_MODEL_GROUP_DISPLAY_CONDITION_FOR_MODEL_UPLOAD,
      {
        condition,
      }
    ),
  setBaseModelDisplayCondition: (
    trainedModelDisplayCondition: DisplayCondition
  ): ActionWithPayload<
    'SET_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
    { trainedModelDisplayCondition: DisplayCondition }
  > =>
    CreateAction(
      ModelUploadActionType.SET_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD,
      {
        trainedModelDisplayCondition,
      }
    ),
  clearBaseModelDisplayCondition:
    (): Action<'CLEAR_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD'> =>
      CreateAction(
        ModelUploadActionType.CLEAR_BASE_MODEL_DISPLAY_CONDITION_FOR_MODEL_UPLOAD
      ),
  setDatasetDisplayCondition: (
    datasetDisplayCondition?: DisplayCondition
  ): ActionWithPayload<
    'SET_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
    { datasetDisplayCondition?: DisplayCondition }
  > =>
    CreateAction(
      ModelUploadActionType.SET_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD,
      {
        datasetDisplayCondition,
      }
    ),
  clearDatasetDisplayCondition:
    (): Action<'CLEAR_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD'> =>
      CreateAction(
        ModelUploadActionType.CLEAR_DATASET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD
      ),
  setSelectedBaseModel: (
    selectedBaseModel?: TrainedModel
  ): ActionWithPayload<
    'SET_SELECTED_BASE_MODEL_FOR_MODEL_UPLOAD',
    { selectedBaseModel?: TrainedModel }
  > =>
    CreateAction(
      ModelUploadActionType.SET_SELECTED_BASE_MODEL_FOR_MODEL_UPLOAD,
      {
        selectedBaseModel,
      }
    ),
  setSelectedDataset: (
    selectedDataset?: Dataset
  ): ActionWithPayload<
    'SET_SELECTED_DATASET_FOR_MODEL_UPLOAD',
    { selectedDataset?: Dataset }
  > =>
    CreateAction(ModelUploadActionType.SET_SELECTED_DATASET_FOR_MODEL_UPLOAD, {
      selectedDataset,
    }),
  setBaseModelGroupList: (
    trainedModelGroups: TrainedModelGroupList
  ): ActionWithPayload<
    'SET_BASE_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD',
    { trainedModelGroups: TrainedModelGroupList }
  > =>
    CreateAction(
      ModelUploadActionType.SET_BASE_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD,
      {
        trainedModelGroups,
      }
    ),
  clearBaseModelGroupDisplayCondition:
    (): Action<'CLEAR_BASE_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD'> =>
      CreateAction(
        ModelUploadActionType.CLEAR_BASE_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD
      ),
  setModelGroupList: (
    modelGroupList: TrainedModelGroup[]
  ): ActionWithPayload<
    'SET_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD',
    { modelGroupList: TrainedModelGroup[] }
  > =>
    CreateAction(ModelUploadActionType.SET_MODEL_GROUP_LIST_FOR_MODEL_UPLOAD, {
      modelGroupList,
    }),
  clearModelGroupDisplayCondition:
    (): Action<'CLEAR_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD'> =>
      CreateAction(
        ModelUploadActionType.CLEAR_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_MODEL_UPLOAD
      ),
  setModelGroupSubState: (
    modelGroupSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_MODEL_GROUP_SUB_STATE',
    { modelGroupSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(ModelUploadActionType.SET_MODEL_GROUP_SUB_STATE, {
      modelGroupSubState,
    }),
  setBaseModelSubState: (
    baseModelSubState: 'Unselected' | 'Selected' | 'NotFound'
  ): ActionWithPayload<
    'SET_BASE_MODEL_SUB_STATE_FOR_MODEL_UPLOAD',
    { baseModelSubState: 'Unselected' | 'Selected' | 'NotFound' }
  > =>
    CreateAction(
      ModelUploadActionType.SET_BASE_MODEL_SUB_STATE_FOR_MODEL_UPLOAD,
      {
        baseModelSubState,
      }
    ),
  setDatasetSubState: (
    datasetSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_DATASET_SUB_STATE_FOR_MODEL_UPLOAD',
    { datasetSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(ModelUploadActionType.SET_DATASET_SUB_STATE_FOR_MODEL_UPLOAD, {
      datasetSubState,
    }),
  setSelectedAlgorithmId: (
    algorithmId: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_ID_FOR_MODEL_UPLOAD',
    { algorithmId: string }
  > =>
    CreateAction(
      ModelUploadActionType.SET_SELECTED_ALGORITHM_ID_FOR_MODEL_UPLOAD,
      {
        algorithmId,
      }
    ),
  setSelectedTrainingAlgorithmVersion: (
    selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_MODEL_UPLOAD',
    { selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion }
  > =>
    CreateAction(
      ModelUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_MODEL_UPLOAD,
      {
        selectedTrainingAlgorithmVersion,
      }
    ),
  setSelectedTrainingAlgorithmStructureVersion: (
    selectedTrainingAlgorithmStructureVersion?: AlgorithmStructureVersion
  ): ActionWithPayload<
    'SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_MODEL_UPLOAD',
    { selectedTrainingAlgorithmStructureVersion?: AlgorithmStructureVersion }
  > =>
    CreateAction(
      ModelUploadActionType.SET_SELECTED_TRAINING_ALGORITHM_STRUCTURE_VERSION_FOR_MODEL_UPLOAD,
      {
        selectedTrainingAlgorithmStructureVersion,
      }
    ),
  setModelMetaData: (
    modelMetaData?: MetaData
  ): ActionWithPayload<'SET_MODEL_META_DATA', { modelMetaData?: MetaData }> =>
    CreateAction(ModelUploadActionType.SET_MODEL_META_DATA, {
      modelMetaData,
    }),
  setSelectedModelKind: (
    modelKind?: 'Generic' | 'Custom' | 'Specified' | undefined
  ): ActionWithPayload<
    'SET_MODEL_KIND',
    { modelKind?: 'Generic' | 'Custom' | 'Specified' | undefined }
  > =>
    CreateAction(ModelUploadActionType.SET_MODEL_KIND, {
      modelKind,
    }),
  setSelectedModelVersion: (
    selectedVersion?: string
  ): ActionWithPayload<
    'SET_SELECTED_MODEL_VERSION',
    { selectedVersion?: string }
  > =>
    CreateAction(ModelUploadActionType.SET_SELECTED_MODEL_VERSION, {
      selectedVersion,
    }),
  setTrainingAlgorithmSubState: (
    trainingAlgorithmSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_ALGORITHM_SUB_STATE',
    { trainingAlgorithmSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(ModelUploadActionType.SET_ALGORITHM_SUB_STATE, {
      trainingAlgorithmSubState,
    }),
  setModelUploadSubState: (
    modelUploadSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_MODEL_UPLOAD_SUB_STATE',
    { modelUploadSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(ModelUploadActionType.SET_MODEL_UPLOAD_SUB_STATE, {
      modelUploadSubState,
    }),
  setMetaDataSubState: (
    metaDataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_MODEL_META_DATA_SUB_STATE',
    { metaDataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(ModelUploadActionType.SET_MODEL_META_DATA_SUB_STATE, {
      metaDataSubState,
    }),
  setModelUploadState: (
    modelUploadStateKind: ModelUploadStateKind
  ): ActionWithPayload<
    'SET_SEND_MODEL_STATE',
    { modelUploadStateKind: ModelUploadStateKind }
  > =>
    CreateAction(ModelUploadActionType.SET_SEND_MODEL_STATE, {
      modelUploadStateKind,
    }),
  clearModelUploadState: (): Action<'CLEAR_MODEL_UPLOAD_STATE'> =>
    CreateAction(ModelUploadActionType.CLEAR_MODEL_UPLOAD_STATE),
  executeModelUploadSuccess: (): Action<'EXEC_MODEL_SEND_SUCCESS'> =>
    CreateAction(ModelUploadActionType.EXEC_MODEL_SEND_SUCCESS),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MODEL_UPLOAD',
    { inProgress: boolean }
  > =>
    CreateAction(ModelUploadActionType.SET_IN_PROGRESS_FOR_MODEL_UPLOAD, {
      inProgress,
    }),
  setExecutedModelId: (
    executedModelId?: string
  ): ActionWithPayload<'SET_EXECUTED_MODEL_ID', { executedModelId?: string }> =>
    CreateAction(ModelUploadActionType.SET_EXECUTED_MODEL_ID, {
      executedModelId,
    }),
  addInputFiles: (
    files: FileAndProgress[]
  ): ActionWithPayload<
    'ADD_IMAGE_SET_FILE_FOR_MODEL_UPLOAD',
    { files: FileAndProgress[] }
  > =>
    CreateAction(ModelUploadActionType.ADD_IMAGE_SET_FILE_FOR_MODEL_UPLOAD, {
      files,
    }),
  executeModelUploadFailure: (): Action<'EXEC_MODEL_SEND_FAILURE'> =>
    CreateAction(ModelUploadActionType.EXEC_MODEL_SEND_FAILURE),
  setFileUploadResult: (
    status: 'beforeUpload' | 'failed' | 'success'
  ): ActionWithPayload<
    'SET_FILE_UPLOAD_RESULT',
    { status: 'beforeUpload' | 'failed' | 'success' }
  > =>
    CreateAction(ModelUploadActionType.SET_FILE_UPLOAD_RESULT, {
      status,
    }),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_MODEL_UPLOAD',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(ModelUploadActionType.SET_TOAST_INFO_FOR_MODEL_UPLOAD, {
      toastInfo,
    }),
  setCurrentDatasetListSnapshot: (
    currentDatasetListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_MODEL_UPLOAD',
    { currentDatasetListSnapshot?: () => void }
  > =>
    CreateAction(
      ModelUploadActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_MODEL_UPLOAD,
      {
        currentDatasetListSnapshot,
      }
    ),

  /** クラスセット一覧を設定する */
  setClassSets: (
    classSets: ClassSet[]
  ): ActionWithPayload<
    'SET_CLASS_SET_LIST_FOR_MODEL_UPLOAD',
    { classSets: ClassSet[] }
  > =>
    CreateAction(ModelUploadActionType.SET_CLASS_SET_LIST_FOR_MODEL_UPLOAD, {
      classSets,
    }),

  /** クラスセット一覧をクリアする */
  clearClassSets: (): Action<'CLEAR_CLASS_SET_LIST_FOR_MODEL_UPLOAD'> =>
    CreateAction(ModelUploadActionType.CLEAR_CLASS_SET_LIST_FOR_MODEL_UPLOAD),

  /** 選択中のクラスセットを設定する */
  setSelectedClassSet: (
    classSet?: ClassSet
  ): ActionWithPayload<
    'SET_SELECTED_CLASS_SET_FOR_MODEL_UPLOAD',
    { classSet?: ClassSet }
  > =>
    CreateAction(
      ModelUploadActionType.SET_SELECTED_CLASS_SET_FOR_MODEL_UPLOAD,
      {
        classSet,
      }
    ),

  /** クラスセットの表示条件をクリアする */
  clearClassSetDisplayCondition:
    (): Action<'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD'> =>
      CreateAction(
        ModelUploadActionType.CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD
      ),

  /** クラスセットの表示条件を設定する */
  setClassSetDisplayCondition: (
    displayCondition: ClassSetDisplayCondition
  ): ActionWithPayload<
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD',
    { displayCondition: ClassSetDisplayCondition }
  > =>
    CreateAction(
      ModelUploadActionType.SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_UPLOAD,
      {
        displayCondition,
      }
    ),

  setClassSetSubState: (
    classSetSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_CLASS_SET_SUB_STATE_FOR_MODEL_UPLOAD',
    { classSetSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(
      ModelUploadActionType.SET_CLASS_SET_SUB_STATE_FOR_MODEL_UPLOAD,
      {
        classSetSubState,
      }
    ),
}
