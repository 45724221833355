import { FeatureDataGroupEntryAction } from './actions'
import {
  FeatureDataGroupEntryActionType,
  FeatureDataGroupEntryState,
} from './types'

const initialState: FeatureDataGroupEntryState = {
  domainData: {
    selectedAlgorithm: undefined,
    featureDataGroupMetaData: {
      featureDataGroupName: '',
      overView: undefined,
      featureDataGroupIcon: undefined,
      remarks: undefined,
    },
    generatedFeatureDataGroupId: '',
  },
  appState: {
    featureDataGroupEntryStateKind: 'AlgorithmState',
    featureDataGroupEntrySubStateKind: {
      algorithmSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
    toastInfo: undefined,
  },
}

export const FeatureDataGroupEntryReducer = (
  state: FeatureDataGroupEntryState = initialState,
  action: FeatureDataGroupEntryAction
): FeatureDataGroupEntryState => {
  switch (action.type) {
    case FeatureDataGroupEntryActionType.SET_SELECTED_ALGORITHM:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedAlgorithm: action.payload.selectedAlgorithm,
        },
      }
    case FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_NAME:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupMetaData: {
            ...state.domainData.featureDataGroupMetaData,
            featureDataGroupName: action.payload.featureDataGroupName,
          },
        },
      }
    case FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_OVER_VIEW:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupMetaData: {
            ...state.domainData.featureDataGroupMetaData,
            overView: action.payload.overView,
          },
        },
      }
    case FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_ICON:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupMetaData: {
            ...state.domainData.featureDataGroupMetaData,
            featureDataGroupIcon: action.payload.featureDataGroupIcon,
          },
        },
      }
    case FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_REMARKS:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataGroupMetaData: {
            ...state.domainData.featureDataGroupMetaData,
            remarks: action.payload.remarks,
          },
        },
      }
    case FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGroupEntryStateKind:
            action.payload.featureDataGroupEntryStateKind,
        },
      }
    case FeatureDataGroupEntryActionType.SET_ALGORITHM_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGroupEntrySubStateKind: {
            ...state.appState.featureDataGroupEntrySubStateKind,
            algorithmSubState: action.payload.algorithmSubState,
          },
        },
      }
    case FeatureDataGroupEntryActionType.SET_METADATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGroupEntrySubStateKind: {
            ...state.appState.featureDataGroupEntrySubStateKind,
            metaDataSubState: action.payload.metadataSubState,
          },
        },
      }
    case FeatureDataGroupEntryActionType.SET_EXECUTE_FEATURE_DATA_GROUP_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          featureDataGroupEntrySubStateKind: {
            ...state.appState.featureDataGroupEntrySubStateKind,
            executeSubState: action.payload.executeSubState,
          },
        },
      }
    case FeatureDataGroupEntryActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case FeatureDataGroupEntryActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case FeatureDataGroupEntryActionType.SET_GENERATED_FEATURE_DATA_GROUP_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          generatedFeatureDataGroupId:
            action.payload.generatedFeatureDataGroupId,
        },
      }
    case FeatureDataGroupEntryActionType.CLEAR_FEATURE_DATA_GROUP_ENTRY_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
