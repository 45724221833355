import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Search from '@mui/icons-material/Search'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { SearchInputProps } from './types'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    maxHeight: '100%',
  },
  iconButton: {
    padding: theme.spacing(1),
    color: theme.palette.common.black,
  },
}))

export const SearchInput: React.FC<SearchInputProps> = (
  props: SearchInputProps
) => {
  const { classes } = useStyles()

  const convertTagToString = () => {
    if (typeof props.value === 'string') {
      return props.value
    } else {
      let str = ''
      props.value.map((item) => {
        str = str + `tag:${item.key}=${item.value},  `
      })
      return str
    }
  }

  return (
    <Paper component='form' variant='outlined' className={classes.root}>
      {props.filter && (
        <IconButton
          className={classes.iconButton}
          onClick={() => props.onClickSearch(props.value)}
          disabled={props.disabled}
          data-testid='search'
        >
          <Search />
        </IconButton>
      )}
      <InputBase
        placeholder={props.placeholder}
        value={convertTagToString()}
        onChange={props.onChangeValue}
        disabled={props.disabled}
        className={classes.input}
        onKeyDown={(event) => {
          if (event.keyCode === 13 && props.onPressEnter) {
            props.onPressEnter(props.value)
          } else if (
            event.keyCode === 8 &&
            typeof props.value !== 'string' &&
            props.onPressDelete
          ) {
            props.onPressDelete()
          }
        }}
        inputProps={{ 'data-testid': 'input-id' }}
      />
      <input type='text' style={{ display: 'none' }} />
      <Divider className={classes.divider} orientation='vertical' />
      <IconButton
        className={classes.iconButton}
        onClick={() =>
          props.filter && props.onClickFilter
            ? props.onClickFilter()
            : props.onClickSearch(props.value)
        }
        disabled={props.disabled}
        data-testid={props.filter ? 'filter' : 'search'}
      >
        {props.filter ? <FilterAltIcon /> : <Search />}
      </IconButton>
    </Paper>
  )
}
