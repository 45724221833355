import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl, getAxiosInstance } from 'state/utils'
import { SettingFireStore } from './types'
import { AxiosResponse } from 'axios'
import { Version } from 'types/StateTypes'

export const CreateSettingApi = {
  settingFireStore: (
    setting: SettingFireStore
  ): Promise<HttpsCallableResult<{ settingId: string }>> =>
    httpsCallable<{ setting: SettingFireStore }, { settingId: string }>(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-setting-setting-firestore'
    )({
      setting,
    }),
  getSignedUrl: (settingFile: {
    fileType: string
    fileName: string
  }): Promise<HttpsCallableResult<{ id: string; url: string }>> =>
    httpsCallable<
      {
        settingFile: {
          fileType: string
          fileName: string
        }
      },
      { id: string; url: string }
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-setting-setting-get-signed-url'
    )({
      settingFile,
    }),
  uploadSettingFile: (url: string, file: File): Promise<AxiosResponse<void>> =>
    getAxiosInstance().upload(url, file, {
      headers: { 'Content-Type': file.type },
    }),
  validate: (
    settingId: string,
    fileName: string,
    userGroupId: string,
    algorithmId: string
  ) =>
    httpsCallable<
      {
        settingId: string
        fileName: string
        userGroupId: string
        algorithmId: string
      },
      | {
          isValid: boolean
          settingFormatVersion: {
            settingFormatId: string
            settingFormatVersion: Version
            datasetTemplateId: string
          }
        }
      | undefined
    >(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'create-setting-validate-training-parameter'
    )({
      settingId,
      fileName,
      userGroupId,
      algorithmId,
    }),
}
