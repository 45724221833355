import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { State } from 'state/store'
import {
  MfaSettingAction,
  mfaSettingActions,
  mfaSettingOperations,
  MfaSettingStateKindArray,
} from 'state/ducks/mfaSetting'

import {
  GlobalLoading,
  CustomTrainingPageParagraph,
  showToast,
  Toast,
} from 'views/components'
import {
  isValidNumberForRegion,
  convertCountryCodePhoneNumber,
} from 'views/containers/utils/phoneNumber'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.mfaSettingState,
  ...state.app.domainData,
  ...state.app.appState,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, MfaSettingAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) => {
    dispatch(mfaSettingOperations.nextStep(currentStep))
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) => {
    dispatch(mfaSettingOperations.prevStep(currentStep))
  },
  /** SMSコードを送信 */
  requestAuthenticationCode: (convertPhoneNumber: string) => {
    dispatch(mfaSettingOperations.requestAuthenticationCode(convertPhoneNumber))
  },
  /** 認証コード入力してを送信 */
  executeAuthCode: (
    authenticationCode: string,
    phoneNumber: string,
    localeId: string
  ) => {
    dispatch(
      mfaSettingOperations.executeAuthCode(
        authenticationCode,
        phoneNumber,
        localeId
      )
    )
  },
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(mfaSettingActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const COUNTRY_LIST = [{ id: 'JP', name: '日本' }]

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
    margin: 'auto 0',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  infoTextField: {
    backgroundColor: 'rgba(237, 219, 94, 0.5)',
    border: '1px solid rgb(237, 219, 94)',
    padding: theme.spacing(1),
  },
  textField: {
    width: '100%',
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0',
    },
  },
  phoneNumberLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
    marginLeft: 'auto',
  },
  countrySelectBox: {
    width: '100%',
  },
}))

const MfaSetting: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()

  /** 電話番号 */
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  /** 認証コード */
  const [authenticationCode, setAuthenticationCode] = useState<string>('')
  /** ロケールID */
  const [localeId, setLocaleId] = useState<string>(COUNTRY_LIST[0].id)

  /** 電話番号の有効可否 */
  const isPhoneNumberValid = useMemo(() => {
    const result = isValidNumberForRegion(phoneNumber, localeId)
    return result
  }, [localeId, phoneNumber])

  /** 電話番号に国コードを付与して返す */
  const addCountryCodePhoneNumber = useMemo(() => {
    return convertCountryCodePhoneNumber(phoneNumber, localeId)
  }, [localeId, phoneNumber])

  /** localeIdでnameを取得 */
  const getLocaleName = useMemo(() => {
    const locale = COUNTRY_LIST.find((locale) => locale.id === localeId)
    if (!locale) return '日本'
    return locale.name
  }, [localeId])

  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(
        props.appState.toastInfo.type,
        props.appState.toastInfo.targets
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  useEffect(() => {
    if (props.appState.mfaSettingState === 'Completed') {
      history.push('/')
    }
  }, [props.appState.mfaSettingState])

  const showErrorToast = (
    type: 'info' | 'warning' | 'error',
    messages: string[]
  ) =>
    showToast(
      type,
      <div>
        <div>{`メッセージ種別: ${type}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  /** 現在の処理ステップ */
  const currentStep = useMemo(
    () => MfaSettingStateKindArray.indexOf(props.appState.mfaSettingStateKind),
    [props.appState.mfaSettingStateKind]
  )

  /** 次へボタンの動作 */
  const nextStepAction = async (currentStep: number) => {
    switch (currentStep) {
      case 0:
        /** 認証コード送信 */
        props.requestAuthenticationCode(addCountryCodePhoneNumber ?? '')
        props.nextStep(currentStep)
        break
      case 1:
        /** 認証コード登録 */
        props.executeAuthCode(
          authenticationCode,
          addCountryCodePhoneNumber,
          localeId
        )
        break
      default:
        break
    }
  }

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.mfaSettingStateKind) {
      case 'phoneNumberState':
        // 電話番号が有効なら次へを有効にする
        return isPhoneNumberValid
      case 'mfaSettingState':
        return authenticationCode.length === 6
      default:
        return false
    }
  }, [currentStep, isPhoneNumberValid, authenticationCode])

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.mfaSettingStateKind) {
      case 'phoneNumberState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph title={'MFA で利用する携帯番号の設定'}>
              <Box className={classes.infoTextField}>
                <Typography>
                  {'2段階認証に必要な携帯電話番号を登録してください。'}
                </Typography>
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph title={'国地域'}>
              <FormControl
                variant='outlined'
                className={classes.countrySelectBox}
              >
                <InputLabel id='countrySelect'>国地域</InputLabel>
                <Select
                  data-testid='country-select'
                  labelId='country-select'
                  id='country-select'
                  defaultValue={getLocaleName}
                  value={getLocaleName}
                  onChange={(event) =>
                    setLocaleId(event.target.value as string)
                  }
                  label='Select country'
                >
                  {COUNTRY_LIST.map((country) => (
                    <MenuItem
                      data-testid={`${country.id}`}
                      value={country.name}
                      key={country.id}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph title={'携帯電話番号'}>
              <Box className={classes.phoneNumberLabel}>
                <TextField
                  className={classes.textField}
                  value={phoneNumber}
                  placeholder={'phone address'}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setPhoneNumber(e.target.value)}
                  variant='outlined'
                  rows={1}
                  type={'number'}
                  data-testid={'phone-number-input'}
                />
              </Box>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'mfaSettingState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph title={'認証コードの設定'}>
              <Box className={classes.infoTextField}>
                <Typography>
                  {'SMS に送信された認証コードを入力してください。'}
                </Typography>
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph title={'携帯電話番号'}>
              <Box>
                <TextField
                  id='auth-code'
                  className={classes.textField}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={addCountryCodePhoneNumber}
                  key={'phone-number-text'}
                />
              </Box>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph title='認証コード'>
              <Box className={classes.phoneNumberLabel}>
                <TextField
                  className={classes.textField}
                  value={authenticationCode}
                  placeholder={'authentication code'}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setAuthenticationCode(e.target.value)}
                  variant='outlined'
                  rows={1}
                  data-testid={'auth-code-input'}
                />
              </Box>
            </CustomTrainingPageParagraph>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.content}>{getStepContent(props)}</div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              {currentStep === 1 ? (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={false}
                  onClick={() => props.prevStep(currentStep)}
                  className={classes.leftButton}
                >
                  {'戻る'}
                </Button>
              ) : (
                <></>
              )}
              <Button
                id='mfa-register'
                data-testid='next-step'
                variant='contained'
                color='primary'
                disabled={!enableNextButton}
                onClick={() => nextStepAction(currentStep)}
                className={classes.rightButton}
              >
                {currentStep === 1 ? '認証' : '次へ'}
              </Button>
            </div>
          </div>
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

export const MfaSettingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MfaSetting))
