import { HttpsCallableResult, httpsCallable } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl, getArrayChunk } from 'state/utils'
import { GetSignedUrlsFunctionType } from './types'

const timeoutLimitSeconds = 15
// const timeoutLimitSecondsForGetZipSignedUrl = 540

export const annotationSetDetailApi = {
  getAnnotationFile: (annotationId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'annotation-file',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      annotationId: annotationId,
    }),
  getSignedUrls: async (
    fileInfoList: { id: string; fileName: string }[],
    action: 'read' | 'delete',
    type: 'thumbnail' | 'original'
  ): Promise<{ [id: string]: string }> => {
    // signedUrl取得の単位を500ファイルずつに分割
    const fileInfoListChunks = getArrayChunk(fileInfoList, 500)

    // 取得
    const signedUrlPromises: Promise<{
      [id: string]: string
    }>[] = fileInfoListChunks.map(async (fileInfoListChunk) => {
      const getReadableSignedUrlRecordsFunction: GetSignedUrlsFunctionType =
        httpsCallable(
          functionsInstance,
          `${AidpCloudFunctionsUrl()}data-manipulation-${action}-${type}`
        )
      return (
        await getReadableSignedUrlRecordsFunction({
          fileInfoList: fileInfoListChunk,
        })
      ).data
    })

    return Object.assign({}, ...(await Promise.all(signedUrlPromises)))
  },
}
