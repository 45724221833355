import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Delete from '@mui/icons-material/Delete'

import { DeleteButtonProps } from './types'

const useStyles = makeStyles()((theme) => ({
  button: {
    margin: theme.spacing(1),
    padding: 6,
    minWidth: 0,
    minHeight: 0,
  },
}))

export const DeleteButton: React.FC<DeleteButtonProps> = (
  props: DeleteButtonProps
) => {
  const { classes } = useStyles()

  return (
    <div className={props.className}>
      <Button
        variant='contained'
        className={classes.button}
        data-testid={props['data-testid']}
        disabled={props.disabled}
        onClick={props.onClick}
        color={
          props.color && props.color === 'blue'
            ? 'primary'
            : props.color && props.color === 'red'
            ? 'secondary'
            : 'inherit'
        }
      >
        <Delete />
      </Button>
    </div>
  )
}
