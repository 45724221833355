import { Timestamp } from 'state/firebase'

export const FeatureDataGroupListActionType = {
  SET_FEATURE_DATA_GROUP_LIST: 'SET_FEATURE_DATA_GROUP_LIST',
  CLEAR_FEATURE_DATA_GROUP_LIST: 'CLEAR_FEATURE_DATA_GROUP_LIST',
  SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION:
    'SET_FEATURE_DATA_GROUP_LIST_DISPLAY_CONDITION',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_LIST:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_LIST',
  CLEAR_FEATURE_DATA_GROUP_LIST_STATE: 'CLEAR_FEATURE_DATA_GROUP_LIST_STATE',
} as const

export interface FeatureDataGroupInfo {
  featureDataGroupId: string
  algorithmName: string
  name: string
  remarks: string
  overview: string
  updatedAt: Timestamp
  createAt: Timestamp
  createdUserId: string
}

export interface FeatureDataGroupListDisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
  totalCount?: number
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

export interface FeatureDataGroupListDomainData {
  currentFeatureDataGroupList: FeatureDataGroupInfo[]
  featureDataGroupListDisplayCondition: FeatureDataGroupListDisplayCondition
}

export interface FeatureDataGroupListAppState {
  inProgress: boolean
}

export interface FeatureDataGroupInfoListState {
  domainData: FeatureDataGroupListDomainData
  appState: FeatureDataGroupListAppState
}
