import { Timestamp } from 'state/firebase'
import { ResourceLoadingState } from 'state/utils/types'
import { AccountGroupRole } from 'views/utils/types'

export const AccountDetailActionType = {
  SET_ACCOUNT_INFO: 'SET_ACCOUNT_INFO',
  SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
  SET_CUSTOMER_LIST_DISPLAY_CONDITION: 'SET_CUSTOMER_LIST_DISPLAY_CONDITION',
  SET_CUSTOMER_LIST_PAGING_STATE: 'SET_CUSTOMER_LIST_PAGING_STATE',
  SET_IN_PROGRESS_FOR_ACCOUNT_DETAIL: 'SET_IN_PROGRESS_FOR_ACCOUNT_DETAIL',
  SET_ACCOUNT_DELETE_SUB_STATE: 'SET_ACCOUNT_DELETE_SUB_STATE',
  SET_ACCOUNT_UPDATE_ROLE_SUB_STATE: 'SET_ACCOUNT_UPDATE_ROLE_SUB_STATE',
  SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE:
    'SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE',
  SET_UPDATE_ACCOUNT_NAME: 'SET_UPDATE_ACCOUNT_NAME',
  SET_UPDATE_ACCOUNT_GROUP_ROLE_FOR_ACCOUNT_DETAIL:
    'SET_UPDATE_ACCOUNT_GROUP_ROLE_FOR_ACCOUNT_DETAIL',
  UPDATE_ACCOUNT_CUSTOMER_RELATIONS: 'UPDATE_ACCOUNT_CUSTOMER_RELATIONS',
  SET_ACCOUNT_DATA_SUB_STATE: 'SET_ACCOUNT_DATA_SUB_STATE',
  CLEAR_ACCOUNT_DETAIL_STATE: 'CLEAR_ACCOUNT_DETAIL_STATE',
} as const

export type MfaGroupSetting = 'required' | 'optional' | 'none'

export type AccountDeleteSubState =
  | 'BeforeDelete'
  | 'DeleteSuccess'
  | 'DeleteError'
  | 'AccountDeleteAborted'

export type AccountUpdateRoleSubState =
  | 'BeforeUpdateRole'
  | 'UpdateRoleSuccess'
  | 'UpdateRoleError'
  | 'UpdateRoleAborted'

export type AccountUpdateCustomerListSubState =
  | 'BeforeUpdateCustomerList'
  | 'UpdateCustomerListSuccess'
  | 'UpdateCustomerListError'

export interface AccountRelationCustomer {
  'customer-id': string
  'last-accessed': Timestamp
  'linked-date'?: Timestamp
  role: string
}
export interface AccountGroupRelationCustomer {
  'customer-id': string
}
export interface CustomerRaw {
  customerId: string
  customerName: string
}
export interface Customer extends CustomerRaw {
  role: string
  lastAccessed: Timestamp
  linkedDate?: Timestamp
}

export interface AccountInfo {
  mail: string
  phoneNumber: string
  firstName: string
  familyName: string
  accountGroupName: string
  accountGroupRole?: AccountGroupRole
  language: string
  locate: string
  role: boolean
  mfaGroupSetting: MfaGroupSetting
  isMfa: boolean
  passwordUpdateDate?: Timestamp
  lastLoginTime?: Timestamp
  createdAt?: Timestamp
  firstLoginTime?: Timestamp
  uid: string
  customerList: Customer[]
}

export interface DisplayCustomer {
  customerName: string
  role?: string
  lastAccessed?: Timestamp
}

export interface CustomerListDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface AccountDetailDomainData {
  accountInfo: AccountInfo
  customerList: CustomerRaw[]
  customerListDisplayCondition: CustomerListDisplayCondition
}

export interface CustomerListPagingState {
  nextPageSubState: 'Enable' | 'Unable'
}

export interface AccountDetailAppState {
  inProgress: boolean
  accountDeleteSubState: AccountDeleteSubState
  accountUpdateRoleSubState: AccountUpdateRoleSubState
  accountUpdateCustomerListSubState: AccountUpdateCustomerListSubState
  customerListState: CustomerListPagingState
  accountDataSubState: ResourceLoadingState
}

export interface AccountDetail {
  domainData: AccountDetailDomainData
  appState: AccountDetailAppState
}

export interface AccountName {
  firstName: string
  familyName: string
}
