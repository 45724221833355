import { Timestamp } from 'state/firebase'
import { TrainingAlgorithmVersion } from 'state/app/domainData'
import {
  VersionWithPreRelease,
  Version,
  VersionWithBuild,
  I18nString,
} from 'types/StateTypes'
import { GenerationType } from 'utils/fireStore/modelQuery'

// ActionType
export const CustomTrainingActionType = {
  SET_TRAINED_MODEL_GROUP_LIST: 'SET_TRAINED_MODEL_GROUP_LIST',
  SET_CLASS_SET_LIST_FOR_CUSTOM_TRAINING:
    'SET_CLASS_SET_LIST_FOR_CUSTOM_TRAINING',
  SET_SOURCE_TRAINED_MODEL_GROUP_LIST: 'SET_SOURCE_TRAINED_MODEL_GROUP_LIST',
  SET_DESTINATION_MODEL_DISPLAY_CONDITION:
    'SET_DESTINATION_MODEL_DISPLAY_CONDITION',
  CLEAR_DESTINATION_MODEL_DISPLAY_CONDITION:
    'CLEAR_DESTINATION_MODEL_DISPLAY_CONDITION',
  SET_MODEL_GROUP_SUB_STATE: 'SET_MODEL_GROUP_SUB_STATE',
  SET_SELECTED_SOURCE_TRAINED_MODEL_GROUP:
    'SET_SELECTED_SOURCE_TRAINED_MODEL_GROUP',
  SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP:
    'SET_SELECTED_DESTINATION_TRAINED_MODEL_GROUP',
  SET_DATASET_LIST_FOR_CUSTOM_TRAINING: 'SET_DATASET_LIST_FOR_CUSTOM_TRAINING',
  SET_SETTING_LIST: 'SET_SETTING_LIST',
  SET_INFERENCE_ALGORITHM_LIST: 'SET_INFERENCE_ALGORITHM_LIST',
  SET_TRAINING_ALGORITHM_DISPLAY_CONDITION:
    'SET_TRAINING_ALGORITHM_DISPLAY_CONDITION',
  CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION:
    'CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION',
  SET_INHERIT_DATASET_DISPLAY_CONDITION:
    'SET_INHERIT_DATASET_DISPLAY_CONDITION',
  CLEAR_INHERIT_DATASET_DISPLAY_CONDITION:
    'CLEAR_INHERIT_DATASET_DISPLAY_CONDITION',
  CLEAR_INFERENCE_ALGORITHM_DISPLAY_CONDITION:
    'CLEAR_INFERENCE_ALGORITHM_DISPLAY_CONDITION',
  SET_SOURCE_MODEL_DISPLAY_CONDITION: 'SET_SOURCE_MODEL_DISPLAY_CONDITION',
  CLEAR_BASE_MODEL_DISPLAY_CONDITION: 'CLEAR_BASE_MODEL_DISPLAY_CONDITION',
  SET_DATASET_DISPLAY_CONDITION: 'SET_DATASET_DISPLAY_CONDITION',
  CLEAR_DATASET_DISPLAY_CONDITION: 'CLEAR_DATASET_DISPLAY_CONDITION',
  SET_SETTING_DISPLAY_CONDITION: 'SET_SETTING_DISPLAY_CONDITION',
  CLEAR_SETTING_DISPLAY_CONDITION: 'CLEAR_SETTING_DISPLAY_CONDITION',
  SET_SELECTED_CLASS_SET_FOR_CUSTOM_TRAINING:
    'SET_SELECTED_CLASS_SET_FOR_CUSTOM_TRAINING',
  SET_SELECTED_TRAINING_ALGORITHM_ID: 'SET_SELECTED_TRAINING_ALGORITHM_ID',
  SET_SELECTED_TRAINING_ALGORITHM_VERSION:
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION',
  SET_SELECTED_BASE_MODEL: 'SET_SELECTED_BASE_MODEL',
  SET_SELECTED_INHERIT_MODEL: 'SET_SELECTED_INHERIT_MODEL',
  SET_SELECTED_DATASET: 'SET_SELECTED_DATASET',
  SET_SELECTED_SETTING: 'SET_SELECTED_SETTING',
  SET_SELECTED_SETTING_FORMAT: 'SET_SELECTED_SETTING_FORMAT',
  SET_SELECTED_CUSTOM_TRAINING_GENERATE_KIND:
    'SET_SELECTED_CUSTOM_TRAINING_GENERATE_KIND',
  // SET_SELECTED_INFERENCE_ALGORITHM: 'SET_SELECTED_INFERENCE_ALGORITHM',
  SET_ML_PIPELINE_META_DATA: 'SET_ML_PIPELINE_META_DATA',
  SET_CUSTOM_MODEL_META_DATA: 'SET_CUSTOM_MODEL_META_DATA',
  SET_TRAINING_ALGORITHM_SUB_STATE: 'SET_TRAINING_ALGORITHM_SUB_STATE',
  SET_CLASS_SET_SUB_STATE_FOR_CUSTOM_TRAINING:
    'SET_CLASS_SET_SUB_STATE_FOR_CUSTOM_TRAINING',
  SET_BASE_MODEL_SUB_STATE: 'SET_BASE_MODEL_SUB_STATE',
  SET_DATASET_SUB_STATE: 'SET_DATASET_SUB_STATE',
  SET_SETTING_SUB_STATE: 'SET_SETTING_SUB_STATE',
  SET_OUTPUT_FORMAT_SUB_STATE: 'SET_OUTPUT_FORMAT_SUB_STATE',
  SET_META_DATA_SUB_STATE: 'SET_META_DATA_SUB_STATE',
  SET_CUSTOM_TRAINING_STATE: 'SET_CUSTOM_TRAINING_STATE',
  CLEAR_SELECTED_BASE_MODEL: 'CLEAR_SELECTED_BASE_MODEL',
  CLEAR_SELECTED_DATASET: 'CLEAR_SELECTED_DATASET',
  CLEAR_SELECTED_SETTING: 'CLEAR_SELECTED_SETTING',
  CLEAR_SELECTED_CUSTOM_TRAINING_GENERATE_KIND:
    'CLEAR_SELECTED_CUSTOM_TRAINING_GENERATE_KIND',
  CLEAR_SELECTED_INFERENCE_ALGORITHM_KIND:
    'CLEAR_SELECTED_INFERENCE_ALGORITHM_KIND',
  CLEAR_SELECTED_INFERECE_ALGORITHM: 'CLEAR_SELECTED_INFERECE_ALGORITHM',
  CLEAR_ML_PIPELINES_META_DATA: 'CLEAR_ML_PIPELINES_META_DATA',
  CLEAR_CUSTOM_MODEL_META_DATA: 'CLEAR_CUSTOM_MODEL_META_DATA',
  CLEAR_CURRENT_CUSTOM_TRAINING_STATE: 'CLEAR_CURRENT_CUSTOM_TRAINING_STATE',
  EXEC_CUSTOM_TRAINING_SUCCESS: 'EXEC_CUSTOM_TRAINING_SUCCESS',
  EXEC_CUSTOM_TRAINING_FAILURE: 'EXEC_CUSTOM_TRAINING_FAILURE',
  SET_EXECUTED_INFO: 'SET_EXECUTED_INFO',
  SET_IN_PROGRESS: 'SET_IN_PROGRESS',
  SET_IS_TRANSFER_FOR_CUSTOM_TRAINING: 'SET_IS_TRANSFER_FOR_CUSTOM_TRAINING',
  SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID_FOR_CUSTOM_TRAINING:
    'SET_SELECTED_BASE_INFERENCE_CONTAINER_IMAGE_ID_FOR_CUSTOM_TRAINING',
  SET_SOURCE_MODEL_GROUP_DISPLAY_CONDITION:
    'SET_SOURCE_MODEL_GROUP_DISPLAY_CONDITION',
  SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION:
    'SET_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION',
  SET_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING:
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING',
  CLEAR_CLASS_SET_LIST_FOR_CUSTOM_TRAINING:
    'CLEAR_CLASS_SET_LIST_FOR_CUSTOM_TRAINING',
  CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING:
    'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_CUSTOM_TRAINING',
  CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION:
    'CLEAR_DESTINATION_MODEL_GROUP_DISPLAY_CONDITION',
  CLEAR_SOURCE_MODEL_GROUP_DISPLAY_CONDITION:
    'CLEAR_SOURCE_MODEL_GROUP_DISPLAY_CONDITION',
  SET_SELECTED_TRAINED_MODEL_VERSION: 'SET_SELECTED_TRAINED_MODEL_VERSION',
  SET_SELECTED_INFERENCE_ALGORITHM: 'SET_SELECTED_INFERENCE_ALGORITHM',
  SET_INFERENCE_ALGORITHM_VERSIONS_FOR_CUSTOM_TRAINING:
    'SET_INFERENCE_ALGORITHM_VERSIONS_FOR_CUSTOM_TRAINING',
  SET_IS_SYSTEM_EVALUATION_FOR_CUSTOM_TRAINING:
    'SET_IS_SYSTEM_EVALUATION_FOR_CUSTOM_TRAINING',
  SET_CURRENT_DATASET_LIST_SNAPSHOT: 'SET_CURRENT_DATASET_LIST_SNAPSHOT',
  SET_OBJECT_CLASSIFICATION_EXTENDED_SETTING:
    'SET_OBJECT_CLASSIFICATION_EXTENDED_SETTING',
  CLEAR_OBJECT_CLASSIFICATION_EXTENDED_SETTING:
    'CLEAR_OBJECT_CLASSIFICATION_EXTENDED_SETTING',
  SET_PRE_PROCESS_KIND: 'SET_PRE_PROCESS_KIND',
  SET_SELECTED_DATASET_TEMPLATE_ID: 'SET_SELECTED_DATASET_TEMPLATE_ID',
  SET_OBJECT_RECOGNITION_EXTENDED_SETTING:
    'SET_OBJECT_RECOGNITION_EXTENDED_SETTING',
} as const

export interface SettingSchema {
  $schema: string
  title: string
  description: string
  type: string
  properties: {
    pre_process_kind: {
      type: string
      enum: PreProcessKind[]
      default: string
    }
    advanced: {
      type: string
      properties: {
        base_size: {
          type: string
          properties: {
            height: { type: 'integer'; default: number }
            width: { type: 'integer'; default: number }
          }
          required: string[]
        }
      }
      required: string[]
    }
  }
  required: ['pre_process_kind', 'advanced']
  additionalProperties: false
}

export type PreProcessKind =
  | 'NONE'
  | 'PRESERVE_ASPECT_RATIO'
  | 'IGNORE_ASPECT_RATIO'

export interface MetaData {
  name?: string
  remarks?: string
}

export interface MLPipelineSrcType {
  algorithmKind: string
  trainingAlgorithmId: string
  trainingAlgorithmTag: string
  baseModelId: string
  skippedTrainingProcess: boolean
  datasetId: string
  settingId: string
  destType: string
  edgeAlgorithmId: string
  edgeAlgorithmTag: string
  trainingContainerImageId: string
}

export interface MLPipelineType {
  mlPipelineKind: string
  destModelKind: string
  transactionStatus: string
  startedAt: Timestamp
  endedAt: Timestamp
  userGroupId: string
  src: MLPipelineSrcType
}

export interface CustomTrainingParamsType {
  baseModel: {
    trainedModelId: string
    trainedModelGroupId: string
    userGroupId: string
  }
  trainingAlgorithmId: string
  algorithmVersion: VersionWithPreRelease
  inheritedVersion?: {
    trainedModelGroupId: string
    trainedModelGroupVersion: Version
    trainedModelId: string
    userGroupId: string
  }
  datasetId: string
  settingId: string
  inferenceAlgorithmVersion?: VersionWithPreRelease
  baseContainerImageIdList: string[]
  trainedModelGroupId: string
  trainedModelGroupVersion: Version
  isTransfer: boolean
  isSystemEvaluation: boolean
  mlPipelineMetadata: {
    name: string
    remarks?: string
  }
  trainedModelMetadata: {
    name: string
    remarks: string
  }
  destType: string
  containerInterfaceVersion?: Version
  extended?: {
    objectRecognition?: {
      annotationTargeting: {
        enabled: boolean
        hiddenPercentage: number
      }
    }
    objectClassification?: {
      classSet: {
        classSetId: string
        userGroupId: string
      }
      setting: {
        preProcessKind: PreProcessKind
        baseSize: {
          height: number
          width: number
        }
        settingFormatId: string
        settingFormatVersion: Version
      }
    }
  }
}

export interface EdgeAlgorithmListType {
  id: string
  description: string
}

export interface TrainingAlgorithmListType {
  id: string
  tag: string
  description: string
  relatedAlgorithm: string[]
}

export interface BaseModelListType {
  id: string
  trainingAlgorithm: TrainingAlgorithmListType
}

export interface TrainedModel {
  trainedModelId: string
  trainedModelGroupVersion: {
    displayName: string
    major: number
    minor: number
    patch: number
  }
  trainedModelName: string
  transactionStatus: string
  algorithmStructureId: string
  algorithmStructureVersion: Version
  generationType: GenerationType
  datasets: {
    datasetId: string
    name: string
    generation: number
    datasetTemplate: DatasetTemplate
    createdAt?: Timestamp
    remarks: string
  }[]
}

export interface Dataset {
  datasetId: string
  name: string
  createdAt?: Timestamp
  remarks: string
}

export interface DatasetTemplate {
  datasetTemplateId: string
  metadata: {
    name: I18nString
  }
}

export interface Setting {
  settingId: string
  name: string
  createdAt?: Timestamp
  remarks: string
  settingFormatId: string
  settingFormatVersion: Version
  /** trueの場合、セッティング一覧でグレーアウトされる */
  disabled?: boolean
  settingGroupName: string
  settingGroupVersion: Version
  datasetTemplate: DatasetTemplate
}

export interface SettingFormat {
  settingFormatId: string
  settingFormatVersion: Version
}

export interface DisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface SortDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
}

export interface DatasetList {
  'dataset-id': string
  generation: number
  'generation-type': GenerationType
  'user-group-id': string
}

export interface TrainedModelDataItem {
  'trained-model-id': string
  'trained-model-name': string
  'trained-model-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
}

export interface CustomTrainingState {
  trainingAlgorithmSubState: 'Unselected' | 'Selected'
  classSetSubState: 'Unselected' | 'Selected'
  modelGroupSubState: 'Unselected' | 'Selected'
  baseModelSubState: 'Unselected' | 'Selected' | 'NotFound'
  datasetSubState: 'Unselected' | 'Selected'
  settingSubState: 'Unselected' | 'Selected'
  outputFormatSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState:
    | 'BeforeExecute'
    | 'ExecuteInProgress'
    | 'Executed'
    | 'ExecuteError'
}

export const CustomTrainingStateKindArray = [
  'TrainingAlgorithmState',
  'ModelGroupState',
  'BaseModelState',
  'SettingState',
  'DatasetState',
  'OutputFormatState',
  'MetaDataState',
  'ExecuteState',
]

export type CustomTrainingStateKind =
  (typeof CustomTrainingStateKindArray)[number]

export interface Constraint {
  setting: {
    settingGroupId: string
    userGroupId: string
  }
}
export interface TrainedModelGroup {
  trainedModelGroupId: string // モデルグループID
  trainedModelGroupName: string // モデルグループ名
  trainedModelCount: number // 登録モデル数
  latestTrainedModelVersion: string // 最新モデルバージョン
  latestTrainedModelName: string // 最新モデル名
  updatedAt?: Timestamp // 更新日
  createdAt?: Timestamp // 作成日
  createdBy: string // 作成者
  trainedModels: TrainedModel[] // モデルの配列
  algorithmId: string
  constraint?: Constraint
  userGroupId: string // ユーザグループID
}

export interface AlgorithmMetadata {
  name?: string // アルゴリズム名
  overview?: string // アルゴリズムの概要
}

export interface ContainerImage {
  baseInferenceContainerImageId: string // 推論コンテナイメージID
  containerImageTags: string[] // 推論コンテナイメージタグ(ex. cpu, cpu/arm64)
  containerImagePlatform: {
    architecture: string
    os: string
  }
}

export interface InferenceAlgorithmVersion {
  inferenceAlgorithmVersion: string // 推論アルゴリズムバージョン
  algorithmVersion: VersionWithPreRelease
  // メタデータ
  metadata: {
    remarks: string // アルゴリズムの備考
  }
  releasedAt: Timestamp // リリース日時
  baseInferenceContainerImages: ContainerImage[]
  inferenceCodeVersion: VersionWithBuild
  containerInterfaceVersion: Version
}

export interface ExecutionInfo {
  mlPipelineId?: string
  trainingStepId?: string
}

export interface TrainedModelGroupLists {
  sharedUserGroup: TrainedModelGroup[]
  userGroup: TrainedModelGroup[]
}

/** クラスセット */
export interface ClassSet {
  /** クラスセットID */
  classSetId: string
  /** クラスセット名 */
  classSetName: string
  /** クラスセット備考 */
  classSetRemarks: string
  /** クラス名リスト */
  classList: string[]
  /** 登録日時 */
  createdAt: Timestamp
  /** ユーザグループ ID */
  userGroupId: string
}

/** クラスセット選択時の表示条件 */
export interface ClassSetDisplayCondition extends DisplayCondition {
  /** データ種別 */
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

/** セッティングの付加情報 */
export interface ObjectClassificationExtendedSetting {
  /** 前処理種別 */
  preProcessKind: 'NONE' | 'PRESERVE_ASPECT_RATIO' | 'IGNORE_ASPECT_RATIO'
  /** ベースサイズ */
  baseSize: {
    /** ベースサイズ横 */
    width: number
    /** ベースサイズ縦 */
    height: number
  }
}

export interface ObjectRecognitionExtendedSetting {
  isEnabledAnnotationTargeting: boolean
  hiddenPercentage: number
}
export interface ExtendedSetting {
  objectRecognition?: ObjectRecognitionExtendedSetting
  objectClassification?: ObjectClassificationExtendedSetting
}

export interface CurrentCustomTrainingDomainData {
  selectedTrainingAlgorithm?: {
    algorithmId: string // 選択中の学習アルゴリズム
  }
  selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion // 選択されたアルゴリズムのバージョン配列
  destinationTrainedModelGroups: TrainedModelGroup[] // 登録先モデルグループの配列
  sourceTrainedModelGroups: TrainedModelGroupLists // ベースモデルのモデルグループ
  selectedSourceTrainedModelGroup?: TrainedModelGroup //  選択されたモデルグループ
  selectedSourceTrainedModel?: TrainedModel // 選択中のモデル
  selectedInheritTrainedModel?: TrainedModel // 選択中の継承モデル
  extendedSetting: ExtendedSetting // 付加情報
  selectedClassSet?: ClassSet // 選択中のクラスセット
  datasetList: Dataset[] // データセット一覧
  settingList: Setting[] // セッティング一覧
  classSets: ClassSet[] // クラスセット一覧
  selectedDataset?: Dataset // 選択中のデータセット
  selectedSetting?: Setting // 選択中のセッティング
  selectedSettingFormat?: SettingFormat // セッティングフォーマット
  selectedDatasetTemplateId: string // 選択中のデータセットテンプレートID
  preProcessKinds?: PreProcessKind[] // 前処理種別
  selectedDestinationTrainedModelGroup?: TrainedModelGroup // 選択されたモデルの登録先モデルグループの情報
  selectedCustomTrainingGenerateKind?: 'EdgeImage' | 'CustomModel' // 選択中のアウトプット形式
  inferenceAlgorithmVersions: InferenceAlgorithmVersion[] // 推論アルゴリズム一覧
  selectedInferenceAlgorithmVersion?: InferenceAlgorithmVersion // 選択された推論アルゴリズムバージョン
  selectedBaseInferenceContainerImageIds: string[] // 選択された推論コンテナイメージID
  isTransfer: boolean // AWSへの転送可否
  isSystemEvaluation: boolean // docker image を評価するかどうか
  mlPipelinesMetaData?: MetaData // 入力されたカスタム学習情報のメタデータ
  customModelMetaData?: MetaData // 入力されたカスタムモデル情報のメタデータ
  selectedCustomModelVersion?: Version // 選択されたモデルバージョン
  executionInfo?: ExecutionInfo // カスタム学習を実行時に生成された ML PipelineのID
  trainingAlgorithmVersionDisplayCondition: SortDisplayCondition // 学習アルゴリズムの表示条件
  sourceModelGroupDisplayCondition: DisplayCondition & {
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  } // ベースモデルのモデルグループの検索条件
  classSetDisplayCondition: ClassSetDisplayCondition // クラスセット一覧の検索条件
  sourceModelDisplayCondition: DisplayCondition // ベースモデルのモデルの検索条件
  destinationModelDisplayCondition: DisplayCondition // 継承モデルのモデルの検索条件
  datasetDisplayCondition: DisplayCondition // データセットの検索条件
  inheritDatasetDisplayCondition: SortDisplayCondition // 継承データセットの検索条件
  settingDisplayCondition: DisplayCondition // セッティングの検索条件
  destinationModelGroupDisplayCondition: DisplayCondition // 登録先モデルグループの検索条件
  currentDatasetListSnapshot?: () => void
}

export interface CurrentCustomTrainingAppState {
  customTrainingState: CustomTrainingStateKind // カスタム学習画面のSTEP
  customTrainingSubState: CustomTrainingState // カスタム学習画面の各種STEPの状態
  inProgress: boolean // データ取得中、カスタム学習実行中フラグ
}

export interface CurrentCustomTrainingState {
  domainData: CurrentCustomTrainingDomainData
  appState: CurrentCustomTrainingAppState
}
