import { Dispatch } from 'redux'
import { State } from 'state/store'
import { featureDataGeneratingListActions } from './actions'
import {
  getMlPipelineQueriesCollection,
  getDatasetQueryCollection,
  getFeatureDataQueriesCollection,
} from 'state/firebase'
import {
  FeatureDataGenerating,
  FeatureDataGeneratingDisplayCondition,
} from './types'
import {
  convertProgressRateByTransactionStatusForFeatureDataGenerating,
  convertQueryStartEndCodeBySearchValue,
} from 'state/utils'
import { isUndefined } from 'utils/typeguard'
import { Algorithm } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataGeneratingMLPipelineQueryDocument } from 'utils/fireStore/featureDataGeneratingMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForFeatureDataQueryDocument } from 'utils/fireStore/featureDataQuery'

const createFeatureDataGeneratingData = async (
  ugid: string,
  snapShot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[]
): Promise<(FeatureDataGenerating | undefined)[]> =>
  // 関連のDocsを取得し表示用の特徴量データ生成一覧を生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const mlPipelineQueryDocData = document.data()
      if (
        !fireStoreTypeGuardForFeatureDataGeneratingMLPipelineQueryDocument(
          mlPipelineQueryDocData
        )
      ) {
        return undefined
      }

      // 生成特徴量データ名取得
      let featureDataData = undefined
      let datasetData = undefined

      // 特徴量データ生成名取得
      const algorithm = algorithms.find((algorithm) => {
        return (
          algorithm.algorithmId ===
          mlPipelineQueryDocData['training-step']?.['src']?.['algorithm-id']
        )
      })
      if (
        mlPipelineQueryDocData['training-step']?.['dest']?.['feature-data-id']
      ) {
        featureDataData = (
          await getDoc(
            doc(
              getFeatureDataQueriesCollection(ugid),
              mlPipelineQueryDocData['training-step']['dest']['feature-data-id']
            )
          )
        ).data()
        if (!fireStoreTypeGuardForFeatureDataQueryDocument(featureDataData)) {
          return undefined
        }
      }

      if (mlPipelineQueryDocData['training-step']?.['src']) {
        datasetData = (
          await getDoc(
            doc(
              getDatasetQueryCollection(ugid),
              mlPipelineQueryDocData['training-step']['src']['dataset-id']
            )
          )
        ).data()

        if (!fireStoreTypeGuardForDatasetQueryDocument(datasetData)) {
          return undefined
        }
      }

      return {
        mlPipelineId: mlPipelineQueryDocData['ml-pipeline']['ml-pipeline-id'],
        mlPipelineName: mlPipelineQueryDocData['ml-pipeline-metadata']['name'],
        algorithmName: algorithm ? algorithm.metadata.name.ja : '',
        progress: {
          transactionStatus:
            mlPipelineQueryDocData['ml-pipeline']['transaction-status'],
          progressRate:
            convertProgressRateByTransactionStatusForFeatureDataGenerating(
              mlPipelineQueryDocData['ml-pipeline']['transaction-status']
            ),
        },
        startedAt: mlPipelineQueryDocData['ml-pipeline']['started-at'],
        endedAt:
          mlPipelineQueryDocData['ml-pipeline']['ended-at'] &&
          mlPipelineQueryDocData['ml-pipeline']['ended-at'].seconds === 0
            ? undefined
            : mlPipelineQueryDocData['ml-pipeline']['ended-at'],
        dataset: {
          datasetId: datasetData
            ? mlPipelineQueryDocData['training-step']['src']['dataset-id']
            : '',
          datasetName: datasetData ? datasetData['dataset-name'] : '',
        },
        featureData: {
          featureDataId: featureDataData
            ? mlPipelineQueryDocData['training-step']['dest']
              ? mlPipelineQueryDocData['training-step']['dest'][
                  'feature-data-id'
                ]
              : ''
            : '',
          featureDataName: featureDataData
            ? featureDataData['feature-data-name']
            : '',
        },
        accountId: mlPipelineQueryDocData['created-by'],
      } as FeatureDataGenerating
    })
  )

export const onUpdateMlPipelineQueryDocument = async (
  dispatch: Dispatch,
  ugid: string,
  snapshot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[],
  mlPipelineIds: string[],
  condition: FeatureDataGeneratingDisplayCondition,
  mlPipeLineDoc: DocumentData | undefined
) => {
  const featureDataGeneratingData = await createFeatureDataGeneratingData(
    ugid,
    snapshot,
    algorithms
  )
  // ML Pipeline IDを保持
  // すでに保持しているIDが存在する場合は、現状の検索位置以降のIDを一度破棄し
  // 新たに取得したML Pipeline IDを保持する
  if (featureDataGeneratingData.length >= 0) {
    if (mlPipeLineDoc) {
      const index = mlPipelineIds.findIndex((id) => id === mlPipeLineDoc?.id)
      const beforePageIds = mlPipelineIds.slice(0, index + 1)
      dispatch(
        featureDataGeneratingListActions.setMLPipeLineIdList([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        featureDataGeneratingListActions.setMLPipeLineIdList([
          ...mlPipelineIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
  }
  // 取得した特徴量データ生成の一覧を保持
  dispatch(
    featureDataGeneratingListActions.setList(
      featureDataGeneratingData.filter(
        (item) => item !== undefined
      ) as FeatureDataGenerating[]
    )
  )
  let totalCountQuery = query(
    getMlPipelineQueriesCollection(ugid),
    where('ml-pipeline.ml-pipeline-kind', '==', 'FeatureDataGenerating')
  )
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )
    totalCountQuery = query(
      totalCountQuery,
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  }
  const totalCount = await getCountFromServer(totalCountQuery)
  dispatch(
    featureDataGeneratingListActions.setListDisplayCondition({
      ...condition,
      totalCount: totalCount.data().count,
    })
  )
}

// 特徴量データ生成一覧の配列をセット
const getFeatureDataGeneratingRelationDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const ugid =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId

  const algorithms = getState().app.domainData.algorithms
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.featureDataGeneratingListState.domainData
      .currentFeatureDataGeneratingListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      featureDataGeneratingListActions.setCurrentFeatureDataGeneratingListSnapshot(
        undefined
      )
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.featureDataGeneratingListState.domainData
      .featureDataGeneratingDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery = query(
    getMlPipelineQueriesCollection(ugid),
    limit(condition.displayNumber),
    where('ml-pipeline.ml-pipeline-kind', '==', 'FeatureDataGenerating')
  )

  // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、MLPipelineIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('ml-pipeline.ml-pipeline-id', 'asc'),
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  } else {
    commonQuery = query(
      commonQuery,
      orderBy(condition.sortKey, condition.sortOrder)
    )
  }

  const mlPipelineIds =
    getState().pages.featureDataGeneratingListState.domainData.mlPipelineIds
  // 取得済みの特徴量データ生成の最後尾
  let mlPipeLineDoc: DocumentData | undefined = undefined
  if (mlPipelineIds.length > 0) {
    mlPipeLineDoc = await getDoc(
      doc(
        getMlPipelineQueriesCollection(ugid),
        mlPipelineIds[mlPipelineIds.length - 1]
      )
    )
  }

  // 取得済みの特徴量データ生成が存在する場合は、取得済みの最後尾以降のデータから取得する
  if (mlPipeLineDoc) {
    commonQuery = query(commonQuery, startAfter(mlPipeLineDoc))
  }

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      onUpdateMlPipelineQueryDocument(
        dispatch,
        ugid,
        snapshot,
        algorithms,
        mlPipelineIds,
        condition,
        mlPipeLineDoc
      )
    }
  )

  // 現在、表示中の特徴量データ生成（ML Pipeline Docsの Snapshot を保持）
  dispatch(
    featureDataGeneratingListActions.setCurrentFeatureDataGeneratingListSnapshot(
      snapshot
    )
  )
}

export const featureDataGeneratingListOperations = {
  /** リストを取得する */
  getFeatureDataGeneratingList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(featureDataGeneratingListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentMlPipelineIds: string[] =
          getState().pages.featureDataGeneratingListState.domainData
            .mlPipelineIds
        const condition =
          getState().pages.featureDataGeneratingListState.domainData
            .featureDataGeneratingDisplayCondition
        const mlPipelineIds = currentMlPipelineIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(
          featureDataGeneratingListActions.setMLPipeLineIdList(mlPipelineIds)
        )

        // 特徴量データ生成一覧に必要な関連Docsを取得
        await getFeatureDataGeneratingRelationDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(featureDataGeneratingListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.featureDataGeneratingListState.domainData
            .currentFeatureDataGeneratingListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
