import { Dispatch } from 'redux'
import {
  getDatasetQueryCollection,
  getTrainingImagesCollection,
  getTrainingDataCollection,
  getGroupedDataCollection,
} from 'state/firebase'
import { State } from 'state/store'
import { annotationSetDetailActions } from './actions'
import {
  Timestamp,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import {
  AnnotationSet,
  fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument,
} from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForTrainingImagesDocument } from 'utils/fireStore/trainingImage'
import { fireStoreTypeGuard as fireStoreTypeGuardForTrainingDataDocument } from 'utils/fireStore/trainingData'
import { fireStoreTypeGuard as fireStoreTypeGuardForGroupedDataDocument } from 'utils/fireStore/groupedData'
import axios from 'axios'
import { annotationSetDetailApi } from './apis'
import {
  AnnotationFileData,
  AnnotationResults,
  GetAnnotationFileDataResponse,
  RleArrayNumberMask,
  RleArrayNumberSegmentation,
  RleStringMask,
  RleStringSegmentation,
  TrainingData,
} from './types'
import { getArrayChunk } from 'state/utils'

// Firestore の collection に対する where in の上限数
const FIRESTORE_WHERE_IN_QUOTA = 10

const isCurrentAnnotationSet = (
  annotationSetId: string,
  getState: () => State
): boolean =>
  annotationSetId ===
  getState().pages.annotationSetDetailState.domainData
    .currentAnnotationSetDetail.annotationSetId

export const AnnotationSetDetailOperations = {
  /** リストを取得する */
  getAnnotationSetDetail:
    (datasetId: string, annotationSetId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(annotationSetDetailActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        /** datasetのデータ */
        const datasetData = (
          await getDoc(doc(getDatasetQueryCollection(userGroupId), datasetId))
        ).data()
        if (!datasetData) {
          dispatch(
            annotationSetDetailActions.setAnnotationSetState(
              'NotFoundProcessed'
            )
          )
          return
        }
        if (!fireStoreTypeGuardForDatasetQueryDocument(datasetData)) {
          dispatch(annotationSetDetailActions.setAnnotationSetState('Failed'))
          return
        }
        const algorithm = getState().app.domainData.algorithms.find(
          (algorithm) => algorithm.algorithmId === datasetData['algorithm-id']
        )

        const annotationSet = datasetData['annotation-set-list'].find(
          (item: AnnotationSet) => item['annotation-set-id'] === annotationSetId
        )

        if (!annotationSet) {
          dispatch(
            annotationSetDetailActions.setAnnotationSetState(
              'NotFoundProcessed'
            )
          )
        }

        // grouped-data取得
        const groupedData = (
          await getDoc(
            doc(
              getGroupedDataCollection(userGroupId),
              annotationSet['grouped-data-id'] ?? ''
            )
          )
        ).data()
        if (!groupedData) {
          dispatch(
            annotationSetDetailActions.setAnnotationSetState(
              'NotFoundProcessed'
            )
          )
          return
        }
        if (!fireStoreTypeGuardForGroupedDataDocument(groupedData)) {
          return undefined
        }

        if (datasetData['generated-for'] == 'Inference') {
          dispatch(
            annotationSetDetailActions.setCurrentAnnotationSetDetail({
              algorithm: {
                algorithmId: datasetData['algorithm-id'],
                metadata: {
                  name: algorithm?.metadata.name ?? { en: '', ja: '' },
                },
              },
              annotationFileData: undefined,
              annotationFileName: '',
              annotationResults: {},
              annotationSetId: annotationSetId,
              annotationSetKind: annotationSet['annotation-set-kind'],
              selectedTrainingDataFileName: '',
              trainingDataList: groupedData['training-data-list'].map(
                (trainingDataId: string) => {
                  return {
                    id: trainingDataId,
                    fileName: '',
                    thumbnailUrl: '',
                    processedUrl: '',
                  }
                }
              ),
              conditions: undefined,
              trainingImageCount: groupedData['training-data-list'].length,
            })
          )
          dispatch(
            annotationSetDetailActions.setAnnotationDisplayCondition({
              mask: false,
              bbox: false,
              label: false,
              selectedIds: [],
            })
          )
          setTrainingDataChunk({
            annotationSetId,
            trainingDataList: groupedData['training-data-list'] as string[],
            userGroupId,
            dispatch,
            getState,
          })
          dispatch(annotationSetDetailActions.setAnnotationSetState('Loaded'))
          return
        }

        let annotationFileData = undefined
        try {
          // ファイル名、ダウンロードリンク取得
          annotationFileData = await annotationSetDetailApi.getAnnotationFile(
            annotationSet['annotation-id']
          )
        } catch (error) {
          dispatch(
            annotationSetDetailActions.setAnnotationSetState(
              'NotFoundProcessed'
            )
          )
          return
        }

        const annotationFile = annotationFileData?.data as {
          name: string
          fileNameForDownload: string
        }

        // アノテーションファイルをダウンロード
        dispatch(
          annotationSetDetailActions.setInProgressForGettingAnnotationFile(true)
        )
        const getAnnotationFileDataResponse = (
          await axios.get<GetAnnotationFileDataResponse>(
            annotationFile.fileNameForDownload
          )
        ).data
        dispatch(
          annotationSetDetailActions.setInProgressForGettingAnnotationFile(
            false
          )
        )
        const annotationFileJson = convertAnnotationFileDataResponse(
          getAnnotationFileDataResponse
        )

        // 初期表示の画像のアノテーションデータをパースする
        dispatch(
          annotationSetDetailActions.setInProgressForGeneratingAnnotationResults(
            true
          )
        )

        const trainingDataListItem = await getTrainingImageInfoList(
          userGroupId,
          [groupedData['training-data-list'][0]]
        )
        const firstImageFileName = trainingDataListItem[0].fileName
        const firstImageId = annotationFileJson.images.find(
          (item) => item.file_name === firstImageFileName
        )?.id
        const annotationResults: { [key: string]: AnnotationResults[] } = {
          [firstImageFileName]: convertAnnotationFileData(
            dispatch,
            annotationFileJson,
            firstImageId ?? ''
          ),
        }
        dispatch(
          annotationSetDetailActions.setInProgressForGeneratingAnnotationResults(
            false
          )
        )

        const trainKind = annotationSet['conditions']?.['train-kind']

        dispatch(
          annotationSetDetailActions.setCurrentAnnotationSetDetail({
            algorithm: {
              algorithmId: datasetData['algorithm-id'],
              metadata: {
                name: algorithm?.metadata.name ?? { en: '', ja: '' },
              },
            },
            annotationFileData: annotationFileJson,
            annotationFileName: annotationFile.name,
            annotationResults,
            annotationSetId,
            annotationSetKind: annotationSet['annotation-set-kind'],
            selectedTrainingDataFileName: firstImageFileName,
            trainingDataList: groupedData['training-data-list'].map(
              (trainingDataId: string) => {
                return {
                  id: trainingDataId,
                  fileName: '',
                  thumbnailUrl: '',
                  processedUrl: '',
                }
              }
            ),
            conditions: trainKind ? { trainKind } : undefined,
            trainingImageCount: groupedData['training-data-list'].length,
          })
        )

        setTrainingDataChunk({
          annotationSetId,
          trainingDataList: groupedData['training-data-list'],
          userGroupId,
          dispatch,
          getState,
        })

        dispatch(annotationSetDetailActions.setAnnotationSetState('Loaded'))
      } catch (error) {
        console.error(error)
        dispatch(annotationSetDetailActions.setAnnotationSetState('Failed'))
      } finally {
        dispatch(annotationSetDetailActions.setInProgress(false))
        dispatch(
          annotationSetDetailActions.setInProgressForGeneratingAnnotationResults(
            false
          )
        )
        dispatch(
          annotationSetDetailActions.setInProgressForGettingAnnotationFile(
            false
          )
        )
      }
    },
  setSelectedImage:
    (imageId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        annotationSetDetailActions.setInProgressForGeneratingAnnotationResults(
          true
        )
      )
      const selectedImageFileName =
        getState().pages.annotationSetDetailState.domainData.currentAnnotationSetDetail.trainingDataList.find(
          (item) => item.id === imageId
        )?.fileName ?? ''
      if (selectedImageFileName === '') return

      const { annotationResults, annotationFileData } =
        getState().pages.annotationSetDetailState.domainData
          .currentAnnotationSetDetail

      if (annotationFileData == null) {
        dispatch(
          annotationSetDetailActions.setSelectedImageFileName(
            selectedImageFileName
          )
        )
        dispatch(
          annotationSetDetailActions.setInProgressForGeneratingAnnotationResults(
            false
          )
        )
        return
      }

      const targetLabelIds: string[] = []

      // キーの存在チェック
      if (
        Object.keys(annotationResults).indexOf(selectedImageFileName) === -1
      ) {
        const targetAnnotationImageId =
          annotationFileData.images.find(
            (item) => item.file_name === selectedImageFileName
          )?.id ?? ''
        const targetAnnotationResult = convertAnnotationFileData(
          dispatch,
          annotationFileData,
          targetAnnotationImageId
        )
        const newAnnotationResults = {
          ...getState().pages.annotationSetDetailState.domainData
            .currentAnnotationSetDetail.annotationResults,
          [selectedImageFileName]: targetAnnotationResult,
        }
        dispatch(
          annotationSetDetailActions.setAnnotationResults(newAnnotationResults)
        )

        // 切り替え先画像のラベルID一覧を取得
        targetAnnotationResult.forEach((item) => {
          if (targetLabelIds.indexOf(item.label.id) === -1)
            targetLabelIds.push(item.label.id)
        })
      } else {
        // 切り替え先画像のラベルID一覧を取得
        getState().pages.annotationSetDetailState.domainData.currentAnnotationSetDetail.annotationResults[
          selectedImageFileName
        ].forEach((item) => {
          if (targetLabelIds.indexOf(item.label.id) === -1)
            targetLabelIds.push(item.label.id)
        })
      }

      // 画像切り替え前に選択していたラベルIDが、切り替え先の画像にも存在する場合は、そのラベルIDを引き継ぐ
      const hasSelectedLabelId = targetLabelIds.includes(
        getState().pages.annotationSetDetailState.domainData.annotationsDisplayCondition.selectedIds.at(
          0
        ) ?? ''
      )
      dispatch(
        annotationSetDetailActions.setAnnotationDisplayCondition({
          ...getState().pages.annotationSetDetailState.domainData
            .annotationsDisplayCondition,
          selectedIds: hasSelectedLabelId
            ? getState().pages.annotationSetDetailState.domainData
                .annotationsDisplayCondition.selectedIds
            : [],
        })
      )

      dispatch(
        annotationSetDetailActions.setSelectedImageFileName(
          selectedImageFileName
        )
      )
      dispatch(
        annotationSetDetailActions.setInProgressForGeneratingAnnotationResults(
          false
        )
      )
    },
}

const getTrainingImageInfoList = async (
  userGroupId: string,
  trainingImageIdList: string[]
): Promise<TrainingData[]> => {
  const originalFileNames: { id: string; fileName: string }[] = []
  const thumbnailFileNames: { id: string; fileName: string }[] = []
  const imageFileInfoDict: {
    [id: string]: {
      id: string
      createdAt: Timestamp
    }
  } = {}

  const trainingImageList = await getDocs(
    query(
      getTrainingImagesCollection(userGroupId),
      where('training-data-id', 'in', trainingImageIdList)
    )
  )
  const trainingDataList = await getDocs(
    query(
      getTrainingDataCollection(userGroupId),
      where('training-data-id', 'in', trainingImageIdList)
    )
  )

  trainingImageList.docs.forEach((doc) => {
    try {
      const trainingImageData = doc.data()
      if (!fireStoreTypeGuardForTrainingImagesDocument(trainingImageData)) {
        return
      }
      const trainingDataId = trainingImageData['training-data-id']
      imageFileInfoDict[trainingDataId] = {
        id: trainingDataId,
        createdAt: trainingImageData['created-at'],
      }

      thumbnailFileNames.push({
        id: trainingDataId,
        fileName: 'thumbnail.jpg',
      })
    } catch (error) {
      console.error(error)
    }
  })

  trainingDataList.docs.forEach((doc) => {
    try {
      const trainingData = doc.data()
      if (!fireStoreTypeGuardForTrainingDataDocument(trainingData)) {
        return
      }
      const docFileName = trainingData['file-name']
      originalFileNames.push({
        id: trainingData['training-data-id'],
        fileName: typeof docFileName === 'string' ? docFileName : '',
      })
    } catch (error) {
      console.error(error)
    }
  })

  const imageFileInfoList = trainingImageIdList
    .map((id) => imageFileInfoDict[id])
    .filter((fileInfo) => fileInfo !== undefined)

  // signedUrl取得
  let thumbnailUrls: { [id: string]: string } = {}

  if (thumbnailFileNames.length > 0) {
    thumbnailUrls = await annotationSetDetailApi.getSignedUrls(
      thumbnailFileNames,
      'read',
      'thumbnail'
    )
  }
  const originalUrls = await annotationSetDetailApi.getSignedUrls(
    originalFileNames,
    'read',
    'original'
  )

  return imageFileInfoList.map((fileInfo) => {
    const thumbnailUrl = thumbnailUrls[fileInfo.id]
    const originalUrl = originalUrls[fileInfo.id]
    const fileName = originalFileNames.find(
      (originalFileName) => originalFileName.id === fileInfo.id
    )?.fileName

    return {
      id: fileInfo.id,
      thumbnailUrl: thumbnailUrl ? thumbnailUrl : '',
      processedUrl: originalUrl ? originalUrl : '',
      fileName: fileName ?? '',
    }
  })
}

/**
 * アノテーションファイルを必要な分だけ所持する
 */
const convertAnnotationFileDataResponse = (
  annotationFileDataResponse: GetAnnotationFileDataResponse
): AnnotationFileData => ({
  annotations: annotationFileDataResponse.annotations.map((annotation) => ({
    id: annotation.id,
    image_id: annotation.image_id,
    category_id: annotation.category_id,
    bbox: annotation.bbox,
    iscrowd: annotation.iscrowd,
    area: annotation.area,
    segmentation: annotation.segmentation,
  })),
  images: annotationFileDataResponse.images.map((image) => ({
    id: image.id,
    width: image.width,
    height: image.height,
    file_name: image.file_name,
  })),
  categories: annotationFileDataResponse.categories.map((category) => ({
    id: category.id,
    name: category.name,
  })),
})

/**
 * JSONをパースした結果から、対象のファイル名の情報をAnnotationResults[]にして返す
 */
const convertAnnotationFileData = (
  dispatch: Dispatch,
  annotationFileData: AnnotationFileData,
  targetImageId: string | number
): AnnotationResults[] => {
  const convertedAnnotationFileData: AnnotationResults[] = []

  const targetAnnotations = annotationFileData.annotations.filter(
    (annotation) => annotation.image_id === targetImageId
  )
  targetAnnotations.forEach((annotation) => {
    const label = annotationFileData.categories.find(
      (category) => category.id === annotation.category_id
    )
    const labelId = label?.name.split(':').at(0)
    const labelName = label?.name.split(':').at(1) ?? labelId
    const area = annotation.area
    let segmentation:
      | RleStringSegmentation
      | RleArrayNumberSegmentation
      | undefined = undefined
    if (isRleStringMask(annotation.segmentation)) {
      segmentation = {
        type: 'RLEString',
        mask: annotation.segmentation,
      }
    } else if (isRleArrayNumberMask(annotation.segmentation)) {
      segmentation = {
        type: 'RLEArrayNumber',
        mask: annotation.segmentation,
      }
    } else {
      dispatch(
        annotationSetDetailActions.setToastInfo({
          type: 'warning',
          title: '非対応のマスク情報です',
          targets: [],
        })
      )
    }

    convertedAnnotationFileData.push({
      imageId: annotation.image_id,
      bbox: annotation.bbox,
      label: {
        id: labelId ?? '',
        name: labelName ?? '',
      },
      area,
      segmentation: segmentation ?? {
        type: 'RLEString',
        mask: {
          size: [],
          counts: '',
        },
      },
    })
  })

  return convertedAnnotationFileData
}

const isRleStringMask = (mask: unknown): mask is RleStringMask =>
  typeof mask === 'object' &&
  mask !== null &&
  Array.isArray((mask as RleStringMask).size) &&
  typeof (mask as RleStringMask).counts === 'string'

const isRleArrayNumberMask = (mask: unknown): mask is RleArrayNumberMask =>
  typeof mask === 'object' &&
  mask !== null &&
  Array.isArray((mask as RleStringMask).size) &&
  Array.isArray((mask as RleStringMask).counts)

/** trainingDataListを10件ずつstateに反映する */
const setTrainingDataChunk = async (props: {
  annotationSetId: string
  trainingDataList: string[]
  userGroupId: string
  dispatch: Dispatch
  getState: () => State
}) => {
  const { trainingDataList, userGroupId, dispatch } = props
  // 10件ずつに分割する
  const trainingImageChunkList = getArrayChunk(
    trainingDataList ?? [],
    FIRESTORE_WHERE_IN_QUOTA
  )

  getTrainingImage: for (const [
    trainingImageIdChunkIndex,
    trainingImageIdChunk,
  ] of trainingImageChunkList.entries()) {
    const trainingImageInfo = await getTrainingImageInfoList(
      userGroupId,
      trainingImageIdChunk
    )

    const updatedTrainingImageList =
      props.getState().pages.annotationSetDetailState.domainData
        .currentAnnotationSetDetail.trainingDataList

    updatedTrainingImageList.splice(
      trainingImageIdChunkIndex * FIRESTORE_WHERE_IN_QUOTA,
      trainingImageIdChunk.length,
      ...trainingImageInfo
    )

    if (!isCurrentAnnotationSet(props.annotationSetId, props.getState)) {
      break getTrainingImage
    }

    dispatch(
      annotationSetDetailActions.setTrainingDataList(updatedTrainingImageList)
    )
  }
}
