import { Dispatch } from 'redux'
import { datasetListActions } from '.'
import { DatasetInfo, AnnotationSet } from './types'
import { State } from 'state/store'
import {
  getDatasetQueryCollection,
  getGroupedDataCollection,
  getTrainingImagesCollection,
} from 'state/firebase'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { convertQueryStartEndCodeBySearchValue } from 'state/utils'
import { getSignedUrls } from './apis'
import { Timestamp } from '@firebase/firestore'

// データセット一覧を生成
const createDatasetList = async (
  datasetQuery: Query<DocumentData>,
  getState: () => State
): Promise<(DatasetInfo | undefined)[]> => {
  const docs = (await getDocs(datasetQuery)).docs
  if (!docs) return []
  return await Promise.all(
    // データセットリストの生成
    docs.map(async (doc: DocumentData) => {
      const dataset = doc.data()
      if (!fireStoreTypeGuardForDatasetQueryDocument(dataset)) {
        return undefined
      }
      const datasetTemplate =
        getState().app.domainData.datasetTemplates &&
        getState().app.domainData.datasetTemplates.find(
          (template) =>
            template.datasetTemplateId === dataset['dataset-template-id']
        )
      const annotationFormat =
        getState().app.domainData.annotationFormats &&
        getState().app.domainData.annotationFormats.find(
          (format) =>
            format.annotationFormatId === dataset['annotation-format-id']
        )
      // データセット一覧を返す
      return {
        datasetId: dataset['dataset-id'] ? dataset['dataset-id'] : doc.id,
        datasetName: dataset['dataset-name'],
        createdAt: dataset['created-at'] ?? undefined,
        createdBy: dataset['created-by'],
        datasetRemarks: dataset['dataset-remarks'],
        algorithmId: dataset['algorithm-id'],
        generatedFor: dataset['generated-for'],
        datasetTemplateName:
          (datasetTemplate && datasetTemplate.metadata.name.ja) ?? '',
        annotationFormatId: dataset['annotation-format-id'],
        annotationFormatKind:
          (annotationFormat && annotationFormat.annotationFormatKind) ?? '',
        annotationFormatVersion: {
          displayName: dataset['annotation-format-version']['display-name'],
          major: dataset['annotation-format-version']['major'],
          minor: dataset['annotation-format-version']['minor'],
          patch: dataset['annotation-format-version']['patch'],
        },

        annotationSetList: doc.data()['annotation-set-list']
          ? doc
              .data()
              ['annotation-set-list'].map((annotationSet: AnnotationSet) => {
                return {
                  annotationSetId: annotationSet['annotation-set-id'],
                  annotationSetKind: annotationSet['annotation-set-kind'],
                  groupedDataId: annotationSet['grouped-data-id'],
                  annotationId: annotationSet['annotation-id'],
                }
              })
          : [],
        updatedAt: dataset['updated-at'],
        updatedBy: dataset['updated-by'],
      } as DatasetInfo
    })
  )
}

export const datasetListOperations = {
  /** リストを取得する */
  getDatasetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(datasetListActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        // 表示条件取得
        const condition =
          getState().pages.datasetListState.domainData
            .datasetListDisplayCondition
        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentDatasetList =
          getState().pages.datasetListState.domainData.currentDatasetList

        const qLimit =
          condition.displayNumber * (condition.pageNumber + 1) -
            currentDatasetList.length >
          0
            ? condition.displayNumber * (condition.pageNumber + 1) -
              currentDatasetList.length
            : condition.displayNumber * (condition.pageNumber + 1)

        // Datasetsを表示件数分取得
        let datasetQuery = query(
          getDatasetQueryCollection(userGroupId),
          where('algorithm-id', '==', condition.selectedAlgorithmId),
          where('generated-for', '==', condition.generatedFor)
        )

        // 作成者フィルタ
        if (condition.createdBy) {
          datasetQuery = query(
            datasetQuery,
            where('created-by', '==', condition.createdBy)
          )
        }

        // データセットテンプレートフィルタ
        if (condition.datasetTemplateId) {
          datasetQuery = query(
            datasetQuery,
            where('dataset-template-id', '==', condition.datasetTemplateId)
          )
        }

        // 文字列検索が存在する場合は、datasetIdの前方一致条件をQueryに設定
        if (condition.searchValue) {
          const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
            condition.searchValue
          )

          datasetQuery = query(
            datasetQuery,
            orderBy('dataset-id', 'asc'),
            where('dataset-id', '>=', startCode),
            where('dataset-id', '<=', endCode)
          )
        }

        // 作成日時の開始日時フィルタ
        if (condition.createdAt.from.enabled) {
          const fromTimeStamp = Timestamp.fromDate(
            new Date(
              `${condition.createdAt.from.date} ${condition.createdAt.from.time}`
            )
          )
          datasetQuery = query(
            datasetQuery,
            where('created-at', '>=', fromTimeStamp)
          )
        }

        // 作成日時の終了日時フィルタ
        if (condition.createdAt.to.enabled) {
          const toTimeStamp = Timestamp.fromDate(
            new Date(
              `${condition.createdAt.to.date} ${condition.createdAt.to.time}`
            )
          )

          datasetQuery = query(
            datasetQuery,
            where('created-at', '<=', toTimeStamp)
          )
        }

        const totalCount = await getCountFromServer(datasetQuery)

        dispatch(
          datasetListActions.setListDisplayCondition({
            ...condition,
            totalCount: totalCount.data().count,
          })
        )

        datasetQuery = query(
          datasetQuery,
          limit(qLimit),
          orderBy(condition.sortKey, condition.sortOrder)
        )

        // 既に取得していれば最後の要素から取得
        let lastItem: DocumentData | undefined = undefined
        if (currentDatasetList.length) {
          lastItem = await getDoc(
            doc(
              getDatasetQueryCollection(userGroupId),
              currentDatasetList[currentDatasetList.length - 1].datasetId
            )
          )
          datasetQuery = query(datasetQuery, startAfter(lastItem))
        }

        // データセット一覧を取得
        const newDatasetList: (DatasetInfo | undefined)[] =
          await createDatasetList(datasetQuery, getState)

        dispatch(
          datasetListActions.setDatasetList([
            ...currentDatasetList,
            ...(newDatasetList.filter(
              (item) => item !== undefined
            ) as DatasetInfo[]),
          ])
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(datasetListActions.setInProgress(false))
      }
    },
  /** サムネイルを取得 */
  getThumbnails:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const currentDatasetList =
          getState().pages.datasetListState.domainData.currentDatasetList

        const thumbnails =
          getState().pages.datasetListState.domainData.thumbnails

        if (Object.keys(thumbnails).length === currentDatasetList.length) {
          return
        }

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const displayCondition =
          getState().pages.datasetListState.domainData
            .datasetListDisplayCondition

        const getThumbnails = currentDatasetList
          .slice(displayCondition.pageNumber * displayCondition.displayNumber)
          .map(async (dataset) => {
            try {
              const groupedDataId = dataset.annotationSetList[0]?.groupedDataId

              if (!groupedDataId) {
                return { [dataset.datasetId]: undefined }
              }

              const groupedData = (
                await getDoc(
                  doc(getGroupedDataCollection(userGroupId), groupedDataId)
                )
              ).data()

              const trainingDataId: string = groupedData
                ? groupedData['training-data-list'][0]
                : ''

              if (!trainingDataId) {
                return { [dataset.datasetId]: undefined }
              }

              const trainingImage = (
                await getDoc(
                  doc(getTrainingImagesCollection(userGroupId), trainingDataId)
                )
              ).data()

              if (!trainingImage) {
                return { [dataset.datasetId]: undefined }
              }

              const thumbnailUrl = await getSignedUrls([
                {
                  id: trainingDataId,
                  fileName: 'thumbnail.jpg',
                },
              ])
              dispatch(
                datasetListActions.setThumbnails({
                  [dataset.datasetId]: thumbnailUrl[trainingDataId],
                })
              )
            } catch (error) {
              console.error(error)
            }
          })

        await Promise.all(getThumbnails)
      } catch (error) {
        console.error(error)
      }
    },
}
