import {
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  Action,
} from 'state/utils/actions-utils'
import { ResourceLoadingState } from 'state/utils/types'
import {
  AccountInfo,
  AccountDetailActionType,
  CustomerListDisplayCondition,
  CustomerListPagingState,
  AccountDeleteSubState,
  AccountName,
  AccountUpdateRoleSubState,
  Customer,
  AccountUpdateCustomerListSubState,
  CustomerRaw,
} from './types'
import { AccountGroupRole } from 'views/utils/types'

export type AccountDetailAction = ActionsUnion<typeof accountDetailActions>

export const accountDetailActions = {
  setAccountInfo: (
    data: AccountInfo
  ): ActionWithPayload<'SET_ACCOUNT_INFO', { data: AccountInfo }> =>
    CreateAction(AccountDetailActionType.SET_ACCOUNT_INFO, {
      data,
    }),
  setCustomerList: (
    data: CustomerRaw[]
  ): ActionWithPayload<'SET_CUSTOMER_LIST', { data: CustomerRaw[] }> =>
    CreateAction(AccountDetailActionType.SET_CUSTOMER_LIST, {
      data,
    }),
  setCustomerListDisplayCondition: (
    customerListDisplayCondition: CustomerListDisplayCondition
  ): ActionWithPayload<
    'SET_CUSTOMER_LIST_DISPLAY_CONDITION',
    { customerListDisplayCondition: CustomerListDisplayCondition }
  > =>
    CreateAction(AccountDetailActionType.SET_CUSTOMER_LIST_DISPLAY_CONDITION, {
      customerListDisplayCondition,
    }),
  setCustomerListPagingState: (
    customerListPageState: CustomerListPagingState
  ): ActionWithPayload<
    'SET_CUSTOMER_LIST_PAGING_STATE',
    { customerListPageState: CustomerListPagingState }
  > =>
    CreateAction(AccountDetailActionType.SET_CUSTOMER_LIST_PAGING_STATE, {
      customerListPageState,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_ACCOUNT_DETAIL',
    { inProgress: boolean }
  > =>
    CreateAction(AccountDetailActionType.SET_IN_PROGRESS_FOR_ACCOUNT_DETAIL, {
      inProgress,
    }),
  setAccountDeleteState: (
    data: AccountDeleteSubState
  ): ActionWithPayload<
    'SET_ACCOUNT_DELETE_SUB_STATE',
    { data: AccountDeleteSubState }
  > =>
    CreateAction(AccountDetailActionType.SET_ACCOUNT_DELETE_SUB_STATE, {
      data,
    }),
  setAccountUpdateRoleState: (
    data: AccountUpdateRoleSubState
  ): ActionWithPayload<
    'SET_ACCOUNT_UPDATE_ROLE_SUB_STATE',
    { data: AccountUpdateRoleSubState }
  > =>
    CreateAction(AccountDetailActionType.SET_ACCOUNT_UPDATE_ROLE_SUB_STATE, {
      data,
    }),
  setAccountUpdateCustomerListState: (
    data: AccountUpdateCustomerListSubState
  ): ActionWithPayload<
    'SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE',
    { data: AccountUpdateCustomerListSubState }
  > =>
    CreateAction(
      AccountDetailActionType.SET_ACCOUNT_UPDATE_CUSTOMER_LIST_SUB_STATE,
      {
        data,
      }
    ),
  setUpdateAccountName: (
    data: AccountName
  ): ActionWithPayload<'SET_UPDATE_ACCOUNT_NAME', { data: AccountName }> =>
    CreateAction(AccountDetailActionType.SET_UPDATE_ACCOUNT_NAME, {
      data,
    }),
  setUpdateAccountGroupRole: (
    data: AccountGroupRole
  ): ActionWithPayload<
    'SET_UPDATE_ACCOUNT_GROUP_ROLE_FOR_ACCOUNT_DETAIL',
    { data: AccountGroupRole }
  > =>
    CreateAction(
      AccountDetailActionType.SET_UPDATE_ACCOUNT_GROUP_ROLE_FOR_ACCOUNT_DETAIL,
      {
        data,
      }
    ),
  updateAccountCustomerRelations: (
    data: Customer[]
  ): ActionWithPayload<
    'UPDATE_ACCOUNT_CUSTOMER_RELATIONS',
    { data: Customer[] }
  > =>
    CreateAction(AccountDetailActionType.UPDATE_ACCOUNT_CUSTOMER_RELATIONS, {
      data,
    }),
  setAccountDataSubState: (
    data: ResourceLoadingState
  ): ActionWithPayload<
    'SET_ACCOUNT_DATA_SUB_STATE',
    { data: ResourceLoadingState }
  > =>
    CreateAction(AccountDetailActionType.SET_ACCOUNT_DATA_SUB_STATE, {
      data,
    }),
  clearAccountDetailState: (): Action<'CLEAR_ACCOUNT_DETAIL_STATE'> =>
    CreateAction(AccountDetailActionType.CLEAR_ACCOUNT_DETAIL_STATE),
}
