import React from 'react'
import { TooltipLinkProps } from './types'
import { Tooltip, Link } from '@mui/material'

import { styled, useTheme } from '@mui/material/styles'

export const TooltipLink: React.FC<TooltipLinkProps> = (
  props: TooltipLinkProps
) => {
  const MuiLink = styled(Link)({
    ...useTheme().typography.body1,
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  }) as typeof Link

  return (
    <Tooltip
      title={props.title}
      placement={props.placement}
      data-testid={props['data-testid'] + '-name'}
    >
      <MuiLink
        onClick={props.onClick}
        underline='none'
        data-testid={props['data-testid'] + '-link'}
      >
        {props.title.substring(0, 8)}
      </MuiLink>
    </Tooltip>
  )
}
