import { ToastInfo } from 'state/utils'

// ActionType
export const FeatureDataGroupEntryActionType = {
  SET_SELECTED_ALGORITHM: 'SET_SELECTED_ALGORITHM',
  SET_SELECTED_FEATURE_DATA_KIND: 'SET_SELECTED_FEATURE_DATA_KIND',
  SET_FEATURE_DATA_GROUP_META_DATA_NAME:
    'SET_FEATURE_DATA_GROUP_META_DATA_NAME',
  SET_FEATURE_DATA_GROUP_META_DATA_OVER_VIEW:
    'SET_FEATURE_DATA_GROUP_META_DATA_OVER_VIEW',
  SET_FEATURE_DATA_GROUP_META_DATA_ICON:
    'SET_FEATURE_DATA_GROUP_META_DATA_ICON',
  SET_FEATURE_DATA_GROUP_META_DATA_REMARKS:
    'SET_FEATURE_DATA_GROUP_META_DATA_REMARKS',
  SET_FEATURE_DATA_GROUP_STATE: 'SET_FEATURE_DATA_GROUP_STATE',
  SET_ALGORITHM_SUB_STATE: 'SET_ALGORITHM_SUB_STATE',
  SET_METADATA_SUB_STATE: 'SET_METADATA_SUB_STATE',
  SET_EXECUTE_FEATURE_DATA_GROUP_SUB_STATE:
    'SET_EXECUTE_FEATURE_DATA_GROUP_SUB_STATE',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_ENTRY:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_ENTRY',
  SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_ENTRY:
    'SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_ENTRY',
  SET_GENERATED_FEATURE_DATA_GROUP_ID: 'SET_GENERATED_FEATURE_DATA_GROUP_ID',
  CLEAR_FEATURE_DATA_GROUP_ENTRY_STATE: 'CLEAR_FEATURE_DATA_GROUP_ENTRY_STATE',
} as const

export interface FeatureDataGroupMetaData {
  featureDataGroupName: string
  overView?: string
  featureDataGroupIcon?: File
  remarks?: string
}

export interface FeatureDataGroupEntrySubStateKind {
  algorithmSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError'
}

export const FeatureDataGroupEntryStateKindArray = [
  'AlgorithmState',
  'MetaDataState',
  'ExecuteState',
]

export type FeatureDataGroupEntryStateKind =
  (typeof FeatureDataGroupEntryStateKindArray)[number]

export interface FeatureDataGroupEntryDomainData {
  selectedAlgorithm?: string // 選択中のアルゴリズム
  featureDataGroupMetaData: FeatureDataGroupMetaData // 入力されたモデルのメタデータ
  generatedFeatureDataGroupId: string // 作成されたモデルグループID
}

export interface FeatureDataGroupEntryAppState {
  inProgress: boolean // データ取得中、実行中フラグ
  toastInfo?: ToastInfo // トーストの表示する情報
  featureDataGroupEntryStateKind: FeatureDataGroupEntryStateKind // モデルグループ作成画面のSTEP
  featureDataGroupEntrySubStateKind: FeatureDataGroupEntrySubStateKind // モデルグループ作成画面の各種STEPの状態
}

export interface FeatureDataGroupEntryState {
  domainData: FeatureDataGroupEntryDomainData
  appState: FeatureDataGroupEntryAppState
}
