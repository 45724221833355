import { Timestamp } from 'state/firebase'
import {
  TrainingAlgorithmVersion,
  AlgorithmStructureVersion,
} from 'state/app/domainData'
import { VersionWithPreRelease, Version } from 'types/StateTypes'

// ActionType
export const FeatureDataGeneratingActionType = {
  SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_GENERATING:
    'SET_FEATURE_DATA_GROUP_LIST_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_FEATURE_DATA_GROUP_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_ALGORITHM_STRUCTURE_VERSION_FOR_FEATURE_DATA_GENERATING',
  SET_DATASET_LIST_FOR_FEATURE_DATA_GENERATING:
    'SET_DATASET_LIST_FOR_FEATURE_DATA_GENERATING',
  SET_SETTING_LIST_FOR_FEATURE_DATA_GENERATING:
    'SET_SETTING_LIST_FOR_FEATURE_DATA_GENERATING',
  SET_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'SET_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_TRAINING_ALGORITHM_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  SET_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'SET_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  CLEAR_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_DATASET_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  SET_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'SET_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  CLEAR_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_SETTING_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING:
    'SET_FEATURE_DATA_GROUP_DISPLAY_CONDITION_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_TRAINING_ALGORITHM_ID_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_TRAINING_ALGORITHM_VERSION_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_DATASET_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_DATASET_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_SETTING_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_SETTING_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_FEATURE_DATA_GENERATE_KIND_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_FEATURE_DATA_GENERATE_KIND_FOR_FEATURE_DATA_GENERATING',
  SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_GENERATING:
    'SET_ML_PIPELINE_META_DATA_FOR_FEATURE_DATA_GENERATING',
  SET_FEATURE_DATA_META_DATA_FOR_FEATURE_DATA_GENERATING:
    'SET_FEATURE_DATA_META_DATA_FOR_FEATURE_DATA_GENERATING',
  SET_TRAINING_ALGORITHM_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
    'SET_TRAINING_ALGORITHM_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
  SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
    'SET_FEATURE_DATA_GROUP_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
  SET_DATASET_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
    'SET_DATASET_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
  SET_SETTING_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
    'SET_SETTING_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
  SET_OUTPUT_FORMAT_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
    'SET_OUTPUT_FORMAT_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
  SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_GENERATING:
    'SET_META_DATA_SUB_STATE_FOR_FEATURE_DATA_GENERATING',
  SET_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING:
    'SET_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING',
  CLEAR_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_GENERATING',
  CLEAR_SELECTED_DATASET_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_SELECTED_DATASET_FOR_FEATURE_DATA_GENERATING',
  CLEAR_SELECTED_SETTING_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_SELECTED_SETTING_FOR_FEATURE_DATA_GENERATING',
  CLEAR_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_GENERATING_GENERATE_KIND_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_SELECTED_FEATURE_DATA_FOR_FEATURE_DATA_GENERATING_GENERATE_KIND_FOR_FEATURE_DATA_GENERATING',
  CLEAR_ML_PIPELINES_META_DATA_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_ML_PIPELINES_META_DATA_FOR_FEATURE_DATA_GENERATING',
  CLEAR_FEATURE_DATA_META_DATA_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_FEATURE_DATA_META_DATA_FOR_FEATURE_DATA_GENERATING',
  CLEAR_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING:
    'CLEAR_FEATURE_DATA_GENERATING_STATE_FOR_FEATURE_DATA_GENERATING',
  EXEC_FEATURE_DATA_GENERATING_SUCCESS_FOR_FEATURE_DATA_GENERATING:
    'EXEC_FEATURE_DATA_GENERATING_SUCCESS_FOR_FEATURE_DATA_GENERATING',
  EXEC_FEATURE_DATA_GENERATING_FAILURE_FOR_FEATURE_DATA_GENERATING:
    'EXEC_FEATURE_DATA_GENERATING_FAILURE_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_FEATURE_DATA_GROUP_VERSION_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_FEATURE_DATA_GROUP_VERSION_FOR_FEATURE_DATA_GENERATING',
  SET_EXECUTED_INFO_FOR_FEATURE_DATA_GENERATING:
    'SET_EXECUTED_INFO_FOR_FEATURE_DATA_GENERATING',
  SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING:
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GENERATING',
  SET_SELECTED_FEATURE_DATA_VERSION_FOR_FEATURE_DATA_GENERATING:
    'SET_SELECTED_FEATURE_DATA_VERSION_FOR_FEATURE_DATA_GENERATING',
  SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_FEATURE_DATA_GENERATING:
    'SET_CURRENT_DATASET_LIST_SNAPSHOT_FOR_FEATURE_DATA_GENERATING',
} as const

export interface MetaData {
  name?: string
  remarks?: string
}

export interface FeatureDataGeneratingParamsType {
  trainingAlgorithmId: string
  algorithmVersion: VersionWithPreRelease
  datasetId: string
  settingId: string
  featureDataGroupId: string
  featureDataGroupVersion: Version
  mlPipelineMetadata: {
    name?: string
    remarks?: string
  }
  featureDataMetadata: {
    name?: string
    remarks?: string
  }
  algorithmStructureId: string
  algorithmStructureVersion: Version
}

export interface EdgeAlgorithmListType {
  id: string
  description: string
}

export interface TrainingAlgorithmListType {
  id: string
  tag: string
  description: string
  relatedAlgorithm: string[]
}

export interface Dataset {
  datasetId: string
  name: string
  createdAt?: Timestamp
  remarks: string
}

export interface Setting {
  settingId: string
  name: string
  createdAt?: Timestamp
  remarks: string
}

export interface DisplayCondition {
  searchValue: string
  sortKey: string
  sortOrder: 'asc' | 'desc'
  displayNumber: number
  pageNumber: number
}

export interface AlgorithmDisplayCondition {
  sortKey: string
  sortOrder: 'asc' | 'desc'
}

export interface FeatureDataList {
  'feature-data-id': string
  'feature-data-name': string
  'feature-data-group-version': {
    'display-name': string
    major: number
    minor: number
    patch: number
  }
  'transaction-status': string
}

export interface FeatureDataState {
  trainingAlgorithmSubState: 'Unselected' | 'Selected'
  featureDataGroupSubState: 'Unselected' | 'Selected'
  datasetSubState: 'Unselected' | 'Selected'
  settingSubState: 'Unselected' | 'Selected'
  outputFormatSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState:
    | 'BeforeExecute'
    | 'ExecuteInProgress'
    | 'Executed'
    | 'ExecuteError'
}

export const FeatureDataGeneratingStateKindArray = [
  'TrainingAlgorithmState',
  'FeatureDataGroupState',
  'DatasetState',
  'SettingState',
  'OutputFormatState',
  'MetaDataState',
  'ExecuteState',
]

export type FeatureDataGeneratingStateKind =
  (typeof FeatureDataGeneratingStateKindArray)[number]

export interface FeatureDataGroup {
  featureDataGroupId: string // 特徴量データグループID
  featureDataGroupName: string // 特徴量データグループ名
  featureDataCount: number // 登録特徴量データ数
  latestFeatureDataVersion: string // 最新特徴量データバージョン
  latestFeatureDataName: string // 最新特徴量データ名
  updatedAt?: Timestamp // 更新日
  createdAt?: Timestamp // 作成日
  createdBy: string // 作成者
}

export interface AlgorithmMetadata {
  name?: string // アルゴリズム名
  overview?: string // アルゴリズムの概要
}

export interface ExecutionInfo {
  mlPipelineId?: string
  trainingStepId?: string
}

export interface FeatureDataGeneratingDomainData {
  selectedTrainingAlgorithm?: {
    algorithmId: string // 選択中の学習アルゴリズム
  }
  selectedTrainingAlgorithmVersion?: TrainingAlgorithmVersion // 選択されたアルゴリズムのバージョン配列
  selectedAlgorithmStructureVersion?: AlgorithmStructureVersion // 選択中のアルゴリズムストラクチャーバージョン
  featureDataGroups: FeatureDataGroup[] // 特徴量データグループの配列
  selectedFeatureDataGroup?: FeatureDataGroup //  選択された特徴量データグループ
  datasetList: Dataset[] // データセット一覧
  settingList: Setting[] // セッティング一覧
  selectedDataset?: Dataset // 選択中のデータセット
  selectedSetting?: Setting // 選択中のセッティング
  mlPipelinesMetaData?: MetaData // 入力されたカスタム学習情報のメタデータ
  featureDataMetaData?: MetaData // 入力された特徴量データ情報のメタデータ
  selectedFeatureDataGenerateKind?: 'All' | 'FeatureDataOnly' // 選択中のアウトプット形式
  executionInfo?: ExecutionInfo // 特徴量データを実行時に生成された ML PipelineのID
  trainingAlgorithmVersionDisplayCondition: AlgorithmDisplayCondition // 学習アルゴリズムの表示条件
  datasetDisplayCondition: DisplayCondition // データセットの検索条件
  settingDisplayCondition: DisplayCondition // セッティングの検索条件
  featureDataGroupDisplayCondition: DisplayCondition // ベース特徴量データの特徴量データグループの検索条件
  selectedFeatureDataGroupVersion?: Version // 選択された特徴量データバージョン
  currentDatasetListSnapshot?: () => void
}

export interface FeatureDataGeneratingAppState {
  featureDataGeneratingState: FeatureDataGeneratingStateKind // 特徴量データ画面のSTEP
  featureDataGeneratingSubState: FeatureDataState // 特徴量データ画面の各種STEPの状態
  inProgress: boolean // データ取得中、特徴量データ実行中フラグ
}

export interface FeatureDataGeneratingState {
  domainData: FeatureDataGeneratingDomainData
  appState: FeatureDataGeneratingAppState
}
