import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link as MuiLink,
  Tooltip,
} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'
import {
  MediaLink,
  ModelDetailAction,
  modelDetailActions,
  ModelDetailOperations,
  ModelListDisplayCondition,
  RelatedTrainedModel,
} from 'state/ducks/modelDetail'

import { isUndefined } from 'utils/typeguard'
import {
  StatusProgressBar,
  GlobalLoading,
  EditableTextField,
  CopyableLabel,
  DataDetailItem,
  showToast,
  Toast,
  ModelIcon,
  TrainingIcon,
  InferenceIcon,
  BuildIcon,
  SelectableTable,
  SelectableTableHeader,
  TooltipLink,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  ExpandPaper,
  CheckableTable,
  CheckableTableHeader,
  BreadcrumbsComponent,
} from 'views/components'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import { formatDateTimeSec } from 'views/components/utils/date'
import {
  convertByteToMatchUnit,
  convertModelKindWord,
  convertEvaluationStatusWord,
  convertEvaluationStatusColor,
  handleResourceNotFound,
} from 'views/containers/utils'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  hasSharedUserGroupQueryParameter,
  sharedUserGroupQueryParameter,
} from 'views/containers/utils/queryParams'

const mapStateToProps = (state: State) => ({
  ...state.pages.modelDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ModelDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** モデル詳細取得 */
  getModelDetail: (trainedModelId: string, isSharedUserGroup: boolean) =>
    dispatch(
      ModelDetailOperations.getModelDetail(trainedModelId, isSharedUserGroup)
    ),
  /** Stateのクリア */
  clearModelDetailState: () =>
    dispatch(modelDetailActions.clearModelDetailState()),
  /** modelの名前を更新する */
  updateModelName: (docId: string, name: string) =>
    dispatch(ModelDetailOperations.updateModelDetailName(docId, name)),
  /** pipelineのremarksを更新する */
  updatePipelineRemarks: (docId: string, remarks: string) =>
    dispatch(ModelDetailOperations.updateModelDetailRemarks(docId, remarks)),
  /** モデルファイルをダウンロードする */
  downloadModelFile: (links: MediaLink[]) =>
    dispatch(ModelDetailOperations.downloadModelFile(links)),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: ModelListDisplayCondition) =>
    dispatch(modelDetailActions.setListDisplayCondition(listCondition)),
  /** 次ページの活性非活性の切り替え */
  setModelListPagingState: (tableNextPageSubState: 'Enable' | 'Unable') =>
    dispatch(modelDetailActions.setModelListPagingState(tableNextPageSubState)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () => dispatch(modelDetailActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trainedModelId',
    title: 'モデル ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelVersion',
    title: 'バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelName',
    title: 'モデル名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'inheritedVersion',
    title: '継承バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'status',
    title: 'ステータス',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** アーキテクチャーのテーブルの選択状態 */
const INHERITED_DATASET_LIST_HEADERS: CheckableTableHeader[] = [
  {
    id: 'datasetId',
    title: 'データセットID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: 'データセット名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'generation',
    title: '世代',
    width: 75,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkButton: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  noteTabButtonDisabled: {
    backgroundColor: theme.palette.grey[100],
  },
  mt2Box: {
    marginTop: '16px',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionContent: {
    marginBottom: '16px',
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
}))

const AlgorithmSpecificContent: React.FC<Props> = (props: Props) => {
  if (isUndefined(props.domainData.currentTrainedModelDetail?.extended))
    return <></>

  if (
    !isUndefined(
      props.domainData.currentTrainedModelDetail?.extended.objectClassification
    )
  ) {
    return (
      <Box component={Paper} my={2}>
        <Box p={'24px 32px 32px'}>
          <Typography>
            {
              props.domainData.currentTrainedModelDetail.trainingAlgorithm
                .metadata.name.ja
            }
          </Typography>
          <DataDetailItem
            formHelperText='クラスセット'
            endAdornment={
              <CopyableLabel
                value={
                  props.domainData.currentTrainedModelDetail.extended
                    .objectClassification
                    ? props.domainData.currentTrainedModelDetail.extended
                        .objectClassification.classSet.classSetId
                    : ''
                }
              />
            }
            startAdornment={
              props.domainData.currentTrainedModelDetail.extended
                .objectClassification.classSet.classSetName ? (
                <Box sx={{ color: 'text.primary' }}>
                  <Typography>
                    {props.domainData.currentTrainedModelDetail
                      ? props.domainData.currentTrainedModelDetail.extended
                          .objectClassification.classSet.classSetName
                      : ''}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ color: 'text.secondary' }}>
                  <Typography>{NO_NAME}</Typography>
                </Box>
              )
            }
          />
        </Box>
      </Box>
    )
  }

  return <></>
}

const ModelDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  // パスパラメータからモデルIDを取得
  const [selectedTrainedModelId, setSelectedTrainedModelId] =
    useState<string>('')
  // パスパラメータからモデルグループIDを取得
  const [selectedTrainedModelGroupId, setSelectedTrainedModelGroupId] =
    useState<string | undefined>(undefined)

  /** 初期実行 */
  useEffect(() => {
    return () => {
      props.clearModelDetailState()
    }
  }, [])

  useEffect(() => {
    // NOTE: modelId, modelgroupIdがprops.match.paramsに存在しないためwarningとなるがモデル詳細に遷移する際は存在する
    setSelectedTrainedModelId(
      (props.match.params as { [key: string]: string })['modelId']
    )
    setSelectedTrainedModelGroupId(
      (props.match.params as { [key: string]: string })['modelGroupId']
    )
    // 表示用詳細取得
    props.getModelDetail(
      (props.match.params as { [key: string]: string })['modelId'] ?? '',
      isSharedUserGroup
    )
  }, [props.match.params])

  /** モデル詳細備考が変更時にセットする */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.currentTrainedModelDetail) &&
      !isUndefined(
        props.domainData.currentTrainedModelDetail.trainedModelRemarks
      )
    ) {
      setEditingTrainedModelRemarks(
        props.domainData.currentTrainedModelDetail.trainedModelRemarks
      )
      setEditingTrainedModelName(
        props.domainData.currentTrainedModelDetail.trainedModelName
      )
    }
  }, [props.domainData.currentTrainedModelDetail])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )
  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** アクションラベルに表示するコンテンツ */
  // TODO: 推論指示、学習開始画面で渡すクエリパラメータの修正が必要
  const getExpandPaperContents = (): {
    id: string
    element: React.ReactNode
  }[] => [
    {
      id: 'custom-training',
      element: (
        <>
          <TrainingIcon data-testid='TrainingLinkIcon' />
          <MuiLink
            data-testid='use-for-custom-training'
            variant='body1'
            className={classes.linkButton}
            underline='none'
            onClick={() => {
              history.push({
                pathname: '/custom-trainings/entry',
                search: `?trained-model-group-id=${
                  props.domainData.currentTrainedModelDetail
                    ?.trainedModelGroupId
                }&trained-model-id=${
                  props.domainData.currentTrainedModelDetail?.trainedModelId
                }&algorithm-id=${
                  props.domainData.currentTrainedModelDetail?.trainingAlgorithm
                    .algorithmId
                }&algorithm-version=${
                  props.domainData.currentTrainedModelDetail?.trainingAlgorithm
                    .trainingAlgorithmVersion
                }${
                  isSharedUserGroup
                    ? '&shared-user-group=true'
                    : '&shared-user-group=false'
                }`,
              })
            }}
          >
            このモデルを利用してカスタム学習を開始する
          </MuiLink>
        </>
      ),
    },
    {
      id: 'inference',
      element: (
        <>
          <InferenceIcon data-testid='InferenceLinkIcon' />
          <MuiLink
            data-testid='use-for-inference'
            variant='body1'
            className={classes.linkButton}
            underline='none'
            onClick={() => {
              history.push({
                pathname: '/inferences/entry',
                search: `?trained-model-group-id=${
                  props.domainData.currentTrainedModelDetail
                    ?.trainedModelGroupId
                }&trained-model-id=${
                  props.domainData.currentTrainedModelDetail?.trainedModelId
                }&algorithm-id=${
                  props.domainData.currentTrainedModelDetail?.trainingAlgorithm
                    .algorithmId
                }&algorithm-version=${
                  props.domainData.currentTrainedModelDetail?.trainingAlgorithm
                    .trainingAlgorithmVersion
                }${isSharedUserGroup ? '&shared-user-group=true' : ''}`,
              })
            }}
          >
            このモデルを利用して推論をする
          </MuiLink>
        </>
      ),
    },
    {
      id: 'build',
      element: (
        <>
          <BuildIcon data-testid='BuildLinkIcon' />
          <MuiLink
            data-testid='use-for-build'
            variant='body1'
            className={classes.linkButton}
            underline='none'
            onClick={() => {
              history.push({
                pathname: '/builds/entry',
                search: `?trained-model-group-id=${
                  props.domainData.currentTrainedModelDetail
                    ?.trainedModelGroupId
                }&trained-model-id=${
                  props.domainData.currentTrainedModelDetail?.trainedModelId
                }&algorithm-id=${
                  props.domainData.currentTrainedModelDetail?.trainingAlgorithm
                    .algorithmId
                }&algorithm-version=${
                  props.domainData.currentTrainedModelDetail?.trainingAlgorithm
                    .trainingAlgorithmVersion
                }${isSharedUserGroup ? '&shared-user-group=true' : ''}`,
              })
            }}
          >
            このモデルを利用してビルドする
          </MuiLink>
        </>
      ),
    },
  ]

  /** モデルファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.modelDetailState.trainedModelDlLinkSubState === 'Failed'
    ) {
      showErrorToast('モデルファイルの取得に失敗しました。')
    }
  }, [props.appState.modelDetailState.trainedModelDlLinkSubState])
  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(
      props.appState.modelDetailState.trainedModelDataState,
      history
    )
  }, [props.appState.modelDetailState.trainedModelDataState])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition =
      props.domainData.currentTrainedModelDetail.modelListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList =
      props.domainData.currentTrainedModelDetail.relatedTrainedModelList.slice(
        condition.displayNumber * condition.pageNumber,
        condition.displayNumber * condition.pageNumber + condition.displayNumber
      )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentTrainedModelDetail.relatedTrainedModelList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    if (
      condition.displayNumber * (condition.pageNumber + 1) >=
      props.domainData.currentTrainedModelDetail.relatedTrainedModelList.length
    ) {
      props.setModelListPagingState('Unable')
    } else {
      props.setModelListPagingState('Enable')
    }
    return displayList
  }, [
    props.domainData.currentTrainedModelDetail.modelListDisplayCondition,
    props.domainData.currentTrainedModelDetail.relatedTrainedModelList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    if (!tableContent) return
    const convertedList: RelatedTrainedModel[] = tableContent.map(
      (modelList: RelatedTrainedModel) => {
        return {
          trainedModelId: modelList.trainedModelId,
          trainedModelVersion: modelList.trainedModelVersion,
          trainedModelName: modelList.trainedModelName,
          inheritedVersion: modelList.inheritedVersion,
          transactionStatus: undefined, // TODO: v0.3.0ではN/Aとする
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelId') {
          if (value) {
            return (
              <TooltipLink
                key={key}
                data-testid={`model-${value}`}
                title={value ?? 'abc'}
                placement='right-start'
                onClick={() => {
                  if (selectedTrainedModelGroupId) {
                    history.replace(
                      `/model-groups/${selectedTrainedModelGroupId}/models/${value}${
                        isSharedUserGroup ? '?shared-user-group=true' : ''
                      }`
                    )
                  } else {
                    history.push(
                      `/models/${value}${
                        isSharedUserGroup ? '?shared-user-group=true' : ''
                      }`
                    )
                  }
                }}
              />
            )
          } else {
            return (
              <Box key={key} sx={{ color: 'text.secondary' }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'trainedModelVersion') {
          if (value) {
            return <Typography key={key}>{value.displayName}</Typography>
          }
          return (
            <Box key={key} sx={{ color: 'text.secondary' }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'trainedModelName') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: 'text.secondary' }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'inheritedVersion') {
          if (value && value.displayName !== '') {
            return <Typography key={key}>{value.displayName}</Typography>
          }
          return (
            <Box key={key} sx={{ color: 'text.secondary' }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'transactionStatus') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: 'text.secondary' }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else {
          return (
            <Box key={key} sx={{ color: 'text.secondary' }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  const isGeneric = useMemo(
    () =>
      props.domainData.currentTrainedModelDetail.trainedModelKind === 'Generic',
    [props.domainData.currentTrainedModelDetail.trainedModelKind]
  )

  /** テーブル */
  const modelListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.currentTrainedModelDetail
            ? props.domainData.currentTrainedModelDetail
                .modelListDisplayCondition.displayNumber
            : 10
        }
        headers={TABLE_HEADERS}
        rows={tableRows ?? []}
        totalCount={
          props.domainData.currentTrainedModelDetail.relatedTrainedModelList
            .length
        }
        tableHeight={
          2 + TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT
        }
        fixedColumnNumber={0}
        page={
          props.domainData.currentTrainedModelDetail
            ? props.domainData.currentTrainedModelDetail
                .modelListDisplayCondition.pageNumber
            : 0
        }
        sortOrder={{
          key: 'modelVersion',
          order: 'desc',
        }}
        displayNoneRadio={true}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.appState.tableNextPageSubState,
    props.domainData.currentTrainedModelDetail.modelListDisplayCondition,
  ])

  const [nowTab, setNowTab] = useState(0)

  const [editingTrainedModelName, setEditingTrainedModelName] =
    useState<string>('')
  const [editingTrainedModelRemarks, setEditingTrainedModelRemarks] =
    useState<string>('')

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.currentTrainedModelDetail.modelListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentTrainedModelDetail.relatedTrainedModelList
        .length >
      props.domainData.currentTrainedModelDetail.modelListDisplayCondition
        .pageNumber *
        displayNumber
        ? props.domainData.currentTrainedModelDetail.modelListDisplayCondition
            .pageNumber
        : Math.ceil(
            props.domainData.currentTrainedModelDetail.relatedTrainedModelList
              .length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.currentTrainedModelDetail.modelListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })
  }

  const inheritedDatasetRows = useMemo(() => {
    const rows: JSX.Element[][] =
      props.domainData.currentTrainedModelDetail.inheritedDatasetList.map<
        JSX.Element[]
      >((item) => {
        return [
          <Tooltip
            key={'datasetId'}
            data-testid={`dataset-${item.datasetId}`}
            title={item.datasetId}
            placement='bottom'
          >
            <Typography>{item.datasetId.substring(0, 8)}</Typography>
          </Tooltip>,
          <Tooltip key={'name'} title={item.name} placement='bottom'>
            <Typography>{item.name}</Typography>
          </Tooltip>,
          <Tooltip key={'generation'} title={''} placement='bottom'>
            <Typography>{item.generation}</Typography>
          </Tooltip>,
          <Tooltip key={'createdAt'} title={''} placement='bottom'>
            <Typography>
              {formatDateTimeSec(item.createdAt.toDate())}
            </Typography>
          </Tooltip>,
          <Tooltip key={'remarks'} title={''} placement='bottom'>
            <Typography>{item.remarks}</Typography>
          </Tooltip>,
        ]
      })

    return rows
  }, [props.domainData.currentTrainedModelDetail.inheritedDatasetList])

  // 一般情報コンテンツ
  const infoTab: TabItems = {
    label: '一般情報',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <div className={classes.flexAndBetween}>
              <Box display='flex'>
                <Typography>モデル</Typography>
              </Box>
              {props.domainData.currentTrainedModelDetail &&
              props.domainData.currentTrainedModelDetail.trainedModelDlLinks &&
              props.domainData.currentTrainedModelDetail.trainedModelDlLinks
                .length > 0 ? (
                <Box display='flex'>
                  <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                  <MuiLink
                    data-testid='all-download-custom-model'
                    style={{ cursor: 'pointer' }}
                    underline='none'
                    onClick={() => {
                      if (props.domainData.currentTrainedModelDetail) {
                        let mediaLinks: MediaLink[] = []
                        props.domainData.currentTrainedModelDetail.trainedModelDlLinks.forEach(
                          (link) => {
                            mediaLinks = mediaLinks.concat(link.mediaLinks)
                          }
                        )
                        props.downloadModelFile(mediaLinks)
                      }
                    }}
                  >
                    一括ダウンロード
                  </MuiLink>
                </Box>
              ) : (
                <></>
              )}
            </div>
            <div className={classes.flexAndBetween} style={{ marginTop: 5 }}>
              <Box display='flex' flexWrap='wrap'>
                {props.domainData.currentTrainedModelDetail
                  ? props.domainData.currentTrainedModelDetail.trainedModelDlLinks.map(
                      (info, index) => (
                        <Box mr={2} key={index}>
                          <Button
                            data-testid='all-download-custom-model-PTH'
                            variant='outlined'
                            style={{
                              backgroundColor: '#D9E5FF',
                            }}
                            onClick={() =>
                              props.downloadModelFile(info.mediaLinks)
                            }
                          >
                            <FileDownloadOutlinedIcon
                              style={{ marginRight: '4px' }}
                            />
                            <Typography variant='subtitle2' color='primary'>
                              {`${info.linkName}`}
                            </Typography>
                          </Button>
                          <Box className={classes.mediaSizeText}>
                            ({convertByteToMatchUnit(info.totalMediaSize)})
                          </Box>
                        </Box>
                      )
                    )
                  : []}
              </Box>
            </div>
          </Box>
        </Box>
        <Box component={Paper} my={2}>
          <Box p={'24px 32px 32px'}>
            <Typography>学習情報</Typography>
            <DataDetailItem
              formHelperText='ML Pipeline ID'
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentTrainedModelDetail
                      ? props.domainData.currentTrainedModelDetail.mlPipeline
                          .mlPipelineId
                      : ''
                  }
                />
              }
              startAdornment={
                props.domainData.currentTrainedModelDetail.mlPipeline
                  .mlPipelineName ? (
                  <MuiLink
                    data-testid='training-detail'
                    variant='body1'
                    className={classes.linkButton}
                    underline='none'
                    onClick={() => {
                      history.push(
                        `/custom-trainings/${props.domainData.currentTrainedModelDetail?.mlPipeline.mlPipelineId}`
                      )
                    }}
                  >
                    {props.domainData.currentTrainedModelDetail
                      ? props.domainData.currentTrainedModelDetail.mlPipeline
                          .mlPipelineName
                      : ''}
                  </MuiLink>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
            <Box mt={1}>
              <DataDetailItem
                formHelperText='ベースモデル'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentTrainedModelDetail
                        ? props.domainData.currentTrainedModelDetail.baseModel
                            .baseModelId
                        : ''
                    }
                  />
                }
                startAdornment={
                  props.domainData.currentTrainedModelDetail.baseModel
                    .baseModelName ? (
                    <MuiLink
                      data-testid='base-model-detail'
                      variant='body1'
                      className={classes.linkButton}
                      underline='none'
                      onClick={() => {
                        if (selectedTrainedModelGroupId) {
                          history.replace(
                            `/model-groups/${
                              props.domainData.currentTrainedModelDetail
                                .baseModel.baseModelGroupId
                            }/models/${
                              props.domainData.currentTrainedModelDetail
                                .baseModel.baseModelId
                            }${
                              props.domainData.currentTrainedModelDetail
                                .baseModel.isSharedUserGroupBaseModel
                                ? '?shared-user-group=true'
                                : ''
                            }`
                          )
                        } else {
                          history.push(
                            `/models/${
                              props.domainData.currentTrainedModelDetail
                                .baseModel.baseModelId
                            }${
                              props.domainData.currentTrainedModelDetail
                                .baseModel.isSharedUserGroupBaseModel
                                ? '?shared-user-group=true'
                                : ''
                            }`
                          )
                        }
                      }}
                    >
                      {props.domainData.currentTrainedModelDetail.baseModel
                        .baseModelName ?? ''}
                    </MuiLink>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='データセットを継承したバージョン'
                endAdornment={
                  props.domainData.currentTrainedModelDetail.inheritedVersion
                    ?.trainedModelId ? (
                    <CopyableLabel
                      value={
                        props.domainData.currentTrainedModelDetail
                          .inheritedVersion.trainedModelId
                      }
                    />
                  ) : undefined
                }
                startAdornment={
                  props.domainData.currentTrainedModelDetail.inheritedVersion
                    ?.trainedModelGroupVersion.displayName ? (
                    <MuiLink
                      data-testid='inherited-version'
                      variant='body1'
                      className={classes.linkButton}
                      underline='none'
                      onClick={() => {
                        if (selectedTrainedModelGroupId) {
                          history.replace(
                            `/model-groups/${props.domainData.currentTrainedModelDetail.inheritedVersion?.trainedModelGroupId}/models/${props.domainData.currentTrainedModelDetail.inheritedVersion?.trainedModelId}`
                          )
                        } else {
                          history.push(
                            `/models/${props.domainData.currentTrainedModelDetail.inheritedVersion?.trainedModelId}`
                          )
                        }
                      }}
                    >
                      {props.domainData.currentTrainedModelDetail
                        .inheritedVersion?.trainedModelGroupVersion
                        .displayName ?? ''}
                    </MuiLink>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={2} mb={2}>
              {props.domainData.currentTrainedModelDetail.inheritedVersion
                ?.trainedModelGroupVersion ? (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant='body1'>
                      継承するデータセット
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CheckableTable
                      headers={INHERITED_DATASET_LIST_HEADERS}
                      fixedColumnNumber={0}
                      page={0}
                      noEmptyRows={true}
                      displayNoneCheckbox={true}
                      tableHeight={TABLE_HEADER_HEIGHT + 10 * 45}
                      rows={inheritedDatasetRows ?? []}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : (
                <></>
              )}
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='データセット'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentTrainedModelDetail &&
                      props.domainData.currentTrainedModelDetail
                        .datasetList[0] &&
                      props.domainData.currentTrainedModelDetail.datasetList[0]
                        .datasetName
                        ? props.domainData.currentTrainedModelDetail
                            .datasetList[0].datasetId
                        : ''
                    }
                  />
                }
                value={
                  props.domainData.currentTrainedModelDetail &&
                  props.domainData.currentTrainedModelDetail.datasetList[0] &&
                  props.domainData.currentTrainedModelDetail.datasetList[0]
                    .datasetName
                    ? props.domainData.currentTrainedModelDetail.datasetList[0]
                        .datasetName
                    : ''
                }
                startAdornment={
                  props.domainData.currentTrainedModelDetail &&
                  props.domainData.currentTrainedModelDetail.datasetList[0] &&
                  props.domainData.currentTrainedModelDetail.datasetList[0]
                    .datasetName ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentTrainedModelDetail
                            .datasetList[0].datasetName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='コンフィグ'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentTrainedModelDetail
                        ? props.domainData.currentTrainedModelDetail.setting
                            .settingId
                        : ''
                    }
                  />
                }
                value={
                  props.domainData.currentTrainedModelDetail
                    ? props.domainData.currentTrainedModelDetail.setting
                        .settingName
                    : ''
                }
                startAdornment={
                  props.domainData.currentTrainedModelDetail &&
                  props.domainData.currentTrainedModelDetail.setting
                    .settingName ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentTrainedModelDetail.setting
                            .settingName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box component='div' sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
          </Box>
        </Box>
        <AlgorithmSpecificContent {...props} />
      </>
    ),
  }
  // 関連モデル TODO: v0.3.0では血統表はTBD
  const relatedModelTab: TabItems = {
    label: '関連モデル',
    displayInfo: (
      <>
        <Box component={Paper} p={'24px 32px 32px'}>
          <Box>
            <Typography>{'モデルグループ'}</Typography>
          </Box>
          <Box p={'24px 32px 32px'}>
            <DataDetailItem
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentTrainedModelDetail
                      ? props.domainData.currentTrainedModelDetail
                          .trainedModelGroupId
                      : ''
                  }
                />
              }
              startAdornment={
                props.domainData.currentTrainedModelDetail
                  .trainedModelGroupName ? (
                  <MuiLink
                    data-testid='model-group-name-link'
                    variant='body1'
                    className={classes.linkButton}
                    underline='none'
                    onClick={() => {
                      props.domainData.currentTrainedModelDetail &&
                        history.push(
                          `/model-groups/${
                            props.domainData.currentTrainedModelDetail
                              .trainedModelGroupId
                          }${
                            isSharedUserGroup ? '?shared-user-group=true' : ''
                          }`
                        )
                    }}
                  >
                    <Box>
                      <Typography>
                        {
                          props.domainData.currentTrainedModelDetail
                            .trainedModelGroupName
                        }
                      </Typography>
                    </Box>
                  </MuiLink>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
          </Box>
          <Box
            display={'flex'}
            justifyContent={'right'}
          >{`登録モデル数 ${props.domainData.currentTrainedModelDetail.relatedTrainedModelList.length}`}</Box>
          <Box my={2}>{modelListTable}</Box>
        </Box>
      </>
    ),
  }
  // 備考タブコンテンツ
  const noteTab: TabItems = {
    label: '備考',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <TextField
              style={{ width: '100%' }}
              value={editingTrainedModelRemarks}
              onChange={(event) =>
                setEditingTrainedModelRemarks(event.target.value)
              }
              variant='outlined'
              multiline
              minRows={5}
              inputProps={{
                'data-testid': 'input-remarks',
              }}
              disabled={
                props.domainData.currentTrainedModelDetail.trainedModelKind ===
                'Generic'
              }
            />
            <Box
              display='flex'
              justifyContent='flex-end'
              width='100%'
              className={classes.mt2Box}
            >
              <Button
                variant='outlined'
                onClick={() =>
                  props.updatePipelineRemarks(
                    selectedTrainedModelId,
                    editingTrainedModelRemarks
                  )
                }
                data-testid='remarks-edit'
                disabled={isGeneric}
                className={
                  isGeneric
                    ? classes.noteTabButtonDisabled
                    : classes.noteTabButton
                }
              >
                <Typography color={isGeneric ? 'textSecondary' : 'primary'}>
                  保存
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    ),
  }

  const tabItems: TabItems[] = isGeneric
    ? [relatedModelTab, noteTab]
    : [infoTab, relatedModelTab, noteTab]

  return (
    <>
      {!isUndefined(props.domainData.currentTrainedModelDetail) ? (
        <>
          <div className={classes.container}>
            <Toast containerOptions={{ limit: 20 }}>
              <Box
                style={{
                  position: 'sticky',
                  top: '64px',
                  backgroundColor: '#fafafa',
                  zIndex: 10,
                }}
              >
                <Box className={classes.innerContainer}>
                  <Box pt={3}>
                    <BreadcrumbsComponent
                      breadcrumbsPath={[
                        {
                          name: 'モデルグループ一覧',
                          path: 'model-groups',
                        },
                        {
                          name:
                            props.domainData.currentTrainedModelDetail
                              .trainedModelGroupName !== ''
                              ? `${props.domainData.currentTrainedModelDetail.trainedModelGroupName}`
                              : `${props.domainData.currentTrainedModelDetail.trainedModelGroupId}`,
                          path: `${props.domainData.currentTrainedModelDetail.trainedModelGroupId}`,
                          query: isSharedUserGroup
                            ? sharedUserGroupQueryParameter
                            : undefined,
                        },
                        {
                          name: 'モデル一覧',
                          path: 'models',
                          query: isSharedUserGroup
                            ? sharedUserGroupQueryParameter
                            : undefined,
                        },
                        {
                          name:
                            props.domainData.currentTrainedModelDetail
                              .trainedModelName !== ''
                              ? `${props.domainData.currentTrainedModelDetail.trainedModelName}`
                              : `${props.domainData.currentTrainedModelDetail.trainedModelId}`,
                          path: `${props.domainData.currentTrainedModelDetail.trainedModelId}`,
                        },
                      ]}
                    />
                  </Box>
                  <div className={classes.flexAndBetween}>
                    <Box
                      display='flex'
                      style={{
                        maxWidth: 'calc(100% - 280px)',
                        overflow: 'hidden',
                      }}
                    >
                      <ModelIcon
                        className={classes.pageIcon}
                        data-testid='modelListTitleIcon'
                      />
                      <Box
                        height={76}
                        data-testid='model-detail-title'
                        style={{
                          overflow: 'hidden',
                        }}
                      >
                        <EditableTextField
                          value={editingTrainedModelName}
                          disabled={isGeneric}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setEditingTrainedModelName(e.target.value)
                          }
                          onBlur={() => {
                            if (
                              props.domainData.currentTrainedModelDetail
                                .trainedModelName !== editingTrainedModelName
                            ) {
                              props.updateModelName(
                                selectedTrainedModelId,
                                editingTrainedModelName
                              )
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box mr={3}>
                        <CopyableLabel
                          value={selectedTrainedModelId}
                          isTooltip
                          placement='top'
                        />
                      </Box>
                      <Box width='150px'>
                        <StatusProgressBar
                          status={convertEvaluationStatusWord(
                            props.domainData.currentTrainedModelDetail
                              ? props.domainData.currentTrainedModelDetail
                                  .evaluationStatus
                              : ''
                          )}
                          progressRate={0}
                          progressColor={convertEvaluationStatusColor(
                            props.domainData.currentTrainedModelDetail
                              ? props.domainData.currentTrainedModelDetail
                                  .evaluationStatus
                              : ''
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box p={1}>
                    <div className={classes.flexAndBetween}>
                      <Box ml={3} mb={2}>
                        <Typography>
                          {props.domainData.currentTrainedModelDetail &&
                            props.domainData.currentTrainedModelDetail
                              .trainedModelGroupName}
                          {' v'}
                          {props.domainData.currentTrainedModelDetail &&
                            props.domainData.currentTrainedModelDetail
                              .trainedModelVersion}
                        </Typography>
                      </Box>
                    </div>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' ml={3}>
                        <Typography>
                          {props.domainData.currentTrainedModelDetail &&
                          props.domainData.currentTrainedModelDetail
                            .trainedModelKind
                            ? `${convertModelKindWord(
                                props.domainData.currentTrainedModelDetail
                                  .trainedModelKind
                              )}モデル`
                            : ''}
                        </Typography>
                      </Box>
                      {props.domainData.currentTrainedModelDetail &&
                      props.domainData.currentTrainedModelDetail.createdAt ? (
                        <Box display='flex'>
                          <TimerIcon style={{ marginRight: '4px' }} />
                          <Typography>
                            {formatDateTimeSec(
                              props.domainData.currentTrainedModelDetail.createdAt.toDate()
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' alignItems='center'>
                        <HandymanIcon
                          style={{ marginRight: '4px' }}
                          sx={{ color: 'text.secondary' }}
                        />
                        <Box sx={{ color: 'text.secondary' }}>
                          <Typography component='div'>
                            <h4>
                              {`${props.domainData.currentTrainedModelDetail.trainingAlgorithm.metadata.name.ja} 
                        v ${props.domainData.currentTrainedModelDetail.trainingAlgorithm.trainingAlgorithmVersion}`}
                            </h4>
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        {typeof props.domainData.currentTrainedModelDetail
                          ?.createdUserName === 'string' ? (
                          <Typography>
                            {
                              props.domainData.currentTrainedModelDetail
                                ?.createdUserName
                            }
                          </Typography>
                        ) : (
                          <Typography>
                            {
                              props.domainData.currentTrainedModelDetail
                                ?.createdUserName.firstName
                            }{' '}
                            {
                              props.domainData.currentTrainedModelDetail
                                ?.createdUserName.familyName
                            }
                          </Typography>
                        )}
                      </Box>
                    </div>
                  </Box>
                  <Paper className={classes.actionContent}>
                    <ExpandPaper
                      paperTitle={'アクション'}
                      defaultExpand={true}
                      contents={getExpandPaperContents()}
                    />
                  </Paper>
                  <Box
                    style={{
                      backgroundColor: '#fafafa',
                    }}
                  >
                    <Tabs
                      indicatorColor='primary'
                      value={nowTab}
                      style={{
                        paddingBottom: '16px',
                        marginBottom: '1px',
                      }}
                      onChange={(_, value) => setNowTab(value)}
                    >
                      {tabItems.map((item, index) => (
                        <Tab
                          style={{
                            width: `${100 / tabItems.length}%`,
                            maxWidth: '1200px',
                          }}
                          key={index}
                          className={clsx(nowTab === index && classes.nowTab)}
                          label={item.label}
                          data-testid={`change-tab-${index}`}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
            </Toast>
          </div>
        </>
      ) : (
        <></>
      )}
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const ModelDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelDetail))
