import React from 'react'

export interface CheckableTableHeader {
  id: string
  title: string
  width: number
  sortable: boolean
  position: 'left' | 'right' | 'inherit' | 'center' | 'justify'
}

export interface CheckableTableSortOrder {
  key: string
  order: 'asc' | 'desc'
}

export interface CheckableTableProps {
  headers: CheckableTableHeader[]
  rows: React.ReactNode[][]
  totalCount?: number
  tableHeight?: number
  selectedRows?: boolean[]
  fixedColumnNumber: number
  sortOrder?: CheckableTableSortOrder
  page: number
  noEmptyRows?: boolean
  disabled?: boolean
  displayNumber?: number
  displayNoneCheckbox?: boolean
  includesStatusProgressBar?: boolean
  isPointer?: boolean
  loading?: boolean
  onClickCheckbox?: (row: number) => void
  onClickRow?: (index: number) => void
  onClickPageChange?: (page: number) => void
  onClickOrderChange?: (key: string) => void
  onChangeDisplayNumber?: (displayNumber: number) => void
}

/** ヘッダーの高さ */
export const CHECKABLE_TABLE_HEADER_HEIGHT = 48

// rowの高さ
/** StatusProgressBarありのrowの高さ */
export const CHECKABLE_STATUS_PROGRESS_BAR_ROW_HEIGHT = 45
/** ラジオあり(StatusProgressBarなし)のrowの高さ */
export const CHECKBOX_ROW_HEIGHT = 43
/** StatusProgressBarなし(ラジオもなし)のrowの高さ */
export const DISPLAY_NONE_CHECKBOX_ROW_HEIGHT = 37
