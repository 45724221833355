const DateTimeSecOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
}

/** 日付正規表現 */
const dateRegex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/
/** 時間正規表現 */
const timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/

/**
 * 日付フォーマット（yyyy/MM/dd HH:mm:ss）
 * @param date Date
 */

export function formatDateTimeSec(date: Date): string {
  if (!date) return '----/--/-- --:--:--'
  return new Intl.DateTimeFormat('ja-JP', DateTimeSecOptions).format(date)
}

/**
 * 時刻フォーマット（HH:mm:ss）
 * @param millSecond number
 */

export function formatTimeSecByMillSecond(millSecond: number): string {
  if (!millSecond) return '00:00:00'

  const paddingZero = (n: number) => {
    return n < 10 ? '0' + n : n
  }

  const hour = Math.floor(Math.abs(millSecond / 1000) / 3600)
  const minute = Math.floor((Math.abs(millSecond / 1000) % 3600) / 60)
  const second = Math.floor(Math.abs(millSecond / 1000) % 60)

  const hourStr = paddingZero(hour)
  const minuteStr = paddingZero(minute)
  const secondStr = paddingZero(second)

  return hourStr + ':' + minuteStr + ':' + secondStr
}

/**
 * 時刻フォーマット（HH:mm:ss）
 * @param date Date
 */

export function formatTimeSecByDate(date: Date): string {
  if (!date) return '00:00:00'

  const paddingZero = (n: number) => {
    return n < 10 ? '0' + n : n
  }

  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  const hourStr = paddingZero(hour)
  const minuteStr = paddingZero(minute)
  const secondStr = paddingZero(second)

  return hourStr + ':' + minuteStr + ':' + secondStr
}

/**
 * 同日かどうか
 * 参考https://www.sejuku.net/blog/23115
 */
export function lowerThanDateOnly(date1: Date, date2: Date): boolean {
  const year1 = date1.getFullYear()
  const month1 = date1.getMonth() + 1
  const day1 = date1.getDate()

  const year2 = date2.getFullYear()
  const month2 = date2.getMonth() + 1
  const day2 = date2.getDate()
  if (year1 === year2) {
    if (month1 === month2) {
      return day1 === day2
    } else {
      return false
    }
  } else {
    return false
  }
}

/** yyyy/MM/ddフォーマットの文字列かどうか */
export function validateDateString(date: string): boolean {
  // 正規表現のチェック
  if (dateRegex.test(date)) {
    const splittedDate = date.split('/').map(Number)
    // その月の最後の日付
    const lastDate = new Date(splittedDate[0], splittedDate[1], 0).getDate()
    // 妥当な日付かチェック
    if (splittedDate[2] > lastDate) {
      return false
    }
  } else {
    return false
  }
  return true
}

/** HH:mm:ssフォーマットの文字列かどうか */
export function validateTimeString(time: string): boolean {
  return timeRegex.test(time)
}
