import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { AlgorithmSelectProps } from './types'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Algorithm } from 'state/app/domainData'

const useStyles = makeStyles()(() => ({
  algorithmSelectBox: {
    width: '100%',
  },
}))

export const AlgorithmSelect: React.FC<AlgorithmSelectProps> = (
  props: AlgorithmSelectProps
) => {
  const { classes } = useStyles()

  const algorithmList = () => {
    const filteredAlgorithms =
      props.type === 'FEATURE_DATA'
        ? props.algorithms.filter(
            (algorithm) => algorithm.algorithmPurpose === 'TemplateMatching'
          )
        : props.algorithms.filter(
            (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
          )

    const list = filteredAlgorithms.map((algorithm: Algorithm) => {
      return (
        <MenuItem
          data-testid={algorithm.algorithmId}
          value={algorithm.algorithmId}
          key={algorithm.algorithmId}
        >
          {algorithm.metadata.name.ja}
        </MenuItem>
      )
    })

    if (props.hasAll) {
      // アルゴリズムのプルダウンにて「すべて」の選択肢がある場合は、以下項目を先頭に追加
      return [
        <MenuItem data-testid={'all'} value={'all'} key={'all'}>
          {'全て'}
        </MenuItem>,
      ].concat(list)
    }

    return list
  }

  return (
    <FormControl variant='outlined' className={classes.algorithmSelectBox}>
      <InputLabel id='modelListAlgorithm'>アルゴリズム</InputLabel>
      <Select
        data-testid='select'
        labelId={`${props['data-testid']}-label`}
        id={`${props['data-testid']}-outlined`}
        value={props.value ?? 'all'}
        onChange={(e: SelectChangeEvent<string>) =>
          e.target.value === 'all'
            ? props.onChange(undefined)
            : props.onChange(e.target.value as string)
        }
        label='Select Algorithm'
      >
        {algorithmList()}
      </Select>
    </FormControl>
  )
}
