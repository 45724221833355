import { AccountEntryState, AccountEntryActionType } from './types'
import { AccountEntryAction } from './actions'

const initialState: AccountEntryState = {
  domainData: {
    accountGroupList: [],
  },
  appState: {
    inProgress: false,
    toastInfo: undefined,
    accountEntryResult: false,
  },
}

export const AccountEntryReducer = (
  state: AccountEntryState = initialState,
  action: AccountEntryAction
): AccountEntryState => {
  switch (action.type) {
    case AccountEntryActionType.SET_ACCOUNT_GROUP_LIST_FOR_ACCOUNT_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          accountGroupList: action.payload.accountGroupList,
        },
      }
    case AccountEntryActionType.SET_TOAST_INFO_FOR_ACCOUNT_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case AccountEntryActionType.SET_IN_PROGRESS_FOR_ACCOUNT_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case AccountEntryActionType.SET_ACCOUNT_ENTRY_RESULT_FOR_ACCOUNT_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          accountEntryResult: action.payload.result,
        },
      }
    case AccountEntryActionType.CLEAR_ACCOUNT_ENTRY_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
