import { LoginAction } from './actions'
import { LoginActionType, LoginState } from './types'

const initialState: LoginState = {
  appState: {
    recaptchaVerifier: undefined,
    loginState: 'NotLogin',
    authState: 'UnAuthed',
    verificationId: '',
    loginError: {
      errorCode: '',
      resolver: undefined,
    },
    errorMessage: '',
    inProgress: false,
  },
}

export const LoginReducer = (
  state: LoginState = initialState,
  action: LoginAction
): LoginState => {
  switch (action.type) {
    case LoginActionType.SET_RECAPCHA_VERIFIER:
      return {
        ...state,
        appState: {
          ...state.appState,
          recaptchaVerifier: action.payload.recaptchaVerifier,
        },
      }
    case LoginActionType.SET_LOGIN_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          loginState: action.payload.data,
        },
      }
    case LoginActionType.SET_AUTH_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          authState: action.payload.data,
        },
      }
    case LoginActionType.SET_VERIFICATION_ID:
      return {
        ...state,
        appState: {
          ...state.appState,
          verificationId: action.payload.verificationId,
        },
      }
    case LoginActionType.SET_LOGIN_ERROR:
      return {
        ...state,
        appState: {
          ...state.appState,
          loginError: action.payload.loginError,
        },
      }
    case LoginActionType.SET_ERROR_MESSAGE:
      return {
        ...state,
        appState: {
          ...state.appState,
          errorMessage: action.payload.data,
        },
      }
    case LoginActionType.SET_FAIL_TOO_MANY_REQUESTS_STATE:
      return {
        ...initialState,
        appState: {
          ...initialState.appState,
          loginState: 'TooManyRequests',
          errorMessage:
            'このアカウントは一時的にロックされています。\nしばらく経ってからログインし直すか\nパスワードリセットしてください',
          recaptchaVerifier: state.appState.recaptchaVerifier,
        },
      }
    case LoginActionType.SET_IN_PROGRESS_FOR_LOGIN:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case LoginActionType.CLEAR_LOGIN_STATE:
      return {
        ...initialState,
        appState: {
          ...initialState.appState,
          recaptchaVerifier: state.appState.recaptchaVerifier,
        },
      }
    default:
      return state
  }
}
