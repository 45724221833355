import { Aug3DSceneCameraRevision } from './types'

import { timeStampSchema, versionSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** aug-3d-scene-camera-revisions documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as Aug3DSceneCameraRevision
  const schema = {
    required: [
      'aug-3d-scene-camera-id',
      'aug-3d-scene-camera-revision',
      'config',
    ],
    type: 'object',
    properties: {
      'aug-3d-scene-camera-id': {
        type: 'string',
      },
      'aug-3d-scene-camera-revision': {
        type: 'number',
      },
      config: {
        required: ['fov', 'location', 'look-at', 'format-version'],
        type: 'object',
        properties: {
          fov: {
            type: 'array',
            items: {
              type: 'number',
            },
          },
          location: {
            type: 'array',
            items: {
              type: 'number',
            },
          },
          'look-at': {
            type: 'array',
            items: {
              type: 'number',
            },
          },
          'format-version': {
            ...versionSchema,
          },
          extended: {
            type: 'object',
            properties: {},
            additionalProperties: true,
          },
        },
      },

      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'aug-3d-scene-camera-revision',
      value: data as unknown as DataObject,
    })
    return false
  }
}
