import React, { FC, useMemo } from 'react'
import { AccountAuthorizerProps } from './types'
import { hasAuthAccountFunction } from 'utils/auth'

/**
 * account毎の権限によってchildrenの表示/非表示を管理する
 */
export const AccountAuthorizer: FC<AccountAuthorizerProps> = (props) => {
  const { children } = props
  const { isAuthed } = useAccountAuthorizer(props)
  return isAuthed ? <>{children}</> : <></>
}

const useAccountAuthorizer = (props: AccountAuthorizerProps) => {
  const { type, superUser, accountGroupRole } = props

  const isAuthed = useMemo(
    () => hasAuthAccountFunction({ type, superUser, accountGroupRole }),
    [type, superUser, accountGroupRole]
  )

  return { isAuthed }
}
