import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Tooltip, useTheme } from '@mui/material'

import { State } from 'state/store'

import {
  StatusProgressBar,
  GlobalLoading,
  EditableTextField,
  CopyableLabel,
  DataDetailItem,
  showToast,
  Toast,
  FeatureDataIcon,
  SelectableTable,
  SelectableTableHeader,
  TooltipLink,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  FileDownloadLabel,
  BreadcrumbsComponent,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
} from 'views/components'

import {
  FeatureDataDetailAction,
  featureDataDetailActions,
  FeatureDataDetailOperations,
  RelatedFeatureData,
  ListDisplayCondition,
  MlPipelineGenerateFile,
  EventHistoryData,
} from 'state/ducks/featureDataDetail'

import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { isUndefined } from 'utils/typeguard'

import {
  convertByteToMatchUnit,
  convertEvaluationStatusWord,
  convertEvaluationStatusColor,
  convertProgressWord,
  convertProgressColor,
} from 'views/containers/utils'

import { formatDateTimeSec } from 'views/components/utils/date'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import Divider from '@mui/material/Divider'
import { MediaLink } from 'types/StateTypes'
import { FileDownloadLabelPropsItems } from 'views/components/molecules/fileDownloadLabel/types'
import CircularProgress from '@mui/material/CircularProgress'
import { Timestamp } from 'firebase/firestore'
import {
  hasSharedUserGroupQueryParameter,
  sharedUserGroupQueryParameter,
} from 'views/containers/utils/queryParams'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データ詳細取得 */
  getFeatureDataDetail: (featureDataId: string, isSharedUserGroup: boolean) =>
    dispatch(
      FeatureDataDetailOperations.getFeatureDataDetail(
        featureDataId,
        isSharedUserGroup
      )
    ),
  /** イベント履歴取得 */
  getEventHistories: () =>
    dispatch(FeatureDataDetailOperations.getEventHistories()),
  /** ファイルデータ取得 */
  getFileData: () => dispatch(FeatureDataDetailOperations.getFileData()),
  /** Stateのクリア */
  clearFeatureDataDetailState: () =>
    dispatch(featureDataDetailActions.clearFeatureDataDetailState()),
  /** featureDataの名前を更新する */
  updateFeatureDataName: (docId: string, name: string) =>
    dispatch(
      FeatureDataDetailOperations.updateFeatureDataDetailName(docId, name)
    ),
  /** featureDataのremarksを更新する */
  updatePipelineRemarks: (docId: string, remarks: string) =>
    dispatch(
      FeatureDataDetailOperations.updateFeatureDataDetailRemarks(docId, remarks)
    ),
  /** 特徴量データファイルをダウンロードする */
  downloadFeatureDataFile: (links: MediaLink[]) =>
    dispatch(FeatureDataDetailOperations.downloadFeatureDataFile(links)),
  /** リストの表示条件の変更 */
  setFeatureDataListDisplayCondition: (listCondition: ListDisplayCondition) =>
    dispatch(
      featureDataDetailActions.setFeatureDataListDisplayCondition(listCondition)
    ),
  /** リストの表示条件の変更 */
  setMlPipelineListDisplayCondition: (listCondition: ListDisplayCondition) =>
    dispatch(
      featureDataDetailActions.setMlPipelineListDisplayCondition(listCondition)
    ),
  /** 次ページの活性非活性の切り替え */
  setFeatureDataListPagingState: (tableNextPageSubState: 'Enable' | 'Unable') =>
    dispatch(
      featureDataDetailActions.setFeatureDataListPagingState(
        tableNextPageSubState
      )
    ),
  /** 次ページの活性非活性の切り替え */
  setMlPipelineListPagingState: (tableNextPageSubState: 'Enable' | 'Unable') =>
    dispatch(
      featureDataDetailActions.setMlPipelineListPagingState(
        tableNextPageSubState
      )
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(featureDataDetailActions.setToastInfo(undefined)),
  /** resultファイルをダウンロードする */
  downloadMlPipelineGenerateFile: (link: MlPipelineGenerateFile) =>
    dispatch(FeatureDataDetailOperations.downloadMlPipelineGenerateFile(link)),
  /** resultファイルをダウンロードする */
  downloadAllMlPipelineGenerateFiles: (
    mlPipelineGenerateFiles: MlPipelineGenerateFile[]
  ) =>
    dispatch(
      FeatureDataDetailOperations.downloadAllMlPipelineGenerateFiles(
        mlPipelineGenerateFiles
      )
    ),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** メタデータの名前がない場合の表示名 */
const NO_NAME = '(N/A)'

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** テーブルのヘッダー */
const RELATED_FEATURE_DATA_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'featureDataId',
    title: '特徴量データ ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureDataVersion',
    title: 'バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureDataName',
    title: '特徴量データ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
]

/** テーブルのヘッダー */
const EVENT_HISTORIES_DATA_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'type',
    title: '種別',
    width: 100,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ml-pipeline-id',
    title: 'ML Pipeline ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'progress',
    title: '進捗',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'started-at',
    title: '実行日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'account-id',
    title: '実行ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkButton: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  noteTabButtonDisabled: {
    backgroundColor: theme.palette.grey[100],
  },
  mt2Box: {
    marginTop: '16px',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionContent: {
    marginBottom: '16px',
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  partialLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}))

const RelatedFeatureDataTable: React.FC<
  Props & {
    selectedFeatureDataGroupId: string | undefined
    isSharedUserGroup: boolean
  }
> = (
  props: Props & {
    selectedFeatureDataGroupId: string | undefined
    isSharedUserGroup: boolean
  }
) => {
  const history = useHistory()

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition =
      props.domainData.currentFeatureDataDetail.featureDataListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList =
      props.domainData.currentFeatureDataDetail.relatedFeatureDataList.slice(
        condition.displayNumber * condition.pageNumber,
        condition.displayNumber * condition.pageNumber + condition.displayNumber
      )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentFeatureDataDetail.relatedFeatureDataList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    if (
      condition.displayNumber * (condition.pageNumber + 1) >=
      props.domainData.currentFeatureDataDetail.relatedFeatureDataList.length
    ) {
      props.setFeatureDataListPagingState('Unable')
    } else {
      props.setFeatureDataListPagingState('Enable')
    }
    return displayList
  }, [
    props.domainData.currentFeatureDataDetail.featureDataListDisplayCondition,
    props.domainData.currentFeatureDataDetail.relatedFeatureDataList,
  ])

  /** テーブルに表示する特徴量データのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    if (!tableContent) return
    const convertedList = tableContent.map(
      (featureDataList: RelatedFeatureData) => {
        return {
          featureDataId: featureDataList.featureDataId,
          featureDataVersion: featureDataList.featureDataVersion.displayName,
          featureDataName: featureDataList.featureDataName,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataId') {
          if (value) {
            return (
              <TooltipLink
                key={key}
                data-testid={`feature-data-${value}`}
                title={value ?? 'abc'}
                placement='right-start'
                onClick={() => {
                  if (props.selectedFeatureDataGroupId) {
                    history.replace(
                      `/feature-data-groups/${
                        props.selectedFeatureDataGroupId
                      }/feature-data/${value}${
                        props.isSharedUserGroup ? '?shared-user-group=true' : ''
                      }`
                    )
                  } else {
                    history.push(
                      `/feature-data/${value}${
                        props.isSharedUserGroup ? '?shared-user-group=true' : ''
                      }`
                    )
                  }
                }}
              />
            )
          } else {
            return (
              <Box key={key} sx={{ color: 'text.secondary' }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'featureDataVersion') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: 'text.secondary' }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'featureDataName') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: 'text.secondary' }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        }
      })
    )
  }, [tableContent])

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setFeatureDataListDisplayCondition({
      ...props.domainData.currentFeatureDataDetail
        .featureDataListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentFeatureDataDetail.relatedFeatureDataList.length >
      props.domainData.currentFeatureDataDetail.featureDataListDisplayCondition
        .pageNumber *
        displayNumber
        ? props.domainData.currentFeatureDataDetail
            .featureDataListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentFeatureDataDetail.relatedFeatureDataList
              .length / displayNumber
          ) - 1

    props.setFeatureDataListDisplayCondition({
      ...props.domainData.currentFeatureDataDetail
        .featureDataListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })
  }

  return (
    <SelectableTable
      displayNumber={
        props.domainData.currentFeatureDataDetail
          ? props.domainData.currentFeatureDataDetail
              .featureDataListDisplayCondition.displayNumber
          : 10
      }
      headers={RELATED_FEATURE_DATA_TABLE_HEADERS}
      rows={tableRows ?? []}
      totalCount={
        props.domainData.currentFeatureDataDetail.relatedFeatureDataList.length
      }
      tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
      fixedColumnNumber={0}
      page={
        props.domainData.currentFeatureDataDetail
          ? props.domainData.currentFeatureDataDetail
              .featureDataListDisplayCondition.pageNumber
          : 0
      }
      sortOrder={{
        key: 'featureDataVersion',
        order: 'desc',
      }}
      displayNoneRadio={true}
      onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
      onChangeDisplayNumber={(displayNumber: number) =>
        handleChangeDisplayNumber(displayNumber)
      }
    />
  )
}

const EventHistoryDataTable: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.mlPipelineListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.mlPipelineList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.mlPipelineList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    if (
      condition.displayNumber * (condition.pageNumber + 1) >=
      props.domainData.mlPipelineList.length
    ) {
      props.setMlPipelineListPagingState('Unable')
    } else {
      props.setMlPipelineListPagingState('Enable')
    }
    return displayList
  }, [
    props.domainData.mlPipelineListDisplayCondition,
    props.domainData.mlPipelineList,
  ])

  /** テーブルに表示する特徴量データのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    if (!tableContent) return
    const convertedList = tableContent.map(
      (eventHistoryData: EventHistoryData) => {
        return {
          type:
            eventHistoryData.mlPipelineKind === 'FeatureDataTransferring'
              ? '転送'
              : '生成',
          mlPipelineId: eventHistoryData.mlPipelineId,
          progress: eventHistoryData.progress,
          startedAt: eventHistoryData.startedAt,
          accountId: eventHistoryData.accountId,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'progress':
            return (
              <StatusProgressBar
                status={convertProgressWord(
                  data.progress.transactionStatus,
                  true
                )}
                progressRate={data.progress.progressRate}
                progressColor={convertProgressColor(
                  data.progress.transactionStatus
                )}
              />
            )
          case 'type':
            return <Typography key={key}>{value}</Typography>
          case 'mlPipelineId':
            return !isUndefined(value) && typeof value === 'string' ? (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'startedAt':
            return !isUndefined(value) && value instanceof Timestamp ? (
              <Typography key={key}>
                {formatDateTimeSec(value.toDate())}
              </Typography>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'accountId':
            return !isUndefined(value) && typeof value === 'string' ? (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          default:
            return (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [tableContent])

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setMlPipelineListDisplayCondition({
      ...props.domainData.mlPipelineListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.mlPipelineList.length >
      props.domainData.mlPipelineListDisplayCondition.pageNumber * displayNumber
        ? props.domainData.mlPipelineListDisplayCondition.pageNumber
        : Math.ceil(props.domainData.mlPipelineList.length / displayNumber) - 1

    props.setMlPipelineListDisplayCondition({
      ...props.domainData.mlPipelineListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })
  }

  return (
    <SelectableTable
      displayNumber={
        props.domainData.mlPipelineListDisplayCondition.displayNumber
      }
      headers={EVENT_HISTORIES_DATA_TABLE_HEADERS}
      rows={tableRows ?? []}
      totalCount={props.domainData.mlPipelineList.length}
      tableHeight={TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT}
      fixedColumnNumber={0}
      page={props.domainData.mlPipelineListDisplayCondition.pageNumber}
      sortOrder={{
        key: 'startedAt',
        order: 'desc',
      }}
      includesStatusProgressBar={true}
      displayNoneRadio={true}
      onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
      onChangeDisplayNumber={(displayNumber: number) =>
        handleChangeDisplayNumber(displayNumber)
      }
    />
  )
}

const FeatureDataDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  // パスパラメータから特徴量データIDを取得
  const [selectedFeatureDataId, setSelectedFeatureDataId] = useState<string>('')
  // パスパラメータから特徴量データグループIDを取得
  const [selectedFeatureDataGroupId, setSelectedFeatureDataGroupId] = useState<
    string | undefined
  >(undefined)

  /** 初期実行 */
  useEffect(() => {
    return () => {
      props.clearFeatureDataDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.domainData.currentFeatureDataDetail?.featureDataId) {
      props.getFileData()
      if (!isSharedUserGroup) {
        props.getEventHistories()
      }
    }
  }, [
    props.domainData.currentFeatureDataDetail?.featureDataId,
    isSharedUserGroup,
  ])

  useEffect(() => {
    // NOTE: featureDataId, featureDataGroupIdがprops.match.paramsに存在しないためwarningとなるが特徴量データ詳細に遷移する際は存在する
    setSelectedFeatureDataId(
      (props.match.params as { [key: string]: string })['featureDataId']
    )
    setSelectedFeatureDataGroupId(
      (props.match.params as { [key: string]: string })['featureDataGroupId']
    )
    // 表示用詳細取得
    props.getFeatureDataDetail(
      (props.match.params as { [key: string]: string })['featureDataId'] ?? '',
      isSharedUserGroup
    )
  }, [props.match.params])

  /** 特徴量データ詳細備考が変更時にセットする */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.currentFeatureDataDetail) &&
      !isUndefined(props.domainData.currentFeatureDataDetail.featureDataRemarks)
    ) {
      setEditingFeatureDataRemarks(
        props.domainData.currentFeatureDataDetail.featureDataRemarks
      )
      setEditingFeatureDataName(
        props.domainData.currentFeatureDataDetail.featureDataName
      )
    }
  }, [props.domainData.currentFeatureDataDetail])

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )
  /** ダウンロード失敗時に表示するトーストのコンテンツ */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** 特徴量データファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.featureDataDetailState.featureDataDlLinkSubState ===
      'Failed'
    ) {
      showErrorToast('特徴量データファイルの取得に失敗しました。')
    }
  }, [props.appState.featureDataDetailState.featureDataDlLinkSubState])
  /** DLエラー時Toast */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  /** 対象のIDのデータがない時はhomeに戻る */
  useEffect(() => {
    if (
      props.appState.featureDataDetailState.featureDataDataState ===
      'NotFoundProcessed'
    ) {
      history.replace('/')
    }
  }, [props.appState.featureDataDetailState.featureDataDataState])

  const [nowTab, setNowTab] = useState(0)

  const [editingFeatureDataName, setEditingFeatureDataName] =
    useState<string>('')
  const [editingFeatureDataRemarks, setEditingFeatureDataRemarks] =
    useState<string>('')

  /** パイプライン生成結果ファイルの取得エラー時Toast */
  useEffect(() => {
    if (
      props.appState.featureDataDetailState.featureDataGenerateFileSubState ===
      'Failed'
    ) {
      showErrorToast('パイプライン生成結果ファイルの取得に失敗しました。')
    }
  }, [props.appState.featureDataDetailState.featureDataGenerateFileSubState])
  /** タブ個別のloading */
  const partialLoading = useMemo(() => {
    if (
      props.appState.isInProgressForGettingFiles ||
      props.appState.isInProgressForEventHistories
    ) {
      if (nowTab === 0 || nowTab === 1) {
        return (
          <Box className={classes.partialLoading}>
            <CircularProgress size={64} />
          </Box>
        )
      }
      return <></>
    }
    return <></>
  }, [
    props.appState.isInProgressForGettingFiles,
    props.appState.isInProgressForEventHistories,
    nowTab,
  ])

  const fileDownloadLabelPropsItems = (
    files: MlPipelineGenerateFile[]
  ): FileDownloadLabelPropsItems[] => {
    return files.map((file) => {
      return {
        timeAndCapacity: `${convertByteToMatchUnit(
          file.fileSize
        )} ${formatDateTimeSec(file.createdAt)}`,
        url: file.fileName,
        onClick: () => props.downloadMlPipelineGenerateFile(file),
      } as FileDownloadLabelPropsItems
    })
  }

  // 一般情報コンテンツ
  const infoTab: TabItems = {
    label: '一般情報',
    displayInfo: (
      <>
        <Box>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <div className={classes.flexAndBetween}>
                <Box display='flex'>
                  <Typography>特徴量データ</Typography>
                </Box>
                {props.domainData.currentFeatureDataDetail &&
                props.domainData.featureDataDlLinks &&
                props.domainData.featureDataDlLinks.length > 0 ? (
                  <Box display='flex'>
                    <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                    <Link
                      data-testid='all-download-feature-data'
                      style={{ cursor: 'pointer' }}
                      underline='none'
                      onClick={() => {
                        if (props.domainData.currentFeatureDataDetail) {
                          let mediaLinks: MediaLink[] = []
                          props.domainData.featureDataDlLinks.forEach(
                            (link) => {
                              mediaLinks = mediaLinks.concat(link.mediaLinks)
                            }
                          )
                          props.downloadFeatureDataFile(mediaLinks)
                        }
                      }}
                    >
                      一括ダウンロード
                    </Link>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
              <div className={classes.flexAndBetween} style={{ marginTop: 5 }}>
                <Box display='flex' flexWrap='wrap'>
                  {props.domainData.currentFeatureDataDetail
                    ? props.domainData.featureDataDlLinks.map((info, index) => (
                        <Box mr={2} key={index}>
                          <Button
                            data-testid='all-download-feature-data-json'
                            variant='outlined'
                            style={{
                              backgroundColor: '#D9E5FF',
                            }}
                            onClick={() =>
                              props.downloadFeatureDataFile(info.mediaLinks)
                            }
                          >
                            <FileDownloadOutlinedIcon
                              style={{ marginRight: '4px' }}
                            />
                            <Typography variant='subtitle2' color='primary'>
                              {`${info.linkName}`}
                            </Typography>
                          </Button>
                          <Box className={classes.mediaSizeText}>
                            ({convertByteToMatchUnit(info.totalMediaSize)})
                          </Box>
                        </Box>
                      ))
                    : []}
                </Box>
              </div>
            </Box>
          </Box>
          <Box component={Paper} mt={2} sx={{ position: 'relative' }}>
            <Box p={'24px 32px 32px'}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box display='flex'>
                  <Typography component='div'>
                    <h4>パイプライン生成物</h4>
                  </Typography>
                </Box>
                {props.domainData.mlPipelineGenerateFiles.length > 0 ? (
                  <Box display='flex'>
                    <FileDownloadOutlinedIcon style={{ marginRight: '4px' }} />
                    <Link
                      data-testid='all-download-logs'
                      style={{ cursor: 'pointer' }}
                      underline='none'
                      onClick={() =>
                        props.domainData.currentFeatureDataDetail &&
                        props.downloadAllMlPipelineGenerateFiles(
                          props.domainData.mlPipelineGenerateFiles
                        )
                      }
                    >
                      一括ダウンロード
                    </Link>
                  </Box>
                ) : (
                  <></>
                )}
              </div>
              <Divider />
              <FileDownloadLabel
                items={fileDownloadLabelPropsItems(
                  props.domainData.mlPipelineGenerateFiles
                )}
              />
            </Box>
          </Box>
          {partialLoading}
        </Box>
        <Box component={Paper} my={2}>
          <Box p={'24px 32px 32px'}>
            <Typography>生成情報</Typography>
            <DataDetailItem
              formHelperText='ML Pipeline ID'
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentFeatureDataDetail
                      ? props.domainData.currentFeatureDataDetail.mlPipeline
                          .mlPipelineId
                      : ''
                  }
                />
              }
              startAdornment={
                props.domainData.currentFeatureDataDetail.mlPipeline
                  .mlPipelineName ? (
                  <Box sx={{ color: 'text.primary' }}>
                    <Typography>
                      {props.domainData.currentFeatureDataDetail
                        ? props.domainData.currentFeatureDataDetail.mlPipeline
                            .mlPipelineName
                        : ''}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
            <Box mt={1}>
              <DataDetailItem
                formHelperText='データセット'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentFeatureDataDetail &&
                      props.domainData.currentFeatureDataDetail
                        .datasetList[0] &&
                      props.domainData.currentFeatureDataDetail.datasetList[0]
                        .datasetName
                        ? props.domainData.currentFeatureDataDetail
                            .datasetList[0].datasetId
                        : ''
                    }
                  />
                }
                value={
                  props.domainData.currentFeatureDataDetail &&
                  props.domainData.currentFeatureDataDetail.datasetList[0] &&
                  props.domainData.currentFeatureDataDetail.datasetList[0]
                    .datasetName
                    ? props.domainData.currentFeatureDataDetail.datasetList[0]
                        .datasetName
                    : ''
                }
                startAdornment={
                  props.domainData.currentFeatureDataDetail &&
                  props.domainData.currentFeatureDataDetail.datasetList[0] &&
                  props.domainData.currentFeatureDataDetail.datasetList[0]
                    .datasetName ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentFeatureDataDetail
                            .datasetList[0].datasetName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='コンフィグ'
                endAdornment={
                  <CopyableLabel
                    value={
                      props.domainData.currentFeatureDataDetail
                        ? props.domainData.currentFeatureDataDetail.setting
                            .settingId
                        : ''
                    }
                  />
                }
                value={
                  props.domainData.currentFeatureDataDetail
                    ? props.domainData.currentFeatureDataDetail.setting
                        .settingName
                    : ''
                }
                startAdornment={
                  props.domainData.currentFeatureDataDetail &&
                  props.domainData.currentFeatureDataDetail.setting
                    .settingName ? (
                    <Box sx={{ color: 'text.primary' }}>
                      <Typography>
                        {
                          props.domainData.currentFeatureDataDetail.setting
                            .settingName
                        }
                      </Typography>
                    </Box>
                  ) : (
                    <Box component='div' sx={{ color: 'text.secondary' }}>
                      <Typography>{NO_NAME}</Typography>
                    </Box>
                  )
                }
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
  }

  // イベント履歴タブコンテンツ
  const eventHistoriesTab: TabItems = {
    label: 'イベント履歴',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <Box my={2}>
              <EventHistoryDataTable {...props} />
            </Box>
          </Box>
          {partialLoading}
        </Box>
      </>
    ),
  }

  // 関連特徴量データ TODO: v0.3.0では血統表はTBD
  const relatedFeatureDataTab: TabItems = {
    label: '関連特徴量データ',
    displayInfo: (
      <>
        <Box component={Paper} p={'24px 32px 32px'}>
          <Box>
            <Typography>{'特徴量データグループ'}</Typography>
          </Box>
          <Box p={'24px 32px 32px'}>
            <DataDetailItem
              endAdornment={
                <CopyableLabel
                  value={
                    props.domainData.currentFeatureDataDetail
                      ? props.domainData.currentFeatureDataDetail
                          .featureDataGroupId
                      : ''
                  }
                />
              }
              startAdornment={
                props.domainData.currentFeatureDataDetail
                  .featureDataGroupName ? (
                  <Link
                    variant='body1'
                    data-testid='feature-data-group-name-link'
                    className={classes.linkButton}
                    underline='none'
                    onClick={() => {
                      props.domainData.currentFeatureDataDetail &&
                        history.push(
                          `/feature-data-groups/${
                            props.domainData.currentFeatureDataDetail
                              .featureDataGroupId
                          }${
                            isSharedUserGroup ? '?shared-user-group=true' : ''
                          }`
                        )
                    }}
                  >
                    <Box>
                      <Typography>
                        {
                          props.domainData.currentFeatureDataDetail
                            .featureDataGroupName
                        }
                      </Typography>
                    </Box>
                  </Link>
                ) : (
                  <Box sx={{ color: 'text.secondary' }}>
                    <Typography>{NO_NAME}</Typography>
                  </Box>
                )
              }
            />
          </Box>
          <Box
            display={'flex'}
            justifyContent={'right'}
          >{`登録特徴量データ数 ${props.domainData.currentFeatureDataDetail.relatedFeatureDataList.length}`}</Box>
          <Box my={2}>
            <RelatedFeatureDataTable
              {...props}
              selectedFeatureDataGroupId={selectedFeatureDataGroupId}
              isSharedUserGroup={isSharedUserGroup}
            />
          </Box>
        </Box>
      </>
    ),
  }
  // 備考タブコンテンツ
  const noteTab: TabItems = {
    label: '備考',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <TextField
              style={{ width: '100%' }}
              value={editingFeatureDataRemarks}
              onChange={(event) =>
                setEditingFeatureDataRemarks(event.target.value)
              }
              variant='outlined'
              multiline
              minRows={5}
              inputProps={{
                'data-testid': 'input-remarks',
              }}
              disabled={isSharedUserGroup}
            />
            <Box
              display='flex'
              justifyContent='flex-end'
              width='100%'
              className={classes.mt2Box}
            >
              <Button
                variant='outlined'
                onClick={() =>
                  props.updatePipelineRemarks(
                    selectedFeatureDataId,
                    editingFeatureDataRemarks
                  )
                }
                data-testid='remarks-edit'
                disabled={isSharedUserGroup}
                className={
                  isSharedUserGroup
                    ? classes.noteTabButtonDisabled
                    : classes.noteTabButton
                }
              >
                <Typography
                  color={isSharedUserGroup ? 'textSecondary' : 'primary'}
                >
                  保存
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    ),
  }

  const tabItems: TabItems[] = isSharedUserGroup
    ? [relatedFeatureDataTab, noteTab]
    : [infoTab, eventHistoriesTab, relatedFeatureDataTab, noteTab]

  return (
    <>
      {!isUndefined(props.domainData.currentFeatureDataDetail) ? (
        <>
          <div className={classes.container}>
            <Toast containerOptions={{ limit: 20 }}>
              <Box
                style={{
                  position: 'sticky',
                  top: '64px',
                  backgroundColor: '#fafafa',
                  zIndex: 10,
                }}
              >
                <Box className={classes.innerContainer}>
                  <Box pt={3}>
                    <BreadcrumbsComponent
                      breadcrumbsPath={[
                        {
                          name: '特徴量データグループ一覧',
                          path: 'feature-data-groups',
                        },
                        {
                          name:
                            props.domainData.currentFeatureDataDetail
                              .featureDataGroupName !== ''
                              ? `${props.domainData.currentFeatureDataDetail.featureDataGroupName}`
                              : `${props.domainData.currentFeatureDataDetail.featureDataGroupId}`,
                          path: `${props.domainData.currentFeatureDataDetail.featureDataGroupId}`,
                          query: isSharedUserGroup
                            ? sharedUserGroupQueryParameter
                            : undefined,
                        },
                        {
                          name: '特徴量データ一覧',
                          path: 'feature-data',
                          query: isSharedUserGroup
                            ? sharedUserGroupQueryParameter
                            : undefined,
                        },
                        {
                          name:
                            props.domainData.currentFeatureDataDetail
                              .featureDataName !== ''
                              ? `${props.domainData.currentFeatureDataDetail.featureDataName}`
                              : `${props.domainData.currentFeatureDataDetail.featureDataId}`,
                          path: `${props.domainData.currentFeatureDataDetail.featureDataId}`,
                        },
                      ]}
                    />
                  </Box>
                  <div className={classes.flexAndBetween}>
                    <Box
                      display='flex'
                      style={{
                        maxWidth: 'calc(100% - 280px)',
                        overflow: 'hidden',
                      }}
                    >
                      <FeatureDataIcon
                        className={classes.pageIcon}
                        data-testid='featureDataListTitleIcon'
                      />
                      <Box
                        height={76}
                        data-testid='feature-data-detail-title'
                        style={{
                          overflow: 'hidden',
                        }}
                      >
                        <EditableTextField
                          value={editingFeatureDataName}
                          disabled={isSharedUserGroup}
                          onChange={(e) =>
                            setEditingFeatureDataName(e.target.value)
                          }
                          onBlur={() => {
                            if (
                              props.domainData.currentFeatureDataDetail
                                .featureDataName !== editingFeatureDataName
                            ) {
                              props.updateFeatureDataName(
                                selectedFeatureDataId,
                                editingFeatureDataName
                              )
                            }
                          }}
                        />
                      </Box>
                    </Box>
                    <Box display='flex'>
                      <Box mr={3}>
                        <CopyableLabel
                          value={selectedFeatureDataId}
                          isTooltip
                          placement='top'
                        />
                      </Box>
                      <Box width='150px'>
                        <StatusProgressBar
                          status={convertEvaluationStatusWord(
                            props.domainData.currentFeatureDataDetail
                              ? props.domainData.currentFeatureDataDetail
                                  .evaluationStatus
                              : ''
                          )}
                          progressRate={0}
                          progressColor={convertEvaluationStatusColor(
                            props.domainData.currentFeatureDataDetail
                              ? props.domainData.currentFeatureDataDetail
                                  .evaluationStatus
                              : ''
                          )}
                        />
                      </Box>
                    </Box>
                  </div>
                  <Box p={1}>
                    <div className={classes.flexAndBetween}>
                      <Box ml={3} mb={2}>
                        <Typography>
                          {props.domainData.currentFeatureDataDetail &&
                            props.domainData.currentFeatureDataDetail
                              .featureDataGroupName}
                          {' v'}
                          {props.domainData.currentFeatureDataDetail &&
                            props.domainData.currentFeatureDataDetail
                              .featureDataVersion}
                        </Typography>
                      </Box>
                      {props.domainData.currentFeatureDataDetail &&
                      props.domainData.currentFeatureDataDetail.createdAt ? (
                        <Box display='flex'>
                          <TimerIcon style={{ marginRight: '4px' }} />
                          <Typography>
                            {formatDateTimeSec(
                              props.domainData.currentFeatureDataDetail.createdAt.toDate()
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className={classes.flexAndBetween}>
                      <Box display='flex' alignItems='center'>
                        <HandymanIcon
                          style={{ marginRight: '4px' }}
                          sx={{ color: 'text.secondary' }}
                        />
                        <Box sx={{ color: 'text.secondary' }}>
                          <Typography component='div'>
                            <h4>
                              {`${props.domainData.currentFeatureDataDetail.trainingAlgorithm.metadata.name.ja} 
                        v ${props.domainData.currentFeatureDataDetail.trainingAlgorithm.trainingAlgorithmVersion}`}
                            </h4>
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex'>
                        {typeof props.domainData.currentFeatureDataDetail
                          ?.createdUserName === 'string' ? (
                          <Typography>
                            {
                              props.domainData.currentFeatureDataDetail
                                ?.createdUserName
                            }
                          </Typography>
                        ) : (
                          <Typography>
                            {
                              props.domainData.currentFeatureDataDetail
                                ?.createdUserName.firstName
                            }{' '}
                            {
                              props.domainData.currentFeatureDataDetail
                                ?.createdUserName.familyName
                            }
                          </Typography>
                        )}
                      </Box>
                    </div>
                  </Box>
                  <Box
                    style={{
                      backgroundColor: '#fafafa',
                    }}
                  >
                    <Tabs
                      indicatorColor='primary'
                      value={nowTab}
                      style={{
                        paddingBottom: '16px',
                        marginBottom: '1px',
                      }}
                      onChange={(_, value) => setNowTab(value)}
                    >
                      {tabItems.map((item, index) => (
                        <Tab
                          style={{
                            width: `${100 / tabItems.length}%`,
                            maxWidth: '1200px',
                          }}
                          key={index}
                          className={clsx(nowTab === index && classes.nowTab)}
                          label={item.label}
                          data-testid={`change-tab-${index}`}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
            </Toast>
          </div>
        </>
      ) : (
        <></>
      )}
      <GlobalLoading
        open={
          props.appState.inProgress || props.appState.isInProgressForDownloading
        }
      />
    </>
  )
}

export const FeatureDataDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataDetail))
