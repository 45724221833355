import { Dispatch } from 'redux'
import { authInstance } from 'state/firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
  User,
} from 'firebase/auth'
import {
  getAccountCustomerRelationsCollection,
  getAccountSettingCollection,
  getAccountsCollection,
  getAccountGroupCollection,
} from 'state/firebase'
import { userSettingActions } from './actions'
import { UserSettingApi } from './apis'
import { convertPhoneNumber } from 'views/containers/utils/phoneNumber'
import { domainDataActions } from 'state/app/domainData'
import { State } from 'state/store'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountSettingDocument } from 'utils/fireStore/accountSetting'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountGroupDocument } from 'utils/fireStore/accountGroup'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountDocument } from 'utils/fireStore/account'
import { fireStoreTypeGuard as fireStoreTypeGuardForAccountCustomerRelationDocument } from 'utils/fireStore/accountCustomerRelation'
import {
  generateInvalidPasswordErrorMessage,
  validatePassword,
} from 'state/utils'

export const UserSettingOperations = {
  /** ユーザー情報を取得する */
  getUserInfo:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(userSettingActions.setInProgress(true))
      try {
        const firebaseCurrentUser: User | null = authInstance.currentUser
        if (firebaseCurrentUser) {
          const accountId =
            getState().app.domainData.authedUser.auth.customClaims.accountId
          const accountGroupId =
            getState().app.domainData.authedUser.auth.customClaims
              .accountGroupId
          const accountDoc = (
            await getDoc(doc(getAccountsCollection(accountGroupId), accountId))
          ).data()
          const accountSettingDoc = (
            await getDoc(
              doc(getAccountSettingCollection(accountGroupId), accountId)
            )
          ).data()
          const accountGroup = (
            await getDoc(doc(getAccountGroupCollection(), accountGroupId))
          ).data()

          if (
            !fireStoreTypeGuardForAccountDocument(accountDoc) ||
            !fireStoreTypeGuardForAccountSettingDocument(accountSettingDoc) ||
            !fireStoreTypeGuardForAccountGroupDocument(accountGroup)
          ) {
            return
          }
          // TODO: 将来的にdocから取得する
          const accountGroupName = '京セラロボティクス事業'
          dispatch(
            userSettingActions.setUserInfo({
              mail: accountDoc ? accountDoc['mail-address'] : '',
              phoneNumber: accountSettingDoc
                ? accountSettingDoc['phone-numbers'][0]
                  ? convertPhoneNumber(accountSettingDoc['phone-numbers'][0])
                  : ''
                : '',
              firstName: accountSettingDoc
                ? accountSettingDoc['first-name']
                  ? accountSettingDoc['first-name']
                  : ''
                : '',
              familyName: accountSettingDoc
                ? accountSettingDoc['family-name']
                  ? accountSettingDoc['family-name']
                  : ''
                : '',
              // TODO: 将来的にdocから取得する
              accountGroupName: accountGroupName,
              language: accountSettingDoc ? accountSettingDoc['language'] : '',
              // TODO 今は固定
              locate: 'JP',
              role: accountDoc && accountDoc['account-group-role'],
              mfaGroupSetting: accountGroup
                ? accountGroup['mfa-group-setting']
                : '',
              isMfa: accountSettingDoc
                ? accountSettingDoc['is-mfa']
                  ? accountSettingDoc['is-mfa']
                  : false
                : false,
            })
          )
        } else {
          console.error('ログイン中のユーザーがいない')
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(userSettingActions.setInProgress(false))
      }
    },
  /** パスワード更新 */
  executePasswordUpdate:
    (nowPassword: string, newPassword: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(userSettingActions.setInProgress(true))
        const user = authInstance.currentUser
        if (!user) return

        // 現在のパスワードが合ってるかどうかログインして確認する
        const credential = EmailAuthProvider.credential(
          user.email as string,
          nowPassword
        )
        await reauthenticateWithCredential(user, credential)
          .then(async () => {
            const validationStatus = await validatePassword(
              authInstance,
              newPassword
            )
            if (
              validationStatus !== undefined &&
              validationStatus.isValid === false
            ) {
              dispatch(
                userSettingActions.setToastInfo({
                  type: 'error',
                  title: 'パスワードが正しく設定されていません',
                  targets:
                    generateInvalidPasswordErrorMessage(validationStatus),
                })
              )
              return
            }

            // パスワード更新処理
            updatePassword(user, newPassword)
              .then(async () => {
                dispatch(
                  userSettingActions.setPasswordUpdatedState('UpdateSuccess')
                )
                // docのパスワード更新日時を更新
                await UserSettingApi.setPasswordUpdateTime()
              })
              .catch(async (error: { code: string }) => {
                if (error.code === 'auth/requires-recent-login') {
                  await authInstance.signOut()
                }
                dispatch(
                  userSettingActions.setPasswordUpdatedState('UpdateError')
                )
                dispatch(
                  userSettingActions.setPasswordUpdateErrorMessage(
                    'パスワードの更新に失敗しました。管理者にお問い合わせください。'
                  )
                )
                console.error(error)
              })
          })
          .catch(async (error) => {
            if (error.code === 'auth/wrong-password') {
              dispatch(
                userSettingActions.setPasswordUpdatedState('NowPasswordMissing')
              )
              // MFAが設定済みの場合
            } else if (error.code === 'auth/multi-factor-auth-required') {
              // パスワード更新処理
              await updatePassword(user, newPassword)
                .then(async () => {
                  dispatch(
                    userSettingActions.setPasswordUpdatedState('UpdateSuccess')
                  )
                  // docのパスワード更新日時を更新
                  await UserSettingApi.setPasswordUpdateTime()
                })
                .catch(async (error: { code: string }) => {
                  if (error.code === 'auth/requires-recent-login') {
                    await authInstance.signOut()
                  }
                  dispatch(
                    userSettingActions.setPasswordUpdatedState('UpdateError')
                  )
                  dispatch(
                    userSettingActions.setPasswordUpdateErrorMessage(
                      'パスワードの更新に失敗しました。管理者にお問い合わせください。'
                    )
                  )
                  console.error(error)
                })
            } else {
              dispatch(
                userSettingActions.setPasswordUpdatedState('UpdateError')
              )
              dispatch(
                userSettingActions.setPasswordUpdateErrorMessage(
                  'パスワードの更新に失敗しました。管理者にお問い合わせください。'
                )
              )
            }
          })
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(userSettingActions.setInProgress(false))
      }
    },
  /** プロフィール更新処理 */
  updateProfile:
    (firstName: string, familyName: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(userSettingActions.setInProgress(true))
      try {
        const auth = getAuth()
        const firebaseCurrentUser = auth.currentUser
        if (firebaseCurrentUser) {
          const accountId =
            getState().app.domainData.authedUser.auth.customClaims.accountId
          const accountGroupId =
            getState().app.domainData.authedUser.auth.customClaims
              .accountGroupId
          await updateDoc(
            doc(getAccountSettingCollection(accountGroupId), accountId),
            {
              ['first-name']: firstName,
              ['family-name']: familyName,
              ['updated-by']: accountId,
              ['updated-at']: new Date(),
            }
          )
          // ユーザー情報を取得する
          try {
            const accountSettingDoc = (
              await getDoc(
                doc(getAccountSettingCollection(accountGroupId), accountId)
              )
            ).data()
            const accountCustomerRelationDoc = (
              await getDoc(
                doc(
                  getAccountCustomerRelationsCollection(accountGroupId),
                  accountId
                )
              )
            ).data()
            if (
              !fireStoreTypeGuardForAccountCustomerRelationDocument(
                accountCustomerRelationDoc
              ) ||
              !fireStoreTypeGuardForAccountSettingDocument(accountSettingDoc)
            ) {
              return
            }
            const userProfile = getState().app.domainData.userProfile
            // TODO: 将来的にdocから取得する
            const accountGroupName = '京セラロボティクス事業'
            dispatch(
              domainDataActions.setUserProfile({
                firstName: accountSettingDoc
                  ? accountSettingDoc['first-name']
                    ? accountSettingDoc['first-name']
                    : ''
                  : '',
                familyName: accountSettingDoc
                  ? accountSettingDoc['family-name']
                    ? accountSettingDoc['family-name']
                    : ''
                  : '',
                // TODO: 将来的にdocから取得する
                accountGroupName: accountGroupName,
                language: accountSettingDoc
                  ? accountSettingDoc['language']
                  : '',
                // TODO 今は固定
                locate: 'JP',
                role: accountCustomerRelationDoc
                  ? accountCustomerRelationDoc['customer-list'][0]['role']
                  : '',
                customerId: userProfile ? userProfile.customerId : '',
                customerName: userProfile ? userProfile.customerName : '',
                accountGroupRole: userProfile
                  ? userProfile.accountGroupRole
                  : 'user',
              })
            )
          } catch (error) {
            console.error(error)
          }
        } else {
          console.error('ログイン中のユーザーがいない')
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(userSettingActions.setInProgress(false))
      }
    },
  /** 環境設定更新処理 */
  updateEnvironment:
    (language: string, locate: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(userSettingActions.setInProgress(true))
      try {
        const firebaseCurrentUser: User | null = authInstance.currentUser
        if (firebaseCurrentUser) {
          const accountId =
            getState().app.domainData.authedUser.auth.customClaims.accountId
          const accountGroupId =
            getState().app.domainData.authedUser.auth.customClaims
              .accountGroupId
          await updateDoc(
            doc(getAccountSettingCollection(accountGroupId), accountId),
            {
              language: language,
              locate: locate,
              ['updated-by']: accountId,
              ['updated-at']: new Date(),
            }
          )
        } else {
          console.error('ログイン中のユーザーがいない')
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(userSettingActions.setInProgress(false))
      }
    },
}
