import { Dispatch } from 'redux'
import { State } from 'state/store'
import { createDatasetActions } from 'state/ducks/createDataset/actions'
import { FileUploadApi } from './apis'
import {
  AddedAnnotationSetList,
  AnnotationStatus,
  CreateDatasetFirestoreResult,
  CreateDatasetRequestType,
  InputRequireType,
  jsonCocoObjectType,
  SelectType,
  UploadSubState,
  AnnotationSetListRequestType,
  SelectedAnnotationFormat,
  ClassSet,
} from './types'
import { TrainingData } from 'state/utils/types'
import {
  AnnotationSetKindAll,
  AnnotationTrainKind,
  DatasetTemplate,
} from 'state/app/domainData/types'
import { domainDataOperations } from 'state/app/domainData/operations'
import { getDocs, query, where } from 'firebase/firestore'
import {
  getClassSetCollection,
  getClassSetMetaDataCollection,
  getSettingFormatsCollection,
  getSettingFormatVersionsCollection,
} from 'state/firebase'
import {
  ClassSetDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetDocument,
} from 'utils/fireStore/classSet'
import {
  ClassSetMetaDataDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaDataDocument,
} from 'utils/fireStore/classSetMetaData'
import { isUndefined } from 'utils/typeguard'
import { retryOperation } from 'utils/retry'
import { isInRange } from 'utils/versions'
import { Version } from 'types/StateTypes'

export const TRAINING_IMAGE_SIZE_MAX = 5000

export const createDatasetOperations = {
  setSelectedAnnotationFormat:
    (selectedAnnotationFormat?: SelectedAnnotationFormat) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        createDatasetActions.setSelectedAnnotationFormat(
          selectedAnnotationFormat
        )
      )

      if (
        selectedAnnotationFormat &&
        getState().pages.createDatasetState.domainData.selectedAlgorithmId
      ) {
        dispatch(createDatasetActions.setAlgorithmSubState('selected'))
      } else {
        dispatch(createDatasetActions.setAlgorithmSubState('unselected'))
      }
    },
  setSelectedDatasetTemplate:
    (datasetTemplate: DatasetTemplate) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const isUserInputAnnotationSetKindList =
        datasetTemplate.annotationSetKindList.filter(
          (annotationSetKind) => annotationSetKind.isUserInput === true
        )
      dispatch(
        createDatasetActions.setSelectedDatasetTemplate({
          ...datasetTemplate,
          annotationSetKindList: isUserInputAnnotationSetKindList,
        })
      )

      if (
        datasetTemplate &&
        getState().pages.createDatasetState.domainData.selectedDatasetTemplate
      ) {
        dispatch(createDatasetActions.setDatasetTemplateSubState('selected'))
      } else {
        dispatch(createDatasetActions.setDatasetTemplateSubState('unselected'))
      }
    },
  checkAnnotation:
    (files: File[], annotationSetList: AddedAnnotationSetList[]) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(createDatasetActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const annotationData: { [fileName: string]: string } = {}
        const selectedDatasetTemplate =
          getState().pages.createDatasetState.domainData.selectedDatasetTemplate

        const initialAnnotationSetKind =
          selectedDatasetTemplate?.annotationSetKindList[0].annotationSetKind ??
          ''
        const initialTrainKind =
          selectedDatasetTemplate?.annotationSetKindList[0].conditions &&
          selectedDatasetTemplate?.annotationSetKindList[0].conditions.trainKind

        const newAnnotationSetList = files.map(
          (file): AddedAnnotationSetList =>
            initAnnotationSet(
              initialAnnotationSetKind,
              initialTrainKind,
              file.name,
              'beforeUpload'
            )
        )
        const universalAnnotationSetList: AddedAnnotationSetList[] = []
        for (const annotationSet of annotationSetList) {
          if (
            newAnnotationSetList.filter(
              (e) =>
                e.annotationMetadata.name ===
                annotationSet.annotationMetadata.name
            ).length === 0
          ) {
            universalAnnotationSetList.push(annotationSet)
          }
        }
        dispatch(
          createDatasetActions.addAnnotationFile(
            newAnnotationSetList.sort().concat(universalAnnotationSetList)
          )
        )

        const getPromiseUploadUrls = files.map(async (file) => {
          try {
            const fileInfoList = [{ fileType: file.type, fileName: file.name }]
            const res = await FileUploadApi.getSignedUrlData(
              userGroupId,
              fileInfoList
            )
            const signedUrlData: {
              [fileName: string]: { id: string; url: string }
            } = res.data

            const newAnnotationSet = initAnnotationSet(
              initialAnnotationSetKind,
              initialTrainKind,
              file.name,
              'uploading'
            )
            return {
              annotationSet: newAnnotationSet,
              url: signedUrlData[file.name]?.url ?? '',
              annotationId: signedUrlData[file.name]?.id ?? '',
            }
          } catch (error) {
            console.error(error)
            return {
              annotationSet: initAnnotationSet(
                initialAnnotationSetKind,
                initialTrainKind,
                file.name,
                'uploadError'
              ),
              url: '',
              annotationId: '',
            }
          }
        })
        const getUploadUrls = await Promise.all(getPromiseUploadUrls)
        dispatch(
          createDatasetActions.addAnnotationFile(
            getUploadUrls
              .map((e) => e.annotationSet)
              .sort()
              .concat(universalAnnotationSetList)
          )
        )
        if (
          getUploadUrls.filter(
            (e) => e.annotationSet.annotationStatus === 'uploadError'
          ).length > 0
        ) {
          return
        }

        const filePromiseUploads = files.map(async (file) => {
          try {
            const getUploadUrl = getUploadUrls.find(
              (e) => e.annotationSet.annotationMetadata.name === file.name
            )
            if (getUploadUrl) {
              const url = getUploadUrl.url
              const annotationId = getUploadUrl.annotationId
              await FileUploadApi.uploadAnnotation(url, file)
              annotationData[file.name] = annotationId
            }
            return getUploadUrl?.annotationSet
          } catch (error) {
            console.error(error)
            return initAnnotationSet(
              initialAnnotationSetKind,
              initialTrainKind,
              file.name,
              'uploadError'
            )
          }
        })
        const tmpFileUploads = await Promise.all(filePromiseUploads)
        const fileUploads = tmpFileUploads.flatMap((x) => x ?? [])
        if (
          fileUploads.filter((e) => e.annotationStatus === 'uploadError')
            .length > 0
        ) {
          dispatch(
            createDatasetActions.addAnnotationFile(
              fileUploads.sort().concat(universalAnnotationSetList)
            )
          )
          return
        }

        const selectedAnnotationFormatKind =
          getState().pages.createDatasetState.domainData
            .selectedAnnotationFormat?.annotationFormatKind ?? ''

        const validatesPromise = files.map(async (file) => {
          try {
            const annotationId = annotationData[file.name]
            const validateAnnotation = await FileUploadApi.validateAnnotation(
              annotationId,
              file.name,
              selectedAnnotationFormatKind
            )

            if (!validateAnnotation.data.isValid) {
              return initAnnotationSet(
                initialAnnotationSetKind,
                initialTrainKind,
                file.name,
                'validateError'
              )
            }

            // TODO: 一旦cocoを用いた正常系のみ対応
            const jsonCocoObject: jsonCocoObjectType = JSON.parse(
              await file.text()
            )

            const annotationSet: AddedAnnotationSetList = {
              annotationSetKind: initialAnnotationSetKind,
              conditions: initialTrainKind
                ? {
                    trainKind: initialTrainKind,
                  }
                : undefined,
              annotationMetadata: { name: file.name },
              annotationId: annotationId,
              // formatType: 'coco',
              groupedData: {
                trainingDataList: jsonCocoObject.images.map(
                  (images): TrainingData => {
                    return {
                      trainingDataId: '',
                      file: {
                        ...file,
                        name: images.file_name,
                      } as File,
                      fileStatus: 'unselected',
                      dataKind: undefined,
                    }
                  }
                ),
              },
              uploadProgress: 0,
              annotationStatus: 'completed',
            }
            return annotationSet
          } catch (error) {
            console.error(error)
            return initAnnotationSet(
              initialAnnotationSetKind,
              initialTrainKind,
              file.name,
              'validateError'
            )
          }
        })
        const validates = await Promise.all(validatesPromise)

        getState().pages.createDatasetState.domainData.addedAnnotationSetList

        const combinedAnnotationSetList = validates
          .sort()
          .concat(universalAnnotationSetList)

        const imageCount = combinedAnnotationSetList.reduce(
          (total, currentValue) =>
            total + currentValue.groupedData.trainingDataList.length,
          0
        )

        if (imageCount > TRAINING_IMAGE_SIZE_MAX) {
          // 保持しているアノテーションセットの情報を元に戻す
          dispatch(
            createDatasetActions.addAnnotationFile(universalAnnotationSetList)
          )
          // エラートーストを表示
          dispatch(
            createDatasetActions.setToastInfo({
              type: 'error',
              title: `画像枚数が ${TRAINING_IMAGE_SIZE_MAX} 枚以下となるように設定してください`,
              targets: [],
            })
          )
          return
        }

        dispatch(
          createDatasetActions.addAnnotationFile(combinedAnnotationSetList)
        )
        const annotationSetKindList =
          (getState().pages.createDatasetState.domainData
            .selectedDatasetTemplate &&
            getState().pages.createDatasetState.domainData
              .selectedDatasetTemplate?.annotationSetKindList) ??
          []
        const annotationSetKindLength = annotationSetKindList.length ?? 1
        const completedValidates = validates.filter(
          (annotationSet) => annotationSet.annotationStatus === 'completed'
        )
        const kindDict: { [id: string]: number } = {}
        annotationSetKindList.forEach((kind) => {
          kindDict[
            `${kind.annotationSetKind}-${kind.conditions?.trainKind}`
          ] = 0
        })
        ;[...universalAnnotationSetList, ...completedValidates].forEach(
          (annotationSet) => {
            if (
              `${annotationSet.annotationSetKind}-${annotationSet.conditions?.trainKind}` in
              kindDict
            ) {
              kindDict[
                `${annotationSet.annotationSetKind}-${annotationSet.conditions?.trainKind}`
              ] += 1
            } else {
              kindDict[
                `${annotationSet.annotationSetKind}-${annotationSet.conditions?.trainKind}`
              ] = -Infinity
            }
          }
        )
        if (
          completedValidates.length > 0 &&
          Object.keys(kindDict).filter((key) => kindDict[key] > 0).length >=
            annotationSetKindLength
        ) {
          dispatch(createDatasetActions.setAnnotationSubState('selected'))
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(createDatasetActions.setInProgress(false))
      }
    },

  setInputData:
    (files: File[], annotationSetList: AddedAnnotationSetList[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      let updateAnnotationSetList = annotationSetList
      for (const file of files) {
        updateAnnotationSetList = updateAnnotationSetList.map(
          (annotationSet): AddedAnnotationSetList => ({
            ...annotationSet,
            groupedData: {
              trainingDataList: annotationSet.groupedData.trainingDataList.map(
                (groupedImage): TrainingData =>
                  groupedImage.file?.name === file.name
                    ? {
                        trainingDataId: '',
                        file: file,
                        fileStatus: 'selected',
                        dataKind: 'Image',
                      }
                    : groupedImage
              ),
            },
          })
        )
      }

      dispatch(createDatasetActions.addAnnotationFile(updateAnnotationSetList))

      if (
        updateAnnotationSetList.filter(
          (annotationSet) =>
            annotationSet.groupedData.trainingDataList.filter(
              (groupedImage) => groupedImage.fileStatus === 'unselected'
            ).length > 0
        ).length === 0
      ) {
        dispatch(createDatasetActions.completeImageFileSave())
      }
    },

  createDataset:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(createDatasetActions.setInProgress(true))

        const addedAnnotationSetList =
          getState().pages.createDatasetState.domainData.addedAnnotationSetList

        dispatch(
          createDatasetActions.addAnnotationFile(
            addedAnnotationSetList.map(
              (annotationSet): AddedAnnotationSetList =>
                annotationSet.groupedData.trainingDataList.filter(
                  (trainingData) => trainingData.fileStatus === 'completed'
                ).length === annotationSet.groupedData.trainingDataList.length
                  ? annotationSet
                  : {
                      ...annotationSet,
                      annotationStatus: 'uploading',
                      groupedData: {
                        ...annotationSet.groupedData,
                        trainingDataList:
                          annotationSet.groupedData.trainingDataList
                            .filter(
                              (trainingData) =>
                                trainingData.fileStatus !== 'completed'
                            )
                            .map(
                              (trainingData): TrainingData => ({
                                ...trainingData,
                                fileStatus: 'uploading',
                              })
                            )
                            .concat(
                              annotationSet.groupedData.trainingDataList.filter(
                                (trainingData) =>
                                  trainingData.fileStatus === 'completed'
                              )
                            ),
                      },
                    }
            )
          )
        )
        dispatch(createDatasetActions.uploading())

        if (
          addedAnnotationSetList.some((annotationSet) =>
            annotationSet.groupedData.trainingDataList.some(
              (trainingData) => trainingData.fileStatus !== 'completed'
            )
          )
        ) {
          // 画像 fileUpload
          // GCSへの署名付きURLを取得する
          let signedUrlMap: {
            [fileName: string]: { url: string; trainingImageId: string }
          }
          try {
            const list = addedAnnotationSetList
              .map((annotationSet) =>
                annotationSet.groupedData.trainingDataList
                  .filter(
                    (trainingData) =>
                      trainingData.file != null &&
                      trainingData.fileStatus !== 'completed'
                  )
                  .map((trainingData) => {
                    return {
                      fileType: trainingData.file?.type,
                      fileName: trainingData.file?.name,
                    }
                  })
              )
              .reduce((prev, current) => {
                prev.push(...current)
                return prev
              }, [])
            // 分割する件数
            const sliceNumber = 500
            const promiseSignedUrlMapList = new Array(
              Math.ceil(list.length / sliceNumber)
            )
              .fill((e: number) => e)
              .map((_, i) => list.slice(i * sliceNumber, (i + 1) * sliceNumber))
              .map(async (fileInfoList) => {
                const operation = async () => {
                  return FileUploadApi.getFileUploadSignedUrl(fileInfoList)
                }

                // 署名付きURL発行のCloudFunctionsを最大9回（400ms間隔（ExponentialBackOff））までリトライする
                const retryConfig = {
                  count: 9,
                  baseDelay: 400,
                  isExponentialBackOff: true,
                }

                const res = await retryOperation(operation, retryConfig)

                const signedUrlMap: {
                  [fileName: string]: { url: string; trainingImageId: string }
                } = res.data
                return signedUrlMap
              })

            const signedUrlMapList = await Promise.all(promiseSignedUrlMapList)
            signedUrlMap = signedUrlMapList.reduce((prev, current) => ({
              ...prev,
              ...current,
            }))
          } catch (e) {
            console.error(e)
            dispatch(
              createDatasetActions.uploadErrorAll(
                addedAnnotationSetList.map(
                  (annotationSet): AddedAnnotationSetList =>
                    annotationSet.groupedData.trainingDataList.filter(
                      (trainingData) => trainingData.fileStatus === 'completed'
                    ).length ===
                    annotationSet.groupedData.trainingDataList.length
                      ? annotationSet
                      : {
                          ...annotationSet,
                          annotationStatus: 'uploadError',
                          groupedData: {
                            ...annotationSet.groupedData,
                            trainingDataList:
                              annotationSet.groupedData.trainingDataList
                                .filter(
                                  (trainingData) =>
                                    trainingData.fileStatus !== 'completed'
                                )
                                .map(
                                  (trainingData): TrainingData => ({
                                    ...trainingData,
                                    fileStatus: 'uploadError',
                                  })
                                )
                                .concat(
                                  annotationSet.groupedData.trainingDataList.filter(
                                    (trainingData) =>
                                      trainingData.fileStatus === 'completed'
                                  )
                                ),
                          },
                        }
                )
              )
            )
            return
          }

          const groupedImageList = addedAnnotationSetList
            .map((annotationSet) =>
              annotationSet.groupedData.trainingDataList.map(
                (trainingData) => trainingData
              )
            )
            .reduce((prev, curr) => prev.concat(curr), [])
          // file Upload 処理

          const sliceNumber = 500
          const loopGroupedImageLists = new Array(
            Math.ceil(groupedImageList.length / sliceNumber)
          )
            .fill((e: number) => e)
            .map((_, i) =>
              groupedImageList.slice(i * sliceNumber, (i + 1) * sliceNumber)
            )
          const hasErrors: boolean[] = []
          for (const loopGroupedImageList of loopGroupedImageLists) {
            const promiseApiInfo = loopGroupedImageList
              .filter((groupedImage) => groupedImage.fileStatus !== 'completed')
              .map(async (groupedImage) => {
                try {
                  if (
                    groupedImage.file &&
                    signedUrlMap[groupedImage.file.name]
                  ) {
                    const apiInfo = await FileUploadApi.uploadFile(
                      signedUrlMap[groupedImage.file.name].url,
                      groupedImage.file
                    )
                    if (apiInfo.hasError) {
                      const annotationSetList: AddedAnnotationSetList[] =
                        getState().pages.createDatasetState.domainData
                          .addedAnnotationSetList
                      dispatch(
                        createDatasetActions.addAnnotationFile(
                          annotationSetList.map(
                            (annotationSet): AddedAnnotationSetList =>
                              annotationSet.groupedData.trainingDataList
                                .length ===
                              annotationSet.groupedData.trainingDataList.filter(
                                (trainingData) =>
                                  trainingData.fileStatus === 'completed'
                              ).length
                                ? annotationSet
                                : {
                                    ...annotationSet,
                                    annotationStatus: 'uploadError',
                                    groupedData: {
                                      ...annotationSet.groupedData,
                                      trainingDataList:
                                        annotationSet.groupedData.trainingDataList.map(
                                          (trainingData): TrainingData =>
                                            trainingData.file?.name ===
                                            apiInfo?.fileName
                                              ? {
                                                  ...groupedImage,
                                                  fileStatus: 'uploadError',
                                                }
                                              : groupedImage
                                        ),
                                    },
                                    uploadProgress: 0,
                                  }
                          )
                        )
                      )
                    } else {
                      const annotationSetList: AddedAnnotationSetList[] =
                        addedAnnotationSetList
                      const updatingAnnotationSetList = annotationSetList.map(
                        (annotationSet): AddedAnnotationSetList =>
                          annotationSet.groupedData.trainingDataList.length ===
                          annotationSet.groupedData.trainingDataList.filter(
                            (groupedImage) =>
                              groupedImage.fileStatus === 'completed'
                          ).length
                            ? annotationSet
                            : {
                                ...annotationSet,
                                groupedData: {
                                  ...annotationSet.groupedData,
                                  trainingDataList:
                                    annotationSet.groupedData.trainingDataList.map(
                                      (trainingData): TrainingData => {
                                        return {
                                          ...trainingData,
                                          trainingDataId:
                                            signedUrlMap[
                                              // NOTE: ファイル名がundefinedなるケースがない
                                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                              trainingData.file!.name
                                            ].trainingImageId,
                                          fileStatus: 'completed',
                                        }
                                      }
                                    ),
                                },
                                annotationStatus:
                                  annotationSet.groupedData.trainingDataList.filter(
                                    (trainingData) =>
                                      trainingData.fileStatus === 'uploadError'
                                  ).length > 0
                                    ? 'uploadError'
                                    : annotationSet.groupedData.trainingDataList.filter(
                                        (trainingData) =>
                                          trainingData.fileStatus ===
                                          'completed'
                                      ).length +
                                        1 ===
                                      annotationSet.groupedData.trainingDataList
                                        .length
                                    ? 'completed'
                                    : 'uploading',
                                uploadProgress: 0,
                              }
                      )
                      const uploadProgressAnnotationSetList =
                        updatingAnnotationSetList.map((annotationSet) => {
                          return {
                            ...annotationSet,
                            uploadProgress:
                              annotationSet.groupedData.trainingDataList.filter(
                                (trainingData) =>
                                  trainingData.fileStatus === 'completed'
                              ).length > 0
                                ? (annotationSet.groupedData.trainingDataList.filter(
                                    (trainingData) =>
                                      trainingData.fileStatus === 'completed'
                                  ).length /
                                    annotationSet.groupedData.trainingDataList
                                      .length) *
                                  100
                                : 0,
                          }
                        })

                      dispatch(
                        createDatasetActions.addAnnotationFile(
                          uploadProgressAnnotationSetList
                        )
                      )
                    }
                    return apiInfo.hasError
                  } else {
                    return false
                  }
                } catch {
                  return true
                }
              })
            hasErrors.push(...(await Promise.all(promiseApiInfo)))
          }

          if (hasErrors.some((hasError) => hasError)) {
            dispatch(createDatasetActions.uploadError())
            return
          }
        }

        const {
          selectedAlgorithmId,
          selectedAnnotationFormat,
          selectedDatasetTemplate,
          datasetMetadata,
          generatedFor,
        } = getState().pages.createDatasetState.domainData

        if (
          !(
            selectedAlgorithmId &&
            selectedAnnotationFormat &&
            selectedDatasetTemplate &&
            datasetMetadata &&
            generatedFor
          )
        ) {
          console.error('Error invalid request')
          dispatch(createDatasetActions.documentError())
          return
        }

        const requestAnnotationSetList =
          getState().pages.createDatasetState.domainData.addedAnnotationSetList

        const annotationSetList = requestAnnotationSetList.map(
          (annotationSet) => {
            return {
              annotationSetKind: annotationSet.annotationSetKind,
              conditions: annotationSet.conditions
                ? annotationSet.conditions
                : undefined,
              annotationData: {
                annotationId: annotationSet.annotationId,
                metadata: {
                  name: annotationSet.annotationMetadata.name,
                },
              },
              groupedData: {
                groupedImageList:
                  annotationSet.groupedData.trainingDataList.map(
                    (trainingData) => {
                      return {
                        trainingDataId: trainingData.trainingDataId,
                        fileName: trainingData.file?.name
                          ? trainingData.file?.name
                          : '',
                        fileType: trainingData.file?.type
                          ? trainingData.file?.type
                          : '',
                        dataKind: trainingData.dataKind
                          ? trainingData.dataKind
                          : 'Image',
                      }
                    }
                  ),
              },
            } as AnnotationSetListRequestType
          }
        )

        const selectedClassSet =
          getState().pages.createDatasetState.domainData.selectedClassSet

        const request: CreateDatasetRequestType = {
          algorithmId: selectedAlgorithmId,
          metadata: {
            name: datasetMetadata.name,
            remarks: datasetMetadata.remarks,
          },
          annotationSetList: annotationSetList,
          annotationFormatId: selectedAnnotationFormat.annotationFormatId,
          annotationFormatVersion: {
            displayName:
              selectedAnnotationFormat.annotationFormatVersion.displayName,
            major: selectedAnnotationFormat.annotationFormatVersion.major,
            minor: selectedAnnotationFormat.annotationFormatVersion.minor,
            patch: selectedAnnotationFormat.annotationFormatVersion.patch,
          },
          generatedFor: generatedFor,
          datasetTemplateId: selectedDatasetTemplate.datasetTemplateId,
          extended: !isUndefined(selectedClassSet)
            ? {
                objectClassification: {
                  classSet: {
                    classSetId: selectedClassSet.classSetId,
                    userGroupId: selectedClassSet.userGroupId,
                  },
                },
              }
            : undefined,
        }

        // dataset 作成
        const datasetResult: CreateDatasetFirestoreResult =
          await FileUploadApi.dataSetFireStore(request)

        const datasetId = datasetResult.data.datasetId
        dispatch(createDatasetActions.uploadCompleted())
        dispatch(createDatasetActions.setDatasetId(datasetId))
      } catch (error) {
        dispatch(createDatasetActions.documentError())
        console.error(error)
      } finally {
        dispatch(createDatasetActions.setInProgress(false))
      }
    },

  setAnnotationSetKind:
    (
      annotationSetIndex: number,
      annotationSetKind: AnnotationSetKindAll,
      annotationTrainKind?: AnnotationTrainKind
    ) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const annotationSetList =
        getState().pages.createDatasetState.domainData.addedAnnotationSetList

      // アノテーションセットの種別を更新する対象を引数の annotationSetName から検索し、更新
      const updatedAnnotationSetList = annotationSetList.map(
        (annotationSet, index) => {
          if (index === annotationSetIndex) {
            return {
              ...annotationSet,
              annotationSetKind: annotationSetKind,
              conditions: annotationTrainKind
                ? { trainKind: annotationTrainKind }
                : undefined,
            }
          } else {
            return annotationSet
          }
        }
      )
      dispatch(createDatasetActions.addAnnotationFile(updatedAnnotationSetList))

      const currentAnnotationSetKindList =
        getState().pages.createDatasetState.domainData.selectedDatasetTemplate
          ?.annotationSetKindList

      const annotationSetKindList = currentAnnotationSetKindList
        ? currentAnnotationSetKindList
        : []
      const annotationSetKindLength = annotationSetKindList.length ?? 1 // annotationSetKindList.length if selectedDatasetTemplate exists else 1
      const kindDict: { [id: string]: number } = {}
      annotationSetKindList.forEach((kind) => {
        kindDict[`${kind.annotationSetKind}-${kind.conditions?.trainKind}`] = 0
      })
      updatedAnnotationSetList.forEach((annotationSet) => {
        if (
          `${annotationSet.annotationSetKind}-${annotationSet.conditions?.trainKind}` in
          kindDict
        ) {
          kindDict[
            `${annotationSet.annotationSetKind}-${annotationSet.conditions?.trainKind}`
          ] += 1
        } else {
          kindDict[
            `${annotationSet.annotationSetKind}-${annotationSet.conditions?.trainKind}`
          ] = -Infinity
        }
      })
      if (
        Object.keys(kindDict).filter((key) => kindDict[key] > 0).length >=
        annotationSetKindLength
      ) {
        dispatch(createDatasetActions.setAnnotationSubState('selected'))
      } else {
        dispatch(createDatasetActions.setAnnotationSubState('unselected'))
      }
    },

  setNextStep:
    (
      datasetState: string,
      datasetSubState: {
        algorithmSubState: SelectType
        datasetTemplateSubState: SelectType
        annotationSubState: SelectType
        inputDataSubState: SelectType
        metadataSubState: InputRequireType
        uploadSubState: UploadSubState
      },
      payload: {
        annotationSetList?: AddedAnnotationSetList[]
      }
    ) =>
    async (dispatch: Dispatch): Promise<void> => {
      switch (datasetState) {
        case 'AlgorithmState':
          if (datasetSubState.algorithmSubState === 'selected') {
            dispatch(
              createDatasetActions.setDatasetState('DatasetTemplateState')
            )
          }
          break
        case 'DatasetTemplateState':
          if (datasetSubState.datasetTemplateSubState === 'selected') {
            dispatch(createDatasetActions.setDatasetState('AnnotationState'))
          }
          break
        case 'AnnotationState':
          if (datasetSubState.annotationSubState === 'selected') {
            dispatch(
              createDatasetActions.addAnnotationFile(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                payload.annotationSetList!.filter(
                  (annotationSet) =>
                    annotationSet.annotationStatus === 'completed'
                )
              )
            )
            dispatch(createDatasetActions.setDatasetState('InputDataState'))
          }
          break
        case 'InputDataState':
          if (datasetSubState.inputDataSubState === 'selected') {
            dispatch(createDatasetActions.setDatasetState('MetadataState'))
          }
          break
        case 'MetadataState':
          if (datasetSubState.metadataSubState === 'inputRequired') {
            dispatch(createDatasetActions.setDatasetState('UploadState'))
          }
          break
        default:
          dispatch(createDatasetActions.clearCreateDataset())
      }
    },

  setPrevStep:
    (props: {
      datasetState: string
      annotationSetList: AddedAnnotationSetList[]
      trainingAlgorithmSubStep?: boolean
    }) =>
    async (dispatch: Dispatch): Promise<void> => {
      const { datasetState, annotationSetList, trainingAlgorithmSubStep } =
        props
      switch (datasetState) {
        case 'AlgorithmState':
          if (trainingAlgorithmSubStep) {
            dispatch(createDatasetActions.clearClassSetStepState())
          }
          break
        case 'DatasetTemplateState':
          dispatch(createDatasetActions.clearDatasetTemplateStepState())
          break
        case 'AnnotationState':
          dispatch(createDatasetActions.clearAnnotationStepState())
          break
        case 'MetadataState':
          dispatch(
            createDatasetActions.clearMetadataStepState(
              annotationSetList.map(
                (annotationSet): AddedAnnotationSetList => ({
                  ...annotationSet,
                  groupedData: {
                    ...annotationSet.groupedData,
                    trainingDataList:
                      annotationSet.groupedData.trainingDataList.map(() => ({
                        trainingDataId: '',
                        file: undefined,
                        fileStatus: 'unselected',
                        dataKind: undefined,
                      })),
                  },
                  uploadProgress: 0,
                  annotationStatus: 'beforeUpload',
                })
              )
            )
          )
          break
        case 'UploadState':
          dispatch(createDatasetActions.clearUploadStepState())
          break
        case 'InputDataState':
        default:
          dispatch(createDatasetActions.clearInputStepState())
      }
    },

  getClassSets:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(createDatasetActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            createDatasetActions.setClassSetDisplayCondition({
              ...getState().pages.createDatasetState.domainData
                .classSetDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.createDatasetState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : domainDataOperations.getSharedUserGroupId()(dispatch, getState)

        const getClassSetListQuery =
          getState().pages.createDatasetState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetCollection(userGroupId))
            : query(
                getClassSetCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        const classSetDataListDocs = (await getDocs(getClassSetListQuery)).docs
        const classSetDataList: ClassSetDocument[] = classSetDataListDocs
          .map((classSetDoc) => {
            const classSetData = classSetDoc.data()

            if (!fireStoreTypeGuardForClassSetDocument(classSetData)) {
              return
            }

            return classSetData as ClassSetDocument
          })
          .filter(
            (classSetData) => !isUndefined(classSetData)
          ) as ClassSetDocument[]

        if (!classSetDataList) return

        const getClassSetMetaDataListQuery =
          getState().pages.createDatasetState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetMetaDataCollection(userGroupId))
            : query(
                getClassSetMetaDataCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
        const classSetMetaDataList: ClassSetMetaDataDocument[] = (
          await getDocs(getClassSetMetaDataListQuery)
        ).docs
          .map((doc) => {
            const classSetMetaDataData = doc.data()

            if (
              !fireStoreTypeGuardForClassSetMetaDataDocument(
                classSetMetaDataData
              )
            ) {
              return
            }

            return classSetMetaDataData as ClassSetMetaDataDocument
          })
          .filter(
            (classSetMetaData) => !isUndefined(classSetMetaData)
          ) as ClassSetMetaDataDocument[]

        const classSetList: ClassSet[] = classSetDataList.map(
          (classSetData) => {
            const classSetMetaData = classSetMetaDataList.find(
              (item) => item['class-set-id'] === classSetData['class-set-id']
            )
            return {
              classSetId: classSetData['class-set-id'],
              classSetName: classSetMetaData?.name ?? '',
              classSetRemarks: classSetMetaData?.remarks ?? '',
              classList: classSetData['class-list'],
              createdAt: classSetData['created-at'],
              userGroupId: classSetData['user-group-id'],
            }
          }
        )

        dispatch(createDatasetActions.setClassSets(classSetList))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(createDatasetActions.setInProgress(false))
      }
    },

  setSelectedClassSet:
    (classSet?: ClassSet) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(createDatasetActions.setSelectedClassSet(classSet))

      if (classSet) {
        dispatch(createDatasetActions.setClassSetSubState('selected'))
      } else {
        dispatch(createDatasetActions.setClassSetSubState('unselected'))
      }
    },

  getAvailableDatasetTemplates:
    (algorithmId: string, algorithmVersion: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // 選択したアルゴリズムIDのアルゴリズムデータを取得
      const targetAlgorithm = getState().app.domainData.algorithms.find(
        (algorithm) => algorithm.algorithmId === algorithmId
      )
      if (targetAlgorithm === undefined) {
        dispatch(createDatasetActions.setAvailableDatasetTemplates([]))
        return
      }

      // 対象のalgorithm-structureを取得
      const targetAlgorithmStructures =
        targetAlgorithm?.trainingAlgorithm.trainingAlgorithmVersions.find(
          (trainingAlgorithmVersion) =>
            trainingAlgorithmVersion.trainingAlgorithmVersion ===
            algorithmVersion
        )?.algorithmStructures
      if (targetAlgorithmStructures === undefined) {
        dispatch(createDatasetActions.setAvailableDatasetTemplates([]))
        return
      }

      const targetAlgorithmStructureVersions: {
        algorithmStructureId: string
        algorithmStructureVersion: Version
      }[] = []
      for (const targetAlgorithmStructure of targetAlgorithmStructures) {
        for (const targetAlgorithmStructureVersion of targetAlgorithmStructure.algorithmStructureVersions) {
          targetAlgorithmStructureVersions.push({
            algorithmStructureId:
              targetAlgorithmStructureVersion.algorithmStructureId,
            algorithmStructureVersion: {
              displayName:
                targetAlgorithmStructureVersion.algorithmStructureVersion
                  .displayName,
              major:
                targetAlgorithmStructureVersion.algorithmStructureVersion.major,
              minor:
                targetAlgorithmStructureVersion.algorithmStructureVersion.minor,
              patch:
                targetAlgorithmStructureVersion.algorithmStructureVersion.patch,
            },
          })
        }
      }

      const datasetTemplateIds: string[] = []
      /** available-algorithm-structure-version が存在する algorithm-structure-version がない場合`true` */
      let isNoneAvailableAlgorithmStructureVersion = true

      for (const targetAlgorithmStructureVersion of targetAlgorithmStructureVersions) {
        const algorithmStructureId =
          targetAlgorithmStructureVersion.algorithmStructureId
        const algorithmStructureVersion =
          targetAlgorithmStructureVersion.algorithmStructureVersion

        // 上記のalgorithm-structure-versionが、setting-formats document のavailable-structure-versionの範囲内のデータを取得
        const settingFormatId = (
          await getDocs(
            query(
              getSettingFormatsCollection(),
              where('algorithm-structure-id', '==', algorithmStructureId)
            )
          )
        ).docs
          .map((settingFormatDoc) => {
            const settingFormatData = settingFormatDoc.data()
            return settingFormatData['setting-format-id'] as string
          })
          .at(0)
        if (settingFormatId === undefined || settingFormatId === '') continue

        const algorithmStructureVersionDocs = (
          await getDocs(
            query(
              getSettingFormatVersionsCollection(settingFormatId),
              where('algorithm-structure-id', '==', algorithmStructureId)
            )
          )
        ).docs

        algorithmStructureVersionDocs.map((algorithmStructureVersionDoc) => {
          const algorithmStructureVersionData =
            algorithmStructureVersionDoc.data()

          if (
            algorithmStructureVersionData[
              'available-algorithm-structure-version'
            ] === undefined
          ) {
            return undefined
          }

          isNoneAvailableAlgorithmStructureVersion = false
          // algorithm-structure-versionがavailable-algorithm-structure-versionの範囲内のデータのみ対象とする
          const isDisplay = isInRange(
            algorithmStructureVersion,
            {
              displayName:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['lower-limit']['display-name'],
              major:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['lower-limit']['major'],
              minor:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['lower-limit']['minor'],
              patch:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['lower-limit']['patch'],
            },
            {
              displayName:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['upper-limit']['display-name'],
              major:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['upper-limit']['major'],
              minor:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['upper-limit']['minor'],
              patch:
                algorithmStructureVersionData[
                  'available-algorithm-structure-version'
                ]['upper-limit']['patch'],
            }
          )
          if (!isDisplay) return undefined

          // available-dataset-template.dataset-template-idを返却する
          datasetTemplateIds.push(
            ...algorithmStructureVersionData['available-dataset-template'].map(
              (availableDatasetTemplate: { 'dataset-template-id': string }) =>
                availableDatasetTemplate['dataset-template-id'] as string
            )
          )
        })
      }

      if (isNoneAvailableAlgorithmStructureVersion) {
        datasetTemplateIds.push(
          ...getState()
            .app.domainData.datasetTemplates.filter((datasetTemplate) => {
              return (
                datasetTemplate.algorithmId ===
                getState().pages.createDatasetState.domainData
                  .selectedAlgorithmId
              )
            })
            .map((datasetTemplate) => datasetTemplate.datasetTemplateId)
        )
      }

      const availableDatasetTemplateIds = Array.from(
        new Set(datasetTemplateIds.flat().filter((item) => item !== undefined))
      )

      const datasetTemplates =
        getState().app.domainData.datasetTemplates.filter((datasetTemplate) =>
          availableDatasetTemplateIds.includes(
            datasetTemplate.datasetTemplateId
          )
        )

      dispatch(
        createDatasetActions.setAvailableDatasetTemplates(datasetTemplates)
      )
    },
}

/**
 * 初期状態の annotationSet を作成する。
 * reducer に初期状態を利用しないため、 operationsにて定義する。
 *
 * @param fileName ファイル名
 * @param status annotationStatus 状態
 */
const initAnnotationSet = (
  annotationSetKind: AnnotationSetKindAll,
  trainKind: AnnotationTrainKind | undefined,
  fileName: string,
  status: AnnotationStatus
): AddedAnnotationSetList => ({
  annotationSetKind: annotationSetKind,
  conditions: trainKind
    ? {
        trainKind: trainKind,
      }
    : undefined,
  annotationMetadata: {
    name: fileName,
  },
  annotationId: '',
  annotationStatus: status,
  groupedData: { trainingDataList: [] },
  uploadProgress: 0,
})
