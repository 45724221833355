import { CreateSettingAction } from 'state/ducks/createSetting/actions'
import { CurrentCreateSettingState, CreateSettingActionType } from './types'

const initialState: CurrentCreateSettingState = {
  domainData: {
    setting: {
      settingId: '',
      trainingPrameterId: '',
      userGroupId: '',
    },
    settingFile: undefined,
    settingMetadata: undefined,
  },
  appState: {
    settingState: 'SettingFileAndMetadataState',
    settingSubState: {
      settingFileState: 'Uploading',
      metadataSubState: 'EmptyRequired',
      uploadSubState: 'BeforeUpload',
    },
    inProgress: false,
  },
}

export const CreateSettingReducer = (
  state: CurrentCreateSettingState = initialState,
  action: CreateSettingAction
): CurrentCreateSettingState => {
  switch (action.type) {
    case CreateSettingActionType.ADD_SETTING_FILE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingFile: action.payload.settingFile,
        },
        appState: {
          ...state.appState,
          settingSubState: {
            ...state.appState.settingSubState,
            settingFileState: 'Completed',
          },
        },
      }
    case CreateSettingActionType.SET_SETTING_METADATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          settingMetadata: action.payload.settingMetadata,
        },
        appState: {
          ...state.appState,
          settingSubState: {
            ...state.appState.settingSubState,
            metadataSubState: action.payload.settingMetadata?.name
              ? 'InputRequired'
              : 'EmptyRequired',
          },
        },
      }
    case CreateSettingActionType.SET_SETTING_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          settingState: action.payload.settingState,
        },
      }
    case CreateSettingActionType.SET_SETTING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          setting: action.payload.setting,
        },
        appState: {
          ...state.appState,
          settingSubState: {
            ...state.appState.settingSubState,
            uploadSubState: action.payload.setting.settingId
              ? 'Completed'
              : 'BeforeUpload',
          },
        },
      }
    case CreateSettingActionType.CREATE_SETTING_SET_SETTINGID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          setting: {
            ...state.domainData.setting,
            settingId: action.payload.settingId,
          },
        },
        appState: {
          ...state.appState,
          settingSubState: {
            ...state.appState.settingSubState,
            uploadSubState: action.payload.settingId
              ? 'Completed'
              : 'BeforeUpload',
          },
        },
      }
    case CreateSettingActionType.CLEAR_CURRENT_SETTING_STATE:
      return {
        ...initialState,
      }
    case CreateSettingActionType.CREATE_SETTING_DOCUMENT_ERROR:
      return {
        ...state,
        appState: {
          ...state.appState,
          settingSubState: {
            ...state.appState.settingSubState,
            uploadSubState: action.payload.uploadSubState,
          },
        },
      }
    case CreateSettingActionType.SET_IN_PROGRESS_FOR_CREATE_SETTING:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    default:
      return state
  }
}
