import {
  getAccountCustomerRelationsCollection,
  getCustomerMetadataCollection,
} from 'state/firebase'
import { Customer as CustomerDoc } from 'state/ducks/customerChange/types'
import { Customer } from 'utils/ducks/customerChange/types'
import { DocumentData, doc, getDoc, getDocs, query } from 'firebase/firestore'

/** カスタマーリストを取得する */
export const getCustomerList = async (
  accountId: string,
  accountGroupId: string,
  isSuperUser: boolean
): Promise<Customer[]> => {
  const accountCustomerRelationDoc = (
    await getDoc(
      doc(getAccountCustomerRelationsCollection(accountGroupId), accountId)
    )
  ).data()

  // カスタマー一覧を取得
  const allCustomerMetadataList = await getDocs(
    query(getCustomerMetadataCollection())
  )
  const allCustomerMetadataListConvert = allCustomerMetadataList.docs.map(
    (item) => {
      return {
        id: item.id,
        ...item.data(),
      } as DocumentData
    }
  )

  const customerList: Customer[] = []
  if (accountCustomerRelationDoc) {
    accountCustomerRelationDoc['customer-list'].forEach((item: CustomerDoc) => {
      // 一致したIDでオブジェクトをマージ
      const metaData = allCustomerMetadataListConvert.find(
        (data) => data.id === item['customer-id']
      )
      // カスタマーのオブジェクトを作成
      const customerInfo: Customer = {
        customerId: item['customer-id'],
        customerName: metaData ? metaData['name'] : '',
        role: isSuperUser ? 'admin' : item['role'],
        lastAccessed: item['last-accessed'],
      }
      customerList.push(customerInfo)
    })
  }
  return customerList
}
