import { AugParamTemplate } from './types'

import { timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** aug-param-templates documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as AugParamTemplate
  const schema = {
    required: [
      'aug-param-template-id',
      'metadata',
      '3d-scene',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    type: 'object',
    properties: {
      'aug-param-template-id': {
        type: 'string',
      },
      '3d-scene': {
        required: ['backgrounds', 'cameras', 'light-groups'],
        type: 'object',
        properties: {
          backgrounds: {
            type: 'array',
            items: {
              required: ['aug-3d-scene-background-id', 'user-group-id'],
              type: 'object',
              properties: {
                'aug-3d-scene-background-id': {
                  type: 'string',
                },
                'user-group-id': {
                  type: 'string',
                },
              },
            },
          },
          cameras: {
            type: 'array',
            items: {
              required: ['aug-3d-scene-camera-id', 'user-group-id'],
              type: 'object',
              properties: {
                'aug-3d-scene-camera-id': {
                  type: 'string',
                },
                'user-group-id': {
                  type: 'string',
                },
              },
            },
          },
          'light-groups': {
            type: 'array',
            items: {
              required: ['aug-3d-scene-light-group-id', 'user-group-id'],
              type: 'object',
              properties: {
                'aug-3d-scene-light-group-id': {
                  type: 'string',
                },
                'user-group-id': {
                  type: 'string',
                },
              },
            },
          },
        },
      },
      metadata: {
        required: ['name', 'overview'],
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          overview: {
            type: 'string',
          },
        },
      },
      'layout-kind': {
        type: 'string',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'aug-param-template',
      value: data as unknown as DataObject,
    })
    return false
  }
}
