import { Timestamp } from 'state/firebase'

// ActionType
export const ModelGroupEntryActionType = {
  SET_SELECTED_ALGORITHM: 'SET_SELECTED_ALGORITHM',
  SET_SELECTED_MODEL_KIND: 'SET_SELECTED_MODEL_KIND',
  SET_MODEL_GROUP_META_DATA_NAME: 'SET_MODEL_GROUP_META_DATA_NAME',
  SET_MODEL_GROUP_META_DATA_OVER_VIEW: 'SET_MODEL_GROUP_META_DATA_OVER_VIEW',
  SET_MODEL_GROUP_META_DATA_ICON: 'SET_MODEL_GROUP_META_DATA_ICON',
  SET_MODEL_GROUP_META_DATA_REMARKS: 'SET_MODEL_GROUP_META_DATA_REMARKS',
  SET_MODEL_GROUP_STATE: 'SET_MODEL_GROUP_STATE',
  SET_ALGORITHM_SUB_STATE: 'SET_ALGORITHM_SUB_STATE',
  SET_METADATA_SUB_STATE: 'SET_METADATA_SUB_STATE',
  SET_EXECUTE_MODEL_GROUP_SUB_STATE: 'SET_EXECUTE_MODEL_GROUP_SUB_STATE',
  SET_IN_PROGRESS_FOR_MODEL_GROUP_ENTRY:
    'SET_IN_PROGRESS_FOR_MODEL_GROUP_ENTRY',
  SET_TOAST_INFO_FOR_MODEL_GROUP_ENTRY: 'SET_TOAST_INFO_FOR_MODEL_GROUP_ENTRY',
  SET_EXECUTE_MODEL_GROUP_ID: 'SET_EXECUTE_MODEL_GROUP_ID',
  CLEAR_MODEL_GROUP_ENTRY_STATE: 'CLEAR_MODEL_GROUP_ENTRY_STATE',
  SET_SELECTED_CLASS_SET_FOR_MODEL_GROUP_ENTRY:
    'SET_SELECTED_CLASS_SET_FOR_MODEL_GROUP_ENTRY',
  CLEAR_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY:
    'CLEAR_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY',
  SET_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY:
    'SET_CLASS_SET_LIST_FOR_MODEL_GROUP_ENTRY',
  CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY:
    'CLEAR_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY',
  SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY:
    'SET_CLASS_SET_DISPLAY_CONDITION_FOR_MODEL_GROUP_ENTRY',
  SET_CLASS_SET_SUB_STATE_FOR_MODEL_GROUP_ENTRY:
    'SET_CLASS_SET_SUB_STATE_FOR_MODEL_GROUP_ENTRY',
} as const

export interface CreateModelGroupRequestType {
  trainedModelGroupId: string
  algorithmId: string
  name: string
  modelKind: 'Generic' | 'Custom' | 'Specified'
  remarks?: string
  overview?: string
  iconName?: string
  iconFileType?: string
  extended?: {
    objectClassification?: {
      classSet: {
        classSetId: string
        userGroupId: string
      }
    }
  }
  isSharedUserGroup: boolean
}

/**
 * クラスセット選択の表示条件
 */
export interface ClassSetDisplayCondition {
  /** 検索文字列 */
  searchValue: string
  /** ソートする項目のキー名 */
  sortKey: string
  /** ソート順 */
  sortOrder: 'asc' | 'desc'
  /** 表示データ数 */
  displayNumber: number
  /** 表示ページ番号 */
  pageNumber: number
  /** データ種別 */
  selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
}

/** クラスセット */
export interface ClassSet {
  /** クラスセットID */
  classSetId: string
  /** クラスセット名 */
  classSetName: string
  /** クラスセット備考 */
  classSetRemarks: string
  /** クラス名リスト */
  classList: string[]
  /** 登録日時 */
  createdAt: Timestamp
  /** ユーザグループ ID */
  userGroupId: string
}

export interface ModelGroupMetaData {
  modelGroupName: string
  overView?: string
  modelGroupIcon?: File
  remarks?: string
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface ModelGroupEntrySubStateKind {
  algorithmSubState: 'Unselected' | 'Selected'
  classSetSubState: 'Unselected' | 'Selected'
  metaDataSubState: 'EmptyRequired' | 'InputRequired'
  executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError'
}

export const ModelGroupEntryStateKindArray = [
  'AlgorithmState',
  'MetaDataState',
  'ExecuteState',
]

export type ModelGroupEntryStateKind =
  (typeof ModelGroupEntryStateKindArray)[number]

export interface ModelGroupEntryDomainData {
  selectedAlgorithm?: string // 選択中のアルゴリズム
  selectedTrainedModelKind?: 'Custom' | 'Specified'
  /** クラスセット一覧 */
  classSets: ClassSet[]
  /** 選択中のクラスセット */
  selectedClassSet?: ClassSet
  /** クラスセット選択の表示条件 */
  classSetDisplayCondition: ClassSetDisplayCondition
  modelGroupMetaData: ModelGroupMetaData // 入力されたモデルのメタデータ
  executedModeGroupId: string // 作成されたモデルグループID
}

export interface ModelGroupEntryAppState {
  inProgress: boolean // データ取得中、実行中フラグ
  toastInfo?: ToastInfo // トーストの表示する情報
  modelGroupEntryStateKind: ModelGroupEntryStateKind // モデルグループ作成画面のSTEP
  modelGroupEntrySubStateKind: ModelGroupEntrySubStateKind // モデルグループ作成画面の各種STEPの状態
}

export interface ModelGroupEntryState {
  domainData: ModelGroupEntryDomainData
  appState: ModelGroupEntryAppState
}
