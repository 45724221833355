import React from 'react'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'

import { InputTextProps } from './types'

const useStyles = makeStyles()((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
}))
export const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
  const { classes } = useStyles()
  return (
    <div>
      <TextField
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name={props.name}
        label={props.label}
        type={props.type}
        id={props.id}
        autoComplete={props.autoComplete}
        className={classes.textField}
        onChange={props.onChange}
        value={props.value}
        InputProps={{
          readOnly: props.readOnly,
        }}
        inputProps={{ 'data-testid': props['data-testid'] }}
      />
    </div>
  )
}
