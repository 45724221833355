import { FeatureDataTransferringListActions } from './actions'
import {
  FeatureDataTransferringListActionType,
  FeatureDataTransferringListState,
} from './types'

const initialState: FeatureDataTransferringListState = {
  domainData: {
    currentFeatureDataTransferringList: [],
    featureDataTransferringDisplayCondition: {
      searchValue: '',
      sortKey: 'ml-pipeline.started-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    mlPipelineIds: [],
    currentFeatureDataTransferringListSnapshot: undefined,
  },
  appState: {
    inProgress: false,
  },
}

export const FeatureDataTransferringListReducer = (
  state: FeatureDataTransferringListState = initialState,
  action: FeatureDataTransferringListActions
): FeatureDataTransferringListState => {
  switch (action.type) {
    case FeatureDataTransferringListActionType.SET_FEATURE_DATA_TRANSFERRING_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataTransferringList:
            action.payload.currentFeatureDataTransferringList,
        },
      }
    case FeatureDataTransferringListActionType.SET_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION:
      if (action.payload.featureDataTransferringDisplayCondition) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            featureDataTransferringDisplayCondition:
              action.payload.featureDataTransferringDisplayCondition,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataTransferringListActionType.CLEAR_FEATURE_DATA_TRANSFERRING_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          featureDataTransferringDisplayCondition: {
            ...initialState.domainData.featureDataTransferringDisplayCondition,
          },
        },
      }
    case FeatureDataTransferringListActionType.SET_ML_PIPELINE_IDS_FOR_FEATURE_DATA_TRANSFERRING_LIST:
      if (action.payload.mlPipelineIds) {
        return {
          ...state,
          domainData: {
            ...state.domainData,
            mlPipelineIds: action.payload.mlPipelineIds,
          },
        }
      } else {
        return { ...state }
      }
    case FeatureDataTransferringListActionType.SET_CURRENT_FEATURE_DATA_TRANSFERRING_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentFeatureDataTransferringListSnapshot:
            action.payload.currentFeatureDataTransferringListSnapshot,
        },
      }
    case FeatureDataTransferringListActionType.CLEAR_FEATURE_DATA_TRANSFERRING_LIST_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
