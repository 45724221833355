import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  ModelInfo,
  ModelListActionType,
  ModelListDisplayCondition,
  GetSubState,
} from './types'

export type ModelListActions = ActionsUnion<typeof modelListActions>

export const modelListActions = {
  setTrainedModelGroupId: (
    trainedModelGroupId: string
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_GROUP_ID',
    { trainedModelGroupId: string }
  > =>
    CreateAction(ModelListActionType.SET_TRAINED_MODEL_GROUP_ID, {
      trainedModelGroupId,
    }),
  setTrainedModelGroupName: (
    trainedModelGroupName: string
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_GROUP_NAME',
    { trainedModelGroupName: string }
  > =>
    CreateAction(ModelListActionType.SET_TRAINED_MODEL_GROUP_NAME, {
      trainedModelGroupName,
    }),
  setModelList: (
    modelList: ModelInfo[]
  ): ActionWithPayload<'SET_MODEL_LIST', { modelList: ModelInfo[] }> =>
    CreateAction(ModelListActionType.SET_MODEL_LIST, {
      modelList,
    }),
  clearModelList: (): Action<'CLEAR_MODEL_LIST'> =>
    CreateAction(ModelListActionType.CLEAR_MODEL_LIST),
  setListDisplayCondition: (
    modelListDisplayCondition: ModelListDisplayCondition
  ): ActionWithPayload<
    'SET_MODEL_LIST_DISPLAY_CONDITION',
    { modelListDisplayCondition: ModelListDisplayCondition }
  > =>
    CreateAction(ModelListActionType.SET_MODEL_LIST_DISPLAY_CONDITION, {
      modelListDisplayCondition,
    }),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_MODEL_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(ModelListActionType.SET_IN_PROGRESS_FOR_MODEL_LIST, {
      inProgress,
    }),
  setModelGroupSubState: (
    modelGroupSubState: GetSubState
  ): ActionWithPayload<
    'SET_MODEL_GROUP_SUB_STATE',
    { modelGroupSubState: GetSubState }
  > =>
    CreateAction(ModelListActionType.SET_MODEL_GROUP_SUB_STATE, {
      modelGroupSubState,
    }),
  clearModelListState: (): Action<'CLEAR_MODEL_LIST_STATE'> =>
    CreateAction(ModelListActionType.CLEAR_MODEL_LIST_STATE),
}
