import { Aug3DObject } from './types'

import { timeStampSchema } from '../common/schemas'
import { validateData, DataObject } from '../common/validations'

/** aug-3d-objects documentの型チェック */
export const fireStoreTypeGuard = (object: unknown): boolean => {
  const data = object as Aug3DObject
  const schema = {
    required: [
      'aug-3d-object-id',
      'aug-3d-object-kind',
      'file-name',
      'hash',
      'is-saved',
      'created-at',
      'created-by',
      'updated-at',
      'updated-by',
    ],
    type: 'object',
    properties: {
      'aug-3d-object-id': {
        type: 'string',
      },
      'aug-3d-object-kind': {
        type: 'string',
      },
      'file-name': {
        type: 'string',
      },
      hash: {
        type: 'string',
      },
      'is-saved': {
        type: 'boolean',
      },
      'created-at': {
        ...timeStampSchema,
      },
      'created-by': {
        type: 'string',
      },
      'updated-at': {
        ...timeStampSchema,
      },
      'updated-by': {
        type: 'string',
      },
    },
  }

  const validationSequences = [{ schema: schema }]
  const errors = validateData(
    validationSequences,
    data as unknown as DataObject
  )
  if (errors.length === 0) {
    return true
  } else {
    console.error({
      errors: errors,
      documentType: 'aug-3d-object',
      value: data as unknown as DataObject,
    })
    return false
  }
}
