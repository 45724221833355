import React, { useEffect } from 'react'
import { Item, Menu } from '../../components/index'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { isUUIDv4 } from 'utils/typeguard'
import { hasAuthAccountFunction, hasAuthCustomerFunction } from 'utils/auth'
import { AccountFunction, CustomerFunction } from 'views/utils/types'

type Props = {
  menu: Menu
  superUser: boolean
  accountGroupRole: string
  customerRole: string
}

type CustomerRouterProps = Props & {
  customerId: string
}

interface NestedRoutesProps extends Item {
  superUser: boolean
  accountGroupRole: string
  customerRole: string
}

interface GetAuthedFunctionProps {
  superUser: boolean
  accountGroupRole: string
  customerRole: string
  accountFunction?: AccountFunction
  customerFunction?: CustomerFunction
}

const getAuthedFunction = (props: GetAuthedFunctionProps) =>
  hasAuthAccountFunction({
    superUser: props.superUser,
    accountGroupRole: props.accountGroupRole,
    type: props.accountFunction,
  }) &&
  hasAuthCustomerFunction({
    auth: props.customerRole,
    type: props.customerFunction,
  })

const NestedRoutes: React.FC<NestedRoutesProps> = (props) => (
  <Route
    exact={props.exact}
    path={props.path}
    component={
      getAuthedFunction({
        superUser: props.superUser,
        accountGroupRole: props.accountGroupRole,
        customerRole: props.customerRole,
        accountFunction: props.accountFunction,
        customerFunction: props.customerFunction,
      })
        ? props.component
        : undefined
    }
  >
    {props.nestedPaths.length > 0 && (
      <Switch>
        {props.nestedPaths.map((nestedPath) => (
          <Route
            key={nestedPath.path}
            exact
            path={nestedPath.path}
            component={
              getAuthedFunction({
                superUser: props.superUser,
                accountGroupRole: props.accountGroupRole,
                customerRole: props.customerRole,
                accountFunction: nestedPath.accountFunction,
                customerFunction: nestedPath.customerFunction,
              })
                ? nestedPath.component
                : undefined
            }
          />
        ))}
      </Switch>
    )}
  </Route>
)

export const Router: React.FC<Props> = (props: Props) => (
  <Switch>
    {props.menu.general.categories.map((category) => {
      if (category.section) {
        return category.section.itemList.map((r) => (
          <NestedRoutes
            key={r['data-testid']}
            {...r}
            superUser={props.superUser}
            accountGroupRole={props.accountGroupRole}
            customerRole={props.customerRole}
          />
        ))
      }
      if (category.item) {
        return (
          <NestedRoutes
            key={category.item['data-testid']}
            {...category.item}
            superUser={props.superUser}
            accountGroupRole={props.accountGroupRole}
            customerRole={props.customerRole}
          />
        )
      }
    })}

    {(props.superUser || props.accountGroupRole === 'admin') &&
      props.menu.admin.categories.map((category) => {
        if (category.section) {
          return category.section.itemList.map((r) => (
            <NestedRoutes
              key={r['data-testid']}
              {...r}
              superUser={props.superUser}
              accountGroupRole={props.accountGroupRole}
              customerRole={props.customerRole}
            />
          ))
        }
        if (category.item) {
          return (
            <NestedRoutes
              key={category.item['data-testid']}
              {...category.item}
              superUser={props.superUser}
              accountGroupRole={props.accountGroupRole}
              customerRole={props.customerRole}
            />
          )
        }
      })}

    {props.superUser &&
      props.menu.superUser.categories.map((category) => {
        if (category.section) {
          return category.section.itemList.map((r) => (
            <NestedRoutes
              key={r['data-testid']}
              {...r}
              superUser={props.superUser}
              accountGroupRole={props.accountGroupRole}
              customerRole={props.customerRole}
            />
          ))
        }
        if (category.item) {
          return (
            <NestedRoutes
              key={category.item['data-testid']}
              {...category.item}
              superUser={props.superUser}
              accountGroupRole={props.accountGroupRole}
              customerRole={props.customerRole}
            />
          )
        }
      })}
  </Switch>
)

export const CustomerRouter: React.FC<CustomerRouterProps> = (
  props: CustomerRouterProps
) => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!hasCustomerId(location.pathname)) {
      history.replace(
        `/${props.customerId}${location.pathname}${location.search}`
      )
    }
  }, [location.pathname, location.search, props.customerId])

  return (
    <Switch>
      <Route exact={false} path='/:customerId'>
        <Router {...props} />
      </Route>
    </Switch>
  )
}

const hasCustomerId = (pathname: string): boolean => {
  const customerId = pathname.split('/').at(1)
  return isUUIDv4(customerId ?? '')
}
