import { Dispatch } from 'redux'
import { State } from 'state/store'
import { inferenceListActions } from './'
import {
  getDatasetsCollection,
  getTrainedModelQueriesCollection,
  getAnnotationSetsCollection,
  getGroupedDataMetadataCollection,
  getMlPipelineQueriesCollection,
} from 'state/firebase'
import { Inference } from './types'
import {
  convertProgressRateByTransactionStatusForInference,
  convertQueryStartEndCodeBySearchValue,
} from '../../utils'
import { isUndefined } from 'utils/typeguard'
import { Algorithm } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForInferenceMLPipelineQueryDocument } from 'utils/fireStore/inferenceMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForAnnotationSetsDocument } from 'utils/fireStore/annotationSet'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForGroupedDataMetadataDocument } from 'utils/fireStore/groupedDataMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetsDocument } from 'utils/fireStore/dataset'

const createInferenceData = async (
  userGroupId: string,
  snapshot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[]
): Promise<(Inference | undefined)[]> =>
  // 関連のDocsを取得し表示用の推論学習データを生成
  await Promise.all(
    snapshot.docs.map(async (document: DocumentData) => {
      const mlPipelineQueryDocData = document.data()
      if (
        !fireStoreTypeGuardForInferenceMLPipelineQueryDocument(
          mlPipelineQueryDocData
        )
      ) {
        return undefined
      }
      let trainedModelQuery
      let dataset
      let annotationSet
      let groupedDataMetadata
      let algorithm
      if (mlPipelineQueryDocData['inference-step']) {
        // モデル情報取得
        const trainedModelUserGroupId =
          mlPipelineQueryDocData['inference-step']['src']['trained-model'][
            'user-group-id'
          ] ?? userGroupId

        trainedModelQuery = (
          await getDocs(
            userGroupId === trainedModelUserGroupId
              ? query(
                  getTrainedModelQueriesCollection(trainedModelUserGroupId),
                  where(
                    'trained-model-id',
                    '==',
                    mlPipelineQueryDocData['inference-step']['src'][
                      'trained-model'
                    ]['trained-model-id']
                  )
                )
              : query(
                  getTrainedModelQueriesCollection(trainedModelUserGroupId),
                  where(
                    'trained-model-id',
                    '==',
                    mlPipelineQueryDocData['inference-step']['src'][
                      'trained-model'
                    ]['trained-model-id']
                  ),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
          )
        ).docs[0]?.data()

        if (!fireStoreTypeGuardForModelQueryDocument(trainedModelQuery)) {
          return undefined
        }
        // 画像セット取得
        /** データセットのデータ */
        dataset = mlPipelineQueryDocData['inference-step']['src']['dataset-id']
          ? (
              await getDoc(
                doc(
                  getDatasetsCollection(userGroupId),
                  mlPipelineQueryDocData['inference-step']['src']['dataset-id']
                )
              )
            ).data()
          : undefined

        if (!fireStoreTypeGuardForDatasetsDocument(dataset)) {
          return undefined
        }

        /** アノテーションセットのデータ */
        annotationSet = dataset
          ? (
              await getDoc(
                doc(
                  getAnnotationSetsCollection(userGroupId),
                  dataset['annotation-set-list'][0]
                )
              )
            ).data()
          : undefined
        if (!fireStoreTypeGuardForAnnotationSetsDocument(annotationSet)) {
          return undefined
        }
        /** グループデータのメタデータのデータ(画像セット) */
        groupedDataMetadata = annotationSet
          ? (
              await getDoc(
                doc(
                  getGroupedDataMetadataCollection(userGroupId),
                  annotationSet['grouped-data-id']
                )
              )
            ).data()
          : undefined

        if (
          !fireStoreTypeGuardForGroupedDataMetadataDocument(groupedDataMetadata)
        ) {
          return undefined
        }

        /** アルゴリズムを取得 */
        algorithm = algorithms.find((algorithm) => {
          return (
            algorithm.algorithmId ===
            mlPipelineQueryDocData['inference-step']['src']['algorithm-id']
          )
        })
      }

      return {
        mlPipelineId: mlPipelineQueryDocData['ml-pipeline']['ml-pipeline-id'],
        mlPipelineName: mlPipelineQueryDocData
          ? mlPipelineQueryDocData['ml-pipeline-metadata']['name']
            ? mlPipelineQueryDocData['ml-pipeline-metadata']['name']
            : ''
          : '',
        algorithmName: algorithm
          ? algorithm['metadata']['name']['ja']
            ? algorithm['metadata']['name']['ja']
            : ''
          : '',
        progress: {
          transactionStatus:
            mlPipelineQueryDocData['ml-pipeline']['transaction-status'],
          progressRate: convertProgressRateByTransactionStatusForInference(
            mlPipelineQueryDocData['ml-pipeline']['transaction-status']
          ),
        },
        startedAt: mlPipelineQueryDocData['ml-pipeline']['started-at'],
        endedAt:
          mlPipelineQueryDocData['ml-pipeline']['ended-at'] &&
          mlPipelineQueryDocData['ml-pipeline']['ended-at'].seconds === 0
            ? undefined
            : mlPipelineQueryDocData['ml-pipeline']['ended-at'],
        groupedData: {
          groupedDataId: annotationSet ? annotationSet['grouped-data-id'] : '',
          groupedDataName: groupedDataMetadata
            ? groupedDataMetadata['name']
            : '',
        },
        trainedModel: {
          trainedModelId: mlPipelineQueryDocData['inference-step']
            ? mlPipelineQueryDocData['inference-step']['src']['trained-model'][
                'trained-model-id'
              ]
            : '',
          trainedModelName: trainedModelQuery
            ? trainedModelQuery['trained-model-name']
            : '',
        },
        accountId: mlPipelineQueryDocData['created-by'],
      } as Inference
    })
  )

// 推論一覧の配列をセット
const getInferenceListRelationDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.inferenceListState.domainData.currentInferenceListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(inferenceListActions.setCurrentInferenceListSnapshot(undefined))
  }
  // 表示条件取得
  const condition =
    getState().pages.inferenceListState.domainData.inferenceDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery = query(
    getMlPipelineQueriesCollection(userGroupId),
    limit(condition.displayNumber),
    where('ml-pipeline.ml-pipeline-kind', '==', 'Inference')
  )

  if (condition.algorithmId) {
    commonQuery = query(
      commonQuery,
      where('inference-step.src.algorithm-id', '==', condition.algorithmId)
    )
  }

  // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、MLPipelineIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('ml-pipeline.ml-pipeline-id', 'asc'),
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  } else {
    commonQuery = query(
      commonQuery,
      orderBy(condition.sortKey, condition.sortOrder)
    )
  }

  const mlPipelineIds =
    getState().pages.inferenceListState.domainData.mlPipelineIds
  // 取得済みのカスタム学習の最後尾
  let mlPipeLineDoc: DocumentData | undefined = undefined
  if (mlPipelineIds.length > 0) {
    mlPipeLineDoc = await getDoc(
      doc(
        getMlPipelineQueriesCollection(userGroupId),
        mlPipelineIds[mlPipelineIds.length - 1]
      )
    )
  }

  // 取得済みの推論が存在する場合は、取得済みの最後尾以降のデータから取得する
  if (mlPipeLineDoc) {
    commonQuery = query(commonQuery, startAfter(mlPipeLineDoc))
  }

  const algorithms = getState().app.domainData.algorithms

  const snapshot = onSnapshot(commonQuery, async (snapshot: QuerySnapshot) => {
    const inferenceData = await createInferenceData(
      userGroupId,
      snapshot,
      algorithms
    )

    // ML Pipeline IDを保持
    // すでに保持しているIDが存在する場合は、現状の検索位置以降のIDを一度破棄し
    // 新たに取得したML Pipeline IDを保持する
    if (mlPipeLineDoc) {
      const index = mlPipelineIds.findIndex((id) => id === mlPipeLineDoc?.id)
      const beforePageIds = mlPipelineIds.slice(0, index + 1)
      dispatch(
        inferenceListActions.setMLPipeLineIdList([
          ...beforePageIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    } else {
      // IDを保持
      dispatch(
        inferenceListActions.setMLPipeLineIdList([
          ...mlPipelineIds,
          ...snapshot.docs.map((doc: DocumentData) => doc.id),
        ])
      )
    }
    // 取得したカスタム学習の一覧を保持
    dispatch(
      inferenceListActions.setList(
        inferenceData.filter((item) => item !== undefined) as Inference[]
      )
    )
    let totalCountQuery = query(
      getMlPipelineQueriesCollection(userGroupId),
      where('ml-pipeline.ml-pipeline-kind', '==', 'Inference')
    )

    if (condition.algorithmId) {
      totalCountQuery = query(
        totalCountQuery,
        where('inference-step.src.algorithm-id', '==', condition.algorithmId)
      )
    }

    if (condition.searchValue) {
      const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
        condition.searchValue
      )
      totalCountQuery = query(
        totalCountQuery,
        where('ml-pipeline.ml-pipeline-id', '>=', startCode),
        where('ml-pipeline.ml-pipeline-id', '<=', endCode)
      )
    }

    const totalCount = await getCountFromServer(totalCountQuery)
    dispatch(
      inferenceListActions.setListDisplayCondition({
        ...condition,
        totalCount: totalCount.data().count,
      })
    )
  })

  // 現在、表示中の推論一覧（ML Pipeline Docsの Snapshot を保持）
  dispatch(inferenceListActions.setCurrentInferenceListSnapshot(snapshot))
}

export const inferenceListOperations = {
  /** リストを取得する */
  getInferenceList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(inferenceListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentMlPipelineIds: string[] =
          getState().pages.inferenceListState.domainData.mlPipelineIds
        const condition =
          getState().pages.inferenceListState.domainData
            .inferenceDisplayCondition
        const mlPipelineIds = currentMlPipelineIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )

        dispatch(inferenceListActions.setMLPipeLineIdList(mlPipelineIds))

        // 推論一覧に必要な関連Docsを取得
        await getInferenceListRelationDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(inferenceListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.inferenceListState.domainData
            .currentInferenceListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
