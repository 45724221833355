import { SceneCameraEntryAction } from './actions'
import { SceneCameraEntryActionType, SceneCameraEntryState } from './types'

const initialState: SceneCameraEntryState = {
  domainData: {
    isUpdateRevision: false,
    currentSceneCameraList: [],
    sceneCameraIds: [],
    currentSceneCameraListSnapshot: undefined,
    cameraSetting: {
      fov: [],
      location: [],
      lookAt: [],
      formatVersion: undefined,
    },
    metadata: {
      name: '',
      overview: '',
    },
    cameraSettingFile: undefined,
    selectedSceneCamera: undefined,
    createdCameraId: undefined,
    sceneCameraListDisplayCondition: {
      searchValue: '',
      sortKey: 'created-at',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
  },
  appState: {
    sceneCameraEntryState: 'DestinationState',
    sceneCameraEntrySubState: {
      createSubState: 'BeforeCreate',
    },
    inProgress: false,
    toastInfo: undefined,
  },
}

export const SceneCameraEntryReducer = (
  state: SceneCameraEntryState = initialState,
  action: SceneCameraEntryAction
): SceneCameraEntryState => {
  switch (action.type) {
    case SceneCameraEntryActionType.SET_IS_UPDATE_REVISION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          isUpdateRevision: action.payload.isUpdateRevision,
        },
      }
    case SceneCameraEntryActionType.SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sceneCameraIds: action.payload.sceneCameraIds,
        },
      }
    case SceneCameraEntryActionType.SET_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSceneCameraList: action.payload.sceneCameraList,
        },
      }
    case SceneCameraEntryActionType.SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSceneCameraListSnapshot:
            action.payload.currentSceneCameraListSnapshot,
        },
      }
    case SceneCameraEntryActionType.CLEAR_SCENE_CAMERA_LIST_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentSceneCameraList: [],
        },
      }
    case SceneCameraEntryActionType.SET_SELECTED_SCENE_CAMERA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedSceneCamera: action.payload.selectedSceneCamera,
        },
      }
    case SceneCameraEntryActionType.SET_CAMERA_SETTING_FILE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          cameraSettingFile: action.payload.cameraSettingFile,
        },
      }
    case SceneCameraEntryActionType.SET_CAMERA_SETTING:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          cameraSetting: action.payload.cameraSetting,
        },
      }
    case SceneCameraEntryActionType.SET_METADATA_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          metadata: action.payload.metadata,
        },
      }
    case SceneCameraEntryActionType.SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          sceneCameraListDisplayCondition:
            action.payload.sceneCameraListDisplayCondition,
        },
      }
    case SceneCameraEntryActionType.SET_CREATED_CAMERA_ID:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          createdCameraId: action.payload.createdCameraId,
        },
      }
    case SceneCameraEntryActionType.SET_SCENE_CAMERA_ENTRY_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          sceneCameraEntryState: action.payload.sceneCameraEntryState,
        },
      }
    case SceneCameraEntryActionType.SET_IN_PROGRESS_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case SceneCameraEntryActionType.SET_SCENE_CAMERA_ENTRY_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          sceneCameraEntrySubState: action.payload.sceneCameraEntrySubState,
        },
      }
    case SceneCameraEntryActionType.SET_TOAST_INFO_FOR_SCENE_CAMERA_ENTRY:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    case SceneCameraEntryActionType.CLEAR_SCENE_CAMERA_ENTRY_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
