import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { TabLayoutProps } from './types'

const useStyles = makeStyles()((theme) => ({
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
}))

export const TabLayout: React.FC<TabLayoutProps> = (props: TabLayoutProps) => {
  const { classes } = useStyles()
  const [nowTab, setNowTab] = useState(props.defaultTabIndex ?? 0)
  if (props.items.length === 0) {
    return <></>
  }

  return (
    <>
      <Tabs
        indicatorColor='primary'
        value={nowTab}
        style={{
          marginBottom: '16px',
        }}
        onChange={(_, value) => setNowTab(value)}
      >
        {props.items.map((item, index) => (
          <Tab
            style={{
              width: `${100 / props.items.length}%`,
              maxWidth: '1200px',
            }}
            key={index}
            className={clsx(nowTab === index && classes.nowTab)}
            label={item.label}
            data-testid={`change-tab-${index}`}
          />
        ))}
      </Tabs>
      <Box maxHeight={props.contentsMaxHeight} overflow='scroll'>
        {props.items[nowTab].displayInfo}
      </Box>
    </>
  )
}
