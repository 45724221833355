import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
  ToastInfo,
} from 'state/utils'
import {
  FeatureDataGroupEntryActionType,
  FeatureDataGroupEntryStateKind,
} from './types'

export type FeatureDataGroupEntryAction = ActionsUnion<
  typeof featureDataGroupEntryActions
>

export const featureDataGroupEntryActions = {
  setSelectedAlgorithm: (
    selectedAlgorithm: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM',
    { selectedAlgorithm: string }
  > =>
    CreateAction(FeatureDataGroupEntryActionType.SET_SELECTED_ALGORITHM, {
      selectedAlgorithm,
    }),
  setFeatureDataGroupMetadataName: (
    featureDataGroupName: string
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_META_DATA_NAME',
    { featureDataGroupName: string }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_NAME,
      {
        featureDataGroupName,
      }
    ),
  setFeatureDataGroupOverView: (
    overView: string
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_META_DATA_OVER_VIEW',
    { overView: string }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_OVER_VIEW,
      {
        overView,
      }
    ),
  setFeatureDataGroupIcon: (
    featureDataGroupIcon: File | undefined
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_META_DATA_ICON',
    { featureDataGroupIcon: File | undefined }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_ICON,
      {
        featureDataGroupIcon,
      }
    ),
  setFeatureDataGroupRemarks: (
    remarks?: string
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_META_DATA_REMARKS',
    { remarks?: string }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_META_DATA_REMARKS,
      {
        remarks,
      }
    ),
  setFeatureDataGroupState: (
    featureDataGroupEntryStateKind: FeatureDataGroupEntryStateKind
  ): ActionWithPayload<
    'SET_FEATURE_DATA_GROUP_STATE',
    { featureDataGroupEntryStateKind: FeatureDataGroupEntryStateKind }
  > =>
    CreateAction(FeatureDataGroupEntryActionType.SET_FEATURE_DATA_GROUP_STATE, {
      featureDataGroupEntryStateKind,
    }),
  setAlgorithmSubState: (
    algorithmSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_ALGORITHM_SUB_STATE',
    { algorithmSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(FeatureDataGroupEntryActionType.SET_ALGORITHM_SUB_STATE, {
      algorithmSubState,
    }),
  setMetadataSubState: (
    metadataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_METADATA_SUB_STATE',
    { metadataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(FeatureDataGroupEntryActionType.SET_METADATA_SUB_STATE, {
      metadataSubState,
    }),
  setExecuteSubState: (
    executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError'
  ): ActionWithPayload<
    'SET_EXECUTE_FEATURE_DATA_GROUP_SUB_STATE',
    { executeSubState: 'BeforeExecute' | 'Executed' | 'ExecuteError' }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_EXECUTE_FEATURE_DATA_GROUP_SUB_STATE,
      {
        executeSubState,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_ENTRY',
    { inProgress: boolean }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_IN_PROGRESS_FOR_FEATURE_DATA_GROUP_ENTRY,
      {
        inProgress,
      }
    ),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_ENTRY',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_TOAST_INFO_FOR_FEATURE_DATA_GROUP_ENTRY,
      {
        toastInfo,
      }
    ),
  setGeneratedFeatureDataGroupId: (
    generatedFeatureDataGroupId: string
  ): ActionWithPayload<
    'SET_GENERATED_FEATURE_DATA_GROUP_ID',
    { generatedFeatureDataGroupId: string }
  > =>
    CreateAction(
      FeatureDataGroupEntryActionType.SET_GENERATED_FEATURE_DATA_GROUP_ID,
      {
        generatedFeatureDataGroupId,
      }
    ),
  clearFeatureDataGroupEntryState:
    (): Action<'CLEAR_FEATURE_DATA_GROUP_ENTRY_STATE'> =>
      CreateAction(
        FeatureDataGroupEntryActionType.CLEAR_FEATURE_DATA_GROUP_ENTRY_STATE
      ),
}
