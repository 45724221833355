import { Dispatch } from 'redux'
import { State } from 'state/store'
import { customTrainingActions } from './'
import {
  getDatasetQueryCollection,
  getSettingsCollection,
  getSettingsMetaDataCollection,
  getTrainedModelGroupQueriesCollection,
  getBaseInferenceContainerImageCollection,
  getTrainedModelQueriesCollection,
  getClassSetCollection,
  getSettingFormatsCollection,
  getClassSetMetaDataCollection,
  getSettingFormatVersionsCollection,
} from 'state/firebase'

import { CustomTrainingApi } from './apis'

import {
  TrainedModelGroup,
  Dataset,
  Setting,
  CustomTrainingStateKindArray,
  CustomTrainingParamsType,
  MetaData,
  TrainedModel,
  TrainedModelDataItem,
  InferenceAlgorithmVersion,
  DatasetList,
  ClassSet,
  PreProcessKind,
  DatasetTemplate,
} from './types'

import {
  TrainingAlgorithmVersion,
  InferenceAlgorithmVersion as InferenceAlgorithmVersionForDomain,
} from 'state/app/domainData'

import { formatDateTimeSec } from 'views/components/utils/date'
import { isUndefined } from 'utils/typeguard'
import {
  compareVersionsWithPreRelease,
  isInRange,
  isInRangeWithPreRelease,
} from 'utils/versions'
import { createInferenceVersionsData } from 'state/utils/algorithms'
import {
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Timestamp,
  where,
} from 'firebase/firestore'
import { HttpsCallableResult } from 'firebase/functions'
import { fireStoreTypeGuard as fireStoreTypeGuardForBaseInferenceContainerImageDocument } from 'utils/fireStore/baseInferenceContainerImage'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import {
  ClassSetDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetDocument,
} from 'utils/fireStore/classSet'
import {
  ClassSetMetaDataDocument,
  fireStoreTypeGuard as fireStoreTypeGuardForClassSetMetaDataDocument,
} from 'utils/fireStore/classSetMetaData'
import { BaseInferenceContainerImageDocument } from '../build'
import { domainDataOperations } from 'state/app/domainData/operations'
import { Version } from 'types/StateTypes'

const createDatasetData = async (
  snapShot: QuerySnapshot<DocumentData>
): Promise<(Dataset | undefined)[]> =>
  // 関連のDocsを取得し表示用のカスタム学習データを生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const datasetQueryDocData = document.data()
      if (!fireStoreTypeGuardForDatasetQueryDocument(datasetQueryDocData)) {
        return undefined
      }
      return {
        datasetId: datasetQueryDocData['dataset-id'],
        name: datasetQueryDocData
          ? datasetQueryDocData['dataset-name']
            ? datasetQueryDocData['dataset-name']
            : ''
          : '',
        createdAt: datasetQueryDocData['created-at'] ?? undefined,
        remarks: datasetQueryDocData
          ? datasetQueryDocData['dataset-remarks']
            ? datasetQueryDocData['dataset-remarks']
            : ''
          : '',
      } as Dataset
    })
  )

// データセット一覧の配列をセット
const getDatasetDocs = async (dispatch: Dispatch, getState: () => State) => {
  // データセット一覧取得
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId

  const datasetTemplateId =
    getState().pages.currentCustomTrainingState.domainData
      .selectedDatasetTemplateId

  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.currentCustomTrainingState.domainData
      .currentDatasetListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(customTrainingActions.setCurrentDatasetListSnapshot(undefined))
  }

  const datasetWhereQuery = [
    where(
      'algorithm-id',
      '==',
      getState().pages.currentCustomTrainingState.domainData
        .selectedTrainingAlgorithm?.algorithmId
    ),
    where('generated-for', '==', 'Training'),
    where('dataset-template-id', '==', datasetTemplateId ?? ''),
  ]

  const selectedClassSetId =
    getState().pages.currentCustomTrainingState.domainData.selectedClassSet
      ?.classSetId

  // クラスセットが選択済みの場合は、モデルグループの検索条件に追加
  if (!isUndefined(selectedClassSetId)) {
    datasetWhereQuery.push(
      where(
        'extended.object-classification.class-set.class-set-id',
        '==',
        selectedClassSetId
      )
    )
  }

  // ベースのQuery（表示件数分指定）
  const commonQuery = query(
    getDatasetQueryCollection(userGroupId),
    ...datasetWhereQuery
  )

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      const datasetData = await createDatasetData(snapshot)

      dispatch(
        customTrainingActions.setDatasetList(
          datasetData.filter((item) => item !== undefined) as Dataset[]
        )
      )
    }
  )

  // 現在、表示中のデータセット（Dataset Queries Collectionの Snapshot を保持）
  dispatch(customTrainingActions.setCurrentDatasetListSnapshot(snapshot))
}

const getSourceTrainedModelQueryData = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const isCustomer =
    getState().pages.currentCustomTrainingState.domainData
      .sourceModelGroupDisplayCondition.selectedUserGroupKind === 'UserGroup'

  const userGroupId = isCustomer
    ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
    : domainDataOperations.getSharedUserGroupId()(dispatch, getState)

  const trainedModelId =
    getState().pages.currentCustomTrainingState.domainData
      .selectedSourceTrainedModel?.trainedModelId

  const trainedModelDocs = (
    await getDocs(
      isCustomer
        ? query(
            getTrainedModelQueriesCollection(userGroupId),
            where('trained-model-id', '==', trainedModelId)
          )
        : query(
            getTrainedModelQueriesCollection(userGroupId),
            where('trained-model-id', '==', trainedModelId),
            where('access-control.is-shared', '==', true),
            where('access-control.share-permissions.webapp', '==', 'list')
          )
    )
  ).docs

  return trainedModelDocs.length > 0 ? trainedModelDocs[0].data() : undefined
}

const getSettingFormatIdByBaseModel = async (
  dispatch: Dispatch,
  getState: () => State
): Promise<string | undefined> => {
  const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
    dispatch,
    getState
  )

  // 共有データの参照権がない場合は、カスタマーデータに変更する
  if (!hasSharedUserGroup) {
    dispatch(
      customTrainingActions.setSourceTrainedModelGroupDisplayCondition({
        ...getState().pages.currentCustomTrainingState.domainData
          .sourceModelGroupDisplayCondition,
        selectedUserGroupKind: 'UserGroup',
      })
    )
  }

  const trainedModelData = await getSourceTrainedModelQueryData(
    dispatch,
    getState
  )

  const algorithmStructureId = trainedModelData?.['algorithm-structure-id']

  // 表示対象のセッティングフォーマットIDを取得
  const settingFormatList = (
    await getDocs(
      query(
        getSettingFormatsCollection(),
        where('algorithm-structure-id', '==', algorithmStructureId ?? '')
      )
    )
  ).docs

  // setting-format document と algorithm-structure document は 1:1 の関係のため 0 番目を取得
  return settingFormatList.length > 0
    ? settingFormatList[0].data()['setting-format-id']
    : undefined
}

const getSettingFormatVersion = async (settingFormatId: string) => {
  const settingFormatVersions = await getDocs(
    query(
      getSettingFormatVersionsCollection(settingFormatId),
      orderBy('setting-format-version.major', 'desc'),
      orderBy('setting-format-version.minor', 'desc'),
      orderBy('setting-format-version.patch', 'desc'),
      limit(1)
    )
  )

  if (settingFormatVersions.docs.length > 0) {
    const settingFormatVersion =
      settingFormatVersions.docs[0].data()?.['setting-format-version']

    if (settingFormatVersion) {
      return {
        settingFormatId: settingFormatId,
        settingFormatVersion: {
          displayName: settingFormatVersion['display-name'],
          major: settingFormatVersion['major'],
          minor: settingFormatVersion['minor'],
          patch: settingFormatVersion['patch'],
        },
      }
    }
  }

  return undefined
}

/**
 * trained-model-group-query の constraint.setting.setting-group-id を取得する
 * @param modelGroupId 選択したモデルグループID
 * @param userGroupId ユーザーグループID
 * @returns セッティンググループID
 */
const getSettingGroupId = async (
  modelGroupId: string,
  userGroupId: string
): Promise<string> => {
  const trainedModelGroupQueryData = (
    await getDoc(
      doc(getTrainedModelGroupQueriesCollection(userGroupId), modelGroupId)
    )
  ).data()
  if (
    trainedModelGroupQueryData === undefined ||
    !fireStoreTypeGuardForModelGroupQueryDocument(trainedModelGroupQueryData)
  ) {
    return ''
  }

  return (
    trainedModelGroupQueryData['constraint']?.['setting']?.[
      'setting-group-id'
    ] ?? ''
  )
}

export const customTrainingOperations = {
  getTrainedModelGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // ベースモデルの一覧とベースモデルのメタデータの一覧を取得して整形
      try {
        dispatch(customTrainingActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const trainedModelGroupWhereQuery = [
          where(
            'algorithm-id',
            '==',
            getState().pages.currentCustomTrainingState.domainData
              .selectedTrainingAlgorithm?.algorithmId
          ),
        ]

        const selectedClassSetId =
          getState().pages.currentCustomTrainingState.domainData
            .selectedClassSet?.classSetId

        // クラスセットが選択済みの場合は、モデルグループの検索条件に追加
        if (!isUndefined(selectedClassSetId)) {
          trainedModelGroupWhereQuery.push(
            where(
              'extended.object-classification.class-set.class-set-id',
              '==',
              selectedClassSetId
            )
          )
        }

        const trainedModelGroup = await getDocs(
          query(
            getTrainedModelGroupQueriesCollection(userGroupId),
            ...trainedModelGroupWhereQuery
          )
        )

        if (!trainedModelGroup) return

        const trainedModelGroups: (TrainedModelGroup | undefined)[] =
          await Promise.all(
            trainedModelGroup.docs.map(async (item) => {
              const trainedModelGroupData = item.data()
              if (
                !fireStoreTypeGuardForModelGroupQueryDocument(
                  trainedModelGroupData
                )
              ) {
                return undefined
              }
              const trainedModel = trainedModelGroupData[
                'trained-model-list'
              ].find(
                (data: TrainedModelDataItem) =>
                  data['trained-model-group-version']['display-name'] ===
                  `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
              )
              const trainedModelCount =
                trainedModelGroupData['trained-model-count']

              const trainedModels: (TrainedModel | undefined)[] =
                await Promise.all(
                  trainedModelGroupData['trained-model-list'].map(
                    async (content: TrainedModelDataItem) => {
                      const modelQuery = (
                        await getDoc(
                          doc(
                            getTrainedModelQueriesCollection(userGroupId),
                            content['trained-model-id']
                          )
                        )
                      ).data()

                      if (
                        modelQuery &&
                        fireStoreTypeGuardForModelQueryDocument(modelQuery)
                      ) {
                        const datasetList = modelQuery['dataset-list']
                          ? modelQuery['dataset-list']
                          : modelQuery['dataset-id']
                          ? [
                              {
                                'dataset-id': modelQuery['dataset-id'],
                                generation: 0,
                                'user-group-id': modelQuery['user-group-id'],
                              },
                            ]
                          : []

                        const datasets: (
                          | {
                              datasetId: string
                              name: string
                              generation: number
                              datasetTemplate: DatasetTemplate
                              createdAt?: Timestamp
                              remarks: string
                            }
                          | undefined
                        )[] = await Promise.all(
                          datasetList.map(async (dataset: DatasetList) => {
                            const datasetData = (
                              await getDocs(
                                dataset['user-group-id'] === sharedUserGroupId
                                  ? query(
                                      getDatasetQueryCollection(
                                        dataset['user-group-id']
                                      ),
                                      where(
                                        'dataset-id',
                                        '==',
                                        dataset['dataset-id']
                                      ),
                                      where(
                                        'access-control.is-shared',
                                        '==',
                                        true
                                      ),
                                      where(
                                        'access-control.share-permissions.webapp',
                                        '==',
                                        'list'
                                      )
                                    )
                                  : query(
                                      getDatasetQueryCollection(
                                        dataset['user-group-id']
                                      ),
                                      where(
                                        'dataset-id',
                                        '==',
                                        dataset['dataset-id']
                                      )
                                    )
                              )
                            ).docs[0]?.data()

                            if (datasetData) {
                              const targetDatasetTemplate =
                                getState().app.domainData.datasetTemplates.find(
                                  (datasetTemplate) =>
                                    datasetTemplate.datasetTemplateId ===
                                    datasetData['dataset-template-id']
                                )

                              return {
                                createdAt: datasetData['created-at'],
                                datasetId: datasetData['dataset-id'],
                                name: datasetData['dataset-name'],
                                generation: dataset['generation'],
                                datasetTemplate: {
                                  datasetTemplateId:
                                    targetDatasetTemplate?.datasetTemplateId ??
                                    '',
                                  metadata: {
                                    name: targetDatasetTemplate?.metadata.name,
                                  },
                                },
                                remarks: datasetData['dataset-remarks'] ?? '',
                              }
                            }
                          })
                        )

                        return {
                          trainedModelId: content['trained-model-id'],
                          trainedModelName: content['trained-model-name'],
                          trainedModelGroupVersion: {
                            displayName:
                              content['trained-model-group-version'][
                                'display-name'
                              ],
                            major:
                              content['trained-model-group-version']['major'],
                            minor:
                              content['trained-model-group-version']['minor'],
                            patch:
                              content['trained-model-group-version']['patch'],
                          },
                          transactionStatus: content['transaction-status'],
                          algorithmStructureId:
                            modelQuery['algorithm-structure-id'],
                          algorithmStructureVersion: {
                            displayName:
                              modelQuery['algorithm-structure-version'][
                                'display-name'
                              ],
                            major:
                              modelQuery['algorithm-structure-version'][
                                'major'
                              ],
                            minor:
                              modelQuery['algorithm-structure-version'][
                                'minor'
                              ],
                            patch:
                              modelQuery['algorithm-structure-version'][
                                'patch'
                              ],
                          },
                          generationType: modelQuery['generation-type'],
                          datasets: datasets
                            ? (datasets.filter(
                                (dataset) => dataset !== undefined
                              ) as Dataset[])
                            : [],
                        }
                      }
                    }
                  )
                )

              return {
                trainedModelGroupId:
                  trainedModelGroupData['trained-model-group-id'],
                trainedModelGroupName:
                  trainedModelGroupData['trained-model-group-name'],
                trainedModelCount: trainedModelCount,
                latestTrainedModelVersion:
                  trainedModelCount > 0
                    ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                    : '',
                latestTrainedModelName: trainedModel
                  ? trainedModel['trained-model-name']
                  : '',
                updatedAt: trainedModelGroupData['updated-at'],
                createdAt: trainedModelGroupData['created-at'],
                createdBy: trainedModelGroupData['created-by'],
                trainedModels: trainedModels,
                userGroupId: trainedModelGroupData['user-group-id'],
              } as TrainedModelGroup
            })
          )
        dispatch(
          customTrainingActions.setTrainedModelGroupList(
            trainedModelGroups.filter(
              (trainedModelGroup) => trainedModelGroup !== undefined
            ) as TrainedModelGroup[]
          )
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },
  getClassSetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(customTrainingActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            customTrainingActions.setClassSetDisplayCondition({
              ...getState().pages.currentCustomTrainingState.domainData
                .classSetDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().pages.currentCustomTrainingState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? getState().app.domainData.authedUser.auth.customClaims.userGroupId
            : domainDataOperations.getSharedUserGroupId()(dispatch, getState)

        const getClassSetListQuery =
          getState().pages.currentCustomTrainingState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetCollection(userGroupId))
            : query(
                getClassSetCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )

        const classSetDataListDocs = (await getDocs(getClassSetListQuery)).docs
        const classSetDataList: ClassSetDocument[] = classSetDataListDocs
          .map((classSetDoc) => {
            const classSetData = classSetDoc.data()

            if (!fireStoreTypeGuardForClassSetDocument(classSetData)) {
              return
            }

            return classSetData as ClassSetDocument
          })
          .filter(
            (classSetData) => !isUndefined(classSetData)
          ) as ClassSetDocument[]

        if (!classSetDataList) return

        const getClassSetMetaDataListQuery =
          getState().pages.currentCustomTrainingState.domainData
            .classSetDisplayCondition.selectedUserGroupKind === 'UserGroup'
            ? query(getClassSetMetaDataCollection(userGroupId))
            : query(
                getClassSetMetaDataCollection(userGroupId),
                where('access-control.is-shared', '==', true),
                where('access-control.share-permissions.webapp', '==', 'list')
              )
        const classSetMetaDataList: ClassSetMetaDataDocument[] = (
          await getDocs(getClassSetMetaDataListQuery)
        ).docs
          .map((doc) => {
            const classSetMetaDataData = doc.data()

            if (
              !fireStoreTypeGuardForClassSetMetaDataDocument(
                classSetMetaDataData
              )
            ) {
              return
            }

            return classSetMetaDataData as ClassSetMetaDataDocument
          })
          .filter(
            (classSetMetaData) => !isUndefined(classSetMetaData)
          ) as ClassSetMetaDataDocument[]

        const classSetList: ClassSet[] = classSetDataList.map(
          (classSetData) => {
            const classSetMetaData = classSetMetaDataList.find(
              (item) => item['class-set-id'] === classSetData['class-set-id']
            )
            return {
              classSetId: classSetData['class-set-id'],
              classSetName: classSetMetaData?.name ?? '',
              classSetRemarks: classSetMetaData?.remarks ?? '',
              classList: classSetData['class-list'],
              createdAt: classSetData['created-at'],
              userGroupId: classSetData['user-group-id'],
            }
          }
        )

        dispatch(customTrainingActions.setClassSetList(classSetList))
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },
  getSourceTrainedModelGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(customTrainingActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            customTrainingActions.setSourceTrainedModelGroupDisplayCondition({
              ...getState().pages.currentCustomTrainingState.domainData
                .sourceModelGroupDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const selectedDestinationTrainedModelGroup =
          getState().pages.currentCustomTrainingState.domainData
            .selectedDestinationTrainedModelGroup

        if (!selectedDestinationTrainedModelGroup) {
          dispatch(customTrainingActions.setInProgress(false))
          return
        }
        const selectedAlgorithmId =
          getState().pages.currentCustomTrainingState.domainData
            .selectedTrainingAlgorithm?.algorithmId

        if (!selectedAlgorithmId) {
          dispatch(customTrainingActions.setInProgress(false))
          return
        }

        if (selectedDestinationTrainedModelGroup.trainedModels.length === 0) {
          const userGroupTrainedModelGroup = await getDocs(
            query(
              getTrainedModelGroupQueriesCollection(userGroupId),
              where('algorithm-id', '==', selectedAlgorithmId)
            )
          )

          const sharedUserGroupTrainedModelGroup = hasSharedUserGroup
            ? await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(sharedUserGroupId),
                  where('access-control.is-shared', '==', true),
                  where(
                    'access-control.share-permissions.webapp',
                    '==',
                    'list'
                  ),
                  where('algorithm-id', '==', selectedAlgorithmId)
                )
              )
            : { docs: [] }

          if (!userGroupTrainedModelGroup || !sharedUserGroupTrainedModelGroup)
            return

          const userGroupTrainedModelGroups: (TrainedModelGroup | undefined)[] =
            await Promise.all(
              userGroupTrainedModelGroup.docs.map(async (item) => {
                const trainedModelGroupData = item.data()
                if (
                  !fireStoreTypeGuardForModelGroupQueryDocument(
                    trainedModelGroupData
                  )
                ) {
                  return undefined
                }
                const trainedModel = trainedModelGroupData[
                  'trained-model-list'
                ].find(
                  (data: TrainedModelDataItem) =>
                    data['trained-model-group-version']['display-name'] ===
                    `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                )
                const trainedModelCount =
                  trainedModelGroupData['trained-model-count']

                const trainedModels = []
                for (const trainedModel of trainedModelGroupData[
                  'trained-model-list'
                ]) {
                  const modelDoc = (
                    await getDocs(
                      query(
                        getTrainedModelQueriesCollection(userGroupId),
                        where(
                          'trained-model-id',
                          '==',
                          trainedModel['trained-model-id']
                        )
                      )
                    )
                  ).docs[0]?.data()

                  if (!modelDoc || !modelDoc['training-algorithm-version'])
                    continue

                  const isDisplay = isInRangeWithPreRelease(
                    {
                      displayName:
                        modelDoc['training-algorithm-version']['display-name'],
                      major: modelDoc['training-algorithm-version']['major'],
                      minor: modelDoc['training-algorithm-version']['minor'],
                      patch: modelDoc['training-algorithm-version']['patch'],
                      preRelease:
                        modelDoc['training-algorithm-version']['pre-release'],
                    },
                    {
                      displayName:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].lowerLimit.displayName ?? '',
                      major:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].lowerLimit.major ?? 0,
                      minor:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].lowerLimit.minor ?? 0,
                      patch:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].lowerLimit.patch ?? 0,
                      preRelease:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].lowerLimit.preRelease ?? 0,
                    },
                    {
                      displayName:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].upperLimit.displayName ?? '',
                      major:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].upperLimit.major ?? 0,
                      minor:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].upperLimit.minor ?? 0,
                      patch:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].upperLimit.patch ?? 0,
                      preRelease:
                        getState().pages.currentCustomTrainingState.domainData
                          .selectedTrainingAlgorithmVersion?.availableVersion?.[
                          'trained-model'
                        ].upperLimit.preRelease ?? 0,
                    }
                  )
                  if (!isDisplay) continue

                  trainedModels.push({
                    trainedModelId: trainedModel['trained-model-id'],
                    trainedModelName: trainedModel['trained-model-name'],
                    trainedModelGroupVersion: {
                      displayName:
                        trainedModel['trained-model-group-version'][
                          'display-name'
                        ],
                      major:
                        trainedModel['trained-model-group-version']['major'],
                      minor:
                        trainedModel['trained-model-group-version']['minor'],
                      patch:
                        trainedModel['trained-model-group-version']['patch'],
                    },
                    transactionStatus: trainedModel['transaction-status'],
                    algorithmStructureId:
                      modelDoc?.['algorithm-structure-id'] ?? '',
                    algorithmStructureVersion: {
                      displayName:
                        modelDoc?.['algorithm-structure-version']?.[
                          'display-name'
                        ] ?? '',
                      major:
                        modelDoc?.['algorithm-structure-version']?.['major'] ??
                        0,
                      minor:
                        modelDoc?.['algorithm-structure-version']?.['minor'] ??
                        0,
                      patch:
                        modelDoc?.['algorithm-structure-version']?.['patch'] ??
                        0,
                    },
                  })
                }

                return {
                  trainedModelGroupId:
                    trainedModelGroupData['trained-model-group-id'],
                  trainedModelGroupName:
                    trainedModelGroupData['trained-model-group-name'],
                  trainedModelCount: trainedModelCount,
                  latestTrainedModelVersion:
                    trainedModelCount > 0
                      ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                      : '',
                  latestTrainedModelName: trainedModel
                    ? trainedModel['trained-model-name']
                    : '',
                  updatedAt: trainedModelGroupData['updated-at'],
                  createdAt: trainedModelGroupData['created-at'],
                  createdBy: trainedModelGroupData['created-by'],
                  trainedModels,
                  algorithmId: trainedModelGroupData['algorithm-id'],
                  constraint: trainedModelGroupData['constraint']
                    ? {
                        setting: {
                          settingGroupId:
                            trainedModelGroupData['constraint']['setting'][
                              'setting-group-id'
                            ],
                          userGroupId:
                            trainedModelGroupData['constraint']['setting'][
                              'user-group-id'
                            ],
                        },
                      }
                    : undefined,
                  userGroupId: trainedModelGroupData['user-group-id'],
                } as TrainedModelGroup
              })
            )

          const sharedUserGroupTrainedModelGroups: (
            | TrainedModelGroup
            | undefined
          )[] = await Promise.all(
            sharedUserGroupTrainedModelGroup.docs.map(async (item) => {
              const trainedModelGroupData = item.data()
              if (
                !fireStoreTypeGuardForModelGroupQueryDocument(
                  trainedModelGroupData
                )
              ) {
                return undefined
              }

              const trainedModel = trainedModelGroupData[
                'trained-model-list'
              ].find(
                (data: TrainedModelDataItem) =>
                  data['trained-model-group-version']['display-name'] ===
                  `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
              )

              const trainedModelCount =
                trainedModelGroupData['trained-model-count']

              const trainedModels = []
              for (const trainedModel of trainedModelGroupData[
                'trained-model-list'
              ]) {
                const modelDoc = (
                  await getDocs(
                    query(
                      getTrainedModelQueriesCollection(sharedUserGroupId),
                      where(
                        'trained-model-id',
                        '==',
                        trainedModel['trained-model-id']
                      ),
                      where('access-control.is-shared', '==', true),
                      where(
                        'access-control.share-permissions.webapp',
                        '==',
                        'list'
                      )
                    )
                  )
                ).docs[0]?.data()

                if (!modelDoc || !modelDoc['training-algorithm-version'])
                  continue

                const isDisplay = isInRangeWithPreRelease(
                  {
                    displayName:
                      modelDoc['training-algorithm-version']['display-name'],
                    major: modelDoc['training-algorithm-version']['major'],
                    minor: modelDoc['training-algorithm-version']['minor'],
                    patch: modelDoc['training-algorithm-version']['patch'],
                    preRelease:
                      modelDoc['training-algorithm-version']['pre-release'],
                  },
                  {
                    displayName:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].lowerLimit.displayName ?? '',
                    major:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].lowerLimit.major ?? 0,
                    minor:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].lowerLimit.minor ?? 0,
                    patch:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].lowerLimit.patch ?? 0,
                    preRelease:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].lowerLimit.preRelease ?? 0,
                  },
                  {
                    displayName:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].upperLimit.displayName ?? '',
                    major:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].upperLimit.major ?? 0,
                    minor:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].upperLimit.minor ?? 0,
                    patch:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].upperLimit.patch ?? 0,
                    preRelease:
                      getState().pages.currentCustomTrainingState.domainData
                        .selectedTrainingAlgorithmVersion?.availableVersion?.[
                        'trained-model'
                      ].upperLimit.preRelease ?? 0,
                  }
                )
                if (!isDisplay) continue

                trainedModels.push({
                  trainedModelId: trainedModel['trained-model-id'],
                  trainedModelName: trainedModel['trained-model-name'],
                  trainedModelGroupVersion: {
                    displayName:
                      trainedModel['trained-model-group-version'][
                        'display-name'
                      ],
                    major: trainedModel['trained-model-group-version']['major'],
                    minor: trainedModel['trained-model-group-version']['minor'],
                    patch: trainedModel['trained-model-group-version']['patch'],
                  },
                  transactionStatus: trainedModel['transaction-status'],
                  algorithmStructureId:
                    modelDoc?.['algorithm-structure-id'] ?? '',
                  algorithmStructureVersion: {
                    displayName:
                      modelDoc?.['algorithm-structure-version']?.[
                        'display-name'
                      ] ?? '',
                    major:
                      modelDoc?.['algorithm-structure-version']?.['major'] ?? 0,
                    minor:
                      modelDoc?.['algorithm-structure-version']?.['minor'] ?? 0,
                    patch:
                      modelDoc?.['algorithm-structure-version']?.['patch'] ?? 0,
                  },
                })
              }

              return {
                trainedModelGroupId:
                  trainedModelGroupData['trained-model-group-id'],
                trainedModelGroupName:
                  trainedModelGroupData['trained-model-group-name'],
                trainedModelCount: trainedModelCount,
                latestTrainedModelVersion:
                  trainedModelCount > 0
                    ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                    : '',
                latestTrainedModelName: trainedModel
                  ? trainedModel['trained-model-name']
                  : '',
                updatedAt: trainedModelGroupData['updated-at'],
                createdAt: trainedModelGroupData['created-at'],
                createdBy: trainedModelGroupData['created-by'],
                trainedModels,
                algorithmId: trainedModelGroupData['algorithm-id'],
                constraint: trainedModelGroupData['constraint']
                  ? {
                      setting: {
                        settingGroupId:
                          trainedModelGroupData['constraint']['setting'][
                            'setting-group-id'
                          ],
                        userGroupId:
                          trainedModelGroupData['constraint']['setting'][
                            'user-group-id'
                          ],
                      },
                    }
                  : undefined,
                userGroupId: trainedModelGroupData['user-group-id'],
              } as TrainedModelGroup
            })
          )

          dispatch(
            customTrainingActions.setSourceTrainedModelGroupList({
              userGroup: userGroupTrainedModelGroups.filter(
                (trainedModelGroup) => trainedModelGroup !== undefined
              ) as TrainedModelGroup[],
              sharedUserGroup: sharedUserGroupTrainedModelGroups.filter(
                (trainedModelGroup) => trainedModelGroup !== undefined
              ) as TrainedModelGroup[],
            })
          )
        } else {
          const trainedModelGroups: (TrainedModelGroup | undefined)[] =
            await Promise.all(
              selectedDestinationTrainedModelGroup.trainedModels.map(
                async (trainedModel) => {
                  const trainedModelData = (
                    await getDoc(
                      doc(
                        getTrainedModelQueriesCollection(userGroupId),
                        trainedModel.trainedModelId
                      )
                    )
                  ).data()
                  if (!trainedModelData) {
                    return
                  }
                  const baseTrainedModelData = (
                    await getDocs(
                      query(
                        getTrainedModelQueriesCollection(sharedUserGroupId),
                        where(
                          'trained-model-id',
                          '==',
                          trainedModelData['base-model-id']
                            ? trainedModelData['base-model-id']
                            : trainedModelData['base-model']['trained-model-id']
                        ),
                        where('access-control.is-shared', '==', true),
                        where(
                          'access-control.share-permissions.webapp',
                          '==',
                          'list'
                        )
                      )
                    )
                  ).docs[0]?.data()

                  if (!baseTrainedModelData) {
                    return
                  }

                  const trainedModelGroupData = (
                    await getDocs(
                      query(
                        getTrainedModelGroupQueriesCollection(
                          sharedUserGroupId
                        ),
                        where(
                          'trained-model-group-id',
                          '==',
                          baseTrainedModelData['trained-model-group-id']
                        ),
                        where('access-control.is-shared', '==', true),
                        where(
                          'access-control.share-permissions.webapp',
                          '==',
                          'list'
                        )
                      )
                    )
                  ).docs[0]?.data()

                  if (!trainedModelGroupData) {
                    return
                  }

                  const trainedModelListData = trainedModelGroupData[
                    'trained-model-list'
                  ].find(
                    (data: TrainedModelDataItem) =>
                      data['trained-model-group-version']['display-name'] ===
                      `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                  )

                  const trainedModelCount =
                    trainedModelGroupData['trained-model-count']

                  const trainedModels = []
                  for (const trainedModel of trainedModelGroupData[
                    'trained-model-list'
                  ]) {
                    const modelDoc = (
                      await getDocs(
                        query(
                          getTrainedModelQueriesCollection(sharedUserGroupId),
                          where(
                            'trained-model-id',
                            '==',
                            trainedModel['trained-model-id']
                          ),
                          where('access-control.is-shared', '==', true),
                          where(
                            'access-control.share-permissions.webapp',
                            '==',
                            'list'
                          )
                        )
                      )
                    ).docs[0]?.data()

                    if (!modelDoc || !modelDoc['training-algorithm-version'])
                      continue

                    const isDisplay = isInRangeWithPreRelease(
                      {
                        displayName:
                          modelDoc['training-algorithm-version'][
                            'display-name'
                          ],
                        major: modelDoc['training-algorithm-version']['major'],
                        minor: modelDoc['training-algorithm-version']['minor'],
                        patch: modelDoc['training-algorithm-version']['patch'],
                        preRelease:
                          modelDoc['training-algorithm-version']['pre-release'],
                      },
                      {
                        displayName:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].lowerLimit
                            .displayName ?? '',
                        major:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].lowerLimit
                            .major ?? 0,
                        minor:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].lowerLimit
                            .minor ?? 0,
                        patch:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].lowerLimit
                            .patch ?? 0,
                        preRelease:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].lowerLimit
                            .preRelease ?? 0,
                      },
                      {
                        displayName:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].upperLimit
                            .displayName ?? '',
                        major:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].upperLimit
                            .major ?? 0,
                        minor:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].upperLimit
                            .minor ?? 0,
                        patch:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].upperLimit
                            .patch ?? 0,
                        preRelease:
                          getState().pages.currentCustomTrainingState.domainData
                            .selectedTrainingAlgorithmVersion
                            ?.availableVersion?.['trained-model'].upperLimit
                            .preRelease ?? 0,
                      }
                    )
                    if (!isDisplay) continue

                    trainedModels.push({
                      trainedModelId: trainedModel['trained-model-id'],
                      trainedModelName: trainedModel['trained-model-name'],
                      trainedModelGroupVersion: {
                        displayName:
                          trainedModel['trained-model-group-version'][
                            'display-name'
                          ],
                        major:
                          trainedModel['trained-model-group-version']['major'],
                        minor:
                          trainedModel['trained-model-group-version']['minor'],
                        patch:
                          trainedModel['trained-model-group-version']['patch'],
                      },
                      transactionStatus: trainedModel['transaction-status'],
                      algorithmStructureId:
                        modelDoc?.['algorithm-structure-id'] ?? '',
                      algorithmStructureVersion: {
                        displayName:
                          modelDoc?.['algorithm-structure-version']?.[
                            'display-name'
                          ] ?? '',
                        major:
                          modelDoc?.['algorithm-structure-version']?.[
                            'major'
                          ] ?? 0,
                        minor:
                          modelDoc?.['algorithm-structure-version']?.[
                            'minor'
                          ] ?? 0,
                        patch:
                          modelDoc?.['algorithm-structure-version']?.[
                            'patch'
                          ] ?? 0,
                      },
                    })
                  }

                  return {
                    trainedModelGroupId: trainedModelGroupData
                      ? trainedModelGroupData['trained-model-group-id']
                      : '',
                    trainedModelGroupName: trainedModelGroupData
                      ? trainedModelGroupData['trained-model-group-name']
                      : '',
                    trainedModelCount: trainedModelCount,
                    latestTrainedModelVersion:
                      trainedModelCount > 0
                        ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                        : '',
                    latestTrainedModelName: trainedModelListData
                      ? trainedModelListData['trained-model-name']
                      : '',
                    updatedAt: trainedModelGroupData['updated-at'],
                    createdAt: trainedModelGroupData['created-at'],
                    createdBy: trainedModelGroupData['created-by'],
                    trainedModels,
                    userGroupId: trainedModelGroupData['user-group-id'],
                  } as TrainedModelGroup
                }
              )
            )

          const filteredTrainedGroupModel = trainedModelGroups.filter(
            (trainedModelGroup) => trainedModelGroup !== undefined
          ) as TrainedModelGroup[]

          const uniqueTrainedModelGroup = filteredTrainedGroupModel.filter(
            (trainedModelGroup, index, self) =>
              self.findIndex(
                (e) =>
                  e.trainedModelGroupId ===
                  trainedModelGroup.trainedModelGroupId
              ) === index
          )

          dispatch(
            customTrainingActions.setSourceTrainedModelGroupList({
              userGroup: [selectedDestinationTrainedModelGroup],
              sharedUserGroup: uniqueTrainedModelGroup,
            })
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },
  getDatasetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // データセットの一覧とデータセットメタデータの一覧を取得して整形
      try {
        dispatch(customTrainingActions.setInProgress(true))

        // データセット一覧に必要な関連Docsを取得
        await getDatasetDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribeDatasetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.currentCustomTrainingState.domainData
            .currentDatasetListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },

  getSettingList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // セッティングの一覧とセッティングメタデータの一覧を取得して整形
      try {
        dispatch(customTrainingActions.setInProgress(true))
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const modelGroupId =
          getState().pages.currentCustomTrainingState.domainData
            .selectedDestinationTrainedModelGroup?.trainedModelGroupId ?? ''

        // 登録先モデルグループの縛り
        const settingGroupId = await getSettingGroupId(
          modelGroupId,
          userGroupId
        )

        let settingFormatId: string | undefined = undefined

        // 選択中のクラスセットIDを取得
        const selectedClassSetId =
          getState().pages.currentCustomTrainingState.domainData
            .selectedClassSet?.classSetId

        if (!isUndefined(selectedClassSetId)) {
          settingFormatId = await getSettingFormatIdByBaseModel(
            dispatch,
            getState
          )
        }

        const getSettingCollectionQuery = settingFormatId
          ? query(
              getSettingsCollection(userGroupId),
              where('setting-format-id', '==', settingFormatId),
              where('setting-group-id', '==', settingGroupId)
            )
          : query(
              getSettingsCollection(userGroupId),
              where('setting-group-id', '==', settingGroupId)
            )

        // セッティング一覧取得
        const allSettingsList = await getDocs(getSettingCollectionQuery)
        const allSettingsListConvert = allSettingsList.docs.map((item) => {
          return {
            id: item.id,
            ...item.data(),
          } as DocumentData
        })

        // メタデータ一覧を取得
        const allSettingsMetadataList = await getDocs(
          query(getSettingsMetaDataCollection(userGroupId))
        )
        const allSettingsMetadataListConvert = allSettingsMetadataList.docs.map(
          (item) => {
            return {
              id: item.id,
              ...item.data(),
            } as DocumentData
          }
        )

        // ベースモデルの情報を取得
        const sourceTrainedModelQueryData =
          await getSourceTrainedModelQueryData(dispatch, getState)
        const algorithmStructureId = sourceTrainedModelQueryData
          ? sourceTrainedModelQueryData['algorithm-structure-id']
          : ''
        const algorithmStructureVersion = sourceTrainedModelQueryData
          ? sourceTrainedModelQueryData['algorithm-structure-version']
          : ''

        // 作成するセッティング配列
        const settings: Setting[] = []
        for (const item of allSettingsListConvert) {
          // 一致したIDでオブジェクトをマージ
          const metaData = allSettingsMetadataListConvert.find(
            (data) => data.id === item.id
          )
          // dataset-template-id から対象のデータを抽出
          const targetDatasetTemplate =
            getState().app.domainData.datasetTemplates.find(
              (datasetTemplate) =>
                datasetTemplate.datasetTemplateId ===
                item['dataset-template-id']
            )
          if (targetDatasetTemplate === undefined) {
            break
          }

          // setting-formats から algorithm-structure-id に一致する setting-format-id を取得する
          const settingFormatsDocs = (
            await getDocs(
              query(
                getSettingFormatsCollection(),
                where('algorithm-structure-id', '==', algorithmStructureId)
              )
            )
          ).docs
          const settingFormatIds = settingFormatsDocs.map(
            (settingFormatsDoc) => {
              const settingFormatData = settingFormatsDoc.data()
              return settingFormatData['setting-format-id']
            }
          )
          const targetSettingFormatId = settingFormatIds.at(0)

          const settingFormatVersionDocs: QueryDocumentSnapshot[] =
            targetSettingFormatId
              ? (
                  await getDocs(
                    query(
                      getSettingFormatVersionsCollection(targetSettingFormatId)
                    )
                  )
                ).docs
              : []

          // 上記で割り出された setting-format-id, setting-format-version の
          // setting document のみ選択できるようにする
          const settingFormatId = item['setting-format-id']
          const settingFormatVersion: Version = {
            displayName: item['setting-format-version']?.['display-name'] ?? '',
            major: item['setting-format-version']?.['major'] ?? 0,
            minor: item['setting-format-version']?.['minor'] ?? 0,
            patch: item['setting-format-version']?.['patch'] ?? 0,
          }
          let disabled = true
          for (const settingFormatVersionDoc of settingFormatVersionDocs) {
            const settingFormatVersionData = settingFormatVersionDoc.data()

            // algorithm-structure-version が setting-format-version collection の
            // available-algorithm-structure-version の範囲内となる setting-format-version document を割り出す
            const isAvailable = isInRange(
              algorithmStructureVersion ?? {
                displayName: '',
                major: 0,
                minor: 0,
                patch: 0,
              },
              {
                displayName:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['lower-limit']['display-name'],
                major:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['lower-limit']['major'],
                minor:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['lower-limit']['minor'],
                patch:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['lower-limit']['patch'],
              },
              {
                displayName:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['upper-limit']['display-name'],
                major:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['upper-limit']['major'],
                minor:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['upper-limit']['minor'],
                patch:
                  settingFormatVersionData[
                    'available-algorithm-structure-version'
                  ]?.['upper-limit']['patch'],
              }
            )

            if (!isAvailable) continue

            if (
              settingFormatVersionData['setting-format-id'] ===
                settingFormatId &&
              settingFormatVersionData['setting-format-version']['major'] ===
                settingFormatVersion.major &&
              settingFormatVersionData['setting-format-version']['minor'] ===
                settingFormatVersion.minor &&
              settingFormatVersionData['setting-format-version']['patch'] ===
                settingFormatVersion.patch
            ) {
              // UI上で活性となる
              disabled = false
              break
            }
          }

          // セッティンググループ名を取得する
          const settingGroupDocs = (
            await getDocs(
              query(
                getTrainedModelGroupQueriesCollection(userGroupId),
                where(
                  'constraint.setting.setting-group-id',
                  '==',
                  item['setting-group-id']
                )
              )
            )
          ).docs
          const settingGroupName = settingGroupDocs
            .map((settingGroupDoc) => {
              const settingGroupData = settingGroupDoc.data()
              if (!fireStoreTypeGuardForModelGroupQueryDocument)
                return undefined

              return settingGroupData['trained-model-group-name'] as string
            })
            .find((item) => item !== undefined)

          // Settingのオブジェクトを作成
          const setting: Setting = {
            settingId: item.id,
            name: metaData ? (metaData['name'] ? metaData['name'] : '') : '',
            createdAt: item['created-at'] ? item['created-at'] : undefined,
            remarks: metaData
              ? metaData['remarks']
                ? metaData['remarks']
                : ''
              : '',
            settingFormatId: item['setting-format-id'],
            settingFormatVersion: {
              displayName:
                item['setting-format-version']?.['display-name'] ?? '',
              major: item['setting-format-version']?.['major'] ?? 0,
              minor: item['setting-format-version']?.['minor'] ?? 0,
              patch: item['setting-format-version']?.['patch'] ?? 0,
            },
            disabled,
            settingGroupName: settingGroupName ?? '',
            settingGroupVersion: {
              displayName: item['setting-group-version']['display-name'],
              major: item['setting-group-version']['major'],
              minor: item['setting-group-version']['minor'],
              patch: item['setting-group-version']['patch'],
            },
            datasetTemplate: {
              datasetTemplateId: targetDatasetTemplate.datasetTemplateId,
              metadata: {
                name: targetDatasetTemplate.metadata.name,
              },
            },
          }
          settings.push(setting)
        }

        dispatch(customTrainingActions.setSettingList(settings))
        dispatch(customTrainingActions.clearSettingDisplayCondition())
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },

  /**
   * セッティング付加情報を取得する
   */
  getObjectClassificationExtendedSetting:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(customTrainingActions.setInProgress(true))

        let targetSettingFormat:
          | { settingFormatId: string; settingFormatVersion: Version }
          | undefined = undefined

        const selectedSetting =
          getState().pages.currentCustomTrainingState.domainData.selectedSetting

        if (!isUndefined(selectedSetting)) {
          targetSettingFormat = {
            settingFormatId: selectedSetting.settingFormatId,
            settingFormatVersion: {
              ...selectedSetting.settingFormatVersion,
            },
          }
        } else {
          const settingFormatId = await getSettingFormatIdByBaseModel(
            dispatch,
            getState
          )

          if (isUndefined(settingFormatId)) return

          targetSettingFormat = await getSettingFormatVersion(settingFormatId)
        }

        if (!targetSettingFormat) return

        const {
          data: {
            result: { fileNameForDownload },
          },
        } = (await CustomTrainingApi.getSettingFormatSchemaSignedUrl(
          targetSettingFormat.settingFormatId,
          targetSettingFormat.settingFormatVersion.displayName
        )) as HttpsCallableResult<{
          result: { fileNameForDownload: string; name: string }
        }>

        const settingSchema = await CustomTrainingApi.getSettingSchema(
          fileNameForDownload
        )

        if (
          isUndefined(
            getState().pages.currentCustomTrainingState.domainData
              .extendedSetting.objectClassification
          )
        ) {
          dispatch(
            customTrainingActions.setSelectedSettingFormat(targetSettingFormat)
          )
          dispatch(
            customTrainingActions.setObjectClassificationExtendedSetting({
              preProcessKind: settingSchema.properties.pre_process_kind
                .default as PreProcessKind,
              baseSize: {
                width:
                  settingSchema.properties.advanced.properties.base_size
                    .properties.width.default,
                height:
                  settingSchema.properties.advanced.properties.base_size
                    .properties.height.default,
              },
            })
          )
          dispatch(
            customTrainingActions.setPreProcessKind(
              settingSchema.properties.pre_process_kind.enum
            )
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },

  getOutputFormatData:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(customTrainingActions.setInProgress(true))
        const selectedAlgorithmId =
          getState().pages.currentCustomTrainingState.domainData
            .selectedTrainingAlgorithm?.algorithmId

        if (isUndefined(selectedAlgorithmId)) {
          console.error('unselected training algorithm')
          return
        }

        const algorithmList = getState().app.domainData.algorithms

        const algorithm = algorithmList.find(
          (algorithm) => algorithm.algorithmId === selectedAlgorithmId
        )
        if (isUndefined(algorithm)) {
          console.error('no selected training algorithm')
          return
        }

        const selectedTrainingAlgorithmVersion =
          getState().pages.currentCustomTrainingState.domainData
            .selectedTrainingAlgorithmVersion
        if (isUndefined(selectedTrainingAlgorithmVersion)) {
          console.error('no selected training algorithm version')
          return
        }

        const matchVersions: InferenceAlgorithmVersionForDomain[] = []
        algorithm.inferenceAlgorithm.inferenceAlgorithmVersions.find(
          (version) => {
            const trainedModelAlgorithmVersion =
              selectedTrainingAlgorithmVersion.algorithmVersion

            const lowerLimitVersion =
              version.availableVersion['training-algorithm'].lowerLimit
            const lowerLimitCheck = compareVersionsWithPreRelease(
              lowerLimitVersion,
              trainedModelAlgorithmVersion
            )

            const upperLimitVersion =
              version.availableVersion['training-algorithm'].upperLimit
            const upperLimitCheck = compareVersionsWithPreRelease(
              upperLimitVersion,
              trainedModelAlgorithmVersion
            )

            if (
              (lowerLimitCheck === 1 || lowerLimitCheck === 0) &&
              (upperLimitCheck === -1 || upperLimitCheck === 0)
            ) {
              matchVersions.push(version)
            }
          }
        )

        const inferenceVersions: InferenceAlgorithmVersion[] = []
        const confirmedVersion: {
          inferenceAlgorithmVersion: string
          containerInterfaceVersion: string
          inferenceCodeVersion: string
        }[] = []

        await Promise.all(
          matchVersions.map(async (version) => {
            const allBaseInferenceContainerImage = await getDocs(
              query(
                getBaseInferenceContainerImageCollection(),
                where('algorithm-id', '==', selectedAlgorithmId),
                where(
                  'inference-algorithm-version.display-name',
                  '==',
                  version.inferenceAlgorithmVersion
                ),
                where('usage-type', '==', 'edge')
              )
            )

            const allBaseInferenceContainerImageConvert =
              allBaseInferenceContainerImage.docs.map((doc) =>
                doc.data()
              ) as BaseInferenceContainerImageDocument[]

            await Promise.all(
              allBaseInferenceContainerImageConvert.map(
                async (baseInferenceContainerImage) => {
                  if (
                    !fireStoreTypeGuardForBaseInferenceContainerImageDocument(
                      baseInferenceContainerImage
                    )
                  ) {
                    return
                  }
                  const inferenceCodeVersion =
                    baseInferenceContainerImage['inference-code-version']?.[
                      'display-name'
                    ] ?? ''

                  // 確認済みバージョンのインデックス
                  const checkedVersionIndex = confirmedVersion.findIndex(
                    (data) =>
                      data.inferenceAlgorithmVersion ===
                        baseInferenceContainerImage[
                          'inference-algorithm-version'
                        ]['display-name'] &&
                      data.containerInterfaceVersion ===
                        baseInferenceContainerImage[
                          'container-interface-version'
                        ]['display-name'] &&
                      data.inferenceCodeVersion === inferenceCodeVersion
                  )

                  // すでに確認済みのバージョンはスキップする
                  if (checkedVersionIndex !== -1) {
                    return
                  }
                  confirmedVersion.push({
                    inferenceAlgorithmVersion:
                      baseInferenceContainerImage[
                        'inference-algorithm-version'
                      ]['display-name'],
                    containerInterfaceVersion:
                      baseInferenceContainerImage[
                        'container-interface-version'
                      ]['display-name'],
                    inferenceCodeVersion: inferenceCodeVersion,
                  })

                  // 現在のバージョンの配列
                  const nowVersions: BaseInferenceContainerImageDocument[] = []
                  allBaseInferenceContainerImageConvert.map(
                    (data: BaseInferenceContainerImageDocument) => {
                      // inference-algorithm-version, container-interface-version, inference-code-version が一致する
                      // base-inference-container-imageを集約する
                      const inferenceCodeVersionForComparison =
                        data['inference-code-version']?.['display-name'] ?? ''
                      if (
                        data['inference-algorithm-version']['display-name'] ===
                          baseInferenceContainerImage[
                            'inference-algorithm-version'
                          ]['display-name'] &&
                        data['container-interface-version']['display-name'] ===
                          baseInferenceContainerImage[
                            'container-interface-version'
                          ]['display-name'] &&
                        inferenceCodeVersionForComparison ===
                          inferenceCodeVersion
                      ) {
                        nowVersions.push(data)
                      }
                    }
                  )

                  const containerImages = nowVersions.map((item) => {
                    return {
                      baseInferenceContainerImageId:
                        item['base-inference-container-image-id'],
                      containerImageTags: item['container-image-tags'],
                      containerImagePlatform: item['container-image-platform'],
                    }
                  })

                  // 推論アルゴリズムのオブジェクトを作成
                  const data: InferenceAlgorithmVersion =
                    createInferenceVersionsData(
                      version,
                      baseInferenceContainerImage,
                      containerImages
                    )

                  inferenceVersions.push(data)
                }
              )
            )
          })
        )
        dispatch(
          customTrainingActions.setInferenceAlgorithmVersions(inferenceVersions)
        )
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },

  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        customTrainingActions.setCustomTrainingState(
          CustomTrainingStateKindArray[currentStep + 1]
        )
      )
      switch (currentStep) {
        case 4: // データセット
          customTrainingOperations.unsubscribeDatasetList()
          break
        case 5: // アウトプット形式
          // メタデータの入力ステップ時にカスタム学習、カスタムモデルの表示名のデフォルト値を設定
          // eslint-disable-next-line no-case-declarations
          const modelName =
            getState().pages.currentCustomTrainingState.domainData
              .selectedSourceTrainedModel?.trainedModelName
          // eslint-disable-next-line no-case-declarations
          const regex = /\/|\s+|:/g
          dispatch(
            customTrainingActions.setMlPipelinesMetaData({
              name: `${modelName}_${formatDateTimeSec(new Date()).replace(
                regex,
                ''
              )}`,
              remarks:
                getState().pages.currentCustomTrainingState.domainData
                  .mlPipelinesMetaData?.remarks,
            })
          )
          dispatch(
            customTrainingActions.setCustomModelMetaData({
              name: modelName,
              remarks:
                getState().pages.currentCustomTrainingState.domainData
                  .customModelMetaData?.remarks,
            })
          )

          if (modelName) {
            dispatch(customTrainingActions.setMetaDataSubState('InputRequired'))
          }
          break
        default:
          break
      }
    },
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 0:
          dispatch(customTrainingActions.setSelectedClassSet(undefined))
          return
        case 1:
          dispatch(
            customTrainingActions.setSelectedDestinationTrainedModelGroup(
              undefined
            )
          )
          dispatch(customTrainingActions.setTrainedModelGroupList([]))
          dispatch(customTrainingActions.setModelGroupSubState('Unselected'))
          break
        case 2:
          dispatch(customTrainingActions.setSelectedBaseModel(undefined))
          dispatch(
            customTrainingActions.setSelectedSourceTrainedModelGroup(undefined)
          )
          dispatch(customTrainingActions.setBaseModelSubState('Unselected'))
          break
        case 3:
          dispatch(customTrainingActions.setSelectedSetting(undefined))
          dispatch(customTrainingActions.setSelectedSettingFormat(undefined))
          dispatch(
            customTrainingActions.setObjectClassificationExtendedSetting(
              undefined
            )
          )
          dispatch(
            customTrainingActions.setObjectRecognitionExtendedSetting({
              isEnabledAnnotationTargeting: false,
              hiddenPercentage: 100,
            })
          )
          dispatch(customTrainingActions.setSettingSubState('Unselected'))
          break
        case 4:
          customTrainingOperations.unsubscribeDatasetList()
          dispatch(customTrainingActions.setDatasetList([]))
          dispatch(customTrainingActions.setSelectedInheritModel(undefined))
          dispatch(customTrainingActions.setSelectedDataset(undefined))
          dispatch(customTrainingActions.setDatasetSubState('Unselected'))
          break
        case 5:
          dispatch(customTrainingActions.setIsTransfer(true))
          dispatch(customTrainingActions.setIsSystemEvaluation(true))
          dispatch(
            customTrainingActions.setSelectedCustomTrainingGenerateKind(
              'CustomModel'
            )
          )
          dispatch(
            customTrainingActions.setSelectedInferenceAlgorithmVersion(
              undefined
            )
          )
          dispatch(customTrainingActions.setInferenceAlgorithmVersions([]))
          dispatch(customTrainingActions.setOutputFormatSubState('Unselected'))
          break
        case 6:
          dispatch(customTrainingActions.setMlPipelinesMetaData(undefined))
          dispatch(customTrainingActions.setCustomModelMetaData(undefined))
          dispatch(customTrainingActions.setMetaDataSubState('EmptyRequired'))
          break
        case 7:
          break
        default:
          break
      }

      dispatch(
        customTrainingActions.setCustomTrainingState(
          CustomTrainingStateKindArray[currentStep - 1]
        )
      )
    },
  setSelectedTrainingAlgorithmVersion:
    (trainingAlgorithmVersion?: TrainingAlgorithmVersion) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        customTrainingActions.setSelectedTrainingAlgorithmVersion(
          trainingAlgorithmVersion
        )
      )

      if (
        trainingAlgorithmVersion &&
        getState().pages.currentCustomTrainingState.domainData
          .selectedTrainingAlgorithm?.algorithmId
      ) {
        dispatch(customTrainingActions.setTrainingAlgorithmSubState('Selected'))
      } else {
        dispatch(
          customTrainingActions.setTrainingAlgorithmSubState('Unselected')
        )
      }
    },
  setSelectedDestinationTrainedModelGroup:
    (modelGroup?: TrainedModelGroup) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(
        customTrainingActions.setSelectedDestinationTrainedModelGroup(
          modelGroup
        )
      )

      if (modelGroup) {
        dispatch(customTrainingActions.setModelGroupSubState('Selected'))
      } else {
        dispatch(customTrainingActions.setModelGroupSubState('Unselected'))
      }
    },
  setSelectedClassSet:
    (classSet?: ClassSet) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(customTrainingActions.setSelectedClassSet(classSet))

      if (classSet) {
        dispatch(customTrainingActions.setClassSetSubState('Selected'))
      } else {
        dispatch(customTrainingActions.setClassSetSubState('Unselected'))
      }
    },
  setSelectedBaseModel:
    (baseModel?: TrainedModel) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(customTrainingActions.setSelectedBaseModel(baseModel))

      if (
        baseModel &&
        getState().pages.currentCustomTrainingState.domainData
          .selectedSourceTrainedModelGroup
      ) {
        dispatch(customTrainingActions.setBaseModelSubState('Selected'))
      } else {
        dispatch(customTrainingActions.setBaseModelSubState('Unselected'))
      }
    },
  setSelectedDataset:
    (dataset?: Dataset) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(customTrainingActions.setSelectedDataset(dataset))

      if (dataset) {
        dispatch(customTrainingActions.setDatasetSubState('Selected'))
      } else {
        dispatch(customTrainingActions.setDatasetSubState('Unselected'))
      }
    },
  setSelectedSetting:
    (setting: Setting | undefined, assignmentSetting: boolean) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(customTrainingActions.setSelectedSetting(setting))

      if (setting) {
        dispatch(
          customTrainingActions.setSelectedDatasetTemplateId(
            setting.datasetTemplate.datasetTemplateId
          )
        )
        dispatch(customTrainingActions.setSettingSubState('Selected'))
      } else {
        // デフォルト選択時、ベースモデルのalgorithm-structure-id/algorithm-structure-versionに紐づく
        // dataset-template-id を保持する
        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const trainedModelUserGroupId =
          getState().pages.currentCustomTrainingState.domainData
            .selectedSourceTrainedModelGroup?.userGroupId

        const trainedModelId =
          getState().pages.currentCustomTrainingState.domainData
            .selectedSourceTrainedModel?.trainedModelId

        const trainedModelDoc = (
          await getDocs(
            trainedModelUserGroupId === sharedUserGroupId
              ? query(
                  getTrainedModelQueriesCollection(sharedUserGroupId),
                  where('trained-model-id', '==', trainedModelId),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              : query(
                  getTrainedModelQueriesCollection(userGroupId),
                  where('trained-model-id', '==', trainedModelId)
                )
          )
        ).docs[0]?.data()

        if (!trainedModelDoc) {
          console.error('Failed to find the trained-model document')
          return
        }

        if (!fireStoreTypeGuardForModelQueryDocument(trainedModelDoc)) {
          console.error('trained-model document is invalid')
          return
        }

        const algorithmId = trainedModelDoc['algorithm-id']
        const algorithmStructureId = trainedModelDoc['algorithm-structure-id']
        const algorithmStructureVersion =
          trainedModelDoc['algorithm-structure-version']['display-name']
        const trainingAlgorithmVersion =
          trainedModelDoc['training-algorithm-version']['display-name']

        const algorithm = getState().app.domainData.algorithms.find(
          (algorithm) => algorithm.algorithmId === algorithmId
        )
        const algorithmVersion =
          algorithm?.trainingAlgorithm.trainingAlgorithmVersions.find(
            (version) =>
              version.trainingAlgorithmVersion === trainingAlgorithmVersion
          )
        const structure = algorithmVersion?.algorithmStructures.find(
          (structure) => structure.algorithmStructureId === algorithmStructureId
        )
        const structureVersion = structure?.algorithmStructureVersions.find(
          (structureVersion) =>
            structureVersion.algorithmStructureVersion.displayName ===
            algorithmStructureVersion
        )

        if (!structureVersion) {
          // ベースモデルのアルゴリズム、学習アルゴリズムバージョンおよびアルゴリズムストラクチャーバージョンの紐付けが不正な場合のエラー
          console.error(
            'Failed to find the algorithm structure version: The algorithm-id or algorithm-structure-version or training-algorithm-version contained in the trained-model document is invalid',
            {
              algorithmId,
              trainingAlgorithmVersion,
              algorithmStructureVersion,
            }
          )
        }

        const datasetTemplateId = structureVersion?.datasetTemplateId

        dispatch(
          customTrainingActions.setSelectedDatasetTemplateId(
            datasetTemplateId ?? ''
          )
        )

        dispatch(
          customTrainingActions.setSettingSubState(
            assignmentSetting ? 'Unselected' : 'Selected'
          )
        )
      }
    },
  setSelectedCustomTrainingGenerateKind:
    (customTrainingGenerateKind: 'EdgeImage' | 'CustomModel') =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        customTrainingActions.setSelectedCustomTrainingGenerateKind(
          customTrainingGenerateKind
        )
      )

      const selectedContainerArchitecture =
        getState().pages.currentCustomTrainingState.domainData
          .selectedBaseInferenceContainerImageIds

      if (
        customTrainingGenerateKind === 'CustomModel' &&
        getState().pages.currentCustomTrainingState.domainData
          .selectedDestinationTrainedModelGroup
      ) {
        dispatch(customTrainingActions.setOutputFormatSubState('Selected'))
      } else if (
        customTrainingGenerateKind === 'EdgeImage' &&
        getState().pages.currentCustomTrainingState.domainData
          .selectedCustomTrainingGenerateKind &&
        getState().pages.currentCustomTrainingState.domainData
          .selectedDestinationTrainedModelGroup &&
        selectedContainerArchitecture.length > 0
      ) {
        dispatch(customTrainingActions.setOutputFormatSubState('Selected'))
      } else {
        dispatch(customTrainingActions.setOutputFormatSubState('Unselected'))
      }
    },
  setSelectedInferenceAlgorithmVersion:
    (selectedInferenceAlgorithmVersion?: InferenceAlgorithmVersion) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        customTrainingActions.setSelectedInferenceAlgorithmVersion(
          selectedInferenceAlgorithmVersion
        )
      )

      const selectedContainerArchitecture =
        getState().pages.currentCustomTrainingState.domainData
          .selectedBaseInferenceContainerImageIds

      if (
        selectedInferenceAlgorithmVersion &&
        selectedContainerArchitecture.length > 0
      ) {
        dispatch(customTrainingActions.setOutputFormatSubState('Selected'))
      } else {
        dispatch(customTrainingActions.setOutputFormatSubState('Unselected'))
      }
    },
  setMlPipelinesMetaData:
    (mlPipelinesMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        customTrainingActions.setMlPipelinesMetaData(mlPipelinesMetaData)
      )

      if (
        mlPipelinesMetaData?.name &&
        getState().pages.currentCustomTrainingState.domainData
          .customModelMetaData?.name
      ) {
        dispatch(customTrainingActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(customTrainingActions.setMetaDataSubState('EmptyRequired'))
      }
    },
  setCustomModelMetaData:
    (customModelMetaData?: MetaData) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(
        customTrainingActions.setCustomModelMetaData(customModelMetaData)
      )

      if (
        customModelMetaData?.name &&
        getState().pages.currentCustomTrainingState.domainData
          .mlPipelinesMetaData?.name
      ) {
        dispatch(customTrainingActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(customTrainingActions.setMetaDataSubState('EmptyRequired'))
      }
    },
  setSelectedContainerArchitecture:
    (baseInferenceContainerImageId: string) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      const selectedIds =
        getState().pages.currentCustomTrainingState.domainData
          .selectedBaseInferenceContainerImageIds
      const index = selectedIds.findIndex(
        (id) => id === baseInferenceContainerImageId
      )

      if (index === -1) {
        selectedIds.push(baseInferenceContainerImageId)
      } else {
        selectedIds.splice(index, 1)
      }

      if (selectedIds.length > 0) {
        dispatch(customTrainingActions.setOutputFormatSubState('Selected'))
      } else {
        dispatch(customTrainingActions.setOutputFormatSubState('Unselected'))
      }

      dispatch(customTrainingActions.setIsSelected(selectedIds))
    },
  executeCustomTraining:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(customTrainingActions.setInProgress(true))
      try {
        const {
          selectedSourceTrainedModel,
          selectedSourceTrainedModelGroup,
          selectedTrainingAlgorithm,
          selectedTrainingAlgorithmVersion,
          selectedDataset,
          selectedSetting,
          mlPipelinesMetaData,
          customModelMetaData,
          selectedBaseInferenceContainerImageIds:
            selectedBaseInferenceContainerImageId,
          isTransfer,
          isSystemEvaluation,
          selectedDestinationTrainedModelGroup,
          selectedCustomModelVersion,
          selectedInferenceAlgorithmVersion,
          selectedCustomTrainingGenerateKind,
          selectedInheritTrainedModel,
        } = getState().pages.currentCustomTrainingState.domainData

        if (
          !(
            selectedTrainingAlgorithmVersion &&
            selectedSourceTrainedModel?.trainedModelId &&
            selectedSourceTrainedModelGroup?.trainedModelGroupId &&
            selectedTrainingAlgorithm?.algorithmId &&
            selectedDataset?.datasetId &&
            mlPipelinesMetaData?.name &&
            customModelMetaData?.name &&
            selectedDestinationTrainedModelGroup &&
            selectedCustomModelVersion &&
            selectedCustomTrainingGenerateKind
          )
        ) {
          console.error('Error invalid request')
          dispatch(customTrainingActions.executeCustomTrainingFailure())
          return
        }
        const customTrainingParams: CustomTrainingParamsType = {
          baseModel: {
            trainedModelId: selectedSourceTrainedModel?.trainedModelId,
            trainedModelGroupId:
              selectedSourceTrainedModelGroup?.trainedModelGroupId,
            userGroupId:
              getState().pages.currentCustomTrainingState.domainData
                .sourceModelGroupDisplayCondition.selectedUserGroupKind ===
              'UserGroup'
                ? getState().app.domainData.authedUser.auth.customClaims
                    .userGroupId
                : getState().app.domainData.authedUser.auth.customClaims
                    .sharedList[0],
          },
          trainingAlgorithmId: selectedTrainingAlgorithm?.algorithmId,
          algorithmVersion: selectedTrainingAlgorithmVersion.algorithmVersion,
          inheritedVersion:
            selectedInheritTrainedModel &&
            selectedDestinationTrainedModelGroup.trainedModelGroupId
              ? {
                  trainedModelId: selectedInheritTrainedModel.trainedModelId,
                  trainedModelGroupId:
                    selectedDestinationTrainedModelGroup.trainedModelGroupId,
                  trainedModelGroupVersion: {
                    displayName:
                      selectedInheritTrainedModel.trainedModelGroupVersion
                        .displayName,
                    major:
                      selectedInheritTrainedModel.trainedModelGroupVersion
                        .major,
                    minor:
                      selectedInheritTrainedModel.trainedModelGroupVersion
                        .minor,
                    patch:
                      selectedInheritTrainedModel.trainedModelGroupVersion
                        .patch,
                  },
                  userGroupId:
                    getState().app.domainData.authedUser.auth.customClaims
                      .userGroupId,
                }
              : undefined,
          datasetId: selectedDataset?.datasetId,
          settingId: selectedSetting?.settingId ?? '',
          inferenceAlgorithmVersion:
            selectedInferenceAlgorithmVersion?.algorithmVersion,
          baseContainerImageIdList: selectedBaseInferenceContainerImageId,
          trainedModelGroupId:
            selectedDestinationTrainedModelGroup?.trainedModelGroupId,
          trainedModelGroupVersion: selectedCustomModelVersion,
          isTransfer: isTransfer,
          isSystemEvaluation: isSystemEvaluation,
          mlPipelineMetadata: {
            name: mlPipelinesMetaData.name ?? '',
            remarks: mlPipelinesMetaData.remarks,
          },
          trainedModelMetadata: {
            name: customModelMetaData.name ?? '',
            remarks: customModelMetaData.remarks ?? '',
          },
          destType: selectedCustomTrainingGenerateKind,
          containerInterfaceVersion:
            selectedInferenceAlgorithmVersion?.containerInterfaceVersion,
        }

        addAlgorithmAdvancedSetting(getState, customTrainingParams)

        const result = (await CustomTrainingApi.executeCustomTraining(
          customTrainingParams
        )) as HttpsCallableResult<{
          result: boolean
          mlPipelineId: string
          stepId: string
        }>

        dispatch(
          customTrainingActions.executeCustomTraining(result.data.result)
        )
        dispatch(
          customTrainingActions.setExecutedInfo({
            mlPipelineId: result.data.mlPipelineId,
            trainingStepId: result.data.stepId,
          })
        )
      } catch (error) {
        console.error(error)
        dispatch(customTrainingActions.executeCustomTrainingFailure())
      } finally {
        dispatch(customTrainingActions.setInProgress(false))
      }
    },
}

const addAlgorithmAdvancedSetting = async (
  getState: () => State,
  customTrainingParams: CustomTrainingParamsType
) => {
  const {
    selectedTrainingAlgorithm,
    selectedClassSet,
    selectedSettingFormat,
    extendedSetting,
  } = getState().pages.currentCustomTrainingState.domainData

  const algorithms = getState().app.domainData.algorithms

  const selectedAlgorithm = algorithms.find(
    (algorithm) =>
      algorithm.algorithmId === selectedTrainingAlgorithm?.algorithmId
  )

  if (!selectedAlgorithm || !selectedAlgorithm.algorithmPurpose) {
    return
  }

  switch (selectedAlgorithm.algorithmPurpose) {
    case 'ObjectRecognition':
      Object.assign(customTrainingParams, {
        extended: {
          objectRecognition: {
            annotationTargeting: {
              enabled:
                extendedSetting.objectRecognition
                  ?.isEnabledAnnotationTargeting ?? false,
              ...(extendedSetting.objectRecognition
                ?.isEnabledAnnotationTargeting && {
                hiddenPercentage:
                  extendedSetting.objectRecognition?.hiddenPercentage,
              }),
            },
          },
        },
      })
      break
    case 'ObjectClassification':
      if (
        selectedClassSet &&
        extendedSetting.objectClassification &&
        selectedSettingFormat
      ) {
        Object.assign(customTrainingParams, {
          extended: {
            objectClassification: {
              classSet: {
                classSetId: selectedClassSet.classSetId,
                userGroupId: selectedClassSet.userGroupId,
              },
              setting: {
                preProcessKind:
                  extendedSetting.objectClassification.preProcessKind,
                baseSize: {
                  height: extendedSetting.objectClassification.baseSize.height,
                  width: extendedSetting.objectClassification.baseSize.width,
                },
                settingFormatId: selectedSettingFormat.settingFormatId,
                settingFormatVersion:
                  selectedSettingFormat.settingFormatVersion,
              },
            },
          },
        })
      }
      break
    default:
      // 他アルゴリズムではプロパティの付与は行わない
      break
  }
}
