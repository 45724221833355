import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { FeatureDataTransferringParamsType } from './types'

const timeoutLimitSeconds = 15

export const FeatureDataTransferringApi = {
  /**
   * 特徴量データ転送
   * @param featureDataTransferringParams 特徴量データ転送情報
   * @returns 結果
   */
  executeFeatureDataTransferring: (
    featureDataTransferringParams: FeatureDataTransferringParamsType
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'feature-data-transferring-execute',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      ...featureDataTransferringParams,
    }),
}
