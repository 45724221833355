import { Timestamp } from 'state/firebase'
import { ResourceLoadingState, TransactionStatusKind } from 'state/utils/types'

export const DatasetAugmentationDetailActionType = {
  SET_CURRENT_DATASET_AUGMENTATION_DETAIL:
    'SET_CURRENT_DATASET_AUGMENTATION_DETAIL',
  SET_DATASET_AUGMENTATION_DETAIL_STATE:
    'SET_DATASET_AUGMENTATION_DETAIL_STATE',
  SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_DETAIL:
    'SET_IN_PROGRESS_FOR_DATASET_AUGMENTATION_DETAIL',
  CLEAR_DATASET_AUGMENTATION_DETAIL_STATE:
    'CLEAR_DATASET_AUGMENTATION_DETAIL_STATE',
  SET_TOAST_INFO_FOR_DATASET_AUGMENTATION_DETAIL:
    'SET_TOAST_INFO_FOR_DATASET_AUGMENTATION_DETAIL',
  SET_TRAINED_MODEL_DL_LINKS: 'SET_TRAINED_MODEL_DL_LINKS',
  SET_SELECTED_GROUPED_DATA_ID_DATASET_AUGMENTATION_DETAIL:
    'SET_SELECTED_GROUPED_DATA_ID_DATASET_AUGMENTATION_DETAIL',
  SET_ML_PIPELINE_LOG_FILES_FOR_DATASET_AUGMENTATION:
    'SET_ML_PIPELINE_LOG_FILES_FOR_DATASET_AUGMENTATION',
} as const

interface Progress {
  transactionStatus: TransactionStatusKind
  progressRate: number
}

export interface MediaLink {
  mediaName: string
  mediaUrl: string
  mediaSize: number
}

export type StepStatus =
  | 'Pending'
  | 'Starting'
  | 'PreProcessing'
  | 'Training'
  | 'Evaluating'
  | 'PostProcessing'
  | 'Completed'
  | 'Failed'

export interface MlPipelineLogFile {
  fileName: string
  fileLink: string
  fileSize: number
  createdAt: Date
}

export interface ToastInfo {
  type: 'info' | 'warning' | 'error'
  title: string
  targets: string[]
}

export interface ThreeDimensionalDataLabel {
  id: string
  name: string
  category: string
}

export interface Texture {
  id: string
  name: string
  overview: string
  isSaved: boolean
}

export interface ThreeDimensionalData {
  fileName: string
  aug3dObjectId: string
  label: ThreeDimensionalDataLabel
  texture: Texture
}

export interface AccountName {
  firstName: string
  familyName: string
}

export interface TargetDocument {
  '3d-object': {
    'aug-3d-object-id': string
    'user-group-id': string
  }
  label: {
    'label-id': string
    name: string
    supercategory: string
  }
  texture: {
    'aug-texture-id': string
    'aug-texture-revision': number
    'user-group-id': string
  }
}

export interface SceneBackGroundDocument {
  'aug-3d-scene-background-id': string
  'aug-3d-scene-background-revision': string
  'user-group-id': string
}

export interface SceneCameraDocument {
  'aug-3d-scene-camera-id': string
  'aug-3d-scene-camera-revision': string
  'user-group-id': string
}

export interface SceneLightGroupDocument {
  'aug-3d-scene-light-group-id': string
  groups: {
    'aug-3d-scene-light-id': string
    'aug-3d-scene-light-revision': string
    'user-group-id': string
  }[]
}

export interface Scene {
  backgrounds: {
    id: string
    name: string
    userGroupId: string
  }[]
  cameras: {
    id: string
    name: string
    userGroupId: string
  }[]
  lightGroups: {
    id: string
    name: string
    userGroupId: string
  }[]
}

export interface RenderersSetting {
  augmentationNumber: number
  size: {
    width: number
    height: number
  }
  depth: {
    min: number
    max: number
  }
}

export interface Placement {
  seed: number
  layout: {
    id: string
    name: string
  }
}

interface Dataset {
  datasetId: string
  datasetName: string
}

interface OutputFormat {
  datasetTemplateName: string
  trainValidRatio?: number
}

export interface CurrentDatasetAugmentationDetail {
  mlPipelineId: string
  mlPipelineName: string
  mlPipelineRemarks: string
  progress: Progress
  startedAt: Timestamp
  endedAt: Timestamp
  '3dData': ThreeDimensionalData[]
  renderersSettings: RenderersSetting
  scene: Scene
  placement: Placement
  outputFormat: OutputFormat
  dataset: Dataset
  createdBy: string | { firstName: string; familyName: string }
}

export interface DatasetAugmentationDetailState {
  mlPipelineDataState: ResourceLoadingState
  mlPipelineLogSubState: ResourceLoadingState
}

export interface DatasetAugmentationDetailDomainData {
  currentDatasetAugmentationDetail?: CurrentDatasetAugmentationDetail
  mlPipelineLogFiles: MlPipelineLogFile[]
}

export interface DatasetAugmentationDetailAppState {
  datasetAugmentationDetailState: DatasetAugmentationDetailState
  toastInfo: ToastInfo | undefined
  inProgress: boolean
}

export interface DatasetAugmentationDetail {
  domainData: DatasetAugmentationDetailDomainData
  appState: DatasetAugmentationDetailAppState
}
