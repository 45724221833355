import { Dispatch } from 'redux'
import { State } from 'state/store'
import { customTrainingListActions } from './actions'
import {
  getMlPipelineQueriesCollection,
  getDatasetQueryCollection,
  getTrainedModelQueriesCollection,
} from 'state/firebase'
import { CustomTraining } from './types'
import {
  convertProgressRateByTransactionStatusForCustomTraining,
  convertQueryStartEndCodeBySearchValue,
} from 'state/utils'
import { isUndefined } from 'utils/typeguard'
import { Algorithm } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getCountFromServer,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { fireStoreTypeGuard as fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument } from 'utils/fireStore/customTrainingMLPipelineQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument } from 'utils/fireStore/datasetQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'

const createCustomTrainingData = async (
  ugid: string,
  snapShot: QuerySnapshot<DocumentData>,
  algorithms: Algorithm[]
): Promise<(CustomTraining | undefined)[]> =>
  // 関連のDocsを取得し表示用のカスタム学習データを生成
  await Promise.all(
    snapShot.docs.map(async (document: DocumentData) => {
      const mlPipelineQueryDocData = document.data()
      if (
        !fireStoreTypeGuardForCustomTrainingMLPipelineQueryDocument(
          mlPipelineQueryDocData
        )
      ) {
        return undefined
      }

      // 生成モデル名取得
      let trainedModelData = undefined
      let datasetData = undefined

      // カスタム学習名取得
      const algorithm = algorithms.find((algorithm) => {
        return (
          algorithm.algorithmId ===
          mlPipelineQueryDocData['training-step']?.['src']?.['algorithm-id']
        )
      })
      const trainedModelId = mlPipelineQueryDocData['training-step']?.[
        'dest'
      ]?.['trained-model-id']
        ? mlPipelineQueryDocData['training-step']?.['dest']?.[
            'trained-model-id'
          ]
        : mlPipelineQueryDocData['training-step']?.['dest']?.[
            'trained-model'
          ]?.['trained-model-id']
      if (trainedModelId) {
        trainedModelData = (
          await getDoc(
            doc(getTrainedModelQueriesCollection(ugid), trainedModelId)
          )
        ).data()
        if (!fireStoreTypeGuardForModelQueryDocument(trainedModelData)) {
          return undefined
        }
      }

      if (mlPipelineQueryDocData['training-step']?.['src']) {
        datasetData = (
          await getDoc(
            doc(
              getDatasetQueryCollection(ugid),
              mlPipelineQueryDocData['training-step']['src']['dataset-id']
            )
          )
        ).data()

        if (!fireStoreTypeGuardForDatasetQueryDocument(datasetData)) {
          return undefined
        }
      }

      return {
        mlPipelineId: mlPipelineQueryDocData['ml-pipeline']['ml-pipeline-id'],
        mlPipelineName: mlPipelineQueryDocData['ml-pipeline-metadata']['name'],
        algorithmName: algorithm ? algorithm.metadata.name.ja : '',
        progress: {
          transactionStatus:
            mlPipelineQueryDocData['ml-pipeline']['transaction-status'],
          progressRate: convertProgressRateByTransactionStatusForCustomTraining(
            mlPipelineQueryDocData['ml-pipeline']['transaction-status']
          ),
        },
        startedAt: mlPipelineQueryDocData['ml-pipeline']['started-at'],
        endedAt:
          mlPipelineQueryDocData['ml-pipeline']['ended-at'] &&
          mlPipelineQueryDocData['ml-pipeline']['ended-at'].seconds === 0
            ? undefined
            : mlPipelineQueryDocData['ml-pipeline']['ended-at'],
        dataset: {
          datasetId: datasetData
            ? mlPipelineQueryDocData['training-step']['src']['dataset-id']
            : '',
          datasetName: datasetData ? datasetData['dataset-name'] : '',
        },
        trainedModel: {
          trainedModelId: trainedModelData
            ? trainedModelData['trained-model-id']
            : '',
          trainedModelName: trainedModelData
            ? trainedModelData['trained-model-name']
            : '',
        },
        accountId: mlPipelineQueryDocData['created-by'],
      } as CustomTraining
    })
  )

// カスタム学習一覧の配列をセット
const getCustomTrainingRelationDocs = async (
  dispatch: Dispatch,
  getState: () => State
) => {
  const ugid =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId

  const algorithms = getState().app.domainData.algorithms
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.customTrainingListState.domainData
      .currentCustomTrainingListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(
      customTrainingListActions.setCurrentCustomTrainingListSnapshot(undefined)
    )
  }

  // 表示条件取得
  const condition =
    getState().pages.customTrainingListState.domainData
      .customTrainingDisplayCondition

  // ベースのQuery（表示件数分指定）
  let commonQuery = query(
    getMlPipelineQueriesCollection(ugid),
    limit(condition.displayNumber),
    where('ml-pipeline.ml-pipeline-kind', '==', 'CustomTraining')
  )

  if (condition.algorithmId) {
    commonQuery = query(
      commonQuery,
      where('training-step.src.algorithm-id', '==', condition.algorithmId)
    )
  }

  // 文字列検索が存在する場合は、MLPipelineIdの前方一致条件をQueryに設定
  if (condition.searchValue) {
    const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
      condition.searchValue
    )

    // whereの範囲検索時は、第１ソートキーはFirebase SDK の仕様上、MLPipelineIdを指定する必要がある
    // 開始日時のソートとの併用不可
    commonQuery = query(
      commonQuery,
      orderBy('ml-pipeline.ml-pipeline-id', 'asc'),
      where('ml-pipeline.ml-pipeline-id', '>=', startCode),
      where('ml-pipeline.ml-pipeline-id', '<=', endCode)
    )
  } else {
    commonQuery = query(
      commonQuery,
      orderBy(condition.sortKey, condition.sortOrder)
    )
  }

  const mlPipelineIds =
    getState().pages.customTrainingListState.domainData.mlPipelineIds
  // 取得済みのカスタム学習の最後尾
  let mlPipeLineDoc: DocumentData | undefined = undefined
  if (mlPipelineIds.length > 0) {
    mlPipeLineDoc = await getDoc(
      doc(
        getMlPipelineQueriesCollection(ugid),
        mlPipelineIds[mlPipelineIds.length - 1]
      )
    )
  }

  // 取得済みのカスタム学習が存在する場合は、取得済みの最後尾以降のデータから取得する
  if (mlPipeLineDoc) {
    commonQuery = query(commonQuery, startAfter(mlPipeLineDoc))
  }

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      const customTrainingData = await createCustomTrainingData(
        ugid,
        snapshot,
        algorithms
      )
      // ML Pipeline IDを保持
      // すでに保持しているIDが存在する場合は、現状の検索位置以降のIDを一度破棄し
      // 新たに取得したML Pipeline IDを保持する
      if (customTrainingData.length >= 0) {
        if (mlPipeLineDoc) {
          const index = mlPipelineIds.findIndex(
            (id) => id === mlPipeLineDoc?.id
          )
          const beforePageIds = mlPipelineIds.slice(0, index + 1)
          dispatch(
            customTrainingListActions.setMLPipeLineIdList([
              ...beforePageIds,
              ...snapshot.docs.map((doc: DocumentData) => doc.id),
            ])
          )
        } else {
          // IDを保持
          dispatch(
            customTrainingListActions.setMLPipeLineIdList([
              ...mlPipelineIds,
              ...snapshot.docs.map((doc: DocumentData) => doc.id),
            ])
          )
        }
      }
      // 取得したカスタム学習の一覧を保持
      dispatch(
        customTrainingListActions.setList(
          customTrainingData.filter(
            (item) => item !== undefined
          ) as CustomTraining[]
        )
      )
      let totalCountQuery = query(
        getMlPipelineQueriesCollection(ugid),
        where('ml-pipeline.ml-pipeline-kind', '==', 'CustomTraining')
      )

      if (condition.algorithmId) {
        totalCountQuery = query(
          totalCountQuery,
          where('training-step.src.algorithm-id', '==', condition.algorithmId)
        )
      }

      if (condition.searchValue) {
        const { startCode, endCode } = convertQueryStartEndCodeBySearchValue(
          condition.searchValue
        )
        totalCountQuery = query(
          totalCountQuery,
          where('ml-pipeline.ml-pipeline-id', '>=', startCode),
          where('ml-pipeline.ml-pipeline-id', '<=', endCode)
        )
      }

      const totalCount = await getCountFromServer(totalCountQuery)
      dispatch(
        customTrainingListActions.setListDisplayCondition({
          ...condition,
          totalCount: totalCount.data().count,
        })
      )
    }
  )

  // 現在、表示中のカスタム学習（ML Pipeline Docsの Snapshot を保持）
  dispatch(
    customTrainingListActions.setCurrentCustomTrainingListSnapshot(snapshot)
  )
}

export const customTrainingListOperations = {
  /** リストを取得する */
  getCustomTrainingList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(customTrainingListActions.setInProgress(true))

        // 現在のページ表示に必要なID以外を破棄する（戻る/ソートで、前ページに移動する際、不要なIDを破棄）
        const currentMlPipelineIds: string[] =
          getState().pages.customTrainingListState.domainData.mlPipelineIds
        const condition =
          getState().pages.customTrainingListState.domainData
            .customTrainingDisplayCondition
        const mlPipelineIds = currentMlPipelineIds.slice(
          0,
          condition.displayNumber * condition.pageNumber
        )
        dispatch(customTrainingListActions.setMLPipeLineIdList(mlPipelineIds))

        // カスタム学習一覧に必要な関連Docsを取得
        await getCustomTrainingRelationDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(customTrainingListActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribe:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.customTrainingListState.domainData
            .currentCustomTrainingListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },
}
