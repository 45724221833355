import { Timestamp } from 'state/firebase'
import { Customer } from 'utils/ducks/customerChange/types'
import {
  ENString,
  I18nString,
  Timestamps,
  Version,
  VersionWithPreRelease,
} from 'types/StateTypes'
import { AccountGroupRole, CustomerRole } from 'views/utils/types'

// Type
export const annotationSetKindArray = ['Train', 'Valid', 'Test']
export const annotationSetKindAllArray = [
  ...annotationSetKindArray,
  'Inference',
]
export const annotationTrainKindArray = ['RI', 'SI']

export type AnnotationSetKind = (typeof annotationSetKindArray)[number]
export type AnnotationSetKindAll = (typeof annotationSetKindAllArray)[number]
export type AnnotationTrainKind = (typeof annotationTrainKindArray)[number]

export interface AnnotationSetKindDocument {
  'annotation-set-kind': AnnotationSetKind
  conditions?: {
    'train-kind'?: AnnotationTrainKind
  }
  'is-user-input': boolean
  name: ENString
}

export interface Auth {
  token: string
  customClaims: CustomClaims
}

export interface CustomClaims {
  accountId: string
  accountGroupId: string
  role: string
  sharedList: string[]
  superUser: boolean
  userGroupId: string
}

export interface AuthedUser {
  userId: string
  mailAddress: string
  phoneNumber?: string
  locale?: string
  customers: Customer[]
  auth: Auth
}

export interface Glossaries {
  [x: string]: { [x: string]: string }
}

export interface MetaData {
  name: {
    ja: string
    en: string
  }
  overview: {
    ja: string
    en: string
  }
}

export interface AvailableVersion {
  [x: string]: {
    lowerLimit: VersionWithPreRelease
    upperLimit: VersionWithPreRelease
  }
}

export interface InferenceAlgorithmVersion {
  inferenceAlgorithmVersion: string
  algorithmVersion: VersionWithPreRelease
  inferenceCodeVersion: Version
  metadata: {
    remarks: {
      ja: string
      en: string
    }
  }
  releasedAt: Timestamp
  availableVersion: AvailableVersion
}

export interface InferenceAlgorithm {
  inferenceCodeId: string
  inferenceAlgorithmVersions: InferenceAlgorithmVersion[]
}

export interface TrainingAlgorithm {
  inferenceCodeId: string
  trainingAlgorithmVersions: TrainingAlgorithmVersion[]
}

export interface AlgorithmStructureVersion {
  algorithmStructureId: string
  algorithmStructureVersion: Version
  datasetTemplateId: string
  metadata: {
    name: {
      ja: string
      en: string
    }
    remarks: {
      ja: string
      en: string
    }
  }
  createdAt: Timestamp
}

export interface AlgorithmStructure {
  algorithmStructureId: string
  mlFramework: { [x: string]: string }
  algorithmStructureKind: string
  algorithmStructureVersions: AlgorithmStructureVersion[]
  featureDataFormatId?: string
}

export interface TrainingAlgorithmVersion {
  trainingAlgorithmVersion: string
  algorithmVersion: VersionWithPreRelease
  mlFramework: { [x: string]: string }
  inferenceCodeVersion: Version
  annotationFormatId: string
  annotationFormatVersion: Version
  algorithmStructureRelationId: string
  metadata: {
    remarks: {
      ja: string
      en: string
    }
  }
  releasedAt: Timestamp
  availableVersion?: AvailableVersion
  algorithmStructures: AlgorithmStructure[]
}

export interface Algorithm {
  algorithmId: string
  algorithmPurpose: string
  metadata: MetaData
  inferenceAlgorithm: InferenceAlgorithm
  trainingAlgorithm: TrainingAlgorithm
}

export interface AnnotationFormat {
  annotationFormatId: string
  annotationFormatKind: string
  annotationFormatVersions: {
    annotationFormatId: string
    annotationFormatVersion: {
      displayName: string
      major: number
      minor: number
      patch: number
    }
    availableVersion: {
      lowerLimit: {
        displayName: string
        major: number
        minor: number
        patch: number
      }
      upperLimit: {
        displayName: string
        major: number
        minor: number
        patch: number
      }
    }
  }[]
}

export interface FeatureDataFormat {
  featureDataFormatId: string
  featureDataFormatKind: string
  fileType: string
}

export interface AnnotationSetKindListItem {
  annotationSetKind: AnnotationSetKind
  conditions?: {
    trainKind?: AnnotationTrainKind
  }
  isUserInput: boolean
  name: ENString
}

export interface DatasetTemplate extends Timestamps {
  datasetTemplateId: string
  algorithmId: string
  annotationSetKindList: AnnotationSetKindListItem[]
  metadata: {
    name: I18nString
    remarks: I18nString
  }
}

// ActionType
export const DomainDataActionType = {
  SET_AUTHED_USER: 'SET_AUTHED_USER',
  SET_GLOSSARIES: 'SET_GLOSSARIES',
  SET_ALGORITHMS: 'SET_ALGORITHMS',
  SET_DATASET_TEMPLATES: 'SET_DATASET_TEMPLATES',
  SET_ANNOTATION_FORMATS: 'SET_ANNOTATION_FORMATS',
  SET_FEATURE_DATA_FORMATS: 'SET_FEATURE_DATA_FORMATS',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_IS_PASSWORD_RESET: 'SET_IS_PASSWORD_RESET',
  SET_IN_PROGRESS: 'SET_IN_PROGRESS',
  CLEAR_DOMAIN_DATA: 'CLEAR_DOMAIN_DATA',
} as const

export interface UserProfile {
  firstName: string
  familyName: string
  accountGroupName: string
  accountGroupRole: AccountGroupRole
  language: string
  locate: string
  role: CustomerRole
  customerId: string
  customerName: string
}
