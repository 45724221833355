import { httpsCallable, HttpsCallableResult } from 'firebase/functions'
import { functionsInstance } from 'state/firebase'
import { AidpCloudFunctionsUrl } from 'state/utils'
const timeoutLimitSeconds = 15

export const FeatureDataDetailApi = {
  getFeatureDataFiles: (featureDataId: string): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'feature-data-files',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      featureDataId: featureDataId,
    }),
  getMlPipelineGenerateFiles: (
    mlPipelineId: string
  ): Promise<HttpsCallableResult> =>
    httpsCallable(
      functionsInstance,
      AidpCloudFunctionsUrl() + 'feature-data-generating-files',
      {
        timeout: timeoutLimitSeconds * 1000,
      }
    )({
      mlPipelineId: mlPipelineId,
    }),
}
