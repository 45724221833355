import {
  Action,
  CreateAction,
  ActionsUnion,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  SceneCamera,
  SceneCameraListActionType,
  SceneCameraListDisplayCondition,
} from './types'

export type SceneCameraListActions = ActionsUnion<typeof sceneCameraListActions>

export const sceneCameraListActions = {
  setSceneCameraIds: (
    sceneCameraIds: string[]
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_LIST',
    { sceneCameraIds: string[] }
  > =>
    CreateAction(
      SceneCameraListActionType.SET_SCENE_CAMERA_IDS_FOR_SCENE_CAMERA_LIST,
      {
        sceneCameraIds,
      }
    ),
  setCurrentSceneCameraListSnapshot: (
    currentSceneCameraListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT',
    { currentSceneCameraListSnapshot?: () => void }
  > =>
    CreateAction(
      SceneCameraListActionType.SET_CURRENT_SCENE_CAMERA_LIST_SNAPSHOT,
      {
        currentSceneCameraListSnapshot,
      }
    ),
  setSceneCameraList: (
    sceneCameraList: SceneCamera[]
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_LIST',
    { sceneCameraList: SceneCamera[] }
  > =>
    CreateAction(SceneCameraListActionType.SET_SCENE_CAMERA_LIST, {
      sceneCameraList,
    }),
  clearSceneCameraList: (): Action<'CLEAR_SCENE_CAMERA_LIST'> =>
    CreateAction(SceneCameraListActionType.CLEAR_SCENE_CAMERA_LIST),
  setListDisplayCondition: (
    sceneCameraListDisplayCondition: SceneCameraListDisplayCondition
  ): ActionWithPayload<
    'SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION',
    { sceneCameraListDisplayCondition: SceneCameraListDisplayCondition }
  > =>
    CreateAction(
      SceneCameraListActionType.SET_SCENE_CAMERA_LIST_DISPLAY_CONDITION,
      {
        sceneCameraListDisplayCondition,
      }
    ),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_SCENE_CAMERA_LIST',
    { inProgress: boolean }
  > =>
    CreateAction(
      SceneCameraListActionType.SET_IN_PROGRESS_FOR_SCENE_CAMERA_LIST,
      {
        inProgress,
      }
    ),
  clearSceneCameraListState: (): Action<'CLEAR_SCENE_CAMERA_LIST_STATE'> =>
    CreateAction(SceneCameraListActionType.CLEAR_SCENE_CAMERA_LIST_STATE),
}
